<template>
  <Content>
    <template #contentTitle>{{ $t('menu.products_consignment_inventory') }}</template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-select v-model:value="selectedWarehouseNo"
                        :placeholder="$t('logistics.warehouse_code')"
                        :show-search="true"
                        option-filter-prop="search-key"
                        allow-clear
                        @change="handleChangeStatus"
                        style="width: 250px;">

                <a-select-option v-for="item in warehouseNos"
                                 :key="item.value"
                                 :title="item.text1 + '('+ item.text +')'"
                                 :value="item.value"
                                 :search-key="item.text1 + item.text">
                  {{item.text1 + '('+ item.text +')'}}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <CSearchShop v-model:shopId="shopId"></CSearchShop>
            </a-col>
            <a-col>
              <SearchProduct ref="refSearchProduct"></SearchProduct>
            </a-col>
            <a-col>
              <a-button type="primary"
                        :loading="productListLoading"
                        @click="handleChangeStatus">{{$t('common.query')}}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="link" @click="handleShowPinModal">{{$t('common.export_product_in_the_warehouse_time')}}</a-button>
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-table :columns="columns"
                 :data-source="productList"
                 :scroll="{ x:400,y: wrap.contentHeight - 138}"
                 size="small"
                 :pagination="false"
                 :loading="productListLoading"
                 class="product-table">

          <template #productNameAndSku>
            <span>
              {{ $t("warehouse.product_name") }}/{{ $t("warehouse.product_no") }}
            </span>
          </template>
          <template #awaitOutWarehouseCount>
            <span>
              {{ $t("warehouse.await_out_warehouse_count") }}
            </span>
          </template>
          <template #packagingSize>
            {{ $t("warehouse.product_dimensions") }}
            <CTooltip :title="$t('warehouse.product_of_size')" />
          </template>

          <template #packagingWeight>
            {{ $t("warehouse.product_weight") }}
            <CTooltip :title="$t('warehouse.product_contains_the_weight_of_the_package')" />
          </template>
          <template #notApplicableTitle>
            {{ $t('warehouse.not_applicable') }}
            <CTooltip :title="$t('warehouse.not_applicable_tips')" />
          </template>

          <template #productNameAndSkuCustom="{ record }">
            <a-space>
              <div class="table-list-img-common ">
                <c-image :src="record.imgSrc" />
              </div>
              <div>
                <div>{{ record.productName}}</div>
                <div>{{ record.productNo}}</div>
              </div>
            </a-space>
          </template>
          <template #customer="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
             <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
        </a-table>
      </div>

      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleExportProductWarehouseTime"
      />
    </template>
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data="pageData"> </CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";
import { Row, Col, Table, Select, Button, Form, Space, Input } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import Content from "../components/Content.vue";
import CPager from "../components/CPager.vue";
import CImage from "../components/CImage.vue";
import SearchProduct from "../components/SearchProduct.vue";
import CSearchShop from "../components/CSearchShop.vue"
import CPinTipsModal from "@/views/components/CPinTipsModal.vue"
import CTooltip from "@/views/components/CTooltip.vue"
import { getManageDeliveryWarehouseInventoryPaged,exportInventoryListWithStockAgeConsignment } from "@/api/modules/products/inventory";
import { getOptionWarehouses } from '@/api/modules/common/index';
import { warehouseType as warehouseTypeEnum } from "@/enum/enum.json";
import { downloadFile } from '@/utils/downloader.js';
import { dateString, gToKg } from '@/utils/general'

export default defineComponent({
  name: "products_consignment_inventory",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ASpace: Space,
    AInput: Input,
    SearchProduct,
    CImage,
    Content,
    CPager,
    CSearchShop,
    CPinTipsModal,
    CTooltip,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        slots: {
          title: "productNameAndSku",
          customRender: "productNameAndSkuCustom",
        },
        width: 200,
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 170,
      },
      {
        width: 120,
        title: 'SESKU',
        dataIndex: "seSku",
      },
      {
        dataIndex: "warehouseNo",
        width: 120,
        title: () => vueI18n.t('logistics.warehouse_code'),
      },
      {
        dataIndex: "packagingSize",
        width: 150,
        slots: {
          title: "packagingSize",
        },
      },
      {
        dataIndex: "packagingWeight",
        width: 120,
         slots: {
          title: "packagingWeight",
        },
      },
      {
        dataIndex: "inWarehousePackagingSize",
        width: 150,
        title: () => vueI18n.t('warehouse.in_warehouse_packaging_size'),
      },
      {
        dataIndex: "inWarehousePackagingWeight",
        width: 120,
        title: () => vueI18n.t('warehouse.in_warehouse_packaging_weight'),
      },
      {
        dataIndex: "awaitInWarehouseCount",
        align: "center",
        width: 100,
        title: () => vueI18n.t('warehouse.await_in_warehouse_count'),
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.on_warehouse_count'),
        children: [
          {
            dataIndex: "onWarehouseUsableCount",
            align: "center",
            title: () => vueI18n.t('warehouse.applicable'),
            width: 100,
          },
          {
            dataIndex: "onWarehouseUnusableCount",
            align: "center",
            width: 100,
            slots: {
              title: 'notApplicableTitle',
            }
          },
        ]
      },
      {
        dataIndex: "awaitOutWarehouseCount",
        align: "center",
        width: 100,
        title: () => vueI18n.t('warehouse.await_out_warehouse_count'),
      },
    ];
    //#endregion

    const refSearchProduct = ref();
    const refPage = ref();

    const state = reactive({
      warehouseNos: [],
      productListLoading: false,
    });

    const data = reactive({
      selectedWarehouseNo: null,
      shopId: null,
      seSku: null,
      productList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });

   const  pinModalState= reactive({
        visible: false,
        loading: false,
        pinCode:null,
      })

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    }

    const handleChangeStatus = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleSearch();
    }

    const handleWarehouseNo = async () => {
      const data = {
        warehouseType: warehouseTypeEnum.consignment,
        isActive: true,
      }
      let { result } = await getOptionWarehouses(data);
      state.warehouseNos = result ?? [];
    }

    const handleSearch = async () => {
      state.productListLoading = true;

      let searchData = {
        productId: refSearchProduct.value.selectedProductId,
        warehouseId: data.selectedWarehouseNo,
        shopId: data.shopId,
        seSku: data.seSku,
        ...data.pageData
      };

      getManageDeliveryWarehouseInventoryPaged(searchData).then((res) => {
        if (res.result) {
          let { items, totalCount } = res.result;
          data.productList = items.map((x, index) => {
            return {
              key: index,
              imgSrc: x.imgUrl,
              productNo: x.productNo,
              shopName: x.shopName,
              shopId: x.shopId,
              shopNo: x.shopNo,
              shopMark:x.shopMark,
              productName: x.productName,
              warehouseNo: x.warehouseNo,
              seSku: x.seSku,
              packagingSize: (!x.packingLength || !x.packingWidth || !x.packingHeight) ? "-" : x.packingLength + 'x' + x.packingWidth + 'x' + x.packingHeight + "cm",
              packagingWeight: !x.packingWeight ? "-" : gToKg(x.packingWeight) + "kg",
              inWarehousePackagingSize: (!x.inwPackingLength || !x.inwPackingLength || !x.inwPackingWidth) ? "-" : x.inwPackingLength + 'x' + x.inwPackingWidth + 'x' + x.inwPackingHeight + "cm",
              inWarehousePackagingWeight: !x.inwPackingWeight ? "-" : gToKg(x.inwPackingWeight) + "kg",
              awaitInWarehouseCount: x.awaitInWarehouseCount,
              onWarehouseCount: x.onWarehouseCount,
              onWarehouseUsableCount: x.onWarehouseUsableCount,
              onWarehouseUnusableCount: x.onWarehouseCount - x.onWarehouseUsableCount,
              awaitOutWarehouseCount: x.awaitOutWarehouseCount,
            }
          })
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.productList = [];
          data.pageData.totalCount = 0;
        }

      }).finally(() => {
        state.productListLoading = false;
      });

    }

    const handleShowPinModal = () => {
      pinModalState.visible = true;
      pinModalState.pinCode = null;
      pinModalState.loading = false;
    }

     const handleExportProductWarehouseTime=(pinCode)=>{
      pinModalState.loading = true;

      let url = exportInventoryListWithStockAgeConsignment()
      const postData = {
        "productId": refSearchProduct.value.selectedProductId,
        "warehouseId": data.selectedWarehouseNo,
        "seSku": data.seSku,
        "pinCode": pinCode,
        "shopId": data?.shopId,
      };
      downloadFile(url, `consignment_inventory_age_${dateString()}.csv`, "POST", postData)
        .then(() => {
      pinModalState.loading = false;
      pinModalState.visible = false;
        })
        .catch(() => {
      pinModalState.loading = false;
        })
    }

    onMounted(async () => {
      handleWarehouseNo();
      handleSearch();
    })

    return {
      columns,
      refPage,
      refSearchProduct,
      ...toRefs(state),
      ...toRefs(data),
      pinModalState,
      handlePage,
      handleChangeStatus,
      handleWarehouseNo,
      handleChangeStatus,
      handleExportProductWarehouseTime,
      handleShowPinModal
    };
  },
})
</script>

import client from "../../client";

const Api = {
  // 库存调整列表
  getProductInventoryAdjustmentListPaged: "/api/WarehouseService/ProductInventoryAdjustment/GetListPaged",
  // 根据Id获取单个配送仓产品库存调整信息
  getProductInventoryAdjustmentById: "/api/WarehouseService/ProductInventoryAdjustment/GetById",
};

// 库存调整列表
export const getProductInventoryAdjustmentListPaged = (data) => {
  return client.request({
    url: Api.getProductInventoryAdjustmentListPaged,
    data,
    method: 'POST'
  })
}

// 根据Id获取单个配送仓产品库存调整信息
export const getProductInventoryAdjustmentById = (params) => {
  return client.request({
    url: Api.getProductInventoryAdjustmentById,
    params,
    method: 'GET'
  })
}

<template>
  <a-form-item class="mb-0" :label="$t('devops.alibaba_cloud_oss_temporary_space')">
    <a-row type="flex" align="middle" :gutter="16">
      <a-col style="width: 250px;">
        <a-input-number v-model:value="state.hour" :min="1" :max="87600" :precision="0"></a-input-number> {{ $t('devops.hours_ago') }}
      </a-col>
      <a-col>
        <a-button @click="handleEmptyCache()">{{ $t('devops.empty_cache') }}</a-button>
      </a-col>
    </a-row>
  </a-form-item>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { Button, Col, Form, InputNumber, Row, message } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  emits: ["confirm"],
  components: {
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    AInputNumber: InputNumber,
    AButton: Button,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      loading: false,
      hour: 1,
    })

    const handleEmptyCache = () => {
      if (!state.hour) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("account.time")]));
        return;
      }
      emit("confirm", state.hour);
    }

    return {
      state,
      handleEmptyCache,
    }
  }
})
</script>

<style scoped>

</style>
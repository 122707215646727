<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('warehouse.change_the_partner')"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :closable="false"
    :mask-closable="false"
    :centered="true"
    :ok-text="$t('common.close')"
    width="auto"
    @ok="handleClose"
  >
    <a-form ref="formRef">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-form-item :label="$t('warehouse.new_partner')">
            <a-auto-complete
              v-model:value="searchValue"
              style="width: 250px"
              allowClear
              @focus="handleSearchProduct"
              @search="handleSearchProduct"
              @select="handleSelectProduct"
            >
              <template #default>
                <a-input :placeholder="$t('warehouse.new_partner')"></a-input>
              </template>
              <template #options>
                <a-select-option
                  v-for="item in partnerList"
                  :key="item.id"
                  :data="item"
                  :value="`${item.name}(${item.no})`"
                >
                  {{ item.name }}
                  <div v-if="item.id">ID: {{ item.id }}</div>
                  <div v-if="item.no">NO: {{ item.no }}</div>
                </a-select-option>
              </template>
            </a-auto-complete>
          </a-form-item>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="confirmLoading" @click="handleSubmit">{{ $t('common.confirm') }}</a-button>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      :columns="columns"
      :data-source="tableData.list"
      :pagination="false"
      :scroll="{ x: 500, y: 400 }"
      size="small"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :loading="tableData.loading"
    >
      <template #newPartner="{ record }">
        <div>{{ record.newPartnerName }}</div>
        <div>{{ record.newPartnerNo }}</div>
      </template>
      <template #oldPartner="{ record }">
        <span v-if="!record.oldPartnerId">-</span>
        <div>{{ record.oldPartnerName }}</div>
        <div>{{ record.oldPartnerNo }}</div>
      </template>
      <template #creationTime="{ record }">
        {{ $filters.utcToCurrentTime(record.creationTime) }}
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from "vue";
import {
  AutoComplete,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Select,
  Button,
  Table,
message,
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import {
  createOrUpdatePartnerShopRl,
  getPartnerOptionList,
  getPartnerShopRlChangeRecordList,
} from "@/api/modules/customer/company/list.js";
import { debounce } from "@/utils/general";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    AAutoComplete: AutoComplete,
    AInput: Input,
    ASelectOption: Select.Option,
    aButton: Button,
    ATable: Table,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);

    const state = reactive({
      visible: false,
      confirmLoading: false,
      searchValue: null,
      formState: {
        shopId: null,
        partnerId: null,
      },
      partnerList: [],
      tableData: {
        loading: false,
        list: []
      },
      isRefresh: false
    });

    const columns = [
      {
        title: () => vueI18n.t("warehouse.new_partner"),
        width: 300,
        slots: {
          customRender: "newPartner",
        },
      },
      {
        title: () => vueI18n.t("warehouse.old_partner"),
        width: 300,
        slots: {
          customRender: "oldPartner",
        },
      },
      {
        title: () => vueI18n.t("logistics.last_modification_time"),
        width: 150,
        slots: {
          customRender: "creationTime",
        },
      },
    ];

    const funcGetShopInfo = () => {
      if (!state.searchValue && state.partnerList.length > 0) {
        return;
      }
      
      state.formState.partnerId = null
      const data = {
        searchKey: state.searchValue,
        optionListLimitCount: 10,
      };
      getPartnerOptionList(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.partnerList = result.map((x) => {
            return {
              name: x.text,
              id: x.value,
              no: x.text1,
            };
          });
        }
      });
    };

    const handleSearchProduct = debounce(funcGetShopInfo, 500);

    const handleSelectProduct = (value, item) => {
      state.formState.partnerId = item.key
    };

    const handleSubmit = () => {
      if (!state.formState.partnerId) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.new_partner')]))
        return
      }
      state.confirmLoading = true
      const data = JSON.parse(JSON.stringify(state.formState))
      createOrUpdatePartnerShopRl(data).then(() => {
        message.success(vueI18n.t('common.succeed'))
        state.isRefresh = true
        handleGetList()
      }).catch(() => {}).finally(() => {
        state.confirmLoading = false
      })
    };

    const handleGetList = () => {
      state.tableData.loading = true;
      getPartnerShopRlChangeRecordList({ shopId: state.formState.shopId })
        .then(({ result }) => {
          if (Array.isArray(result)) {
            state.tableData.list = result
          }
        })
        .catch(() => {})
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleClose = () => {
      if (state.isRefresh) {
        emit('refresh')
      }
      state.visible = false
    }

    const show = (shopId) => {
      state.visible = true;
      state.formState.shopId = shopId;
      state.searchValue = null;
      state.formState.partnerId = null;
      state.isRefresh = false;
      handleGetList();
    };

    return {
      ...toRefs(state),
      formRef,
      columns,
      handleSearchProduct,
      handleSelectProduct,
      handleSubmit,
      show,
      handleClose,
    };
  },
});
</script>

<template >
<template v-if="!hasChildren(item)">

    <a-menu-item :key="item.path">
      <template #icon>

        <component :is="$antIcons[item.meta.icon]"/>
      </template>
      <a :href="item.link" v-if="item.type=='link'" target="_blank">
        {{ $t(item.meta.text) }}
      </a>
      <span v-else>
        {{ $t(item.meta.text) }}
      </span>
    </a-menu-item>
</template>
<template v-else>
    <a-sub-menu :key="item.path">
      <!-- <template #icon v-if="!!item.meta.icon">
        <component :is="$antIcons[item.meta.icon]" />
      </template> -->
      <template #icon >
        <component :is="$antIcons[item.meta.icon || 'MenuOutlined']"/>
      </template>
      <template #title>
        {{ $t(item.meta.text) }}
      </template>
      <template v-for="child in item.children" :key="child.path">
        <SubMenu v-if="!child.hidden" :item="child"> </SubMenu>
      </template>
    </a-sub-menu>
</template>
    <!-- <a-menu-item :key="item.path" v-if="!hasChildren(item)" >
      <template #icon>

        <component :is="$antIcons[item.meta.icon]"/>
      </template>
      <a :href="item.link" v-if="item.type=='link'" target="_blank">
        {{ $t(item.meta.text) }}
      </a>
      <span v-else>
        {{ $t(item.meta.text) }}
      </span>
    </a-menu-item>
    <a-sub-menu v-else :key="item.path">
      <template #icon v-if="!!item.meta.icon">
        <component :is="$antIcons[item.meta.icon]" />
      </template>
      <template #icon >
        <component :is="$antIcons[item.meta.icon || 'MenuOutlined']"/>
      </template>
      <template #title>
        {{ $t(item.meta.text) }}
      </template>
      <template v-for="child in item.children" :key="child.path">
        <SubMenu v-if="!child.hidden" :item="child"> </SubMenu>
      </template>
    </a-sub-menu> -->
</template>
<script>
import { useI18n  } from "vue-i18n/index";
import { getCurrentInstance,onBeforeMount} from 'vue';
import { Menu  } from 'ant-design-vue';
export default {
  name: "SubMenu",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components:{
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASubMenu: Menu.SubMenu
  },
  setup(props,context) {
    const hasChildren = function (item) {
      return item.children != undefined && item.children!=null && item.children.length > 0;
    };
    // const {appContext} = getCurrentInstance();
    // console.log("globalProperties")
    // console.log(appContext.config.globalProperties)

    return {
      hasChildren
    };
  },
};
</script>
<style>

</style>
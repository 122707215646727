<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.logistics_account") }}
    </template>
    <template #contentBody="wrap">
      <a-form :model="searchForm">
        <a-row :gutter="24">
          <a-col :xl="3" :lg="4" :md="4" :sm="12" :xs="24">
            <a-form-item>
              <a-input
                v-model:value="searchForm.keyword"
                :placeholder="$t('logistics.name_or_keyword')"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :xl="3" :lg="4" :md="4" :sm="12" :xs="24">
            <a-form-item>
              <a-select
                v-model:value="searchForm.isAddressValidationsType"
                :placeholder="$t('logistics.applicable_type')"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in isAddressValidationsTypeList"
                  :key="index"
                  :value="item.value"
                  :title="$t(item.label)"
                  >{{ $t(item.label) }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xl="3" :lg="4" :md="4" :sm="12" :xs="24">
            <a-form-item>
              <a-select
                v-model:value="searchForm.thirdLogisticType"
                :placeholder="$t('logistics.third_party_channels')"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in thirdLogisticTypeList"
                  :key="index"
                  :value="item.value"
                  :title="$t($enumLangkey('thirdLogisticType', item.value))"
                  >{{
                    $t($enumLangkey("thirdLogisticType", item.value))
                  }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xl="3" :lg="4" :md="4" :sm="12" :xs="24">
            <a-form-item>
              <a-select
                v-model:value="searchForm.deliveryType"
                :placeholder="$t('logistics.applicable_delivery_method')"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in deliveryTypeList"
                  :key="index"
                  :value="item.value"
                  :title="$t($enumLangkey('deliveryWay', item.value))"
                  >{{
                    $t($enumLangkey("deliveryWay", item.value))
                  }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xl="3" :lg="4" :md="4" :sm="12" :xs="12">
            <a-button type="primary" :loading="searchBtnLoading" @click="handleSearch">{{
              $t("common.query")
            }}</a-button>
          </a-col>
          <a-col :xl="{ span: 3, offset: 6 }" :lg="4" :md="4" :sm="12" :xs="12">
            <div style="text-align: right">
              <a-button @click="handleShowModal()"
                ><template #icon><PlusOutlined /></template
                >{{ $t("common.addition") }}</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 160 }"
        :pagination="false"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #isActive="{ record }">{{
          record.isActive ? $t("logistics.enabled") : $t("logistics.disabled")
        }}</template>
        <template #deliveryTypes="{ record }">{{
          getDeliveryTypesName(record.deliveryTypes)
        }}</template>
        <template #thirdLogisticType="{ record }">{{
          getThirdLogisticType(record.thirdLogisticType)
        }}</template>
        <template #isAddressValidationsType="{ record }">{{
          record.isAddressValidationsType
            ? $t("logistics.address_verification")
            : $t("logistics.print_sheet")
        }}</template>
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #logisticsList="{ record }">
          <template v-if="record.logisticsList">
            <div v-for="(item, index) in record.logisticsList" :key="index">{{ item.name }},</div>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </template>
        <template #operation="{ record }">
          <a-button
            type="link"
            size="small"
            :loading="record.tableBtnLoading"
            v-if="record.isActive"
            @click="handleActived(record)"
            >{{ $t("common.disabled") }}</a-button
          >
          <a-button
            type="link"
            size="small"
            :loading="record.tableBtnLoading"
            v-else
            @click="handleActived(record)"
            >{{ $t("common.enabled") }}</a-button
          >
          <a-button type="link" size="small" @click="handleShowModal(record)">{{
            $t("common.edit")
          }}</a-button>
        </template>
      </a-table>
      <DetailModal
        ref="detailModalRef"
        :thirdLogisticTypeList="thirdLogisticTypeList"
        :deliveryTypeList="deliveryTypeList"
        @refresh="getListData"
      />
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="tableData.pageData" />
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";
import {
  Table,
  Input,
  Select,
  Button,
  Row,
  Col,
  Form,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content";
import CPager from "@/views/components/CPager.vue";
import DetailModal from "./components/DetailModal.vue";
import {
  deliveryWay as deliveryWayEnum,
  thirdLogisticType as thirdLogisticTypeEnum,
} from "@/enum/enum.json";
import { useI18n } from "vue-i18n/index";
import {
  getPagedList,
  updateEnableOrDisEnable,
} from "@/api/modules/logistics/account";

export default defineComponent({
  components: {
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ATable: Table,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    Content,
    CPager,
    DetailModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const detailModalRef = ref(null);

    const state = reactive({
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      searchBtnLoading: false,
      searchForm: {
        keyword: null,
        isAddressValidationsType: null,
        thirdLogisticType: null,
        deliveryType: null,
      },
      searchFormCache: {},
      isAddressValidationsTypeList: [
        { label: "logistics.address_verification", value: true },
        { label: "logistics.print_sheet", value: false },
      ],
      thirdLogisticTypeList: [],
      deliveryTypeList: [],
    });

    state.thirdLogisticTypeList = Object.keys(thirdLogisticTypeEnum).map(
      (item) => {
        return { value: thirdLogisticTypeEnum[item] };
      }
    );

    state.deliveryTypeList = Object.keys(deliveryWayEnum).map((item) => {
      return { value: deliveryWayEnum[item] };
    });

    const columns = [
      {
        dataIndex: "name",
        title: () => vueI18n.t("warehouse.name"),
        width: 150,
      },
      {
        title: () => vueI18n.t("finance.status"),
        width: 100,
        slots: {
          customRender: "isActive",
        },
      },
      {
        title: () => vueI18n.t("logistics.interaction_mode"),
        width: 150,
        slots: {
          customRender: "deliveryTypes",
        },
      },
      {
        title: () => vueI18n.t("logistics.third_party"),
        width: 100,
        slots: {
          customRender: "thirdLogisticType",
        },
      },
      {
        title: () => vueI18n.t("logistics.applicable_type"),
        width: 100,
        slots: {
          customRender: "isAddressValidationsType",
        },
      },
      {
        dataIndex: "jsonData",
        title: () => vueI18n.t("logistics.configuration_data"),
        width: 300,
      },
      {
        title: () => vueI18n.t("warehouse.creation_time"),
        width: 100,
        slots: {
          customRender: "creationTime",
        },
      },
      {
        title: () => vueI18n.t("menu.logistics_channel"),
        width: 150,
        slots: {
          customRender: "logisticsList",
        },
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 100,
        fixed: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const handlePage = () => {};

    const handleSearch = () => {
      state.searchBtnLoading = true
      Object.assign(state.searchFormCache, state.searchForm);
      initPage();
    };

    const getListData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchFormCache
      );
      getPagedList(data)
        .then((res) => {
          let { result } = res;
          if (result) {
            let { items, totalCount } = res.result;
            state.tableData.tableList = items.map((item) => {
              item.tableBtnLoading = false;
              return item;
            });
            state.tableData.pageData.totalCount = parseInt(totalCount);
          } else {
            state.tableData.pageData.totalCount = 0;
            state.tableData.tableList = [];
          }
        })
        .finally(() => {
          state.tableData.loading = false;
          state.searchBtnLoading = false;
        });
    };

    const initPage = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getListData();
    };

    const handleActived = (record) => {
      record.tableBtnLoading = true;
      const data = { id: record.id, isActive: !record.isActive };
      updateEnableOrDisEnable(data)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          getListData();
        })
        .finally(() => {
          record.tableBtnLoading = false;
        });
    };

    const handleShowModal = (record) => {
      detailModalRef.value.init(record?.id);
    };

    const getDeliveryTypesName = (arr) => {
      if (!Array.isArray(arr)) {
        return "";
      }
      let result = arr.map((item) => {
        return vueI18n.t("enum.delivery_way_" + item);
      });
      return result.join(", ");
    };

    const getThirdLogisticType = (val) => {
      if (!val) {
        return vueI18n.t("logistics.none");
      }
      return vueI18n.t("enum.third_logistic_type_" + val);
    };

    const getLogisticsList = (arr) => {
      if (!Array.isArray(arr)) {
        return "-";
      }
      let result = arr.map((item) => {
        return item.name;
      });
      return result.join(", ");
    };

    onMounted(initPage);

    return {
      ...toRefs(state),
      detailModalRef,
      columns,
      handlePage,
      handleSearch,
      getDeliveryTypesName,
      getThirdLogisticType,
      getLogisticsList,
      handleActived,
      handleShowModal,
      getListData,
    };
  },
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    :title="`${$t('menu.supplier_print_waybill_api')}-${
      modalType === 1 ? $t('common.edit') : $t('common.create')
    }`"
    :confirm-loading="confirmLoading"
    @ok="handleSubmit"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        
      >
        <a-form-item :label="$t('logistics.billing_name')" name="name">
          <a-input
            v-model:value="formState.name"
            :placeholder="$t('logistics.billing_name')"
            allowClear
          />
        </a-form-item>
        <a-form-item
          :label="$t('logistics.api_account_number')"
          name="settingId"
        >
          <a-select
            v-model:value="formState.settingId"
            :placeholder="$t('logistics.api_account_number')"
            :show-search="true"
            option-filter-prop="search-key"
            @change="handleSettingIdChange"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in apiAccountNumberList"
              :key="index"
              :value="item.value"
              :title="item.text"
              :search-key="item.text"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('logistics.receiving_supply_code')" name="receivingSupplyChannel">
          <a-auto-complete
            v-model:value="formState.receivingSupplyChannel"
            :options="receivingSupplyChannelList.map(x => ({ value: x.text }))"
            :filter-option="(input, option) => { return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0; }"
            :getPopupContainer="triggerNode => triggerNode.parentNode"
            :placeholder="$t('logistics.receiving_supply_code')"
          />
        </a-form-item>
        <a-form-item :label="$t('logistics.supply_channels')" name="relationId">
          <a-select
            v-model:value="formState.relationId"
            :placeholder="$t('logistics.supply_channels')"
            :show-search="true"
            option-filter-prop="search-key"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in logisticsChannelList"
              :key="index"
              :value="item.value"
              :title="`${item.text}(${item.text1})`"
              :search-key="item.text + item.text1"
              >{{ item.text }}({{ item.text1 }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('logistics.support_address')" name="supportAddress">
          <a-select
            v-model:value="formState.supportAddress"
            :placeholder="$t('logistics.support_address')"
            mode="multiple"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in supportAddressList"
              :key="index"
              :value="item.value"
              :title="item.text"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.enabled')">
          <a-switch v-model:checked="formState.isActive" />
        </a-form-item>
        <!-- 收件信息 -->
        <a-divider orientation="left">{{ $t('logistics.receipt_of_information') }}</a-divider>
        <a-form-item :label="$t('account.company_name')">
          <a-space>
            <a-input v-model:value="formState.companyName" :placeholder="$t('account.company_name')" allowClear />
            <a-switch v-model:checked="formState.isEnableCompanyNameOverride" />
          </a-space>
        </a-form-item>
        <a-form-item :label="$t('logistics.recipients')">
          <a-space>
            <a-input v-model:value="formState.contract" :placeholder="$t('logistics.recipients')" allowClear />
            <a-switch v-model:checked="formState.isEnableContractOverride" />
          </a-space>
        </a-form-item>
        <a-form-item :label="$t('account.phone')">
          <a-space>
            <a-input v-model:value="formState.contractTelephone" :placeholder="$t('account.phone')" allowClear />
            <a-switch v-model:checked="formState.isEnableContractTelephoneOverride" />
          </a-space>
        </a-form-item>
        <!-- 发件信息 -->
        <a-divider orientation="left">{{ $t('logistics.send_message') }}</a-divider>
        <a-form-item :label="$t('logistics.the_sender')" :name="['address', 'name']">
          <a-input v-model:value="formState.address.name" :placeholder="$t('logistics.the_sender')" allowClear />
        </a-form-item>
        <a-form-item :label="$t('account.company_name')" :name="['address', 'companyName']">
          <a-input v-model:value="formState.address.companyName" :placeholder="$t('account.company_name')" allowClear />
        </a-form-item>
        <a-form-item :label="$t('account.phone')" :name="['address', 'telephone']">
          <a-input v-model:value="formState.address.telephone" :placeholder="$t('account.phone')" allowClear />
        </a-form-item>
        <a-form-item :label="$t('common.country')" :name="['address', 'countryId']">
          <a-select
            v-model:value="formState.address.countryId"
            :placeholder="$t('common.country')"
            :loading="addressData.countryLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            allow-clear
            @change="handleCountryChange"
          >
            <a-select-option
              v-for="(item, index) in addressData.countries"
              :key="index"
              :value="item.id"
              :search-key="item.ioS2 + item.cnName + item.enName"
              :title="`${getLanguageName(item)}(${item.ioS2})`"
            >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.province')" :name="['address', 'provinceId']">
          <a-select
            v-model:value="formState.address.provinceId"
            :placeholder="$t('common.province')"
            :loading="addressData.provinceLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            allow-clear
            @change="handleProvinceChange"
          >
            <a-select-option
              v-for="(item, index) in addressData.provinces"
              :key="index"
              :value="item.id"
              :search-key="item.abbrCode + item.cnName + item.enName"
              :title="`${getLanguageName(item)}(${item.abbrCode})`"
            >{{ getLanguageName(item) }}({{ item.abbrCode }})</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.city')" :name="['address', 'cityId']">
          <a-select
            v-model:value="formState.address.cityId"
            :placeholder="$t('common.city')"
            :loading="addressData.cityLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            allow-clear
            @change="handleCityChange"
          >
            <a-select-option
              v-for="(item, index) in addressData.cities"
              :key="index"
              :search-key="item.cnName + item.enName"
              :value="item.id"
            >{{ getLanguageName(item) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="addressData.counties.length" :label="$t('common.county')" :name="['address', 'countyId']">
          <a-select
            v-model:value="formState.address.countyId"
            :placeholder="$t('common.county')"
            :loading="addressData.countyLoading"
            :showSearch="true"
            optionFilterProp="search-key"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in addressData.counties"
              :key="index"
              :search-key="item.cnName + item.enName"
              :value="item.id"
            >{{ getLanguageName(item) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('common.address_line')" :name="['address', 'address']">
          <a-input v-model:value="formState.address.address" :placeholder="$t('common.address_line')" allowClear :maxlength="35"/>
        </a-form-item>
          <a-form-item :label="$t('common.address_line')+2" >
          <a-input v-model:value="formState.address.address2" :placeholder="$t('common.address_line')" allowClear  :maxlength="35"/>
        </a-form-item>
        <a-form-item :label="$t('common.post_code')" :name="['address', 'postCode']">
          <a-input v-model:value="formState.address.postCode" :placeholder="$t('common.post_code')" allowClear />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, toRefs } from 'vue'
import {
  Input,
  Select,
  Modal,
  Switch,
  Spin,
  Form,
  Space,
  Divider,
  AutoComplete,
  message,
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import {
  getConfigurationById,
  getReceivingChannels,
  createOpenApiInterfaceConfiguration,
  updateOpenApiInterfaceConfiguration,
} from "@/api/modules/supplier/printWaybillApi";
import { getCountriesParams, getProvinces, getCitys, getCountys } from "@/api/modules/common/index";
import { getName } from "@/utils/general";
import { useStore } from 'vuex';

export default defineComponent({
  name: "printWaybillApiModal",
  components: {
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AModal: Modal,
    ASwitch: Switch,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ASpace: Space,
    ADivider: Divider,
    AAutoComplete: AutoComplete,
  },
  props: {
    apiAccountNumberList: {
      type: Array,
      default: () => ([])
    },
    logisticsChannelList: {
      type: Array,
      default: () => ([])
    },
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      modalType: 0,
      receivingSupplyChannelList: [],
      supportAddressList: [{ text: vueI18n.t('logistics.business_site'), value: 'isSupportCommercialAddr' }, { text: vueI18n.t('logistics.residence'), value: 'isSupportResidentialAddr' }],
      formState: {
        id: null,
        name: null,
        settingId: null,
        receivingSupplyChannel: null,
        relationId: null,
        isActive: true,
        isSupportCommercialAddr: false,
        isSupportResidentialAddr: false,
        supportAddress: [],
        companyName: null,
        isEnableCompanyNameOverride: false,
        contract: null,
        isEnableContractOverride: false,
        contractTelephone: null,
        isEnableContractTelephoneOverride: false,
        // 发件人
        address: {
          companyName: null,
          name: null,
          telephone: null,
          countryId: null,
          provinceId: null,
          cityId: null,
          countyId: null,
          address: null,
          address2: null,
          postCode: null,
        }
      },
      addressData: {
        countryLoading: false,
        countries: [],
        provinceLoading: false,
        provinces: [],
        cityLoading: false,
        cities: [],
        countyLoading: false,
        counties: []
      }
    })

    const validatePostCode = (rule, value) => {
      value = value?.trim();
      if (!value) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.post_code')]));
      }
      if (value.length < 5) {
        return Promise.reject(vueI18n.t('common.p0_please_enter_at_least_p1_digits', [vueI18n.t('common.post_code'), 5]));
      }
      return Promise.resolve();
    }

    const rules = {
      name: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.billing_name"),
        ]),
      },
      settingId: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.api_account_number"),
        ]),
      },
      receivingSupplyChannel: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.receiving_supply_code"),
        ]),
      },
      relationId: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.supply_channels"),
        ]),
      },
      supportAddress: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.support_address"),
        ]),
      },
      address: {
        name: {
          required: true,
          message: vueI18n.t("common.p0_is_required", [
            vueI18n.t("logistics.the_sender"),
          ]),
        },
        countryId: {
          required: true,
          message: vueI18n.t("common.p0_is_required", [
            vueI18n.t("common.country"),
          ]),
        },
        provinceId: {
          required: true,
          message: vueI18n.t("common.p0_is_required", [
            vueI18n.t("common.province"),
          ]),
        },
        cityId: {
          required: true,
          message: vueI18n.t("common.p0_is_required", [
            vueI18n.t("common.city"),
          ]),
        },
        countyId: {
          required: true,
          message: vueI18n.t("common.p0_is_required", [
            vueI18n.t("common.county"),
          ]),
        },
        address: {
          required: true,
          message: vueI18n.t("common.p0_is_required", [
            vueI18n.t("common.address_line"),
          ]),
        },
        postCode: {
          required: true,
          validator: validatePostCode,
        },
      }
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const handleSettingIdChange = () => {
      state.receivingSupplyChannelList = []
      let settingId = state.formState.settingId
      if (settingId) {
        getReceivingChannels({ settingId }).then(({ result }) => {
          if (result) {
            result = JSON.parse(result)
            let list = []
            for (const key in result) {
              list.push({ text: result[key], value: key })
            }
            state.receivingSupplyChannelList = list
          }
        })
      } else { 
        state.formState.receivingSupplyChannel = null
      }
    }

    // 获取国家列表
    const getCountriesData = () => {
      state.addressData.countryLoading = true;
      getCountriesParams(true)
        .then(({ result }) => {
          if (result) {
            state.addressData.countries = result
          }
        }).finally(() => {
          state.addressData.countryLoading = false;
        });
    }

    // 获取省份
    const getProvinceData = (countryId) => {
      return getProvinces({ countryId }).then(({ result }) => {
        if (result) {
          state.addressData.provinces = result
        }
      })
    }

    // 获取城市
    const getCityData = (provinceId) => {
      return getCitys({ provinceId }).then(({ result }) => {
        if (result) {
          state.addressData.cities = result
        }
      })
    }

    // 获取区县
    const getCountiyData = (cityId) => {
      return getCountys({ cityId }).then(({ result }) => {
        if (result) {
          state.addressData.counties = result
        }
      })
    }
    
    const handleCountryChange = async () => {
      try {
        state.formState.address.provinceId = null
        state.formState.address.cityId = null
        state.formState.address.countyId = null
        state.addressData.provinceLoading = true
        state.addressData.provinces = []
        let countryId = state.formState.address.countryId
        if (countryId) {
          await getProvinceData(countryId)
        }
      } catch (error) {
        
      } finally {
        state.addressData.provinceLoading = false
      }
    }

    // 获取城市
    const handleProvinceChange = async () => {
      try {
        state.formState.address.cityId = null
        state.formState.address.countyId = null
        state.addressData.cityLoading = true
        state.addressData.cities = []
        let provinceId = state.formState.address.provinceId
        if (provinceId) {
          await getCityData(provinceId)
        }
      } catch (error) {
        
      } finally {
        state.addressData.cityLoading = false
      }
    }

    // 获取区县
    const handleCityChange = async () => {
      try {
        state.formState.address.countyId = null
        state.addressData.countyLoading = true
        state.addressData.counties = []
        let cityId = state.formState.address.cityId
        if (cityId) {
          await getCountiyData(cityId)
        }
      } catch (error) {
        
      } finally {
        state.addressData.countyLoading = false
      }
    }

    const getDetail = async (id) => {
      state.loading = true;
      try {
        let { result } = await getConfigurationById({ id })
        if (result) {
          let formState = JSON.parse(
            JSON.stringify(state.formState)
          );
          for (const key in formState) {
            if (Object.hasOwnProperty.call(result, key)) {
              if (result[key] !== undefined) {
                state.formState[key] = result[key];
              }
            }
            if (state.supportAddressList.find(item => item.value === key)) {
              if (result[key]) {
                state.formState.supportAddress.push(key)
              }
            }
          }
          state.formState.receivingSupplyChannel = result.receivingSupplyChannelDto?.receivingSupplyChannel ?? null
          state.formState.relationId = result.chanel.id
          handleSettingIdChange()

          if (result.address) {
            await getProvinceData(result.address.countryId)
            await getCityData(result.address.provinceId)
            await getCountiyData(result.address.cityId)
          }
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    const handleSubmit = async () => {
      try {
        await formRef.value.validate()
        state.confirmLoading = true
        const data = JSON.parse(JSON.stringify(state.formState))

        let selectedReceivingSupplyChannel = state.receivingSupplyChannelList.find(item => item.text === data.receivingSupplyChannel)
        if (selectedReceivingSupplyChannel) {
          data.receivingSupplyChannel = selectedReceivingSupplyChannel.value
        }

        data.isSupportCommercialAddr = false
        data.isSupportResidentialAddr = false
        state.formState.supportAddress.map(key => {
          if (Object.hasOwnProperty.call(data, key)) {
            data[key] = true
          }
        })
        delete data.supportAddress

        if (state.modalType === 0) {
          await createOpenApiInterfaceConfiguration(data)
        } else {
          await updateOpenApiInterfaceConfiguration(data)
        }
        message.success(vueI18n.t('common.succeed'))
        state.visible = false
        emit('refresh')
      } catch (error) {
      } finally {
        state.confirmLoading = false
      }
    }

    const init = (modalType = 0, id) => {
      state.visible = true
      state.modalType = modalType

      state.receivingSupplyChannelList = []
      state.addressData.provinces = []
      state.addressData.cities = []
      state.addressData.counties = []
      state.formState.companyName = null
      state.formState.isEnableCompanyNameOverride = false
      state.formState.contract = null
      state.formState.isEnableContractOverride = false
      state.formState.contractTelephone = null
      state.formState.isEnableContractTelephoneOverride = false

      nextTick(() => {
        formRef.value.resetFields();
        getCountriesData();
        if (id) {
          getDetail(id);
        }
      });
    }

    return {
      ...toRefs(state),
      formRef,
      rules,
      labelCol: { style: { width: "120px" } },
      wrapperCol: { style: { width: "calc(100% - 120px)" } },
      getLanguageName,
      handleSettingIdChange,
      handleSubmit,
      handleCountryChange,
      handleProvinceChange,
      handleCityChange,
      init,
    }
  },
})
</script>

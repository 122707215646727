export default {
  "devops": {
    "box_no": "Carton Number",
    "scanning_device_no": "Scan Device Number",
    "generating_facilities": "Occurring Facility",
    "account_name": "Account Name",
    "running_state": "Running Status",
    "api_classification": "API Type",
    "api_code": "API Code",
    "api_code_json": "API Code JSON",
    "example": "Example",
    "copy": "Copy",
    "currency": "货币",
    "choose_currency": "选择货币",
    "configuration_item": "配置项",
    "edit_configuration": "编辑配置",
    "support_logistics_to_pay": "支持物流到付",
    "export_number": "导出条数",
    "query_parameters": "查询参数",
    "original_province_code": "原始省份CODE",
    "target_province_code": "目标省份CODE"
  }
}
import client from "../../client"

const Api = {
  // 公告管理分页列表
  getAnnounceListPaged: "/api/ProductService/Announce/GetListPaged",
  // 公告管理详情
  getAnnounce: "/api/ProductService/Announce/Get",
  // 创建公告管理
  createAnnounce: "/api/ProductService/Announce/Create",
  // 修改公告管理
  updateAnnounce: "/api/ProductService/Announce/Update",
  // 删除公告管理
  deleteAnnounce: "/api/ProductService/Announce/Delete",
}

export const getAnnounceListPaged = (data) => {
  return client.request({
    url: Api.getAnnounceListPaged,
    data,
    method: 'POST'
  })
}

export const getAnnounce = (params) => {
  return client.request({
    url: Api.getAnnounce,
    params,
    method: 'GET'
  })
}

export const createAnnounce = (data) => {
  return client.request({
    url: Api.createAnnounce,
    data,
    method: 'POST'
  })
}

export const updateAnnounce = (data) => {
  return client.request({
    url: Api.updateAnnounce,
    data,
    method: 'POST'
  })
}

export const deleteAnnounce = (data) => {
  return client.request({
    url: Api.deleteAnnounce,
    data,
    method: 'POST'
  })
}
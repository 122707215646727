import client from "../../client";

const Api = {
  getList: "/api/WarehouseService/ProductPackingUpdateRecord/GetListPaged",
  allocation: "/api/WarehouseService/ProductPackingUpdateRecord/Allot",
  cancelRecord: "/api/WarehouseService/ProductPackingUpdateRecord/Cancel",
  deleteRecord: "/api/WarehouseService/ProductPackingUpdateRecord/Delete",
  getDistributeWarehouseList:
    "/api/WarehouseService/InventoryStatistics/GetInvWarehousesByProduct",
};

export const getList = ({
  shopId,
  productId,
  warehouseId,
  status,
  beginCreationTime,
  endCreationTime,
  skipCount,
  maxResultCount,
}) => {
  return client.request({
    url: Api.getList,
    data: {
      shopId,
      productId,
      warehouseId,
      status,
      beginCreationTime,
      endCreationTime,
      skipCount,
      maxResultCount,
    },
    method: "POST",
  });
};

export const allocation = ({ id, warehouseId }) => {
  return client.request({
    url: Api.allocation,
    data: {
      id,
      warehouseId,
    },
    method: "POST",
  });
};

export const cancelRecord = ( id ) => {
  return client.request({
    url: Api.cancelRecord,
    data: { id },
    method: "POST",
  });
};

export const deleteRecord = ({ ids }) => {
  return client.request({
    url: Api.deleteRecord,
    data: { ids },
    method: "POST",
  });
};

export const getAllocationWarehouseList = (data) => {
  return client.request({
    url: Api.getAllocationWarehouseList,
    data,
    method: "POST",
  });
};

export const getDistributeWarehouseList = ({ shopId, productId }) => {
  return client.request({
    url: Api.getDistributeWarehouseList,
    data: { shopId, productId },
    method: "POST",
  });
};

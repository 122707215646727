import client from "../../client"

const Api = {
  // 列表
  getConfigurationPagedList: "/api/SupplierService/OpenApiAddressSupplierConfigure/GetConfigurationPagedList",
  // 新增地址验证API
  createOpenApiInterfaceConfiguration: "/api/SupplierService/OpenApiAddressSupplierConfigure/CreateOpenApiInterfaceConfiguration",
  // 修改地址验证API
  updateOpenApiInterfaceConfiguration: "/api/SupplierService/OpenApiAddressSupplierConfigure/UpdateOpenApiInterfaceConfiguration",
  // 启用/禁用地址验证API
  updateOpenApiInterfaceConfigurationActive: "/api/SupplierService/OpenApiAddressSupplierConfigure/UpdateOpenApiInterfaceConfigurationActive",
  // 详情
  getConfigurationById: "/api/SupplierService/OpenApiAddressSupplierConfigure/GetConfigurationById",
  // 查询所有供应商方案
  getAllCanChooseSupplier: "/api/SupplierService/OpenApiAddressSupplierConfigure/GetAllCanChooseSupplier",
  // 查询可选择的API账户
  getAllCanChooseSetting: "/api/SupplierService/OpenApiManagement/GetAddressValidateChooseSetting",
  // 接口账号列表
  getCalFeeChooseSetting: "/api/SupplierService/OpenApiManagement/GetCalFeeChooseSetting",
}

export const getConfigurationPagedList = (data) => {
  return client.request({
    url: Api.getConfigurationPagedList,
    data,
    method: 'POST'
  });
}

export const createOpenApiInterfaceConfiguration = (data) => {
  return client.request({
    url: Api.createOpenApiInterfaceConfiguration,
    data,
    method: 'POST'
  });
}

export const updateOpenApiInterfaceConfiguration = (data) => {
  return client.request({
    url: Api.updateOpenApiInterfaceConfiguration,
    data,
    method: 'POST'
  });
}

export const updateOpenApiInterfaceConfigurationActive = (data) => {
  return client.request({
    url: Api.updateOpenApiInterfaceConfigurationActive,
    data,
    method: 'POST'
  });
}

export const getConfigurationById = (data) => {
  return client.request({
    url: Api.getConfigurationById,
    data,
    method: 'POST'
  });
}

export const getAllCanChooseSupplier = () => {
  return client.request({
    url: Api.getAllCanChooseSupplier,
    method: 'GET'
  });
}

export const getAllCanChooseSetting = () => {
  return client.request({
    url: Api.getAllCanChooseSetting,
    method: 'GET'
  });
}

export const getCalFeeChooseSetting = () => {
  return client.request({
    url: Api.getCalFeeChooseSetting,
    method: 'GET'
  });
}
<template>
    <Content>
        <template #contentTitle>
            {{ $t("menu.warehouse_device_manager") }}
        </template>
        <template v-slot:contentBody="wrap">

        <a-row type="flex"
               justify="space-between"
               align="bottom"
               :gutter="[16, 16]">
          <a-col flex="1">
            <a-row :gutter="[16, 16]">
                <a-col>
                    <a-input placeholder="仓库名称/仓库代码"
                         allow-clear
                         v-model:value="tableData.searchData.searchKey"
                         style="width: 250px"
                         @keyup.enter="handleSearch"
                         ></a-input>
                </a-col>
                <a-col>
                    <a-select
                        placeholder="国家"
                        v-model:value="tableData.searchData.countryId"
                        allow-clear
                        @change="handleSearch"
                        :showSearch="true"
                        style="width: 250px"
                        optionFilterProp="search-key"
                    >
                        <a-select-option
                        v-for="item in addressData.countries"
                        :search-key="item.ioS2 + item.cnName + item.enName"
                        :key="item.id"
                        :title="`${getLanguageName(item)}(${item.ioS2})`"
                        >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option
                        >
                    </a-select>
                </a-col>
                <a-col>
                    <a-select
                        placeholder="仓库类型"
                        v-model:value="tableData.searchData.warehouseType"
                        allow-clear
                        @change="handleSearch"
                        :showSearch="true"
                        style="width: 250px"
                        optionFilterProp="search-key"
                    >
                        <a-select-option
                        v-for="item in warehouseTypeEnum"
                        :search-key="item"
                        :key="item"
                        :title="$t($enumLangkey('warehouseType', item))"
                        >{{ $t($enumLangkey("warehouseType", item)) }}</a-select-option
                        >
                    </a-select>
                </a-col>
                <a-col>
                    <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.query') }}</a-button>
                </a-col>
            </a-row>
          </a-col>
          <a-col>
             <a-button @click="handleOpenCreateModal">创建设施</a-button>
          </a-col>
        </a-row>
        <a-table
            class="mt-3"
            :columns="columns"
            size="small"
            :data-source="tableData.tableList"
            :pagination="false"
            :scroll="{ x: 500, y: wrap.contentHeight - 100 }"
            :rowKey="(record, index) => index"
            :loading="tableData.loading"
        >
            <template v-slot:warehouseNo="{ record }">
                <div>{{ record.warehouseNo }}</div>
                <div>
                    <small>{{ record.warehouseName }}</small>
                </div>
            </template>
            <template
                v-slot:warehouseType="{ record }"
            >{{ $t($enumLangkey("warehouseType", record.warehouseType)) }}</template>
            <template
                v-slot:warehouseCountry="{ record }"
            >{{ getLanguageName({ cnName: record.countryCnName, enName: record.countryEnName }) }}</template>
            <template
                v-slot:warehouseCity="{ record }"
            >{{ getLanguageName({ cnName: record.cityCnName, enName: record.cityEnName }) || "-" }}</template>
            <template #address="{ record }">
                {{ getDetailAddress(record.address, record.address2) }}
            </template>
            <template v-slot:isActive="{ record }">
                <a-switch
                    v-model:checked="record.isActive"
                    size="small"
                    :loading="record.statusLoading"
                    @change="handleIsActiveChanged(record)"
                ></a-switch>
            </template>
            <template v-slot:contact="{ record }">
                <div v-if="record.linkMan">
                    <small>{{ record.linkMan || '-' }}</small>
                </div>
                <div v-if="record.linkManPhone">
                    <small>{{ record.linkManPhone || '-' }}</small>
                </div>
            </template>
            <template v-slot:rtContact="{ record }">
                <div>
                    <small>{{ record.rtLinkMan || '-' }}</small>
                </div>
                <div>
                    <small>{{ record.rtLinkManPhone || '-' }}</small>
                </div>
            </template>
            <template v-slot:operation="{ record }">
                <a-dropdown-button @click="handleOpenEditModal(record)">
                    {{ $t('common.edit') }}
                    <template #overlay>
                        <a-menu @click="handleMenuClick">
                            <a-menu-item key="1" :record="record">{{ $t('logistics.config') }}</a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown-button>
            </template>
        </a-table>

            <a-modal
                v-model:visible="modalData.visible"
                :confirm-loading="modalData.loading"
                :title="modalData.formData.id > 0 ? '编辑' : '创建'"
                :maskClosable="false"
                @ok="handleSave"
            >
                <a-form
                    :model="modalData.formData"
                    :label-col="{ span: 6 }"
                    ref="refEditModalForm"
                    :rules="warehouseRules"
                >
                    <a-form-item :label="$t('warehouse.warehouse_name')" name="warehouseName">
                        <a-input
                            v-model:value="modalData.formData.warehouseName"
                            :placeholder="$t('warehouse.warehouse_name')"
                        ></a-input>
                    </a-form-item>
                    <a-form-item :label="$t('common.country')" name="countryId">
                        <a-select
                            :placeholder="$t('common.country')"
                            v-model:value="modalData.formData.countryId"
                            allow-clear
                            @change="countryChange"
                            :loading="addressData.countryLoading"
                            :showSearch="true"
                            optionFilterProp="search-key"
                            :disabled="modalData.formData.id > 0"
                        >
                            <a-select-option
                                v-for="item in addressData.countries"
                                :search-key="item.ioS2 + item.cnName + item.enName"
                                :key="item.id"
                                :title="`${getLanguageName(item)}(${item.ioS2})`"
                            >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('common.province')" name="provinceId">
                        <a-select
                            :placeholder="$t('common.province')"
                            v-model:value="modalData.formData.provinceId"
                            allow-clear
                            @change="provinceChange"
                            :loading="addressData.provinceLoading"
                            :showSearch="true"
                            optionFilterProp="search-key"
                            :disabled="modalData.formData.id > 0"
                        >
                            <a-select-option
                                v-for="item in addressData.provinces"
                                :search-key="item.cnName + item.enName"
                                :key="item.id"
                                :title="getLanguageName(item, true)"
                            >{{ getLanguageName(item, true) }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('common.city')" name="cityId">
                        <a-select
                            :placeholder="$t('common.city')"
                            v-model:value="modalData.formData.cityId"
                            allow-clear
                            @change="cityChange"
                            :loading="addressData.cityLoading"
                            :showSearch="true"
                            optionFilterProp="search-key"
                        >
                            <a-select-option
                                v-for="item in addressData.cities"
                                :search-key="item.cnName + item.enName"
                                :key="item.id"
                                :title="getLanguageName(item)"
                            >{{ getLanguageName(item) }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('common.county')" name="countyId" v-if="addressData.counties.length">
                        <a-select
                            :placeholder="$t('common.county')"
                            v-model:value="modalData.formData.countyId"
                            allow-clear
                            :loading="addressData.cityLoading"
                            :showSearch="true"
                            optionFilterProp="search-key"
                        >
                            <a-select-option
                                v-for="item in addressData.counties"
                                :search-key="item.cnName + item.enName"
                                :key="item.id"
                                :title="getLanguageName(item)"
                            >{{ getLanguageName(item) }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('common.address_line')" name="address">
                        <a-input
                            v-model:value="modalData.formData.address"
                            :maxlength="35"
                            :placeholder="$t('common.address_line')"
                        ></a-input>
                    </a-form-item>
                     <a-form-item :label="$t('common.address_line')+2" >
                        <a-input
                            v-model:value="modalData.formData.address2"
                            :maxlength="35"
                            :placeholder="$t('common.address_line')+2"
                            allow-clear
                        ></a-input>
                    </a-form-item>
                    <a-form-item :label="$t('common.post_code')" name="postCode">
                        <a-input
                            v-model:value="modalData.formData.postCode"
                            :placeholder="$t('common.post_code')"
                        ></a-input>
                    </a-form-item>
                    <a-form-item :label="$t('warehouse.warehouse_type')" name="warehouseType">
                        <a-radio-group v-model:value="modalData.formData.warehouseType">
                            <a-radio
                                v-for=" item in warehouseTypeEnum"
                                :key="item"
                                :value="item"
                                :disabled="modalData.formData.id > 0"
                            >{{ $t($enumLangkey("warehouseType", item)) }}</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item :label="'仓库代码'" name="serialNo">
                        <!-- <a-input
                            :addon-before="getPrefix()"
                            :addon-after="getSuffix()"
                            v-model:value="modalData.formData.serialNo"
                        ></a-input>-->
                        <a-input-group>
                            <span class="ant-input-group-addon">{{ getPrefix() }}</span>
                            <a-input-number
                                class="input-group-mid-number"
                                v-model:value="modalData.formData.serialNo"
                                :disabled="modalData.formData.id > 0"
                            ></a-input-number>
                            <span class="ant-input-group-addon">{{ getSuffix() }}</span>
                        </a-input-group>
                        <a-button
                            type="link"
                            size="small"
                            v-if="modalData.recommendSerialNo && !(modalData.formData.id > 0)"
                            @click="handleRecommendSerialNoClick"
                        >推荐编号:{{ modalData.recommendSerialNo }}</a-button>
                    </a-form-item>
                    <a-form-item :label="$t('account.timezone')" name="timezone">
                        <a-select
                            :allowClear="true"
                            v-model:value="modalData.formData.timezone"
                            show-search
                            optionFilterProp="search-key"
                            :placeholder="$t('account.choose_timezone')"
                        >
                            <a-select-option
                            :value="item.zoneName"
                            v-for="item in timezoneList"
                            :search-key="
                                item.zoneName +
                                item.countryCnName +
                                item.countryEnName +
                                item.countryCode +
                                item.gmtOffset
                            "
                            :title="`${item.zoneName}(${getLanguageName({
                                cnName: item.countryCnName,
                                enName: item.countryEnName,
                            })}) ${item.gmtOffset}`"
                            :key="item.id"
                            >
                            {{ item.zoneName }}({{
                                getLanguageName({
                                    cnName: item.countryCnName,
                                    enName: item.countryEnName,
                                })
                            }} {{ item.gmtOffset }})
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('logistics.contacts')" name="linkMan">
                        <a-input
                            v-model:value="modalData.formData.linkMan"
                            :placeholder="$t('logistics.contacts')"
                            allow-clear
                        ></a-input>
                    </a-form-item>
                    <a-form-item :label="$t('account.phone')" name="linkManPhone">
                        <a-input
                            v-model:value="modalData.formData.linkManPhone"
                            :placeholder="$t('account.phone')"
                            allow-clear
                        ></a-input>
                    </a-form-item>
                    <a-form-item :label="$t('logistics.return_contact_name')" name="rtLinkMan">
                        <a-input
                            v-model:value="modalData.formData.rtLinkMan"
                            :placeholder="$t('logistics.return_contact_name')"
                            allow-clear
                        ></a-input>
                    </a-form-item>
                    <a-form-item :label="$t('logistics.return_contact_phone_number')" name="rtLinkManPhone">
                        <a-input
                            v-model:value="modalData.formData.rtLinkManPhone"
                            :placeholder="$t('logistics.return_contact_phone_number')"
                            allow-clear
                        ></a-input>
                    </a-form-item>
                </a-form>
            </a-modal>
        </template>
        <!-- 分页 -->
        <template #contentFooter>
            <CPager class="text-center" @handlePage="handlePage" :page-data="tableData.pageData"></CPager>
        </template>
    </Content>
</template>
<script>
import { Table, Button, Modal, Input, Select, Card, Row, Col, Form, Radio, Switch, InputNumber, Dropdown, Menu } from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue'
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    onActivated,
    watch,
    toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n/index';
import {
    getCountriesParams, getProvinces, getCitys, getCountys, getWarehouseListPaged
} from "../../api/modules/common/index";
import { getName } from "../../utils/general";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { warehouseType as warehouseTypeEnum } from "../../enum/enum.json"
import { updateIsActive as updateWarehouseIsActive, getNextSerialNo, create as createWarehouse, update as updateWarehouse } from "../../api/modules/warehouse/index"
import { getTimeZoneList } from "@/api/modules/account";

export default defineComponent({
    name: "warehouse_device_manager",
    components: {
        ATable: Table,
        AButton: Button,
        AModal: Modal,
        CPager,
        AInput: Input,
        AInputGroup: Input.Group,
        ASelect: Select,
        ACard: Card,
        ARow: Row,
        ACol: Col,
        Content,
        ASelectOption: Select.Option,
        AForm: Form,
        AFormItem: Form.Item,
        ARadio: Radio,
        ARadioOption: Radio.Option,
        ARadioButton: Radio.Button,
        ARadioGroup: Radio.Group,
        ASwitch: Switch,
        AInputNumber: InputNumber,
        ADropdownButton: Dropdown.Button,
        AMenu: Menu,
        AMenuItem: Menu.Item,
    },
    setup() {
        const { getters } = useStore();
        const router = useRouter();
        const vueI18n = useI18n({ useScope: 'global' });
        const refEditModalForm = ref();
        
        const columns = [
            {
                dataIndex: 'warehouseNo',
                width: 150,
                fixed: 'left',
                title: () => "仓库代码",
                slots: {
                    customRender: "warehouseNo"
                }
            },
            {
                dataIndex: 'warehouseType',
                width: 150,
                title: () => '仓库类型',
                slots: {
                    customRender: "warehouseType"
                }
            },
            {
                dataIndex: 'country',
                width: 150,
                title: () => '所在国家',
                slots: {
                    customRender: "warehouseCountry"
                }
            },
            {
                dataIndex: 'city',
                width: 150,
                title: () => '所在城市',
                slots: {
                    customRender: "warehouseCity"
                }
            },
            {
                width: 200,
                title: () => '仓库地址',
                slots: {
                    customRender: "address"
                }
            },
            {
                dataIndex: 'isActive',
                width: 100,
                title: () => '状态',
                slots: {
                    customRender: "isActive"
                }
            },
            {
                width: 150,
                title: () => '联系人',
                slots: {
                    customRender: "contact",
                }
            },
            {
                width: 150,
                title: () => vueI18n.t('logistics.return_contact_name'),
                slots: {
                    customRender: "rtContact",
                }
            },
            {
                fixed: "right",
                width: 100,
                title: () => '',
                slots: {
                    customRender: "operation",
                }
            }
        ];

        const tableData = reactive({
            tableList: [],
            loading: false,
            searchData:{
                warehouseType:null,
                countryId:null,
                searchKey:null
            },
            pageData: {
                currentIndex: 1,
                skipCount: 0,
                totalCount: 0,
                maxResultCount: 10
            }
        });

        const getDefaultWarehouseObj = () => {
            return {
                id: 0,
                warehouseName: null,
                countryId: null,
                provinceId: null,
                cityId: null,
                countyId: null,
                address: null,
                address2: null,
                postCode: null,
                warehouseType: warehouseTypeEnum.storage,
                serialNo: null,
                timezone: null,
                linkMan: null,
                linkManPhone: null,
                rtLinkMan: null,
                rtLinkManPhone: null,
            }
        }
        const modalData = reactive({
            visible: false,
            loading: false,
            recommendSerialNo: null,
            formData: getDefaultWarehouseObj(),
        });

        const setWareghouseFromRecord = (record = {}) => {
            let {
                id,
                warehouseName,
                countryId,
                provinceId,
                cityId,
                countyId,
                address,
                address2,
                postCode,
                warehouseType,
                serialNo,
                timezone,
                linkMan,
                linkManPhone,
                rtLinkMan,
                rtLinkManPhone,
            } = record;
            Object.assign(modalData.formData, {
                id,
                warehouseName,
                countryId,
                provinceId,
                cityId,
                countyId,
                address,
                address2,
                postCode,
                warehouseType,
                serialNo,
                timezone,
                linkMan,
                linkManPhone,
                rtLinkMan,
                rtLinkManPhone,
            })
        }


        const addressData = reactive({
            countryLoading: false,
            countries: [],
            provinceLoading: false,
            provinces: [],
            cityLoading: false,
            cities: [],
            countyLoading: false,
            counties: [],
        });

        const state = reactive({
            timezoneList: [],
        })


        const addressCache = {
            provinces: {},
            cities: {},
            counties: {}
        };

        const getLanguageName = (item, showCode = false) => {
            if (showCode) {
                return `${getName(item, getters.language)}(${item.abbrCode})`;
            }
            return getName(item, getters.language);
        }

        const getWarehouseList = () => {
            tableData.loading = true;
            var searchData = {
                skipCount: tableData.pageData.skipCount,
                maxResultCount: tableData.pageData.maxResultCount,
                searchKey: tableData.searchData.searchKey,
                countryId: tableData.searchData.countryId,
                warehouseType:tableData.searchData.warehouseType
            };
            getWarehouseListPaged(searchData)
                .then((res) => {
                    let { items = [], totalCount = 0 } = res.result;
                    tableData.tableList = items;
                    tableData.pageData.totalCount = parseInt(totalCount);
                    tableData.loading = false;
                })
                .catch(() => {
                    tableData.loading = false;
                });
        }

        const handleSearch = () => {
            tableData.pageData.skipCount = 0;
            tableData.pageData.currentIndex = 1;
            getWarehouseList();
        };


        const handlePage = (pageData) => {
            tableData.pageData.skipCount = pageData.skipCount;
            tableData.pageData.maxResultCount = pageData.maxResultCount;
            getWarehouseList();
        };

        const beforeModalOpen = () => {
            if (addressData.countries.length == 0) {
                addressData.countryLoading = true;
                getCountriesParams()
                    .then((res) => {
                        addressData.countries = res.result;
                    }).finally(() => {
                        addressData.countryLoading = false;
                        countryChange();
                    });

            }
            if (refEditModalForm.value && refEditModalForm.value.clearValidate) {
                refEditModalForm.value.clearValidate();
            }
        }

        const getCountriesData = ()=>{
            if (addressData.countries.length == 0) {
                addressData.countryLoading = true;
                getCountriesParams()
                    .then((res) => {
                        addressData.countries = res.result;
                    }).finally(() => {
                        addressData.countryLoading = false;
                        countryChange();
                    });

            }
        };

        const handleOpenCreateModal = () => {
            // 清空缓存
            modalData.formData.countryId = null;
            modalData.formData.provinceId = null;
            addressData.provinces = [];
            modalData.formData.cityId = null;
            addressData.cities = [];
            modalData.formData.countyId = null;
            addressData.counties = [];

            // modalData.formData = {};
            if(modalData.formData.id>0){
                modalData.formData = getDefaultWarehouseObj();
            }
            beforeModalOpen();
            modalData.visible = true;
        };

        const handleOpenEditModal = (record) => {

            setWareghouseFromRecord(record);
            beforeModalOpen();
            countryChange();
            modalData.visible = true;
        }

        const handleIsActiveChanged = (record) => {
            record.statusLoading = true;
            updateWarehouseIsActive({ id: record.id, isActive: record.isActive })
                .then(() => {
                    record.statusLoading = false;
                }).catch(() => {
                    record.isActive = !record.isActive;
                    record.statusLoading = false;
                });
        }

        const getRecommendSerialNo = () => {
            if (
                !(modalData.formData.id > 0)
                && modalData.formData.countryId
                && modalData.formData.provinceId
                && modalData.formData.warehouseType) {
                getNextSerialNo({
                    countryId: modalData.formData.countryId,
                    provinceId: modalData.formData.provinceId,
                    warehouseType: modalData.formData.warehouseType,
                })
                    .then((res) => {
                        modalData.recommendSerialNo = res.result;
                    })
                    .catch(() => {
                        modalData.recommendSerialNo = null;
                    });
            } else {
                modalData.recommendSerialNo = null;
            }
        }

        const handleRecommendSerialNoClick = () => {
            modalData.formData.serialNo = modalData.recommendSerialNo;
            if (refEditModalForm.value && refEditModalForm.value.validate) {
                refEditModalForm.value.validate("serialNo");
            }
        };

        const handleSave = () => {
            refEditModalForm.value.validate()
                .then(() => {
                    modalData.loading = true;
                    return modalData.formData.id > 0
                        ? updateWarehouse(modalData.formData)
                        : createWarehouse(modalData.formData);
                })
                .then(() => {
                    modalData.loading = false;
                    modalData.visible = false;
                    handleSearch();
                })
                .catch(() => {
                    modalData.loading = false;
                });
        }

        const countryChange = () => {
            addressData.provinceLoading = true;
            getProvinceList().then((res) => {
                addressData.provinces = res;
            }).catch((err) => {
                addressData.provinces = [];
                throw err;
            }).finally(() => {
                addressData.provinceLoading = false;

                if (addressData.provinces.findIndex(x => x.id == modalData.formData.provinceId) >= 0) {
                    //keep it
                } else {
                    modalData.formData.provinceId = null;
                }
                provinceChange();
            });
        };

        const provinceChange = () => {
            addressData.cityLoading = true;
            getCityList().then((res) => {
                addressData.cities = res;
            }).catch((err) => {
                addressData.cities = [];
                throw err;
            }).finally(() => {
                addressData.cityLoading = false;
                if (addressData.cities.findIndex(x => x.id == modalData.formData.cityId) >= 0) {
                    //keep it
                } else {
                    modalData.formData.cityId = null;
                }
                cityChange();
            });
            getRecommendSerialNo();
        };

        const cityChange = () => {
            addressData.countyLoading = true;
            getCountiesList().then((res) => {
                addressData.counties = res;
            }).catch((err) => {
                addressData.counties = [];
                throw err;
            }).finally(() => {
                addressData.countyLoading = false;
                if (addressData.counties.findIndex(x => x.id == modalData.formData.countyId) >= 0) {
                    //keep it
                } else {
                    modalData.formData.countyId = null;
                }
            });
        };

        const getProvinceList = async () => {
            if (modalData.formData.countryId) {
                var cacheKey = modalData.formData.countryId + "";
                if (addressCache.provinces[cacheKey]) {
                    return addressCache.provinces[cacheKey];
                } else {
                    let { result } = await getProvinces({ countryId: modalData.formData.countryId });
                    addressCache.provinces[cacheKey] = result;
                    return result;
                }
            } else {
                return [];
            }

        }

        const getCityList = async () => {
            if (modalData.formData.provinceId) {
                var cacheKey = modalData.formData.provinceId + "";
                if (addressCache.cities[cacheKey]) {
                    return addressCache.cities[cacheKey];
                } else {
                    let { result } = await getCitys({ provinceId: modalData.formData.provinceId });
                    addressCache.cities[cacheKey] = result;
                    return result;
                }
            } else {
                return [];
            }
        }

        const getCountiesList = async () => {
            if (modalData.formData.cityId) {
                var cacheKey = modalData.formData.cityId + "";
                if (addressCache.counties[cacheKey]) {
                    return addressCache.counties[cacheKey];
                } else {
                    let { result } = await getCountys({ cityId: modalData.formData.cityId });
                    addressCache.counties[cacheKey] = result;
                    return result;
                }
            } else {
                return [];
            }
        }

        const getPrefix = () => {
            let prefix = "";
            if (modalData.formData.countryId && addressData.countries) {
                let countryItem = addressData.countries.find(x => x.id == modalData.formData.countryId);
                if (countryItem) {
                    prefix += countryItem.ioS2 + "-";
                }

                if (modalData.formData.provinceId && addressData.provinces) {
                    let provinceItem = addressData.provinces.find(x => x.id == modalData.formData.provinceId);
                    if (provinceItem) {
                        prefix += provinceItem.abbrCode + "-";
                    }
                }
            }
            return prefix;
        }

        const getSuffix = () => {
            switch (modalData.formData.warehouseType) {
                case warehouseTypeEnum.storage:
                    return "-S";
                case warehouseTypeEnum.consignment:
                    return "-D";
                case warehouseTypeEnum.logisticsCenter:
                    return "-L";
                case warehouseTypeEnum.transitWarehouse:
                    return "-T";
                default:
                    return "";
            }
        }

        watch(() => modalData.formData.warehouseType, (newVal, oldVal) => {
            getRecommendSerialNo();
        })

        const validatePostCode = (rule, value) => {
            value = value?.trim();
            if (!value) {
                return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.post_code')]));
            }
            if (value.length < 5) {
                return Promise.reject(vueI18n.t('common.p0_please_enter_at_least_p1_digits', [vueI18n.t('common.post_code'), 5]));
            }
            return Promise.resolve();
        }

        const warehouseRules = {
            warehouseName: [
                {
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('warehouse.warehouse_name')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
            countryId: [
                {
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('common.country')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
            provinceId: [
                {
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('common.province')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
            cityId: [
                {
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('common.city')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
            address: [
                {
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('common.address_line')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
            postCode: [
                {
                    required: true,
                    validator: validatePostCode,
                    trigger: ['blur', 'change']
                }
            ],
            warehouseType: [
                {
                    type: 'number',
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('warehouse.warehouse_type')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
            serialNo: [
                {
                    type: "number",
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('logistics.warehouse_code')])
                    },
                    trigger: ['blur', 'change']
                },
                {
                    type: "number",
                    min: 1,
                    max: 99,
                    message: () => {
                        return vueI18n.t("warehouse.should_be_between", [vueI18n.t("logistics.warehouse_code"), 1, 99])
                    },
                    trigger: ['blur', 'change']
                },
            ],
            timezone: [
                {
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('account.timezone')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
        };

        const getTimeZoneData = () => {
            getTimeZoneList()
                .then((res) => {
                    let { result } = res;
                    if (result) {
                        state.timezoneList = result;
                    }
                })
        }

        const getDetailAddress = (address1, address2) => {
            return [address1, address2].filter(Boolean).join(', ')
        }

        const handleMenuClick = (e) => {
            let { key, item } = e
            let { record } = item
            switch (key) {
                case '1':
                    router.push({ name: 'warehouse_fee_setting', params: { id: record.id } })
                    break;
                default:
                    break;
            }
        }

        onMounted(() => {
            getCountriesData();
        });

        onActivated(() => {
            handleSearch()
            getTimeZoneData()
        })

        return {
            refEditModalForm,
            columns,
            tableData,
            modalData,
            addressData,
            ...toRefs(state),

            warehouseTypeEnum,
            warehouseRules,

            getCountriesData,
            handleSearch,
            handleOpenCreateModal,
            handleOpenEditModal,
            handleSave,
            handlePage,
            handleIsActiveChanged,
            handleRecommendSerialNoClick,

            countryChange,
            provinceChange,
            cityChange,
            getLanguageName,
            getPrefix,
            getSuffix,
            getDetailAddress,
            handleMenuClick,
        }
    }
})
</script>
<style lang="less" scoped>
:deep(.input-group-mid .ant-input-group-addon) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
:deep(.input-group-mid-number) {
    border-radius: 0;
    width: 100%;
}
:deep(.input-group-end-number) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
}
:deep(.input-group-start-number) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}
</style>
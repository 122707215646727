import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  //订单
  getOrderList: "/api/WarehouseService/AgentOrderConsole/GetListPaged",
  createWavesTask: "/api/WarehouseService/AgentOrderConsole/CreateWaveTask",
  getOrderDetails: "/api/WarehouseService/AgentOrderConsole/GetDetailById",
  createShipment: "/api/WarehouseService/AgentOrderConsole/CreateShipment",
  cancelOrder: "/api/WarehouseService/AgentOrderConsole/CancelOrder",
  refundOrder: "/api/WarehouseService/AgentOrderConsole/Refund",
  loseMoneyOrder: "/api/WarehouseService/AgentOrderConsole/LoseMoney",
  exportAgentOrder: "/api/WarehouseService/AgentOrderConsole/ExportAgentOrder",
  exportAgentOrderSku: "/api/WarehouseService/AgentOrderConsole/ExportAgentOrderSku",
  getTrackList:"/api/WarehouseService/AgentOrderConsole/GetTrack",
  confirmOrder:"/api/WarehouseService/AgentOrderConsole/Confirmed",
};

export const confirmOrder = (data) => {
  return client.request({
    url: Api.confirmOrder,
    data: data,
    method: "post",
  });
};

// 订单
export const getOrderList = (data) => {
  return client.request({
    url: Api.getOrderList,
    data: data,
    method: "post",
  });
};

export const createWavesTask = (data) => {
  return client.request({
    url: Api.createWavesTask,
    data: data,
    method: "post",
  });
};

export const getOrderDetails = ({ id }) => {
  return client.request({
    url: Api.getOrderDetails,
    data: {
      id,
    },
    method: "post",
  });
};

export const createShipment = (data) => {
  return client.request({
    url: Api.createShipment,
    data,
    method: "POST",
  });
};

export const cancelOrder = (data) => {
  return client.request({
    url: Api.cancelOrder,
    data,
    method: "POST",
  });
};

export const refundOrder = (data) => {
  return client.request({
    url: Api.refundOrder,
    data,
    method: "POST",
  });
};

export const loseMoneyOrder = (data) => {
  return client.request({
    url: Api.loseMoneyOrder,
    data,
    method: "POST",
  });
};

export const exportAgentOrder = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportAgentOrder);
  return url;
}

export const exportAgentOrderSku = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportAgentOrderSku);
  return url;
}

export const getTrackList = ({ orderId }) => {
  return client.request({
    url: Api.getTrackList,
    data: {
      orderId,
    },
    method: "post",
  });
};
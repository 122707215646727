<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.dev_exchange_rate_history') }}
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input v-model:value="searchState.currencyCode" :disabled="true" style="width: 250px;"></a-input>
        </a-col>
        <a-col>
          <a-range-picker style="width: 300px" allowClear @change="handleDateChange"/>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 90 }"
        :rowKey="(record) => record.id"
        :loading="tableData.loading"
      >
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #option="{ record }">
          <a-button @click="handleNavigate(record.currencyCode)">{{ $t('menu.dev_exchange_rate_history') }}</a-button>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Button, Col, DatePicker, Input, Row, Table } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import { useI18n } from 'vue-i18n/index';
import { useRoute } from "vue-router";
import { currentTimeToUtc } from '@/utils/general';
import { getExchangeRateRecordList } from '@/api/modules/devops/exchangeRate.js';

export default defineComponent({
  name: "dev_exchange_rate_history",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    
    const state = reactive({
      searchState: {
        currencyCode: null,
        beginTime: null,
        endTime: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: "currencyName",
        title: () => vueI18n.t('warehouse.name'),
        width: 150,
      },
      {
        dataIndex: "currencyCode",
        title: () => vueI18n.t('logistics.code'),
        width: 150,
      },
      {
        dataIndex: "buyExchangeRateRecord",
        title: () => vueI18n.t('finance.purchase_exchange_rate'),
        width: 150,
      },
      {
        dataIndex: "saleExchangeRateRecord",
        title: () => vueI18n.t('finance.selling_exchange_rate'),
        width: 150,
      },
      {
        dataIndex: "referenceRate",
        title: () => vueI18n.t('finance.middle_price'),
        width: 150,
      },
      {
        title: () => vueI18n.t('warehouse.create_date'),
        width: 150,
        slots: {
          customRender: "creationTime"
        }
      },
    ]

    const handleDateChange = (e) => {
      let beginTime = null;
      let endTime = null;
      if (Array.isArray(e) && e?.length > 1) {
        beginTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.beginTime = beginTime;
      state.searchState.endTime = endTime;
    }

    const getPageData = () => {
      const data = Object.assign(
        {},
        state.searchStateCache,
        state.tableData.pageData
      );
      state.tableData.loading = true;
      getExchangeRateRecordList(data)
        .then((res) => {
          let { result } = res;
          if (result) {
            let { items = [], totalCount = 0 } = result;
            state.tableData.pageData.totalCount = parseInt(totalCount);
            state.tableData.tableList = items;
          }
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    onMounted(() => {
      let code = route.params?.code;
      if (code) {
        state.searchState.currencyCode = code;
        handleSearch();
      }
    })

    return {
      ...toRefs(state),
      columns,
      handleDateChange,
      handleSearch,
      handlePage,
    }
  }
})
</script>

<style scoped>

</style>
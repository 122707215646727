<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.dev_api_manage") }}
    </template>
    <template #contentBody="wrap">
     
      <a-row type="flex" justify="space-between" align="bottom" :gutter="[16, 16]">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                v-model:value="searchData.keyword"
                :placeholder="$t('logistics.name_or_keyword')"
                allowClear
              />
            </a-col>
            <a-col>
              <a-input-group compact>
                <a-select
                  style="min-width: 150px"
                  v-model:value="searchData.openApiCategory"
                  :placeholder="$t('devops.api_classification')"
                  @change="(e) => handleSearchCategoryChange(e, 'openApiCodeList')"
                  allowClear
                >
                  <a-select-option
                    v-for="item in openApiCategoryList"
                    :key="item"
                    :value="item"
                    :title="$t($enumLangkey('openApiCategory', item))"
                    >{{ $t($enumLangkey("openApiCategory", item)) }}</a-select-option
                  >
                </a-select>
                <a-select
                  style="min-width: 200px"
                  v-model:value="searchData.openApiCode"
                  :placeholder="$t('devops.api_code')"
                  :disabled="disabledOpenApiCode"
                  allowClear
                >
                  <a-select-option
                    v-for="item in openApiCodeList"
                    :key="item"
                    :value="item"
                    :title="$t($enumLangkey('openApiCode', item))"
                    >{{ $t($enumLangkey("openApiCode", item)) }}</a-select-option
                  >
                </a-select>
              </a-input-group>
            </a-col>
            <a-col>
              <a-select
                style="min-width: 200px"
                v-model:value="searchData.openApiRunStatus"
                :placeholder="$t('devops.running_state')"
                allowClear
              >
                <a-select-option
                  v-for="item in openApiRunStatusList"
                  :key="item"
                  :value="item"
                  :title="$t($enumLangkey('openApiRunStatus', item))"
                  >{{ $t($enumLangkey("openApiRunStatus", item)) }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchData.isActive"
                :placeholder="$t('warehouse.status')"
                allow-clear
              >
                <a-select-option :value="1" :title="$t('logistics.enabled')">{{ $t('logistics.enabled') }}</a-select-option>
                <a-select-option :value="0" :title="$t('logistics.disabled')">{{ $t('logistics.disabled') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button
                type="primary"
                :loading="tableData.loading"
                @click="handleSearch"
                >{{ $t("common.query") }}</a-button
              >
            </a-col>
          </a-row>
        </a-col>
        <a-col
          ><a-button @click="handleCreate">{{
            $t("common.create")
          }}</a-button></a-col
        >
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 110 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #openApiRunStatus="{ record }">
          <span
            v-if="record.openApiRunStatus === openApiRunStatusEnum.normal"
            >{{ $t("common.normal") }}</span
          >
          <span
            v-else-if="
              record.openApiRunStatus === openApiRunStatusEnum.exception
            "
            class="text-error"
            >{{ $t("common.exception") }}</span
          >
        </template>
        <template #openApiCategory="{ record }">
          {{ $t($enumLangkey("openApiCategory", record.openApiCategory)) }}
        </template>
        <template #openApiCode="{ record }">
          {{ $t($enumLangkey("openApiCode", record.openApiCode)) }}
        </template>
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #isEnable="{ record }">
          <a-switch
            v-model:checked="record.isActive"
            :loading="record.loading"
            @change="handleChangeActive(record)"
          />
        </template>
        <template #operate="{ record }">
          <a-button type="ghost" @click="handleEdit(record.id)">{{
            $t("common.edit")
          }}</a-button>
        </template>
      </a-table>
      <a-modal
        v-model:visible="modalState.visible"
        :title="`${$t('menu.dev_api_manage')}-${
          modalState.modalType === 1 ? $t('common.edit') : $t('common.create')
        }`"
        :confirm-loading="modalState.confirmLoading"
        @ok="handleSubmit"
      >
        <a-spin :spinning="modalState.loading">
          <a-form
            ref="formRef"
            :model="modalState.formState"
            :rules="rules"
            :label-col="labelCol"
          >
            <a-form-item :label="$t('devops.account_name')" name="name">
              <a-input
                v-model:value="modalState.formState.name"
                :placeholder="$t('devops.account_name')"
                allowClear
              />
            </a-form-item>
            <a-form-item
              :label="$t('devops.api_classification')"
              name="openApiCategory"
            >
              <a-select
                v-model:value="modalState.formState.openApiCategory"
                :placeholder="$t('devops.api_classification')"
                @change="
                  (e) => handleSearchCategoryChange(e, 'modalOpenApiCodeList')
                "
                allowClear
              >
                <a-select-option
                  v-for="item in openApiCategoryList"
                  :key="item"
                  :value="item"
                  :title="$t($enumLangkey('openApiCategory', item))"
                  >{{
                    $t($enumLangkey("openApiCategory", item))
                  }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('devops.api_code')" name="openApiCode">
              <a-select
                v-model:value="modalState.formState.openApiCode"
                :placeholder="$t('devops.api_code')"
                :disabled="disabledOpenApiCode"
                @change="handleApiCodeChange"
                allowClear
              >
                <a-select-option
                  v-for="item in modalOpenApiCodeList"
                  :key="item"
                  :value="item"
                  :title="$t($enumLangkey('openApiCode', item))"
                  >{{ $t($enumLangkey("openApiCode", item)) }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('devops.api_code_json')" name="openApiJson">
              <a-text-area
                v-model:value="modalState.formState.openApiJson"
                :placeholder="$t('devops.api_code_json')"
                :rows="6"
              />
            </a-form-item>
            <a-form-item
              v-if="modalState.example"
              :label="'JSON' + $t('devops.example')"
            >
              <a-row :gutter="[8]" type="flex" align="bottom">
                <a-col flex="1">
                  <a-text-area
                    v-model:value="modalState.example"
                    :rows="4"
                    disabled
                  />
                </a-col>
                <a-col>
                  <a-button
                    class="copyBtn"
                    size="small"
                    :data-clipboard-text="modalState.example"
                    @click="handleCopyAfter"
                    >{{ $t("devops.copy") }}</a-button
                  >
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              v-if="modalDescriptionList && modalDescriptionList.length > 0"
              :label="'JSON' + $t('common.remark')"
            >
              <a-popover v-for="item in modalDescriptionList"
                  :key="item" trigger="click">
                  <template #content>
                      <div style="max-width:500px;max-height:300px;overflow-y: auto;"><small>{{item.text}}</small></div>
                  </template>
                  <a-tag class="mt-1 mb-1">{{item.key}}</a-tag>
              </a-popover>
            </a-form-item>
            <a-form-item :label="$t('common.enabled')">
              <a-switch v-model:checked="modalState.formState.isActive" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </template>
    <template #contentFooter
      ><CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager
    ></template>
  </Content>
</template>

<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
  computed 
} from "vue";
import { useI18n } from "vue-i18n/index";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Modal,
  Switch,
  Spin,
  Form,
  message,
  Popover,
  Tag,
} from "ant-design-vue";
import {
  getSettingPagedList,
  updateOpenApiInterfaceSettingActive,
  getSettingById,
  getSettingJson,
  createOpenApiInterfaceSetting,
  updateOpenApiInterfaceSetting,
  getOpenApiCodeByOpenApiCategory,
} from "@/api/modules/devops/apimanage";
import {
  openApiRunStatus as openApiRunStatusEnum,
  openApiCategory as openApiCategoryEnum,
} from "@/enum/enum.json";
import Clipboard from "clipboard";

export default defineComponent({
  name: "dev_api_manage",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ASwitch: Switch,
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ATextArea: Input.TextArea,
    APopover: Popover,
    ATag: Tag,
    Content,
    CPager,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const apiManageModalRef = ref(null);
    const formRef = ref(null);

    const state = reactive({
      openApiRunStatusList: [],
      openApiCategoryList: [],
      openApiCodeList: [],
      modalOpenApiCodeList: [],
      disabledOpenApiCode: true,
      searchData: {
        keyword: null,
        openApiCategory: null,
        openApiCode: null,
        openApiRunStatus: null,
        isActive: null,
      },
      searchDataCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      modalState: {
        visible: false,
        loading: false,
        confirmLoading: false,
        modalType: 0,
        example: null,
        description: null,
        formState: {
          id: null,
          name: null,
          openApiCategory: null,
          openApiCode: null,
          openApiJson: null,
          isActive: true,
        },
      },
    });

    for (const key in openApiRunStatusEnum) {
      state.openApiRunStatusList.push(openApiRunStatusEnum[key]);
    }

    for (const key in openApiCategoryEnum) {
      state.openApiCategoryList.push(openApiCategoryEnum[key]);
    }

    const columns = [
      {
        dataIndex: "name",
        title: () => vueI18n.t("devops.account_name"),
        width: 200,
        align: "center",
      },
      {
        title: () => vueI18n.t("devops.running_state"),
        width: 120,
        slots: {
          customRender: "openApiRunStatus",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("devops.api_classification"),
        width: 120,
        slots: {
          customRender: "openApiCategory",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("devops.api_code"),
        width: 120,
        slots: {
          customRender: "openApiCode",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("warehouse.creation_time"),
        width: 150,
        slots: {
          customRender: "creationTime",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("finance.status"),
        width: 150,
        slots: {
          customRender: "isEnable",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 150,
        slots: {
          customRender: "operate",
        },
        align: "center",
      },
    ];

    const rules = {
      name: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("devops.account_name"),
        ]),
      },
      openApiCategory: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("devops.api_classification"),
        ]),
      },
      openApiCode: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("devops.api_code"),
        ]),
      },
      openApiJson: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("devops.api_code_json"),
        ]),
      },
    };

    const handleCopyAfter = () => {
      message.success(vueI18n.t('common.succeed'))
    }

    const getDetail = async (id) => {
      try {
        state.loading = true;
        let { result } = await getSettingById({ id });
        if (result) {
          let formState = JSON.parse(
            JSON.stringify(state.modalState.formState)
          );
          for (const key in formState) {
            if (Object.hasOwnProperty.call(result, key)) {
              state.modalState.formState[key] = result[key];
            }
          }
          // if (typeof state.modalState.example === 'object') {
          //   state.modalState.example = JSON.stringify(state.modalState.example)
          // }
        }

        let { result: list } = await getOpenApiCodeByOpenApiCategory({
          openApiCategory: state.modalState.formState.openApiCategory,
        });
        if (result) {
          state.modalOpenApiCodeList = list;
        }
        handleApiCodeChange()
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    // 查询表单分类改变
    const handleSearchCategoryChange = (e, name) => {
      if (name === "openApiCodeList") {
        state.searchData.openApiCode = null;
        state.disabledOpenApiCode = true;
      } else {
        initModalForm();
      }
      state[name] = [];
      if (e !== undefined) {
        state.disabledOpenApiCode = true;
        getOpenApiCodeByOpenApiCategory({ openApiCategory: e })
          .then((res) => {
            let { result } = res;
            if (result) {
              state[name] = result;
            }
          })
          .finally(() => {
            state.disabledOpenApiCode = false;
          });
      }
    };

    const handleChangeActive = (record) => {
      record.loading = true;
      updateOpenApiInterfaceSettingActive({
        id: record.id,
        isActive: record.isActive,
      })
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .finally(() => {
          record.loading = false;
        });
    };

    const handleSubmit = async () => {
      try {
        await formRef.value.validate();
        state.modalState.confirmLoading = true;
        const data = JSON.parse(JSON.stringify(state.modalState.formState));
        if (state.modalState.modalType === 0) {
          await createOpenApiInterfaceSetting(data);
        } else {
          await updateOpenApiInterfaceSetting(data);
        }
        message.success(vueI18n.t("common.succeed"));
        state.modalState.visible = false;
        handleInitSearch();
      } catch (error) {
      } finally {
        state.modalState.confirmLoading = false;
      }
    };
    
    const initModalForm = () => {
      state.modalOpenApiCodeList = [];
      state.modalState.example = null;
      state.modalState.description = null;
      state.modalState.formState.openApiCode = null;
    };

    const handleCreate = () => {
      initModalForm();
      state.modalState.modalType = 0;
      state.modalState.visible = true;

      nextTick(() => {
        formRef.value.resetFields();
        new Clipboard(".copyBtn");
      });
    };

    const handleEdit = (id) => {
      initModalForm();
      state.modalState.modalType = 1;
      state.modalState.visible = true;

      nextTick(() => {
        formRef.value.resetFields();
        new Clipboard(".copyBtn");
        getDetail(id);
      });
    };

    const handleApiCodeChange = () => {
      state.modalState.example = null;
      state.modalState.description = null;
      getSettingJson({
        openApiCode: state.modalState.formState.openApiCode,
      }).then((res) => {
        let { result } = res;
        if (result) {
          state.modalState.example = result.demo;
          state.modalState.description = result.description;
        }
      });
    };

    const getPageData = () => {
      const data = Object.assign(
        {},
        state.searchDataCache,
        state.tableData.pageData
      );
      data.isActive = data.isActive === 1 ? true : data.isActive === 0 ? false : null;
      state.tableData.loading = true;
      getSettingPagedList(data)
        .then((res) => {
          let { result } = res;
          if (result) {
            let { items = [], totalCount = 0 } = result;
            state.tableData.pageData.totalCount = parseInt(totalCount);
            state.tableData.tableList = items.map((item) => {
              item.loading = false;
              return item;
            });
          }
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const modalDescriptionList = computed(()=>{
      if(state.modalState.description){
        var descObj = {};
        if(typeof state.modalState.description === "string"){
          try{
            descObj = JSON.parse(state.modalState.description);
          }catch{}
        }else{
          descObj = state.modalState.description;
        }        
        return Object.keys(descObj).map((key)=>{
          return {
            key:key,
            text:descObj[key]
          };
        })
      }
      return [];
    });

    const handleSearch = () => {
      Object.assign(state.searchDataCache, state.searchData);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    onMounted(handleInitSearch);

    return {
      ...toRefs(state),
      modalDescriptionList,
      apiManageModalRef,
      columns,
      formRef,
      labelCol: { style: { width: "120px" } },
      rules,
      openApiRunStatusEnum,
      handleSearch,
      handlePage,
      handleCreate,
      handleEdit,
      handleApiCodeChange,
      handleChangeActive,
      handleSubmit,
      handleSearchCategoryChange,
      handleCopyAfter,
    };
  },
});
</script>

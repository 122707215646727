import client from "../../client";

const Api = {
  getList: "/api/ProductService/RechargeLog/GetListManagePaged",
  successfulInAccount: "/api/ProductService/RechargeLog/Update",
  notInAccount: "/api/ProductService/RechargeLog/RecordedFailure",
  // 获取计算汇率
  getExchangeRate: "/api/ProductService/ExchangeRate/GetExchangeRate",
  // 查询收款账户信息
  getPayeeAccountList: "/api/ProductService/PayeeAccount/GetListPaged",
  // 计算充值应付款
  getReChargePayMoney: "/api/ProductService/ReChargeLog/GetReChargePayMoney",
  // 创建充值
  createReChargeLog: "/api/ProductService/RechargeLog/CreateManage",
  // 追加内部备注
  updateInternalRemark: "/api/ProductService/RechargeLog/UpdateRemark",
};

export const getList = ({
  serialNumberType,
  serialNo,
  shopkeyWord,
  status,
  rechargeType,
  currencyId,
  rechargeLow,
  rechargeTop,
  payCurrencyId,
  payLow,
  payTop,
  shopId,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: {
      serialNumberType,
      serialNo,
      shopkeyWord,
      status,
      shopId,
      rechargeType,
      currencyId,
      rechargeLow,
      rechargeTop,
      payCurrencyId,
      payLow,
      payTop,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const successfulInAccount = ({ payeeNo, remark,internalRemark, id }) => {
  return client.request({
    url: Api.successfulInAccount,
    data: { payeeNo, remark,internalRemark, id },
    method: "post",
  });
};

export const notInAccount = ({ recordedFailureRemark, id }) => {
  return client.request({
    url: Api.notInAccount,
    data: { recordedFailureRemark, id },
    method: "post",
  });
};

export const getExchangeRate = (data = {}) => {
  return client.request({
    url: Api.getExchangeRate,
    data,
    method: "POST",
  });
};

export const getPayeeAccountList = (data = {}) => {
  return client.request({
    url: Api.getPayeeAccountList,
    data,
    method: "POST",
  });
};

export const getReChargePayMoney = (data = {}) => {
  return client.request({
    url: Api.getReChargePayMoney,
    data,
    method: "POST",
  });
};

export const createReChargeLog = (data = {}) => {
  return client.request({
    url: Api.createReChargeLog,
    data,
    method: "POST",
  });
};

export const updateInternalRemark = (data = {}) => {
  return client.request({
    url: Api.updateInternalRemark,
    data,
    method: "POST",
  });
};
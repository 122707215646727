import addressFilter from './addressFilter.js';
import boxNoFilter from './boxNoFilter.js';


// 自定义指令
const directives = {
  addressFilter,
  boxNoFilter,
}
 
export default {
  install(app) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key])
    })
  },
}
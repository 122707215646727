<template>
  <a-modal
    v-model:visible="visible" 
    :title="$t('warehouse.authorized_application_tenants_permissions')"
    :confirm-loading="loading"
    :mask-closable="false"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('warehouse.application')">{{ appName }}</a-form-item>
      <a-form-item :label="$t('warehouse.authorized_customers')" name="shopId">
        <CSearchShop ref="cSearchShopRef" v-model:shopId="formState.shopId" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, nextTick } from 'vue';
import { Button, Form, message, Modal, Select } from 'ant-design-vue';
import CSearchShop from '@/views/components/CSearchShop.vue';
import { useI18n } from "vue-i18n/index";
import { allowAppAuthShop } from "@/api/modules/openPlatform/applysCompany";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    CSearchShop,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);
    const cSearchShopRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      appName: "",
      formState: {
        authorizeApplicationId: null,
        shopId: null
      },
      selectedData: null
    })

    const rules = {
      shopId: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.authorized_customers")]),
      }
    }

    const show = (id, appName) => {
      state.visible = true
      state.formState.authorizeApplicationId = id
      state.appName = appName
      nextTick(() => {
        formRef.value.resetFields()
        cSearchShopRef.value.searchKey = null
      })
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate()
        state.loading = true
        const data = Object.assign({}, state.formState)
        let { result } = await allowAppAuthShop(data)
        if (result) {
          message.success(vueI18n.t("common.succeed"))
          emit("refresh")
          state.visible = false
        } else {
          message.error(vueI18n.t("warehouse.error_message"))
        }
      } catch (error) {
      } finally {
        state.loading = false
      }
    }

    return {
      ...toRefs(state),
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },
      rules,
      formRef,
      cSearchShopRef,
      show,
      handleConfirm,
    }
  }
})
</script>
import client from "../../client";

const Api = {
  getListPaged: "/api/WarehouseService/TranSportShipingRlConsole/GetListPaged",
  getList: "/api/WarehouseService/TranSportShipingRlConsole/GetList",
};

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};

export const getList = ({
  shippingSpaceId,
  transportType,
  destinationType,
  toCountryId,
  toCityId,
  searchKey,
	status,
  sorting,
  isIncludingCancel=true
}) => {
  return client.request({
    url: Api.getList,
    data: {
      shippingSpaceId,
      transportType,
      destinationType,
      toCountryId,
      toCityId,
      status,
      isIncludingCancel,
			searchKey,
      sorting,
    },
    method: "post",
  });
};


<template>
  <div class="clearfix upload-container">
    <a-upload name="uploadFile"
              list-type="picture-card"
              v-model:file-list="fileUplpadedList"
              :customRequest="handleUpload"
              :remove='deleteFile'
              @preview="handlePreview">
      <span v-if="fileUplpadedList.length < maxCount"
            ref="refUpdate">
        <div class="upload-chooser text-primary"
             @click.stop>
          <div @click="handleLocalUpload">
            <a-tooltip placement="rightBottom">
              <template #title>
                <span> {{$t('common.local_upload')}}</span>
              </template>
              <UploadOutlined :style="{fontSize: '20px'}" />
            </a-tooltip>
          </div>
          <div class="mt-3"
               @click.stop="handleShowTakePhotoModal">
            <a-tooltip placement="rightBottom">
              <template #title>
                <span>{{$t('common.photo_upload')}}</span>
              </template>
              <CameraOutlined :style="{fontSize: '20px'}" />
            </a-tooltip>
          </div>

        </div>
      </span>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" :centered="true" width="auto" @cancel="handleCancelPreview">
      <div class="center-middle">
        <c-image :src="previewImage" :thumb-width="800" :thumb-height="800" :preview="false" />
      </div>
    </a-modal>
    <CTakePhotoModal v-model:visible="takePhotoVisible"
                     @ok="getTakePhoFileData"></CTakePhotoModal>
  </div>
</template>
<script>
import {
  Upload, Modal, Image, Button, Tooltip, message, Row, Col, Spin
} from "ant-design-vue";
import { defineComponent, reactive, toRefs, watch, ref, nextTick } from 'vue';
import { uploadFile } from "../../../api/modules/common/index";
import CImage from '@/views/components/CImage';
import { useI18n } from "vue-i18n/index";
import CTakePhotoModal from "../../components/CTakePhotoModal.vue";


export default defineComponent({
  name: "InboundAddImg",
  components: {
    AUpload: Upload,
    AModal: Modal,
    AImage: Image,
    AButton: Button,
    ATooltip: Tooltip,
    CTakePhotoModal,
    ARow: Row,
    ACol: Col,
    ASpin: Spin,
    CImage,
  },
  props: {
    allowUploadCount: {
      type: Number,
      default: 3,
      required: false,
    },
    fileList: {
      type: Array,
      default: [],
      required: false
    },
    functionModule: {
      type: Number,
      default: 6,
    }
  },
  setup (props, { emit }) {
    const i18n = useI18n({ useScope: "global" });

    let fileUplpadedList = ref([]);
    let refUpdate = ref();
    let maxCount = ref(2);

    watch(props, function (prev, next) {
      setInit(next.allowUploadCount, next.fileList);
    });

    setInit(props.allowUploadCount, props.fileList);

    function setInit (allowUploadCount, fileList) {
      maxCount.value = allowUploadCount || maxCount.value;
      fileUplpadedList.value = (fileList || []).map((x, index) => {
        return {
          url: x,
          uid: "sub_" + index
        }
      });
    }

    const state = reactive({
      previewVisible: false,
      uploadLoading: false,
      previewImage: "",
      deviceId: null,
      takePhotoVisible: false,
    });


    //预览
    const handlePreview = async file => {
      state.previewImage = file.url || file.preview;
      state.previewVisible = true;
    };

    //取消预览
    const handleCancelPreview = () => {
      state.previewVisible = false;
    };

    //控制预览
    const hanldeOnVisibleChange = (visible, prevVisible) => {
      if (prevVisible) {
        state.previewVisible = false;
      }
    }

    //上传图片
    const handleUpload = async (file) => {

      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", props.functionModule);

      return uploadFile(parameter).then((res) => {
        if (res.result && res.result.length > 0) {
          res.result[0].status = "done";
          let targetFile = fileUplpadedList.value.find(x => x.uid == file.file?.uid);
          if (targetFile) {
            Object.assign(targetFile || {}, res.result[0])
          } else {
            let temp = {
              url: res.result[0].url,
              uid: "sub_" + Date.now() + '_' + fileUplpadedList.value?.length
            };
            fileUplpadedList.value.push(temp);
          }
          let updataData = fileUplpadedList.value.map(x => x.url);
          emit('update:fileList', updataData);
        }
        state.uploadLoading = false;

      }).catch((error) => {
        fileUplpadedList.value = fileUplpadedList.value.filter(x => x.uid != file.file.uid);
        state.uploadLoading = false;
        message.error(i18n.t("common.failed"));
      });
    }

    const handleLocalUpload = (e) => {
      refUpdate.value.click();
    }

    const handleShowTakePhotoModal = () => {
      state.takePhotoVisible = true;
    }

    const funcBase64ToFile = (base64String) => {
      var bytes = window.atob(base64String.split(',')[1]);
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      var blobData = new File([ia], Date.now() + "_inwh_photo.jpg", { type: 'image/jpeg' });
      return blobData;
    }

    const getTakePhoFileData = (base64Img) => {
      let file = { file: funcBase64ToFile(base64Img) };
      handleUpload(file);
    }

    const deleteFile = async (file) => {
      fileUplpadedList.value = fileUplpadedList.value.filter(x => x.url != file.url);
      let updataFiles = fileUplpadedList.value.map(x => x.url);
      emit('update:fileList', updataFiles);
    }

    return {
      ...toRefs(state),
      refUpdate,
      hanldeOnVisibleChange,
      handleCancelPreview,
      handlePreview,
      handleUpload,
      fileUplpadedList,
      deleteFile,
      maxCount,
      handleLocalUpload,
      getTakePhoFileData,
      handleShowTakePhotoModal
    };
  },
});
</script>
<style lang="less" scoped>
.upload-container {
  // margin-top: 10px;
  // margin-left: 10px;
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
:deep(.ant-upload) {
  padding: 0 !important;
  .upload-chooser {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
  }
}
</style>
import client from "../../client"

const Api = {
  // 获取分区分页列表
  getPagedList: "/api/LogisticsService/LogisticsAccount/GetPagedList",
  // 启用/禁用
  updateEnableOrDisEnable: "/api/LogisticsService/LogisticsAccount/UpdateEnableOrDisEnable",
  // 新增
  createLogisticsAccount: "/api/LogisticsService/LogisticsAccount/Create",
  // 修改
  updateLogisticsAccount: "/api/LogisticsService/LogisticsAccount/Update",
  // 详情
  getLogisticsAccountById: "/api/LogisticsService/LogisticsAccount/GetById",
  // 获取物流渠道
  filterByDeliveryTypes: "/api/LogisticsService/Logistics/FilterByDeliveryTypes",
}

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: "POST",
  });
};

export const createLogisticsAccount = (data) => {
  return client.request({
    url: Api.createLogisticsAccount,
    data,
    method: "POST",
  });
};

export const updateLogisticsAccount = (data) => {
  return client.request({
    url: Api.updateLogisticsAccount,
    data,
    method: "POST",
  });
};

export const updateEnableOrDisEnable = (data) => {
  return client.request({
    url: Api.updateEnableOrDisEnable,
    data,
    method: "POST",
  });
};

export const getLogisticsAccountById = (data) => {
  return client.request({
    url: Api.getLogisticsAccountById,
    params: data,
    method: "GET",
  });
};

export const filterByDeliveryTypes = (data) => {
  return client.request({
    url: Api.filterByDeliveryTypes,
    data,
    method: "POST",
  });
};
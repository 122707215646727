<template>
  <a-upload name="uploadFile"
            :customRequest="handleUpload"
              :accept="accept"
            :showUploadList="false"
            v-bind="$attrs">
    <a-button v-if="files.length<props.maxFileNumber"
              size="small"
              class="ml-1"
              :loading="uploadLoading">{{$t('common.upload_file')}}</a-button>
  </a-upload>
  <div style="position:relative;display: flex;flex: 0;">
    <div v-for="(item, index) in files"
         :key="index"
         class="p-1 "
         style="position:relative;">
      <div v-if="urlHelper.isImageUrl(item)"
           class="file-imgs">
        <c-image :src="item"> </c-image>
      </div>
      <div v-else-if="urlHelper.isPdfUrl(item)"
           class="file-imgs">
        <a :href="item"
           target="_blank">
          <FilePdfOutlined :style="{fontSize: '40px',color: '#08c' }" />
        </a>
      </div>
      <div v-else
           class="file-imgs">
        <a :href="item"
           target="_blank">
          <LinkOutlined :style="{fontSize: '40px',color: '#08c' }" />
        </a>
      </div>
      <a-tooltip placement="right">
        <template #title>{{$t('common.delete')}}</template>
        <span class="delete-label"
              @click="deleteFile(index)">
          <DeleteOutlined />
        </span>
      </a-tooltip>
    </div>
  </div>

</template>
<script>
import { reactive, toRefs, watchEffect } from "vue";
import CImage from "../../components/CImage.vue"
import {
  uploadFile
} from "../../../api/modules/common/index";
import urlHelper from "../../../utils/urlHelper";
import {
  Row, Col, Upload,
  Tooltip,
  Select, Button, Form,
} from "ant-design-vue";
export default ({
  components: {
    CImage,
    ATooltip: Tooltip,
    AUpload: Upload,
    AImage: Image,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
  },
  props: {
     accept: {
      type: String,
      default: ''
    },
    fileUrl: {
      type: String,
      default: null,
    },
    maxFileNumber: {
      type: Number,
      default: 1,
    },
    functionModule: {
      type: Number,
      required: true,
      default: 1,
    },
    onChange:{//重写form item 传下来的change事件
      type: Function,
    },
    emits: ['fileUrl'],
  },
  setup (props, { emit }) {
    const state = reactive({
      uploadLoading: false,
      files: [],
    });

    watchEffect(() => {
      if (props.fileUrl) {
        state.files = [props.fileUrl];
      } else {
        state.files = [];
      }
    });

    const handleUpload = (file) => {
        state.uploadLoading = true;
        let parameter = new FormData();
        parameter.append("file", file.file);
        parameter.append("functionModule", props.functionModule);//类型
        uploadFile(parameter).then(async (res) => {
          if (res.result && res.result.length > 0) {
            await emit("update:fileUrl", res.result[0].url)
            // state.files.push(res.result[0].url);
            props.onChange && props.onChange();//触发父组件的form-item的验证,
          }
        }).finally(() => {
          state.uploadLoading = false;
        });
    }

    const deleteFile = async (itemIndex) => {
      //state.files = state.files.filter((x, index) => index != itemIndex);
      await emit("update:fileUrl", null);
      props.onChange && props.onChange();
    }

    return {
      props,
      ...toRefs(state),
      handleUpload,
      deleteFile,
      urlHelper,
    };
  }
})
</script>

<style lang="less" scoped>
.file-imgs {
  width: 50px;
  height: 50px;
  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :deep(.ant-image img) {
    max-height: 40px;
  }
}

.delete-label {
  position: absolute;
  top: 90%;
  left: 40%;
  font-size: 15px;
  color: #d9d9d9;
  :hover {
    color: #04befe;
    //font-size: 20px;
  }
}
</style>
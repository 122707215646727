<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.warehouse_monitor_unload') }}
    </template>
    <!-- 下拉按钮 and table -->
    <template v-slot:contentBody='wrap'>
      <div class="mb-3">
        <a-form layout="inline">
          <a-form-item>
            <a-input :placeholder="$t('warehouse.appointment_no') + '/' + $t('warehouse.container_no')"
                     allow-clear
                     v-model:value="data.searchKey"></a-input>
          </a-form-item>
          <a-form-item>
            <CSearchShop v-model:shopId="data.shopId"></CSearchShop>
          </a-form-item>
          <a-form-item>
            <a-select style="min-width: 150px"
                      v-model:value="data.status"
                      :placeholder="$t('finance.status')"
                      allow-clear>
              <a-select-option v-for="(value,index) in fullContainerInWarehouseTypeEnum"
                               :key="index"
                               :title="$t($enumLangkey('fullContainerInWarehouseType',value))"
                               :value="value">
                {{$t($enumLangkey('fullContainerInWarehouseType',value))}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary"
                      @click="handleSearch">{{ $t('common.query') }}</a-button>
          </a-form-item>

        </a-form>
      </div>
      <div>
        <!-- 表格 -->
        <a-table :columns="columns"
                 :data-source="data.list"
                 :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
                 :pagination="false"
                 :rowKey="
          (record, id) => {
            return id;
          }
        "
                 size="small"
                 :loading="data.loading">
                 <template #customer="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
            <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
          <template #appointmentTime="{record}">
            {{moment.tz(record.appointmentTime, record.warehouseTimeZone).format('YYYY-MM-DD')}}
            <div>({{$t('warehouse.local_time')}})</div>
          </template>

          <template #containerType="{record}">
            {{
              $t($enumLangkey("containerType", record.containerType))
            }}
          </template>

          <template #driverTelephone="{record}">
            <template v-if="record.driverTelCode && record.driverTelephone">
              <span v-if="record.driverTelCode">{{record.driverTelCode}}</span>
              <span v-if="record.driverTelephone">{{record.driverTelephone}}</span>
            </template>
            <span v-else>-</span>
          </template>

          <template #status="{record}">
            {{
              $t($enumLangkey("fullContainerInWarehouseType", record.status))
            }}
            <span v-if="record.isTimeOut">({{ $t('warehouse.timed_out') }})</span>
          </template>

          <template #creationTime="{record}">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
          </template>

          <template #lastModificationTime="{record}">
            {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
          </template>

          <template #employee="{ record }">
            <CEmployeeInfo v-if="record.employee" :employee="record.employee" />
            <span v-else>-</span>
          </template>

        </a-table>
      </div>
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data='data.pageData'> </CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive } from 'vue';
import {
  Table,
  Button,
  Select,
  Row,
  Col,
  Input, Form,
} from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue';
import CSearchShop from "../components/CSearchShop.vue"
import CEmployeeInfo from "@/views/components/CEmployeeInfo.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import moment from "moment-timezone";
import {
  getTask
} from '../../api/modules/warehouseMonitor/unload';
import {
  fullContainerInWarehouseType as fullContainerInWarehouseTypeEnum,
  containerType as containerTypeEnum
} from '../../enum/enum.json'

export default defineComponent({
  name: "warehouse_monitor_unload",
  components: {
    ATable: Table,
    AButton: Button,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AFormItem: Form.Item,
    AForm: Form,
    Content,
    CPager,
    CSearchShop,
    CEmployeeInfo,
  },

  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const columns = [
      {
        title: () => vueI18n.t('warehouse.appointment_no'),
        dataIndex: 'appointmentNo',
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 180,
      },
      {
        title: () => vueI18n.t('warehouse.appointment_time'),
        dataIndex: 'appointmentTime',
        width: 100,
        slots: {
          customRender: 'appointmentTime',
        },
      },
      {
        title: () => vueI18n.t('warehouse.container_no'),
        dataIndex: 'containerNo',
        width: 120,
      },
      {
        title: () => vueI18n.t('warehouse.container_type'),
        dataIndex: 'containerType',
        slots: {
          customRender: 'containerType',
        }, width: 120,
      },
      {
        title: () => vueI18n.t('warehouse.plan_num'),
        dataIndex: 'planNum',
        width: 80,
      },
      {
        title: () => vueI18n.t('warehouse.driver_telephone'),
        slots: {
          customRender: 'driverTelephone',
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('finance.status'),
        dataIndex: 'status',
        slots: {
          customRender: 'status',
        }
        ,
        width: 80,
      },
      {
        title: () => vueI18n.t('logistics.create_date'),
        dataIndex: 'creationTime',
        slots: {
          customRender: 'creationTime',
        }
        ,
        width: 100,
      },
      {
        title: () => vueI18n.t('logistics.last_modification_time'),
        dataIndex: 'lastModificationTime',
        slots: {
          customRender: 'lastModificationTime',
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('warehouse.principal'),
        width: 150,
        dataIndex: 'employee',
        slots: {
          customRender: "employee"
        },
      },
    ];

    const data = reactive({
      list: [],
      status: null,
      searchKey: null,
      shopId: null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      loading: false,
    });


    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      getTable();
    }


    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      getTable()
    }

    const getTable = async () => {
      data.loading = true;
      let params = { ...data, ...data.pageData }
      getTask(params).then(({ result }) => {
        if (result) {
          let { items, totalCount } = result;
          data.list = items;
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.pageData.totalCount = 0;
          data.list = [];
        }
        data.loading = false;
      }).catch(() => {
        data.loading = false;
      })
    }

    onActivated(() => {
      getTable();
    });

    return {
      columns,
      data,

      moment,
      fullContainerInWarehouseTypeEnum,
      containerTypeEnum,
      handlePage,
      handleSearch,
    };
  },
});
</script>
<style lang="less" scoped>
</style>

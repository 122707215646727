<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.open_platform_authorizes") }}
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px;" v-model:value="data.searchKey" :placeholder="$t('授权项名称')" allowClear />
            </a-col>
            <a-col>
              <a-button type="primary" :loading="data.loading" @click="handleSearch">{{ $t('common.query') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleShowModal" ghost>{{ $t("common.create") }}</a-button>
        </a-col>
      </a-row>

      <div class="mt-3">
        <a-table
          :columns="columns"
          :data-source="data.list"
          :scroll="{ x: 400, y: wrap.contentHeight - 150 }"
          :pagination="false"
          :rowKey="(record) => record.id"
          :loading="data.loading"
        >
          <template v-slot:operate="{ record }">
            <div>
              <a-dropdown-button @click="handleShowModal(record)">
                {{ $t('common.edit') }}
                <template #overlay>
                  <a-menu @click="handleMenuClick">
                    <a-menu-item :key="2" :record="record">授权项API管理</a-menu-item>
                    <a-menu-item :key="3" :record="record">{{ $t('common.delete') }}</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown-button>
            </div>
          </template>
        </a-table>
      </div>
      <a-modal
        v-model:visible="modalData.visible"
        :confirm-loading="modalData.loading"
        :title="modalData.id ? $t('common.edit') : $t('common.edit')"
        :maskClosable="false"
        :centered="true"
        @ok="handleSaveModal"
      >
        <a-form :model="modalData" ref="refModal" :label-col="{ span: 7 }" :rules="rules">
          <a-form-item :label="'授权项名称'" name="authorizeName">
            <a-input v-model:value="modalData.authorizeName" :maxlength="256"></a-input>
          </a-form-item>
          <a-form-item :label="'授权项标识'" name="authorizeKey">
            <a-input v-model:value="modalData.authorizeKey" :maxlength="128"></a-input>
          </a-form-item>
          <a-form-item :label="'授权项描述'">
            <a-textarea v-model:value="modalData.description" :maxlength="512"></a-textarea>
          </a-form-item>
        </a-form>
      </a-modal>
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="data.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { reactive, onActivated, ref, nextTick } from "vue";
import {
  Row, Col, Table,
  Button, Form, Input,
  Dropdown, Menu, Modal
} from "ant-design-vue";
import Content from "../components/Content.vue";
import CPager from "../components/CPager.vue";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { getList, create, update, deleteAuthorize } from "../../api/modules/openPlatform/authorizes";

export default {
  name: "open_platform_authorizes",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AForm: Form,
    AButton: Button,
    AFormItem: Form.Item,
    AInput: Input,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AModal: Modal,
    ATextarea: Input.TextArea,
    Content,
    CPager,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();

    const refModal = ref();

    const data = reactive({
      loading: false,
      searchKey: null,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const modalData = reactive({
      visible: false,
      loading: false,
      authorizeName: null,
      authorizeKey: null,
      description: null,
      id: null,
    });
    
    const columns = [
      {
        dataIndex: "authorizeName",
        width: 200,
        title: () => vueI18n.t('授权项名称'),
      },
      {
        dataIndex: "authorizeKey",
        width: 200,
        title: () => vueI18n.t('授权项标识'),
      },
      {
        dataIndex: "description",
        width: 200,
        title: () => vueI18n.t('logistics.describe'),
      },
      {
        width: 150,
        slots: {
          customRender: "operate",
        },
      },
    ];

    const handleDelete = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operate"),
        content: vueI18n.t("common.are_you_sure_delete"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return deleteAuthorize([id]).then(() => {
            funcGetList();
          }).catch(() => {});
        },
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 2:
          // 授权项API管理
          router.push({
            name: 'open_platform_authorize_api',
            params: { id: record.id },
          });
          break;
        case 3:
          // 删除
          handleDelete(record.id);
          break;
        default:
          break;
      }
    }

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcGetList();
    };

    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcGetList();
    }

    const funcGetList = () => {
      data.loading = true;
      const searchData = {
        searchKey: data.searchKey,
        ...data.pageData
      };
      getList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.list = items;
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
            data.list = [];
          }
        })
        .finally(() => {
          data.loading = false;
        });
    }

    const handleShowModal = (record) => {
      modalData.visible = true;
      modalData.id = null;
      modalData.authorizeName = null;
      modalData.authorizeKey = null;
      modalData.description = null;

      if (record?.id) {
        modalData.id = record.id;
        modalData.authorizeName = record.authorizeName;
        modalData.description = record.description;
        modalData.authorizeKey = record.authorizeKey;
      }

      nextTick(() => {
        refModal.value.clearValidate();
      })
    };

    const handleSaveModal = () => {
      modalData.loading = true;
      refModal.value
        .validate()
        .then(async () => {
          return modalData.id ? update({ ...modalData }) : create({ ...modalData });
        })
        .then(() => {
          modalData.visible = false;
          modalData.loading = false;
          handleSearch()
        })
        .catch(() => {
          modalData.loading = false;
        });
    }

    const rules = {
      authorizeName: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("授权项名称")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      authorizeKey: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("授权项标识")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      // description: [
      //   {
      //     required: true,
      //     message: () => {
      //       return vueI18n.t("common.p0_is_required", [vueI18n.t("授权项描述")]);
      //     },
      //     trigger: ["blur", "change"],
      //   },
      // ],
    };

    onActivated(() => {
      handleSearch()
    });

    return {
      columns,
      data,
      modalData,
      rules,
      refModal,
      handleMenuClick,
      handlePage,
      handleShowModal,
      handleSaveModal,
      handleSearch,
    };
  },
};
</script>

<style lang="less" scoped></style>
<template>
  <a-pagination :show-quick-jumper="showQuickJumper"
                :show-less-items="showLessItems"
                v-model:current="current"
                :total="total"
                @change="handleGotoTargetPage"
                :pageSizeOptions="pageSizeOptions"
                @showSizeChange="handleChangePageSize"
                :showSizeChanger="showSizeChanger"
                :defaultPageSize="defaultPageSize"
                :show-total="total => `总计${total}条 `" />
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import { Pagination } from 'ant-design-vue'

export default ({
  name: "Paging",
  props: {
    defaultPageSize: {
      type: Number,
      default: 10,
      required: false
    },
    showQuickJumper: {
      type: Boolean,
      default: true,
      required: false
    },
    showSizeChanger: {
      type: Boolean,
      default: true,
      required: false
    },
    hideOnSinglePage: {
      type: Boolean,
      default: true,
      required: false
    },
    pageSizeOptions: {
      type: Array,
      default: ['10', '30', '50', '100'],
      required: false
    },
    showLessItems: {//显示少的分页数量
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup (props, { emit }) {
    const current = ref(1)
    const paging = reactive({
      pageSizeOptions: props.pageSizeOptions,
      pageSize: props.defaultPageSize,
      total: 0,
      showQuickJumper: props.showQuickJumper,
      showSizeChanger: props.showSizeChanger,
      hideOnSinglePage: props.hideOnSinglePage,
      showLessItems: props.showLessItems
    })

    const handleGotoTargetPage = (page, pageSize) => {
      getData(page, pageSize)
    }

    const handleChangePageSize = (page, size) => {
      getData(page, size)
    }

    const getData = async (currentPage, pageSize) => {
      currentPage = currentPage == 0 ? 1 : currentPage;
      let skipCount = pageSize * (currentPage - 1);

      let pageData = { 'skipCount': skipCount, 'maxResultCount': pageSize };
      emit('handlePage', pageData)
    }


    return {
      current,
      handleGotoTargetPage,
      handleChangePageSize,
      ...toRefs(paging)
    }
  },
  components: {
    APagination: Pagination
  }
})
</script>

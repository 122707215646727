<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('warehouse.operation_tips')"
    :mask-closable="false"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item label="Vat" name="vatCode">
        <a-input v-model:value="formState.vatCode" allow-clear />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, toRefs } from 'vue'
import { Form, Input, Modal } from 'ant-design-vue'
import { useI18n } from "vue-i18n/index"

export default defineComponent({
  name: 'vatFormModal',
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
  },
  emits: ['confirm'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const vueI18n = useI18n({ useScope: 'global' })

    const state = reactive({
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formState: {
        vatCode: null,
      }
    })

    const rules = {
      vatCode: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', ['Vat'])
      }
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate()
        state.visible = false
        nextTick(() => {
          emit('confirm', JSON.parse(JSON.stringify(state.formState)))
        })
      } catch (error) {
        
      }
    }

    const show = (data) => {
      state.visible = true
      state.formState.vatCode = data.vatCode
      nextTick(() => {
        formRef.value.resetFields()
        Object.assign(state.formState, data)
      })
    }

    return {
      ...toRefs(state),
      rules,
      formRef,
      handleConfirm,
      show,
    }
  }
})
</script>
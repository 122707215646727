import client from "../../client";

const Api = {
  //根据物流方案，获取费用模板
  getListBySupplierId: "/api/SupplierService/FeeTemplate/GetListBySupplierId",
  getFeeTemplates: "/api/SupplierService/FeeTemplate/GetPagedList",
  deleteById: "/api/SupplierService/FeeTemplate/Delete",

  //根据模板id获得费用配置信息(编辑费用配置)
  getFeeConfigInfo: "/api/SupplierService/FeeTemplate/GetById",
  createFeeConfig: "/api/SupplierService/FeeTemplate/Create",
  updateFeeConfig: "/api/SupplierService/FeeTemplate/Update",
};

//根据物流方案，获取费用模板
export const getListBySupplierId = (supplierId) => {
  return client.request({
    url: Api.getListBySupplierId,
    params: { supplierId },
    method: "post",
  });
};

export const getFeeTemplates = (data) => {
  return client.request({
    url: Api.getFeeTemplates,
    data,
    method: "post",
  });
};

export const deleteById = (ids) => {
  return client.request({
    url: Api.deleteById,
    data: { ids },
    method: "post",
  });
};

export const createFeeConfig = (data) => {
  return client.request({
    url: Api.createFeeConfig,
    data,
    method: "post",
  });
};

export const updateFeeConfig = (data) => {
  return client.request({
    url: Api.updateFeeConfig,
    data,
    method: "post",
  });
};

export const getFeeConfigInfo = (id) => {
  return client.request({
    url: Api.getFeeConfigInfo,
    params: { id },
    method: "get",
  });
};

import client from "../../client"

const Api = {
  // 汇率列表
  getExchangeRateList: "/api/ProductService/ExchangeRate/GetList",
  // 汇率更新记录列表
  getExchangeRateRecordList: "/api/ProductService/ExchangeRateRecord/GetListPaged",
}

export const getExchangeRateList = (data) => {
  return client.request({
    url: Api.getExchangeRateList,
    data,
    method: 'POST'
  })
}

export const getExchangeRateRecordList = (data) => {
  return client.request({
    url: Api.getExchangeRateRecordList,
    data,
    method: 'POST'
  })
}
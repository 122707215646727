import client from "../../../client";

const Api = {
  // 店铺物流账期列表
  getPagedList: "/api/LogisticsService/ShopAccountingPeriodConfig/GetPagedList",
  // 新增或者修改店铺物流账期
  addOrUpdate: "/api/LogisticsService/ShopAccountingPeriodConfig/AddOrUpdate",
  // 根据id获取店铺物流账期
  getById: "/api/LogisticsService/ShopAccountingPeriodConfig/GetById",
  // 根据id删除店铺物流账期配置
  deleteShopAccountingPeriodConfig: "/api/LogisticsService/ShopAccountingPeriodConfig/Delete",
  // 获取有店铺可配置的账期物流渠道
  getLogisticsOptionList: "/api/LogisticsService/ShopAccountingPeriodConfig/GetLogisticsOptionList",
};

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: "POST",
  });
};

export const addOrUpdate = (data) => {
  return client.request({
    url: Api.addOrUpdate,
    data,
    method: "POST",
  });
};

export const getById = (params) => {
  return client.request({
    url: Api.getById,
    params,
    method: "GET",
  });
};

export const deleteShopAccountingPeriodConfig = (data) => {
  return client.request({
    url: Api.deleteShopAccountingPeriodConfig,
    data,
    method: "POST",
  });
};

export const getLogisticsOptionList = (data) => {
  return client.request({
    url: Api.getLogisticsOptionList,
    data,
    method: "POST",
  });
};

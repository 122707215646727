<template>
  <Content>
    <template #contentTitle>{{ $t("menu.warehouse_monitor_return") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input
            style="width: 250px;"
            v-model:value="searchState.searchKey"
            :placeholder="
              $t('return.return_package_no') + '/' + $t('return.return_plan_no')
            "
            allowClear
          />
        </a-col>
         <a-col>
            <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
          </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.sourceType"
            style="width: 250px;"
            :placeholder="$t('return.return_of_the_source')"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in returnPlanSourceTypeList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('returnPlanSourceType', item))"
              >{{
                $t($enumLangkey("returnPlanSourceType", item))
              }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.receiveWarehouseId"
            style="width: 250px;"
            :placeholder="$t('return.the_goods_warehouse')"
            :show-search="true"
            option-filter-prop="search-key"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :value="item.id"
              :title="`${item.warehouseNo}(${item.warehouseName})`"
              :search-key="item.warehouseNo + item.warehouseName"
            >{{ item.warehouseNo }}({{ item.warehouseName }})</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.status"
            style="width: 250px;"
            :placeholder="$t('finance.status')"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in returnPlanStatusList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('returnPlanStatus', item))"
              >{{
                $t($enumLangkey("returnPlanStatus", item))
              }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.waybillOperationStatus"
            style="width: 250px;"
            :placeholder="$t('return.trajectory_status')"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in waybillOperationStatusEnumList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('waybillOperationStatus', item))"
              >{{
                $t($enumLangkey("waybillOperationStatus", item))
              }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <SearchProduct ref="searchProductRef" />
        </a-col>
        <a-col>
          <a-range-picker style="width: 250px;" @change="handleDateChange" />
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleSearch">{{ $t("common.query") }}</a-button>
        </a-col>
        <a-col>
          <a-button type="ghost" @click="handleShowPinModal(1)">{{ $t("common.export") }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 170 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #serialNumber>
          <div>{{ $t("return.return_package_no") }}</div>
          <div>{{ $t("return.return_plan_no") }}</div>
        </template>
        <template #serialNumberData="{ record }">
          <div>{{ record.packageNo }}</div>
          <div>{{ record.returnPlanNo }}</div>
        </template>
        <template #sourceType="{ record }">
          {{ $t($enumLangkey('returnPlanSourceType', record.sourceType)) }}
        </template>
        <template #deliveryType="{ record }">
          {{ $t($enumLangkey('deliveryWay', record.deliveryType)) }}
        </template>
        <template #status="{ record }">
          {{ $t($enumLangkey('returnPlanStatus', record.status)) }}
          <div >
              <small v-if="!record.rtsStatus||record.rtsStatus == rtsStatusEnum.noMatch
              && record.status != returnPlanStatusEnum.completed
              " 
              class="text-error">
                {{$t('warehouse.warehouse_not_received_goods')}}
              </small>
              <small v-else-if="record.rtsStatus && record.rtsStatus != rtsStatusEnum.noMatch" class="text-warning">
                {{$t('warehouse.warehouse_temporarily_stored_p0_days',[record.temporaryDays||0])}}
              </small>
          </div>
        </template>
        <template #newestTrack="{ record }">
          <span v-if="record.newestTrack">
            <div><small>{{ record.newestTrack?.trackRemark }}</small></div>
            <div><small>{{ $filters.utcToCurrentTime(record.newestTrack?.trackTime) }}</small></div>
          </span>
          <span v-else>-</span>
        </template>
        <template #customer="{ record }">
          {{record.shopName}}
          <div v-if="record.shopNo">
            NO: {{record.shopNo}}
          </div>
          <div >
            {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
          </div>
        </template>
        <template #warehouseNo="{ record }">
          {{ record.warehouseNo }}
        </template>
        <template #waybillOperationStatus="{ record }">
          <template v-if="record.isShowTrackOperate">
            <span v-if="record.waybillOperationStatus">{{ $t($enumLangkey('waybillOperationStatus', record.waybillOperationStatus)) }}</span>
            <span v-else>-</span>
          </template>
          <small v-else style="color: #AAAAAA;">{{ $t('return.untraceable') }}</small>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #updateTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown-button>
            <router-link :to="{ name: 'warehouse_monitor_return_detail', params: { id: record.channelReturnPlanId } }">
              {{ $t("common.details") }}
            </router-link>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :disabled="!record.isShowTrackOperate"
                             :key="1"
                             :record="record">
                  {{ $t("warehouse.check_the_track") }}
                </a-menu-item>
                <a-menu-item :disabled="record.status && record.status !== returnPlanStatusEnum.planned"
                             :key="2"
                             :record="record">
                  {{ $t("common.cancel") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>

      <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("menu.warehouse_monitor_return") }}
        </template>
        <template v-slot:no>
          {{trackModal.packageNo}}/{{trackModal.returnPlanNo}}
        </template>
      </CTrackInfoModal> 

      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>
<script>
import { defineComponent, reactive, toRefs, onActivated, ref } from "vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CSearchShop from "@/views/components/CSearchShop.vue"
import SearchProduct from "@/views/components/SearchProduct.vue"
import CPinTipsModal from '@/views/components/CPinTipsModal.vue'
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Space,
  DatePicker,
  Table,
  Dropdown,
  Menu,
  message,
} from "ant-design-vue";
import { currentTimeToUtc, dateString } from "@/utils/general";
import { useI18n } from "vue-i18n/index";
import { getWarehouses } from "@/api/modules/common";
import { getPlanPaged, exportChannelReturnPlanConsole, refundChannelReturnPlanConsole } from "@/api/modules/return";
import {
  returnPlanStatus as returnPlanStatusEnum,
  returnPlanSourceType as returnPlanSourceTypeEnum,
  warehouseType as warehouseTypeEnum,
  waybillOperationStatus as waybillOperationStatusEnum,
  rtsStatus as rtsStatusEnum
} from "@/enum/enum.json";
import { downloadFile } from '@/utils/downloader.js';
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import { getTrack } from '@/api/modules/return';

export default defineComponent({
  name: "warehouse_monitor_return",
  components: {
    CTrackInfoModal,
    ASpace: Space,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CSearchShop,
    SearchProduct,
    CPinTipsModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const searchProductRef = ref(null);

    const state = reactive({
      returnPlanSourceTypeList: [],
      waybillOperationStatusEnumList: [],
      warehouseList: [],
      returnPlanStatusList: [],
      searchState: {
        searchKey: null,
        shopId:null,
        sourceType: null,
        receiveWarehouseId: null,
        status: null,
        waybillOperationStatus: null,
        productId:null,
        startTime: null,
        endTime: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      pinModalState: {
        visible: false,
        loading: false,
        key: null,
        dataTemp: null,
      }
    });

    state.returnPlanSourceTypeList = Object.values(returnPlanSourceTypeEnum)
    state.waybillOperationStatusEnumList = Object.values(waybillOperationStatusEnum)
    state.returnPlanStatusList = Object.values(returnPlanStatusEnum)

    const columns = [
      {
        width: 170,
        fixed: "left",
        slots: {
          title: "serialNumber",
          customRender: "serialNumberData",
        },
      },
       {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 170,
      },
      {
        width: 120,
        title: vueI18n.t("return.the_goods_warehouse"),
        slots: {
          customRender: "warehouseNo"
        }
      },
      {
        width: 100,
        title: vueI18n.t("return.return_of_the_source"),
        slots: {
          customRender: "sourceType"
        }
      },
      {
        width: 80,
        title: vueI18n.t("logistics.delivery_way"),
        slots: {
          customRender: "deliveryType"
        }
      },
      {
        dataIndex: "productNum",
        width: 80,
        title: vueI18n.t("warehouse.product_quantity"),
      },
      {
        dataIndex: "returnNum",
        width: 80,
        title: vueI18n.t("return.returns_number"),
      },
      {
        dataIndex: "receiveNum",
        width: 80,
        title: vueI18n.t("return.received_quantity"),
      },
      {
        width: 140,
        title: vueI18n.t("finance.status"),
        slots: {
          customRender: "status"
        }
      },
      {
        width: 200,
        title: vueI18n.t("warehouse.trajectory"),
        slots: {
          customRender: "newestTrack",
        },
      },
      {
        width: 80,
        title: vueI18n.t("return.trajectory_status"),
        slots: {
          customRender: "waybillOperationStatus",
        },
      },
      {
        width: 110,
        title: vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 110,
        title: vueI18n.t("logistics.last_modification_time"),
        slots: {
          customRender: "updateTime",
        },
      },
      {
        width: 100,
        fixed: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const handleDateChange = (e) => {
      let beginTime = null,
        endTime = null;
      if (e?.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.startTime = beginTime;
      state.searchState.endTime = endTime;
    };

    const handleShowPinModal = (key, dataTemp = null) => {
      state.pinModalState.key = key
      state.pinModalState.visible = true
      state.pinModalState.dataTemp = dataTemp
    }

    const handlePinModalConfirm = (pinCode) => {
      switch (state.pinModalState.key) {
        // 导出
        case 1:
          handleExport(pinCode)
          break;
        // 取消
        case 2:
          handleCancelPlan(pinCode)
          break;
        default:
          break;
      }
    }
    
    const handleExport = (pinCode) => {
      state.pinModalState.loading = true
      let url = exportChannelReturnPlanConsole();
      const data = Object.assign({}, state.searchStateCache, { pinCode });
      downloadFile(url, `returned_goods_into_storage_${dateString()}.csv`, "POST", data).then(() => {
        state.pinModalState.visible = false
        message.success(vueI18n.t('common.succeed'))
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getPlanPaged(data)
        .then(({ result }) => {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      let productId = searchProductRef.value.selectedProductId;
      if (productId) {
        state.searchStateCache.productId = productId;
      } else {
        state.searchStateCache.productId = null;
      }
      handleInitSearch();
    };

    const handleCancelPlan = (pinCode) => {
      const data = {
        id: state.pinModalState.dataTemp,
        pinCode
      }
      state.pinModalState.loading = true;
      refundChannelReturnPlanConsole(data)
        .then(() => {
          message.success(vueI18n.t("common.successfully_canceled"));
          state.pinModalState.visible = false
          getPageData();
        })
        .catch(() => {})
        .finally(() => {
          state.pinModalState.loading = false;
        });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item
      switch (key) {
        case 1:
          handleShowTrackModal(record);
          break;
        case 2:
          handleShowPinModal(key, record.channelReturnPlanId);
          break;
        default:
          break;
      }
    };

     const trackModal = reactive({
      visible: false,
      loading: false,
      planId: null,
      list: [],
      packageNo: null,
      returnPlanNo: null,
    });

    const handleShowTrackModal = (record) => {
      trackModal.visible = true;
      trackModal.planId = record.channelReturnPlanId;
      trackModal.packageNo = record.packageNo;
      trackModal.returnPlanNo = record.returnPlanNo;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrack({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    onActivated(async () => {
      try {
        let { result } = await getWarehouses({
          isActive: true,
          warehouseType: warehouseTypeEnum.consignment,
        });
        state.warehouseList = result ?? [];

        handleInitSearch();
      } catch (error) {}
    });

    return {
      ...toRefs(state),
      returnPlanStatusEnum,
      rtsStatusEnum,
      searchProductRef,
      columns,
      handleDateChange,
      handleShowPinModal,
      handlePinModalConfirm,
      handleSearch,
      handlePage,
      handleMenuClick,
      trackModal,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>{{ $t("menu.finance_customer_balance") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-select style="min-width: 250px"
                    v-model:value="searchState.debtStatus"
                    :placeholder="$t('account.whether_the_money_owed')"
                    allowClear>
            <a-select-option v-for="item in debtStatuSelects"
                             :key="item"
                             :title="$t($enumLangkey('debtStatus', item))"
                             :value="item">
              {{ $t($enumLangkey('debtStatus', item)) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-space>
            <span>{{ $t('account.account_balance') }}:</span>
            <a-input-group compact>
              <a-select allowClear
                        show-search
                        style="width: 100px"
                        optionFilterProp="search-key"
                        v-model:value="searchState.currencyId"
                        :placeholder="$t('finance.unlimited')">
                <a-select-option v-for="item in currencyList"
                                 :key="item.id"
                                 :title="`${getLanguageName(item)}(${item.symbol})`"
                                 :value="item.id"
                                 :search-key="
                    item.code + item.symbol + item.cnName + item.enName
                  ">
                  {{ getLanguageName(item) }}({{ item.symbol }})
                </a-select-option>
              </a-select>
              <a-input v-model:value="searchState.startAmount"
                       allowClear
                       style="width: 120px; text-align: center"
                       :min="0"
                       :placeholder="$t('finance.minimum')"
                       @blur="handleSearchAmountBlur" />
              <a-input style="
                  width: 30px;
                  border-left: 0;
                  pointer-events: none;
                  background-color: #fff;
                "
                       placeholder="~"
                       disabled />
              <a-input v-model:value="searchState.endAmount"
                       allowClear
                       style="width: 120px; text-align: center; border-left: 0"
                       :min="0"
                       :placeholder="$t('finance.highest')"
                       @blur="handleSearchAmountBlur" />
            </a-input-group>
          </a-space>
        </a-col>
        <a-col>
          <a-space>
            <a-button type="primary"
                      :loading="tableData.loading"
                      @click="handleSearch">{{ $t("common.search") }}</a-button>
            <a-button type="ghost"
                      @click="handleShowPinModal">{{ $t('common.export') }}</a-button>
          </a-space>
        </a-col>
      </a-row>
      <a-table class="mt-3"
               :columns="columns"
               :data-source="tableData.list"
               :scroll="{ x: 800, y: wrap.contentHeight - 110 }"
               :pagination="false"
               :rowKey="
          (record, index) => {
            return index;
          }
        "
               size="small"
               :loading="tableData.loading">
        <template #shop="{ record }">
          <div>{{ record.shopName }}</div>
          <div>NO: {{ record.shopNo ??'-'}}</div>
          <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
        </template>

        <template #currency="{ record }">
          {{ record.currencyCode }} {{ record.currencySymbol }}
        </template>
        <template #balance="{ record }">
          <span :class="record.amount >= 0 ? 'text-success' : 'text-error'">
            {{ $filters.amountToFixed2(record.amount) }}
          </span>
        </template>

        <template #overdraftLimit="{ record }">
          <span :class="record.overdraftFacility > 0 ? 'text-error' : 'text-success'">
            {{ $filters.amountToFixed2(record.overdraftFacility) }}
          </span>
        </template>

        <template #freezeOverdraftFacility="{ record }">
          <span :class="record.freezeOverdraftFacility > 0 ? 'text-error' : 'text-success'">
            {{ $filters.amountToFixed2(record.freezeOverdraftFacility) }}
          </span>
        </template>

        <template #totalRechargeAmount="{ record }">
          {{ $filters.amountToFixed2(record.totalRechargeAmount) }}
        </template>

        <template #totalTransactionAmount="{ record }">
          {{ $filters.amountToFixed2(record.totalTransactionAmount) }}
        </template>

        <template #paymentDays="{ record }">
          {{ record.paymentDays }}{{ $t('common.days') }}
        </template>

        <template #isLogisticsArrivePay="{ record }">
          {{ record.isLogisticsArrivePay ? $t('common.yes') : $t('common.no') }}
        </template>

        <template #rxpireDate="{ record }">
          {{ $filters.utcToCurrentTime(record.overdraftDueDateTime) }}
        </template>
        <template #overdraftStatus="{ record }">
          <span :class="
              record.overdraftStatus == overdraftStatusEnum.normal
                ? 'text-success'
                : 'text-error'
            ">
            {{ $t($enumLangkey("overdraftStatus", record.overdraftStatus)) }}
          </span>
          <span v-if="record.lastMonthOwingMoney < 0"
                class="text-error">
            ({{ $t("account.amount") }}: {{ record.currencySymbol
            }}{{ $filters.amountToFixed2(record.lastMonthOwingMoney * -1) }})
          </span>
        </template>
      </a-table>
      <CPinTipsModal v-model:visible="pinModalState.visible"
                     v-model:loading="pinModalState.loading"
                     @confirm="handleDownloadExcel" />
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="center"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="tableData.pageData">
          </CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import {
  Button,
  Col,
  Space,
  Input,
  Row,
  Select,
  Table,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CSearchShop from "@/views/components/CSearchShop.vue"
import CPinTipsModal from "@/views/components/CPinTipsModal.vue"
import { useI18n } from "vue-i18n/index";
import { getCurrencys } from "@/api/modules/common/index";
import { getListPaged } from "@/api/modules/finance/customerBalance";
import { exportListPaged } from "@/api/modules/finance/index";
import { useStore } from "vuex";
import { overdraftStatus as overdraftStatusEnum, debtStatus as debtStatusEnum } from "@/enum/enum.json";
import { getName, dateString } from "@/utils/general";
import { downloadFile } from "@/utils/downloader";

export default defineComponent({
  name: "finance_customer_balance",
  components: {
    ASpace: Space,
    ARow: Row,
    ACol: Col,
    AInputGroup: Input.Group,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
    CSearchShop,
    CPinTipsModal,
  },
  setup () {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const debtStatuSelects = [20,30]

    const state = reactive({
      currencyList: [],
      searchState: {
        shopId: null,
        debtStatus: null,
        currencyId: null,
        startAmount: null,
        endAmount: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        list: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      pinModalState: {
        visible: false,
        loading: false,
      }
    });

    const columns = [
      {
        title: () => vueI18n.t("warehouse.customer"),
        width: 150,
        slots: {
          customRender: "shop",
        },
      },
      {
        align: "center",
        title: () => vueI18n.t("finance.currency"),
        width: 100,
        slots: {
          customRender: "currency",
        },
      },
      {
        align: "center",
        title: () => vueI18n.t("account.account_balance"),
        width: 100,
        slots: {
          customRender: "balance",
        },
      },

      {
        align: "center",
        title: () => vueI18n.t("account.overdraft_limit"),
        width: 100,
        slots: {
          customRender: "overdraftLimit",
        },
      },
      {
        align: "center",
        title: () => vueI18n.t("account.freeze"),
        width: 100,
        slots: {
          customRender: "freezeOverdraftFacility",
        },
      },
      {
        align: "center",
        title: () => vueI18n.t("account.total_top_up"),
        width: 100,
        slots: {
          customRender: "totalRechargeAmount",
        },
      },
      {
        align: "center",
        title: () => vueI18n.t("account.total_consumption"),
        width: 100,
        slots: {
          customRender: "totalTransactionAmount",
        },
      },

      {
        align: "center",
        title: () => vueI18n.t("finance.accounting_period"),
        width: 80,
        slots: {
          customRender: "paymentDays",
        },
      },
      {
        align: "center",
        title: () => vueI18n.t("logistics.pay_on_arrival"),
        width: 80,
        slots: {
          customRender: "isLogisticsArrivePay",
        },
      },
      {
        align: "center",
        title: () => vueI18n.t("account.rxpire_date"),
        width: 100,
        slots: {
          customRender: "rxpireDate",
        },
      },

      {
        align: "center",
        title: () => vueI18n.t("account.overdraft_status"),
        dataIndex: "overdraftStatus",
        width: 170,
        slots: {
          customRender: "overdraftStatus",
        },
      },
    ];

    const handleSearchAmountBlur = () => {
      let startAmount = parseFloat(state.searchState.startAmount)
      let endAmount = parseFloat(state.searchState.endAmount)
      if (Number.isNaN(startAmount)) {
        state.searchState.startAmount = null
      }
      if (Number.isNaN(endAmount)) {
        state.searchState.endAmount = null
      }
      if (!Number.isNaN(startAmount) && !Number.isNaN(endAmount)) {
        if (startAmount > endAmount) {
          state.searchState.startAmount = endAmount
          state.searchState.endAmount = startAmount
        }
      }
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const getData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.searchStateCache,
        state.tableData.pageData
      );
      getListPaged(data)
        .then(({ result }) => {
          if (result) {
            let { items = [], totalCount = 0 } = result;
            state.tableData.list = items;
            state.tableData.pageData.totalCount = parseInt(totalCount);
          }
        })
        .catch(() => { })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getData();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const getCurrencyList = () => {
      getCurrencys().then(({ result }) => {
        if (Array.isArray(result)) {
          state.currencyList = result;
        }
      });
    };

    const handleShowPinModal = () => {
      Object.assign(state.searchStateCache, state.searchState);
      state.pinModalState.visible = true
    }

    const handleDownloadExcel = (pinCode) => {
      state.pinModalState.loading = true
      let url = exportListPaged();
      const data = Object.assign({}, state.searchStateCache, { pinCode })
      downloadFile(url, `customer_balance_${dateString()}.csv`, "POST", data).then(() => {
        state.pinModalState.visible = false
      }).catch(() => { }).finally(() => {
        state.pinModalState.loading = false
      })
    }

    onMounted(() => {
      getCurrencyList();
      handleInitPage();
    });

    return {
      ...toRefs(state),
      overdraftStatusEnum,
      debtStatusEnum,
      columns,
      handlePage,
      handleSearch,
      getLanguageName,
      handleSearchAmountBlur,
      handleShowPinModal,
      handleDownloadExcel,
      debtStatuSelects
    };
  },
});
</script>

// import { onUnmounted, ref } from "vue"
 import { nameKeys } from "../consts";
import moment from "moment-timezone";
import { extensionKeys } from "../consts/index";

export const isProduction = () => {
  // return import.meta.env.NODE_ENV === 'production'
  return process.env === "production";
};

export const findPathById = (arr, id) => {
  if (arr && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      if (item.id === id) {
        return item.path;
      } else {
        if (item.children && item.children > 0) {
          let path = findPathById(item.children, id);
          if (path) {
            return path;
          }
        }
      }
    }
  }
  return null;
};

export const findItemById = (arr, id) => {
  if (arr && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      if (item.id === id) {
        return item;
      } else {
        if (item.children && item.children > 0) {
          let subItem = findItemById(item.children, id);
          if (subItem) {
            return subItem;
          }
        }
      }
    }
  }
  return null;
};

export const findParents = (menuArr, menuPath, includeSelf = false) => {
  let temp = [];
  var eachFind = function (list, path) {
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.path === path) {
        let parentPath = null;
        if (item.parent) {
          parentPath = findPathById(menuArr, item.parent);
        }
        if (includeSelf) {
          temp.push(item.path);
        } else {
          if (parentPath) {
            temp.push(parentPath);
          }
        }
        if (parentPath && temp.indexOf(parentPath) < 0) {
          eachFind(menuArr, parentPath);
        }
      } else {
        if (item.children) {
          eachFind(item.children, path);
        }
      }
    }
  };
  eachFind(menuArr, menuPath);
  return temp;
};

export const getLocalLang = (defaultLang) => {
  return localStorage.getItem(extensionKeys.confLang)
    ? localStorage.getItem(extensionKeys.confLang)
    : defaultLang;
};

export const maxUploadFileSizeByte = (defaultMB=50) => {
  return defaultMB*1024*1024
 };




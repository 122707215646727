<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.finance_accounting_cost") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <a-row :gutter="[0, 8]">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.transport_plan_name") }}: {{ plan.name }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.transportation_plan_number") }}: {{ plan.no }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.relevance_out_no") }}:
                {{ plan.outWarehouseNo || "-" }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.relevance_in_no") }}:
                {{ plan.inWarehouseNo || plan.toInWarehouseNo || "-" }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.creation_time") }}:
                {{ $filters.utcToCurrentTime(plan.creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.last_modification_time") }}:
                {{ $filters.utcToCurrentTime(plan.updateTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.transport_route") }}:
                {{ $t($enumLangkey("transportRoutes", plan.transportRoutes)) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.destination_type") }}:
                {{ $t($enumLangkey("destinationType", plan.toWarehouseType)) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.recipients") }}: {{ plan.receiptUserName }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.phone") }}: {{ plan.receiptContactPhone }}
              </a-col>
              <a-col :span="24">
                {{ $t("account.operator") }}:
                <span v-if="plan.employee">{{
                  getLanguageFullName(plan.employee)
                }}</span>
                <span v-else-if="plan.operatorName">{{
                  plan.operatorName
                }}</span>
                <span v-else>-</span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row
              :gutter="[0, 8]"
              style="height: 100%"
              type="flex"
              align="middle"
            >
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center">
                  <a-typography-text strong>{{
                    $t("finance.status")
                  }}</a-typography-text>
                </div>
                <div style="text-align: center">
                  <a-typography-text
                    type="secondary"
                    v-if="plan.planStatus === transportStatusEnum.canceled"
                    >{{
                      $t($enumLangkey("transportStatus", plan.planStatus))
                    }}</a-typography-text
                  >
                  <a-typography-text type="success" v-else>{{
                    $t($enumLangkey("transportStatus", plan.planStatus))
                  }}</a-typography-text>
                </div>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center">
                  <a-typography-text strong>{{
                    $t("logistics.accounting_status")
                  }}</a-typography-text>
                </div>
                <div style="text-align: center">
                  <a-typography-text
                    type="success"
                    v-if="plan.isCostEstimates"
                    >{{ $t("logistics.have_accounting") }}</a-typography-text
                  >
                  <a-typography-text type="danger" v-else>{{
                    $t("logistics.not_accounting")
                  }}</a-typography-text>
                </div>
              </a-col>
              <a-col :span="24">
                <div style="text-align: center">
                  <a-button type="primary" @click="handleReloadCheck">{{
                    $t("logistics.reload_check")
                  }}</a-button>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <template v-if="plan.shopData">
            <a-col :md="8" :sm="24" :xs="24">
              {{ $t("warehouse.customer") }}:
              <span v-if="plan.shopData.shopName">{{
                plan.shopData.shopName || "-"
              }}</span>
              <span v-else>-</span>
              <span
                class="text-warning pl-1"
                v-if="
                  plan.shopData.expressDiscount &&
                  plan.shopData.expressDiscount !== 100
                "
                >({{ $t("logistics.discount") }}:
                {{ plan.shopData.expressDiscount }}%)</span
              >
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              {{ $t("common.shop_mark") }}:
              <span v-if="plan.shopData.shopMark">{{
                plan.shopData.shopMark || "-"
              }}</span>
              <span v-else>-</span>
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              NO:
              <span v-if="plan.shopData.shopNo">{{
                plan.shopData.shopNo || "-"
              }}</span>
              <span v-else>-</span>
            </a-col>
          </template>
          <a-col
            :md="8"
            :sm="24"
            :xs="24"
            v-if="plan.toWarehouseType == destinationTypeEnum.FBA"
          >
            {{ $t("warehouse.fba_warehouse_code") }}: {{ plan.toWarehouseCode }}
          </a-col>
          <a-col
            :md="8"
            :sm="24"
            :xs="24"
            v-else-if="plan.toWarehouseType == destinationTypeEnum.SeWarehouse"
          >
            {{ $t("logistics.warehouse_code") }}: {{ plan.toWarehouseCode }}
          </a-col>
          <a-col
            :md="24"
            :sm="24"
            :xs="24"
            v-if="
              !plan.toWarehouseType ||
              plan.toWarehouseType == destinationTypeEnum.OtherAddress
            "
          >
            {{ $t("warehouse.transport_address") }}:
            {{ getAddress(plan.toAddressData) }}
          </a-col>
          <a-col :md="16" :sm="24" :xs="24" v-else>
            {{ $t("warehouse.transport_address") }}:
            {{ getAddress(plan.toAddressData) }}
            <span
              v-if="
                plan.toAddressData &&
                plan.toWarehouseType == destinationTypeEnum.SeWarehouse
              "
            >
              ({{ getLinkman(plan.toAddressData) }})
            </span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("logistics.originate") }}: {{ plan.fromAddressData.fromName }}
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            {{ $t("logistics.shipments_address") }}:
            {{ getAddress(plan.fromAddressData) }}
            <a-tag
              color="warning"
              v-if="plan.toAddressData.isInternationalTransport"
            >
              {{ $t("logistics.international_transport") }}</a-tag
            >
            <a-tag
              color="default"
              class="ml-3"
              style="min-height: 32px"
              v-if="plan.isSelectDoorTake"
            >
              <a-row class="mt-1">
                <a-col>
                  <a-tag color="success">{{ $t("logistics.door_took") }}</a-tag>
                </a-col>
                <a-col class="ml-3"
                  >{{ $t("logistics.contacts") }}:{{
                    plan.doorLinkUserName
                  }}</a-col
                >
                <a-col class="ml-3 mr-2"
                  >{{ $t("account.phone") }}:{{ plan.doorContactPhone }}</a-col
                >
              </a-row>
            </a-tag>
          </a-col>
          <a-col :span="24">
            {{ $t("logistics.logistics_center_harvest_address") }}
            <c-tooltip :title="$t('logistics.logistics_select_hint')" />:
            {{ getAddress(plan.seLogisticsAddress) }}
            <span v-if="plan.seLogisticsContact && plan.seLogisticsPhone"
              >({{ plan.seLogisticsContact }},
              {{ plan.seLogisticsPhone }})</span
            >
          </a-col>
          <a-col :md="8" :sm="24" :xs="24" v-if="plan.fbaTransportNo">
            <span v-if="plan.toWarehouseType == destinationTypeEnum.FBA">{{
              $t("logistics.amazon_shipping_number")
            }}</span>
            <span
              v-else-if="
                plan.toWarehouseType == destinationTypeEnum.mercadolibre
              "
              >{{ $t("logistics.seller_id") }}</span
            >
            <span>: {{ plan.fbaTransportNo }}</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24" v-if="plan.productTrackNo">
            <span v-if="plan.toWarehouseType == destinationTypeEnum.FBA">{{
              $t("logistics.shipment_tracking_number")
            }}</span>
            <span
              v-else-if="
                plan.toWarehouseType == destinationTypeEnum.mercadolibre
              "
              >{{ $t("warehouse.warehousing_number") }}</span
            >
            <span>: {{ plan.productTrackNo }}</span>
          </a-col>
        </a-row>
        <!-- 箱表格 -->
        <div class="mt-3">
          <a-table
            style="overflow-x: auto; min-height: 223px"
            :columns="plan.boxTableColumns"
            :data-source="plan.producTableList"
            size="small"
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
            :pagination="{
              defaultPageSize: defaultPageSize,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            :row-key="(record) => record.planDetailId"
            @change="
              (page) => {
                currentPageIndex = page.current;
              }
            "
          >
            <template #encasementCount>
              <span>
                {{ $t("warehouse.encasement_count") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_1')" />
              </span>
            </template>
            <template #encasementInfo>
              <span>
                {{ $t("warehouse.encasement_info") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_2')" />
              </span>
            </template>
            <template #transportInfo>
              <span>
                {{ $t("logistics.transport_info") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_3')" />
              </span>
            </template>
            <template #boxNo="{ record }">
              <div :style="record.isCancel ? 'color: #CCCCCC;' : ''">
                {{ record.boxNo }}
                <CTagMixedOrSingle
                  :productKindCount="record.productKindCount * 1"
                ></CTagMixedOrSingle>
              </div>
              <div>
                <small>{{ record.selfBoxNo || "-" }}</small>
              </div>
              <div v-if="record.isCancel">
                <a-tag color="red">{{ $t("logistics.canceled") }}</a-tag>
              </div>
            </template>
            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation
                :open="expanded"
                @click="(e) => onExpand(record, e)"
              />
            </template>

            <template #encasementCountCustom="{ record }">
              {{ record.containerCount }} Unit
            </template>

            <template #encasementInfoCustom="{ record }">
              <span
                v-if="
                  !record.packBoxInfo ||
                  record.packBoxInfo.length <= 0 ||
                  record.packBoxInfo.width <= 0 ||
                  record.packBoxInfo.height <= 0
                "
              >
                -
              </span>
              <span v-else>
                {{ record.packBoxInfo.length }}x{{
                  record.packBoxInfo.width
                }}x{{ record.packBoxInfo.height }}cm
              </span>
              <div>
                <span v-if="record.packBoxInfo && record.packBoxInfo.weight"
                  >{{ gToKg(record.packBoxInfo.weight) }}kg</span
                >
                <span v-else>-</span>
              </div>
            </template>

            <template #transportInfoCustom="{ record }">
              <a-row :gutter="[8, 8]" type="flex" align="middle">
                <a-col>
                  <span v-if="!record.isCancel">
                    <span
                      v-if="
                        !record.transportBoxInfo ||
                        record.transportBoxInfo.length <= 0 ||
                        record.transportBoxInfo.width <= 0 ||
                        record.transportBoxInfo.height <= 0
                      "
                    >
                      -
                    </span>
                    <span v-else>
                      {{ record.transportBoxInfo.length }}x{{
                        record.transportBoxInfo.width
                      }}x{{ record.transportBoxInfo.height }}cm
                    </span>
                    <div>
                      <span
                        v-if="
                          record.transportBoxInfo &&
                          record.transportBoxInfo.weight
                        "
                        >{{ gToKg(record.transportBoxInfo.weight) }}kg</span
                      >
                      <span v-else>-</span>
                    </div>
                  </span>
                </a-col>
                <a-col>
                  <a-tag color="#f50">
                    <template v-if="record.logisticsCalcMethod === 0">
                      {{
                        record.isVolumeWeight
                          ? $t("logistics.volume_weight")
                          : $t("logistics.actual_weight")
                      }}
                    </template>
                    <template v-else>
                      {{
                        record.isDensityVolume
                          ? $t("logistics.density_volume")
                          : $t("logistics.actual_volume")
                      }}
                    </template>
                  </a-tag>
                </a-col>
              </a-row>
            </template>

            <template #checkPictureCustom="{ record }">
              <div
                v-if="
                  record.checkImgUrls &&
                  record.checkImgUrls.length > 0 &&
                  !record.isCancel
                "
              >
                <a-popover>
                  <template #content>
                    <a-space>
                      <div
                        v-for="(imgItem, index) in record.checkImgUrls"
                        class="table-list-img-common"
                        :key="index"
                      >
                        <c-image :src="imgItem" :dis-preview-process="true" />
                      </div>
                    </a-space>
                  </template>
                  <span class="text-primary" style="font-size: 20px">
                    <FileImageOutlined />
                  </span>
                </a-popover>
              </div>
            </template>

            <template #footer>
              <a-row type="flex" justify="space-between" align="middle">
                <a-col>
                  <a-row type="flex" justify="start" class="mr-5" :gutter="32">
                    <a-col
                      >{{ $t("logistics.total") }}: {{ plan.boxTotalCount
                      }}{{ $t("warehouse.box") }}</a-col
                    >
                    <a-col
                      >{{ $t("logistics.total_volume_of_transport") }}:
                      {{ plan.transportTotalVolume }}m<sup>3</sup></a-col
                    >
                    <a-col
                      >{{ $t("logistics.gross_shipping_weight") }}:
                      {{ plan.transportTotalWeight }}kg</a-col
                    >
                    <a-col
                      >{{ $t("warehouse.total_volume") }}:
                      {{ plan.chargeableVolume || "-" }}m<sup>3</sup></a-col
                    >
                    <a-col
                      >{{ $t("warehouse.total_encasement_weight") }}:
                      {{ plan.chargeableWeight || "-" }}kg</a-col
                    >

                    <a-col>
                      {{ $t("logistics.transport_characteristics") }}:
                      <a-tag
                        color="processing"
                        v-for="item in plan.transportTotalCharacteristic"
                        :key="item"
                      >
                        {{ $t($enumLangkey("transportCharacteristics", item)) }}
                      </a-tag>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col>
                  <a-row
                    v-if="
                      plan.logisticsCalcMethod == logisticsCalcMethodEnum.weight
                    "
                  >
                    <a-col v-if="plan.isPallet">{{
                      $t("logistics.selected_supporting_service")
                    }}</a-col>
                    <a-col class="ml-3 mr-3 text-error"
                      >{{ $t("logistics.chargeable_total_weight") }}:
                      {{ plan.chargeableWeight ?? 0 }}kg
                      <span v-if="plan.isPallet">
                        ({{ $t("logistics.selected_supporting") }})</span
                      >
                    </a-col>
                  </a-row>
                  <a-row v-else>
                    <a-col v-if="plan.isPallet">{{
                      $t("logistics.selected_supporting_service")
                    }}</a-col>
                    <a-col class="ml-3 mr-3 text-error"
                      >{{ $t("logistics.chargeable_total_volume") }}:
                      {{ plan.chargeableVolume ?? 0 }}m³
                      <span v-if="plan.isPallet">
                        ({{ $t("logistics.selected_supporting") }})</span
                      >
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </template>

            <template #expandedRowRender="{ record }">
              <a-table
                :columns="plan.innerColumns"
                :data-source="record.productInfos"
                :pagination="false"
                :row-key="(record) => record.id"
                size="small"
              >
                <template #productInfo="{ record }">
                  <a-row :gutter="8" type="flex" align="middle">
                    <a-col>
                      <div class="table-list-img-common">
                        <c-image
                          :src="record.productImgUrl"
                          :thumbWidth="600"
                          :thumbHeight="600"
                        />
                      </div>
                    </a-col>
                    <a-col flex="1">
                      <div>{{ record.productName }}</div>
                      <div>{{ record.productNo }}</div>
                    </a-col>
                  </a-row>
                </template>
                <template #containerCount="{ record }">
                  {{ record.containerCount }} Unit
                </template>
                <template #restsCustom="{ record }">
                  <a-row v-if="!record.isCancel">
                    <a-col :span="24">
                      <a-row type="flex" justify="space-between">
                        <a-col :span="12">
                          {{ $t("warehouse.chinese_trade_name") }}:
                          {{ record.customsInfo?.brandCName ??"-"}}
                        </a-col>
                        <a-col :span="12">
                          {{ $t("warehouse.english_trade_name") }}:
                          {{ record.customsInfo?.brandEnName ??"-"}}
                        </a-col>
                      </a-row>
                    </a-col>

                    <a-col :span="24">
                      <a-row type="flex" justify="space-between">
                        <a-col :span="12">
                          HS Code: {{ record.customsInfo?.hsCode ??"-"}}
                        </a-col>
                        <a-col :span="12">
                          {{ $t("warehouse.declare_price") }}:
                          <span v-if="record.customsInfo?.currencySymbol">
                          {{
                            $filters.formatCurrencyAmount(
                              record.customsInfo?.currencySymbol,
                              record.customsInfo?.totalPrice,
                              false
                            )
                          }}
                          </span>
                          <span v-else>-</span>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
              </a-table>
            </template>
          </a-table>
        </div>
        <!-- 清关信息汇总 -->
        <div v-if="plan.toAddressData.isInternationalTransport">
          <div class="mt-3 mb-3">
            <strong>{{
              $t("logistics.summary_customs_clearance_information")
            }}</strong>
          </div>
          <a-table
            :columns="customsInfoColumns"
            :data-source="customsInfo"
            :scroll="{ x: 800 }"
            size="small"
            :pagination="false"
          >
            <template #productNameAndSeSku>
              <span>
                {{ $t("warehouse.product_name") }}/{{
                  $t("warehouse.product_no")
                }}
              </span>
            </template>

            <template #productNameAndSeSkuCustom="{ record }">
              <div>
                {{ record.productName }}
              </div>
              <div>
                {{ record.productNo }}
              </div>
            </template>

            <template #tradeNameCustom="{ record }">
              <div>
                {{ record.enTradeNameCustom }}
              </div>
              <div>
                {{ record.chTradeNameCustom }}
              </div>
            </template>
            <template #averagePrice="{ record }">
              {{ record.currencySymbol
              }}{{ $filters.amountToFixed2(record.averagePrice) }}
            </template>
            <template #totalPrice="{ record }">
              {{ record.currencySymbol
              }}{{ $filters.amountToFixed2(record.totalPrice) }}
            </template>
            <template #footer>
              <a-row type="flex" justify="end" class="mr-5" :gutter="32">
                <a-col
                  >{{ $t("common.total") }}:{{ customsSymbol
                  }}{{ $filters.amountToFixed2(customsTotalFee) }}</a-col
                >
              </a-row>
            </template>
          </a-table>
        </div>
        <!-- 物流方案 -->
        <div class="mt-3 mb-3">
          <p>
            <strong>{{ $t("menu.logistics_channel") }}</strong>
          </p>
          <div>
            <a-table
              :columns="logistics.columnsLogisticsScheme"
              :data-source="logistics.logisticsSchemeList"
              :scroll="{ x: true }"
              :pagination="false"
            >
              <template #logisticsScheme>
                <span
                  >{{ $t("logistics.logistics_scheme") }} /
                  {{ $t("logistics.logistics_code") }}</span
                >
              </template>

              <template #logisticsWay>
                <span>
                  {{ $t("logistics.logistics_type") }}
                </span>
              </template>

              <template #deliveryWay>
                <span>
                  {{ $t("logistics.delivery_way") }}
                </span>
              </template>

              <template #referenceAging>
                <span>
                  {{ $t("logistics.reference_aging") }}
                </span>
              </template>

              <template #details>
                <span>
                  {{ $t("common.details") }}
                </span>
              </template>
              <template #optionalServices>
                <span>
                  {{ $t("logistics.selected_service") }}
                </span>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="yellow-words">
                  {{ record.scheme }} / {{ record.code }}
                </div>
                <a-row :gutter="[8, 4]">
                  <a-col v-for="item in record.descriptionItems" :key="item">
                    <CheckCircleOutlined class="yellow-words" /><span
                      class="ml-2"
                      >{{ item }}</span
                    >
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{ record.daysMin }}-{{ record.daysMax }}
                  {{ $t("logistics.workday") }}
                  ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div>
                  <span
                    v-if="
                      record.logisticsCalcMethod ==
                      logisticsCalcMethodEnum.weight
                    "
                  >
                    {{ $t("logistics.chargeable_weight") }}:
                    {{ record.chargeableWeight }}kg
                  </span>
                  <span v-else>
                    {{ $t("logistics.chargeable_volume") }}:
                    {{ record.chargeableVolume }}m³
                  </span>
                </div>
                <div>
                  {{ $t("logistics.volume_weight_coefficient")
                  }}{{ record.volumeWeightParam }}
                </div>
              </template>
              <template #optionalServicesCustom="{ record }">
                <div v-if="record.logisticsRegionInfo.isChooseDdp">DDP</div>
                <div v-if="record.logisticsRegionInfo.isChooseSign">
                  {{ $t("logistics.collect_signature_fee") }}
                </div>
              </template>
              <!-- 卡车专属 -->
              <template #quoteOptionsTitle>
                {{ $t("logistics.quote_options") }}
                <CTooltip :title="$t('logistics.quote_options_tips')" />
              </template>
              <template #quoteOptions="{ record }">
                <a-row :gutter="[16, 8]">
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{
                        $t("logistics.quote_type")
                      }}</a-col>
                      <a-col flex="1">
                        <span>{{ record.truckBaseInfo.quoteType?.name }}</span>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24" v-if="record.truckBaseInfo.serviceLevel">
                    <a-row type="flex">
                      <a-col flex="100px">{{
                        $t("logistics.service_level")
                      }}</a-col>
                      <a-col flex="1">
                        <template>
                          <span>{{
                            record.truckBaseInfo.serviceLevel.name
                          }}</span>
                          <span>-</span>
                        </template>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{
                        $t("logistics.additional_options")
                      }}</a-col>
                      <a-col flex="1">
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="max-width: 300px">
                              {{
                                getAccessorialsName(
                                  record.truckBaseInfo.accessorials
                                )
                              }}
                            </div>
                          </template>
                          <div
                            style="
                              max-width: 250px;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            "
                          >
                            {{
                              getAccessorialsName(
                                record.truckBaseInfo.accessorials
                              )
                            }}
                          </div>
                        </a-popover>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </template>
              <template #quoteDetails="{ record }">
                <span v-if="record.truckBaseInfo"
                  >{{ record.truckBaseInfo.carrierName }} [{{
                    $filters.formatCurrencyAmount(
                      record.truckBaseInfo.currencySymbol,
                      record.truckBaseInfo.totalCharge,
                      false
                    )
                  }},
                  {{ handleParseTime(record.truckBaseInfo.transitTime) }}
                  day(s)]</span
                >
                <span v-else>-</span>
              </template>
            </a-table>
          </div>
        </div>

        <div class="mt-4" v-if="plan.toAddressData.isInternationalTransport">
          <a-form-item v-if="logistics.isShowVATInput">
            <template #label>
              <div style="width: 150px">
                <label>VAT</label>
              </div>
            </template>
            {{ logistics.VAT || "-" }}
          </a-form-item>
          <a-form-item>
            <template #label>
              <div style="width: 150px">
                <label>{{ $t("logistics.purchase_insurance") }}</label>
              </div>
            </template>
            {{
              plan.isInsure === true
                ? $t("common.necessary")
                : plan.isInsure === false
                ? $t("common.unnecessary")
                : "-"
            }}
          </a-form-item>
        </div>

        <!-- 报关方式 -->
        <div v-if="isUploadHsInfo">
          <p>
            {{ $t("logistics.clearance_way") }}:
            {{ $t("logistics.ordinary_trade_clearance") }}
          </p>
        </div>
        <!-- 费用 -->
        <a-list class="mt-3" bordered :data-source="cost">
          <template #renderItem="{ item, index }">
            <a-list-item v-if="index == 0">
              <a-row style="width: 100%">
                <a-col :span="6" v-for="(i, index) in item" :key="index"
                  >{{ $t(i.name) }}: {{ i?.value1 }}{{ i?.value2 }}</a-col
                >
              </a-row>
            </a-list-item>
            <a-list-item v-else-if="index == 2">
              <span class="text-error"
                >{{ $t("logistics.total_income") }}: {{ item.value1
                }}{{ $filters.amountToFixed2(item.value2) }}</span
              >
            </a-list-item>
            <a-list-item v-else>
              <a-row style="width: 100%" :gutter="[0, 8]">
                <a-col
                  :xxl="6"
                  :xl="6"
                  :lg="7"
                  :md="8"
                  :sm="8"
                  v-for="(i, index) in item"
                  :key="index"
                >
                  {{ $t($enumLangkey("orderLogisticsFee", i.feeType)) }}:
                  {{ i?.currencySymbol
                  }}{{ $filters.amountToFixed2(i?.amount) }}</a-col
                >
              </a-row>
            </a-list-item>
          </template>
        </a-list>

        <!-- 已分配供应渠道 -->
        <div class="mt-3">
          <a-row>
            <a-col :sm="12" :lg="8">
              <a-form-item
                v-if="
                  supplyChannels.logisticsCalcMethod ==
                  logisticsCalcMethodEnum.weight
                "
                :label="$t('logistics.chargeable_total_weight')"
                class="mb-3"
              >
                {{ supplyChannels.chargeableWeight }}kg
              </a-form-item>
              <a-form-item
                v-else
                :label="$t('logistics.chargeable_total_volume')"
                class="mb-3"
              >
                {{ supplyChannels.chargeableVolume }}m³
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <!-- 修改费用 -->
        <a-list bordered :data-source="amendCost">
          <template #renderItem="{ item, index }">
            <a-list-item v-if="index == 0">
              <a-row style="width: 100%">
                <a-col
                  :xxl="6"
                  :xl="6"
                  :lg="7"
                  :md="8"
                  :sm="8"
                  v-for="(i, index) in item"
                  :key="index"
                >
                  <a-form-item
                    v-if="index == 0"
                    :label="$t(i.name)"
                    class="mb-0"
                  >
                    {{ i?.value1 }}{{ i?.value2 }}
                  </a-form-item>
                  <a-form-item
                    v-else-if="index == 1"
                    :label="$t(i.name)"
                    class="mb-0"
                  >
                    <!-- <a-input-group style="width: 150px">
                      <span class="ant-input-group-addon">{{ i.value1 }}</span>
                      <a-input-number
                        class="input-group-mid-number-end"
                        type="number"
                        :precision="2"
                        :defaultValue="0"
                        v-model:value="i.value2"
                      ></a-input-number>
                    </a-input-group> -->
                    {{ i.value1 }}{{ i.value2 }}
                  </a-form-item>
                  <a-form-item v-else :label="$t(i.name)" class="mb-0">
                    {{ i.value1 }}{{ i.value2 }}
                  </a-form-item>
                </a-col>
              </a-row>
            </a-list-item>
            <a-list-item v-else-if="index == 2">
              <span class="text-error"
                >{{ $t("logistics.cost_of_combined") }}:
                {{ item.value1 }}
                {{ $filters.amountToFixed2(item.value2) }}
              </span>
            </a-list-item>
            <a-list-item v-else-if="index == 1 && item.length > 0">
              <a-row style="width: 100%" :gutter="[0, 8]">
                <a-col
                  :xxl="6"
                  :xl="6"
                  :lg="7"
                  :md="8"
                  :sm="8"
                  v-for="(i, index) in item"
                  :key="index"
                >
                  {{ $t($enumLangkey("orderLogisticsFee", i.feeType)) }}:
                  {{ i?.currencySymbol
                  }}{{ $filters.amountToFixed2(i?.amount) }}
                </a-col>
              </a-row>
            </a-list-item>
          </template>
        </a-list>

        <div class="text-error mt-3">
          {{ $t("logistics.profit") }}:
          <span v-if="profit < 0">-</span>
          <span v-if="amendCost.length > 0">
            {{ amendCost[0][0].value1
            }}{{ $filters.amountToFixed2(Math.abs(profit)) }}
          </span>
        </div>

        <div class="mt-3 mb-3">
          <a-row type="flex" justify="center">
            <a-col>
              <a-button type="primary" @click="handleBack">
                {{ $t("common.backtrack") }}
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import {
  Row,
  Col,
  Tag,
  Typography,
  Radio,
  Table,
  Input,
  Popover,
  InputNumber,
  Select,
  Button,
  Form,
  Modal,
  Spin,
  List,
  Space,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CHaveProductInformation from "@/views/components/CHaveProductInformation.vue";
import CTagMixedOrSingle from "@/views/components/CTagMixedOrSingle.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from "vue-router";
import { useTab } from "@/hooks/tabs/index";
import { getDetails } from "@/api/modules/finance/accounting";
import {
  getAddressByLanguageV2,
  gToKg,
  cmCubicToM,
  roundNumber,
  getLinkman,
  getNameByLanguage,
} from "@/utils/general";
import {
  destinationType as destinationTypeEnum,
  transportStatus as transportStatusEnum,
  logisticsCalcMethod as logisticsCalcMethodEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
} from "@/enum/enum.json";
import filters from "@/filters/index.js";

export default {
  name: "finance_accounting_cost",
  components: {
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpin: Spin,
    ATypographyText: Typography.Text,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    APopover: Popover,
    AList: List,
    AListItem: List.Item,
    ASpace: Space,
    Content,
    CImage,
    CTooltip,
    CHaveProductInformation,
    CTagMixedOrSingle,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();

    const boxTableColumns = [
      {
        dataIndex: "serialNumber",
        width: 60,
        title: () => vueI18n.t("common.serial_number"),
      },
      {
        width: 150,
        title: vueI18n.t("warehouse.box_number"),
        slots: {
          customRender: "boxNo",
        },
      },
      { width: 50 },
      {
        width: 100,
        slots: {
          title: "encasementCount",
          customRender: "encasementCountCustom",
        },
      },
      {
        width: 150,
        slots: {
          title: "encasementInfo",
          customRender: "encasementInfoCustom",
        },
      },
      {
        width: 150,
        slots: {
          title: "transportInfo",
          customRender: "transportInfoCustom",
        },
      },
      {
        title: "",
        width: 100,
        slots: {
          customRender: "checkPictureCustom",
        },
      },
    ];

    const innerProductColumns = [
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        dataIndex: "seSku",
        width: 150,
        title: "SESKU",
      },
      {
        title: () => vueI18n.t("logistics.count"),
        width: 150,
        slots: {
          customRender: "containerCount",
        },
      },
      {
        title: vueI18n.t("logistics.rests"),
        width: 500,
        slots: {
          customRender: "restsCustom",
        },
      },
    ];

    const columnsLogisticsScheme = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        dataIndex: "logisticsWay", //物流类型
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
      },
      {
        dataIndex: "deliveryWay", //交付方式
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        dataIndex: "referenceAging", //参考时效
        width: 100,
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
      },
      {
        dataIndex: "details", //详情
        width: 150,
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
      },
      {
        dataIndex: "optionalServices", //可选服务
        width: 150,
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
      },
    ];

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ];

    const customsInfoColumns = [
      {
        title: vueI18n.t("common.serial_number"),
        dataIndex: "serialNumber ",
        slots: {},
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        width: 60,
      },
      {
        dataIndex: "productNameAndSeSku",
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
        width: 150,
      },
      {
        title: vueI18n.t("logistics.trade_name"),
        dataIndex: "tradeName",
        slots: {
          customRender: "tradeNameCustom",
        },
        width: 150,
      },
      {
        title: "HS Code",
        dataIndex: "hsCode",
        width: 150,
      },
      {
        title: vueI18n.t("logistics.apply_middle_rate"),
        width: 150,
        slots: {
          customRender: "averagePrice",
        },
      },
      {
        title: vueI18n.t("logistics.amount"),
        dataIndex: "number",
        width: 150,
      },
      {
        title: vueI18n.t("logistics.subtotal"),
        width: 150,
        slots: {
          customRender: "totalPrice",
        },
      },
    ];

    const state = reactive({
      transportStatusEnum,
      destinationTypeEnum,
      logisticsCalcMethodEnum,
      pageLoading: false,
      currencys: [],
      //箱表格信息
      plan: {
        boxTableColumns,
        innerColumns: innerProductColumns,
        id: "",
        no: "",
        name: "",
        creationTime: null,
        updateTime: null,

        isCostEstimates: null,
        transportRoutes: null,
        toWarehouseType: null,
        toWarehouseId: "",
        toWarehouseCode: "",
        fbaTransportNo: "",
        productTrackNo: "",
        fromAddressData: {},
        toAddressData: {},
        producTableList: [],
        boxTotalCount: 0,
        transportTotalVolume: 0,
        transportTotalWeight: 0,
        transportTotalCharacteristic: null,
        isSelectDoorTake: false, //是否上门揽件
        doorLinkUserName: "",
        doorContactPhone: "",

        receiptUserName: "",
        receiptContactPhone: "",

        outWarehouseNo: null,
        inWarehouseNo: null,
        toInWarehouseNo: null,
        seLogisticsAddress: "",
        seLogisticsContact: "",
        seLogisticsPhone: "",
        chargeableWeight: null, //打托重量
        isPallet: null, //是否打托

        employee: null,
        operatorName: null,
        shopData: null,
        isInsure: null, //是否需要保险
      },
      // 清关信息
      customsInfo: [],
      isUploadHsInfo: false, //是否为一般报关方式
      customsTotalFee: "",
      customsSymbol: "",
      //物流渠道
      logistics: {
        isShowVATInput: false,
        VAT: null,

        columnsLogisticsScheme: columnsLogisticsScheme,
        logisticsSchemeList: [],
      },
      cost: [], //费用
      alterationCost: [],
      //渠供应渠道
      supplyChannels: {
        supplierChannelName: null,
        channelBusinessNo: null,
        chargeableWeight: null,
        chargeableVolume: null,
        logisticsCalcMethod: null,
      },
      amendCost: [], //修改费用
      profit: null, //利润
    });

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return "-";
      }
      return list.map((item) => item.name).join(", ");
    };

    const handleParseTime = (num) => {
      let number = Number.parseInt(num);
      return Number.isNaN(number) ? num : number;
    };

    const funcSearchPlanInfo = async (planId) => {
      state.pageLoading = true;
      getDetails({ id: planId })
        .then((res) => {
          let r = res.result;
          try {
            state.plan.id = r.planBasicInfo.planId;
            state.plan.no = r.planBasicInfo.planNo;
            state.plan.name = r.planBasicInfo.planName;
            state.plan.isCostEstimates = r.planBasicInfo.isCostEstimates;
            state.plan.planStatus = r.planBasicInfo.planStatus;
            state.plan.creationTime = r.planBasicInfo.createTime;
            state.plan.updateTime = r.planBasicInfo.updateTime;
            state.plan.toWarehouseType = r.planBasicInfo.destinationType; // 目的仓库类型
            state.plan.toWarehouseId = r.planBasicInfo.toWarehouseId;
            state.plan.toWarehouseCode = r.planBasicInfo.warehouseCode;
            state.plan.fbaTransportNo = r.planBasicInfo.amazonTransportNo;
            state.plan.productTrackNo = r.planBasicInfo.trackNo;
            state.plan.fromAddressData = r.planBasicInfo.fromAddress;
            state.plan.fromAddressData.fromName = r.planBasicInfo.fromName;
            state.plan.toAddressData = r.planBasicInfo.toAddress;
            state.plan.toAddressData.isInternationalTransport =
              r.planBasicInfo.isInternationalTransport;
            state.plan.seLogisticsAddress =
              r.planBasicInfo.logisitcWarehouseAddress;
            state.plan.seLogisticsPhone =
              r.planBasicInfo.logisitcWarehouseAddress.linkPhone;
            state.plan.seLogisticsContact =
              r.planBasicInfo.logisitcWarehouseAddress.linkUserName;
            state.plan.isSelectDoorTake = r.planBasicInfo.isDoorTook;
            state.plan.doorLinkUserName = r.planBasicInfo.doorLinkUserName;
            state.plan.doorContactPhone = r.planBasicInfo.doorContactPhone;
            state.plan.receiptUserName = r.planBasicInfo.receiptUserName;
            state.plan.receiptContactPhone =
              r.planBasicInfo.receiptContactPhone;
            state.plan.outWarehouseNo = r.planBasicInfo.outWarehouseNo;
            state.plan.inWarehouseNo = r.planBasicInfo.inWarehouseNo;
            state.plan.toInWarehouseNo = r.planBasicInfo.toInWarehouseNo;
            state.plan.isInsure = r.planBasicInfo.isInsure;

            state.plan.transportRoutes = r.transportRoutes; // 运输路线
            state.plan.employee = r.employee;
            state.plan.operatorName = r.operatorName;
            state.plan.shopData = r.shopData;

            //箱表格
            state.plan.producTableList = r.planBoxInfo.transportPlanBoxData.map(
              (x, index) => {
                x.loading = false;
                x.serialNumber = index + 1;
                return x;
              }
            );

            state.plan.boxTotalCount = r.planBoxInfo.totalBox;
            state.plan.transportTotalVolume = r.planBoxInfo.totalVolume
              ? cmCubicToM(r.planBoxInfo.totalVolume)
              : "-";
            state.plan.transportTotalWeight = r.planBoxInfo.totalBoxWeight
              ? gToKg(r.planBoxInfo.totalBoxWeight)
              : "-";
            state.plan.transportTotalCharacteristic =
              r.planBoxInfo.transportCharacteristics;

            state.plan.logisticsCalcMethod = r.planBoxInfo.logisticsCalcMethod;
            state.plan.chargeableWeight = gToKg(r.planBoxInfo.chargeableWeight);
            state.plan.chargeableVolume = cmCubicToM(
              r.planBoxInfo.chargeableVolume
            );
            state.plan.isPallet = r.planBoxInfo.isPallet;

            let columns = [];
            if (!state.plan.toAddressData.isInternationalTransport) {
              columns = innerProductColumns.filter(
                (x) => x.dataIndex != "rests"
              );
            } else {
              columns = innerProductColumns;
            }
            state.plan.innerColumns = columns;

            //清关信息
            state.customsInfo = r.customsInfo?.customsInfoCollect?.map(
              (x, index) => {
                return {
                  key: index + 1,
                  productName: x.productName,
                  productNo: x.productNo,
                  enTradeNameCustom: x?.brandEnName,
                  chTradeNameCustom: x?.brandCName,
                  currencySymbol: x?.currencySymbol,
                  averagePrice: x?.averagePrice,
                  hsCode: x?.hsCode,
                  number: x?.unit,
                  totalPrice: x?.totalPrice,
                };
              }
            );
            state.customsTotalFee = r.customsInfo?.totalPrice?.totalPrice;
            state.customsSymbol = r.customsInfo?.totalPrice?.currencySymbol;
            state.isUploadHsInfo = r.isUploadHsInfo;

            //物流方案
            let logisticsTemp = [
              r?.logisticChannelData?.transportPlanLogistic?.logisticsBaseInfo,
            ];
            let logisticsRegionInfo =
              r?.logisticChannelData?.transportPlanLogistic
                ?.logisticsRegionInfo;
            state.logistics.logisticsSchemeList = logisticsTemp.map(
              (x, index) => {
                return {
                  key: index,
                  id: x.id,
                  scheme: x.name,
                  code: x.code,
                  descriptionItems: x.descriptionItems,

                  logisticsWay: x.logisticsType,
                  deliveryWay: x.deliveryType,

                  daysMin: x.daysMin,
                  daysMax: x.daysMax,
                  channelTimeType: x.channelTimeType, //签收或自提 枚举

                  logisticsCalcMethod: x.logisticsCalcMethod,
                  chargeableWeight: x?.chargeableWeight
                    ? gToKg(x.chargeableWeight)
                    : "0", //计费重
                  chargeableVolume: x.chargeableVolume
                    ? cmCubicToM(x.chargeableVolume)
                    : "0", //计费体积
                  volumeWeightParam: x.volumeWeightParam, //体积系数

                  logisticsRegionInfo: logisticsRegionInfo || null, //是否支持DDP和签字

                  // 卡车相关
                  feeCalculationMethod: x?.feeCalculationMethod,
                  truckBaseInfo: x?.truckBaseInfo,
                };
              }
            );

            if (logisticsRegionInfo) {
              state.logistics.isShowVATInput =
                logisticsRegionInfo?.isVatRequired; //是否支持Vat
              state.logistics.VAT = logisticsRegionInfo?.vatCode;
            }

            if (state.logistics.logisticsSchemeList.length) {
              let logisticsSchemeItem = state.logistics.logisticsSchemeList[0];
              if (
                logisticsSchemeItem.feeCalculationMethod ===
                feeCalculationMethodEnum.template
              ) {
                // 物流
                state.logistics.columnsLogisticsScheme = columnsLogisticsScheme;
              } else {
                // 卡车
                state.logistics.columnsLogisticsScheme = columnsLogisticsTruck;
              }
            }

            //物流费用
            state.cost = _funcGetFee(r?.logisticChannelData);

            //渠道
            state.supplyChannels = r?.supplierChannelData?.supplierChannel;
            state.supplyChannels.chargeableWeight = gToKg(
              r?.supplierChannelData?.chargeableWeight
            );
            state.supplyChannels.chargeableVolume = cmCubicToM(
              r?.supplierChannelData?.chargeableVolume
            );
            state.supplyChannels.logisticsCalcMethod =
              r?.supplierChannelData?.logisticsCalcMethod;

            //修改费用
            state.amendCost = _funcGetFee(r?.supplierChannelData);
            state.profit = r?.supplierChannelData?.profit;
          } catch (error) {}
        })
        .catch((error) => {})
        .finally(() => {
          state.pageLoading = false;
        });
    };

    const _funcGetFee = (result) => {
      let cost = [];
      let r = result?.expensesFeeData;
      let list1 = [
        {
          name: "logistics.freight",
          value1: r?.logisticsFreight?.currencySymbol,
          value2: roundNumber(r?.logisticsFreight?.expressFee),
        },
        {
          name: "warehouse.discount_amount",
          value1: r?.logisticsFreight?.currencySymbol,
          value2:
            filters.amountToFixed2(
              r?.logisticsFreight?.expressFeeDiscountAmount
            ) ?? 0,
        },
        {
          name: "logistics.discount",
          value1: r?.logisticsFreight?.expressFeeDiscountRate ?? 100,
          value2: "%",
        },
      ];

      let list2 = r?.surchargeFreight ?? [];
      let list3 = {
        // name: "logistics.total_income",
        value1: r?.logisticsFreight?.currencySymbol,
        value2: roundNumber(r.totalAmount),
      };

      cost.push(list1);

      cost.push(list2);
      cost.push(list3);
      return cost;
    };

    const handleReloadCheck = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({
        name: "finance_accounting_cost",
        params: { id: state.plan.id },
      });
    };

    const getLanguageFullName = (employee) => {
      return getNameByLanguage(employee, getters.language);
    };

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "finance_accounting_list" });
    };

    onMounted(async () => {
      let planId = route.params.id;
      if (planId) {
        state.plan.id = planId;
        funcSearchPlanInfo(planId);
      }
    });

    const currentPageIndex = ref(1);
    const defaultPageSize = ref(50);

    return {
      ...toRefs(state),
      gToKg,
      getLinkman,
      currentPageIndex,
      defaultPageSize,
      customsInfoColumns,
      getAddress,
      getAccessorialsName,
      handleParseTime,
      handleReloadCheck,
      getLanguageFullName,
      handleBack,
    };
  },
};
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}
.yellow-words {
  color: rgb(255, 120, 0);
}

.table-list-img-common {
  width: 54px;
  height: 54px;

  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :deep(.ant-image img) {
    max-height: 45px;
  }
}

:deep(.input-group-mid-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}

:deep(.input-group-mid-number-end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
</style>

import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";
const Api = {
  // 舱位列表
  getShippingSpaceConsoleListPaged: "/api/WarehouseService/ShippingSpaceConsole/GetListPaged",
  // 舱位详细
  getShippingSpaceConsole: "/api/WarehouseService/ShippingSpaceConsole/Get",
  // 港口列表
  getPortList: "/api/WarehouseService/Port/GetList",
  // 舱位轨迹
  getShippingHistoryByShipping: "/api/WarehouseService/ShippingHistory/GetByShipping",
  exportPackingList: "/api/WarehouseService/TranSportShipingRlConsole/ExportBox",
}

export const exportPackingList = (data) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportPackingList);
  return url;
}

export const getShippingSpaceConsoleListPaged = (data) => {
  return client.request({
    url: Api.getShippingSpaceConsoleListPaged,
    data,
    method: "POST",
  });
};

export const getShippingSpaceConsole = (params) => {
  return client.request({
    url: Api.getShippingSpaceConsole,
    params,
    method: "GET",
  });
};

export const getPortList = (data) => {
  return client.request({
    url: Api.getPortList,
    data,
    method: "POST",
  });
};

export const getShippingHistoryByShipping = (data) => {
  return client.request({
    url: Api.getShippingHistoryByShipping,
    data,
    method: "POST",
  });
};
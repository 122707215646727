<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.warehouse_monitor_transit_outbound")
    }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-input
            style="width: 250px"
            v-model:value="searchState.planName"
            :placeholder="$t('warehouse.out_plan_name')"
            allow-clear
          ></a-input>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.planNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="14"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('warehouse.outbound_plan_number')"
            allowClear
            @change="handleSelectValueChange('planNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.boxNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="18"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('warehouse.box_id') + '/' + $t('warehouse.scannable_box_no')"
            allowClear
            @change="handleSelectValueChange('boxNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.seShipmentNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="18"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('warehouse.se_shipment_number')"
            allowClear
            @change="handleSelectValueChange('seShipmentNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-input
            v-model:value="searchState.shipmentNameOrNo"
            :placeholder="$t('warehouse.shipment_name_or_shipment_number')"
            :allowClear="true"
            style="width: 250px"
          />
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.transitDeliveryMethod"
            :placeholder="$t('warehouse.type_of_shipping')"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in transitDeliveryMethodEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('transitDeliveryMethod', item))"
              >{{
                $t($enumLangkey("transitDeliveryMethod", item))
              }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.destinationType"
            :placeholder="$t('warehouse.out_warehouse_type')"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in destinationTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('destinationType', item))"
              >{{ $t($enumLangkey("destinationType", item)) }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.wareStatus"
            :placeholder="$t('warehouse.status')"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in transitOutBoundWareStatusEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('transitOutBoundWareStatus', item))"
              >{{
                $t($enumLangkey("transitOutBoundWareStatus", item))
              }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>{{ $t("logistics.create_date") }}:</a-col>
            <a-col>
              <a-range-picker
                v-model:value="searchDateState.creationTime"
                allow-clear
              ></a-range-picker>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>{{ $t("warehouse.appointment_time") }}:</a-col>
            <a-col>
              <a-input-group compact style="width: 350px">
                <a-select
                  v-model:value="searchState.warehouseId"
                  :placeholder="$t('warehouse.warehouse')"
                  allow-clear
                  :showSearch="true"
                  @change="handleChangeWarehouse"
                  optionFilterProp="search-key"
                  style="width: 40%"
                >
                  <a-select-option
                    v-for="(item, key) in warehouseList"
                    :key="key"
                    :value="item.id"
                    :title="`${item.warehouseNo}(${item.warehouseName})`"
                    :search-key="item.warehouseNo + item.warehouseName"
                  >
                    {{ item.warehouseNo }}({{ item.warehouseName }})
                  </a-select-option>
                </a-select>
                <a-range-picker
                  style="width: 60%"
                  allow-clear
                  v-model:value="searchDateState.scheduleDeliveryTime"
                  :disabled="!searchState.warehouseId"
                ></a-range-picker>
              </a-input-group>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button
            type="primary"
            :loading="tableData.loading"
            @click="handleSearch"
            >{{ $t("common.search") }}</a-button
          >
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-table
          :columns="columns"
          :data-source="tableData.tableList"
          :scroll="{ x: true, y: wrap.contentHeight - 170 }"
          :pagination="false"
          size="small"
          :loading="tableData.loading"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #planNoTitle>
            <div>{{ $t("warehouse.out_plan_name") }}</div>
            <div>{{ $t("warehouse.outbound_plan_number") }}</div>
          </template>
          <template #planNo="{ record }">
            <div>{{ record.planName }}</div>
            <div>{{ record.planNo }}</div>
          </template>
          <template #shopInfo="{ record }">
            <template v-if="record.shopInfo">
              <div>{{ record.shopInfo.shopName }}</div>
              <div>NO: {{ record.shopInfo.shopNo }}</div>
              <div>
                {{ $t("common.shop_mark") }}: {{ record.shopInfo.shopMark }}
              </div>
            </template>
            <span v-else>-</span>
          </template>

          <template #scheduleDeliveryTimeTitle>
            <div>{{ $t("warehouse.date_of_reservation") }}</div>
            <div>
              <a-tag color="warning">{{ $t("warehouse.local_time") }}</a-tag>
            </div>
          </template>
          <template #scheduleDeliveryTime="{ record }">
            <template
              v-if="
                record.transitDeliveryMethod ===
                transitDeliveryMethodEnum.takeTheir
              "
            >
              {{
                $filters.utcToSpecificTime(
                  record.scheduleDeliveryTime,
                  record.warehouseTimezone
                )
              }}
            </template>
            <template v-else>
              <template v-if="record.scheduleDeliveryType">
                <div
                  v-if="
                    record.scheduleDeliveryType ===
                    scheduleDeliveryTypeEnum.anytime
                  "
                >
                  {{
                    $t(
                      $enumLangkey(
                        "scheduleDeliveryType",
                        scheduleDeliveryTypeEnum.anytime
                      )
                    )
                  }}
                </div>
                <div
                  v-else-if="
                    record.scheduleDeliveryType ===
                    scheduleDeliveryTypeEnum.date
                  "
                >
                  {{
                    $filters.utcToSpecificTime(
                      record.scheduleDeliveryTime,
                      record.warehouseTimezone,
                      "yyyy-MM-DD"
                    )
                  }}
                </div>
                <div
                  v-else-if="
                    record.scheduleDeliveryType ===
                    scheduleDeliveryTypeEnum.dateTime
                  "
                >
                  {{
                    $filters.utcToSpecificTime(
                      record.scheduleDeliveryTime,
                      record.warehouseTimezone
                    )
                  }}
                </div>
              </template>
              <span v-else>-</span>
            </template>
            <ScheduleTimeTypeTag
              v-if="record.scheduleTimeType"
              :type="record.scheduleTimeType"
            />
          </template>

          <template #transitDeliveryMethod="{ record }">
            {{
              $t(
                $enumLangkey(
                  "transitDeliveryMethod",
                  record.transitDeliveryMethod
                )
              )
            }}
          </template>

          <template #destinationType="{ record }">
            {{ $t($enumLangkey("destinationType", record.destinationType)) }}
          </template>

          <template #shipmentTotalCount="{ record }">
            {{
              $filters.countProgressFormat(
                record.transitTotalInfo.shipmentTotalCount?.actual,
                record.transitTotalInfo.shipmentTotalCount?.plan
              )
            }}
          </template>

          <template #boxCount="{ record }">
            {{
              $filters.countProgressFormat(
                record.transitTotalInfo.boxCount?.actual,
                record.transitTotalInfo.boxCount?.plan
              )
            }}
          </template>

          <template #status="{ record }">
            <span
              v-if="
                record.wareStatus === transitOutBoundWareStatusEnum.completed
              "
              class="text-succeed"
              >{{
                $t($enumLangkey("transitOutBoundWareStatus", record.wareStatus))
              }}</span
            >
            <span
              v-else-if="
                record.wareStatus ===
                transitOutBoundWareStatusEnum.partlyCompleted
              "
              class="text-warning"
              >{{
                $t($enumLangkey("transitOutBoundWareStatus", record.wareStatus))
              }}</span
            >
            <span
              v-else-if="
                record.wareStatus === transitOutBoundWareStatusEnum.canceled
              "
              class="text-error"
              >{{
                $t($enumLangkey("transitOutBoundWareStatus", record.wareStatus))
              }}</span
            >
            <span v-else>{{
              $t($enumLangkey("transitOutBoundWareStatus", record.wareStatus))
            }}</span>
            <a-tag v-if="record.isRepresented" color="blue" class="ml-1">{{ $t('account.agent') }}</a-tag>

          </template>

          <template #date>
            <div>{{ $t("warehouse.create_date") }}</div>
            <div>{{ $t("warehouse.update_date") }}</div>
          </template>
          <template #dateCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>
              {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
            </div>
          </template>

          <template #operateCustom="{ record }">
            <a-dropdown-button @click="handleDetails(record)">
              <span>{{ $t("common.details") }}</span>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item
                    key="1"
                    :record="record"
                    :disabled="!record.cancelled"
                  >
                    {{ $t("common.cancel") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleCancelPlan"
      />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="tableData.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import {
  Row,
  Col,
  Tag,
  Table,
  Input,
  Select,
  Button,
  DatePicker,
  Dropdown,
  Menu,message
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CSearchShop from "@/views/components/CSearchShop.vue";
import ScheduleTimeTypeTag from "@/views/components/ScheduleTimeTypeTag.vue";
import { getWarehouses } from "@/api/modules/common/index.js";
import { getTransitOutBoundConsolePagedList } from "@/api/modules/warehouseMonitor/transit.js";
import { useI18n } from "vue-i18n/index";
import {
  warehouseType as warehouseTypeEnum,
  transitDeliveryMethod as transitDeliveryMethodEnum,
  destinationType as destinationTypeEnum,
  scheduleDeliveryType as scheduleDeliveryTypeEnum,
  transitOutBoundWareStatus as transitOutBoundWareStatusEnum,
} from "@/enum/enum.json";
import { useStore } from "vuex";
import { currentTimeToUtc } from "@/utils/general";
import localPrint from "@/utils/localPrint";
import { cancelPlan } from "../../../api/modules/warehouseMonitor/transitInPlanDetail";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "warehouse_monitor_transit_outbound",
  components: {
    CPinTipsModal,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    ARangePicker: DatePicker.RangePicker,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATag: Tag,
    Content,
    CPager,
    CSearchShop,
    ScheduleTimeTypeTag,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();

    const state = reactive({
      searchDateState: {
        creationTime: [],
        scheduleDeliveryTime: [],
      },
      searchState: {
        shopId: null,
        planNo: null,
        planNoes: [],
        planName: null,
        boxNoes: [],
        seShipmentNoes: [],
        shipmentNameOrNo: null,
        transitDeliveryMethod: null,
        destinationType: null,
        wareStatus: null,
        warehouseId: null,
        beginScheduleDeliveryTime: null,
        endScheduleDeliveryTime: null,
        beginCreationTime: null,
        endCreationTime: null,
        isNeedShop: true,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      warehouseList: [],
      pinModalState: {
        visible: false,
        loading: false,
        dataTemp: null,
      },
    });

    const columns = [
      {
        slots: {
          title: "planNoTitle",
          customRender: "planNo",
        },
        fixed: "left",
        width: 170,
      },
      {
        dataIndex: "warehouseNo",
        title: () => vueI18n.t("logistics.warehouse_code"),
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.company"),
        width: 150,
        slots: {
          customRender: "shopInfo",
        },
      },
      {
        slots: {
          title: "scheduleDeliveryTimeTitle",
          customRender: "scheduleDeliveryTime",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.type_of_shipping"),
        slots: {
          customRender: "transitDeliveryMethod",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("warehouse.out_warehouse_type"),
        slots: {
          customRender: "destinationType",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("warehouse.number_of_packages"),
        slots: {
          customRender: "shipmentTotalCount",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "boxCount",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "status",
        },
        width: 120,
      },
      {
        slots: {
          title: "date",
          customRender: "dateCustom",
        },
        width: 150,
      },
      {
        dataIndex: "operate",
        title: () => vueI18n.t("common.operate"),
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
        width: 150,
      },
    ];

    const handleSelectValueChange = (key) => {
      let value = state.searchState[key];
      if (Array.isArray(value)) {
        state.searchState[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handleDateChange = (e, keys) => {
      let beginTime = null,
        endTime = null;
      if (e?.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState[keys[0]] = beginTime;
      state.searchState[keys[1]] = endTime;
    };

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getTransitOutBoundConsolePagedList(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      let userInfo = getters.userInfo;
      let warehouseInfo = state.warehouseList.find(
        (item) => item.id === state.searchState.warehouseId
      );
      let creationTime = state.searchDateState.creationTime;
      let scheduleDeliveryTime = state.searchDateState.scheduleDeliveryTime;
      if (creationTime?.length === 2) {
        Object.assign(state.searchState, {
          beginCreationTime: currentTimeToUtc(
            creationTime[0].format("YYYY-MM-DD 00:00:00"),
            userInfo.timezone
          ),
          endCreationTime: currentTimeToUtc(
            creationTime[1].format("YYYY-MM-DD 00:00:00"),
            userInfo.timezone
          ),
        });
      } else {
        Object.assign(state.searchState, {
          beginCreationTime: null,
          endCreationTime: null,
        });
      }
      if (scheduleDeliveryTime?.length === 2) {
        Object.assign(state.searchState, {
          beginScheduleDeliveryTime: currentTimeToUtc(
            scheduleDeliveryTime[0].format("YYYY-MM-DD 00:00:00"),
            warehouseInfo.timezone
          ),
          endScheduleDeliveryTime: currentTimeToUtc(
            scheduleDeliveryTime[1].format("YYYY-MM-DD 00:00:00"),
            warehouseInfo.timezone
          ),
        });
      } else {
        Object.assign(state.searchState, {
          beginScheduleDeliveryTime: null,
          endScheduleDeliveryTime: null,
        });
      }

      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const getWarehouseList = () => {
      getWarehouses({
        isActive: true,
        warehouseType: warehouseTypeEnum.transitWarehouse,
      })
        .then(({ result }) => {
          if (Array.isArray(result)) {
            state.warehouseList = result;
          } else {
            state.warehouseList = [];
          }
        })
        .catch();
    };

    const handleDetails = (record) => {
      router.push({
        name: "warehouse_monitor_transit_outbound_detail",
        params: { id: record.id },
      });
    };

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      // const data = { planId: record.planId, auditStatus: null };
      state.pinModalState.dataTemp = record;
      switch (key) {
        case "1":
          // 取消预约
          state.pinModalState.visible = true;
          break;
        default:
          break;
      }
    };

    const handleCancelPlan = (pinCode) => {
      state.pinModalState.visible = true;
      state.pinModalState.loading = true;
      cancelPlan(state.pinModalState.dataTemp.id, pinCode)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          state.pinModalState.visible = false;
          state.pinModalState.loading = false;
          handleSearch();
        })
        .catch((w) => {
          state.pinModalState.loading = false;
        });
    };

     const handleChangeWarehouse = (value, expand) => {
      if (!value) {
        state.searchDateState.scheduleDeliveryTime = null;
      }
    };

    onMounted(() => {
      getWarehouseList();
      handleSearch();
    });

    return {
      ...toRefs(state),
      transitDeliveryMethodEnum,
      destinationTypeEnum,
      scheduleDeliveryTypeEnum,
      transitOutBoundWareStatusEnum,
      columns,
      handleSelectValueChange,
      handleDateChange,
      handleSearch,
      handlePage,
      handleDetails,
      handleMenuClick,
      handleCancelPlan,
      handleChangeWarehouse
    };
  },
});
</script>

<style scoped></style>


<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.partner_settlement_record') }}
    </template>
    <template #contentBody="wrap">
      <a-card hoverable>
        <a-row :gutter="40">
          <a-col :xs="8"
                 :sm="8"
                 :md="6"
                 :lg="5"
                 :xl="4"
                 :xxl="3">
            <h1><strong>{{ $t('finance.total_amount_confirmed') }}</strong></h1>
          </a-col>
          <a-col :xs="8"
                 :sm="8"
                 :md="6"
                 :lg="5"
                 :xl="4"
                 :xxl="3">
            <h1><strong>{{ $t('finance.settled_total') }}</strong></h1>
          </a-col>
          <a-col :xs="8"
                 :sm="8"
                 :md="6"
                 :lg="5"
                 :xl="4"
                 :xxl="3">
            <h1><strong>{{ $t('finance.unsettled_total') }}</strong></h1>
          </a-col>
        </a-row>
        <template v-if="statistics?.length>0">
          <a-row :gutter="40"
                 v-for="(item,index) in statistics"
                 :key="index">
            <a-col :xs="8"
                   :sm="8"
                   :md="6"
                   :lg="5"
                   :xl="4"
                   :xxl="3">
              <span>
                {{item.currencyCode}}
                {{item.currencySymbol}}
                {{item.confirmAmount}}
              </span>
            </a-col>
            <a-col :xs="8"
                   :sm="8"
                   :md="6"
                   :lg="5"
                   :xl="4"
                   :xxl="3">
              <span class="text-success">
                {{item.currencySymbol}}
                {{item.settlementAmount}}
              </span>
            </a-col>
            <a-col :xs="8"
                   :sm="8"
                   :md="6"
                   :lg="5"
                   :xl="4"
                   :xxl="3">
              <span style="color: #AAAAAA">
                {{item.currencySymbol}}
                {{item.noSettlementAmount}}</span>
            </a-col>
          </a-row>
        </template>
      </a-card>
      <a-row :gutter="[16, 24]"
             class="mt-3 mb-2">
        <a-col>
          <a-input v-model:value="searchState.searchKey"
                   :placeholder="$t('finance.settlement_object') + '/' + $t('finance.payee')"
                   allowClear />
        </a-col>
        <a-col>
          <a-input v-model:value="searchState.bankNoAndSettlementNo"
                   :placeholder="$t('finance.settlement_serial_number') + '/' + $t('finance.bank_account_no')"
                   allowClear />
        </a-col>
        <a-col>
          <a-range-picker @change="handleDateChange" />
        </a-col>
        <a-col>
          <a-button type="primary"
                    @click="handleInitSearch">{{ $t('common.query') }}</a-button>
        </a-col>
      </a-row>
      <a-table :columns="columns"
               size="small"
               :data-source="tableData.tableList"
               :pagination="false"
               :scroll="{ x: 500, y: wrap.contentHeight - 160 }"
               :rowKey="
          (record, index) => {
            return index;
          }
        "
               :loading="tableData.loading">
        <template #creationTime="{ record }">
          <span>{{ $filters.utcToCurrentTime(record.creationTime) }}</span>
        </template>
        <template #partnerName="{ record }">
          <span v-if="record.partnerName">{{ record.partnerName }}</span>
          <span v-else>-</span>
        </template>
        <template #amount="{ record }">
          <span v-if="record.amount">{{ record.currencyCode }} {{ record.currencySymbol }}{{ record.amount }}</span>
          <span v-else>-</span>
        </template>
        <template #settlementChannelType="{ record }">
          <span>{{ $t($enumLangkey('settlementChannelType', record.settlementChannelType)) }}</span>
        </template>
        <template #settlement="{ record }">
          <a-space v-if="record.collectionAccountNumber"
                   :size="50">
            <span>{{ record.accountName }}</span>
            <span>{{ record.bankName }}({{ record.collectionAccountNumber }})</span>
          </a-space>
          <span v-else>-</span>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data="tableData.pageData"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import { Row, Col, Input, DatePicker, Table, Button, Space,Card } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { currentTimeToUtc } from '@/utils/general';
import { getAllAmountList, getManageListPaged } from '@/api/modules/partner/settlementRecord';

export default defineComponent({
  name: "partner_settlement_record",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ASpace: Space,
    Content,
    CPager,
    ACard:Card
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      searchState: {
        searchKey: null,
        bankNoAndSettlementNo: null,
        startTime: null,
        endTime: null,
      },
      searchStateCache: {},
      tableData: {
        loading: true,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      statistics: []
    });

    const columns = [
      {
        title: () => vueI18n.t("finance.date"),
        width: 120,
        slots: {
          customRender: "creationTime",
        },
        align: "center",
      },
      {
        dataIndex: "settlementNo",
        title: () => vueI18n.t("finance.settlement_serial_number"),
        width: 150,
        align: "center",
      },
      {
        title: () => vueI18n.t("finance.settlement_object"),
        width: 180,
        slots: {
          customRender: "partnerName",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("account.amount"),
        width: 120,
        slots: {
          customRender: "amount",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("finance.settlement_channel"),
        width: 120,
        slots: {
          customRender: "settlementChannelType",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("finance.payee"),
        width: 400,
        slots: {
          customRender: "settlement",
        },
        align: "center",
      },
    ];

    const handleDateChange = (e) => {
      let startTime = null
      let endTime = null
      if (Array.isArray(e) && e?.length > 1) {
        startTime = currentTimeToUtc(e[0]?.format('YYYY-MM-DD 00:00:00'));
        endTime = currentTimeToUtc(e[1]?.format('YYYY-MM-DD 00:00:00'));
      }
      state.searchState.startTime = startTime
      state.searchState.endTime = endTime
    }

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign({}, state.tableData.pageData, state.searchStateCache);
      getManageListPaged(data)
        .then(({ result }) => {
          if (result) {
            let { totalCount = 0, items = [] } = result;
            state.tableData.pageData.totalCount = parseInt(totalCount);
            state.tableData.tableList = items;
          }
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitSearch = () => {
      Object.assign(state.searchStateCache, state.searchState)
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const funcGetStatistics = () => {
      getAllAmountList().then(({ result }) => {
        if (result) {
          state.statistics = result;
        }
      }).catch(() => {

      })
    }

    const init = async () => {
      try {
        // let { result } = await getReferralLinkInfo();
        // if (result) {

        // }
        handleInitSearch();
        funcGetStatistics();
      } catch (error) { }
    };

    onMounted(init)

    return {
      ...toRefs(state),
      columns,
      handlePage,
      handleInitSearch,
      handleDateChange,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.warehouse_monitor_storage_outbound_task') }}
    </template>
    <template v-slot:contentBody='wrap'>
      <div class="mb-3">
        <a-row :gutter="[16, 16]">
          <a-col>
            <a-select
              style="width: 250px"
              v-model:value="searchState.planNoes"
              mode="tags"
              :maxTagCount="1"
              :maxTagTextLength="14"
              :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
              :placeholder="$t('warehouse.outbound_number')"
              allowClear
              @change="handleSelectValueChange('planNoes')"
            ></a-select>
          </a-col>
          <a-col>
            <a-select
              style="width: 250px"
              v-model:value="searchState.boxNoes"
              mode="tags"
              :maxTagCount="1"
              :maxTagTextLength="18"
              :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
              :placeholder="$t('warehouse.box_number')"
              allowClear
              @change="handleSelectValueChange('boxNoes')"
            ></a-select>
          </a-col>
          <a-col>
            <SearchProduct ref="refSearchProduct"></SearchProduct>
           </a-col>
          <a-col>
            <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
          </a-col>
          <a-col>
            <a-select
                    style="width: 250px"
                    v-model:value="searchState.warehouseId"
                    :placeholder="$t('logistics.warehouse_code')"
                    :show-search="true"
                    option-filter-prop="search-key"
                    allow-clear>
              <a-select-option
                v-for="(item, index) in warehouseList"
                :key="index"
                :title="`${item.warehouseNo}(${item.warehouseName})`"
                :value="item.id"
                :search-key="item.warehouseNo + item.warehouseName"
              >
                {{ item.warehouseNo }}({{ item.warehouseName }})
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
                    style="width: 250px;"
                    v-model:value="searchState.outWarehouseType"
                    :placeholder="$t('warehouse.out_warehouse_type')"
                    allow-clear>
              <a-select-option
                v-for="item in outWarehouseTypeList"
                :key="item"
                :title="$t($enumLangkey('outWarehouseType', item))"
                :value="item"
              >
                {{ $t($enumLangkey('outWarehouseType', item)) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
                    style="width: 250px;"
                    v-model:value="searchState.outWarehouseStatus"
                    :placeholder="$t('warehouse.plan_status')"
                    allow-clear>
              <a-select-option
                v-for="item in outPlanStatusList"
                :key="item"
                :title="$t($enumLangkey('outPlanStatus', item))"
                :value="item"
              >
                {{ $t($enumLangkey('outPlanStatus', item)) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
                    style="width: 250px;"
                    v-model:value="searchState.transDeliverType"
                    :placeholder="$t('warehouse.transportation_delivery_way')"
                    allow-clear>
              <a-select-option
                v-for="item in deliverTypeList"
                :key="item"
                :title="$t($enumLangkey('deliverType', item))"
                :value="item.id"
              >
                {{ $t($enumLangkey('deliverType', item)) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
                    style="width: 250px;"
                    v-model:value="searchState.transDeliverStatus"
                    :placeholder="$t('warehouse.transportation_delivery_status')"
                    allow-clear>
              <a-select-option
                v-for="item in deliverStatusList"
                :key="item"
                :title="$t($enumLangkey('deliverStatus', item))"
                :value="item"
              >
                {{ $t($enumLangkey('deliverStatus', item)) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-range-picker style="width: 250px;" @change="handleSetDateChange" />
          </a-col>
          <a-col>
            <a-space>
              <a-button :loading="loading" type="primary" @click="handleSearch">{{ $t('common.query') }}</a-button>
              <a-button type="ghost" @click="handleExport">{{$t('common.export')}}</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
      <a-table :columns="columns"
               :data-source="list"
               :pagination="false"
               :scroll="{ x: 500, y: wrap.contentHeight - 170}"
               :loading="loading"
               :rowKey="
          (record, index) => {
            return index;
          }
        "
        size="small"
      >
        <template #planNoTitle>
              {{$t('warehouse.plan_name')}}
          <div>
              {{$t('warehouse.outbound_number')}}
          </div>
        </template>

        <template #planNo="{ record }">
          {{record.planName}}
          <div>
            <CPlanNumberItem :no="record.planNo" />
          </div>
        </template>
        <template #toRelationPlanNo="{ record }">
          <CPlanNumberItem :no="record.toRelationPlanNo" />
        </template>
        <template #customer="{ record }">
          {{record.shopName}}
          <div v-if="record.shopNo">
            NO: {{record.shopNo}}
          </div>
          <div v-if="record.shopMark">
            {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
          </div>
        </template>
        <template #outWarehouseType="{record}">
          <span v-if="record.outWarehouseType">{{ $t($enumLangkey('outWarehouseType', record.outWarehouseType)) }}</span>
          <span v-else>-</span>
        </template>
        <template #outPlanStatusCol="{record}">
          <span v-if="record.outPlanStatus">{{ $t($enumLangkey('outPlanStatus', record.outPlanStatus)) }}</span>
          <span v-else>-</span>
        </template>
        <template #transDeliverTypeCol="{record}">
          <span v-if="record.transDeliverType">{{ $t($enumLangkey('deliverType', record.transDeliverType)) }}</span>
          <span v-else>-</span>
        </template>
        <template #deliverStatusCol="{record}">
          <span v-if="record.deliverStatus">{{ $t($enumLangkey('deliverStatus', record.deliverStatus)) }}</span>
          <span v-else>-</span>
        </template>
        <template #dateTimeTitle>
          <div>{{ $t('warehouse.creation_time') }}</div>
          <div>{{ $t('logistics.last_modification_time') }}</div>
        </template>
        <template #dateTimeCustomer="{ record }">
          <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
          <div>{{ $filters.utcToCurrentTime(record.updateTime) }}</div>
        </template>
        <template #action="{ record }">
          <a-dropdown-button>
            <router-link :to="'/warehousemonitor/storage/outboundtask/' + record.id">
              {{ $t("warehouse.task_details") }}
            </router-link>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item
                  :key="1"
                  :record="record"
                  :disabled="!record.isCanCancel"
                >{{ $t("common.cancel") }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <CPinTipsModal
        v-model:visible="pinModalOfCancelOutPlan.visible"
        v-model:loading="pinModalOfCancelOutPlan.loading"
        @confirm="handleCancelOutPlan"
      /> 
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />
    </template>
    <template #contentFooter>
      <CPager class="text-center" @handlePage="handlePage" :page-data='pageData' />
    </template>
  </Content>
</template>
<script>
import { defineComponent, reactive, onMounted, toRefs,ref } from 'vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import SearchProduct from '@/views/components/SearchProduct.vue';
import CPinTipsModal from "@/views/components/CPinTipsModal.vue"
import {
  Table,
  Button,
  Modal,
  Descriptions,
  Pagination,
  Card,
  Row,
  Col,
  Space,
  Input,
  Select,
  DatePicker,
  Dropdown,
  Menu,
  message
} from 'ant-design-vue';
import CSearchShop from "@/views/components/CSearchShop.vue"
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import { getWarehouses } from '@/api/modules/common';
import {
  getStorageOutWarehouseListPaged,
  exportOutWarehousePlan,updateCancelOutPlanForConsole
} from '@/api/modules/warehouseMonitor/storage';
import {
  outPlanStatus as outPlanStatusEnum,
  deliverType as deliverTypeEnum,
  deliverStatus as deliverStatusEnum,
  warehouseType,
  outWarehouseType as outWarehouseTypeEnum,
} from '@/enum/enum.json'
import { useI18n } from "vue-i18n/index";
import { currentTimeToUtc, dateString } from "@/utils/general";
import { downloadFile } from "@/utils/downloader";

export default defineComponent({
  name: "warehouse_monitor_storage_outbound_task",
  components: {
    ATable: Table,
    AButton: Button,
    AModal: Modal,
    APagination: Pagination,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    ADropdownButton: Dropdown.Button,
    aMenu: Menu,
    aMenuItem: Menu.Item,
    Content,
    CPager,
    CSearchShop,
    SearchProduct,
    CPlanNumberItem,
    CPinTipsModal,
  },

  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const refSearchProduct = ref();

    const searchProductRef = ref(null);

    const data = reactive({
      list: [],
      searchState: {
        plan: null,
        planNoes: [],
        box: null,
        boxNoes: [],
        shopId:null,
        warehouseId: null,
        outWarehouseType: null,
        outWarehouseStatus: null,
        transDeliverType: null,
        transDeliverStatus: null,
        productId: null,
        beginCreationTime: null,
        endCreationTime: null,
      },
      searchStateCache: {},
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      pinModalState: {
        visible: false,
        loading: false,
      },
      pinModalOfCancelOutPlan: {
        visible: false,
        loading: false,
      },
      
    });

    const state = reactive({
      loading: true,
      warehouseList: [],
      outWarehouseTypeList: [],
      outPlanStatusList: [],
      deliverTypeList: [],
      deliverStatusList: [],
    })

    for (const key in outWarehouseTypeEnum) {
      state.outWarehouseTypeList.push(outWarehouseTypeEnum[key])
    }

    for (const key in outPlanStatusEnum) {
      state.outPlanStatusList.push(outPlanStatusEnum[key])
    }

    for (const key in deliverTypeEnum) {
      state.deliverTypeList.push(deliverTypeEnum[key])
    }

    for (const key in deliverStatusEnum) {
      state.deliverStatusList.push(deliverStatusEnum[key])
    }

    const columns = [
      {
        width: 170,
        fixed: 'left',
        slots: {
          customRender: "planNo",
          title: "planNoTitle",
        }
      },
      {
        title: () => vueI18n.t('warehouse.target_related_doc_no'),
        width: 170,
        slots: {
          customRender: "toRelationPlanNo"
        },
      },
      {
        dataIndex: 'warehouseNo',
        width: 120,
        title: () => vueI18n.t('logistics.warehouse_code'),
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        width: 170,
        slots: {
          customRender: "customer"
        },
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.out_warehouse_type'),
        slots: {
          customRender: 'outWarehouseType'
        }
      },
      {
        dataIndex: 'outCount',
        width: 80,
        title: () => vueI18n.t('warehouse.outbound_warehouse_amount'),
      },
      {
        dataIndex: 'outBoxCount',
        width: 80,
        title: () => vueI18n.t('warehouse.outbound_warehouse_box_amount'),
      },
      {
        dataIndex: 'totalCount',
        width: 80,
        title: () => vueI18n.t('warehouse.product_quantity'),
      },
      {
        width: 120,
        title: () => vueI18n.t('finance.status'),
        slots: {
          customRender: 'outPlanStatusCol',
        },
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.transportation_delivery_way'),
        slots: {
          customRender: 'transDeliverTypeCol',
        },
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.delivery_status'),
        slots: {
          customRender: 'deliverStatusCol',
        },
      },
      {
        width: 150,
        slots: {
          title: 'dateTimeTitle',
          customRender: 'dateTimeCustomer'
        },
      },
      {
        title: '',
        width: 150,
        fixed: 'right',
        slots: {
          customRender: 'action',
        },
      },
    ];

    const handleSelectValueChange = (key) => {
      let value = data.searchState[key];
      if (Array.isArray(value)) {
        data.searchState[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handlePinModalConfirm = (pinCode) => {
      let url = exportOutWarehousePlan();
      const postData = Object.assign({}, data.searchStateCache, {
        warehouseType: warehouseType.storage,
        pinCode
      })
      data.pinModalState.loading = true
      downloadFile(url, `ws_outbound_plan_${dateString()}.csv`, "POST", postData).then(() => {
        data.pinModalState.visible = false
      }).catch((err) => {}).finally(() => {
        data.pinModalState.loading = false
      })
    }

    // 导出
    const handleExport = () => {
      data.pinModalState.visible = true
    }

    const funcGetTableList = async () => {
      state.loading = true;
      try {
        const tableData = Object.assign({}, data.pageData, data.searchStateCache, {
          warehouseType: warehouseType.storage,
        })
        let { result } = await getStorageOutWarehouseListPaged(tableData);
        let { items, totalCount } = result;
        const tableList = items.map(x => {
          return {
            id: x.id,
            planNo: x.planNo,
            planName: x.planName,
            toRelationPlanNo: x.toRelationPlanNo,
            warehouseNo: x.warehouseNo,
            shopName: x.shopName,
            shopId: x.shopId,
            shopNo: x.shopNo,
            shopMark: x.shopMark,
            outWarehouseType: x.outWarehouseType,
            outCount: x.total.plan.totalWarehouseCount,
            outBoxCount: x.total.plan.totalContainerBoxCount,
            totalCount: x.total.plan.totalCount,
            outPlanStatus: x.outWarehouseStatus,
            isCanCancel: x.isCanCancel,
            transDeliverType: x.transDeliverType,
            deliverStatus: x.transDeliverStatus,
            creationTime: x.creationTime,
            updateTime: x.lastModificationTime,
          }
        })
        data.list = tableList;
        data.pageData.totalCount = parseInt(totalCount);
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 1:
          // 取消
          handleShowPinModal(record);
          break;
        default:
          break;
      }

    }

    const handleSetDateChange = (e) => {
      let beginTime = null,
          endTime = null;
      if (e?.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format('YYYY-MM-DD 00:00:00'));
        endTime = currentTimeToUtc(e[1]?.format('YYYY-MM-DD 00:00:00'));
      }
      data.searchState.beginCreationTime = beginTime;
      data.searchState.endCreationTime = endTime;
    }

    const handlePage = (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcGetTableList();
    }

    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      Object.assign(data.searchStateCache, data.searchState);
      let productId = refSearchProduct.value.selectedProductId;
      if (productId) {
        data.searchStateCache.productId = productId;
      } else {
        data.searchStateCache.productId = null;
      }
      funcGetTableList()
    }

    const getWarehouseList = () => {
      getWarehouses({ warehouseType: warehouseType.storage }).then((res) => {
        let { result } = res
        if (Array.isArray(result)) {
          state.warehouseList = result
        }
      })
    }

     const handleShowPinModal = (record) => {
      data.pinModalOfCancelOutPlan.visible = true;
      data.cancelId = record.id;
    };

    const handleCancelOutPlan = (pinCode) => {
      const params = {
        id: data.cancelId,
        pin: pinCode,
      };
      data.pinModalOfCancelOutPlan.loading = true;
      updateCancelOutPlanForConsole(params)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          data.cancelId = null;
          data.pinModalOfCancelOutPlan.visible = false;
          data.pinModalOfCancelOutPlan.loading = false;
          handleSearch();
        })
        .catch(() => {
          data.pinModalOfCancelOutPlan.loading = false;
        });
    };

    onMounted(() => {
      funcGetTableList();
      getWarehouseList();
    });

    return {
      refSearchProduct,
      columns,
      ...toRefs(data),
      ...toRefs(state),
      outPlanStatusEnum,
      searchProductRef,
      handleSelectValueChange,
      funcGetTableList,
      handleMenuClick,
      handlePage,
      handleSearch,
      handleSetDateChange,
      handlePinModalConfirm,
      handleExport,
      handleCancelOutPlan,
      handleShowPinModal,
    };
  },
});
</script>

<style lang="less" scoped>
</style>

import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  //获得调拨计划列表
  getAllotPlans: "/api/WarehouseService/TransferToWarehousePlanConsole/GetListPaged",
  //获得计划的详情
  getDetails: "/api/WarehouseService/TransferToWarehousePlanConsole/GetDetail",
  // 取消
  TransferToWarehousePlanCancel: "/api/WarehouseService/TransferToWarehousePlanConsole/Cancel",
  // 导出调拨任务
  exportTransferToWarehousePlanConsole: "/api/WarehouseService/TransferToWarehousePlanConsole/ExportPlan",
};

export const getAllotPlans = ({
  status, //20 : 已计划, 30 : 处理中, 40 : 已调拨
  delegateWarehouseId,
  storageWarehouseId,
  searchKey,
  boxNo,
  shopId,
  productId,
  skipCount,
  maxResultCount,
  sorting,
  warehouseType = 1,
}) => {
  return client.request({
    url: Api.getAllotPlans,
    data: {
      status,
      delegateWarehouseId,
      storageWarehouseId,
      shopId,
  boxNo,
  productId,
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
      warehouseType,
    },
    method: "post",
  });
};

export const getDetails = (id) => {
  return client.request({
    url: Api.getDetails,
    data: { id },
    method: "post",
  });
};

export const TransferToWarehousePlanCancel = (data) => {
  return client.request({
    url: Api.TransferToWarehousePlanCancel,
    data,
    method: "POST",
  });
};

export const exportTransferToWarehousePlanConsole = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportTransferToWarehousePlanConsole);
  return url;
}
<template>
  <a-modal
    :title="$t('menu.warehouse_monitor_consignment_return_temporary_storage_shop') + '-' + state.formState.id ? $t('common.edit') : $t('common.add')"
    v-model:visible="state.visible"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-spin :spinning="state.loading">
      <a-form
        ref="formRef"
        :model="state.formState"
        :rules="rules"
        :label-col="{ style: { width: '150px' } }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-item :label="$t('warehouse.store_name')" name="storageName">
          <a-input v-model:value="state.formState.storageName"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, Modal, Spin, message } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { getReturnStorageStore, updateReturnStorageStore } from "@/api/modules/warehouseMonitor/returnTemporaryStorageShop.js";

export default defineComponent({
  emits: ["update:visible", "refresh"],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);
    
    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        id: null,
        storageName: null,
      }
    })

    const rules = {
      storageName: {
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.store_name")]),
        required: true,
      },
    }

    const getDetail = () => {
      state.loading = true;
      getReturnStorageStore({ id: props.id }).then(({ result }) => {
        if (result) {
          state.formState.id = result.id;
          state.formState.storageName = result.storageName;
        }
      }).catch(() => {
      }).finally(() => {
        state.loading = false;
      });
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        await updateReturnStorageStore(data);
        message.success(vueI18n.t("common.succeed"));
        emit("refresh");
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit("update:visible", false);
    }

    watch(() => [props.visible, props.id], ([visible, id]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            formRef.value.resetFields();
            state.formState.id = null;
            if (id) {
              getDetail();
            }
          })
        }
      }
    })

    return {
      state,
      formRef,
      rules,
      close,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>
import client from "../../client";

const Api = {
  //组织结构树
  getCompanyOrganTreeList:
    "/api/WarehouseService/Company/GetCompanyOrganTreeList",

  //创建部门
  createDepartment: "/api/WarehouseService/Department/Create",

  //修改部门
  updateDepartment: "/api/WarehouseService/Department/Update",

  //删除部门
  deleteDepartment: "/api/WarehouseService/Department/Delete",

  //获取员工分页列表
  getEmployeeListPaged: "/api/WarehouseService/Employee/GetListPaged",

  //创建员工
  createEmployee: "/api/WarehouseService/Employee/Create",

  //修改员工基本信息
  updateStaff: "/api/WarehouseService/Employee/Update",

  //根据Id获取单个员工信息
  getStaffInfoById: "/api/WarehouseService/Employee/GetById",

  //角色权限列表
  getRolePermissionList: "/api/WarehouseService/Employee/GetRoleList",

  //更新员工权限状态
  setStaffPermission: "/api/WarehouseService/Employee/AllowEmployeePermission",

  //更新员工状态
  updateStaffAccountStatus: "/api/WarehouseService/Employee/UpdateIsActive",

  getTimezones: "/api/BasicDataService/CustomTimeZone/GetList",

  //重置员工的PIN码
  resetPinCode:'/api/WarehouseService/Employee/ResetPin',

  // 重置员工的密码
  resetPwd:'/api/WarehouseService/Employee/ResetPwd',
};

export const getCompanyOrganTreeList = (companyId = 0) => {
  return client.request({
    url: Api.getCompanyOrganTreeList,
    data: { companyId: companyId },
    method: "post",
  });
};

export const createDepartment = ({ name, parentDepartmentId }) => {
  return client.request({
    url: Api.createDepartment,
    data: { name, parentDepartmentId },
    method: "post",
  });
};

export const updateDepartment = ({ id, name, parentDepartmentId }) => {
  return client.request({
    url: Api.updateDepartment,
    data: { id, name, parentDepartmentId },
    method: "post",
  });
};

export const deleteDepartment = (ids) => {
  return client.request({
    url: Api.deleteDepartment,
    data: { ids },
    method: "post",
  });
};

export const getEmployeeListPaged = ({
  ids,
  nationalityCountryId,
  workCountryId,
  departmentId,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getEmployeeListPaged,
    data: {
      ids,
      nationalityCountryId,
      workCountryId,
      departmentId,
      departmentHierarchy:true,
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const createEmployee = ({
  firstName,
  lastName,
  middleName,
  departmentId,
  isManager,
  isActive,
  jobStatu,
  nationalityCountryId,
  workCountryId,
  email,
  phoneCountryCode,
  phone,
  imageUrl,
  birthday,
  timezone,
}) => {
  return client.request({
    url: Api.createEmployee,
    data: {
      firstName,
      lastName,
      middleName,
      departmentId,
      isManager,
      isActive,
      jobStatu,
      nationalityCountryId,
      workCountryId,
      email,
      phoneCountryCode,
      phone,
      imageUrl,
      birthday,
      timezone,
    },
    method: "post",
  });
};

export const getRolePermissionList = (isShowManageRole) => {
  return client.request({
    url: Api.getRolePermissionList,
    data: {isShowManageRole},
    method: "post",
  });
};

export const updateStaff = ({
  id,
  imageUrl,
  jobStatu,
  isActive,
  workCountryId,
  email,
  departmentId,
  isManager,
  phoneCountryCode,
  phone,
}) => {
  return client.request({
    url: Api.updateStaff,
    data: {
      id,
      imageUrl,
      jobStatu,
      isActive,
      workCountryId,
      email,
      departmentId,
      isManager,
      phoneCountryCode,
      phone,
    },
    method: "post",
  });
};

export const setStaffPermission = ({ employeeId, roleIds, warehouseIds,isChangeUserToManagerUser }) => {
  return client.request({
    url: Api.setStaffPermission,
    data: { employeeId, roleIds, warehouseIds,isChangeUserToManagerUser },
    method: "post",
  });
};

export const updateStaffAccountStatus = ({ id, isActive }) => {
  return client.request({
    url: Api.updateStaffAccountStatus,
    data: { id, isActive },
    method: "post",
  });
};

export const getTimezones = () => {
  return client.request({
    url: Api.getTimezones,
    data: {},
    method: "post",
  });
};

export const getStaffInfoById = (id) => {
  return client.request({
    url: Api.getStaffInfoById,
    params: { id },
    method: "get",
  });
};

export const resetPinCode = (data) => {
  return client.request({
    url: Api.resetPinCode,
    data,
    method: "post",
  });
};

export const resetPwd = (data) => {
  return client.request({
    url: Api.resetPwd,
    data,
    method: "post",
  });
};

<template>
  <Content>
    <template #contentTitle>{{ $t('menu.products_storage_inventory') }}</template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <CSearchShop v-model:shopId="shopId"></CSearchShop>
            </a-col>
            <a-col>
              <SearchProduct ref="refSearchProduct" />
            </a-col>
            <a-col>
              <a-select v-model:value="selectedStatus"
                        :allowClear="true"
                        :placeholder="$t('finance.status')"
                        style="min-width: 200px;">
                <a-select-option v-for="(value,key) in boxStatus"
                                 :key="key"
                                 :title="$t($enumLangkey('boxStatus',value))"
                                 :value="value">
                  {{$t($enumLangkey('boxStatus',value))}}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedWarehouse"
                        :allowClear="true"
                        :placeholder="$t('logistics.warehouse_code')"
                        :show-search="true"
                        option-filter-prop="search-key"
                        style="width: 200px;">
                <a-select-option v-for="item in inventoryWarehouses"
                                 :key="item.value"
                                 :title="`${item.text1}(${item.text})`"
                                 :value="item.value"
                                 :search-key="item.text1 + item.text">
                  {{ `${item.text1}(${item.text})` }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-input-group compact style="width: 300px;">
                <a-select v-model:value="selectedSearchType"
                          style="width: 45%">
                  <a-select-option v-for="item in searchType"
                                   :key="item.value"
                                   :title="$t(item.text)"
                                   :value="item.value">
                    {{$t(item.text)}}
                  </a-select-option>
                </a-select>
                <a-input style="width: 55%"
                         v-model:value="searchKey"
                         allowClear />
              </a-input-group>
            </a-col>
            <a-col>
              <a-button type="primary"
                        :loading="productListLoading"
                        @click="handleSearch">{{$t('common.query')}}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="link" @click="handleShowPinModal">{{$t('common.export_product_in_the_warehouse_time')}}</a-button>
        </a-col>
      </a-row>
      <div class="inventory-table mt-3">
        <a-table :columns="columns"
                 :data-source="tableList"
                 :scroll="{ x:400, y: wrap.contentHeight - 140}"
                 :pagination="false"
                 size="small"
                 :loading="productListLoading"
                 class="product-table">
          <template #nameAndNo>
            <div>
              {{ $t("warehouse.product_no") }}
            </div>
            <div>
              {{ $t("warehouse.product_name") }}
            </div>
          </template>
          <template #encasementSize>
            <div>
              {{ $t("warehouse.encasement_specification") }}
            </div>
            <div>
              {{ $t("warehouse.inbound_specification") }}
            </div>
          </template>
          <template #encasementWeight>
            <div>
              {{ $t("warehouse.encasement_weight") }}
            </div>
            <div>
              {{ $t("warehouse.inbound_weight") }}
            </div>
          </template>

          <template #boxNo="{ record }">

             <a-row :gutter="[8, 8]" class="ml-1">
              <a-col>
                {{ record.boxNo }}
              </a-col>
              <a-col>
                <CTagMixedOrSingle
                  :productKindCount="record.productKindCount * 1"
                ></CTagMixedOrSingle>
              </a-col>
              <a-col :span="24">
                  {{record.selfBoxNo}}
              </a-col>
            </a-row>
          </template>

           <template #related_products="{ record }">
            <CHaveProductInformation
              :disabled="record.productKindCount < 1"
              @click="(e) => handleClickProductIoc(record)"
            />
          </template>

          <template #customer="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
             <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
          <template #statusCustom="{ record }">
            {{$t($enumLangkey('boxStatus',record.status))}}
          </template>

          <template #inWarehouseTimeCustom="{ record }">
            {{$filters.utcToCurrentTime(record.inWarehouseTime)}}
          </template>

          <template #outWarehouseTimeCustom="{ record }">
            {{$filters.utcToCurrentTime(record.outWarehouseTime)}}
          </template>

         

          <template #encasementSizeCustom="{ record }">
            <div>
              {{record.encasementSize}}
            </div>
            <div>
              {{record.inWarehouseSize}}
            </div>
          </template>

          <template #encasementWeightCustom="{ record }">
            <div>
              {{record.encasementWeight}}
            </div>
            <div>
              {{record.inWarehouseWeight}}
            </div>
          </template>

        </a-table>
      </div>
    <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="hanldeExportProductWarehouseTime"
      />

      <div 
        v-if="productDetail.whboxId">
        <CProductDetailModal
          v-model:visible="productDetail.visible"
          :whboxId="productDetail.whboxId"
          :boxNo="productDetail.boxNo"
        ></CProductDetailModal>
      </div>
    </template>
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data="pageData"> </CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, onActivated, ref, nextTick } from "vue";
import {
  Row, Col, Dropdown, Menu, Modal, Tag,
  Table, Input, AutoComplete, Card,
  Select, Button, Space
} from "ant-design-vue";
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import Content from "../components/Content.vue";
import CPager from "../components/CPager.vue";
import CImage from "../components/CImage.vue";
import SearchProduct from '../components/SearchProduct.vue';
import { getManageInventoryListPaged,exportInventoryListWithStockAge } from "@/api/modules/products/inventory";
import { getOptionWarehouses } from '@/api/modules/common/index';
import { warehouseType as warehouseTypeEnum, boxStatus as boxStatusEnum } from "@/enum/enum.json";
import { gToKg, dateString } from '@/utils/general';
import CSearchShop from "../components/CSearchShop.vue"
import { downloadFile } from '@/utils/downloader.js';
import CPinTipsModal from "../../views/components/CPinTipsModal.vue"
import CTagMixedOrSingle from "../components/CTagMixedOrSingle.vue";
import CHaveProductInformation from "../components/CHaveProductInformation.vue";
import CProductDetailModal from "../components/CProductDetailModal.vue";
import { getBoxOfProductsByWhboxId } from "../../api/modules/common/index";

export default defineComponent({
  name: "products_storage_inventory",
  components: {
    CProductDetailModal,
    CTagMixedOrSingle,
    CHaveProductInformation,
    CSearchShop,
    ARow: Row,
    ACol: Col,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AAutoComplete: AutoComplete,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ASpace: Space,
    AModal: Modal,
    ACard: Card,
    ATag: Tag,
    CImage,
    Content,
    CPager,
    SearchProduct,
    CPinTipsModal 
  },
  setup () {
    const refSearchProduct = ref();
    const refSearchProductModal = ref();
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        fixed: "left",
        width: 180,
        title: () => vueI18n.t('devops.box_no'),
        slots: {
          customRender: "boxNo"
        }
      },
        {
        title: () => vueI18n.t("common.related_products"),
        slots: {
          customRender: "related_products",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 170,
      },
      {
        width: 120,
        dataIndex: "warehouseNo",
        title: () => vueI18n.t('logistics.warehouse_code'),
      },
      {
        width: 120,
        slots: {
          title: "encasementSize",
          customRender: "encasementSizeCustom",
        },
      },
      {
        dataIndex: "encasementAmount",
        width: 100,
        title: () => vueI18n.t('warehouse.encasement_count'),
      },
      {
        width: 120,
        slots: {
          title: "encasementWeight",
          customRender: "encasementWeightCustom",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t('finance.status'),
        slots: {
          customRender: "statusCustom",
        },
      },
      {
        width: 150,
        dataIndex: "inWarehousePlanNo",
        title: () => vueI18n.t('warehouse.inbound_warehouse_number'),
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.inbound_warehouse_time'),
        slots: {
          customRender: "inWarehouseTimeCustom",
        },
      },
      {
        width: 150,
        dataIndex: "outWarehousePlanNo",
        title: () => vueI18n.t('warehouse.outbound_plan_number'),
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.outbound_time'),
        slots: {
          customRender: "outWarehouseTimeCustom",
        },
      },
    ];

    const state = reactive({
      inventoryWarehouses: [],
      boxStatus: boxStatusEnum,
      searchType: [
        { "text": "devops.box_no", "value": "boxNo" },
        { "text": "warehouse.inbound_plan_number", "value": "inWarehousePlanNo" },
        { "text": "warehouse.outbound_plan_number", "value": "outWarehousePlanNo" },
        { "text": "warehouse.user_defined_box_no", "value": "selfBoxNo" },
      ],
      productListLoading: false,
    });

    const data = reactive({
      selectedSearchType: "boxNo",
      searchKey: "",
      selectedWarehouse: null,
      shopId: null,
      selectedStatus: null,

      tableList: [],
      outWarehousePlanNos: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
       productDetail:{
        visible: false,
        whboxId: null,
        boxNo: null,
      }
    });

    const  pinModalState= reactive({
        visible: false,
        loading: false,
        pinCode:null,
      })

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    }

    const handleSearch = async (pageData) => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcSearch();
    }

    const funcSearch = () => {
      let searchData = {
        productId: refSearchProduct.value.selectedProductId,
        warehouseId: data.selectedWarehouse,
        shopId: data.shopId,
        containerBoxStatus: data.selectedStatus,
        [data.selectedSearchType]: data.searchKey,
        ...data.pageData
      };

      state.productListLoading = true;
      getManageInventoryListPaged(searchData).then((res) => {
        if (res.result) {
          let { items, totalCount } = res.result;
          data.tableList = items.map(x => {
            return {
              key: x.id,
              boxNo: x.boxNo,
              shopName: x.shopName,
                shopId: x.shopId,
                shopNo: x.shopNo,
                shopMark: x.shopMark,
              selfBoxNo: x.selfBoxNo,
              productKindCount: x.productKindCount,
              warehouseNo: x.warehouseNo,
              imgUrl: x.productImgUrl,
              productNo: x.productNo,
              seSku: x.seSku,
              productId: x.productId,
              productName: x.productName,
              encasementSize: (x.containerLength && x.containerWidth && x.containerHeight) ? x.containerLength + 'x' + x.containerWidth + 'x' + x.containerHeight + "cm" : "-",
              inWarehouseSize: (x.checkContainerLength && x.checkContainerLength && x.checkContainerWidth) ? x.checkContainerLength + 'x' + x.checkContainerWidth + 'x' + x.checkContainerHeight + "cm" : "-",
              encasementAmount: x.containerCount,
              encasementWeight: x.containerWeight ? gToKg(x.containerWeight) + 'kg' : "-",
              inWarehouseWeight: x.checkContainerWeight ? gToKg(x.checkContainerWeight) + 'kg' : "-",
              status: x.containerBoxStatus,
              inWarehousePlanId: x.inWarehousePlanId,
              inWarehousePlanNo: x.inWarehousePlanNo || '-',
              inWarehouseTime: x.inWarehouseTime,
              outWarehousePlanId: x.outWarehousePlanId,
              outWarehousePlanNo: x.outWarehousePlanNo || '-',
              outWarehouseTime: x.lastModificationTime,
              selfBoxNo: x.selfBoxNo,
              whBoxId: x.id,
              productRls: x.productRls,
              isCanUpdateProduct: x.isCanUpdateProduct,
              transportCharacteristics: x.transportCharacteristics
            }
          })
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.tableList = [];
          data.pageData.totalCount = 0;
        }
      }).finally(() => {
        state.productListLoading = false;
      });
    }

    const funcUserOfInventoryWarehouses = async () => {
      const data = {
        warehouseType: warehouseTypeEnum.storage,
        isActive: true,
      }
      let { result } = await getOptionWarehouses(data);
      state.inventoryWarehouses = result ?? [];
    }

    const handleLookPlan = async (option) => {
      if (option.key === "inPlan") {
        router.push({ path: '/storage/inplandetails/' + option.item.planId.inWarehousePlanId });
      } else if (option.key === "outPlan") {
        router.push({ path: "/storage/outplandetails/" + option.item.planId.outWarehousePlanId });
      } else {
        hanldeShowReconnectProductModal(option.item.planId);
      }
    }

    const reconnectModal = reactive({
      loading: false,
      visible: false,
    })

    const getReconnectModal = () => {
      return {
        oldProduct: {
          imgUrl: null,
          productName: null,
          productNo: null,
          transportCharacteristics: null,
          whBoxId: null,
        },
        newProduct: {
          id: "",
          imgUrl: null,
          productName: null,
          productNo: null,
          transportCharacteristics: null,
          whBoxId: null,
        }
      }
    }

    const hanldeShowReconnectProductModal = (record) => {


      Object.assign(reconnectModal, getReconnectModal());
      Object.assign(reconnectModal.oldProduct, record);
      reconnectModal.visible = true;
      nextTick(() => {
        refSearchProductModal.value.searchProductKey = null;
        refSearchProductModal.value.selectedProductId = null;
      })

    }


    const handleShowPinModal = () => {
      pinModalState.visible = true;
      pinModalState.pinCode = null;
      pinModalState.loading = false;
    }

    const hanldeExportProductWarehouseTime=(pinCode)=>{
      pinModalState.loading = true;

      let url = exportInventoryListWithStockAge()
      const postData = {
        "productId": refSearchProduct.value.selectedProductId,
        "warehouseId": data.selectedWarehouse,
        "shopId": data?.shopId,
        "containerBoxStatus": data.selectedStatus,
        [data.selectedSearchType]: data.searchKey,
        "pinCode":pinCode
      };
      downloadFile(url, `storage_inventory_age_${dateString()}.csv`, "POST", postData)
        .then(() => {
         pinModalState.loading = false;
      pinModalState.visible = false;
        })
        .catch(() => {
      pinModalState.loading = false;
        })
    }

    const handleClickProductIoc = (record) => {
      data.productVisible = true;
      data.productDetail.boxNo = record.boxNo;
      data.productDetail.whboxId = record.key;
      data.productDetail.visible = true;
    };

    onMounted(async () => {
      funcUserOfInventoryWarehouses();
    })

    onActivated(async () => {
      funcSearch();
    })


    return {
      columns,
      ...toRefs(state),
      ...toRefs(data),
      reconnectModal,
      refSearchProduct,
      handlePage,
      funcSearch,
      handleSearch,
      handleLookPlan,
      hanldeExportProductWarehouseTime,
      pinModalState,
      handleShowPinModal,
      handleClickProductIoc
    };
  },
})
</script>

import client from "../../client"
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 入库任务列表
  getStorageInWarehouseListPaged: "/api/WarehouseService/WarehouseTaskMonitoring/GetStorageInPlanPagedList",
  // 入库任务详情
  getInWarehouse: "/api/WarehouseService/WarehouseTaskMonitoring/GetStorage",
  // 入库任务详情/轨迹列表
  getAllInWarehouseTraceList: "/api/WarehouseService/WarehouseTaskMonitoring/GetAllInWarehouseTraceList",
  // 出库任务列表
  getStorageOutWarehouseListPaged: "/api/WarehouseService/WarehouseTaskMonitoring/GetStorageOutWarehouseListPaged",
  // 出库任务详情
  getOutWarehouse: "/api/WarehouseService/WarehouseTaskMonitoring/GetOutWarehouse",
  // 取消入库
  updateCancelInPlanForConsole: "/api/WarehouseService/WarehouseTaskMonitoring/UpdateCancelInPlanForConsole",

  // 取消出库
  updateCancelOutPlanForConsole: "/api/WarehouseService/WarehouseTaskMonitoring/UpdateCancelOutPlan",

  // 存储仓-出库任务导出
  exportOutWarehousePlan: "/api/WarehouseService/WarehouseTaskMonitoring/ExportOutwarehousePlan",
  // 存储仓-入库任务导出
  exportStorageInWarehouse: "/api/WarehouseService/WarehouseTaskMonitoring/ExportStorageInWarehouse",
  // 配送仓-入库任务导出
  exportDelegateInWarehouse: "/api/WarehouseService/WarehouseTaskMonitoring/ExportDelegateInWarehouse",

  // 存储仓-换标任务-列表
  getStorageConsoleLabelChangePagedList: "/api/WarehouseService/StorageConsoleLabelChange/GetPagedList",
  // 存储仓-换标任务-详情
  getStorageConsoleLabelChange: "/api/WarehouseService/StorageConsoleLabelChange/Get",
  // 存储仓-换标任务-取消
  cancelStorageConsoleLabelChange: "/api/WarehouseService/StorageConsoleLabelChange/Cancel",
  // 存储仓-换标任务-箱-取消
  cancelStorageConsoleLabelChangeBox: "/api/WarehouseService/StorageConsoleLabelChange/CancelBox",
}

export const getStorageInWarehouseListPaged = (data) => {
  return client.request({
    url: Api.getStorageInWarehouseListPaged,
    data,
    method: 'POST'
  });
}

export const getInWarehouse = (params) => {
  return client.request({
    url: Api.getInWarehouse,
    params,
    method: 'GET'
  });
}

export const getAllInWarehouseTraceList = (params) => {
  return client.request({
    url: Api.getAllInWarehouseTraceList,
    params,
    method: 'GET'
  });
}

export const getStorageOutWarehouseListPaged = (data) => {
  return client.request({
    url: Api.getStorageOutWarehouseListPaged,
    data,
    method: 'POST'
  });
}

export const getOutWarehouse = (params) => {
  return client.request({
    url: Api.getOutWarehouse,
    params,
    method: 'GET'
  });
}

export const updateCancelInPlanForConsole = (data) => {
  return client.request({
    url: Api.updateCancelInPlanForConsole,
    data,
    method: 'POST'
  });
}

export const exportOutWarehousePlan = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportOutWarehousePlan);
  return url;
}

export const updateCancelOutPlanForConsole = (data) => {
  return client.request({
    url: Api.updateCancelOutPlanForConsole,
    data,
    method: 'POST'
  });
}

export const exportStorageInWarehouse = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportStorageInWarehouse);
  return url;
}

export const exportDelegateInWarehouse = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportDelegateInWarehouse);
  return url;
}

export const getStorageConsoleLabelChangePagedList = (data) => {
  return client.request({
    url: Api.getStorageConsoleLabelChangePagedList,
    data,
    method: 'POST'
  });
}

export const getStorageConsoleLabelChange = (params) => {
  return client.request({
    url: Api.getStorageConsoleLabelChange,
    params,
    method: 'GET'
  });
}

export const cancelStorageConsoleLabelChange = (data) => {
  return client.request({
    url: Api.cancelStorageConsoleLabelChange,
    data,
    method: 'POST'
  });
}

export const cancelStorageConsoleLabelChangeBox = (data) => {
  return client.request({
    url: Api.cancelStorageConsoleLabelChangeBox,
    data,
    method: 'POST'
  });
}
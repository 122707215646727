<template>
  <a-modal
    :title="$t('devops.sms_template')"
    v-model:visible="state.visible"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-spin :spinning="state.loading">
      <a-form
        ref="formRef"
        :model="state.formState"
        :rules="rules"
        :label-col="{ style: { width: '150px' } }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-item :label="$t('logistics.template_name')" name="templateName">
          <a-input v-model:value="state.formState.templateName" />
        </a-form-item>
        <a-form-item :label="$t('devops.notification_business_type')" name="notificationBusinessType">
          <a-select
            v-model:value="state.formState.notificationBusinessType"
            :disabled="!!props.type"
          >
            <a-select-option
              v-for="(item, index) in notificationBusinessTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('notificationBusinessType', item))"
            >{{ $t($enumLangkey('notificationBusinessType', item)) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('devops.sms_platform_template_id')" name="platformTemplateId">
          <a-input v-model:value="state.formState.platformTemplateId" />
        </a-form-item>
        <a-form-item :label="$t('devops.applicable_area_code')" name="phoneCountryCodes">
          <a-select
            v-model:value="state.formState.phoneCountryCodes"
            show-search
            option-filter-prop="search-key"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in state.templateCountryList"
              :key="index"
              :search-key="item"
              :value="item"
            >{{ item }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('devops.template_content')" name="templateContent">
          <a-textarea v-model:value="state.formState.templateContent" :auto-size="{ minRows: 4, maxRows: 6 }" />
        </a-form-item>
        <a-form-item label=" " :colon="false">
          <AttributeList :type="state.formState.notificationBusinessType" @confirm="handleSetContentAttribute" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, message, Modal, Select, Spin } from 'ant-design-vue';
import AttributeList from './AttributeList.vue';
import { useI18n } from "vue-i18n/index";
import { setFormStateValue } from '@/utils/general.js';
import {
  getAvailablePhoneCountryCodeList,
  getShopSmsNotificationTemplateById,
  createOrUpdateShopSmsNotificationTemplate,
} from '@/api/modules/devops/notice.js';
import { notificationBusinessType as notificationBusinessTypeEnum } from '@/enum/enum.json';

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATextarea: Input.TextArea,
    AttributeList,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: Number,
    id: String,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      templateCountryList: [],
      formState: {
        id: null,
        templateName: null,
        notificationBusinessType: null,
        platformTemplateId: null,
        templateContent: '',
        phoneCountryCodes: [],
      },
    })

    const rules = {
      templateName: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.template_name')]),
      },
      notificationBusinessType: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.notification_business_type')]),
      },
      platformTemplateId: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.sms_platform_template_id')]),
      },
      phoneCountryCodes: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.applicable_area_code')]),
      },
      templateContent: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.template_content')]),
      },
    }

    const handleSetContentAttribute = (item) => {
      state.formState.templateContent += ('${' + item.attributeCode + '}');
      nextTick(() => {
        formRef.value.validate(['templateContent']);
      });
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        await createOrUpdateShopSmsNotificationTemplate(data);
        message.success(vueI18n.t('common.succeed'));
        emit('confirm');
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    const init = async (id) => {
      try {
        state.loading = true;

        let { result: countryList } = await getAvailablePhoneCountryCodeList();
        if (Array.isArray(countryList)) {
          state.templateCountryList = countryList;
        }

        if (id) {
          let { result } = await getShopSmsNotificationTemplateById({ id });
          if (result) {
            setFormStateValue(state.formState, result);
          }
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    watch(() => [props.visible, props.type, props.id], ([visible, type, id]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            formRef.value.resetFields();
            state.formState.id = id || null;
            state.formState.notificationBusinessType = type || null;
            init(id);
          });
        }
      }
    }, { immediate: true })

    return {
      state,
      notificationBusinessTypeEnum,
      props,
      formRef,
      rules,
      close,
      handleSetContentAttribute,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>
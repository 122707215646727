<template>
  <Content>
    <template #contentTitle>
      {{ $t("授权项API管理") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row type="flex"
             justify="space-between">
        <a-col>
          授权项: {{data.authorizeName}}
        </a-col>
        <a-col>
          <a-button type="primary"
                    @click="handleShowModal"
                    ghost>{{
            $t("创建")
          }}</a-button>
        </a-col>
      </a-row>

      <div class=" mt-2">
        <a-table :columns="columns"
                 size="small"
                 :data-source="data.list"
                 :scroll="{ x: 400, y: wrap.contentHeight - 150 }"
                 :pagination="false"
                 :rowKey="
            (record, index) => {
              return index;
            }
          "
                 :loading="data.loading">

          <template v-slot:operate="{ record }">
            <a-popconfirm :title="$t('你确定删除吗?')"
                          :ok-text="$t('common.confirm')"
                          :cancel-text="$t('common.cancel')"
                          @confirm="hanldeDelete(record)">
              <a-button type="link">删除</a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
      <a-modal v-model:visible="modalData.visible"
               :confirm-loading="modalData.loading"
               :title="'授权项'"
               :maskClosable="false"
               @ok="handleSaveModal">
        <a-form :model="modalData"
                ref="refModal"
                :label-col="{ span: 7 }"
                :rules="rules">
          <a-form-item :label="'API路由'"
                       name="apiRouting">
            <a-input v-model:value="modalData.apiRouting"></a-input>
          </a-form-item>

          <a-form-item :label="'API描述'"
                       name="apiDescription">
            <a-textarea v-model:value="modalData.apiDescription"></a-textarea>
          </a-form-item>
        </a-form>
      </a-modal>
    </template>

  </Content>
</template>

<script>
import { reactive, onMounted, ref, nextTick } from "vue";
import {
  Row, Col,  Table,
  Button, Form, Space, Input,
  Dropdown, Popconfirm,
  Menu,
  Modal
} from "ant-design-vue";
import Content from "../components/Content.vue";
import { getList, create, deleteAuthorizeAPI } from "../../api/modules/openPlatform/authorizesAPI";
import {  useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";

export default {
  name: "open_platform_authorizes",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AForm: Form,
    AButton: Button,
    AFormItem: Form.Item,
    ASpace: Space,
    AInput: Input,
    Content,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AModal: Modal,
    ATextarea: Input.TextArea,
    APopconfirm: Popconfirm,

  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const columns = [
      {
        title: () => vueI18n.t('API路由'),
        dataIndex: "apiRouting",
      },
      {
        title: () => vueI18n.t('API描述'),
        dataIndex: "apiDescription",
      },
      {
        dataIndex: "operate",
        slots: {
          customRender: "operate",
        },
      },
    ];

    const refModal = ref();
    const data = reactive({
      id: null,
      loading: false,
      authorizeName: null,
      list: [],
    });

    const modalData = reactive({
      authorizeInfoId: null,
      visible: false,
      loading: false,
      apiRouting: null,
      apiDescription: null,
      id: null,
    });

    const handleSearch = () => {
      funcGetList();
    }

    const funcGetList = () => {
      data.loading = true;
      getList(data.id, true)
        .then(({ result }) => {
          if (result) {
            data.authorizeName = result.authorizeName;
            data.list = result.relationApis;
          } else {
            data.list = [];
          }
        })
        .finally(() => {
          data.loading = false;
        });
    }

    const handleShowModal = (record) => {
      modalData.visible = true;
      modalData.authorizeInfoId = data.id;

      nextTick(() => {
        refModal.value.resetFields();
      })
    };

    const handleSaveModal = () => {
      modalData.loading = true;
      refModal.value
        .validate()
        .then(async () => {
          return create({ ...modalData });
        })
        .then(() => {
          modalData.visible = false;
          modalData.loading = false;
          handleSearch()
        })
        .catch(() => {
          modalData.loading = false;
        });
    }

    const hanldeDelete = (record) => {
      deleteAuthorizeAPI(data.id, [record.id]).then(() => {
        funcGetList();
      }).catch(() => {

      })
    }

    const rules = {
      apiRouting: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("API路由")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      apiDescription: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("API描述")]);
          },
          trigger: ["blur", "change"],
        },
      ],
    };

    onMounted(() => {
      data.id = route.params.id;
      if (data.id) {
        handleSearch();
      }
    })

    return {
      columns,
      data,
      modalData,
      handleShowModal,
      handleSaveModal,
      handleSearch,
      rules,
      refModal,
      hanldeDelete
    };
  },
};
</script>

<style lang="less" scoped>
</style>
//示例  url = combineURLs("http://www.test.com","path1/path2/path3")
const combineURLs = (baseURL, relativeURL) => {
  return relativeURL
    ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
    : baseURL;
};

//示例1 url = addQueryString(url, 'paramName', 'paramValue');
//示例2 url = addQueryString(url, { key:'paramName', value:'paramValue'});
const addQueryString = (url, keyOrObj, value) => {
  if (url) {
    var hostAndPath = url;
    var queryString = "";
    var hash = "";
    var arr = [];
    let key = keyOrObj;
    if (keyOrObj instanceof Object) {
      key = keyOrObj.key;
      value = keyOrObj.value;
    }
    if (hostAndPath.indexOf("#") >= 0) {
      arr = hostAndPath.split("#");
      hostAndPath = arr[0];
      hash = arr[1];
    }
    if (hostAndPath.indexOf("?") >= 0) {
      arr = hostAndPath.split("?");
      hostAndPath = arr[0];
      queryString = arr[1];
    }
    if ((queryString || "").trim() !== "") {
      queryString +=
        "&" +
        encodeURIComponent(key + "") +
        "=" +
        encodeURIComponent(value + "");
    } else {
      queryString =
        encodeURIComponent(key + "") + "=" + encodeURIComponent(value + "");
    }
    var returnUtl = hostAndPath + "?" + queryString;
    if (hash) {
      returnUtl += "#" + hash;
    }
    return returnUtl;
  }
  return url;
};


const getQueryVal = (url, paraName)=>{
  var urlObj = new URL(url);
  var reg = new RegExp('(^|&)' + paraName + '=([^&]*)(&|$)', 'i');
  var queryStr = urlObj.search;
  if (queryStr.indexOf("?") === 0) {
      queryStr = queryStr.substring(1);
  }
  var arr = queryStr.match(reg);
  if (arr) {
      return decodeURIComponent(arr[2]);
  }
  else {
      return null;
  }
}

const getQueryArr = (url) => {
  var result = [];
  var urlObj = new URL(url);
  var queryStr = urlObj.search;
  if (queryStr.indexOf("?") === 0) {
      queryStr = queryStr.substring(1);
  }
  if (queryStr) {
      var splitArr = queryStr.split("&");
      for (var i = 0; i < splitArr.length; i++) {
          var itemArr = splitArr[i].split("=");
          result.push({ "name": itemArr[0], "value": decodeURIComponent(itemArr[1] || "") });
      }
  }
  return result;
}

//示例 paramObj = getAliOssProcessParam(60, 60)
const getAliOssProcessParam = (tWidth, tHeight) => {
  if (!tWidth || tWidth < 0) {
    tWidth = 60;
  }
  if (!tHeight || tHeight < 0) {
    tHeight = 60;
  }
  return {
    key: "x-oss-process",
    value: "image/resize,m_lfit,h_" + tHeight + ",w_" + tWidth,
  };
};

const getUrlExtension = (url) => {
  if (url) {
    let fixedUrl = url.split("?")[0];
    fixedUrl = fixedUrl.split("#")[0];
    const lastIndex = fixedUrl.lastIndexOf(".");
    if (lastIndex >= 0) {
      return fixedUrl.slice(lastIndex).toLowerCase();
    }
    return null;
  }
};

//示例1 isPdfUrl('http://www.test.com/md5.pdf') : true
//示例2 isPdfUrl('http://www.test.com/md5.jpg') : false
const isPdfUrl = (url) => {
  const ext = getUrlExtension(url);
  return ext && [".pdf"].findIndex((item) => item == ext) >= 0;
};

//示例1 isImageUrl('http://www.test.com/md5.jpg') : true
//示例2 isImageUrl('http://www.test.com/md5.pdf') : false
const isImageUrl = function (url) {
  const ext = getUrlExtension(url);
  return (
    ext &&
    [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".webp"].findIndex(
      (item) => item == ext
    ) >= 0
  );
};

const isExcelUrl = function (url) {
  const ext = getUrlExtension(url);
  return (
    ext &&
    [".xls", ".xlsx", ".xlsb", ".xlsm", ".xlst"].findIndex(
      (item) => item == ext
    ) >= 0
  );
};

const isWordUrl = function (url) {
  const ext = getUrlExtension(url);
  return (
    ext &&
    [".doc", ".docm", ".docx", ".dot", ".dotm", ".dotx"].findIndex(
      (item) => item == ext
    ) >= 0
  );
};

const isTxtUrl = function (url) {
  const ext = getUrlExtension(url);
  return ext && [".txt"].findIndex((item) => item == ext) >= 0;
};

export default {
  combineURLs,
  addQueryString,
  getAliOssProcessParam,
  getUrlExtension,
  isPdfUrl,
  isImageUrl,
  isExcelUrl,
  isWordUrl,
  isTxtUrl,
  getQueryVal,
  getQueryArr,
};

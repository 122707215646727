import client from "../../client"

const Api = {
  // 获取计划配置
  getTransportPlanConsoleConfig: "/api/WarehouseService/TransportPlanConsole/GetConfig",
  // 修改计划配置
  editTransportPlanConsoleConfig: "/api/WarehouseService/TransportPlanConsole/EditConfig",
}

export const getTransportPlanConsoleConfig = () => {
  return client.request({
    url: Api.getTransportPlanConsoleConfig,
    method: 'GET'
  })
}

export const editTransportPlanConsoleConfig = (data) => {
  return client.request({
    url: Api.editTransportPlanConsoleConfig,
    data,
    method: 'POST'
  })
}
import client from "../../client"

const Api = {
  // 创建省份AbbrCode转换配置
  createShipmentProvinceExchangeConfig: "/api/SupplierService/ShipmentProvinceExchangeConfig/Create",
  // 获取列表-省份AbbrCode转换配置
  updateShipmentProvinceExchangeConfig: "api/SupplierService/ShipmentProvinceExchangeConfig/Update",
  // 获取列表-省份AbbrCode转换配置
  deleteShipmentProvinceExchangeConfig: "/api/SupplierService/ShipmentProvinceExchangeConfig/Delete",
  // 获取列表-省份AbbrCode转换配置
  getShipmentProvinceExchangeConfigPagedList: "/api/SupplierService/ShipmentProvinceExchangeConfig/GetPagedList",
}

export const createShipmentProvinceExchangeConfig = data => {
  return client.request({
    url: Api.createShipmentProvinceExchangeConfig,
    data,
    method: 'POST'
  })
}

export const updateShipmentProvinceExchangeConfig = data => {
  return client.request({
    url: Api.updateShipmentProvinceExchangeConfig,
    data,
    method: 'PUT'
  })
}

export const deleteShipmentProvinceExchangeConfig = data => {
  return client.request({
    url: Api.deleteShipmentProvinceExchangeConfig,
    data,
    method: 'DELETE'
  })
}

export const getShipmentProvinceExchangeConfigPagedList = data => {
  return client.request({
    url: Api.getShipmentProvinceExchangeConfigPagedList,
    data,
    method: 'POST'
  })
}
<template>
  <Content>
    <template #contentTitle>{{ $t("menu.products_transit_inventory") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input-group compact style="width: 300px">
            <a-select
              v-model:value="searchTypeState.searchKeyType"
              :placeholder="$t('')"
              style="width: 40%"
            >
              <a-select-option
                v-for="(item, index) in searchKeyList"
                :key="index"
                :value="item.key"
                :title="$t(item.title)"
                >{{ $t(item.title) }}</a-select-option
              >
            </a-select>
            <a-input
              v-model:value="searchTypeState.searchKey"
              :placeholder="$t('account.please_input')"
              style="width: 60%"
              allow-clear
            ></a-input>
          </a-input-group>
        </a-col>
        <a-col>
          <CSearchShopWidthProps v-model:shopId="searchState.shopId" ></CSearchShopWidthProps>
        </a-col>
        <a-col>
          <CSearchShopWidthProps v-model:shopId="searchState.representedShopId" :placeholder="$t('account.third_party_company_name') + '/NO/' + $t('common.shop_mark')"></CSearchShopWidthProps>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.status"
            :placeholder="$t('warehouse.status')"
            style="width: 250px"
            allow-clear
          >
            <template
              v-for="(item, index) in transitShipmentDetailStatusEnum"
              :key="index"
            >
              <a-select-option
                v-if="item !== transitShipmentDetailStatusEnum.inCancel && item !== transitShipmentDetailStatusEnum.inAbnormal"
                :value="item"
                :title="$t($enumLangkey('transitShipmentDetailStatus', item))"
                >{{
                  $t($enumLangkey("transitShipmentDetailStatus", item))
                }}</a-select-option
              >
            </template>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.destinationType"
            :placeholder="$t('warehouse.out_warehouse_type')"
            style="width: 250px"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in destinationTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('destinationType', item))"
              >{{ $t($enumLangkey("destinationType", item)) }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-input-group compact style="width: 350px">
            <a-select
              v-model:value="searchTypeState.searchDateType"
              :placeholder="$t('')"
              style="width: 40%"
            >
              <a-select-option
                v-for="(item, index) in searchDateKeyList"
                :key="index"
                :value="item.key.join(',')"
                :title="$t(item.title)"
                >{{ $t(item.title) }}</a-select-option
              >
            </a-select>
            <a-range-picker
              style="width: 60%"
              allow-clear
              v-model:value="searchTypeState.searchDate"
            ></a-range-picker>
          </a-input-group>
        </a-col>
        <a-col>
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>
              <span>{{ $t('warehouse.date_of_reservation') }}:</span>
            </a-col>
            <a-col>
              <a-input-group compact style="width: 350px">
                <a-select
                  v-model:value="searchState.warehouseId"
                  :placeholder="$t('logistics.warehouse_code')"
                  :show-search="true"
                  option-filter-prop="search-key"
                  allow-clear
                  style="width: 40%"
                  @change="handleWarehouseChange"
                >
                  <a-select-option
                    v-for="(item, index) in warehouseList"
                    :key="index"
                    :title="item.warehouseNo + '(' + item.warehouseName + ')'"
                    :value="item.id"
                    :search-key="item.warehouseNo + item.warehouseName"
                    >{{ item.warehouseNo + '(' + item.warehouseName + ')' }}</a-select-option
                  >
                </a-select>
                <a-range-picker
                  style="width: 60%"
                  allow-clear
                  v-model:value="searchTypeState.scheduleDeliveryTime"
                  :disabled="!searchState.warehouseId"
                ></a-range-picker>
              </a-input-group>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button
            type="primary"
            :loading="tableData.loading"
            @click="handleSearch"
            >{{ $t("common.search") }}</a-button
          >
        </a-col>
      </a-row>
      <a-table
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 170 }"
        :pagination="false"
        size="small"
        :row-key="(record) => record.id"
        :loading="tableData.loading"
        class="mt-3"
      >
        <template #boxTitle>
          <div>{{ $t("warehouse.identifiable_box_number") }}</div>
          <div>{{ $t("warehouse.box_id") }}</div>
        </template>
        <template #shipmentTitle>
          <div>{{ $t("warehouse.shipment_name") }}</div>
          <div>{{ $t("warehouse.se_shipment_number") }}</div>
        </template>
        <template #dataTitle>
          <div>{{ $t("warehouse.forecast_data") }}</div>
          <div>{{ $t("warehouse.inbound_data") }}</div>
        </template>
        <template #planNoTitle>
          <div>{{ $t("warehouse.warehousing_reservation_number") }}</div>
          <div>{{ $t("warehouse.outbound_appointment_number") }}</div>
        </template>
        <template #deliveryTimeTitle>
          <div class="mr-2">{{ $t("warehouse.date_of_reservation") }}</div>
          <a-tag color="warning">{{ $t("warehouse.local_time") }}</a-tag>
        </template>
        <template #boxNumberCustomer="{ record }">
          <div v-if="record.whBox?.box">{{ record.whBox.box.selfBoxNo }}</div>
          <div v-else>-</div>
          <div>{{ record.whBox?.boxNo || "-" }}</div>
        </template>
        <template #shipmentCustomer="{ record }">
          <div>{{ record.transitShipment?.name || "-" }}</div>
          <div>{{ record.transitShipment?.seShipmentNo || "-" }}</div>
        </template>
        <template #representShop="{ record }">
          <template v-if="record.transitShipment && record.transitShipment.representShop">
            <div>{{ record.transitShipment.representShop.shopName || '-' }}</div>
            <div>NO: {{ record.transitShipment.representShop.shopNo || '-' }}</div>
            <div>{{$t('common.shop_mark')}}: {{ record.transitShipment.representShop.shopMark || '-' }}</div>
          </template>
          <span v-else>-</span>
        </template>
        <template #representedShop="{ record }">
          <template v-if="record.transitShipment && record.transitShipment.representedShop">
            <div>{{ record.transitShipment.representedShop.shopName || '-' }}</div>
            <div>NO: {{ record.transitShipment.representedShop.shopNo || '-' }}</div>
            <div>{{$t('common.shop_mark')}}: {{ record.transitShipment.representedShop.shopMark || '-' }}</div>
          </template>
          <span v-else>-</span>
        </template>
        <template #boxDataCustomer="{ record }">
          <div v-if="record.whBox?.box">
            {{ record.whBox.box.containerLength }}x{{
              record.whBox.box.containerWidth
            }}x{{ record.whBox.box.containerHeight }}cm |
            {{ cmCubicToM(record.whBox.box.volume) }}m<sup>3</sup>
            {{ gToKg(record.whBox.box.containerWeight) }}kg
          </div>
          <div v-else>-</div>
          <div
            v-if="
              record.whBox?.boxCheckType !== null &&
              record.whBox.boxCheckType !== boxCheckTypeEnum.notVerified
            "
            class="text-success"
          >
            {{ record.whBox.checkContainerLength }}x{{
              record.whBox.checkContainerWidth
            }}x{{ record.whBox.checkContainerHeight }}cm |
            {{ cmCubicToM(record.whBox.checkVolume) }}m<sup>3</sup>
            {{ gToKg(record.whBox.checkContainerWeight) }}kg
          </div>
          <div v-else>-</div>
        </template>
        <template #status="{ record }">
          {{ $t($enumLangkey("transitShipmentDetailStatus", record.status)) }}
        </template>
        <template #warehouse="{ record }">
          {{ record.warehouseNo || '-' }}
        </template>
        <template #warehousePositionRoute="{ record }">
          {{ record.whBox.warehousePositionRoute || '-' }}
        </template>
        <template #planNo="{ record }">
          <div><CPlanNumberItem :no="record.transitShipment.inPlanNo" /></div>
          <div><CPlanNumberItem :no="record.transitShipment.outPlanNo" /></div>
        </template>
        <template #inBoundTime="{ record }">
          {{ $filters.utcToCurrentTime(record.inBoundTime) }}
        </template>
        <template #destinationType="{ record }">
          <div v-if="record.transitShipment.destinationType">
            {{
              $t(
                $enumLangkey(
                  "destinationType",
                  record.transitShipment.destinationType
                )
              )
            }}
          </div>
          <span v-else>-</span>
          <div>
            {{
              record.transitShipment.transitBoundPlanOutAddress
                ?.fromOrToWarehouseNo || "-"
            }}
          </div>
        </template>
        <template #transitBoundPlanOutAddress="{ record }">
          <span v-if="record.transitShipment.transitBoundPlanOutAddress">
            <div>
              {{ record.transitShipment.transitBoundPlanOutAddress.linkMan || '-' }}
              <span v-if="record.transitShipment.transitBoundPlanOutAddress.linkManPhone">
                {{ record.transitShipment.transitBoundPlanOutAddress.linkManPhone }}
              </span>
            </div>
            <div>
              {{
                getLanguageAddress(
                  record.transitShipment.transitBoundPlanOutAddress
                )
              }}
            </div>
          </span>
          <span v-else>-</span>
        </template>
        <template #scheduleDeliveryTime="{ record }">
          <template v-if="record.transitShipment.transitDeliveryMethod === transitDeliveryMethodEnum.takeTheir">
            {{
              $filters.utcToSpecificTime(
                record.transitShipment.scheduleDeliveryTime,
                record.transitShipment.warehouseTimezone
              )
            }}
          </template>
          <template v-else>
            <template v-if="record.transitShipment.scheduleDeliveryType">
              <div
                v-if="
                  record.transitShipment.scheduleDeliveryType ===
                  scheduleDeliveryTypeEnum.anytime
                "
                >{{
                  $t(
                    $enumLangkey(
                      "scheduleDeliveryType",
                      scheduleDeliveryTypeEnum.anytime
                    )
                  )
                }}</div
              >
              <div
                v-else-if="
                  record.transitShipment.scheduleDeliveryType ===
                  scheduleDeliveryTypeEnum.date
                "
              >
                {{
                  $filters.utcToSpecificTime(
                    record.transitShipment.scheduleDeliveryTime,
                    record.transitShipment.warehouseTimezone,
                    "yyyy-MM-DD"
                  )
                }}
              </div>
              <div
                v-else-if="
                  record.transitShipment.scheduleDeliveryType ===
                  scheduleDeliveryTypeEnum.dateTime
                "
              >
                {{
                  $filters.utcToSpecificTime(
                    record.transitShipment.scheduleDeliveryTime,
                    record.transitShipment.warehouseTimezone
                  )
                }}
              </div>
            </template>
            <span v-else>-</span>
          </template>
          <ScheduleTimeTypeTag
            v-if="record.transitShipment.scheduleTimeType"
            :type="record.transitShipment.scheduleTimeType"
          />
        </template>
        <template #outBoundTime="{ record }">
          {{ $filters.utcToCurrentTime(record.outBoundTime) }}
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="tableData.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Table,
  Tag,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CSearchShopWidthProps from "@/views/components/CSearchShopWidthProps.vue";
import ScheduleTimeTypeTag from "@/views/components/ScheduleTimeTypeTag.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import {
  currentTimeToUtc,
  gToKg,
  cmCubicToM,
  getAddressByLanguageV2,
} from "@/utils/general";
import { getWarehouses } from '@/api/modules/common/index.js';
import { getTransitShipmentDetailListPaged } from "@/api/modules/products/inventory.js";
import {
  warehouseType as warehouseTypeEnum,
  destinationType as destinationTypeEnum,
  scheduleDeliveryType as scheduleDeliveryTypeEnum,
  transitShipmentDetailStatus as transitShipmentDetailStatusEnum,
  boxCheckType as boxCheckTypeEnum,
  scheduleTimeType as scheduleTimeTypeEnum,
  transitDeliveryMethod as transitDeliveryMethodEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "products_transit_inventory",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATag: Tag,
    Content,
    CPager,
    CSearchShopWidthProps,
    ScheduleTimeTypeTag,
    CPlanNumberItem,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const searchKeyList = [
      {
        key: "canScanedBoxNo",
        title: "warehouse.identifiable_box_number",
      },
      {
        key: "boxNo",
        title: "warehouse.setb_box_number",
      },
      {
        key: "seShipmentNo",
        title: "warehouse.se_shipment_number",
      },
      {
        key: "shipmentName",
        title: "warehouse.shipment_name",
      },
      {
        key: "inPlanNo",
        title: "warehouse.warehousing_reservation_number",
      },
      {
        key: "outPlanNo",
        title: "warehouse.outbound_appointment_number",
      },
    ];

    const searchDateKeyList = [
      {
        key: ["inBoundTimeBegin", "inBoundTimeEnd"],
        title: "warehouse.inbound_date",
        timezone: "timezone",
      },
      {
        key: ["outBoundTimeBegin", "outBoundTimeEnd"],
        title: "warehouse.outbound_time",
        timezone: "timezone",
      },
    ];

    const state = reactive({
      searchTypeState: {
        searchKeyType: searchKeyList[0].key,
        searchKey: null,
        searchDateType: searchDateKeyList[0].key.join(","),
        searchDate: [],
        // 预约日期
        scheduleDeliveryTime: [],
      },
      searchState: {
        canScanedBoxNo: null,
        boxNo: null,
        seShipmentNo: null,
        shipmentName: null,
        inPlanNo: null,
        outPlanNo: null,
        status: null,
        destinationType: null,
        warehouseId: null,
        inBoundTimeBegin: null,
        inBoundTimeEnd: null,
        // 预约日期
        scheduleDeliveryTimeBegin: null,
        scheduleDeliveryTimeEnd: null,
        outBoundTimeBegin: null,
        outBoundTimeEnd: null,
        // 是否需要目的地址
        isNeedOutAddress: true,
        // 是否需要仓库信息
        isNeedWarehouse: true,
        // 客户编号/名称
        shopId: null,
        representedShopId: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      warehouseLoading: false,
      warehouseList: [],
    });

    const columns = [
      {
        width: 150,
        fixed: "left",
        slots: {
          title: "boxTitle",
          customRender: "boxNumberCustomer",
        },
      },
      {
        width: 150,
        fixed: "left",
        slots: {
          title: "shipmentTitle",
          customRender: "shipmentCustomer",
        },
      },
      {
        width: 170,
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "representShop",
        },
      },
      {
        width: 170,
        title: () => vueI18n.t("warehouse.third_party_customer"),
        slots: {
          customRender: "representedShop",
        },
      },
      {
        width: 230,
        slots: {
          title: "dataTitle",
          customRender: "boxDataCustomer",
        },
      },
      {
        title: () => vueI18n.t("warehouse.status"),
        width: 100,
        slots: {
          customRender: "status",
        },
      },
      {
        title: () => vueI18n.t("logistics.warehouse_code"),
        width: 120,
        slots: {
          customRender: "warehouse",
        },
      },
      {
        title: () => vueI18n.t("warehouse.current_storage_location"),
        width: 100,
        slots: {
          customRender: "warehousePositionRoute",
        },
      },
      {
        width: 150,
        slots: {
          title: "planNoTitle",
          customRender: "planNo",
        },
      },
      {
        title: () => vueI18n.t("warehouse.inbound_date"),
        width: 150,
        slots: {
          customRender: "inBoundTime",
        },
      },
      {
        title: () => vueI18n.t("warehouse.out_warehouse_type"),
        width: 100,
        slots: {
          customRender: "destinationType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.outbound_destination"),
        width: 250,
        slots: {
          customRender: "transitBoundPlanOutAddress",
        },
      },
      {
        width: 150,
        slots: {
          title: "deliveryTimeTitle",
          customRender: "scheduleDeliveryTime",
        },
      },
      {
        title: () => vueI18n.t("warehouse.outbound_time"),
        width: 150,
        slots: {
          customRender: "outBoundTime",
        },
      },
    ];

    const handleWarehouseChange = () => {
      if (!state.searchState.warehouseId) {
        state.searchTypeState.scheduleDeliveryTime = [];
      }
    }

    const getLanguageAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const getPageList = () => {
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      state.tableData.loading = true;
      getTransitShipmentDetailListPaged(data)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {})
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      for (let i = 0; i < searchKeyList.length; i++) {
        let key = searchKeyList[i].key;
        if (Object.hasOwnProperty.call(state.searchState, key)) {
          if (key === state.searchTypeState.searchKeyType) {
            state.searchState[key] = state.searchTypeState.searchKey;
          } else {
            state.searchState[key] = null;
          }
        }
      }

      let userInfo = getters.userInfo;
      for (let i = 0; i < searchDateKeyList.length; i++) {
        let keys = searchDateKeyList[i].key;
        let timezone = searchDateKeyList[i].timezone;
        let searchDate = state.searchTypeState.searchDate;
        if (
          keys.join(",") === state.searchTypeState.searchDateType &&
          searchDate.length === 2
        ) {
          if (Object.hasOwnProperty.call(state.searchState, keys[0])) {
            state.searchState[keys[0]] = currentTimeToUtc(
              searchDate[0]?.format("YYYY-MM-DD 00:00:00"),
              userInfo[timezone]
            );
          }
          if (Object.hasOwnProperty.call(state.searchState, keys[1])) {
            state.searchState[keys[1]] = currentTimeToUtc(
              searchDate[1]?.format("YYYY-MM-DD 00:00:00"),
              userInfo[timezone]
            );
          }
        } else {
          if (Object.hasOwnProperty.call(state.searchState, keys[0])) {
            state.searchState[keys[0]] = null;
          }
          if (Object.hasOwnProperty.call(state.searchState, keys[1])) {
            state.searchState[keys[1]] = null;
          }
        }
      }

      // 单独处理预约日期
      let scheduleDeliveryTime = state.searchTypeState.scheduleDeliveryTime;
      if (scheduleDeliveryTime.length === 2) {
        let warehouse = state.warehouseList.find(item => item.id === state.searchState.warehouseId);
        if (warehouse) {
          let timezone = warehouse.timezone;
          state.searchState.scheduleDeliveryTimeBegin = currentTimeToUtc(scheduleDeliveryTime[0]?.format("YYYY-MM-DD 00:00:00"), timezone);
          state.searchState.scheduleDeliveryTimeEnd = currentTimeToUtc(scheduleDeliveryTime[1]?.format("YYYY-MM-DD 00:00:00"), timezone);
        }
      } else {
        state.searchState.scheduleDeliveryTimeBegin = null;
        state.searchState.scheduleDeliveryTimeEnd = null;
      }

      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const getWarehouseList = () => {
      state.warehouseLoading = true;
      const data = {
        warehouseType: warehouseTypeEnum.transitWarehouse,
      }
      getWarehouses(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        } else {
          state.warehouseList = [];
        }
      }).catch(() => {}).finally(() => {
        state.warehouseLoading = false;
      });
    }

    onMounted(() => {
      getWarehouseList();
      handleSearch();
    });

    return {
      ...toRefs(state),
      gToKg,
      cmCubicToM,
      destinationTypeEnum,
      scheduleDeliveryTypeEnum,
      transitShipmentDetailStatusEnum,
      boxCheckTypeEnum,
      scheduleTimeTypeEnum,
      transitDeliveryMethodEnum,
      searchKeyList,
      searchDateKeyList,
      columns,
      handleWarehouseChange,
      getLanguageAddress,
      handleSearch,
      handlePage,
    };
  },
});
</script>
<style scoped></style>

export default {
  "menu": {
    "dashboard": "Dashborad",
    "account_info": "Account info",
    "warehouse_manager": "Warehouse Management",
    "warehouse_device_manager": "Warehouse Facility Management",
    "warehouse_fee_setting": "Charges Configuration",
    "warehouse_monitor": "Warehouse Task Monitor",
    "warehouse_monitor_storage_outbound_task": "Storage Warehouse-Outbound Task",
    "warehouse_monitor_storage_outbound_task_detail": "Storage Warehouse-Outbound Task Details",
    "warehouse_monitor_storage_warehousing_task": "Storage Warehouse-Inbound Task",
    "warehouse_monitor_storage_warehousing_task_detail": "Storage Warehouse-Inbound Task Details",
    "warehouse_monitor_consignment_warehousing_task": "Distribution Warehouse-Inbound Task",
    "warehouse_monitor_consignment_warehousing_task_detail": "Distribution Warehouse-Inbound Task Details",
    "warehouse_monitor_consignment_order_task": "Distribution Warehouse-Distribution Order",
    "warehouse_monitor_consignment_order_task_detail": "Distribution Warehouse-Distribution Order Details",
    "warehouse_monitor_unload": "Discharge Task",
    "warehouse_monitor_return": "Return Product Inbound Task",
    "warehouse_monitor_return_detail": "Return Plan Details",
    "warehouse_monitor_print_waybill": "Label List",
    "warehouse_monitor_print_waybill_detail": "Label Details",
    "warehouse_monitor_allot_plan": "Distribution Warehouse-Allocation Task",
    "warehouse_monitor_allot_plan_detail": "Allocation Task Details",
    "warehouse_monitor_consignment_return_temporary_storage": "配送仓-退货暂存管理",
    "products": "Product",
    "product_management": "Product management",
    "products_verify_data": "Verifying Data Application",
    "products_storage_inventory": "Inventory in Storage Warehouse.",
    "products_consignment_inventory": "Inventory in Distribution Warehouse.",
    "products_edit": "Modify product info.",
    "logistics_config": "Logistics Configuration",
    "logistics_channel": "Logistics Channel",
    "logistics_region": "Logistics Zone",
    "logistics_region_edit": "Zone Configuration",
    "logistics_fee_template": "Freight Template",
    "logistics_fee_config": "Charges Configuration",
    "logistics_surcharge": "Surcharge Template",
    "logistics_surcharge_edit": "Surcharge Configuration",
    "special_region": "Special Zone",
    "special_region_edit": "Special Zone Configuration",
    "logistics_account": "Logistics Account Configuration",
    "logistics_embargo_configuration": "Embargo Configuration",
    "logistics_limited_transportation_configuration": "Limited-shipping Configuration",
    "logistics_discount": "Logistics Discount",
    "logistics_address_api": "Logistics Address Inquiry API",
    "supplier_config": "Supplier Configuration",
    "supplier_channel": "Supplier Channel",
    "supplier_region": "Supplier Zone",
    "supplier_region_edit": "Supplier Zone Configuration",
    "supplier_fee_template": "Supplier Freight Template",
    "supplier_fee_config": "Supplier Freight Configuration",
    "supplier_surcharge": "Supplier Surcharge Template",
    "supplier_surcharge_edit": "Supplier Surcharge Configuration",
    "supplier_special_region": "Supplier Special Zone",
    "supplier_special_region_edit": "Supplier Special Zone Configuration",
    "supplier_built_in_channel": "Supplier Built-in Channel Management",
    "supplier_embargo_configuration": "Supplier Embargo Configuration",
    "supplier_limited_transportation_configuration": "Supplier Limited-shipping Configuration",
    "supplier_discount": "Supplier Discount",
    "supplier_address_api": "Supplier Address Inquiry API",
    "supplier_print_waybill_api": "Supplier Shipping Label Print API",
    "right_module": "Authority Management",
    "role_manager": "Character Management",
    "right_manager": "Authority Menu Management",
    "staff_organizational": "Employee & Organization Structure",
    "account_restpwd": "Reset password",
    "account_center": "Account center",
    "settings": "Settings",
    "about": "About",
    "sdas": "SDAS",
    "transport": "Shipping Plan",
    "transport_detail": "Shipping Plan Details",
    "operate_console": "Operation Platform",
    "operate_console_inbound": "Inbound Operation Platform",
    "operate_console_package": "Packaging Operation Platform",
    "operate_console_transfer": "Transferring Operation Platform",
    "operate_console_outbound": "Outbound Operation Platform",
    "operate_console_delivery": "Hand Over Operation Platform",
    "task": "Task",
    "task_inbound_task": "Inbound Task",
    "task_outbound_task": "Outbound Task",
    "task_transfer": "Transfer Task",
    "task_survey": "Investigation Task",
    "task_my_transfer": "My Transfer Task",
    "task_anomaly_of_scan": "Scanning Exception Task",
    "error": "Error page",
    "error_403": "403",
    "error_404": "404",
    "error_500": "500",
    "permission": "System permission",
    "warehouse_transferlog": "Transferring Record",
    "inboundPlanDetails": "Inbound Details",
    "outboundPlanDetails": "Outbound details",
    "warehouse_inventory_management": "Inventory Management",
    "location_configuration": "Location Configuration",
    "area_status": "Area Status Setting",
    "barcode_operation_task": "Barcode Handling Task",
    "location_management": "Location Management",
    "create_the_move_task": "Create Transfer Task",
    "area_management": "Area Management",
    "location_barcode_manipulation": "Location Barcode Handling",
    "location_type_configuration": "Location Type Configuration",
    "customer": "Client Management",
    "customer_account_list": "Account List",
    "customer_account_details": "Account Details",
    "customer_company_list": "Company List",
    "customer_company_details": "Company Details",
    "customer_level_list": "Account Class",
    "customer_company_discount_list": "公司仓库折扣",
    "customer_company_discount_details": "折扣详情",
    "customer_company_transaction_record": "交易记录",
    "finance": "Finance",
    "finance_accounting_list": "Shipping Order Accounting List",
    "finance_accounting_cost": "Cost Accounting",
    "finance_accounting_details": "Cost Details",
    "finance_recharge": "Client Recharge",
    "finance_inventory": "Expense statement",
    "finance_details": "Expense details",
    "finance_customer_balance": "客户余额",
    "finance_order_waybill": "配送订单运单",
    "dev_ops": "Operation and maintenance",
    "dev_ops_ali_log": "Ali Log",
    "dev_box_edit_list": "Modification record of carton data.",
    "dev_scan_exception": "Scan Exception",
    "dev_api_manage": "API Management",
    "dev_currency_configuration_list": "货币配置",
    "dev_abbr_code_convert": "省份或州代码转换",
    "dev_export_log": "导出记录",
    "partner": "Partner",
    "partner_list": "Partner List",
    "partner_details": "Partner Details",
    "partner_global_set": "Overall Commission Setting",
    "partner_group_manage": "Group Management",
    "partner_group_set": "Group Commission Setting",
    "partner_settlement_record": "Settlement Record",
    "open_platform": "开放平台",
    "open_platform_authorizes": "授权项列表",
    "open_platform_authorize_api": "授权项API管理",
    "open_platform_applys": "应用列表",
    "open_platform_applys_company": "应用授权租户管理"
  }
}
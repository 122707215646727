import client from "../../client"

const Api = {
  // 获取供应商折扣列表
  getPagedList: "/api/SupplierService/SupplierDiscount/GetPagedList",
  // 创建供应商折扣
  createSupplierDiscount: "/api/SupplierService/SupplierDiscount/Create",
  // 修改供应商折扣
  updateSupplierDiscount: "/api/SupplierService/SupplierDiscount/Update",
  // 删除供应商折扣
  deleteSupplierDiscount: "/api/SupplierService/SupplierDiscount/Delete",
  // 根据ld获取折扣信息
  getSupplierDiscountById: "/api/SupplierService/SupplierDiscount/GetById",
}

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: 'POST'
  });
}

export const createSupplierDiscount = (data) => {
  return client.request({
    url: Api.createSupplierDiscount,
    data,
    method: 'POST'
  });
}

export const updateSupplierDiscount = (data) => {
  return client.request({
    url: Api.updateSupplierDiscount,
    data,
    method: 'POST'
  });
}

export const deleteSupplierDiscount = (data) => {
  return client.request({
    url: Api.deleteSupplierDiscount,
    data,
    method: 'POST'
  });
}

export const getSupplierDiscountById = (params) => {
  return client.request({
    url: Api.getSupplierDiscountById,
    params,
    method: 'GET'
  });
}
import client from "../../client";

const Api = {
  getList: "/api/OpenPlatformService/AuthorizeInfo/GetById",
  create: "/api/OpenPlatformService/AuthorizeInfo/AddApi",
  deleteAuthorizeAPI: "/api/OpenPlatformService/AuthorizeInfo/RemoveApi",
};

export const getList = (id,isIncludeApis) => {
  return client.request({
    url: Api.getList,
    params: {
      id,isIncludeApis
    },
    method: "GET",
  });
};

export const create = ({ authorizeInfoId, apiRouting, apiDescription }) => {
  return client.request({
    url: Api.create,
    data: { authorizeInfoId, apiRouting, apiDescription },
    method: "POST",
  });
};

export const deleteAuthorizeAPI = (
  authorizeInfoId,
  authorizeInfoAndAPIRelationIds
) => {
  return client.request({
    url: Api.deleteAuthorizeAPI,
    data: { authorizeInfoId, authorizeInfoAndAPIRelationIds },
    method: "POST",
  });
};

import client from "../../client"

const Api = {
  // 获取物流折扣列表
  getPagedList: "/api/LogisticsService/LogisticsDiscount/GetPagedList",
  // 创建物流折扣
  createLogisticsDiscount: "/api/LogisticsService/LogisticsDiscount/Create",
  // 修改物流折扣
  updateLogisticsDiscount: "/api/LogisticsService/LogisticsDiscount/Update",
  // 删除物流折扣
  deleteLogisticsDiscount: "/api/LogisticsService/LogisticsDiscount/Delete",
  // 根据ld获取折扣信息
  getLogisticsDiscountById: "/api/LogisticsService/LogisticsDiscount/GetById",
}

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: 'POST'
  });
}

export const createLogisticsDiscount = (data) => {
  return client.request({
    url: Api.createLogisticsDiscount,
    data,
    method: 'POST'
  });
}

export const updateLogisticsDiscount = (data) => {
  return client.request({
    url: Api.updateLogisticsDiscount,
    data,
    method: 'POST'
  });
}

export const deleteLogisticsDiscount = (data) => {
  return client.request({
    url: Api.deleteLogisticsDiscount,
    data,
    method: 'POST'
  });
}

export const getLogisticsDiscountById = (params) => {
  return client.request({
    url: Api.getLogisticsDiscountById,
    params,
    method: 'GET'
  });
}
<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.customer_company_transaction_record")
    }}</template>
    <template #contentBody>
      <div>
        {{ $t("account.company_name") }}:
        <span v-if="companyInfo">{{ companyInfo.shopName }}</span>
      </div>
      <a-table
        class="mt-3"
        :columns="currencyColumns"
        :data-source="currencyTable.list"
        :scroll="{ x: 500, y: 400 }"
        :pagination="false"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="currencyTable.loading"
        size="small"
      >
        <template #accountBalance="{ record }">
          <a-space :size="20">
            <span>{{ record.currencyCode }} {{ record.currencySymbol }}</span>
            <span :class="record.amount >= 0 ? 'text-success' : 'text-error'">
              {{ $filters.amountToFixed2(record.amount) }}
            </span>
          </a-space>
        </template>
        <template #overdraftLimit="{ record }">
          <a-space :size="20">
            <span>{{ record.currencyCode }} {{ record.currencySymbol }}</span>
            <span
              :class="
                record.overdraftFacility >= 0 ? 'text-success' : 'text-error'
              "
            >
              {{ $filters.amountToFixed2(record.overdraftFacility) }}
            </span>
          </a-space>
        </template>
        <template #rxpireDate="{ record }">{{
          $filters.utcToCurrentTime(record.overdraftDueDateTime)
        }}</template>
        <template #overdraftStatus="{ record }">
          <span
            :class="
              record.overdraftStatus === overdraftStatusEnum.normal
                ? 'text-success'
                : 'text-error'
            "
          >
            {{ $t($enumLangkey("overdraftStatus", record.overdraftStatus)) }}
          </span>
        </template>
        <template #totalTransactionAmount="{ record }">
          <a-space :size="20">
            <span>{{ record.currencyCode }} {{ record.currencySymbol }}</span>
            <span
              :class="
                record.totalTransactionAmount >= 0
                  ? 'text-success'
                  : 'text-error'
              "
            >
              {{ $filters.amountToFixed2(record.totalTransactionAmount) }}
            </span>
          </a-space>
        </template>
      </a-table>
      <a-tabs
        class="mt-3"
        v-model:activeKey="searchTabStateCache.balanceId"
        @change="initTabTablePage"
      >
        <a-tab-pane
          v-for="item in currencyTable.list"
          :key="item.id"
          :tab="getLanguageName(item.currencyCnName, item.currencyEnName)"
        ></a-tab-pane>
      </a-tabs>
      <a-row :gutter="[32, 16]">
        <a-col>
          <a-select
            style="min-width: 150px"
            v-model:value="searchTabState.transactionType"
            :placeholder="$t('finance.transaction_type')"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in transactionTypeList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('transactionType', item))"
              >{{ $t($enumLangkey("transactionType", item)) }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="min-width: 150px"
            v-model:value="searchTabState.costType"
            :placeholder="$t('finance.business_type')"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in businessTypeList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('businessType', item))"
              >{{ $t($enumLangkey("businessType", item)) }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-range-picker
            style="min-width: 150px"
            allowClear
            @change="handleDateChange"
          />
        </a-col>
        <a-col>
          <a-button
            type="primary"
            :loading="tabTable.loading"
            @click="handleSearch"
            >{{ $t("common.query") }}</a-button
          >
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="tabColumns"
        :data-source="tabTable.list"
        :scroll="{ x: 500 }"
        :pagination="false"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tabTable.loading"
        size="small"
      >
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #transactionType="{ record }">
          {{ $t($enumLangkey("transactionType", record.transactionType)) }}
        </template>
        <template #costType="{ record }">
          {{ $t($enumLangkey("businessType", record.costType)) }}
        </template>
        <template #countryName="{ record }">
          <span v-if="record.countryCnName && record.countryEnName">{{ getLanguageName(record.countryCnName, record.countryEnName) }}</span>
          <span v-else>-</span>
        </template>
        <template #facilityCode="{ record }">
          <span v-if="record.facilityCode">{{ record.facilityCode }}</span>
          <span v-else>-</span>
        </template>
        <template #changeAmount="{ record }">
          <span :class="record.changeAmount >= 0 ? 'text-success' : 'text-error'">{{ $filters.formatCurrencyAmount(record.currencySymbol, record.changeAmount) }}</span>
        </template>
        <template #afterBalance="{ record }">
          <span :class="record.afterBalance >= 0 ? 'text-success' : 'text-error'">{{ $filters.formatCurrencyAmount(record.currencySymbol, record.afterBalance, false) }}</span>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager
            @handlePage="handlePage"
            :page-data="tabTable.pageData"
          ></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import {
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import { getInfo } from "@/api/modules/customer/company/details";
import {
  getBalanceList,
  getManagerListPaged,
} from "@/api/modules/customer/company/transactionRecord";
import {
  overdraftStatus as overdraftStatusEnum,
  transactionType as transactionTypeEnum,
  businessType as businessTypeEnum,
} from "@/enum/enum.json";
import { currentTimeToUtc } from '@/utils/general';

export default defineComponent({
  name: "customer_company_transaction_record",
  components: {
    ATable: Table,
    ASpace: Space,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    Content,
    CPager,
  },
  setup() {
    const route = useRoute();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      shopId: "",
      companyInfo: null,
      currencyTable: {
        loading: true,
        list: [],
      },

      searchTabState: {
        transactionType: null,
        costType: null,
        startTime: null,
        endTime: null,
      },
      searchTabStateCache: {
        balanceId: "",
      },
      tabTable: {
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
        loading: true,
        list: [],
      },
    });

    const transactionTypeList = Object.values(transactionTypeEnum);
    const businessTypeList = Object.values(businessTypeEnum);

    const currencyColumns = [
      {
        title: () => vueI18n.t("account.account_balance"),
        width: 200,
        slots: {
          customRender: "accountBalance",
        },
      },
      {
        title: () => vueI18n.t("account.overdraft_limit"),
        width: 200,
        slots: {
          customRender: "overdraftLimit",
        },
      },
      {
        title: () => vueI18n.t("account.rxpire_date"),
        width: 100,
        slots: {
          customRender: "rxpireDate",
        },
      },
      {
        title: () => vueI18n.t("account.overdraft_status"),
        width: 100,
        slots: {
          customRender: "overdraftStatus",
        },
      },
      {
        title: () => vueI18n.t("account.total_transaction_amount"),
        width: 200,
        slots: {
          customRender: "totalTransactionAmount",
        },
      },
    ];

    const tabColumns = [
      {
        title: () => vueI18n.t("account.time"),
        width: 100,
        slots: {
          customRender: "creationTime",
        },
      },
      {
        dataIndex: "balanceLogNo",
        title: () => vueI18n.t("finance.trading_flow_number"),
        width: 120,
      },
      {
        title: () => vueI18n.t("finance.transaction_type"),
        width: 80,
        slots: {
          customRender: "transactionType",
        },
      },
      {
        title: () => vueI18n.t("finance.business_type"),
        width: 100,
        slots: {
          customRender: "costType",
        },
      },
      {
        dataIndex: "businessNo",
        title: () => vueI18n.t("finance.related_business_number"),
        width: 120,
      },
      {
        title: () => vueI18n.t("finance.business_country"),
        width: 80,
        slots: {
          customRender: "countryName",
        },
      },
      {
        title: () => vueI18n.t("finance.facility_code"),
        width: 100,
        slots: {
          customRender: "facilityCode",
        },
      },
      {
        title: () => vueI18n.t("account.amount"),
        width: 80,
        slots: {
          customRender: "changeAmount",
        },
      },
      {
        title: () => vueI18n.t("finance.balance"),
        width: 80,
        slots: {
          customRender: "afterBalance",
        },
      },
    ];

    const getLanguageName = (cnName, enName) => {
      return getters.language === "zh-CN" ? cnName : enName;
    };

    const handleDateChange = (e) => {
      let startTime = null;
      let endTime = null;
      if (Array.isArray(e) && e?.length > 1) {
        startTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchTabState.startTime = startTime;
      state.searchTabState.endTime = endTime;
    };

    const handlePage = (pageData) => {
      state.tabTable.pageData.skipCount = pageData.skipCount;
      state.tabTable.pageData.maxResultCount = pageData.maxResultCount;
      getListData();
    };

    const initTabTablePage = () => {
      state.tabTable.pageData.skipCount = 0;
      state.tabTable.pageData.currentIndex = 1;
      getListData();
    };

    const handleSearch = () => {
      Object.assign(state.searchTabStateCache, state.searchTabState);
      initTabTablePage();
    };

    const getListData = async () => {
      try {
        state.tabTable.loading = true;
        const data = Object.assign(
          {},
          state.searchTabStateCache,
          state.tabTable.pageData,
          {
            shopId: state.shopId,
          }
        );
        let { result } = await getManagerListPaged(data);
        if (result) {
          let { items = [], totalCount = 0 } = result;
          state.tabTable.pageData.totalCount = parseInt(totalCount);
          state.tabTable.list = items;
        }
      } catch (error) {
      } finally {
        state.tabTable.loading = false;
      }
    };

    const getData = async () => {
      try {
        state.currencyTable.loading = true;

        let { result: companyInfo } = await getInfo(state.shopId);
        state.companyInfo = companyInfo;

        let { result: currencyList } = await getBalanceList({
          shopId: state.shopId,
        });
        state.currencyTable.list = currencyList ?? [];
        if (currencyList.length > 0) {
          state.searchTabStateCache.balanceId = currencyList[0].id;
          getListData();
        }
      } catch (error) {
      } finally {
        state.currencyTable.loading = false;
      }
    };

    onMounted(() => {
      const id = route.params.id;
      state.shopId = id;
      getData();
    });

    return {
      ...toRefs(state),
      transactionTypeList,
      businessTypeList,
      currencyColumns,
      tabColumns,
      overdraftStatusEnum,
      initTabTablePage,
      getLanguageName,
      handleDateChange,
      handleSearch,
      handlePage,
    };
  },
});
</script>

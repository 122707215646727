<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.transfer_warehouse_reserve_warehousing") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row class="mb-3" :gutter="[16, 16]">
        <a-col>
          <CSearchShop v-model:shopId="searchData.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-input
            v-model:value="searchData.planName"
            :placeholder="$t('warehouse.in_warehouse_reservation_name')"
            :allowClear="true"
            style="width: 250px"
          />
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchData.planNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="18"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('warehouse.warehousing_reservation_number')"
            allowClear
            @change="handleSelectValueChange('planNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchData.boxNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="18"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('warehouse.box_id') + '/' + $t('warehouse.scannable_box_no')"
            allowClear
            @change="handleSelectValueChange('boxNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchData.seShipmentNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="18"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('warehouse.se_shipment_number')"
            allowClear
            @change="handleSelectValueChange('seShipmentNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-input
            v-model:value="searchData.shipmentNameOrNo"
            :placeholder="$t('warehouse.shipment_name')"
            :allowClear="true"
            style="width: 250px"
          />
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchData.transportationMethod"
            :placeholder="$t('warehouse.type_of_shipping')"
            allow-clear
            style="width: 250px"
          >
            <a-select-option
              v-for="(value, key) in transportationMethodEnum"
              :key="key"
              :value="value"
              :title="$t($enumLangkey('transportationMethod', value))"
            >
              {{ $t($enumLangkey("transportationMethod", value)) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchData.transitStatus"
            :placeholder="$t('warehouse.status')"
            allow-clear
            style="width: 250px"
          >
            <a-select-option
              v-for="(value, key) in transitStatusEnum"
              :key="key"
              :value="value"
              :title="$t($enumLangkey('transitStatus', value))"
            >
              {{ $t($enumLangkey("transitStatus", value)) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>{{ $t('warehouse.creation_time') }}:</a-col>
            <a-col>
              <a-range-picker
                style="width: 350px"
                @change="handleCreateDateSelected"
              />
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>{{ $t('warehouse.date_of_reservation') }}:</a-col>
            <a-col>
              <a-input-group compact style="width: 350px">
                <a-select
                  v-model:value="searchData.warehouseId"
                  :placeholder="$t('warehouse.target_warehouse')"
                  allow-clear
                  :loading="warehouseListLoading"
                  optionFilterProp="search-key"
                  :showSearch="true"
                  @change="handleChangeWarehouse"
                  style="width: 40%"
                >
                  <a-select-option
                    v-for="(value, index) in warehouseList"
                    :key="value.id"
                    :value="value.id"
                    :title="`${value.warehouseNo}(${value.warehouseName})`"
                    :search-key="value.warehouseNo + value.warehouseName"
                    :timezone="value?.timezone"
                  >
                    {{ value.warehouseNo }}({{ value.warehouseName }})
                  </a-select-option>
                </a-select>
                <a-range-picker
                  v-model:value="searchData.appointmentTime"
                  :disabled="!searchData.warehouseId"
                  style="width: 60%"
                  @change="handleSchedulingDateSelected"
                />
              </a-input-group>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleSearch">{{
            $t("common.query")
          }}</a-button>
        </a-col>
      </a-row>
      <div>
        <a-table
          :columns="columns"
          :data-source="list"
          :scroll="{ x: true, y: wrap.contentHeight - 170 }"
          :pagination="false"
          size="small"
          :loading="loading"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #customer="{ record: { shopOutputDto } }">
            {{ shopOutputDto.shopName }}
            <div>NO: {{ shopOutputDto.shopNo ?? "-" }}</div>
            <div>
              {{ $t("common.shop_mark") }}: {{ shopOutputDto.shopMark ?? "-" }}
            </div>
          </template>
          <template #planNoTitle>
            {{ $t("warehouse.in_warehouse_reservation_name") }}
            <div>
              {{ $t("warehouse.warehousing_reservation_number") }}
            </div>
          </template>
          <template #planNo="{ record }">
            {{ record.planName }}
            <div>
              {{ record.planNo }}
            </div>
          </template>
          <template #wareZoneAppointmentDate="{ record }">
            <div>
              {{
                $filters.utcToSpecificTime(
                  record.wareZoneAppointmentDate,
                  record.wareTimeZone
                )
              }}
            </div>
          </template>

          <template #transportationMethod="{ record }">
            {{
              $t(
                $enumLangkey(
                  "transportationMethod",
                  record.transportationMethod
                )
              )
            }}
            <template v-if="record.transportationMethod === transportationMethodEnum.container">
              <div>{{ record.containerNo || '-' }}</div>
              <div v-if="record.containerType">
                <a-tag>
                  {{
                    $t(
                      $enumLangkey(
                        "containerType",
                        record.containerType
                      )
                    )  
                  }}
                </a-tag>
              </div>
            </template>
          </template>

          <template #number_of_packages="{ record }">
            {{
              $filters.countProgressFormat(
                record.shipmentTotalCount?.actual,
                record.shipmentTotalCount?.plan
              )
            }}
          </template>

          <template #box_amount="{ record }">
            {{
              $filters.countProgressFormat(
                record.boxCount?.actual,
                record.boxCount?.plan
              )
            }}
          </template>
          <template #transitStatus="{ record }">
            <span
              :class="{
                'text-success':
                  record.transitStatus == transitStatusEnum.accomplish,
                'text-warning':
                  record.transitStatus == transitStatusEnum.partlyCompleted ||
                  record.transitStatus == transitStatusEnum.overfulfil,
                'text-error':
                  record.transitStatus == transitStatusEnum.failureAppointment,
              }"
            >
              {{ $t($enumLangkey("transitStatus", record.transitStatus)) }}
            </span>
            <a-tag v-if="record.isRepresented" color="blue" class="ml-1">{{ $t('account.agent') }}</a-tag>
          </template>

          <template #date>
            {{ $t("warehouse.create_date") }}
            <div>
              {{ $t("warehouse.update_date") }}
            </div>
          </template>
          <template #dateCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>
              {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
            </div>
          </template>
          <template #operateCustom="{ record }">
            <a-dropdown-button @click="handleDetails(record)">
              <span>{{ $t("common.details") }}</span>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item
                    key="1"
                    :record="record"
                    :disabled="!record.canAudited"
                  >
                    {{ $t("warehouse.accept_appointment") }}
                  </a-menu-item>
                  <a-menu-item
                    key="2"
                    :record="record"
                    :disabled="!record.canAudited"
                  >
                    {{ $t("warehouse.reject_appointment") }}
                  </a-menu-item>

                  <a-menu-item
                    key="3"
                    :record="record"
                    :disabled="!record.cancelled"
                  >
                    {{ $t("warehouse.cancel_appointment") }}
                  </a-menu-item>
                  <a-menu-item
                    v-if="
                      record.transportationMethod ==
                      transportationMethodEnum.truck
                    "
                    :disabled="!record.canPrintAppointmentLetter"
                    key="4"
                    :record="record"
                  >
                    {{ $t("warehouse.print_reserve_information") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePicCodeConfirm"
      />
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>
<script>
import { useI18n } from "vue-i18n/index";
import { defineComponent, reactive, toRefs, onMounted, onActivated } from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  DatePicker,
  Space,
  Dropdown,
  Menu,
  Popconfirm,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CSearchShop from "@/views/components/CSearchShop.vue";
import {
  getWarehouseList,
  getTransitInBounds,
  auditTransitWareInBound,
  getPrintAppointmentLetterUrl,
} from "../../../api/modules/warehouseMonitor/transit";
import {
  transitStatus as transitStatusEnum,
  transportationMethod as transportationMethodEnum,
  transitAuditStatus as transitAuditStatusEnum,
} from "../../../enum/enum.json";
import { useRouter } from "vue-router";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import { currentTimeToUtc } from "../../../utils/general";
import localPrint from "../../../utils/localPrint";

export default defineComponent({
  name: "warehouse_monitor_transit_inbound",
  components: {
    Content,
    CPager,
    CPinTipsModal,
    CSearchShop,
    ATooltip: Tooltip,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ARangePicker: DatePicker.RangePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    APopconfirm: Popconfirm,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();

    const state = reactive({
      searchData: {
        shopId: null,
        planNo: null,
        planNoes: [],
        planName: null,
        boxNoes: [],
        seShipmentNoes: [],
        shipmentNameOrNo: null,
        warehouseId: null,
        beginWareZoneAppointmentDate: null,
        endWareZoneAppointmentDate: null,
        transitStatus: null,
        transportationMethod: null,
        beginCreationTime: null,
        endCreationTime: null,
      },
      warehouseList: [],
      warehouseListLoading: false,
      list: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      pinModalState: {
        visible: false,
        loading: false,
        dataTemp: null,
      },
    });

    const columns = [
      {
        slots: {
          title: "planNoTitle",
          customRender: "planNo",
        },
        fixed: "left",
        width: 170,
      },
      {
        dataIndex: "warehouseNo",
        title: () => vueI18n.t("warehouse.in_warehouse_code"),
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer",
        },
        width: 170,
      },
      {
        title: () => vueI18n.t("warehouse.date_of_reservation"),
        slots: {
          customRender: "wareZoneAppointmentDate",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.type_of_shipping"),
        slots: {
          customRender: "transportationMethod",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.number_of_packages"),
        slots: {
          customRender: "number_of_packages",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "transitStatus",
        },
        width: 120,
      },
      {
        slots: {
          title: "date",
          customRender: "dateCustom",
        },
        width: 150,
      },
      {
        dataIndex: "operate",
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
        width: 150,
      },
    ];

    const handleSelectValueChange = (key) => {
      let value = state.searchData[key];
      if (Array.isArray(value)) {
        state.searchData[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const funcGetWarehouseList = () => {
      state.warehouseListLoading = true;
      getWarehouseList({ isActive: true })
        .then(({ result }) => {
          if (result) {
            state.warehouseList = result;
          }
        })
        .finally((res) => {
          state.warehouseListLoading = false;
        });
    };

    const funcSearch = () => {
      state.loading = true;
      var data = Object.assign({}, state.searchData, state.pageData);
      getTransitInBounds(data)
        .then(({ result }) => {
          if (result) {
            let { items = [], totalCount = 0 } = result;
            state.list = items;
            state.pageData.totalCount = totalCount * 1;
          }
        })
        .finally((res) => {
          state.loading = false;
        });
    };

    const handleCreateDateSelected = (e) => {
      let beginCreationTime = null;
      let endCreationTime = null;
      if (e.length === 2) {
        beginCreationTime = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endCreationTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchData.beginCreationTime = beginCreationTime;
      state.searchData.endCreationTime = endCreationTime;
    };

    const handleSchedulingDateSelected = (e) => {
      let beginWareZoneAppointmentDate = null;
      let endWareZoneAppointmentDate = null;
      if (e.length === 2) {
        beginWareZoneAppointmentDate = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00"),
          state.timezone
        );
        endWareZoneAppointmentDate = currentTimeToUtc(
          e[1]?.format("YYYY-MM-DD 00:00:00"),
          state.timezone
        );
      }
      state.searchData.beginWareZoneAppointmentDate =
        beginWareZoneAppointmentDate;
      state.searchData.endWareZoneAppointmentDate = endWareZoneAppointmentDate;
    };

    const handleSearch = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcSearch();
    };

    const handleDetails = (record) => {
      router.push({
        name: "warehouse_monitor_transit_inbound_detail",
        params: { id: record.planId },
      });
    };

    const handlePage = (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      const data = { planId: record.planId, auditStatus: null };
      switch (key) {
        case "1":
          // 接受预约
          data.auditStatus = transitAuditStatusEnum.accepted;
          break;
        case "2":
          // 拒绝预约
          data.auditStatus = transitAuditStatusEnum.rejected;
          break;
        case "3":
          // 取消预约
          data.auditStatus = transitAuditStatusEnum.cancelled;
          break;
        case "4":
          // 打印预约信
          handlePrint(record);
          break;
        default:
          break;
      }

      if (key == "4") {
        return;
      }

      state.pinModalState.dataTemp = data;

      // 接受预约不需要填写pin码
      if (data.auditStatus === transitAuditStatusEnum.accepted) {
        handleAuditTransitWareInBound();
      } else {
        state.pinModalState.visible = true;
      }
    };

    const handleAuditTransitWareInBound = () => {
      state.pinModalState.loading = true;
      auditTransitWareInBound(state.pinModalState.dataTemp)
        .then(() => {
          state.pinModalState.visible = false;
          message.success(vueI18n.t("common.succeed"));
          handleSearch();
        })
        .catch(() => {
          state.pinModalState.visible = false;
        })
        .finally(() => {
          state.pinModalState.loading = false;
        });
    };

    const handlePicCodeConfirm = (pinCode) => {
      state.pinModalState.dataTemp.pinCode = pinCode;
      handleAuditTransitWareInBound();
    };

    const handleChangeWarehouse = (value, expand) => {
      state.timezone = value ? expand?.timezone : null;
      if (!value) {
        state.searchData.appointmentTime = null;
        state.searchData.beginWareZoneAppointmentDate = null;
        state.searchData.endWareZoneAppointmentDate = null;
      }
    };

    const handlePrint = (record) => {
      let pdfUrl = getPrintAppointmentLetterUrl(record.planId);
      localPrint(pdfUrl);
    };

    onMounted(() => {
      funcGetWarehouseList();
    });

    onActivated(() => {
      funcSearch();
    });

    return {
      columns,
      transitStatusEnum,
      transportationMethodEnum,
      ...toRefs(state),

      handleSelectValueChange,
      handleCreateDateSelected,
      handleSchedulingDateSelected,
      handleSearch,
      handleDetails,

      handlePage,
      handleMenuClick,
      handlePicCodeConfirm,
      handleChangeWarehouse,
    };
  },
});
</script>

/// 基础路由
export default [
  {
    path: "/",
    redirect: "/dashboard",
    hidden: true,
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/accounts/Login.vue"),
    meta: {
      title: "登录",
      text: 'common.login'
    },
    hidden: true,
  },
  {
    path: "/Layout",
    name: "Layout",
    component: () => import("@/views/layout/Index.vue"),
    meta: {
      title: "Layout",
    },
    hidden: true,
  },
  // {
  //   path: "/error/404",
  //   name: "404",
  //   meta: {
  //     title: "404",
  //   },
  //   component: () => import("@/views/error/404.vue"),
  //   hidden: true,
  // },
  // {
  //   path: "/error/500",
  //   name: "500",
  //   meta: {
  //     title: "500",
  //   },
  //   component: () => import("@/views/error/500.vue"),
  // }
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/error/Error.vue"),
    meta: {
      title: "Error",
    },
    hidden: true,
    children: [
      {
        path: "403",
        name: "403",
        meta: {
          title: "403",
        },
        component: () => import("@/views/error/403.vue"),
      },
      {
        path: "404",
        name: "404",
        meta: {
          title: "404",
        },
        component: () => import("@/views/error/404.vue"),
      },
      {
        path: "500",
        name: "500",
        meta: {
          title: "500",
        },
        component: () => import("@/views/error/500.vue"),
      },
    ],
  },
];

import client from "../../client";

const Api = {
  getProductInfo: "/api/ProductService/Product/GetSkuCounts",

  getStorageAndConsignmentInfo:
    "/api/WarehouseService/InventoryStatisticsConsole/GetSdWhOverview",
  getInPlanInfo:
    "/api/WarehouseService/InventoryStatisticsConsole/GetInWhPlan",
  getOutPlanInfo:
    "/api/WarehouseService/InventoryStatisticsConsole/GetOutWhPlan",
  getTransportInfo:
    "/api/WarehouseService/InventoryStatisticsConsole/GetTranPlan",
  getOrderInfo: "/api/WarehouseService/InventoryStatisticsConsole/GetOrderOver",
};

export const getProductInfo = (startTime, endTime) => {
  return client.request({
    url: Api.getProductInfo,
    data: { startTime, endTime },
    method: "post",
  });
};

export const getStorageAndConsignmentInfo = () => {
  return client.request({
    url: Api.getStorageAndConsignmentInfo,
    method: "get",
  });
};

export const getInPlanInfo = () => {
  return client.request({
    url: Api.getInPlanInfo,
    method: "get",
  });
};
export const getOutPlanInfo = () => {
  return client.request({
    url: Api.getOutPlanInfo,
    method: "get",
  });
};

export const getTransportInfo = () => {
  return client.request({
    url: Api.getTransportInfo,
    method: "get",
  });
};

export const getOrderInfo = () => {
  return client.request({
    url: Api.getOrderInfo,
    method: "get",
  });
};

import client from "../../client";

const Api = {
  //根据物流方案，获取费用模板
  getListByLogisticsId:
    "/api/LogisticsService/FeeTemplate/GetListByLogisticsId",
  getFeeTemplates: "/api/LogisticsService/FeeTemplate/GetPagedList",
  deleteById: "/api/LogisticsService/FeeTemplate/Delete",

  //根据模板id获得费用配置信息(编辑费用配置)
  getFeeConfigInfo: "/api/LogisticsService/FeeTemplate/GetById",
  createFeeConfig: "/api/LogisticsService/FeeTemplate/Create",
  updateFeeConfig: "/api/LogisticsService/FeeTemplate/Update",
};

//根据物流方案，获取费用模板
export const getListByLogisticsId = (logisticsId) => {
  return client.request({
    url: Api.getListByLogisticsId,
    //data: { logisticsId: logisticsId },
    params: { logisticsId: logisticsId },
    method: "post",
  });
};

export const getFeeTemplates = ({
  logisticsId,
  searchKey,
  sorting,
  skipCount,
  maxResultCount,
}) => {
  return client.request({
    url: Api.getFeeTemplates,
    data: { logisticsId, searchKey, sorting, skipCount, maxResultCount },
    method: "post",
  });
};

export const deleteById = (ids) => {
  return client.request({
    url: Api.deleteById,
    data: { ids: ids },
    method: "post",
  });
};

export const createFeeConfig = ({
  id,
  logisticsId,
  name,
  feeTemplateItems,
  regionIds,
}) => {
  return client.request({
    url: Api.createFeeConfig,
    data: { id, logisticsId, name, feeTemplateItems, regionIds },
    method: "post",
  });
};

export const updateFeeConfig = ({
  id,
  logisticsId,
  name,
  feeTemplateItems,
  regionIds,
}) => {
  return client.request({
    url: Api.updateFeeConfig,
    data: { id, logisticsId, name, feeTemplateItems, regionIds },
    method: "post",
  });
};

export const getFeeConfigInfo = (templateId) => {
  return client.request({
    url: Api.getFeeConfigInfo,
    params: { id: templateId },
    method: "get",
  });
};

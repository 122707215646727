<template>
  <video
    ref="videoRef"
    :src="source"
    :autoplay="autoplay"
    :playsinline="playsinline"
    :style="videoStyle"
  />
</template>

<script>
import {
  onMounted,
  onBeforeUnmount,
  toRefs,
  watch,
  reactive,
  ref,
  nextTick,
  defineComponent,
  computed,
} from "vue";

export default defineComponent({
  name: "CWebCam",
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: 500,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    screenshotFormat: {
      type: String,
      default: "image/jpeg",
    },
    selectFirstDevice: {
      type: Boolean,
      default: true,
    },
    deviceId: {
      type: String,
      default: "",
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    resolution: {
      type: Object,
      default: null,
      validator: (value) => {
        return value.height && value.width;
      },
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      source: null,
      canvas: null,
      ctx: null,
      camerasListEmitted: false,
      cameras: [],
    });
    const videoRef = ref(null);

    const legacyGetUserMediaSupport = () => {
      return (constraints) => {
        // First get ahold of the legacy getUserMedia, if present
        let getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia;
        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }
        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    };

    /**
     * setup media
     */
    const setupMedia = () => {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = legacyGetUserMediaSupport();
      }
      testMediaAccess();
    };

    /**
     * load available cameras
     */
    const loadCameras = () => {
      if (!navigator.mediaDevices) {
        emit("notsupported", '"mediaDevices" is undfined');
      } else {
        navigator.mediaDevices
          .enumerateDevices()
          .then((deviceInfos) => {
            for (let i = 0; i !== deviceInfos.length; ++i) {
              let deviceInfo = deviceInfos[i];
              if (deviceInfo.kind === "videoinput") {
                //state.cameras.push(deviceInfo);
                state.cameras.unshift(deviceInfo);
              }
            }
          })
          .then(() => {
            if (!state.camerasListEmitted) {
              if (props.selectFirstDevice && state.cameras.length > 0) {
                emit("update:deviceId", state.cameras[0].deviceId);
              }
              emit("cameras", state.cameras);
              state.camerasListEmitted = true;
            }
          })
          .catch((error) => emit("notsupported", error));
      }
    };

    /**
     * change to a different camera stream, like front and back camera on phones
     */
    const changeCamera = (deviceId) => {
      stop();
      emit("camera-change", deviceId);
      loadCamera(deviceId);
    };

    /**
     * load the stream to the
     */
    const loadSrcStream = (stream) => {
      if ("srcObject" in videoRef.value) {
        // new browsers api
        videoRef.value.srcObject = stream;
      } else if ("mozSrcObject" in videoRef && navigator.mozGetUserMedia) {
        videoRef.value.mozSrcObject = stream;
      } else if (window.URL || window.webkitURL) {
        state.source = (window.URL || window.webkitURL).createObjectURL(stream);
      } else {
        // old broswers
        state.source = window.HTMLMediaElement.srcObject(stream);
      }
      // Emit video start/live event
      videoRef.value.onloadedmetadata = () => {
        videoRef.value.play();
        emit("video-live", stream);
      };
      emit("started", stream);
    };

    /**
     * stop the selected streamed video to change camera
     */
    const stopStreamedVideo = (videoElem) => {
      let stream = videoElem.srcObject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        // stops the video track
        track.stop();
        // emit("stopped", stream);
        // videoRef.value.srcObject = null;
        // state.source = null;
      });
      emit("stopped", stream);
    };

    // stop the video
    const stop = () => {
      var needEmitStop = false;
      if (videoRef.value !== null) {
        if (videoRef.value.srcObject) {
          stopStreamedVideo(videoRef.value);
        } else {
          needEmitStop = true;
        }
        if (videoRef.value.stop) {
          videoRef.value.stop();
        }
        videoRef.value.srcObject = null;
      }
      if(needEmitStop){
        emit("stopped", null);
      }
      state.source = null;
    };

    // start the video
    const start = () => {
      if (props.deviceId) {
        loadCamera(props.deviceId);
      }
      if (videoRef.value !== null && videoRef.value.start) {
        videoRef.value.start();
      }
    };

    // pause the video
    const pause = () => {
      if (videoRef.value !== null && videoRef.value.pause) {
        videoRef.value.pause();
      }
    };

    // resume the video
    const resume = () => {
      if (videoRef.value !== null) {
        if (videoRef.value.resume) {
          videoRef.value.resume();
        } else if (videoRef.value.play) {
          videoRef.value.play();
        }
      }
    };

    /**
     * test access
     */
    const testMediaAccess = () => {
      let constraints = { video: true };
      if (props.resolution) {
        constraints.video = {};
        constraints.video.height = props.resolution.height;
        constraints.video.width = props.resolution.width;
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          //Make sure to stop this MediaStream
          let tracks = stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
          loadCameras();
        })
        .catch((error) => emit("error", error));
    };

    /**
     * load the camera passed as index!
     */
    const loadCamera = (device) => {
      let constraints = { video: { deviceId: { exact: device } } };
      if (props.resolution) {
        constraints.video.height = props.resolution.height;
        constraints.video.width = props.resolution.width;
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => loadSrcStream(stream))
        .catch((error) => emit("error", error));
    };

    /**
     * capture screenshot
     */
    const capture = () => {
      return getCanvas().toDataURL(props.screenshotFormat);
    };

    /**
     * get canvas
     */
    const getCanvas = () => {
      let video = videoRef.value;
      if (!state.ctx) {
        let canvas = document.createElement("canvas");
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        state.canvas = canvas;
        state.ctx = canvas.getContext("2d");
      }
      const { ctx, canvas } = state;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      return canvas;
    };

    const takePicture = () => {
      // pause()
      // if(videoRef.value){
      //   var videoKeepOpacity = videoRef.value.style.opacity;
      //   videoRef.value.style.opacity = "0.5"
      //   setTimeout(() => {
      //     videoRef.value.style.opacity = videoKeepOpacity;
      //   }, 100);
      // }
      // setTimeout(() => {
      //   resume()
      // }, 300);
      return capture();
    };

    const videoStyle = computed(() => {
      return {
        width:
          typeof props.width === "number" ? props.width + "px" : props.width,
        height:
          typeof props.height === "number" ? props.height + "px" : props.height,
      };
    });

    watch(
      () => props.deviceId,
      (id) => {
        changeCamera(id);
      }
    );

    onMounted(() => {
      nextTick(setupMedia);
    });

    onBeforeUnmount(stop);

    return {
      ...toRefs(state),
      videoRef,
      start,
      stop,
      pause,
      resume,
      capture,
      takePicture,
      videoStyle,
    };
  },
});
</script>

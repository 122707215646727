<template>
  <a-card class="mt-3" :title="$t('warehouse.reconciliation_detail')">
    <a-row :gutter="[16, 32]" type="flex" justify="space-around" align="middle">
      <a-col>
        {{ $t('warehouse.reconciliation_quantity') }}: {{ info.accountWaybillCount }}
      </a-col>
      <a-col>
        {{ $t('warehouse.billing_weight_of_waybill') }}: {{ gToKg(info.accountChargeableWeight) }}kg
      </a-col>
      <a-col>
        {{ $t('warehouse.reconciliation_amount') }}: 
        <span v-if="info.accountCurrency && info.conversionAmount">
          <span>{{
            $filters.formatCurrencyAmount(
              info.accountCurrency.currencySymbol,
              info.accountCurrency.amount,
              false
            )
          }}</span>
          (<span class="text-success">{{
            $filters.formatCurrencyAmount(
              info.conversionAmount.currencySymbol,
              info.conversionAmount.amount,
              false
            )
          }}</span
          >)
        </span>
        <span v-else>-</span>
      </a-col>
      <a-col>
        {{ $t('finance.exchange_rate') }}: {{ info.currencyRate }}
      </a-col>
      <a-col>
        {{ $t('warehouse.profit_and_loss_amount') }}: 
        <span v-if="info.profitLossCurrency">
          <span
            v-if="info.profitLossCurrency.amount > 0"
            class="text-success"
            >{{
              $filters.formatCurrencyAmount(
                info.profitLossCurrency.currencySymbol,
                info.profitLossCurrency.amount
              )
            }}</span
          >
          <span v-else class="text-error">{{
            $filters.formatCurrencyAmount(
              info.profitLossCurrency.currencySymbol,
              info.profitLossCurrency.amount
            )
          }}</span>
        </span>
        <span v-else>-</span>
      </a-col>
      <a-col>
        {{ $t('warehouse.reconciliation_time') }}: {{ $filters.utcToCurrentTime(info.reconciliationTime) }}
      </a-col>
      <a-col>
        <a-space>
          <span>{{ $t('account.operator') }}:</span>
          <CEmployeeInfo v-if="info.employee" :employee="info.employee" />
          <span v-else-if="info.operationUserName">{{ info.operationUserName }}</span>
          <span v-else>-</span>
        </a-space>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { defineComponent } from 'vue'
import { Card, Col, Row, Space } from 'ant-design-vue';
import CEmployeeInfo from '@/views/components/CEmployeeInfo.vue';
import { gToKg } from '@/utils/general'
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    ACard: Card,
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    CEmployeeInfo,
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  setup () {

    const { getters } = useStore()

    return {
      gToKg,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.partner_group_set') }}
    </template>
    <!-- 下拉按钮 and table -->
    <template v-slot:contentBody>
      <div class="mb-3">
        <div>{{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.storageService)) }}</div>
        <div class="ml-4 mt-3">
          <UpdateMoney v-model:price="storageCommission.percent"
                       v-model:enable='storageCommission.isActive'
                       v-model:isEdit="isEdit"
                       :isShowSwitch='true'
                       :saving="confirmBtnLoading"
                       @enablechange="e => handleaActive(1, e)"
                       @updateMoney="e => handleEditCommission(1, e)"/>
        </div>
      </div>
      <div>
        <!-- 表格 -->
        <div>{{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.transportService)) }}</div>
        <a-table :columns="columns"
                 :data-source="transportCommissions"
                 :pagination="false"
                 :rowKey="
          (record, id) => {
            return id;
          }
        "
                 size="small"
                 :loading="loading">
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>

          <template #logisticAddress="{ record }">
            {{ getLanguageName(record.logisticAddress.countryCnName, record.logisticAddress.countryEnName) }}
          </template>

          <template #percent="{ record }">
            <UpdateMoney
              v-model:price="record.percent"
              v-model:enable='record.isActive'
              v-model:isEdit="record.isEdit"
              :isShowTitle="false"
              :isShowSwitch='true'
              :saving="record.loading"
              @enablechange="e => handleaActive(2, e, record)"
              @updateMoney="e => handleEditCommission(2, e, record)"
            />
          </template>
        </a-table>
      </div>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, toRefs } from 'vue';
import {
  Table,
  Button,
  Select,
  Row,
  Col,
  Input, Form, message,
} from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue';
import UpdateMoney from './components/UpdateMoney.vue';
import {
  getAllGroupCommission, createOrUpdateGroupCommission, editPartnerGroupCommissionActive
} from '@/api/modules/partner/groupSet';
import { commissionBusinessType as commissionBusinessTypeEnum } from '@/enum/enum.json'
import { useI18n } from "vue-i18n/index";
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';

export default defineComponent({
  name: "partner_group_set",
  components: {
    ATable: Table,
    AButton: Button,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AFormItem: Form.Item,
    AForm: Form,
    UpdateMoney,
    Content,
    CPager,
  },

  setup () {
    const route = useRoute();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        title: () => vueI18n.t('common.serial_number'),
        slots: {
          customRender: 'serialNumber',
        },
        width: 80,
      },
      {
        dataIndex: 'logisticName',
        title: () => vueI18n.t('logistics.logistics_scheme_name'),
        width: 200,
      },
      {
        dataIndex: 'logisticCode',
        title: () => vueI18n.t('finance.channel_code'),
        width: 200,
      },
      {
        title: () => vueI18n.t('finance.origin_support'),
        width: 200,
        slots: {
          customRender: 'logisticAddress',
        },
      },
      {
        title: () => vueI18n.t('finance.partner_commission'),
        width: 250,
        slots: {
          customRender: 'percent',
        },
      },
    ];

    const state = reactive({
      loading: true,
      confirmBtnLoading: false,
      isEdit: false,
      groupId: null,
      storageCommission: {
        percent: 0,
        isActive: false
      },
      transportCommissions: []
    })

    const getLanguageName = (cnName, enName) => {
      if (getters.language === 'zh-CN') {
        return cnName
      } else {
        return enName
      }
    }

    const handleaActive = (businessType, isActive, record) => {
      const data = {
        groupId: state.groupId,
        businessType,
        isActive
      }
      if (businessType === 1) {
        state.confirmBtnLoading = true
      } else {
        record.loading = true
        data.businessId = record.logisticId
      }
      editPartnerGroupCommissionActive(data).then(() => {
        message.success(vueI18n.t('common.succeed'))
      }).finally(() => {
        if (businessType === 1) {
          state.confirmBtnLoading = false
        } else {
          record.loading = false
        }
      })
    }

    const handleEditCommission = (businessType, percent, record) => {
      const data = {
        groupId: state.groupId,
        businessType,
        percent,
      }
      if (businessType === 1) {
        state.confirmBtnLoading = true
      } else {
        record.loading = true
        data.businessId = record.logisticId
      }
      createOrUpdateGroupCommission(data).then(() => {
        message.success(vueI18n.t('common.succeed'))
        if (businessType === 1) {
          state.isEdit = false
          state.storageCommission.percent = percent
        } else {
          record.isEdit = false
          record.percent = percent
        }
      }).finally(() => {
        if (businessType === 1) {
          state.confirmBtnLoading = false
        } else {
          record.loading = false
        }
      })
    }

    const getTable = async () => {
      state.loading = true;
      const params = Object.assign({}, {
        groupId: route.params.id
      })
      getAllGroupCommission(params).then(({ result }) => {
        if (result) {
          let { groupId, storageCommission, transportCommissions } = result
          state.groupId = groupId
          state.storageCommission = storageCommission
          state.transportCommissions = transportCommissions.map(item => {
            item.loading = false
            item.isEdit = false
            return item
          })
        }
      }).finally(() => {
        state.loading = false;
      })
    }

    onActivated(getTable);

    return {
      ...toRefs(state),
      columns,
      getLanguageName,
      handleaActive,
      handleEditCommission,
      commissionBusinessTypeEnum,
    };
  },
});
</script>
<style lang="less" scoped>
</style>

<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.transport_customer_detail') }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <a-row :gutter="[0, 8]">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('logistics.transport_plan_name') }}: {{ plan.name }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('logistics.transportation_plan_number') }}: {{plan.no}}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.source_related_doc_no')}}: <CPlanNumberItem :no="plan.inWarehouseNo || plan.outWarehouseNo"></CPlanNumberItem>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.target_related_doc_no')}}: <CPlanNumberItem :no="plan.toInWarehouseNo"></CPlanNumberItem>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.creation_time')}}: {{$filters.utcToCurrentTime(plan.creationTime)}}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('logistics.last_modification_time')}}: {{$filters.utcToCurrentTime(plan.updateTime)}}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('logistics.transport_route')}}: {{ $t($enumLangkey('transportRoutes', plan.transportRoutes)) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('logistics.destination_type')}}: {{$t($enumLangkey('destinationType',plan.toWarehouseType))}}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('logistics.recipients')}}: {{ plan.receiptUserName }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('account.phone')}}: {{ plan.receiptContactPhone }}
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]" style="height: 100%;" type="flex" align="middle">
              <a-col :span="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t('finance.status') }}</a-typography-text>
                </div>
                <a-row class="mt-2" :gutter="16" type="flex" justify="center">
                  <a-col>
                    <a-typography-text type="secondary" v-if="plan.planStatus === transportStatusEnum.canceled">{{ $t($enumLangkey('transportStatus', plan.planStatus)) }}</a-typography-text>
                    <a-typography-text type="success" v-else>{{ $t($enumLangkey('transportStatus', plan.planStatus)) }}</a-typography-text>
                  </a-col>
                  <a-col v-if="plan.isPayOnArrival && plan.logisticsNode">
                    <div>
                      <a-tag color="blue" v-if="plan.logisticsNode.accountingPeriodCalculationWay === logisticsAccountingPeriodCalculationWayEnum.nextMonth">
                        {{ $t($enumLangkey("logisticsNode", plan.logisticsNode.logisticsNode)) }}, {{ $t("logistics.next_month") }}, {{ plan.logisticsNode.accountingPeriodDays }}{{ $t('common.days') }}
                      </a-tag>
                      <a-tag color="blue" v-else>{{ $t($enumLangkey("logisticsNode", plan.logisticsNode.logisticsNode)) }}, {{ plan.logisticsNode.accountingPeriodDays }}{{ $t('common.days') }}</a-tag>
                    </div>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="center">
                  <a-col>
                    <PaymentCountdown
                      v-if="plan.planStatus === transportStatusEnum.awaitPay"
                      :value="plan.countdownPaidSeconds"
                      @finish="funcSearchPlanInfo(plan.id)"
                    />
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="24">
                <a-row :gutter="16" type="flex" justify="center">
                  <a-col>
                    <a-button @click="handleShowTrackModal">{{ $t('warehouse.tracking_shipment') }}</a-button>
                  </a-col>
                  <a-col
                    v-if="
                    logistics.logisticsType !=logisticsWayEnum.commercialExpress &&
                    plan.planStatus >= transportStatusEnum.inTransit && plan.planStatus <= transportStatusEnum.signed"
                  >
                    <a-button @click="handleShowModalTrackingTrajectory">{{ $t('warehouse.track_info') }}</a-button>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <template v-if="plan.shopData">
            <a-col :md="8" :sm="24" :xs="24">
              {{ $t('warehouse.customer') }}:
              <span v-if="plan.shopData.shopName">{{ plan.shopData.shopName || '-' }}</span>
              <span v-else>-</span>
              <small v-if="plan.shopData.expressDiscount && (plan.shopData.expressDiscount !== 100 || !!plan.shopData.expressDiscountAmount)">
                (
                  <span class="text-warning express-discount" v-if="!!plan.shopData.expressDiscountAmount">{{ $t('warehouse.discount_amount') }}: {{ $filters.formatCurrencyAmount(fee?.currencySymbol, plan.shopData.expressDiscountAmount) }}</span>
                  <span class="text-warning express-discount" v-if="plan.shopData.expressDiscount !== 100">{{ $t('warehouse.discount') }}: {{ plan.shopData.expressDiscount }}%</span>
                )
              </small>
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              {{$t('common.shop_mark')}}:
              <span v-if="plan.shopData.shopMark">{{ plan.shopData.shopMark || '-' }}</span>
              <span v-else>-</span>
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              NO:
              <span v-if="plan.shopData.shopNo">{{ plan.shopData.shopNo || '-' }}</span>
              <span v-else>-</span>
            </a-col>
          </template>
          <a-col :md="8" :sm="24" :xs="24" v-if="plan.toWarehouseType==destinationTypeEnum.FBA">
            {{$t('warehouse.fba_warehouse_code')}}: {{plan.toWarehouseCode}}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24" v-else-if="plan.toWarehouseType==destinationTypeEnum.SeWarehouse">
            {{$t('logistics.warehouse_code')}}: {{plan.toWarehouseCode}}
          </a-col>
          <a-col :md="24" :sm="24" :xs="24" v-if="!plan.toWarehouseType || plan.toWarehouseType == destinationTypeEnum.OtherAddress">
            {{$t('warehouse.transport_address')}}: {{ getAddress(plan.toAddressData) }}
          </a-col>
          <a-col :md="16" :sm="24" :xs="24" v-else>
            {{$t('warehouse.transport_address')}}: {{getAddress(plan.toAddressData)}}
            <span v-if="plan.toAddressData && plan.toWarehouseType == destinationTypeEnum.SeWarehouse">
              ({{getLinkman(plan.toAddressData)}})
            </span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{$t('logistics.originate')}}: {{plan.fromAddressData.fromName}}
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            {{$t('logistics.shipments_address')}}: {{ getAddress(plan.fromAddressData) }}
            <a-tag color="warning" v-if="plan.toAddressData.isInternationalTransport"> {{$t('logistics.international_transport')}}</a-tag>
            <a-tag color="warning" v-else>{{$t('enum.transport_routes_2')}}</a-tag>
            <a-tag color="default" class="ml-3" style="min-height:32px;" v-if="plan.isDoorTook">
              <a-row class="mt-1">
                <a-col>
                  <a-tag color="success">{{$t('logistics.door_took')}}</a-tag>
                </a-col>
                <a-col class="ml-3">{{$t('logistics.contacts')}}: {{plan.doorLinkUserName}}</a-col>
                <a-col class="ml-3 mr-2">{{$t('account.phone')}}: {{plan.doorContactPhone}}</a-col>
              </a-row>
            </a-tag>
          </a-col>
          <a-col :span="24">
            {{$t('logistics.logistics_center_harvest_address')}}
            <c-tooltip :title="$t('logistics.logistics_select_hint')" />:
            {{getAddress(plan.seLogisticsAddress)}}
            <span v-if="plan.seLogisticsAddress">({{ getLinkUser(plan.seLogisticsAddress) }})</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24" v-if="plan.fbaTransportNo">
            <span v-if="plan.toWarehouseType==destinationTypeEnum.FBA">{{$t('logistics.amazon_shipping_number')}}</span>
            <span v-else-if="plan.toWarehouseType==destinationTypeEnum.mercadolibre">{{$t('logistics.seller_id')}}</span>
            <span>: {{plan.fbaTransportNo}}</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24" v-if="plan.productTrackNo">
            <span v-if="plan.toWarehouseType==destinationTypeEnum.FBA">{{$t('logistics.shipment_tracking_number')}}</span>
            <span v-else-if="plan.toWarehouseType==destinationTypeEnum.mercadolibre">{{$t('warehouse.warehousing_number')}}</span>
            <span>: {{plan.productTrackNo}}</span>
          </a-col>
        </a-row>
        <!-- 箱表格 -->
        <div>
          <a-row justify="end" :gutter="24" class="mb-2">
            <a-col v-if="plan.planStatus != transportStatusEnum.canceled">
              <ExportBoxListBtn v-if="plan.id" :plan-id="plan.id" :plan-no="plan.no" />
            </a-col>
            <a-col v-if="plan.planStatus>=transportStatusEnum.accomplishConsoleReceived && plan.planStatus!=transportStatusEnum.canceled">
              <a-button type="primary" ghost @click="handleExportPackingList" :loading="partialReceiptState.loading">{{ $t('warehouse.export_packing_list') }}</a-button>
            </a-col>
            <a-col>
              <a-button type="primary" ghost @click="handleShowProductStatisticsModal">{{ $t('common.product_statistics') }}</a-button>
            </a-col>
          </a-row>
          <a-table
            style="overflow-x: auto; min-height:223px;"
            :columns="plan.boxTableColumns"
            :data-source="plan.productTableList"
            size="small"
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
            :pagination="{
              defaultPageSize: 50,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            :row-key="(record) => record.planDetailId"
            @change='(page) => {currentPageIndex = page.current;}'
          >
            <template #encasementCount>
              <span>
                {{ $t("warehouse.encasement_count") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_1')" />
              </span>
            </template>
            <template #encasementInfo>
              <span>
                {{ $t("warehouse.encasement_info") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_2')" />
              </span>
            </template>
            <template #transportInfo>
              <span>
                {{ $t("warehouse.transportation_measurement_info") }}
                <c-tooltip :title="$t('warehouse.tooltip_text_3')" />
              </span>
            </template>
            <template #boxNo="{ record }">
              <div :style="record.isCancel ? 'color: #CCCCCC;' : ''">
                {{ record.boxNo }}
                <CTagMixedOrSingle :productKindCount="record.productKindCount * 1"></CTagMixedOrSingle>
              </div>
              <div><small>{{ record.selfBoxNo || '-' }}</small></div>
              <div v-if="record.isCancel">
                <a-tag color="red">{{ $t('logistics.canceled') }}</a-tag>
              </div>
            </template>
            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation :open="expanded" @click="e => onExpand(record, e)" />
            </template>

            <template #encasementCountCustom="{record }">
              {{record.containerCount}} Unit
            </template>

            <template #encasementInfoCustom="{record }">
              <span v-if="!record.packBoxInfo||record.packBoxInfo.length<= 0||record.packBoxInfo.width<= 0||record.packBoxInfo.height<= 0">
                -
              </span>
              <span v-else>
                {{ record.packBoxInfo.length }}x{{ record.packBoxInfo.width }}x{{ record.packBoxInfo.height }}cm
              </span>
              <div>
                <span v-if="record.packBoxInfo && record.packBoxInfo.weight">{{ gToKg(record.packBoxInfo.weight) }}kg</span>
                <span v-else>-</span>
              </div>
            </template>

            <template #transportInfoCustom="{record }">
              <span v-if="!record.isCancel">
                <span v-if="!record.transportBoxInfo||record.transportBoxInfo.length<= 0||record.transportBoxInfo.width<= 0||record.transportBoxInfo.height<= 0">
                  -
                </span>
                <span v-else>
                  {{ record.transportBoxInfo.length }}x{{ record.transportBoxInfo.width }}x{{ record.transportBoxInfo.height }}cm
                </span>
                <div>
                  <span v-if="record.transportBoxInfo && record.transportBoxInfo.weight">{{ gToKg(record.transportBoxInfo.weight) }}kg</span>
                  <span v-else>-</span>
                </div>
              </span>
            </template>

            <template #trackingNo="{ record }">
              <span v-if="!record.isCancel">{{record.logisticTrackNo || '-'}}</span>
            </template>

            <template #print>
              <span v-if="plan.planStatus !== transportStatusEnum.canceled">
                <a-button :loading="bulkPrintLoading" @click="handleBulkPrint">{{$t('common.batch_print')}}</a-button>
              </span>
            </template>
            <template #printCustom="{ record }">
              <a-button v-if="!record.isCancel" :loading="record.buttonPrintBoxLoading" @click="handlePrintBoxLabel(record)">
                <span v-if="plan.toWarehouseType === destinationTypeEnum.SeWarehouse">
                  {{$t('common.se_outer_box_label')}}
                </span>
                <span v-else>
                  {{$t('common.print_box_label')}}
                </span>
              </a-button>
              <span v-else>-</span>
            </template>

            <template #checkPictureCustom="{ record }">
              <div v-if="record.checkImgUrls && record.checkImgUrls.length > 0 && !record.isCancel">
                <a-popover>
                  <template #content>
                    <a-space>
                      <div v-for="(imgItem, index) in record.checkImgUrls" class="table-list-img-common" :key="index">
                        <c-image :src="imgItem" :dis-preview-process="true" />
                      </div>
                    </a-space>
                  </template>
                  <span class="text-primary" style="font-size:20px">
                    <FileImageOutlined />
                  </span>
                </a-popover>
              </div>
            </template>

            <template v-slot:expectArriveTime="{ record }">
              <span v-if="!record.isCancel">
                <a-row type="flex" justify="space-between" align="middle">
                  <a-col>
                    <div>
                      {{ $t("logistics.charged_weight") }}:
                      <span v-if="record.chargeableWeight">{{ gToKg(record.chargeableWeight) }}kg</span>
                      <span v-else>-</span>
                    </div>
                    <div>
                      {{ $t("logistics.chargeable_volume") }}:
                      <span v-if="record.chargeableVolume">{{ cmCubicToM(record.chargeableVolume) }}m<sup>3</sup></span>
                      <span v-else>-</span>
                    </div>
                  </a-col>
                  <a-col>
                    <a-tag color="#f50">
                      <template v-if="record.logisticsCalcMethod === 0">
                        {{ record.isVolumeWeight ? $t('logistics.volume_weight') : $t('logistics.actual_weight') }}
                      </template>
                      <template v-else>
                        {{ record.isDensityVolume ? $t('logistics.density_volume') : $t('logistics.actual_volume') }}
                      </template>
                    </a-tag>
                  </a-col>
                </a-row>
              </span>
            </template>

            <template #operate="{ record }">
              <span v-if="!record.isCancel">
                <a-button v-if="isCanCancelBox"
                          size="small"
                          danger
                          @click="handleCancelBox(record)">{{ $t("logistics.customer_cancels_shipment") }}</a-button>
              </span>
            </template>

         

            <template #footer>
              <a-row type="flex"
                     justify="start"
                     class="mr-5"
                     :gutter="32">
                <a-col>{{$t('common.total')}}: {{plan.boxTotalCount}}{{$t('warehouse.box')}}</a-col>
                <a-col v-if="plan.transportTotalVolume>0">{{$t('logistics.total_volume_of_transport')}}: {{plan.transportTotalVolume}}m<sup>3</sup></a-col>
                <a-col v-if="plan.transportTotalWeight>0">{{$t('logistics.gross_shipping_weight')}}: {{plan.transportTotalWeight}}kg</a-col>
                <a-col>{{$t('warehouse.total_volume')}}: {{plan.totalContainerVolume}}m<sup>3</sup></a-col>
                <a-col>{{$t('warehouse.total_encasement_weight')}}: {{plan.totalContainerWeight}}kg</a-col>

                <a-col>
                  {{$t('logistics.transport_characteristics')}}:
                  <a-tag color="default"
                         v-for="item in plan.transportTotalCharacteristic"
                         :key="item">
                    {{$t($enumLangkey('transportCharacteristics',item))}}
                  </a-tag>
                </a-col>
              </a-row>
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="plan.innerColumns"
                :data-source="record.productInfos"
                :pagination="false"
                :row-key="(record) => record.id"
                size="small"
              >
                <template #productInfo="{ record }">
                  <a-row :gutter="8" type="flex" align="middle">
                    <a-col>
                      <div class="table-list-img-common">
                        <c-image
                          :src="record.productImgUrl"
                          :thumbWidth="600"
                          :thumbHeight="600"
                        />
                      </div>
                    </a-col>
                    <a-col flex="1">
                      <div>{{ record.productName }}</div>
                      <div>{{ record.productNo }}</div>
                    </a-col>
                  </a-row>
                </template>
                <template #containerCount="{ record }">
                  {{ record.containerCount }} Unit
                </template>
                <template #restsCustom="{record }">
                   <a-row 
                         v-if="!record.isCancel">
                    <a-col :span="24">
                        <a-row type="flex"
                        justify="space-between">
                            <a-col :span="12">
                          {{$t('warehouse.chinese_trade_name')}}: {{record.customsInfo.brandCName}}
                        </a-col>
                        <a-col :span="12">
                          {{$t('warehouse.english_trade_name')}}: {{record.customsInfo.brandEnName}}
                        </a-col>
                        </a-row>
                    </a-col>

                    <a-col :span="24">
                      <a-row type="flex"
                             justify="space-between">
                        <a-col :span="12">
                      HS Code: {{record.customsInfo.hsCode}}
                        </a-col>
                        <a-col :span="12">
                          {{$t('warehouse.declare_price')}}: {{record.customsInfo.currencySymbol}}{{$filters.amountToFixed2(record.customsInfo.totalPrice)}}
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
              </a-table>
            </template>
          </a-table>
        </div>
        <!-- 清关信息汇总 -->
        <div v-if="plan.toAddressData.isInternationalTransport"
             class="mt-3">
          <p><strong>{{$t('logistics.summary_customs_clearance_information')}}</strong></p>
          <a-table :columns="customsInfoColumns"
                   :data-source="customsInfo"
                   :scroll="{ x: 800}"
                   size="small"
                   :pagination="false">

            <template #productNameAndSeSku>
              <span>
                {{ $t("warehouse.product_name") }}/{{ $t("warehouse.product_no") }}
              </span>
            </template>

            <template #productNameAndSeSkuCustom="{ record }">
              <div>{{ record.productName }}</div>
              <div>{{ record.productNo }}</div>
            </template>

            <template #texture="{ record }">
              <div>{{ record.enMaterial ?? '-' }}</div>
              <div>{{ record.cnMaterial ?? '-' }}</div>
            </template>

            <template #tradeNameCustom="{record }">
              <div>
                {{ record.enTradeNameCustom}}
              </div>
              <div>
                {{ record.chTradeNameCustom}}
              </div>
            </template>

            <template #brand="{ record }">
              {{ record.brand || '-' }}
            </template>

            <template #purposeDescription="{ record }">
              <div>{{ record.purposeEnDescription || '-' }}</div>
              <div>{{ record.purposeCnDescription || '-' }}</div>
            </template>

            <template #applyMiddleRate="{record }">
              {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.applyMiddleRate) }}
            </template>
            <template #subtotal="{record }">
              {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.subtotal) }}
            </template>
            <template #footer>
              <a-row type="flex"
                     justify="end"
                     class="mr-5"
                     :gutter="32">
                <a-col>{{$t('common.total')}}: {{customsSymbol}}{{$filters.amountToFixed2(customsTotalFee)}}</a-col>
              </a-row>
            </template>
          </a-table>
        </div>
        <!-- 物流方案 -->
        <div class="mt-3">
          <a-space>
            <strong>{{$t('menu.logistics_channel')}}</strong>
            <a-button
              v-if="plan.isCanChooseLogistics"
              type="primary"
              size="small"
              @click="handleOpenLogisticsModal"
              >{{ $t("logistics.select_logistics_scheme") }}</a-button
            >
          </a-space>
          <div class="mt-3 mb-5">
            <a-table :columns="logistics.columnsLogisticsScheme"
                     :data-source="logistics.logisticsSchemeList"
                     :scroll="{ x:true}"
                     :pagination="false">

              <template #logisticsScheme>
                <span>{{$t('logistics.logistics_scheme')}} / {{ $t('logistics.logistics_code') }}</span>
              </template>

              <template #logisticsWay>
                <span>
                  {{ $t("logistics.logistics_type") }}
                </span>
              </template>

              <template #deliveryWay>
                <span>
                  {{ $t("logistics.delivery_way") }}
                </span>
              </template>

              <template #referenceAging>
                <span>
                  {{ $t("logistics.reference_aging") }}
                </span>
              </template>
              <template #freights>
                <span v-if="!fee">
                  {{ $t("logistics.freight_forecast_no_audit") }}
                </span>
                <span v-else>
                  {{ $t("logistics.freight") }}
                </span>
              </template>

              <template #details>
                <span>
                  {{ $t("common.details") }}
                </span>
              </template>
              <template #optionalServices>
                <span>
                  {{ $t("logistics.selected_service") }}
                </span>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">{{ record.scheme }} / {{ record.code }}</div>
                <a-row :gutter="[8, 4]">
                  <a-col v-for="item in record.descriptionItems"
                         :key="item">
                    <CheckCircleOutlined class="text-warning mr-1"
                                         :style="{fontSize: '12px'}" />
                    <span style="font-size: 12px; color: rgba(102,102,102,1)">
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{$t($enumLangkey('logisticsWay',record.logisticsWay))}}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{$t($enumLangkey('deliveryWay',record.deliveryWay))}}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{record.daysMin}}-{{record.daysMax}}
                  {{$t("logistics.workday")}}
                  ({{$t($enumLangkey('aging',record.channelTimeType))}})
                </div>
              </template>
              <template #freightsCustom="{ record }">
                <div class="text-warning">
                  {{$t('warehouse.average_unit_price')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.averagePrice)}}
                </div>
                <div class="text-warning">
                  {{$t('logistics.total')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.expressFee)}}
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div v-if="record.logisticsCalcMethod==0">
                  <span v-if="record.chargeableWeight">{{$t('logistics.chargeable_weight')}}: {{record.chargeableWeight}}kg</span>
                  <span v-else>-</span>
                </div>
                <div v-else>
                  <span v-if="record.chargeableVolume">{{$t('logistics.chargeable_volume')}}: {{record.chargeableVolume}}m<sup>3</sup></span>
                  <span v-else>-</span>
                </div>
                <div>
                  {{$t('logistics.volume_weight_coefficient')}}{{record.volumeWeightParam}}
                </div>
              </template>
              <template #optionalServicesCustom="{ record }">
                <div v-if="record.logisticsRegionInfo.isChooseDdp || record.logisticsRegionInfo.isChooseSign">
                  <div v-if="record.logisticsRegionInfo.isChooseDdp">
                    DDP({{record.currencySymbol}}{{$filters.amountToFixed2(record.logisticsRegionInfo.ddpFee)}})
                  </div>
                  <CSignSupported
                    v-if="record.logisticsRegionInfo.isChooseSign"
                    v-model:value="record.logisticsRegionInfo.signatureType"
                    type="readonly"
                    :symbol="record.currencySymbol"
                    :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                  />
                </div>
                <div v-else>-</div>
              </template>
              <!-- 卡车专属 -->
              <template #quoteOptionsTitle>
                {{ $t('logistics.quote_options') }}
                <CTooltip :title="$t('logistics.quote_options_tips')" />
              </template>
              <template #quoteOptions="{ record }">
                <a-row :gutter="[16, 8]">
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{ $t('logistics.quote_type') }}</a-col>
                      <a-col flex="1">
                        <span>{{ record.truckBaseInfo.quoteType?.name }}</span>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24" v-if="record.truckBaseInfo.serviceLevel">
                    <a-row type="flex">
                      <a-col flex="100px">{{ $t('logistics.service_level') }}</a-col>
                      <a-col flex="1">
                        <template>
                          <span>{{ record.truckBaseInfo.serviceLevel.name }}</span>
                          <span>-</span>
                        </template>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row type="flex">
                      <a-col flex="100px">{{ $t('logistics.additional_options') }}</a-col>
                      <a-col flex="1">
                        <a-popover trigger="hover">
                          <template #content>
                            <div style="max-width: 300px;">{{ getAccessorialsName(record.truckBaseInfo.accessorials) }}</div>
                          </template>
                          <div style="max-width: 250px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ getAccessorialsName(record.truckBaseInfo.accessorials) }}</div>
                        </a-popover>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </template>
              <template #quoteDetails="{ record }">
                <span v-if="record.truckBaseInfo">{{ record.truckBaseInfo.carrierName }} [{{ $filters.formatCurrencyAmount(record.truckBaseInfo.currencySymbol, record.truckBaseInfo.totalCharge, false) }}, {{ handleParseTime(record.truckBaseInfo.transitTime) }} day(s)]</span>
                <span v-else>-</span>
              </template>
            </a-table>
            <div class="mt-2">
              <a-tag color="warning"
                     v-if="logistics.isRemoteArea">{{$t('logistics.remote_site')}}</a-tag>
              <template v-if="logistics.isCommercial!=null">
                <a-tag color="warning"
                       v-if="logistics.isCommercial">{{$t('logistics.business_site')}}</a-tag>
                <a-tag color="warning"
                       v-else>{{$t('logistics.residence')}}</a-tag>
              </template>
            </div>

            <a-form-item class="mt-3"
                         v-show="logistics.isShowVATInput">
              <template #label>
                <div>
                  <span style="color:red"> * </span>
                  <label>VAT</label>
                </div>
              </template>
              <a-input style="max-width:250px"
                       :disabled="true"
                       v-model:value="logistics.VAT" />
            </a-form-item>
          </div>
        </div>
        <!-- 报关方式 -->
        <div class="mt-5"
             v-if="logistics.isSelectedCustoms">
          <p><strong>{{$t('logistics.clearance_way')}}</strong>: {{$t('logistics.ordinary_trade_clearance')}}</p>
          <a-row :gutter=[32,8]>
            <a-col v-for="(item,index) in logistics.imgUrl"
                   :key="index">
              <div v-if="item.url">
                <div class="text-center">{{$t(item.name)}}</div>
                <div>
                  <CFileDisplay :fileUrl="item.url" />
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- 费用明细 -->
        <CFee
          v-if="fee?.fees"
          :fees="getCommonFeeList(fee?.fees)"
          :totalFee="{ symbol: fee?.currencySymbol, value: fee?.totalAmount }"
        />
        <!-- button 返回和支付modal -->
        <div class="mr-5 mb-5 mt-5">
          <a-row type="flex"
                 justify="end">
            <a-col class="ml-3">
              <a-button @click="handleBacktrack">{{$t('common.backtrack')}}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
      <TraceModal ref="traceModalRef" />
      <CProductStatisticsModal ref="productStatisticsModalRef" :show-inbound="true" />
      <CPinTipsModal v-model:visible="partialReceiptState.visible"
                     :loading="partialReceiptState.loading"
                     @confirm="handleConsoleCancelBox" />
      <LogisticsChannelModal ref="logisticsChannelModalRef" @refresh="handleGetFeeAuditDetail" />
      <CTrackInfoModal 
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
         dateFormat="yyyy-MM-DD"
      >
        <template v-slot:numberName>
          {{ $t("logistics.transportation_plan_number") }}
        </template>
         <template v-slot:no>
          {{trackModal.no}}
        </template>
      </CTrackInfoModal>
    </template>
  </Content>
</template>
<script>
import { defineComponent, reactive, toRefs, onMounted, ref, computed } from "vue";
import {
  Row,
  Col,
  Tag,
  Typography,
  Table,
  Input,
  Select,
  Button,
  Form,
  Spin,
  message,
  Popover,
  Space,
  InputNumber
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CProductStatisticsModal from '@/views/components/CProductStatisticsModal.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import CTagMixedOrSingle from '@/views/components/CTagMixedOrSingle.vue';
import CFee from "@/views/components/CFee.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import TraceModal from "../components/TraceModal.vue";
import LogisticsChannelModal from '../components/LogisticsChannelModal.vue';
import PaymentCountdown from "../components/PaymentCountdown.vue";
import ExportBoxListBtn from "../components/ExportBoxListBtn.vue";
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from "vue-router";
import { useTab } from "@/hooks/tabs/index";
import { useStore } from "vuex";
import urlHelper from "@/utils/urlHelper";
import localPrint from "@/utils/localPrint";
import { getAddressByLanguageV2, gToKg, cmCubicToM, getLinkman, getLinkUser ,dateString } from "@/utils/general";
import { downloadFile } from "@/utils/downloader";
import { getCurrencys } from "@/api/modules/common/index";
import { batchPrintBoxOfPlanTransport, printBoxOfPlanTransport, } from "@/api/modules/printUrl/pdf.js"
import { getById, consoleCancelBox, getTrackList, exportPackingList } from "@/api/modules/transport/index";
import {
  destinationType as destinationTypeEnum,
  transportStatus as transportStatusEnum,
  productStatisticsType as productStatisticsTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  logisticsWay as logisticsWayEnum,
  logisticsAccountingPeriodCalculationWay as logisticsAccountingPeriodCalculationWayEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "transport_customer_detail",
  components: {
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ASpin: Spin,
    ATypographyText: Typography.Text,
    APopover: Popover,
    ASpace: Space,
    AInputNumber: InputNumber,
    CFileDisplay,
    CImage,
    CTooltip,
    Content,
    CProductStatisticsModal,
    CHaveProductInformation,
    CTagMixedOrSingle,
    CPinTipsModal,
    CPlanNumberItem,
    CFee,
    CSignSupported,
    CTrackInfoModal,
    TraceModal,
    LogisticsChannelModal,
    PaymentCountdown,
    ExportBoxListBtn,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();
    const traceModalRef = ref(null);
    const currentPageIndex = ref(1);
    const productStatisticsModalRef = ref();
    const logisticsChannelModalRef = ref();

    const boxTableColumns = [
      {
        dataIndex: "serialNumber",
        width: 60,
        title: () => vueI18n.t('common.serial_number'),
      },
      {
        width: 150,
        title: vueI18n.t('warehouse.box_number'),
        slots: {
          customRender: 'boxNo'
        }
      },
      { width: 50, },
      {
        width: 100,
        slots: {
          title: "encasementCount",
          customRender: "encasementCountCustom",
        },
      },
      {
        width: 150,
        slots: {
          title: "encasementInfo",
          customRender: "encasementInfoCustom",
        },
      },
      {
        width: 150,
        slots: {
          title: "transportInfo",
          customRender: "transportInfoCustom",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.tracking_ticket_no'),
        slots: {
          customRender: "trackingNo",
        },
      },
      {
        slots: {
          title: "print",
          customRender: "printCustom",
        },
        width: 120,
      },
      {
        title: "",
        width: 100,
        slots: {
          customRender: "checkPictureCustom",
        },
      },
      {
        width: 150,
        title: "",
        slots: {
          customRender: "expectArriveTime",
        },
      },
      {
        title: "",
        width: 100,
        slots: {
          customRender: "operate",
        },
      },
    ];

    const innerProductColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        dataIndex: "seSku",
        width: 150,
        title: "SESKU",
      },
      {
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "containerCount"
        }
      },
      {
        width: 500,
        dataIndex: 'rests',
        title: vueI18n.t('warehouse.customs_clearance_info'),
        slots: {
          customRender: "restsCustom",
        },
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 300,
      },
      {
        dataIndex: "logisticsWay",//物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay",//交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging",//参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 100,
      },
      {
        dataIndex: "freights",//运费
        slots: {
          title: "freights",
          customRender: "freightsCustom",//平均单价和合计
        },
        width: 100,
      },
      {
        dataIndex: "details",//详情
        slots: {
          title: "details",
          customRender: "detailsCustom",//计费重、体积重系数：除600
        },
        width: 220,
      },
      {
        dataIndex: "optionalServices",//可选服务
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 170,
      }
    ];

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t('logistics.quote_details'),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ]

    const customsInfoColumns = [
      {
        title: () => vueI18n.t("common.serial_number"),
        dataIndex: "serialNumber ",
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        width: 60,
      },
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t('logistics.trade_name'),
        dataIndex: "tradeName",
        slots: {
          customRender: "tradeNameCustom",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.texture"),
        width: 150,
        slots: {
          customRender: "texture",
        },
      },
      {
        title: vueI18n.t('warehouse.brand_name'),
        slots: {
          customRender: "brand",
        },
        width: 150,
      },
      {
        title: vueI18n.t('warehouse.purpose_description'),
        slots: {
          customRender: "purposeDescription",
        },
        width: 250,
      },
      {
        title: "HS Code",
        dataIndex: "hsCode",
        width: 150,
      },
      {
        title: () => vueI18n.t('logistics.apply_middle_rate'),
        width: 150,
        slots: {
          customRender: "applyMiddleRate",
        },
      },
      {
        title: () => vueI18n.t('logistics.amount'),
        dataIndex: "number",
        width: 150,
      },
      {
        title: () => vueI18n.t('logistics.subtotal'),
        width: 150,
        slots: {
          customRender: "subtotal",
        },
      },
    ]

    const state = reactive({
      transportStatusEnum,
      destinationTypeEnum,
      logisticsWayEnum,
      logisticsAccountingPeriodCalculationWayEnum,
      pageLoading: false,
      payLoading: false,
      bulkPrintLoading: false,
      currencys: [],
      plan: {
        boxTableColumns,
        innerColumns: innerProductColumns,
        id: "",
        no: "",
        name: "",
        creationTime: null,
        updateTime: null,

        planStatus: null,
        countdownPaidSeconds: null,
        toWarehouseType: null,
        toWarehouseId: "",
        toWarehouseCode: "",
        fbaTransportNo: "",
        productTrackNo: "",
        fromAddressData: {},
        toAddressData: {},
        productTableList: [],
        boxTotalCount: 0,
        transportTotalVolume: 0,
        transportTotalWeight: 0,
        totalContainerVolume: 0,
        totalContainerWeight: 0,
        transportTotalCharacteristic: null,
        isDoorTook: false,//是否上门揽件
        doorLinkUserName: "",
        doorContactPhone: "",

        toName: "",
        receiptUserName: "",
        receiptContactPhone: "",
        outWarehouseNo: null,
        inWarehouseNo: null,
        toInWarehouseNo: null,
        logisticsWarehouseNo: "",
        isPayOnArrival: false,
        isCanChooseLogistics: false,

        seLogisticsAddress: "",
        seLogisticsContact: "",
        seLogisticsPhone: "",

        employee: null,
        operatorName: null,
        logisticsNode: null,

      },
      customsInfo: [],
      customsTotalFee: "",
      customsSymbol: "",

      logistics: {
        columnsLogisticsScheme: columnsLogisticsScheme,
        logisticsSchemeList: [],
        isShowVATInput: false,

        VAT: null,
        isRemoteArea: null,//是否为偏远地址
        isCommercial: null,//是否为商业地址,
        isSelectedCustoms: false,
        imgUrl: []
      },

      fee: {
        totalAmount: 0,
        currencyId: '',
        currencyCode: "",
        currencySymbol: "",
        fees: [
          {
            feeType: "",
            price: 0,
            amount: 0,
            num: 0
          }
        ]
      },
      isShowOrdeModal: false,
      payModal: {
        visible: false,
        loading: false
      },
      partialReceiptState: {
        visible: false,
        loading: false,
        planDetailId: null,
        whBoxId: null,
      },
      exportPackingListLoading:false,
      pinCodeType:null
    })

    const isCanCancelBox = computed(() => {
      const list = [
        transportStatusEnum.consoleAwaitReceiving,
        transportStatusEnum.accomplishConsoleReceived,
        transportStatusEnum.awaitCheck,
        transportStatusEnum.awaitPay,
      ];
      return list.includes(state.plan.planStatus);
    })

    const getCommonFeeList = (list = []) => {
      if (Array.isArray(list)) {
        return list.map(item => ({
          feeType: item.feeType,
          symbol: state.fee.currencySymbol,
          value: item.amount,
          checkValue: item.amount,
          remark: item.remark,
        }))
      }
      return []
    }

    //币种
    const funcGetCurrencys = () => {
      return getCurrencys({ "isActive": true }).then((obj) => {
        let { result } = obj;
        state.currencys = result;
      });
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return '-'
      }
      return list.map(item => item.name).join(', ')
    };

    const handleParseTime = (num) => {
      let number = Number.parseInt(num)
      return Number.isNaN(number) ? num : number
    }

    const funcSearchPlanInfo = async (planId) => {
      state.pageLoading = true;
      getById({ "id": planId })
        .then((res) => {
          let r = res.result;
          try {
            state.plan.id = r.planBasicInfo.planId;
            state.plan.logisticsWarehouseId = r.planBasicInfo.logisticsWarehouseId;
            state.plan.no = r.planBasicInfo.planNo;
            state.plan.name = r.planBasicInfo.planName;
            state.plan.planStatus = r.planBasicInfo.planStatus;
            state.plan.countdownPaidSeconds = r.planBasicInfo.countdownPaidSeconds;
            state.plan.creationTime = r.planBasicInfo.createTime;
            state.plan.updateTime = r.planBasicInfo.updateTime;
            state.plan.toWarehouseType = r.planBasicInfo.destinationType;//目的仓库类型
            state.plan.toWarehouseId = r.planBasicInfo.toWarehouseId;
            state.plan.toWarehouseCode = r.planBasicInfo.warehouseCode;
            state.plan.fbaTransportNo = r.planBasicInfo.amazonTransportNo;
            state.plan.productTrackNo = r.planBasicInfo.trackNo;
            state.plan.fromAddressData = r.planBasicInfo.fromAddress;
            state.plan.fromAddressData.fromName = r.planBasicInfo.fromName;
            state.plan.toAddressData = r.planBasicInfo.toAddress;
            state.plan.toAddressData.isInternationalTransport = r.planBasicInfo.isInternationalTransport;
            state.plan.seLogisticsAddress = r.planBasicInfo.logisitcWarehouseAddress;
            state.plan.seLogisticsPhone = r.planBasicInfo.logisitcWarehouseAddress.linkPhone;
            state.plan.seLogisticsContact = r.planBasicInfo.logisitcWarehouseAddress.linkUserName;
            state.plan.isDoorTook = r.planBasicInfo.isDoorTook;
            state.plan.doorLinkUserName = r.planBasicInfo.doorLinkUserName;

            state.plan.doorContactPhone = r.planBasicInfo.doorContactPhone;
            state.plan.toName = r.planBasicInfo.toName;
            state.plan.receiptUserName = r.planBasicInfo.receiptUserName;
            state.plan.receiptContactPhone = r.planBasicInfo.receiptContactPhone;
            state.plan.outWarehouseNo = r.planBasicInfo.outWarehouseNo;
            state.plan.inWarehouseNo = r.planBasicInfo.inWarehouseNo;
            state.plan.toInWarehouseNo = r.planBasicInfo.toInWarehouseNo;
            state.plan.logisticsWarehouseNo = r.planBasicInfo.logisticsWarehouseNo;
            state.plan.isPayOnArrival = r.planBasicInfo.isPayOnArrival;
            state.plan.logisticsNode = r.planBasicInfo.logisticsNode;
            state.plan.isCanChooseLogistics = r.planBasicInfo.isCanChooseLogistics;
            state.customsTotalFee = r.customsInfo?.totalPrice?.totalPrice;
            state.customsSymbol = r.customsInfo?.totalPrice?.currencySymbol;

            state.logistics.isRemoteArea = r.transportPlanLogistic?.logisticsBaseInfo?.isRemoteArea;
            state.logistics.isCommercial = r.transportPlanLogistic?.logisticsBaseInfo?.isCommercial;
            state.logistics.logisticsType = r.transportPlanLogistic?.logisticsBaseInfo?.logisticsType;
            state.logistics.isSelectedCustoms = r.isUploadHsInfo;

            state.plan.transportRoutes = r.transportRoutes; // 运输路线
            state.plan.employee = r.employee;
            state.plan.operatorName = r.operatorName;
            state.plan.shopData = r.shopData;

            state.plan.productTableList = r.planBoxInfo.transportPlanBoxData.map((x, index) => {
              x.loading = false;
              x.serialNumber = index + 1;
              return x;
            })

            state.plan.boxTotalCount = r.planBoxInfo.totalBox ?? "-";
            state.plan.transportTotalVolume = r.planBoxInfo.totalVolume ? cmCubicToM(r.planBoxInfo.totalVolume) : '-';
            state.plan.transportTotalWeight = r.planBoxInfo.totalBoxWeight ? gToKg(r.planBoxInfo.totalBoxWeight) : '-';
            state.plan.transportTotalCharacteristic = r.planBoxInfo.transportCharacteristics;
            state.plan.totalContainerVolume = r.planBoxInfo.totalContainerVolume ? cmCubicToM(r.planBoxInfo.totalContainerVolume) : 0;
            state.plan.totalContainerWeight = r.planBoxInfo.totalContainerWeight ? gToKg(r.planBoxInfo.totalContainerWeight) : 0;

            let columns = [];
            if (!state.plan.toAddressData.isInternationalTransport) {
              columns = innerProductColumns.filter(x => x.dataIndex != "rests");
            } else {
              columns = innerProductColumns;
            }
            state.plan.innerColumns = columns;

            if (r.customsWayInfo) {
              if (r.customsWayInfo.integrationUrl) {
                state.logistics.imgUrl =
                  [{
                    name: "logistics.four_in_one_file",
                    url: r.customsWayInfo.integrationUrl
                  }];
              } else {
                state.logistics.imgUrl = [
                  {
                    name: "logistics.customs_declaration",
                    url: r.customsWayInfo.customsDeclarationUrl
                  },
                  {
                    name: "logistics.contract",
                    url: r.customsWayInfo.contractUrl
                  },
                  {
                    name: "logistics.invoice",
                    url: r.customsWayInfo.invoiceUrl
                  },
                  {
                    name: "logistics.packing_documents",
                    url: r.customsWayInfo.packingUrl
                  }
                ];
              }
            }

            state.customsInfo = r.customsInfo?.customsInfoCollect?.map((x, index) => {
              return {
                key: index + 1,
                productName: x.productName,
                productNo: x.productNo,
                enTradeNameCustom: x.brandEnName,
                chTradeNameCustom: x.brandCName,
                brand: x.brand,
                purposeEnDescription: x.purposeEnDescription,
                purposeCnDescription: x.purposeCnDescription,
                currencySymbol: x.currencySymbol,
                applyMiddleRate: x.averagePrice,
                hsCode: x.hsCode,
                number: x.unit,
                subtotal: x.totalPrice,
                cnMaterial: x.cnMaterial,
                enMaterial: x.enMaterial,
              }
            });

            let logisticsTemp = r.transportPlanLogistic ? [r.transportPlanLogistic] : [];
            state.logistics.logisticsSchemeList = logisticsTemp?.map((x, index) => {
              if (index == 0) {
                state.logistics.isShowVATInput = x?.logisticsRegionInfo?.isVatRequired;//是否支持Vat
                state.logistics.VAT = x?.logisticsRegionInfo?.vatCode;
              }
              return {
                key: index,
                id: x.logisticsBaseInfo.id,
                scheme: x.logisticsBaseInfo.name,
                code: x.logisticsBaseInfo.code,
                descriptionItems: x.logisticsBaseInfo.descriptionItems,

                logisticsWay: x.logisticsBaseInfo.logisticsType,
                deliveryWay: x.logisticsBaseInfo.deliveryType,

                daysMin: x.logisticsBaseInfo.daysMin,
                daysMax: x.logisticsBaseInfo.daysMax,
                channelTimeType: x.logisticsBaseInfo.channelTimeType,//签收或自提 枚举

                averagePrice: x.logisticsBaseInfo.averagePrice,//平均费
                expressFee: x.logisticsBaseInfo.totalFeeWithoutServices,//合计费用
                currencySymbol: x.logisticsBaseInfo.currencySymbol,//货号符号

                chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight ? gToKg(x.logisticsBaseInfo.chargeableWeight) : '0',//计费重
                volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam,//体积系数
                chargeableVolume: cmCubicToM(x.logisticsBaseInfo.chargeableVolume),
                logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,
                logisticsRegionInfo: x.logisticsRegionInfo,//是否支持DDP和签字

                // 卡车相关
                feeCalculationMethod: x.logisticsBaseInfo?.feeCalculationMethod,
                truckBaseInfo: x.logisticsBaseInfo?.truckBaseInfo,
              }
            })

            if (state.logistics.logisticsSchemeList.length) {
              let logisticsSchemeItem = state.logistics.logisticsSchemeList[0]
              if (logisticsSchemeItem.feeCalculationMethod === feeCalculationMethodEnum.template) {
                // 物流
                state.logistics.columnsLogisticsScheme = columnsLogisticsScheme;
              } else {
                // 卡车
                state.logistics.columnsLogisticsScheme = columnsLogisticsTruck;
              }
            }

            state.fee = r.fee;
          } catch (error) {
          }
        })
        .catch((res) => {
        })
        .finally(() => {
          state.pageLoading = false;
        })
    };

    const handleBacktrack = () => {
      delVisitedRoute(router.currentRoute.value);
      // router.push({ name: 'transport_customer_list' });
      router.go(-1);
    }

    const handleOrderPay = () => {

      let pra = {
        "id": state.plan.id,
      };
      state.payModal.loading = true;
      payOrder(pra).then((res) => {
        state.payModal.visible = false;
        message.success(vueI18n.t("common.succeed"));
        state.plan.planStatus = transportStatusEnum.havePaid;
        state.payModal.loading = false;
      }).catch(() => {
        state.payModal.loading = false;
      })
    }

    const handleShowTrackModal = () => {
      traceModalRef.value.handleTrackShowModal(state.plan.productTableList)
    }

    const handleBulkPrint = async () => {
      try {
        state.bulkPrintLoading = true;
        let boxLabelPdfUrls = batchPrintBoxOfPlanTransport(route.params.id);
        await localPrint(boxLabelPdfUrls, "pdf");
      } catch (error) {
      } finally {
        state.bulkPrintLoading = false;
      }
    }

    const handlePrintBoxLabel = async (record) => {
      try {
        record.loading = true;
        let boxLabelPdfUrl = printBoxOfPlanTransport(route.params.id, record.planDetailId);
        await localPrint(boxLabelPdfUrl, "pdf")
      } catch (error) {
      } finally {
        record.loading = false;
      }
    }

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(productStatisticsTypeEnum.console, route.params.id)
    }

    const handleCancelBox = (record) => {
      state.partialReceiptState.planDetailId = record.planDetailId
      state.partialReceiptState.whBoxId = record.whBoxId
      state.partialReceiptState.visible = true;
    }

    const handleConsoleCancelBox=(pinCode)=>{
       state.partialReceiptState.loading = true;
      const data = {
        planId: route.params.id,
        planDetailId: state.partialReceiptState.planDetailId,
        whBoxId: state.partialReceiptState.whBoxId,
        pinCode
      }
      consoleCancelBox(data).then(() => {
        state.partialReceiptState.visible = false;
        message.success(vueI18n.t("common.succeed"));
        funcSearchPlanInfo(data.planId);
      }).catch(() => { }).finally(() => {
        state.partialReceiptState.loading = false;
      })
    }

    const handleGetFeeAuditDetail = () => {
      funcSearchPlanInfo(route.params.id);
    };

    const handleOpenLogisticsModal = () => {
      let transportPlanLogistic = state.logistics.logisticsSchemeList.length ? state.logistics.logisticsSchemeList[0] : null;
      let data = null
      if (transportPlanLogistic) {
        data = {
          logisticsId: transportPlanLogistic.id,
          isChooseDdp: transportPlanLogistic.logisticsRegionInfo.isChooseDdp,
          isChooseSign: transportPlanLogistic.logisticsRegionInfo.isChooseSign,
          vatCode: transportPlanLogistic.logisticsRegionInfo.vatCode,
        }
      }
      logisticsChannelModalRef.value.init(route.params.id, data);
    };

    const trackModal = reactive({
      visible: false,
      loading: false,
      planId: null,
      warehouseId: null,
      list: [],
      no: null,
    })

     const handleShowModalTrackingTrajectory = () => {
        trackModal.visible=true;
        trackModal.planId= state.plan.id;
        trackModal.warehouseId= state.plan.logisticsWarehouseId;
        trackModal.no= state.plan.no;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    const handleShowPinCode=()=>{
      state.partialReceiptState.visible = true;
    }

    const handleExportPackingList=()=>{
      state.partialReceiptState.loading = true;
      let url = exportPackingList()
      const postData = {
        planId: route.params.id,
      };
      downloadFile(url, `${state.plan.no}_packing_list_${dateString()}.xlsx`, "POST", postData)
        .then(() => {
          state.partialReceiptState.loading = false
        })
        .catch(() => {
          state.partialReceiptState.loading = false
        })
    }

    onMounted(async () => {
      let planId = route.params.id;
      if (planId) {
        state.plan.id = planId;
        funcSearchPlanInfo(planId);
        funcGetCurrencys();
      }
    });

    return {
      ...toRefs(state),
      gToKg,
      cmCubicToM,
      customsInfoColumns,
      traceModalRef,
      currentPageIndex,
      productStatisticsModalRef,
      logisticsChannelModalRef,
      getLinkman,
      getLinkUser,
      urlHelper,

      isCanCancelBox,
      getCommonFeeList,
      getAddress,
      getAccessorialsName,
      handleParseTime,
      handleBacktrack,
      handleOrderPay,
      handleShowTrackModal,
      handleBulkPrint,
      handlePrintBoxLabel,
      handleShowProductStatisticsModal,
      handleCancelBox,
      handleOpenLogisticsModal,
      handleGetFeeAuditDetail,
      handleConsoleCancelBox,
      trackModal,
      handleShowModalTrackingTrajectory,

      handleExportPackingList,
      handleShowPinCode,
      funcSearchPlanInfo,
    };
  },
});
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}

.table-list-img-common {
  width: 54px;
  height: 54px;

  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :deep(.ant-image img) {
    max-height: 45px;
  }
}

.express-discount+.express-discount::before {
  content: ", ";
}
</style>
<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.supplier_address_api") }}
    </template>
    <template #contentBody="wrap">
      
      <a-row type="flex" justify="space-between" align="bottom" :gutter="[16, 16]">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                style="width: 250px"
                v-model:value="searchData.keyword"
                :placeholder="$t('logistics.name_or_keyword')"
                allowClear
              />
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchData.settingId"
                :placeholder="$t('logistics.api_account_number')"
                :show-search="true"
                option-filter-prop="search-key"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in settingIdList"
                  :key="index"
                  :value="item.value"
                  :title="item.text"
                  :search-key="item.text"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchData.objectId"
                :placeholder="$t('logistics.supply_channels')"
                :show-search="true"
                option-filter-prop="search-key"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in objectIdList"
                  :key="index"
                  :value="item.value"
                  :title="`${item.text}(${item.text1})`"
                  :search-key="item.text + item.text1"
                  >{{ item.text }}({{ item.text1 }})</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchData.isActive"
                :placeholder="$t('warehouse.status')"
                allow-clear
              >
                <a-select-option :value="1" :title="$t('logistics.enabled')">{{ $t('logistics.enabled') }}</a-select-option>
                <a-select-option :value="0" :title="$t('logistics.disabled')">{{ $t('logistics.disabled') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button
                type="primary"
                :loading="tableData.loading"
                @click="handleSearch"
                >{{ $t("common.query") }}</a-button
              >
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button @click="handleCreate">{{ $t("common.create") }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #openApiRunStatus="{ record }">
          <span
            v-if="record.openApiRunStatus === openApiRunStatusEnum.normal"
            >{{ $t("common.normal") }}</span
          >
          <span
            v-else-if="
              record.openApiRunStatus === openApiRunStatusEnum.exception
            "
            class="text-error"
            >{{ $t("common.exception") }}</span
          >
        </template>
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #updateTime="{ record }">{{
          $filters.utcToCurrentTime(record.lastModificationTime)
        }}</template>
        <template #chanels="{ record }">
          {{ record.chanels.map(item => item.name).join() }}
        </template>
        <template #isEnable="{ record }">
          <a-switch
            v-model:checked="record.isActive"
            :loading="record.loading"
            @change="handleChangeActive(record)"
          />
        </template>
        <template #operate="{ record }">
          <a-button type="ghost" @click="handleEdit(record.id)">{{
            $t("common.edit")
          }}</a-button>
        </template>
      </a-table>
      <a-modal
        v-model:visible="modalState.visible"
        :title="`${$t('menu.supplier_address_api')}-${
          modalState.modalType === 1 ? $t('common.edit') : $t('common.create')
        }`"
        :confirm-loading="modalState.confirmLoading"
        @ok="handleSubmit"
      >
        <a-spin :spinning="modalState.loading">
          <a-form
            ref="formRef"
            :model="modalState.formState"
            :rules="rules"
            :label-col="labelCol"
          >
            <a-form-item :label="$t('logistics.interface_name')" name="name">
              <a-input
                v-model:value="modalState.formState.name"
                :placeholder="$t('logistics.interface_name')"
                allowClear
              />
            </a-form-item>
            <a-form-item
              :label="$t('logistics.api_account_number')"
              name="settingId"
            >
              <a-select
                v-model:value="modalState.formState.settingId"
                :placeholder="$t('logistics.api_account_number')"
                :show-search="true"
                option-filter-prop="search-key"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in settingIdList"
                  :key="index"
                  :value="item.value"
                  :title="item.text"
                  :search-key="item.text"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('logistics.supply_channels')" name="relationIdList">
              <a-select
                v-model:value="modalState.formState.relationIdList"
                :placeholder="$t('logistics.supply_channels')"
                :show-search="true"
                option-filter-prop="search-key"
                mode="multiple"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in objectIdList"
                  :key="index"
                  :value="item.value"
                  :title="`${item.text}(${item.text1})`"
                  :search-key="item.text + item.text1"
                  >{{ item.text }}({{ item.text1 }})</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('common.enabled')">
              <a-switch v-model:checked="modalState.formState.isActive" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </template>
    <template #contentFooter
      ><CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager
    ></template>
  </Content>
</template>

<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { useI18n } from "vue-i18n/index";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Modal,
  Switch,
  Spin,
  Form,
  message,
} from "ant-design-vue";
import {
  getConfigurationPagedList,
  updateOpenApiInterfaceConfigurationActive,
  getConfigurationById,
  createOpenApiInterfaceConfiguration,
  updateOpenApiInterfaceConfiguration,
  getAllCanChooseSupplier,
  getAllCanChooseSetting,
} from "@/api/modules/supplier/addressapi";
import {
  openApiRunStatus as openApiRunStatusEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "supplier_address_api",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ASwitch: Switch,
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ATextArea: Input.TextArea,
    Content,
    CPager,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const apiManageModalRef = ref(null);
    const formRef = ref(null);

    const state = reactive({
      settingIdList: [],
      objectIdList: [],
      searchData: {
        keyword: null,
        settingId: null,
        objectId: null,
        isActive: null,
      },
      searchDataCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      modalState: {
        visible: false,
        loading: false,
        confirmLoading: false,
        modalType: 0,
        formState: {
          id: null,
          name: null,
          settingId: null,
          relationIdList: [],
          isActive: true,
        },
      },
    });

    const columns = [
      {
        dataIndex: "name",
        title: () => vueI18n.t("logistics.interface_name"),
        width: 200,
        align: "center",
      },
      {
        dataIndex: "settingName",
        title: () => vueI18n.t("logistics.api_account_number"),
        width: 200,
        align: "center",
      },
      {
        title: () => vueI18n.t("devops.running_state"),
        width: 120,
        slots: {
          customRender: "openApiRunStatus",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("warehouse.creation_time"),
        width: 150,
        slots: {
          customRender: "creationTime",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("logistics.last_modification_time"),
        width: 150,
        slots: {
          customRender: "updateTime",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("menu.logistics_channel"),
        width: 250,
        slots: {
          customRender: "chanels",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("finance.status"),
        width: 150,
        slots: {
          customRender: "isEnable",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 150,
        slots: {
          customRender: "operate",
        },
        align: "center",
      },
    ];

    const rules = {
      name: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.interface_name"),
        ]),
      },
      settingId: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.api_account_number"),
        ]),
      },
      relationIdList: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.supply_channels"),
        ]),
      },
    };

    const getDetail = (id) => {
      state.loading = true;
      getConfigurationById({ id })
        .then((res) => {
          let { result } = res;
          if (result) {
            let formState = JSON.parse(
              JSON.stringify(state.modalState.formState)
            );
            for (const key in formState) {
              if (Object.hasOwnProperty.call(result, key)) {
                state.modalState.formState[key] = result[key];
              }
            }
            state.modalState.formState.relationIdList = result.chanels.map(item => item.id);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const handleChangeActive = (record) => {
      record.loading = true;
      updateOpenApiInterfaceConfigurationActive({
        id: record.id,
        isActive: record.isActive,
      })
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .finally(() => {
          record.loading = false;
        });
    };

    const handleSubmit = async () => {
      try {
        await formRef.value.validate()
        state.modalState.confirmLoading = true
        const data = JSON.parse(JSON.stringify(state.modalState.formState))
        if (state.modalState.modalType === 0) {
          await createOpenApiInterfaceConfiguration(data)
        } else {
          await updateOpenApiInterfaceConfiguration(data)
        }
        message.success(vueI18n.t('common.succeed'))
        state.modalState.visible = false
        handleInitSearch()
      } catch (error) {
        
      } finally {
        state.modalState.confirmLoading = false
      }
    }

    const handleCreate = () => {
      state.modalState.visible = true;
      state.modalState.modalType = 0;
      nextTick(() => {
        formRef.value.resetFields();
      });
    };

    const handleEdit = (id) => {
      state.modalState.visible = true;
      state.modalState.modalType = 1;
      getDetail(id);
    };

    const getPageData = () => {
      const data = Object.assign(
        {},
        state.searchDataCache,
        state.tableData.pageData
      );
      data.isActive = data.isActive === 1 ? true : data.isActive === 0 ? false : null;
      state.tableData.loading = true;
      getConfigurationPagedList(data)
        .then((res) => {
          let { result } = res;
          if (result) {
            let { items = [], totalCount = 0 } = result;
            state.tableData.pageData.totalCount = parseInt(totalCount);
            state.tableData.tableList = items.map((item) => {
              item.loading = false;
              return item;
            });
          }
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchDataCache, state.searchData);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    onMounted(async () => {
      try {
        let { result: accountList } = await getAllCanChooseSetting()
        state.settingIdList = accountList
        let { result: supplierList } = await getAllCanChooseSupplier()
        state.objectIdList = supplierList
        handleInitSearch()
      } catch (error) {
        
      }
    });

    return {
      ...toRefs(state),
      apiManageModalRef,
      columns,
      formRef,
      labelCol: { style: { width: "120px" } },
      rules,
      openApiRunStatusEnum,
      handleSearch,
      handlePage,
      handleCreate,
      handleEdit,
      handleChangeActive,
      handleSubmit,
    };
  },
});
</script>

import client from "../../../client";

const Api = {
  // 获取所有有效租户列表
  getSelectOptionList: "/api/ProductService/Shop/GetSelectOptionList",
  // 获取租户用户统计
  getShopUserStatistics: "/api/URCService/ShopUser/GetShopUserStatistics",
  // 获取所有租户用户列表
  getShopUserListPaged: "/api/ProductService/ShopUser/GetListPaged",
  // 获取租户用户详情
  getShopUserById: "/api/ProductService/ShopUser/GetById",
  // 锁定租户用户
  lockShopUser: "/api/ProductService/Account/LockAndLogOut",
  // 恢复锁定的租户用户
  continueShopUser: "/api/URCService/User/Continue",
  // 删除租户用户
  deleteShopUser: "/api/URCService/ShopUser/Delete",
  // 登陆记录
  getSafetyList: "/api/URCService/UserLoginLog/GetList",
  // 获取所有可用租户用户角色
  getShopRoles: "/api/URCService/Role/GetShopRoles",
  // 获取租户用户角色
  getRoleIdsByUserId: "/api/URCService/User/GetRoleIdsByUserId",
  // 更新店铺(租户|公司)角色
  updateShopUserRole: "/api/URCService/ShopUser/UpdateShopUserRole",
};

export const getSelectOptionList = (data) => {
  return client.request({
    url: Api.getSelectOptionList,
    data,
    method: "POST",
  });
};

export const getShopUserStatistics = (data) => {
  return client.request({
    url: Api.getShopUserStatistics,
    data,
    method: "POST",
  });
};

export const getShopUserListPaged = (data) => {
  return client.request({
    url: Api.getShopUserListPaged,
    data,
    method: "POST",
  });
};

export const getShopUserById = (data) => {
  return client.request({
    url: Api.getShopUserById,
    params: data,
    method: "GET",
  });
};

export const lockShopUser = (data) => {
  return client.request({
    url: Api.lockShopUser,
    data,
    method: "POST",
  });
};

export const continueShopUser = (data) => {
  return client.request({
    url: Api.continueShopUser,
    data,
    method: "PUT",
  });
};

export const deleteShopUser = (data) => {
  return client.request({
    url: Api.deleteShopUser,
    data,
    method: "PUT",
  });
};

export const getSafetyList = (data) => {
  return client.request({
    url: Api.getSafetyList,
    data,
    method: "POST",
  });
};

export const getShopRoles = (data) => {
  return client.request({
    url: Api.getShopRoles,
    data,
    method: "POST",
  });
};

export const getRoleIdsByUserId = (data) => {
  return client.request({
    url: Api.getRoleIdsByUserId,
    params: data,
    method: "GET",
  });
};

export const updateShopUserRole = (data) => {
  return client.request({
    url: Api.updateShopUserRole,
    data,
    method: "POST",
  });
};

<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.warehouse_monitor_return_detail")
    }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[0, 8]">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("return.return_package_no") }}: {{ returnInfo.packageNo }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("return.return_plan_no") }}: {{ returnInfo.channelReturnPlanNo }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.standby_identification1") }}: {{ returnInfo?.packageNo1??"-" }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.standby_identification2") }}: {{ returnInfo?.packageNo2??"-" }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.creation_time") }}: {{ $filters.utcToCurrentTime(returnInfo.creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.last_modification_time") }}: {{ $filters.utcToCurrentTime(returnInfo.lastModificationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("return.the_goods_warehouse") }}: {{ returnInfo.warehouseNo }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("return.return_of_the_source") }}: {{ $t($enumLangkey("returnPlanSourceType", returnInfo.sourceType)) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.delivery_way") }}: {{ $t($enumLangkey("deliveryWay", returnInfo.deliveryType)) }}
              </a-col>
              <template v-if="returnInfo.shopData">
                <a-col :md="12" :sm="24" :xs="24">
                  {{ $t("warehouse.customer") }}: {{ returnInfo.shopData.shopName }}
                </a-col>
                <a-col :md="12" :sm="24" :xs="24">
                  {{$t('common.shop_mark')}}: {{ returnInfo.shopData.shopMark  }}
                </a-col>
                <a-col :md="12" :sm="24" :xs="24">
                  NO: {{ returnInfo.shopData.shopNo  }}
                </a-col>
              </template>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('warehouse.trajectory') }}: 
                <span v-if="returnInfo.newestTrack">
                  <div><small>{{ returnInfo.newestTrack?.trackRemark }}</small></div>
                  <div><small>{{ $filters.utcToCurrentTime(returnInfo.newestTrack?.trackTime) }}</small></div>
                </span>
                <span v-else>-</span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]" style="height: 100%;" type="flex" align="middle">
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t("return.the_parcel_status") }}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <a-typography-text
                    :type="returnInfo.status === returnPlanStatusEnum.canceled ? 'secondary' : 'success'"
                  >{{ $t($enumLangkey("returnPlanStatus", returnInfo.status)) }}</a-typography-text>
                </div>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t('return.trajectory_status') }}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <span v-if="returnInfo.isShowTrackOperate">
                    <a-typography-text type="success" v-if="returnInfo.waybillOperationStatus">{{ $t($enumLangkey('waybillOperationStatus', returnInfo.waybillOperationStatus)) }}</a-typography-text>
                    <span v-else>-</span>
                  </span>
                  <span v-else style="color: #AAAAAA;">{{ $t('return.untraceable') }}</span>
                </div>
              </a-col>
              <a-col :span="24" v-if="returnInfo.isShowTrackOperate">
                <div style="text-align: center;">
                  <a-button type="primary" @click="handleShowTrackModal" ghost>{{ $t("warehouse.check_the_track") }}</a-button>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-table
          class="mt-3"
          :columns="columns"
          size="small"
          :data-source="returnInfo.planDetail.details"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="{
            defaultPageSize: defaultPageSize,
            hideOnSinglePage: true,
            current: currentPageIndex,
            position: 'both',
          }"
          @change="
            (page) => {
              currentPageIndex = page.current;
            }
          "
        >
          <template #serialNumber="{ index }">{{
            defaultPageSize * (currentPageIndex - 1) + (index + 1)
          }}</template>
          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.productImg" />
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>
          <template #packingImgUrl="{ record }">
            <div class="table-list-img-common" v-if="record.productPackingImg">
              <c-image :src="record.productPackingImg" />
            </div>
            <span v-else>-</span>
          </template>
          <template #packagingSize="{ record }">
            {{ record.packSize.length }}x{{ record.packSize.width }}x{{
              record.packSize.height
            }}cm
          </template>
          <template #productWeight="{ record }">
            {{ handleGToKg(record.packSize.weight) }}kg
          </template>
          <template #checkPackSize="{ record }">
            {{ record.checkPackSize.length }}x{{
              record.checkPackSize.width
            }}x{{ record.checkPackSize.height }}cm/{{
              handleGToKg(record.checkPackSize.weight)
            }}kg
          </template>
          <template #isKeepProductPackage="{ record }">
            <span v-if="record.isKeepProductPackage === true">{{ $t("warehouse.need_to_keep_the_outer_packaging") }}</span>
            <span v-else-if="record.isKeepProductPackage === false">{{ $t("warehouse.do_not_keep_the_outer_packaging") }}</span>
            <span v-else>-</span>
          </template>
          <template #optionService="{ record }">
            <template v-if="record.optionService?.isFacadeQC || record.optionService?.isFunctionQC">
              <div v-if="record.optionService?.isFacadeQC">
                {{ $t("enum.order_logistics_fee_130") }}
              </div>
              <div v-if="record.optionService?.isFunctionQC">
                {{ $t("enum.order_logistics_fee_150") }}
              </div>
            </template>
            <span v-else>-</span>
          </template>
          <template #scannableInformation>
            {{ $t($enumLangkey("returnPlanFnskuType", returnInfo.fnSkuType)) }}
          </template>
          <template #footer>
            <a-row :gutter="32" type="flex" justify="end">
              <a-col
                >{{ $t("return.product_SKU") }}:
                {{ returnInfo.planDetail.skuCount }}</a-col
              >
              <a-col
                >{{ $t("return.returns_the_total") }}:
                {{ returnInfo.planDetail.returnCount }}</a-col
              >
              <a-col
                >{{ $t("return.have_received") }}:
                {{ returnInfo.planDetail.receiveNum }}</a-col
              >
              <a-col
                >{{ $t("return.destroyed") }}:
                {{ returnInfo.planDetail.destroyNum }}</a-col
              >
            </a-row>
          </template>
        </a-table>
        <a-row class="mt-3" type="flex" justify="end">
          <a-col>
            <a-form
              v-if="returnInfo.fee"
              :label-col="{ style: { width: '250px' } }"
              :wrapper-col="{ style: { width: '200px' } }"
            >
              <template
                v-for="(item, index) in returnInfo.fee.fees"
                :key="index"
              >
                <a-form-item
                  v-if="item.price"
                  style="margin-bottom: 0"
                  :label="$t($enumLangkey('orderLogisticsFee', item.feeType))"
                >
                  <template v-if="typeof item.actualAmount === 'number' && item.amount !== item.actualAmount">
                    <del style="color: #AAAAAA;"
                      >{{ returnInfo.fee.currencySymbol
                      }}{{ $filters.amountToFixed2(item.amount) }}</del
                    >
                    <span class="ml-2"
                      >{{ returnInfo.fee.currencySymbol
                      }}{{ $filters.amountToFixed2(item.actualAmount) }}</span
                    >
                  </template>
                  <span v-else
                    >{{ returnInfo.fee.currencySymbol
                    }}{{ $filters.amountToFixed2(item.amount) }}</span
                  >
                </a-form-item>
              </template>
            </a-form>
          </a-col>
        </a-row>
        <!-- 处理记录 -->
        <a-table
          class="mt-3"
          :columns="recordColumns"
          size="small"
          :data-source="tableData.tableList"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="false"
        >
          <template #serialNumber="{ index }">{{
            tableData.pageData.maxResultCount *
              (tableData.pageData.currentIndex - 1) +
            (index + 1)
          }}</template>
          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.productImg" />
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>
          <template #packagingSize="{ record }">
            {{ record.packSize.length }}x{{ record.packSize.width }}x{{
              record.packSize.height
            }}cm
          </template>
          <template #productWeight="{ record }">
            {{ handleGToKg(record.packSize.weight) }}kg
          </template>
          <template #checkPackSize="{ record }">
            {{ record.checkPackSize.length }}x{{
              record.checkPackSize.width
            }}x{{ record.checkPackSize.height }}cm/{{
              handleGToKg(record.checkPackSize.weight)
            }}kg
          </template>
          <template #isQualified="{ record }">
            <span v-if="record.isQualified === true" class="text-success">{{
              $t("return.is_qualified_1")
            }}</span>
            <span v-else-if="record.isQualified === false" class="text-error">{{
              $t("return.is_qualified_2")
            }}</span>
            <span v-else>-</span>
          </template>
          <template #qualityImgUrls="{ record }">
            <a-popover v-if="record.qualityImgUrls.length">
              <template #content>
                <a-space>
                  <div
                    v-for="(imgItem, index) in record.qualityImgUrls"
                    class="table-list-img-common"
                    :key="index"
                  >
                    <c-image :src="imgItem" />
                  </div>
                </a-space>
              </template>
              <span class="text-primary" style="font-size: 20px">
                <FileImageOutlined />
              </span>
            </a-popover>
            <span v-else>-</span>
          </template>
          <template #status="{ record }">
            <span
              v-if="record.status === returnPlanRecordStatusEnum.inStorage"
              class="text-success"
              >{{
                $t($enumLangkey("returnPlanRecordStatus", record.status))
              }}</span
            >
            <span
              v-if="record.status === returnPlanRecordStatusEnum.destruction"
              class="text-error"
              >{{
                $t($enumLangkey("returnPlanRecordStatus", record.status))
              }}</span
            >
          </template>
          <template #title>
            <a-space>
              {{ $t("return.the_processing_results") }}
              <a-select v-model:value="tableData.searchState.isQualified" @change="handleQualifiedStatusChange">
                <a-select-option
                  v-for="(item, index) in isQualifiedList"
                  :key="index"
                  :value="item.value"
                  :title="item.label"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-space>
          </template>
          <template #footer>
            <a-row :gutter="32" type="flex" justify="end">
              <a-col
                v-if="returnReceiveFee?.totalAmount > 0"
                ><a-tag color="orange">{{
                  $t("return.deducted_automatically")
                }}</a-tag></a-col
              >
              <a-col v-if="returnReceiveFee"
                >{{ $t("return.destruction_handling_charge") }}:
                {{ returnReceiveFee.currencySymbol
                }}{{ $filters.amountToFixed2(returnReceiveFee.totalAmount) }}</a-col
              >
            </a-row>
          </template>
        </a-table>
        <CPager
          class="text-center mt-2"
          @handlePage="handlePage"
          :page-data="tableData.pageData"
        ></CPager>
        <a-row class="mt-3 mr-3" type="flex" justify="end">
          <a-col>
            <a-form
              v-if="returnInfo.fee"
              :label-col="{ style: { width: '250px' } }"
              :wrapper-col="{ style: { width: '200px', textAlign: 'right' } }"
            >
              <a-divider style="margin-bottom: 0;" />
              <a-form-item style="margin-bottom: 0;" :label="$t('logistics.total')">
                <template v-if="returnInfo.fee.actualTotalAmount && returnInfo.fee.totalAmount !== returnInfo.fee.actualTotalAmount">
                  <del style="color: #AAAAAA;">{{ returnInfo.fee.currencySymbol }}{{ $filters.amountToFixed2(returnInfo.fee.totalAmount) }}</del>
                  <span class="ml-2">{{ returnInfo.fee.currencySymbol }}{{ $filters.amountToFixed2(returnInfo.fee.actualTotalAmount) }}</span>
                </template>
                <span v-else>{{ returnInfo.fee.currencySymbol }}{{ $filters.amountToFixed2(returnInfo.fee.totalAmount) }}</span>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </a-spin>
      <a-row class="mt-4" type="flex" justify="end">
        <a-col>
          <a-space>
            <a-button type="ghost" v-if="!returnInfo.status || returnInfo.status === returnPlanStatusEnum.planned" @click="handleShowPinModal(2, channelReturnPlanId)">{{
              $t("common.cancel")
            }}</a-button>
            <a-button type="primary" @click="handleBack">{{
              $t("common.backtrack")
            }}</a-button>
          </a-space>
        </a-col>
      </a-row>
      <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("menu.warehouse_monitor_return") }}
        </template>
        <template v-slot:no>
          {{trackModal.packageNo}}/{{trackModal.returnPlanNo}}
        </template>
      </CTrackInfoModal> 
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />
    </template>
  </Content>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Checkbox,
  Space,
  Form,
  Spin,
  Popover,
  Tag,
  Divider,
  Typography,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage";
import CPager from "@/views/components/CPager";
import CPinTipsModal from '@/views/components/CPinTipsModal.vue';
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useTab } from "@/hooks/tabs/index";
import { getAddressByLanguageV2, gToKg, getLinkman } from "@/utils/general";
import { getDetailById, getOperateReceive, getTrack, refundChannelReturnPlanConsole } from "@/api/modules/return";
import {
  returnPlanStatus as returnPlanStatusEnum,
  returnPlanRecordStatus as returnPlanRecordStatusEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "warehouse_monitor_return_detail",
  components: {
    CTrackInfoModal,
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ACheckbox: Checkbox,
    ASpace: Space,
    AForm: Form,
    AFormItem: Form.Item,
    APopover: Popover,
    ATag: Tag,
    ADivider: Divider,
    ATypographyText: Typography.Text,
    Content,
    CImage,
    CPager,
    CPinTipsModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const trackModalRef = ref(null);
    const defaultPageSize = ref(50);
    const currentPageIndex = ref(1);

    const state = reactive({
      channelReturnPlanId: null,
      loading: false,
      btnLoading: false,
      packageNo: null,
      returnInfo: {
        packageNo: null,
        packageNo1: null,
        packageNo2: null,
        channelReturnPlanNo: null,
        warehouseNo: null,
        shopData: null,
        creationTime: null,
        lastModificationTime: null,
        status: null,
        deliveryType: null,
        sourceType: null,
        receiveWarehouseAddress: null,
        optionService: null,
        fnSkuType: null,
        isShowTrackOperate: null,
        waybillOperationStatus: null,
        planDetail: {
          skuCount: 0,
          returnCount: 0,
          receiveNum: 0,
          destroyNum: 0,
          details: [],
        },
        fee: null,
      },
      returnReceiveFee: null,
      isQualifiedList: [
        {
          label: vueI18n.t("account.all"),
          value: 0,
        },
        {
          label: vueI18n.t("return.is_qualified_1"),
          value: 1,
        },
        {
          label: vueI18n.t("return.is_qualified_2"),
          value: 2,
        },
      ],
      tableData: {
        loading: false,
        tableList: [],
        searchState: {
          isQualified: 0,
        },
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      pinModalState: {
        visible: false,
        loading: false,
        key: null,
        dataTemp: null,
      },
    });

    const columns = [
      {
        width: 50,
        title: () => vueI18n.t("common.serial_number"),
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        width: 150,
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("warehouse.product_no"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("logistics.packaging_figure"),
        slots: {
          customRender: "packingImgUrl",
        },
      },
      {
        dataIndex: "seSku",
        width: 100,
        title: "SESKU",
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.business_packaging_size"),
        slots: {
          customRender: "packagingSize",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("warehouse.product_weight"),
        slots: {
          customRender: "productWeight",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("return.approved_packing_information"),
        slots: {
          customRender: "checkPackSize",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.whether_to_keep_the_outer_packaging"),
        slots: {
          customRender: "isKeepProductPackage",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("return.optional_services"),
        slots: {
          customRender: "optionService",
        },
      },
      {
        dataIndex: "fnSku",
        width: 100,
        slots: {
          title: "scannableInformation"
        }
      },
      {
        dataIndex: "num",
        width: 80,
        title: () => vueI18n.t("logistics.amount"),
      },
      {
        dataIndex: "receiveNum",
        width: 80,
        title: () => vueI18n.t("return.have_received"),
      },
      {
        dataIndex: "destroyNum",
        width: 80,
        title: () => vueI18n.t("return.destroyed"),
      },
    ];

    const recordColumns = [
      {
        width: 80,
        title: () => vueI18n.t("common.serial_number"),
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        width: 150,
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("warehouse.product_no"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.business_packaging_size"),
        slots: {
          customRender: "packagingSize",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.product_weight"),
        slots: {
          customRender: "productWeight",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("return.approved_packing_information"),
        slots: {
          customRender: "checkPackSize",
        },
      },
      {
        dataIndex: "fnSku",
        width: 100,
        title: () => vueI18n.t("return.identifiable_information"),
      },
      {
        width: 80,
        title: () => vueI18n.t("return.quality_inspection_results"),
        slots: {
          customRender: "isQualified",
        },
      },
      {
        width: 80,
        slots: {
          customRender: "qualityImgUrls",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("return.the_processing_results"),
        slots: {
          customRender: "status",
        },
      },
    ];

    const getAddressInfo = () => {
      if (!state.returnInfo.receiveWarehouseAddress) {
        return "";
      }
      return getAddressByLanguageV2(
        state.returnInfo.receiveWarehouseAddress,
        getters.language
      );
    };

    const handleGToKg = (val) => {
      if (!val) {
        return 0;
      }
      return gToKg(val);
    };

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "warehouse_monitor_return" });
    };

    const handleCancelPlan = (pinCode) => {
      const data = {
        id: state.pinModalState.dataTemp,
        pinCode
      }
      state.pinModalState.loading = true;
      refundChannelReturnPlanConsole(data)
        .then(() => {
          message.success(vueI18n.t("common.successfully_canceled"));
          state.pinModalState.visible = false
          handleBack();
        })
        .catch(() => {})
        .finally(() => {
          state.pinModalState.loading = false;
        });
    }

    const handleShowPinModal = (key, dataTemp = null) => {
      state.pinModalState.key = key
      state.pinModalState.visible = true
      state.pinModalState.dataTemp = dataTemp
    }

    const handlePinModalConfirm = (pinCode) => {
      switch (state.pinModalState.key) {
        // 取消
        case 2:
          handleCancelPlan(pinCode)
          break;
        default:
          break;
      }
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getReceiveData();
    };

    const getReceiveData = () => {
      state.tableData.loading = true;
      let isQualified =
        state.tableData.searchState.isQualified === 0
          ? null
          : state.tableData.searchState.isQualified === 1
          ? true
          : false;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.tableData.searchState,
        {
          channelReturnPlanId: state.channelReturnPlanId,
          isQualified,
        }
      );
      getOperateReceive(data)
        .then(({ result }) => {
          let { totalCount = 0, items = [], fee = null } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
          state.returnReceiveFee = fee;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleQualifiedStatusChange = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;

      getReceiveData();
    };


    const trackModal = reactive({
      visible: false,
      loading: false,
      planId: null,
      list: [],
      packageNo: null,
      returnPlanNo: null,
    });

    const handleShowTrackModal = () => {
      trackModal.visible = true;
      trackModal.planId = state.channelReturnPlanId;
      trackModal.packageNo = state.returnInfo.packageNo;
      trackModal.returnPlanNo = state.returnInfo.channelReturnPlanNo;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrack({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    onMounted(async () => {
      if ("id" in route.params) {
        try {
          state.loading = true;
          state.channelReturnPlanId = route.params.id;
          let { result: returnInfo } = await getDetailById({
            channelReturnPlanId: state.channelReturnPlanId,
          });
          for (const key in state.returnInfo) {
            if (Object.hasOwnProperty.call(returnInfo, key)) {
              state.returnInfo[key] = returnInfo[key];
            }
          }

          getReceiveData();
        } catch (error) {
        } finally {
          state.loading = false;
        }
      } else {
        message.error(vueI18n.t("common.exception"));
      }
    });

    return {
      ...toRefs(state),
      trackModalRef,
      columns,
      recordColumns,
      defaultPageSize,
      currentPageIndex,
      returnPlanStatusEnum,
      returnPlanRecordStatusEnum,
      handleQualifiedStatusChange,
      handleBack,
      handleShowPinModal,
      handlePinModalConfirm,
      getAddressInfo,
      handleGToKg,
      handlePage,
      handleShowTrackModal,
      getLinkman,
      trackModal,
    };
  },
});
</script>

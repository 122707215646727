import client from "../../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  getShopCountStatistics: "/api/ProductService/Shop/GetShopCountStatistics",

  getList: "/api/ProductService/Shop/GetListPaged",

  //账户状态
  updateAccountStatus: "/api/ProductService/Shop/UpdateIsActive",
  //审核状态
  updateAuditStatus: "/api/ProductService/Shop/UpdateAuditStatus",

  // 账户列表
  // 获取所有有效租户列表
  getSelectOptionList: "/api/ProductService/Shop/GetSelectOptionList",
  // 获取所有租户用户列表
  getShopUserListPaged: "/api/ProductService/ShopUser/GetListPaged",
  // 修改分佣状态
  updatePartnerShopRlActive: "/api/SupplierService/PartnerShopRl/UpdateIsActive",
  // 变更伙伴租户关系
  createOrUpdatePartnerShopRl: "/api/SupplierService/PartnerShopRl/CreateOrUpdate",
  // 获取合作伙伴下拉列表
  getPartnerOptionList: "/api/SupplierService/Partner/GetOptionList",
  // 获取合作伙伴变更记录分页列表
  getPartnerShopRlChangeRecordList: "/api/SupplierService/PartnerShopRlChangeRecord/GetList",
  // 导出公司列表
  exportShopList: "/api/ProductService/Shop/ExportShopList",
  // 员工列表
  getEmployeeList: "/api/WarehouseService/Employee/GetSimpleListPaged",
  // 更新公司业务负责人
  updateBusinessLeader: "/api/ProductService/Shop/UpdateBusinessPersonLiable",
  // 更新公司客服
  updateSupportManager: "/api/ProductService/Shop/UpdateCustomerServiceStaff",
  updateUpdateContract: "/api/ProductService/Shop/SetContract",
  getALLAccountLevel: "/api/ProductService/ShopLevel/GetList"
};

export const getALLAccountLevel = () => {
  return client.request({
    url: Api.getALLAccountLevel,
    data:{},
    method: "post",
  });
};

export const updateUpdateContract = ({id,hasContract,contractPath}) => {
  return client.request({
    url: Api.updateUpdateContract,
    data:{id,hasContract,contractPath},
    method: "post",
  });
};

export const getShopCountStatistics = () => {
  return client.request({
    url: Api.getShopCountStatistics,
    data: {},
    method: "post",
  });
};

export const getList = ({ 
  shopLevelId,
  businessPersonLiableIds,
  customerServiceStaffIds,
  auditStatus,ids, shopAccountStatus, searchKey, skipCount, maxResultCount, sorting}) => {
  return client.request({
    url: Api.getList,
    data: { 
      shopLevelId,
      businessPersonLiableIds,
      customerServiceStaffIds,auditStatus,ids, shopAccountStatus, searchKey , skipCount, maxResultCount, sorting},
    method: "post",
  });
};

export const updateAccountStatus = ( id, isActive ) => {
  return client.request({
    url: Api.updateAccountStatus,
    data: { id, isActive },
    method: "post",
  });
};

export const updateAuditStatus = ({ id, isPass, auditFailureReason }) => {
  return client.request({
    url: Api.updateAuditStatus,
    data: { id, isPass, auditFailureReason },
    method: "post",
  });
};

export const getSelectOptionList = (data) => {
  return client.request({
    url: Api.getSelectOptionList,
    data,
    method: "post",
  });
};

export const getShopUserListPaged = (data) => {
  return client.request({
    url: Api.getShopUserListPaged,
    data,
    method: "post",
  });
};

export const updatePartnerShopRlActive = (data) => {
  return client.request({
    url: Api.updatePartnerShopRlActive,
    data,
    method: "post",
  });
};

export const createOrUpdatePartnerShopRl = (data) => {
  return client.request({
    url: Api.createOrUpdatePartnerShopRl,
    data,
    method: "POST",
  });
};

export const getPartnerOptionList = (data) => {
  return client.request({
    url: Api.getPartnerOptionList,
    data,
    method: "POST",
  });
};

export const getPartnerShopRlChangeRecordList = (data) => {
  return client.request({
    url: Api.getPartnerShopRlChangeRecordList,
    data,
    method: "POST",
  });
};

export const exportShopList = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportShopList);
  return url;
}

export const getEmployeeList = (data) => {
  return client.request({
    url: Api.getEmployeeList,
    data,
    method: "POST",
  });
};

export const updateBusinessLeader = (data) => {
  return client.request({
    url: Api.updateBusinessLeader,
    data,
    method: "POST",
  });
};

export const updateSupportManager = (data) => {
  return client.request({
    url: Api.updateSupportManager,
    data,
    method: "POST",
  });
};
<template>
  <Content>
    <template #contentTitle>{{ $t("menu.transport_shipment_transport") }}</template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="state.searchData.planNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="12"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('logistics.transportation_plan_number')"
            allowClear
            @change="handleSelectValueChange('planNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="state.searchData.destinationType"
            :allowClear="true"
            :placeholder="$t('logistics.destination_type')"
            style="width: 250px"
          >
            <a-select-option
              v-for="(value, key) in destinationTypeEnum"
              :key="key"
              :title="$t($enumLangkey('destinationType', value))"
              :value="value"
            >
              {{ $t($enumLangkey("destinationType", value)) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="state.searchData.status"
            :allowClear="true"
            :placeholder="$t('warehouse.status')"
            style="width: 250px"
          >
            <a-select-option
              v-for="(value, key) in transportShippingStatusEnum"
              :key="key"
              :title="$t($enumLangkey('transportShippingStatus', value))"
              :value="value"
            >
              {{ $t($enumLangkey("transportShippingStatus", value)) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-input-group compact>
            <a-select
              style="width: 150px"
              :loading="dropDownData.countryLoading"
              v-model:value="state.searchData.toCountryId"
              :placeholder="$t('common.destination_country')"
              @change="handleCountryChange"
              show-search
              optionFilterProp="search-key"
              allowClear
            >
              <a-select-option
                v-for="(item, index) in dropDownData.country"
                :key="index"
                :search-key="item.cnName + item.enName + item.ioS2"
                :title="getLanguageName(item) + '(' + item.ioS2 + ')'"
                :value="item.id"
                >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option
              >
            </a-select>
            <a-select
              style="width: 150px"
              v-model:value="state.searchData.toCityId"
              :placeholder="$t('logistics.city_search')"
              :filter-option="false"
              :loading="dropDownData.cityLoading"
              show-search
              optionFilterProp="search-key"
              @search="getCityList"
              :disabled="!state.searchData.toCountryId"
              allowClear
            >
              <a-select-option
                v-for="(item, index) in dropDownData.city"
                :key="index"
                :value="item.id"
                :search-key="item.cnName + item.enName + item.abbrCode"
                :title="
                  getLanguageName(item) +
                  '(' +
                  getLanguageName({
                    cnName: item.provinceCnName,
                    enName: item.provinceEnName,
                  }) +
                  ')'
                "
              >
                <template v-if="dropDownData.cityLoading" #notFoundContent>
                  <a-spin size="small" />
                </template>
                {{ getLanguageName(item) }}({{ item.provinceAbbrCode }})
              </a-select-option>
            </a-select>
          </a-input-group>
        </a-col>

        <a-col>
          <a-button type="primary" @click="handleSearch">{{
            $t("common.query")
          }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="state.list"
        :scroll="{ x: 2000, y: wrap.contentHeight - 110 }"
        :pagination="false"
        size="small"
        :loading="state.loading"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template #type_of_goods="{ record }">
          <a-row
            :gutter="[8, 8]"
            v-if="record.transportCharacteristics?.length > 0"
          >
            <a-col
              v-for="(item, index) in record.transportCharacteristics"
              :key="index"
            >
              <a-tag color="default">
                {{ $t($enumLangkey("transportCharacteristics", item)) }}</a-tag
              >
            </a-col>
          </a-row>
          <div v-else>-</div>
        </template>

        <template #shopCustomer="{ record }">
            {{record.shopInfo?.shopName}}
            <div v-if="record.shopInfo?.shopNo">
              NO: {{record.shopInfo?.shopNo}}
            </div>
             <div >
              {{$t('common.shop_mark')}}: {{record.shopInfo?.shopMark??"-"}}
            </div>
        </template>

        <template #mode_of_transport="{ record }">
          {{ $t($enumLangkey("transportType", record.transportType)) }}
        </template>

        <template #destination_type_title>
            {{$t("logistics.transport_route")}}
          <div>
            {{$t("logistics.destination_type")}}
          </div>
        </template>

        <template #destination_type="{ record }">
          <a-row :gutter="[8,8]">
            <a-col>{{$t($enumLangkey('transportRoutes',record.transportRoutes))}}
              <div>
               {{$t($enumLangkey('destinationType',record.destinationType))}}
              </div>
            </a-col>
            <a-col v-if="record.isUploadHsInfo">
             <a-tag color="processing">{{$t('warehouse.declare')}}</a-tag>
            </a-col>
          </a-row>
        </template>

        <template #destination_country_city="{ record }">
          {{
            getLanguageName({
              cnName: record.toCountryCnName,
              enName: record.toCountryEnName,
            })
          }}/
          {{
            getLanguageName({
              cnName: record.toCityCnName,
              enName: record.toCityEnName,
            })
          }}
        </template>

        <template #volume_and_weight="{ record }">
          <div v-if="record.transportVolume < 0 && record.transportWeight < 0">
            -
          </div>
          <div>
            <span v-if="record.transportVolume">
              {{ cmCubicToM(record.transportVolume) }}m<sup>3</sup>
            </span>
            <span v-if="record.transportWeight">
              <span v-if="record.transportVolume"> /</span>
              {{ gToKg(record.transportWeight) }}Kg
            </span>
          </div>
        </template>

        <template #status="{ record }">
          {{ $t($enumLangkey("transportShippingStatus", record.status)) }}
        </template>

        <template #cabin_information="{ record }">
          <a-row>
            <a-col :span="15">
              {{ record.shippingSpaceNo }}
            </a-col>
            <a-col :span="9">
              <span
                v-if="
                  record.transportType == transportTypeEnum.maritimeTransport
                "
              >
                {{ record.cabinetNo }}
              </span>
            </a-col>
          </a-row>
          <div>
            <a-row>
              <a-col :span="15">
                <span
                  v-if="
                    record.transportType == transportTypeEnum.maritimeTransport
                  "
                >
                  <!-- 航司 -->
                  {{ record.vesselCompany }}
                </span>
                <span
                  v-if="record.transportType == transportTypeEnum.airTransport"
                >
                  <!-- 船司 -->
                  {{ record.flightCompany }}
                </span>
              </a-col>
              <a-col :span="9">
                {{ record.bookingNo }}
              </a-col>
            </a-row>
          </div>
        </template>

        <template #timeTitle>
          <div>{{ $t("warehouse.transportation_plan_create_time") }}</div>
          <div>{{ $t("warehouse.transportation_plan_update_time") }}</div>
        </template>

        <template #time="{ record }">
          <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
          <div>{{ $filters.utcToCurrentTime(record.lastModificationTime) }}</div>
        </template>

        <template #operate="{ record }">
          <a-dropdown-button>
            <router-link
              :to="{
                name: 'transport_detail',
                params: { id: record?.transportPlanId },
              }"
            >
              {{ $t("common.details") }}
            </router-link>
            <template #overlay>
              <a-menu @click="(e) => handleClickMenu(e, record)">
                <a-menu-item :key="3" :disabled="!!!record.shippingSpaceId">
                  {{ $t("logistics.view_cabin") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="state.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, onMounted, computed } from "vue";
import {
  Button,
  Col,
  Row,
  Input,
  Table,
  Modal,
  Select,
  Dropdown,
  Menu,
  Spin,
  Tag,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import { getListPaged } from "@/api/modules/transport/list";
import {
  getGlobalCountrys,
  searchCities,
} from "@/api/modules/common/index";
import {
  getName,
  cmCubicToM,
  gToKg,
  debounce,
} from "@/utils/general";
import { useI18n } from "vue-i18n/index";
import {
  transportShippingStatus as transportShippingStatusEnum,
  destinationType as destinationTypeEnum,
  transportType as transportTypeEnum,
} from "@/enum/enum.json";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "transport_shipment_transport",
  components: {
    ARow: Row,
    ACol: Col,
    AButton: Button,
    AInputGroup: Input.Group,
    AInput: Input,
    ATable: Table,
    ASelect: Select,
    ASelectOption: Select.Option,
    AModal: Modal,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpin: Spin,
    Content,
    CPager,
    ATag: Tag,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();

    const columns = [
      {
        width: 150,
        dataIndex: "transportPlanNo",
        title: () => vueI18n.t("logistics.transportation_plan_number"),
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.type_of_goods"),
        slots: {
          customRender: "type_of_goods",
        },
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        width: 150,
        slots: {
          customRender: "shopCustomer"
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.type_of_shipping"),
        slots: {
          customRender: "mode_of_transport",
        },
      },
      {
        width: 100,
        // title: () => vueI18n.t("logistics.destination_type"),
        slots: {
          title: "destination_type_title",
          customRender: "destination_type",
        },
      },
      {
        width: 130,
        title: () => vueI18n.t("logistics.destination_country_city"),
        slots: {
          customRender: "destination_country_city",
        },
      },
      {
        width: 100,
        dataIndex: "transportBoxesNum",
        title: () => vueI18n.t("logistics.transport_box_count"),
      },
      {
        width: 120,
        dataIndex: "logisticsName",
        title: () => vueI18n.t("logistics.logistics_scheme"),
      },
      {
        width: 100,
        title: () =>
          vueI18n.t("common.volume") + "/" + vueI18n.t("common.weight"),
        slots: {
          customRender: "volume_and_weight",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "status",
        },
      },
      {
        width: 230,
        title: () => vueI18n.t("warehouse.cabin_information"),
        slots: {
          customRender: "cabin_information",
        },
      },

      {
        width: 130,
        slots: {
          title: "timeTitle",
          customRender: "time",
        },
      },
      {
        width: 150,
        title: "",
        fixed: "right",
        slots: {
          customRender: "operate",
        },
      },
    ];

    const state = reactive({
      searchData: {
        destinationType: null,
        toCountryId: null,
        toCityId: null,
        planNoes: [],
        status: null,
      },
      list: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const dropDownData = reactive({
      country: [],
      countryLoading: false,
      city: [],
      cityLoading: false,
    });

    const handleSelectValueChange = (key) => {
      let value = state.searchData[key];
      if (Array.isArray(value)) {
        state.searchData[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handleCountryChange = () => {
      dropDownData.city = [];
      state.searchData.toCityId = null;
    };

    const getCityList = debounce((searchKey) => {
      if (searchKey) {
        let countryId = state.searchData.toCountryId;
        dropDownData.city = [];
        if (countryId) {
          dropDownData.cityLoading = true;
          const data = {
            countryId,
            searchKey,
            skipCount: 0,
            maxResultCount: 10,
          };
          searchCities(data)
            .then(({ result }) => {
              if (result) {
                let { items = [] } = result;
                dropDownData.city = items;
              }
            })
            .finally(() => {
              dropDownData.cityLoading = false;
            });
        }
      }
    }, 500);

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleSearch = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcSearch();
    };

    const funcSearch = async () => {
      state.loading = true;
      const data = Object.assign({}, state.pageData, state.searchData);
      getListPaged(data)
        .then(({ result }) => {
          if (result) {
            let { items = [], totalCount = 0 } = result;
            state.list = items;
            state.pageData.totalCount = totalCount * 1;
          }
          state.loading = false;
        })
        .catch((e) => {
          state.loading = false;
        });
    };

    const handleClickMenu = (menu, record) => {
      if (menu.key == 3) {
        router.push({
          name: "transport_shipment_cabin_detail",
          params: {
            id: record.shippingSpaceId,
          },
        });
      }
    };

    
    const funcGetCountry = () => {
      dropDownData.countryLoading = true;
      getGlobalCountrys()
        .then(({ result }) => {
          dropDownData.country = result;
          dropDownData.countryLoading = false;
        })
        .catch(() => {
          dropDownData.countryLoading = false;
        });
    };

    onMounted(() => {
      handleSearch();
      funcGetCountry();
    });

    return {
      cmCubicToM,
      gToKg,
      getLanguageName,

      transportShippingStatusEnum,
      destinationTypeEnum,
      transportTypeEnum,

      state,
      columns,
      dropDownData,

      handleSelectValueChange,
      handleCountryChange,
      getCityList,

      handlePage,
      handleSearch,
      handleClickMenu,

    };
  },
});
</script>

<style scoped></style>

//仓库信息相关:中文
export default {
    right:{
        /*权限相关 begin*/
        role_name:"角色名称",
        app_id:"所属应用",
        role_type_id:"角色类型",
        is_default:"是否是默认",
        /*权限相关 end*/

        /*权限相关 begin*/
        right_name:"权限名称",
        right_show_name:"权限展示名称",
        right_uni_key:"权限唯一标识",
        right_type:"权限类型",
        parent_right_id:"上级权限",
        menu_url:"权限路由",
        p_order:"排序",
        p_icon:"图标",
        /*权限相关 end*/

        assign_role_permissions: "分配角色权限"
    }
};
  
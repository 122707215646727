<template>
  <a-row :gutter="[8, 8]">
    <a-col style="width: 150px;">
      <a-select
        v-model:value="state.selectState.countryId"
        :loading="state.countriesLoading"
        :placeholder="$t('common.country')"
        show-search
        optionFilterProp="search-key"
        @change="handleChangeCountry"
      >
        <a-select-option
          v-for="(item, index) in state.countriesList"
          :key="index"
          :value="item.id"
          :title="getLanguageName(item.cnName, item.enName)"
          :search-key="item.cnName + item.enName + item.ioS2"
        >{{ getLanguageName(item.cnName, item.enName) }}</a-select-option>
      </a-select>
    </a-col>
    <a-col style="width: 150px;">
      <a-select
        v-model:value="state.selectState.provinceId"
        :loading="state.provincesLoading"
        :placeholder="$t('common.province')"
        show-search
        optionFilterProp="search-key"
        @change="handleChangeProvince"
      >
        <a-select-option
          v-for="(item, index) in state.provincesList"
          :key="index"
          :value="item.id"
          :title="getLanguageName(item.cnName, item.enName) + '(' + item.abbrCode + ')'"
          :search-key="item.cnName + item.enName + item.abbrCode"
        >{{ getLanguageName(item.cnName, item.enName) }}({{ item.abbrCode }})</a-select-option>
      </a-select>
    </a-col>
    <a-col style="width: 150px;">
      <a-select
        v-model:value="state.selectState.cityId"
        :loading="state.citiesLoading"
        :placeholder="$t('common.city')"
        show-search
        optionFilterProp="search-key"
        @change="handleChangeCity"
      >
        <a-select-option
          v-for="(item, index) in state.citiesList"
          :key="index"
          :value="item.id"
          :title="getLanguageName(item.cnName, item.enName)"
          :search-key="item.cnName + item.enName"
        >{{ getLanguageName(item.cnName, item.enName) }}</a-select-option>
      </a-select>
    </a-col>
    <a-col style="width: 150px;" v-if="state.countiesList.length > 0">
      <a-select
        v-model:value="state.selectState.countyId"
        :loading="state.countiesLoading"
        :placeholder="$t('common.county')"
        show-search
        optionFilterProp="search-key"
        @change="handleChangeCounty"
      >
        <a-select-option
          v-for="(item, index) in state.countiesList"
          :key="index"
          :value="item.id"
          :title="getLanguageName(item.cnName, item.enName)"
          :search-key="item.cnName + item.enName"
        >{{ getLanguageName(item.cnName, item.enName) }}</a-select-option>
      </a-select>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, onMounted, reactive, watch } from 'vue';
import { Col, Row, Select } from 'ant-design-vue';
import { useStore } from 'vuex';
import { getName } from "@/utils/general.js";
import { useNewAddrHelper } from '@/utils/addressHelper.js';

export default defineComponent({
  components: {
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  props: {
    addressCache: {
      type: Object,
      default: () => ({}),
    },
    countryId: String,
    provinceId: String,
    cityId: String,
    countyId: String,
  },
  setup (props, { emit }) {
    const { getters } = useStore();
    const addressHelper = useNewAddrHelper(false, props.addressCache);

    const state = reactive({
      selectState: {
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
      },
      countriesLoading: false,
      countriesList: [],
      provincesLoading: false,
      provincesList: [],
      citiesLoading: false,
      citiesList: [],
      countiesLoading: false,
      countiesList: [],
    })
    
    const getLanguageName = (cnName, enName) => {
      return getName({ cnName, enName }, getters.language);
    }

    // 国家
    const getCountriesList = () => {
      state.countriesLoading = true;
      addressHelper.getCountries().then((result) => {
        if (Array.isArray(result)) {
          state.countriesList = result;
        } else {
          state.countriesList =  [];
        }
      }).catch(() => {}).finally(() => {
        state.countriesLoading = false;
      });
    }

    // 省
    const getProvincesList = (countryId) => {
      state.provincesLoading = true;
      addressHelper.getProvinces(countryId).then((result) => {
        if (Array.isArray(result)) {
          state.provincesList =  result;
        } else {
          state.provincesList =  [];
        }
      }).catch(() => {}).finally(() => {
        state.provincesLoading = false;
      });
    }

    // 市
    const getCitiesList = (provinceId) => {
      state.citiesLoading = true;
      addressHelper.getCities(provinceId).then((result) => {
        if (Array.isArray(result)) {
          state.citiesList =  result;
        } else {
          state.citiesList =  [];
        }
      }).catch(() => {}).finally(() => {
        state.citiesLoading = false;
      });
    }

    // 区县
    const getCountiesList = (cityId) => {
      state.countriesLoading = true;
      addressHelper.getCounties(cityId).then((result) => {
        if (Array.isArray(result)) {
          state.countiesList =  result;
        } else {
          state.countiesList =  [];
        }
      }).catch(() => {}).finally(() => {
        state.countriesLoading = false;
      });
    }

    watch(() => [props.countryId, props.provinceId, props.cityId, props.countyId], ([countryId, provinceId, cityId, countyId]) => {
      if (countryId) {
        state.selectState.countryId = countryId;
        getProvincesList(countryId);
      }
      if (provinceId) {
        state.selectState.provinceId = provinceId;
        getCitiesList(provinceId);
      }
      if (cityId) {
        state.selectState.cityId = cityId;
        getCountiesList(cityId);
      }
      if (countyId) {
        state.selectState.countyId = countyId;
      }
    });

    const handleChangeCountry = () => {
      emit('update:countryId', state.selectState.countryId);
      emit('update:provinceId', null);
      emit('update:cityId', null);
      emit('update:countyId', null);

      state.selectState.provinceId = null;
      state.selectState.cityId = null;
      state.selectState.countyId = null;

      state.citiesList = [];
      state.countiesList = [];
    }

    const handleChangeProvince = () => {
      emit('update:provinceId', state.selectState.provinceId);
      emit('update:cityId', null);
      emit('update:countyId', null);

      state.selectState.cityId = null;
      state.selectState.countyId = null;

      state.countiesList = [];
    }

    const handleChangeCity = () => {
      emit('update:cityId', state.selectState.cityId);
      emit('update:countyId', null);
      
      state.selectState.countyId = null;
    }

    const handleChangeCounty = () => {
      emit('update:countyId', state.selectState.countyId);
    }

    onMounted(getCountriesList);

    return {
      state,
      getLanguageName,
      handleChangeCountry,
      handleChangeProvince,
      handleChangeCity,
      handleChangeCounty,
    }
  }
})
</script>

<style scoped>

</style>
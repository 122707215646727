import client from "../../client";

const Api = {
  //国家
  getCountrys: "/api/BasicDataService/Country/GetList", //默认启用的国家
  getCountriesParams:"/api/BasicDataService/Country/GetAllByParams",//通过参数获取国家信息
  getGlobalCountrys: "/api/BasicDataService/Country/GetAllByParams", //全球的国家
  getProvinces: "/api/BasicDataService/Province/GetListByCountryId",
  getCitys: "/api/BasicDataService/City/GetListByProvinceId",
  getCountys: "/api/BasicDataService/County/GetListByCityId",

  //搜索城市
  searchCities: "/api/BasicDataService/City/GetListPaged",

  //币种
  getCurrencys: "/api/BasicDataService/Currency/GetList",

  uploadFile: "/api/BasicDataService/AliyunOSS/UploadToTemp",

  //获得仓库列表
  getWarehouses: "/api/WarehouseService/Warehouse/GetList",

  //获得仓库分页列表
  getWarehouseListPaged: "/api/WarehouseService/Warehouse/GetListPaged",

  //获得下拉框仓库列表
  getOptionWarehouses: "/api/WarehouseService/Warehouse/GetSelectOptionList",

  //获得商户信息
  getShopInfo: "/api/ProductService/Shop/GetSelectOptionList",

  // 产品统计-入库
  getInWarehouseTotalPlanProduct: "/api/WarehouseService/WarehouseTaskMonitoring/GetInWarehouseTotalPlanProduct",
  // 产品统计-出库
  getOutWarehouseTotalPlanProduct: "/api/WarehouseService/WarehouseTaskMonitoring/GetOutWarehouseTotalPlanProduct",
  // 产品统计-运输计划
  getSummary: "/api/WarehouseService/TransportPlanConsole/GetSummary",
  
  // 物流方案-卡车-报价类型
  getReceivingChannelsList: "/api/SupplierService/Supplier/GetReceivingChannelsList",
  // 物流方案-卡车-服务等级
  getServiceLevel: "/api/SupplierService/Supplier/GetServiceLevel",
  // 物流方案-卡车-附加选项
  getAdditionalOptions: "/api/SupplierService/Supplier/GetAdditionalOptions",

  //auto产品搜索(下拉框)
  getAutoProductList: "/api/ProductService/Product/GetAutoListPaged",

   //获得箱内产品
   getBoxOfProductsByWhboxId:"/api/WarehouseService/Box/GetBoxProductListByWhBoxId"

};

//查项目启用的国家
export const getCountrys = () => {
  return client.request({
    url: Api.getCountrys,
    method: "get",
  });
};

//通过参数查询国家信息
export const getCountriesParams = (isActive = null) => {
  return client.request({
    url: Api.getCountriesParams,
    data:{isActive:isActive},
    method: "post",
  });
};

//查全球所有国家
export const getGlobalCountrys = () => {
  return client.request({
    url: Api.getGlobalCountrys,
    data: {},
    method: "post",
  });
};
export const getProvinces = ({ countryId }) => {
  return client.request({
    url: Api.getProvinces,
    params: { countryId },
    method: "get",
  });
};

export const getCitys = ({ provinceId }) => {
  return client.request({
    url: Api.getCitys,
    params: { provinceId },
    method: "get",
  });
};

export const getCountys = ({ cityId }) => {
  return client.request({
    url: Api.getCountys,
    params: { cityId },
    method: "get",
  });
};

export const searchCities = (data) => {
  return client.request({
    url: Api.searchCities,
    data,
    method: "post",
  });
};

//币种
export const getCurrencys = (data) => {
  return client.request({
    url: Api.getCurrencys,
    params: data,
    method: "get",
  });
};

export const uploadFile = (data) => {
  return client.request({
    url: Api.uploadFile,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getWarehouses = ({
  countryId,
  provinceId,
  warehouseType,
  isActive,
  ids,
  searchKey,
  warehouseNo,
  currencyId,
}) => {
  return client.request({
    url: Api.getWarehouses,
    data: {
      countryId,
      provinceId,
      warehouseType,
      isActive,
      ids,
      searchKey,
      warehouseNo,
      currencyId,
    },
    method: "post",
  });
};

export const getWarehouseListPaged = ({
  countryId,
  provinceId,
  warehouseType,
  isActive,
  ids,
  searchKey,
  warehouseNo,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getWarehouseListPaged,
    data: {
      countryId,
      provinceId,
      warehouseType,
      isActive,
      ids,
      searchKey,
      warehouseNo,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};


//warehouseType 1 : 存储仓, 2 : 配送仓, 4 : 物流中心
export const getOptionWarehouses = ({
  countryId,
  provinceId,
  warehouseType,
  searchKey,
  warehouseNo,
  isActive = true,
}) => {
  return client.request({
    url: Api.getOptionWarehouses,
    data: {
      countryId,
      provinceId,
      warehouseType,
      searchKey,
      warehouseNo,
      isActive,
    },
    method: "post",
  });
};


export const getShopInfo = ({
  searchKey,
  shopAccountStatus,
  auditStatus,
  shopNo
}) => {
  return client.request({
    url: Api.getShopInfo,
    data: {
      searchKey,
      shopAccountStatus,
      auditStatus,
      shopNo
    },
    method: "post",
  });
};

export const getInWarehouseTotalPlanProduct = (data) => {
  return client.request({
    url: Api.getInWarehouseTotalPlanProduct,
    params: data,
    method: "GET",
  });
};

export const getOutWarehouseTotalPlanProduct = (data) => {
  return client.request({
    url: Api.getOutWarehouseTotalPlanProduct,
    params: data,
    method: "GET",
  });
};

export const getSummary = (data) => {
  return client.request({
    url: Api.getSummary,
    data,
    method: "POST",
  });
};

export const getReceivingChannelsList = () => {
  return client.request({
    url: Api.getReceivingChannelsList,
    method: "GET",
  });
};

export const getServiceLevel = () => {
  return client.request({
    url: Api.getServiceLevel,
    method: "GET",
  });
};

export const getAdditionalOptions = () => {
  return client.request({
    url: Api.getAdditionalOptions,
    method: "GET",
  });
};

export const getAutoProductList = ({
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getAutoProductList,
    data: {
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const getBoxOfProductsByWhboxId = (whboxId) => {
  return client.request({
    url: Api.getBoxOfProductsByWhboxId,
    params: {whboxId},
    method: "get",
  });
};
<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.finance_recharge') }}
    </template>
    <template v-slot:contentBody='wrap'>
      <a-form layout="inline" class="mb-3">
        <a-row :gutter="[0,8]">
          <a-col>
            <a-form-item :label="$t('common.serial_number')">
              <a-input-group compact>
                <a-select v-model:value="searchData.serialNumberType"
                          defaultActiveFirstOption
                          >
                  <a-select-option v-for="(item,index) in serialNumberTypeEnum "
                                   :key="index"
                                   :title="$t($enumLangkey('serialNumberType', item))"
                                   :value="item">
                    {{ $t($enumLangkey('serialNumberType', item)) }}
                  </a-select-option>
                </a-select>
                <a-input v-model:value="searchData.serialNo"
                         allowClear
                         style="width:200px"
                         :placeholder="$t('common.serial_number')"></a-input>
              </a-input-group>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :label="$t('warehouse.customer')">
               <CSearchShop v-model:shopId="searchData.shopId"></CSearchShop>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :label="$t('finance.status')">
              <a-select v-model:value="searchData.status"
                        defaultActiveFirstOption
                        allowClear
                        @change="handleChangeFilter"
                        :placeholder="$t('finance.unlimited')"
                        style="width: 250px">
                <a-select-option v-for="(item,index) in topUpStatusEnum "
                                 :key="index"
                                 :title="$t($enumLangkey('topUpStatus', item))"
                                 :value="item">
                  {{ $t($enumLangkey('topUpStatus', item)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :label="$t('finance.payment_method')">
              <a-select v-model:value="searchData.rechargeType"
                        defaultActiveFirstOption
                        allowClear
                        @change="handleChangeFilter"
                        :placeholder="$t('finance.unlimited')"
                        style="width: 250px">
                <a-select-option v-for="(item,index) in rechargeTypeEnum "
                                 :key="index"
                                 :title="$t($enumLangkey('rechargeType', item))"
                                 :value="item">
                  {{ $t($enumLangkey('rechargeType', item)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :label="$t('finance.top_up_currency')">
              <a-input-group compact>
                <a-select allowClear
                          show-search
                          style="min-width:100px"
                          optionFilterProp="search-key"
                          v-model:value="searchData.currencyId"
                          @change="handleChangeFilter"
                          :placeholder="$t('finance.unlimited')">
                  <a-select-option :value="item.id"
                                   v-for="item in data.currencys"
                                   :key="item.id"
                                   :title="`${getLanguageName(item)}(${item.symbol})`"
                                   :search-key="item.code + item.symbol+item.cnName+item.enName">
                    {{getLanguageName(item)}}({{item.symbol}})
                  </a-select-option>
                </a-select>
                <a-input v-model:value="searchData.rechargeLow"
                         allowClear
                         style="width: 120px; text-align: center"
                         :placeholder="$t('finance.minimum')" />
                <a-input style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
                         placeholder="~"
                         disabled />
                <a-input v-model:value="searchData.rechargeTop"
                         allowClear
                         style="width: 120px; text-align: center; border-left: 0"
                         :placeholder="$t('finance.highest')" />
              </a-input-group>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :label="$t('finance.currency_of_payment')">
              <a-input-group compact>
                <a-select allowClear
                          show-search
                          style="min-width:100px"
                          optionFilterProp="search-key"
                          v-model:value="searchData.payCurrencyId"
                          @change="handleChangeFilter"
                          :placeholder="$t('finance.unlimited')">
                  <a-select-option :value="item.id"
                                   v-for="item in data.currencys"
                                   :key="item.id"
                                   :title="`${getLanguageName(item)}(${item.symbol})`"
                                   :search-key="item.code + item.symbol+item.cnName+item.enName">
                    {{getLanguageName(item)}}({{item.symbol}})
                  </a-select-option>
                </a-select>
                <a-input v-model:value="searchData.payLow"
                         allowClear
                         style="width: 120px; text-align: center"
                         :placeholder="$t('finance.minimum')" />
                <a-input style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
                         placeholder="~"
                         disabled />
                <a-input v-model:value="searchData.payTop"
                         allowClear
                         style="width: 120px; text-align: center; border-left: 0"
                         :placeholder="$t('finance.highest')" />
              </a-input-group>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item>
              <a-space>
                <a-button @click="handleChangeFilter" :loading="data.loading" type="primary">{{$t('common.query')}}</a-button>
                <a-button type="ghost" @click="handleShowPinModal">{{ $t('common.export') }}</a-button>
                <a-button type="ghost" @click="rechargeModalState.visible = true">{{ $t('finance.manual_recharge') }}</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <div>
        <a-table :columns="columns"
                 :data-source="data.list"
                 :scroll="{ x: 1600, y:wrap.contentHeight - 150}"
                 :pagination="false"
                 :rowKey="(record, index) => {return index;}"
                 size="small"
                 :loading="data.loading">
          <template #shopNameAndShopId="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
             <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
          <template #creationTime="{record}">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
          </template>
          <template #symbolAndRechargeAmount="{record}">
            {{record.currencyCode}} {{record.symbol}}{{$filters.amountToFixed2(record.rechargeAmount)}}
          </template>
          <template #paySymbolAndPayAmount="{record}">
            {{record.payCurrencyCode}} {{record.paySymbol}}{{$filters.amountToFixed2(record.payAmount)}}
          </template>
          <template #rechargeType="{ record }">
            {{ $t($enumLangkey('rechargeType', record.rechargeType)) }}
          </template>
          <template #payeeAccountInfo="{record}">
            {{record.payeeAccountInfo?.bank}}
            <div>{{record.payeeAccountInfo?.accountNo}}</div>
          </template>
          <template #payNoAndPayeeNo="{record}">
            <div>{{$t('finance.payment_serial_number')}}: {{record.payNo}}</div>
            <div>{{$t('finance.collection_serial_number')}}: {{record.payeeNo}}</div>
          </template>
          <template #status="{ record }">
            <span v-if="record.status==topUpStatusEnum.toBeConfirmed">
              <a-tag color="#faad14">{{ $t($enumLangkey('topUpStatus', record.status)) }}</a-tag>
            </span>
            <span v-else-if="record.status==topUpStatusEnum.succeed"
                  class="text-success">
              {{ $t($enumLangkey('topUpStatus', record.status)) }}
            </span>
            <span v-else-if="record.status==topUpStatusEnum.fail"
                  class="text-error">
              {{ $t($enumLangkey('topUpStatus', record.status)) }}
            </span>
            <span v-else-if="record.status==topUpStatusEnum.waitSubmit" class="text-primary">
              {{ $t($enumLangkey('topUpStatus', record.status)) }}
            </span>
            <span v-else-if="record.status==topUpStatusEnum.canceled" class="color-canceled">
              {{ $t($enumLangkey('topUpStatus', record.status)) }}
            </span>
            <span v-else>
              {{ $t($enumLangkey('topUpStatus', record.status)) }}
            </span>
          </template>
          <template #remark="{ record }">
            <span v-if="record.recordedFailureRemark">
              {{ $t($enumLangkey('rejectInAccount', record.recordedFailureRemark)) }}
            </span>
            <template v-else>
              <span v-if="record.remark">{{ record.remark }}</span>
              <span v-else>-</span>
            </template>
          </template>

          <template #internalRemark="{ record }">
            <div>
              <small v-if="record.internalRemark" style="white-space: pre-line;">{{ record.internalRemark }}</small>
              <span v-else>-</span>
            </div>
            <small class="text-primary" style="cursor: pointer;" @click="handleOpenAppendModal(record)"><PlusCircleOutlined /> {{ $t('finance.append') }}</small>
          </template>

          <template #operate="{ record }">
            <a-dropdown v-if="record.status == topUpStatusEnum.toBeConfirmed">
              <a-button>
                {{ $t('common.operate') }}
                <DownOutlined />
              </a-button>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1" :record="record">{{ $t('finance.confirm_the_inventory') }}</a-menu-item>
                  <a-menu-item key="2" :record="record">{{ $t('finance.in_account_of_failure') }}</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="modal.visible"
               :title="()=>
                 modal.type==modalTypeEnum.confirm
                 ?
                 $t('finance.confirm_the_inventory')
                 :
                $t('finance.in_account_of_failure')"
               :confirmLoading="modal.loading"
               :okText="()=>
                 modal.type==modalTypeEnum.confirm
                 ?
                 $t('finance.confirm_the_inventory')
                 :
                $t('finance.in_account_of_failure')"
               @ok="handleOk">
        <a-row :gutter=[32,8]>
          <a-col :span="6"
                 style="text-align:right">{{$t('finance.top_up_currency')}}</a-col>
          <a-col :span="18">{{modal.currencyCode}}</a-col>
          <a-col :span="6"
                 style="text-align:right">{{$t('account.amount')}}</a-col>
          <a-col :span="18">{{modal.currencyCode}} {{modal.symbol}}{{$filters.amountToFixed2(modal.rechargeAmount)}} </a-col>
          <a-col :span="6"
                 style="text-align:right">{{$t('finance.currency_of_payment')}}</a-col>
          <a-col :span="18">{{modal.payCurrencyCode}} </a-col>
          <a-col :span="6"
                 style="text-align:right">{{$t('finance.exchange_rate')}}</a-col>
          <a-col :span="18">{{modal.currentExchangeRate}} </a-col>
          <a-col :span="6"
                 style="text-align:right">{{$t('finance.payment_money')}}</a-col>
          <a-col :span="18">{{modal.payCurrencyCode}} {{modal.paySymbol}}{{modal.payAmount}} </a-col>

          <!-- 确认入账-->
          <a-col v-if="modal.type==modalTypeEnum.confirm"
                 :span="6"
                 style="text-align:right">{{$t('finance.collection_serial_number')}}</a-col>
          <a-col v-if="modal.type==modalTypeEnum.confirm"
                 :span="18">
            <a-input v-model:value="modal.payeeNo"></a-input>
            <span class="text-warning">{{$t('finance.please_check_the_payment_serial_number_and_amount_carefully_and_cannot_be_modified_after_submission')}}</span>
          </a-col>
          <a-col v-if="modal.type==modalTypeEnum.confirm"
                 :span="6"
                 style="text-align:right">{{$t('finance.customer_remarks')}}</a-col>
          <a-col v-if="modal.type==modalTypeEnum.confirm"
                 :span="18">
            <a-textarea v-model:value="modal.remark"
                        :placeholder="$t('finance.display_on_client')"
                        :rows="3" />
          </a-col>
          <a-col v-if="modal.type==modalTypeEnum.confirm"
                 :span="6"
                 style="text-align:right">{{$t('finance.internal_remarks')}}</a-col>
          <a-col v-if="modal.type==modalTypeEnum.confirm"
                 :span="18">
            <a-textarea v-model:value="modal.internalRemark"
                        :placeholder="$t('finance.internal_view_only')"
                        :rows="3" />
          </a-col>

          <!-- 入账失败 -->
          <a-col v-if="modal.type!=modalTypeEnum.confirm"
                 :span="6"
                 style="text-align:right">{{$t('finance.remark_info')}}</a-col>
          <a-col v-if="modal.type!=modalTypeEnum.confirm"
                 :span="18">
            <a-select v-model:value="modal.recordedFailureRemark"
                      defaultActiveFirstOption
                      style="min-width:200px"
                      :placeholder="$t('finance.select_a_failure_cause')">
              <a-select-option v-for="(item,index) in rejectInAccountEnum "
                               :key="index"
                               :value="item">
                {{ $t($enumLangkey('rejectInAccount', item)) }}
              </a-select-option>
            </a-select>

          </a-col>

        </a-row>

      </a-modal>
      <CPinTipsModal v-model:visible="data.pinModalState.visible" v-model:loading="data.pinModalState.loading" @confirm="handleDownloadExcel" />
      <RechargeModal v-model:visible="rechargeModalState.visible" :currency-list="data.currencys" @confirm="handleChangeFilter" />
      <RechargeRemarksAppendModal
        v-model:visible="rechargeRemarksAppendModalState.visible"
        :id="rechargeRemarksAppendModalState.id"
        :remark="rechargeRemarksAppendModalState.remark"
        @confirm="handleSearchList"
      />
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <a-row type="flex"
             justify="center"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="data.pageData">
          </CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue';
import {
  Table, Button, Input, Select, Row, Col, Form, Modal, Space, Tag, Dropdown, Menu, message
} from 'ant-design-vue';
import CPager from '../components/CPager.vue';
import Content from '../components/Content.vue';
import CSearchShop from "../components/CSearchShop.vue"
import CPinTipsModal from "../components/CPinTipsModal.vue"
import RechargeModal from "./components/RechargeModal.vue";
import RechargeRemarksAppendModal from "./components/RechargeRemarksAppendModal.vue";
import {
  getList, successfulInAccount, notInAccount,
} from '../../api/modules/finance/recharge';
import { exportListManagePaged } from '@/api/modules/finance/index';
import {
  getCurrencys
} from '../../api/modules/common/index';
import {
  serialNumberType as serialNumberTypeEnum,
  topUpStatus as topUpStatusEnum,
  rechargeType as rechargeTypeEnum,
  rejectInAccount as rejectInAccountEnum
} from '../../enum/enum.json';
import { useI18n } from 'vue-i18n/index';
import { useStore } from "vuex";
import { getName, dateString } from "../../utils/general";
import { downloadFile } from "@/utils/downloader";

export default defineComponent({
  name: "finance_recharge",
  components: {
    ATable: Table,
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    AModal: Modal,
    ATextarea: Input.TextArea,
    ASpace: Space,
    ATag: Tag,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CSearchShop,
    CPinTipsModal,
    RechargeModal,
    RechargeRemarksAppendModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: 'global' });
    const { getters } = useStore();

    const data = reactive({
      list: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      currencys: [],
      pinModalState: {
        visible: false,
        loading: false,
      }
    });

    const searchData = reactive({
      serialNumberType: 1,
      serialNo: null,

      shopId:null,
      status: null,
      rechargeType: null,

      currencyId: null,
      rechargeLow: null,
      rechargeTop: null,

      payCurrencyId: null,
      payLow: null,
      payTop: null,
    });

    const modalTypeEnum = {
      confirm: 1,
      fail: 2,
    }

    const modal = reactive({
      type: null,
      visible: false,
      loading: false,
      remark: null,
      internalRemark: null,
      recordedFailureRemark: null,
      id: null,//充值记录Id
    });

    const rechargeModalState = reactive({
      visible: false,
    })

    const rechargeRemarksAppendModalState = reactive({
      visible: false,
      id: null,
      remark: null,
    })

    const columns = [
      {
        title: () => vueI18n.t('warehouse.customer'),
        dataIndex: 'shopNameAndShopId',
        slots: {
          customRender: "shopNameAndShopId"
        },
        fixed: 'left',
        width: 200,
      },
      {
        title: () => vueI18n.t('account.time'),
        dataIndex: 'creationTime',
        slots: {
          customRender: "creationTime"
        },
        width: 130,
      },
      {
        title: () => vueI18n.t('finance.recharge_serial_number'),
        dataIndex: 'reChargeNo',
        width: 160,
      },
      {
        title: () =>
          vueI18n.t('finance.top_up_currency')
          + '/' +
          vueI18n.t('account.amount'),
        dataIndex: 'symbolAndRechargeAmount',
        slots: {
          customRender: "symbolAndRechargeAmount"
        },
        width: 120,
      },
      {
        title: () =>
          vueI18n.t('finance.currency_of_payment')
          + '/' +
          vueI18n.t('account.amount'),
        dataIndex: 'paySymbolAndPayAmount',
        slots: {
          customRender: "paySymbolAndPayAmount"
        },
        width: 120,
      },
      {
        title: () => vueI18n.t('finance.exchange_rate'),
        dataIndex: 'currentExchangeRate',
        width: 80,
      },
      {
        title: () => vueI18n.t('finance.payment_method'),
        dataIndex: 'rechargeType',
        slots: {
          customRender: "rechargeType"
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('finance.account_to_be_credited'),
        dataIndex: 'payeeAccountInfo',
        slots: {
          customRender: "payeeAccountInfo"
        },
        width: 200,
      },
      {
        title: () => vueI18n.t('common.serial_number'),
        dataIndex: 'payNoAndPayeeNo',
        slots: {
          customRender: "payNoAndPayeeNo"
        },
        width: 280,
      },
      {
        title: () => vueI18n.t('finance.top_up_status'),
        dataIndex: 'status',
        slots: {
          customRender: "status"
        },
        width: 80,
      },
      {
        title: () => vueI18n.t('finance.customer_remarks'),
        dataIndex: 'remark',
        slots: {
          customRender: "remark"
        },
        width: 150,
      },
      {
        title: () => vueI18n.t('finance.internal_remarks'),
        slots: {
          customRender: "internalRemark"
        },
        width: 150,
      },
      {
        title: '',
        dataIndex: 'operate',
        fixed: 'right',
        width: 120,
        slots: {
          customRender: "operate"
        }
      },

    ];

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const handlePage = (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearchList();
    };

    const handleChangeFilter = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleSearchList();
    }

    const funcGetcurrencys = () => {
      getCurrencys().then((res) => {
        if (res.result) {
          data.currencys = res.result;
        }
      })
    }

    const handleSearchList = () => {
      data.loading = true;
      getList({ ...data.pageData, ...searchData }).then((res) => {
        let { items, totalCount } = res.result;
        if (totalCount > 0) {
          data.list = items;
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.pageData.totalCount = 0;
          data.list = [];
        }
        data.loading = false;
      }).catch((error) => {
        data.loading = false;
      })
    }

    const handleShowModla = (type, record) => {
      modal.type = type;
      modal.visible = true;
      Object.assign(modal, record);
      modal.payeeNo = null;
      modal.remark = null;
      modal.internalRemark = null;
      modal.recordedFailureRemark = null;
    }

    const handleOk = () => {
      if (modal.type == modalTypeEnum.confirm) {
        funcComfirm();
      } else {
        funcFail();
      }
    }

    const funcComfirm = () => {
      if (!modal.payeeNo || modal.payeeNo.length == 0) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.collection_serial_number")]))
        return false
      }

      modal.loading = true;
      successfulInAccount(modal).then((res) => {
        modal.visible = false;
        modal.loading = false;
        handleSearchList();
      }).catch(() => {
        modal.loading = false;
      })
    }

    const funcFail = () => {
      if (!modal.recordedFailureRemark) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.remark_info")]))
        return false
      }
      modal.loading = true;
      notInAccount(modal).then((res) => {
        modal.visible = false;
        modal.loading = false;
        handleSearchList();
        modal.loading = false;
      }).catch(() => {
        modal.loading = false;
      })
    }

    const handleShowPinModal = () => {
      data.pinModalState.visible = true
    }

    const handleDownloadExcel = (pinCode) => {
      data.pinModalState.loading = true
      let url = exportListManagePaged();
      const postData = Object.assign({}, searchData, { pinCode })
      downloadFile(url, `recharge_${dateString()}.csv`, "POST", postData).then(()=>{
        data.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        data.pinModalState.loading = false
      })
    }

    const handleOpenAppendModal = (record) => {
      rechargeRemarksAppendModalState.visible = true;
      rechargeRemarksAppendModalState.id = record.id;
      rechargeRemarksAppendModalState.remark = record.internalRemark;
    }

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          handleShowModla(modalTypeEnum.confirm, record)
          break;
        case '2':
          handleShowModla(modalTypeEnum.fail, record)
          break;
        default:
          break;
      }
    }

    onMounted(() => {
      funcGetcurrencys();
      handleSearchList();
    });

    return {
      serialNumberTypeEnum,
      topUpStatusEnum,
      rechargeTypeEnum,
      modalTypeEnum,
      rejectInAccountEnum,

      data,
      searchData,

      rechargeModalState,
      rechargeRemarksAppendModalState,
      columns,
      modal,
      handlePage,
      handleChangeFilter,
      handleSearchList,
      getLanguageName,
      handleShowModla,
      handleOk,
      handleShowPinModal,
      handleDownloadExcel,
      handleOpenAppendModal,
      handleMenuClick,
    };
  },
});
</script>
<style lang="less" scoped>
.color-canceled {
  color: #BBBBBB;
}
</style>

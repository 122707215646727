<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.warehouse_monitor_storage_warehousing_task_detail") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <a-row :gutter="[0, 8]">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('warehouse.inbound_plan_number') }}: {{ planNo || '-' }}
                <template v-if="hasException">
                  <a-tag class="ml-1" v-if="planStatus === inWarehouseStatusEnum.complete || planStatus === inWarehouseStatusEnum.cancel">{{ $t('common.exception') }}</a-tag>
                  <a-tag class="ml-1" color="error" v-else>{{ $t('common.exception') }}</a-tag>
                </template>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('warehouse.inbound_plan_name') }}: {{ planName }}
              </a-col>
              <a-col :span="24" v-if="relation">
                {{ $t('warehouse.source_related_doc_no') }}: <CPlanNumberItem :no="relation"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" v-else-if="toRelationPlanNo">
                {{ $t('warehouse.target_related_doc_no') }}: <CPlanNumberItem :no="toRelationPlanNo"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" v-else>
                {{ $t('warehouse.relation_no') }}: -
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.creation_time") }}: {{ $filters.utcToCurrentTime(creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.last_modification_time") }}: {{ $filters.utcToCurrentTime(lastModificationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('warehouse.logistics_way') }}:
                <span v-if="selectedLogisticsWay">{{ $t($enumLangkey('logisticsWay', selectedLogisticsWay)) }}</span>
                <span v-else>-</span>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('logistics.delivery_way') }}:
                <span v-if="selectedDeliveryWay">{{ $t($enumLangkey('deliveryWay', selectedDeliveryWay)) }}</span>
                <span v-else>-</span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]" style="height: 100%;" type="flex" align="middle">
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t('warehouse.plan_status') }}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <span v-if="planStatus">
                    <a-typography-text type="secondary" v-if="planStatus === inWarehouseStatusEnum.cancel">{{ $t($enumLangkey('inWarehouseStatus', planStatus)) }}</a-typography-text>
                    <a-typography-text type="success" v-else>{{ $t($enumLangkey('inWarehouseStatus', planStatus)) }}</a-typography-text>
                  </span>
                  <span v-else>-</span>
                </div>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t('warehouse.inbound_type') }}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <a-typography-text type="success" v-if="inWarehouseType">{{ $t($enumLangkey('inWarehouseType', inWarehouseType)) }}</a-typography-text>
                  <span v-else>-</span>
                </div>
              </a-col>
              <a-col :span="24">
                <div style="text-align: center;">
                  <a-button type="primary" @click="handleTrackShowModal" ghost>{{ $t('warehouse.tracking_shipment') }}</a-button>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t('warehouse.customer') }}:
            <span v-if="shopName">{{ shopName || '-' }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{$t('common.shop_mark')}}:
            <span v-if="shopMark">{{ shopMark || '-' }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            NO:
            <span v-if="shopNo">{{ shopNo || '-' }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <label>{{ $t('logistics.originate') }}: </label>
            <span v-if="originate">{{ originate }}</span>
            <span v-else>-</span>
            <span v-if="originateType">({{ $t($enumLangkey('warehouseType', originateType)) }})</span>
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            <label>{{ $t('warehouse.send_goods_address') }}:</label>
            {{ getAddress(originateAddress) }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <label>{{ $t('warehouse.inbound_target_warehouse') }}: </label>
            <span v-if="inTargetWarehouse">{{ inTargetWarehouse }}</span>
            <span v-else>-</span>
            <span v-if="inTargetWarehouseType">({{ $t($enumLangkey('warehouseType', inTargetWarehouseType)) }})</span>
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            <label>{{ $t('warehouse.warehouse_address') }}: </label>
            {{ getAddress(warehouseAddress) }}
            <span class="ml-5" v-if="warehouseAddress">{{ $t('logistics.contacts') }}: {{getLinkman(warehouseAddress)}}</span>
          </a-col>
        </a-row>
        <a-table
          class="mt-2"
          style="overflow-x: auto;"
          :columns="columns"
          :data-source="planDetails"
          size="small"
          :expandIconAsCell="false"
          :expandIconColumnIndex="2"
          :pagination="{
            defaultPageSize: defaultPageSize,
            hideOnSinglePage: true,
            current: currentPageIndex,
            position: 'both',
          }"
          :row-key="(record) => record.detailId"
          @change='(page) => { currentPageIndex = page.current; }'
        >
          <template #productNameAndSeSku>
            <span>
              {{ $t("warehouse.product_name") }}/{{ $t("warehouse.product_no") }}
            </span>
          </template>
          <template #boxNoCustom="{ record }">
            <div>
              {{ record.box.boxNo || '-' }}
              <CTagMixedOrSingle :productKindCount="record.box.container.productKindCount * 1"></CTagMixedOrSingle>
            </div>
            <div>{{ record.box.selfBoxNo || '-' }}</div>
          </template>
          <template #expandIcon="{ expanded, record, onExpand }">
            <CHaveProductInformation :disabled="!record.box.container?.hasRelationProduct" :open="expanded" @click="e => onExpand(record, e)" />
          </template>
          <template #encasementSize="{ record }">
            <span v-if="record.box.container">{{ record.box.container.containerLength }}x{{ record.box.container.containerWidth }}x{{ record.box.container.containerHeight }}cm</span>
            <span v-else>-</span>
          </template>
          <template #encasementWeight="{ record }">
            <span v-if="record.box.container">{{ gToKg(record.box.container.containerWeight) }}kg</span>
            <span v-else>-</span>
          </template>
          <template #encasementCount="{ record }">
            {{ record.box.container ? record.box.container.containerCount : '-' }} Unit
          </template>
          <template #inWarehouseStatus="{ record }">
            {{ $t($enumLangkey('inWarehouseStatus', record.inWarehouseStatus)) }}
          </template>
          <template #checkSize="{ record }">
            <span v-if="record.box.check">
              {{ record.box.check?.checkContainerLength || '-' }}x{{ record.box.check?.checkContainerWidth || '-' }}x{{ record.box.check?.checkContainerHeight || '-' }}cm
            </span>
            <span v-else>-</span>
          </template>
          <template #checkWeight="{ record }">
            <span v-if="record.box.check">{{ gToKg(record.box.check?.checkContainerWeight) }}kg</span>
            <span v-else>-</span>
          </template>
          <template #checkImgUrls="{ record }">
            <a-popover v-if="record.box.check?.checkImgUrls && record.box.check.checkImgUrls.length > 0" :getPopupContainer="trigger => trigger.parentNode">
              <template #content>
                <a-space>
                  <div v-for="(imgItem, index) in record.box.check.checkImgUrls"
                        class="table-list-img-common"
                        :key="index">
                    <c-image :src="imgItem" />
                  </div>
                </a-space>
              </template>
              <span class="text-primary" style="font-size:20px">
                <FileImageOutlined />
              </span>
            </a-popover>
            <span v-else>-</span>
          </template>
          <template #errorTypeCustom="{ record }">
            <div class="text-error" v-if="record.box.exception.currentErrorType">
                <a-row type="flex"  align="middle">
                  <a-col>
                    {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.currentErrorType)) }}
                  </a-col>
                </a-row>
            </div>
            <span class="text-error" v-else-if="record.box.exception.boxErrorType">
                {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.boxErrorType)) }} 
            </span>
            <span v-else>-</span>
          </template>
          <template #operateCustom="{ record }">
            <div v-if="record.box.exception.boxErrorType">
                <!-- 1 : 接收超时(漏发 和请求调查)-->
                <div v-if="record.box.exception.boxErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout">
                  {{ $t($enumLangkey('productErrorDisposeType', record.box.exception.timeOut.inWarehouseErrorCustomerType)) }}
                </div>
            </div>
            <span v-else>-</span>
          </template>

          <template #operateCustomer="{ record }">
            <a-button
              v-if="record.inWarehouseStatus === inWarehouseStatusEnum.waitStorage"
              type="ghost"
              @click="handleShowPinModal(record)"
            >{{ $t('common.cancel') }}</a-button>
          </template>

          <!-- <template #title>
            <a-row justify="end">
              <a-col>
                <a-button type="primary" ghost @click="handleShowProductStatisticsModal">{{ $t('common.product_statistics') }}</a-button>
              </a-col>
            </a-row>
          </template> -->

          <template #footer>
             <a-row  type="flex" justify="space-between" v-if="total">
                <a-col :span="8">{{ $t('common.total') }}: {{ total.plan?.totalContainerBoxCount }}{{ $t('warehouse.box') }}</a-col>
                <a-col :span="8">
                  <a-row :gutter="[16,0]">
                    <a-col>{{ total.plan ? cmCubicToM(total.plan?.totalVolume) : '-' }} m<sup>3</sup></a-col>
                    <a-col>{{ total.plan ? gToKg(total?.plan?.totalWeight) : '-' }} kg</a-col>
                    <a-col>{{ total.plan ? total.plan?.totalWarehouseCount : '-' }} Unit</a-col>
                  </a-row>
                </a-col>
                <a-col :span="8">
                   <a-row :gutter="[16,0]">
                   <a-col v-if="total?.check?.totalVolume > 0">{{cmCubicToM(total.check.totalVolume)}} m<sup>3</sup></a-col>
                   <a-col v-if="total?.check?.totalWeight > 0">{{ gToKg(total.check.totalWeight) }} kg</a-col>
                   <a-col v-if="total?.check?.totalWarehouseCount > 0">{{total.check.totalWarehouseCount}} Unit</a-col>
                  </a-row>
                </a-col>
             </a-row> 
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.box.items"
              :pagination="false"
              :row-key="(record, index) => index"
              size="small"
            >
              <template #productInfo="{ record }">
                <a-row :gutter="8" type="flex" align="middle">
                  <a-col>
                    <div class="table-list-img-common">
                      <c-image
                        :src="record.productImgUrl"
                        :thumbWidth="600"
                        :thumbHeight="600"
                      />
                    </div>
                  </a-col>
                  <a-col flex="1">
                    <div>{{ record.productName }}</div>
                    <div>{{ record.productNo }}</div>
                  </a-col>
                </a-row>
              </template>
              <template #containerCount="{ record }">
                {{ record.containerCount }} Unit
              </template>
            </a-table>
          </template>
        </a-table>
        <div class="footer-button mt-3">
          <CFee :fees="fee?.fees" :totalFee="fee?.totalFee"></CFee>
        
          <a-row type="flex"
                  justify="end" class="mt-3">
                  <a-col>
            <div style="width: 400px">
              <a-row type="flex" justify="center" :gutter="[24, 12]">
            <a-col >
              <a-button type="primary"
                        @click="handleBack"
                        ghost>{{ $t('common.backtrack') }}</a-button>
            </a-col>
              </a-row>
            </div>
          </a-col>
          </a-row>
        </div>
        <Trace ref="refTrace" />
      </a-spin>
      <CProductStatisticsModal ref="productStatisticsModalRef" />
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleCancelBox"
      />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import {
  Row, Col, Tag, Typography, Upload, Tooltip,
  Table, Descriptions, Input, Image,
  Select, Button, Form, Modal, Spin, Space, Popover, message
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import Paging from "@/views/components/Pagination.vue";
import CImage from "@/views/components/CImage.vue";
import Trace from "./components/TraceProductNo.vue";
import CProductStatisticsModal from '@/views/components/CProductStatisticsModal.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import CTagMixedOrSingle from '@/views/components/CTagMixedOrSingle.vue';
import CPinTipsModal from '@/views/components/CPinTipsModal.vue';
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CFee from "@/views/components/CFee.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import {
  inWarehouseStatus as inWarehouseStatusEnum,
  warehouseType as warehouseTypeEnum,
  productStatisticsType as productStatisticsTypeEnum,
  inWarehouseOperationErrorType as inWarehouseOperationErrorTypeEnum,
} from "@/enum/enum.json";
import { getAddressByLanguageV2, gToKg, cmCubicToM, getLinkman } from "@/utils/general"
import { useTab } from "@/hooks/tabs/index";
import { inPlaneBoxCancel } from "@/api/modules/warehouseMonitor/index";
import { getInWarehouse } from "@/api/modules/warehouseMonitor/storage";

export default ({
  name: "warehouse_monitor_storage_warehousing_task_detail",
  components: {
    ATooltip: Tooltip,
    AUpload: Upload,
    AImage: Image,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    ATextarea: Input.TextArea,
    ATag: Tag,
    ATypographyText: Typography.Text,
    AInputGroup: Input.Group,
    AInputSearch: Input.Search,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpin: Spin,
    ASpace: Space,
    APopover: Popover,
    Content,
    Trace,
    Paging,
    CImage,
    CProductStatisticsModal,
    CHaveProductInformation,
    CTagMixedOrSingle,
    CPinTipsModal,
    CPlanNumberItem,
    CFee,
  },
  setup () {
    const i18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();

    const refTrace = ref();
    const refPage = ref();
    const productStatisticsModalRef = ref();

    const columns = [
      {
        title: () => i18n.t('common.serial_number'),
        fixed: "left",
        width: 50,
        customRender: ({ record }) => {
          return record.serialNumber;
        },
      },
      {
        width: 150,
        fixed: "left",
        title: () => i18n.t('warehouse.box_number'),
        slots: {
          customRender: "boxNoCustom"
        },
      },
      { width: 50, },
      {
        width: 150,
        title: () => i18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 100,
        title: () => i18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 100,
        title: () => i18n.t("common.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 120,
        title: () => i18n.t("warehouse.inbound_status"),
        slots: {
          customRender: "inWarehouseStatus"
        }
      },
      {
        width: 150,
        title: () => i18n.t("warehouse.inbound_packing_size"),
        slots: {
          customRender: "checkSize"
        }
      },
      {
        width: 120,
        title: () => i18n.t("warehouse.inbound_packing_weight"),
        slots: {
          customRender: "checkWeight"
        }
      },
      {
        width: 120,
        title: () => i18n.t("warehouse.in_warehouse_img"),
        slots: {
          customRender: "checkImgUrls"
        }
      },
      {
        width: 100,
        title: () => i18n.t('warehouse.error_state'),
        slots: {
          customRender: "errorTypeCustom",
        },
      },
      {
        width: 100,
        title: () => '',
        slots: {
          customRender: "operateCustom",
        },
      },
      {
        fixed: "right",
        width: 120,
        slots: {
          customRender: "operateCustomer",
        },
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 150,
      },
      {
        title: () => i18n.t("common.count"),
        slots: {
          customRender: "containerCount"
        }
      },
    ];

    const total = reactive({
    })

    const state = reactive({
      columns,
      innerColumns,
      warehouseTypeEnum: warehouseTypeEnum,
      pageLoading: false,
      pageSaveLaoding: false,
      hasException: false,

      pinModalState: {
        visible: false,
        loading: false,
        id: null,
      },
    });

    const data = reactive({
      planDetails: [],
      planId: "",
      planNo: "",
      creationTime: null,
      lastModificationTime: null,
      planName: "",
      relation: null,
      toRelationPlanNo: null,
      planStatus: "",
      inWarehouseType: "",
      selectedLogisticsWay: "",
      selectedDeliveryWay: "",

      originate: "",
      originateType: "",
      inTargetWarehouse: "",
      inTargetWarehouseType: "",
      inTargetWarehouseEnum: "",
      originateAddress: "",
      warehouseAddress: "",

      totalBoxCount: "",
      totalVolume: "",
      totalEncasementWeight: "",
      totalEncasementCount: "",

      shopName: "",
      shopMark:"",
      shopNo: "",
      fee:null
    });

    const handleShowPinModal = (record) => {
      state.pinModalState.visible = true;
      state.pinModalState.loading = false;
      state.pinModalState.id = record.detailId;
    }

    const handleCancelBox = (pin) => {
      state.pinModalState.loading = true
      const requestData = {
        planId: data.planId,
        detailId: state.pinModalState.id,
        pin,
        warehouseType: warehouseTypeEnum.storage
      }
      inPlaneBoxCancel(requestData).then(() => {
        message.success(i18n.t('common.succeed'))
        state.pinModalState.visible = false
        init()
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const funcSearchPlanDetail = async (planId) => {
      let searchData = {
        "id": planId,
      };
      state.pageLoading = true;
      getInWarehouse(searchData)
        .then((res) => {
          let r = res.result;
          data.planName = r.planName;
          data.relation = r.relation;
          data.toRelationPlanNo = r.toRelationPlanNo;

          data.planNo = r.planNo;
          data.fee = r.fee;
          data.creationTime = r.creationTime;
          data.lastModificationTime = r.lastModificationTime;
          data.planId = r.id;
          state.isConfirm = r.isConfirm;
          state.isDespatch = r.isDeliver;
          data.targetWarehouseType = r.to.warehouseType;
          data.selectedLogisticsWay = r.logisticsType;
          data.selectedDeliveryWay = r.deliveryType;
          state.hasException = r.hasException;
          data.planStatus = r.inWarehouseStatus;
          data.inWarehouseType = r.inWarehouseType;
          if (r.to.no && r.to.warehouseType > 0) {
            data.inTargetWarehouse = r.to.no ?? "";
            data.inTargetWarehouseType = r.to.warehouseType ?? "";
          } else {
            data.inTargetWarehouse = "";
          }
          data.inTargetWarehouseEnum = r.to.warehouseType;
          if (r.from.isWarehouse && r.from.no && r.from.warehouseType > 0) {
            data.originate = r.from.no ?? "";
            data.originateType = r.from.warehouseType ?? "";
          } else {
            data.originate = r.from.name ? r.from.name : "";
          }

          data.originateAddress = r.from;
          data.warehouseAddress = r.to;
          data.shopName = r.shopName;
          data.shopMark = r.shopMark;
          data.shopNo = r.shopNo;

          data.planDetails = r.items.map((x, index) => {
            x.serialNumber = index + 1;
            return x;
          })

          Object.assign(total,r.total);

          state.pageLoading = false;
        })
        .catch((res) => {
          state.pageLoading = false;
        })
      state.pageLoading = true;
    }

    const handleBack = async () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/warehousemonitor/storage/warehousingtask" });
    }

    const handleTrackShowModal = async () => {
      refTrace.value.handleTrackShowModal(data.planId);
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(productStatisticsTypeEnum.inWarehouse, route.params.id, warehouseTypeEnum.storage)
    }

    const init = () => {
      let planId = route.params.id;
      if (planId) {
        funcSearchPlanDetail(planId);
      }
    }

    onMounted(init)

    const currentPageIndex = ref(1);
    const defaultPageSize = ref(50);

    return {
      ...toRefs(state),
      ...toRefs(data),
      total,

      gToKg,
      total,
      cmCubicToM,
      inWarehouseOperationErrorTypeEnum,
      inWarehouseStatusEnum,

      currentPageIndex,
      defaultPageSize,
      columns,

      refPage,
      refTrace,
      productStatisticsModalRef,
      handleShowPinModal,
      handleCancelBox,
      handleTrackShowModal,
      handleBack,
      getAddress,
      getLinkman,
      handleShowProductStatisticsModal,
    };
  }
})
</script>

<style lang="less" scoped>
.file-imgs {
  width: 50px;
  height: 50px;
  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  :deep(.ant-image img) {
    max-height: 40px;
  }
}

.delete-label {
  position: absolute;
  top: 90%;
  left: 35%;
  font-size: 15px;
  color: #d9d9d9;

  :hover {
    color: #04befe;
  }
}
</style>
import client from "../../client"

const Api = {
    //获取物流分页列表
    getListPaged: "/api/LogisticsService/Logistics/GetListPaged",

    //创建物流
    create: "/api/LogisticsService/Logistics/Create",

    //更新物流
    update: "/api/LogisticsService/Logistics/Update",

    //更新物流状态
    updateStatus: "/api/LogisticsService/Logistics/UpdateStatus",

    //获取物流渠道信息
    getById: "/api/LogisticsService/Logistics/GetById",

    //获取所有物流渠道
    getAllList: "/api/LogisticsService/Logistics/GetAllList",

    //复制渠道
    copy: "/api/LogisticsService/Logistics/Copy",

    // 账期配置
    addOrUpdate: "/api/LogisticsService/AccountingPeriodConfig/AddOrUpdate",

    // 根据物流渠道Id获取物流账期配置
    getByLogisticsId: "/api/LogisticsService/AccountingPeriodConfig/GetByLogisticsId",

    // 根据地址类型获取地址编码
    getPlatformAddressList: "/api/BasicDataService/PlatformAddress/GetList",

    // 查询邮编对应的国家省市区
    getByCountryIdAndZipCode: "/api/BasicDataService/AreaZipcodeRelation/GetByCountryIdAndZipCode",

    // 获取所有物流渠道
    getLogisticsValidBasicData: "/api/LogisticsService/Logistics/GetLogisticsValidBasicData",
}

export const getListPaged = (data) => {
    return client.request({
        url: Api.getListPaged,
        data,
        method: 'POST'
    });
}

export const create = ({
    id,
    name,
    code,
    isInternationalTransport,
    channelTimeType,
    daysMin,
    daysMax,
    declareSupported,
    countryId,
    provinceId,
    cityId,
    countyId,
    isSupportPayOnArrival,

    warehouseIds,
    specialRegionIds,
    logisticsType,
    deliveryType,
    transportCharacteristicsList,
    discount,
    fuelSurcharge,
    commissionRate,
    singleMinDistributionWeight,
    volumeWeightParam,
    distributionRatio,
    singleCeiling,
    singleCeilingWeightStep,
    minWeight,
    maxWeight,
    minVolume,
    maxVolume,
    orderMinChargeableWeight,
    orderMinChargeableWeightEnabled,
    singleMinChargeableWeight,
    singleMinChargeableWeightEnabled,
    descriptionItems,
    calcWithSingle,
    sortNum,
    isActive,
    onlySingleSupported,
    logisticsCalcMethod,
    maxWeightPreCubicMeter,
    maxSingleWeight,
    maxSingleVolume,
    maxLongestSideLength,
    maxGirthSideLength,
    minimumCharge,
    feeCalculationMethod,
    apiAccountId,
    calcLogicType,
}) => {
    return client.request({
        url: Api.create,
        data: {
            id,
            name,
            code,
            isInternationalTransport,
            channelTimeType,
            daysMin,
            daysMax,
            declareSupported,
            countryId,
            provinceId,
            cityId,
            countyId,
            isSupportPayOnArrival,

            warehouseIds,
            specialRegionIds,
            logisticsType,
            deliveryType,
            transportCharacteristicsList,
            discount,
            fuelSurcharge,
            commissionRate,
            singleMinDistributionWeight,
            volumeWeightParam,
            distributionRatio,
            singleCeiling,
            singleCeilingWeightStep,
            minWeight,
            maxWeight,
            minVolume,
            maxVolume,
            orderMinChargeableWeight,
            orderMinChargeableWeightEnabled,
            singleMinChargeableWeight,
            singleMinChargeableWeightEnabled,
            descriptionItems,
            calcWithSingle,
            sortNum,
            isActive,
            onlySingleSupported,
            logisticsCalcMethod,
            maxWeightPreCubicMeter,
            maxSingleWeight,
            maxSingleVolume,
            maxLongestSideLength,
            maxGirthSideLength,
            minimumCharge,
            feeCalculationMethod,
            apiAccountId,
            calcLogicType,
        },
        method: 'POST'
    });
}

export const update = ({
    id,
    name,
    code,
    isInternationalTransport,
    channelTimeType,
    daysMin,
    daysMax,
    declareSupported,
    countryId,
    provinceId,
    cityId,
    countyId,
    isSupportPayOnArrival,
    warehouseIds,
    specialRegionIds,
    logisticsType,
    deliveryType,
    transportCharacteristicsList,
    discount,
    fuelSurcharge,
    commissionRate,
    singleMinDistributionWeight,
    volumeWeightParam,
    distributionRatio,
    singleCeiling,
    singleCeilingWeightStep,
    minWeight,
    maxWeight,
    minVolume,
    maxVolume,
    orderMinChargeableWeight,
    orderMinChargeableWeightEnabled,
    singleMinChargeableWeight,
    singleMinChargeableWeightEnabled,
    descriptionItems,
    calcWithSingle,
    sortNum,
    isActive,
    onlySingleSupported,
    logisticsCalcMethod,
    maxWeightPreCubicMeter,
    maxSingleWeight,
    maxSingleVolume,
    maxLongestSideLength,
    maxGirthSideLength,
    minimumCharge,
    feeCalculationMethod,
    apiAccountId,
    calcLogicType,
}) => {
    return client.request({
        url: Api.update,
        data: {
            id,
            name,
            code,
            isSupportPayOnArrival,
            isInternationalTransport,
            channelTimeType,
            daysMin,
            daysMax,
            declareSupported,
            countryId,
            provinceId,
            cityId,
            countyId,
            warehouseIds,
            specialRegionIds,
            logisticsType,
            deliveryType,
            transportCharacteristicsList,
            discount,
            fuelSurcharge,
            commissionRate,
            singleMinDistributionWeight,
            volumeWeightParam,
            distributionRatio,
            singleCeiling,
            singleCeilingWeightStep,
            minWeight,
            maxWeight,
            minVolume,
            maxVolume,
            orderMinChargeableWeight,
            orderMinChargeableWeightEnabled,
            singleMinChargeableWeight,
            singleMinChargeableWeightEnabled,
            descriptionItems,
            calcWithSingle,
            sortNum,
            isActive,
            onlySingleSupported,
            logisticsCalcMethod,
            maxWeightPreCubicMeter,
            maxSingleWeight,
            maxSingleVolume,
            maxLongestSideLength,
            maxGirthSideLength,
            minimumCharge,
            feeCalculationMethod,
            apiAccountId,
            calcLogicType,
        },
        method: 'POST'
    });
}

export const updateStatus = ({ id, isActive }) => {
    return client.request({
        url: Api.updateStatus,
        data: { id, isActive },
        method: 'POST'
    });
}

export const getById = (id) => {
    return client.request({
        url: Api.getById,
        params: { id },
        method: 'GET'
    });
}

export const getAllList = () => {
    return client.request({
        url: Api.getAllList,
        method: 'GET'
    });
}

export const copy = ({ id }) => {
    return client.request({
        url: Api.copy,
        data: { id },
        method: 'POST'
    });
}

export const addOrUpdate = (data) => {
    return client.request({
        url: Api.addOrUpdate,
        data,
        method: 'POST'
    });
}

export const getByLogisticsId = (params) => {
    return client.request({
        url: Api.getByLogisticsId,
        params,
        method: 'GET'
    });
}

export const getPlatformAddressList = (data) => {
    return client.request({
        url: Api.getPlatformAddressList,
        data,
        method: 'POST'
    });
}

//获取支持邮编查询的国家
export const getByCountryIdAndZipCode = ({ countryId, zipCode }) => {
    return client.request({
        url: Api.getByCountryIdAndZipCode,
        data: { countryId, zipCode },
        method: "POST",
    });
}

export const getLogisticsValidBasicData = (params) => {
    return client.request({
        url: Api.getLogisticsValidBasicData,
        params,
        method: 'GET',
    });
}
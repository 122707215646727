import client from "../../client"

const Api = {
  getTask:"/api/WarehouseService/CabinetInStorageAppointmentConsole/GetListPaged",
}

export const getTask = ({status,searchKey,shopId,skipCount,maxResultCount,sorting}) => {
  return client.request({
    url: Api.getTask,
    data: {status,searchKey,skipCount,shopId,maxResultCount,sorting},
    method: "post",
  });
};


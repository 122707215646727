<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.warehouse_monitor_storage_warehousing_task') }}
    </template>
    <template v-slot:contentBody='wrap'>
      <div class="mb-3">
        <a-row :gutter="[16, 16]">
          <a-col>
            <a-select
              style="width: 250px"
              v-model:value="searchState.planNoes"
              mode="tags"
              :maxTagCount="1"
              :maxTagTextLength="18"
              :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
              :placeholder="$t('warehouse.inbound_warehouse_number')"
              allowClear
              @change="handleSelectValueChange('planNoes')"
            ></a-select>
          </a-col>
          <a-col>
            <a-select
              style="width: 250px"
              v-model:value="searchState.boxNoes"
              mode="tags"
              :maxTagCount="1"
              :maxTagTextLength="18"
              :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
              :placeholder="$t('warehouse.box_number')"
              allowClear
              @change="handleSelectValueChange('boxNoes')"
            ></a-select>
          </a-col>
          <a-col>
            <SearchProduct ref="refSearchProduct"></SearchProduct>
          </a-col>
          <a-col>
            <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
          </a-col>
          <a-col>
            <a-select style="width: 250px"
                      v-model:value="searchState.inWarehouseStatus"
                      :placeholder="$t('finance.status')"
                      allow-clear>
              <a-select-option v-for="(value, index) in inWarehouseStatusEnum"
                               :key="index"
                               :title="$t($enumLangkey('inWarehouseStatus', value))"
                               :value="value">
                {{ $t($enumLangkey('inWarehouseStatus', value)) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select style="width: 250px"
                      v-model:value="searchState.inWarehouseType"
                      :placeholder="$t('warehouse.inbound_type')"
                      allow-clear>
              <a-select-option v-for="item in inWarehouseTypeList"
                               :key="item"
                               :title="$t($enumLangkey('inWarehouseType', item))"
                               :value="item">
                {{ $t($enumLangkey("inWarehouseType", item)) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select style="width: 250px"
                      v-model:value="searchState.warehouseId"
                      :placeholder="$t('warehouse.target_warehouse')"
                      :show-search="true"
                      option-filter-prop="search-key"
                      allow-clear>
              <a-select-option v-for="(item, index) in warehouseList"
                               :key="index"
                               :title="`${item.warehouseNo}(${item.warehouseName})`"
                               :search-key="item.warehouseNo + item.warehouseName"
                               :value="item.id">
                {{ item.warehouseNo }}({{ item.warehouseName }})
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-range-picker style="width: 250px;"
                            @change="handleSetDateChange" />
          </a-col>
          <a-col>
            <a-button type="primary" @click="handleSearch">{{ $t('common.query') }}</a-button>
          </a-col>
          <a-col>
            <a-button type="ghost" @click="handleShowPinModal(1)">{{$t('common.export')}}</a-button>
          </a-col>
        </a-row>
      </div>
      <div>
        <!-- 表格 -->
        <a-table :columns="columns"
                 :data-source="list"
                 :scroll="{ x: 500, y: wrap.contentHeight - 170 }"
                 :pagination="false"
                 :rowKey="
            (record, id) => {
              return id;
            }
          "
                 size="small"
                 :loading="loading">

          <template #noT>
            <span>{{ $t("warehouse.plan_name") }}</span>
            <div>{{ $t("warehouse.inbound_warehouse_number") }}</div>
          </template>
          <template #no="{ record }">
            <div>{{ record.planName }}</div>
            <div><CPlanNumberItem :no="record.serialNumber" /></div>
          </template>
          <template #relationTitle>
            <div>{{ $t("warehouse.source_related_doc_no") }}</div>
            <div>{{ $t("warehouse.target_related_doc_no") }}</div>
          </template>
          <template #relationV="{ record }">
            <div><CPlanNumberItem :no="record.relation"></CPlanNumberItem></div>
            <div><CPlanNumberItem :no="record.toRelationPlanNo"></CPlanNumberItem></div>
          </template>
          <template #customer="{ record }">
            {{record.shopName}}
            <div >
              NO: {{record.shopNo??"-"}}
            </div>
            <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
          <template #inboundType="{ record }">
            <span> {{ $t($enumLangkey('inWarehouseType', record.inWarehouseType)) || "-" }} </span>
          </template>
          <template #statusColumns="{ record }">
            <span> {{ $t($enumLangkey('inWarehouseStatus', record.inWarehouseStatus)) || "-" }} </span>
            <template v-if="record.hasException">
              <a-tag class="ml-1" v-if="record.inWarehouseStatus === inWarehouseStatusEnum.complete || record.inWarehouseStatus === inWarehouseStatusEnum.cancel">{{ $t('common.exception') }}</a-tag>
              <a-tag class="ml-1" color="error" v-else>{{ $t('common.exception') }}</a-tag>
            </template>
          </template>
          <template #timeTitle>
            <div>{{ $t('warehouse.creation_time') }}</div>
            <div>{{ $t('logistics.last_modification_time') }}</div>
          </template>
          <template #timeCustomer="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>{{ $filters.utcToCurrentTime(record.updateTime) }}</div>
          </template>
          <template #action="{ record }">
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1" :record="record">
                    {{ $t("warehouse.task_details") }}
                  </a-menu-item>
                  <a-menu-item key="2" :record="record" :disabled="!record.isCanCancel">
                    {{ $t("common.cancel") }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                {{ $t('common.operate') }}
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </template>
        </a-table>
      </div>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data='pageData' />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, onMounted, reactive, toRefs ,ref} from 'vue';
import {
  Table,
  Button,
  Modal,
  Select,
  Descriptions,
  Pagination,
  Card,
  Row,
  Col,
  Space,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  message,
Tag,
} from 'ant-design-vue';
import CPager from '@/views/components/CPager.vue';
import Content from '@/views/components/Content.vue';
import CPinTipsModal from '@/views/components/CPinTipsModal.vue';
import CSearchShop from "@/views/components/CSearchShop.vue"
import SearchProduct from "@/views/components/SearchProduct.vue"
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import { getWarehouses } from '@/api/modules/common';
import {
  getStorageInWarehouseListPaged,
  updateCancelInPlanForConsole,
  exportStorageInWarehouse,
} from '@/api/modules/warehouseMonitor/storage';
import {
  inWarehouseStatus as inWarehouseStatusEnum,
  warehouseType,
  inWarehouseType as inWarehouseTypeEnum
} from '@/enum/enum.json'
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { currentTimeToUtc, dateString } from "@/utils/general";
import { downloadFile } from '@/utils/downloader.js';

export default defineComponent({
  name: "warehouse_monitor_storage_warehousing_task",
  components: {
    SearchProduct,ATable: Table,
    AButton: Button,
    AModal: Modal,
    ASelect: Select,
    APagination: Pagination,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    ASelectOption: Select.Option,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    AInput: Input,
    ARangePicker: DatePicker.RangePicker,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATag: Tag,
    Content,
    CPager,
    CPinTipsModal,
    CSearchShop,
    CPlanNumberItem,
  },

  setup () {
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });
    const refSearchProduct = ref();

    const data = reactive({
      list: [],
      searchState: {
        plan: null,
        planNoes: [],
        box: null,
        boxNoes: [],
        shopId: null,
        warehouseId: null,
        inWarehouseType: null,
        inWarehouseStatus: null,
        productId: null,
        beginCreationTime: null,
        endCreationTime: null,
      },
      searchStateCache: {},
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });

    const state = reactive({
      inWarehouseStatusEnum,
      warehouseList: [],
      inWarehouseTypeList: [],
      loading: true,
      pinModalState: {
        visible: false,
        loading: false,
        key: null,
        dataTemp: null,
      }
    })

    for (const key in inWarehouseTypeEnum) {
      state.inWarehouseTypeList.push(inWarehouseTypeEnum[key])
    }

    const columns = [
      {
        dataIndex: 'serialNumber',
        width: 170,
        fixed: 'left',
        slots: {
          customRender: "no",
          title: "noT",
        },
      },
      {
        width: 170,
        slots: {
          title: "relationTitle",
          customRender: "relationV",
        },
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 200,
      },
      {
        width: 100,
        title: () => vueI18n.t('finance.status'),
        slots: {
          customRender: "statusColumns"
        },
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.inbound_type'),
        slots: {
          customRender: "inboundType"
        },
      },
      {
        title: () => vueI18n.t('logistics.warehouse_code'),
        dataIndex: 'toInWarehouseNo',
        width: 100,
      },
      {
        dataIndex: 'quantity',
        width: 100,
        title: () => vueI18n.t('warehouse.inbound_receipt_quantity'),
      },
      {
        dataIndex: 'cartonNumbers',
        width: 100,
        title: () => vueI18n.t('warehouse.inbound_box_number'),
      },
      {
        dataIndex: 'receiveNumber',
        width: 100,
        title: () => vueI18n.t('warehouse.received_quantity'),
      },
      {
        dataIndex: 'receptionNumber',
        width: 100,
        title: () => vueI18n.t('warehouse.receive_box_quantity'),
      },
      {
        dataIndex: 'productQuantity',
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
      },
      {
        width: 120,
        slots: {
          title: "timeTitle",
          customRender: "timeCustomer"
        },
      },
      {
        width: 100,
        fixed: 'right',
        slots: {
          customRender: 'action',
        },
      }
    ];

    const handleSelectValueChange = (key) => {
      let value = data.searchState[key];
      if (Array.isArray(value)) {
        data.searchState[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handleShowPinModal = (key, data) => {
      state.pinModalState.key = key
      state.pinModalState.visible = true
      if (data) {
        state.pinModalState.dataTemp = data
      }
    }

    const handlePinModalConfirm = (pinCode) => {
      switch (state.pinModalState.key) {
        // 导出
        case 1:
          handleExport(pinCode)
          break;
        // 取消
        case 2:
          handleCancel(pinCode)
          break;
        default:
          break;
      }
    }
    
    const handleExport = (pinCode) => {
      state.pinModalState.loading = true
      let url = exportStorageInWarehouse();
      const searchState = Object.assign({}, data.searchStateCache, { pinCode, warehouseType: warehouseType.storage });
      downloadFile(url, `ws_inbound_plan_${dateString()}.csv`, "POST", searchState).then(() => {
        state.pinModalState.visible = false
        message.success(vueI18n.t('common.succeed'))
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const funcGetinTaskTableList = async () => {
      state.loading = true;
      try {
        data.searchStateCache.productId= refSearchProduct.value.selectedProductId;
        const tableData = Object.assign({}, data.pageData, data.searchStateCache, {
          warehouseType: warehouseType.storage
        })
        let res = await getStorageInWarehouseListPaged(tableData);
        let { items, totalCount } = res.result;
        const tableList = items.map(x => {
          return {
            id: x.id,
            serialNumber: x.planNo,
            planName: x.planName,
            relation: x.relation,
            toRelationPlanNo: x.toRelationPlanNo,
            toInWarehouseNo: x.toInWarehouseNo,
            shopName: x.shopName,
            shopId: x.shopId,
            shopMark: x.shopMark,
            shopNo: x.shopNo,
            quantity: x.total.plan.totalWarehouseCount,
            cartonNumbers: x.total.plan.totalContainerBoxCount,
            receiveNumber: x.total.check.totalWarehouseCount,
            receptionNumber: x.total.check.totalContainerBoxCount,
            productQuantity: x.total.plan.totalCount,
            inWarehouseType: x.inWarehouseType,
            inWarehouseStatus: x.inWarehouseStatus,
            hasException: x.hasException,
            creationTime: x.creationTime,
            updateTime: x.lastModificationTime,
            isCanCancel: x.isCanCancel,
          }
        })
        data.list = tableList;
        data.pageData.totalCount = parseInt(totalCount);
      } catch (error) {

      } finally {
        state.loading = false;
      }
    }

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcGetinTaskTableList();
    }

    const handleToInDetails = async (row) => {
      router.push({ name: "warehouse_monitor_storage_warehousing_task_detail", params: { id: row.id } })
    };

    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      Object.assign(data.searchStateCache, data.searchState);
      funcGetinTaskTableList();
    }

    const getWarehouseList = () => {
      getWarehouses({ warehouseType: warehouseType.storage }).then((res) => {
        let { result } = res
        if (Array.isArray(result)) {
          state.warehouseList = result
        }
      })
    }

    const handleSetDateChange = (e) => {
      let beginTime = null,
        endTime = null;
      if (e?.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format('YYYY-MM-DD 00:00:00'));
        endTime = currentTimeToUtc(e[1]?.format('YYYY-MM-DD 00:00:00'));
      }
      data.searchState.beginCreationTime = beginTime;
      data.searchState.endCreationTime = endTime;
    }

    const handleCancel = (pin) => {
      state.pinModalState.loading = true
      const params = {
        planId: state.pinModalState.dataTemp,
        pin
      }
      updateCancelInPlanForConsole(params).then(() => {
        message.success(vueI18n.t('common.succeed'))
        state.pinModalState.dataTemp = null
        state.pinModalState.visible = false
        handleSearch()
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          handleToInDetails(record)
          break;
        case '2':
          handleShowPinModal(2, record.id)
          break;
        default:
          break;
      }
    }

    onActivated(funcGetinTaskTableList);

    onMounted(getWarehouseList);

    return {
      columns,
      ...toRefs(data),
      ...toRefs(state),
      handleSelectValueChange,
      handleShowPinModal,
      handlePinModalConfirm,
      handlePage,
      handleSearch,
      handleSetDateChange,
      handleCancel,
      handleMenuClick,
      refSearchProduct
    };
  },
});
</script>
<style lang="less" scoped>
</style>

<template>
  <Content>
    <template #contentTitle>{{ $t('menu.warehouse_monitor_storage_label_change_detail') }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[8, 8]" type="flex" justify="space-between">
          <a-col>
            <a-row :gutter="[8, 8]">
              <a-col :span="24">
                {{ $t('warehouse.belonging_to_warehouse') }}: 
                <span v-if="pageInfo.warehouse">{{ pageInfo.warehouse.warehouseNo }}</span>
                <span v-else>-</span>
              </a-col>
              <a-col :span="24">{{ $t('warehouse.label_task_name') }}: {{ pageInfo.taskName || '-' }}</a-col>
              <a-col :span="24">{{ $t('warehouse.tag_task_number') }}: {{ pageInfo.taskNo || '-' }}</a-col>
              <a-col :span="24">{{ $t('warehouse.operate_console_no') }}: {{ pageInfo.location?.locationRoute || '-' }}</a-col>
            </a-row>
          </a-col>
          <a-col :lg="6" :span="24">
            <a-row :gutter="[8, 8]" style="height: 100%;" type="flex" align="middle">
              <a-col :span="24" style="text-align: center;">{{ $t('warehouse.task_status') }}</a-col>
              <a-col :span="24" style="text-align: center;">
                <a-typography-text type="secondary" v-if="pageInfo.status === labelTaskStatusEnum.cancelled">{{ $t($enumLangkey('labelTaskStatus', pageInfo.status)) }}</a-typography-text>
                <a-typography-text type="success" v-else>{{ $t($enumLangkey('labelTaskStatus', pageInfo.status)) }}</a-typography-text>
              </a-col>
              <a-col :span="24" v-if="pageInfo.cancelled" style="text-align: center;">
                <a-button @click="handleShowCancelPin()">{{ $t('common.cancel') }}</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-table
          style="overflow-x: auto;"
          class="mt-3"
          size="small"
          :columns="columns"
          :data-source="pageInfo.items"
          :pagination="false"
          :row-key="(record) => record.detailId"
        >
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #boxNo="{ record }">
            {{ record.box?.boxNo || '-' }}
          </template>
          <template #selfBoxNo="{ record }">
            {{ record.box?.selfBoxNo || '-' }}
          </template>
          <template #locationRoute="{ record }">
            {{ record.location?.locationRoute || '-' }}
          </template>
          <template #status="{ record }">
            <LabelChangeStep
              v-if="record.status.length === 1 && record.status[0].status === labelTaskDetailStatusEnum.canceled"
              :name="$t($enumLangkey('labelTaskDetailStatus', record.status[0].status))"
              :status="record.status[0].status"
              :userInfo="record.status.length > 0 ? record.status[0].employee : null"
            />
            <a-row :gutter="[16, 16]" type="flex" align="middle" v-else>
              <a-col flex="1 1 150px">
                <LabelChangeStep
                  :name="$t('menu.task_transfer')+ '-' + $t('warehouse.notional_pooling')"
                  :status="record.status.length > 0 ? record.status[0].status : null"
                  :userInfo="record.status.length > 0 ? record.status[0].employee : null"
                />
              </a-col>
              <a-col flex="1 1 150px">
                <LabelChangeStep
                  :name="$t('warehouse.service_status')"
                  :status="record.status.length > 1 ? record.status[1].status : null"
                  :userInfo="record.status.length > 1 ? record.status[1].employee : null"
                />
              </a-col>
              <a-col flex="1 1 150px">
                <LabelChangeStep
                  :name="$t('menu.task_transfer')+ '-' + $t('warehouse.listing')"
                  :status="record.status.length > 2 ? record.status[2].status : null"
                  :userInfo="record.status.length > 2 ? record.status[2].employee : null"
                />
              </a-col>
            </a-row>
          </template>
          <template #operation="{ record }">
            <a-button v-if="record.cancelled" @click="handleShowCancelPin(record)">{{ $t('common.cancel') }}</a-button>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.items"
              :pagination="false"
              :row-key="(record) => record.productRlId"
            >
              <template #serialNumber="{ index }">
                {{ index + 1 }}
              </template>
              <template #productIdentifyImgs="{ record }">
                <a-row :gutter="8" v-if="record.productIdentifyImgs.length > 0">
                  <a-col v-for="(item, index) in record.productIdentifyImgs" :key="index">
                    <CFileDisplay :fileUrl="item" />
                  </a-col>
                </a-row>
                <span v-else>-</span>
              </template>
              <template #labelUrl="{ record }">
                <CFileDisplay :fileUrl="record.labelUrl" />
              </template>
              <template #newProductIdentify="{ record }">
                {{ record.newProductIdentify || '-' }}
              </template>
              <template #productCount="{ record }">
                {{ record.productCount || '-' }}
              </template>
              <template #actualProductCount="{ record }">
                {{ record.actualProductCount || '0' }}
              </template>
            </a-table>
          </template>
        </a-table>
        <a-row class="mt-3" :gutter="[16, 16]" type="flex" justify="end">
          <a-col v-if="pageInfo.fee">
            <CFee :fees="pageInfo.fee.fees" :totalFee="pageInfo.fee.totalFee" />
          </a-col>
        </a-row>
        <a-row class="mt-5 mt-3" type="flex" justify="end">
          <a-col :pull="2">
            <a-button type="primary" ghost @click="handleBack">{{ $t('common.backtrack') }}</a-button>
          </a-col>
        </a-row>
      </a-spin>

      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinConfirm"
      />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Button, Col, Row, Spin, Table, Popover, Typography, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import CFee from '@/views/components/CFee.vue';
import CPinTipsModal from '@/views/components/CPinTipsModal.vue';
import LabelChangeStep from './components/LabelChangeStep.vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { useTab } from "@/hooks/tabs/index";
import { setFormStateValue } from '@/utils/general.js';
import {
  labelTaskStatus as labelTaskStatusEnum,
  labelTaskDetailStatus as labelTaskDetailStatusEnum,
} from '@/enum/enum.json';
import { getStorageConsoleLabelChange, cancelStorageConsoleLabelChange, cancelStorageConsoleLabelChangeBox } from '@/api/modules/warehouseMonitor/storage.js';

export default defineComponent({
  name: 'warehouse_monitor_storage_label_change_detail',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    APopover: Popover,
    ATypographyText: Typography.Text,
    Content,
    CFileDisplay,
    CFee,
    CPinTipsModal,
    LabelChangeStep,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    
    const state = reactive({
      taskId: null,
      loading: false,
      pageInfo: {
        cancelled: false,
        fee: null,
        items: [],
        status: null,
        warehouse: null,
        taskName: null,
        taskNo: null,
        location: null,
      },
      pinModalState: {
        visible: false,
        loading: false,
        dataTemp: null,
      },
    });

    const columns = [
      {
        width: 100,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.se_box_number'),
        slots: {
          customRender: 'boxNo',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.user_defined_box_no'),
        slots: {
          customRender: 'selfBoxNo',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.current_location'),
        slots: {
          customRender: 'locationRoute',
        }
      },
      {
        slots: {
          customRender: 'status',
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const innerColumns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        dataIndex: 'productIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.identifiable_product_information'),
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.recognizable_product_images'),
        slots: {
          customRender: 'productIdentifyImgs',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_labeling_document'),
        slots: {
          customRender: 'labelUrl',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_label'),
        slots: {
          customRender: 'newProductIdentify',
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
        slots: {
          customRender: 'productCount',
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.quantity_of_finished_products'),
        slots: {
          customRender: 'actualProductCount',
        }
      },
    ];

    // 取消任务
    const handleCancelTask = (pinCode) => {
      state.pinModalState.loading = true;
      const data = {
        taskId: state.taskId,
        pinCode,
      }
      cancelStorageConsoleLabelChange(data).then(() => {
        message.success(vueI18n.t("common.succeed"));
        state.pinModalState.visible = false;
        getDetail();
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false;
      });
    }

    // 取消箱
    const handleCancelBox = (pinCode, taskDetailId) => {
      state.pinModalState.loading = true;
      const data = {
        taskId: state.taskId,
        taskDetailId,
        pinCode,
      }
      cancelStorageConsoleLabelChangeBox(data).then(() => {
        message.success(vueI18n.t("common.succeed"));
        state.pinModalState.visible = false;
        getDetail();
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false;
      });
    }

    const handlePinConfirm = (pinCode) => {
      if (state.pinModalState.dataTemp) {
        handleCancelBox(pinCode, state.pinModalState.dataTemp);
      } else {
        handleCancelTask(pinCode);
      }
      state.pinModalState.dataTemp = null;
    }

    const handleShowCancelPin = (boxRecord) => {
      if (boxRecord) {
        state.pinModalState.dataTemp = boxRecord.detailId;
      } else {
        state.pinModalState.dataTemp = null;
      }
      state.pinModalState.visible = true;
    }

    const getDetail = () => {
      state.loading = true;
      getStorageConsoleLabelChange({ taskId: state.taskId }).then(({ result }) => {
        if (result) {
          setFormStateValue(state.pageInfo, result);
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "warehouse_monitor_storage_label_change" });
    };

    onMounted(() => {
      let params = route.params;
      if ('id' in params) {
        state.taskId = params.id;
        getDetail();
      }
    });

    return {
      ...toRefs(state),
      labelTaskStatusEnum,
      labelTaskDetailStatusEnum,
      columns,
      innerColumns,
      handlePinConfirm,
      handleShowCancelPin,
      handleBack,
    }
  }
})
</script>

<style scoped>

</style>
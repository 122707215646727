<template>
  <div>
    <a-auto-complete
      v-model:value="searchProductKey"
      style="width: 250px"
      :allowClear="true"
      @search="handleSearchProduct"
      @select="handleSelectProduct"
      @blur="handleCheckSearchProductKey"
      @focus="handleFocus"
      @change="handleChange"
    >
      <template #default>
        <a-input
          :placeholder="$t('warehouse.please_input_search_product_no_name')"
        ></a-input>
      </template>
      <template #options>
        <a-select-option
          v-for="item in productList"
          :key="item.id"
          :disabled="!item?.id"
          :data="item"
          :value="`${item.productName}(${item.productNo})`"
        >
          <a-empty v-if="!item?.id" :image="simpleImage" />
          <a-row v-else type="flex" justify="space-between" align="middle">
            <a-col :span="7">
              <CFileDisplay
                :fileUrl="item.imgUrl"
                :disImgPreview="true"
                :disThumbProcess="true"
              />
            </a-col>
            <a-col :span="17">
              <div :title="item.productName ? item.productName : '-'">
                <a-typography-paragraph
                  :ellipsis="true"
                  class="mb-0"
                  :content="item.productName ? item.productName : '-'"
                />
              </div>
              <div
                :title="`${$t('warehouse.product_no')}: ${
                  item.productNo ? item.productNo : '-'
                }`"
              >
                <a-typography-paragraph
                  :ellipsis="true"
                  class="mb-0"
                  :content="`${$t('warehouse.product_no')}: ${
                    item.productNo ? item.productNo : '-'
                  }`"
                />
              </div>
              <div :title="`${$t('SESKU')}: ${item.seSku ? item.seSku : '-'}`">
                <a-typography-paragraph
                  :ellipsis="true"
                  class="mb-0"
                  :content="`${$t('SESKU')}: ${item.seSku ? item.seSku : '-'}`"
                />
              </div>
            </a-col>
          </a-row>
        </a-select-option>
      </template>
    </a-auto-complete>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import {
  Row,
  Col,
  AutoComplete,
  Typography,
  Input,
  Select,
  Form,
  Empty,
} from "ant-design-vue";
import { getAutoProductList } from "../../api/modules/common/index";
import { debounce } from "../../utils/general";
import CFileDisplay from "../components/CFileDisplay.vue";
export default {
  name: "SearchProduct",
  components: {
    CFileDisplay,
    ARow: Row,
    ACol: Col,
    ATypographyParagraph: Typography.Paragraph,
    ASelect: Select,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AAutoComplete: AutoComplete,
    AInput: Input,
    AEmpty: Empty,
    emits: ["productData"],
  },
  setup(props, { emit }) {
    const state = reactive({
      productList: [],
    });

    const data = reactive({
      oldSearchKey: null,
      searchProductKey: null,
      selectedProductId: null,
    });

    const funcGetProductList = () => {
      if (
        !data.searchProductKey ||
        data.searchProductKey?.trim()?.length == 0
      ) {
        return;
      }
      data.selectedProductId = null;
      let searchData = {
        searchKey: data.searchProductKey,
        skipCount: 0,
        maxResultCount: 10,
      };

      getAutoProductList(searchData)
        .then((res) => {
          if (res.result?.items && res.result?.items?.length > 0) {
            state.productList = res.result.items?.map((x)=>{
              x.productName = x.productName.replace("\n"," ")
              .replace("\r","");
              return x;
            });
          } else {
            state.productList = [{ id: null }];
          }
          data.oldSearchKey = data.searchProductKey;
        })
        .finally(() => {
          return state.productList;
        });
    };

    const handleFocus = () => {
      state.productList = [];
    };

    const handleSearchProduct = debounce(funcGetProductList, 500);

    const handleCheckSearchProductKey = async () => {
      //直接根据id判断,如果动了输入框id就为空
      if (!data.selectedProductId) {
        data.searchProductKey = null;
        data.selectedProductId = null;
        emit("update:productData", null);
      }
    };

    const handleChange = (option) => {
      if (!data.searchProductKey) {
        data.selectedProductId = null;
        emit("update:productData", null);
      }
    };

    const handleSelectProduct = async (value, option) => {
      data.selectedProductId = option.key;
      emit("update:productData", option.data);
    };

    return {
      ...toRefs(state),
      ...toRefs(data),

      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      handleSearchProduct,
      handleCheckSearchProductKey,
      handleSelectProduct,
      handleChange,
      handleFocus,
    };
  },
};
</script>

<template>
  <a-modal
    :visible="visible"
    :confirmLoading="state.btnLoading"
    title="编辑合同"
    @ok="handleOk"
    @cancel="close"
  >
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="签署合同" required>
        <a-radio-group v-model:value="state.hasContract">
          <a-radio :value="true">已签署</a-radio>
          <a-radio :value="false">未签署</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="合同文件">
        <CUpload
          v-model:fileUrl="state.contractPath"
          accept=" .jpeg,.jpg,.png,.pdf"
        ></CUpload>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { Modal, Form, Select, message, Spin, Radio } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { updateUpdateContract } from "@/api/modules/customer/company/list.js";
import CUpload from "./Upload.vue";

export default defineComponent({
  components: {
    CUpload,
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpin: Spin,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shopId: String, //店铺Id
    hasContract: Boolean,
    contractPath: String,
  },
  emits: ["update:visible", "ok"],
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      loading: false,
      id: null,
      hasContract: false,
      contractPath: null,
    });

    watch(
      () => props.visible,
      (value) => {
        if (value) {
          state.id = props.shopId;
          state.hasContract = props.hasContract;
          state.contractPath = props.contractPath;
        }
      }
    );

    const handleOk = async () => {
      state.btnLoading = true;
      updateUpdateContract({ ...state })
        .then(() => {
          state.btnLoading = false;
          message.success(vueI18n.t("common.succeed"));
          emit("ok");
          close();
        })
        .catch(() => {
          state.btnLoading = false;
        });
    };

    const close = () => {
      emit("update:visible", false);
    };

    return {
      state,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      handleOk,
      close,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.dev_box_edit_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div>
        <a-row :gutter="16">
          <a-col class="mb-2">
            <a-input
              :placeholder="$t('devops.box_no')"
              v-model:value="data.search.boxNo"
              allow-clear
              style="width: 260px; max-width: 100%"
            ></a-input>
          </a-col>
          <a-col>
            <span class="d-inline-block">
              <label class="mr-1 mb-2 text-nowrap">{{
                $t("warehouse.modified_data")
              }}</label>
              <a-select
                style="min-width: 70px"
                class="mr-1 mb-2"
                v-model:value="data.search.boxOrProductModifyRecordType"
                @change="calcUnitTypeList"
              >
                <a-select-option v-for="item in recordTypeEnum" :key="item" :title="$t($enumLangkey('boxOrProductModifyRecordType', item))">{{
                  $t($enumLangkey("boxOrProductModifyRecordType", item))
                }}</a-select-option>
              </a-select>
              <a-select
                style="min-width: 70px"
                class="mr-1 mb-2"
                v-model:value="data.search.boxOrProductModifyRecordCompareType"
              >
                <a-select-option v-for="item in compareTypeEnum" :key="item" :title="$t($enumLangkey('boxOrProductModifyRecordCompareType', item))">{{
                  $t($enumLangkey("boxOrProductModifyRecordCompareType", item))
                }}</a-select-option>
              </a-select>
            </span>
            <span class="d-inline-block">
              <label class="ml-1 mr-1 mb-2 text-nowrap">{{
                $t("warehouse.general_data")
              }}</label>
              <a-input
                style="width: 100px"
                class="mr-1 mb-2"
                allow-clear
                :placeholder="$t('warehouse.general_data')"
                v-model:value="data.search.compareValue"
              ></a-input>
              <a-select
                style="min-width: 70px"
                class="mb-2"
                v-model:value="data.search.boxOrProductModifyRecordUnitType"
              >
                <a-select-option
                  v-for="item in data.unitTypeList"
                  :key="item"
                  >{{
                    $t($enumLangkey("boxOrProductModifyRecordUnitType", item))
                  }}</a-select-option
                >
              </a-select>
            </span>
          </a-col>
          <a-col class="mb-2">
            <a-button
              type="primary"
              :loading="data.loading"
              @click="handleSearch"
              >{{ $t("common.query") }}</a-button
            >
          </a-col>
        </a-row>
      </div>
      <div>
        <a-table
          :columns="columns"
          :scroll="{ x: 500, y: wrap.contentHeight - 150 }"
          :pagination="false"
          :rowKey="(record, index) => index"
          :data-source="data.tableList"
          :loading="data.loading"
          size="middle"
          class="text-center"
        >
          <template v-slot:updatedLength="{ record }">
            <small class="d-block">
              {{ record.updatedLengthObj.value }}
              (<span :class="getUpdateCss(record.updatedLengthObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedLengthObj.addOrSub)
                }}{{ record.updatedLengthObj.addOrSubValue }}</span
              >)
            </small>
            <small class="d-block">
              <span :class="getUpdateCss(record.updatedLengthObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedLengthObj.addOrSub)
                }}{{
                  getUpdatePercent(record.updatedLengthObj.addOrSubValuePercent)
                }}</span
              >
            </small>
          </template>
          <template v-slot:updatedWidth="{ record }">
            <small class="d-block">
              {{ record.updatedWidthObj.value }}
              (<span :class="getUpdateCss(record.updatedWidthObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedWidthObj.addOrSub)
                }}{{ record.updatedWidthObj.addOrSubValue }}</span
              >)
            </small>
            <small class="d-block">
              <span :class="getUpdateCss(record.updatedWidthObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedWidthObj.addOrSub)
                }}{{
                  getUpdatePercent(record.updatedWidthObj.addOrSubValuePercent)
                }}</span
              >
            </small>
          </template>
          <template v-slot:updatedHeight="{ record }">
            <small class="d-block">
              {{ record.updatedHeightObj.value }}
              (<span :class="getUpdateCss(record.updatedHeightObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedHeightObj.addOrSub)
                }}{{ record.updatedHeightObj.addOrSubValue }}</span
              >)
            </small>
            <small class="d-block">
              <span :class="getUpdateCss(record.updatedHeightObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedHeightObj.addOrSub)
                }}{{
                  getUpdatePercent(record.updatedHeightObj.addOrSubValuePercent)
                }}</span
              >
            </small>
          </template>
          <template v-slot:updatedVolume="{ record }">
            <small class="d-block">
              {{ record.updatedVolumeObj.value }}
              (<span :class="getUpdateCss(record.updatedVolumeObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedVolumeObj.addOrSub)
                }}{{ record.updatedVolumeObj.addOrSubValue }}</span
              >)
            </small>
            <small class="d-block">
              <span :class="getUpdateCss(record.updatedVolumeObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedVolumeObj.addOrSub)
                }}{{
                  getUpdatePercent(record.updatedVolumeObj.addOrSubValuePercent)
                }}</span
              >
            </small>
          </template>
          <template v-slot:updatedWeight="{ record }">
            <small class="d-block">
              {{ gToKg(record.updatedWeightObj.value) }}
              (<span :class="getUpdateCss(record.updatedWeightObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedWeightObj.addOrSub)
                }}{{ gToKg(record.updatedWeightObj.addOrSubValue) }}</span
              >)
            </small>
            <small class="d-block">
              <span :class="getUpdateCss(record.updatedWeightObj.addOrSub)"
                >{{ getUpdateSymbol(record.updatedWeightObj.addOrSub)
                }}{{
                  getUpdatePercent(record.updatedWeightObj.addOrSubValuePercent)
                }}</span
              >
            </small>
          </template>
          <template v-slot:creationTimeRender="{ record }">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
          </template>
          <template #createUserName="{ record }">
            <CEmployeeInfo v-if="record.createUser" :employee="record.createUser" />
            <span v-else>{{ record.createUserName }}</span>
          </template>
        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePageUpdate"
        :page-data="data.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useStore } from 'vuex';
import CPager from "../components/CPager.vue";
import Content from "../components/Content.vue";
import CEmployeeInfo from "@/views/components/CEmployeeInfo.vue";
import { useI18n } from "vue-i18n/index";
import {
  boxOrProductModifyRecordType as recordTypeEnum,
  boxOrProductModifyRecordCompareType as compareTypeEnum,
  boxOrProductModifyRecordUnitType as unitTypeEnum,
} from "@/enum/enum.json";
import { Table, Button, Row, Col, Select, Input } from "ant-design-vue";
import { getListPaged } from "@/api/modules/devops/index.js";
import { gToKg } from "@/utils/general.js";
import { warehouseType as warehouseTypeEnum } from '@/enum/enum.json';

export default {
  name: "dev_box_edit_list",
  components: {
    ATable: Table,
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    Content,
    CPager,
    CEmployeeInfo,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore()

    const columns = [
      {
        dataIndex: "boxNo",
        fixed: "left",
        title: () => vueI18n.t("devops.box_no"),
        width: 150,
      },
      {
        dataIndex: "warehuseNo",
        title: () => vueI18n.t("devops.generating_facilities"),
        width: 150,
      },
      {
        dataIndex: "symbol",
        title: () => vueI18n.t("devops.scanning_device_no"),
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.general_data"),
        align: "center",
        children: [
          {
            dataIndex: "normalLength",
            title: () => vueI18n.t("common.length") + " (cm)",
            width: 100,
            align: "center",
          },
          {
            dataIndex: "normalWidth",
            title: () => vueI18n.t("common.width") + " (cm)",
            width: 100,
            align: "center",
          },
          {
            dataIndex: "normalHeight",
            title: () => vueI18n.t("common.height") + " (cm)",
            width: 100,
            align: "center",
          },
          {
            dataIndex: "normalVolume",
            title: () => vueI18n.t("common.volume") + " (cm3)",
            width: 100,
            align: "center",
          },
          {
            dataIndex: "normalWeight",
            title: () => vueI18n.t("common.weight") + " (kg)",
            width: 100,
            align: "center",
          },
        ],
      },
      {
        title: () => vueI18n.t("warehouse.modified_data"),
        align: "center",
        children: [
          {
            dataIndex: "update.updatedLengthObj",
            title: () => vueI18n.t("common.length") + " (cm)",
            width: 100,
            align: "center",
            slots: {
              customRender: "updatedLength",
            },
          },
          {
            dataIndex: "update.updatedWidthObj",
            title: () => vueI18n.t("common.width") + " (cm)",
            width: 100,
            align: "center",
            slots: {
              customRender: "updatedWidth",
            },
          },
          {
            dataIndex: "update.updatedHeightObj",
            title: () => vueI18n.t("common.height") + " (cm)",
            width: 100,
            align: "center",
            slots: {
              customRender: "updatedHeight",
            },
          },
          {
            dataIndex: "update.updatedVolumeObj",
            title: () => vueI18n.t("common.volume") + " (cm3)",
            width: 100,
            align: "center",
            slots: {
              customRender: "updatedVolume",
            },
          },
          {
            dataIndex: "update.updatedWeightObj",
            title: () => vueI18n.t("common.weight") + " (kg)",
            width: 100,
            align: "center",
            slots: {
              customRender: "updatedWeight",
            },
          },
        ],
      },
      {
        dataIndex: "creationTime",
        width: 120,
        title: () => vueI18n.t("warehouse.occurrence_time"),
        slots: {
          customRender: "creationTimeRender",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.modifier"),
        slots: {
          customRender: "createUserName"
        }
      },
    ];
    
    const data = reactive({
      loading: false,
      tableList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      search: {
        boxNo: null,
        boxOrProductModifyRecordType: recordTypeEnum.length,
        boxOrProductModifyRecordCompareType: compareTypeEnum.greaterThan,
        compareValue: null,
        boxOrProductModifyRecordUnitType: unitTypeEnum.cm,
        warehouseType: 1
      },
      unitTypeList: [unitTypeEnum.cm, unitTypeEnum.percentage],
      searchCache: {}
    });

    const calcUnitTypeList = () => {
      let unitList = [unitTypeEnum.percentage];
      switch (data.search.boxOrProductModifyRecordType) {
        case recordTypeEnum.length:
        case recordTypeEnum.width:
        case recordTypeEnum.height:
          unitList = [unitTypeEnum.cm].concat(unitList);
          break;
        case recordTypeEnum.volume:
          unitList = [unitTypeEnum.cm3].concat(unitList);
          break;
        case recordTypeEnum.weight:
          unitList = [unitTypeEnum.kg].concat(unitList);
          break;
        default:
          break;
      }
      data.unitTypeList = unitList;
      if (
        !(
          unitList.findIndex(
            (x) => x == data.search.boxOrProductModifyRecordUnitType
          ) >= 0
        )
      ) {
        data.search.boxOrProductModifyRecordUnitType = unitList[0];
      }
    };

    const getUpdateSymbol = (addOrSub) => {
      if (addOrSub == 1) {
        return "+";
      } else if (addOrSub == 2) {
        return "-";
      }
      return "";
    };

    const getUpdateCss = (addOrSub) => {
      if (addOrSub == 1) {
        return "text-success";
      } else if (addOrSub == 2) {
        return "text-error";
      }
      return "";
    };

    const getUpdatePercent = (percent) => {
      return ((percent || 0) * 100).toFixed(2) * 1 + "%";
    };

    const getBoxSizeRecords = async () => {
      const searchModel = Object.assign({}, data.searchCache, {
        skipCount: data.pageData.skipCount,
        maxResultCount: data.pageData.maxResultCount,
        warehouseType: warehouseTypeEnum.logisticsCenter
      })
      data.loading = true;
      getListPaged(searchModel)
        .then((res) => {
          data.loading = false;
          let { result } = res;
          data.pageData.totalCount = parseInt(result.totalCount);
          data.tableList = result.items.map((x) => ({
            boxNo: x.boxNo,
            warehuseNo: x.warehuseNo || "-",
            symbol: x.symbol || "-",
            normalLength: x.normal.checkContainerLength || "-",
            normalWidth: x.normal.checkContainerWidth || "-",
            normalHeight: x.normal.checkContainerHeight || "-",
            normalVolume: x.normal.checkContainerVolume || "-",
            normalWeight: gToKg(x.normal.checkContainerWeight) || "-",
            updatedLengthObj: x.update.checkContainerLength,
            updatedWidthObj: x.update.checkContainerWidth,
            updatedHeightObj: x.update.checkContainerHeight,
            updatedVolumeObj: x.update.checkContainerVolume,
            updatedWeightObj: x.update.checkContainerWeight,
            createUser: x.createUser,
            createUserName: x.createUserName,
            creationTime: x.creationTime,
          }));
        })
        .catch(() => {
          data.loading = false;
        });
    };

    const handlePageUpdate = (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      getBoxSizeRecords();
    };

    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      Object.assign(data.searchCache, data.search)
      getBoxSizeRecords();
    };

    const handleInitSearch = () => {
      data.unitTypeList = [unitTypeEnum.cm, unitTypeEnum.percentage]
      data.search = Object.assign({}, data.search, {
        boxNo: null,
        boxOrProductModifyRecordType: recordTypeEnum.length,
        boxOrProductModifyRecordCompareType: compareTypeEnum.greaterThan,
        compareValue: null,
        boxOrProductModifyRecordUnitType: unitTypeEnum.cm,
      })
      getBoxSizeRecords();
    }

    onMounted(handleSearch);

    return {
      columns,
      data,
      recordTypeEnum,
      compareTypeEnum,
      calcUnitTypeList,
      handlePageUpdate,
      handleSearch,
      handleInitSearch,
      getUpdateSymbol,
      getUpdateCss,
      getUpdatePercent,
      gToKg,
    };
  },
};
</script>
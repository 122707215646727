<template>
  <a-modal
    v-model:visible="modalData.visible"
    width="520px"
    :title="$t('finance.manual_recharge')"
    :maskClosable="false"
    @cancel="handleClose"
    @ok="handleShowPinModal"
  >
    <a-spin :spinning="modalLoading">
      <a-form
        ref="formRef"
        :rules="rules"
        :model="modalForm"
        :label-col="labelCol"
      >
        <a-form-item :label="$t('warehouse.customer')" name="shopId">
          <CSearchShop v-model:shopId="modalForm.shopId" ref="cSearchShopRef"></CSearchShop>
        </a-form-item>
        <a-form-item :label="$t('finance.top_up_currency')" name="toCurrencyCode">
          <a-select
            v-model:value="modalForm.toCurrencyCode"
            style="width: 250px;"
            optionFilterProp="search-key"
            show-search
            @change="handleToCurrencyCodeChange"
          >
            <a-select-option
              v-for="(item, index) in currencyList"
              :key="index"
              :value="item.code"
              :search-key="item.cnName + item.enName"
              :title="getCnOrEnName(item)"
              >{{ getCnOrEnName(item) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('account.amount')" name="rechargeAmount">
          <a-input-number
            style="width: 250px;"
            ref="rechargeAmountRef"
            v-model:value="modalForm.rechargeAmount"
            :allowClear="true"
            :min="0"
            :max="1000000"
            :loading="inputLoading"
          />
        </a-form-item>
        <a-form-item :label="$t('finance.currency_of_payment')" name="fromCurrencyCode">
          <a-select
            v-model:value="modalForm.fromCurrencyCode"
            style="width: 250px;"
            optionFilterProp="search-key"
            show-search
            @change="modalForm.fromCurrencyId = null"
          >
            <a-select-option
              v-for="(item, index) in getPaymentCurrenciesList()"
              :key="index"
              :value="item.key"
              :search-key="item.cnName + item.enName"
              :title="getCnOrEnName(item)"
              >{{ getCnOrEnName(item) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('finance.payment_method')" name="fromCurrencyId">
          <a-select
            v-model:value="modalForm.fromCurrencyId"
            style="width: 250px;"
            optionFilterProp="search-key"
            show-search
            @change="handleFromCurrencyCodeChange"
          >
            <a-select-option
              v-for="(item, index) in getSelectPaymentCurrenciesList()"
              :key="index"
              :value="item.id"
              :search-key="$t($enumLangkey('rechargeType', item.payeeAccountType))"
              >{{ $t($enumLangkey('rechargeType', item.payeeAccountType)) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('finance.exchange_rate')">
          <span>{{ modalFormDetail.exchangeRate }}</span>
        </a-form-item>
        <a-form-item :label="$t('finance.payment_money')">
          <span>{{ modalFormDetail.payInfo }} {{ modalFormDetail.payMoney }}</span>
        </a-form-item>
        <a-form-item :label="$t('menu.account_info')">
          <div v-if="modalFormDetail.accountInfo">
            <div v-if="modalFormDetail.accountInfo.accountNo">{{ $t("finance.account_number") }}: {{ modalFormDetail.accountInfo.accountNo }}</div>
            <div v-if="modalFormDetail.accountInfo.accountName">{{ $t("finance.account_name") }}: {{ modalFormDetail.accountInfo.accountName }}</div>
            <div v-if="modalFormDetail.accountInfo.bank">{{ $t("finance.bank_name") }}: {{ modalFormDetail.accountInfo.bank }}</div>
            <div v-if="modalFormDetail.accountInfo.swiffCode">SWIFF CODE: {{ modalFormDetail.accountInfo.swiffCode }}</div>
          </div>
          <span v-else style="color: #CCCCCC">{{ $t('finance.please_select_currency_of_payment_first') }}</span>
        </a-form-item>
        <a-form-item :label="$t('finance.payment_serial_number')" name="payNo">
          <a-input v-model:value="modalForm.payNo" style="width: 250px;" :allowClear="true" />
        </a-form-item>
        <a-form-item :label="$t('finance.collection_serial_number')" name="payeeNo">
          <a-input v-model:value="modalForm.payeeNo" style="width: 250px;" :allowClear="true" />
        </a-form-item>
        <a-form-item :label="$t('finance.customer_remarks')">
          <a-textarea v-model:value="modalForm.remark" :placeholder="$t('finance.display_on_client')" :rows="3" />
        </a-form-item>
        <a-form-item :label="$t('finance.internal_remarks')">
          <a-textarea v-model:value="modalForm.internalRemark" :placeholder="$t('finance.internal_view_only')" :rows="3" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
  <CPinTipsModal v-model:visible="pinModalState.visible" v-model:loading="pinModalState.loading" @confirm="handleDistribution" />
</template>

<script>
import { defineComponent, nextTick, reactive, toRefs, ref, watch } from 'vue'
import {
  Button,
  Modal,
  Form,
  Select,
  Input,
  InputNumber,
  Spin,
  message,
} from "ant-design-vue";
import CSearchShop from "@/views/components/CSearchShop.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import {
  getExchangeRate,
  getPayeeAccountList,
  getReChargePayMoney,
  createReChargeLog,
} from "@/api/modules/finance/recharge";
import { refInputElemFocus } from '@/utils/domoperate';
import { debounce, getName } from '@/utils/general';

export default defineComponent({
  emits: ["update:visible", "confirm"],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    ATextarea: Input.TextArea,
    AInputNumber: InputNumber,
    AButton: Button,
    CSearchShop,
    CPinTipsModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currencyList: {
      type: Array,
      default: ([])
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const formRef = ref(null);
    const rechargeAmountRef = ref(null);
    const cSearchShopRef = ref(null);

    const state = reactive({
      modalData: {
        visible: false,
      },
      // 付款币种列表
      paymentCurrenciesList: [],
      modalLoading: false,
      modalForm: {
        fromCurrencyCode: null,
        fromCurrencyId: null,
        shopId: null,
        toCurrencyCode: null,
        rechargeAmount: "",
        payNo: null,
        payeeNo: null,
        remark: null,
        internalRemark: null,
      },
      modalFormDetail: {
        exchangeRate: "",
        payMoney: "",
        payInfo: "",
        accountInfo: null,
      },
      payeeAccountId: '',
      inputLoading: false,
      // 付款币种
      selectedFromCurrencyCode: null,
      labelCol: { style: { width: "150px" } },
    })

    const pinModalState = reactive({
      visible: false,
      loading: false,
    })

    const checkPayNo = async (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('finance.payment_serial_number')]))
      }
      value = String(value)
      if (value.length > 32) {
        return Promise.reject(vueI18n.t('common.p0_incorrect_format', [vueI18n.t('finance.payment_serial_number')]))
      }
      return Promise.resolve()
    }

    const checkPayeeNo = async (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('finance.collection_serial_number')]))
      }
      value = String(value)
      if (value.length > 32) {
        return Promise.reject(vueI18n.t('common.p0_incorrect_format', [vueI18n.t('finance.collection_serial_number')]))
      }
      return Promise.resolve()
    }

    const rules = {
      fromCurrencyCode: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('finance.currency_of_payment')]),
      }],
      fromCurrencyId: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('finance.payment_method')]),
      }],
      shopId: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.customer')])
      }],
      toCurrencyCode: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('finance.top_up_currency')])
      }],
      rechargeAmount: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('account.amount')])
      }],
      payNo: [{
        required: true,
        validator: checkPayNo
      }],
      payeeNo: [{
        required: true,
        validator: checkPayeeNo
      }],
    }

    const getCnOrEnName = (item) => {
      return getName(item, getters.language)
    }

    const getPaymentCurrenciesList = () => {
      let list = []
      state.paymentCurrenciesList.map(item => {
        if (!list.some(ite => ite.currencyId === item.currencyId)) {
          list.push(item)
        }
      })
      return list
    }

    const handleModalClear = () => {
      state.modalForm = {
        shopId: null,
        fromCurrencyCode: null,
        fromCurrencyId: null,
        toCurrencyCode: null,
        rechargeAmount: "",
        payNo: null,
        payeeNo: null,
        remark: null,
        internalRemark: null,
      }
      state.modalFormDetail = {
        exchangeRate: "",
        payMoney: "",
        payInfo: "",
        accountInfo: null,
      }
    };

    const getSelectPaymentCurrenciesList = () => {
      if (state.modalForm.fromCurrencyCode) {
        const list = state.paymentCurrenciesList.filter(item => item.key === state.modalForm.fromCurrencyCode)
        return list.filter((item, index) => {
          return list.findIndex(ite => ite.payeeAccountType === item.payeeAccountType) === index
        })
      }
      return []
    }

    const handleToCurrencyCodeChange = async () => {
      try {
        if (state.modalForm.fromCurrencyId) {
          await handleFromCurrencyCodeChange();
        }
      } catch (error) {
        
      }
    }

    const handleFromCurrencyCodeChange = async () => {
      // 付款币种改变
      try {
        let fromCurrencyInfo = state.paymentCurrenciesList.find(item => item.id === state.modalForm.fromCurrencyId)
        state.modalFormDetail.accountInfo = fromCurrencyInfo.payeeAccountInfo
        if (fromCurrencyInfo) {
            state.modalFormDetail.payInfo = `${fromCurrencyInfo.key} ${fromCurrencyInfo.symbol}`
          }
        if (state.modalForm.toCurrencyCode) {
          const currencyData = {
            fromCurrencyCode: state.modalForm.fromCurrencyCode,
            toCurrencyCode: state.modalForm.toCurrencyCode,
          };
          let { result } = await getExchangeRate(currencyData);
          if (result) {
            state.modalFormDetail.exchangeRate = result.currentExchangeRate.buyExchangeRate
          }
          if (state.modalForm.rechargeAmount) {
            await handleInputMoneyChange();
          }
        }
      } catch (error) {}
    };

    const handleInputMoneyChange = async () => {
      // 金额改变
      try {
        state.inputLoading = true
        if (
          state.modalForm.fromCurrencyId &&
          state.modalForm.toCurrencyCode &&
          state.modalForm.rechargeAmount
        ) {
          const { payNo, fromCurrencyId, ...amountData } = state.modalForm;
          let { result } = await getReChargePayMoney(amountData);
          if (result) {
            state.modalFormDetail.exchangeRate = result.exchangeRate
            state.modalFormDetail.payMoney = result.payAmount
          }
        }
        refInputElemFocus(rechargeAmountRef.value.input)
      } catch (error) {
      } finally {
        state.inputLoading = false
      }
    };
    
    const handleDistribution = async (pinCode) => {
      try {
        let fromCurrency = state.paymentCurrenciesList.find(item => item.id === state.modalForm.fromCurrencyId);
        let toCurrency = props.currencyList.find(item => item.code === state.modalForm.toCurrencyCode);
        if (fromCurrency && toCurrency) {
          const data = {
            shopId: state.modalForm.shopId,
            fromCurrencyId: fromCurrency.currencyId,
            toCurrencyId: toCurrency.id,
            rechargeAmount: state.modalForm.rechargeAmount,
            rechargeType: fromCurrency.payeeAccountType,
            payeeAccountId: state.modalForm.fromCurrencyId,
            payNo: state.modalForm.payNo,
            payeeNo: state.modalForm.payeeNo,
            remark: state.modalForm.remark,
            internalRemark: state.modalForm.internalRemark,
            pinCode,
          }
          pinModalState.loading = true;
          await createReChargeLog(data);
          message.success(vueI18n.t('common.succeed'));
          pinModalState.visible = false;
          nextTick(() => {
            emit('confirm');
            handleClose();
          })
        } else {
          message.error(vueI18n.t('finance.error_and_again'))
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        pinModalState.loading = false
      }
    };

    const handleShowPinModal = () => {
      formRef.value.validate().then(() => {
        pinModalState.visible = true;
      }).catch(() => {});
    }

    const getAccountList = () => {
      state.modalLoading = true;
      const accountData = {
        currentIndex: 1,
        maxResultCount: 99,
        skipCount: 0,
      };
      getPayeeAccountList(accountData).then(({ result }) => {
        if (result) {
          let { items } = result
          if (items.length > 0) {
            state.paymentCurrenciesList = items.map((item) => {
              return {
                id: item.id,
                currencyId: item.currencyId,
                key: item.currencyCode,
                symbol: item.currencySymbol,
                value: '',
                cnName: item.currencyCnName,
                enName: item.currencyEnName,
                payeeAccountInfo: item.payeeAccountInfo,
                payeeAccountType: item.payeeAccountType,
              };
            });
          } else {
            state.paymentCurrenciesList = []
          }
        }
      }).finally(() => {
        state.modalLoading = false;
      })
    }

    const handleClose = () => {
      emit("update:visible", false);
    }

    watch(() => state.modalForm.rechargeAmount, debounce(handleInputMoneyChange, 500))

    watch(() => props.visible, (visible) => {
      if (typeof visible === 'boolean') {
        state.modalData.visible = visible;
        if (visible) {
          nextTick(() => {
            cSearchShopRef.value.clear();
            formRef.value.resetFields()
            handleModalClear();
            getAccountList();
          })
        }
      }
    })

    return {
      ...toRefs(state),
      rules,
      formRef,
      rechargeAmountRef,
      cSearchShopRef,
      pinModalState,
      getCnOrEnName,
      getSelectPaymentCurrenciesList,
      getPaymentCurrenciesList,
      handleClose,
      handleDistribution,
      handleShowPinModal,
      handleModalClear,
      handleToCurrencyCodeChange,
      handleFromCurrencyCodeChange,
      handleInputMoneyChange,
    }
  }
})
</script>

<style scoped>

</style>
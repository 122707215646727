<template>
  <Content>
    <template #contentTitle> 产品核验记录 </template>
    <template v-slot:contentBody="wrap">
      <div>
        <h4><strong>产品信息</strong></h4>
        <a-row :gutter="16" class="product-container mb-2">
          <a-col class="image-container">
            <div class="image-wrapper">
              <img
                :src="info?.imgUrl"
                alt="Product Image"
                class="product-image"
              />
            </div>
          </a-col>
          <a-col class="details-container">
            <div class="product-details">
              <div class="product-name">
                产品名称:{{ info?.productName ?? "-" }}
              </div>
              <div class="product-no">
                产品货号: {{ info?.productNo ?? "-" }}
              </div>
              <div class="product-sku">SESKU: {{ info?.seSku ?? "-" }}</div>
            </div>
          </a-col>
        </a-row>

        <!-- list表格 -->
        <div>
          <h4><strong>核验记录</strong></h4>
          <a-table
            :columns="columns"
            :data-source="list"
            :scroll="{ x: true, y: 1000 }"
            size="small"
            :pagination="false"
            :loading="loading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #oldSize="{ record }">
              <div v-if="record.normal?.checkContainerLength">
                {{ record.normal?.checkContainerLength }}x
                {{ record.normal?.checkContainerWidth }}x
                {{ record.normal?.checkContainerHeight }}cm
              </div>
              <span v-else>-</span>
            </template>

            <template #oldWeight="{ record }">
              <div v-if="record.normal?.checkContainerWeight">
                {{ gToKg(record.normal?.checkContainerWeight) }}kg
              </div>
              <span v-else>-</span>
            </template>

            <template #oldVolume="{ record }">
              <div v-if="record.normal?.checkContainerVolume">
                {{ cmCubicToM(record.normal?.checkContainerVolume) }}m<sup
                  >3</sup
                >
              </div>
              <span v-else>-</span>
            </template>

            <template #oldImg="{ record }">
              <a-row
                :gutter="[8, 8]"
                v-if="record.normal.checkImgUrls?.length > 0"
              >
                <a-col
                  v-for="(item, index) in record.normal.checkImgUrls"
                  :key="index"
                >
                  <CFileDisplay :fileUrl="item"></CFileDisplay>
                </a-col>
              </a-row>
              <span v-else>-</span>
            </template>

            <template #newSize="{ record }">
              <div v-if="record.update?.checkContainerLength?.value">
                {{ record.update?.checkContainerLength?.value }}x{{
                  record.update?.checkContainerWidth?.value
                }}x{{ record.update?.checkContainerHeight?.value }}cm
              </div>
              <span v-else>-</span>
            </template>

            <template #newWeight="{ record }">
              <div v-if="record.update?.checkContainerWeight?.value">
                {{ gToKg(record.update?.checkContainerWeight?.value) }}kg
              </div>
              <span v-else>-</span>
            </template>

            <template #newVolume="{ record }">
              <div v-if="record.update?.checkContainerVolume?.value">
                {{
                  cmCubicToM(record.update?.checkContainerVolume?.value)
                }}m<sup>3</sup>
              </div>
              <span v-else>-</span>
            </template>
            <template #warehouseNo="{ record }">
              <div v-if="record.warehouseNo">
                {{ record.warehouseNo }}
              </div>
              <span v-else>-</span>
            </template>

            <template #newImg="{ record }">
              <a-row
                :gutter="[8, 8]"
                v-if="record.update.checkImgUrls?.length > 0"
              >
                <a-col
                  v-for="(item, index) in record.update.checkImgUrls"
                  :key="index"
                >
                  <CFileDisplay :fileUrl="item"></CFileDisplay>
                </a-col>
              </a-row>
              <span v-else>-</span>
            </template>

            <template #lastModificationTime="{ record }">
              {{ $filters.utcToCurrentTime(record.creationTime) }}
            </template>
            <template #operatorName="{ record }">
                <CEemployeeInfo :employee="record.employee"></CEemployeeInfo>
            </template>
          </a-table>
        </div>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle" class="mb-1">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import CEemployeeInfo from "../components/CEmployeeInfo.vue";
import Content from "../components/Content.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import { Row, Col, Table } from "ant-design-vue";
import { toRefs, reactive, onActivated } from "vue";
import CPager from "../components/CPager.vue";
import { getProductCheckHistory } from "../../api/modules/products/list";
import { getProducInfo } from "../../api/modules/products/edit";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { gToKg, cmCubicToM } from "../../utils/general";
import { getNameByLanguage } from "@/utils/general";
import { useStore } from "vuex";

export default {
  name: "products_check_history",
  components: {
    CEemployeeInfo,
    CFileDisplay,
    Content,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    CPager,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const columns = [
      {
        title: "旧尺寸",
        slots: {
          customRender: "oldSize",
        },
        width: 100,
      },
      {
        title: "旧重量",
        slots: {
          customRender: "oldWeight",
        },
        width: 100,
      },
      {
        title: "旧体积",
        slots: {
          customRender: "oldVolume",
        },
        width: 100,
      },
      {
        title: "旧核验图",
        slots: {
          customRender: "oldImg",
        },
        width: 150,
      },

      {
        title: "新尺寸",
        slots: {
          customRender: "newSize",
        },
        width: 100,
      },
      {
        title: "新重量",
        slots: {
          customRender: "newWeight",
        },
        width: 100,
      },
      {
        title: "新体积",
        slots: {
          customRender: "newVolume",
        },
        width: 100,
      },
      {
        title: "新核验图",
        slots: {
          customRender: "newImg",
        },
        width: 150,
      },
      {
        title: () => "仓库",
        slots: {
          customRender: "warehouseNo",
        },
        width: 100,
      },
      {
        title: () => "更新时间",
        slots: {
          customRender: "lastModificationTime",
        },
        width: 100,
      },
      {
        title: () => "操作人",
        slots: {
          customRender: "operatorName",
        },
        width: 140,
      },
    ];

    const data = reactive({
      id: null,
      loading: false,
      info: {},
      seSku: null,
      productNo: null,

      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const getStaffName = (item) => {
      if (item) {
        return getNameByLanguage(item, getters.language);
      }
    };

    const funcSearch = () => {
      if (!data.id) {
        data.list = [];
        return;
      }
      let searchData = {
        productId: data.id,
        skipCount: data.pageData.skipCount,
        maxResultCount: data.pageData.maxResultCount,
      };
      data.loading = true;
      getProductCheckHistory(searchData)
        .then((res) => {
          let { items, totalCount } = res.result;
          if (items && items.length > 0) {
            data.list = items;
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
            data.list = [];
          }
          data.loading = false;
        })
        .catch(() => {
          data.loading = false;
        });
    };

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleSearch = async (pageData) => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcSearch();
    };

    const funcGetProductInfo = () => {
      getProducInfo({ id: data.id })
        .then(({ result }) => {
          Object.assign(data.info, result);
        })
        .catch(() => {});
    };

    onActivated(async () => {
      data.id = route.params.id;
      if (data.id) {
        handleSearch();
        funcGetProductInfo();
      }
    });

    return {
      ...toRefs(data),
      columns,

      handlePage,
      handleSearch,
      gToKg,
      cmCubicToM,
      getStaffName,
    };
  },
};
</script>
<style lang="less" scoped>
.product-container {
  display: flex;
  align-items: stretch; /* 使图片和文字等高 */
}

.image-container {
  display: flex;
  align-items: center; /* 图片居中对齐 */
}

.image-wrapper {
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 100px; /* 设置图片容器的最大宽度 */
  max-height: 100px; /* 设置图片容器的最大高度 */
  padding: 8px;
  box-sizing: border-box;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.details-container {
  display: flex;
  align-items: center;
}

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 三行文本垂直均匀分布 */
  height: 100%;
  line-height: 1.2; /* 控制文本行高 */
}

.product-name,
.product-no,
.product-sku {
  margin: 0;
}
</style>

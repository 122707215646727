export default {
  common: {
    forbidden: "没有权限",
    incorrect_username_or_password: "用户名或密码错误",
    username: "用户名",
    password: "密码",
    login: "登录",
    logout: "登出",
    submit: "提交",
    re_login: "重新登陆",
    remeber_me: "记住我",
    required: "必填.",
    p0_is_required: "{0}是必填的.",
    p0_must_be_at_least_p1_characters: "{0}至少{1}个字符",
    p0_should_be_p1_to_p2_characters: "{0}应为{1}至{2}个字符",
    confirm: "确定",
    cancel: "取消",
    print: "打印",
    please_scan_or_enter_the_box_number: "请扫描或输入箱号",
    please_scan_the_operate_console_number: "请扫描操作台编号",
    target_storage_location_number: "目标库位编号",
    no_data: "没有相关数据",
    product_packing_imag:"产品包装图",
    must_be_an_integer: "必须为整数",
    p0_must_be_an_integer: "{0}必须为整数",
    delete_success: "删除成功",

    reset_succeed: "重置成功",
    scan_succeed: "扫描成功",
    close: "关闭",
    save: "保存",
    days:"天",
    hour:"小时",
    minute:"分",

    succeed: "成功",
    succeed_delete: "成功删除",
    are_you_sure: "您确定吗?",
    are_you_sure_you_want_to_delete_this_item: "确实需要删除该项吗?",
    confirm_move_task: "您这次添加的移库任务总数为:",
    successfully_canceled: "取消成功",
    back_to_previous_page: "返回上一页",
    backtrack: "返回",
    details: "详情",
    operate: "操作",


    length: "长",
    width: "宽",
    height: "高",
    volume: "体积",
    weight: "重量",
    max_length_side: "最长边",
    max_width_side: "次长边",
    max_height_side: "最短边",

    inbound_warehouse: "入库",
    confirm_scan: "确认扫描",
    confirm_delivery: "确认交付",
    delivered: "已交付",
    add_img: "添加图片",
    confirm_outbound_warehouse: "确认出库",
    print_success: "打印成功",

    search: "搜索",

    print_the_list_of_executable_tasks: "打印可执行任务表",
    product_label_manage: "产品标签管理",
    upload_img: "上传图片",
    are_you_sure_delete: "你确定要删除吗?",

    enabled: "启用",
    disabled: "禁用",
    edit: "编辑",
    normal: "正常",
    exception: "异常",
    create: "创建",
    remove: "移除",
    delete: "删除",
    print_position_coding: "打印位置编码",

    binding_location: "绑定库位",

    addition: "添加",
    added: "已添加",

    complete_the_operation: "完成操作",

    remark: "备注",

    select: "选择",

    "403_cue_words": "对不起，您没有权限访问此页面。",
    "404_cue_words": "对不起，您访问的页面不存在。",
    "500_cue_words": "对不起，服务器出问题了。",
    back_home: "返回主页",

    undelivered_warehouse: "未送达仓库",
    warehouse_lose: "仓库丢失",

    Please_input_number_or_storage_plan_number: "请输入箱号或入库计划编号",

    close_current: "关闭当前",
    close_others: "关闭其他",
    close_all: "关闭所有",
    total: "总计",
    item: "条",
    upload: "上传",
    upload_file: "上传文件",
    query: "查询",
    receive: "领取",
    p0_is_not_complete: "{0}未填写完整.",
    operate_console_no_inexistence: "扫描的操作台不存在!",
    invalid_operation: "无效操作",
    operate_no_inexistence: "扫描的编号不存在!",
    look_general_surveying_data: "查看通用测量数据",
    plan_not_finished_scanning: "计划未扫描完",
    p0_days_ago: "{0}天前",
    p0_hours_ago: "{0}小时前",
    p0_minutes_ago: "{0}分钟前",
    data_not_found: "数据未找到",
    p0_incorrect_format: "{0}格式不正确",
    country: "国家",
    destination_country: "目的国家",
    province: "省/州",
    city: "市",
    county: "区县",
    address_line: "详细地址",
    post_code: "邮编",
    please_select: "请选择",
    hours: "小时",

    take_photo: "拍照",
    rephotograph: "重拍",
    local_upload: "本地上传",
    photo_upload: "拍照上传",
    failed: "失败",
    p0_is_wrong: "{0}错误",
    add: "新增",
    serial_number : "序号",
    product_statistics: "产品统计",
    product_no: "货号",
    number_of_boxes: "箱数",
    the_total_number_of_boxes: "总箱数",
    total_product: "产品总数",

    export: "导出",
    export_product_in_the_warehouse_time:"导出产品库龄",

    yes: "是",
    no: "否",
    version_tips: "当前为非正式版本",
    match_or_not: "是否匹配",
    shop_mark:"标识",
    count: "数量",
    mixed: "混装",
    single: "单一",
    related_products:"相关产品",
    user_defined_box_no: "自定义箱号",

    signature: "签名签收",
    please_upload_file_with_size_less_than_50MB:"请上传大小不超过50MB的文件",
    p0_products: "{0}个产品",

    export_storage_fee_details:"导出存储费明细",
    are_you_sure_cancel: "你确定要取消吗?",
    product_name_length_limit:"请输入最大长度小于120位的产品名称",
    selected: "已选择",
    p0_please_enter_at_least_p1_digits: "{0}请至少输入{1}位",
    not_support:"不支持",
    support:"支持",
    more: "更多",
    batch_print: "批量打印",
    print_box_label: "打印箱标",
    se_outer_box_label: "SE外箱标签",
    good_morning: "上午好",
    good_afternoon: "下午好",
    good_evening: "晚上好",
    welcome: "欢迎您",
    necessary: "需要",
    unnecessary: "不需要",
    please_upload_p0_pictures: "请上传{0}张图片",
    sort: "排序",
    sort_warehouse_tips: "数字越大展示越靠前",
    enable_status: "启用状态",
  },
};

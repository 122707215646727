<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.customer_company_details") }}
    </template>
    <template v-slot:contentBody>
      <!-- 基本信息 -->
      <a-spin :spinning="loading">
        <a-row :gutter="[0, 8]">
          <a-col :span="24">
            <strong>{{ $t("account.essential_information") }}</strong>
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                NO: {{ pageInfo.shopNo }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.responsible_for_account") }}:
                {{ pageInfo.managerUserName }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.company_name") }}: {{ pageInfo.shopName }}
                <a-popover>
                  <template #content v-if="pageInfo.businessLicense">
                    <div>
                      <CFileDisplay
                        :fileUrl="pageInfo.businessLicense"
                        :disImgProcess="true"
                      ></CFileDisplay>
                    </div>
                  </template>
                  <a-button class="ml-2" size="small">{{
                    $t("account.view_registration_files")
                  }}</a-button>
                </a-popover>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.register_id") }}:
                {{ pageInfo.businessLicenseCode }}
                <a-button
                  type="link"
                  size="small"
                  @click="showUpdateLevelModal"
                  >{{ $t("common.edit") }}</a-button
                >
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.company_id") }}: {{ pageInfo.shopMark }}
                <a-tag color="blue" class="ml-2">
                  <div style="display: flex; align-items: center;">
                    <span class="pr-1">{{ $t('account.agent') }}</span>
                    <a-switch size="small" v-model:checked="pageInfo.isAgent" @change="showPinCodeModal(null, 4)"></a-switch>
                  </div>
                </a-tag>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.registration_date") }}:
                {{ $filters.utcToCurrentTime(pageInfo.creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.last_login_int") }}:
                {{ $filters.utcToCurrentTime(pageInfo.lastLoginTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("common.country") }}:
                {{
                  getLanguageName({
                    cnName: pageInfo.countryCnName,
                    enName: pageInfo.countryEnName,
                  })
                }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("menu.customer_level_list") }}:
                {{ pageInfo.shopLevelName }}
                <a
                  src="javascript:"
                  @click="
                    handleShowEditLevelModal(pageInfo.id, pageInfo.shopLevelId)
                  "
                >
                  <EditOutlined />
                </a>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("account.approval_time") }}:
                {{ $filters.utcToCurrentTime(pageInfo.auditPassTime) }}
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row
              :gutter="[0, 8]"
              style="height: 100%"
              type="flex"
              align="middle"
            >
              <a-col :span="24">
                <div style="text-align: center">
                  <a-typography-text strong>{{
                    $t("account.account_status")
                  }}</a-typography-text>
                </div>
                <div style="text-align: center">
                  <a-typography-text
                    type="success"
                    v-if="
                      pageInfo.shopAccountStatus ==
                      shopAccountStatusEnum.inTheActivity
                    "
                    >{{
                      $t(
                        $enumLangkey(
                          "shopAccountStatus",
                          pageInfo.shopAccountStatus
                        )
                      )
                    }}</a-typography-text
                  >
                  <a-typography-text type="secondary" v-else>{{
                    $t(
                      $enumLangkey(
                        "shopAccountStatus",
                        pageInfo.shopAccountStatus
                      )
                    )
                  }}</a-typography-text>
                </div>
              </a-col>
              <a-col :span="24">
                <div style="text-align: center">
                  <a-button
                    :loading="freezeLoading"
                    @click="handleOperation"
                    type="ghost"
                  >
                    <span
                      v-if="
                        pageInfo.shopAccountStatus ==
                        shopAccountStatusEnum.inTheActivity
                      "
                      >{{ $t("account.freeze") }}</span
                    >
                    <span
                      v-else-if="
                        pageInfo.shopAccountStatus ==
                        shopAccountStatusEnum.haveBeenFrozen
                      "
                      >{{ $t("account.unfreeze") }}</span
                    >
                  </a-button>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("account.account_principal") }}:
            {{ pageInfo.managerRealName }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("account.mobile_phone") }}:
            {{ pageInfo.managerUserPhoneNumber }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("account.secure_mailbox") }}:
            <span v-if="pageInfo.managerUserEmail">{{
              pageInfo.managerUserEmail
            }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row :gutter="[8, 8]">
              <a-col> {{ $t("account.contract_status") }}: </a-col>
              <a-col>
                <span v-if="pageInfo.hasContract" class="text-success">
                  <CheckCircleOutlined /> {{ $t("account.signed") }}</span
                >
                <span v-else class="text-grey">
                  <CloseCircleOutlined /> {{ $t("account.unsigned") }}</span
                >
                <div v-if="pageInfo.contractPath">
                  <CFileDisplay :fileUrl="pageInfo.contractPath"></CFileDisplay>
                </div>
              </a-col>
              <a-col>
                <a src="javascript:" @click="handleShowUpdateContractModal">
                  <EditOutlined />
                </a>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24">
            <strong>{{ $t("menu.partner") }}</strong>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("finance.partner_name") }}: {{ pageInfo.partnerName || "-" }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            NO: {{ pageInfo.partnerNo || "-" }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("finance.partner_commission") }}:
            <a-space v-if="pageInfo.partnerId">
              <span v-if="pageInfo.partnerShopRlIsActive">{{
                $t("finance.normal_commission_distribution")
              }}</span>
              <span v-else>{{
                $t("finance.termination_of_commission_distribution")
              }}</span>
              <a-switch
                size="small"
                v-model:checked="pageInfo.partnerShopRlIsActive"
                @change="(e) => handleActiveChange(e, pageInfo)"
              />
            </a-space>
            <span v-else>-</span>
          </a-col>
        </a-row>
      </a-spin>

      <!-- 账户币种余额 -->
      <div class="mt-3">
        <a-table
          :columns="balanceColumns"
          :data-source="balanceList"
          :scroll="{ x: true }"
          :pagination="false"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          class="product-table"
        >
          <template #balanceOfSymbols="{ record }">
            {{ record.currencyCode }} {{ record.currencySymbol }}
          </template>
          <template #balance="{ record }">
            <span :class="record.amount >= 0 ? 'text-success' : 'text-error'">
              {{ $filters.amountToFixed2(record.amount) }}
            </span>
          </template>

          <template #overdraftLimitOfSymbols="{ record }">
            {{ record.currencyCode }} {{ record.currencySymbol }}
          </template>
          <template #overdraftLimit="{ record }">
            <a-row>
              <a-col :span="5">
                <span
                  :class="
                    record.overdraftFacility >= 0
                      ? 'text-success'
                      : 'text-error'
                  "
                  >{{ $filters.amountToFixed2(record.overdraftFacility) }}</span
                >
              </a-col>
              <a-col>
                <a-row>
                  <a-col>
                    <div v-if="!record.edit">
                      <a-button
                        size="small"
                        type="primary"
                        @click="
                          () => {
                            record.limit = record.overdraftFacility;
                            record.edit = true;
                          }
                        "
                        >{{ $t("account.limit_adjustment") }}
                      </a-button>
                    </div>
                    <a-input-group
                      v-else
                      style="width: 230px"
                      size="small"
                      compact
                    >
                      <a-input-number
                        size="small"
                        style="width: 40%"
                        class="input-group-mid-number"
                        :min="0"
                        v-model:value="record.limit"
                        :loading="record.saving"
                      ></a-input-number>
                      <a-button
                        size="small"
                        style="width: 30%"
                        :loading="record.saving"
                        @click="handleUpdateLimit(record)"
                      >
                        <small>{{ $t("common.save") }}</small>
                      </a-button>
                      <a-button
                        size="small"
                        style="width: 30%"
                        @click="
                          () => {
                            record.edit = false;
                          }
                        "
                      >
                        <small>{{ $t("common.cancel") }}</small>
                      </a-button>
                    </a-input-group>
                  </a-col>
                  <a-col>
                    <a-button
                      size="small"
                      class="ml-3"
                      type="primary"
                      @click="showRecordsModal(record)"
                      >{{ $t("account.adjusting_records") }}</a-button
                    >
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </template>

          <template #freezeOverdraftFacility="{ record }">
            <a-row type="flex">
              <a-col style="width: 70px"
                ><span
                  >{{ record.currencyCode }} {{ record.currencySymbol }}</span
                ></a-col
              >
              <a-col flex="2">
                <span
                  v-if="record.freezeOverdraftFacility > 0"
                  class="text-error"
                  >{{
                    $filters.amountToFixed2(record.freezeOverdraftFacility)
                  }}</span
                >
                <span v-else class="text-success">{{
                  $filters.amountToFixed2(record.freezeOverdraftFacility)
                }}</span>
              </a-col>
            </a-row>
          </template>
          <template #paymentDays="{ record }">
            {{ record.paymentDays }}{{ $t("common.days") }}
            <a-button type="link" @click="handelShowAccountModal(record)">
              <EditOutlined />
            </a-button>
          </template>

          <template #rxpireDate="{ record }">
            {{ $filters.utcToCurrentTime(record.overdraftDueDateTime) }}
          </template>
          <template #overdraftStatus="{ record }">
            <span
              :class="
                record.overdraftStatus == overdraftStatusEnum.normal
                  ? 'text-success'
                  : 'text-error'
              "
            >
              {{ $t($enumLangkey("overdraftStatus", record.overdraftStatus)) }}
            </span>
            <span v-if="record.lastMonthOwingMoney < 0" class="text-error">
              ({{ $t("account.amount") }}: {{ record.currencySymbol
              }}{{ $filters.amountToFixed2(record.lastMonthOwingMoney * -1) }})
            </span>
          </template>
          <template #operation="{ record }">
            <a-button
              size="small"
              :disabled="record.amount <= 0"
              @click="handleShowRefund(record)"
              >{{ $t("account.refund") }}</a-button
            >
            <a-button
              class="ml-2"
              size="small"
              @click="handleShowOfflineServices(record)"
              >{{ $t("account.offline_services") }}</a-button
            >
          </template>
        </a-table>
      </div>

      <!-- 账号安全 -->
      <div class="mt-3">
        <strong>{{ $t("account.account_security") }}</strong>
        <a-table
          class="mt-2"
          :columns="safetyColumns"
          :data-source="safetyList"
          :scroll="{ x: true }"
          :pagination="false"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #loginTime="{ record }">
            {{ $filters.utcToCurrentTime(record.loginUtcTime) }}
          </template>
          <template #userName="{ record }">
            {{ record.userName }}
            <span v-if="record.userNo">({{ record.userNo }})</span>
          </template>
        </a-table>
        <a-row type="flex" justify="center" align="middle" class="mt-4">
          <a-col>
            <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
          </a-col>
        </a-row>
      </div>

      <a-row type="flex" justify="end" class="mb-5 mt-3">
        <a-col>
          <div style="width: 400px">
            <a-row type="flex" justify="center" :gutter="[24, 12]">
              <a-col class="">
                <a-button @click="handleBack">{{
                  $t("common.backtrack")
                }}</a-button>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>

      <!-- 额度调整记录 -->
      <a-modal
        v-model:visible="recordsModal.visible"
        :title="
          $t('account.quota_adjustment_record') +
          '-' +
          recordsModal.titleSymbols
        "
        :confirmLoading="recordsModal.loading"
        :footer="false"
        width="800px"
      >
        <a-table
          :columns="recordsColumns"
          :data-source="recordsModal.list"
          :scroll="{ x: true, y: 600 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :maskClosable="false"
          :pagination="false"
          :loading="recordsModal.loading"
        >
          <template #creationTime="{ record }">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
          </template>
          <template #newOverdraftFacility="{ record }">
            {{ record.currencySymbol }}
            {{ $filters.amountToFixed2(record.newOverdraftFacility) }}
          </template>
          <template #shaftType="{ record }">
            {{ $t($enumLangkey("shaftType", record.shaftType)) }}
          </template>
          <template #operator="{ record }">
            <span v-if="record.employee?.employeeNo"
              >{{ getStaffName(record.employee) }}({{
                record.employee?.employeeNo
              }})</span
            >
            <span v-else-if="record.operatorName">{{
              record.operatorName
            }}</span>
            <span v-else>-</span>
          </template>
        </a-table>
        <a-row type="flex" justify="space-around" align="middle" class="mt-3">
          <a-col>
            <CPager
              @handlePage="handlePageRecords"
              :showLessItems="true"
              :pageSizeOptions="['5', '10', '30']"
              :page-data="recordsModal.pageData"
            ></CPager>
          </a-col>
        </a-row>
      </a-modal>

      <!-- 账户调整 -->
      <a-modal
        v-model:visible="accountModal.visible"
        :title="$t('account.account_adjustment')"
        :confirmLoading="accountModal.loading"
        :footer="false"
      >
        <a-form
          layout="horizontal"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
        >
          <a-form-item :label="$t('account.account_type')">
            {{ accountModal.currencyCode }} {{ accountModal.currencySymbol }}
          </a-form-item>
          <a-form-item :label="$t('finance.accounting_period')">
            <a-input-number
              :min="0"
              :precision="0"
              v-model:value="accountModal.paymentDays"
            />
            {{ $t("common.days") }}
          </a-form-item>
        </a-form>
        <a-row type="flex" justify="center" class="mt-3">
          <a-col>
            <a-button
              class="mr-3"
              const
              accountModal="reactive({"
              :loading="accountModal.loading"
              @click="accountModal.visible = false"
              >{{ $t("common.cancel") }}</a-button
            >
          </a-col>
          <a-col>
            <a-button
              type="primary"
              :loading="accountModal.loading"
              @click="handelSaveAccountModal"
              >{{ $t("common.confirm") }}</a-button
            >
          </a-col>
        </a-row>
      </a-modal>

      <UpdateContract
        v-model:visible="updateContractModal.visible"
        :shopId="updateContractModal.shopId"
        :hasContract="updateContractModal.hasContract"
        :contractPath="updateContractModal.contractPath"
        @ok="funcGetInfo(pageInfo.id)"
      ></UpdateContract>

      <UpdateLevelModal
        ref="updateLevelModalRef"
        @refresh="funcGetInfo(pageInfo.id)"
      />
      <RefundModal ref="refundModalRef" @confirm="handleRefundBefore" />
      <OfflineServicesModal
        v-model:visible="offlineServicesModalState.visible"
        :record="offlineServicesModalState.record"
        @confirm="(data) => showPinCodeModal(data, 3)"
      />
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @handleCancel="handleCancelPinModal"
        @confirm="handlePinConfirm"
      />

      <UpdateInfoModal
        ref="updateInfoModalRef"
        @confirm="(data) => showPinCodeModal(data, 1)"
      />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import {
  Row,
  Col,
  Modal,
  message,
  Image,
  Form,
  Switch,
  Space,
  Table,
  Button,
  Input,
  Popover,
  InputNumber,
  Typography,
  Spin,
  Tag,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CImage from "@/views/components/CImage.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import UpdateLevelModal from "./components/UpdateLevelModal.vue";
import UpdateInfoModal from "./components/UpdateInfoModal.vue";
import RefundModal from "./components/RefundModal.vue";
import OfflineServicesModal from "./components/OfflineServicesModal.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import _ from "lodash";
import { getName, getNameByLanguage } from "@/utils/general";
import { useRoute, useRouter } from "vue-router";
import { useTab } from "@/hooks/tabs/index";
import {
  updatePartnerShopRlActive,
  updateAccountStatus,
} from "@/api/modules/customer/company/list";
import {
  getInfo,
  getSafetyList,
  getBalanceList,
  getLimitRecords,
  updateLimit,
  rechargeRefund,
  updateBalance,
  updateBusinessInfo,
  offlineService,
  updateIsAgent,
} from "@/api/modules/customer/company/details";
import {
  overdraftStatus as overdraftStatusEnum,
  shopAccountStatus as shopAccountStatusEnum,
  auditStatus as auditStatusEnum,
} from "@/enum/enum.json";
import UpdateContract from "./components/UpdateContract";

export default {
  name: "customer_company_details",
  components: {
    UpdateContract,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    AModal: Modal,
    ATextarea: Input.TextArea,
    AInputNumber: InputNumber,
    APopover: Popover,
    AImage: Image,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    ATypographyText: Typography.Text,
    ASpace: Space,
    ASpin: Spin,
    ATag: Tag,
    Content,
    CImage,
    CPager,
    CFileDisplay,
    CPinTipsModal,
    UpdateLevelModal,
    UpdateInfoModal,
    RefundModal,
    OfflineServicesModal,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const updateLevelModalRef = ref();
    const refundModalRef = ref();
    const updateInfoModalRef = ref();
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const balanceColumns = [
      {
        title: () => vueI18n.t("account.account_balance"),
        dataIndex: "balanceOfSymbols",
        width: 80,
        slots: {
          customRender: "balanceOfSymbols",
        },
      },
      {
        title: "",
        dataIndex: "balance",
        width: 100,
        slots: {
          customRender: "balance",
        },
      },
      {
        title: () => vueI18n.t("account.overdraft_limit"),
        dataIndex: "overdraftLimitOfSymbols",
        width: 80,
        slots: {
          customRender: "overdraftLimitOfSymbols",
        },
      },
      {
        title: "",
        dataIndex: "overdraftLimit",
        width: 300,
        slots: {
          customRender: "overdraftLimit",
        },
      },
      {
        title: () => vueI18n.t("account.freeze"),
        width: 150,
        slots: {
          customRender: "freezeOverdraftFacility",
        },
      },
      {
        title: () => vueI18n.t("finance.accounting_period"),
        width: 100,
        slots: {
          customRender: "paymentDays",
        },
      },
      {
        title: () => vueI18n.t("account.rxpire_date"),
        dataIndex: "rxpireDate",
        width: 150,
        slots: {
          customRender: "rxpireDate",
        },
      },
      {
        title: () => vueI18n.t("account.overdraft_status"),
        dataIndex: "overdraftStatus",
        width: 200,
        slots: {
          customRender: "overdraftStatus",
        },
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 150,
        slots: {
          customRender: "operation",
        },
      },
    ];

    const safetyColumns = [
      {
        title: () => vueI18n.t("common.serial_number"),
        dataIndex: "",
        width: 150,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: () => vueI18n.t("account.log_in_time"),
        dataIndex: "loginTime",
        slots: {
          customRender: "loginTime",
        },
        width: 300,
      },
      {
        title: () => vueI18n.t("account.login_account"),
        dataIndex: "userName",
        slots: {
          customRender: "userName",
        },
        width: 300,
      },
      {
        title: "IP",
        dataIndex: "loginIp",
      },
    ];

    const recordsColumns = [
      {
        title: () => vueI18n.t("account.time"),
        dataIndex: "creationTime",
        slots: {
          customRender: "creationTime",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("account.amount"),
        dataIndex: "newOverdraftFacility",
        slots: {
          customRender: "newOverdraftFacility",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("account.adjustment_type"),
        dataIndex: "shaftType",
        slots: {
          customRender: "shaftType",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("account.operator"),
        dataIndex: "operator",
        slots: {
          customRender: "operator",
        },
        width: 200,
      },
    ];

    const state = reactive({
      shopId: null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      loading: false,
      freezeLoading: false,
      pageInfo: {
        id: null, //shopId
        shopNo: null,
        managerUserName: null, //账号
        shopName: null,
        businessLicenseCode: null,
        businessLicense: null,
        countryCnName: null,
        countryEnName: null,
        managerUserNickName: null,
        managerUserPhoneNumber: null,
        managerUserEmail: null,
        shopLevelName: null,
        shopAccountStatus: null,
        creationTime: null,
        auditPassTime: null,
        partnerId: null,
        partnerName: null,
        partnerNo: null,
        partnerShopRlId: null,
        partnerShopRlIsActive: null,
        isAgent: null,
      },
      balanceList: [],
      records: [],
      safetyList: [],
      pinModalState: {
        visible: false,
        loading: false,
        // 1: 注册号编辑; 2: 退款; 3: 线下服务
        type: null,
        dataTemp: null,
      },
    });

    const offlineServicesModalState = reactive({
      visible: false,
      record: null,
    });

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcGetSafetyList();
    };

    const handlePageRecords = async (pageData) => {
      recordsModal.pageData.skipCount = pageData.skipCount;
      recordsModal.pageData.maxResultCount = pageData.maxResultCount;
      handleGetLimitRecords();
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const funcGetInfo = (id) => {
      state.loading = true;
      getInfo(id)
        .then(({ result }) => {
          if (result) {
            state.pageInfo = result;
          }
        })
        .catch(() => {})
        .finally(() => {
          state.loading = false;
        });
    };

    const funcGetBalanceList = (id) => {
      getBalanceList(id)
        .then(({ result }) => {
          if (result) {
            state.balanceList = result;
          }
        })
        .catch({});
    };

    const handleOperation = () => {
      state.freezeLoading = true;
      let isActive =
        state.pageInfo.shopAccountStatus === shopAccountStatusEnum.inTheActivity
          ? false
          : true;
      updateAccountStatus(route.params.id, isActive)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          funcGetInfo(state.shopId);
        })
        .catch(() => {})
        .finally(() => {
          state.freezeLoading = false;
        });
    };

    const handleActiveChange = _.debounce(() => {
      const params = {
        id: state.pageInfo.partnerShopRlId,
        isActive: state.pageInfo.partnerShopRlIsActive,
      };
      state.loading = true;
      updatePartnerShopRlActive(params)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .catch(() => {
          state.pageInfo.partnerShopRlIsActive = !params.isActive;
        })
        .finally(() => {
          state.loading = false;
        });
    }, 300);

    const funcGetSafetyList = () => {
      let params = {
        shopId: state.pageInfo.id,
        ...state.pageData,
      };
      getSafetyList(params)
        .then(({ result }) => {
          let { pageData, totalCount } = result;
          if (totalCount > 0) {
            state.safetyList = pageData;
            state.pageData.totalCount = parseInt(totalCount);
          } else {
            state.pageData.totalCount = 0;
            state.safetyList = [];
          }
        })
        .catch((error) => {});
    };

    let createRecordsModal = () => {
      return {
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 5,
        },
        list: [],
        id: null,
      };
    };
    const recordsModal = reactive({
      visible: false,
      loading: false,
      titleSymbols: null,
    });

    const showRecordsModal = (record) => {
      recordsModal.visible = true;
      recordsModal.titleSymbols = record.currencyCode;
      Object.assign(recordsModal, createRecordsModal());
      recordsModal.id = record.id;
      handleGetLimitRecords();
    };

    const handleGetLimitRecords = () => {
      let params = {
        balanceId: recordsModal.id,
        ...recordsModal.pageData,
      };
      recordsModal.loading = true;
      getLimitRecords(params)
        .then(({ result }) => {
          recordsModal.loading = false;
          let { items, totalCount } = result;
          if (totalCount > 0) {
            recordsModal.list = items;
            recordsModal.pageData.totalCount = parseInt(totalCount);
          } else {
            recordsModal.list = [];
            recordsModal.pageData.totalCount = 0;
          }
        })
        .catch(() => {
          recordsModal.loading = false;
        });
    };

    const handleUpdateLimit = (record) => {
      if (record.limit != 0 && !record.limit) {
        message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("account.amount")])
        );
        return false;
      }

      if (record.limit == record.overdraftFacility) {
        record.edit = false;
        return false;
      }

      updateLimit(record.id, record.limit)
        .then(({ result }) => {
          if (result) {
            record.overdraftFacility = record.limit;
            record.edit = false;
          }
        })
        .catch(() => {});
    };

    const getStaffName = (item) => {
      if (item) {
        return getNameByLanguage(item, getters.language);
      }
    };

    const handleUpdateIsAgent = (pinCode) => {
      state.pinModalState.loading = true;
      const data = {
        id: state.pageInfo.id,
        isAgent: state.pageInfo.isAgent,
        pinCode,
      }
      updateIsAgent(data)
        .then(() => {
          state.pinModalState.visible = false;
          message.success(vueI18n.t("common.succeed"));
        })
        .catch(() => {})
        .finally(() => {
          state.pinModalState.loading = false;
        });
    }

    const handleShowEditLevelModal = (id, shopLevelId) => {
      updateLevelModalRef.value.init(id, shopLevelId);
    };

    const handleShowOfflineServices = (record) => {
      offlineServicesModalState.record = record;
      offlineServicesModalState.visible = true;
    };

    const handleShowRefund = (record) => {
      state.refundData = null;
      refundModalRef.value.init(
        record.id,
        record.currencySymbol,
        record.amount,
        record.freezeOverdraftFacility
      );
    };

    const handleRefundBefore = (result) => {
      let { data, status } = result;
      if (status) {
        Modal.confirm({
          title: vueI18n.t("warehouse.operation_tips"),
          content: vueI18n.t("finance.refund_balance_insufficient_prompt"),
          okText: vueI18n.t("common.confirm"),
          cancelText: vueI18n.t("common.cancel"),
          onOk: () => {
            showPinCodeModal(data, 2);
          },
        });
      } else {
        showPinCodeModal(data, 2);
      }
    };

    const handleRefund = (pinCode) => {
      const data = Object.assign({}, state.pinModalState.dataTemp, { pinCode });
      state.pinModalState.loading = true;
      rechargeRefund(data)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          state.pinModalState.visible = false;
          refundModalRef.value.close();
          funcGetBalanceList(route.params.id);
        })
        .finally(() => {
          state.pinModalState.loading = false;
        });
    };

    const getAccountModal = () => {
      return {
        balanceId: null,
        paymentDays: null,
        isLogisticsArrivePay: null,
      };
    };

    const accountModal = reactive({
      visible: false,
      loading: false,
    });

    const handelShowAccountModal = (record) => {
      accountModal.visible = true;
      Object.assign(accountModal, getAccountModal());
      Object.assign(accountModal, record);
      accountModal.balanceId = record.id;
    };

    const handelSaveAccountModal = () => {
      accountModal.loading = true;
      updateBalance({ ...accountModal })
        .then((result) => {
          funcGetBalanceList(state.shopId);
          accountModal.loading = false;
          accountModal.visible = false;
        })
        .catch(() => {
          accountModal.loading = false;
        });
    };

    const showUpdateLevelModal = () => {
      updateInfoModalRef.value.open(state.pageInfo);
    };

    const showPinCodeModal = (data, type) => {
      state.pinModalState.visible = true;
      state.pinModalState.type = type;
      state.pinModalState.dataTemp = data;
    };

    const handleUpdateBusinessInfo = (pinCode) => {
      const data = Object.assign({}, state.pinModalState.dataTemp, { pinCode });
      state.pinModalState.loading = true;
      updateBusinessInfo(data)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          state.pinModalState.visible = false;
          funcGetInfo(state.shopId);
        })
        .catch(() => {})
        .finally(() => {
          state.pinModalState.loading = false;
        });
    };

    const handleOfflineServices = (pinCode) => {
      const data = Object.assign({}, state.pinModalState.dataTemp, { pinCode });
      state.pinModalState.loading = true;
      offlineService(data)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          state.pinModalState.visible = false;
          offlineServicesModalState.visible = false;
          funcGetBalanceList(state.shopId);
        })
        .catch(() => {})
        .finally(() => {
          state.pinModalState.loading = false;
        });
    };

    const handlePinConfirm = (pinCode) => {
      switch (state.pinModalState.type) {
        case 1:
          // 编辑
          handleUpdateBusinessInfo(pinCode);
          break;
        case 2:
          // 退款
          handleRefund(pinCode);
          break;
        case 3:
          // 线下服务
          handleOfflineServices(pinCode);
          break;
        case 4:
          // 修改代理
          handleUpdateIsAgent(pinCode);
          break;
        default:
          break;
      }
    };

    const handleCancelPinModal = () => {
      switch (state.pinModalState.type) {
        case 4:
          state.pageInfo.isAgent = !state.pageInfo.isAgent;
          break;
        default:
          break;
      }
    }

    const handleBack = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "customer_company_list" });
    };

    //------------------------更新合同 start---------------

    const updateContractModal = reactive({
      visible: false,
      shopId: null,
      hasContract: null,
      contractPath: null,
    });

    const handleShowUpdateContractModal = () => {
      updateContractModal.shopId = state.pageInfo.id;
      updateContractModal.hasContract = state.pageInfo.hasContract;
      updateContractModal.contractPath = state.pageInfo.contractPath;
      updateContractModal.visible = true;
    };

    //------------------------更新合同 end---------------

    onMounted(async () => {
      state.shopId = route.params.id;
      if (state.shopId) {
        state.pageInfo.id = state.shopId;
        funcGetInfo(state.shopId);
        funcGetBalanceList(state.shopId);
        funcGetSafetyList();
      }
    });

    return {
      ...toRefs(state),
      offlineServicesModalState,
      updateLevelModalRef,
      refundModalRef,
      updateInfoModalRef,
      safetyColumns,
      balanceColumns,
      shopAccountStatusEnum,
      auditStatusEnum,
      overdraftStatusEnum,
      getLanguageName,
      handlePage,
      handlePageRecords,
      showRecordsModal,
      recordsModal,
      recordsColumns,
      handleUpdateLimit,
      getStaffName,
      handleUpdateIsAgent,
      handleShowEditLevelModal,
      funcGetInfo,
      funcGetBalanceList,
      handleShowRefund,
      handleShowOfflineServices,
      handleRefundBefore,

      accountModal,
      handelShowAccountModal,
      handelSaveAccountModal,
      showUpdateLevelModal,
      showPinCodeModal,
      handleUpdateBusinessInfo,
      handleActiveChange,
      handleOperation,
      handlePinConfirm,
      handleCancelPinModal,
      handleBack,

      updateContractModal,
      handleShowUpdateContractModal,
    };
  },
};
</script>

<style lang="less" scoped>
.table-list-img-common {
  width: 54px;
  height: 54px;

  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  .ant-image img {
    max-height: 45px !important;
  }
}
</style>

import client from "../../client"
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 列表
  getPlanPaged: "/api/WarehouseService/ChannelReturnPlanConsole/GetPlanPaged",
  // 查看轨迹
  getTrack: "/api/WarehouseService/ChannelReturnPlanTrack/GetTrack",
  // 列表
  getDetailById: "/api/WarehouseService/ChannelReturnPlanConsole/GetDetailById",
  // 列表
  getOperateReceive: "/api/WarehouseService/ChannelReturnPlanConsole/GetOperateReceive",
  // 导出列表
  exportChannelReturnPlanConsole: "/api/WarehouseService/ChannelReturnPlanConsole/Export",
  // 取消
  refundChannelReturnPlanConsole: "/api/WarehouseService/ChannelReturnPlanConsole/Refund",
}

export const getPlanPaged = (data) => {
  return client.request({
    url: Api.getPlanPaged,
    data,
    method: 'POST'
  });
}

export const getTrack = (data) => {
  return client.request({
    url: Api.getTrack,
    data,
    method: "POST",
  });
};

export const getDetailById = (data) => {
  return client.request({
    url: Api.getDetailById,
    data,
    method: 'POST'
  });
}

export const getOperateReceive = (data) => {
  return client.request({
    url: Api.getOperateReceive,
    data,
    method: 'POST'
  });
}

export const exportChannelReturnPlanConsole = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportChannelReturnPlanConsole);
  return url;
}

export const refundChannelReturnPlanConsole = (data) => {
  return client.request({
    url: Api.refundChannelReturnPlanConsole,
    data,
    method: 'POST'
  });
}
<template>
  <Content>
    <template #contentTitle>{{ $t("menu.products_historical_version") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="6" :xxl="6">
          <a-card :bodyStyle="{ height: (wrap.contentHeight - 2) + 'px', display: 'flex', flexDirection: 'column' }" size="small">
            <div>{{ shopInfo.shopName || '-' }}</div>
            <div>NO: {{ shopInfo.shopNo || '-' }}</div>
            <div>{{ $t('common.shop_mark') }}: {{ shopInfo.shopMark || '-' }}</div>
            <a-divider />
            <div style="flex: 1; overflow-y: auto;">
              <a-list :data-source="leftData.list" :loading="leftData.loading" :bordered="false" size="small">
                <template #renderItem="{ item, index }">
                  <a-list-item>
                    <a-row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style="width: 100%; cursor: pointer;"
                      :class="isSelectedVersion(item.id) ? 'text-primary' : ''"
                      @click="handleClickVersion(item)"
                    >
                      <a-col>
                        <div>V{{ getVersionName(index) }}</div>
                        <small>{{ $filters.utcToCurrentTime(item.creationTime) }}</small>
                      </a-col>
                      <a-col><RightOutlined /></a-col>
                    </a-row>
                  </a-list-item>
                </template>
              </a-list>
            </div>
            <a-pagination
              class="text-center pt-2"
              simple
              v-model:current="leftData.pageData.currentIndex"
              v-model:pageSize="leftData.pageData.maxResultCount"
              :total="leftData.pageData.totalCount"
              @change="handlePage"
            />
          </a-card>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="18" :xxl="18">
          <a-spin :spinning="rightLoading">
            <a-card v-if="selectedVersion.id" :style="{ height: (wrap.contentHeight - 2) + 'px', overflowY: 'auto', overflowX: 'hidden' }" size="small">
              <template #title>
                <a-row type="flex" justify="end">
                  <a-col>
                    <span>{{ $t('account.operator') }}: </span>
                    <span class="text-primary" v-if="productVersionInfo.employee">{{ getEmployeeName(productVersionInfo.employee) }}({{ productVersionInfo.employee.employeeNo }})</span>
                    <span class="text-primary" v-else-if="productVersionInfo.userName">{{ productVersionInfo.userName }}</span>
                    <span v-else>-</span>
                  </a-col>
                </a-row>
              </template>
              <a-row :gutter="[16, 16]">
                <a-col>
                  <div class="pabi-img">
                    <c-image :src="productVersionInfo.imgUrl" :thumb-width="100" :thumb-height="100"></c-image>
                  </div>
                  <p class="text-center">{{ $t('warehouse.product_imag') }}</p>
                </a-col>
                <a-col>
                  <div class="pabi-img">
                    <c-image :src="productVersionInfo.packingImgUrl" :thumb-width="100" :thumb-height="100"></c-image>
                  </div>
                  <p class="text-center">{{ $t('common.product_packing_imag') }}</p>
                </a-col>
                <a-col>
                  <a-row type="flex" justify="space-between" style="height: 104px; flex-direction: column;">
                    <a-col>{{ $t('warehouse.product_name') }}: {{ productVersionInfo.productName || '-' }}</a-col>
                    <a-col>{{ $t('warehouse.product_no') }}: {{ productVersionInfo.productNo || '-' }}</a-col>
                    <a-col>SESKU: {{ productVersionInfo.seSku || '-' }}</a-col>
                  </a-row>
                </a-col>
              </a-row>
              <p class="mt-3 mb-1"><strong>{{ $t('warehouse.storage_logistics_info') }}</strong></p>
              <a-divider style="margin: 0;" />
              <a-row :gutter="[8, 16]" class="mt-3">
                <a-col span="24">
                  <a-row :gutter="4">
                    <a-col><span class="mr-1">{{ $t('logistics.transport_characteristics') }}: </span></a-col>
                    <a-col v-for="(item, index) in productVersionInfo.transportCharacteristics" :key="index">
                      <a-tag>{{ $t($enumLangkey('transportCharacteristics', item)) }}</a-tag>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24" v-if="hasBattery">
                  <a-row :gutter="32">
                    <a-col>
                      <span>{{ $t('warehouse.battery_type') }}: </span>
                      {{ $t($enumLangkey('batteryType', productVersionInfo.batteryType)) }}
                    </a-col>
                    <a-col>
                      <span>{{ $t('warehouse.battery_ingredient') }}: </span>
                      {{ $t($enumLangkey('batteryIngredient', productVersionInfo.batteryIngredient)) }}
                    </a-col>
                    <a-col>
                      <span>{{ $t('warehouse.battery_capacity') }}: </span>
                      {{ productVersionInfo.batteryCapacity || '-' }}mA
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row :gutter="32">
                    <a-col>
                      <span>{{ $t('warehouse.encasement_size') }}: </span>
                      {{ productVersionInfo.packingLength || '-' }}x{{ productVersionInfo.packingWidth || '-' }}x{{ productVersionInfo.packingHeight || '-' }}cm
                    </a-col>
                    <a-col>
                      <span>{{ $t('warehouse.encasement_weight') }}: </span>
                      {{ gToKg(productVersionInfo.packingLength) }}kg
                    </a-col>
                    <a-col>
                      <span>{{ $t('warehouse.encasement_count') }}: </span>
                      {{ productVersionInfo.containerCount || '-' }}Unit
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <a-row :gutter="32">
                    <a-col>
                      <span>{{ $t('warehouse.product_dimensions') }}<c-tooltip :title="$t('warehouse.product_of_size')"></c-tooltip> : </span>
                      {{ productVersionInfo.containerLength || '-' }}x{{ productVersionInfo.containerWidth || '-' }}x{{ productVersionInfo.containerHeight || '-' }}cm
                    </a-col>
                    <a-col>
                      <span>{{ $t('warehouse.encasement_weight') }}: </span>
                      {{ gToKg(productVersionInfo.containerWeight) }}kg
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="24">
                  <span>{{ $t('warehouse.is_the_wrapping_film_supported') }}: </span>
                  <span v-if="productVersionInfo.isWrappingFilm"> 
                      {{ $t('common.support') }}
                  </span>
                  <span v-else>
                      {{ $t('common.not_support') }}
                  </span>
                </a-col>
                <a-col :span="24">
                  <span>{{ $t('warehouse.does_it_support_not_selecting_packaging') }}: </span>
                  <span v-if="productVersionInfo.isOptionalPacking"> 
                      {{ $t('common.support') }}
                  </span>
                  <span v-else>
                      {{ $t('common.not_support') }}
                  </span>
                </a-col>
                <a-col :span="24">
                  <span>{{ $t('warehouse.combined_packaging_logo') }}: </span>
                  {{ $t($enumLangkey('productMergeMark', productVersionInfo.mergeMark)) }}
                </a-col>
              </a-row>
              <a-tabs v-model:activeKey="activeKey" class="mt-3">
                <a-tab-pane key="1" :tab="$t('warehouse.customs_declare_info')"></a-tab-pane>
                <a-tab-pane key="2" :tab="$t('common.product_label_manage')"></a-tab-pane>
              </a-tabs>
              <template v-if="activeKey === '1'">
                <a-row :gutter="[8, 16]" class="mt-3">
                  <a-col :span="24">
                    <a-row :gutter="32">
                      <a-col>
                        <span>{{ $t('warehouse.customs_no') }}: </span>
                        <span >{{productVersionInfo.brandInfo.hsCode??"-"}}</span>
                      </a-col>
                      <a-col v-if="productVersionInfo.brandInfo.currencyCode">
                        <span>{{ $t('warehouse.declare_price') }}: </span>
                    {{ productVersionInfo.brandInfo.currencyCode }} {{ $filters.amountToFixed2(productVersionInfo.brandInfo.declarePrice) }}
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row :gutter="32">
                      <a-col>
                        <span>{{ $t('warehouse.brand_info') }}: </span>
                        <span v-if="productVersionInfo.brandInfo.currencyCode">{{ $t('warehouse.have_name') }}</span>
                        <span v-else>{{ $t('warehouse.no_brand') }}</span>
                      </a-col>
                      <a-col v-if="productVersionInfo.brandInfo.currencyCode">
                        <span>{{ $t('warehouse.brand_name') }}: </span>
                        {{ productVersionInfo.brandInfo.brand }}
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row :gutter="32">
                      <a-col>
                        <span>{{ $t('warehouse.english_trade_name') }}: </span>
                        {{ productVersionInfo.brandInfo.customsEnName }}
                      </a-col>
                      <a-col>
                        <span>{{ $t('warehouse.chinese_trade_name') }}: </span>
                        {{ productVersionInfo.brandInfo.customsCnName }}
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row :gutter="32">
                      <a-col>
                        <span>{{ $t('warehouse.english_texture') }}: </span>
                        {{ productVersionInfo.brandInfo.enMaterial }}
                      </a-col>
                      <a-col>
                        <span>{{ $t('warehouse.chinese_texture') }}: </span>
                        {{ productVersionInfo.brandInfo.cnMaterial }}
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="24">
                    <a-row :gutter="32">
                      <a-col>
                        <span>{{ $t('warehouse.purpose_english_description') }}: </span>
                        {{ productVersionInfo.brandInfo.purposeEnDescription }}
                      </a-col>
                      <a-col>
                        <span>{{ $t('warehouse.purpose_chinese_description') }}: </span>
                        {{ productVersionInfo.brandInfo.purposeCnDescription }}
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-table
                  class="mt-3"
                  :columns="columns"
                  :data-source="productVersionInfo.customsInformation"
                  :pagination="false"
                  size="small"
                  :row-key="(record) => record.countryId"
                >
                  <template #countryName="{ record }">{{ getLanguageName(record.countryCnName, record.countryEnName) }}</template>
                  <template #customsCnName="{ record }">{{ record.customsCnName }}</template>
                  <template #customsEnName="{ record }">{{ record.customsEnName }}</template>
                  <template #declarePrice="{ record }">{{ record.currencyCode }} {{ $filters.amountToFixed2(record.declarePrice) }}</template>
                  <template #hsCode="{ record }">{{ record.hsCode }}</template>
                </a-table>
              </template>
              <template v-else-if="activeKey === '2'">
                <a-row :gutter="[16, 16]">
                  <a-col v-for="(item, index) in productVersionInfo.labels" :key="index">
                    <a-card style="width: 250px;">
                      <a-row type="flex" justify="space-between" align="middle" style="flex-direction: column;">
                        <a-col>
                          <div class="text-center">{{ item.name }}</div>
                          <div class="text-center">{{ $t('warehouse.can_identification_content') }}: {{ item.code }}</div>
                        </a-col>
                        <a-col class="mt-4">
                          <CFileDisplay :fileUrl="item.imgUrl" />
                        </a-col>
                      </a-row>
                    </a-card>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-spin>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { Card, Col, Divider, Form, List, Pagination, Row, Spin, Table, Tabs, Tag } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CImage from "@/views/components/CImage.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import { setFormStateValue, gToKg, getName, getNameByLanguage } from '@/utils/general.js';
import { getProducInfo } from '@/api/modules/products/edit.js';
import { getProductVersionListPaged, getProductVersion } from '@/api/modules/products/list.js';
import { transportCharacteristics as transportCharacteristicsEnum } from '@/enum/enum.json';

export default defineComponent({
  name: "products_historical_version",
  components: {
    ARow: Row,
    ACol: Col,
    AList: List,
    AListItem: List.Item,
    ACard: Card,
    APagination: Pagination,
    ADivider: Divider,
    AForm: Form,
    AFormItem: Form.Item,
    ATag: Tag,
    ASpin: Spin,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATable: Table,
    Content,
    CTooltip,
    CImage,
    CFileDisplay,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const { getters } = useStore();

    const state = reactive({
      productId: null,
      shopInfo: {
        shopId: null,
        shopName: null,
        shopNo: null,
        shopMark: null,
      },
      leftData: {
        loading: false,
        list: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10
        },
      },
      // 选中的产品名称
      selectedVersion: {
        id: null,
      },
      versionName: null,
      rightLoading: false,
      productVersionInfo: null,
      activeKey: "1",
    });

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t('common.serial_number'),
        customRender: ({ index }) => index + 1,
      },
      {
        width: 100,
        title: () => vueI18n.t('common.country'),
        slots: {
          customRender: 'countryName'
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.chinese_trade_name'),
        slots: {
          customRender: 'customsCnName'
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.english_trade_name'),
        slots: {
          customRender: 'customsEnName'
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.declare_price'),
        slots: {
          customRender: 'declarePrice'
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.customs_no'),
        slots: {
          customRender: 'hsCode'
        }
      },
    ]

    const isSelectedVersion = computed(() => (id) => {
      if (id === state.selectedVersion.id) {
        return true;
      }
      return false;
    })

    // 判断运输特征中是否包含电池
    const hasBattery = computed(() => {
      const batteryArray = [
        transportCharacteristicsEnum.supportingBattery,
        transportCharacteristicsEnum.builtInBattery,
        transportCharacteristicsEnum.independenceBattery,
      ];
      return batteryArray.some((item) => state.productVersionInfo.transportCharacteristics.includes(item));
    })

    const getVersionName = (index = 0) => {
      return state.leftData.pageData.totalCount - state.leftData.pageData.skipCount - index;
    }

    const getEmployeeName = (employee) => {
      return getNameByLanguage(employee, getters.language)
    }

    const getLanguageName = (cnName, enName) => {
      return getName({ cnName, enName }, getters.language);
    }

    const handleResetFields = () => {
      state.productVersionInfo = {
        id: null,
        imgUrl: null,
        packingImgUrl: null,
        productName: null,
        productNo: null,
        seSku: null,
        transportCharacteristics: [],
        batteryType: null,
        batteryIngredient: null,
        batteryCapacity: null,
        packingLength: null,
        packingWidth: null,
        packingHeight: null,
        packingWeight: null,
        containerCount: null,
        containerLength: null,
        containerWidth: null,
        containerHeight: null,
        containerWeight: null,
        isWrappingFilm: null,
        isOptionalPacking: null,
        mergeMark: null,
        employee: null,
        userName: null,
        brandInfo: {
          hsCode: null,
          currencyCode: null,
          currencySymbol: null,
          declarePrice: null,
          hasBrand: null,
          brand: null,
          customsEnName: null,
          customsCnName: null,
          enMaterial: null,
          cnMaterial: null,
          purposeEnDescription: null,
          purposeCnDescription: null,
        },
        customsInformation: [],
        labels: [],
      }
      state.activeKey = '1';
    }

    const handleClickVersion = (record) => {
      if (record.id === state.selectedVersion.id) {
        return;
      }
      handleResetFields();
      state.selectedVersion.id = record.id;

      state.rightLoading = true;
      getProductVersion({ id: record.id }).then(({ result }) => {
        if (result) {
          setFormStateValue(state.productVersionInfo, result);
          if (result.customsDeclarations.length) {
            setFormStateValue(state.productVersionInfo.brandInfo, result.customsDeclarations[0]);
            result.customsDeclarations.splice(0, 1);
            state.productVersionInfo.customsInformation = result.customsDeclarations;
          }
        }
      }).catch(() => {}).finally(() => {
        state.rightLoading = false;
      });
    }

    const getPageList = () => {
      state.leftData.loading = true;
      const data = Object.assign({}, state.leftData.pageData, {
        productId: state.productId,
        shopId: state.shopInfo.shopId,
      });
      getProductVersionListPaged(data)
        .then(({ result }) => {
          if (result) {
            let { items, totalCount } = result;
            state.leftData.pageData.totalCount = parseInt(totalCount);
            state.leftData.list = items;
          }
        })
        .catch(() => {})
        .finally(() => {
          state.leftData.loading = false;
        });
    };

    const handlePage = (current) => {
      if (current) {
        state.leftData.pageData.currentIndex = current;
        state.leftData.pageData.skipCount = state.leftData.pageData.maxResultCount * (current - 1);
        getPageList();
      }
    }

    const getProductDetail = () => {
      getProducInfo({ id: state.productId }).then(({ result }) => {
        if (result) {
          setFormStateValue(state.shopInfo, result);
          getPageList();
        }
      }).catch(() => {})
    }

    onMounted(() => {
      let params = route.params;
      state.productId = params.id;
      if (params.id) {
        getProductDetail();
      }
    });

    return {
      ...toRefs(state),
      gToKg,
      columns,
      isSelectedVersion,
      hasBattery,
      getVersionName,
      getEmployeeName,
      getLanguageName,
      handleClickVersion,
      handlePage,
    };
  },
});
</script>

<style lang="less" scoped>
.pabi-img {
  width: 104px;
  height: 104px;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
}
</style>


<template>
  <a-row :gutter="[24, 8]">
    <a-col :xs=" 24 "
           :sm=" 24 "
           :xl=" 16 ">
      <a-row type="flex"
             align="middle"
             :wrap="true"
             :gutter="[32, 8]">
        <a-col :lg=" 12 "
               :sm=" 24 "
               :xs=" 24 ">
          <a-card hoverable>
            <a-spin :spinning="state.storageAndConsignment.loading">
              <h3><strong>{{$t('warehouse.storage_warehouse')}} </strong> </h3>
              <div class="pl-3 pr-3">
                <a-row type="flex"
                       justify="space-between"
                       class="text-center">
                  <a-col>
                    <div>{{$t('warehouse.on_warehouse_sku_number')}}</div>
                    <div class="number-size">{{state.storageAndConsignment.storageWarehouseInSkuCount}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.inventory_box_count')}}</div>
                    <div class="number-size">{{state.storageAndConsignment.storageWarehouseBoxCount}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.estimated_inventory_quantity')}}</div>
                    <div class="number-size">{{state.storageAndConsignment.storageWarehouseEstimateCount}}</div>
                  </a-col>
                </a-row>
              </div>
            </a-spin>
          </a-card>
        </a-col>

        <a-col :lg=" 12 "
               :sm=" 24 "
               :xs=" 24 ">
          <a-card hoverable>
            <a-spin :spinning="state.storageAndConsignment.loading">
              <h3><strong> {{$t('warehouse.consignment_warehouse')}}</strong></h3>
              <div class="pl-3 pr-3">

                <a-row type="flex"
                       justify="space-around"
                       align="top"
                       class="text-center">
                  <a-col>
                    <div>{{$t('warehouse.on_warehouse_sku_number')}}</div>
                    <div class="number-size">{{state.storageAndConsignment.delegateWarehouseInSkuCount}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.inventory_count')}}</div>
                    <div class="number-size">{{state.storageAndConsignment.delegateWarehouseInventoryCount}}</div>
                  </a-col>
                </a-row>
              </div>
            </a-spin>
          </a-card>
        </a-col>

        <a-col :lg=" 12 "
               :sm=" 24 "
               :xs=" 24 ">
          <a-card hoverable>
            <h3><strong> {{$t('menu.transport')}}</strong></h3>
            <a-spin :spinning="state.transport.loading">
              <div id="transport"
                   style="width: 100%;height: 300px;"></div>
            </a-spin>

          </a-card>
        </a-col>
        <a-col :lg=" 12 "
               :sm=" 24 "
               :xs=" 24 ">
          <a-card hoverable>
            <a-spin :spinning="state.outPlan.loading">
              <h3><strong> {{$t('warehouse.outbound_plan')}}</strong></h3>
              <div id="outPlan"
                   style="width: 100%;height: 300px;">
              </div>
            </a-spin>
          </a-card>
        </a-col>

        <a-col :lg=" 12 "
               :sm=" 24 "
               :xs=" 24 ">
          <a-card hoverable>
            <h3><strong> {{$t('logistics.consignment_order')}}</strong></h3>
            <a-spin :spinning="state.order.loading">
              <div id="order"
                   style="width: 100%;height: 300px;"></div>
            </a-spin>
          </a-card>
        </a-col>
        <a-col :lg=" 12 "
               :sm=" 24 "
               :xs=" 24 ">
          <a-card hoverable>
            <a-spin :spinning="state.inPlan.loading">
              <h3><strong>{{$t('warehouse.inbound_plan')}}</strong></h3>
              <div id="inPlan"
                   style="width: 100%;height: 300px;">
              </div>
            </a-spin>
          </a-card>
        </a-col>
      </a-row>
    </a-col>
    <a-col :xs=" 24 "
           :sm=" 24 "
           :xl=" 8 ">
      <a-col :span=" 24 ">
        <a-card hoverable>
          <a-spin :spinning="state.product.loading">
            <a-row>
              <a-col :span="3">
                <h3><strong>{{$t('menu.products')}}</strong></h3>
              </a-col>
              <a-col :span="21">
                <a-range-picker style="min-width: 200px"
                                @change="handleGetProductInfo"
                                :ranges="ranges"
                                v-model:value="state.product.dataRange" />
              </a-col>
            </a-row>
            <div id="product"
                 style="width: 100%;height: 460px;"></div>
          </a-spin>
        </a-card>
      </a-col>
    </a-col>
  </a-row>

</template>
<script>
import { onMounted, reactive, onActivated, watch } from "vue";

import { Row, Col, Divider, Card, Spin, DatePicker } from "ant-design-vue";
import {
  getProductInfo, getStorageAndConsignmentInfo,
  getInPlanInfo, getOutPlanInfo, getTransportInfo, getOrderInfo
} from "../../api/modules/dashboard/index";
import moment from "moment-timezone";
import { useI18n } from "vue-i18n/index";
import { currentTimeToUtc } from "../../utils/general.js";
import { getCurrentInstance } from "vue";
import { onBeforeRouteLeave } from 'vue-router';
import * as echarts from 'echarts/core';
import { PieChart, LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { markRaw } from 'vue'

export default {
  name: "dashboard_monitor",
  components: {
    ARow: Row,
    ACol: Col,
    ARangePicker: DatePicker.RangePicker,
    ADivider: Divider,
    ACard: Card,
    ASpin: Spin
  },
  setup () {
    echarts.use([
      TooltipComponent,
      GridComponent,
      LegendComponent,
      LineChart,
      PieChart,
      CanvasRenderer
    ]);
    const vueI18n = useI18n({ useScope: "global" });

    const enumLangkey =
      getCurrentInstance().appContext.config.globalProperties.$enumLangkey;
    const ranges = {
      [vueI18n.t('warehouse.today')]: [moment(), moment()],
      [vueI18n.t('warehouse.yesterday')]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      [vueI18n.t('warehouse.this_week')]: [moment().startOf('week'), moment().endOf('week')],
      [vueI18n.t('warehouse.this_month')]: [moment().startOf('month'), moment().endOf('month')],
    };

    const state = reactive({
      product: {
        loading: false,
        dataRange: [moment().startOf('week'), moment().endOf('week')],
        chart: null
      },
      storageAndConsignment: {
        storageWarehouseInSkuCount: 0,
        storageWarehouseBoxCount: 0,
        storageWarehouseEstimateCount: 0,

        delegateWarehouseInSkuCount: 0,
        delegateWarehouseInventoryCount: 0,
        loading: false
      },
      inPlan: {
        chart: null,
        loading: false
      },
      outPlan: {
        chart: null,
        loading: false
      },
      transport: {
        chart: null,
        loading: false
      },
      order: {
        chart: null,
        loading: false
      }
    });

    //存储仓和配送仓
    const funcGetStorageAndConsignmentInfo = () => {
      state.storageAndConsignment.loading = true;
      getStorageAndConsignmentInfo().then(({ result }) => {
        if (!result) {
          state.storageAndConsignment.loading = false;
          return
        }
        state.storageAndConsignment = result;
        state.storageAndConsignment.loading = false;
      }).catch(() => {
        state.storageAndConsignment.loading = false;
      })
    }


    //运输计划
    const funcGetTransportInfo = () => {
      state.transport.loading = true;
      getTransportInfo().then(({ result }) => {
        if (!result) {
          state.transport.loading = false;
          return
        }
        state.transport.echartData = result.map((item) => {
          return {
            value: item.count, name: vueI18n.t(enumLangkey("transportStatus", item.status))
          }
        })
        getEchartsPie(state.transport, 'transport');
      }).catch((error) => {
        state.transport.loading = false;
      })
    }

    //配送订单
    const funcGetOrderInfo = () => {
      state.order.loading = true;
      getOrderInfo().then(({ result }) => {
        if (!result) {
          state.order.loading = false;
          return
        }
        state.order.echartData = result.map((item) => {
          return {
            value: item.count, name: vueI18n.t(enumLangkey("agentOrderStatus", item.status))
          }
        })
        getEchartsPie(state.order, 'order');
      }).catch((error) => {
        state.order.loading = false;
      })
    }


    //入库计划
    const funcGetInPlanInfo = () => {
      state.inPlan.loading = true;
      getInPlanInfo().then(({ result }) => {
        if (!result) {
          state.inPlan.loading = false
        }
        state.inPlan.echartData = result.map((item) => {
          return {
            value: item.count, name: vueI18n.t(enumLangkey("inWarehouseStatus", item.status))
          }
        })
        getEchartsPie(state.inPlan, 'inPlan');
      }).catch(() => {
        state.inPlan.loading = false;
      })
    }

    //出库计划
    const funcGetOutPlanInfo = () => {
      state.outPlan.loading = true;
      getOutPlanInfo().then(({ result }) => {
        if (!result) {
          state.outPlan.loading = false
        }
        state.outPlan.echartData = result.map((item) => {
          return {
            value: item.count, name: vueI18n.t(enumLangkey("outPlanStatus", item.status))
          }
        })
        getEchartsPie(state.outPlan, 'outPlan');
      }).catch(() => {
        state.outPlan.loading = false;
      })
    }

    //产品
    const handleGetProductInfo = () => {
      let startTime = null;
      let endTime = null;
      if (state.product?.dataRange?.length > 1) {
        startTime = currentTimeToUtc(state.product.dataRange[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(state.product.dataRange[1]?.format("YYYY-MM-DD 00:00:00"));
      } else {
        return
      }

      state.product.loading = true;
      getProductInfo(startTime, endTime).then(({ result }) => {
        if (!result) {
          state.product.loading = false
        }
        state.product.timeDate = [];
        state.product.number = [];
        result.forEach((item, index) => {
          if (index == 0) {
            state.product.timeDate.push(item.createDate);
          } else {
            let dateValue = item.createDate?.substr(item.createDate.indexOf('-') + 1)
            state.product.timeDate.push(dateValue);
          }
          state.product.number.push(item.seskuCount);
        })

        getEchartsLine(state.product, 'product');
      }).catch(() => {
        state.product.loading = false;
      })
    }


    const getEchartsLine = (data, id) => {
      data.chart = echarts.getInstanceByDom(document.getElementById(id));
      if (data.chart == null) {
        data.chart = markRaw(echarts.init(document.getElementById(id)));
      }
      var option = {
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: data.timeDate
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: data.number,
            type: 'line'
          }
        ]
      };
      data.chart.setOption(option);
      data.loading = false;
    }

    const getEchartsPie = (data, id) => {
      data.chart = echarts.getInstanceByDom(document.getElementById(id));
      if (data.chart == null) {
        //markRaw 解决窗口变化引起的bug
        data.chart = markRaw(echarts.init(document.getElementById(id)));
      }

      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          left: 'center'
        },
        color: [
          '#5470c6', '#91cc75', '#fac858', '#ee6666',
          '#73c0de', '#3ba272', '#fc8452', '#9a60b4',
          '#ea7ccc', '#2F4F4F', '#0000FF'
        ],
        series: [
          {
            type: 'pie',
            radius: '50%',
            startAngle: 210,
            data: data.echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      data.chart.setOption(option);
      data.loading = false;
    }

    const initEcharts = async () => {
      funcGetStorageAndConsignmentInfo();
      funcGetTransportInfo();
      funcGetOrderInfo();
      funcGetInPlanInfo();
      funcGetOutPlanInfo();
      handleGetProductInfo();
    }

    const resizeEcharts = async () => {
      state.product.chart?.resize();
      state.inPlan.chart?.resize();
      state.outPlan.chart?.resize();
      state.transport.chart?.resize();
      state.order.chart?.resize();
    }

    onActivated(() => {
      initEcharts();
      window.addEventListener('resize', resizeEcharts, false);
    })

    onBeforeRouteLeave((to, from, next) => {
      window.removeEventListener('resize', resizeEcharts, false);
      next();
    });

    return {
      state,
      ranges,
      handleGetProductInfo,
    }
  }
}
</script>
<style lang="less" scoped>
.number-size {
  font-size: 30px;
}
</style>
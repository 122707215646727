<template>
  <a-button type="primary" :loading="loading" ghost @click="handleExport">{{ $t('warehouse.export_box_number_list') }}</a-button>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { Button } from 'ant-design-vue';
import { dateString } from "@/utils/general";
import { downloadFile } from "@/utils/downloader.js";
import { exportPlanBoxes } from "@/api/modules/transport/index";

export default defineComponent({
  components: {
    AButton: Button,
  },
  props: {
    planId: {
      type: String,
    },
    planNo: {
      type: String,
    }
  },
  setup (props) {
    const loading = ref(false);

    const handleExport = () => {
      loading.value = true;
      let url = exportPlanBoxes({ planId: props.planId })
      downloadFile(url, `${props.planNo}_box_number_list_${dateString()}.csv`, "GET")
        .then(() => {
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    return {
      loading,
      handleExport,
    }
  }
})
</script>

<style scoped>

</style>
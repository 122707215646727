<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.warehouse_monitor_consignment_return_temporary_storage_shop") }}
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input
            style="width: 250px"
            v-model:value="searchState.searchKey"
            :placeholder="$t('warehouse.store_name')"
            allow-clear
          ></a-input>
        </a-col>
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId" />
        </a-col>
        <a-col>
          <a-button
            type="primary"
            :loading="tableData.loading"
            @click="handleSearch"
            >{{ $t("common.search") }}</a-button
          >
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
        :pagination="false"
        :rowKey="(record) => record.id"
        :loading="tableData.loading"
        size="small"
      >
        <template #shopInfo="{ record }">
          {{ record?.shopName || '-' }}
          <div v-if="record?.shopNo">
            NO: {{ record?.shopNo }}
          </div>
          <div>
            {{ $t('common.shop_mark') }}: {{ record.shopMark || '-' }}
          </div>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #lastModificationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operate="{ record }">
          <a-dropdown-button @click="handleOpenModal(record.id)">
            {{ $t('common.edit') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu @click="handleMenuClick">
                  <a-menu-item :key="1" :record="record">{{
                    $t("common.delete")
                  }}</a-menu-item>
                </a-menu>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>

      <EditReturnTemporaryStorageShopModal v-model:visible="returnTemporaryStorageShopModalState.visible" :id="returnTemporaryStorageShopModalState.id" @refresh="handleSearch" />
      <CPinTipsModal v-model:visible="pinModalState.visible" v-model:loading="pinModalState.loading" @confirm="handleDelete" />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="tableData.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { Button, Col, Dropdown, Input, Menu, Row, Table, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CSearchShop from "@/views/components/CSearchShop.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import EditReturnTemporaryStorageShopModal from "../components/EditReturnTemporaryStorageShopModal.vue";
import { useI18n } from "vue-i18n/index";
import { getReturnStorageStoreListPaged, deleteReturnStorageStore } from "@/api/modules/warehouseMonitor/returnTemporaryStorageShop.js";

export default defineComponent({
  name: "warehouse_monitor_consignment_return_temporary_storage_shop",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CSearchShop,
    CPinTipsModal,
    EditReturnTemporaryStorageShopModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      searchState: {
        searchKey: null,
        shopId: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const returnTemporaryStorageShopModalState = reactive({
      visible: false,
      id: null,
    })

    const pinModalState = reactive({
      visible: false,
      loading: false,
      dataTemp: null,
    })

    const columns = [
      {
        width: 150,
        dataIndex: "storageName",
        title: () => vueI18n.t("warehouse.store_name"),
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "shopInfo",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.update_time"),
        slots: {
          customRender: "lastModificationTime",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("common.operate"),
        slots: {
          customRender: "operate",
        },
      },
    ];

    const handleOpenModal = (id = null) => {
      returnTemporaryStorageShopModalState.id = id;
      returnTemporaryStorageShopModalState.visible = true;
    }

    const handleDelete = (pinCode) => {
      const data = {
        ids: [pinModalState.dataTemp],
        pinCode,
      }
      pinModalState.loading = true;
      deleteReturnStorageStore(data).then(() => {
        message.success(vueI18n.t("common.succeed"));
        pinModalState.dataTemp = null;
        pinModalState.visible = false;
        getPageList();
      }).catch(() => {}).finally(() => {
        pinModalState.loading = false;
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 1:
          // 删除
          pinModalState.dataTemp = record.id;
          pinModalState.visible = true;
          break;
        default:
          break;
      }
    };

    const getPageList = () => {
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      state.tableData.loading = true;
      getReturnStorageStoreListPaged(data)
        .then(({ result }) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    onMounted(handleInitPage);

    return {
      ...toRefs(state),
      returnTemporaryStorageShopModalState,
      pinModalState,
      columns,
      handleOpenModal,
      handleDelete,
      handleMenuClick,
      handleSearch,
      handlePage,
    }
  }
})
</script>

<style scoped>

</style>
import client from "../../client";

const Api = {
  // 获取远程版本文件
  getVersionJson: "/version.json",
};

export const getVersionJson = () => {
  return client.request({
    baseURL: "",
    url: Api.getVersionJson,
    params: { t: new Date().getTime() },
    method: "GET",
    disableAutoError: true,
    disableAutoRedirect: true,
  });
};

//仓库信息相关:中文
export default {
  warehouse: {
    warehouse_type: "仓库类型",
    warehouse: "仓库",
    warehouse_name: "仓库名称",
    in_warehouse_plan_detail: "入库计划详情",
    deal_with_wave_number: "处理波次号",
    user_id: "用户ID",
    pick_up_method: "自提方式",
    on_warehouse_sku_number: "在库SKU数量",
    inventory_box_count: "库存箱数",
    estimated_inventory_quantity: "预估库存数量",
    third_side_list: "第三方面单",
    should_be_between: "{0}应该在{1}至{2}间",
    device_not_found_or_browser_not_supported: "设备未找到或浏览器不支持",
    in_warehouse_no: "入库编号",
    labels_cannot_be_scanned_handling_expense_p0: "标签无法扫描,处理费用{0}",
    
    express_sheet: "面单",
    approved_package_size: "核定包装尺寸",
    approved_package_weight: "核定包装重量",
    consignment_warehouse: "配送仓",
    today: "今天",
    yesterday: "昨天",
    this_week: "本周",
    this_month: "本月",
    inventory_count: "库存数量",
    storage_warehouse: "存储仓",
    freight: "运费",

    inbound_plan: "入库计划",
    inbound_plan_of_storage_warehouse: "存储仓入库",
    inbound_plan_of_consignment_warehouse: "配送仓入库",
    inbound_plan_of_transit_warehouse: "中转仓入库",
    outbound_plan: "出库计划",
    outbound_plan_of_transit_warehouse: "中转仓出库",
    outbound_plan_of_storage_warehouse: "存储仓出库",
    transport_plan: "运输计划",

    destination: "发至",
    shipment_not_set:"未设发货",


    //以下为项目拷贝，暂时未用到的多语言

    related_outbound_plan: "相关出库计划",
    related_delivery_plan: "相关交付计划",
    outbound_plan_number: "出库计划编号",
    outbound_warehouse_task_details: "出库任务详情",
    inbound_warehouse_task_details: "入库任务详情",
    outbound_warehouse_amount: "出库数量",
    outbound_warehouse_box_amount: "出库箱数",
    outbound_warehouse_specification: "出库规格",
    outbound_weight: "出库重量",
    box_number: "箱号",
    customer_product_number: "客户货号",
    inner_sku: "SESKU",
    new_tab: "新标签",
    organization_structure: "组织结构",
    service_type: "服务类型",
    task_details: "任务详情",
    transport_delivery_object: "运输交付对象",
    scan_count: "扫描数量",
    add_staff: "添加员工",
    staff_management: "员工管理",
    plan_name: "计划名称",
    inbound_type: "入库类型",
    client_id: "客户ID",

    tab_operate_console: "标签操作台",
    label_operate: "标签操作",
    change_operate_console: "更换操作台",
    accomplish_labelling: "完成贴标",
    check_label: "查看标签",
    sign_in_operating_desk: "签入操作台",
    already_added: "已经存在列表中",
    reset_scan_record: "重置扫描记录",

    box_status: "箱状态",
    current_region: "当前所在区域",
    current_location: "当前所在库位",
    target_area: "目标区域",
    target_location: "目标库位",
    task_status: "任务状态",
    trigger_reason: "触发原因",
    last_operator: "最后操作人",
    principal: "负责人",
    completeing: "待贴标",

    outbound_number: "出库编号",
    please_input_search_outbound_number: "请输入出库编号",
    please_input_search_in_number: "请输入入库编号",
    inbound_warehouse_number: "入库编号",
    inbound_receipt_quantity: "入库数量",
    total_inbound_receipt_quantity: "总入库数量",

    inbound_box_number: "入库箱数",
    received_quantity: "接收数量",
    inbound_method: "入库方式",
    creation_time: "创建时间",
    update_time: "更新时间",
    happen_warehouse_code: "发生仓库代码",
    article_number: "MSKU/货号",
    actual_packing: "实际装箱",
    transportation_delivery_status: "运输交付状态",
    customer_information: "客户信息（须授权）",
    outbound_information: "出库信息",
    inbound_information: "入库信息",
    the_target_position_cannot_be_empty: "目标位置不能为空",

    estimated_delivery_time: "预计送达时间",
    tracking_serial_number: "跟踪编号",
    address_information: "地址信息",
    delivery_man: "送货人",

    address_nformation: "地址信息（如采用系统物流）",
    cargo_details: "货物详情",

    customer_name: "姓名",

    estimatedPick_up_time: "预计提货时间",
    logistics_mode: "零担物流/整车运输/快递物流",

    deliver_storage_location: "交付库位",
    deliver: "交付",

    inbound_box: "入库箱",
    warehoused_box: "已入库箱",

    added_list: "已添加列表",
    inbound_specification: "入库规格",
    inbound_weight: "入库重量",
    cancel_move_storehouse: "取消移库",
    comfirm_move_storehouse: "确认移库",
    storage_location_number: "库位编码",
    storage_location_max_capacity_and_bearing_weight: "库位最大容量/承重",
    max_remaining_capacity_and_bearing_weight: "最大剩余容量/承重",
    number_of_boxes_stored: "已存放箱数",
    current_storage_location: "当前库位",

    inbound_plan_number: "入库计划编号",
    inbound_plan_name: "入库计划名称",
    inbound_target_warehouse: "入库目标仓",
    warehouse_address: "仓库地址",
    plan_status: "计划状态",

    product_name_and_productNo: "产品名称/产品货号",
    inbound_status: "入库状态",
    inbound_packing_size: "入库装箱尺寸",
    inbound_packing_weight: "入库装箱重量",
    abnormal_state_operation: "异常状态需操作",
    trajectory: "轨迹",
    survey_state: "调查状态",
    survey_result: "调查结果",
    inquirer: "调查人",
    abandon: "放弃",

    related_inbound_warehouse_plan: "相关入库计划",
    tray_number: "托盘数量",
    labeled: "已贴标",
    receiving_storage_location: "收货库位",

    product_info: "产品信息",
    label_service: "标签服务",
    unlabeled_service:"无标签服务",

    transport_address: "运送地址",
    logistics_delivery_status: "物流交付状态",
    reserved_Pick_up_information: "预留提货信息",
    payment_method: "支付方式",
    warehouse_expenses: "仓库费用",
    basic_operating_expenses: "基础操作费",
    additional_packaging_materials: "附加包装材料",
    label_cost: "标签费",

    outbound_total_volume: "出库总体积",
    outbound_total_number: "出库总数量",

    location_qualified_status: "位置合格状态",
    owning_customer_id: "所属客户ID",
    inbound_warehouse_time: "入库时间",
    return_goods_in_warehouse_time: "退货入库时间",
    last_updated: "最后更新时间",
    move_track: "移动轨迹",
    operate_console_no: "操作台编号",
    return_package_number: "退货包裹号",

    area_status_configuration: "区域状态配置",
    area_name: "区域名称",
    area_number: "区域编号",
    admissible_state: "可允许状态",

    location_id_or_code: "库位ID/编码",
    location_id: "库位ID",
    current_location_code: "当前库位编码",
    new_location_code: "新库位编码",
    task_trigger: "任务触发人",
    task_type: "任务类型",
    trigger_time: "触发时间",
    completed_time: "完成时间",

    location_type: "库位类型",
    add_location_type: "添加库位类型",
    specification: "规格",
    load_bearing_upper_limit: "承重上限",
    location: "库位",
    add_location: "添加库位",
    edit_location: "编辑库位",

    select_the_location_type: "选择库位类型",
    select_the_location: "选择库位",
    select_the_region: "选择区域",
    the_goods_transfer: "货物转移",
    location_specification: "库位规格",
    add_location_count: "添加库位数量",
    transfer_to: "转移至",

    inbound_warehouse_specification: "入仓规格",
    inbound_warehouse_weight: "入仓重量",

    area_construction: "区域结构",
    current_area: "所在区域",
    load_bearing: "承重",
    position_code: "位置编码",
    volume_usage: "体积使用量",
    load_bearing_usage: "承重使用量",
    can_enter_2_letters_or_numbers: "可输入2位字母或数字",
    add_the_library_location_area: "添加库位至区域",

    related_task_details: "相关任务详情",
    location_number: "库位编号",
    operation_tips: "操作提示",
    update_area: "更新区域",
    new_location_number: "新库位编号",

    look_abnormal: "查看入库异常",
    anomaly_details: "异常详情",

    request_time: "请求时间",
    please_scan_console_first: "请先扫描操作台",
    please_scan_target_storage_locationfirst: "请先扫描目标库位",

    add_subdomain: "添加子区域",
    edit_subdomain: "编辑子区域",

    maximum_capacity: "最大容量/承重",
    surplus_capacity: "剩余容量/承重",
    import_location: "可输入库位ID/库位编码",

    apply_content: "申请内容",

    survey_dispose: "调查处理",

    extra_prove_file: "额外证明文件",

    occurrence_time: "发生时间",

    SESKUNumber: "SESKU数量",
    delivery_status: "交付状态",

    type_name: "类型名称",
    in_storage_error: "该箱异常(可能原因:1.箱号不存在 2.箱已入库 3.箱接收超时)",
    current_box_not_belong_to_plan: "当前扫描的箱不属于该计划.",
    whether_to_switch_plans: "是否切换到扫描箱所属的计划?",
    current_box_inexistence: "当前箱不存在",
    current_box_has_been_put_in_storage: "当前箱已入库",
    warehousing_success: "入库成功",
    out_warehous_success: "出库成功",
    has_been_put_in_storage: "已入库",
    please_add_size_photos: "请添加测量图",
    storage_location_no_error: "库位编号不存在!",
    box_no_inexistence: "箱号不存在!",
    survey_sheet: "测量图",
    size_img: "尺寸图",
    other_proof_img: "其他举证图",
    ignore_new_surveying_data: "忽略新测量数据",
    update_new_surveying_data: "更新为新测量数据",
    warn_scan_data_exceed_threshold: "警告:新扫描数据超过误差阈值",
    general_surveying_data: "通用测量数据",
    reserved_info: "预留信息",
    input_reserved_info: "请输入预留信息",
    reserved_info_no_null: "预留信息是必填的",

    planned_box_has_not_been_scanned: "计划中的箱没有扫描完",
    affiliation_location: "所在位置",
    no_complete_labelling: "贴标未完成",
    scaned: "已扫描",
    unscan: "待扫描",
    please_scan_out_location: "请扫描准备存放的出库区库位编号",
    box_scaned: "箱已扫描",

    last_measurement_data: "最新测量数据",
    time_from_now: "距离现在",
    modified_data: "修改的数据",
    general_data: "通用数据",
    modifier: "修改人",
    code_operation_tips_for_add:
      "请把库位条码标签贴到易于扫描的位置后点击完成操作",
    code_operation_tips_for_change:
      "请扫描所更换的新标签并把该标签覆盖旧的标签",
    code_operation_tips_for_remove:
      "请移出该库位内的货物后销毁该标签，然后点击完成操作",

    out_plan_name: "出库计划名称",
    out_warehouse_no: "出库仓编号",
    out_warehouse_type: "出库类型",
    fbm_warehouse_code: "FBM仓库代码",
    warehousing_number: "货件 ID",
    product_name: "产品名称",
    encasement_count: "装箱数量",
    out_warehouse_encasement_size: "出库装箱尺寸",
    out_warehouse_encasement_weight: "出库装箱重量",
    outer_box_label: "外箱标签",
    box: "箱",
    out_total_weight: "出库总重量",
    transportation_delivery_way: "运输交付方式",
    target_warehouse_code: "目标仓库代码",
    target_warehouse: "目标仓库",
    selected_pallet_packing_serve: "已选打托服务",
    send_goods_address: "发货方地址",
    logistics_way: "物流方式",
    tracking_shipment: "追踪货件",
    encasement_size: "装箱尺寸",
    encasement_weight: "装箱重量",
    label_processing: "贴标方式",
    in_warehouse_img: "入库图片",
    error_state: "异常状态",
    need_operate: "需操作",
    total_volume: "总体积",
    total_encasement_weight: "总装箱重量",
    total_encasement_count: "总装箱数量",
    tracking_no: "追踪号",
    track: "追踪轨迹",
    has_been_operating: "已操作",
    local_time: "当地时间",

    appointment_no: "预约号",
    appointment_time: "预约日期",
    container_no: "柜号",
    container_type: "集装箱类型",
    plan_num: "包含入库计划数",
    driver_telephone: "司机联系方式",
    contact_information:"联系方式",
    area_code: "区号",
    phone_number: "号码",
    please_enter_the_correct_number:"请输入正确的号码",

    appointment_number_or_cabinet_number: "预约编号或柜号",
    additional_packing_material_selection: "附加包装材料选择",
    average_unit_price: "平均单价",
    transport_frees: "运输费用",
    cws_box_label: "赛易标签",

    timed_out: "已超时",
    customs_clearance_info: "清关信息",
    chinese_trade_name: "中文品名",
    english_trade_name: "英文品名",
    declare_price: "申报价格",
    tracking_ticket_no: "跟踪单号",
    charge: "费用",
    transportation_measurement_info:"运输测量信息",
    
    tooltip_text_1: "用户预设的每箱产品数量",
    tooltip_text_2: "用户预设的尺寸重量信息",
    tooltip_text_3: "仓库或物流中心扫描的尺寸重量信息",
    tooltip_text_4: "用户预设的尺寸信息",
    tooltip_text_5: "用户预设的重量信息",
    tooltip_text_6: "预估运费不包含任何附加费用项目",

    customer_number: "客户单号",
    system_number: "系统单号",
    originating_country: "始发国家",
    operating_state: "操作状态",
    shipping_lines: "运输线路",
    number: "件数",
    channel_name: "渠道名称",
    turn_single_track: "转单轨迹",
    encasement_info: "装箱信息",
    check_the_track: "查看轨迹",
    size: "尺寸",
    girth: "围长",
    total_weight: "总重量",

    plan_no_or_company_id: "计划编号或公司ID",
    consignment_code: "配送仓代码",
    consignment_warehouse_code: "配送仓-仓库代码",
    storage_code: "存储仓代码",
    plan_no: "计划编号",

    planned_quantity: "计划数量",
    planned_box_quantity: "计划箱数",
    product_quantity: "产品数量",
    receive_box_quantity: "接收箱数",
    receive_quantity: "接收数量",

    storage_in_plan_no: "存储仓入库编号",
    company_id: "公司ID",
    allot_to_storage_plan_detail: "调拨至存储仓的计划详情",
    packaging_material_name: "包装材料名称",
    packaging_box_weight: "包装箱重量",
    materials: "材料",
    outer_diameter_size: "外径尺寸",
    material_fee: "材料费用",
    total_weight_after_packing: "装箱后总重量",
    space_utilization: "空间利用率",
    max_capacity_number: "最大容纳数量",
    allot_amount: "调拨数量",
    product_label: "产品标签",
    not_select_service: "不选择服务",
    weight_after_packing: "装箱后重量",
    p0_a_product: "{0}个产品",
    whether_to_keep_the_outer_packaging: "是否保留外包装",
    need_to_keep_the_outer_packaging: "需保留外包装",
    do_not_keep_the_outer_packaging: "勿须保留外包装",
    keep_the_outer_packaging: "保留外包装",
    order_status: "订单状态",
    warehouse_operation_status: "仓库操作状态",

    verification_number: "核验编号",
    raw_data: "原数据",
    correct_data: "修正数据",
    processing_time: "处理时间",
    outbound_time: "出库时间",
    in_warehouse_packaging_size: "入库包装规格",
    in_warehouse_packaging_weight: "入库包装重量",
    await_in_warehouse_count: "待入库数量",
    on_warehouse_usable_count: "在库可用数量",
    await_out_warehouse_count: "待出库数量",

    encasement_specification: "装箱规格",
    packaging_size: "包装规格",
    product_gross_weight: "产品毛重",

    product_dimensions:"产品尺寸",
    product_of_size: "产品包装尺寸",
    product_weight: "产品重量",
    product_contains_the_weight_of_the_package: "产品含包装的重量",

    product_no: "产品货号",
    product_imag: "产品图",
    storage_logistics_info: "仓储物流信息",
    battery_type: "电池类型",
    battery_ingredient: "电池成分",
    battery_capacity: "电池容量",
    packaging_weight: "包装重量",
    customs_declare_info: "海关申报信息",
    english_texture: "英文材质",
    chinese_texture: "中文材质",
    texture: "材质",
    customs_no: "海关编码",
    can_identification_content: "可识别内容",
    survey_product_packing_data: "测量产品包装数据",
    general_survey_size: "通用测量尺寸",
    general_survey_weight: "通用测量重量",
    general_survey_img: "通用测量图",
    survey_size: "测量尺寸",
    survey_weight: "测量重量",
    error_customs_declaration: "海关申报表格中一行的数据需要填写完整",
    at_least_one_survey_map_is_required: "至少需要一张测量图",
    weight_measurement_needs_to_be_greater_than_0: "重量测量需要大于0",
    consignment_warehouse_verified_packaging_size: "配送仓已校验的包装尺寸",
    consignment_warehouse_verified_packaging_weight: "配送仓已校验的包装重量",
    please_input_search_shop_name_or_number_or_id: "请输入公司名称或编号或标识",
    please_input_search_product_no_name: "请输入产品货号、名称、SESKU",
    user_defined_box_no: "自定义箱号",
    error_message: "错误信息",
    customer: "客户",
    third_party_customer: "三方客户",
    total_cost: "费用总计",
    warehouse_discount: "仓库折扣",
    company: "公司",
    name: "名称",
    add_discount: "新增折扣",
    print_sesku_lable: "打印SE标签",
    download_se_lable: "下载SE标签",
    packing_material_discount: "包装材料折扣",
    operating_cost_discount: "操作费折扣",
    storage_charge_discount: "仓储费折扣",

    fee_code: "费用代码",
    material_type: "材质类型",
    packing_material_size: "包装材料规格",
    packing_capacity: "包装容量",
    packing_load_bearing: "包装承重",
    unit: "单位",
    price: "价格",
    discount: "折扣比例",
    discount_amount: "折扣金额",
    discount_amount_tips: "正数为减去费用，负数为增加费用",
    discount_price: "折扣价",
    product_lable_name: "产品标签名称",
    the_code_cannot_be_recognized_please_change_the_file:"无法识别编码,请更换文件",

    application_logo: "应用图标",
    application_name: "应用名称",
    application_id: "应用ID",
    application_description: "应用描述",
    call_back_address: "回调地址",
    set_the_authority: "设置权限项",
    reset_key: "重置密钥",
    app_secret: "应用密匙",
    whether_to_confirm_the_reset_key: "是否确认重置密钥？",
    app_secret_tips_1: "应用创建成功，请妥善保管应用密匙！",
    app_secret_tips_2: "重置密钥成功，请妥善保管应用密匙！",
    create_application: "创建应用",
    edit_application: "编辑应用",
    application: "应用",
    authorized_tenant: "授权租户",
    details_of_authorization_items: "授权项详情",
    authorized_application_tenants_permissions: "授权应用租户权限",
    cancel_the_authorization: "取消授权",
    do_you_confirm_the_cancellation_authorization: "是否确认取消授权？",
    authorized_customers: "授权客户",
    please_check_at_least_one_item: "请至少勾选一项",
    relation_no:"相关单号",
    cancel_shipment_processing:"取消发货- 处理中",
    cancel_shipment_succeed:"取消发货- 成功",
    cancel_shipment_unsuccessful:"取消发货- 失败",

    change_the_partner: "变更合作伙伴",
    new_partner: "新合作伙伴",
    old_partner: "旧合作伙伴",

    purpose_description:"用途",
    brand_info:"品牌信息",
    brand_name:"品牌名称",
    have_name:"有品牌",
    no_brand:"无品牌",
    purpose_chinese_description:"中文用途",
    purpose_english_description:"英文用途",
    max_volume_weight:"最大容重",

    package_identification_information: "包裹识别信息",
    additional_identification_information: "额外的识别信息",
    temporary_storage_location_number: "暂存库位编号",
    staging_time: "暂存时间",
    staging_days: "暂存天数",
    staging_operator: "暂存操作人",
    no_matching_return_information: "无匹配的退货信息",
    matched_return_information: "已匹配退货信息",
    return_operation_completed: "已完成退货操作",
    stock_transfer: "移库",
    process_return_task: "处理退货任务",
    package: "包裹",
    day: "天",
    no_return_temporary_storage_fee_within_p0_days: "{0}天内免退货暂存费",
    p0_p1_will_calculate_the_return_temporary_deposit_fee: "{0}-{1}日将计算退货暂存费",
    return_information_that_does_not_match_for_more_than_p0_days_can_be_destroyed: "超过{0}日无匹配的退货信息可销毁",

    compensation: "赔付",
    amount_of_compensation: "赔付金额",
    whether_to_refund: "是否退款",
    whether_to_compensate: "是否赔付",
    please_enter_the_correct_amount: "请输入正确的金额",

    is_it_abnormal: "是否异常",
    abnormal: "有异常",
    no_abnormality: "无异常",

    consolidated_packing_fee: "合并包装费用",
    reconciliation: "对账",
    reconciliation_type: "对账类型",
    place_of_departure: "起运地",
    select_import_file: "选择导入文件",
    click_download_template: "点击下载模板",
    number_of_waybills: "运单件数",
    reconciliation_quantity: "对账件数",
    billing_weight_of_waybill: "运单计费重",
    reconciliation_billing_weight: "对账计费重",
    reconciliation_amount: "对账金额",
    order_amount: "订单金额",
    profit_and_loss_amount: "盈亏金额",
    number_of_successful_matches: "匹配成功数量",
    number_of_matching_failures: "匹配失败数量",
    is_reconciliation: "是否对账",
    reconciliation_time: "对账时间",
    reconciliation_information: "对账信息",
    labels_cannot_be_scanned_handling_expense: "标签无法扫描,处理费用",

    standby_identification1:"备用识别信息1",
    standby_identification2:"备用识别信息2",

    cancel_reconciliation:"取消对账",
    combined_packaging_logo: "合并包装标识",
    is_the_wrapping_film_supported: "是否支持缠膜",
    does_it_support_not_selecting_packaging:"是否支持不选包装",
    packing_methods: "包装方式",

    status: "状态",
    reservation_number: "预约号",
    transfer_warehouse_reserve_warehousing: "中转仓预约入库",
    in_warehouse_reservation_name :"入库预约名称",
    warehousing_reservation_number: "入库预约号",
    outbound_appointment_number: "出库预约号",
    type_of_shipping: "运输方式",

    
    in_warehouse_code: "入库仓库代码",
    out_warehouse_code: "出库仓库代码",
    date_of_reservation: "预约日期",
    number_of_packages: "货件数",
    create_date: "创建日期",
    update_date: "更新日期",
    print_reserve_information: "打印预约信",
    box_amount: "箱数",
    accept_appointment: "接受预约",
    reject_appointment: "拒绝预约",
    cancel_appointment: "取消预约",
    reservation_file:"预约文件",
    transit_in_plan_detail:"预约入库详情",
    modify_reservation_time:"修改预约日期",
    reservation_time:"预约日期",
    box_status:"箱状态",
    modify_reservation_time_text1:"提示: 须提前48小时修改预约日期",
    container_loading_list:"装箱清单",
    shipment_list:"货件列表",
    estimated_arrival_date: "预计到库日期",
    shipment_name:"货件名称",
    system_box_no:"系统箱号",
    box_id:"箱ID",
    prediction_size:"预报尺寸",
    prediction_weight:"预报重量",
    box_type:"箱型",
    se_shipment_number:"SE货件号",
    total_volume_of_shipment:"货件总体积",
    total_weight_of_the_shipment:"货件总重量",
    serial_number: "序号",
    scannable_box_no:"可扫描的箱号",
    scannable_box_no_hint:"请提供可扫描的条码编号，条码编号需要唯一 ",
    forecast_data: "预报数据",
    inbound_data: "入库数据",
    inbound_date: "入库日期",
    print_SE_shipment_label:"打印SE货件标签",
    print_SETB_labels_inside_the_shipment:"打印货件内SETB标签",
    detail_shipment:"货件详情",
    print_SETB_labels:"打印SETB标签",
    total_prices: "合计",
    transfer_warehouse: "中转仓库",
    cancel_plan: "取消计划",
    tracking_number: "跟踪号",
    delivery_way: "交付方式",
    out_warehouse: "出库仓",
    out_plan_detail: "出库计划详情",
    se_warehouse_code:"赛易仓库代码",
    shipment_volume:"货件体积",
    shipment_weight:"货件重量",
    out_data:"出库数据",
    shipment_status:"货件状态",
    p0_outbound_shipments:"{0}个出库货件",
    hit_torr_serve: "打托服务",
    tray_volume_restriction: "每托盘体积限制",
    tray_weight_limit: "每托盘限重",
    logistics_scheme: "物流方案",
    logistics_code: "物流代码",
    logistics_type: "物流类型",
    time_type: "时间类型",
    identifiable_box_number: "可识别箱号",
    recipients: "收件人",
    phone: "电话",
    pick_up_time:"提货时间",
    shipping_time:"发运时间",
    delivery_time:"送达时间",
    reference_aging: "参考时效",
    workday: "工作日",
    fba_warehouse_code: "FBA仓库代码",
    mercado_libre_warehouse_code: "美客多仓库代码",

    adjustment_type: "调整类型",
    operator: "操作人",
    operation: "操作",
    inventory_remaining_quantity: "库存剩余数量",
    inventory_adjustment_quantity: "库存调整数量",
    adjustment_type: "调整类型",

    label_task_name: "标签任务名称",
    tag_task_number: "标签任务编号",
    label_service_type: "标签服务类型",
    task_time: "任务时间",
    number_of_task_boxes: "任务箱数",
    sku_number: "SKU数量",
    quantity_of_finished_products: "完成产品数量",
    belonging_to_warehouse: "所在仓库",
    se_box_number: "SE箱编号",
    identifiable_product_information: "可识别产品信息",
    recognizable_product_images: "可识别产品图片",
    new_product_labeling_document: "新产品标签文件",
    new_product_label: "新产品标签",
    change_the_shipment_name: "改变货件名称",
    notional_pooling: "归集",
    listing: "上架",
    service_status: "服务状态",
    new_shipment_name: "新货件名称",
    number_of_cargo_boxes: "货件箱数",
    number_of_labels_per_box: "每箱标签数",
    new_box_label: "新的箱标签",
    box_label_quantity: "箱标签数量",
    out_warehouse_info: "出库信息",
    outbound_destination: "出库目的地",
    setb_box_number: "SETB箱号",
    export_product: "导出产品",
    shipment_name_or_shipment_number:"货件名称或货件号",
    case_no_or_scannable_case_no:"箱号或可扫描箱号",
    product_information_summary:"产品信息汇总",
    current_warehouse_usable_inventory: "当前仓库可用库存",
    track_info:"轨迹信息",
    cabin_name: "舱位名称",
    cabin_no: "舱位编号",
    cabin_type: "舱位类型",
    cabin_information: "舱位信息",
    cabinet_no: "柜号",
    booking_number: "订舱号",
    port_of_departure: "起运港",
    port_of_destination: "目的港",
    type_of_goods: "货物类型",
    ship_division: "船司",
    aviation_division: "航司",
    name_of_ship: "船名",
    voyage_no: "航次号",
    closing_date: "截关日",
    type_of_goods_accepted: "接受货物类型",
    waybill_list: "运单清单",
    allocated_warehouse:"分配的仓库",
    distribute:"分配",
    verify_images:"核验图片",
    on_warehouse_count: "在库数量",
    applicable: "可用",
    not_applicable: "不可用",
    not_applicable_tips: "包括待出库、其它操作或流程处理中",
    modify_identifiable_information: "修改可识别信息",

    export_packing_list:"导出装箱单",
    export_box_number_list: "导出箱号列表",
    declare:"报关",
    extra_tags:"额外标签",
    existing_order: "现有订单",
    custom_validation: "自定义验证",
    order_type: "订单类型",
    start_verification: "开始验证",
    verification_results: "验证结果",
    add_product: "添加产品",
    add_by_the_product: "按产品添加",
    add_box: "添加箱",
    address_type: "地址类型",
    address_code: "地址编码",
    logistics_center: "物流中心",
    other_weights: "其他重量",
    the_box_information_is_incorrect_please_confirm: "箱信息有误, 请确认",
    transportation_plan_time:"运输计划时间",
    transportation_plan_create_time:"运输计划创建时间",
    transportation_plan_update_time:"运输计划更新时间",
    source_related_doc_no: "来源相关单号",
    target_related_doc_no: "目标相关单号",
    is_it_timeout:"是否超时",
    timeout:"超时",
    please_enter_the_container_number: "请输入箱号",
    store_name: "店铺名称",
    system: "系统",
    reconciliation_detail: "对账详情",
    destroy_order: "销毁订单",
    warehouse_not_received_goods:"仓库未收到货",
    warehouse_temporarily_stored_p0_days: "仓库已暂存{0}天",

  },
};

export default {
  "logistics": {
    "logistics_scheme_name": "Logistics Solution Name",
    "logistics_scheme_code": "Logistics Solution Code",
    "create_template": "Create template",
    "freight_fee_config": "Freight Configuration",
    "config": "Configuration",
    "template_name": "Template Name",
    "freight_fee_config_cue_words": "Notice: It's left excluded and right included for the section limits. e.g.: 20-50kg, 20kg excluded, 50kg included.",
    "volume_freight_fee_config_cue_words": "Attention: left open and right closed for the section.Example:20-50m³,excluding 20m³,including 50m³.",
    "seller_id": "Seller ID",
    "first_weight": "First Weight",
    "first_volume": "First Volume",
    "first_weight_canceled": "Cancel First Weight",
    "first_volume_canceled": "Cancel First Volume",
    "pre_each": "Prepose",
    "fuel_surcharge_canceled": "Cancel Fuel Surcharge",
    "effect_subarea": "Applicable zone",
    "subarea_warning_title": "Notice: If delete existing applicable zones, these zones will become unavailable due to surcharge template missing. Please update surcharge template for these zones timely.",
    "transportation_plan_number": "Shipping plan number",
    "transport_route": "Shipping route",
    "destination_type": "Destination type",
    "start_country_city": "Origin country/city",
    "destination_country_city": "Destination country/city",
    "logistics_scheme": "Logitstics solution",
    "logistics_code": "物流代码",
    "supply_channel_scheme": "Supply Channel Solution",
    "accounting_status": "Adjusting Accounts Status",
    "have_accounting": "Accounts Adjusted",
    "not_accounting": "Accounts Unadjusted",
    "entry_accounting": "Be in Accounts Adjusting",
    "created_at": "Created at",
    "update_on": "Updated at",
    "view_details": "Check Details",
    "transport_plan_name": "Shipping plan name",
    "update_time": "Update Time",
    "warehouse_code": "Warehouse code",
    "receiving_address": "Delivery address",
    "originate": "Shipping from",
    "shipments_address": "Dispatching address",
    "logistics_center_harvest_address": "Logistics center receiving address",
    "contacts": "Contact person",
    "shipments_contacts": "Consigner",
    "last_modification_time": "Updating Time",
    "recipients": "Consignee",
    "the_sender": "Sender",
    "rests": "Others",
    "box_no": "Carton Number",
    "picture": "Picture",
    "transport_info": "Shipping info",
    "total": "Total",
    "transport_characteristics": "Shipping Features",
    "delivery_way": "Delivery Type",
    "logistics_type": "Logistics type",
    "reference_aging": "Referenced time frame",
    "freight_forecast": "Estimated freight",
    "freight_forecast_no_audit": "Freight(estimated)",
    "freight": "Shipping fee",
    "selected_service": "Selected service",
    "cost_accounting": "Cost Accounting",
    "international_transport": "International shipping",
    "door_took": "Pick Up on door",
    "summary_customs_clearance_information": "Summary of customs clearance info",
    "trade_name": "Product name",
    "apply_middle_rate": "Average declared value",
    "subtotal": "Subtotal",
    "clearance_way": "Customs clearance method",
    "ordinary_trade_clearance": "General trading customs clearance",
    "charged_weight": "Chargeable Weight",
    "total_volume_of_transport": "Total Shipping Volume",
    "gross_shipping_weight": "Total Shipping Weight",
    "remote_site": "Delivery Area",
    "business_site": "Commercial area",
    "residence": "Residential area",
    "chargeable_weight": "Chargeable weight",
    "chargeable_total_weight": "Total Chargeable Weight",
    "volume_weight_coefficient": "Volume weight coefficient: divide",
    "workday": "Working Day",
    "discount": "Discount",
    "total_income": "Total Income",
    "supply_channels_have_been_allocated": "Supply Channels have been assigned.",
    "channel_business_no": "Channel Business Number",
    "cost_of_combined": "Total Cost",
    "profit": "Profit",
    "selected_supporting_service": "Selected Pallet Service",
    "selected_supporting": "Palletized",
    "complete_cost_accounting": "Complete Cost Accounting",
    "temporary_additional_fee": "Temporary delivery surcharge",
    "collect_signature_fee": "Receipt signature fee",
    "reload_check": "Reexamine",
    "logistics_select_hint": "System will offer the best logistics center according to your dispatching address.",
    "amazon_shipping_number": "Amazon shipping number",
    "shipment_tracking_number": "Shipment tracking number",
    "address_verification": "Address Verification",
    "print_sheet": "Print Shipping Label",
    "name_or_keyword": "Name or Keyword",
    "applicable_type": "Applicable Type",
    "third_party_channels": "3rd Party Channel",
    "third_party": "3rd Party",
    "interaction_mode": "Interaction Mode",
    "configuration_data": "Configuration Data",
    "enabled": "Enabled",
    "disabled": "Disabled",
    "none": "None",
    "create_logistics_account": "Create Logistics Account",
    "applicable_delivery_method": "Applicable Hand-over Method",
    "data": "Data",
    "describe": "Description",
    "inner_channels": "Built-in Channel",
    "create_built_in_channel": "Create Built-in Channel",
    "edit_built_in_channel": "Edit Built-in Channel",
    "number_of_companies": "Company Quantity",
    "level": "Class",
    "platform": "Platform",
    "code": "Code",
    "to": "To",
    "customer_level": "Client Class",
    "received": "Cargo received",
    "await_visit_fetch": "Waiting for pick up",
    "visit_fetching": "Picking up",
    "console_await_receiving": "Operation Center waiting for receiving",
    "received_goods": "Waiting for bill confirmation/Cargo Received",
    "waiting_for_payment": "等待支付",
    "account_paid": "Dispatch processing/Paid",
    "await_bill_confirm": "Waiting for bill confirmation",
    "send_goods_ing": "Dispatch processing",
    "in_transit": "Dispatched",
    "have_arrived": "Delivered to destination",
    "canceled": "Cancelled",
    "picked_up": "Picked up",
    "processing_status": "Processing Status",
    "transport_box_count": "Shipping carton quantity",
    "receiving_point": "Branch of pick up",
    "create_date": "Creating date",
    "update_date": "Updating date",
    "please_input_plan_name_or_no": "Please enter plan name or number",
    "consignment_out_task": "Outbound task",
    "source_of_sales": "Sales source",
    "consignment_order_no": "Distribution order number",
    "relation_order_no": "Related order number",
    "consignment_order": "Distribution order",
    "transport_scheme": "Shipping solution",
    "logistics_track_no": "Tracking number",
    "logistics_tracking_number": "物流跟踪号",
    "destination_address": "Destination address",
    "placeholder_order_recipients": "Waybill number/Related waybill number/Consignee",
    "create_waves_task": "Create wave task",
    "shipments_warehouse": "Dispatching warehouse",
    "order_info": "Order info",
    "business_packaging_size": "Product packaging dimension",
    "amount": "Quantity",
    "max_capacity": "Maximum capacity",
    "weight_packing_material": "Weight of packaging material",
    "volume_weight_coefficient_p0": "Volume weight coefficient: divide {0}",
    "chargeable_volume": "Chargeable volume",
    "chargeable_total_volume": "Total Chargeable Volume",
    "volume_weight": "Volume Weight",
    "actual_weight": "Actual Weight",
    "density_volume": "Density Volume",
    "actual_volume": "Actual Volume",
    "supplier_channel_name": "Supplier Channel Name",
    "supplier_scheme_code": "Supplier Solution Code",
    "api_account_number": "API Account",
    "billing_name": "打单名称",
    "receiving_channel_code": "收货渠道代码",
    "receiving_supply_code": "收货供应代码",
    "api_status": "API Status",
    "supply_channels": "Supply Channel",
    "interface_name": "Interface Name",
    "support_address": "Supported Address",
    "receipt_of_information": "Receiver's Information",
    "send_message": "Sender's Information",
    "waybill_number": "Tracking Number",
    "receipt_company": "Receiver Company",
    "phone": "Phone",
    "packaging_figure": "Package picture",
    "customer_cancels_shipment": "客户取消发运",
    "end_receipt": "结束收货",
    "are_you_sure_cancels_shipment": "是否确认取消发运？",
    "pay_on_arrival": "物流到付",
    "cancel_box_count": "取消箱数",
    "select_logistics_scheme": "Select Logistics Solution",
    "relevance_out_no": "Related outbound code",
    "relevance_in_no": "Related inbound code",
    "four_in_one_file": "4 in 1 document",
    "customs_declaration": "Cusotms declaration paper",
    "contract": "Contract",
    "invoice": "Invoice",
    "packing_documents": "Packing List",
    "print_waybill_creation_time": "运单创建时间",
    "print_booking_note": "Print Shipping Statement",
    "reset_print": "重置打单",
    "fee_algorithm": "费用算法",
    "interface_account": "接口账号",
    "carrier": "承运商",
    "price": "价格",
    "aging": "时效",
    "logistics": "物流",
    "truck": "卡车",
    "quote": "报价",
    "quote_options": "报价选项",
    "quote_details": "报价详情",
    "quote_options_tips": "卡车价格波动较大，需实时询价",
    "quote_type": "报价类型",
    "service_level": "服务等级",
    "additional_options": "附加选项",
    "inquiry_immediately": "立即询价",
    "select_quote": "选择报价",
    "return_contact_name": "退货联系人",
    "return_contact_phone_number": "退货联系电话",
    "service_charge": "Handling fee",
  }
}
import client from "../../client"

const Api = {
    //获取分区分页列表
    getPagedList: "/api/SupplierService/Region/GetPagedList",

    //创建分区信息
    create: "/api/SupplierService/Region/Create",

    //更新分区信息
    update: "/api/SupplierService/Region/Update",

    //根据Id获取详细
    getById: "/api/SupplierService/Region/GetById",

    //删除分区
    deleteById: "/api/SupplierService/Region/Delete",

    //根据物流方案，获取可用分区
    getListBySupplierId: "/api/SupplierService/Region/GetListBySupplierId",
}

//获取分区分页列表
export const getPagedList = (data) => {
    return client.request({
        url: Api.getPagedList,
        data,
        method: 'POST'
    });
}

//创建分区信息
export const create = (data) => {
    return client.request({
        url: Api.create,
        data,
        method: 'POST'
    });
}

//更新分区信息
export const update = (data) => {
    return client.request({
        url: Api.update,
        data,
        method: 'POST'
    });
}

//根据Id获取详细
export const getById = (regionId) => {
    return client.request({
        url: Api.getById,
        params: { regionId },
        method: 'GET'
    });
}

//删除分区
export const deleteById = (id) => {
    return client.request({
        url: Api.deleteById,
        data: { ids: [id] },
        method: 'POST'
    });
}


//根据物流方案，获取可用分区
export const getListBySupplierId = (supplierId) => {
    return client.request({
        url: Api.getListBySupplierId,
        params: { supplierId },
        method: "post",
    });
};
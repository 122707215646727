<template>
    <Content>
        <template
            #contentTitle
        >{{ $t("menu.supplier_surcharge") }}-{{ surchargeId ? $t("common.edit") : $t("common.create") }}</template>
        <template #contentBody>
            <div class="lg-row border-b pb-3 mb-3">
                <div class="content">
                    <div class="d-inline-block">
                        <span class="d-inline-block mr-2">{{ $t('logistics.supplier_channel_name') }}:</span>
                        <b
                            class="d-inline-block mr-2"
                            v-if="data.logisticsData.code"
                        >{{ data.logisticsData.code }} ({{ data.logisticsData.name }})</b>
                        <span v-else>-</span>
                    </div>
                </div>
            </div>
            <div>
                <a-form :model="data.formData" ref="refForm" :label-col="{ span: 6 }">
                    <a-row>
                        <a-col :span="24" :md="12" :xl="8">
                            <a-form-item :label="'分区名称'" name="name" :rules="requiredStringRules">
                                <a-input v-model:value="data.formData.name"></a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.generalDeclarationData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.generalDeclarationData.isEnabled"
                                    ></a-switch>
                                </label>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(generalItem, index) in data.formData.generalDeclarationData.data.chargeableItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单票计费重</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['generalDeclarationData', 'data', 'chargeableItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="generalItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['generalDeclarationData', 'data', 'chargeableItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="generalItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['generalDeclarationData', 'data', 'chargeableItems', index, 'additionalFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="generalItem.additionalFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="generalItem.additionalMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delGeneralDeclarationItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-button size="small" @click="addGeneralDeclarationItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.specialProductData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.specialProductData.isEnabled"
                                    ></a-switch>
                                </label>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(specialItem, index) in data.formData.specialProductData.data.specialProductItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="specialItem.characteristic"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in characteristicsEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('transportCharacteristics', item))"
                                                    >{{ $t($enumLangkey('transportCharacteristics', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['specialProductData', 'data', 'specialProductItems', index, 'additionalFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="specialItem.additionalFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="specialItem.additionalMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >最低 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['specialProductData', 'data', 'specialProductItems', index, 'minAdditionalFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="specialItem.minAdditionalFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="specialItem.minAdditionalMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delSpecialProductItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-button size="small" @click="addSpecialProductItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.productNameLimitData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.productNameLimitData.isEnabled"
                                    ></a-switch>
                                </label>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">每票最多接受申报品名个数</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['productNameLimitData', 'data', 'limitCount']"
                                                :rules="requiredAndMin1Rules"
                                            >
                                                <a-input-number
                                                    :min="1"
                                                    v-model:value="data.formData.productNameLimitData.data.limitCount"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >每增加一个品名, 加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['productNameLimitData', 'data', 'preSkuFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.productNameLimitData.data.preSkuFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.continuePageData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.continuePageData.isEnabled"
                                    ></a-switch>
                                </label>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">每页最多申报品名个数</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['continuePageData', 'data', 'countOfPage']"
                                                :rules="requiredAndMin1Rules"
                                            >
                                                <a-input-number
                                                    :min="1"
                                                    v-model:value="data.formData.continuePageData.data.countOfPage"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">, 符合品名附加费免加收的条件下,最多页数</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['continuePageData', 'data', 'limitPageCount']"
                                                :rules="requiredAndMin1Rules"
                                            >
                                                <a-input-number
                                                    :min="1"
                                                    v-model:value="data.formData.continuePageData.data.limitPageCount"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 超过后每页加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['continuePageData', 'data', 'prePageFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.continuePageData.data.prePageFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.notFbaAddrData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.notFbaAddrData.isEnabled"
                                    ></a-switch>
                                </label>
                                <div class="pb-1">
                                    <a-checkbox
                                        v-model:checked="data.formData.notFbaAddrData.data.isFuelSurchargeEnabled"
                                    >计算燃油</a-checkbox>
                                </div>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(notFbaItem, index) in data.formData.notFbaAddrData.data.addrItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单票计费重</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['notFbaAddrData', 'data', 'addrItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="notFbaItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['notFbaAddrData', 'data', 'addrItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="notFbaItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['notFbaAddrData', 'data', 'addrItems', index, 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="notFbaItem.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="notFbaItem.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['notFbaAddrData', 'data', 'addrItems', index, 'businessMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="notFbaItem.businessMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="notFbaItem.businessMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['notFbaAddrData', 'data', 'addrItems', index, 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="notFbaItem.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="notFbaItem.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['notFbaAddrData', 'data', 'addrItems', index, 'residentialMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="notFbaItem.residentialMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="notFbaItem.residentialMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delNotFbaAddrItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-button size="small" @click="addNotFbaAddrItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.remoteDistrictsData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.remoteDistrictsData.isEnabled"
                                    ></a-switch>
                                </label>
                                <div class="pb-1">
                                    <a-checkbox
                                        v-model:checked="data.formData.remoteDistrictsData.data.isFuelSurchargeEnabled"
                                    >计算燃油</a-checkbox>
                                </div>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(remoteDistrictsItem, index) in data.formData.remoteDistrictsData.data.addrItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单票计费重</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDistrictsData', 'data', 'addrItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDistrictsItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDistrictsData', 'data', 'addrItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDistrictsItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDistrictsData', 'data', 'addrItems', index, 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDistrictsItem.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDistrictsItem.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDistrictsData', 'data', 'addrItems', index, 'businessMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDistrictsItem.businessMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDistrictsItem.businessMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDistrictsData', 'data', 'addrItems', index, 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDistrictsItem.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDistrictsItem.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDistrictsData', 'data', 'addrItems', index, 'residentialMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDistrictsItem.residentialMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDistrictsItem.residentialMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delRemoteDistrictsItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-button size="small" @click="addRemoteDistrictsItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.ultraRemoteDistrictsData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.ultraRemoteDistrictsData.isEnabled"
                                    ></a-switch>
                                </label>
                                <div class="pb-1">
                                    <a-checkbox
                                        v-model:checked="data.formData.ultraRemoteDistrictsData.data.isFuelSurchargeEnabled"
                                    >计算燃油</a-checkbox>
                                </div>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(ultraRemoteDistrictsItem, index) in data.formData.ultraRemoteDistrictsData.data.addrItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单票计费重</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['ultraRemoteDistrictsData', 'data', 'addrItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="ultraRemoteDistrictsItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['ultraRemoteDistrictsData', 'data', 'addrItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="ultraRemoteDistrictsItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['ultraRemoteDistrictsData', 'data', 'addrItems', index, 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="ultraRemoteDistrictsItem.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="ultraRemoteDistrictsItem.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['ultraRemoteDistrictsData', 'data', 'addrItems', index, 'businessMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="ultraRemoteDistrictsItem.businessMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="ultraRemoteDistrictsItem.businessMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['ultraRemoteDistrictsData', 'data', 'addrItems', index, 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="ultraRemoteDistrictsItem.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="ultraRemoteDistrictsItem.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['ultraRemoteDistrictsData', 'data', 'addrItems', index, 'residentialMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="ultraRemoteDistrictsItem.residentialMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="ultraRemoteDistrictsItem.residentialMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delUltraRemoteDistrictsItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-button size="small" @click="addUltraRemoteDistrictsItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.remoteDeliveryData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.remoteDeliveryData.isEnabled"
                                    ></a-switch>
                                </label>
                                <div class="pb-1">
                                    <a-checkbox
                                        v-model:checked="data.formData.remoteDeliveryData.data.isFuelSurchargeEnabled"
                                    >计算燃油</a-checkbox>
                                </div>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(remoteDeliveryItem, index) in data.formData.remoteDeliveryData.data.addrItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单票计费重</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDeliveryData', 'data', 'addrItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDeliveryItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDeliveryData', 'data', 'addrItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDeliveryItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDeliveryData', 'data', 'addrItems', index, 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDeliveryItem.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDeliveryItem.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDeliveryData', 'data', 'addrItems', index, 'businessMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDeliveryItem.businessMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDeliveryItem.businessMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDeliveryData', 'data', 'addrItems', index, 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDeliveryItem.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDeliveryItem.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 最低收取 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['remoteDeliveryData', 'data', 'addrItems', index, 'residentialMinFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="remoteDeliveryItem.residentialMinFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="remoteDeliveryItem.residentialMinMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delRemoteDeliveryItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-button size="small" @click="addRemoteDeliveryItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.overStandardData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.overStandardData.isEnabled"
                                    ></a-switch>
                                </label>
                                <div class="pb-1">
                                    <a-checkbox
                                        v-model:checked="data.formData.overStandardData.data.isFuelSurchargeEnabled"
                                    >计算燃油</a-checkbox>
                                </div>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(overStandardItem, index) in data.formData.overStandardData.data.singleItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单件</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="overStandardItem.weightType"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in weightTypeEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('weightType', item))"
                                                    >{{ $t($enumLangkey('weightType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'singleItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="overStandardItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'singleItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="overStandardItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'singleItems', index, 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="overStandardItem.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="overStandardItem.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'singleItems', index, 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="overStandardItem.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="overStandardItem.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delOverStandardItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="mb-2">
                                    <a-button size="small" @click="addOverStandardItem">+ 添加</a-button>
                                </div>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">最长边</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'longest', 'startLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.longest.startLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'longest', 'endLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.longest.endLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select v-model:value="data.formData.overStandardData.data.edgeUnitType" style="width: 70px;">
                                                    <a-select-option
                                                        v-for="(item, index) in edgeUnitTypeEnum"
                                                        :key="index"
                                                        :value="item"
                                                        :title="$t($enumLangkey('edgeUnitType', item))"
                                                    >{{ $t($enumLangkey('edgeUnitType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                , 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'longest', 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.longest.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.overStandardData.data.longest.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'longest', 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.longest.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.overStandardData.data.longest.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">,</label>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">单件计费重不足</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'longest', 'whenUnderWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.longest.whenUnderWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg, 计算为</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'longest', 'fixedWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.longest.fixedWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg</span>
                                            </label>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">次长边</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'secondarily', 'startLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.secondarily.startLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'secondarily', 'endLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.secondarily.endLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select v-model:value="data.formData.overStandardData.data.edgeUnitType" style="width: 70px;">
                                                    <a-select-option
                                                        v-for="(item, index) in edgeUnitTypeEnum"
                                                        :key="index"
                                                        :value="item"
                                                        :title="$t($enumLangkey('edgeUnitType', item))"
                                                    >{{ $t($enumLangkey('edgeUnitType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'secondarily', 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.secondarily.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.overStandardData.data.secondarily.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'secondarily', 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.secondarily.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.overStandardData.data.secondarily.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">,</label>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">单件计费重不足</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'secondarily', 'whenUnderWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.secondarily.whenUnderWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg, 计算为</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'secondarily', 'fixedWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.secondarily.fixedWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg</span>
                                            </label>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">
                                                <small>最长边+2*(次边长+最短边)</small>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'twofold', 'startLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.twofold.startLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'twofold', 'endLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.twofold.endLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select v-model:value="data.formData.overStandardData.data.edgeUnitType" style="width: 70px;">
                                                    <a-select-option
                                                        v-for="(item, index) in edgeUnitTypeEnum"
                                                        :key="index"
                                                        :value="item"
                                                        :title="$t($enumLangkey('edgeUnitType', item))"
                                                    >{{ $t($enumLangkey('edgeUnitType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'twofold', 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.twofold.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.overStandardData.data.twofold.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'twofold', 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.twofold.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.overStandardData.data.twofold.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">,</label>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">单件计费重不足</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'twofold', 'whenUnderWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.twofold.whenUnderWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg, 计算为</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['overStandardData', 'data', 'twofold', 'fixedWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.overStandardData.data.twofold.fixedWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg</span>
                                            </label>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.largePackageData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.largePackageData.isEnabled"
                                    ></a-switch>
                                </label>
                                <div class="pb-1">
                                    <a-checkbox
                                        v-model:checked="data.formData.largePackageData.data.isFuelSurchargeEnabled"
                                    >计算燃油</a-checkbox>
                                </div>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">与超规附加费</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select v-model:value="data.formData.largePackageData.data.caclMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in surchargeCaclMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('surchargeCaclMethod', item))"
                                                    >{{ $t($enumLangkey('surchargeCaclMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(largePackageItem, index) in data.formData.largePackageData.data.singleItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单件</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="largePackageItem.weightType"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in weightTypeEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('weightType', item))"
                                                    >{{ $t($enumLangkey('weightType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'singleItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="largePackageItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'singleItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="largePackageItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'singleItems', index, 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="largePackageItem.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="largePackageItem.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'singleItems', index, 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="largePackageItem.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="largePackageItem.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delLargePackageItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="mb-2">
                                    <a-button size="small" @click="addLargePackageItem">+ 添加</a-button>
                                </div>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">最长边</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'longest', 'startLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.longest.startLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'longest', 'endLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.longest.endLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select v-model:value="data.formData.largePackageData.data.edgeUnitType" style="width: 70px;">
                                                    <a-select-option
                                                        v-for="(item, index) in edgeUnitTypeEnum"
                                                        :key="index"
                                                        :value="item"
                                                        :title="$t($enumLangkey('edgeUnitType', item))"
                                                    >{{ $t($enumLangkey('edgeUnitType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'longest', 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.longest.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.largePackageData.data.longest.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'longest', 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.longest.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.largePackageData.data.longest.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">,</label>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">单件计费重不足</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'longest', 'whenUnderWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.longest.whenUnderWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg, 计算为</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'longest', 'fixedWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.longest.fixedWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg</span>
                                            </label>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">次长边</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'secondarily', 'startLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.secondarily.startLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'secondarily', 'endLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.secondarily.endLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select v-model:value="data.formData.largePackageData.data.edgeUnitType" style="width: 70px;">
                                                    <a-select-option
                                                        v-for="(item, index) in edgeUnitTypeEnum"
                                                        :key="index"
                                                        :value="item"
                                                        :title="$t($enumLangkey('edgeUnitType', item))"
                                                    >{{ $t($enumLangkey('edgeUnitType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'secondarily', 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.secondarily.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.largePackageData.data.secondarily.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'secondarily', 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.secondarily.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.largePackageData.data.secondarily.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">,</label>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">单件计费重不足</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'secondarily', 'whenUnderWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.secondarily.whenUnderWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg, 计算为</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'secondarily', 'fixedWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.secondarily.fixedWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg</span>
                                            </label>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-row :gutter="8">
                                        <a-col>
                                            <label class="line-label">
                                                <small>最长边+2*(次边长+最短边)</small>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'twofold', 'startLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.twofold.startLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'twofold', 'endLength']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.twofold.endLength"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select v-model:value="data.formData.largePackageData.data.edgeUnitType" style="width: 70px;">
                                                    <a-select-option
                                                        v-for="(item, index) in edgeUnitTypeEnum"
                                                        :key="index"
                                                        :value="item"
                                                        :title="$t($enumLangkey('edgeUnitType', item))"
                                                    >{{ $t($enumLangkey('edgeUnitType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'twofold', 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.twofold.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.largePackageData.data.twofold.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'twofold', 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.twofold.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="data.formData.largePackageData.data.twofold.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">,</label>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">单件计费重不足</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'twofold', 'whenUnderWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.twofold.whenUnderWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg, 计算为</span>
                                            </label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['largePackageData', 'data', 'twofold', 'fixedWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="data.formData.largePackageData.data.twofold.fixedWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">
                                                <span class="text-warning">kg</span>
                                            </label>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>


                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.tailDeliveryData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.tailDeliveryData.isEnabled"
                                    ></a-switch>
                                </label>
                                <div class="pb-1">
                                    <a-checkbox
                                        v-model:checked="data.formData.tailDeliveryData.data.isFuelSurchargeEnabled"
                                    >计算燃油</a-checkbox>
                                </div>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(tailDeliveryItem, index) in data.formData.tailDeliveryData.data.singleItems"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">单件</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="tailDeliveryItem.weightType"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in weightTypeEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('weightType', item))"
                                                    >{{ $t($enumLangkey('weightType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['tailDeliveryData', 'data', 'singleItems', index, 'startWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="tailDeliveryItem.startWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">至</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['tailDeliveryData', 'data', 'singleItems', index, 'endWeight']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="tailDeliveryItem.endWeight"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >kg, 商业地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['tailDeliveryData', 'data', 'singleItems', index, 'businessFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="tailDeliveryItem.businessFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="tailDeliveryItem.businessMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >, 住宅地址加收 {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['tailDeliveryData', 'data', 'singleItems', index, 'residentialFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="tailDeliveryItem.residentialFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-select
                                                    v-model:value="tailDeliveryItem.residentialMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delTailDeliveryItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="mb-2">
                                    <a-button size="small" @click="addTailDeliveryItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card size="small" class="mb-3">
                        <a-row :gutter="16">
                            <a-col class="surcharge-label">
                                <label class="pb-1 d-inline-block">
                                    <b
                                        class="mr-1"
                                    >{{ $t($enumLangkey("surchargeType", data.formData.othersData.surchargeType)) }}</b>
                                    <a-switch
                                        size="small"
                                        v-model:checked="data.formData.othersData.isEnabled"
                                    ></a-switch>
                                </label>
                            </a-col>
                            <a-col>
                                <div>
                                    <a-row
                                        :gutter="8"
                                        v-for="(otherItem, index) in data.formData.othersData.data.items"
                                        :key="index"
                                    >
                                        <a-col>
                                            <label class="line-label">附加费名称</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['othersData', 'data', 'items', index, 'feeName']"
                                                :rules="requiredStringRules"
                                            >
                                                <a-input v-model:value="otherItem.feeName"></a-input>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label class="line-label">计费方式</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['othersData', 'data', 'items', index, 'interval']"
                                                :rules="requiredAndLargeThan0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="otherItem.interval"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['othersData', 'data', 'items', index, 'additionalMethod']"
                                            >
                                                <a-select
                                                    v-model:value="otherItem.additionalMethod"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in chargingMethodEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('chargingMethod', item))"
                                                    >{{ $t($enumLangkey('chargingMethod', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col
                                            v-if="otherItem.additionalMethod == chargingMethodEnum.preKg"
                                        >
                                            <a-form-item
                                                :name="['othersData', 'data', 'items', index, 'weightType']"
                                            >
                                                <a-select
                                                    v-model:value="otherItem.weightType"
                                                    class="w-85px"
                                                >
                                                    <a-select-option
                                                        v-for="item in weightTypeEnum"
                                                        :key="item"
                                                        :title="$t($enumLangkey('weightType', item))"
                                                    >{{ $t($enumLangkey('weightType', item)) }}</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <label
                                                class="line-label"
                                            >/ {{ data.formData.currencyCode }} {{ data.formData.currencySymbol }}</label>
                                        </a-col>
                                        <a-col>
                                            <a-form-item
                                                :name="['othersData', 'data', 'items', index, 'additionalFee']"
                                                :rules="requiredAndMin0Rules"
                                            >
                                                <a-input-number
                                                    :min="0"
                                                    v-model:value="otherItem.additionalFee"
                                                ></a-input-number>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-checkbox
                                                    v-model:checked="otherItem.isFuelSurchargeEnabled"
                                                >计算燃油</a-checkbox>
                                            </a-form-item>
                                        </a-col>
                                        <a-col>
                                            <a-form-item>
                                                <a-button
                                                    size="small"
                                                    type="link"
                                                    @click="delOthersItem(index)"
                                                >{{ $t('common.delete') }}</a-button>
                                            </a-form-item>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div>
                                    <a-button size="small" @click="addOthersItem">+ 添加</a-button>
                                </div>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-row>
                        <a-col :span="24" :md="12" :xl="8">
                            <a-form-item :label="'适用分区'">
                                <a-select
                                    v-model:value="data.formData.regionIds"
                                    mode="multiple"
                                    placeholder="适用分区"
                                    optionFilterProp="searchKey"
                                    allow-clear
                                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                >
                                    <a-select-option
                                        v-for="item in data.regionList"
                                        :key="item.value"
                                        :searchKey="item.text"
                                    >{{ item.text }}</a-select-option>
                                </a-select>

                                <div>
                                    <small>注意: 若删除已存在的适用分区, 将会导致原分区没有附加费模板而变为不可用, 请及时更新原分区的附加费模板</small>
                                </div>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </template>
        <template #contentFooter>
            <div class="text-center">
                <a-button
                    type="primary"
                    class="m-1"
                    @click="handleSaveSurcharge"
                    :loading="data.saving"
                >保存</a-button>
                <a-button class="m-1" @click="handleBackSurchargeList">取消</a-button>
            </div>
        </template>
    </Content>
</template>
<script>
import { Table, Button, Modal, Input, Select, Card, Row, Col, Form, Radio, Switch, InputNumber, Checkbox, Tag } from 'ant-design-vue';
import Content from '../components/Content.vue'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { getById as getLogisticsById } from '../../api/modules/supplier/index'
import { getById, create, update } from '../../api/modules/supplier/surchargeconfig'
import { getListBySupplierId as getRegions } from '../../api/modules/supplier/region'
import {
    surchargeType as surchargeTypeEnum,
    chargingMethod as chargingMethodEnum,
    weightType as weightTypeEnum,
    surchargeCaclMethod as surchargeCaclMethodEnum,
    transportCharacteristics as characteristicsEnum,
    edgeUnitType as edgeUnitTypeEnum,
} from '../../enum/enum.json'
import { useTab } from '@/hooks/tabs/index'
import { useRoute, useRouter } from "vue-router"
import { useI18n } from 'vue-i18n/index'
import { gToKg, kgToG } from '../../utils/general';
export default defineComponent({
    name: "supplier_surcharge_edit",
    components: {
        ATable: Table,
        AButton: Button,
        AModal: Modal,
        AInput: Input,
        AInputGroup: Input.Group,
        ASelect: Select,
        ACard: Card,
        ARow: Row,
        ACol: Col,
        Content,
        ASelectOption: Select.Option,
        AForm: Form,
        AFormItem: Form.Item,
        ARadio: Radio,
        ARadioOption: Radio.Option,
        ARadioButton: Radio.Button,
        ARadioGroup: Radio.Group,
        ASwitch: Switch,
        AInputNumber: InputNumber,
        ACheckbox: Checkbox,
        ACheckboxGroup: Checkbox.Group,
        ATag: Tag,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const vueI18n = useI18n({ useScope: 'global' });
        const surchargeId = route.params.id;
        const refForm = ref();
        const { delVisitedRoute, } = useTab();

        /// 01.一般贸易报关费
        const getGeneralDeclarationDefaultItem = () => {

            return {
                startWeight: null,
                endWeight: null,
                additionalFee: null,
                additionalMethod: chargingMethodEnum.prePcs,
            }
        }

        const getGeneralDeclarationDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.generalDeclaration,
                isEnabled: false,
                data: {
                    chargeableItems: [
                        // getGeneralDeclarationDefaultItem()
                    ]
                }
            };
        }
        /// end 一般贸易报关费

        /// 02.特殊产品附加费


        const getSpecialProductDefaultItem = () => {
            return {
                characteristic: characteristicsEnum.generalCargo,
                additionalFee: null,
                additionalMethod: chargingMethodEnum.prePcs,
                minAdditionalFee: null,
                minAdditionalMethod: chargingMethodEnum.prePcs,
            }
        }
        const getSpecialProductDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.specialProduct,
                isEnabled: false,
                data: {
                    specialProductItems: [
                        // getSpecialProductDefaultItem()
                    ]
                }
            }
        }

        /// end 特殊产品附加费

        /// 03.品名附加费

        const getProductNameLimitDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.productNameLimit,
                isEnabled: false,
                data: {
                    limitCount: 1,
                    preSkuFee: 0
                }
            }
        }
        /// end 品名附加费

        /// 04.续页附加费
        const getContinuePageDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.continuePage,
                isEnabled: false,
                data: {
                    countOfPage: 1,
                    limitPageCount: 1,
                    prePageFee: 0
                }
            }
        }
        /// end 续页附加费

        //通用的地址项，用于:非Fba、偏远地址、超偏远地址、远程派送
        const getAddrConfigItem = () => {
            return {
                startWeight: null,
                endWeight: null,
                businessFee: null,
                businessMethod: chargingMethodEnum.prePcs,
                businessMinFee: null,
                businessMinMethod: chargingMethodEnum.prePcs,
                residentialFee: null,
                residentialMethod: chargingMethodEnum.prePcs,
                residentialMinFee: null,
                residentialMinMethod: chargingMethodEnum.prePcs
            }
        }
        /// 05.非FBA地址附加费

        const getNotFbaAddrDefaultItem = () => {
            return getAddrConfigItem();
        }

        const getNotFbaAddrDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.notFbaAddr,
                isEnabled: false,
                data: {
                    isFuelSurchargeEnabled: false,
                    addrItems: []
                }
            }
        }

        /// end 非FBA地址附加费

        /// 06.偏远地址附加费
        const getRemoteDistrictsDefaultItem = () => {
            return getAddrConfigItem();
        }

        const getRemoteDistrictsDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.remoteDistricts,
                isEnabled: false,
                data: {
                    isFuelSurchargeEnabled: false,
                    addrItems: []
                }
            }
        }
        /// end 偏远地址附加费

        /// 07.超偏远地址
        const getUltraRemoteDistrictsDefaultItem = () => {
            return getAddrConfigItem();
        }

        const getUltraRemoteDistrictsDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.ultraRemoteDistricts,
                isEnabled: false,
                data: {
                    isFuelSurchargeEnabled: false,
                    addrItems: []
                }
            }
        }
        /// end 超偏远地址

        /// 08.远程派送
        const getRemoteDeliveryDefaultItem = () => {
            return getAddrConfigItem();
        }

        const getRemoteDeliveryDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.remoteDelivery,
                isEnabled: false,
                data: {
                    isFuelSurchargeEnabled: false,
                    addrItems: []
                }
            }
        }
        /// end 远程派送

        /// 09.超规附加费

        const getOverStandardDefaultItem = () => {
            return {
                weightType: weightTypeEnum.actual,
                startWeight: null,
                endWeight: null,
                businessFee: null,
                businessMethod: chargingMethodEnum.prePcs,
                residentialFee: null,
                residentialMethod: chargingMethodEnum.prePcs,
            };
        }

        const getOverStandardDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.overStandard,
                isEnabled: false,
                data: {
                    isFuelSurchargeEnabled: false,
                    edgeUnitType: edgeUnitTypeEnum.cm,
                    singleItems: [],
                    longest: {
                        startLength: 0,
                        endLength: 0,
                        whenUnderWeight: 0,
                        fixedWeight: 0,
                        businessFee: 0,
                        businessMethod: chargingMethodEnum.prePcs,
                        residentialFee: 0,
                        residentialMethod: chargingMethodEnum.prePcs,
                    },
                    secondarily: {
                        startLength: 0,
                        endLength: 0,
                        whenUnderWeight: 0,
                        fixedWeight: 0,
                        businessFee: 0,
                        businessMethod: chargingMethodEnum.prePcs,
                        residentialFee: 0,
                        residentialMethod: chargingMethodEnum.prePcs,
                    },
                    twofold: {
                        startLength: 0,
                        endLength: 0,
                        whenUnderWeight: 0,
                        fixedWeight: 0,
                        businessFee: 0,
                        businessMethod: chargingMethodEnum.prePcs,
                        residentialFee: 0,
                        residentialMethod: chargingMethodEnum.prePcs,
                    }
                }
            }
        }
        /// end 超规附加费

        /// 10.大型包裹附加费

        const getLargePackageDefaultItem = () => {
            return {
                weightType: weightTypeEnum.actual,
                startWeight: null,
                endWeight: null,
                businessFee: null,
                businessMethod: chargingMethodEnum.prePcs,
                residentialFee: null,
                residentialMethod: chargingMethodEnum.prePcs,
            };
        }

        const getLargePackageDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.largePackage,
                isEnabled: false,
                data: {
                    caclMethod: surchargeCaclMethodEnum.superposition,
                    isFuelSurchargeEnabled: false,
                    edgeUnitType: edgeUnitTypeEnum.cm,
                    singleItems: [],
                    longest: {
                        startLength: 0,
                        endLength: 0,
                        whenUnderWeight: 0,
                        fixedWeight: 0,
                        businessFee: 0,
                        businessMethod: chargingMethodEnum.prePcs,
                        residentialFee: 0,
                        residentialMethod: chargingMethodEnum.prePcs,
                    },
                    secondarily: {
                        startLength: 0,
                        endLength: 0,
                        whenUnderWeight: 0,
                        fixedWeight: 0,
                        businessFee: 0,
                        businessMethod: chargingMethodEnum.prePcs,
                        residentialFee: 0,
                        residentialMethod: chargingMethodEnum.prePcs,
                    },
                    twofold: {
                        startLength: 0,
                        endLength: 0,
                        whenUnderWeight: 0,
                        fixedWeight: 0,
                        businessFee: 0,
                        businessMethod: chargingMethodEnum.prePcs,
                        residentialFee: 0,
                        residentialMethod: chargingMethodEnum.prePcs,
                    }
                }
            }
        }

        /// end 大型包裹附加费

        /// 尾程派送附加费

        const getTailDeliveryDefaultItem = () => {
            return {
                weightType: weightTypeEnum.actual,
                startWeight: null,
                endWeight: null,
                businessFee: null,
                businessMethod: chargingMethodEnum.prePcs,
                residentialFee: null,
                residentialMethod: chargingMethodEnum.prePcs,
            };
        }

        const getTailDeliveryDefault = ()=>{
            return {
                surchargeType: surchargeTypeEnum.tailDelivery,
                isEnabled: false,
                data: {
                    caclMethod: surchargeCaclMethodEnum.superposition,
                    isFuelSurchargeEnabled: false,
                    singleItems: []
                }
            }
        }
        /// end 尾程派送附加费

        /// 其他附加费

        const getOthersDefaultItem = () => {
            return {
                feeName: null,
                isFuelSurchargeEnabled: false,
                interval: null,
                additionalMethod: chargingMethodEnum.prePcs,
                weightType: weightTypeEnum.actual,
                additionalFee: null
            };
        }

        const getOthersDefault = () => {
            return {
                surchargeType: surchargeTypeEnum.others,
                isEnabled: false,
                data: {
                    items: []
                }
            };
        }

        /// end 其他附加费

        const data = reactive({
            loading: false,
            saving: false,
            logisticsData: {
                name: null,
                code: null
            },
            formData: {
                supplierId: route.params.supplierId,
                name: null,
                currencyCode: null,
                currencySymbol: null,
                generalDeclarationData: getGeneralDeclarationDefault(),
                specialProductData: getSpecialProductDefault(),
                productNameLimitData: getProductNameLimitDefault(),
                continuePageData: getContinuePageDefault(),
                notFbaAddrData: getNotFbaAddrDefault(),
                remoteDistrictsData: getRemoteDistrictsDefault(),
                ultraRemoteDistrictsData: getUltraRemoteDistrictsDefault(),
                remoteDeliveryData: getRemoteDeliveryDefault(),
                overStandardData: getOverStandardDefault(),
                largePackageData: getLargePackageDefault(),
                othersData: getOthersDefault(),
                tailDeliveryData: getTailDeliveryDefault(),
                regionIds: [],
            },
            regionLoading: false,
            regionList: [],
        });

        const addGeneralDeclarationItem = () => {
            data.formData.generalDeclarationData.data.chargeableItems.push(getGeneralDeclarationDefaultItem());
        }

        const delGeneralDeclarationItem = (index) => {
            data.formData.generalDeclarationData.data.chargeableItems.splice(index, 1);
        }

        const addSpecialProductItem = () => {
            data.formData.specialProductData.data.specialProductItems.push(getSpecialProductDefaultItem());
        }

        const delSpecialProductItem = (index) => {
            data.formData.specialProductData.data.specialProductItems.splice(index, 1);
        }

        const addNotFbaAddrItem = () => {
            data.formData.notFbaAddrData.data.addrItems.push(getNotFbaAddrDefaultItem());
        }

        const delNotFbaAddrItem = (index) => {
            data.formData.notFbaAddrData.data.addrItems.splice(index, 1);
        }

        const addRemoteDistrictsItem = () => {
            data.formData.remoteDistrictsData.data.addrItems.push(getRemoteDistrictsDefaultItem());
        }

        const delRemoteDistrictsItem = (index) => {
            data.formData.remoteDistrictsData.data.addrItems.splice(index, 1);
        }

        const addUltraRemoteDistrictsItem = () => {
            data.formData.ultraRemoteDistrictsData.data.addrItems.push(getUltraRemoteDistrictsDefaultItem());
        }

        const delUltraRemoteDistrictsItem = (index) => {
            data.formData.ultraRemoteDistrictsData.data.addrItems.splice(index, 1);
        }

        const addRemoteDeliveryItem = () => {
            data.formData.remoteDeliveryData.data.addrItems.push(getRemoteDeliveryDefaultItem());
        }

        const delRemoteDeliveryItem = (index) => {
            data.formData.remoteDeliveryData.data.addrItems.splice(index, 1);
        }

        const addOverStandardItem = () => {
            data.formData.overStandardData.data.singleItems.push(getOverStandardDefaultItem());
        }

        const delOverStandardItem = (index) => {
            data.formData.overStandardData.data.singleItems.splice(index, 1);
        }

        const addLargePackageItem = () => {
            data.formData.largePackageData.data.singleItems.push(getLargePackageDefaultItem());
        }

        const delLargePackageItem = (index) => {
            data.formData.largePackageData.data.singleItems.splice(index, 1);
        }

        const addTailDeliveryItem = () => {
            data.formData.tailDeliveryData.data.singleItems.push(getTailDeliveryDefaultItem());
        }

        const delTailDeliveryItem = (index) => {
            data.formData.tailDeliveryData.data.singleItems.splice(index, 1);
        }

        const addOthersItem = () => {
            data.formData.othersData.data.items.push(getOthersDefaultItem());
        }

        const delOthersItem = (index) => {
            data.formData.othersData.data.items.splice(index, 1);
        }

        const getRegionList = () => {
            data.regionLoading = true;
            getRegions(data.formData.supplierId).then((res) => {
                data.regionLoading = false;
                let { result } = res;
                data.regionList = result;
            }).catch(() => {
                data.regionLoading = false;
            });
        }

        //1、修正g到kg
        //2、修正空数据，防止页面出错
        const beforeSetSurchargeData = (data) => {
            data = data || {};
            if (data.generalDeclarationData) {
                data.generalDeclarationData.data = data.generalDeclarationData.data || {};
                data.generalDeclarationData.data.chargeableItems = (data.generalDeclarationData.data.chargeableItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                });
            } else {
                data.generalDeclarationData = getGeneralDeclarationDefault();
            }

            if (data.specialProductData) {
                data.specialProductData.data = data.specialProductData.data || {};
            } else {
                data.specialProductData = getSpecialProductDefault();
            }

            if (data.productNameLimitData) {
                data.productNameLimitData.data = data.productNameLimitData.data || {};
            } else {
                data.productNameLimitData = getProductNameLimitDefault();
            }

            if (data.continuePageData) {
                data.continuePageData.data = data.continuePageData.data || {};
            } else {
                data.continuePageData = getContinuePageDefault();
            }

            if (data.notFbaAddrData) {
                data.notFbaAddrData.data = data.notFbaAddrData.data || {};
                data.notFbaAddrData.data.addrItems = (data.notFbaAddrData.data.addrItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                })
            } else {
                data.notFbaAddrData = getNotFbaAddrDefault();
            }

            if (data.remoteDistrictsData) {
                data.remoteDistrictsData.data = data.remoteDistrictsData.data || {};
                data.remoteDistrictsData.data.addrItems = (data.remoteDistrictsData.data.addrItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                })
            } else {
                data.remoteDistrictsData = getRemoteDistrictsDefault();
            }

            if (data.ultraRemoteDistrictsData) {
                data.ultraRemoteDistrictsData.data = data.ultraRemoteDistrictsData.data || {};
                data.ultraRemoteDistrictsData.data.addrItems = (data.ultraRemoteDistrictsData.data.addrItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                })
            } else {
                data.ultraRemoteDistrictsData = getUltraRemoteDistrictsDefault();
            }

            if (data.remoteDeliveryData) {
                data.remoteDeliveryData.data = data.remoteDeliveryData.data || {};
                data.remoteDeliveryData.data.addrItems = (data.remoteDeliveryData.data.addrItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                })
            } else {
                data.remoteDeliveryData = getRemoteDeliveryDefault();
            }

            if (data.overStandardData) {
                data.overStandardData.data = data.overStandardData.data || {};
                data.overStandardData.data.edgeUnitType = data.overStandardData.data.edgeUnitType || edgeUnitTypeEnum.cm;
                data.overStandardData.data.longest = data.overStandardData.data.longest || {};
                data.overStandardData.data.longest.whenUnderWeight = gToKg(data.overStandardData.data.longest.whenUnderWeight||0);
                data.overStandardData.data.longest.fixedWeight = gToKg(data.overStandardData.data.longest.fixedWeight||0);
                data.overStandardData.data.secondarily = data.overStandardData.data.secondarily || {};
                data.overStandardData.data.secondarily.whenUnderWeight = gToKg(data.overStandardData.data.secondarily.whenUnderWeight||0);
                data.overStandardData.data.secondarily.fixedWeight = gToKg(data.overStandardData.data.secondarily.fixedWeight||0);
                data.overStandardData.data.twofold = data.overStandardData.data.twofold || {};
                data.overStandardData.data.twofold.whenUnderWeight = gToKg(data.overStandardData.data.twofold.whenUnderWeight||0);
                data.overStandardData.data.twofold.fixedWeight = gToKg(data.overStandardData.data.twofold.fixedWeight||0);

                data.overStandardData.data.singleItems = (data.overStandardData.data.singleItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                })
            } else {
                data.overStandardData = getOverStandardDefault();
            }

            if (data.largePackageData) {
                data.largePackageData.data = data.largePackageData.data || {};
                data.largePackageData.data.edgeUnitType = data.largePackageData.data.edgeUnitType || edgeUnitTypeEnum.cm;
                data.largePackageData.data.longest = data.largePackageData.data.longest || {};
                data.largePackageData.data.longest.whenUnderWeight = gToKg(data.largePackageData.data.longest.whenUnderWeight||0);
                data.largePackageData.data.longest.fixedWeight = gToKg(data.largePackageData.data.longest.fixedWeight||0);
                data.largePackageData.data.secondarily = data.largePackageData.data.secondarily || {};
                data.largePackageData.data.secondarily.whenUnderWeight = gToKg(data.largePackageData.data.secondarily.whenUnderWeight||0);
                data.largePackageData.data.secondarily.fixedWeight = gToKg(data.largePackageData.data.secondarily.fixedWeight||0);
                data.largePackageData.data.twofold = data.largePackageData.data.twofold || {};
                data.largePackageData.data.twofold.whenUnderWeight = gToKg(data.largePackageData.data.twofold.whenUnderWeight||0);
                data.largePackageData.data.twofold.fixedWeight = gToKg(data.largePackageData.data.twofold.fixedWeight||0);

                data.largePackageData.data.singleItems = (data.largePackageData.data.singleItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                })
            } else {
                data.largePackageData = getLargePackageDefault();
            }

            if(data.tailDeliveryData){
                data.tailDeliveryData.data = data.tailDeliveryData.data || {};
                data.tailDeliveryData.data.singleItems = (data.tailDeliveryData.data.singleItems || []).map(x => {
                    x.startWeight = gToKg(x.startWeight);
                    x.endWeight = gToKg(x.endWeight);
                    return x;
                })
            }else{
                data.tailDeliveryData = getTailDeliveryDefault();
            }

            if (data.othersData) {
                data.othersData.data = data.othersData.data || {};

            } else {
                data.othersData = getOthersDefault();
            }
            return data;
        }

        const getSurchargeConfigInfo = () => {
            data.loading = true;
            getById(surchargeId)
                .then((res) => {
                    data.loading = false;
                    let { result } = res;
                    data.formData = beforeSetSurchargeData(result);
                    data.formData.regionIds = (result.supplierRegions || []).map(x => x.id);
                    getLogisticsInfo();
                }).catch(() => {
                    data.loading = false;
                })
        }

        const getLogisticsInfo = (needUpdateSurcharge) => {
            data.loagind = true;
            getLogisticsById(data.formData.supplierId)
                .then((res) => {
                    data.loading = false;
                    let { result } = res;
                    data.logisticsData.name = result.name;
                    data.logisticsData.code = result.code;
                    if (needUpdateSurcharge) {
                        data.formData.supplierId = result.id;
                        data.formData.currencyCode = result.currencyCode;
                        data.formData.currencySymbol = result.currencySymbol;
                    }
                })
                .catch(() => {
                    data.loading = false;
                });

            getRegionList();
        }

        //修正kg到g
        const beforeSaveSurcharge = (data)=>{
            if(data.generalDeclarationData?.data?.chargeableItems){
                data.generalDeclarationData.data.chargeableItems = data.generalDeclarationData.data.chargeableItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }
            if(data.notFbaAddrData?.data?.addrItems){
                data.notFbaAddrData.data.addrItems = data.notFbaAddrData.data.addrItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }
            if(data.remoteDistrictsData?.data?.addrItems){
                data.remoteDistrictsData.data.addrItems = data.remoteDistrictsData.data.addrItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }
            if(data.ultraRemoteDistrictsData?.data?.addrItems){
                data.ultraRemoteDistrictsData.data.addrItems = data.ultraRemoteDistrictsData.data.addrItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }
            if(data.remoteDeliveryData?.data?.addrItems){
                data.remoteDeliveryData.data.addrItems = data.remoteDeliveryData.data.addrItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }

            if(data.overStandardData?.data?.longest){
                data.overStandardData.data.longest.whenUnderWeight = kgToG(data.overStandardData.data.longest.whenUnderWeight);
                data.overStandardData.data.longest.fixedWeight = kgToG(data.overStandardData.data.longest.fixedWeight);
            }
            if(data.overStandardData?.data?.secondarily){
                data.overStandardData.data.secondarily.whenUnderWeight = kgToG(data.overStandardData.data.secondarily.whenUnderWeight);
                data.overStandardData.data.secondarily.fixedWeight = kgToG(data.overStandardData.data.secondarily.fixedWeight);
            }
            if(data.overStandardData?.data?.twofold){
                data.overStandardData.data.twofold.whenUnderWeight = kgToG(data.overStandardData.data.twofold.whenUnderWeight);
                data.overStandardData.data.twofold.fixedWeight = kgToG(data.overStandardData.data.twofold.fixedWeight);
            }
            if(data.overStandardData?.data?.singleItems){
                data.overStandardData.data.singleItems = data.overStandardData.data.singleItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }

            if(data.largePackageData?.data?.longest){
                data.largePackageData.data.longest.whenUnderWeight = kgToG(data.largePackageData.data.longest.whenUnderWeight);
                data.largePackageData.data.longest.fixedWeight = kgToG(data.largePackageData.data.longest.fixedWeight);
            }
            if(data.largePackageData?.data?.secondarily){
                data.largePackageData.data.secondarily.whenUnderWeight = kgToG(data.largePackageData.data.secondarily.whenUnderWeight);
                data.largePackageData.data.secondarily.fixedWeight = kgToG(data.largePackageData.data.secondarily.fixedWeight);
            }
            if(data.largePackageData?.data?.twofold){
                data.largePackageData.data.twofold.whenUnderWeight = kgToG(data.largePackageData.data.twofold.whenUnderWeight);
                data.largePackageData.data.twofold.fixedWeight = kgToG(data.largePackageData.data.twofold.fixedWeight);
            }
            if(data.largePackageData?.data?.singleItems){
                data.largePackageData.data.singleItems = data.largePackageData.data.singleItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }
            if(data.tailDeliveryData?.data?.singleItems){
                data.tailDeliveryData.data.singleItems = data.tailDeliveryData.data.singleItems.map(x=>{
                    x.startWeight = kgToG(x.startWeight);
                    x.endWeight = kgToG(x.endWeight);       
                    return x;
                });
            }
            
            return data;
        }

        const handleSaveSurcharge = () => {
            refForm.value.validate()
                .then(async (res) => {
                    data.saving = true;
                    let submitData = JSON.parse(JSON.stringify(data.formData));
                    submitData = beforeSaveSurcharge(submitData);
                    return surchargeId
                    ?update(submitData)
                    :create(submitData);
                }).then(()=>{
                    data.saving = false;
                    handleBackSurchargeList();
                })
                .catch((err) => {
                    data.saving = false;
                });
        }

        const handleBackSurchargeList = () => {
            if (data.formData.supplierId) {
                router.push({ name: 'supplier_surcharge', params: { supplierId: data.formData.supplierId } });
            } else {
                router.push({ name: 'supplier_channel' });
            }
            delVisitedRoute(route);
        }

        onMounted(() => {
            if (surchargeId) {
                getSurchargeConfigInfo();
            } else {
                getLogisticsInfo(true);
            }
        })

        const requiredStringRules = [
            {
                required: true,
                trigger: ['blur', 'change'],
                message: () => vueI18n.t('common.required'),
            }
        ];

        const requiredAndMin0Rules = [
            // {
            //     type: "number",
            //     required: true,
            //     trigger: ['blur', 'change'],
            //     message: () => '必填',

            // },
            {
                type: "number",
                required: true,
                min: 0,
                trigger: ['blur', 'change'],
                message: () => '最小为0',
            }
        ]

        const requiredAndMin1Rules = [
            {
                type: "number",
                required: true,
                min: 1,
                trigger: ['blur', 'change'],
                message: () => '最小为1',
            }
        ]

        const requiredAndLargeThan0Rules = [
            // {
            //     type: "number",
            //     required: true,
            //     trigger: ['blur', 'change'],
            //     message: () => '必填',

            // },
            {
                type: "number",
                required: true,
                validator: async (rule, value) => {
                    if (value > 0) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject();
                    }
                },
                trigger: ['blur', 'change'],
                message: () => '需大于0',
            }
        ];

        return {
            surchargeTypeEnum,
            chargingMethodEnum,
            weightTypeEnum,
            surchargeCaclMethodEnum,
            characteristicsEnum,
            edgeUnitTypeEnum,


            refForm,
            data,
            surchargeId,

            requiredStringRules,
            requiredAndMin0Rules,
            requiredAndMin1Rules,
            requiredAndLargeThan0Rules,

            addGeneralDeclarationItem,
            delGeneralDeclarationItem,

            addSpecialProductItem,
            delSpecialProductItem,

            addNotFbaAddrItem,
            delNotFbaAddrItem,

            addRemoteDistrictsItem,
            delRemoteDistrictsItem,

            addUltraRemoteDistrictsItem,
            delUltraRemoteDistrictsItem,

            addRemoteDeliveryItem,
            delRemoteDeliveryItem,

            addOverStandardItem,
            delOverStandardItem,

            addLargePackageItem,
            delLargePackageItem,

            addTailDeliveryItem,
            delTailDeliveryItem,

            addOthersItem,
            delOthersItem,

            handleSaveSurcharge,
            handleBackSurchargeList,
        }
    }
})
</script>
<style lang="less" scoped>
.surcharge-label {
    width: 160px;
    // flex-grow: 0;
}
.border-b {
    border-bottom: 1px solid #f0f0f0;
}
.lg-row {
    display: flex;
    flex-wrap: wrap;
    // justify-content: stretch;
    align-items: stretch;
    .content {
        flex-grow: 1;
    }
    .actions {
        flex-grow: 0;
    }
}
.line-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
}
.w-85px {
    width: 85px;
}
:deep(.input-group-mid .ant-input-group-addon) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
:deep(.input-group-mid-number) {
    border-radius: 0;
    width: 100%;
}
:deep(.input-group-end-number) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
}
:deep(.input-group-start-number) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}
</style>
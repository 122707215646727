<template>
  <Content>
    <template #contentTitle>{{ $t('menu.products_verify_data') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 24]">
        <a-col>
          <a-space>
            <span>{{ $t('warehouse.verification_number') }}: </span>
            <a-input style="width: 250px;" v-model:value="searchState.id" :placeholder="$t('warehouse.verification_number')" allowClear />
          </a-space>
        </a-col>
        <a-col>
          <a-space>
            <span>{{ $t('finance.status') }}: </span>
            <a-select
              style="width: 250px;"
              v-model:value="searchState.status"
              :placeholder="$t('finance.status')"
              allowClear
            >
              <!-- <a-select-option

              ></a-select-option> -->
            </a-select>
          </a-space>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 110 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >

      </a-table>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import { Space, Row, Col, Input, Select, Button, Table } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "products_verify_data",
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      searchState: {
        id: null,
        status: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    })

    const columns = [
      {
        width: 120,
        title: vueI18n.t('warehouse.verification_number'),
        slots: {
          customRender: "verificationNumber",
        },
      },
      {
        width: 120,
        title: vueI18n.t('warehouse.customer'),
        slots: {
          customRender: "client",
        },
      },
      {
        width: 120,
        title: vueI18n.t('warehouse.raw_data'),
        slots: {
          customRender: "verificationNumber",
        },
      },
      {
        width: 120,
        title: vueI18n.t('finance.status'),
        slots: {
          customRender: "verificationNumber",
        },
      },
      {
        width: 120,
        title: vueI18n.t('warehouse.correct_data'),
        slots: {
          customRender: "verificationNumber",
        },
      },
      {
        width: 120,
        title: vueI18n.t('warehouse.creation_time'),
        slots: {
          customRender: "verificationNumber",
        },
      },
      {
        width: 120,
        title: vueI18n.t('warehouse.processing_time'),
        slots: {
          customRender: "verificationNumber",
        },
      },
      {
        width: 120,
        title: vueI18n.t('account.operator'),
        slots: {
          customRender: "verificationNumber",
        },
      },
      {
        width: 120,
        title: vueI18n.t('common.operate'),
        slots: {
          customRender: "verificationNumber",
        },
      },
    ]

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getPlanPaged(data)
        .then(({ result }) => {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    }

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    }

    return {
      ...toRefs(state),
      columns,
      handleSearch,
      handlePage,
    }
  },
})
</script>

<template>
  <Content>
    <template #contentTitle> 港口管理 </template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                v-model:value="searchData.searchKey"
                placeholder="请输入港口名称或代码"
                :allowClear="true"
                style="width: 200px"
              />
            </a-col>
            <a-col>
              <a-select
                v-model:value="searchData.transportType"
                :allowClear="true"
                placeholder="运输类型"
                style="min-width: 200px"
              >
                <a-select-option
                  v-for="(value, key) in transportTypeEnum"
                  :key="key"
                  :title="$t($enumLangkey('transportType', value))"
                  :value="value"
                >
                  {{ $t($enumLangkey("transportType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col
              ><a-button type="primary" @click="handleSearch">{{
                $t("common.search")
              }}</a-button></a-col
            >
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleShowAddOrEditModal">{{
            $t("common.addition")
          }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 70 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #country="{ record }">
          {{ record.countryCnName }}/{{ record.countryEnName }}
        </template>
        <template #transportType="{ record }">
          {{ $t($enumLangkey("transportType", record.transportType)) }}
        </template>
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #updateTime="{ record }">{{
          $filters.utcToCurrentTime(record.lastModificationTime)
        }}</template>
        <template #operate="{ record }">
          <!-- <a-dropdown-button @click="handleEdit(record)">
            {{ $t("common.edit") }}
            <template #overlay>
              <a-menu @click="handleOperateMenuClick">
                <a-menu-item :key="1" :record="record">
                  {{ $t("common.delete") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button> -->
          <a-row :gutter="[16, 8]">
            <a-col>
              <a-button @click="handleShowAddOrEditModal(record)"
                >编辑</a-button
              >
            </a-col>
            <a-col>
              <a-popconfirm
                :title="$t('common.are_you_sure')"
                :ok-text="$t('common.confirm')"
                :cancel-text="$t('common.cancel')"
                @confirm="handleDelete(record)"
              >
                <a-button :loading="record.deleteLoading">
                  {{ $t("common.delete") }}
                </a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
        </template>
      </a-table>

      <a-modal
        :visible="addOrEditModal.visible"
        :mask-closable="false"
        :title="addOrEditModal.id ? '港口编辑' : '港口添加'"
        :cancelText="$t('common.cancel')"
        :okText="$t('common.confirm')"
        @ok="handleAddOrEditModalOk"
        @cancel="handleCancel"
        :confirmLoading="addOrEditModal.loading"
      >
        <a-form
          ref="formRef"
          :model="addOrEditModal"
          :rules="rules"
          :label-col="{
            span: 5,
          }"
          :wrapper-col="{
            span: 14,
          }"
        >
          <a-form-item label="港口名称" name="portName">
            <a-input
              v-model:value="addOrEditModal.portName"
              autocomplete="off"
            />
          </a-form-item>
          <a-form-item label="港口代码" name="portCode">
            <a-input v-model:value="addOrEditModal.portCode" />
          </a-form-item>
          <a-form-item label="运输类型" name="transportType">
            <a-select
              v-model:value="addOrEditModal.transportType"
              :allowClear="true"
              style="min-width: 200px"
            >
              <a-select-option
                v-for="(value, key) in transportTypeEnum"
                :key="key"
                :title="$t($enumLangkey('transportType', value))"
                :value="value"
              >
                {{ $t($enumLangkey("transportType", value)) }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="'国家'" name="countryId">
            <a-select
              :allowClear="true"
              v-model:value="addOrEditModal.countryId"
              show-search
              @change="handleChangeCountry"
              optionFilterProp="search-key"
            >
              <a-select-option
                :value="item.id"
                v-for="item in dropDownData.country"
                :key="item.id"
                :countryCode="item.ioS2"
                :title="`${getLanguageName(item)}(${item.ioS2})`"
                :search-key="item.ioS2 + item.cnName + item.enName"
              >
                {{ getLanguageName(item) }}({{ item.ioS2 }})
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('时区')" name="timezone">
            <a-select
              :disabled="!addOrEditModal.countryId"
              :allowClear="true"
              v-model:value="addOrEditModal.timezone"
              show-search
              optionFilterProp="search-key"
            >
              <a-select-option
                :value="item.zoneName"
                v-for="item in addOrEditModal.timezones"
                :search-key="
                  item.zoneName +
                  item.countryCnName +
                  item.countryEnName +
                  item.gmtOffset +
                  item.countryCode
                "
                :key="item.id"
              >
                {{ item.zoneName }}({{
                  getLanguageName({
                    cnName: item.countryCnName,
                    enName: item.countryEnName,
                  })
                }}
                {{ item.gmtOffset }})
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  onActivated,
  ref,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  Select,
  Button,
  Table,
  Tag,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Spin,
  Form,
  Popconfirm,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import { useI18n } from "vue-i18n/index";
import {
  getList,
  add,
  update,
  deleteData,
  detail,
} from "@/api/modules/devops/portManagement.js";
import { transportType as transportTypeEnum } from "@/enum/enum.json";
import { getTimezones } from "@/api/modules/company/index";
import { getGlobalCountrys } from "@/api/modules/common/index";
import { getName } from "@/utils/general.js";

export default defineComponent({
  name: "dev_port_management",
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ATag: Tag,
    AInput: Input,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AModal: Modal,
    Content,
    CPager,
    AFormItem: Form.Item,
    AForm: Form,
    APopconfirm: Popconfirm,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { getters } = useStore();

    const state = reactive({
      currencyList: [],
      searchData: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const dropDownData = reactive({
      timezones: [],
      timezoneLoading: false,
      country: [],
      countryLoading: false,
    });

    const columns = [
      {
        dataIndex: "portName",
        title: "港口名称",
        width: 100,
      },
      {
        dataIndex: "portCode",
        title: "港口代码",
        width: 100,
      },
      {
        title: "运输类型",
        width: 100,
        slots: {
          customRender: "transportType",
        },
      },
      {
        dataIndex: "timezone",
        title: "时区",
        width: 100,
      },
      {
        title: "国家",
        width: 100,
        slots: {
          customRender: "country",
        },
      },
      {
        title: "创建时间",
        width: 100,
        slots: {
          customRender: "creationTime",
        },
      },
      {
        title: "修改时间",
        width: 100,
        slots: {
          customRender: "updateTime",
        },
      },
      {
        title: "",
        width: 100,
        slots: {
          customRender: "operate",
        },
      },
    ];

    //--------------------------------添加 start----------------------------------------------------------
    const formRef = ref();

    const addOrEditModal = reactive({
      id: null,
      visible: false,
      loading: false,
      portName: null,
      portCode: null,
      transportType: null,
      timezone: null,
      countryId: null,
      timezones: [],
    });

    const handleShowAddOrEditModal = async (record) => {
      addOrEditModal.id = null;
      addOrEditModal.visible = true;
      nextTick(() => {
        formRef.value?.resetFields();
      });
      if (dropDownData.timezones?.length == 0) {
        await funcGetTimezones(record);
      }

      if (dropDownData.country?.length == 0) {
        funcGetCountrys();
      }

      if (record?.id) {
        funcGetDetail(record);
      }
    };

    const funcGetDetail = (record) => {
      addOrEditModal.loading = true;
      detail(record.id)
        .then(({ result }) => {
          Object.assign(addOrEditModal, result);
          addOrEditModal.loading = false;
          if (result.countryIOS2Name) {
            addOrEditModal.timezones = dropDownData.timezones.filter((x) => {
              return x.countryCode == record.countryIOS2Name;
            });
          }
        })
        .catch(() => {
          addOrEditModal.loading = false;
        });
    };

    const handleAddOrEditModalOk = () => {
      formRef.value
        .validate()
        .then(() => {
          if (addOrEditModal.id) {
            _funcUpdate();
          } else {
            _funcAdd();
          }
        })
        .catch(() => {});
    };

    const _funcAdd = () => {
      addOrEditModal.loading = true;
      add({ ...addOrEditModal })
        .then(({ result }) => {
          addOrEditModal.visible = false;
          addOrEditModal.loading = false;
          handleSearch();
        })
        .catch(() => {
          addOrEditModal.loading = false;
        });
    };

    const _funcUpdate = () => {
      addOrEditModal.loading = true;
      update({ ...addOrEditModal })
        .then(({ result }) => {
          addOrEditModal.visible = false;
          addOrEditModal.loading = false;
          handleSearch();
        })
        .catch(() => {
          addOrEditModal.loading = false;
        });
    };

    const handleCancel = (record) => {
      addOrEditModal.visible = false;
    };

    const rules = {
      portName: [
        {
          required: true,
          whitespace: true,
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("港口名称")]);
          },
        },
      ],
      portCode: [
        {
          required: true,
          whitespace: true,
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("港口代码")]);
          },
        },
      ],
      transportType: [
        {
          required: true,
          type: "number",
          whitespace: true,
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("运输类型")]);
          },
        },
      ],
      timezone: [
        {
          required: true,
          whitespace: true,
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("时区")]);
          },
        },
      ],
      countryId: [
        {
          required: true,
          whitespace: true,
          trigger: ["change", "blur"],
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("国家")]);
          },
        },
      ],
    };

    //--------------------------------添加 end----------------------------------------------------------

    const handleChangeCountry = (value, e) => {
      addOrEditModal.timezones = [];
      addOrEditModal.timezone = null;
      addOrEditModal.timezones = dropDownData.timezones.filter((x) => {
        return x.countryCode == e.countryCode;
      });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const funcGetTimezones = (record) => {
      dropDownData.timezoneLoading = true;
     return getTimezones()
        .then((res) => {
          if (res.result && res.result.length > 0) {
            dropDownData.timezones = res.result;
          }
          dropDownData.timezoneLoading = false;
        })
        .catch(() => {
          dropDownData.timezoneLoading = false;
        });
    };

    const funcGetCountrys = () => {
      dropDownData.countryLoading = false;
     return getGlobalCountrys()
        .then((res) => {
          if (res && res.result) {
            dropDownData.country = res.result;
          }
          dropDownData.countryLoading = false;
        })
        .catch(() => {
          dropDownData.countryLoading = false;
        });
    };

    const handleDelete = (record) => {
      record.deleteLoading = true;
      deleteData([record.id])
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          handleSearch();
          record.deleteLoading = false;
        })
        .catch(() => {
          record.deleteLoading = false;
        });
    };

    const getCurrencyName = (item) => {
      if (getters.language === "zh-CN") {
        return `${item.cnName} ${item.code} ${item.symbol}`;
      } else {
        return `${item.enName} ${item.code} ${item.symbol}`;
      }
    };

    const funcGetList = async () => {
      try {
        state.tableData.loading = true;
        const data = Object.assign(
          {},
          state.tableData.pageData,
          state.searchData
        );
        let { result } = await getList(data);
        if (result) {
          let { totalCount = 0, items = [] } = result;
          state.tableData.tableList = items;
          state.tableData.pageData.totalCount = parseInt(totalCount);
        }
      } catch (error) {
      } finally {
        state.tableData.loading = false;
      }
    };

    const handleSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      funcGetList();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      funcGetList();
    };

    onActivated(() => {
      handleSearch();
    });

    return {
      formRef,
      rules,
      ...toRefs(state),
      dropDownData,
      columns,
      transportTypeEnum,

      getLanguageName,

      addOrEditModal,
      getCurrencyName,
      handleSearch,
      handlePage,
      handleShowAddOrEditModal,
      handleCancel,
      handleAddOrEditModalOk,
      handleDelete,
      handleChangeCountry,
    };
  },
});
</script>

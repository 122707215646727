<template>
  <Content>
    <template #contentTitle>{{ $t('menu.dev_export_log') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[24, 16]">
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-select v-model:value="searchState.businessType" style="min-width: 250px" :placeholder="$t('finance.business_type')" allow-clear>
            <a-select-option
              v-for="(item, index) in exportBusinessTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('exportBusinessType', item))"
            >{{ $t($enumLangkey("exportBusinessType", item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-space>
            <span>{{ $t("warehouse.creation_time") }}:</span>
            <a-range-picker style="width: 250px" @change="handleDateChange" />
          </a-space>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.query') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 90 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #shopInfo="{ record }">
          <div>{{ record.shopName || '-' }}</div>
          <div>ID: <span v-if="record.shopId && record.shopId !== '0'">{{ record.shopId }}</span></div>
          <div>NO: {{ record.shopNo || '-' }}</div>
        </template>
        <template #businessType="{ record }">{{ $t($enumLangkey("exportBusinessType", record.businessType)) }}</template>
        <template #queryParam="{ record }">{{ record.queryParam || '{}' }}</template>
        <template #creationTime="{ record }">{{ $filters.utcToCurrentTime(record.creationTime) }}</template>
        <template #employee="{ record }">
          <CEmployeeInfo v-if="record.employee" :employee="record.employee" />
          <span v-else-if="record.operatorName">{{ record.operatorName }}</span>
          <span v-else>-</span>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import Content from '@/views/components/Content'
import CPager from '@/views/components/CPager.vue'
import CSearchShop from "@/views/components/CSearchShop.vue"
import CEmployeeInfo from "@/views/components/CEmployeeInfo.vue"
import { Select, Table, DatePicker, Row, Col, Space, Button } from 'ant-design-vue'
import { getExportLogListPaged } from '@/api/modules/devops/index'
import { exportBusinessType as exportBusinessTypeEnum } from '@/enum/enum.json';
import { currentTimeToUtc } from '@/utils/general';

export default defineComponent({
  name: "dev_export_log",
  components: {
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpace: Space,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
    CSearchShop,
    CEmployeeInfo,
  },
  setup () {
    const { getters } = useStore();

    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      searchState: {
        shopId: null,
        businessType: null,
        beginTime: null,
        endTime: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    })

    const columns = [
      {
        width: 150,
        title: () => vueI18n.t('warehouse.customer'),
        slots: {
          customRender: "shopInfo"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('finance.business_type'),
        slots: {
          customRender: "businessType"
        }
      },
      {
        width: 300,
        title: () => vueI18n.t('devops.query_parameters'),
        slots: {
          customRender: "queryParam"
        }
      },
      {
        dataIndex: "count",
        width: 100,
        title: () => vueI18n.t('devops.export_number'),
        align: "center"
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.creation_time'),
        slots: {
          customRender: "creationTime"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('account.operator'),
        slots: {
          customRender: "employee"
        }
      },
    ]

    const handleDateChange = (e) => {
      let beginTime = null,
        endTime = null;
      if (e?.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.beginTime = beginTime;
      state.searchState.endTime = endTime;
    };

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.searchStateCache,
        state.tableData.pageData
      );
      getExportLogListPaged(data)
        .then(({ result }) => {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    onMounted(handleInitSearch)

    return {
      ...toRefs(state),
      exportBusinessTypeEnum,
      columns,
      handleDateChange,
      handleSearch,
      handlePage,
    }
  }
})
</script>

<style scoped>

</style>
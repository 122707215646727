<template>
    <Content>
        <template
            #contentTitle
        >{{ $t("menu.special_region") }}-{{ regionId ? $t("common.edit") : $t("common.create") }}</template>
        <template v-slot:contentBody="wrap">
            <div>
                <a-form
                    :model="data.formData"
                    ref="refForm"
                    :label-col="{ span: 6 }"
                    :rules="regionRules"
                >
                    <a-row>
                        <a-col :span="24" :md="12" :xl="8">
                            <a-form-item :label="'分区名称'" name="name">
                                <a-input v-model:value="data.formData.name"></a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-form-item
                        :label-col="{ span: 6, md: { span: 3 }, xl: { span: 2 } }"
                        :label="'特殊标签'"
                        name="regionTags"
                    >
                        <a-checkbox-group v-model:value="data.formData.regionTags">
                            <a-checkbox
                                v-for="item in regionTagEnum"
                                :key="item"
                                :value="item"
                            >{{ $t($enumLangkey('regionTag', item)) }}</a-checkbox>
                        </a-checkbox-group>
                    </a-form-item>
                    <a-row>
                        <a-col :span="24" :md="12" :xl="8">
                            <a-form-item :label="'物流渠道'" name="logisticsIds">
                                <a-select
                                    v-model:value="data.formData.logisticsIds"
                                    :placeholder="'物流渠道'"
                                    allow-clear
                                    mode="multiple"
                                    :loading="data.logisticsesLoading"
                                    :showSearch="true"
                                    optionFilterProp="search-key"
                                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                >
                                    <a-select-option
                                        v-for="item in data.logisticses"
                                        :key="item.id"
                                        :value="item.id"
                                        :search-key="item.code + item.name"
                                    >{{ item.code }}({{ item.name }})</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <div class="pt-2 pb-2 mb-2 border-b">适用区域</div>
                    <a-form-item name="specialRegionCountryRls">
                        <div class="mb-2">
                            <label>
                                <b class="mr-2">{{ $t('common.country') }}</b>
                                <a-switch
                                    v-model:checked="data.formData.specialRegionCountryEnabled"
                                    size="small"
                                    @change="validRegionCountryRls"
                                ></a-switch>
                            </label>
                        </div>
                        <div>
                            <a-select
                                :placeholder="$t('common.country')"
                                allow-clear
                                :loading="addressData.countryLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @select="handleCountrySelect"
                            >
                                <a-select-option
                                    v-for="item in addressData.countries"
                                    :key="item.id"
                                    :value="item.id"
                                    :search-key="item.ioS2 + item.cnName + item.enName"
                                    :title="`${getLanguageName(item)}(${item.ioS2})`"
                                >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
                            </a-select>
                        </div>
                        <a-card size="small">
                            <a-tag
                                class="mb-1 mt-1"
                                v-for="item in data.formData.specialRegionCountryRls"
                                closable
                                @close.prevent="handleCountryTagClose(item)"
                            >{{ getLanguageName({ cnName: item.countryCnName, enName: item.countryEnName }) }}</a-tag>
                        </a-card>
                    </a-form-item>
                    <a-form-item name="specialRegionProvinceRls">
                        <div class="mb-2">
                            <label>
                                <b class="mr-2">{{ $t('common.province') }}</b>
                                <a-switch
                                    v-model:checked="data.formData.specialRegionProvinceEnabled"
                                    size="small"
                                    @change="validRegionProvinceRls"
                                ></a-switch>
                            </label>
                        </div>
                        <div>
                            <a-select
                                v-model:value="addressData.provinceRegion.countryId"
                                :placeholder="$t('common.country')"
                                allow-clear
                                :loading="addressData.countryLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @change="handleCountryChangeForProvinceRegion"
                            >
                                <a-select-option
                                    v-for="item in addressData.countries"
                                    :key="item.id"
                                    :value="item.id"
                                    :search-key="item.ioS2 + item.cnName + item.enName"
                                    :title="`${getLanguageName(item)}(${item.ioS2})`"
                                >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
                            </a-select>

                            <a-select
                                v-model:value="addressData.provinceRegion.provinceId"
                                :placeholder="$t('common.province')"
                                allow-clear
                                :loading="addressData.provinceRegion.provinceLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @select="handleProvinceSelect"
                            >
                                <a-select-option
                                    v-for="item in addressData.provinceRegion.provinces"
                                    :search-key="item.abbrCode + item.cnName + item.enName"
                                    :key="item.id"
                                    :title="getLanguageName(item, 'province')"
                                >{{ getLanguageName(item, 'province') }}</a-select-option>
                            </a-select>
                        </div>
                        <a-card size="small">
                            <a-tag
                                class="mb-1 mt-1"
                                v-for="item in data.formData.specialRegionProvinceRls"
                                closable
                                @close.prevent="handleProvinceTagClose(item)"
                            >{{ getLanguageName({ cnName: item.provinceCnName, enName: item.provinceEnName }) }}({{ getLanguageName({ cnName: item.countryCnName, enName: item.countryEnName }) }})</a-tag>
                        </a-card>
                    </a-form-item>

                    <a-form-item name="specialRegionCityRls">
                        <div class="mb-2">
                            <label>
                                <b class="mr-2">{{ $t('common.city') }}</b>
                                <a-switch
                                    v-model:checked="data.formData.specialRegionCityEnabled"
                                    size="small"
                                    @change="validRegionCityRls"
                                ></a-switch>
                            </label>
                        </div>
                        <div>
                            <a-select
                                v-model:value="addressData.cityRegion.countryId"
                                :placeholder="$t('common.country')"
                                allow-clear
                                :loading="addressData.countryLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @change="handleCountryChangeForCityRegion"
                            >
                                <a-select-option
                                    v-for="item in addressData.countries"
                                    :key="item.id"
                                    :value="item.id"
                                    :search-key="item.ioS2 + item.cnName + item.enName"
                                    :title="`${getLanguageName(item)}(${item.ioS2})`"
                                >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
                            </a-select>

                            <a-select
                                v-model:value="addressData.cityRegion.provinceId"
                                :placeholder="$t('common.province')"
                                allow-clear
                                :loading="addressData.cityRegion.provinceLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @change="handleProviceChangeForCityRegion"
                            >
                                <a-select-option
                                    v-for="item in addressData.cityRegion.provinces"
                                    :search-key="item.abbrCode + item.cnName + item.enName"
                                    :key="item.id"
                                    :title="getLanguageName(item, 'province')"
                                >{{ getLanguageName(item, 'province') }}</a-select-option>
                            </a-select>

                            <a-select
                                :placeholder="$t('common.city')"
                                v-model:value="addressData.cityRegion.cityId"
                                allow-clear
                                :loading="addressData.cityRegion.cityLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @select="handleCitySelect"
                            >
                                <a-select-option
                                    v-for="item in addressData.cityRegion.cities"
                                    :search-key="item.cnName + item.enName"
                                    :key="item.id"
                                    :title="getLanguageName(item)"
                                >{{ getLanguageName(item) }}</a-select-option>
                            </a-select>
                        </div>
                        <a-card size="small">
                            <a-tag
                                class="mb-1 mt-1"
                                v-for="item in data.formData.specialRegionCityRls"
                                closable
                                @close.prevent="handleCityTagClose(item)"
                            >{{ getLanguageName({ cnName: item.cityCnName, enName: item.cityEnName }) }}({{ getLanguageName({ cnName: item.countryCnName, enName: item.countryEnName }) }})</a-tag>
                        </a-card>
                    </a-form-item>

                    <a-form-item name="specialRegionCountyRls">
                        <div class="mb-2">
                            <label>
                                <b class="mr-2">{{ $t('common.county') }}</b>
                                <a-switch
                                    v-model:checked="data.formData.specialRegionCountyEnabled"
                                    size="small"
                                    @change="validRegionCountyRls"
                                ></a-switch>
                            </label>
                        </div>
                        <div>
                            <a-select
                                v-model:value="addressData.countyRegion.countryId"
                                :placeholder="$t('common.country')"
                                allow-clear
                                :loading="addressData.countryLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @change="handleCountryChangeForCountyRegion"
                            >
                                <a-select-option
                                    v-for="item in addressData.countries"
                                    :key="item.id"
                                    :value="item.id"
                                    :search-key="item.ioS2 + item.cnName + item.enName"
                                    :title="`${getLanguageName(item)}(${item.ioS2})`"
                                >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
                            </a-select>

                            <a-select
                                v-model:value="addressData.countyRegion.provinceId"
                                :placeholder="$t('common.province')"
                                allow-clear
                                :loading="addressData.countyRegion.provinceLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @change="handleProviceChangeForCountyRegion"
                            >
                                <a-select-option
                                    v-for="item in addressData.countyRegion.provinces"
                                    :search-key="item.abbrCode + item.cnName + item.enName"
                                    :key="item.id"
                                    :title="getLanguageName(item, 'province')"
                                >{{ getLanguageName(item, 'province') }}</a-select-option>
                            </a-select>

                            <a-select
                                :placeholder="$t('common.city')"
                                v-model:value="addressData.countyRegion.cityId"
                                allow-clear
                                :loading="addressData.countyRegion.cityLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @select="handleCityChangeForCountyRegion"
                            >
                                <a-select-option
                                    v-for="item in addressData.countyRegion.cities"
                                    :search-key="item.cnName + item.enName"
                                    :key="item.id"
                                    :title="getLanguageName(item)"
                                >{{ getLanguageName(item) }}</a-select-option>
                            </a-select>

                            <a-select
                                :placeholder="$t('common.county')"
                                v-model:value="addressData.countyRegion.countyId"
                                allow-clear
                                :loading="addressData.countyRegion.countyLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                                @select="handleCountySelect"
                            >
                                <a-select-option
                                    v-for="item in addressData.countyRegion.counties"
                                    :search-key="item.cnName + item.enName"
                                    :key="item.id"
                                    :title="getLanguageName(item)"
                                >{{ getCountyName(item) }}</a-select-option>
                            </a-select>
                        </div>
                        <a-card size="small">
                            <a-tag
                                class="mb-1 mt-1"
                                v-for="item in data.formData.specialRegionCountyRls"
                                closable
                                @close.prevent="handleCountyTagClose(item)"
                            >{{ getCountyName({ cnName: item.countyCnName, enName: item.countyEnName, cityCnName: item.cityCnName, cityEnName: item.cityEnName }) }}({{ getLanguageName({ cnName: item.countryCnName, enName: item.countryEnName }) }})</a-tag>
                        </a-card>
                    </a-form-item>

                    <a-form-item name="specialRegionPostCodeRls">
                        <div class="mb-2">
                            <label>
                                <b class="mr-2">{{ $t('common.post_code') }}</b>
                                <a-switch
                                    v-model:checked="data.formData.specialRegionPostEnabled"
                                    size="small"
                                    @change="validRegionPostCodeRls"
                                ></a-switch>
                            </label>
                        </div>
                        <div>
                            <a-select
                                v-model:value="addressData.postRegion.countryId"
                                :placeholder="$t('common.country')"
                                allow-clear
                                :loading="addressData.countryLoading"
                                :showSearch="true"
                                optionFilterProp="search-key"
                                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                                class="mr-2 mb-2"
                                style="width: 200px;"
                            >
                                <a-select-option
                                    v-for="item in addressData.countries"
                                    :key="item.id"
                                    :value="item.id"
                                    :search-key="item.ioS2 + item.cnName + item.enName"
                                    :title="`${getLanguageName(item)}(${item.ioS2})`"
                                >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
                            </a-select>
                            <a-input
                                v-model:value="addressData.postRegion.postStartWith"
                                style="width: 200px;"
                                class="mr-2 mb-2"
                                @pressEnter="handlePostAdd"
                                addonBefore="邮编开头为:"
                            ></a-input>

                            <a-button
                                size="small"
                                class="mr-3 mb-2"
                                :disabled="!(addressData.postRegion.countryId && addressData.postRegion.postStartWith)"
                                @click="handlePostAdd"
                            >+{{ $t('common.addition') }}</a-button>

                            <a-button
                                size="small"
                                class="mr-3 mb-2"
                                :disabled="!(addressData.postRegion.countryId)"
                                @click="handlePoseBathAddClick"
                            >+{{ $t('批量添加') }}</a-button>

                            <a-button
                                size="small"
                                class="mr-1 mb-2"
                                :disabled="!(data.formData.specialRegionPostCodeRls && data.formData.specialRegionPostCodeRls.length > 0)"
                                @click="handlePostTagClear"
                            >{{ $t('清除全部') }}</a-button>
                        </div>
                        <a-card size="small">
                            <a-tag
                                class="mb-1 mt-1"
                                v-for="item in data.formData.specialRegionPostCodeRls"
                                closable
                                @close.prevent="handlePostTagClose(item)"
                            >
                                {{ getLanguageName({ cnName: item.countryCnName, enName: item.countryEnName }) }}
                                {{ item.postStartWith }}
                                开头
                            </a-tag>
                        </a-card>
                    </a-form-item>
                </a-form>
            </div>
            <a-modal
                v-model:visible="data.bathAddPostModalVisible"
                :title="$t('批量添加')"
                :maskClosable="false"
                @ok="handlePoseBathAddSave"
            >
                <div>
                    <a-textarea
                        v-model:value="data.bathAddPostText"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                    ></a-textarea>
                </div>
                <div style="text-align:right;">
                    <small style="color:gray;">{{ bathPostArr.length }}</small>
                </div>
            </a-modal>
        </template>
        <template #contentFooter>
            <div class="text-center">
                <a-button
                    type="primary"
                    class="m-1"
                    @click="handleSaveRegion"
                    :loading="data.saving"
                >{{ $t('common.save') }}</a-button>
                <a-button class="m-1" @click="handleBackToRegions">{{ $t('common.cancel') }}</a-button>
            </div>
        </template>
    </Content>
</template>
<script>
import { Table, Button, Modal, Input, Select, Card, Row, Col, Form, Radio, Switch, InputNumber, Checkbox, Tag } from 'ant-design-vue';
import Content from '../components/Content.vue'
import { defineComponent, onMounted, reactive, toRefs, ref, onActivated, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n/index'
import { getName } from "../../utils/general"
import { useStore } from "vuex"
import { logisticsRegionType as regionTypeEnum, regionTag as regionTagEnum } from '../../enum/enum.json'
import { create, update, getById } from '../../api/modules/logistics/specialregion'
import { getAllList as getAllLogisticses } from '../../api/modules/logistics/index'
import { getCountriesParams, getProvinces, getCitys, getCountys, } from "../../api/modules/common/index"
import { useTab } from '@/hooks/tabs/index';
import { useRoute, useRouter } from "vue-router"
export default defineComponent({
    name: "special_region_edit",
    components: {
        ATable: Table,
        AButton: Button,
        AModal: Modal,
        AInput: Input,
        AInputGroup: Input.Group,
        ASelect: Select,
        ACard: Card,
        ARow: Row,
        ACol: Col,
        Content,
        ASelectOption: Select.Option,
        AForm: Form,
        AFormItem: Form.Item,
        ARadio: Radio,
        ARadioOption: Radio.Option,
        ARadioButton: Radio.Button,
        ARadioGroup: Radio.Group,
        ASwitch: Switch,
        AInputNumber: InputNumber,
        ACheckbox: Checkbox,
        ACheckboxGroup: Checkbox.Group,
        ATag: Tag,
        ATextarea: Input.TextArea,
    },
    setup() {
        const { getters } = useStore();
        const vueI18n = useI18n({ useScope: 'global' });
        const router = useRouter();
        const route = useRoute();
        const regionId = route.params.id;
        // const logisticsId = route.params.logisticsId;

        const refForm = ref();
        const { delVisitedRoute, } = useTab();
        const data = reactive({
            loading: false,
            saving: false,
            formData: {
                id: regionId,
                name: null,
                regionTags: [],

                specialRegionLogisticsRls:[],
                logisticsIds: [],

                regionTypes: [],

                specialRegionCountryEnabled: false,
                specialRegionCountryRls: [],

                specialRegionProvinceEnabled: false,
                specialRegionProvinceRls: [],

                specialRegionCityEnabled: false,
                specialRegionCityRls: [],

                specialRegionCountyEnabled: false,
                specialRegionCountyRls: [],

                specialRegionPostEnabled: false,
                specialRegionPostCodeRls: [],
            },
            logisticsesLoading: false,
            logisticses: [],
            bathAddPostModalVisible: false,
            bathAddPostText: "",
        });

        const addressData = reactive({
            countryLoading: false,
            countries: [],
            provinceRegion: {
                countryId: null,
                provinceId: null,
                provinceLoading: false,
                provinces: []
            },
            cityRegion: {
                countryId: null,
                provinceId: null,
                cityId: null,
                provinceLoading: false,
                provinces: [],
                cityLoading: false,
                cities: [],
            },
            countyRegion: {
                countryId: null,
                provinceId: null,
                cityId: null,
                countyId: null,
                provinceLoading: false,
                provinces: [],
                cityLoading: false,
                cities: [],
                countyLoading: false,
                counties: []
            },
            postRegion: {
                countryId: null,
                postStartWith: null,
            }
        });

        const addressCache = {
            provinces: {},
            cities: {},
            counties: {}
        };

        const calcLogisticsIds = ()=>{
            data.formData.logisticsIds = (data.formData.specialRegionLogisticsRls||[]).map(x=>x.logisticsId);
        }

        const calcRegionTypeEnabled = () => {
            if (data.formData.regionTypes) {
                data.formData.specialRegionCountryEnabled = data.formData.regionTypes.findIndex(x => x == regionTypeEnum.country) >= 0;
                data.formData.specialRegionProvinceEnabled = data.formData.regionTypes.findIndex(x => x == regionTypeEnum.province) >= 0;
                data.formData.specialRegionCityEnabled = data.formData.regionTypes.findIndex(x => x == regionTypeEnum.city) >= 0;
                data.formData.specialRegionCountyEnabled = data.formData.regionTypes.findIndex(x => x == regionTypeEnum.county) >= 0;
                data.formData.specialRegionPostEnabled = data.formData.regionTypes.findIndex(x => x == regionTypeEnum.post) >= 0;
            } else {
                data.formData.specialRegionCountryEnabled = false;
                data.formData.specialRegionProvinceEnabled = false;
                data.formData.specialRegionCityEnabled = false;
                data.formData.specialRegionCountyEnabled = false;
                data.formData.specialRegionPostEnabled = false;
            }
        }

        const getRegionTypes = () => {
            let types = [];
            if (data.formData.specialRegionCountryEnabled) {
                types.push(regionTypeEnum.country);
            }
            if (data.formData.specialRegionProvinceEnabled) {
                types.push(regionTypeEnum.province);
            }
            if (data.formData.specialRegionCityEnabled) {
                types.push(regionTypeEnum.city);
            }
            if (data.formData.specialRegionCountyEnabled) {
                types.push(regionTypeEnum.county);
            }
            if (data.formData.specialRegionPostEnabled) {
                types.push(regionTypeEnum.post);
            }
            return types;
        }

        const getLanguageName = (item, other = '') => {
            if (other === 'province') {
                return `${getName(item, getters.language)}(${item.abbrCode})`
            }
            return getName(item, getters.language);
        }

        const getCountyName = (item) => {
            let countyName = getLanguageName(item);
            let cityName = getLanguageName({ cnName: item.cityCnName, enName: item.cityEnName })
            return cityName
                ? (countyName + "-" + cityName)
                : countyName;
        };

        const getSpecialRegionInfo = () => {
            data.loading = true;
            getById(regionId)
                .then((res) => {
                    data.loading = false;
                    let { result } = res;
                    data.formData = result;
                    calcRegionTypeEnabled();
                    calcLogisticsIds();
                }).catch(() => {
                    data.loading = false;

                })
        }

        const getLogisticsList = () => {
            getAllLogisticses().then(({ result }) => {
                data.logisticses = result.map(x => ({
                    id: x.value,
                    code: x.text,
                    name: x.text1
                }));
            });
        }

        const getCountryList = () => {
            addressData.countryLoading = true;
            getCountriesParams()
                .then((res) => {
                    addressData.countries = res.result;
                }).finally(() => {
                    addressData.countryLoading = false;
                });
        }

        const getProvinceList = async (countryId) => {
            if (countryId) {
                var cacheKey = countryId + "";
                if (addressCache.provinces[cacheKey]) {
                    return addressCache.provinces[cacheKey];
                } else {
                    let { result } = await getProvinces({ countryId: countryId });
                    addressCache.provinces[cacheKey] = result;
                    return result;
                }
            } else {
                return [];
            }
        }

        const getCityList = async (provinceId) => {
            if (provinceId) {
                var cacheKey = provinceId + "";
                if (addressCache.cities[cacheKey]) {
                    return addressCache.cities[cacheKey];
                } else {
                    let { result } = await getCitys({ provinceId: provinceId });
                    addressCache.cities[cacheKey] = result;
                    return result;
                }
            } else {
                return [];
            }
        }

        const getCountyList = async (cityId) => {
            if (cityId) {
                var cacheKey = cityId + "";
                if (addressCache.counties[cacheKey]) {
                    return addressCache.counties[cacheKey];
                } else {
                    let { result } = await getCountys({ cityId: cityId });
                    addressCache.counties[cacheKey] = result;
                    return result;
                }
            } else {
                return [];
            }
        }

        //------------Country Region-------------//

        const validRegionCountryRls = () => {
            refForm.value.validate("regionCountryRls");
        }

        const handleCountryTagClose = (item) => {
            if (data.formData.specialRegionCountryRls) {
                data.formData.specialRegionCountryRls = data.formData.specialRegionCountryRls.filter(x => x.countryId != item.countryId);
                validRegionCountryRls();
            }
        }

        const handleCountrySelect = (key) => {
            if (key && data.formData.specialRegionCountryRls.findIndex(x => x.countryId == key) < 0) {
                let country = addressData.countries.find(x => x.id == key);
                if (country) {
                    data.formData.specialRegionCountryRls.push({
                        countryId: country.id,
                        countryCnName: country.cnName,
                        countryEnName: country.enName,
                    })
                    validRegionCountryRls();
                }
            }
        }

        //------------End Country Region-------------//


        //------------Province Region-------------//

        const validRegionProvinceRls = () => {
            refForm.value.validate("regionProvinceRls");
        }

        const handleCountryChangeForProvinceRegion = () => {
            addressData.provinceRegion.provinceLoading = true;
            getProvinceList(addressData.provinceRegion.countryId)
                .then((res) => {
                    addressData.provinceRegion.provinces = res;
                })
                .catch(() => {
                    addressData.provinceRegion.provinces = [];
                })
                .finally(() => {
                    addressData.provinceRegion.provinceLoading = false;
                    if (addressData.provinceRegion.provinces
                        && addressData.provinceRegion.provinces.findIndex(x => x.id == addressData.provinceRegion.provinceId) >= 0) {
                        //keep it
                    } else {
                        addressData.provinceRegion.provinceId = null;
                    }
                });
        }

        const handleProvinceSelect = (key) => {
            if (key && data.formData.specialRegionProvinceRls.findIndex(x => x.provinceId == key) < 0) {
                let item = addressData.provinceRegion.provinces.find(x => x.id == key);
                if (item) {
                    let country = addressData.countries.find(x => x.id == addressData.provinceRegion.countryId) || {};
                    data.formData.specialRegionProvinceRls.push({
                        provinceId: item.id,
                        provinceCnName: item.cnName,
                        provinceEnName: item.enName,
                        countryId: country.id,
                        countryCnName: country.cnName,
                        countryEnName: country.enName,
                    })
                    validRegionProvinceRls();
                }
            }
        }

        const handleProvinceTagClose = (item) => {
            if (data.formData.specialRegionProvinceRls) {
                data.formData.specialRegionProvinceRls = data.formData.specialRegionProvinceRls.filter(x => x.provinceId != item.provinceId);
                validRegionProvinceRls();
            }
        }
        //------------End Province Region-------------//


        //---------------City Region ---------------//

        const validRegionCityRls = () => {
            refForm.value.validate("regionCityRls");
        }

        const handleProviceChangeForCityRegion = () => {
            addressData.cityRegion.cityLoading = true;
            getCityList(addressData.cityRegion.provinceId)
                .then((res) => {
                    addressData.cityRegion.cities = res;
                })
                .catch(() => {
                    addressData.cityRegion.cities = [];
                })
                .finally(() => {
                    addressData.cityRegion.cityLoading = false;
                    if (addressData.cityRegion.cities
                        && addressData.cityRegion.cities.findIndex(x => x.id == addressData.cityRegion.cityId) >= 0) {
                        //keep it
                    } else {
                        addressData.cityRegion.cityId = null;
                    }
                });
        }

        const handleCountryChangeForCityRegion = () => {
            addressData.cityRegion.provinceLoading = true;
            getProvinceList(addressData.cityRegion.countryId)
                .then((res) => {
                    addressData.cityRegion.provinces = res;
                })
                .catch(() => {
                    addressData.cityRegion.provinces = [];
                })
                .finally(() => {
                    addressData.cityRegion.provinceLoading = false;
                    if (addressData.cityRegion.provinces
                        && addressData.cityRegion.provinces.findIndex(x => x.id == addressData.cityRegion.provinceId) >= 0) {
                        //keep it
                    } else {
                        addressData.cityRegion.provinceId = null;
                        handleProviceChangeForCityRegion();
                    }
                });
        }

        const handleCitySelect = (key) => {
            if (key && data.formData.specialRegionCityRls.findIndex(x => x.cityId == key) < 0) {
                let item = addressData.cityRegion.cities.find(x => x.id == key);
                if (item) {
                    let country = addressData.countries.find(x => x.id == addressData.cityRegion.countryId) || {};
                    data.formData.specialRegionCityRls.push({
                        cityId: item.id,
                        cityCnName: item.cnName,
                        cityEnName: item.enName,
                        countryId: country.id,
                        countryCnName: country.cnName,
                        countryEnName: country.enName,
                    });
                    validRegionCityRls();
                }
            }
        }

        const handleCityTagClose = (item) => {
            if (data.formData.specialRegionCityRls) {
                data.formData.specialRegionCityRls = data.formData.specialRegionCityRls.filter(x => x.cityId != item.cityId);
                validRegionCityRls();
            }
        }

        //---------------End City Region ---------------//

        //---------------County Region ---------------//

        const validRegionCountyRls = () => {
            refForm.value.validate("regionCountyRls");
        }

        const handleCityChangeForCountyRegion = () => {
            addressData.countyRegion.countyLoading = true;
            getCountyList(addressData.countyRegion.cityId)
                .then((res) => {
                    addressData.countyRegion.counties = res;
                })
                .catch(() => {
                    addressData.countyRegion.counties = [];
                })
                .finally(() => {
                    addressData.countyRegion.countyLoading = false;
                    if (addressData.countyRegion.counties
                        && addressData.countyRegion.counties.findIndex(x => x.id == addressData.countyRegion.countyId) >= 0) {
                        //keep it
                    } else {
                        addressData.countyRegion.countyId = null;
                    }
                });
        }

        const handleProviceChangeForCountyRegion = () => {
            addressData.countyRegion.cityLoading = true;
            getCityList(addressData.countyRegion.provinceId)
                .then((res) => {
                    addressData.countyRegion.cities = res;
                })
                .catch(() => {
                    addressData.countyRegion.cities = [];
                })
                .finally(() => {
                    addressData.countyRegion.cityLoading = false;
                    if (addressData.countyRegion.cities
                        && addressData.countyRegion.cities.findIndex(x => x.id == addressData.countyRegion.cityId) >= 0) {
                        //keep it
                    } else {
                        addressData.countyRegion.cityId = null;
                        handleCityChangeForCountyRegion();
                    }
                });
        }

        const handleCountryChangeForCountyRegion = () => {
            addressData.countyRegion.provinceLoading = true;
            getProvinceList(addressData.countyRegion.countryId)
                .then((res) => {
                    addressData.countyRegion.provinces = res;
                })
                .catch(() => {
                    addressData.countyRegion.provinces = [];
                })
                .finally(() => {
                    addressData.countyRegion.provinceLoading = false;
                    if (addressData.countyRegion.provinces
                        && addressData.countyRegion.provinces.findIndex(x => x.id == addressData.countyRegion.provinceId) >= 0) {
                        //keep it
                    } else {
                        addressData.countyRegion.provinceId = null;
                        handleProviceChangeForCountyRegion();
                    }
                });
        }

        const handleCountySelect = (key) => {
            if (key && data.formData.specialRegionCountyRls.findIndex(x => x.countyId == key) < 0) {
                let item = addressData.countyRegion.counties.find(x => x.id == key);
                if (item) {
                    let country = addressData.countries.find(x => x.id == addressData.countyRegion.countryId) || {};
                    data.formData.specialRegionCountyRls.push({
                        countyId: item.id,
                        countyCnName: item.cnName,
                        countyEnName: item.enName,
                        countryId: country.id,
                        countryCnName: country.cnName,
                        countryEnName: country.enName,
                        cityCnName: item.cityCnName,
                        cityEnName: item.cityEnName,
                        cityId: item.cityId,
                    });
                    validRegionCountyRls();
                }
            }
        }

        const handleCountyTagClose = (item) => {
            if (data.formData.specialRegionCountyRls) {
                data.formData.specialRegionCountyRls = data.formData.specialRegionCountyRls.filter(x => x.countyId != item.countyId);
                validRegionCountyRls();
            }
        }

        //---------------End County Region ---------------//

        //------------Post Region-------------//

        const validRegionPostCodeRls = () => {
            refForm.value.validate("regionPostCodeRls");
        }

        const handlePostAdd = () => {
            let countryId = addressData.postRegion.countryId;
            let postStartWith = addressData.postRegion.postStartWith;
            if (countryId && postStartWith
                && data.formData.specialRegionPostCodeRls.findIndex(x => x.countryId == countryId && x.postStartWith == postStartWith) < 0) {
                let country = addressData.countries.find(x => x.id == countryId);
                if (country) {
                    data.formData.specialRegionPostCodeRls.push({
                        countryId: country.id,
                        countryCnName: country.cnName,
                        countryEnName: country.countryEnName,
                        postStartWith: postStartWith
                    });
                    validRegionPostCodeRls();
                }
                addressData.postRegion.postStartWith = null;
            }
        }

        const handlePostTagClose = (item) => {
            if (data.formData.specialRegionPostCodeRls) {
                data.formData.specialRegionPostCodeRls = data.formData.specialRegionPostCodeRls.filter(x => !(x.countryId == item.countryId && x.postStartWith == item.postStartWith));
                validRegionPostCodeRls();
            }
        }

        const handlePostTagClear = () => {
            data.formData.specialRegionPostCodeRls = [];
            validRegionPostCodeRls();
        }

        const handlePoseBathAddClick = () => {
            data.bathAddPostText = "";
            data.bathAddPostModalVisible = true;
        }

        const handlePoseBathAddSave = () => {
            let countryId = addressData.postRegion.countryId;
            if (countryId && bathPostArr.value.length > 0) {
                let country = addressData.countries.find(x => x.id == countryId);
                if (country) {
                    let existedArr = data.formData.specialRegionPostCodeRls.filter(x => x.countryId == countryId);
                    let items = [];
                    bathPostArr.value.forEach(postStartWith => {
                        if (existedArr.length > 0) {
                            if (existedArr.findIndex(x => x.postStartWith == postStartWith) < 0) {
                                items.push({
                                    countryId: country.id,
                                    countryCnName: country.cnName,
                                    countryEnName: country.countryEnName,
                                    postStartWith: postStartWith
                                });
                            }
                        } else {
                            items.push({
                                countryId: country.id,
                                countryCnName: country.cnName,
                                countryEnName: country.countryEnName,
                                postStartWith: postStartWith
                            });
                        }
                    });
                    data.formData.specialRegionPostCodeRls = data.formData.specialRegionPostCodeRls.concat(items);
                    validRegionPostCodeRls();
                }
            }
            data.bathAddPostText = "";
            data.bathAddPostModalVisible = false;
        }

        const bathPostArr = computed(() => {
            if (data.bathAddPostText) {
                return Array.from(new Set(data.bathAddPostText.split(/[ ,;，\r\n]/).filter(x => !!x)));
            }
            return [];
        })
        //------------End Post Region-------------//

        const handleBackToRegions = () => {
            router.push({ name: 'special_region' });
            delVisitedRoute(route);
        }

        const handleSaveRegion = () => {
            refForm.value.validate()
                .then(async () => {
                    data.saving = true;
                    var postData = {};
                    Object.assign(postData, data.formData);
                    postData.regionTypes = getRegionTypes();
                    return regionId
                        ? update(postData)
                        : create(postData);
                })
                .then(() => {
                    data.saving = false;
                    //close current
                    handleBackToRegions();
                })
                .catch(() => {
                    data.saving = false;
                })
        }

        const regionRules = {
            name: [
                {
                    required: true,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t('名称')])
                    },
                    trigger: ['blur', 'change']
                }
            ],
            regionTags: [
                {
                    type: "array",
                    required: true,
                    validator: async (rule, value) => {
                        if (value && value.length > 0) {
                            return Promise.resolve();
                        }
                        return Promise.reject();
                    },
                    message: () => {
                        return vueI18n.t("common.required")
                    },
                    trigger: ['blur', 'change']
                }
            ],
            specialRegionCountryRls: [
                {
                    type: "array",
                    validator: async (rule, value) => {
                        if (data.formData.specialRegionCountryEnabled) {
                            if ((!value) || value.length <= 0) {
                                return Promise.reject();
                            }
                        }
                        return Promise.resolve();
                    },
                    message: () => {
                        return vueI18n.t("common.required")
                    }
                }
            ],
            specialRegionProvinceRls: [
                {
                    type: "array",
                    validator: async (rule, value) => {
                        if (data.formData.specialRegionProvinceEnabled) {
                            if ((!value) || value.length <= 0) {
                                return Promise.reject();
                            }
                        }
                        return Promise.resolve();
                    },
                    message: () => {
                        return vueI18n.t("common.required")
                    }
                }
            ],
            specialRegionCityRls: [
                {
                    type: "array",
                    validator: async (rule, value) => {
                        if (data.formData.specialRegionCityEnabled) {
                            if ((!value) || value.length <= 0) {
                                return Promise.reject();
                            }
                        }
                        return Promise.resolve();
                    },
                    message: () => {
                        return vueI18n.t("common.required")
                    }
                }
            ],
            specialRegionCountyRls: [
                {
                    type: "array",
                    validator: async (rule, value) => {
                        if (data.formData.specialRegionCountyEnabled) {
                            if ((!value) || value.length <= 0) {
                                return Promise.reject();
                            }
                        }
                        return Promise.resolve();
                    },
                    message: () => {
                        return vueI18n.t("common.required")
                    }
                }
            ],
            specialRegionPostCodeRls: [
                {
                    type: "array",
                    validator: async (rule, value) => {
                        if (data.formData.specialRegionPostEnabled) {
                            if ((!value) || value.length <= 0) {
                                return Promise.reject();
                            }
                        }
                        return Promise.resolve();
                    },
                    message: () => {
                        return vueI18n.t("common.required")
                    }
                }
            ]

        }

        onMounted(() => {
            if (regionId) {
                getSpecialRegionInfo();
            }
            getLogisticsList();
            getCountryList();
        })

        return {
            regionTypeEnum,
            regionTagEnum,

            refForm,
            regionId,
            data,
            addressData,
            regionRules,

            handleCountryTagClose,
            handleCountrySelect,
            validRegionCountryRls,

            handleCountryChangeForProvinceRegion,
            handleProvinceSelect,
            handleProvinceTagClose,
            validRegionProvinceRls,

            handleProviceChangeForCityRegion,
            handleCountryChangeForCityRegion,
            handleCitySelect,
            handleCityTagClose,
            validRegionCityRls,

            handleCityChangeForCountyRegion,
            handleProviceChangeForCountyRegion,
            handleCountryChangeForCountyRegion,
            handleCountySelect,
            handleCountyTagClose,
            validRegionCountyRls,


            handlePostAdd,
            handlePostTagClose,
            handlePoseBathAddClick,
            handlePoseBathAddSave,
            bathPostArr,
            validRegionPostCodeRls,
            handlePostTagClear,

            handleSaveRegion,
            handleBackToRegions,
            getLanguageName,
            getCountyName,
        }
    }
})

</script>
<style lang="less" scoped>
.border-b {
    border-bottom: 1px solid #f0f0f0;
}
:deep(.input-group-mid .ant-input-group-addon) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
:deep(.input-group-mid-number) {
    border-radius: 0;
    width: 100%;
}
:deep(.input-group-end-number) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
}
:deep(.input-group-start-number) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}
</style>
<template>
  <a-modal
    v-model:visible="state.visible"
    :title="$t('menu.logistics_channel_discount') + '-' + (state.formState.id ? $t('common.edit') : $t('common.create'))"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-spin :spinning="state.loading">
      <div class="pb-3 text-center"><span class="text-error">{{ $t('logistics.discount_tips') }}</span></div>
      <a-form
        ref="formRef"
        :model="state.formState"
        :rules="rules"
        :label-col="{ style: { width: '150px' } }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-item :label="$t('menu.supplier_channel')" name="supplierId">
          <a-select
            v-model:value="state.formState.supplierId"
            :disabled="!!state.formState.id"
            option-filter-prop="search-key"
            show-search
          >
            <a-select-option
              v-for="(item, index) in state.logisticsList"
              :key="index"
              :value="item.value"
              :title="`${item.text}(${item.text1})`"
              :search-key="item.text + item.text1"
            >{{ item.text }}({{ item.text1 }})</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('logistics.customer_level')" name="shopLevelId">
          <a-select
            v-model:value="state.formState.shopLevelId"
            optionFilterProp="search-key"
            show-search
          >
            <a-select-option
              v-for="(item, index) in state.shopLevelList"
              :key="index"
              :value="item.id"
              :title="item.name"
              :search-key="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('warehouse.discount_amount')" name="freightDiscountAmount">
          <a-input-group>
            <span class="ant-input-group-addon">{{ getCurrencySymbol }}</span>
            <a-input-number
              class="input-group-end-number"
              v-model:value="state.formState.freightDiscountAmount"
              :disabled="!state.formState.supplierId"
              :precision="2"
            ></a-input-number>
          </a-input-group>
          <div class="text-warning"><small>{{ $t('warehouse.discount_amount_tips') }}</small></div>
        </a-form-item>
        <a-form-item :label="$t('warehouse.discount')" name="freightDiscountRatio">
          <a-input-group>
            <a-input-number
              class="input-group-mid-number"
              :min="0"
              :max="1000"
              :precision="2"
              v-model:value="state.formState.freightDiscountRatio"
              :disabled="!state.formState.supplierId"
            ></a-input-number>
            <span class="ant-input-group-addon">%</span>
          </a-input-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { computed, defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, InputNumber, message, Modal, Select, Spin } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { setFormStateValue } from '@/utils/general.js';
import { getAllCanChooseSupplier } from "@/api/modules/supplier/addressapi.js";
import {
  getSupplierDiscountById,
  createSupplierDiscount,
  updateSupplierDiscount,
} from "@/api/modules/supplier/discount.js";
import { getShopLevelList } from "@/api/modules/customer/level/index.js";

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputGroup: Input.Group,
    AInputNumber: InputNumber,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: String,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);
    
    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      // 物流渠道
      logisticsList: [],
      // 等级列表
      shopLevelList: [],
      formState: {
        id: null,
        supplierId: null,
        shopLevelId: null,
        freightDiscountAmount: 0,
        freightDiscountRatio: 100,
      },
    })

    const rules = {
      supplierId: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("menu.supplier_channel")]),
      },
      shopLevelId: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.customer_level")]),
      },
      freightDiscountAmount: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.discount_amount")]),
      },
      freightDiscountRatio: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.discount")]),
      },
    };

    const getCurrencySymbol = computed(() => {
      let supplierId = state.formState.supplierId;
      if (supplierId) {
        let item = state.logisticsList.find(item => item.value === supplierId);
        if (item) {
          return item.text2;
        }
      }
      return '';
    });

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        if (!!data.id) {
          await updateSupplierDiscount(data);
        } else {
          delete data.id;
          await createSupplierDiscount(data);
        }
        message.success(vueI18n.t('common.succeed'));
        emit('confirm');
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit("update:visible", false);
    }

    const initModal = async (id = null) => {
      try {
        state.loading = true;
        let { result: shopLevelList } = await getShopLevelList();
        if (Array.isArray(shopLevelList)) {
          state.shopLevelList = shopLevelList;
        } else {
          state.shopLevelList = [];
        }

        let { result: logisticsList } = await getAllCanChooseSupplier();
        if (Array.isArray(logisticsList)) {
          state.logisticsList = logisticsList;
        } else {
          state.logisticsList = [];
        }

        state.formState.id = id;
        if (id) {
          let { result: discountInfo } = await getSupplierDiscountById({ id });
          if (discountInfo) {
            setFormStateValue(state.formState, discountInfo);
          }
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    watch(() => [props.visible, props.id], ([visible, id]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible
        if (visible) {
          nextTick(() => {
            formRef.value.resetFields();
            initModal(id);
          });
        }
      }
    }, { immediate: true })

    return {
      state,
      formRef,
      rules,
      getCurrencySymbol,
      handleConfirm,
      close,
    }
  }
})
</script>

<style lang="less" scoped>
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
:deep(.input-group-mid-number) {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.input-group-end-number) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
:deep(.input-group-start-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
</style>
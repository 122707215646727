import client from "../../client"

const Api = {

    //根据分页附加费配置
    getPagedList: "/api/LogisticsService/SurchargeConfig/GetPagedList",

    //根据物流方案，获取附加费配置
    getListByLogisticsId: "/api/LogisticsService/SurchargeConfig/GetListByLogisticsId",
    
    //根据Id获取附加费详情
    getById:"/api/LogisticsService/SurchargeConfig/GetById",

    //删除费用配置
    deleteById: "/api/LogisticsService/SurchargeConfig/Delete",

    //创建费用配置
    create:"/api/LogisticsService/SurchargeConfig/Create",

    //更新费用配置
    update:"/api/LogisticsService/SurchargeConfig/Update",
}

//根据分页附加费配置
export const getPagedList = ({ logisticsId, searchKey, sorting, skipCount, maxResultCount }) => {
    return client.request({
        url: Api.getPagedList,
        data: { logisticsId, searchKey, sorting, skipCount, maxResultCount },
        method: 'post'
    });
}


//根据物流方案，获取附加费配置
export const getListByLogisticsId = (logisticsId) => {
    return client.request({
        url: Api.getListByLogisticsId,
        //data: { logisticsId: logisticsId },
        params: { logisticsId: logisticsId },
        method: 'post'
    });
}


//根据Id获取详细
export const getById = (id) => {
    return client.request({
        url: Api.getById,
        params: { id: id },
        method: 'GET'
    });
}

//删除附加费模板
export const deleteById = (id) =>{
    return client.request({
        url: Api.deleteById,
        data: { ids:[id]},
        method: 'POST'
    });
}

//创建附加费模板, data模型比较复杂，请在接口文档中查看详细
export const create = (data) =>{
    return client.request({
        url: Api.create,
        data: data,
        method: 'POST'
    });
}

//更新附加费模板, data模型比较复杂，请在接口文档中查看详细
export const update = (data) =>{
    return client.request({
        url: Api.update,
        data: data,
        method: 'POST'
    });
}
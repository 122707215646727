<template>
  <a-spin :spinning="state.loading">
    <a-row :gutter="[0, 8]">
      <a-col v-for="(item, index) in state.list" :key="index">
        <a-tag style="cursor: pointer;" @click="$emit('confirm', item)">
          <span v-pre>${</span>{{ item.attributeName }}<span v-pre>}</span>
        </a-tag>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import { Col, Row, Spin, Tag } from 'ant-design-vue';
import { getShopNotificationAttributeList } from '@/api/modules/devops/notice.js';

export default defineComponent({
  emits: ['confirm'],
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ATag: Tag,
  },
  props: {
    type: {
      type: Number,
    }
  },
  setup (props) {
    const state = reactive({
      list: [],
      loading: false,
    });

    const handleGetAttributeList = () => {
      state.loading = true;
      getShopNotificationAttributeList({ notificationBusinessType: props.type })
        .then(({ result }) => {
          if (Array.isArray(result)) {
            state.list = result;
          } else {
            state.list = [];
          }
        })
        .catch(() => {})
        .finally(() => {
          state.loading = false;
        });
    }

    watch(() => props.type, (newVal, oldVal) => {
      if (!!newVal && newVal !== oldVal) {
        handleGetAttributeList();
      }
    }, { immediate: true });

    return {
      state,
    }
  }
})
</script>

<style scoped>

</style>
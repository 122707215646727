<template>
  <Content>
    <template #contentTitle>{{ $t('menu.warehouse_monitor_consignment_return_temporary_storage') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input
            style="width: 250px"
            v-model:value="searchState.packageNo"
            :placeholder="$t('warehouse.package_identification_information')"
            allow-clear
          ></a-input>
        </a-col>
        <a-col>
          <a-input
            style="width: 250px"
            v-model:value="searchState.warehouseRtsRegionRoute"
            :placeholder="$t('warehouse.temporary_storage_location_number')"
            allow-clear
          ></a-input>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.rtsStatus"
            :placeholder="$t('finance.status')"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in rtsStatusList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('rtsStatus', item))"
            >{{ $t($enumLangkey('rtsStatus', item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.warehouseId"
            optionFilterProp="search-key"
            :placeholder="$t('warehouse.consignment_code')"
            show-search
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in consignmentsList"
              :key="index"
              :value="item.value"
              :title="`${item.text1}(${item.text})`"
              :search-key="item.text1 + item.text"
            >{{ item.text1 }}({{ item.text }})</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId" ></CSearchShop>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.query') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 110 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #stagingDaysTitle>
          <span>{{ $t('warehouse.staging_days') }}</span>
        </template>
          <template #customer="{ record }">
            {{record.shopInfo?.shopName}}
            <div v-if="record.shopInfo?.shopNo">
              NO: {{record.shopInfo?.shopNo}}
            </div>
            <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
        <template #additionalIdentify="{ record }">
          {{ record.additionalIdentify || '-' }}
        </template>
        <template #packageImgs="{ record }">
          <a-row v-if="record.packageImgs.length > 0" :gutter="[8, 8]" :wrap="true">
            <a-col v-for="(item, index) in record.packageImgs" :key="index">
              <CFileDisplay :fileUrl="item" :ossHeight="1200" :ossWidth="1200"></CFileDisplay>
            </a-col>
          </a-row>
          <span v-else>-</span>
        </template>
        <template #warehouseRtsRegionRoute="{ record }">
          {{ record.warehouseRtsRegionRoute || '-' }}
        </template>
        <template #consignment="{ record }">
          <span v-if="record.warehouseNo">{{ record.warehouseNo }}({{ record.warehouseName }})</span>
          <span v-else>-</span>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #temporaryDays="{ record }">
          <span v-if="record.temporaryDays">{{ record.temporaryDays }}</span>
          <span v-else>-</span>
        </template>
        <template #rtsStatus="{ record }">
          <small v-if="record.rtsStatus === rtsStatusEnum.noMatch" class="text-error">{{ $t('warehouse.no_matching_return_information') }}</small>
          <small v-else-if="record.rtsStatus === rtsStatusEnum.matched">
            <div class="text-warning">{{ $t('warehouse.matched_return_information') }}</div>
            <div>{{ record.channelReturnPlanNo	 }}</div>
          </small>
          <small v-else-if="record.rtsStatus === rtsStatusEnum.completed" class="text-success">{{ $t('warehouse.return_operation_completed') }}</small>
          <a-typography-text type="secondary" v-else-if="record.rtsStatus === rtsStatusEnum.cancel" class="text-success">
            <small>{{ $t($enumLangkey('rtsStatus', record.rtsStatus)) }}</small>
          </a-typography-text>
          <a-typography-text type="secondary" v-else>
            <small v-if="record.destroyedType">{{ $t($enumLangkey('rtsDestroyedType', record.destroyedType)) }}</small>
            <span v-else>-</span>
          </a-typography-text>
        </template>
        <template #employee="{ record }">
          <CEmployeeInfo v-if="record.employee" :employee="record.employee" />
          <span v-else>-</span>
        </template>
        <template #lastModificationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown v-if="record.rtsStatus === rtsStatusEnum.noMatch">
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1" :record="record">
                  {{ $t($enumLangkey('returnPlanRecordStatus', returnPlanRecordStatusEnum.destruction)) }}
                </a-menu-item>
                <a-menu-item key="2" :record="record">
                  {{ $t('warehouse.modify_identifiable_information') }}
                </a-menu-item>
                <a-menu-item key="3" :record="record">
                  {{ $t('common.cancel') }}
                </a-menu-item>
              </a-menu>
            </template>
            <a-button>
              {{ $t('common.operate') }}
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </template>
      </a-table>

      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />

      <ModifyIdentifiableInformationModal
        v-model:visible="modifyModalState.visible"
        :record="modifyModalState.tempData"
        @confirm="getPageData"
      />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, onActivated, onMounted } from 'vue'
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Table,
  Typography,
  Dropdown,
  Menu,
  message,
} from "ant-design-vue";
import Content from '@/views/components/Content.vue'
import CPager from '@/views/components/CPager.vue'
import CSearchShop from "@/views/components/CSearchShop.vue"
import CPinTipsModal from '@/views/components/CPinTipsModal.vue'
import CEmployeeInfo from '@/views/components/CEmployeeInfo.vue'
import CFileDisplay from '@/views/components/CFileDisplay.vue'
import ModifyIdentifiableInformationModal from '../components/ModifyIdentifiableInformationModal.vue';
import { rtsStatus as rtsStatusEnum, returnPlanRecordStatus as returnPlanRecordStatusEnum } from '@/enum/enum.json'
import { getOptionWarehouses } from "@/api/modules/common/index";
import { getReturnTemporaryStorageListPaged, destroyRtsWithConsole, cancelRts } from '@/api/modules/warehouseMonitor/returnTemporaryStorage'

export default defineComponent({
  name: "warehouse_monitor_consignment_return_temporary_storage",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ATypographyText: Typography.Text,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CSearchShop,
    CPinTipsModal,
    CEmployeeInfo,
    CFileDisplay,
    ModifyIdentifiableInformationModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const refInput = ref(null);

    const state = reactive({
      configInfo: {
        destroyRtsTemporaryDays: 0,
        freeRtsDays: 0,
        currencySymbol: '',
        price: 0,
      },
      rtsStatusList: [],
      consignmentsList: [],
      searchState: {
        packageNo: null,
        warehouseRtsRegionRoute: null,
        rtsStatus: null,
        warehouseId: null,
        shopId:null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      modelState: {
        visible: false,
        loading: false,
        formState: {
          id: null,
          warehouseRtsRegionId: null,
        }
      },
      pinModalState: {
        visible: false,
        loading: false,
        dataTemp: null,
        dataKey: null,
      },
      modifyModalState: {
        visible: false,
        tempData: null,
      }
    })

    state.rtsStatusList = Object.values(rtsStatusEnum)

    const columns = [
      {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 130,
      },
      {
        dataIndex: 'packageNo',
        title: () => vueI18n.t('warehouse.package_identification_information'),
        width: 150,
      },
      {
        title: () => vueI18n.t('warehouse.additional_identification_information'),
        width: 150,
        slots: {
          customRender: 'additionalIdentify',
        }
      },
      {
        title: () => vueI18n.t('logistics.picture'),
        width: 150,
        slots: {
          customRender: 'packageImgs',
        }
      },
      {
        title: () => vueI18n.t('warehouse.temporary_storage_location_number'),
        width: 150,
        slots: {
          customRender: 'warehouseRtsRegionRoute',
        }
      },
      {
        title: () => vueI18n.t('warehouse.consignment_code'),
        width: 150,
        slots: {
          customRender: 'consignment',
        }
      },
      {
        title: () => vueI18n.t('warehouse.staging_time'),
        width: 150,
        slots: {
          customRender: 'creationTime',
        }
      },
      {
        width: 100,
        slots: {
          title: "stagingDaysTitle",
          customRender: 'temporaryDays',
        }
      },
      {
        title: () => vueI18n.t('finance.status'),
        width: 150,
        slots: {
          customRender: 'rtsStatus',
        }
      },
      {
        title: () => vueI18n.t('warehouse.staging_operator'),
        width: 150,
        slots: {
          customRender: 'employee',
        }
      },
      {
        title: () => vueI18n.t('logistics.update_time'),
        width: 150,
        slots: {
          customRender: 'lastModificationTime',
        }
      },
      {
        title: () => vueI18n.t('common.operate'),
        width: 150,
        slots: {
          customRender: 'operation',
        }
      },
    ]

    const handleShowPinModal = (record, key) => {
      state.pinModalState.visible = true
      state.pinModalState.dataTemp = record.id
      state.pinModalState.dataKey = key
    }

    const handleDestroy = (data) => {
      // 销毁
      state.pinModalState.loading = true
      destroyRtsWithConsole(data).then(() => {
        message.success(vueI18n.t('common.succeed'))
        state.pinModalState.visible = false
        getPageData();
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handleCancel = (data) => {
      // 取消
      state.pinModalState.loading = true
      cancelRts(data).then(() => {
        message.success(vueI18n.t('common.succeed'))
        state.pinModalState.visible = false
        getPageData();
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handlePinModalConfirm = (pinCode) => {
      const data = {
        id: state.pinModalState.dataTemp,
        pinCode,
      }
      switch (state.pinModalState.dataKey) {
        case '1':
          // 销毁
          handleDestroy(data);
          break;
        case '3':
          // 取消
          handleCancel(data);
          break;
        default:
          break;
      }
    }

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.searchStateCache,
        state.tableData.pageData
      );
      getReturnTemporaryStorageListPaged(data)
        .then(({ result }) => {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items.map(item => {
            item.loading = false
            return item
          });
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const getConsignments = async () => {
      getOptionWarehouses({ warehouseType: 2 }).then(({ result }) => {
        if (Array.isArray(result)) {
          state.consignmentsList = result
        }
      })
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case '1':
          // 销毁
          handleShowPinModal(record, key);
          break;
        case '2':
          // 修改识别信息
          state.modifyModalState.tempData = record;
          state.modifyModalState.visible = true;
          break;
        case '3':
          // 取消
          handleShowPinModal(record, key);
          break;
        default:
          break;
      }
    }

    onMounted(getConsignments)
    
    onActivated(handleInitSearch)

    return {
      ...toRefs(state),
      returnPlanRecordStatusEnum,
      columns,
      rtsStatusEnum,
      refInput,
      handlePinModalConfirm,
      handleSearch,
      handlePage,
      getPageData,
      handleMenuClick,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <a-modal
    v-model:visible="visible"
    :confirmLoading="loading"
    :centered="true"
    :mask-closable="false"
    :title="
      formState.id
        ? $t('account.amend') + $t('menu.logistics_discount')
        : $t('enum.task_type_1') + $t('menu.logistics_discount')
    "
    @ok="handleOk"
  >
    <div class="pb-3 text-center"><span class="text-error">{{ $t('logistics.discount_tips') }}</span></div>
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('logistics.customer_level')" name="shopLevelId">
        <a-select
          v-model:value="formState.shopLevelId"
          optionFilterProp="search-key"
          show-search
        >
          <a-select-option
            v-for="(item, index) in shopLevelList"
            :key="index"
            :value="item.id"
            :title="item.name"
            :search-key="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('warehouse.discount_amount')" name="freightDiscountAmount">
        <a-input-group>
          <span class="ant-input-group-addon">{{ currencySymbol }}</span>
          <a-input-number
            class="input-group-end-number"
            v-model:value="formState.freightDiscountAmount"
            :precision="2"
          ></a-input-number>
        </a-input-group>
        <div class="text-warning"><small>{{ $t('warehouse.discount_amount_tips') }}</small></div>
      </a-form-item>
      <a-form-item :label="$t('warehouse.discount')" name="freightDiscountRatio">
        <a-input-group>
          <a-input-number
            class="input-group-mid-number"
            :min="0"
            :max="1000"
            :precision="2"
            v-model:value="formState.freightDiscountRatio"
          ></a-input-number>
          <span class="ant-input-group-addon">%</span>
        </a-input-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue';
import { Modal, Select, Form, Input, InputNumber, message } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import {
  createLogisticsDiscount,
  updateLogisticsDiscount,
} from "@/api/modules/logistics/discount.js";
import { getShopLevelList } from "@/api/modules/customer/level/index.js";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputNumber: InputNumber,
    AInputGroup: Input.Group,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref();

    const state = reactive({
      visible: false,
      loading: false,
      currencySymbol: null,
      formState: {
        id: null,
        logisticsId: "",
        shopLevelId: null,
        freightDiscountAmount: 0,
        freightDiscountRatio: 100,
      },
      shopLevelList: [],
    });

    const rules = {
      shopLevelId: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.customer_level")]),
      },
      freightDiscountAmount: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.discount_amount")]),
      },
      freightDiscountRatio: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.discount")]),
      },
    };

    const handleOk = async () => {
      try {
        await formRef.value.validate();
        state.loading = true;
        const data = JSON.parse(JSON.stringify(state.formState))
        if (data.id) {
          await updateLogisticsDiscount(data)
        } else {
          await createLogisticsDiscount(data)
        }
        message.success(vueI18n.t('common.succeed'))
        state.visible = false
        emit("refresh");
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    const getShopLevelListArray = () => {
      getShopLevelList().then(({ result }) => {
        if (result) {
          state.shopLevelList = result;
        }
      })
    }

    const init = (logisticsId, record = null, currencySymbol) => {
      getShopLevelListArray();
      state.formState.logisticsId = logisticsId;
      state.formState.id = null;
      state.currencySymbol = currencySymbol;
      state.visible = true;
      nextTick(() => {
        formRef.value.resetFields()
        if (record) {
          state.formState.id = record.id;
          state.formState.shopLevelId = record.shopLevelId;
          state.formState.freightDiscountAmount = record.freightDiscountAmount;
          state.formState.freightDiscountRatio = record.freightDiscountRatio;
        }
      })
    };

    return {
      ...toRefs(state),
      formRef,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      rules,
      init,
      handleOk,
    };
  },
});
</script>

<style lang="less" scoped>
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
:deep(.input-group-mid-number) {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.input-group-end-number) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
:deep(.input-group-start-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
</style>
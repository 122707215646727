export default {
  return: {
    return_of_the_source: "退货来源",
    the_goods_warehouse: "收货仓库",
    return_package_no: "退货包裹编号",
    return_plan_no: "退货计划编号",
    returns_number: "退货数量",
    received_quantity: "已接收数量",
    the_parcel_status: "包裹状态",
    return_the_parcel: "退货包裹",
    identifiable_information: "可识别信息",
    approved_packing_information: "核定的包装信息",
    have_received: "已接收",
    destroyed: "已销毁",
    product_SKU: "产品SKU",
    returns_the_total: "退货总数",
    optional_services: "可选服务",
    return_service_fee: "退货服务费",
    standard_quality_inspection_service: "标准质检服务",
    additional_functional_quality_inspection_services: "附加功能性质检服务",
    quality_inspection_results: "质检结果",
    the_processing_results: "处理结果",
    destruction_handling_charge: "销毁操作费",
    deducted_automatically: "自动扣除",
    is_qualified_1: "合格",
    is_qualified_2: "不合格",
    trajectory_status: "轨迹状态",
    untraceable: "不可追踪",
  }
}
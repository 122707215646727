import client from "../../client";

const Api = {
  // 总结算信息
  getAllAmountList: "/api/SupplierService/PartnerBalance/GetAllAmountList",
  // 结算记录列表
  getManageListPaged:
    "/api/SupplierService/PartnerSettlementRecord/GetManageListPaged",
};

export const getAllAmountList = () => {
  return client.request({
    url: Api.getAllAmountList,
    params: {
      
    },
    method: "get",
  });
};

export const getManageListPaged = (data) => {
  return client.request({
    url: Api.getManageListPaged,
    data,
    method: "POST",
  });
};

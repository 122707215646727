<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.dev_notice_template') }}
    </template>
    <template #contentBody="wrap">
      <a-tabs v-model:activeKey="activeKey" @change="handleSearch">
        <a-tab-pane :key="notificationWayEnum.sms" :tab="$t('devops.sms_template')"></a-tab-pane>
        <a-tab-pane :key="notificationWayEnum.mail" :tab="$t('devops.mail_template')"></a-tab-pane>
      </a-tabs>
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]" wrap>
            <a-col>
              <a-select
                v-model:value="searchState.notificationBusinessType"
                :placeholder="$t('devops.notification_business_type')"
                style="width: 250px;"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in notificationBusinessTypeEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('notificationBusinessType', item))"
                >{{ $t($enumLangkey('notificationBusinessType', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button v-if="activeKey === notificationWayEnum.sms" type="primary" ghost @click="handleOpenSMSModal()">{{ $t('devops.create_sms_template') }}</a-button>
          <a-button v-else-if="activeKey === notificationWayEnum.mail" type="primary" ghost @click="handleOpenMailModal()">{{ $t('devops.create_mail_template') }}</a-button>
        </a-col>
      </a-row>

      <a-table
        class="mt-3"
        :columns="activeKey === notificationWayEnum.sms ? smsColumns : mailColumns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 110 }"
        :pagination="false"
        size="small"
        :loading="tableData.loading"
        :rowKey="(record) => record.id"
      >
        <template #notificationBusinessType="{ record }">
          {{ $t($enumLangkey('notificationBusinessType', record.notificationBusinessType)) }}
        </template>
        <template #phoneCountryCodes="{ record }">
          <a-row :gutter="[0, 8]">
            <a-col v-for="(item, index) in record.phoneCountryCodes" :key="index">
              <a-tag color="green">{{ item }}</a-tag>
            </a-col>
          </a-row>
        </template>
        <template #templateContent="{ record }">
          <a-typography-paragraph
            class="mb-0"
            :ellipsis="{ rows: 2 }"
            :content="record.templateContent || '-'"
          />
        </template>
        <template #operation="{ record }">
          <a-dropdown-button @click="handleOpenEditModal(record)">
            {{ $t('common.edit') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :key="1" :record="record" :disabled="record.disabled">{{ $t('common.delete') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>

      <EditSMSTemplateModal v-model:visible="editSMSTemplateModalState.visible" :type="editSMSTemplateModalState.type" :id="editSMSTemplateModalState.id" @confirm="handleSearch" />
      <EditMailTemplateModal v-model:visible="editMailTemplateModalState.visible" :type="editMailTemplateModalState.type" :id="editMailTemplateModalState.id" @confirm="handleSearch" />
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue';
import { Button, Col, Dropdown, message, Menu, Row, Select, Switch, Table, Typography, Tabs, Tag, Modal } from 'ant-design-vue';
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import EditSMSTemplateModal from './components/EditSMSTemplateModal.vue';
import EditMailTemplateModal from './components/EditMailTemplateModal.vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import {
  getShopSmsNotificationTemplateListPaged,
  deleteShopSmsNotificationTemplate,
  getShopMailNotificationTemplateListPaged,
  deleteShopMailNotificationTemplate,
} from '@/api/modules/devops/notice.js';
import {
  notificationBusinessType as notificationBusinessTypeEnum,
  notificationWay as notificationWayEnum,
} from '@/enum/enum.json';

export default defineComponent({
  name: "dev_notice_template",
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATable: Table,
    ASwitch: Switch,
    ATag: Tag,
    AButton: Button,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATypographyParagraph: Typography.Paragraph,
    Content,
    CPager,
    EditSMSTemplateModal,
    EditMailTemplateModal,
  },
  setup () {
    const route = useRoute();
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      activeKey: notificationWayEnum.sms,
      searchState: {
        notificationBusinessType: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    })
    
    const editSMSTemplateModalState = reactive({
      visible: false,
      id: null,
      type: null,
    })

    const editMailTemplateModalState = reactive({
      visible: false,
      id: null,
      type: null,
    })

    const smsColumns = [
      {
        dataIndex: "templateName",
        width: 200,
        title: () => vueI18n.t('logistics.template_name'),
      },
      {
        width: 200,
        title: () => vueI18n.t('devops.notification_business_type'),
        slots: {
          customRender: "notificationBusinessType",
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('devops.applicable_area_code'),
        slots: {
          customRender: "phoneCountryCodes",
        }
      },
      {
        dataIndex: "platformTemplateId",
        width: 200,
        title: () => vueI18n.t('devops.sms_platform_template_id'),
      },
      {
        width: 300,
        title: () => vueI18n.t('devops.template_content'),
        slots: {
          customRender: "templateContent",
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: "operation",
        }
      },
    ]

    const mailColumns = [
      {
        dataIndex: "templateName",
        width: 200,
        title: () => vueI18n.t('logistics.template_name'),
      },
      {
        width: 200,
        title: () => vueI18n.t('devops.notification_business_type'),
        slots: {
          customRender: "notificationBusinessType",
        }
      },
      {
        dataIndex: "templateSubject",
        width: 200,
        title: () => vueI18n.t('devops.mail_template_subject'),
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: "operation",
        }
      },
    ]

    const handleOpenSMSModal = (id = null, type = null) => {
      editSMSTemplateModalState.id = id;
      editSMSTemplateModalState.type = type;
      editSMSTemplateModalState.visible = true;
    }

    const handleOpenMailModal = (id = null, type = null) => {
      editMailTemplateModalState.id = id;
      editMailTemplateModalState.type = type;
      editMailTemplateModalState.visible = true;
    }

    const handleOpenEditModal = (record) => {
      if (state.activeKey === notificationWayEnum.sms) {
        handleOpenSMSModal(record.id, record.notificationBusinessType);
      } else if (state.activeKey === notificationWayEnum.mail) {
        handleOpenMailModal(record.id, record.notificationBusinessType);
      }
    }

    const handleDeleteMsgTemplate = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operate"),
        content: vueI18n.t("common.are_you_sure_delete"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return deleteShopSmsNotificationTemplate({ ids: [id] }).then(() => {
            message.success(vueI18n.t("common.succeed"));
            handleInitPage();
          });
        },
      });
    }

    const handleDeleteMailTemplate = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operate"),
        content: vueI18n.t("common.are_you_sure_delete"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return deleteShopMailNotificationTemplate({ ids: [id] }).then(() => {
            message.success(vueI18n.t("common.succeed"));
            handleInitPage();
          });
        },
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;

      switch (key) {
        case 1:
          // 删除
          if (state.activeKey === notificationWayEnum.sms) {
            handleDeleteMsgTemplate(record.id);
          } else if (state.activeKey === notificationWayEnum.mail) {
            handleDeleteMailTemplate(record.id);
          }
          break;
        default:
          break;
      }
    }

    const getPageList = async () => {
      try {
        state.tableData.loading = true;
        const data = Object.assign(
          {},
          state.tableData.pageData,
          state.searchStateCache
        );
        let res;
        if (state.activeKey === notificationWayEnum.sms) {
          res = await getShopSmsNotificationTemplateListPaged(data);
        } else if (state.activeKey === notificationWayEnum.mail) {
          res = await getShopMailNotificationTemplateListPaged(data);
        }
        let { result } = res;
        let { items = [], totalCount = 0 } = result;
        state.tableData.pageData.totalCount = parseInt(totalCount);
        state.tableData.tableList = items;
      } catch (error) {
        state.tableData.pageData.totalCount = 0;
        state.tableData.tableList = [];
      } finally {
        state.tableData.loading = false;
      }
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0
      state.tableData.pageData.currentIndex = 1
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      router.push('/devops/notice/template');
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    watch(() => route.query, (query) => {
      if ('t' in query && query.t) {
        // 填充
        let type = parseInt(query.t);
        if (!Number.isNaN(type)) {
          state.searchState.notificationBusinessType = type;
          Object.assign(state.searchStateCache, state.searchState);
        }
      }
    }, { immediate: true, deep: true });

    onMounted(handleInitPage);

    return {
      ...toRefs(state),
      notificationBusinessTypeEnum,
      notificationWayEnum,
      editSMSTemplateModalState,
      editMailTemplateModalState,
      smsColumns,
      mailColumns,
      handleOpenSMSModal,
      handleOpenMailModal,
      handleOpenEditModal,
      handleMenuClick,
      handleSearch,
      handlePage,
    }
  }
})
</script>

<style scoped>

</style>
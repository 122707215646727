<template>
  <Content>
    <template #contentTitle>{{ $t('menu.transport_shipment_cabin') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input
            style="width: 250px;"
            v-model:value="searchState.searchKey"
            :placeholder="$t('warehouse.cabin_name') + '/' + $t('warehouse.cabin_no') + '/' + $t('warehouse.cabinet_no') + '/' + $t('warehouse.booking_number')"
            allow-clear
          ></a-input>
        </a-col>
        <a-col>
          <a-select style="width: 250px;" v-model:value="searchState.shippingSpaceType" :placeholder="$t('warehouse.cabin_type')" allow-clear>
            <a-select-option
              v-for="(item, index) in shippingSpaceTypeEnum"
              :key="index"
              :title="$t($enumLangkey('shippingSpaceType', item))"
              :value="item"
            >{{ $t($enumLangkey('shippingSpaceType', item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select style="width: 250px;" v-model:value="searchState.transportType" :placeholder="$t('warehouse.type_of_shipping')" allow-clear>
            <a-select-option
              v-for="(item, index) in transportTypeEnum"
              :key="index"
              :title="$t($enumLangkey('transportType', item))"
              :value="item"
            >{{ $t($enumLangkey('transportType', item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px;"
            v-model:value="searchState.fromPortId"
            :loading="portLoading"
            :disabled="disabledPortSelect"
            :placeholder="$t('warehouse.port_of_departure')"
            option-filter-prop="search-key"
            show-search
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in portCache[searchState.transportType]"
              :key="index"
              :value="item.id"
              :title="item.portCode"
              :search-key="item.portCode + item.portName"
            >{{ item.portCode }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px;"
            v-model:value="searchState.toPortId"
            :loading="portLoading"
            :disabled="disabledPortSelect"
            :placeholder="$t('warehouse.port_of_destination')"
            option-filter-prop="search-key"
            show-search
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in portCache[searchState.transportType]"
              :key="index"
              :value="item.id"
              :title="item.portCode"
              :search-key="item.portCode + item.portName"
            >{{ item.portCode }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 110 }"
        :pagination="false"
        size="small"
        :loading="tableData.loading"
        :rowKey="(record) => record.id"
      >
        <template #cabinetTitle>
          <div>{{ $t('warehouse.cabin_name') }}</div>
          <div>{{ $t('warehouse.cabin_no') }}</div>
        </template>
        <template #portTitle>
          {{ $t('warehouse.port_of_departure') }} - {{ $t('warehouse.port_of_destination') }}
        </template>
        <template #meansOfTransportTitle>
          {{ $t('warehouse.name_of_ship') }}/{{ $t('logistics.flight_number') }}
        </template>
        <template #timeOfShipmentTitle>
          {{ $t('warehouse.voyage_no') }}/{{ $t('logistics.take_off_time') }}
        </template>
        <template #specificationTitle>
          {{ $t('common.volume') }}/{{ $t('common.weight') }}
        </template>
        <template #cabinetCustomer="{ record }">
          <div>{{ record.shippingSpaceName }}</div>
          <div>{{ record.shippingSpaceNo }}</div>
        </template>
        <template #shippingSpaceType="{ record }">
          {{ $t($enumLangkey('shippingSpaceType', record.shippingSpaceType)) }}
        </template>
        <template #transportType="{ record }">
          {{ $t($enumLangkey('transportType', record.transportType)) }}
        </template>
        <template #portCustomer="{ record }">
          <span v-if="record.fromPortCode && record.toPortCode">{{ record.fromPortCode }} - {{ record.toPortCode }}</span>
          <span v-else>-</span>
        </template>
        <template #meansOfTransportCustomer="{ record }">
          <span v-if="record.transportType === transportTypeEnum.maritimeTransport">{{ record.vessel || '-' }}</span>
          <span v-else-if="record.transportType === transportTypeEnum.airTransport">{{ record.flightNumber || '-' }}</span>
          <span v-else>-</span>
        </template>
        <template #timeOfShipmentCustomer="{ record }">
          <span v-if="record.transportType === transportTypeEnum.maritimeTransport">{{ record.voyageNumber || '-' }}</span>
          <span v-else-if="record.transportType === transportTypeEnum.airTransport">{{ $filters.utcToCurrentTime(record.cargoCutoffTime) }}</span>
          <span v-else>-</span>
        </template>
        <template #cabinInformation="{ record }">
          <div v-if="record.transportType === transportTypeEnum.maritimeTransport">{{ record.cabinetNo || '-' }}</div>
          <div>
            <span v-if="record.transportType === transportTypeEnum.maritimeTransport">{{ record.vesselCompany || '-' }}</span>
            <span v-if="record.transportType === transportTypeEnum.airTransport">{{ record.flightCompany || '-' }}</span>
            / {{ record.bookingNo || '-' }}
          </div>
        </template>
        <template #transportationInformation="{ record }">
          {{ record.transportPlanCount || '-' }} / {{ record.transportBoxesNum }} Canton
        </template>
        <template #specificationCustomer="{ record }">
          {{ cmCubicToM(record.transportVolume) }}m<sup>3</sup> / {{ gToKg(record.transportWeight) }}kg
        </template>
        <template #status="{ record }">
          {{ $t($enumLangkey('shippingSpaceStatus', record.status)) }}
        </template>
        <template #thirdPartyTrack="{ record }">
          <template v-if="record.thirdPartyTrack">
            <div>{{ getThirdPartyTracks(record.thirdPartyTrack) }}</div>
            <div style="font-size: 12px;">{{ $filters.utcToSpecificTime(record.thirdPartyTrack.trackTime, record.thirdPartyTrack.timezone, "yyyy-MM-DD") }}</div>
          </template>
          <span v-else>-</span>
        </template>
        <template #operation="{ record }">
          <router-link :to="'/transport/shipment/cabin/detail/' + record.id">
            <a-button type="primary" ghost>{{ $t('common.details') }}</a-button>
          </router-link>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, toRefs, watch } from 'vue';
import { Button, Col, Input, Row, Select, Table } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from 'vuex';
import { cmCubicToM, gToKg } from '@/utils/general.js';
import { getShippingSpaceConsoleListPaged, getPortList } from '@/api/modules/transport/cabin.js';
import {
  transportType as transportTypeEnum,
  shippingSpaceType as shippingSpaceTypeEnum,
} from '@/enum/enum.json';

export default defineComponent({
  name: 'transport_shipment_cabin',
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const state = reactive({
      searchState: {
        searchKey: null,
        shippingSpaceType: null,
        transportType: null,
        fromPortId: null,
        toPortId: null,
      },
      searchStateCache: {},
      portLoading: false,
      disabledPortSelect: true,
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const portCache = {
      [transportTypeEnum.maritimeTransport]: [],
      [transportTypeEnum.airTransport]: [],
    }

    const columns = [
      {
        width: 150,
        slots: {
          title: "cabinetTitle",
          customRender: "cabinetCustomer",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.cabin_type"),
        slots: {
          customRender: "shippingSpaceType",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.type_of_shipping"),
        slots: {
          customRender: "transportType",
        }
      },
      {
        width: 150,
        slots: {
          title: "portTitle",
          customRender: "portCustomer",
        }
      },
      {
        width: 150,
        slots: {
          title: "meansOfTransportTitle",
          customRender: "meansOfTransportCustomer",
        }
      },
      {
        width: 150,
        slots: {
          title: "timeOfShipmentTitle",
          customRender: "timeOfShipmentCustomer",
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.cabin_information"),
        slots: {
          customRender: "cabinInformation",
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.transport_info"),
        slots: {
          customRender: "transportationInformation",
        }
      },
      {
        width: 150,
        slots: {
          title: "specificationTitle",
          customRender: "specificationCustomer",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "status",
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.track_info"),
        slots: {
          customRender: "thirdPartyTrack",
        }
      },
      {
        width: 120,
        fixed: "right",
        align: "center",
        title: () => vueI18n.t("warehouse.operation"),
        slots: {
          customRender: "operation",
        }
      },
    ];

    const getPortData = () => {
      let transportType = state.searchState.transportType;
      state.portLoading = true;
      getPortList({ transportType }).then(({ result }) => {
        if (Array.isArray(result)) {
          portCache[transportType] = result;
        }
      }).catch(() => {}).finally(() => {
        state.portLoading = false;
      });
    }

    watch(() => state.searchState.transportType, (val, oldVal) => {
      if (!val) {
        state.disabledPortSelect = true;
        state.searchState.fromPortId = null;
        state.searchState.toPortId = null;
      } else {
        if (val !== oldVal) {
          state.searchState.fromPortId = null;
          state.searchState.toPortId = null;
        }
        state.disabledPortSelect = false;
        if (portCache[val].length === 0) {
          getPortData();
        }
      }
    }, { immediate: true })

    const getThirdPartyTracks = (info) => {
      if (getters.language === 'zh-CN') {
        return info.trackCnRemark;
      } else {
        return info.trackRemark;
      }
    }

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getShippingSpaceConsoleListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0
      state.tableData.pageData.currentIndex = 1
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    onActivated(handleInitPage);

    return {
      ...toRefs(state),
      cmCubicToM,
      gToKg,
      transportTypeEnum,
      shippingSpaceTypeEnum,
      portCache,
      columns,
      getThirdPartyTracks,
      handleSearch,
      handlePage,
      getPortData,
    }
  }
})
</script>

<style scoped></style>
import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 始发国家
  getSourceCountrys: "/api/WarehouseService/WaybillPlan/GetSupplierCountry",
  // 运单列表
  getWayBillListPaged: "/api/WarehouseService/WarehouseTaskMonitoring/GetWayBillListPaged",
  // 获取运单轨迹
  getWaybillTrackList: "/api/WarehouseService/WaybillPlan/GetWaybillTrackList",
  // 运单详情
  gettWayBill: "/api/WarehouseService/WarehouseTaskMonitoring/GetWayBill",
  // 取消运单
  cancelWayBill: "/api/WarehouseService/WarehouseTaskMonitoring/CancelWayBill",
  // 取消对账
  cancelReconciliation: "/api/WarehouseService/WarehouseTaskMonitoring/CancelReconciliation",
  // 导出运单列表
  exportWayBill: "/api/WarehouseService/WarehouseTaskMonitoring/ExportWayBill",
  // 面单获取对账详细
  getAccountRecordDetail: "/api/WarehouseService/WarehouseTaskMonitoring/GetAccountRecordDetail",
};

export const getSourceCountrys = () => {
  return client.request({
    url: Api.getSourceCountrys,
    method: "GET",
  });
};

export const getWayBillListPaged = (data) => {
  return client.request({
    url: Api.getWayBillListPaged,
    data,
    method: "POST",
  });
};

export const getWaybillTrackList = (params) => {
  return client.request({
    url: Api.getWaybillTrackList,
    params,
    method: "GET",
  });
};

export const gettWayBill = (params) => {
  return client.request({
    url: Api.gettWayBill,
    params,
    method: "GET",
  });
};

export const cancelWayBill = (data) => {
  return client.request({
    url: Api.cancelWayBill,
    data,
    method: "POST",
  });
};

export const cancelReconciliation = (data) => {
  return client.request({
    url: Api.cancelReconciliation,
    data,
    method: "POST",
  });
};

export const exportWayBill = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportWayBill);
  return url;
}

export const getAccountRecordDetail = (params) => {
  return client.request({
    url: Api.getAccountRecordDetail,
    params,
    method: "GET",
  });
};

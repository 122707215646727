<template>
  <Content>
    <template #contentTitle>{{ $t("logistics.freight_fee_config") }}-{{ data.id>0 ? $t("common.edit") : $t("common.create") }}</template>
    <template #contentBody>
      <div class="lg-row border-b pb-3 mb-3">
        <div class="content">
          <div class="d-inline-block">
            <span class="d-inline-block mr-2">{{ $t('logistics.logistics_scheme_name') }}:</span>
            <b class="d-inline-block mr-2"
               v-if="data.logisticsCode">{{ data.logisticsCode }} ({{ data.logisticsName }})</b>
            <span v-else>-</span>
          </div>
        </div>
      </div>
      <div>
        <a-row>
          <a-col :span="24"
                 :xxl="18">
            <a-form :model="data"
                    ref="refForm"
                    :rules="rules">
              <div class="border-b mb-3">
                <!-- 模板名称 -->
                <a-row>
                  <a-col :span="24"
                         :md="12"
                         :xl="8">
                    <a-form-item :label="$t('logistics.template_name')"
                                 name="name">
                      <a-input v-model:value="data.name"></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>

                <!-- 循环 -->
                <a-row v-for="(item,index) in data.feeTemplateItems"
                       :key="index">
                  <a-col :span="24"
                         :lg="6">
                    <a-form-item :name="['feeTemplateItems',index,'endWeight']"
                                 :rules="requiredAndMaxLargeThan0Rules">
                      <a-input-group compact>
                        <a-input :disabled="index==0"
                                 v-model:value="item.startWeight"
                                 style="width:40%"
                                 :min="0"
                                 type="number"
                                 class="input-group-mid input-group-left"
                                 :addon-after="'~'" />
                        <a-input style="width:40%"
                                 v-model:value="item.endWeight"
                                 :min="0"
                                 type="number"
                                 :addon-after="(data.isVolumeBilling?'m³':'kg')" />
                      </a-input-group>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24"
                         :lg="6">
                    <a-form-item :name="['feeTemplateItems',index]"
                                 :rules="requiredAndAllLargeThan0">
                      <a-input-group compact>
                        <a-input style="width: 50%;"
                                 type="number"
                                 :min="0"
                                 v-model:value="item.weightIntervalPrice"
                                 :addonBefore="data.currencyCode"
                                 :addon-after="'/'"
                                 class="input-group-mid" />
                        <a-input style="width: 40%"
                                 v-model:value="item.weightInterval"
                                 type="number"
                                 :min="0"
                                 :addon-after="(data.isVolumeBilling?'m³':'kg')" />
                      </a-input-group>
                    </a-form-item>
                  </a-col>
                  <a-col :span="24"
                         :lg="12">
                    <a-row>
                      <a-col :span="12"
                             v-if="index==0">
                        <a-row>
                          <a-col :span="6"
                                 :lg="7"
                                 :xl="6">
                            <a-form-item name="isFirstWeight">
                              <a-checkbox v-model:checked="item.isFirstWeight">
                                <span v-if="data.isVolumeBilling">{{$t('logistics.first_volume')}}</span>
                                <span v-else>{{$t('logistics.first_weight')}}</span>
                              </a-checkbox>
                            </a-form-item>
                          </a-col>
                          <a-col :span="18"
                                 :lg="17"
                                 :xl="18"
                                 v-if="item.isFirstWeight"
                                 style="width:237px">
                            <a-form-item :name="['feeTemplateItems',index,'firstWeightFee']"
                                         :rules="requiredAndLargeThan0">
                              <a-input-group compact>
                                <a-input style="width: 60%;"
                                         type="number"
                                         :min="0"
                                         v-model:value="item.firstWeightFee"
                                         :addonBefore="data.currencyCode"
                                         class="input-group-right" />
                              </a-input-group>
                            </a-form-item>
                          </a-col>
                        </a-row>
                      </a-col>
                      <a-col :span="6"
                             v-else>
                        <a-form-item>
                          <a-checkbox v-model:checked="item.isFirstWeightCanceled">

                            <span v-if="data.isVolumeBilling"> {{$t('logistics.first_volume_canceled')}}</span>
                            <span v-else> {{$t('logistics.first_weight_canceled')}}</span>

                          </a-checkbox>
                        </a-form-item>
                      </a-col>
                      <a-col :span="6"
                             v-if="index!=0">
                        <a-form-item>
                          <a-checkbox v-model:checked="item.isPreEach">{{$t('logistics.pre_each')}}</a-checkbox>
                        </a-form-item>
                      </a-col>
                      <a-col :span="6"
                             :lg="7">
                        <a-form-item :name="['feeTemplateItems',index,'isCancelFuelSurcharge']">
                          <a-checkbox v-model:checked="item.isFuelSurchargeCanceled">{{$t('logistics.fuel_surcharge_canceled')}}</a-checkbox>
                        </a-form-item>
                      </a-col>
                      <a-col :span="6"
                             :lg="2"
                             v-if="index!=0">
                        <a-form-item>
                          <a-button type="link"
                                    @click="deleteFormItem(index)">{{$t('common.delete')}}</a-button>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>

                <a-row>
                  <a-col>
                    <a-form-item>
                      <a-space>
                        <a-button @click="handleImportModalOpen">{{$t('导入')}}</a-button>
                        <a-button @click="addFormItem">{{$t('common.addition')}}</a-button>
                      </a-space>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row class="mb-3">
                  <a-col>
                    <small v-if="data.isVolumeBilling">{{$t('logistics.volume_freight_fee_config_cue_words')}}</small>
                    <small v-else>{{$t('logistics.freight_fee_config_cue_words')}}</small>
                  </a-col>
                </a-row>
              </div>
              <!-- 适用分区 -->
              <a-row>
                <a-col :span="24"
                       :md="12"
                       :xl="8">
                  <a-form-item :label="$t('logistics.effect_subarea')"
                               name="selectedRegion">
                    <a-select v-model:value="data.selectedRegion"
                              :allowClear='true'
                              mode="multiple"
                              style="width: 100%"
                              :options="
                                data.regionList">
                    </a-select>

                  </a-form-item>
                </a-col>
              </a-row>
              <a-row class="mb-3">
                <a-col>
                  <small>{{$t('logistics.subarea_warning_title')}}</small>
                </a-col>
              </a-row>
            </a-form>            
          </a-col>
        </a-row>
        <a-modal v-model:visible="importModalData.visible"
               :title="'快速导入'"
               :maskClosable="false"
               :width="400"
               @ok="handleImportModalOk">
               
            <a-form :model="importModalData"
                :label-col="{ span: 6 }">
              <a-form-item :label="'重量单位'"
                           name="isPound" v-if="!data.isVolumeBilling">
                <!-- <a-radio v-model:checked="importModalData.isPound">磅</a-radio>
                <a-radio v-model:checked="!importModalData.isPound">千克</a-radio> -->
                <a-radio-group v-model:value="importModalData.isPound">
                  <a-radio :value="true">磅(lbs)</a-radio>
                  <a-radio :value="false">千克(kg)</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item :label="'自动合并'"
                           name="autoMerge" >
                <a-checkbox v-model:checked="importModalData.autoMerge">合并相同价格的连续区间</a-checkbox>
              </a-form-item>
              
                  <a-form-item 
                           name="text">
                           <template #label>
                              <a-popover title="示例">
                                <template #content>
                                  <div><small>1.每行一条数据</small></div>
                                  <div><small>2.每条数据包含(重量或体积&nbsp;&nbsp;&nbsp;价格&nbsp;&nbsp;&nbsp;取消燃油[1为取消,0为不取消])</small></div>
                                  <div><small>例如:</small></div>
                                  <div>1.0&nbsp;&nbsp;&nbsp;12.00&nbsp;&nbsp;&nbsp;0</div>
                                  <div>2.0&nbsp;&nbsp;&nbsp;12.00&nbsp;&nbsp;&nbsp;0</div>
                                  <div>3.0&nbsp;&nbsp;&nbsp;12.00&nbsp;&nbsp;&nbsp;1</div>
                                </template>
                                <span>数据 <QuestionCircleOutlined /></span>
                              </a-popover>                             
                           </template>
                      <a-text-area v-model:value="importModalData.text"></a-text-area>
                  </a-form-item>
            </a-form>    
        </a-modal>
      </div>
    </template>
    <template #contentFooter>
      <div class="text-center">
        <a-button type="primary"
                  class="m-1"
                  @click="handleSaveConfig"
                  :loading="data.saving">{{$t('common.save')}}</a-button>
        <a-button class="m-1"
                  @click="handleBackSurchargeList">{{$t('common.cancel')}}</a-button>
      </div>
    </template>
  </Content>
</template>
<script>
import { Button, Input, Select, Card, Row, Col, Form, Radio, Checkbox, message, Modal, Popover, Space } from 'ant-design-vue';
import Content from '../components/Content.vue'
import { defineComponent, onMounted, reactive, ref, nextTick, computed, } from 'vue'
import { getById as getLogisticsById } from '../../api/modules/logistics/index'
import { updateFeeConfig, createFeeConfig, getFeeConfigInfo } from '../../api/modules/logistics/feetemplate'
import { getListByLogisticsId as getRegions } from '../../api/modules/logistics/region'
import { useTab } from '@/hooks/tabs/index'
import { useRoute, useRouter } from "vue-router"
import { useI18n } from 'vue-i18n/index';
import { gToKg, kgToG } from '../../utils/general';
import { logisticsCalcMethod } from '../../enum/enum.json';

export default defineComponent({
  name: "logistics_fee_config",
  components: {
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    Content,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ATextArea: Input.TextArea,
    AModal: Modal,
    APopover: Popover,
    ASpace: Space,
  },
  setup () {
    const router = useRouter();
    const route = useRoute();
    const vueI18n = useI18n({ useScope: 'global' });
    const refForm = ref();
    const { delVisitedRoute, } = useTab();
    const data = reactive({
      loading: false,
      saving: false,
      id: 0,//
      name: "",
      logisticsId: 0,
      logisticsName: "",
      logisticsCode: "",
      // currencySymbol: "",
      currencyCode: "",
      feeTemplateItems: [],
      selectedRegion: [],
      regionList: [],
      isVolumeBilling: false
    });

    const importModalData = reactive({
      visible: false,
      text: "",
      isPound: true,
      autoMerge: false,
      parseLines:[],
    })

    const funcGetFeeConfigInfo = () => {
      data.loading = true;
      getFeeConfigInfo(data.id).then((res) => {
        let { result } = res;

        if (result) {
          nextTick(() => {
            data.id = result.id;
            data.name = result.name;
            data.logisticsId = result.logisticsId;
            data.logisticsName = result.logisticsName;
            data.logisticsCode = result.logisticsCode;
            data.currencyCode = result.currencyCode;
            data.feeTemplateItems = result.feeTemplateItems;
            data.selectedRegion = result.logisticsRegions?.map(x => x.id);
            data.isVolumeBilling = result.logisticsCalcMethod == logisticsCalcMethod.volume;
            if (!data.isVolumeBilling) {
              _dataGToKg(data);
            }
          })
        }
      }).finally(() => {
        data.loading = false;
      })
    }

    //获取名称/代码
    const getLogisticsInfo = () => {
      data.loaging = true;
      getLogisticsById(data.logisticsId)
        .then((res) => {
          data.loading = false;
          let { result } = res;
          data.logisticsName = result.name;
          data.logisticsCode = result.code;
          data.currencyCode = result.currencyCode;
          data.isVolumeBilling = result.logisticsCalcMethod == logisticsCalcMethod.volume;
        })
        .catch(() => {
          data.loading = false;
        });
    }

    const getDefaultItem = () => {
      return {
        "startWeight": 0,
        "endWeight": 0,
        "isFirstWeight": false,
        "firstWeightFee": 0,
        "weightInterval": 0,
        "weightIntervalPrice": 0,
        "isFirstWeightCanceled": false,
        "isPreEach": false,
        "isFuelSurchargeCanceled": false,
        "feeTemplateId": 0
      }
    }

    const getRegionList = () => {
      getRegions(data.logisticsId).then((res) => {
        let { result } = res;
        data.regionList = result.map(x => ({ value: x.value, label: x.text }));
      })
    }

    const handleBackSurchargeList = () => {
      if (data.logisticsId > 0) {
        router.push({ name: 'logistics_fee_template', params: { logisticsId: data.logisticsId } });
      } else {
        router.push({ name: 'logistics_channel' });
      }
      delVisitedRoute(route);
    }

    const handleSaveConfig = () => {
      refForm.value.validate()
        .then((res) => {
          data.regionIds = data.selectedRegion;
          data.saving = true;
          var newData = JSON.parse(JSON.stringify(data));

          if (!data.isVolumeBilling) {
            _dataKgToG(newData);
          }

          if (data.id) {
            return updateFeeConfig(newData);
          } else {
            return createFeeConfig(newData);
          }
        }).then(() => {
          message.success(vueI18n.t("common.succeed"));
          router.push({ name: 'logistics_fee_template', params: { logisticsId: data.logisticsId } });
          delVisitedRoute(route);
        }).finally(() => {
          data.saving = false;
        })
    }

    const _dataGToKg = (object) => {
      object.feeTemplateItems.forEach((x) => {
        x.startWeight = gToKg(x.startWeight);
        x.endWeight = gToKg(x.endWeight);
        x.weightInterval = gToKg(x.weightInterval);
      })
    }

    const _dataKgToG = (object) => {
      object.feeTemplateItems.forEach((x) => {
        x.startWeight = kgToG(x.startWeight);
        x.endWeight = kgToG(x.endWeight);
        x.weightInterval = kgToG(x.weightInterval);
      })
    }

    const poundToKg = (val) => {
      // return (val * 2.2046226).toFixed(3) * 1;
      return (val * 0.4535924).toFixed(3) * 1;
    }

    const parseForImport = ()=>{
      var inputArr = (importModalData.text||"").split(/\n|\r/);
      var items = [];
      for(var i=0;i<inputArr.length;i++){
        var rows = (inputArr[i]||"").split(/ |\t|,/).filter(x=>x!="");
        if(rows.length>=2){
          var item = {
            line:i,
            weight:parseFloat(rows[0]),
            price:parseFloat(rows[1]),
            isFuelSurchargeCanceled:(rows.length > 2 && rows[2]=="1")
          };
          item.error = isNaN(item.weight) || isNaN(item.price);
          items.push(item);
        }else{
          items.push({
            line:i,
            error:true
          });
        }
      }
      
      var result = [];
      var noErrorItems = items.filter(x=>!x.error).sort((a,b)=>a.weight-b.weight);
      var autoMerge = importModalData.autoMerge;
      if(noErrorItems.length > 0){
        var cacheItem = {
          startWeight:0,
          endWeight:noErrorItems[0].weight,
          price:noErrorItems[0].price,
          isFuelSurchargeCanceled: noErrorItems[0].isFuelSurchargeCanceled
        }
        for(var i=1;i<noErrorItems.length;i++){
          if(autoMerge
          && noErrorItems[i].price == cacheItem.price
          && noErrorItems[i].isFuelSurchargeCanceled == cacheItem.isFuelSurchargeCanceled)
          {
            cacheItem.endWeight = noErrorItems[i].weight;
          }else{
            result.push(Object.assign({}, cacheItem));
            cacheItem.startWeight = cacheItem.endWeight;
            cacheItem.endWeight = noErrorItems[i].weight;
            cacheItem.price = noErrorItems[i].price;
            cacheItem.isFuelSurchargeCanceled = noErrorItems[i].isFuelSurchargeCanceled;
          }
        }
        result.push(Object.assign({}, cacheItem));
      }
      return {
          parseItems: items,
          rangeItems: result
        };
    }

    const convertToFeeItems = (rangeItems)=>{
        // if (!data.isVolumeBilling) {
        //     _dataKgToG(newData);
        //   }
        var feeItems = [];
        if(rangeItems && rangeItems.length>0){
          feeItems = rangeItems.map(x => {
            var item = getDefaultItem();            
            if((!data.isVolumeBilling) && importModalData.isPound)
            {
                item.startWeight = poundToKg(x.startWeight);
                item.endWeight = poundToKg(x.endWeight);
            }else{
                item.startWeight = x.startWeight;
                item.endWeight = x.endWeight;
            }
                item.weightInterval = item.endWeight;
                item.weightIntervalPrice = x.price;
                item.isFuelSurchargeCanceled = x.isFuelSurchargeCanceled;
            return item;
          });
        }
        
        //console.log(feeItems)
        if(feeItems.length>0){
          data.feeTemplateItems = feeItems;
        }
    }

    const handleImportModalOpen = ()=>{
      importModalData.text = "";
      importModalData.visible = true;
    }

    const handleImportModalOk = ()=>{
      var result = parseForImport();
      convertToFeeItems(result.rangeItems);
      importModalData.visible = false;
    }

    const addFormItem = () => {
      data.feeTemplateItems.push(getDefaultItem());
    }

    const deleteFormItem = (index) => {
      data.feeTemplateItems.splice(index, 1);
    }

    const requiredAndMaxLargeThan0Rules = [
      {
        type: "number",
        required: true,
        validator: async (rule, value) => {
          if (value > 0) {
            return Promise.resolve();
          }
          return Promise.reject('最大值需大于0');
        },
        trigger: ['blur', 'change'],
        //message: () => '需大于0',
      }
    ];

    const requiredAndAllLargeThan0 = [
      {
        type: "number",
        required: true,
        validator: async (rule, value) => {
          if (value.weightIntervalPrice > 0 && value.weightInterval > 0
          ) {
            return Promise.resolve();
          }
          return Promise.reject('都需大于0');
        },
        trigger: ['blur', 'change'],
      }
    ];

    const requiredAndLargeThan0 = [
      {
        type: "number",
        required: true,
        validator: async (rule, value) => {

          if (value > 0
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject('需大于0');
          }
        },
        trigger: ['blur', 'change'],
      }
    ];

    const rules = {
      name: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t('模板名称')])
          },
          trigger: ['blur', 'change']
        }
      ],

    }

    onMounted(() => {
      data.logisticsId = route.params.logisticsId;
      data.id = route.params.id;
      if (data.id) {
        funcGetFeeConfigInfo()
      } else {
        data.feeTemplateItems.push(getDefaultItem());
        data.feeTemplateItems.push(getDefaultItem());
        getLogisticsInfo();
      }
      getRegionList();
      // data.isVolumeBilling = true;
    })

    return {
      refForm,
      data,
      rules,
      requiredAndMaxLargeThan0Rules,
      requiredAndLargeThan0,
      requiredAndAllLargeThan0,
      handleSaveConfig,
      handleBackSurchargeList,
      addFormItem,
      deleteFormItem,

      importModalData,
      handleImportModalOpen,
      handleImportModalOk,
    }
  }
})
</script>
<style lang="less" scoped>
.border-b {
  border-bottom: 1px solid #f0f0f0;
}
.lg-row {
  display: flex;
  flex-wrap: wrap;
  // justify-content: stretch;
  align-items: stretch;
  .content {
    flex-grow: 1;
  }
}
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

:deep(.input-group-left .ant-input-group .ant-input) {
  //左边input 圆角
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
:deep(.input-group-right .ant-input-group .ant-input) {
  //左边input 圆角
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
import client from "../../client";

const Api = {
  //获得所有仓库
  getAllWarehouses: "/api/WarehouseService/Warehouse/GetList",

  //更改仓库状态
  updateIsActive: "/api/WarehouseService/Warehouse/UpdateIsActive",

  //获取序列号
  getNextSerialNo: "/api/WarehouseService/Warehouse/GetNextSerialNo",

  //创建仓库或物流中心
  create: "/api/WarehouseService/Warehouse/Create",

  //更新仓库或物流中心
  update: "/api/WarehouseService/Warehouse/Update",

  //获取仓库信息
  getById: "/api/WarehouseService/Warehouse/GetById",

  //获取包装费配置
  getPackageConfigList: "/api/WarehouseService/PackMaterialConfig/GetList",

  //更新包装费配置状态
  updatePackageConfigStatus:
    "/api/WarehouseService/PackMaterialConfig/UpdateIsActive",

  //更新包装费配置价格
  updatePackagePrice: "/api/WarehouseService/PackMaterialConfig/UpdatePrice",

  //获取操作费配置
  getOperatingConfigList: "/api/WarehouseService/OperatingFeeConfig/GetList",

  //更新操作费配置状态
  updateOperatingConfigStatus:
    "/api/WarehouseService/OperatingFeeConfig/UpdateIsActive",

  //更新操作费配置价格
  updateOperatingPrice: "/api/WarehouseService/OperatingFeeConfig/UpdatePrice",

  //获取存储费配置
  getStorageConfigList: "/api/WarehouseService/StorageFeeConfig/GetList",

  //更新存储费配置状态
  updateStorageConfigStatus:
    "/api/WarehouseService/StorageFeeConfig/UpdateIsActive",

  //更新存储费配置价格
  updateStoragePrice: "/api/WarehouseService/StorageFeeConfig/UpdatePrice",

  // 移库记录
  getTransferLog: "/api/WarehouseService/TransferWarehouseRecord/GetListPaged",

  // 库存管理
  getInventoryManagement: "/api/WarehouseService/Box/GetInventoryListPaged",

  //获取扫描异常记录
  getWhBoxHasException: "/api/WarehouseService/Box/GetWhBoxHasException",

  //箱数据修改记录
  boxCheckUpdateRecord:
    "/api/WarehouseService/BoxCheckUpdateRecord/GetListPaged",

  // 根据计划验证物流方案是否可用
  validationChannelIsSupportPlan: "/api/WarehouseService/WarehouseLogistics/ValidationChannelIsSupportPlan",

  // 自定义验证物流方案是否可用
  validationChannelIsSupportOther: "/api/WarehouseService/WarehouseLogistics/ValidationChannelIsSupportOther",
};

//更改仓库状态
export const updateIsActive = ({ id, isActive }) => {
  return client.request({
    url: Api.updateIsActive,
    data: { id, isActive },
    method: "post",
  });
};

//获取序列号
export const getNextSerialNo = ({ countryId, provinceId, warehouseType }) => {
  return client.request({
    url: Api.getNextSerialNo,
    params: { countryId, provinceId, warehouseType },
    disableAutoError: true,
    method: "GET",
  });
};

//创建仓库或物流中心
export const create = ({
  id,
  warehouseName,
  warehouseType,
  address,
  address2,
  countryId,
  provinceId,
  cityId,
  countyId,
  postCode,
  serialNo,
  timezone,
  linkMan,
  linkManPhone,
  phoneCountryCode,
  rtLinkMan,
  rtLinkManPhone,
}) => {
  return client.request({
    url: Api.create,
    data: {
      id,
      warehouseName,
      warehouseType,
      address,
      address2,
      countryId,
      provinceId,
      cityId,
      countyId,
      postCode,
      serialNo,
      timezone,
      linkMan,
      linkManPhone,
      phoneCountryCode,
      rtLinkMan,
      rtLinkManPhone,
    },
    method: "post",
  });
};

//更新仓库或物流中心
export const update = ({
  id,
  warehouseName,
  warehouseType,
  address,
  address2,
  countryId,
  provinceId,
  cityId,
  countyId,
  postCode,
  serialNo,
  timezone,
  linkMan,
  linkManPhone,
  phoneCountryCode,
  rtLinkMan,
  rtLinkManPhone,
}) => {
  return client.request({
    url: Api.update,
    data: {
      id,
      warehouseName,
      warehouseType,
      address,
      address2,
      countryId,
      provinceId,
      cityId,
      countyId,
      postCode,
      serialNo,
      timezone,
      linkMan,
      linkManPhone,
      phoneCountryCode,
      rtLinkMan,
      rtLinkManPhone,
    },
    method: "post",
  });
};

//获取信息
export const getById = (id) => {
  return client.request({
    url: Api.getById,
    params: { id },
    method: "GET",
  });
};

//获取包装费配置
export const getPackageConfigList = ({
  ids = [],
  searchKey,
  feeCode,
  materialTypeId,
  warehouseId,
  isDefault,
  isPallet,
}) => {
  return client.request({
    url: Api.getPackageConfigList,
    data: {
      ids,
      searchKey,
      feeCode,
      materialTypeId,
      warehouseId,
      isDefault,
      isPallet,
    },
    method: "post",
  });
};

//更新包装费状态
export const updatePackageConfigStatus = ({ id, warehouseId, isActive }) => {
  return client.request({
    url: Api.updatePackageConfigStatus,
    data: { id, warehouseId, isActive },
    method: "post",
  });
};

//更新包装费价格
export const updatePackagePrice = ({ warehouseId, id, price }) => {
  return client.request({
    url: Api.updatePackagePrice,
    data: { warehouseId, id, price },
    method: "post",
  });
};

//获取操作费配置
export const getOperatingConfigList = ({
  ids = [],
  searchKey,
  feeCode,
  materialTypeId,
  warehouseId,
  isDefault,
  isPallet,
  supportWarehouseType,
}) => {
  return client.request({
    url: Api.getOperatingConfigList,
    data: {
      ids,
      searchKey,
      feeCode,
      materialTypeId,
      warehouseId,
      isDefault,
      isPallet,
      supportWarehouseType,
    },
    method: "post",
  });
};

//更新操作费状态
export const updateOperatingConfigStatus = ({ id, warehouseId, isActive }) => {
  return client.request({
    url: Api.updateOperatingConfigStatus,
    data: { id, warehouseId, isActive },
    method: "post",
  });
};

//更新操作费价格
export const updateOperatingPrice = ({ warehouseId, id, price }) => {
  return client.request({
    url: Api.updateOperatingPrice,
    data: { warehouseId, id, price },
    method: "post",
  });
};

//获取存储费配置
export const getStorageConfigList = ({
  ids = [],
  searchKey,
  feeCode,
  materialTypeId,
  warehouseId,
  isDefault,
  isPallet,
}) => {
  return client.request({
    url: Api.getStorageConfigList,
    data: {
      ids,
      searchKey,
      feeCode,
      materialTypeId,
      warehouseId,
      isDefault,
      isPallet,
    },
    method: "post",
  });
};

//更新存储费状态
export const updateStorageConfigStatus = ({ id, warehouseId, isActive }) => {
  return client.request({
    url: Api.updateStorageConfigStatus,
    data: { id, warehouseId, isActive },
    method: "post",
  });
};

//更新存储费价格
export const updateStoragePrice = ({ warehouseId, id, price }) => {
  return client.request({
    url: Api.updateStoragePrice,
    data: { warehouseId, id, price },
    method: "post",
  });
};

export const getTransPage = (data) => {
  return client.request({
    url: Api.getTransPage,
    data: data,
    method: "post",
  });
};
// 移库记录 table
export const getTransferLog = (data) => {
  return client.request({
    url: Api.getTransferLog,
    data: data,
    method: "POST",
  });
};

// 库存管理 tableList
export const getInventoryManagement = (data) => {
  return client.request({
    url: Api.getInventoryManagement,
    data: data,
    method: "POST",
  });
};

// 获取扫描异常记录
export const getWhBoxHasException = ({
  skipCount,
  maxResultCount,
  sorting,
  warehouseType = 1,
}) => {
  return client.request({
    url: Api.getWhBoxHasException,
    data: { skipCount, maxResultCount, sorting, warehouseType },
    method: "POST",
  });
};

// 箱数据修改记录
export const boxCheckUpdateRecord = ({
  boxNo,
  boxOrProductModifyRecordType,
  boxOrProductModifyRecordCompareType,
  compareValue,
  boxOrProductModifyRecordUnitType,
  skipCount,
  maxResultCount,
  sorting,
  warehouseType = 1,
}) => {
  return client.request({
    url: Api.boxCheckUpdateRecord,
    data: {
      boxNo,
      boxOrProductModifyRecordType,
      boxOrProductModifyRecordCompareType,
      compareValue,
      boxOrProductModifyRecordUnitType,
      skipCount,
      maxResultCount,
      sorting,
      warehouseType,
    },
    method: "POST",
  });
};

//获得所有仓库
export const getAllWarehouses = (data) => {
  return client.request({
    url: Api.getAllWarehouses,
    data,
    method: "POST",
  });
};

// 根据计划验证物流方案是否可用
export const validationChannelIsSupportPlan = (data) => {
  return client.request({
    url: Api.validationChannelIsSupportPlan,
    data,
    method: "POST",
  });
};

// 自定义验证物流方案是否可用
export const validationChannelIsSupportOther = (data) => {
  return client.request({
    url: Api.validationChannelIsSupportOther,
    data,
    method: "POST",
  });
};

<template>
  <a-modal
    :title="$t('logistics.final_delivery')"
    v-model:visible="state.visible"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-spin :spinning="state.loading">
      <a-form
        ref="formRef"
        :model="state.formState"
        :label-col="{ style: { width: '150px' } }"
        :wrapper-col="{ span: 14 }"
        :rules="rules"
      >
        <a-form-item :label="$t('logistics.delivery_type')" name="endJourneyType">
          <a-select v-model:value="state.formState.endJourneyType" @change="handleTypeChange">
            <a-select-option
              v-for="(item, index) in transportPlanEndJourneyTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('transportPlanEndJourneyType', item))"
            >{{ $t($enumLangkey('transportPlanEndJourneyType', item)) }}</a-select-option>
          </a-select>
        </a-form-item>
        <template v-if="state.formState.endJourneyType === transportPlanEndJourneyTypeEnum.express">
          <a-form-item :label="$t('logistics.carrier')" :name="['express', 'expressCompanyName']">
            <a-input v-model:value="state.formState.express.expressCompanyName" :placeholder="$t('logistics.carrier')"></a-input>
          </a-form-item>
          <a-form-item :label="$t('logistics.single_number')" :name="['express', 'expressNo']">
            <a-input v-model:value="state.formState.express.expressNo" :placeholder="$t('logistics.single_number')"></a-input>
          </a-form-item>
        </template>
        <template v-else-if="state.formState.endJourneyType === transportPlanEndJourneyTypeEnum.transportationCompany">
          <a-form-item :label="$t('logistics.carrier')" :name="['transportationCompany', 'companyName']">
            <a-input v-model:value="state.formState.transportationCompany.companyName" :placeholder="$t('logistics.carrier')"></a-input>
          </a-form-item>
          <a-form-item :label="$t('logistics.phone')" :name="['transportationCompany', 'companyPhone']">
            <a-input-group style="display: flex;" compact>
              <a-select
                style="width: 100px"
                v-model:value="state.formState.transportationCompany.phoneCountryId"
                :loading="state.phoneCountryLoading"
                show-search
                option-filter-prop="search-key"
                option-label-prop="label"
                allow-clear
                :placeholder="$t('account.international_area_code')"
                @change="handleChangePhoneCountry"
              >
                <a-select-option
                  v-for="(item, index) in state.phoneCountryList"
                  :key="index"
                  :value="item.id"
                  :search-key="item.cnName + item.enName + item.ioS2 + item.telCode"
                  :label="'+' + item.telCode"
                  :title="'+' + item.telCode + '(' + getLanguageName(item) + ')'"
                >+{{ item.telCode + '(' + getLanguageName(item) }})</a-select-option>
              </a-select>
              <a-input style="flex: 1;"
                v-model:value="state.formState.transportationCompany.phone"
                :placeholder="$t('logistics.phone')"
                allow-clear
                @change="handleValidateCompanyPhone"
              ></a-input>
            </a-input-group>
          </a-form-item>
          <a-form-item :label="$t('logistics.single_number')" :name="['transportationCompany', 'trackingNumber']">
            <a-input v-model:value="state.formState.transportationCompany.trackingNumber" :placeholder="$t('logistics.single_number')"></a-input>
          </a-form-item>
        </template>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, message, Modal, Select, Spin } from 'ant-design-vue';
import { useI18n } from 'vue-i18n/index';
import { useStore } from "vuex";
import { getName, verifyMobilePhoneNumber } from "@/utils/general.js";
import { getGlobalCountrys } from '@/api/modules/common/index.js';
import { outForDelivery, getOutForDelivery } from "@/api/modules/transport/index.js";
import { transportPlanEndJourneyType as transportPlanEndJourneyTypeEnum } from '@/enum/enum.json';

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    AInputGroup: Input.Group,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    planId: String,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: 'global' });
    const { getters } = useStore();

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        endJourneyType: null,
      },
      phoneCountryLoading: false,
      phoneCountryList: [],
    })

    const validatePhone = (rule, value) => {
      if (state.formState.endJourneyType === transportPlanEndJourneyTypeEnum.transportationCompany) {
        let phoneCountryId = state.formState.transportationCompany.phoneCountryId;
        let phone = state.formState.transportationCompany.phone;
        if (!!phoneCountryId || !!phone) {
          if (!phoneCountryId) {
            return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('account.international_area_code')]));
          }
          if (!phone) {
            return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.phone')]));
          }
          let countryItem = state.phoneCountryList.find((item) => item.id === phoneCountryId);
          if (countryItem) {
            let countryCode = countryItem.ioS2;
            if (!verifyMobilePhoneNumber(countryCode, phone)) {
              return Promise.reject(vueI18n.t('common.p0_incorrect_format', [vueI18n.t('logistics.phone')]));
            }
          }
        }
      }
      return Promise.resolve();
    }

    const rules = {
      endJourneyType: [
        { required: true, message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.delivery_type')]) },
      ],
      express: {
        expressCompanyName: [
          { required: true, message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.carrier')]) },
        ],
        expressNo: [
          { required: true, message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.single_number')]) },
        ],
      },
      transportationCompany: {
        companyName: [
          { required: true, message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.carrier')]) },
        ],
        companyPhone: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        trackingNumber: [
          { required: true, message: () => vueI18n.t('common.p0_is_required',[vueI18n.t('logistics.single_number')])}
        ],
      }
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const handleTypeChange = () => {
      if (state.formState.endJourneyType === transportPlanEndJourneyTypeEnum.express) {
        delete state.formState.transportationCompany;
        state.formState.express = {
          expressCompanyName: null,
          expressNo: null,
        }
      } else if (state.formState.endJourneyType === transportPlanEndJourneyTypeEnum.transportationCompany) {
        delete state.formState.express;
        state.formState.transportationCompany = {
          companyName: null,
          telCode: null,
          phone: null,
          phoneCountryId: null,
          trackingNumber: null,
        }
      } else if (state.formState.endJourneyType === transportPlanEndJourneyTypeEnum.warehouseDirectDelivery) {
        delete state.formState.express;
        delete state.formState.transportationCompany;
      }
    }

    const handleValidateCompanyPhone = () => {
      formRef.value.validate([['transportationCompany', 'companyPhone']]);
    }

    const handleChangePhoneCountry = () => {
      let countryItem = state.phoneCountryList.find((item) => item.id === state.formState.transportationCompany.phoneCountryId);
      if (countryItem) {
        state.formState.transportationCompany.telCode = countryItem.telCode;
      }
      handleValidateCompanyPhone();
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState, { planId: props.planId });
        await outForDelivery(data);
        message.success(vueI18n.t("common.succeed"));
        emit("confirm");
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const getCountryPhoneList = () => {
      state.phoneCountryLoading = true;
      getGlobalCountrys().then(({ result }) => {
        if (Array.isArray(result)) {
          state.phoneCountryList = result;
        } else {
          state.phoneCountryList = [];
        }
      }).catch(() => {}).finally(() => {
        state.phoneCountryLoading = false;
      })
    }

    const getDetail = (planId) => {
      state.loading = true;
      getOutForDelivery({ planId }).then(({ result }) => {
        if (result) {
          let { endJourneyType, express, transportationCompany } = result;
          state.formState.endJourneyType = endJourneyType;
          if (endJourneyType === transportPlanEndJourneyTypeEnum.express) {
            delete state.formState.transportationCompany;
            state.formState.express = express;
          } else if (endJourneyType === transportPlanEndJourneyTypeEnum.transportationCompany) {
            delete state.formState.express;
            state.formState.transportationCompany = transportationCompany;
          } else {
            delete state.formState.express;
            delete state.formState.transportationCompany;
          }
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      })
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => [props.visible, props.planId], ([visible, planId]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          getCountryPhoneList();
          nextTick(() => {
            formRef.value.resetFields();
            if (planId) {
              getDetail(planId);
            }
          });
        }
      }
    }, { immediate: true });

    return {
      transportPlanEndJourneyTypeEnum,
      state,
      formRef,
      rules,
      getLanguageName,
      handleTypeChange,
      handleChangePhoneCountry,
      handleValidateCompanyPhone,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>
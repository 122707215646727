<template>
  <Content>
    <template #contentTitle>{{ $t('menu.open_platform_applys_company') }}</template>
    <template #contentBody="wrap">
      <a-row type="flex" justify="space-between">
        <a-col>{{ $t('warehouse.application') }}: <span v-if="programInfo">{{ programInfo.appName }}</span></a-col>
        <a-col><a-button type="default" @click="handleOpenModal">{{ $t('warehouse.authorized_tenant') }}</a-button></a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 70 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #company="{ record }">
          <div>{{ record.shopName }}</div>
          <div>{{ record.shopNo }}</div>
        </template>
        <template #authorizeInfos="{ record }">
          <span v-if="record.authorizeInfos">{{ getAuthorizeInfoName(record.authorizeInfos) }}</span>
          <span v-else>-</span>
        </template>
        <template #operate="{ record }">
          <a-button size="small" :loading="record.loading" @click="handleCancel(record)">{{ $t('warehouse.cancel_the_authorization') }}</a-button>
        </template>
      </a-table>
      <AuthorizedTenantModal ref="authorizedTenantModalRef" @refresh="getList" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, onMounted, createVNode } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { Button, Col, message, Row, Table, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import Content from "@/views/components/Content";
import AuthorizedTenantModal from "./components/AuthorizedTenantModal.vue";
import { getAuthorizeApplicationById } from '@/api/modules/openPlatform/applys';
import { getAuthorizeShopByAuthorizeAppId, cancelAppAuthShop } from "@/api/modules/openPlatform/applysCompany";

export default defineComponent({
  name: "open_platform_applys_company",
  components: {
    ARol:Row,
    ACol: Col,
    AButton: Button,
    ATable: Table,
    ARow: Row,
    ACol: Col,
    Content,
    AuthorizedTenantModal,
  },
  setup () {
    const route = useRoute();
    const vueI18n = useI18n({ useScope: "global" });
    const authorizedTenantModalRef = ref(null);

    const state = reactive({
      authorizeAppId: null,
      programInfo: null,
      tableData: {
        loading: false,
        tableList: [],
      },
    })
    
    const columns = [
      {
        width: 100,
        title: () => (vueI18n.t("account.company_name") + '/' + vueI18n.t("warehouse.company_id")),
        slots: {
          customRender: "company"
        }
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.details_of_authorization_items"),
        slots: {
          customRender: "authorizeInfos"
        }
      },
      {
        width: 50,
        title: "",
        slots: {
          customRender: "operate"
        }
      },
    ]

    const handleOpenModal = () => {
      authorizedTenantModalRef.value.show(state.authorizeAppId, state.programInfo?.appName);
    }

    const handleCancel = (record) => {
      Modal.confirm({
        icon: createVNode(ExclamationCircleOutlined),
        title: () => vueI18n.t("warehouse.operation_tips"),
        content: () => vueI18n.t("warehouse.do_you_confirm_the_cancellation_authorization"),
        onOk() {
          const data = {
            authorizeApplicationId: state.authorizeAppId,
            shopId: record.shopId
          }
          record.loading = true
          cancelAppAuthShop(data).then(() => {
            message.success(vueI18n.t('common.succeed'))
            getList()
          }).catch(() => {}).finally(() => {
            record.loading = false
          })
        }
      });
    }

    const getAuthorizeInfoName = (list) => {
      if (Array.isArray(list)) {
        return list.map(item => item.authorizeInfoName).filter(Boolean).join(', ')
      }
      return '-'
    }

    const getList = () => {
      state.tableData.loading = true;
        
      getAuthorizeShopByAuthorizeAppId({ authorizeAppId: state.authorizeAppId }).then(({ result }) => {
        if (result) {
          state.tableData.tableList = result.map(item => {
            item.loading = false
            return item
          })
        }
      }).catch(() => {}).finally(() => {
        state.tableData.loading = false;
      });
    }

    const getData = async () => {
      getAuthorizeApplicationById({ id: state.authorizeAppId }).then(({ result }) => {
        if (result) {
          state.programInfo = result
        }
      }).catch(() => {})
    };

    onMounted(() => {
      let id = route.params.id;
      state.authorizeAppId = id
      getData()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      authorizedTenantModalRef,
      handleOpenModal,
      handleCancel,
      getList,
      getAuthorizeInfoName,
    }
  }
})
</script>
import client from "../../client"

const Api = {
  // 货币配置创建
  createCurrencyConfigRelation: "/api/BasicDataService/CurrencyConfigRelation/Create",
  // 货币配置修改
  updateCurrencyConfigRelation: "/api/BasicDataService/CurrencyConfigRelation/Update",
  // 货币配置列表
  getListPaged: "/api/BasicDataService/CurrencyConfigRelation/GetListPaged",
  // 删除货币配置
  deleteCurrencyConfigRelation: "/api/BasicDataService/CurrencyConfigRelation/Delete",
  // 获取货币配置详情
  getCurrencyConfigRelationById: "/api/BasicDataService/CurrencyConfigRelation/GetById",
}

export const createCurrencyConfigRelation = (data) => {
  return client.request({
    url: Api.createCurrencyConfigRelation,
    data,
    method: 'POST'
  })
}

export const updateCurrencyConfigRelation = (data) => {
  return client.request({
    url: Api.updateCurrencyConfigRelation,
    data,
    method: 'POST'
  })
}

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: 'POST'
  })
}

export const deleteCurrencyConfigRelation = (data) => {
  return client.request({
    url: Api.deleteCurrencyConfigRelation,
    data,
    method: 'POST'
  })
}

export const getCurrencyConfigRelationById = (data) => {
  return client.request({
    url: Api.getCurrencyConfigRelationById,
    params: data,
    method: 'GET'
  })
}
import client from "../../../client";

const Api = {
  getList: "/api/WarehouseService/WarehouseShopDiscountConfig/GetListPaged",
  getShopInfo: "/api/ProductService/Shop/GetById",

  deleteDiscount: "/api/WarehouseService/WarehouseShopDiscountConfig/Delete",

  getWarehouses:
    "/api/WarehouseService/WarehouseShopDiscountConfig/GetNoConfigWarehouseOptionListByShopId",
  getDiscountList:
    "/api/WarehouseService/WarehouseShopDiscountConfig/GetWarehouseShopFeeConfigWithDiscount",

  add: "/api/WarehouseService/WarehouseShopDiscountConfig/Create",
  update: "/api/WarehouseService/WarehouseShopDiscountConfig/Update",

  //编辑的时候先获得页面信息
  getDiscountInfo: "/api/WarehouseService/WarehouseShopDiscountConfig/GetById",
};

export const getList = ({
  shopId,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: { shopId, searchKey, skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const getShopInfo = (id) => {
  return client.request({
    url: Api.getShopInfo,
    params: { id },
    method: "get",
  });
};

export const getWarehouses = (shopId, includeWarehouseIds) => {
  return client.request({
    url: Api.getWarehouses,
    data: { shopId, includeWarehouseIds },
    method: "post",
  });
};

export const getDiscountList = ({ shopId, warehouseId }) => {
  return client.request({
    url: Api.getDiscountList,
    data: { shopId, warehouseId },
    method: "post",
  });
};

export const add = ({
  name,
  shopId,
  warehouseId,
  warehouseShopDiscountFeeConfigRls,
}) => {
  return client.request({
    url: Api.add,
    data: { name, shopId, warehouseId, warehouseShopDiscountFeeConfigRls },
    method: "post",
  });
};

export const update = ({
  id,
  name,
  shopId,
  warehouseId,
  warehouseShopDiscountFeeConfigRls,
}) => {
  return client.request({
    url: Api.update,
    data: { id, name, shopId, warehouseId, warehouseShopDiscountFeeConfigRls },
    method: "post",
  });
};

export const deleteDiscount = (ids) => {
  return client.request({
    url: Api.deleteDiscount,
    data: { ids },
    method: "post",
  });
};

export const getDiscountInfo = (id) => {
  return client.request({
    url: Api.getDiscountInfo,
    params: { id },
    method: "get",
  });
};

<template>
  <a-modal
    v-model:visible="visible"
    :confirm-loading="confirmLoading"
    :title="$t('logistics.create_logistics_account')"
    :maskClosable="false"
    :width="600"
    @ok="handleSubmit"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item :label="$t('warehouse.name')" name="name">
          <a-input v-model:value="formState.name" allowClear />
        </a-form-item>
        <a-form-item :label="$t('finance.status')">
          <a-switch v-model:checked="formState.isActive" />
        </a-form-item>
        <a-form-item
          :label="$t('logistics.applicable_type')"
          name="isAddressValidationsType"
        >
          <a-select v-model:value="formState.isAddressValidationsType">
            <a-select-option
              v-for="(item, index) in isAddressValidationsTypeList"
              :key="index"
              :value="item.value"
              >{{ $t(item.label) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('logistics.third_party_channels')"
          name="thirdLogisticType"
        >
          <a-select v-model:value="formState.thirdLogisticType" allowClear>
            <a-select-option
              v-for="(item, index) in thirdLogisticTypeList"
              :key="index"
              :value="item.value"
              >{{
                $t($enumLangkey("thirdLogisticType", item.value))
              }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('logistics.applicable_delivery_method')"
          name="deliveryTypes"
        >
          <a-select
            v-if="selectMode === 'multiple'"
            v-model:value="formState.deliveryTypes"
            mode="multiple"
             @change="getDeliveryTypesList([])"
          >
            <a-select-option
              v-for="(item, index) in deliveryTypeList"
              :key="index"
              :value="item.value"
              >{{ $t($enumLangkey("deliveryWay", item.value)) }}</a-select-option
            >
          </a-select>
          <a-select v-else v-model:value="formState.deliveryTypes" @change="getDeliveryTypesList([])">
            <a-select-option
              v-for="(item, index) in deliveryTypeList"
              :key="index"
              :value="item.value"
              >{{ $t($enumLangkey("deliveryWay", item.value)) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('logistics.data')" name="jsonData">
          <a-textarea v-model:value="formState.jsonData" :rows="6" allowClear />
        </a-form-item>
        <a-form-item :label="$t('menu.logistics_channel')">
          <a-select v-model:value="formState.logisticsIds" mode="multiple">
            <a-select-option
              v-for="(item, index) in logisticsList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  nextTick,
  watch,
} from "vue";
import { Modal, Form, Input, Select, Switch, message, Spin } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import {
  createLogisticsAccount,
  updateLogisticsAccount,
  getLogisticsAccountById,
  filterByDeliveryTypes,
} from "@/api/modules/logistics/account";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASwitch: Switch,
    ATextarea: Input.TextArea,
    ASpin: Spin,
  },
  props: {
    thirdLogisticTypeList: {
      type: Array,
      default: () => [],
    },
    deliveryTypeList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        id: null,
        name: "",
        isActive: true,
        isAddressValidationsType: null,
        thirdLogisticType: null,
        deliveryTypes: [],
        jsonData: "",
        logisticsIds: [],
      },
      selectMode: "multiple",
      isAddressValidationsTypeList: [
        { label: "logistics.address_verification", value: 1 },
        { label: "logistics.print_sheet", value: 0 },
      ],
      logisticsList: [],
    });

    const validateIsAddress = (rule, value) => {
      if (Number.isNaN(parseInt(value))) {
        return Promise.reject(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("logistics.applicable_type"),
          ])
        );
      }
      return Promise.resolve();
    };

    const validateDeliveryTypes = (rule, value) => {
      if (state.selectMode) {
        if (!value.length) {
          return Promise.reject(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.applicable_delivery_method"),
            ])
          );
        }
      } else {
        if (!value) {
          return Promise.reject(
            vueI18n.t("common.p0_is_required", [
              vueI18n.t("logistics.applicable_delivery_method"),
            ])
          );
        }
      }
      return Promise.resolve();
    };

    const rules = {
      name: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("warehouse.name"),
        ]),
        trigger: "blur",
      },
      isAddressValidationsType: {
        required: true,
        validator: validateIsAddress,
        trigger: "blur",
      },
      deliveryTypes: {
        required: true,
        validator: validateDeliveryTypes,
        trigger: "blur",
      },
      jsonData: {
        required: true,
        message: vueI18n.t("common.p0_is_required", [
          vueI18n.t("logistics.data"),
        ]),
        trigger: "blur",
      },
    };

    const handleSubmit = async () => {
      try {
        await formRef.value.validate();
        const data = JSON.parse(JSON.stringify(state.formState));
        data.isAddressValidationsType = Boolean(data.isAddressValidationsType);
        if (!Array.isArray(data.deliveryTypes)) {
          data.deliveryTypes = [data.deliveryTypes]
        }
        state.confirmLoading = true;
        if (data.id) {
          await updateLogisticsAccount(data);
        } else {
          delete data.id
          await createLogisticsAccount(data);
        }
        message.success(vueI18n.t("common.succeed"));
        state.visible = false;
        emit("refresh");
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    };

    const handleFormSelectChange = () => {
      if (
        state.formState.isAddressValidationsType === 0 &&
        !state.formState.thirdLogisticType
      ) {
        state.selectMode = "";
        state.formState.deliveryTypes = "";
      } else {
        state.selectMode = "multiple";
        state.formState.deliveryTypes = [];
      }
      state.formState.logisticsIds = []
      state.logisticsList = []
    };

    const getDeliveryTypesList = (arr = []) => {
      let deliveryTypes = state.formState.deliveryTypes
      if (!Array.isArray(deliveryTypes)) {
        if (!deliveryTypes) {
          return
        } else {
          deliveryTypes = [deliveryTypes]
        }
      } else {
        if (!deliveryTypes.length) {
          return
        }
      }
      deliveryTypes = JSON.parse(JSON.stringify(deliveryTypes))
      filterByDeliveryTypes({ deliveryTypes }).then((res) => {
        let { result } = res;
        state.logisticsList = result.map((item) => {
          return {
            value: item.value,
            label: item.text1,
            enName: item.text,
            cnName: item.text1,
          };
        });
        nextTick(() => {
          state.formState.logisticsIds = arr
        })
      });
    }

    const init = (id) => {
      state.visible = true;
      state.formState.id = id
      nextTick(() => {
        formRef.value.resetFields();
        state.formState.logisticsIds = []
      })
      if (id) {
        state.loading = true
        getLogisticsAccountById({ id }).then((res) => {
          let { result } = res
          if (result) {
            for (const key in result) {
              if (Object.hasOwnProperty.call(state.formState, key)) {
                state.formState[key] = result[key];
              }
            }
            state.formState.isAddressValidationsType = state.formState
              .isAddressValidationsType
              ? 1
              : 0;
            handleFormSelectChange();
            nextTick(() => {
              state.formState.deliveryTypes = result.deliveryTypes
              let logisticsIds = result.logisticsList.map(
                (item) => item.id
              );
              getDeliveryTypesList(logisticsIds)
            })
          }
          state.loading = false
        })
      }
    };

    watch(
      () => state.formState.isAddressValidationsType,
      () => {
        handleFormSelectChange();
      }
    );

    watch(
      () => state.formState.thirdLogisticType,
      () => {
        handleFormSelectChange();
      }
    );

    return {
      ...toRefs(state),
      formRef,
      rules,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      init,
      handleSubmit,
      getDeliveryTypesList,
    };
  },
});
</script>

import client from "../client"
import settings from "../../siteconfigs/index.js"

const Api = {
  getPublicKey:"/api/Login/GetPublicKey",
  login: "/api/Login/ManagerRequestToken",
  // login: "/api/Login/WarehouserRequestToken",
  logout: "/api/WarehouseService/ManagerAccount/LogOut",
  // getUserInfo: "/api/WarehouseService/Account/GetWarehouseUser",
  getUserInfo: "/api/WarehouseService/ManagerAccount/GetManagerUser",

  getMenusOfUser: "/api/URCSecurityService/Security/GetGreaterThanFunctionMenuOfUser",
}

export const getPublicKey = () => {
  return client.request({
    url: Api.getPublicKey,
    method: 'GET'
  })
}

//userName 和 username 兼容不同字段名称
export const login = ({ userName, username, password, isRsaPassword = false, warehouseType = 1 }) => {
  return client.request({
    url: Api.login,
    data: { userName: userName || username, password, isRsaPassword, warehouseType },
    method: 'post'
  })
}

export const logout = data => {
  return client.request({
    url: Api.logout,
    data: data,
    method: 'post'
  })
}

export const getUserInfo = () => {
  return client.request({
    url: Api.getUserInfo,
    method: 'post'
  })
}

export const getMenusOfUser = ()=>{
  return client.request({
    url: Api.getMenusOfUser,
    params:{appId:settings.appId},
    method: 'get'
  })
}
import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
    
  getAddShipmentDetail:
    "/api/WarehouseService/TransitConsoleInBound/Get",
  getPackingList:
    "/api/WarehouseService/TransitConsoleInBound/GetShipmentBoxPagedList",
  getShipmentList:
    "/api/WarehouseService/TransitConsoleInBound/GetShipmentPagedList",

  getPrintBoxSETB:
    "/api/WarehouseService/TransitShipmentPrint/PrintSEShpimentBox/SeShipmentBoxLabel.pdf",
  getShipmentDetail:
    "/api/WarehouseService/TransitConsoleInBound/GetShipmentDetailPagedList",
  //货件批量打印
  getPrintShipmentSETableBatch:
    "/api/WarehouseService/TransitShipmentPrint/BatchPrintSEShpiment/BatchSeShipmentLabel.pdf",
  //货件的标签
  getPrintShipmentSETable:
    "/api/WarehouseService/TransitShipmentPrint/PrintSEShpiment/SeShipmentLabel.pdf",
  //货件的所有箱标签
  getPrintShipmentInSETBAll:
    "/api/WarehouseService/TransitShipmentPrint/BatchPrintSEShpimentBox/BatchSeShipmentBoxLabel.pdf",


  cancelPlan: "/api/WarehouseService/TransitOutBoundConsole/Cancel",

  // updateAppointmentTime:
  //   "/api/WarehouseService/TransitUserInBound/UpdateWareZoneAppointmentDate",
};


export const getAddShipmentDetail = (planId) => {
  return client.request({
    url: Api.getAddShipmentDetail,
    params: { planId },
    method: "get",
  });
};

export const getShipmentList = ({
  planId,
  name,
  seShipmentNo,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getShipmentList,
    data: { planId, name, seShipmentNo, skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const getPackingList = ({
  planId,
  containerBoxStatus,
  boxType,
  inWarehouseErrorCustomerType,
  shipmentName,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getPackingList,
    data: {
      planId,
      containerBoxStatus,
      boxType,
      inWarehouseErrorCustomerType,
      shipmentName,
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const getPrintBoxSETB = ({ planId, shipmentId, shipmentDetailId }) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getPrintBoxSETB);
  url = urlHelper.addQueryString(url, "planId", planId);
  url = urlHelper.addQueryString(url, "shipmentId", shipmentId);
  url = urlHelper.addQueryString(url, "shipmentDetailId", shipmentDetailId);
  return url;
};

export const getPrintShipmentSETableBatch = (planId) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.getPrintShipmentSETableBatch
  );
  url = urlHelper.addQueryString(url, "planId", planId);
  return url;
};

export const getPrintShipmentSETable = ({ planId, shipmentId }) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.getPrintShipmentSETable
  );
  url = urlHelper.addQueryString(url, "planId", planId);
  url = urlHelper.addQueryString(url, "shipmentId", shipmentId);
  return url;
};

export const getPrintShipmentInSETBAll = ({ planId, shipmentId }) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.getPrintShipmentInSETBAll
  );
  url = urlHelper.addQueryString(url, "planId", planId);
  url = urlHelper.addQueryString(url, "shipmentId", shipmentId);
  return url;
};

export const getShipmentDetail = ({
  shipmentId,
  skipCount,
  maxResultCount,
}) => {
  return client.request({
    url: Api.getShipmentDetail,
    data: { shipmentId, skipCount, maxResultCount },
    method: "post",
  });
};

export const cancelPlan = (id,pinCode) => {
  return client.request({
    url: Api.cancelPlan,
    data: { id,pinCode },
    method: "post",
  });
};

// export const updateAppointmentTime = (planId, wareZoneAppointmentDate) => {
//   return client.request({
//     url: Api.updateAppointmentTime,
//     data: { planId, wareZoneAppointmentDate },
//     method: "post",
//   });
// };

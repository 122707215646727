<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.dev_exchange_rate') }}
    </template>
    <template #contentBody="wrap">
      <a-table
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 60 }"
        :rowKey="(record) => record.id"
        :loading="tableData.loading"
      >
        <template #lastModificationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #option="{ record }">
          <a-button @click="handleNavigate(record.currencyCode)">{{ $t('menu.dev_exchange_rate_history') }}</a-button>
        </template>
      </a-table>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Button, Table } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import { useI18n } from 'vue-i18n/index';
import { useRouter } from 'vue-router';
import { getExchangeRateList } from '@/api/modules/devops/exchangeRate.js';

export default defineComponent({
  name: "dev_exchange_rate",
  components: {
    AButton: Button,
    ATable: Table,
    Content,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    
    const state = reactive({
      searchData: {
        searchKey: null,
      },
      searchDataCache: {},
      tableData: {
        loading: false,
        tableList: [],
      },
    });

    const columns = [
      {
        dataIndex: "currencyName",
        title: () => vueI18n.t('warehouse.name'),
        width: 150,
      },
      {
        dataIndex: "currencyCode",
        title: () => vueI18n.t('logistics.code'),
        width: 150,
      },
      {
        dataIndex: "buyExchangeRate",
        title: () => vueI18n.t('finance.purchase_exchange_rate'),
        width: 150,
      },
      {
        dataIndex: "saleExchangeRate",
        title: () => vueI18n.t('finance.selling_exchange_rate'),
        width: 150,
      },
      {
        dataIndex: "referenceRate",
        title: () => vueI18n.t('finance.middle_price'),
        width: 150,
      },
      {
        title: () => vueI18n.t('warehouse.update_time'),
        width: 150,
        slots: {
          customRender: "lastModificationTime"
        }
      },
      {
        title: () => vueI18n.t('common.operate'),
        width: 150,
        slots: {
          customRender: "option"
        }
      },
    ];

    const handleNavigate = (code) => {
      if (code) {
        router.push('/devops/exchangerate/history/' + code);
      }
    }

    const getPageData = () => {
      const data = Object.assign({}, state.searchDataCache);
      state.tableData.loading = true;
      getExchangeRateList(data)
        .then(({ result }) => {
          let { items } = result;
          if (Array.isArray(items)) {
            state.tableData.tableList = items;
          } else {
            state.tableData.tableList = [];
          }
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchDataCache, state.searchData);
      getPageData();
    };

    onMounted(getPageData);

    return {
      ...toRefs(state),
      columns,
      handleNavigate,
      handleSearch,
    }
  }
})
</script>

<style scoped>

</style>
import client from "../../client";

const Api = {
  // 佣金分组不分页
  getPartnerGroupList: "/api/SupplierService/PartnerGroup/GetList",
  updateGroupCommission: "/api/SupplierService/Partner/UpdateGroup",

  //结算信息
  updateBankAccountInfo: "/api/SupplierService/Partner/UpdateSettlementChannel",

  //特殊佣金渠道表格
  getChannels:
    "/api/SupplierService/PartnerCommission/GetAllCommissionByPartnerId",
  //获得添加渠道列表
  getAddChannelsList:
    "/api/SupplierService/PartnerCommission/GetLogisticsService",
  //添加渠道
  //addChannel:"/api/SupplierService/PartnerCommission/CreateOrUpdateGroupCommission",
  updateChannel:
    "/api/SupplierService/PartnerCommission/CreateOrUpdateCommission",
  deleteChannel: "/api/SupplierService/PartnerCommission/DeleteCommission",
};

export const getPartnerGroupList = (data) => {
  return client.request({
    url: Api.getPartnerGroupList,
    data,
    method: "POST",
  });
};

export const updateGroupCommission = (partnerId, partnerGroupId) => {
  return client.request({
    url: Api.updateGroupCommission,
    data: { partnerId, partnerGroupId },
    method: "POST",
  });
};

export const updateBankAccountInfo = ({
  partnerId,
  settlementChannelType,
  accountName,
  bankName,
  collectionAccountNumber,
}) => {
  return client.request({
    url: Api.updateBankAccountInfo,
    data: {
      partnerId,
      settlementChannelType,
      accountName,
      bankName,
      collectionAccountNumber,
    },
    method: "POST",
  });
};

export const getAddChannelsList = (partnerId) => {
  return client.request({
    url: Api.getAddChannelsList,
    data: { partnerId },
    method: "POST",
  });
};

export const updateChannel = ({
  partnerId,
  businessId,
  mark,
  businessType,
  percent,
}) => {
  return client.request({
    url: Api.updateChannel,
    data: { partnerId, businessId, mark, businessType, percent },
    method: "POST",
  });
};

export const deleteChannel = (commissionId) => {
  return client.request({
    url: Api.deleteChannel,
    data: { commissionId },
    method: "POST",
  });
};

export const getChannels = (partnerId) => {
  return client.request({
    url: Api.getChannels,
    data: { partnerId },
    method: "POST",
  });
};

<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.finance_inventory_unsettled") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div>
        <a-row :gutter="[16, 16]">
          <a-col>
            <a-select
              style="width: 250px"
              v-model:value="data.searchState.businessNoes"
              mode="tags"
              :maxTagCount="1"
              :maxTagTextLength="12"
              :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
              :placeholder="$t('finance.business_no')"
              allowClear
              @change="handleSelectValueChange('businessNoes')"
            ></a-select>
          </a-col>
          <a-col>
            <CSearchShop v-model:shopId="data.searchState.shopId"></CSearchShop>
          </a-col>
          <a-col>
            <a-select
              v-model:value="data.searchState.businessType"
              style="width: 250px"
              allow-clear
              :placeholder="$t('finance.business_type')"
            >
              <a-select-option
                v-for="(value, key) in businessTypeEnum"
                :key="key"
                :title="$t($enumLangkey('businessType', value))"
                :value="value"
              >
                {{ $t($enumLangkey("businessType", value)) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
              :allowClear="true"
              show-search
              style="width: 250px"
              optionFilterProp="search-key"
              v-model:value="data.searchState.countryId"
              :placeholder="$t('finance.business_country')"
            >
              <a-select-option
                :value="item.id"
                v-for="item in dropDownData.countrys"
                :key="item.id"
                :title="`${getLanguageName(item)}(${item.ioS2})`"
                :search-key="item.ioS2 + item.cnName + item.enName"
              >
                {{ getLanguageName(item) }}({{ item.ioS2 }})
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
              :allowClear="true"
              show-search
              style="min-width: 250px"
              optionFilterProp="search-key"
              v-model:value="data.searchState.facilityCode"
              :placeholder="$t('finance.facility_code')"
            >
              <a-select-option
                :value="item.warehouseNo"
                v-for="item in dropDownData.warehouses"
                :key="item.id"
                :title="`${item.warehouseNo}(${item.warehouseName})`"
                :search-key="item.warehouseName + item.warehouseNo"
              >
                {{ item.warehouseNo }}({{ item.warehouseName }})
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
              :allowClear="true"
              show-search
              style="width: 250px"
              optionFilterProp="search-key"
              v-model:value="data.searchState.currencyId"
              :placeholder="$t('finance.happen_currency')"
            >
              <a-select-option
                :value="item.id"
                v-for="item in dropDownData.currency"
                :key="item.id"
                :title="`${getLanguageName(item)}(${item.symbol})`"
                :search-key="
                  item.code + item.symbol + item.cnName + item.enName
                "
              >
                {{ getLanguageName(item) }}({{ item.symbol }})
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-range-picker
              style="width: 250px"
              allow-clear
              v-model:value="data.dateRange"
            />
          </a-col>
          <a-col>
            <a-button @click="handleChangeFilter" type="primary">{{ $t("common.query") }}</a-button>
          </a-col>
          <a-col>
            <a-button @click="handleShowPinModal" type="ghost">{{ $t("common.export") }}</a-button>
          </a-col>
        </a-row>
      </div>
      <!-- 表格 -->
      <div class="mt-3">
        <a-table
          :columns="columns"
          :data-source="data.list"
          :scroll="{ x: 500, y: wrap.contentHeight - 150 }"
          :pagination="false"
          :loading="data.loading"
          size="small"
        >
          <template #shopNameAndShopId="{ record }">
            {{ record.shopName }}
            <div v-if="record.shopNo">NO: {{ record.shopNo }}</div>
            <div>{{ $t("common.shop_mark") }}: {{ record.shopMark ?? "-" }}</div>
          </template>
          <template #timeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.time) }}
          </template>

          <template #transactionTypeCustom="{ record }">
            {{ $t($enumLangkey("transactionType", record.transactionType)) }}
          </template>
          <template #businessTypeCustom="{ record }">
            {{ $t($enumLangkey("businessType", record.businessType)) }}
          </template>

          <template #businessHappenCountryCustom="{ record }">
            <span v-if="record.countryCnName || record.countryEnName">{{
              getLanguageName({
                cnName: record.countryCnName,
                enName: record.countryEnName,
              })
            }}</span>
            <span v-else>-</span>
          </template>
          <template #happenWarehouseCodeCustom="{ record }">
            {{ record.happenWarehouseCode || "-" }}
          </template>
          <template #happenCurrencyCustom="{ record }">
            {{ record.currencyCode || "-" }}
          </template>

          <template #moneyCustom="{ record }">
            <span :class="[record.amount >= 0 ? 'text-success' : 'text-error']">
              {{
                $filters.formatCurrencyAmount(
                  record.currencySymbol,
                  record.amount
                )
              }}
            </span>
          </template>
        </a-table>
      </div>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleDownload"
      />
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="data.pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, onActivated } from "vue";
import {
  Table,
  Select,
  Button,
  Row,
  Col,
  Tag,
  Input,
  Form,
  DatePicker,
  Space,
  Modal,
} from "ant-design-vue";
import CPager from "@/views/components/CPager.vue";
import Content from "@/views/components/Content.vue";
import CSearchShop from "@/views/components/CSearchShop.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { getName, currentTimeToUtc, dateString } from "@/utils/general.js";
import { downloadFile } from "@/utils/downloader";
import {
  getWarehouses,
  getCountrys,
  getCurrencys,
} from "@/api/modules/common/index.js";
import { getUnCompleteListPaged } from "@/api/modules/finance/inventory.js";
import { exportUnCompleteList } from "@/api/modules/finance/index";
import {
  transactionType as transactionTypeEnum,
  businessType as businessTypeEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "finance_inventory_unsettled",
  components: {
    CPinTipsModal,
    CSearchShop,
    ATable: Table,
    Content,
    CPager,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    ATag: Tag,
    AInput: Input,
    AForm: Form,
    ARangePicker: DatePicker.RangePicker,
    AFormItem: Form.Item,
    ASpace: Space,
    AModal: Modal,
    AMonthPicker: DatePicker.MonthPicker,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const data = reactive({
      loading: false,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      searchState: {
        businessNoes: [], //业务编号
        shopId: null,
        businessType: null, //业务类型
        countryId: null, //业务发生国家
        facilityCode: null, //业务发生国家
        currencyId: null, //发生币种
        startTime: null,
        endTime: null,
      },
      dateRange: [],
      searchStateCache: {},
    });

    const pinModalState = reactive({
      visible: false,
      loading: false,
      type: null,
    });

    const dropDownData = reactive({
      countrys: [], //国家
      currency: [], //币种
      warehouses: [],
    });

    const { getters } = useStore();

    const columns = [
      {
        dataIndex: "businessNumber",
        width: 150,
        title: () => vueI18n.t("finance.business_no"),
      },
      {
        title: () => vueI18n.t("warehouse.customer"),
        width: 200,
        slots: {
          customRender: "shopNameAndShopId",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("finance.transaction_type"),
        slots: {
          customRender: "transactionTypeCustom",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("finance.business_type"),
        slots: {
          customRender: "businessTypeCustom",
        },
      },
      {
        title: () => vueI18n.t("finance.business_country"),
        width: 150,
        slots: {
          customRender: "businessHappenCountryCustom",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("finance.facility_code"),
        slots: {
          customRender: "happenWarehouseCodeCustom",
        },
      },

      {
        width: 120,
        title: () => vueI18n.t("finance.happen_currency"),
        slots: {
          customRender: "happenCurrencyCustom",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("account.amount"),
        slots: {
          customRender: "moneyCustom",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("account.time"),
        slots: {
          customRender: "timeCustom",
        },
      },
    ];

    const handleSelectValueChange = (key) => {
      let value = data.searchState[key];
      if (Array.isArray(value)) {
        data.searchState[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    };

    const handleChangeFilter = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      Object.assign(data.searchStateCache, data.searchState);
      handleSearch();
    };

    const handleSearch = async () => {
      data.loading = true;
      let startTime, endTime;
      if (data.dateRange?.length > 0) {
        startTime = currentTimeToUtc(
          data.dateRange[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endTime = currentTimeToUtc(
          data.dateRange[1]?.format("YYYY-MM-DD 00:00:00")
        );
      }
      const parameter = Object.assign({}, data.pageData, data.searchStateCache, {
        startTime,
        endTime,
      });
      getUnCompleteListPaged(parameter)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.list = items.map((x, index) => {
              return {
                key: index,
                id: x.id,
                time: x.creationTime,
                shopName: x.shopName,
                shopId: x.shopId,
                shopNo: x.shopNo,
                shopMark: x.shopMark,
                businessNumber: x.businessNo,
                transactionType: x.transactionType,
                businessType: x.businessType,
                countryEnName: x.countryEnName,
                countryCnName: x.countryCnName,
                happenWarehouseCode: x.warehouseNo,
                happenCurrency: x.trackNo,
                currencyCode: x.currencyCode,
                currencySymbol: x.currencySymbol,
                amount: x.changeAmount,
              };
            });
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
          }
          data.loading = false;
        })
        .catch(() => {
          data.loading = false;
        });
    };

    const funcGetAllWarehouse = () => {
      getWarehouses({}).then((res) => {
        if (res.result) {
          dropDownData.warehouses = res.result;
        }
      });
    };

    const funcGetCountrys = () => {
      getCountrys({}).then((res) => {
        if (res.result) {
          dropDownData.countrys = res.result;
        }
      });
    };

    const funcGetCurrency = () => {
      getCurrencys({}).then((res) => {
        if (res.result) {
          dropDownData.currency = res.result;
        }
      });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const handleShowPinModal = () => {
      pinModalState.visible = true;
      pinModalState.type = 1;
    };

    const handleDownloadExcel = (pinCode) => {
      pinModalState.loading = true;
      let url = exportUnCompleteList();
      let startTime, endTime;
      if (data.dateRange?.length > 0) {
        startTime = currentTimeToUtc(
          data.dateRange[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endTime = currentTimeToUtc(
          data.dateRange[1]?.format("YYYY-MM-DD 00:00:00")
        );
      }
      const reqData = Object.assign({}, data.searchStateCache, {
        pinCode,
        startTime,
        endTime,
      });
      downloadFile(url, `inventory_${dateString()}.csv`, "POST", reqData)
        .then(() => {
          pinModalState.visible = false;
        })
        .catch(() => {})
        .finally(() => {
          pinModalState.loading = false;
        });
    };

    const handleDownload = (pinCode) => {
      if (pinModalState.type == 1) {
        handleDownloadExcel(pinCode);
      } else if (pinModalState.type == 2) {
        handleShowExportStorageFeeModalOk(pinCode);
      }
    };

    onMounted(() => {
      funcGetAllWarehouse();
      funcGetCountrys();
      funcGetCurrency();
    });

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      transactionTypeEnum,
      businessTypeEnum,
      dropDownData,
      data,
      pinModalState,

      handleSelectValueChange,
      handleSearch,
      handlePage,
      handleChangeFilter,
      getLanguageName,

      handleDownload,

      handleShowPinModal,
      handleDownloadExcel,
    };
  },
});
</script>
<style lang="less" scoped>
.moneyColor {
  color: red;
}
</style>

import client from "../../client";

const Api = {
  getList: "/api/OpenPlatformService/AuthorizeInfo/GetListPaged",
  create: "/api/OpenPlatformService/AuthorizeInfo/Create",
  update: "/api/OpenPlatformService/AuthorizeInfo/Update",
  deleteAuthorize:"api/OpenPlatformService/AuthorizeInfo/Delete"
};

export const getList = ({
  ids,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: {
      ids,
      searchKey,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "POST",
  });
};

export const create = ({ authorizeName,authorizeKey, description }) => {
  return client.request({
    url: Api.create,
    data: { authorizeName, authorizeKey,description },
    method: "POST",
  });
};

export const update = ({ id, authorizeName,authorizeKey, description }) => {
  return client.request({
    url: Api.update,
    data: { id, authorizeName, authorizeKey,description },
    method: "POST",
  });
};

export const deleteAuthorize = (ids) => {
  return client.request({
    url: Api.deleteAuthorize,
    data: { ids },
    method: "POST",
  });
};

import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 客户充值导出
  exportListManagePaged: "/api/ProductService/ReChargeLog/ExportListManagePaged",
  // 费用清单导出
  exportManagerListPaged: "/api/ProductService/CostLog/ExportManagerListPaged",
  getExportStorageFeeUrl: "/api/WarehouseService/StorageFeeDetail/ExportStorageFeeDetailsByMonthWithConsole",
  // 客户余额导出
  exportListPaged: "/api/ProductService/Balance/ExportListPaged",
  // 客户仓储费用明细导出
  exportStorageFeeDetail: "/api/WarehouseService/StorageFeeDetail/ExportStorageFeeDetail",
  // 客户退货暂存费用明细导出
  exportTemporaryStorageFeeDetail:"/api/WarehouseService/RtsFeeDetail/ExportRtsFeeDetail",
  // 导出未结算费用清单
  exportUnCompleteList: "/api/ProductService/CostLogManage/ExportUnCompleteList",
};

export const exportListManagePaged = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportListManagePaged);
  return url;
}

export const exportManagerListPaged = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportManagerListPaged);
  return url;
}

export const getExportStorageFeeUrl = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getExportStorageFeeUrl);
  return url;
}

export const exportListPaged = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportListPaged);
  return url;
}

export const exportStorageFeeDetail = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportStorageFeeDetail);
  return url;
}

export const exportTemporaryStorageFeeDetail = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportTemporaryStorageFeeDetail);
};

export const exportUnCompleteList = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportUnCompleteList);
};
<template>
  <a-menu 
  class="menu-item-ul"
  v-model:selectedKeys="selectedKeys" 
  v-model:mode="menuMode"
  v-model:theme="menuTheme"
  v-model:openKeys="openKeys"
  @select="onSelect"
  @openChange="openChange"
  collapsed>
      <template v-for="menu in menus" :key="menu.path">
        <SubMenu v-if="!menu.hidden" :item="menu"></SubMenu>
      </template>
    <!-- <a-menu-item key="mail" >
      <template #icon>
        <mail-outlined />
      </template>
      <router-link to="/about">about</router-link>
    </a-menu-item>
    <a-menu-item key="app">
      <template #icon>
        <appstore-outlined />
      </template>
     <router-link to="/form">form</router-link>
    </a-menu-item>
     <a-menu-item key="tas">
      <template #icon>
        <appstore-outlined />
      </template>
     <router-link to="/task">task</router-link>
    </a-menu-item>
    <a-sub-menu>
      <template #icon>
        <setting-outlined />
      </template>
      <template #title>Navigation Three - Submenu</template>
      <a-menu-item-group title="Item 1">
        <a-menu-item key="setting:1"></a-menu-item>
        <a-menu-item key="setting:2">Option 2</a-menu-item>
      </a-menu-item-group>
      <a-menu-item-group title="Item 2">
        <a-menu-item key="setting:3">Option 3</a-menu-item>
        <a-menu-item key="setting:4">Option 4</a-menu-item>
      </a-menu-item-group>
    </a-sub-menu>
    <a-menu-item key="alipay">
      <a href="https://antdv.com" target="_blank" rel="noopener noreferrer">
        Navigation Four - Link
      </a>
    </a-menu-item> -->
  </a-menu>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { useMenu } from '@/hooks/menu/index';
import SubMenu from "../menu/SubMenu.vue";
import { Menu, Space, Dropdown, Avatar  } from 'ant-design-vue';
export default {
  name:"LayoutMenu",
  components: {
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
    SubMenu,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpace: Space,
    ADropdown: Dropdown,
    AAvatar: Avatar
  },
  setup() {
    const { getters, commit } = useStore();
    const current = ref(['mail']);
    const menuMode = computed(()=>(getters.layout == "layout-head" && !getters.isMobile) ? "horizontal" : "inline");
    const menuTheme = computed(()=>((getters.layout == "layout-head" && !getters.isMobile) || (getters.theme =="theme-light" )) ? "light" : "dark");
    const router = useRouter();
    const { selectedKeys, openKeys, openChange, menus } = useMenu();
    const onSelect = (selectItem) => {
    //   router.push(key);      
    //   console.log(key)
    //   console.log(router);

    //   console.log("selectItem");
    //   console.log(selectItem);
      let { key } = selectItem;
      router.push(key);
      if(getters.isMobile){
        commit("app/TOGGLE_COLLAPSED");
      }
    }


    return {
      current,
      menuMode,
      menuTheme,
      onSelect,
      selectedKeys, 
      openKeys, 
      openChange, 
      menus 
    };
  }
};
</script>
<style>
#header ul.menu-item-ul{
  border-bottom:0;
}
</style>
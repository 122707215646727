export default {
  enum: {
    empty: "",

    transport_characteristics_1: "普货",
    transport_characteristics_2: "配套电池",
    transport_characteristics_4: "内置电池",
    transport_characteristics_8: "纯电池",
    transport_characteristics_16: "液体",
    transport_characteristics_32: "弱磁",
    transport_characteristics_64: "带磁带电",
    transport_characteristics_128: "纺织品",
    transport_characteristics_256: "粉末",
    transport_characteristics_512: "敏感货",

    location_region_type_10: "入库区",
    location_region_type_20: "存储区",
    location_region_type_30: "服务区",
    location_region_type_32: "拣货箱",
    location_region_type_33: "分拣盒",
    location_region_type_34: "归集箱",
    location_region_type_40: "出库区",
    location_region_type_42: "调拨台",
    location_region_type_50: "交付区",
    location_region_type_100: "移库箱",

    warehouse_type_1: "存储仓",
    warehouse_type_2: "配送仓",
    warehouse_type_4: "物流中心",
    warehouse_type_8: "中转仓",

    // 入库任务---- 状态
    in_warehouse_status_10: "计划中",
    in_warehouse_status_20: "已发运",
    in_warehouse_status_30: "待入库",
    in_warehouse_status_40: "入库中",
    in_warehouse_status_50: "已入库",
    in_warehouse_status_200: "取消",

    in_warehouse_type_1: "存储仓转入",
    in_warehouse_type_2: "配送仓转入",
    in_warehouse_type_3: "其他入库",
    in_warehouse_type_5: "直接入库",
    in_warehouse_type_6: "调拨入库",
    in_warehouse_type_7: "运输入库",
    in_warehouse_type_8: "中转仓转入",

    // 箱状态
    box_status_10: "待入库",
    box_status_20: "已入库",
    box_status_30: "待服务",
    box_status_40: "待出库",
    box_status_50: "待交付/已出库",
    box_status_60: "已交付",
    box_status_190: "取消",


    // 我的移库任务 ----当前所在区域
    current_location_10: "入库区",
    current_location_20: "存储区",
    current_location_30: "服务区",
    current_location_40: "出库区",
    current_location_50: "交付区",

    // 我的移库任务 ----目标区域
    target_area_10: "入库区",
    target_area_20: "存储区",
    target_area_30: "服务区",
    target_area_40: "出库区",
    target_area_50: "交付区",

    // 我的移库任务 ----任务状态
    my_trans_status_1: "待执行",
    my_trans_status_2: "执行中",
    my_trans_status_3: "完成",
    my_trans_status_4: "取消",

    // 我的移库任务 ----触发原因
    trigger_reason_10: "状态改变",
    trigger_reason_20: "库位盘点",
    trigger_reason_30: "库位管理",
    trigger_reason_40: "库存调整",
    trigger_reason_50: "出库归集",
    trigger_reason_60: "换标服务",

    // 存储仓出库状态
    out_plan_status_1: "计划中",
    out_plan_status_2: "待出库",
    out_plan_status_3: "出库中",
    out_plan_status_4: "完成出库",
    out_plan_status_5: "取消出库",

    // 出库任务 --- 运输交付方式
    trans_deliver_type_1: "使用赛易物流",
    trans_deliver_type_2: "自提",

    // 出库任务--- 交付状态
    deliver_status_1: "待交付",
    deliver_status_2: "待交付-预约延迟",
    deliver_status_3: "等待物流方案",
    deliver_status_4: "完成",

    //在物流方案中的参考时效中使用
    aging_0: "签收",
    aging_10: "提取",

    // 派送方式
    delivery_way_10: "DHL",
    delivery_way_20: "UPS",
    delivery_way_30: "USPS",
    delivery_way_40: "FEDEX",
    delivery_way_50: "ESTAFETA",
    delivery_way_60: "PAQUETEXPRESS",
    delivery_way_70: "AMZL",
    delivery_way_80: "MEXICOPOST",
    delivery_way_90: "GRUPOAMPM",
    delivery_way_100: "MERCADOLIBRELOGISTIC",
    delivery_way_110: "JTEXPRESSMX",
    delivery_way_120: "REDPACK",
    delivery_way_130: "IMILE",
    delivery_way_9980: "TRUCK",
    delivery_way_9990: "OTHERS",

    // 调查任务 ---- 调查状态
    survey_state_1: "请求调查",
    survey_state_2: "调查完成",

    // 调查任务 ---- 调查结果
    survey_result_11: "漏发",
    survey_result_121: "仓库丢失",
    survey_result_122: "未成功接收",
    survey_result_123: "仓库已找到货",
    survey_result_21: "放弃入库并销毁",
    survey_result_22: "更换货号",
    survey_result_23: "更换标签",
    survey_result_12: "请求调查",

     //（代发仓）入库计划错误产品客户可处理操作
     product_error_dispose_type_11: "漏发",
     product_error_dispose_type_12: "请求调查",
     product_error_dispose_type_121: "仓库丢失",
     product_error_dispose_type_122: "未成功接收",
     product_error_dispose_type_123: "仓库已找到货",
     product_error_dispose_type_21: "放弃入库并销毁",
     product_error_dispose_type_22: "更换货号",
     product_error_dispose_type_23: "更换标签",

    // 移库 任务状态
    task_status_1: "待执行",
    task_status_2: "执行中",
    task_status_3: "完成",
    task_status_4: "取消",

    // 库位配置 ---- 条码操作任务 ----任务类型
    task_type_1: "新增",
    task_type_2: "更换",
    task_type_3: "移除",

    //箱交付状态
    box_scan_status_1: "已确认",
    box_scan_status_2: "未确认",

    //交付类型(出库计划交付)
    deliver_type_1: "SE物流",
    deliver_type_2: "自提",

    //接收到的错误箱的类型
    box_receive_error_type_1: "接收超时",
    box_receive_error_type_2: "错误产品",

    //需比较的记录类型
    box_or_product_modify_record_type_1: "长",
    box_or_product_modify_record_type_2: "宽",
    box_or_product_modify_record_type_3: "高",
    box_or_product_modify_record_type_4: "体积",
    box_or_product_modify_record_type_5: "重量",

    //比较方式
    box_or_product_modify_record_compare_type_1: "大于",
    box_or_product_modify_record_compare_type_2: "小于",

    //需比较单位
    box_or_product_modify_record_unit_type_1: "cm",
    box_or_product_modify_record_unit_type_2: "cm3",
    box_or_product_modify_record_unit_type_3: "kg",
    box_or_product_modify_record_unit_type_4: "%",

    //物流时效类型
    channel_time_type_0: "签收",
    channel_time_type_10: "提取",

    //区域类型
    logistics_region_type_1: "国家",
    logistics_region_type_2: "省/州",
    logistics_region_type_4: "城市",
    logistics_region_type_8: "区县",
    logistics_region_type_16: "邮编",

    //区域标记
    region_tag_1: "偏远地区",
    region_tag_2: "超偏远地区",
    region_tag_4: "远程配送",

    //角色类型
    role_type_1: "店铺角色",
    role_type_2: "仓库角色",
    role_type_4: "控制台角色",
    role_type_8: "合作伙伴角色",

    //权限类型
    right_type_1: "系统",
    right_type_2: "模块",
    right_type_3: "菜单",
    right_type_4: "功能",
    right_type_5: "资源",

    //附加费类型
    surcharge_type_10: "一般贸易报关费",
    surcharge_type_20: "特殊产品附加费",
    surcharge_type_30: "品名附加费",
    surcharge_type_40: "续页附加费",
    surcharge_type_50: "非FBA地址附加费",
    surcharge_type_60: "偏远地址附加费",
    surcharge_type_70: "超偏远地址",
    surcharge_type_80: "远程派送费",
    surcharge_type_90: "超规附加费",
    surcharge_type_100: "大型包裹附加费",
    surcharge_type_110: "尾程派送附加费",
    surcharge_type_190: "其他附加费",

    //附加费计费方式
    charging_method_0: "每件",
    charging_method_10: "每千克",
    charging_method_20: "每票",

    //重量类型
    weight_type_0: "实重",
    weight_type_10: "计费重",

    //附加费计算方式
    surcharge_cacl_method_0: "叠加",
    surcharge_cacl_method_10: "取最大",

    //工作状态
    job_statu_1: "在职",
    job_statu_2: "离职",

    // 付款方式
    recharge_type_10: "转账汇款",
    recharge_type_20: "Payoneer",
    recharge_type_30: "企业支付宝",

    //充值状态
    top_up_status_10: "待确认",
    top_up_status_20: "成功",
    top_up_status_30: "失败",
    top_up_status_40: "待提交",
    top_up_status_50: "已取消",

    //拒绝入账的类型
    reject_in_account_1: "款项被拒收",
    reject_in_account_2: "未收到款项",
    reject_in_account_3: "错误的款项金额",

    //流水号类型
    serial_number_type_1: "付款流水号",
    serial_number_type_2: "收款流水号",
    serial_number_type_3: "充值流水号",

    //运输计划状态
    transport_status_10: "计划中",
    transport_status_20: "等待上门揽件",
    transport_status_30: "上门揽件中",
    transport_status_40: "已完成揽件",
    transport_status_50: "操作中心等待收货",
    transport_status_60: "操作中心已收货",
    transport_status_70: "待审核",
    transport_status_80: "等待支付",
    transport_status_90: "已支付",
    transport_status_100: "运输中",
    transport_status_110: "已到达目的地仓库",
    transport_status_120: "派送中",
    transport_status_130: "已签收",
    transport_status_999: "已取消",

    //目的地类型
    destination_type_1: "赛易仓库",
    destination_type_2: "亚马逊仓库",
    destination_type_3: "其它地址",
    destination_type_4: "美客多",

    //运输线路
    transport_routes_1: "国际运输",
    transport_routes_2: "本土运输",

    //计重方式
    billing_way_1: "实重",
    billing_way_2: "体积重",
    billing_way_3: "实际体积",
    billing_way_4: "计费体积",

    //订单的费用类型
    order_logistics_fee_10: "仓储费",
    order_logistics_fee_20: "卸货费",
    order_logistics_fee_30: "标签费",
    order_logistics_fee_40: "出库操作费",
    order_logistics_fee_50: "订单操作费",
    order_logistics_fee_60: "打托费",
    order_logistics_fee_70: "包装费",
    order_logistics_fee_80: "归集操作费",
    order_logistics_fee_90: "调拨操作费",
    order_logistics_fee_100: "赔付费",
    order_logistics_fee_110: "爽约金",
    order_logistics_fee_120: "退货服务费",
    order_logistics_fee_130: "标准质检费",
    order_logistics_fee_140: "销毁服务费",
    order_logistics_fee_150: "附加功能性质检费",
    order_logistics_fee_160: "人工识别费",
    order_logistics_fee_170: "自提费",
    order_logistics_fee_180: "暂存费",
    order_logistics_fee_190: "合并包装费",
    order_logistics_fee_200: "基础卸货费",
    order_logistics_fee_210: "超规卸货附加费",
    order_logistics_fee_220: "超大件卸货附加费",
    order_logistics_fee_230 :"上架费",
    order_logistics_fee_240 :"装箱费",
    order_logistics_fee_250 :"清点费",
    order_logistics_fee_260 :"缠膜费",
    order_logistics_fee_261 :"缠膜基础费",
    order_logistics_fee_262 :"超量缠膜费",
    order_logistics_fee_500: "运输费",
    order_logistics_fee_501: "DDP关税预报",
    order_logistics_fee_502: "签收签名费",
    order_logistics_fee_503: "达标折扣",
    order_logistics_fee_510: "一般贸易报关费",
    order_logistics_fee_520: "特殊产品附加费",
    order_logistics_fee_530: "品名附加费",
    order_logistics_fee_540: "续页附加费",
    order_logistics_fee_550: "非FBA地址附加费",
    order_logistics_fee_560: "偏远地址附加费",
    order_logistics_fee_570: "超偏远地址附加费",
    order_logistics_fee_580: "远程派送费",
    order_logistics_fee_590: "超规附加费",
    order_logistics_fee_600: "大型包裹附加费",
    order_logistics_fee_610: "尾程派送附加费",
    order_logistics_fee_690: "其他附加费",
    order_logistics_fee_700: "额外附加费",
    order_logistics_fee_1000: "充值",
    order_logistics_fee_1010: "手动扣款",

    //账户状态
    shop_account_status_10: "活动中",
    shop_account_status_20: "已冻结",
    shop_account_status_30: "已关闭",

    //审核状态
    audit_status_1: "待审核",
    audit_status_2: "审核通过",
    audit_status_3: "审核未通过",

    //透支状态
    overdraft_status_1: "正常",
    overdraft_status_2: "逾期未付",

    //调整额度的方式
    shaft_type_10: "手动",
    shaft_type_20: "自动动",

    // 第三方物流渠道
    third_logistic_type_1: "易抵达",

    // 地址类型
    platform_addr_type_0: "其他",
    platform_addr_type_1: "FBA",
    platform_addr_type_2: "美客多",
    platform_addr_type_4: "赛易仓库",

    //物流计算方式
    logistics_calc_method_0: "重量",
    logistics_calc_method_1: "体积",

    // 存储仓运输类型
    out_warehouse_type_1: "仓库间调拨",
    out_warehouse_type_2: "转入本地配送仓",
    out_warehouse_type_3: "亚马逊FBA",
    out_warehouse_type_4: "其他地址",
    out_warehouse_type_5: "美客多",
    out_warehouse_type_6: "销毁",


    // 存储仓出库物流交付状态
    delivery_status_1: "待交付",
    delivery_status_2: "待交付-预约延迟",
    delivery_status_3: "等待物流方案",
    delivery_status_4: "完成",

    // 物流入库物流方式
    logistics_way_1: "海运",
    logistics_way_2: "空运",
    logistics_way_3: "商业快递",
    logistics_way_4: "铁路",
    logistics_way_5: "邮政",
    logistics_way_6: "陆运",

    //整柜入库状态
    full_container_in_warehouse_type_10: "已预约",
    full_container_in_warehouse_type_30: "已爽约",
    full_container_in_warehouse_type_31: "开始卸货",
    full_container_in_warehouse_type_40: "卸货完成",

    //整柜入库规格
    container_type_1: "20英尺普柜",
    container_type_2: "40英尺普柜",
    container_type_3: "40英尺高柜",
    container_type_4: "45英尺高柜",

    //订单状态-用户显示的
    agent_order_status_5: "待确认",
    agent_order_status_10: "已下单",
    agent_order_status_20: "取消",
    agent_order_status_30: "处理中",
    agent_order_status_40: "已发运",
    agent_order_status_50: "已送达",
    agent_order_status_60: "取消发货",
    agent_order_status_70: "已取消销毁",
    agent_order_status_80: "已销毁",

    //订单状态-仓库显示的
    warehouse_agent_order_status_10: "已下单",
    warehouse_agent_order_status_20: "拣货中",
    warehouse_agent_order_status_30: "等待分拣",
    warehouse_agent_order_status_40: "分拣中",
    warehouse_agent_order_status_50: "待出库",
    warehouse_agent_order_status_51: "待出库-挂起",
    warehouse_agent_order_status_60: "出库中",
    warehouse_agent_order_status_70: "已发运",
    warehouse_agent_order_status_80: "已送达",
    warehouse_agent_order_status_90: "取消发货",
    warehouse_agent_order_status_100: "取消销毁",
    warehouse_agent_order_status_110: "销毁",

    //自提选择的方式
    self_mention_type_1: "卡车",
    self_mention_type_2: "快递",

    partner_type_1: "公司",
    partner_type_2: "个人",

    settlement_channel_type_1: "银行电汇",

    // API运行状态
    open_api_run_status_1: "正常",
    open_api_run_status_2: "异常",

    // API分类
    open_api_category_1: "物流",

    // API代码
    open_api_code_1: "Fedex",
    open_api_code_2: "Ups",
    open_api_code_3: "Dhl",
    open_api_code_4: "Usps",
    open_api_code_5: "Itidida",
    open_api_code_6: "Elvish",
    open_api_code_7: "Areship",
    open_api_code_8: "Frayun",
    open_api_code_9: "JTExpressMX",
    open_api_code_10: "Hyeus",
    open_api_code_11: "Accufrate",
    open_api_code_12: "FedexRest",
    open_api_code_13: "Ykm",
    open_api_code_14: "Yuncan",

    commission_business_type_1: "仓储服务",
    commission_business_type_2: "运输服务",

    // 渠道退货商业平台
    return_plan_source_type_1: "亚马逊",
    return_plan_source_type_2: "沃尔玛",
    return_plan_source_type_3: "第三方仓库",
    return_plan_source_type_4: "其它",

    // 退货计划状态详细
    return_plan_record_status_10: "已入库",
    return_plan_record_status_20: "销毁",

    // 退货计划状态
    return_plan_status_10: "已计划",
    return_plan_status_20: "接收中",
    return_plan_status_30: "已完成",
    return_plan_status_90: "已取消",

    // 打单-运单操作状态
    waybill_operation_status_1: "已预报",
    waybill_operation_status_2: "待上网",
    waybill_operation_status_3: "待签收",
    waybill_operation_status_4: "已签收",
    waybill_operation_status_5: "已取消",
    waybill_operation_status_6: "手动取消",
    waybill_operation_status_999: "异常",

    //调拨至存储仓的计划状态
    allot_plan_status_20: "已计划",
    allot_plan_status_30: "处理中",
    allot_plan_status_40: "已调拨",
    allot_plan_status_50: "已取消",

    pay_way_10: "余额支付",
    pay_way_20: "账期支付",

    // 退货计划可识别码类型
    return_plan_fnsku_type_1: "可扫描信息",
    return_plan_fnsku_type_2: "人工识别",

    label_dispose_1: "用户贴标",
    label_dispose_2: "仓库贴标",

    //配送订单物流类型
    agent_order_logistics_type_1: "赛易物流",
    agent_order_logistics_type_2: "第三方面单",
    agent_order_logistics_type_3: "销毁",

    //交易类型
    transaction_type_10: "付款",
    transaction_type_20: "赔偿",
    transaction_type_30: "充值",
    transaction_type_40: "退款",
    transaction_type_50: "达标折扣",

    //业务类型
    business_type_10: "充值",
    business_type_20: "存储仓出库",
    business_type_30: "配送订单",
    business_type_40: "运输计划",
    business_type_50: "仓储赔付",
    business_type_60: "运输赔付",
    business_type_70: "整柜预约入库",
    business_type_80: "存储仓存储",
    business_type_90: "配送仓存储",
    business_type_100: "调拨至存储仓",
    business_type_110: "定制服务",
    business_type_120: "退货处理",
    business_type_130: "存储仓入库",
    business_type_140: "配送仓入库",
    business_type_150: "爽约金",
    business_type_160: "运单",
    business_type_170: "退货计划",
    business_type_180: "手动退款",
    business_type_190: "中转仓入库",
    business_type_200: "中转仓出库",
    business_type_210: "中转仓存储",
    business_type_220: "退货暂存",
    business_type_230: "退货暂存销毁",
    business_type_240: "存储仓换标",
    business_type_250: "中转仓换标",
    business_type_260: "线下服务",

    battery_type_1: "A",
    battery_type_2: "AA",
    battery_type_3: "AAA",
    battery_type_4: "AAAA",
    battery_type_5: "SC",
    battery_type_6: "C",
    battery_type_7: "D",
    battery_type_8: "N",
    battery_type_9: "F",
    battery_type_10: "CR2",
    battery_type_11: "CR5",
    battery_type_12: "CR123A",
    battery_type_13: "非标准电池",
    battery_type_14: "产品特定电池",
    battery_type_15: "锂金属电池",
    battery_type_16: "锂聚合物电池",
    battery_type_17: "锂离子电池",
    battery_type_18: "9V",
    battery_type_19: "6V",
    battery_type_20: "12V",
    battery_type_21: "P76",
    battery_type_22: "LR41",
    battery_type_23: "LR44",
    battery_type_24: "LR63",
    battery_type_25: "LR60",
    battery_type_26: "LR59",
    battery_type_27: "LR66",
    battery_type_28: "LR48",
    battery_type_29: "LR69",
    battery_type_30: "LR57",
    battery_type_31: "LR45",
    battery_type_32: "LR54",
    battery_type_33: "LR55",
    battery_type_34: "LR58",
    battery_type_35: "LR43",
    battery_type_36: "MR-9",
    battery_type_37: "CR2025",
    battery_type_38: "CR2016",
    battery_type_39: "CR2032",
    battery_type_40: "CR2430",
    battery_type_41: "CR2450",
    battery_type_42: "CR2412",
    battery_type_43: "CR3032",
    battery_type_44: "CR2330",
    battery_type_45: "CR2320",
    battery_type_46: "CR2325",

    battery_ingredient_1: "锂离子",
    battery_ingredient_2: "二氧化锰锂",
    battery_ingredient_3: "锂聚合物",
    battery_ingredient_4: "锂金属",
    battery_ingredient_5: "锂",
    battery_ingredient_6: "锂空气",
    battery_ingredient_7: "铅酸",
    battery_ingredient_8: "钴酸锂",
    battery_ingredient_9: "锂镍钴铝",
    battery_ingredient_10: "锂镍锰钴",
    battery_ingredient_11: "磷酸锂",
    battery_ingredient_12: "钛酸锂",
    battery_ingredient_13: "镍铁",
    battery_ingredient_14: "镍锌",
    battery_ingredient_15: "银钙",
    battery_ingredient_16: "银锌",
    battery_ingredient_17: "锌空气",
    battery_ingredient_18: "亚硫酰氯锂",
    battery_ingredient_19: "羟基氧化镍",
    battery_ingredient_20: "氯化锌",
    battery_ingredient_21: "锌碳",
    battery_ingredient_22: "氧化汞",
    battery_ingredient_23: "密封铅酸",
    battery_ingredient_24: "镍氢",
    battery_ingredient_25: "碱性",
    battery_ingredient_26: "镍镉",
    battery_ingredient_27: "氧化银",
    battery_ingredient_28: "铅钙",
    battery_ingredient_29: "铝氧",
    battery_ingredient_30: "铅酸",
    battery_ingredient_31: "锌",
    battery_ingredient_32: "锰",

    debt_status_10: "不限",
    debt_status_20: "正常",
    debt_status_30: "欠款",

    export_business_type_10: "客户充值",
    export_business_type_20: "控制台费用清单",
    export_business_type_30: "客户余额",
    export_business_type_40: "运单",
    export_business_type_50: "配送订单",
    export_business_type_60: "存储仓库存",
    export_business_type_70: "配送仓库存",
    export_business_type_80: "用户端费用清单",
    export_business_type_90: "导出箱库龄",
    export_business_type_100: "导出产品库龄",
    export_business_type_110: "配送订单",
    export_business_type_120: "店铺列表",
    export_business_type_130: "出库计划",
    export_business_type_140: "产品信息",
    export_business_type_150: "存储费详细",
    export_business_type_160: "运输计划",
    export_business_type_161: "运输计划箱列表",
    export_business_type_170: "存储仓入库计划",
    export_business_type_180: "配送仓入库计划",
    export_business_type_190: "调拨至存储仓计划",
    export_business_type_200: "退货计划",
    export_business_type_210: "退货暂存费详细",
    export_business_type_220: "存储费详细按月导出",

    // 退货暂存状态
    rts_status_1: "无匹配",
    rts_status_2: "已匹配",
    rts_status_3: "已完成退货",
    rts_status_4: "已销毁",
    rts_status_5: "已取消",

    rts_destroyed_type_1: "用户销毁",
    rts_destroyed_type_2: "仓库销毁",
    rts_destroyed_type_3: "直接销毁",

    fee_calculation_method_0: "模板计算",
    fee_calculation_method_1: "卡车接口",

    pack_material_type_0: "外包装",
    pack_material_type_1: "合并包装",
    pack_material_type_2: "缠膜包装",

    account_record_type_1: "运单",
    account_record_type_2: "配送订单",

    product_merge_mark_0: "不支持",
    product_merge_mark_1: "支持-主产品",
    product_merge_mark_2: "支持-副产品",

    transit_status_10:"创建中",
    transit_status_20:"预约已提交",
    transit_status_30:"预约成功",
    transit_status_40:"入库中",
    transit_status_50:"部分完成",
    transit_status_60:"超额完成",
    transit_status_70:"完成",
    transit_status_190:"预约取消",
    transit_status_200:"预约失败",

    transportation_method_10:"商业快递",
    transportation_method_20:"集装箱",
    transportation_method_30:"卡车",

    box_type_10:"常规",
    box_type_20:"超规",
    box_type_30:"超大件",

    transit_in_bound_error_customer_type_11:"漏发",
    transit_in_bound_error_customer_type_12:"请求调查",
    transit_in_bound_error_customer_type_121:"仓库丢失",
    transit_in_bound_error_customer_type_122:"未成功接收",
    transit_in_bound_error_customer_type_123:"仓库已找到货",

    transit_out_bound_user_status_10:"已创建",
    transit_out_bound_user_status_20:"仓库处理中",
    transit_out_bound_user_status_30:"部分完成",
    transit_out_bound_user_status_40:"已完成",
    transit_out_bound_user_status_99:"已取消",

    schedule_delivery_type_1:"任意时间",
    schedule_delivery_type_2:"日期",
    schedule_delivery_type_3:"日期和时间",

    // 预约时间类型
    schedule_time_type_1: "提货时间",
    schedule_time_type_2: "发运时间",
    schedule_time_type_3: "送达时间",
    
    agent_order_logistics_print_type_1:"线上",
    agent_order_logistics_print_type_2:"线下",

    transit_delivery_method_1: "赛易物流",
    transit_delivery_method_2: "自提",

    // 预约送达类型/自提提货时间
    schedule_delivery_type_1: "任意时间",
    schedule_delivery_type_2: "日期",
    schedule_delivery_type_3: "日期和时间",

    // 审核状态
    transit_audit_status_10: "待审核",
    transit_audit_status_20: "已接受",
    transit_audit_status_30: "已拒绝",
    transit_audit_status_90: "取消",

    // 货件状态
    transit_shipment_status_10: "待入库",
    transit_shipment_status_20: "入库中",
    transit_shipment_status_30: "已入库",
    transit_shipment_status_35: "服务中",
    transit_shipment_status_40: "待出库",
    transit_shipment_status_50: "部分出库",
    transit_shipment_status_60: "已出库",
    transit_shipment_status_190: "被拒收",

    transit_out_bound_ware_status_10:"待分配",
    transit_out_bound_ware_status_20:"待拣货",
    transit_out_bound_ware_status_30:"拣货中",
    transit_out_bound_ware_status_40:"待出库",
    transit_out_bound_ware_status_50:"部分出库",
    transit_out_bound_ware_status_60:"已出库",
    transit_out_bound_ware_status_99:"已取消",

    sendout_type_1:"自己货物",
    sendout_type_2:"第三方货物",

    inventory_adjustment_type_1: "销毁",
    inventory_adjustment_type_2: "丢失",
    inventory_adjustment_type_3: "移除",

    transportation_delivery_way_1: "赛易物流",
    transportation_delivery_way_2: "自提",

    label_task_type_1: "箱标",
    label_task_type_2: "产品标",
    label_task_type_3: "箱标和产品标",

    label_task_status_10: "计划中",
    label_task_status_20: "已下单",
    label_task_status_30: "处理中",
    label_task_status_40: "已完成",
    label_task_status_190: "已取消",

    label_task_detail_status_1: "已归集",
    label_task_detail_status_2: "已服务",
    label_task_detail_status_4: "已上架",
    label_task_detail_status_99: "已取消",

    // 中转仓
    transit_shipment_detail_status_10: "待入库",
    transit_shipment_detail_status_15: "入库异常",
    transit_shipment_detail_status_20: "入库取消",
    transit_shipment_detail_status_30: "已入库",
    transit_shipment_detail_status_35: "服务中",
    transit_shipment_detail_status_40: "待出库",
    transit_shipment_detail_status_50: "已出库",
    
    // 核验类别
    box_check_type_0: "未核验",
    box_check_type_1: "手动核验",
    box_check_type_2: "闸机自动核验",

    //签名签收类型
    signature_type_1: "直接签名",
    signature_type_2: "成人签名",

    //配送仓入库异常 错误产品 选择的处理方式
    in_warehouse_error_for_error_product_customer_type_21:"放弃入库并销毁",
    in_warehouse_error_for_error_product_customer_type_22:"更换箱内信息",

    //入库货物异常情况
    in_warehouse_operation_error_type_1: "接收超时",
    in_warehouse_operation_error_type_2: "错误产品",

    transport_type_1:"海运",
    transport_type_2:"空运",

    airline_type_1:"船司",
    airline_type_2:"航司",

    announce_type_1: "更新日志",
    announce_type_2: "系统公告",

    announce_status_1: "正式",
    announce_status_2: "草稿",

    // 舱位状态
    shipping_space_status_10: "配货中",
    shipping_space_status_20: "货物离开发货仓",
    shipping_space_status_30: "货物已到达起运港",
    shipping_space_status_40: "货物报关中",
    shipping_space_status_45: "报关查验",
    shipping_space_status_50: "货物报关已放行",
    shipping_space_status_60: "计划离港",
    shipping_space_status_65: "航司甩柜",
    shipping_space_status_70: "货物已离港",
    shipping_space_status_80: "计划到港",
    shipping_space_status_90: "货物已到港",
    shipping_space_status_100: "货物清关中",
    shipping_space_status_110: "货物清关已放行",
    shipping_space_status_120: "货物到达海外仓",

    // 舱位类型
    shipping_space_type_1: "自装",
    shipping_space_type_2: "外配",

    transport_shipping_status_5:"待确认",
    transport_shipping_status_10:"待配货",
    transport_shipping_status_20:"已配仓位",
    transport_shipping_status_30:"已发运",
    transport_shipping_status_40:"已到海外仓",
    transport_shipping_status_50:"已送达",
    transport_shipping_status_190:"已取消",

    // 物流节点
    logistics_node_10: "离港",
    logistics_node_20: "到港",
    logistics_node_30: "到仓",

    // 物流账期计算配置
    logistics_accounting_period_calculation_way_1: "即时计算",
    logistics_accounting_period_calculation_way_2: "次月计算",

    product_packing_update_record_status_10:"待分配",
    product_packing_update_record_status_20:"已分配",
    product_packing_update_record_status_30:"已完成",
    product_packing_update_record_status_99:"取消",

    channel_is_support_for_plan_type_10: "出库计划",
    channel_is_support_for_plan_type_20: "运输计划",

    logistics_rebate_type_1: "体积",
    logistics_rebate_type_2: "重量",

    edge_unit_type_1: "CM",
    edge_unit_type_2: "IN",

    notification_business_type_1: "退货暂存-货物达到仓库",

    notification_way_1: "短信",
    notification_way_2: "邮件",

    transport_plan_end_journey_type_10: "快递",
    transport_plan_end_journey_type_20: "运输公司",
    transport_plan_end_journey_type_30: "仓库直送",
  },
};

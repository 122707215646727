<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.dev_currency_configuration_list")
    }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-select
                style="min-width: 200px"
                v-model:value="searchState.currencyId"
                :placeholder="$t('devops.choose_currency')"
                optionFilterProp="search-key"
                show-search
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in currencyList"
                  :key="item.id"
                  :value="item.id"
                  :title="getCurrencyName(item)"
                  :search-key="item.cnName + item.enName + item.code + item.symbol"
                  >{{ getCurrencyName(item) }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col
              ><a-button type="primary" @click="handleSearch">{{
                $t("common.search")
              }}</a-button></a-col
            >
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleCreate">{{
            $t("common.addition")
          }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 70 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #serialNumber="{ index }">{{ index + 1 }}</template>
        <template #currency="{ record }">{{
          getCurrencyName(record)
        }}</template>
        <template #configurationItem="{ record }">
          <a-tag :color="record.isSupportPayOnArrival ? '#2db7f5' : '#CCCCCC'"
            >{{ $t("devops.support_logistics_to_pay") }}:
            {{ getTagStatus(record.isSupportPayOnArrival) }}</a-tag
          >
        </template>
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #operate="{ record }">
          <a-dropdown-button @click="handleEdit(record)">
            {{ $t("common.edit") }}
            <template #overlay>
              <a-menu @click="handleOperateMenuClick">
                <a-menu-item :key="1" :record="record">
                  {{ $t("common.delete") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  createVNode,
  onActivated,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  Select,
  Button,
  Table,
  Tag,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  message,
} from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import { useI18n } from "vue-i18n/index";
import { getCurrencys } from "@/api/modules/common/index.js";
import {
  getListPaged,
  deleteCurrencyConfigRelation,
} from "@/api/modules/devops/currencyConfiguration.js";

export default defineComponent({
  name: "dev_currency_configuration_list",
  components: {
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ATag: Tag,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { getters } = useStore();

    const state = reactive({
      currencyList: [],
      searchState: {
        currencyId: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        title: () => vueI18n.t("common.serial_number"),
        width: 50,
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        title: () => vueI18n.t("devops.currency"),
        width: 100,
        slots: {
          customRender: "currency",
        },
      },
      {
        title: () => vueI18n.t("account.time"),
        width: 100,
        slots: {
          customRender: "creationTime",
        },
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 100,
        slots: {
          customRender: "operate",
        },
      },
    ];

    const handleCreate = () => {
      router.push({ name: "dev_currency_configuration_edit" });
    };

    const handleEdit = (record) => {
      router.push({
        name: "dev_currency_configuration_edit",
        params: { id: record.id },
      });
    };

    const handleDelete = (record) => {
      deleteCurrencyConfigRelation({ id: record.id })
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          handleInitTable();
        })
        .catch(() => {});
    };

    const handleOperateMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 1:
          Modal.confirm({
            icon: createVNode(ExclamationCircleOutlined),
            title: () => vueI18n.t("warehouse.operation_tips"),
            content: () => vueI18n.t("region.delete_confirm") + "?",
            onOk() {
              handleDelete(record);
            },
          });
          break;
        default:
          break;
      }
    };

    const getData = async () => {
      try {
        state.tableData.loading = true;
        const data = Object.assign(
          {},
          state.tableData.pageData,
          state.searchStateCache
        );
        let { result } = await getListPaged(data);
        if (result) {
          let { totalCount = 0, items = [] } = result;
          items = items.map((item) => {
            item.loading = false;
            return item;
          });
          state.tableData.tableList = items;
          state.tableData.pageData.totalCount = parseInt(totalCount);
        }
      } catch (error) {
      } finally {
        state.tableData.loading = false;
      }
    };

    const handleInitTable = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getData();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitTable();
    };
    
    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getData();
    };

    const getCurrencyName = (item) => {
      if (getters.language === "zh-CN") {
        return `${item.cnName} ${item.code} ${item.symbol}`;
      } else {
        return `${item.enName} ${item.code} ${item.symbol}`;
      }
    };

    const getCurrencyList = () => {
      getCurrencys()
        .then(({ result }) => {
          if (result) {
            state.currencyList = result;
          }
        })
        .catch(() => {});
    };

    const getTagStatus = (result) => {
      if (getters.language === "zh-CN") {
        return !!result ? "是" : "否";
      } else {
        return !!result ? "Yes" : "No";
      }
    };

    onActivated(handleInitTable);

    onMounted(getCurrencyList);

    return {
      ...toRefs(state),
      columns,
      getCurrencyName,
      handleSearch,
      handlePage,
      handleCreate,
      handleEdit,
      handleOperateMenuClick,
      getTagStatus,
    };
  },
});
</script>

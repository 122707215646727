<template>
    <Content>
        <template #contentTitle>
            {{ $t("menu.warehouse_fee_setting") }}
        </template>
        <template v-slot:contentBody="wrap">
            <div class="mb-2">
                <b class="d-inline-block mr-4">仓库编号: {{ warehouseData.warehouseNo }}</b>
                <b class="d-inline-block">地址: {{ getAddress() }}</b>
            </div>
            <div>
                <a-tabs v-model:activeKey="activeKey" size="small">
                    <a-tab-pane key="package" tab="包装费配置">
                        <div>
                            <a-table
                                :columns="packageFeeColumns"
                                size="small"
                                :data-source="warehouseData.packageSettings"
                                :pagination="false"
                                :scroll="{ x: 500, y: wrap.contentHeight - 150 }"
                                :rowKey="(record, index) => { return index; }"
                                :loading="warehouseData.packageLoading"
                            >
                                <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
                                <template
                                    v-slot:materialType="{ record }"
                                >{{ getLanguageName({ cnName: record.materialTypeCnName, enName: record.materialTypeEnName }) }}</template>
                                <template
                                    v-slot:specifications="{ record }"
                                >{{ record.packMaterialLength || 0 }}x{{ record.packMaterialWidth || 0 }}x{{ record.packMaterialHeight || 0 }}cm</template>
                                <template v-slot:weight="{ record }">
                                    <span>{{ gToKg(record.packMaterialWeight) }}kg</span>
                                </template>
                                <template v-slot:volume="{ record }">
                                    <span>
                                        {{ cmCubicToM(record.packMaterialVolume) }}m
                                        <sup>3</sup>
                                    </span>
                                </template>
                                <template v-slot:bearingQuantity="{ record }">
                                    <span>{{ gToKg(record.packBearingQuantity) }}kg</span>
                                </template>
                                <template v-slot:price="{ record }">
                                    <div v-if="!record.edit" class="text-nowrap">
                                        <span>{{ record.currencyCode + " " + record.currencySymbol }}{{ record.price }}</span>
                                        <a-button
                                            type="link"
                                            size="small"
                                            @click="() => { record.editPrice = record.price; record.edit = true; }"
                                        >
                                            <EditOutlined />
                                        </a-button>
                                    </div>
                                    <a-input-group v-else style="width:150px;" size="small" compact>
                                        <a-input-number
                                            size="small"
                                            style="width: 40%;"
                                            class="input-group-mid-number"
                                            :min="0"
                                            v-model:value="record.editPrice"
                                            :loading="record.saving"
                                        ></a-input-number>
                                        <a-button
                                            size="small"
                                            style="width: 30%;"
                                            :loading="record.saving"
                                            @click="handlePackagePriceSave(record)"
                                        >
                                            <small>保存</small>
                                        </a-button>
                                        <a-button
                                            size="small"
                                            style="width: 30%;"
                                            @click="() => { record.edit = false; }"
                                        >
                                            <small>取消</small>
                                        </a-button>
                                    </a-input-group>
                                </template>

                                <template v-slot:isActive="{ record }">
                                    <a-switch
                                        v-model:checked="record.isActive"
                                        size="small"
                                        :loading="record.saving"
                                        @change="handlePackageIsActive(record)"
                                    ></a-switch>
                                </template>
                            </a-table>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="operating" tab="操作费配置">
                        <div>
                            <a-table
                                :columns="operatingFeeColumns"
                                size="small"
                                :data-source="warehouseData.operatingSettings"
                                :pagination="false"
                                :scroll="{ x: 500, y: wrap.contentHeight - 150 }"
                                :rowKey="(record, index) => { return index; }"
                                :loading="warehouseData.operatingLoading"
                            >
                                <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
                                <template v-slot:price="{ record }">
                                    <div v-if="!record.edit" class="text-nowrap">
                                        <span>{{ record.currencyCode + " " + record.currencySymbol }}{{ record.price }}</span>
                                        <a-button
                                            type="link"
                                            size="small"
                                            @click="() => { record.editPrice = record.price; record.edit = true; }"
                                        >
                                            <EditOutlined />
                                        </a-button>
                                    </div>
                                    <a-input-group v-else style="width:150px;" size="small" compact>
                                        <a-input-number
                                            size="small"
                                            style="width: 40%;"
                                            class="input-group-mid-number"
                                            :min="0"
                                            v-model:value="record.editPrice"
                                            :loading="record.saving"
                                        ></a-input-number>
                                        <a-button
                                            size="small"
                                            style="width: 30%;"
                                            :loading="record.saving"
                                            @click="handleOperatingPriceSave(record)"
                                        >
                                            <small>保存</small>
                                        </a-button>
                                        <a-button
                                            size="small"
                                            style="width: 30%;"
                                            @click="() => { record.edit = false; }"
                                        >
                                            <small>取消</small>
                                        </a-button>
                                    </a-input-group>
                                </template>

                                <template v-slot:isActive="{ record }">
                                    <a-switch
                                        v-model:checked="record.isActive"
                                        size="small"
                                        :loading="record.saving"
                                        @change="handleOperatingIsActive(record)"
                                    ></a-switch>
                                </template>
                            </a-table>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="storage" tab="仓储费配置" force-render>
                        <div>
                            <a-table
                                :columns="storageFeeColumns"
                                size="small"
                                :data-source="warehouseData.storageSettings"
                                :pagination="false"
                                :scroll="{ x: 500 }"
                                :rowKey="(record, index) => { return index; }"
                                :loading="warehouseData.storageLoading"
                            >
                                <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
                                <template v-slot:price="{ record }">
                                    <div v-if="!record.edit" class="text-nowrap">
                                        <span>{{ record.currencyCode + " " + record.currencySymbol }}{{ record.price }}</span>
                                        <a-button
                                            type="link"
                                            size="small"
                                            @click="() => { record.editPrice = record.price; record.edit = true; }"
                                        >
                                            <EditOutlined />
                                        </a-button>
                                    </div>
                                    <a-input-group v-else style="width:150px;" size="small" compact>
                                        <a-input-number
                                            size="small"
                                            style="width: 40%;"
                                            class="input-group-mid-number"
                                            :min="0"
                                            v-model:value="record.editPrice"
                                            :loading="record.saving"
                                        ></a-input-number>
                                        <a-button
                                            size="small"
                                            style="width: 30%;"
                                            :loading="record.saving"
                                            @click="handleStoragePriceSave(record)"
                                        >
                                            <small>保存</small>
                                        </a-button>
                                        <a-button
                                            size="small"
                                            style="width: 30%;"
                                            @click="() => { record.edit = false; }"
                                        >
                                            <small>取消</small>
                                        </a-button>
                                    </a-input-group>
                                </template>

                                <template v-slot:isActive="{ record }">
                                    <a-switch
                                        v-model:checked="record.isActive"
                                        size="small"
                                        :loading="record.saving"
                                        @change="handleStorageIsActive(record)"
                                    ></a-switch>
                                </template>
                            </a-table>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </template>
    </Content>
</template>

<script>
import { Table, Button, Modal, Input, Select, Card, Row, Col, Form, Radio, Tabs, InputNumber, Switch } from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue'
import { useRoute, useRouter } from "vue-router";
import {
    getById as getWarehouseById,
    getPackageConfigList, updatePackageConfigStatus, updatePackagePrice,
    getOperatingConfigList, updateOperatingConfigStatus, updateOperatingPrice,
    getStorageConfigList, updateStorageConfigStatus, updateStoragePrice
} from "../../api/modules/warehouse/index"
import { defineComponent, onMounted, reactive, toRefs, ref, onActivated, } from 'vue';
import { getAddressByLanguageV2, getName } from "../../utils/general"
import { useStore } from "vuex"
import { useI18n } from 'vue-i18n/index'
import { gToKg, kgToG, cmCubicToM, mCubicToCm } from "../../utils/general.js";

export default defineComponent({
    name: "warehouse_fee_setting",
    components: {
        ATable: Table,
        AButton: Button,
        AModal: Modal,
        CPager,
        AInput: Input,
        AInputGroup: Input.Group,
        AInputSearch: Input.Search,
        ASelect: Select,
        ACard: Card,
        ARow: Row,
        ACol: Col,
        Content,
        ASelectOption: Select.Option,
        AForm: Form,
        AFormItem: Form.Item,
        ARadio: Radio,
        ARadioOption: Radio.Option,
        ARadioButton: Radio.Button,
        ARadioGroup: Radio.Group,
        ATabs: Tabs,
        ATabPane: Tabs.TabPane,
        AInputNumber: InputNumber,
        ASwitch: Switch
    },
    setup() {
        const route = useRoute();
        const { getters } = useStore();
        const vueI18n = useI18n({ useScope: 'global' });

        const packageFeeColumns = [
            {
                width: 80,
                fixed: 'left',
                title: () => "序号",
                slots: {
                    customRender: "tableIndex"
                }
            },
            {
                dataIndex: "feeCode",
                width: 100,
                fixed: 'left',
                title: () => "费用代码"
            },
            {
                dataIndex: "packMaterialName",
                width: 200,
                title: () => "包装材料名称"
            },
            {
                width: 200,
                title: () => "材质类型",
                slots: {
                    customRender: "materialType"
                }
            },
            {
                width: 200,
                title: () => "包装材料规格",
                slots: {
                    customRender: "specifications"
                }
            },
            {
                width: 150,
                title: () => "包装材料重量",
                slots: {
                    customRender: "weight"
                }
            },
            {
                width: 150,
                title: () => "包装容量",
                slots: {
                    customRender: "volume"
                }
            },
            {
                width: 100,
                title: () => "包装承重",
                slots: {
                    customRender: "bearingQuantity"
                }
            },
            {
                dataIndex: "feeUnitName",
                width: 100,
                title: () => "单位"
            },
            {
                width: 150,
                title: () => "价格",
                slots: {
                    customRender: "price"
                }
            },
            {
                width: 150,
                title: () => "状态",
                slots: {
                    customRender: "isActive",
                }
            },
        ];
        const operatingFeeColumns = [
            {
                width: 80,
                fixed: 'left',
                title: () => "序号",
                slots: {
                    customRender: "tableIndex"
                }
            },
            {
                dataIndex: "feeCode",
                width: 150,
                fixed: 'left',
                title: () => "费用代码"
            },
            {
                //todo 暂时直接使用文本，后期需要改为多语言枚举文本
                dataIndex: "feeTypeStr",
                width: 150,
                title: () => "费用类型"
            },
            {
                //todo 暂时直接使用文本，后期需要改为多语言枚举文本
                dataIndex: "feeDescription",
                width: 300,
                title: () => "费用描述"
            },
            {
                dataIndex: "serviceTypeNames",
                width: 200,
                title: () => "适用服务类型"
            },
            {
                dataIndex: "feeUnitName",
                width: 100,
                title: () => "收费单位"
            },
            {
                width: 150,
                title: () => "价格",
                slots: {
                    customRender: "price"
                }
            },
            {
                width: 150,
                title: () => "状态",
                slots: {
                    customRender: "isActive",
                }
            },
        ];
        const storageFeeColumns = [].concat(operatingFeeColumns);

        const warehouseId = route.params.id;

        const warehouseData = reactive({
            loading: false,
            warehouseNo: null,
            info: null,
            packageLoading: false,
            packageSettings: [],
            operatingLoading: false,
            operatingSettings: [],
            storageLoading: false,
            storageSettings: []
        });

        const getWarehouseInfo = () => {
            warehouseData.loading = true;
            return getWarehouseById(warehouseId)
                .then((res) => {
                    warehouseData.loading = false;
                    let { result } = res;
                    warehouseData.warehouseNo = result.warehouseNo;
                    warehouseData.info = result;
                }).catch(() => {
                    warehouseData.loading = false;
                });
        }

        const getAddress = () => {
            if (warehouseData.info) {
                return getAddressByLanguageV2(warehouseData.info || {}, getters.language);
            } else {
                return "-";
            }
        }

        const getLanguageName = (item) => {
            return getName(item, getters.language);
        }

        const getPackageList = () => {
            warehouseData.packageLoading = true;
            getPackageConfigList({ warehouseId: warehouseId })
                .then((res) => {
                    let { result } = res;
                    warehouseData.packageSettings = result;
                    warehouseData.packageLoading = false;
                })
                .catch(() => {
                    warehouseData.packageLoading = false;
                })

        }

        const handlePackagePriceSave = (record) => {
            record.saveing = true;
            updatePackagePrice({ warehouseId: warehouseId, id: record.id, price: record.editPrice })
                .then((res) => {
                    record.price = record.editPrice;
                    record.saving = false;
                    record.edit = false;
                })
                .catch(() => {
                    record.saving = false;
                })
        }

        const handlePackageIsActive = (record) => {
            record.saving = true;
            updatePackageConfigStatus({ id: record.id, warehouseId: warehouseId, isActive: record.isActive })
                .then((res) => {
                    record.saving = false;
                })
                .catch(() => {
                    record.isActive = !record.isActive;
                    record.saving = false;
                })

        }

        const getOperatingList = () => {
            warehouseData.operatingLoading = true;
            getOperatingConfigList({ warehouseId: warehouseId, supportWarehouseType: warehouseData.info.warehouseType })
                .then((res) => {
                    let { result } = res;
                    warehouseData.operatingSettings = result;
                    warehouseData.operatingLoading = false;
                })
                .catch(() => {
                    warehouseData.operatingLoading = false;
                })
        }

        const handleOperatingPriceSave = (record) => {
            record.saveing = true;
            updateOperatingPrice({ warehouseId: warehouseId, id: record.id, price: record.editPrice })
                .then((res) => {
                    record.price = record.editPrice;
                    record.saving = false;
                    record.edit = false;
                })
                .catch(() => {
                    record.saving = false;
                })
        }

        const handleOperatingIsActive = (record) => {
            record.saving = true;
            updateOperatingConfigStatus({ id: record.id, warehouseId: warehouseId, isActive: record.isActive })
                .then((res) => {
                    record.saving = false;
                })
                .catch(() => {
                    record.isActive = !record.isActive;
                    record.saving = false;
                })

        }

        const getStorageList = () => {
            warehouseData.storageLoading = true;
            getStorageConfigList({ warehouseId: warehouseId })
                .then((res) => {
                    let { result } = res;
                    warehouseData.storageSettings = result;
                    warehouseData.storageLoading = false;
                })
                .catch(() => {
                    warehouseData.storageLoading = false;
                })
        }

        const handleStoragePriceSave = (record) => {
            record.saveing = true;
            updateStoragePrice({ warehouseId: warehouseId, id: record.id, price: record.editPrice })
                .then((res) => {
                    record.price = record.editPrice;
                    record.saving = false;
                    record.edit = false;
                })
                .catch(() => {
                    record.saving = false;
                })
        }

        const handleStorageIsActive = (record) => {
            record.saving = true;
            updateStorageConfigStatus({ id: record.id, warehouseId: warehouseId, isActive: record.isActive })
                .then((res) => {
                    record.saving = false;
                })
                .catch(() => {
                    record.isActive = !record.isActive;
                    record.saving = false;
                })

        }

        const handleSearch = () => {
            getWarehouseInfo().then(() => {
                getOperatingList();
            });
            getPackageList();
            getStorageList();
        }

        onMounted(() => {
            handleSearch();
        });

        return {
            packageFeeColumns,
            operatingFeeColumns,
            storageFeeColumns,
            warehouseData,

            handleSearch,
            getAddress,
            getLanguageName,
            gToKg,
            cmCubicToM,
            handlePackagePriceSave,
            handlePackageIsActive,
            handleOperatingPriceSave,
            handleOperatingIsActive,
            handleStoragePriceSave,
            handleStorageIsActive,

            activeKey: ref('package'),
        };
    }
})
</script>
<style lang="less" scoped>
:deep(.input-group-mid .ant-input-group-addon) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}
:deep(.input-group-mid-number) {
    border-radius: 0;
    width: 100%;
}
:deep(.input-group-end-number) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
}
:deep(.input-group-start-number) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}
</style>
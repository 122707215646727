import client from "../../client";

const Api = {
  // 应用列表
  getListPaged: "/api/OpenPlatformService/AuthorizeApplication/GetListPaged",
  // 新增应用
  createAuthorizeApplication: "/api/OpenPlatformService/AuthorizeApplication/Create",
  // 编辑应用
  updateAuthorizeApplication: "/api/OpenPlatformService/AuthorizeApplication/Update",
  // 根据Id获取应用
  getAuthorizeApplicationById: "/api/OpenPlatformService/AuthorizeApplication/GetById",
  // 切换禁用启用状态
  updateAuthorizeApplicationIsActive: "/api/OpenPlatformService/AuthorizeApplication/UpdateIsActive",
  // 根据Id获取应用
  resetAuthorizeApplicationAppSecret: "/api/OpenPlatformService/AuthorizeApplication/ResetAppSecret",
  // 查询所有权限项
  getAuthorizeInfoList: "/api/OpenPlatformService/AuthorizeInfo/GetList",
  // 设置应用所需权限项
  setAppRequiredAuthorizeInfo: "/api/OpenPlatformService/AuthorizeApplication/SetAppRequiredAuthorizeInfo",
  // 根据应用id获取授权项id集合
  getAuthorizeIdsByAppId: "/api/OpenPlatformService/AuthorizeApplication/GetAuthorizeIdsByAppId",
};

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};

export const createAuthorizeApplication = (data) => {
  return client.request({
    url: Api.createAuthorizeApplication,
    data,
    method: "POST",
  });
};

export const updateAuthorizeApplication = (data) => {
  return client.request({
    url: Api.updateAuthorizeApplication,
    data,
    method: "POST",
  });
};

export const getAuthorizeApplicationById = (data) => {
  return client.request({
    url: Api.getAuthorizeApplicationById,
    params: data,
    method: "GET",
  });
};

export const updateAuthorizeApplicationIsActive = (data) => {
  return client.request({
    url: Api.updateAuthorizeApplicationIsActive,
    data,
    method: "POST",
  });
};

export const resetAuthorizeApplicationAppSecret = (data) => {
  return client.request({
    url: Api.resetAuthorizeApplicationAppSecret,
    data,
    method: "POST",
  });
};

export const getAuthorizeInfoList = (data) => {
  return client.request({
    url: Api.getAuthorizeInfoList,
    data,
    method: "POST",
  });
};

export const setAppRequiredAuthorizeInfo = (data) => {
  return client.request({
    url: Api.setAppRequiredAuthorizeInfo,
    data,
    method: "POST",
  });
};

export const getAuthorizeIdsByAppId = (data) => {
  return client.request({
    url: Api.getAuthorizeIdsByAppId,
    data,
    method: "POST",
  });
};

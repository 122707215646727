<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.warehouse_monitor_storage_outbound_task_detail") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <!-- 仓库信息 -->
        <a-row :gutter="[0, 8]">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.outbound_plan_number") }}: {{ plan.planNo }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.out_plan_name") }}: {{ plan.planName }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.creation_time") }}:
                {{ $filters.utcToCurrentTime(plan.creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.last_modification_time") }}:
                {{ $filters.utcToCurrentTime(plan.lastModificationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.target_related_doc_no") }}:
                <CPlanNumberItem :no="plan.toRelationPlanNo"></CPlanNumberItem>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.out_warehouse_type") }}:
                {{
                  $t($enumLangkey("outWarehouseType", plan.outWarehouseType))
                }}
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row
              :gutter="[0, 8]"
              style="height: 100%"
              type="flex"
              align="middle"
            >
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center">
                  <a-typography-text strong>{{
                    $t("finance.status")
                  }}</a-typography-text>
                </div>
                <div style="text-align: center">
                  <span v-if="plan.outPlanStatus">
                    <a-typography-text
                      type="secondary"
                      v-if="plan.outPlanStatus === outPlanStatusEnum.cancelOut"
                      >{{
                        $t($enumLangkey("outPlanStatus", plan.outPlanStatus))
                      }}</a-typography-text
                    >
                    <a-typography-text type="success" v-else>{{
                      $t($enumLangkey("outPlanStatus", plan.outPlanStatus))
                    }}</a-typography-text>
                  </span>
                  <span v-else>-</span>
                </div>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center">
                  <a-typography-text strong>{{
                    $t("warehouse.logistics_delivery_status")
                  }}</a-typography-text>
                </div>
                <div style="text-align: center">
                  <a-typography-text
                    type="success"
                    v-if="plan.deliveryStatus"
                    >{{
                      $t($enumLangkey("deliveryStatus", plan.deliveryStatus))
                    }}</a-typography-text
                  >
                  <span v-else>-</span>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("warehouse.customer") }}:
            <span v-if="plan.shopName">{{ plan.shopName || "-" }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("common.shop_mark") }}:
            <span v-if="plan.shopMark">{{ plan.shopMark || "-" }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            NO:
            <span v-if="plan.shopNo">{{ plan.shopNo || "-" }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("warehouse.out_warehouse_no") }}:
            {{ plan.inventoryWarehouseAddress.no }}
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            {{ $t("warehouse.warehouse_address") }}:
            {{ getAddress(plan.inventoryWarehouseAddress) }}
            <span class="ml-5" v-if="plan.inventoryWarehouseAddress">
              {{ $t("logistics.contacts") }}:
              {{ getLinkman(plan.inventoryWarehouseAddress) }}
            </span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <!-- 目标仓库代码(仓库间调拨有) -->
            <span
              v-if="
                plan.outWarehouseType ==
                  outWarehouseTypeEnum.betweenWarehouseAllocate ||
                plan.outWarehouseType ==
                  outWarehouseTypeEnum.replaceSendWarehouseEntry
              "
            >
              {{ $t("warehouse.target_warehouse_code") }}:
              {{ plan.targetAddress.no || "-" }}
            </span>
            <!-- FBA仓库代码(只有fba) -->
            <span
              v-else-if="
                plan.outWarehouseType == outWarehouseTypeEnum.amazonFba
              "
            >
              {{ $t("warehouse.fba_warehouse_code") }}:
              {{ plan.targetAddress.name || "-" }}
            </span>
            <span
              v-else-if="
                plan.outWarehouseType == outWarehouseTypeEnum.mercadolibre
              "
            >
              {{ $t("warehouse.fbm_warehouse_code") }}:
              {{ plan.targetAddress.name || "-" }}
            </span>
            <!-- 发至-->
            <span
              v-else-if="
                plan.outWarehouseType == outWarehouseTypeEnum.otherAddress
              "
            >
              {{ $t("warehouse.destination") }}:
              {{ plan.targetAddress?.name || "-" }}
            </span>
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            <!-- 运送地址(本地配送仓没有) -->
            <span
              v-if="
                plan.outWarehouseType !=
                outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                plan.outWarehouseType !=
                outWarehouseTypeEnum.destruction
              "
            >
              {{ $t("warehouse.transport_address") }}:
              {{ getAddress(plan.targetAddress) }}
              <span v-if="plan.targetAddress.linkManPhone">
                (+{{ plan.targetAddress.phoneCountryCode }}
                {{ plan.targetAddress.linkManPhone }})
              </span>
              <a-tag
                v-if="
                  plan.targetAddress.countryId !=
                  plan.inventoryWarehouseAddress.countryId
                "
                color="warning"
              >
                {{ $t("logistics.international_transport") }}
              </a-tag>
            </span>
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            <a-row
              :gutter="[0, 8]"
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.amazonFba"
            >
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.amazon_shipping_number") }}:
                {{ plan.amazonShippingNumber }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.shipment_tracking_number") }}:
                {{ plan.shipmentTrackingNumber }}
              </a-col>
            </a-row>
            <a-row
              :gutter="[0, 8]"
              v-if="plan.outWarehouseType == outWarehouseTypeEnum.mercadolibre"
            >
              <a-col :md="12" :sm="24" :xs="24">
                Seller ID: {{ plan.amazonShippingNumber }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.warehousing_number") }}:
                {{ plan.shipmentTrackingNumber }}
              </a-col>
            </a-row>
          </a-col>
        </a-row>

        <!-- 列表 -->
        <div class="table mt-3">
          <a-table
            :columns="columns"
            :data-source="plan.tableList"
            size="small"
            :pagination="{
              defaultPageSize: defaultPageSize,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            @change="
              (page) => {
                currentPageIndex = page.current;
              }
            "
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
          >
            <template #productNameAndSeSku>
              <span>
                {{ $t("warehouse.product_name") }}/{{
                  $t("warehouse.product_no")
                }}
              </span>
            </template>

            <template #boxNoCustom="{ record }">
              <a-row :gutter="[12, 12]">
                <a-col>
                  <div>{{ record.box.boxNo }}</div>
                  <div>
                    <small>{{ record.box.selfBoxNo ?? "-" }}</small>
                  </div>
                </a-col>
                <a-col>
                  <CTagMixedOrSingle
                    :productKindCount="
                      record.box?.container?.productKindCount * 1
                    "
                  ></CTagMixedOrSingle>
                </a-col>
              </a-row>
            </template>

            <template #containerCount="{ record }">
              <div v-if="record.box?.container?.containerCount">
                {{ record.box?.container?.containerCount }} Unit
              </div>
              <div v-else>-</div>
            </template>

            <template #out_warehouse_info="{ record }">
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_size") }}:
                </a-col>
                <a-col>
                  <span v-if="record.box?.container?.containerLength">
                    {{ record.box?.container?.containerLength }}x{{
                      record.box?.container?.containerWidth
                    }}x{{ record.box?.container?.containerHeight }} cm</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_weight") }}:
                </a-col>
                <a-col>
                  <span v-if="record.box?.container?.containerWeight">
                    {{ gToKg(record.box?.container?.containerWeight) }} kg</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
            </template>

            <template #lableServeCustom="{ record }">
              <div>
                <div
                  v-if="
                    plan.outWarehouseType ==
                    outWarehouseTypeEnum.replaceSendWarehouseEntry
                  "
                >
                  {{
                    $t(
                      $enumLangkey(
                        "labelDispose",
                        record.productLabelProcessing
                      )
                    )
                  }}
                </div>
                <template
                  v-else-if="
                    plan.outWarehouseType !=
                    outWarehouseTypeEnum.betweenWarehouseAllocate
                  "
                >
                  <template v-if="record.serviceLabelImgUrl">
                    <div>{{ $t("warehouse.outer_box_label") }}</div>
                    <CFileDisplay
                      :fileUrl="record.serviceLabelImgUrl"
                      :useLocal="true"
                    ></CFileDisplay>
                    <div>{{ record.serviceLabelNo }}</div>
                  </template>
                  <span v-else>-</span>
                </template>
              </div>
            </template>

            <template #title>
              <a-row justify="end">
                <a-col
                  ><a-button
                    type="primary"
                    ghost
                    @click="handleShowProductStatisticsModal"
                    >{{ $t("common.product_statistics") }}</a-button
                  ></a-col
                >
              </a-row>
            </template>

            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation
                :disabled="!record.box.items || record.box.items?.length == 0"
                :open="expanded"
                @click="(e) => onExpand(record, e)"
              />
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="innerColumns"
                :data-source="record.box.items"
                :pagination="false"
                :row-key="(record, index) => index"
                size="small"
              >
                <template #product_info="{ record }">
                  <a-row :gutter="[12, 12]">
                    <a-col>
                      <CFileDisplay :fileUrl="record.productImgUrl" />
                    </a-col>
                    <a-col>
                      {{ record.productName }}
                      <div>
                        {{ record.productNo }}
                      </div>
                    </a-col>
                  </a-row>
                </template>
                <template #count="{ record }">
                  {{ record.containerCount }} Unit
                </template>
              </a-table>
            </template>

            <template #footer>
              <a-row type="flex" justify="start" class="mr-5" :gutter="32">
                <a-col
                  >{{ $t("common.total") }}: {{ plan.totalBox
                  }}{{ $t("warehouse.box") }}</a-col
                >
                <a-col
                  >{{ $t("warehouse.outbound_total_volume") }}:
                  {{ plan.outTotalVolume }}m³</a-col
                >
                <a-col
                  >{{ $t("warehouse.out_total_weight") }}:
                  {{ plan.outTotalWeight }}kg</a-col
                >

                <a-col>
                  {{ $t("logistics.transport_characteristics") }}:
                  <a-tag
                    color="default"
                    v-for="item in plan.transportCharacteristics"
                    :key="item"
                  >
                    {{ $t($enumLangkey("transportCharacteristics", item)) }}
                  </a-tag>
                </a-col>

                <a-col
                  v-if="
                    plan.outWarehouseType !=
                    outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                    plan.outWarehouseType !=
                    outWarehouseTypeEnum.destruction
                  "
                >
                  <a-typography-text
                    v-if="plan.isSelectHitServe != 0"
                    type="danger"
                    >{{
                      $t("warehouse.selected_pallet_packing_serve")
                    }}</a-typography-text
                  >
                </a-col>
              </a-row>
            </template>
          </a-table>
        </div>

        <!-- 提货信息 -->
        <div class="mt-3">
          <a-row>
            <a-col
              :span="4"
              v-if="
                plan.outWarehouseType !=
                outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                plan.outWarehouseType !=
                outWarehouseTypeEnum.destruction
              "
            >
              {{ $t("warehouse.transportation_delivery_way") }}:
              <span v-if="plan.deliverType">{{
                $t($enumLangkey("deliverType", plan.deliverType))
              }}</span>
              <span v-else>-</span>
            </a-col>
            <a-col
              :span="4"
              v-if="plan.deliverType == deliverTypeEnum.selfTake"
            >
              {{ $t("warehouse.pick_up_method") }}:
              <span>{{
                $t($enumLangkey("selfMentionType", plan.selfMentionType))
              }}</span>
            </a-col>
            <a-col
              :span="4"
              v-if="
                plan.deliverType == deliverTypeEnum.selfTake &&
                plan.selfMentionType == selfMentionTypeEnum.truck
              "
            >
              {{ $t("warehouse.reserved_Pick_up_information") }}:
              <span>{{ plan.reservedInfo }}</span>
            </a-col>
            <a-col>
              {{ $t("warehouse.payment_method") }}:
              <span v-if="plan.payWay">{{
                $t($enumLangkey("payWay", plan.payWay))
              }}</span>
              <span v-else>-</span>
            </a-col>
          </a-row>
        </div>

        <!-- 物流方案 -->
        <div
          class="mt-3"
          v-if="
            plan.transportationDeliveryWay ==
              transportationDeliveryWayEnum.cwmLogistics &&
            logisticsSchemeList?.length > 0
          "
        >
          <!-- 模板计算物流方案 -->
          <div
            v-if="
              logisticsSchemeList?.length > 0 &&
              logisticsSchemeList[0]?.feeCalculationMethod ==
                feeCalculationMethodEnum.template
            "
          >
            <a-table
              :columns="columnsLogisticsScheme"
              :data-source="logisticsSchemeList"
              size="small"
              :scroll="{ x: true, y: 1000 }"
              :pagination="false"
            >
              <template #logisticsScheme>
                <span>
                  {{ $t("warehouse.logistics_scheme") }} /
                  {{ $t("warehouse.logistics_code") }}
                </span>
              </template>

              <template #logisticsWay>
                <span>
                  {{ $t("warehouse.logistics_type") }}
                </span>
              </template>

              <template #deliveryWay>
                <span>
                  {{ $t("warehouse.delivery_way") }}
                </span>
              </template>

              <template #referenceAging>
                <span>
                  {{ $t("warehouse.reference_aging") }}
                </span>
              </template>
              <template #freights>
                <span>
                  {{ $t("warehouse.freight") }}
                </span>
              </template>

              <template #details>
                <span>
                  {{ $t("common.details") }}
                </span>
              </template>
              <template #optionalServices>
                <span>
                  {{ $t("logistics.selected_service") }}
                </span>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">
                  {{ record.scheme }} / {{ record.code }}
                </div>
                <a-row :gutter="[8, 8]">
                  <a-col v-for="item in record.descriptionItems" :key="item">
                    <CheckCircleOutlined
                      class="text-warning mr-1"
                      :style="{ fontSize: '12px' }"
                    />
                    <span
                      style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                    >
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{ record.daysMin }}-{{ record.daysMax }}
                  {{ $t("warehouse.workday") }}
                  ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
                </div>
              </template>
              <template #freightsCustom="{ record }">
                <div class="text-warning">
                  {{ $t("warehouse.average_unit_price") }}:
                  {{ record.currencySymbol
                  }}{{ $filters.amountToFixed2(record.averagePrice) }}
                </div>
                <div class="text-warning">
                  {{ $t("warehouse.total_prices") }}: {{ record.currencySymbol
                  }}{{ $filters.amountToFixed2(record.expressFee) }}
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div v-if="record.logisticsCalcMethod == 0">
                  {{ $t("logistics.chargeable_weight") }}:
                  {{ record.chargeableWeight }}kg
                </div>
                <div v-else>
                  {{ $t("logistics.chargeable_volume") }}:
                  {{ record.chargeableVolume }}m<sup>3</sup>
                </div>
                <div>
                  {{ $t("logistics.volume_weight_coefficient")
                  }}{{ record.volumeWeightParam }}
                </div>
              </template>
              <template #optionalServicesCustom="{ record }">
                <template v-if="isSelectedDDP || isSelectedSignFee">
                  <div v-if="isSelectedDDP">
                    DDP({{
                      $filters.formatCurrencyAmount(
                        record.currencySymbol,
                        record.logisticsRegionInfo.ddpFee,
                        false
                      )
                    }})
                  </div>
                  <div v-if="record.logisticsRegionInfo.signatureType">
                    <CSignSupported
                      v-model:value="record.logisticsRegionInfo.signatureType"
                      :symbol="record.currencySymbol"
                      :signFeeOptions="record?.logisticsRegionInfo.signFeeDtos"
                      :type="'readonly'"
                    />
                  </div>
                </template>
                <span v-else>-</span>
              </template>
            </a-table>
            <div class="mt-2">
              <a-tag color="warning" v-if="isRemoteArea">{{
                $t("logistics.remote_site")
              }}</a-tag>
              <template v-if="isCommercial != null">
                <a-tag color="warning" v-if="isCommercial">{{
                  $t("logistics.business_site")
                }}</a-tag>
                <a-tag color="warning" v-else>{{
                  $t("logistics.residence")
                }}</a-tag>
              </template>
            </div>

            <div class="mt-3" v-show="isShowVATInput">
              <label>VAT: </label>
              {{ VAT }}
            </div>
          </div>
          <!-- 卡车物流方案 -->
          <div v-else>
            <a-table
              :columns="columnsTruckScheme"
              :data-source="logisticsSchemeList"
              :scroll="{ x: 1000, y: 1000 }"
              size="small"
              :pagination="false"
            >
              <template #quoteOptionsTitle>
                <span>{{ $t("logistics.quote_options") }}</span>
              </template>

              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">
                  {{ record.scheme }} / {{ record.code }}
                </div>
                <a-row :gutter="[8, 8]">
                  <a-col v-for="item in record.descriptionItems" :key="item">
                    <CheckCircleOutlined
                      class="text-warning mr-1"
                      :style="{ fontSize: '12px' }"
                    />
                    <span
                      style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                    >
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>

              <template #logisticsWayCustom="{ record }">
                {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
              </template>

              <template #quoteOptions="{ record }">
                <div>
                  <a-form-item :label="$t('logistics.quote_type')">
                    {{ record.truckBaseInfo.quoteType.name }}
                  </a-form-item>
                  <a-form-item
                    :label="$t('logistics.service_level')"
                    v-if="record.truckBaseInfo.serviceLevel"
                  >
                    {{ record.truckBaseInfo.serviceLevel.name }}
                  </a-form-item>
                  <a-form-item :label="$t('logistics.additional_options')">
                    <a-popover
                      placement="top"
                      v-if="record.truckBaseInfo.accessorials?.length > 0"
                    >
                      <template #content>
                        <div style="max-width: 350px">
                          <div
                            v-for="(item, index) in record.truckBaseInfo
                              .accessorials"
                            :key="index"
                          >
                            {{ item.name }}
                            <span
                              v-if="
                                index + 1 !=
                                record.truckBaseInfo.accessorials?.length
                              "
                              >,
                            </span>
                          </div>
                        </div>
                      </template>
                      <div
                        style="
                          max-width: 200px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        <span
                          v-for="(item, index) in record.truckBaseInfo
                            .accessorials"
                          :key="index"
                        >
                          {{ item.name }}
                          <span
                            v-if="
                              index + 1 !=
                              record.truckBaseInfo.accessorials?.length
                            "
                            >,</span
                          >
                        </span>
                      </div>
                    </a-popover>
                    <span v-else> - </span>
                  </a-form-item>
                </div>
              </template>

              <template #quoteDetails="{ record }">
                <div v-if="record.truckBaseInfo">
                  {{ $t("logistics.carrier") }}:
                  {{ record.truckBaseInfo.carrierName }}
                  [
                  {{ record.truckBaseInfo.currencySymbol }}
                  {{
                    $filters.amountToFixed2(record.truckBaseInfo.totalCharge)
                  }}
                  ,
                  {{
                    isNaN(parseInt(record.truckBaseInfo.transitTime) + "")
                      ? record.truckBaseInfo.transitTime
                      : parseInt(record.truckBaseInfo.transitTime)
                  }}
                  {{ $t("common.days") }}
                  ]
                </div>
                <span v-else>-</span>
              </template>
            </a-table>
          </div>
        </div>

        <!-- 费用明细 -->
        <div class="mt-1">
          <CFee
            :fees="plan?.warehouseFees"
            :totalFee="plan.totalFee"
            class="mt-2"
          ></CFee>
        </div>

        <a-row type="flex" justify="end" class="mb-5 mt-3">
          <a-col>
            <div style="width: 400px">
              <a-row type="flex" justify="center" :gutter="[24, 12]">
                <a-col>
                  <a-button type="primary" @click="handleBackOutPlan">{{
                    $t("common.backtrack")
                  }}</a-button>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </a-spin>
      <CProductStatisticsModal ref="productStatisticsModalRef" />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick } from "vue";
import {
  Row,
  Col,
  Steps,
  Tag,
  Spin,
  Popover,
  Form,
  Table,
  Input,
  Typography,
  Button,
  Space,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import CFee from "@/views/components/CFee.vue";
import CProductStatisticsModal from "@/views/components/CProductStatisticsModal.vue";
import CHaveProductInformation from "../../components/CHaveProductInformation.vue";
import CTagMixedOrSingle from "../../components/CTagMixedOrSingle.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useTab } from "@/hooks/tabs/index";
import { getOutWarehouse } from "@/api/modules/warehouseMonitor/storage";
import {
  getAddressByLanguageV2,
  gToKg,
  cmCubicToM,
  getLinkman,
} from "@/utils/general";
import {
  outWarehouseType as outWarehouseTypeEnum,
  deliverType as deliverTypeEnum,
  selfMentionType as selfMentionTypeEnum,
  productStatisticsType as productStatisticsTypeEnum,
  outPlanStatus as outPlanStatusEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  transportationDeliveryWay as transportationDeliveryWayEnum,
} from "@/enum/enum.json";

export default {
  name: "warehouse_monitor_storage_outbound_task_detail",
  components: {
    ASpin: Spin,
    CFee,
    ATag: Tag,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AButton: Button,
    ATypographyText: Typography.Text,
    ASpace: Space,
    APopover: Popover,
    AFormItem: Form.Item,
    AForm: Form,
    Content,
    CImage,
    CFileDisplay,
    CProductStatisticsModal,
    CTagMixedOrSingle,
    CHaveProductInformation,
    CSignSupported,
    CPlanNumberItem,
  },
  setup() {
    const i18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();

    const { delVisitedRoute } = useTab();
    const { getters } = useStore();
    const refInputPlanName = ref();

    const currentPageIndex = ref(1);
    const defaultPageSize = ref(50);
    const productStatisticsModalRef = ref();

    const innerColumns = [
      { width: 60 },
      {
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        title: () => i18n.t("common.count"),
        slots: {
          customRender: "count",
        },
      },
    ];

    const columns = [
      {
        title: () => i18n.t("common.serial_number"),
        customRender: ({ text, record, index }) => {
          return (
            (currentPageIndex.value - 1) * defaultPageSize.value + (index + 1)
          );
        },
        width: 60,
      },
      {
        dataIndex: "boxNo",
        width: 100,
        title: () => i18n.t("devops.box_no"),
        slots: {
          customRender: "boxNoCustom",
        },
      },
      {
        title: "",
        width: 50,
      },
      {
        dataIndex: "containerCount",
        title: () => i18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "containerCount",
        },
        width: 120,
      },
      {
        title: () => i18n.t("warehouse.out_warehouse_info"),
        slots: {
          customRender: "out_warehouse_info",
        },
        width: 200,
      },

      {
        dataIndex: "lableServe",
        title: () => i18n.t("warehouse.label_service"),
        slots: {
          customRender: "lableServeCustom",
        },
        width: 200,
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 200,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 120,
      },
      {
        dataIndex: "freights", //运费
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
        width: 220,
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 150,
      },
      {
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 150,
      },
    ];

    const columnsTruckScheme = [
      {
        title: () =>
          i18n.t("warehouse.logistics_scheme") +
          " / " +
          i18n.t("warehouse.logistics_code"),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
        width: 100,
      },
      {
        title: () => i18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",
        },
        width: 80,
      },
      {
        title: () => i18n.t("warehouse.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
        width: 80,
      },
      {
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
        width: 150,
      },
      {
        title: () => i18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
        width: 100,
      },
    ];

    const state = reactive({
      columns: columns,
      outWarehouseTypeEnum: outWarehouseTypeEnum,
      pageLoading: false,
      isChangingPlanName: false,
      isAnewInWarehouse: false,
      tempPlanName: "",
      plan: {
        planId: "",
        shopMark: "",
        planNo: "",
        planName: "",
        creationTime: null,
        lastModificationTime: null,
        toRelationPlanNo: null,
        inventoryWarehouseAddress: {
          //出库仓库
          no: "",
        },
        targetAddress: {
          //目标库
          warehouseType: "",
          warehouseNo: "",
          id: "", //仓库id
        },
        outWarehouseType: "",
        amazonShippingNumber: "",
        shipmentTrackingNumber: "",

        tableList: [],
        totalBox: "-",
        outTotalVolume: "-",
        outTotalWeight: "-",
        transportCharacteristics: null,
        isSelectHitServe: false,

        outPlanStatus: "",
        deliveryStatus: "",

        deliverType: "",
        reservedInfo: "",
        payWay: "",

        warehouseFees: [],
        totalFee: null,

        shopName: "",
        shopId: "",
        shopNo: "",
      },
      logisticsSchemeList: [],
      logisticsSchemeListTruck: [],
      isSelectedDDP: false,
      isSelectedSignFee: false,
      isShowVATInput: false,
      VAT: "", //税号

      isRemoteArea: null, //是否为偏远地址
      isCommercial: null, //true商业地址 false住宅地址  null不显示
    });

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleCancelAlterPlanName = async () => {
      state.plan.planName = state.tempPlanName;
      state.isChangingPlanName = false;
    };

    const handleButtonAlterPlanName = async () => {
      state.isChangingPlanName = true;
      state.tempPlanName = state.plan.planName;
      nextTick(() => {
        refInputPlanName.value.focus();
      });
    };

    const funcOutPlanDetails = async (planId) => {
      state.pageLoading = true;
      getOutWarehouse({ id: planId })
        .then((res) => {
          let r = res.result;
          if (r) {
            state.plan.inventoryWarehouseAddress = r.from;
            state.plan.targetAddress = r.to;

            state.plan.planId = r.id;
            state.plan.planNo = r.planNo;
            state.plan.planName = r.planName;
            state.plan.creationTime = r.creationTime;
            state.plan.lastModificationTime = r.lastModificationTime;
            state.plan.toRelationPlanNo = r.toRelationPlanNo;
            state.plan.warehouseNo = r.from.no;
            state.plan.outWarehouseType = r.outWarehouseType;
            state.plan.amazonShippingNumber = r.fbaAmazonTransNo;
            state.plan.shipmentTrackingNumber = r.fbaTranceNo;

            state.plan.totalBox = r.total.plan.totalContainerBoxCount;
            state.plan.outTotalVolume = r.total.plan.totalVolume
              ? cmCubicToM(r.total.plan.totalVolume)
              : "-";
            state.plan.outTotalWeight = r.total.plan.totalWeight
              ? gToKg(r.total.plan.totalWeight)
              : "-";
            state.plan.transportCharacteristics =
              r.total.transportCharacteristics;
            state.plan.isSelectHitServe = r.daycareServiceId;
            state.plan.outPlanStatus = r.outWarehouseStatus;
            state.plan.deliveryStatus = r.transDeliverStatus;
            state.plan.deliverType = r.transDeliverType;
            state.plan.transportationDeliveryWay = r.transDeliverType;
            state.plan.selfMentionType = r.selfMentionType;
            state.plan.reservedInfo = r.transSelfMentionDistinguish;
            state.plan.payWay = r.paymentMethodType;

            state.plan.shopName = r.shopName;
            state.plan.shopId = r.shopId;
            state.plan.shopNo = r.shopNo;
            state.plan.shopMark = r.shopMark;

            if (
              state.plan.outWarehouseType ==
              outWarehouseTypeEnum.betweenWarehouseAllocate ||
              state.plan.outWarehouseType ==
              outWarehouseTypeEnum.destruction
            ) {
              state.columns = columns.filter(
                (x) => x.dataIndex != "lableServe"
              );
            }

            state.plan.tableList = r.items;

            state.plan.warehouseFees = r.fee?.fees?.map((x, index) => {
              return {
                key: index + 1,
                feeType: x.feeType,
                symbol: x.symbol,
                value: x.value,
              };
            });
            state.plan.totalFee = r.fee?.totalFee ?? null;
            if (
              state.plan.transportationDeliveryWay ==
                transportationDeliveryWayEnum.cwmLogistics &&
              r.logistic?.logisticsBaseInfo
            ) {
              let l = r.logistic.logisticsBaseInfo;
              let logistic = {
                key: l.id,
                id: l.id,
                scheme: l.name,
                code: l.code,
                descriptionItems: l.descriptionItems,

                logisticsWay: l.logisticsType,
                deliveryWay: l.deliveryType,

                daysMin: l.daysMin,
                daysMax: l.daysMax,
                channelTimeType: l.channelTimeType, //签收或自提 枚举

                averagePrice: l.averagePrice, //平均费
                expressFee: l.totalFeeWithoutServices, //合计费用
                currencySymbol: l.currencySymbol, //货号符号

                chargeableWeight: l.chargeableWeight
                  ? gToKg(l.chargeableWeight)
                  : "", //计费重
                volumeWeightParam: l.volumeWeightParam, //体积系数
                chargeableVolume: cmCubicToM(l.chargeableVolume),
                logisticsCalcMethod: l.logisticsCalcMethod,
                truckBaseInfo: l.truckBaseInfo,
                feeCalculationMethod: l.feeCalculationMethod,
                logisticsRegionInfo: r.logistic.logisticsRegionInfo,
              };
              state.logisticsSchemeList.push(logistic);
              state.isSelectedDDP = r.logistic.logisticsRegionInfo.isChooseDdp;
              state.isSelectedSignFee =
                r.logistic.logisticsRegionInfo.isChooseSign;
              state.isShowVATInput = r.logistic.logisticsRegionInfo.vatCode;
              state.VAT = r.logistic.logisticsRegionInfo.vatCode;
              state.isRemoteArea = r.logistic?.logisticsBaseInfo?.isRemoteArea;
              state.isCommercial = r.logistic?.logisticsBaseInfo?.isCommercial;
            }
          }
        })
        .catch((e) => {
          // console.log(e);
        })
        .finally(() => {
          state.pageLoading = false;
        });
    };

    const handleBackOutPlan = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/warehousemonitor/storage/outboundtask" });
    };

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(
        productStatisticsTypeEnum.outWarehouse,
        route.params.id
      );
    };

    onMounted(async () => {
      let planId = route.params.id;
      if (planId) {
        funcOutPlanDetails(planId);
      }
    });

    return {
      gToKg,
      feeCalculationMethodEnum,
      currentPageIndex,
      defaultPageSize,
      innerColumns,
      columns,
      ...toRefs(state),
      refInputPlanName,
      deliverTypeEnum,
      selfMentionTypeEnum,
      outPlanStatusEnum,
      productStatisticsModalRef,
      getLinkman,
      getAddress,
      handleCancelAlterPlanName,
      handleButtonAlterPlanName,
      handleBackOutPlan,
      handleShowProductStatisticsModal,
      columnsLogisticsScheme,
      columnsTruckScheme,
      transportationDeliveryWayEnum,
    };
  },
};
</script>

<style lang="less" scoped>
.table-list-img-common {
  width: 54px;
  height: 54px;

  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  .ant-image img {
    max-height: 45px !important;
  }
}
</style>

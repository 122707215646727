<template>
  <a-table
    size="small"
    style="min-height:223px;"
    :columns="columns"
    :data-source="state.boxList"
    :pagination="false"
    :expanded-row-keys="state.boxExpandedRowKeysList"
    :expandIconAsCell="false"
    :expandIconColumnIndex="1"
    :row-key="(record) => record.rowKey"
  >
    <template #title>
      <a-row :gutter="16">
        <a-col>
          <a-button type="primary" ghost @click="handleShowModalProduct(null, true)">{{ $t('warehouse.add_by_the_product') }}</a-button>
        </a-col>
        <a-col>
          <a-button type="primary" ghost @click="handleAddBox()">{{ $t('warehouse.add_box') }}</a-button>
        </a-col>
      </a-row>
    </template>
    <template #expandIcon="{ expanded, record }">
      <CHaveProductInformation :open="expanded" @click="handleOpenOrCloseRow(record)" />
    </template>
    <template #encasementSizeCustom="{ record }">
      <a-input-number v-model:value="record.encasementLength" :precision="0" class="mr-1"
        :class="[state.tableVerified && record.encasementLength <= 0 ? 'input-error-border' : '']"
        :placeholder="$t('common.max_length_side')" :min="1" :max="99999" />x
      <a-input-number v-model:value="record.encasementWidth" class="mr-1" :precision="0"
        :class="[state.tableVerified && record.encasementWidth <= 0 ? 'input-error-border' : '']"
        :placeholder="$t('common.max_width_side')" :min="1" :max="99999" />x
      <a-input-number v-model:value="record.encasementHeight" :precision="0"
        :class="[state.tableVerified && record.encasementHeight <= 0 ? 'input-error-border' : '']"
        :placeholder="$t('common.max_height_side')" :min="1" :max="99999" /> cm
    </template>
    <template #encasementGrossWeightCustom="{ record }">
      <a-input-number v-model:value="record.encasementGrossWeight" :precision="3"
        :class="[state.tableVerified && record.encasementGrossWeight <= 0 ? 'input-error-border' : '']" :min="0.001"
        :max="999999.999" /> kg
    </template>
    <template #operateCustom="{ record }">
      <a-row :gutter="8">
        <a-col>
          <a-button type="primary" ghost
            @click="handleShowModalProduct(record)">{{ $t('warehouse.add_product') }}</a-button>
        </a-col>
        <a-col>
          <a-button type="ghost" danger @click="handleDelete(record)">{{ $t('common.remove') }}</a-button>
        </a-col>
      </a-row>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="innerColumns" :data-source="record.detailProducts" :pagination="false"
        :row-key="(record) => record.id" size="small">
        <template #productInfo="{ record }">
          <a-row :gutter="8" type="flex" align="middle">
            <a-col>
              <div class="table-list-img-common">
                <c-image :src="record.imgUrl" :thumbWidth="600" :thumbHeight="600" />
              </div>
            </a-col>
            <a-col flex="1">
              <div>{{ record.productName }}</div>
              <div>{{ record.productNo }}</div>
            </a-col>
          </a-row>
        </template>
        <template #operation="{ record, index }">
          <a-button type="ghost" danger @click="handleRemoveBoxProduct(record.parentRowKey, index)">{{ $t('common.remove')
          }}</a-button>
        </template>
      </a-table>
    </template>
  </a-table>
  <!-- 添加产品modal -->
  <ProductListModal ref="productListModalRef" @confirm="handleAddProductToRow" />
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue'
import { Button, Col, Input, InputNumber, Row, Table } from 'ant-design-vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue'
import CImage from '@/views/components/CImage.vue';
import ProductListModal from './ProductListModal.vue';
import { gToKg, randomString } from "@/utils/general";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  emits: ['update:items'],
  components: {
    ATable: Table,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    CHaveProductInformation,
    CImage,
    ProductListModal,
  },
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    
    const productListModalRef = ref(null);

    const state = reactive({
      boxExpandedRowKeysList: [],
      boxList: [],
      tableVerified: false,
    })

    const columns = [
      {
        width: 60,
        title: () => vueI18n.t('warehouse.serial_number'),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
      },
      { width: 50, },
      {
        width: 400,
        title: () => vueI18n.t('warehouse.encasement_size'),
        slots: {
          customRender: "encasementSizeCustom",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.encasement_weight'),
        slots: {
          customRender: "encasementGrossWeightCustom",
        },
      },
      {
        width: 250,
        slots: {
          customRender: "operateCustom",
        },
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 350,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
       {
        width: 200,
        title: ('SESKU'),
        dataIndex: "seSku",
      },
      {
        slots: {
          customRender: "operation"
        }
      },
    ];

    const combinationResults = (list) => {
      let result = list.map(item => {
        let products = item.detailProducts.map(item => item.seSku);
        return {
          length: item.encasementLength,
          width: item.encasementWidth,
          height: item.encasementHeight,
          weight: item.encasementGrossWeight,
          products,
        }
      })
      emit('update:items', result);
    }

    watch(() => state.boxList, (value) => {
      if (Array.isArray(value)) {
        combinationResults(value);
      }
    }, { deep: true, immediate: true })

    const handleDelete = (record) => {
      state.boxList = state.boxList.filter(item => item.rowKey != record.rowKey);
    };

    const handleRemoveBoxProduct = (parentRowKey, index) => {
      let boxItem = state.boxList.find(item => item.rowKey === parentRowKey);
      if (boxItem) {
        boxItem.detailProducts.splice(index, 1);
      }
    }

    // 打开产品弹窗
    const handleShowModalProduct = (record = null, needBox = false) => {
      productListModalRef.value.open(record, needBox);
    };

    // 设置全部行展开
    const handleSetAllExpandedRows = () => {
      state.boxExpandedRowKeysList = state.boxList?.map(item => item.rowKey);
    }

    // 展开关闭行
    const handleOpenOrCloseRow = (record, keepOpen = false) => {
      let rowKey = record.rowKey;
      if (state.boxExpandedRowKeysList.includes(rowKey)) {
        if (!keepOpen) {
          state.boxExpandedRowKeysList = state.boxExpandedRowKeysList.filter(item => item !== rowKey);
        }
      } else {
        state.boxExpandedRowKeysList.push(rowKey);
      }
    }

    // 添加产品
    const handleAddProductToRow = ({ list, record, needBox }) => {
      if (needBox) {
        list.forEach(item => {
          if (!('productCount' in item)) {
            item.productCount = item.containerCount;
          }
          for (let i = 0; i < item.boxCount; i++) {
            handleAddBox();
            let boxInfo = state.boxList[state.boxList.length - 1];
            item = JSON.parse(JSON.stringify(item));
            item.parentRowKey = boxInfo.rowKey;
            // 设置箱尺寸
            boxInfo.encasementLength = item.containerLength;
            boxInfo.encasementWidth = item.containerWidth;
            boxInfo.encasementHeight = item.containerHeight;
            boxInfo.encasementGrossWeight = gToKg(item.containerWeight);

            boxInfo.detailProducts.push(item);
          }
        });
        handleSetAllExpandedRows();
      } else {
        let item = state.boxList.find(item => item.rowKey === record.rowKey);
        item.detailProducts = list.map(item => {
          if (!('productCount' in item)) {
            item.productCount = item.containerCount;
          }
          item.parentRowKey = record.rowKey;
          return item;
        });
        handleOpenOrCloseRow(record, true);
      }
    };

    const handleAddBox = () => {
      let obj = {
        rowKey: randomString(12),
        selfBoxNo: null,
        encasementLength: null,
        encasementWidth: null,
        encasementHeight: null,
        encasementGrossWeight: null,
        inWarehouseBoxCount: null,
        detailProducts: [],
      }
      state.boxList.push(obj);
    }

    return {
      productListModalRef,
      state,
      columns,
      innerColumns,
      handleDelete,
      handleRemoveBoxProduct,
      handleShowModalProduct,
      handleSetAllExpandedRows,
      handleOpenOrCloseRow,
      handleAddProductToRow,
      handleAddBox,
    }
  }
})
</script>

<style scoped></style>
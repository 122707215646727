<template>
    <Content>
        <template #contentTitle>
            {{ $t("menu.dev_ops_ali_log") }}
        </template>
        <template v-slot:contentBody="wrap">
            <div>
                <a-form layout="inline" class="mb-3">
                    <div class="ant-form-inline mr-2">
                        <a-form-item>
                            <a-input v-model:value="tableData.query" style="width:500px">
                                <template #suffix>
                                    <a-popover title="">
                                        <template #content>
                                            <p>查询：</p>
                                            <p>1. 全文查询：error</p>
                                            <p>2. 指定字段查询：Latency>5000 and Method:Get* and not Status:200</p>
                                            <p>3. 短语（指定引号）："http error"</p>
                                            <p>4. 常用:</p>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>使用模糊查询</td>
                                                        <td>查询包含foo前缀的词</td>
                                                        <td><code>foo*</code></td>
                                                    </tr>
                                                    <tr>
                                                        <td>使用全文查询</td>
                                                        <td>查询任何字段中包含foot的日志</td>
                                                        <td><code>foot</code></td>
                                                    </tr>
                                                    <tr>
                                                        <td>使用字段查询</td>
                                                        <td>查询message字段包含foot的日志</td>
                                                        <td><code>message:foot</code></td>
                                                    </tr>
                                                    <tr>
                                                        <td>使用数字范围查询</td>
                                                        <td>查询status范围在400（包含）到499（包含）（注意，数值查询，需要把字段定义成long或double类型）
                                                        </td>
                                                        <td><code>status in [400 499]</code> 或者
                                                            <code>status ≥400 and status ≤499</code></td>
                                                    </tr>
                                                    <tr>
                                                        <td>组合查询</td>
                                                        <td>查询status范围在400（包含）到499（包含），并且message包含foot</td>
                                                        <td><code>status ≥400 and status ≤499 and message:foot</code>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p>分析：</p>
                                            <p>1. 无查询条件：* | $analytics</p>
                                            <p>2. 带查询条件：$search | $analytics</p>
                                            <p>查询分析：<a href="https://help.aliyun.com/document_detail/43772.html">帮助</a>
                                            </p>
                                            <p>查询语法：<a
                                                    href="https://help.aliyun.com/document_detail/29060.html">查询语法</a> /
                                                <a href="https://help.aliyun.com/document_detail/53608.html">分析语法</a>
                                            </p>
                                        </template>
                                        <a-button type="text" size="small">
                                            <template #icon>
                                                <question-circle-filled />
                                            </template>
                                        </a-button>
                                    </a-popover>
                                </template>
                            </a-input>
                        </a-form-item>
                    </div>
                    <div class="ant-form-inline mr-2">
                        <a-form-item>
                            <a-range-picker :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm"
                                :placeholder="['Start Time', 'End Time']" :ranges="ranges"
                                v-model:value="selectTimes" />
                        </a-form-item>
                    </div>
                    <div class="ant-form-inline mr-2">
                        <a-form-item>
                            <a-button type="primary" @click="handleSearch" :loading="tableData.loading">
                                {{ $t("common.query") }}
                            </a-button>
                        </a-form-item>
                    </div>
                </a-form>
            </div>
            <div>
                <a-table :columns="columns" size="small" :data-source="tableData.tableList" :pagination="false"
                    :scroll="{ x: 500, y: wrap.contentHeight - 148 }" :rowKey="(record, index) => index"
                    :loading="tableData.loading">
                    <template #utcDateTime="{ record }">
                        {{$filters.utcToCurrentTime(record.utcDateTime) }}
                    </template>
                    <template v-slot:operation="{ record }">
                        <a-button type="link" size="small" @click="showDetailModal(record)">详情</a-button>
                    </template>
                </a-table>
            </div>

            <!-- 阿里日志详情 -->
            <a-modal v-model:visible="detailModalData.visible" :confirm-loading="detailModalData.loading"
                :title="`阿里日志详情`" :maskClosable="true" :width="720">
                <template #footer>
                    <a-button key="submit" type="primary" @click="handleClose">关闭</a-button>
                </template>
                <div :class="{ overflowList: detailModalData.showOverflowList }">
                    <a-form :model="detailModalData.formData" :label-col="{ span: 5 }">
                        <a-form-item label="服务名称">
                            {{detailModalData.formData.serviceName}}
                        </a-form-item>
                        <a-form-item label="运行环境">
                            {{detailModalData.formData.env}}
                        </a-form-item>
                        <a-form-item label="错误类型">
                            {{detailModalData.formData.errorType}}
                        </a-form-item>
                        <a-form-item label="日志类型">
                            {{detailModalData.formData.logType}}
                        </a-form-item>
                        <a-form-item label="日志消息">
                            {{detailModalData.formData.message}}
                        </a-form-item>
                        <a-form-item label="店铺Id">
                            {{detailModalData.formData.shopId}}
                        </a-form-item>
                        <a-form-item label="仓库Id">
                            {{detailModalData.formData.warehouseId}}
                        </a-form-item>
                        <a-form-item label="操作人">
                            {{detailModalData.formData.operator}}
                        </a-form-item>
                        <a-form-item label="API地址">
                            {{detailModalData.formData.api}}
                        </a-form-item>
                        <a-form-item label="请求数据">
                            {{detailModalData.formData.requestData}}
                        </a-form-item>
                         <a-form-item label="用户Ip">
                            {{detailModalData.formData.ip}}
                        </a-form-item>
                        <a-form-item label="服务器Ip">
                            {{detailModalData.formData.localIp}}
                        </a-form-item>
                        <a-form-item label="日志时间">
                            {{$filters.utcToCurrentTime(detailModalData.formData.utcDateTime) }}
                        </a-form-item>
                        <a-form-item label="异常消息">
                            {{detailModalData.formData.exception}}
                        </a-form-item>
                        <a-form-item label="堆栈信息">
                            {{detailModalData.formData.stackTrace}}
                        </a-form-item>
                    </a-form>
                </div>
            </a-modal>
        </template>
        <!-- 分页 -->
        <template #contentFooter>
            <CPager class="text-center" @handlePage="handlePage" :page-data="tableData.pageData"></CPager>
        </template>
    </Content>

</template>

<script>
    import {
        Table,
        Button,
        Modal,
        Input,
        Select,
        Card,
        Row,
        Col,
        Form,
        Radio,
        Switch,
        InputNumber,
        Popconfirm,
        DatePicker,
        Popover
    } from "ant-design-vue";
    import CPager from "../components/CPager.vue";
    import Content from "../components/Content.vue";
    import {
        defineComponent,
        onMounted,
        reactive,
        ref,
    } from "vue";
    import { useI18n } from "vue-i18n/index";
    import { getName, currentTimeToUtc } from "../../utils/general";
    import { useStore } from "vuex";
    import moment from "moment-timezone";
    import { getTimezone } from "../../hooks/login/index";
    import {
        getAliLogPageList
    } from "../../api/modules/devops/index";

    export default defineComponent({
        name: "dev_ops_ali_log",
        components: {
            ATable: Table,
            AButton: Button,
            AModal: Modal,
            CPager,
            AInput: Input,
            AInputGroup: Input.Group,
            ASelect: Select,
            ACard: Card,
            ARow: Row,
            ACol: Col,
            Content,
            ASelectOption: Select.Option,
            AForm: Form,
            AFormItem: Form.Item,
            ARadio: Radio,
            ARadioOption: Radio.Option,
            ARadioButton: Radio.Button,
            ARadioGroup: Radio.Group,
            ASwitch: Switch,
            AInputNumber: InputNumber,
            APopconfirm: Popconfirm,
            ADatePicker: DatePicker,
            ARangePicker: DatePicker.RangePicker,
            APopover: Popover
        },
        setup() {
            const { getters } = useStore();
            const vueI18n = useI18n({ useScope: "global" });
            const getLanguageName = (item) => {
                return getName(item, getters.language);
            };

            onMounted(() => {
                initSearchTime();
                handleSearch();
            });

            /** begin 列表 */
            const columns = [
                {
                    dataIndex: "serviceName",
                    width: 50,
                    fixed: "left",
                    title: () => "服务名称",
                },
                {
                    dataIndex: "env",
                    width: 50,
                    fixed: "left",
                    title: () => "运行环境",
                },
                {
                    dataIndex: "errorType",
                    width: 50,
                    title: () => "错误类型",
                },
                {
                    dataIndex: "message",
                    width: 150,
                    title: () => "日志消息",
                },
                {
                    title: () => "应用数据",
                    children: [
                        {
                            dataIndex: "shopId",
                            width: 50,
                            title: () => "店铺Id",
                        },
                        {
                            dataIndex: "warehouseId",
                            width: 50,
                            title: () => "仓库Id",
                        },
                        {
                            dataIndex: "operator",
                            width: 50,
                            title: () => "操作人",
                        },
                        {
                            dataIndex: "api",
                            width: 100,
                            title: () => "API地址",
                        },
                        {
                            dataIndex: "requestData",
                            width: 150,
                            title: () => "请求数据",
                        }
                    ]
                },
                {
                    dataIndex: "localIp",
                    width: 100,
                    title: () => "服务器Ip",
                },
                {
                    dataIndex: "utcDateTime",
                    width: 100,
                    title: () => "日志时间",
                    slots: {
                        customRender: "utcDateTime"
                    }
                },
                {
                    width: 50,
                    title: () => "操作",
                    slots: {
                        customRender: "operation",
                    },
                },
            ];

            const tableData = reactive({
                tableList: [],
                loading: false,
                pageData: {
                    currentIndex: 1,
                    skipCount: 0,
                    totalCount: 0,
                    maxResultCount: 10,
                },
                query: null,
            });

            const selectTimes = ref([]);
            const currentTimeToUtcTime = (date, format = "yyyy-MM-DD HH:mm") => {
                let userTimezone = getTimezone();
                let currentMoment = moment.tz(new Date(date), userTimezone);
                var utcTime = currentMoment.utc().format(format);
                return utcTime;
            };

            const getAliLogList = () => {
                tableData.loading = true;
                let searchData = {
                    ...tableData.pageData,
                    projectName: "saleasy-cws-log",
                    logstoreName: "cws_errorlog",
                    query: tableData.query,
                    reverse: true
                };
                searchData.beginDate = currentTimeToUtc(selectTimes.value[0]?.format('YYYY-MM-DD HH:mm:00'));
                searchData.endDate = currentTimeToUtc(selectTimes.value[1]?.format('YYYY-MM-DD HH:mm:00'));
                getAliLogPageList(searchData)
                    .then((res) => {
                        if (res.result) {
                            let { items, totalCount } = res.result;
                            tableData.tableList = items;
                            tableData.pageData.totalCount = parseInt(totalCount);
                        } else {
                            tableData.pageData.totalCount = 0;
                            tableData.tableList = [];
                        }
                        tableData.loading = false;
                    })
                    .catch(() => {
                        tableData.loading = false;
                    });;
            };

            const initSearchTime = () => {
                selectTimes.value[0] = moment().startOf('days');
                selectTimes.value[1] = moment();
            };

            const handleSearch = () => {
                tableData.pageData.currentIndex = 1;
                tableData.pageData.skipCount = 0;
                getAliLogList();
            };

            const handlePage = (pageData) => {
                tableData.pageData.skipCount = pageData.skipCount;
                tableData.pageData.maxResultCount = pageData.maxResultCount;
                getAliLogList();
            };
            /** end 列表 */

            /** begin 详情 */
            const detailModalData = reactive({
                visible: false,
                loading: false,
                showOverflowList: false,
                formData: {}
            });

            const showDetailModal = (record) => {
                detailModalData.formData = record;
                if (!detailModalData.visible) {
                    detailModalData.visible = true;
                    detailModalData.showOverflowList = true;
                }
            }

            const handleClose = () => {
                detailModalData.visible = false;
            };
            /** end 详情 */

            return {
                tableData,
                columns,
                selectTimes,
                ranges: { 'Today': [moment().startOf('days'), moment()], 'This Week': [moment().startOf('week'), moment()], 'This Month': [moment().startOf('month'), moment()] },
                handleSearch,
                handlePage,
                getLanguageName,
                detailModalData,
                showDetailModal,
                handleClose
            };
        },
    });
</script>
<style lang="less" scoped>
    .overflowList {
        width: 100%;
        height: 580px;
        overflow-y: auto;
    }

    :deep(.input-group-mid .ant-input-group-addon) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    :deep(.input-group-mid-number) {
        border-radius: 0;
        width: 100%;
    }

    :deep(.input-group-end-number) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
    }

    :deep(.input-group-start-number) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
    }
</style>
import client from "../../client";

const Api = {
  getList: "/api/WarehouseService/Airline/GetListPaged",
  add: "/api/WarehouseService/Airline/Create",
  update: "/api/WarehouseService/Airline/Update",
  deleteData: "/api/WarehouseService/Airline/Delete",
  detail: "/api/WarehouseService/Airline/Get",
};

export const getList = ({
  airlineType,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: { airlineType, searchKey, skipCount, maxResultCount, sorting },
    method: "POST",
  });
};

export const add = ({
  airlineType,
  airlineName,
  airlineCode,
}) => {
  return client.request({
    url: Api.add,
    data: { airlineType, airlineName, airlineCode },
    method: "POST",
  });
};

export const update = ({
  id,
  airlineType,
  airlineName,
  airlineCode,
}) => {
  return client.request({
    url: Api.update,
    data: { id,airlineType, airlineName, airlineCode },
    method: "POST",
  });
};

export const deleteData = (ids) => {
  return client.request({
    url: Api.deleteData,
    data: { ids },
    method: "POST",
  });
};

export const detail = (id) => {
  return client.request({
    url: Api.detail,
    params: { id },
    method: "get",
  });
};

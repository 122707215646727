<template>
  <Content>
    <template #contentTitle>{{ $t("menu.warehouse_monitor_print_waybill_detail") }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[0, 8]" v-if="pageInfo">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.customer_number") }}: {{ pageInfo.customerNo }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.system_number") }}: {{ pageInfo.planNo }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('warehouse.creation_time') }}: {{ $filters.utcToCurrentTime(pageInfo.creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('logistics.logistics_track_no') }}: {{ pageInfo.transferOrderNo || '-' }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('warehouse.shipping_lines') }}: {{ $t($enumLangkey("transportRoutes", pageInfo.transportRoutes)) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t('devops.api_code') }}: 
                <span v-if="pageInfo.openApiCode">{{ $t($enumLangkey('openApiCode', pageInfo.openApiCode)) }}</span>
                <span v-else>-</span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]" style="height: 100%;" type="flex" align="middle">
              <a-col :span="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t('warehouse.operating_state') }}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <span v-if="pageInfo.waybillOperationStatus">
                    <a-typography-text type="secondary" v-if="pageInfo.waybillOperationStatus === waybillOperationStatusEnum.cancelShipment">{{ $t($enumLangkey('waybillOperationStatus', pageInfo.waybillOperationStatus)) }}</a-typography-text>
                    <a-typography-text type="success" v-else>{{ $t($enumLangkey('waybillOperationStatus', pageInfo.waybillOperationStatus)) }}</a-typography-text>
                  </span>
                  <span v-else>-</span>
                </div>
              </a-col>
              <a-col :span="24">
                <div style="text-align: center;">
                  <a-space :size="20">
                    <a-button type="primary" @click="handleShowTrackModal" ghost>{{ $t('warehouse.check_the_track') }}</a-button>
                    <a-button type="primary" v-if="pageInfo.hasError" @click="handleShowErrorMessage(pageInfo.errorMsg)" ghost danger>{{ $t('warehouse.error_message') }}</a-button>
                  </a-space>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <template v-if="pageInfo.shopData">
            <a-col :md="8" :sm="24" :xs="24">
              {{ $t('warehouse.customer') }}:
              <span v-if="pageInfo.shopData.shopName">{{ pageInfo.shopData.shopName || '-' }}</span>
              <span v-else>-</span>
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              {{$t('common.shop_mark')}}:
              <span v-if="pageInfo.shopData.shopMark">{{ pageInfo.shopData.shopMark || '-' }}</span>
              <span v-else>-</span>
            </a-col>
            <a-col :md="8" :sm="24" :xs="24">
              NO:
              <span v-if="pageInfo.shopData.shopNo">{{ pageInfo.shopData.shopNo || '-' }}</span>
              <span v-else>-</span>
            </a-col>
          </template>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("logistics.originate") }}: {{ pageInfo.from.name }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <!-- {{ $t("warehouse.originating_country") }}: {{ getLanguageName(pageInfo.from?.countryCnName, pageInfo.from?.countryEnName) }} -->
            {{ $t("logistics.shipments_address") }}: {{ getAddressLanguageName(pageInfo.from) }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("logistics.destination_type") }}: {{ $t($enumLangkey("destinationType", pageInfo.destinationType)) }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t("logistics.destination_country_city") }}: 
            {{
              getLanguageName(pageInfo.to.countryCnName, pageInfo.to.countryEnName)
            }}
            /
            {{ getLanguageName(pageInfo.to.cityCnName, pageInfo.to.cityEnName) }}
          </a-col>
          <template v-if="pageInfo?.destinationType != destinationTypeEnum.OtherAddress">
            <a-col :md="8" :sm="24" :xs="24">
              {{ $t("logistics.warehouse_code") }}: {{ pageInfo.to.no }}
            </a-col>
            <a-col :md="16" :sm="24" :xs="24">
              {{ $t("logistics.receiving_address") }}: {{ getAddressLanguageName(pageInfo.to) }}
              <template v-if="channelTable.length">
                <span v-if="channelTable[0].logisticsBaseInfo?.isCommercial !== null">
                  <a-tag color="#f59b22" v-if="channelTable[0].logisticsBaseInfo?.isCommercial">{{ $t("logistics.business_site") }}</a-tag>
                  <a-tag color="#2db7f5" v-else>{{ $t("logistics.residence") }}</a-tag>
                </span>
              </template>
              <span class="ml-3">{{ $t("logistics.recipients") }}: {{ pageInfo.to?.name }}</span>
            </a-col>
          </template>
          <a-col :span="24" v-else>
            {{ $t("logistics.receiving_address") }}: {{ getAddressLanguageName(pageInfo.to) }}
            <template v-if="channelTable.length">
              <span v-if="channelTable[0].logisticsBaseInfo?.isCommercial !== null">
                <a-tag color="#f59b22" v-if="channelTable[0].logisticsBaseInfo?.isCommercial">{{ $t("logistics.business_site") }}</a-tag>
                <a-tag color="#2db7f5" v-else>{{ $t("logistics.residence") }}</a-tag>
              </span>
            </template>
            <span class="ml-3">{{ $t("logistics.recipients") }}: {{ pageInfo.to?.name }}</span>
          </a-col>
          <template v-if="pageInfo?.destinationType === destinationTypeEnum.OtherAddress">
            <a-col :md="8" :sm="24" :xs="24" v-if="pageInfo.to?.companyName">
              {{ $t("logistics.receipt_company") }}: {{ pageInfo.to?.companyName }}
            </a-col>
            <a-col :md="8" :sm="24" :xs="24" v-if="pageInfo.to?.telephone">
              {{ $t("logistics.phone") }}: {{ pageInfo.to?.telephone }}
            </a-col>
          </template>
          <a-col :span="24" v-if="pageInfo.track">
            {{ $t("warehouse.turn_single_track") }}: 
            <a-typography-text type="secondary">
              {{ $filters.utcToCurrentTime(pageInfo.track.trackTime) }} - {{ pageInfo.track.trackRemark }}
            </a-typography-text>
          </a-col>
        </a-row>
        <a-table
          class="mt-2"
          :columns="columns"
          size="small"
          :data-source="packingInformation"
          :pagination="false"
          :rowKey="(record) => record.id"
        >
          <template #title>{{ $t("warehouse.encasement_info") }}</template>
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #weight="{ record }">
            {{ gToKg(record.weight) }}kg
          </template>
          <template #size="{ record }">
            {{ record.length }}x{{ record.width }}x{{ record.height }}cm
          </template>
          <template #girth="{ record }"> {{ record.girth }}cm </template>
          <template #volume="{ record }">
            {{ cmCubicToM(record.volume) }}m<sup>3</sup>
          </template>
          <template #footer>
            <a-row :gutter="48" type="flex" justify="end">
              <a-col v-if="pageInfo"
                >{{ $t("warehouse.total_weight") }}:
                {{ gToKg(pageInfo.totalWeight) }}kg</a-col
              >
              <a-col v-if="pageInfo"
                >{{ $t("warehouse.total_volume") }}:
                {{ cmCubicToM(pageInfo.totalVolume) }}m<sup>3</sup></a-col
              >
            </a-row>
          </template>
        </a-table>
        <div class="mt-3">
          {{ $t("logistics.transport_characteristics") }}:
          <a-space v-if="pageInfo">
            <span
              v-for="(item, index) in pageInfo.tranPlanCharacteristics"
              :key="index"
              >{{
                $t($enumLangkey("transportCharacteristics", item))
              }}</span
            >
          </a-space>
        </div>
        <a-table
          class="mt-3"
          :columns="secondColumns"
          size="small"
          :data-source="channelTable"
          :pagination="false"
          :rowKey="(record) => record.logisticsBaseInfo.id"
        >
          <template #logisticsCode="{ record }">
            {{ record.logisticsBaseInfo?.code }}
          </template>
          <template #logisticsName="{ record }">
            <span style="color: orange; font-weight: bold">{{
              record.logisticsBaseInfo?.name
            }}</span>
          </template>
          <template #logisticsType="{ record }">
            {{ $t($enumLangkey("logisticsWay", record.logisticsBaseInfo?.logisticsType)) }}
          </template>
          <template #deliveryType="{ record }">
            {{ $t($enumLangkey("deliveryWay", record.logisticsBaseInfo?.deliveryType)) }}
          </template>
          <template #referenceAging="{ record }">
            {{ record.logisticsBaseInfo?.daysMin }}-{{
              record.logisticsBaseInfo?.daysMax
            }}{{ $t("logistics.workday") }}({{ $t("enum.aging_0") }})
          </template>
          <template #freights="{ record }">
            <div style="color: orange">
              {{ $t("warehouse.average_unit_price") }}:
              {{ record.logisticsBaseInfo?.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo?.averagePrice) }}
            </div>
            <div style="color: orange">
              {{ $t("logistics.total") }}:
              {{ record.logisticsBaseInfo?.currencySymbol
              }}{{ $filters.amountToFixed2(record.logisticsBaseInfo?.totalFeeWithoutServices) }}
            </div>
          </template>
          <template #details="{ record }">
            <div>
              {{ $t("logistics.chargeable_weight") }}:
              {{ gToKg(record.logisticsBaseInfo?.chargeableWeight) }}kg
            </div>
            <div>
              {{ $t("logistics.volume_weight_coefficient") }}:
              {{ record.logisticsBaseInfo?.volumeWeightParam }}
            </div>
          </template>
          <template #canChooseServerInfoes="{ record }">
            <template v-if="record.logisticsRegionInfo.isChooseDdp || record.logisticsRegionInfo.isChooseSign">
              <div v-if="record.logisticsRegionInfo.isChooseDdp">
                {{
                    $t(
                      $enumLangkey(
                        "orderLogisticsFee",
                        record.logisticsRegionInfo.ddpFeeType
                      )
                    )
                  }}: {{ record.logisticsBaseInfo.currencySymbol
                  }}{{ $filters.amountToFixed2(record.logisticsRegionInfo.ddpFee) }}
              </div>
              <CSignSupported
                v-if="record.logisticsRegionInfo.isChooseSign"
                v-model:value="record.logisticsRegionInfo.signatureType"
                type="readonly"
                :symbol="record.logisticsBaseInfo.currencySymbol"
                :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
              />
            </template>
            <span v-else>-</span>
          </template>
        </a-table>
        <CFee v-if="pageInfo" class="mt-5" :fees="pageInfo.warehouseFrees" :totalFee="getTotalFee(pageInfo.warehouseFrees)" />
      </a-spin>
      <ReconciliationDetailCard v-if="wayBillCheck && pageInfo.isReconciliation" :info="wayBillCheck" />
      <CReconciliationTable v-if="pageInfo?.isReconciliation" class="mt-3" :data-source="dataSource" />
      <a-row type="flex" justify="end" class="mt-5 mr-5">
        <a-col>
          <a-button @click="handleClose">{{ $t("common.close") }}</a-button>
        </a-col>
      </a-row>
      <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("logistics.waybill_number") }}
        </template>
         <template v-slot:no>
          {{trackModal.customerNo}}/{{ trackModal.planNo }}
        </template>
      </CTrackInfoModal>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import {
  Spin,
  Space,
  Table,
  Row,
  Col,
  Divider,
  Button,
  Tag,
  Typography,
  Modal,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CFee from "@/views/components/CFee.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import ReconciliationDetailCard from './components/ReconciliationDetailCard.vue';
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import CReconciliationTable from "@/views/components/CReconciliationTable.vue";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useTab } from "@/hooks/tabs/index";
import { gettWayBill, getWaybillTrackList, getAccountRecordDetail } from "@/api/modules/warehouseMonitor/printWaybill";
import { gToKg, cmCubicToM, getAddressByLanguageV2 } from "@/utils/general";
import { destinationType as destinationTypeEnum, waybillOperationStatus as waybillOperationStatusEnum } from "@/enum/enum.json";
import BigNumber from 'bignumber.js'

export default defineComponent({
  name: "warehouse_monitor_print_waybill_detail",
  components: {
    CTrackInfoModal ,
    ASpin: Spin,
    ASpace: Space,
    ATable: Table,
    ARow: Row,
    ACol: Col,
    ADivider: Divider,
    AButton: Button,
    ATag: Tag,
    ATypographyText: Typography.Text,
    Content,
    CFee,
    CSignSupported,
    CReconciliationTable,
    ReconciliationDetailCard,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();


    const state = reactive({
      loading: false,
      wayBillCheck: null,
      pageInfo: null,
      packingInformation: [],
      channelTable: [],
      // 对象详情信息
      dataSource: null,
    });

    const columns = [
      {
        title: () => vueI18n.t("common.serial_number"),
        width: 80,
        slots: {
          customRender: "serialNumber",
        },
      },
      {
        title: () => vueI18n.t("logistics.actual_weight"),
        width: 150,
        slots: {
          customRender: "weight",
        },
      },
      {
        title: () => vueI18n.t("warehouse.size"),
        width: 150,
        slots: {
          customRender: "size",
        },
      },
      {
        title: () => vueI18n.t("warehouse.girth"),
        width: 150,
        slots: {
          customRender: "girth",
        },
      },
      {
        title: () => vueI18n.t("common.volume"),
        width: 150,
        slots: {
          customRender: "volume",
        },
      },
    ];

    const secondColumns = [
      {
        title: () => vueI18n.t("finance.channel_code"),
        width: 100,
        slots: {
          customRender: "logisticsCode",
        },
      },
      {
        title: () => vueI18n.t("warehouse.channel_name"),
        width: 150,
        slots: {
          customRender: "logisticsName",
        },
      },
      {
        title: () => vueI18n.t("logistics.logistics_type"),
        width: 100,
        slots: {
          customRender: "logisticsType",
        },
      },
      {
        title: () => vueI18n.t("logistics.delivery_way"),
        width: 100,
        slots: {
          customRender: "deliveryType",
        },
      },
      {
        title: () => vueI18n.t("logistics.reference_aging"),
        width: 120,
        slots: {
          customRender: "referenceAging",
        },
      },
      {
        title: () => vueI18n.t("logistics.freight"),
        width: 120,
        slots: {
          customRender: "freights",
        },
      },
      {
        title: () => vueI18n.t("common.details"),
        width: 120,
        slots: {
          customRender: "details",
        },
      },
      {
        title: () => vueI18n.t("logistics.selected_service"),
        width: 120,
        slots: {
          customRender: "canChooseServerInfoes",
        },
      },
    ];

    const handleClose = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "warehouse_monitor_print_waybill" });
    };

    const getLanguageName = (cnName = "", enName = "") => {
      if (getters.language === "zh-CN") {
        return cnName;
      }
      return enName;
    };

    const getAddressLanguageName = (data) => {
      if (typeof data !== "object") {
        return "";
      }
      return getAddressByLanguageV2(data, getters.language);
    };

    const getTotalFee = (list = []) => {
      let symbol = list.length > 0 ? list[0].symbol : '';
      let value = list.reduce((total, item) => {
        return total.plus(item.value);
      }, new BigNumber(0)).toNumber();
      return { symbol, value, checkValue: null };
    };

    const handleShowErrorMessage = (errorMsg) => {
      Modal.error({
        title: () => vueI18n.t('warehouse.error_message'),
        content: () => errorMsg ?? ''
      })
    }


   const trackModal = reactive({
      visible: false,
      loading: false,
      customerNo: null,
      planNo: null,
      list: [],
      id: null,
    });

    const handleShowTrackModal = () => {
      trackModal.visible = true;
      trackModal.customerNo = state.pageInfo.customerNo;
      trackModal.planNo = state.pageInfo.planNo;
      trackModal.planId = route.params.id;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getWaybillTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    const getDetail = async (id) => {
      try {
        state.loading = true;
        let { result } = await gettWayBill({ id });
        if (result) {
          let { details = [], supplierLogisticScheme, wayBillCheck, ...info } = result;
          state.packingInformation = details;
          state.wayBillCheck = wayBillCheck;
          state.pageInfo = info;
          if (supplierLogisticScheme) {
            state.channelTable = [supplierLogisticScheme]
          }
        } else {
          message.error(vueI18n.t("common.exception"));
        }

        let { result: dataSource } = await getAccountRecordDetail({ id });
        if (dataSource) {
          state.dataSource = dataSource;
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    onMounted(() => {
      let id = route.params.id;
      if (id) {
        getDetail(id);
      } else {
        message.error(vueI18n.t("common.exception"));
      }
    });

    return {
      ...toRefs(state),
      columns,
      secondColumns,
      labelCol: { style: { width: "150px" } },
      wrapperCol: { style: { width: "calc(100% - 150px)" } },
      destinationTypeEnum,
      waybillOperationStatusEnum,
      trackModal,
      gToKg,
      cmCubicToM,
      getLanguageName,
      getAddressLanguageName,
      getTotalFee,
      handleClose,
      handleShowErrorMessage,
      handleShowTrackModal,
    };
  },
});
</script>

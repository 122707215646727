<template>
  <div v-if="state.showing">
    <a-modal v-model:visible="state.visible"
             :centered="true"
             @ok="handleConfirm"
             @cancel="cancel"
             :after-close="afterClose"
             :confirm-loading="loading"
             :maskClosable="false">
      <template #title>
        <slot name="title">{{ $t('warehouse.operation_tips') }}</slot>
      </template>
      <slot></slot>
      <div class="mt-3">
        <a-form :model="state.formState"
                ref="formRef"
                :label-col="{ style: { width: '100px' } }"
                :rules="rules">
          <a-form-item label="PIN"
                       name="pin">
            <a-input-password v-model:value="state.formState.pin"
                              ref="inputRef"
                              @focus="(event) => {event.currentTarget.select()}"
                              @pressEnter="handleConfirm"
                              style="width: 250px;"></a-input-password>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watchEffect } from 'vue';
import { useI18n } from "vue-i18n/index";
import { Modal, Form, Input } from 'ant-design-vue';
import { refInputElemFocus } from '../../utils/domoperate';

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInputPassword: Input.Password,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    // 是否需要在弹窗打开时聚焦pin输入框
    needFocus: {
      type: Boolean,
      default: true,
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);
    const inputRef = ref(null);

    const elemFocusInput = (elem) => {
      nextTick(() => {
        elem.value.blur();
        refInputElemFocus(elem.value.input);
      });
    }

    const state = reactive({
      visible: false,
      showing:false,
      formState: {
        pin: null,
      }
    })

    watchEffect(() => {
      state.visible = props.visible;
      if (props.visible) {
        state.showing = state.visible;
        nextTick(() => {
          formRef.value.resetFields();
          state.formState.pin=null;
          if (props.needFocus) {
            elemFocusInput(inputRef);
          }
        })
      }
    });

    const rules = {
      pin: {
        required: true,
        message: vueI18n.t('common.p0_is_required', ['PIN']),
      }
    }

    const handleConfirm = async () => {
      formRef.value.validate().then(() => {
        emit('confirm', state.formState.pin)
      }).catch(() => {
        elemFocusInput(inputRef);
      })
    }

    const cancel = () => {
      emit('update:visible', false);
      emit('handleCancel');
    }

    const afterClose = () => {
      state.showing = false;
    }

    return {
      state,
      rules,
      formRef,
      inputRef,
      handleConfirm,
      cancel,
      afterClose
    }
  },
})
</script>

<style scoped></style>
import client from "../../client";

const Api = {
  getList: "/api/WarehouseService/TransportPlanConsole/GetPlanPaged",
  getDetails: "/api/WarehouseService/TransportPlanConsole/GetDetail",
  completeCostAccounting: "/api/WarehouseService/TransportPlanConsole/FinanceAccount",
};

export const getList = ({
  shopId,
  planNo,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: {  shopId,planNo,skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const getDetails = ({ id }) => {
  return client.request({
    url: Api.getDetails,
    data: { id },
    method: "post",
  });
};

export const completeCostAccounting = ({ planId,checkBillingWeight,checkBillingVolume,expressFeeDiscountAmount,expressFeeDiscountRate,feeData}) => {
  return client.request({
    url: Api.completeCostAccounting,
    data: { planId,checkBillingWeight,checkBillingVolume,expressFeeDiscountAmount,expressFeeDiscountRate,feeData},
    method: "post",
  });
};

import client from "../../client"

const Api = {
    //获取特殊分区分页列表
    getPagedList: "/api/SupplierService/SpecialRegion/GetPagedList",

    //创建特殊分区信息
    create: "/api/SupplierService/SpecialRegion/Create",

    //更新特殊分区信息
    update: "/api/SupplierService/SpecialRegion/Update",

    //根据Id获取详细
    getById: "/api/SupplierService/SpecialRegion/GetById",

    //删除特殊分区
    deleteById: "/api/SupplierService/SpecialRegion/Delete",

    //获取所有的物流分区选项
    getAllList: "/api/SupplierService/SpecialRegion/GetAllList",
}

//获取特殊分区分页列表
export const getPagedList = (data) => {
    return client.request({
        url: Api.getPagedList,
        data,
        method: 'POST'
    });
}


//创建特殊分区信息
export const create = (data) => {
    return client.request({
        url: Api.create,
        data,
        method: 'POST'
    });
}

//更新特殊分区信息
export const update = (data) => {
    return client.request({
        url: Api.update,
        data,
        method: 'POST'
    });
}

//根据Id获取详细
export const getById = (regionId) => {
    return client.request({
        url: Api.getById,
        params: { regionId },
        method: 'GET'
    });
}

//删除分区
export const deleteById = (id) => {
    return client.request({
        url: Api.deleteById,
        data: { ids: [id] },
        method: 'POST'
    });
}

//获取全部的列表-简单数据，用于下拉选择
export const getAllList = () => {
    return client.request({
        url: Api.getAllList,
        method: 'GET'
    });
}
import client from "../../client";

const Api = {
  getList: "/api/WarehouseService/WarehouseTaskMonitoring/GetDelegateInPlanPagedList",
  getInPlanDetail: "/api/WarehouseService/WarehouseTaskMonitoring/GetDelegate",

  getTrackList: "/api/WarehouseService/WarehouseTaskMonitoring/GetAllInWarehouseTraceList",

};

export const getList = (data) => {
  return client.request({
    url: Api.getList,
    data,
    method: "POST",
  });
};

export const getInPlanDetail = (data) => {
  return client.request({
    url: Api.getInPlanDetail,
    params: data,
    method: "get",
  });
};

export const getTrackList = (data) => {
  return client.request({
    url: Api.getTrackList,
    params: data,
    method: "get",
  });
};
import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  getProductList: "/api/ProductService/ProductManage/GetListPaged",
  updateProduct: "/api/ProductService/Product/Update",
  getDefaultProductSeLable:"/api/ProductService/Label/GetDefault",
  deleteProduct:"/api/ProductService/ProductManage/Hide",
  // 导出产品excel
  exportInventoryList: "/api/ProductService/Product/ExportProduct",
  // 获取产品版本分页列表
  getProductVersionListPaged: "/api/ProductService/ProductVersion/GetListPaged",
  // 产品版本详细
  getProductVersion: "/api/ProductService/ProductVersion/Get",
  getProductCheckHistory: "/api/ProductService/PackingVerifyLog/GetListPaged",
};

export const getProductCheckHistory = (data) => {
  return client.request({
    url: Api.getProductCheckHistory,
    data: data,
    method: "post",
  });
};

export const exportInventoryList = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportInventoryList);
};

export const getProductList = ({
  searchKey,
  shopId,
  productName,
  productNo,
  seSku,
  isPackingVerify = true,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getProductList,
    data: {
      searchKey,
      shopId,
      productName,
      productNo,
      seSku,
      isPackingVerify,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};

export const updateProduct = (data) => {
  return client.request({
    url: Api.updateProduct,
    data: data,
    method: "post",
  });
};

export const getDefaultProductSeLable = (productId) => {
  return client.request({
    url: Api.getDefaultProductSeLable,
    params: {productId},
    method: "get",
  });
};

export const deleteProduct = (id) => {
  return client.request({
    url: Api.deleteProduct,
    data: {id},
    method: "post",
  });
};

export const getProductVersionListPaged = (data) => {
  return client.request({
    url: Api.getProductVersionListPaged,
    data,
    method: "POST",
  });
};

export const getProductVersion = (params) => {
  return client.request({
    url: Api.getProductVersion,
    params,
    method: "GET",
  });
};
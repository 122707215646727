<template>
  <Content>
    <template #contentTitle>{{ $t('menu.dev_logistics_payment_countdown') }}</template>
    <template #contentBody>
      <a-form
        ref="formRef"
        :model="state.formState"
        :rules="rules"
        :label-col="{ style: { width: '250px' } }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-item :label="$t('logistics.customer_payment_return_time') + '(' + $t('common.hours') + ')'" name="countdownPaidHour">
          <a-input-number v-model:value="state.formState.countdownPaidHour" :min="1" :max="9999" :precision="0" style="width: 250px;"></a-input-number>
        </a-form-item>
        <a-form-item label=" " :colon="false">
          <a-button type="primary" :loading="state.loading" @click="handleConfirm">{{ $t('common.confirm') }}</a-button>
        </a-form-item>
      </a-form>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { Button, Form, InputNumber, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import { useI18n } from "vue-i18n/index";
import { getTransportPlanConsoleConfig, editTransportPlanConsoleConfig } from "@/api/modules/devops/logisticsPaymentCountdown.js";

export default defineComponent({
  name: "dev_logistics_payment_countdown",
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AInputNumber: InputNumber,
    AButton: Button,
    Content,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      loading: false,
      formState: {
        id: null,
        countdownPaidHour: null,
      }
    })

    const rules = {
      countdownPaidHour: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.customer_payment_return_time")]),
      }
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.loading = true;
        const data = Object.assign({}, state.formState);
        await editTransportPlanConsoleConfig(data);
        message.success(vueI18n.t("common.succeed"));
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    const getDate = () => {
      state.loading = true;
      getTransportPlanConsoleConfig().then(({ result }) => {
        if (result) {
          for (const key in state.formState) {
            if (Object.hasOwnProperty.call(result, key)) {
              state.formState[key] = result[key];
            }
          }
        }
      }).catch(() => {
      }).finally(() => {
        state.loading = false;
      });
    }

    onMounted(getDate);

    return {
      state,
      formRef,
      rules,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>
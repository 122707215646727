<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.customer_company_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-tabs v-model:activeKey="activeKey" @change="handleChangeTab">
        <a-tab-pane
          :key="0"
          :tab="$t('account.all') + ' ' + (statistics.totalCount ?? '')"
        ></a-tab-pane>
        <a-tab-pane
          :key="auditStatusEnum.checkPending"
          :tab="
            $t($enumLangkey('auditStatus', auditStatusEnum.checkPending)) +
            ' ' +
            (statistics.noAuditCount ?? '')
          "
          force-render
        ></a-tab-pane>
        <a-tab-pane
          :key="auditStatusEnum.passTheAudit"
          :tab="
            $t($enumLangkey('auditStatus', auditStatusEnum.passTheAudit)) +
            ' ' +
            (statistics.passCount ?? '')
          "
        ></a-tab-pane>
        <a-tab-pane
          :key="auditStatusEnum.auditFailed"
          :tab="
            $t($enumLangkey('auditStatus', auditStatusEnum.auditFailed)) +
            ' ' +
            (statistics.noPassCount ?? '')
          "
        ></a-tab-pane>
      </a-tabs>
      <div>
        <a-form layout="inline">
          <a-form-item class="mb-1">
            <a-input
              v-model:value="shopId"
              style="width: 250px"
              :placeholder="`${$t('account.company_name')}/NO/${$t(
                'account.register_id'
              )}/${$t('account.company_id')}`"
              allow-clear=""
            ></a-input>
          </a-form-item>
          <a-form-item class="mb-1">
            <a-select
              v-model:value="selectedStatus"
              :placeholder="$t('account.account_status')"
              allowClear
              style="width: 250px"
            >
              <a-select-option
                v-for="(item, index) in shopAccountStatusEnum"
                :key="index"
                :title="$t($enumLangkey('shopAccountStatus', item))"
                :value="item"
              >
                {{ $t($enumLangkey("shopAccountStatus", item)) }}
              </a-select-option>
            </a-select>
          </a-form-item>
          
          <a-form-item  class="mb-1">
             <a-select
            :allowClear="true"
            v-model:value="shopLevelId"
            show-search
            optionFilterProp="search-key"
            placeholder="账户等级"
            style="width: 250px"
          >
            <a-select-option
                :value="item.id"
                v-for="item in accountLevelAll"
                :search-key="item.name"
                :title="item.name"
                :key="item.id"
              >
                {{ item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="业务负责人" class="mb-1">
            <SearchEmployeeModal
              v-model:employeeId="businessPersonLiableIds"
            ></SearchEmployeeModal>
          </a-form-item>
          <a-form-item label="客服" class="mb-1">
            <SearchEmployeeModal
              v-model:employeeId="customerServiceStaffIds"
            ></SearchEmployeeModal>
          </a-form-item>
          <a-form-item>
            <a-space>
              <a-button type="primary" @click="handleQuery">
                {{ $t("common.query") }}
              </a-button>
              <a-button type="ghost" @click="handleShowPinModal">
                {{ $t("common.export") }}
              </a-button>
            </a-space>
          </a-form-item>
        </a-form>
      </div>
      <div class="inventory-table mt-2">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :scroll="{ x: true, y: wrap.contentHeight - 150 }"
          :pagination="false"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :loading="listLoading"
          class="product-table"
        >
          <template #shopNo="{ record }">
            <div>{{ record.shopNo }}</div>
            <div>{{ record.shopMark }}</div>
          </template>

          <template #company="{ record }">
            <div>{{ record.shopName }} <a-tag color="blue" v-if="record.isAgent">{{ $t('account.agent') }}</a-tag></div>
            <div>{{ record.businessLicenseCode }}</div>
          </template>

          <template #manager="{ record }">
            <div>
              {{ record.managerRealName }}
              <span v-if="record.managerUserNo"
                >({{ record.managerUserNo }})</span
              >
            </div>
            <div>{{ record.managerUserName }}</div>
          </template>

          <template #shopLevel="{ record }">
            {{ record.shopLevelName }}
            <a
              src="javascript:"
              @click="handleShowEditLevelModal(record.id, record.shopLevelId)"
            >
              <EditOutlined />
            </a>
          </template>

          <template #countryAndDate="{ record }">
            <div>
              {{
                getLanguageName({
                  cnName: record.countryCnName,
                  enName: record.countryEnName,
                })
              }}
            </div>
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
          </template>
          <template #auditPassTime="{ record }">
            {{ $filters.utcToCurrentTime(record.auditPassTime) }}
          </template>
          <template #supplierChannelName="{ record }">
            {{ $filters.utcToCurrentTime(record.lastLoginTime) }}
          </template>

          <template #shopAccountStatus="{ record }">
            {{
              $t($enumLangkey("shopAccountStatus", record.shopAccountStatus))
            }}
          </template>
          <template #auditStatus="{ record }">
            {{ $t($enumLangkey("auditStatus", record.auditStatus)) }}
          </template>
          <template #contract="{ record }">
            <a-row justify="start" align="middle" :gutter="4">
              <a-col>
                <span v-if="record.hasContract" class="text-success">
                  <CheckCircleOutlined /> {{ $t("account.signed") }}</span
                >
                <span v-else class="text-grey">
                  <CloseCircleOutlined /> {{ $t("account.unsigned") }}</span
                >
                <div v-if="record.contractPath">
                  <CFileDisplay :fileUrl="record.contractPath"></CFileDisplay>
                </div>
              </a-col>
              <a-col>
                <a
                  src="javascript:"
                  @click="handleShowUpdateContractModal(record)"
                >
                  <EditOutlined />
                </a>
              </a-col>
            </a-row>
          </template>

          <template #partner="{ record }">
            <div v-if="record.partnerId">
              <div>{{ record.partnerName }}</div>
              <div>{{ record.partnerNo }}</div>
            </div>
            <span v-else>-</span>
          </template>
          <template #partnerCommission="{ record }">
            <a-space v-if="record.partnerId">
              <span v-if="record.partnerShopRlIsActive">{{
                $t("finance.normal_commission_distribution")
              }}</span>
              <span v-else>{{
                $t("finance.termination_of_commission_distribution")
              }}</span>
              <a-switch
                size="small"
                v-model:checked="record.partnerShopRlIsActive"
                @change="(e) => handleActiveChange(e, record)"
              />
            </a-space>
            <span v-else>-</span>
          </template>

          <template #businessPersonLiable="{ record }">
            <a-space>
              <CEmployeeInfo v-if="record.businessPersonLiableEmployee" :employee="record.businessPersonLiableEmployee" />
              <a
                src="javascript:"
                @click="handleShowUpdateLeaderModal(record, 1)"
              >
                <EditOutlined />
              </a>
            </a-space>
          </template>

          <template #customerServiceStaff="{ record }">
            <a-space>
              <CEmployeeInfo v-if="record.customerServiceStaffEmployee" :employee="record.customerServiceStaffEmployee" />
              <a
                src="javascript:"
                @click="handleShowUpdateLeaderModal(record, 2)"
              >
                <EditOutlined />
              </a>
            </a-space>
          </template>

          <template #operat="{ record }">
            <a-dropdown-button>
              <router-link
                :to="{
                  name: 'customer_company_details',
                  params: { id: record.id },
                }"
              >
                {{ $t("common.details") }}
              </router-link>
              <template #overlay>
                <a-menu @click="handleClickOperateMenu">
                  <a-menu-item
                    :key="1"
                    v-if="record.auditStatus == auditStatusEnum.checkPending"
                    :record="record"
                  >
                    {{ $t("account.audit") }}
                  </a-menu-item>
                  <a-menu-item
                    :key="2"
                    :record="record"
                    v-if="
                      record.shopAccountStatus ==
                      shopAccountStatusEnum.inTheActivity
                    "
                  >
                    {{ $t("account.freeze") }}
                  </a-menu-item>
                  <a-menu-item
                    :key="3"
                    :record="record"
                    v-if="
                      record.shopAccountStatus ==
                      shopAccountStatusEnum.haveBeenFrozen
                    "
                  >
                    {{ $t("account.unfreeze") }}
                  </a-menu-item>
                  <a-menu-item :key="4" :record="record">
                    {{ $t("warehouse.warehouse_discount") }}
                  </a-menu-item>
                  <a-menu-item :key="5" :record="record">
                    {{ $t("menu.customer_company_transaction_record") }}
                  </a-menu-item>
                  <a-menu-item :key="6" :record="record">
                    {{ $t("warehouse.change_the_partner") }}
                  </a-menu-item>
                  <a-menu-item :key="7" :record="record">
                    {{ $t("menu.customer_company_accounting_period") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>

        <a-modal
          v-model:visible="modal.visible"
          :title="$t('account.account_audit')"
          :confirmLoading="modal.loading"
          :footer="false"
        >
          <a-row :gutter="[32, 8]">
            <a-col :span="6" style="text-align: right">{{
              $t("account.company_name")
            }}</a-col>
            <a-col :span="18">{{ modal.shopName }}</a-col>
            <a-col :span="6" style="text-align: right">{{
              $t("account.register_id")
            }}</a-col>
            <a-col :span="18">{{ modal.businessLicenseCode }} </a-col>
            <a-col :span="6" style="text-align: right">{{
              $t("account.business_license")
            }}</a-col>
            <a-col :span="18">
              <div>
                <CFileDisplay
                  :fileUrl="modal.businessLicense"
                  :disImgProcess="true"
                ></CFileDisplay>
              </div>
            </a-col>
            <a-col :span="6" style="text-align: right">{{
              $t("account.account_principal")
            }}</a-col>
            <a-col :span="18">
              {{ modal.managerRealName }}
              <span v-if="modal.managerUserNo"
                >({{ modal.managerUserNo }})</span
              >
            </a-col>
            <a-col :span="6" style="text-align: right">{{
              $t("account.phone")
            }}</a-col>
            <a-col :span="18">{{ modal.managerUserPhoneNumber }}</a-col>

            <a-col :span="6" style="text-align: right">{{
              $t("account.audit_result")
            }}</a-col>
            <a-col :span="18">
              <a-textarea
                v-model:value="modal.auditFailureReason"
                :placeholder="$t('account.audit_result_placeholder')"
                :rows="4"
              />
            </a-col>
          </a-row>
          <a-row type="flex" justify="center" align="middle" class="mt-3">
            <a-col>
              <a-button
                type="primary"
                :loading="modal.loading"
                @click="handleUpdateAuditStatus(true)"
                >{{ $t("account.pass") }}</a-button
              >
            </a-col>
            <a-col>
              <a-button
                class="ml-5"
                :loading="modal.loading"
                @click="handleUpdateAuditStatus(false)"
                >{{ $t("account.reject") }}</a-button
              >
            </a-col>
          </a-row>
        </a-modal>
      </div>

      <UpdateContract
        v-model:visible="updateContractModal.visible"
        :shopId="updateContractModal.record?.id"
        :hasContract="updateContractModal.record?.hasContract"
        :contractPath="updateContractModal.record?.contractPath"
        @ok="handleSearch"
      ></UpdateContract>

      <UpdateLeaderModal
        v-model:visible="leaderModal.visible"
        :shopId="leaderModal.record?.id"
        :type="leaderModal.type"
        :employeeId="leaderModal.employeeId"
        @ok="handleSearch"
      ></UpdateLeaderModal>
      <UpdateLevelModal ref="updateLevelModalRef" @refresh="handleQuery()" />
      <PartnerChangeRecordModal
        ref="partnerChangeRecordModalRef"
        @refresh="handleQuery()"
      />
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleDownloadExcel"
      />
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onActivated, ref } from "vue";
import {
  Row,
  Col,
  Tabs,
  Form,
  Select,
  Menu,
  Modal,
  message,
  Table,
  Button,
  Input,
  Dropdown,
  Space,
  Switch,
  Tag,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CImage from "@/views/components/CImage.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import CEmployeeInfo from "@/views/components/CEmployeeInfo.vue";
import UpdateLevelModal from "./components/UpdateLevelModal.vue";
import SearchEmployeeModal from "./components/SearchEmployeeModal.vue";
import UpdateLeaderModal from "./components/UpdateLeaderModal";
import UpdateContract from "./components/UpdateContract";
import PartnerChangeRecordModal from "./components/PartnerChangeRecordModal.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
import { downloadFile } from "@/utils/downloader";
import _ from "lodash";
import {
  getName,
  getAddressByLanguageV2,
  dateString,
} from "@/utils/general";
import {
  getList,
  getShopCountStatistics,
  updateAccountStatus,
  updateAuditStatus,
  updatePartnerShopRlActive,
  exportShopList,
  getALLAccountLevel
} from "@/api/modules/customer/company/list";
import {
  shopAccountStatus as shopAccountStatusEnum,
  auditStatus as auditStatusEnum,
} from "@/enum/enum.json";

export default {
  name: "customer_company_list",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AModal: Modal,
    ATextarea: Input.TextArea,
    ASpace: Space,
    ASwitch: Switch,
    ATag: Tag,
    Content,
    CPager,
    CImage,
    CFileDisplay,
    UpdateLeaderModal,
    UpdateContract,
    CPinTipsModal,
    CEmployeeInfo,
    PartnerChangeRecordModal,
    SearchEmployeeModal,
    UpdateLevelModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const updateLevelModalRef = ref();
    const partnerChangeRecordModalRef = ref();

    const columns = [
      {
        title: () => "NO/" + vueI18n.t("account.company_id"),
        dataIndex: "shopNo",
        fixed: "left",
        width: 100,
        slots: {
          customRender: "shopNo",
        },
      },
      {
        title: () =>
          vueI18n.t("account.company_name") +
          "/" +
          vueI18n.t("account.register_id"),
        dataIndex: "company",
        slots: {
          customRender: "company",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("account.the_head_of_a_company"),
        dataIndex: "manager",
        slots: {
          customRender: "manager",
        },
        width: 180,
      },
       {
        dataIndex: "contract",
        title: () => vueI18n.t("account.contract_status"),
        slots: {
          customRender: "contract",
        },
        width: 110,
      },
      
      {
        dataIndex: "country",
        title: () =>
          vueI18n.t("account.country") +
          "/" +
          vueI18n.t("account.registration_date"),
        slots: {
          customRender: "countryAndDate",
        },
        width: 150,
      },
      {
        dataIndex: "auditPassTime",
        title: () => vueI18n.t("account.approval_time"),
        slots: {
          customRender: "auditPassTime",
        },
        width: 150,
      },
      {
        dataIndex: "supplierChannelName",
        title: () => vueI18n.t("account.last_login_int"),
        slots: {
          customRender: "supplierChannelName",
        },
        width: 150,
      },
      {
        dataIndex: "shopAccountStatus",
        title: () => vueI18n.t("account.account_status"),
        slots: {
          customRender: "shopAccountStatus",
        },
        width: 90,
      },
      {
        dataIndex: "auditStatus",
        title: () => vueI18n.t("account.audit_status"),
        slots: {
          customRender: "auditStatus",
        },
        width: 90,
      },
     {
        title: () => vueI18n.t("menu.customer_level_list"),
        slots: {
          customRender: "shopLevel",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("menu.partner"),
        slots: {
          customRender: "partner",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("finance.partner_commission"),
        slots: {
          customRender: "partnerCommission",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("account.business_manager"),
        slots: {
          customRender: "businessPersonLiable",
        },
        width: 180,
      },
      {
        title: () => vueI18n.t("account.service"),
        slots: {
          customRender: "customerServiceStaff",
        },
        width: 180,
      },
      {
        dataIndex: "operat",
        title: "",
        width: 120,
        fixed: "right",
        slots: {
          customRender: "operat",
        },
      },
    ];

    const state = reactive({
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      listLoading: false,
      tableList: [],
      activeKey: 0,
      shopId: null,
      selectedStatus: null,
      shopLevelId:null,
      businessPersonLiableIds: [],
      customerServiceStaffIds: [],
      statistics: {
        totalCount: null,
        noAuditCount: null,
        passCount: null,
        noPassCount: null,
      },
      pinModalState: {
        visible: false,
        loading: false,
      },
      accountLevelAll:[],

    });

    let getModal = () => {
      return {
        id: null,
        shopName: null,
        businessLicenseCode: null,
        businessLicense: null,
        managerRealName: null,
        managerUserNo: null,
        managerUserPhoneNumber: null,
        auditFailureReason: null,
      };
    };

    const modal = reactive({
      visible: false,
      loading: false,
    });

    const funcGetShopCountStatistics = () => {
      getShopCountStatistics()
        .then((res) => {
          if (res.result) {
            Object.assign(state.statistics, res.result);
          }
        })
        .catch(() => {});
    };

    const handleChangeTab = (activeKey) => {
      state.activeKey = activeKey ?? 0;
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      handleSearch();
    };

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleQuery = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      handleSearch();
      funcGetShopCountStatistics();
    };

    const handleSearch = async () => {
      let searchData = {
        ...state.pageData,
        auditStatus: state.activeKey === 0 ? null : state.activeKey,
        shopAccountStatus: state.selectedStatus,
        searchKey: state.shopId,
        shopLevelId: state.shopLevelId,
        businessPersonLiableIds: state.businessPersonLiableIds,
        customerServiceStaffIds: state.customerServiceStaffIds,
      };

      state.listLoading = true;
      getList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            state.tableList = items.map((item) => {
              item.loading = false;
              return item;
            });
            state.pageData.totalCount = parseInt(totalCount);
          } else {
            state.pageData.totalCount = 0;
            state.tableList = [];
          }
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const handleClickOperateMenu = (option) => {
      let { key, item } = option;
      let { record } = item;
      switch (key) {
        case 1:
          showAuditModla(record);
          break;
        case 2:
          funcUpdateAccountStatus(record, false);
          break;
        case 3:
          funcUpdateAccountStatus(record, true);
          break;
        case 4:
          router.push({
            name: "customer_company_discount_list",
            params: { id: record.id },
          });
          break;
        case 5:
          router.push({
            name: "customer_company_transaction_record",
            params: { id: record.id },
          });
          break;
        case 6:
          partnerChangeRecordModalRef.value.show(record.id);
          break;
        case 7:
          router.push({
            name: "customer_company_accounting_period",
            params: { id: record.id },
          });
          break;
        default:
          break;
      }
    };

    const funcUpdateAccountStatus = (record, isActive) => {
      updateAccountStatus(record.id, isActive)
        .then((res) => {
          message.success(vueI18n.t("common.succeed"));
          handleSearch();
        })
        .catch(() => {});
    };

    const showAuditModla = (record) => {
      modal.visible = true;
      Object.assign(modal, getModal());
      Object.assign(modal, record);
    };

    const handleUpdateAuditStatus = (isPass) => {
      if (
        !isPass &&
        (!modal.auditFailureReason ||
          modal.auditFailureReason?.trim()?.length == 0)
      ) {
        message.error(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("account.audit_result"),
          ])
        );
        return false;
      }
      modal.loading = true;
      let params = {
        id: modal.id,
        isPass: isPass,
        auditFailureReason: modal.auditFailureReason,
      };
      updateAuditStatus(params)
        .then((res) => {
          message.success(vueI18n.t("common.succeed"));
          modal.visible = false;
          modal.loading = false;
          handleSearch();
          funcGetShopCountStatistics();
        })
        .catch(() => {
          modal.loading = false;
        });
    };

    const handleShowEditLevelModal = (id, shopLevelId) => {
      updateLevelModalRef.value.init(id, shopLevelId);
    };

    const handleActiveChange = _.debounce((isActive, record) => {
      record.loading = true;
      updatePartnerShopRlActive({ id: record.partnerShopRlId, isActive })
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .catch(() => {
          record.partnerShopRlIsActive = !isActive;
        })
        .finally(() => {
          record.loading = false;
        });
    }, 300);

    const handleShowPinModal = () => {
      state.pinModalState.visible = true;
    };

    const handleDownloadExcel = (pinCode) => {
      state.pinModalState.loading = true;
      console.log(state.shopId);
      let url = exportShopList();
      let searchData = {
        auditStatus: state.activeKey === 0 ? null : state.activeKey,
        shopAccountStatus: state.selectedStatus,
        searchKey: state.shopId,
        shopLevelId: state.shopLevelId,
        businessPersonLiableIds: state.businessPersonLiableIds,
        customerServiceStaffIds: state.customerServiceStaffIds,
        pinCode,
      };
      downloadFile(
        url,
        `customer_company_${dateString()}.csv`,
        "POST",
        searchData
      )
        .then(() => {
          state.pinModalState.visible = false;
        })
        .catch(() => {})
        .finally(() => {
          state.pinModalState.loading = false;
        });
    };

    //------------------------更新业务负责人 start---------------

    const leaderModal = reactive({
      visible: false,
      record: null,
      type: null,
      employeeId: null,
    });

    const handleShowUpdateLeaderModal = (record, type) => {
      leaderModal.visible = true;
      leaderModal.record = record;
      leaderModal.type = type;
      if (type == 1) {
        leaderModal.employeeId = record.businessPersonLiableId;
      } else if (type == 2) {
        leaderModal.employeeId = record.customerServiceStaffId;
      }
    };

    //------------------------更新业务负责人 end---------------

    //------------------------更新合同 start---------------

    const updateContractModal = reactive({
      visible: false,
      record: null,
    });

    const handleShowUpdateContractModal = (record) => {
      updateContractModal.record = record;
      updateContractModal.visible = true;
    };

    //------------------------更新合同 end---------------

    //------------------------更新客服 start---------------
    // const handleShowUpdateLeaderModal = ( record ) => {

    // }

    //------------------------更新客服 end---------------

    const funcGetAccountLevelALL = () => {
      getALLAccountLevel().then((res) => {
            if (res.result) {
              state.accountLevelAll = res.result;
            }
          })
      .catch(() => {});
    }

    onActivated(async () => {
      let shopLevelId = route.params.levelId ;
      if (shopLevelId) {
        state.shopLevelId = shopLevelId;
        state.activeKey = 0;
      }
      handleChangeTab(state.activeKey);
      funcGetShopCountStatistics();
      funcGetAccountLevelALL();
    });

    return {
      updateLevelModalRef,
      partnerChangeRecordModalRef,
      columns,
      modal,
      ...toRefs(state),
      shopAccountStatusEnum,
      auditStatusEnum,
      handlePage,
      handleSearch,
      getLanguageName,
      getAddress,
      handleChangeTab,
      handleClickOperateMenu,
      handleUpdateAuditStatus,
      handleShowEditLevelModal,
      handleActiveChange,
      handleQuery,
      handleShowPinModal,
      handleDownloadExcel,

      leaderModal,
      handleShowUpdateLeaderModal,

      updateContractModal,
      handleShowUpdateContractModal,
    };
  },
};
</script>

<style lang="less" scoped>
.table-list-img-common {
  width: 54px;
  height: 54px;

  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  .ant-image img {
    max-height: 45px !important;
  }
}
</style>

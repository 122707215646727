<template>
  <!-- 追踪号modal -->
  <a-modal
    width="700px"
    v-model:visible="isShowModal"
    :centered="true"
    :maskClosable="false"
    :title="$t('warehouse.tracking_shipment')"
    :okButtonProps="{ style: { display: 'none' } }"
    :okText="$t('common.save')"
    :confirmLoading="modalConfirmLoading"
    :cancelText="$t('common.close')"
  >
    <a-table
      :columns="modalColumns"
      :data-source="modalList"
      :pagination="false"
      :expandIconAsCell="false"
      :expandIconColumnIndex="2"
      :loading="modelLoading"
      :row-key="(record) => record.detailId"
    >
      <template #expandIcon="{ expanded, record, onExpand }">
        <CHaveProductInformation
          :disabled="!record.container?.hasRelationProduct"
          :open="expanded"
          @click="(e) => onExpand(record, e)"
        />
      </template>
      <template #trackingNoCustom="{ record }">
        {{ record.trackNo || "-" }}
      </template>
      <template #track="{ record }">
        {{ record.track || "-" }}
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.items"
          :pagination="false"
          :row-key="(record, index) => index"
          size="small"
        >
          <template #productInfo="{ record }">
            <a-row :gutter="8" type="flex" align="middle">
              <a-col>
                <div class="table-list-img-common">
                  <c-image
                    :src="record.productImgUrl"
                    :thumbWidth="600"
                    :thumbHeight="600"
                  />
                </div>
              </a-col>
              <a-col flex="1">
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
                <div>SESKU: {{ record.seSku }}</div>
              </a-col>
            </a-row>
          </template>
          <template #encasementCount="{ record }">
            {{ record.containerCount }} Unit
          </template>
        </a-table>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from "vue";
import { Table, Input, Button, Modal, Row, Col } from "ant-design-vue";
import CHaveProductInformation from "@/views/components/CHaveProductInformation.vue";
import CImage from "@/views/components/CImage.vue";
import { useI18n } from "vue-i18n/index";
import { getTrackList } from "@/api/modules/warehouseMonitor/consignmentWarehousing";
import { warehouseType as warehouseTypeEnum } from "@/enum/enum.json";

export default {
  name: "traceProductModal",
  components: {
    ATable: Table,
    AInput: Input,
    AButton: Button,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    CHaveProductInformation,
    CImage,
  },
  setup() {
    const i18n = useI18n({ useScope: "global" });

    const state = reactive({
      modelLoading: false,
      isShowModal: false,
      modalConfirmLoading: false,
    });

    const data = reactive({
      modalList: [],
      planId: "",
    });

    const modalColumns = [
      {
        width: 60,
        title: () => i18n.t('warehouse.serial_number'),
        customRender: ({ index }) => {
          return index + 1
        }
      },
      {
        dataIndex: "boxNo",
        fixed: "left",
        width: 150,
        title: () => i18n.t("warehouse.box_number"),
      },
      { width: 50 },
      {
        width: 150,
        title: () => i18n.t("warehouse.tracking_no"),
        slots: {
          customRender: "trackingNoCustom",
        },
      },
      {
        width: 200,
        title: () => i18n.t("warehouse.track"),
        slots: {
          customRender: "track",
        },
      },
    ];

    const innerColumns = [
      { width: 60 },
      {
        width: 450,
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo",
        },
      },
      {
        width: 150,
        title: () => i18n.t("warehouse.number"),
        slots: {
          customRender: "encasementCount",
        },
      },
    ];
    //#endregion

    const handleTrackShowModal = async (planId) => {
      state.isShowModal = true;
      data.planId = planId;
      handelSearchModal();
    };

    const handelSearchModal = () => {
      state.modelLoading = true;
      state.modalConfirmLoading = true;
      getTrackList({
        planId: data.planId,
        warehouseType: warehouseTypeEnum.consignment,
      })
        .then(({ result }) => {
          if (Array.isArray(result)) {
            data.modalList = result;
          } else {
            data.modalList = [];
          }
        })
        .finally(() => {
          state.modelLoading = false;
          state.modalConfirmLoading = false;
        });
    };

    return {
      ...toRefs(state),
      ...toRefs(data),
      modalColumns,
      innerColumns,

      handleTrackShowModal,
      handelSearchModal,
    };
  },
};
</script>

<style lang="less" scoped>
.button-none {
  display: "none";
}
</style>

<template>
  <a-modal
    v-model:visible="state.visible"
    :title="$t('warehouse.reconciliation_detail')"
    :width="1200"
    :centered="true"
    :mask-closable="false"
    :footer="null"
    @cancel="close"
  >
    <a-spin :spinning="state.loading">
      <CReconciliationTable :data-source="state.dataSource" />
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
import { Modal, Spin } from "ant-design-vue";
import CReconciliationTable from "@/views/components/CReconciliationTable.vue";
import { getAccountRecordDetail } from "@/api/modules/warehouseMonitor/printWaybill";

export default defineComponent({
  emits: ['update:visible'],
  components: {
    AModal: Modal,
    ASpin: Spin,
    CReconciliationTable,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const state = reactive({
      visible: false,
      loading: false,
      dataSource: null,
    })

    const getReconciliationInfo = (id) => {
      state.loading = true;
      getAccountRecordDetail({ id }).then(({ result }) => {
        state.dataSource = result;
      }).finally(() => {
        state.loading = false;
      })
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => [props.visible, props.id], ([visible, id]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible && id) {
          getReconciliationInfo(id);
        }
      }
    }, { immediate: true });

    return {
      state,
      close,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <Content>
    <template #contentTitle>
      {{ $t('logistics.logistics_scheme') }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px;" v-model:value="searchState.name" :placeholder="$t('logistics.logistics_scheme_name')" allow-clear></a-input>
            </a-col>
            <a-col>
              <a-input style="width: 250px;" v-model:value="searchState.code" :placeholder="$t('finance.channel_code')" allow-clear></a-input>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.countryId"
                :placeholder="$t('warehouse.place_of_departure')"
                :loading="addressData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                allow-clear
              >
                <a-select-option v-for="item in addressData.countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.ioS2 + item.cnName + item.enName"
                  :title="`${getLanguageName(item)}(${item.ioS2})`"
                >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.isActive"
                :placeholder="$t('warehouse.status')"
                allow-clear
              >
                <a-select-option :value="1" :title="$t('logistics.enabled')">{{ $t('logistics.enabled') }}</a-select-option>
                <a-select-option :value="0" :title="$t('logistics.disabled')">{{ $t('logistics.disabled') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col><a-button @click="handleOpenCreateModal">{{ $t('common.create') }}</a-button></a-col>
      </a-row>
      <div class="mt-3">
        <a-table :columns="columns"
                 size="small"
                 :data-source="tableData.tableList"
                 :pagination="false"
                 :scroll="{ x: 500, y: wrap.contentHeight - 110 }"
                 :rowKey="
                   (record, index) => {
                     return index;
                   }
                 "
                 :loading="tableData.loading">
          <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
          <template v-slot:logisticsName="{ record }">
            <div>
              <span style="margin-right: 5px;">{{ record.name }}</span>
              <a-tag color="blue" v-if="record.apiAccountId">{{ $t('logistics.truck') }}</a-tag>
            </div>
            <div>
              <small>时效: {{ record.daysMin == null ? "-" : record.daysMin }}至{{
                  record.daysMax == null ? "-" : record.daysMax
                }}
                {{
                  $t($enumLangkey("channelTimeType", record.channelTimeType))
                }}</small>
            </div>
          </template>
          <template v-slot:logisticsCode="{ record }">
            <div>{{ record.code }}</div>
            <div v-if="record.discount">
              <small class="text-error">
                {{ $t('warehouse.discount') }}:
                <span>{{ record.discount }}%</span>
              </small>
            </div>
            <div v-if="record.commissionRate">
              <small class="text-warning">
                {{ $t('finance.commission_rate') }}:
                <span>{{ record.commissionRate }}%</span>
              </small>
            </div>
          </template>
          <template v-slot:addressInfo="{ record }">
            {{
            getAddressInfo(record.logisticsAddress)
          }}
            <!-- <div v-if="record.isSupportPayOnArrival">
              <a-tag color="processing">支持物流到付</a-tag>
            </div> -->
          </template>
          <template v-slot:warehouses="{ record }">
            <span v-if="
                !record.logisticsWarehouses ||
                record.logisticsWarehouses.length == 0
              ">-</span>
            <template v-else>
              <a-tag class="m-1"
                     v-for="wh in record.logisticsWarehouses"
                     :key="wh.warehouseId">{{ wh.warehouseNo }}</a-tag>
            </template>
          </template>
          <template v-slot:characteristics="{ record }">
            <a-tag class="m-1"
                   v-for="tc in record.transportCharacteristicsList"
                   :key="tc">{{ $t($enumLangkey("transportCharacteristics", tc)) }}</a-tag>
          </template>
          <template v-slot:accountingPeriodConfig="{ record }">
            <template v-if="record.accountingPeriodConfig && record.accountingPeriodConfig.isActive">
              <div>{{ $t($enumLangkey('logisticsNode', record.accountingPeriodConfig.logisticsNode)) }}</div>
              <div>{{ $t($enumLangkey('logisticsAccountingPeriodCalculationWay', record.accountingPeriodConfig.accountingPeriodCalculationWay)) }}</div>
              <div>{{ record.accountingPeriodConfig.accountingPeriodDays }}{{ $t('common.days') }}</div>
            </template>
            <span v-else>-</span>
          </template>
          <template v-slot:sizeRange="{ record }">
            <div>{{ gToKg(record.minWeight) }}-{{ gToKg(record.maxWeight) }}kg</div>
            <div>{{ cmCubicToM(record.minVolume) }}-{{ cmCubicToM(record.maxVolume) }}m<sup>3</sup></div>
          </template>
          <template v-slot:volumeWeightParam="{ record }">
            <div>{{ record.volumeWeightParam }}</div>
            <div>
              <small class="mr-1">分泡系数</small>
              {{ record.distributionRatio }}%
            </div>
          </template>
          <template v-slot:fuelSurcharge="{ record }">
            <div v-if="record.fuelSurcharge">{{ record.fuelSurcharge }}%</div>
            <div v-else>-</div>
          </template>
          <template v-slot:isActive="{ record }">
            <a-switch v-model:checked="record.isActive"
                      size="small"
                      :loading="record.statusLoading"
                      @change="handleIsActiveChanged(record)"></a-switch>
            <div class="mt-2"><small>{{ $t('common.sort') }}: {{ record.sortNum }}</small></div>
          </template>
          <template v-slot:operation="{ record }">
            <a-spin :spinning="!!record.copyLoading">
              <a-dropdown-button @click="handleOpenEditModal(record)">
                {{ $t('common.edit') }}
                <template #overlay>
                  <a-menu>
                    <router-link :to="{
                              name: 'logistics_region',
                              params: { logisticsId: record.id },
                            }">
                      <a-menu-item key="2">
                        <a>{{ $t('menu.logistics_region') }}</a>
                      </a-menu-item>
                    </router-link>
                    <template v-if="record.feeCalculationMethod === feeCalculationMethodEnum.template">
                      <router-link :to="{
                            name: 'logistics_surcharge',
                            params: { logisticsId: record.id },
                          }">
                        <a-menu-item key="3">
                          <a>{{ $t('menu.logistics_surcharge') }}</a>
                        </a-menu-item>
                      </router-link>
                      <router-link :to="{
                        name: 'logistics_fee_template',
                        params: { logisticsId: record.id },
                      }">
                        <a-menu-item key="4">
                          <a>{{ $t('menu.logistics_fee_template') }}</a>
                        </a-menu-item>
                      </router-link>
                    </template>
                    <router-link :to="{
                      name: 'logistics_embargo_configuration',
                      params: { id: record.id },
                    }">
                      <a-menu-item key="5">
                        <a>{{ $t("menu.logistics_embargo_configuration") }}</a>
                      </a-menu-item>
                    </router-link>

                    <router-link :to="{
                      name: 'logistics_limited_transportation_configuration',
                      params: { id: record.id },
                    }">
                      <a-menu-item key="6">
                        <a>{{ $t("menu.logistics_limited_transportation_configuration") }}</a>
                      </a-menu-item>
                    </router-link>
                    <router-link :to="{
                      name: 'logistics_discount',
                      params: { id: record.id },
                    }">
                      <a-menu-item key="7">
                        <a>{{ $t("menu.logistics_channel_discount") }}</a>
                      </a-menu-item>
                    </router-link>
                    <a-menu-item key="8" @click="handleShowCopyModal(record)">
                      <a>复制</a>
                    </a-menu-item>
                    <a-menu-item key="9" :disabled="record.logisticsType === logisticsWayEnum.commercialExpress" @click="handleSetAccountingPeriod(record)">
                      <span v-if="record.logisticsType === logisticsWayEnum.commercialExpress">{{ $t('logistics.accounting_period_configuration') }}</span>
                      <a v-else>{{ $t('logistics.accounting_period_configuration') }}</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown-button>
            </a-spin>

          </template>
        </a-table>
      </div>
      <a-modal v-model:visible="modalData.visible"
               :confirm-loading="modalData.loading"
               :title="'物流方案'"
               :maskClosable="false"
               :width="900"
               @ok="handleLogisticsSave">
        <a-form :model="modalData.logisticsItem"
                ref="refEditLogisticsForm"
                :label-col="{ span: 6 }"
                :rules="logisticsRules">
          <a-row>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="'名称'"
                           name="name">
                <a-input v-model:value="modalData.logisticsItem.name"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="'方案代码'"
                           name="code">
                <a-input v-model:value="modalData.logisticsItem.code"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="'物流线路'"
                           class="mb-0">
                <a-row :gutter="18">
                  <a-col :xs="24"
                         :sm="12">
                    <a-form-item name="isInternationalTransport">
                      <a-radio-group v-model:value="
                          modalData.logisticsItem.isInternationalTransport
                        "
                                     button-style="solid"
                                     size="small">
                        <a-radio-button :value="true">
                          <small>国际运输</small>
                        </a-radio-button>
                        <a-radio-button :value="false">
                          <small>本土运输</small>
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24"
                         :sm="12"
                         v-if="modalData.logisticsItem.isInternationalTransport">
                    <a-form-item name="declareSupported">
                      <label>
                        <small class="mr-1">支持一般贸易报关</small>
                        <a-switch v-model:checked="
                            modalData.logisticsItem.declareSupported
                          "></a-switch>
                      </label>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="'时效'">
                <a-input-group compact>
                  <a-select style="width: 30%"
                            v-model:value="modalData.logisticsItem.channelTimeType"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                    <a-select-option v-for="item in channelTimeTypeEnum"
                                     :key="item">{{
                        $t($enumLangkey("channelTimeType", item))
                      }}</a-select-option>
                  </a-select>
                  <a-input style="width: 35%"
                           type="number"
                           :addon-after="'~'"
                           class="input-group-mid"
                           v-model:value="modalData.logisticsItem.daysMin" />
                  <a-input v-model:value="modalData.logisticsItem.daysMax"
                           style="width: 35%"
                           type="number"
                           :addon-after="'天'" />
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item :label-col="{ span: 6, lg: { span: 3 } }"
                       :label="'起运地'"
                       required
                       class="mb-0">
            <a-row :gutter="16">
              <a-col :span="24"
                     :md="8">
                <a-form-item name="countryId">
                  <a-select v-model:value="modalData.logisticsItem.countryId"
                            :placeholder="$t('common.country')"
                            allow-clear
                            @change="countryChange"
                            :loading="addressData.countryLoading"
                            :showSearch="true"
                            optionFilterProp="search-key"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                    <a-select-option v-for="item in addressData.countries"
                                     :key="item.id"
                                     :value="item.id"
                                     :search-key="item.ioS2 + item.cnName + item.enName"
                                     :title="`${getLanguageName(item)}(${item.ioS2})`">{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option>
                  </a-select>
                  <small class="text-warning">注意:变更国家后,费用配置的币种也将被更新</small>
                </a-form-item>
              </a-col>
              <a-col :span="24"
                     :md="8">
                <a-form-item name="provinceId">
                  <a-select v-model:value="modalData.logisticsItem.provinceId"
                            :placeholder="$t('common.province')"
                            allow-clear
                            @change="provinceChange"
                            :loading="addressData.provinceLoading"
                            :showSearch="true"
                            optionFilterProp="search-key"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                    <a-select-option v-for="item in addressData.provinces"
                                     :key="item.id"
                                     :search-key="item.abbrCode + item.cnName + item.enName"
                                     :title="getLanguageName(item, 'province')">{{ getLanguageName(item, 'province') }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24"
                     :md="8">
                <a-form-item name="cityId">
                  <a-select :placeholder="$t('common.city')"
                            v-model:value="modalData.logisticsItem.cityId"
                            allow-clear
                            :loading="addressData.cityLoading"
                            :showSearch="true"
                            optionFilterProp="search-key"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                    <a-select-option v-for="item in addressData.cities"
                                     :key="item.id"
                                     :search-key="item.cnName + item.enName"
                                     :title="getLanguageName(item)">{{ getLanguageName(item) }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item :label-col="{ span: 6, lg: { span: 3 } }"
                       :label="'物流中心'"
                       name="warehouseIds">
            <a-select v-model:value="modalData.logisticsItem.warehouseIds"
                      mode="multiple"
                      placeholder="物流中心"
                      optionFilterProp="searchKey"
                      allow-clear
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option v-for="item in modalData.logisticsList"
                               :key="item.id"
                               :searchKey="item.warehouseNo + item.warehouseName">{{ item.warehouseName }} ({{
                  item.warehouseNo
                }})</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="{ span: 6, lg: { span: 3 } }"
                       :label="'特殊区域'"
                       name="specialRegionIds">
            <a-select v-model:value="modalData.logisticsItem.specialRegionIds"
                      mode="multiple"
                      placeholder="特殊区域"
                      optionFilterProp="searchKey"
                      allow-clear
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option v-for="item in modalData.specialRegions"
                               :key="item.value"
                               :searchKey="item.text">{{ item.text }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-row>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="'物流类型'"
                           name="logisticsType">
                <a-select v-model:value="modalData.logisticsItem.logisticsType"
                          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                  <a-select-option v-for="item in logisticsWayEnum"
                                   :key="item">{{
                      $t($enumLangkey("logisticsWay", item))
                    }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="'派送方式'"
                           name="deliveryType"
                           :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                <a-select v-model:value="modalData.logisticsItem.deliveryType">
                  <a-select-option v-for="item in deliveryWayEnum"
                                   :key="item">{{
                      $t($enumLangkey("deliveryWay", item))
                    }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="$t('logistics.fee_algorithm')"
                           name="feeCalculationMethod">
                <a-radio-group v-model:value="modalData.logisticsItem.feeCalculationMethod"
                               button-style="solid"
                               size="small">
                  <a-radio-button
                    v-for="(item, index) in feeCalculationMethodEnum"
                    :key="index"
                    :value="item"
                  >
                    <small>{{ $t($enumLangkey("feeCalculationMethod", item)) }}</small>
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="24" :lg="12" v-if="modalData.logisticsItem.feeCalculationMethod === feeCalculationMethodEnum.truckApi">
              <a-form-item :label="$t('logistics.interface_account')"
                           name="apiAccountId"
                           :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                <a-select
                  v-model:value="modalData.logisticsItem.apiAccountId"
                  :show-search="true"
                  option-filter-prop="search-key"
                >
                  <a-select-option
                    v-for="(item, index) in modalData.settingIdList"
                    :key="index"
                    :value="item.value"
                    :title="item.text"
                    :search-key="item.text"
                  >{{ item.text }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item :label-col="{ span: 6, lg: { span: 3 } }"
                       :label="$t('logistics.transport_characteristics')"
                       name="transportCharacteristicsList">
            <a-checkbox-group v-model:value="
                modalData.logisticsItem.transportCharacteristicsList
              ">
              <a-checkbox v-for="item in transportCharacteristicsEnum"
                          :key="item"
                          :value="item">{{
                  $t($enumLangkey("transportCharacteristics", item))
                }}</a-checkbox>
            </a-checkbox-group>
          </a-form-item>

          <a-row>
            <a-col :span="24"
                   :lg="12">

              <a-form-item :label="'计费方式'"
                           name="calcWithSingle">
                <!-- <a-radio-group v-model:value="modalData.logisticsItem.calcWithSingle"
                               button-style="solid"
                               size="small">
                  <a-radio-button :value="false">
                    <small>整体计费</small>
                  </a-radio-button>
                  <a-radio-button :value="true">
                    <small>单件计费</small>
                  </a-radio-button>
                </a-radio-group> -->
                <a-radio-group v-model:value="modalData.logisticsItem.calcLogicType"
                               button-style="solid"
                               size="small">
                  <a-radio-button :value="calcLogicTypeEnum.single">                    
                    <a-popover>
                      <template #content>
                        <p><small>计算单件计费重量/体积，并匹配模板，然后累加费用</small></p>
                      </template>
                      <small>单件计费</small>
                    </a-popover>
                  </a-radio-button>
                  <a-radio-button :value="calcLogicTypeEnum.overallAccumulatedFeeWeight">
                    <a-popover>
                      <template #content>
                        <p><small>先计算单件计费重量/体积，然后累加重量/体积，并匹配模板</small></p>
                      </template>
                      <small>整体重量累加</small>
                    </a-popover>
                  </a-radio-button>
                  <a-radio-button :value="calcLogicTypeEnum.overallApportionFeeWeight">
                    <a-popover>
                      <template #content>
                        <p><small>先计算总体实际重量/体积，通过总实际重量/体积计算计费重量/体积，再分摊到每件货物</small></p>
                      </template>
                      <small>整体重量分摊</small>
                    </a-popover>
                  </a-radio-button>
                </a-radio-group>

              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :lg="12">
              <a-form-item :label="'支持订单'"
                           name="onlySingleSupported">
                <a-radio-group v-model:value="modalData.logisticsItem.onlySingleSupported"
                               button-style="solid"
                               size="small">
                  <a-radio-button :value="false">
                    <small>一票多件</small>
                  </a-radio-button>
                  <a-radio-button :value="true">
                    <small>一票单件</small>
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24"
                   :lg="12">

              <a-form-item :label="'计算方式'"
                           name="logisticsCalcMethod">
                <a-radio-group v-model:value="modalData.logisticsItem.logisticsCalcMethod"
                               button-style="solid"
                               size="small">
                  <a-radio-button :value="logisticsCalcMethodEnum.weight">
                    <small>重量</small>
                  </a-radio-button>
                  <a-radio-button :value="logisticsCalcMethodEnum.volume">
                    <small>体积</small>
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :lg="8"
                   v-if="modalData.logisticsItem.logisticsCalcMethod == logisticsCalcMethodEnum.volume">
              <a-form-item name="maxWeightPreCubicMeter">
                <a-input-group>
                  <span class="ant-input-group-addon">每方最大</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.maxWeightPreCubicMeter"
                                  placeholder="填0表示不限制"></a-input-number>
                  <span class="ant-input-group-addon">kg</span>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item :label-col="{ span: 6, lg: { span: 3 } }"
                       :label="'标签'"
                       name="descriptionItems">
            <span v-for="(item, index) in modalData.logisticsItem.descriptionItems"
                  :key="item + index">
              <a-tag closable
                     @close.prevent="handleDescriptionItemClose(item)"
                     class="mt-1 mb-1">
                {{ item }}
              </a-tag>
            </span>
            <a-input v-if="modalData.tagInputVisible"
                     ref="modalDescTagInputRef"
                     type="text"
                     size="small"
                     :style="{ width: '80px' }"
                     class="mt-1 mb-1"
                     v-model:value="modalData.tagInputValue"
                     @blur="handleDescriptionItemConfirm"
                     @keyup.enter="handleDescriptionItemConfirm" />
            <a-tag v-else
                   @click="handleShowDescriptionItemInput"
                   style="background: #fff; border-style: dashed"
                   class="mt-1 mb-1">
              <plus-outlined />
              添加
            </a-tag>
          </a-form-item>
          <a-row :gutter="16">
            <a-col :span="24"
                   :md="8">
              <a-form-item name="discount">
                <a-input-group>
                  <span class="ant-input-group-addon">折扣比例</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.discount"></a-input-number>
                  <span class="ant-input-group-addon">%</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="fuelSurcharge">
                <a-input-group>
                  <span class="ant-input-group-addon">燃油附加费</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.fuelSurcharge"></a-input-number>
                  <span class="ant-input-group-addon">%</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="commissionRate">
                <a-input-group>
                  <span class="ant-input-group-addon">佣金比例</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.commissionRate"></a-input-number>
                  <span class="ant-input-group-addon">%</span>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24"
                   :md="8">
              <a-form-item name="singleMinDistributionWeight">
                <a-input-group>
                  <span class="ant-input-group-addon">单件最低分泡重</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="
                      modalData.logisticsItem.singleMinDistributionWeight
                    "></a-input-number>
                  <span class="ant-input-group-addon">kg</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="volumeWeightParam">
                <a-input-group>
                  <span class="ant-input-group-addon">体积重系数</span>
                  <a-input-number class="input-group-end-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.volumeWeightParam"></a-input-number>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="distributionRatio">
                <a-input-group>
                  <span class="ant-input-group-addon">分泡比例</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.distributionRatio"></a-input-number>
                  <span class="ant-input-group-addon">%</span>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <a-row>
              <a-col :span="24"
                     :md="16">
                <a-card size="small">
                  <a-row>
                    <a-col :span="24"
                           :md="12">
                      <a-form-item name="singleCeiling"
                                   class="mb-0">
                        <label>
                          <span class="mr-1 pt-1">单件取整进位</span>
                          <a-switch @change="handleSingleCeilingChange"
                                    v-model:checked="
                              modalData.logisticsItem.singleCeiling
                            "></a-switch>
                        </label>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24"
                           :md="12">
                      <a-form-item name="singleCeilingWeightStep"
                                   class="mb-0">
                        <a-input-group>
                          <span class="ant-input-group-addon">步长进位</span>
                          <a-input-number class="input-group-mid-number"
                                          type="number"
                                          :min="0"
                                          v-model:value="
                              modalData.logisticsItem.singleCeilingWeightStep
                            "></a-input-number>
                          <span class="ant-input-group-addon">kg</span>
                        </a-input-group>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-card>
              </a-col>
            </a-row>
          </a-form-item>
          <a-row :gutter="16">
            <a-col :span="24"
                   :md="8">
              <a-form-item name="minWeight">
                <a-input-group>
                  <span class="ant-input-group-addon">最小重量</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.minWeight"></a-input-number>
                  <span class="ant-input-group-addon">kg</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="maxWeight">
                <a-input-group>
                  <span class="ant-input-group-addon">最大重量</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.maxWeight"></a-input-number>
                  <span class="ant-input-group-addon">kg</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="maxSingleWeight">
                <a-input-group>
                  <span class="ant-input-group-addon">单件最大重量</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.maxSingleWeight"
                                  placeholder="填0表示不限制"></a-input-number>
                  <span class="ant-input-group-addon">kg</span>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24"
                   :md="8">
              <a-form-item name="minVolume">
                <a-input-group>
                  <span class="ant-input-group-addon">最小体积</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.minVolume"></a-input-number>
                  <span class="ant-input-group-addon">
                    m
                    <sup>3</sup>
                  </span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="maxVolume">
                <a-input-group>
                  <span class="ant-input-group-addon">最大体积</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.maxVolume"></a-input-number>
                  <span class="ant-input-group-addon">
                    m
                    <sup>3</sup>
                  </span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="maxSingleVolume">
                <a-input-group>
                  <span class="ant-input-group-addon">最大单件体积</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.maxSingleVolume"
                                  placeholder="填0表示不限制"></a-input-number>
                  <span class="ant-input-group-addon">
                    m
                    <sup>3</sup>
                  </span>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24"
                   :md="8">
              <a-form-item name="maxLongestSideLength">
                <a-input-group>
                  <span class="ant-input-group-addon">最长边不超过</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.maxLongestSideLength"
                                  placeholder="填0表示不限制"></a-input-number>
                  <span class="ant-input-group-addon">cm</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="maxGirthSideLength">
                <a-input-group>
                  <span class="ant-input-group-addon">围长不超过</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.maxGirthSideLength"
                                  placeholder="填0表示不限制"></a-input-number>
                  <span class="ant-input-group-addon">cm</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="minimumCharge">
                <a-input-group>
                  <span class="ant-input-group-addon">
                    单票最低消费
                    <template v-if="selectedCountryInfo">{{ selectedCountryInfo.currencyCode }} {{ selectedCountryInfo.currencySymbol }}</template>
                  </span>
                  <a-input-number class="input-group-end-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="modalData.logisticsItem.minimumCharge"
                                  placeholder="填0表示不限制"></a-input-number>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24"
                   :md="8">
              <a-form-item name="orderMinChargeableWeight">
                <a-input-group>
                  <span class="ant-input-group-addon">单票最小计费重</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="
                      modalData.logisticsItem.orderMinChargeableWeight
                    "></a-input-number>
                  <span class="ant-input-group-addon">kg</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="orderMinChargeableWeightEnabled">
                <label>
                  <span class="mr-1 pt-1">支持低于单票最小计费重</span>
                  <a-switch v-model:checked="
                      modalData.logisticsItem.orderMinChargeableWeightEnabled
                    "></a-switch>
                </label>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24"
                   :md="8">
              <a-form-item name="singleMinChargeableWeight">
                <a-input-group>
                  <span class="ant-input-group-addon">单件最小计费重</span>
                  <a-input-number class="input-group-mid-number"
                                  type="number"
                                  :min="0"
                                  v-model:value="
                      modalData.logisticsItem.singleMinChargeableWeight
                    "></a-input-number>
                  <span class="ant-input-group-addon">kg</span>
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col :span="24"
                   :md="8">
              <a-form-item name="singleMinChargeableWeightEnabled">
                <label>
                  <span class="mr-1 pt-1">支持低于单件最小计费重</span>
                  <a-switch v-model:checked="
                      modalData.logisticsItem.singleMinChargeableWeightEnabled
                    "></a-switch>
                </label>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24" :md="8">
              <a-form-item name="sortNum">
                <a-input-group>
                  <span class="ant-input-group-addon">{{ $t('common.sort') }}</span>
                  <a-input-number
                    class="input-group-end-number"
                    v-model:value="modalData.logisticsItem.sortNum"
                    :default-value="0"
                    :max="999999999"
                    :precision="0"
                    :placeholder="$t('common.sort_warehouse_tips')"
                  ></a-input-number>
                </a-input-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24" :md="8">
              <a-form-item :label="$t('common.enable_status')" name="isActive" :label-col="{ style: { width: 'auto'} }">
                <a-switch v-model:checked="modalData.logisticsItem.isActive"></a-switch>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
      <AccountingPeriodConfigurationModal v-model:visible="accountingPeriodState.visible" :info="accountingPeriodState.info" @confirm="handleSearch" />

      <a-modal 
        v-model:visible="copyModal.visible" 
        :confirmLoading="copyModal.copyLoading"
        title="复制"
        @ok="handleCopyChannel">
          <p>您确定复制该物流方案吗?</p>
        </a-modal>
      </template>
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data="tableData.pageData"></CPager>
    </template>
  </Content>
</template>

<script>
import {
  Table,
  Button,
  Modal,
  Input,
  Select,
  Card,
  Row,
  Col,
  Form,
  Radio,
  Tabs,
  Switch,
  InputNumber,
  Checkbox,
  Tag,
  Dropdown,
  Menu,
  Spin,
  Popover,
} from "ant-design-vue";
import Content from "../components/Content.vue";
import CPager from "../components/CPager.vue";
import AccountingPeriodConfigurationModal from './components/AccountingPeriodConfigurationModal.vue';
import {
  getCountriesParams,
  getProvinces,
  getCitys,
  getCountys,
  getWarehouses,
} from "../../api/modules/common/index";
import {
  getListPaged,
  create,
  update,
  updateStatus,
  copy,
} from "../../api/modules/logistics/index";
import { getAllList as getSpecialRegions } from "../../api/modules/logistics/specialregion";
import { getCalFeeChooseSetting } from "../../api/modules/logistics/addressapi";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import {
  deliveryWay as deliveryWayEnum,
  transportCharacteristics as transportCharacteristicsEnum,
  logisticsWay as logisticsWayEnum,
  channelTimeType as channelTimeTypeEnum,
  warehouseType as warehouseTypeEnum,
  logisticsCalcMethod as logisticsCalcMethodEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  calcLogicType as calcLogicTypeEnum,
} from "../../enum/enum.json";
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  ref,
  nextTick,
} from "vue";
import { getName, gToKg, kgToG, cmCubicToM, mCubicToCm, roundNumber } from "@/utils/general.js";
import { refInputElemFocus } from "@/utils/domoperate.js";

export default defineComponent({
  name: "logistics_channel",
  components: {
    ATable: Table,
    AButton: Button,
    AModal: Modal,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    Content,
    CPager,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    ARadio: Radio,
    ARadioOption: Radio.Option,
    ARadioButton: Radio.Button,
    ARadioGroup: Radio.Group,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ATag: Tag,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpin: Spin,
    AccountingPeriodConfigurationModal,
    APopover: Popover,
  },
  setup () {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        width: 60,
        fixed: "left",
        title: () => vueI18n.t('common.serial_number'),
        slots: {
          customRender: "tableIndex",
        },
      },
      {
        dataIndex: "name",
        width: 150,
        title: () => vueI18n.t('logistics.logistics_scheme_name'),
        slots: {
          customRender: "logisticsName",
        },
      },
      {
        dataIndex: "code",
        width: 150,
        title: () => vueI18n.t('finance.channel_code'),
        slots: {
          customRender: "logisticsCode",
        },
      },
      {
        width: 120,
        title: () => "起运地",
        slots: {
          customRender: "addressInfo",
        },
      },
      {
        width: 200,
        title: () => "物流中心",
        slots: {
          customRender: "warehouses",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t('logistics.transport_characteristics'),
        slots: {
          customRender: "characteristics",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t('logistics.accounting_period_configuration'),
        slots: {
          customRender: "accountingPeriodConfig",
        },
      },
      {
        width: 150,
        title: () => "支持重量/体积段",
        slots: {
          customRender: "sizeRange",
        },
      },
      {
        dataIndex: "volumeWeightParam",
        width: 150,
        title: () => "体积重系数",
        slots: {
          customRender: "volumeWeightParam",
        },
      },
      {
        dataIndex: "fuelSurcharge",
        width: 150,
        title: () => "燃油附加费",
        slots: {
          customRender: "fuelSurcharge",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t('finance.status'),
        slots: {
          customRender: "isActive",
        },
      },
      {
        width: 120,
        fixed: "right",
        align: "center",
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: "operation",
        },
      },
    ];
    const refEditLogisticsForm = ref();

    const tableData = reactive({
      tableList: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const getDefaultItem = () => {
      return {
        id: 0,
        name: null,
        code: null,
        isInternationalTransport: false,
        channelTimeType: channelTimeTypeEnum.signed,
        daysMin: null,
        daysMax: null,
        declareSupported: false,
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
        isSupportPayOnArrival: false,
        warehouseIds: [],
        specialRegionIds: [],
        specialRegionList: [],
        logisticsType: null,
        deliveryType: null,
        transportCharacteristicsList: [],
        discount: null,
        fuelSurcharge: null,
        singleMinDistributionWeight: null,
        volumeWeightParam: null,
        distributionRatio: null,
        singleCeiling: false,
        singleCeilingWeightStep: null,
        minWeight: null,
        maxWeight: null,
        minVolume: null,
        maxVolume: null,
        orderMinChargeableWeight: null,
        orderMinChargeableWeightEnabled: false,
        singleMinChargeableWeight: null,
        singleMinChargeableWeightEnabled: false,
        descriptionItems: [],
        calcWithSingle: false,
        sortNum: 0,
        isActive: false,
        onlySingleSupported: false,
        logisticsCalcMethod: logisticsCalcMethodEnum.weight,
        maxWeightPreCubicMeter: 0,
        maxSingleWeight: 0,
        maxSingleVolume: 0,
        maxLongestSideLength: 0,
        maxGirthSideLength: 0,
        minimumCharge: 0,
        feeCalculationMethod: 0,
        apiAccountId: null,
      };
    };

    const modalData = reactive({
      visible: false,
      loading: false,
      logisticsLoading: false,
      logisticsList: [],
      specialRegionsLoading: false,
      specialRegions: [],
      logisticsItem: getDefaultItem(),
      tagInputValue: "",
      tagInputVisible: false,
      settingIdList: [],
    });
    const modalDescTagInputRef = ref();

    const addressData = reactive({
      countryLoading: false,
      countries: [],
      provinceLoading: false,
      provinces: [],
      cityLoading: false,
      cities: [],
    });

    const addressCache = {
      provinces: {},
      cities: {},
    };

    const state = reactive({
      selectedCountryInfo: null,
      searchState: {
        name: null,
        code: null,
        countryId: null,
        isActive: null,
      },
      searchStateCache: {},
    })

    const accountingPeriodState = reactive({
      visible: false,
      info: null,
    })

    const getLanguageName = (item, other = '') => {
      if (other === 'province') {
        return `${getName(item, getters.language)}(${item.abbrCode})`
      }
      return getName(item, getters.language);
    };

    const getAddressInfo = (logisticsAddress = {}) => {
      let arr = [];
      if (logisticsAddress.countryId) {
        arr.push(
          getLanguageName({
            cnName: logisticsAddress.countryCnName,
            enName: logisticsAddress.countryEnName,
          })
        );
      }
      return arr.join(", ");
    };

    const getLogisticsList = () => {
      tableData.loading = true;
      const searchData = Object.assign({}, state.searchStateCache, tableData.pageData)
      searchData.isActive = searchData.isActive === 1 ? true : searchData.isActive === 0 ? false : null;
      getListPaged(searchData)
        .then((res) => {
          tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          tableData.pageData.totalCount = parseInt(totalCount);
          tableData.tableList = items;
        })
        .catch(() => {
          tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      tableData.pageData.skipCount = 0;
      tableData.pageData.currentIndex = 1;
      getLogisticsList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState)
      handleInitPage();
    };

    const handlePage = (pageData) => {
      tableData.pageData.skipCount = pageData.skipCount;
      tableData.pageData.maxResultCount = pageData.maxResultCount;
      getLogisticsList();
    };

    const handleIsActiveChanged = (record) => {
      record.statusLoading = true;
      updateStatus({ id: record.id, isActive: record.isActive })
        .then(() => {
          record.statusLoading = false;
        })
        .catch(() => {
          record.isActive = !record.isActive;
          record.statusLoading = false;
        });
    };

     //---------- 复制确认Modal  start------------------------------
    const copyModal = reactive({
      visible:false,
      loading:false
    })

    const handleShowCopyModal = (record) => {
      copyModal.record = record;
      copyModal.visible = true;
    };

     // 复制
    const handleCopyChannel = () => {
      copyModal.copyLoading = true;
      copy({ id: copyModal.record.id })
        .then(() => {
          copyModal.copyLoading = false;
          copyModal.visible = false;
          handleInitPage();
        })
        .catch(() => {
          record.copyLoading = false;
        })
    };

    //---------- 复制确认Modal  end------------------------------



   

    // 设置账期
    const handleSetAccountingPeriod = (record) => {
      accountingPeriodState.visible = true;
      accountingPeriodState.info = {
        logisticsId: record.id,
        name: record.name,
        code: record.code,
      };
    }

    const getCountryList = () => {
      addressData.countryLoading = true;
      return getCountriesParams()
        .then(({ result }) => {
          addressData.countries = result;
        })
        .finally(() => {
          addressData.countryLoading = false;
        });
    }

    const beforeModalOpen = () => {
      if (addressData.countries.length == 0) {
        getCountryList().then(() => {}).finally(() => {
          countryChange();
        })
      } else {
        countryChange();
      }
      if (modalData.logisticsList.length == 0) {
        modalData.logisticsLoading = true;
        getWarehouses({ warehouseType: warehouseTypeEnum.logisticsCenter })
          .then((res) => {
            let { result } = res;
            modalData.logisticsList = result;
            modalData.logisticsLoading = false;
          })
          .catch(() => {
            modalData.logisticsLoading = false;
          });
      }
      if (modalData.specialRegions.length == 0) {
        modalData.specialRegionsLoading = true;
        getSpecialRegions()
          .then((res) => {
            let { result } = res;
            modalData.specialRegions = result;
            modalData.specialRegionsLoading = false;
          })
          .catch(() => {
            modalData.specialRegionsLoading = false;
          });
      }
      if (
        refEditLogisticsForm.value &&
        refEditLogisticsForm.value.clearValidate
      ) {
        refEditLogisticsForm.value.clearValidate();
      }
    };

    const handleOpenCreateModal = () => {
      if (modalData.logisticsItem.id) {
        modalData.logisticsItem = getDefaultItem();
      }
      beforeModalOpen();
      modalData.visible = true;
    };

    const fixedGToKg = (g) => {
      return g == null ? null : gToKg(g);
    };

    const fixedCmCubicToM = (vol) => {
      return vol == null ? null : cmCubicToM(vol);
    };

    const handleOpenEditModal = (record) => {
      let item = {};
      // Object.assign(modalData.logisticsItem, record);
      Object.assign(item, record);

      item.countryId = (record.logisticsAddress || {}).countryId;
      item.provinceId = (record.logisticsAddress || {}).provinceId;
      item.cityId = (record.logisticsAddress || {}).cityId;
      if (record.logisticsWarehouses && record.logisticsWarehouses.length > 0) {
        item.warehouseIds = record.logisticsWarehouses.map(
          (x) => x.warehouseId
        );
      } else {
        item.warehouseIds = [];
      }
      if (record.specialRegionList && record.specialRegionList.length > 0) {
        item.specialRegionIds = record.specialRegionList.map((x) => x.id);
      } else {
        item.specialRegionIds = [];
      }
      item.maxWeightPreCubicMeter = fixedGToKg(record.maxWeightPreCubicMeter);
      item.singleMinDistributionWeight = fixedGToKg(
        record.singleMinDistributionWeight
      );
      item.singleCeilingWeightStep = fixedGToKg(record.singleCeilingWeightStep);
      item.minWeight = fixedGToKg(record.minWeight);
      item.maxWeight = fixedGToKg(record.maxWeight);
      item.maxSingleWeight = fixedGToKg(record.maxSingleWeight);
      item.orderMinChargeableWeight = fixedGToKg(
        record.orderMinChargeableWeight
      );
      item.singleMinChargeableWeight = fixedGToKg(
        record.singleMinChargeableWeight
      );

      item.minVolume = fixedCmCubicToM(record.minVolume);
      item.maxVolume = fixedCmCubicToM(record.maxVolume);
      item.maxSingleVolume = fixedCmCubicToM(record.maxSingleVolume);
      item.isSupportPayOnArrival = record.isSupportPayOnArrival;

      modalData.logisticsItem = item;
      beforeModalOpen();
      modalData.visible = true;
    };

    const handleSingleCeilingChange = () => {
      if (refEditLogisticsForm.value && refEditLogisticsForm.value.validate) {
        refEditLogisticsForm.value.validate("singleCeilingWeightStep");
      }
    };

    const handleShowDescriptionItemInput = () => {
      modalData.tagInputVisible = true;
      nextTick(() => {
        if (modalDescTagInputRef.value) {
          refInputElemFocus(modalDescTagInputRef, false);
        }
      });
    };

    const handleDescriptionItemConfirm = () => {
      const inputValue = modalData.tagInputValue;
      let tags = modalData.logisticsItem.descriptionItems;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      modalData.logisticsItem.descriptionItems = tags;
      modalData.tagInputVisible = false;
      modalData.tagInputValue = "";
    };

    const handleDescriptionItemClose = (tag) => {
      modalData.logisticsItem.descriptionItems =
        modalData.logisticsItem.descriptionItems.filter((x) => x != tag);
    };

    const handleLogisticsSave = () => {
      refEditLogisticsForm.value
        .validate()
        .then(async () => {
          modalData.loading = true;
          var data = {};
          Object.assign(data, modalData.logisticsItem);
          data.maxWeightPreCubicMeter = kgToG(data.maxWeightPreCubicMeter);
          data.singleMinDistributionWeight = kgToG(
            data.singleMinDistributionWeight
          );
          data.singleCeilingWeightStep = kgToG(data.singleCeilingWeightStep);
          data.minWeight = kgToG(data.minWeight);
          data.maxWeight = kgToG(data.maxWeight);
          data.maxSingleWeight = kgToG(data.maxSingleWeight);
          data.orderMinChargeableWeight = kgToG(data.orderMinChargeableWeight);
          data.singleMinChargeableWeight = kgToG(
            data.singleMinChargeableWeight
          );

          data.minVolume = mCubicToCm(data.minVolume);
          data.maxVolume = mCubicToCm(data.maxVolume);
          data.maxSingleVolume = mCubicToCm(data.maxSingleVolume);
          data.minimumCharge = roundNumber(data.minimumCharge || 0);

          if (data.feeCalculationMethod === feeCalculationMethodEnum.template) {
            data.apiAccountId = null
          }
          return data.id ? update(data) : create(data);
        })
        .then(() => {
          modalData.visible = false;
          modalData.loading = false;
          getLogisticsList();
        })
        .catch(() => {
          modalData.loading = false;
        });
    };

    const countryChange = () => {
      state.selectedCountryInfo = addressData.countries.find(item => item.id === modalData.logisticsItem.countryId)

      if (refEditLogisticsForm.value && refEditLogisticsForm.value.validate) {
        refEditLogisticsForm.value.validate("warehouseIds");
      }
      addressData.provinceLoading = true;
      getProvinceList()
        .then((res) => {
          addressData.provinces = res;
          // if (addressData.provinces.length > 0) {
          //     proviceId = addressData.provinces[0].id;
          // }
        })
        .catch((err) => {
          addressData.provinces = [];
          // console.error("load province error");
          throw err;
        })
        .finally(() => {
          addressData.provinceLoading = false;
          if (
            addressData.provinces.findIndex(
              (x) => x.id == modalData.logisticsItem.provinceId
            ) >= 0
          ) {
            //keep it
          } else {
            modalData.logisticsItem.provinceId = null;
          }
          provinceChange();
        });
    };

    const provinceChange = () => {
      // let cityId = null;
      addressData.cityLoading = true;
      getCityList()
        .then((res) => {
          addressData.cities = res;
          // if (addressData.cities.length > 0) {
          //     cityId = addressData.cities[0].id;
          // }
        })
        .catch((err) => {
          addressData.cities = [];
          //console.error("load cities error");
          throw err;
        })
        .finally(() => {
          addressData.cityLoading = false;
          if (
            addressData.cities.findIndex(
              (x) => x.id == modalData.logisticsItem.cityId
            ) >= 0
          ) {
            //keep it
          } else {
            modalData.logisticsItem.cityId = null;
          }
        });
    };

    const getProvinceList = async () => {
      if (modalData.logisticsItem.countryId) {
        var cacheKey = modalData.logisticsItem.countryId + "";
        if (addressCache.provinces[cacheKey]) {
          return addressCache.provinces[cacheKey];
        } else {
          let { result } = await getProvinces({
            countryId: modalData.logisticsItem.countryId,
          });
          addressCache.provinces[cacheKey] = result;
          return result;
        }
      } else {
        return [];
      }
    };

    const getCityList = async () => {
      if (modalData.logisticsItem.provinceId) {
        var cacheKey = modalData.logisticsItem.provinceId + "";
        if (addressCache.cities[cacheKey]) {
          return addressCache.cities[cacheKey];
        } else {
          let { result } = await getCitys({
            provinceId: modalData.logisticsItem.provinceId,
          });
          addressCache.cities[cacheKey] = result;
          return result;
        }
      } else {
        return [];
      }
    };

    const logisticsRules = {
      name: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("名称")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      code: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("代码")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      countryId: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("common.country"),
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      warehouseIds: [
        {
          validator: (rule, value) => {
            if (value && value.length > 0) {
              var differentCountryList = modalData.logisticsList.filter((x) => {
                return (
                  value.findIndex((v) => v == x.id) >= 0 &&
                  x.countryId != modalData.logisticsItem.countryId
                );
              });
              if (differentCountryList.length > 0) {
                return Promise.reject("物流中心与物流方案需要归属于同一个国家");
              }
            }
            return Promise.resolve();
          },
          trigger: ["blur", "change"],
        },
      ],
      logisticsType: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("物流类型")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      deliveryType: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("派送方式")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      transportCharacteristicsList: [
        {
          required: true,
          validator: async (rule, value) => {
            if (value && value.length > 0) {
              return Promise.resolve();
            }
            return Promise.reject();
          },
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.transport_characteristics")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      discount: [
        {
          type: "number",
          min: 0,
          max: 1000,
          message: () => {
            return vueI18n.t("{0}应该在{1}至{2}间", [
              vueI18n.t("折扣比例"),
              0,
              1000,
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      fuelSurcharge: [
        {
          type: "number",
          min: 0,
          max: 1000,
          message: () => {
            return vueI18n.t("{0}应该在{1}至{2}间", [
              vueI18n.t("燃油附加费"),
              0,
              1000,
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      commissionRate: [
        {
          type: "number",
          min: 0,
          max: 1000,
          message: () => {
            return vueI18n.t("{0}应该在{1}至{2}间", [
              vueI18n.t("佣金比例"),
              0,
              1000,
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      singleMinDistributionWeight: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("单件最低分泡重"),
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      volumeWeightParam: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("体积重系数"),
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      distributionRatio: [
        {
          type: "number",
          min: 0,
          max: 1000,
          message: () => {
            return vueI18n.t("{0}应该在{1}至{2}间", [
              vueI18n.t("分泡比例"),
              0,
              1000,
            ]);
          },
          trigger: ["blur", "change"],
        },
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("分泡比例")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      singleCeilingWeightStep: [
        {
          type: "number",
          validator: async (rule, value) => {
            // if (modalData.logisticsItem.singleCeiling) {
            //     if (value > 0) {
            //         return Promise.resolve();
            //     } else {
            //         return Promise.reject();
            //     }
            // }
            if (
              value > 0 ||
              (!modalData.logisticsItem.singleCeiling && value == 0)
            ) {
              return Promise.resolve();
            } else {
              return Promise.reject();
            }
          },
          message: () => {
            return vueI18n.t("{0}必须大于0", [vueI18n.t("步长进位")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      minWeight: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("最小重量")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      maxWeight: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("最大重量")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      minVolume: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("最小体积")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      maxVolume: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("最大体积")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      orderMinChargeableWeight: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("单票最小计费重"),
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      singleMinChargeableWeight: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("单件最小计费重"),
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      feeCalculationMethod: [
        {
          type: "number",
          required: true,
          message: () => vueI18n.t("common.p0_is_required", [ vueI18n.t("logistics.fee_algorithm") ]),
          trigger: ["blur", "change"],
        },
      ],
      apiAccountId: [
        {
          type: "number",
          validator: async (rule, value) => {
            if (modalData.logisticsItem.feeCalculationMethod === feeCalculationMethodEnum.truckApi) {
              if (value === null) {
                return Promise.reject();
              }
              return Promise.resolve();
            } else {
              return Promise.resolve();
            }
          },
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.interface_account")]);
          },
          trigger: ["blur", "change"],
        },
      ],
    };

    // onActivated(()=>{
    //     modalData.logisticsList=[];
    //     modalData.specialRegions=[];
    // });

    onMounted(() => {
      getCountryList();
      handleInitPage();

      getCalFeeChooseSetting().then(({ result }) => {
        if (Array.isArray(result)) {
          modalData.settingIdList = result
        }
      }).catch(() => {})
    });

    return {
      deliveryWayEnum,
      transportCharacteristicsEnum,
      logisticsWayEnum,
      channelTimeTypeEnum,
      logisticsCalcMethodEnum,
      feeCalculationMethodEnum,
      calcLogicTypeEnum,

      columns,
      refEditLogisticsForm,
      tableData,
      modalData,
      addressData,
      ...toRefs(state),
      accountingPeriodState,
      logisticsRules,
      modalDescTagInputRef,

      handleSearch,
      handlePage,
      handleInitPage,
      getAddressInfo,
      handleIsActiveChanged,
      handleCopyChannel,
      handleSetAccountingPeriod,
      handleOpenCreateModal,
      handleOpenEditModal,
      handleSingleCeilingChange,
      handleLogisticsSave,
      getLanguageName,
      countryChange,
      provinceChange,
      handleShowDescriptionItemInput,
      handleDescriptionItemConfirm,
      handleDescriptionItemClose,

      gToKg,
      cmCubicToM,

      copyModal,
      handleShowCopyModal,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
:deep(.input-group-mid-number) {
  border-radius: 0;
  width: 100%;
}
:deep(.input-group-end-number) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
:deep(.input-group-start-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
</style>

import client from "../../client"
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  getTrackList:"/api/WarehouseService/TransportPlanConsole/QueryTracks",
  // 获取物流方案列表
  getPagedList: "/api/WarehouseService/TransportPlanManager/GetPlanMonitorPaged",
  // 获取客户运输计划列表
  getSelfManagePlanPaged: "/api/WarehouseService/TransportPlanConsole/GetSelfManagePlanPaged",
  // 获取计划详细
  getById: "/api/WarehouseService/TransportPlanConsole/GetById",
  // 用户取消计划
  consoleCancel: "/api/WarehouseService/TransportPlanConsole/ConsoleCancel",
  // 结束揽收
  partialReceipt: "/api/WarehouseService/TransportPlanConsole/PartialReceipt",
  // 等到收货状态取消箱信息
  consoleCancelBox: "/api/WarehouseService/TransportPlanConsole/ConsoleCancelBox",
  // 获取物流方案列表
  transportPlanGetLogistic: "/api/WarehouseService/WarehouseLogistics/TransportPlanGetLogistic",
  // 获取物流方案详细
  getTransportPlanLogisticesFeeDetail: "/api/WarehouseService/TransportPlanConsole/GetTransportPlanLogisticesFeeDetail",
  // 选择物流方案获取费用详细
  costChangeTransportPlanLogisticesFee: "/api/WarehouseService/TransportPlanManager/CostChangeTransportPlanLogisticesFee",
  // 赔付
  loseMoneyTransport: "/api/WarehouseService/TransportPlanConsole/LoseMoney",
  // 获取卡车费用
  getTruckFee: "/api/WarehouseService/TransportPlanManager/GetTruckFee",
  // 派送中
  outForDelivery: "/api/WarehouseService/TransportPlanManager/OutForDelivery",
  // 签收
  signedTransportPlan: "/api/WarehouseService/TransportPlanManager/Signed",
  // 获取尾程派送详情
  getOutForDelivery: "/api/WarehouseService/TransportPlanManager/GetOutForDelivery",
  // 导出物流方案列表
  exportTransportPlan: "/api/WarehouseService/TransportPlanConsole/Export",
  // 导出物流方案列表(客户运输计划)
  exportSelfManage: "/api/WarehouseService/TransportPlanConsole/ExportSelfManage",
  // 导出装箱单
  exportPackingList: "/api/WarehouseService/TransportPlanManager/ExportBox",
  // 导出箱号列表
  exportPlanBoxes: "/api/WarehouseService/TransportPlanManager/ExportPlanBoxes",
  // 批量打印入仓单
  batchSeInWarehouseOrder: "/api/WarehouseService/TransportPlanManager/GetBatchSEInWarehourseOrderPdf/batchseinwarehourseorder.pdf",
  // 批量打印账单
  printTransportBill: "/api/WarehouseService/TransportPlanManager/GetTransportBillPdf/transportbill.pdf",
  // 导出账单
  exportTransportBill: "/api/WarehouseService/TransportPlanManager/ExportTransportBill",
  // 导出账单(财务)
  exportTransportBillOfFinance: "/api/WarehouseService/TransportPlanConsole/ExportTransportBill",
  //取消运输计划
  cancelPlan: "/api/WarehouseService/TransportPlanManager/CancelPlan",

}
export const cancelPlan = ({planId,pinCode }) => {
  return client.request({
    url: Api.cancelPlan,
    data: {planId,pinCode },
    method: "post",
  });
};

export const getTrackList = ({planId,warehouseId }) => {
  return client.request({
    url: Api.getTrackList,
    data: {planId,warehouseId },
    method: "post",
  });
};

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: 'POST'
  });
}

export const getSelfManagePlanPaged = (data) => {
  return client.request({
    url: Api.getSelfManagePlanPaged,
    data,
    method: 'POST'
  });
}

export const getById = (data) => {
  return client.request({
    url: Api.getById,
    data,
    method: 'POST'
  });
}

export const consoleCancel = (data) => {
  return client.request({
    url: Api.consoleCancel,
    data,
    method: 'POST'
  });
}

export const partialReceipt = (data) => {
  return client.request({
    url: Api.partialReceipt,
    data,
    method: 'POST'
  });
}

export const consoleCancelBox = (data) => {
  return client.request({
    url: Api.consoleCancelBox,
    data,
    method: 'POST'
  });
}

export const transportPlanGetLogistic = (data) => {
  return client.request({
    url: Api.transportPlanGetLogistic,
    data,
    method: 'POST'
  });
}

export const getTransportPlanLogisticesFeeDetail = (data) => {
  return client.request({
    url: Api.getTransportPlanLogisticesFeeDetail,
    data,
    method: 'POST'
  });
}

export const costChangeTransportPlanLogisticesFee = (data) => {
  return client.request({
    url: Api.costChangeTransportPlanLogisticesFee,
    data,
    method: 'POST'
  });
}

export const loseMoneyTransport = (data) => {
  return client.request({
    url: Api.loseMoneyTransport,
    data,
    method: 'POST'
  });
}

export const getTruckFee = (data) => {
  return client.request({
    url: Api.getTruckFee,
    data,
    method: "POST",
  });
};

export const outForDelivery = (data) => {
  return client.request({
    url: Api.outForDelivery,
    data,
    method: "POST",
  });
};

export const signedTransportPlan = (data) => {
  return client.request({
    url: Api.signedTransportPlan,
    data,
    method: "POST",
  });
};

export const getOutForDelivery = (params) => {
  return client.request({
    url: Api.getOutForDelivery,
    params,
    method: "GET",
  });
};

export const exportTransportPlan = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportTransportPlan);
  return url;
}

export const exportSelfManage = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportSelfManage);
  return url;
}

export const exportPackingList = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportPackingList);
  return url;
}

export const exportPlanBoxes = (data) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportPlanBoxes);
  url = urlHelper.addQueryString(url, "id", data.planId);
  return url;
}

export const batchSeInWarehouseOrder = (data) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.batchSeInWarehouseOrder
  );
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  return url;
}

export const printTransportBill = (data) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.printTransportBill
  );
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  return url;
}

export const exportTransportBill = (data) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportTransportBill);
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  return url;
}
export const exportTransportBillOfFinance = (data) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportTransportBillOfFinance);
  data.planIds.forEach(item => {
    url = urlHelper.addQueryString(url, "planIds", item);
  });
  url = urlHelper.addQueryString(url, "pinCode", data.pinCode);
  return url;
}
<template>
  <Content>
    <template v-slot:contentBody>
      <h1>{{ $t("menu.customer_account_details") }}</h1>
      <!-- 基本信息 -->
      <div>
        <a-row :gutter="[0, 16]" type="flex" align="middle">
          <a-col :xs="24" :sm="21" :md="19" :lg="17" :xxl="8">
            <a-row :gutter="[0, 16]">
              <a-col :span="24">
                <strong>{{ $t("account.essential_information") }}</strong>
              </a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("account.login_account")
              }}</a-col>
              <a-col :span="17" :offset="1">
                <span v-if="showEditControl">
                  <a-input-group compact>
                    <a-input
                      style="width: 150px;"
                      v-model:value="accountNumber"
                      size="small"
                      allow-clear
                    ></a-input>
                    <a-button size="small" @click="handleCancelAccount">{{ $t('common.cancel') }}</a-button>
                    <a-button size="small" @click="handleEditAccount">{{ $t('common.confirm') }}</a-button>
                  </a-input-group>
                </span>
                <span v-else>
                  {{ pageInfo.userName }}
                  <span v-if="showEdit" class="text-primary ml-3" style="cursor: pointer;" @click="showEditControl = true">{{ $t('account.amend') }}</span>
                </span>
              </a-col>
              <a-col :span="5" style="text-align: right">No</a-col>
              <a-col :span="17" :offset="1"
                >{{ pageInfo.userNo }}
              </a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("common.country")
              }}</a-col>
              <a-col :span="17" :offset="1"
                >{{
                  getLanguageName({
                    cnName: pageInfo.countryCnName,
                    enName: pageInfo.countryEnName,
                  })
                }}
              </a-col>
              <a-col :span="5" style="text-align: right">{{ $t("account.timezone") }}</a-col>
              <a-col :span="17" :offset="1">{{ pageInfo.timezone }}</a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("account.mobile_phone")
              }}</a-col>
              <a-col :span="17" :offset="1"
                >+{{ pageInfo.phoneCountryCode }} {{ pageInfo.phoneNumber }}</a-col
              >
              <a-col :span="5" style="text-align: right">{{
                $t("account.secure_mailbox")
              }}</a-col>
              <a-col :span="17" :offset="1">{{ pageInfo.mail || '-' }}</a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("warehouse.creation_time")
              }}</a-col>
              <a-col :span="17" :offset="1">{{
                $filters.utcToCurrentTime(pageInfo.createTime)
              }}</a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("account.company_name")
              }}</a-col>
              <a-col :span="17" :offset="1">{{ pageInfo.shopName }}</a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("account.company_id")
              }}</a-col>
              <a-col :span="17" :offset="1">{{ pageInfo.shopMark?pageInfo.shopMark:"-" }}</a-col>
              <a-col :span="5" style="text-align: right">{{ $t("account.register_id") }}</a-col>
              <a-col :span="17" :offset="1">{{
                pageInfo.businessLicenseCode
              }}</a-col>
              <a-col :span="5" style="text-align: right">{{ $t("account.registration_date") }}</a-col>
              <a-col :span="17" :offset="1">{{ $filters.utcToCurrentTime(pageInfo.createTime) }}</a-col>
            </a-row>
          </a-col>
          <a-col>
            <a-row :gutter="[0, 8]">
              <a-col :span="24" class="text-center"
                ><strong>{{ $t("account.account_status") }}</strong></a-col
              >
              <a-col :span="24" class="text-center">
                <span v-if="pageInfo.isLocked" class="text-error">{{
                  $t("account.locked")
                }}</span>
                <span v-else class="text-success">{{
                  $t("account.in_the_activity")
                }}</span>
              </a-col>
              <a-col :span="24" class="text-center">
                <a-button type="ghost" :loading="lockLoading" @click="handleClock">
                  <span v-if="pageInfo.isLocked">{{ $t('account.unlock') }}</span>
                  <span v-else>{{ $t('account.locking') }}</span>
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>

      <!-- 账号安全 -->
      <div class="mt-3">
        <strong>{{ $t("account.account_security") }}</strong>
        <a-table
          class="mt-2"
          :columns="safetyColumns"
          :data-source="safetyList"
          :scroll="{ x: true }"
          :pagination="false"
          :loading="loading"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #loginTime="{ record }">
            {{ $filters.utcToCurrentTime(record.loginUtcTime) }}
          </template>
          <template #userAgent="{ record }">
            <a-typography-paragraph class="mb-0" :copyable="record.userAgent ? { tooltips: false } : false">
              {{ record.userAgent || '-' }}
            </a-typography-paragraph>
          </template>
        </a-table>
        <a-row type="flex" justify="center" align="middle" class="mt-3">
          <a-col :xs="24" :sm="24" :xl="22" :xxl="16">
            <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
          </a-col>
        </a-row>
      </div>

      <a-row type="flex"
            justify="end"
            class=" mb-5 mt-3">
          <a-col>
          <div style="width: 400px">
            <a-row type="flex" justify="center" :gutter="[24, 12]">
            <a-col class="">
              <a-button @click="handleBack ">{{$t('common.backtrack')}}</a-button>
            </a-col>
          </a-row>
          </div>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Row, Col, Table, Button, Input, message, Typography } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CImage from "@/views/components/CImage.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getCountrys } from "@/api/modules/common/index";
import {
  getShopUserById,
  getSafetyList,
  lockShopUser,
  continueShopUser,
} from "@/api/modules/customer/account";
import { getName, verifyMobilePhoneNumber } from "@/utils/general";
import { useRoute, useRouter } from "vue-router";
import { useTab } from "../../../hooks/tabs/index";
export default defineComponent({
  name: "customer_account_details",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    ATypographyParagraph: Typography.Paragraph,
    Content,
    CImage,
    CPager,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    const state = reactive({
      loading: false,
      lockLoading: false,
      pageInfo: {
        shopId: "",
        userNo: "",
        userName: "",
        countryId: "",
        countryCnName: "",
        countryEnName: "",
        phoneCountryCode: "",
        phoneNumber: "",
        mail: "",
        createTime: "",
        shopName: "",
        shopMark: "",
        businessLicenseCode: "",
        timezone: null,
        isLocked: null,
      },
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      safetyList: [],
      // 显示修改密码按钮
      showEdit: false,
      showEditControl: false,
      accountNumber: null,
      countryList: [],
    });

    const safetyColumns = [
      {
        title: () => vueI18n.t("common.serial_number"),
        dataIndex: "",
        width: 100,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: () => vueI18n.t("account.log_in_time"),
        dataIndex: "loginTime",
        slots: {
          customRender: "loginTime",
        },
        width: 200,
      },
      {
        title: "IP",
        dataIndex: "loginIp",
        width: 200,
      },
      {
        title: "User-Agent",
        slots: {
          customRender: "userAgent"
        }
      },
    ];

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcGetSafetyList();
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const funcGetSafetyList = () => {
      const data = Object.assign({}, {
        userIds: [state.userId],
        shopId: state.pageInfo.shopId,
        pageIndex: state.pageData.currentIndex,
        pageSize: state.pageData.maxResultCount,
      });

      getSafetyList(data).then(({ result }) => {
        let { pageData, totalCount } = result;
        if (totalCount > 0) {
          state.safetyList = pageData;
          state.pageData.totalCount = parseInt(totalCount);
        } else {
          state.pageData.totalCount = 0;
          state.safetyList = [];
        }
      });
    };

    const handleCancelAccount = () => {
      state.accountNumber = null
      state.showEditControl = false
    }

    const handleEditAccount = () => {
      if (!state.accountNumber) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('account.login_account')]))
        return
      }
      let country = state.countryList.find(item => item.id === state.pageInfo.countryId)
      let result = verifyMobilePhoneNumber((country ? country.ioS2 : ''), state.accountNumber)
      if (!result) {
        message.error(vueI18n.t('common.p0_incorrect_format', [vueI18n.t('account.login_account')]))
        return
      }
    }

    const initDate = () => {
      getShopUserById({ userId: state.userId }).then((res) => {
        let { result } = res;
        if (result) {
          for (const key in state.pageInfo) {
            if (Object.hasOwnProperty.call(result, key)) {
              state.pageInfo[key] = result[key];
            }
          }
          funcGetSafetyList();
        } else {
          message.error(vueI18n.t("common.no_data"));
        }
      });
    };

    const getCountries = () => {
      getCountrys().then(({ result }) => {
        state.countryList = result ?? []
      }).catch(() => {})
    }

    const handleClock = async () => {
      try {
        state.lockLoading = true
        if (state.pageInfo.isLocked) {
          // 解锁
          await continueShopUser([route.params.id])
        } else {
          // 锁定
          await lockShopUser({ userId: route.params.id })
        }
        state.pageInfo.isLocked = !state.pageInfo.isLocked
        message.success(vueI18n.t("common.succeed"))
      } catch (error) {
      } finally {
        state.lockLoading = false
      }
    }

    const handleBack = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "customer_account_list" });
    };

    onMounted(() => {
      let id = route.params.id;
      if (id) {
        state.userId = id;
        initDate();
      }
      let query = route.query
      if (query && 'isEdit' in query) {
        if (query.isEdit === 'true') {
          state.showEdit = true
          getCountries()
        }
      }
    });

    return {
      ...toRefs(state),
      safetyColumns,
      getLanguageName,
      handlePage,
      handleCancelAccount,
      handleEditAccount,
      handleClock,
      handleBack
    };
  },
});
</script>

import client from "../../../client";

const Api = {
  // 获取租户等级分页列表
  getShopLevelListPaged: "/api/ProductService/ShopLevel/GetListPaged",
  // 创建租户等级
  createShopLevel: "/api/ProductService/ShopLevel/Create",
  // 修改租户等级
  updateShopLevel: "/api/ProductService/ShopLevel/Update",
  // 删除租户等级
  deleteShopLevel: "/api/ProductService/ShopLevel/Delete",
  // 获取所有租户等级
  getShopLevelList: "/api/ProductService/ShopLevel/GetList",
  // 修改公司账户等级
  updateCompanyShopLevel: "/api/ProductService/Shop/UpdateShopLevel",
};

export const getShopLevelListPaged = (data) => {
  return client.request({
    url: Api.getShopLevelListPaged,
    data,
    method: "POST",
  });
};

export const createShopLevel = (data) => {
  return client.request({
    url: Api.createShopLevel,
    data,
    method: "POST",
  });
};

export const updateShopLevel = (data) => {
  return client.request({
    url: Api.updateShopLevel,
    data,
    method: "POST",
  });
};

export const deleteShopLevel = (data) => {
  return client.request({
    url: Api.deleteShopLevel,
    data,
    method: "POST",
  });
};

export const getShopLevelList = (data) => {
  return client.request({
    url: Api.getShopLevelList,
    data,
    method: "POST",
  });
};

export const updateCompanyShopLevel = (data) => {
  return client.request({
    url: Api.updateCompanyShopLevel,
    data,
    method: "POST",
  });
};

<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('warehouse.reconciliation')"
    :mask-closable="false"
    :confirm-loading="loading"
    width="auto"
    centered
    @ok="handleConfirm"
  >
    <a-table
      :columns="columns"
      :data-source="dataList"
      :pagination="false"
      :scroll="{ x: 1200, y: 400 }"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      size="small"
    >
      <template #accountCountTitle>
        <div>{{ $t("warehouse.number_of_waybills") }} /</div>
        <div>{{ $t("warehouse.reconciliation_quantity") }}</div>
      </template>
      <template #accountWeightTitle>
        <div>{{ $t("warehouse.billing_weight_of_waybill") }} /</div>
        <div>{{ $t("warehouse.reconciliation_billing_weight") }}</div>
      </template>
      <template #accountCurrencyTitle>
        <div>{{ $t("warehouse.reconciliation_amount") }} /</div>
        <div>{{ $t("finance.exchange_rate") }}</div>
      </template>
      <template #recordType="{ record }">
        <span>{{
          $t($enumLangkey("accountRecordType", record.recordType))
        }}</span>
      </template>
      <template #isMatch="{ record }">
        <span class="text-success" v-if="record.isMatch">{{
          $t("common.yes")
        }}</span>
        <span class="text-error" v-else>{{ $t("common.no") }}</span>
      </template>
      <template #accountCount="{ record }">
        {{ record.count || "-" }} / {{ record.accountCount }}
      </template>
      <template #accountWeight="{ record }">
        <span v-if="record.chargeableWeight">{{
          gToKg(record.chargeableWeight)
        }}</span>
        <span v-else>-</span>kg /
        <span v-if="record.accountWeight">{{
          gToKg(record.accountWeight)
        }}</span>
        <span v-else>-</span>kg
      </template>
      <template #accountCurrency="{ record }">
        <div v-if="record.accountCurrency && record.conversionAmount">
          <span>{{
            $filters.formatCurrencyAmount(
              record.accountCurrency.currencySymbol,
              record.accountCurrency.amount,
              false
            )
          }}</span>
          (<span class="text-success">{{
            $filters.formatCurrencyAmount(
              record.conversionAmount.currencySymbol,
              record.conversionAmount.amount,
              false
            )
          }}</span
          >) /
        </div>
        <div v-else>- /</div>
        <div v-if="record.currencyRate > 0">{{ record.currencyRate }}</div>
        <div v-else>{{ record.currencyRate }}</div>
        <a-popover v-if="record.isMatch" placement="top" :title="$t('warehouse.reconciliation_detail')">
          <template #content>
            <div style="max-width: 1200px;">
              <CReconciliationTable :data-source="record" />
            </div>
          </template>
          <div class="text-primary" style="cursor: pointer;">{{ $t('common.details') }}</div>
        </a-popover>
      </template>
      <template #targetCurrency="{ record }">
        <span v-if="record.targetCurrency">{{
          $filters.formatCurrencyAmount(
            record.targetCurrency.currencySymbol,
            record.targetCurrency.amount,
            false
          )
        }}</span>
        <span v-else>-</span>
      </template>
      <template #profitLossCurrency="{ record }">
        <span v-if="record.profitLossCurrency">
          <span
            v-if="record.profitLossCurrency.amount > 0"
            class="text-success"
            >{{
              $filters.formatCurrencyAmount(
                record.profitLossCurrency.currencySymbol,
                record.profitLossCurrency.amount
              )
            }}</span
          >
          <span v-else class="text-error">{{
            $filters.formatCurrencyAmount(
              record.profitLossCurrency.currencySymbol,
              record.profitLossCurrency.amount
            )
          }}</span>
        </span>
        <span v-else>-</span>
      </template>
      <template #enterDate="{ record }">
        {{ $filters.utcToCurrentTime(record.enterDate) }}
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, nextTick, h } from "vue";
import { Modal, Popover, Table, message } from "ant-design-vue";
import CReconciliationTable from "@/views/components/CReconciliationTable.vue";
import { useI18n } from "vue-i18n/index";
import { gToKg } from "@/utils/general";
import {
  createAccountRecordForWaybill,
  createAccountRecordForAgentOrder,
} from "@/api/modules/finance/orderWaybill";
import { accountRecordType as accountRecordTypeEnum } from "@/enum/enum.json";

export default defineComponent({
  emits: ["confirm"],
  components: {
    AModal: Modal,
    ATable: Table,
    APopover: Popover,
    CReconciliationTable,
  },
  props: {
    recordType: Number,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      loading: false,
      dataList: [],
    });

    const columns = [
      {
        dataIndex: "refid",
        width: 150,
        title: () => vueI18n.t("warehouse.system_number"),
      },
      {
        width: 80,
        title: () => vueI18n.t("warehouse.reconciliation_type"),
        slots: {
          customRender: "recordType",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("common.match_or_not"),
        slots: {
          customRender: "isMatch",
        },
      },
      {
        width: 80,
        slots: {
          title: "accountCountTitle",
          customRender: "accountCount",
        },
      },
      {
        width: 100,
        slots: {
          title: "accountWeightTitle",
          customRender: "accountWeight",
        },
      },
      {
        width: 150,
        slots: {
          title: "accountCurrencyTitle",
          customRender: "accountCurrency",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.order_amount"),
        slots: {
          customRender: "targetCurrency",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.profit_and_loss_amount"),
        slots: {
          customRender: "profitLossCurrency",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("finance.date"),
        slots: {
          customRender: "enterDate",
        },
      },
    ];

    const handleConfirmAfter = () => {
      message.success(vueI18n.t("common.succeed"));
      emit("confirm");
      state.visible = false;
    };

    const handleConfirm = () => {
      let accountRecords = state.dataList
        .map((item) => {
          if (item.isMatch) {
            return {
              refid: item.refid,
              business: item.business,
              country: item.country,
              accountCount: item.accountCount,
              accountWeight: item.accountWeight,
              remark: item.remark,
              accountCurrency: item.accountCurrency,
              enterDate: item.enterDate,
            };
          }
        })
        .filter(Boolean);
      Modal.confirm({
        title: vueI18n.t("common.confirm"),
        content: () =>
          h("div", {}, [
            h(
              "p",
              vueI18n.t("warehouse.number_of_successful_matches") +
                ": " +
                accountRecords.length
            ),
            h(
              "p",
              vueI18n.t("warehouse.number_of_matching_failures") +
                ": " +
                (state.dataList.length - accountRecords.length)
            ),
          ]),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        okButtonProps: {
          disabled: accountRecords.length === 0
        },
        onOk: () => {
          if (props.recordType === accountRecordTypeEnum.waybill) {
            return createAccountRecordForWaybill({ accountRecords }).then(
              () => {
                handleConfirmAfter();
              }
            );
          } else if (props.recordType === accountRecordTypeEnum.agentOrder) {
            return createAccountRecordForAgentOrder({ accountRecords }).then(
              () => {
                handleConfirmAfter();
              }
            );
          }
        },
      });
    };

    const open = (list = []) => {
      nextTick(() => {
        state.visible = true;
        state.dataList = list;
      });
    };

    return {
      ...toRefs(state),
      accountRecordTypeEnum,
      columns,
      gToKg,
      handleConfirm,
      open,
    };
  },
});
</script>

<style scoped></style>

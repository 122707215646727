export default {
  "right": {
    "role_name": "Character Name",
    "app_id": "For APP",
    "role_type_id": "Character Type",
    "is_default": "If default",
    "right_name": "Authority name",
    "right_show_name": "Authority displayed name",
    "right_uni_key": "Authority unique ID",
    "right_type": "Authority Type",
    "parent_right_id": "Superior authority",
    "menu_url": "Authority route",
    "p_order": "Sequence",
    "p_icon": "Icon",
    "assign_role_permissions": "Assign Permissions for Role"
  }
}
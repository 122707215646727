import client from "../../client"
import qs from 'qs'

const Api = {

  //添加权限菜单。
  createRight: "/api/URCService/Right/Create",

  //修改权限菜单。
  updateRight: "/api/URCService/Right/Modify",

  //删除权限菜单。
  deleteRight: "/api/URCService/Right/Delete",

  //根据权限菜单Id获取权限菜单。
  getRight: "/api/URCService/Right/GetRight",

  //获取权限菜单分页数据
  getRightPageList: "/api/URCService/Right/GetList",

  //获取权限菜单列表
  getRights: "/api/URCService/Right/GetRights",

  //更新权限排序
  updateRightSort: "/api/URCService/Right/UpdateRightSort",

  //添加角色。
  createRole: "/api/URCService/Role/Create",

  //修改角色。
  updateRole: "/api/URCService/Role/Modify",

  //删除角色。
  deleteRole: "/api/URCService/Role/Delete",

  //根据角色Id获取角色。
  getRole: "/api/URCService/Role/GetRole",

  //获取角色分页数据
  getRolePageList: "/api/URCService/Role/GetList",

  //获取角色拥有的权限Id集合。
  getRightsFromRole: "/api/URCService/Role/GetRightsFromRole",

  //更改角色权限。(原权限树将被新权限树覆盖)
  updateRightsFromRole: "/api/URCService/Role/UpdateRightsFromRole",



  //添加应用。
  createApplication: "/api/URCService/Application/Create",

  //修改应用。
  updateApplication: "/api/URCService/Application/Modify",

  //删除应用。
  deleteApplication: "/api/URCService/Application/Delete",

  //根据应用Id获取应用。
  getApplication: "/api/URCService/Application/GetApplication",

  //获取应用分页数据
  getApplicationPageList: "/api/URCService/Application/GetList",

  //获取应用列表数据
  getApplications: "/api/URCService/Application/GetApplications",

  //获取所有资源
  getAllApiResourceList: "/api/URCSecurityService/Security/GetAllApiResourceList"
}

/** 权限begin */
//添加权限
export const createRight = ({ appId,rightId,rightName, rightShowName, parentRightId, pOrder, menuUrl, description, piCon, rightType,rightUniKey}) => {
  return client.request({
    url: Api.createRight,
    data: { appId,rightId,rightName, rightShowName, parentRightId, pOrder, menuUrl, description, piCon, rightType,rightUniKey },
    method: 'post',
    disableAutoError:true
  })
}
//修改权限
export const updateRight = ({ appId,rightId,rightName, rightShowName, parentRightId, pOrder, menuUrl, description, piCon, rightType,rightUniKey}) => {
  return client.request({
    url: Api.updateRight,
    data: { appId,rightId,rightName, rightShowName, parentRightId, pOrder, menuUrl, description, piCon, rightType,rightUniKey },
    method: 'put',
    disableAutoError:true
  })
}
//删除权限
export const deleteRight = (ids) => {
  return client.request({
    url: Api.deleteRight,
    data:ids,
    method: 'put',
    disableAutoError:true
  })
}
//获取权限得分页列表
export const getRightPageList = ({ keyWord, sortName, order, isDelete, pageIndex, pageSize,appId,userPOrderSort }) => {
  return client.request({
    url: Api.getRightPageList,
    data: { keyWord, sortName, order, isDelete, pageIndex, pageSize,appId,userPOrderSort },
    method: 'post'
  })
}
//获取权限
export const getRight = (id) => {
  return client.request({
    url: Api.getRight,
    params: { id },
    method: 'GET'
  });
}

//修改权限
export const updateRightSort = (rightSorts) => {
  return client.request({
    url: Api.updateRightSort,
    data: rightSorts,
    method: 'put',
    disableAutoError:true
  })
}
//获取所有权限得列表
export const getRights = (appId) => {
  return client.request({
    url: Api.getRights,
    params: { appId },
    method: 'GET'
  });
}
/** 权限end */

/** 角色begin */
//添加角色
export const createRole = ({ appId,roleId,roleName,description,roleTypeId,isDefault}) => {
  return client.request({
    url: Api.createRole,
    data: { appId,roleId,roleName,description,roleTypeId,isDefault },
    method: 'post'
  })
}
//修改角色
export const updateRole = ({ appId,roleId,roleName,description,roleTypeId,isDefault}) => {
  return client.request({
    url: Api.updateRole,
    data: { appId,roleId,roleName,description,roleTypeId,isDefault },
    method: 'put'
  })
}
//获取角色得分页列表
export const getRolePageList = ({ keyWord, sortName, order, isDelete, pageIndex, pageSize,appIds,roleTypeId }) => {
  return client.request({
    url: Api.getRolePageList,
    data: { keyWord, sortName, order, isDelete, pageIndex, pageSize,appIds,roleTypeId },
    method: 'post'
  })
}
//获取角色权限
export const getRightsFromRole = (roleId) => {
  return client.request({
    url: Api.getRightsFromRole,
    params: { roleId },
    method: 'GET'
  });
}
//修改角色权限
export const updateRightsFromRole = ({ roleId,rightIds}) => {
  return client.request({
    url: Api.updateRightsFromRole,
    data: { roleId,rightIds },
    method: 'post',
    disableAutoError:true
  })
}

//删除角色
export const deleteRole = (ids) => {
  return client.request({
    url: Api.deleteRole,
    data:ids,
    method: 'put',
    disableAutoError:true
  })
}
/** 角色end */

/** 应用begin */
//添加应用
export const createApplication = ({ appId,appName,description,createUserId,sOrder,businessPlatform}) => {
  return client.request({
    url: Api.createApplication,
    data: { appId,appName,description,createUserId,sOrder,businessPlatform },
    method: 'post'
  })
}
//修改应用
export const updateApplication = ({ appId,appName,description,createUserId,sOrder,businessPlatform}) => {
  return client.request({
    url: Api.updateApplication,
    data: { appId,appName,description,createUserId,sOrder,businessPlatform },
    method: 'put'
  })
}
//获取应用得分页列表
export const getApplicationPageList = ({ keyWord, sortName, order, isDelete, pageIndex, pageSize }) => {
  return client.request({
    url: Api.getApplicationPageList,
    data: { keyWord, sortName, order, isDelete, pageIndex, pageSize },
    method: 'post'
  })
}
//获取所有应用得列表
export const getApplications = (businessPlatform=1) => {
  return client.request({
    url: Api.getApplications,
    params: { businessPlatform },
    method: 'GET'
  });
}
/** 应用end */

/** 资源begin */
export const getAllApiResourceList = () => {
  return client.request({
    url: Api.getAllApiResourceList,
    params: {},
    method: 'GET'
  });
}
/** 资源end */
<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.right_manager") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-select
                v-model:value="tableData.searchData.appId"
                :placeholder="$t('right.app_id')"
                :loading="appData.appLoading"
                :showSearch="true"
                style="width: 240px"
                optionFilterProp="search-key"
                allow-clear
              >
                <a-select-option
                  v-for="item in appData.apps"
                  :search-key="item.name"
                  :key="item.id"
                  :title="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleSearch" :loading="tableData.loading">
                {{ $t("common.query") }}
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="ghost" @click="handleOpenCreateModal">创建权限</a-button>
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-table :columns="columns" size="small" :data-source="tableData.tableList" :pagination="false"
          :scroll="{ x: 500, y: wrap.contentHeight - 100 }" :rowKey="(record, index) => record.rightId"
          :loading="tableData.loading" :indent-size="12" :expandedRowKeys="tableData.expandedRowKeys"
          @expand="onExpand">
          <template v-slot:rightName="{ record }">
            {{ record.rightName }}
          </template>
          <template v-slot:rightCode="{ record }">
            <span :title="record.rightCode">{{ record.rightCode }}</span>
          </template>
          <template v-slot:menuUrl="{ record }">
            <span :title="record.menuUrl">{{ record.menuUrl }}</span>
          </template>
          <template v-slot:description="{ record }">
            <span :title="record.description">{{ record.description }}</span>
          </template>
          <template v-slot:operation="{ record }">
            <a-dropdown-button @click="handleOpenCreateModal(record)">
              {{ $t('新增下级') }}
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1" :record="record" :disabled="record.rightType === rightTypeEnum.app">{{ $t('common.edit') }}</a-menu-item>
                  <a-menu-item key="2" :record="record" :disabled="record.rightType === rightTypeEnum.app">{{ $t('common.delete') }}</a-menu-item>
                  <a-menu-item key="3" :record="record" :disabled="!record.children.length">{{ $t('right.p_order') }}</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>

      <!-- 新增编辑框 -->
      <a-modal v-model:visible="modalData.visible" :confirm-loading="modalData.loading"
        :title="modalData.formData.rightId > 0 ? '编辑' : '创建'" :maskClosable="false" @ok="handleSave">
        <a-form :model="modalData.formData" :label-col="{ span: 5 }" ref="refEditModalForm" :rules="rightRules">
          <a-form-item :label="$t('right.right_name')" name="rightName">
            <a-input v-model:value="modalData.formData.rightName" :placeholder="$t('right.right_name')"></a-input>
          </a-form-item>
          <a-form-item :label="$t('right.right_show_name')" name="rightShowName">
            <a-input v-model:value="modalData.formData.rightShowName" :placeholder="$t('right.right_show_name')">
            </a-input>
          </a-form-item>
          <a-form-item :label="$t('right.right_uni_key')" name="rightUniKey">
            <a-input v-model:value="modalData.formData.rightUniKey" :placeholder="$t('right.right_uni_key')"></a-input>
          </a-form-item>

          <a-form-item :label="$t('right.app_id')" name="appId">
            <a-select :placeholder="$t('right.app_id')" v-model:value="modalData.formData.appId" allow-clear
              @change="appChange" :loading="appData.appLoading" :showSearch="true" optionFilterProp="search-key"
              :disabled="modalData.formData.rightId > 0">
              <a-select-option v-for="item in appData.apps" :search-key="item.name" :key="item.id">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('right.right_type')" name="rightType">
            <a-radio-group v-model:value="modalData.formData.rightType">
              <a-radio v-for="item in rightTypeFilterEnum" :key="item.value" :value="item.value">{{
                $t($enumLangkey("rightType", item.value)) }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item :label="$t('right.parent_right_id')" name="parentRightId">
            <a-tree-select v-model:value="modalData.formData.parentRightId"
              :defaultValue="modalData.formData.parentRightId" style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="rightTree"
              :placeholder="$t('right.parent_right_id')" allow-clear :tree-default-expand-all="true">
              <template #tree-select-title="{ key, value }">
                <span style="color: #08c" v-if="key === '0-0-1'">Child Node1 {{ value }}</span>
              </template>
            </a-tree-select>
          </a-form-item>
          <a-form-item :label="$t('right.menu_url')" name="menuUrl">
            <a-input v-if="modalData.formData.rightType != rightTypeEnum.resources"
              v-model:value="modalData.formData.menuUrl" :placeholder="$t('right.menu_url')"></a-input>
            <a-auto-complete v-else v-model:value="modalData.formData.menuUrl" :placeholder="$t('right.menu_url')"
              @search="handleApiResourceSearch" @select="handleApiResourceSelect">
              <template #options>
                <a-select-option v-for="apiResource in apiResourceData.apiResourcesResult" :key="apiResource.routeUrl">
                  <div>{{ apiResource.routeUrl }}</div>
                  <div>
                    <small>{{ apiResource.description }}</small>
                  </div>
                </a-select-option>
              </template>
            </a-auto-complete>
          </a-form-item>

          <a-form-item :label="$t('right.p_order')" name="pOrder">
            <a-input-number v-model:value="modalData.formData.pOrder" :placeholder="$t('right.p_order')">
            </a-input-number>
          </a-form-item>
          <a-form-item :label="$t('logistics.describe')" name="description">
            <a-input v-model:value="modalData.formData.description" :placeholder="$t('logistics.describe')"></a-input>
          </a-form-item>
          <a-form-item :label="$t('right.p_icon')" name="piCon">
            <a-select v-model:value="modalData.formData.piCon" allow-clear :showSearch="true"
              :placeholder="$t('right.p_icon')" optionFilterProp="search-key">
              <a-select-option v-for="(item, index) in apiResourceData.icons" :key="index" :value="item"
                :search-key="item">
                <span>
                  <component :is="$antIcons[item]" /> {{ item }}
                </span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>

      <!-- 排序框 -->
      <a-modal v-model:visible="sortMotalData.visible" :confirm-loading="sortMotalData.loading" :title="'排序'"
        :maskClosable="false" @ok="handleSortTableSave">
        <a-table :columns="sortcolumns" size="small" :data-source="sortMotalData.table.tableList" :pagination="false"
          :scroll="{ x: 400, y: wrap.contentHeight - 100 }" :rowKey="(record, index) => record.rightId"
          :loading="sortMotalData.table.loading">
          <template v-slot:rightName="{ record }">
            {{ record.rightName }}
          </template>
          <template v-slot:pOrder="{ record }">
            <a-input-number v-model:value="record.pOrder" :min="0" :max="1000" />
          </template>
        </a-table>
      </a-modal>
    </template>
  </Content>
</template>
<script>
  import {
    Table,
    Button,
    Modal,
    Input,
    Select,
    Card,
    Row,
    Col,
    Form,
    Radio,
    Switch,
    InputNumber,
    TreeSelect,
    message as Msg,
    Popconfirm,
    AutoComplete,
Dropdown,
Menu,
  } from "ant-design-vue";
  import CPager from "../components/CPager.vue";
  import Content from "../components/Content.vue";
  import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    getCurrentInstance
  } from "vue";
  import { useI18n } from "vue-i18n/index";
  import { getName } from "../../utils/general";
  import { useStore } from "vuex";
  import { rightType as rightTypeEnum } from "../../enum/enum.json";
  import {
    getRightPageList,
    createRight,
    updateRight,
    getApplications,
    getRights,
    deleteRight,
    getAllApiResourceList,
    updateRightSort,
  } from "../../api/modules/right/index";

  export default defineComponent({
    name: "right_manager",
    components: {
      ATable: Table,
      AButton: Button,
      AModal: Modal,
      CPager,
      AInput: Input,
      AInputGroup: Input.Group,
      ASelect: Select,
      ACard: Card,
      ARow: Row,
      ACol: Col,
      Content,
      ASelectOption: Select.Option,
      AForm: Form,
      AFormItem: Form.Item,
      ARadio: Radio,
      ARadioOption: Radio.Option,
      ARadioButton: Radio.Button,
      ARadioGroup: Radio.Group,
      ASwitch: Switch,
      AInputNumber: InputNumber,
      ATreeSelect: TreeSelect,
      APopconfirm: Popconfirm,
      AAutoComplete: AutoComplete,
      ADropdownButton: Dropdown.Button,
      AMenu: Menu,
      AMenuItem: Menu.Item,
    },
    setup() {
      const currentInstance = getCurrentInstance();
      /** 资源选择begin */
      const apiResourceData = reactive({
        loading: false,
        apiResourcesResult: [],
        sourceResult: [],
        icons: []
      });

      const buildIcons = () => {
        if (currentInstance.proxy.$antIcons) {
          apiResourceData.icons = Object.keys(currentInstance.proxy.$antIcons)
            .filter(x => {
              let item = currentInstance.proxy.$antIcons[x];
              return x != "default" && item && item.displayName && item.length && item.length >= 2;
            })
        }
      }
      const handleApiResourceSearch = (val) => {
        //console.log(val);
        //去除空格
        val = val.replace(/[\u200B-\u200D\uFEFF]/g, "");
        let reg = new RegExp(val || "");
        apiResourceData.apiResourcesResult = val
          ? apiResourceData.sourceResult.filter((param) => {
            return (param.routeUrl || "").match(reg);
          })
          : apiResourceData.sourceResult;
      };

      const handleApiResourceSelect = (val) => {
        //console.log(val);
        val = val.replace(/[\u200B-\u200D\uFEFF]/g, "");
        let selectResouce = apiResourceData.sourceResult.filter(
          (p) => p.routeUrl == val
        );
        modalData.formData.description = selectResouce
          ? selectResouce[0].description
          : "";
      };

      const onExpand = (expanded, record) => {
        //console.log('extend:' + expanded)
        //console.log('record: ' + JSON.stringify(record))
        if (expanded) {
          // 设置展开窗Key，代表展开操作
          tableData.expandedRowKeys.push(record.id)
        } else {
          // 代表折叠操作
          if (tableData.expandedRowKeys.length) {
            tableData.expandedRowKeys = tableData.expandedRowKeys.filter(v => {
              return v !== record.id
            })
          }
        }
      }

      /** 资源选择end  */
      const { getters } = useStore();
      const vueI18n = useI18n({ useScope: "global" });
      const refEditModalForm = ref();

      const columns = [
        {
          dataIndex: "rightName",
          width: 250,
          fixed: "left",
          title: () => vueI18n.t("right.right_name"),
          slots: {
            customRender: "rightName",
          },
        },
        {
          dataIndex: "rightShowName",
          width: 100,
          title: () => "权限展示名称",
        },
        {
          dataIndex: "appName",
          width: 100,
          title: () => "所属应用名称",
        },
        {
          dataIndex: "rightTypeName",
          width: 50,
          title: () => "权限类型",
        },
        {
          dataIndex: "menuUrl",
          width: 150,
          title: () => "权限路由",
          customCell: () => {
            return {
              style: {
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
              },
            };
          },
          slots: {
            customRender: "menuUrl",
          },
        },
        {
          dataIndex: "rightCode",
          width: 100,
          customCell: () => {
            return {
              style: {
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
              },
            };
          },
          title: () => "权限编码",
          slots: {
            customRender: "rightCode",
          },
        },
        {
          dataIndex: "description",
          width: 150,
          title: () => "权限描述",
          customCell: () => {
            return {
              style: {
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
              },
            };
          },
          title: () => "权限描述",
          slots: {
            customRender: "description",
          },
        },
        {
          width: 180,
          title: () => "操作",
          slots: {
            customRender: "operation",
          },
        },
      ];

      const tableData = reactive({
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10000,
        },
        searchData: {
          searchKey: "",
          appId: null,
        },
        expandedRowKeys: []
      });

      const getDefaultRightObj = () => {
        return {
          appId: null,
          rightId: 0,
          rightName: "",
          rightShowName: "",
          parentRightId: null,
          pOrder: 0,
          menuUrl: "",
          description: "",
          piCon: "",
          rightType: null,
          rightUniKey: null
        };
      };
      const modalData = reactive({
        visible: false,
        loading: false,
        formData: getDefaultRightObj(),
      });

      const setRightFromRecord = (record = {}) => {
        let {
          appId,
          rightId,
          rightName,
          rightShowName,
          parentRightId,
          pOrder,
          menuUrl,
          description,
          piCon,
          rightType,
          rightUniKey
        } = record;
        Object.assign(modalData.formData, {
          appId,
          rightId,
          rightName,
          rightShowName,
          parentRightId,
          pOrder,
          menuUrl,
          description,
          piCon,
          rightType,
          rightUniKey
        });
      };

      const appData = reactive({
        appLoading: false,
        apps: [],
      });

      const getLanguageName = (item) => {
        return getName(item, getters.language);
      };

      //table列表
      const getRightList = () => {
        tableData.loading = true;
        getRightPageList({
          pageIndex: tableData.pageData.currentIndex,
          pageSize: tableData.pageData.maxResultCount,
          keyWord: tableData.searchData.searchKey,
          appId: tableData.searchData.appId || 0,
          sortName: "",
          userPOrderSort: "true"
        })
          .then((res) => {
            let { pageData = [], totalCount = 0 } = res.result;
            //将数据处理为树型
            if (pageData.length > 0) {
              let pageMapData = pageData.map((r) => {
                let item = {
                  id: r.rightId,
                  parentId: r.parentRightId,
                  rightId: r.rightId,
                  parentRightId: r.parentRightId,
                  rightName: r.rightName,
                  menuUrl: r.menuUrl,
                  appName: r.appName,
                  rightTypeName: r.rightTypeName,
                  description: r.description,
                  rightCode: r.rightCode,
                  rightShowName: r.rightShowName,
                  rightType: r.rightType,
                  appId: r.appId,
                  rightUniKey: r.rightUniKey,
                  pOrder: r.pOrder,
                  piCon: r.piCon,
                  children: [],
                };
                return item;
              });

              let tableTree = toTree(pageMapData);
              //console.log(tableTree);
              tableData.tableList = tableTree;
              var ids = tableTree.map(p => p.id);
              //console.log(ids);
              if (tableData.expandedRowKeys.length == 0) {
                tableData.expandedRowKeys = ids;
              }
              tableData.pageData.totalCount = parseInt(totalCount);
              tableData.loading = false;
            }
          })
          .catch(() => {
            tableData.loading = false;
          });
      };

      const getApiResourceList = () => {
        getAllApiResourceList()
          .then((res) => {
            apiResourceData.apiResourcesResult = apiResourceData.sourceResult =
              res.result;
          })
          .catch(() => {
            apiResourceData.apiResourcesResult = [];
          })
          .finally(() => {
            apiResourceData.loading = false;
          });
      };

      const handleSearch = () => {
        tableData.pageData.currentIndex = 1;
        tableData.pageData.skipCount = 0;
        getRightList();
      };

      /*打开模态框之前*/
      const beforeModalOpen = () => {
        //加载App数据
        if (appData.apps.length == 0) {
          appData.appLoading = true;
          getApplications()
            .then((res) => {
              appData.apps = res.result;
            })
            .finally(() => {
              appData.appLoading = false;
            });
        }
        //加载资源数据
        if (apiResourceData.apiResourcesResult.length == 0) {
          getApiResourceList();
        }
        if (refEditModalForm.value && refEditModalForm.value.clearValidate) {
          refEditModalForm.value.clearValidate();
        }
      };

      const getAppData = () => {
        if (appData.apps.length == 0) {
          appData.appLoading = true;
          getApplications()
            .then((res) => {
              appData.apps = res.result;
            })
            .finally(() => {
              appData.appLoading = false;
              //console.log(tableData.searchData);
              //默认有个应用
              if (!tableData.searchData.appId) {
                if (appData.apps) {
                  //console.log(appData.apps)
                  if (appData.apps[0]) {
                    tableData.searchData.appId = appData.apps[0].id;
                  }
                  handleSearch();
                }
              }
            });
        }
      };

      /*打开模态框*/
      const handleOpenCreateModal = (record) => {
        // modalData.formData = {};
        //数据还原
        modalData.formData = getDefaultRightObj();
        if (tableData.searchData.appId) {
          modalData.formData.appId = tableData.searchData.appId;
        }
        if (record) {
          modalData.formData.parentRightId = record.rightId;
          appChange();
        }
        beforeModalOpen();
        modalData.visible = true;
      };

      const handleOpenEditModal = (record) => {
        setRightFromRecord(record);
        beforeModalOpen();
        appChange();
        modalData.visible = true;
      };
      const handleSave = () => {
        refEditModalForm.value
          .validate()
          .then(() => {
            modalData.loading = true;
            return modalData.formData.rightId > 0
              ? updateRight(modalData.formData)
              : createRight(modalData.formData);
          })
          .then(() => {
            modalData.loading = false;
            modalData.visible = false;
            handleSearch();
            //保存成功还原数据
            modalData.formData = getDefaultRightObj();
          })
          .catch((data) => {
            if (data.msg) {
              Msg.error(data.msg);
            }
            modalData.loading = false;
          });
      };

      const rightData = reactive({
        loading: false,
        rights: [],
      });

      const appChange = () => {
        //nothing
        rightData.loading = true;
        let appId = modalData.formData.appId;
        getRights(appId)
          .then((res) => {
            rightData.rights = res.result;
          })
          .catch((err) => {
            rightData.rights = [];
            // console.error("load province error");
            throw err;
          })
          .finally(() => {
            rightData.loading = false;
            if (
              rightData.rights.findIndex(
                (x) => x.rightId == modalData.formData.parentRightId
              ) >= 0
            ) {
              //keep it
            } else {
              modalData.formData.parentRightId = null;
            }
            getRightTree();
          });
      };

      /*验证规则 */
      const rightRules = {
        rightName: [
          {
            required: true,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("right.right_name"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
        rightShowName: [
          {
            required: true,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("right.right_show_name"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
        appId: [
          {
            required: true,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("right.app_id"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
        rightType: [
          {
            type: "number",
            required: true,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("right.right_type"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
        parentRightId: [
          {
            required: true,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("right.parent_right_id"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
        menuUrl: [
          {
            required: false,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("right.menu_url"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
        pOrder: [
          {
            type: "number",
            required: true,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("right.p_order"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
        description: [
          {
            required: true,
            message: () => {
              return vueI18n.t("common.p0_is_required", [
                vueI18n.t("logistics.describe"),
              ]);
            },
            trigger: ["blur", "change"],
          },
        ],
      };

      //选择得权限类型
      const rightTypeFilterEnum = Object.keys(rightTypeEnum)
        .filter((x) => x != "app")
        .map((x) => ({ key: x, value: rightTypeEnum[x] }));

      /** selectTree begin */

      const rightTree = ref([]);

      const toTree = (list) => {
        let node;
        const map = {};
        const tree = [];
        for (let i = 0; i < list.length; i++) {
          map[list[i].id] = i;
        }
        for (let i = 0; i < list.length; i++) {
          node = list[i];
          if (node.parentId > 0) {
            //防止该parentId没有节点
            if (list[map[node.parentId]]) {
              const children = list[map[node.parentId]].children || [];
              list[map[node.parentId]].children = [...children, node];
            }
          } else {
            tree.push(node);
          }
        }
        return tree;
      };

      //渲染权限树
      const getRightTree = async () => {
        let rights = rightData.rights;
        if (rightData.rights && rightData.rights.length) {
          rights = rightData.rights.map((r) => {
            let item = {
              id: r.rightId,
              parentId: r.parentRightId,
              key: r.rightId,
              title: r.rightName + "[" + r.rightShowName + "]",
              value: r.rightId,
              isDelete: r.isDelete,
              slots: {
                title: "tree-select-title",
              },
              editData: r,
              children: [],
            };
            return item;
          });
          rights = rights.filter((r) => r.isDelete == false);
          let subTree = toTree(rights);
          if (subTree.length > 0 && rightTree.value) {
            rightTree.value = subTree;
          }
        }
      };

      /**begin 排序相关 */
      const sortcolumns = [
        {
          dataIndex: "rightName",
          width: 100,
          fixed: "left",
          title: () => vueI18n.t("right.right_name"),
          slots: {
            customRender: "rightName",
          },
        },
        {
          dataIndex: "pOrder",
          width: 100,
          title: () => "排序",
          slots: {
            customRender: "pOrder",
          },
        }
      ];

      const sortMotalData = reactive({
        visible: false,
        loading: false,
        table: {
          loading: false,
          tableList: []
        },
      });

      /*打开排序框*/
      const handleOpenSortModal = (record) => {
        if (record && record.children.length > 0) {
          sortMotalData.visible = true;
          let pageMapData = record.children.map((r) => {
            let item = {
              id: r.rightId,
              parentId: r.parentRightId,
              rightId: r.rightId,
              parentRightId: r.parentRightId,
              rightName: r.rightName,
              menuUrl: r.menuUrl,
              appName: r.appName,
              rightTypeName: r.rightTypeName,
              description: r.description,
              rightCode: r.rightCode,
              rightShowName: r.rightShowName,
              rightType: r.rightType,
              appId: r.appId,
              rightUniKey: r.rightUniKey,
              pOrder: r.pOrder,
              piCon: r.piCon
            };
            return item;
          });
          sortMotalData.table.tableList = pageMapData;
        }
      };

      const handleSortTableSave = () => {
        let updateSortData = sortMotalData.table.tableList.map((r) => {
          let data = {
            rightId: r.rightId,
            pOrder: r.pOrder
          };
          return data;
        });
        //console.log(sortMotalData.table.tableList);
        updateRightSort(updateSortData)
          .then(() => {
            sortMotalData.loading = false;
            sortMotalData.visible = false;
            handleSearch();
          })
          .catch((data) => {
            if (data.msg) {
              Msg.error(data.msg);
            }
            modalData.loading = false;
          });
      };
      /**end 排序相关 */

      const handleMenuClick = (e) => {
        let { key, item } = e
        let { record } = item
        switch (key) {
          case '1':
            handleOpenEditModal(record)
            break;
          case '2':
            Modal.confirm({
              title: vueI18n.t("common.operate"),
              content: vueI18n.t("common.are_you_sure_delete"),
              okText: vueI18n.t("common.confirm"),
              cancelText: vueI18n.t("common.cancel"),
              onOk: () => {
                return deleteRight([ record.rightId ]).then(() => {
                  Msg.success(vueI18n.t("common.succeed"));
                  handleSearch();
                });
              },
            });
            break;
          case '3':
            handleOpenSortModal(record)
            break;
          default:
            break;
        }
      }

      onMounted(() => {
        buildIcons();
        getAppData();
      });

      return {
        apiResourceData,
        rightTree,
        refEditModalForm,
        columns,
        tableData,
        modalData,
        appData,
        rightTypeEnum,
        rightTypeFilterEnum,
        rightRules,
        sortcolumns,
        sortMotalData,
        onExpand,
        handleApiResourceSearch,
        handleApiResourceSelect,
        handleSearch,
        handleOpenCreateModal,
        handleOpenEditModal,
        handleOpenSortModal,
        handleSave,
        handleSortTableSave,
        appChange,
        getLanguageName,
        handleMenuClick,
      };
    },
  });
</script>

<style lang="less" scoped>
  .hidden-cell {
    width: "100px";
    overflow: "hidden";
    white-space: "nowrap";
    text-overflow: "ellipsis";
    cursor: "pointer";
  }

  .dpop {
    position: absolute;
    z-index: 3;
    border: 1px dashed #eef;
    background: #eee;
  }
  :deep(.input-group-mid .ant-input-group-addon) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  :deep(.input-group-mid-number) {
    border-radius: 0;
    width: 100%;
  }

  :deep(.input-group-end-number) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
  }

  :deep(.input-group-start-number) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
  }
</style>
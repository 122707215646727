export default {
  "warehouse": {
    "warehouse_type": "Warehouse type",
    "warehouse": "仓库",
    "warehouse_name": "Warehouse Name",
    "in_warehouse_plan_detail": "Inbound plan details",
    "deal_with_wave_number": "Handle Wave Number",
    "user_id": "User ID",
    "pick_up_method": "Pick up method",
    "on_warehouse_sku_number": "SKU quantity in warehouse",
    "inventory_box_count": "Inventory carton quantity",
    "estimated_inventory_quantity": "Estimated carton quantity",
    "third_side_list": "3rd Party Waybill",
    "should_be_between": "{0}应该在{1}至{2}间",
    "device_not_found_or_browser_not_supported": "Device not found or unsupported browser.",
    "express_sheet": "Waybill",
    "approved_package_size": "Verify package dimension",
    "approved_package_weight": "Verify package weight",
    "consignment_warehouse": "Distribution Warehouse",
    "today": "Today",
    "yesterday": "Yesterday",
    "this_week": "This Week",
    "this_month": "This Month",
    "inventory_count": "Inventory quantity",
    "storage_warehouse": "Storage Warehouse",
    "outbound_plan": "Outbound plan",
    "inbound_plan": "Inbound Plan",
    "transport_plan": "Shipping Plan",
    "destination": "Destination",
    "related_outbound_plan": "Relevant Outbound Plan",
    "related_delivery_plan": "Relevant Handing Over Plan",
    "outbound_plan_number": "Outbound Plan Number",
    "outbound_warehouse_task_details": "Outbound Task details",
    "inbound_warehouse_task_details": "Inbound Task details",
    "outbound_warehouse_amount": "Outbound Quantity",
    "outbound_warehouse_box_amount": "Outbound Carton Quantity",
    "outbound_warehouse_specification": "Outbound Specification",
    "outbound_weight": "Outbound Weight",
    "box_number": "Carton Number",
    "customer_product_number": "Client's Item Number.",
    "inner_sku": "SESKU",
    "new_tab": "New Label",
    "organization_structure": "Organization Structure",
    "service_type": "Service Type",
    "task_details": "Task details",
    "transport_delivery_object": "Shipping Handing Over Party",
    "scan_count": "Scan Quantity",
    "add_staff": "Add employee",
    "staff_management": "Employee Management",
    "plan_name": "Plan name",
    "inbound_type": "Inbound Type",
    "client_id": "Client ID",
    "tab_operate_console": "Label Operation Platform",
    "label_operate": "Label Handling",
    "change_operate_console": "Change Operation Platform",
    "accomplish_labelling": "Labeling Completed",
    "check_label": "Check Label",
    "sign_in_operating_desk": "Signing In Operation Platform",
    "already_added": "Already in the list",
    "reset_scan_record": "Reset scanning record",
    "box_status": "Carton Status",
    "current_region": "Current Area",
    "current_location": "Current Location",
    "target_area": "Target Area",
    "target_location": "Target Location",
    "task_status": "Task status",
    "trigger_reason": "Trigger reason",
    "last_operator": "Last Operator",
    "principal": "Responsible Person",
    "completeing": "To be labeled",
    "outbound_number": "Outbound Number",
    "please_input_search_outbound_number": "Please input Outbound Number.",
    "please_input_search_in_number": "Please input Inbound Number.",
    "inbound_warehouse_number": "Inbound Number",
    "inbound_receipt_quantity": "Inbound Quantity",
    "inbound_box_number": "Inbound Carton Quantity",
    "received_quantity": "Received Quantity",
    "inbound_method": "Stocking In method",
    "creation_time": "Creating time",
    "happen_warehouse_code": "Warehouse code of occurrence",
    "article_number": "MSKU/Item Number",
    "actual_packing": "Actual Packing",
    "transportation_delivery_status": "Shipping handing over status",
    "customer_information": "Client Info(Authorization required)",
    "outbound_information": "Outbound Info",
    "inbound_information": "Inbound Info",
    "the_target_position_cannot_be_empty": "Target Location can not be empty.",
    "estimated_delivery_time": "Estimated Delivery Time",
    "tracking_serial_number": "Tracking Number",
    "address_information": "Address Info",
    "delivery_man": "Deliveryman",
    "address_nformation": "Address Info(If use system logistics)",
    "cargo_details": "Cargo Details",
    "customer_name": "Name",
    "estimatedPick_up_time": "Estimated pick up time",
    "logistics_mode": "LTL Logistics/FTL Logistics/Express Logistics",
    "deliver_storage_location": "Handing Over Location",
    "deliver": "Hand Over",
    "inbound_box": "Inbound Carton",
    "warehoused_box": "Stocked In Carton",
    "added_list": "Added List",
    "inbound_specification": "Inbound Specification",
    "inbound_weight": "Inbound Weight",
    "cancel_move_storehouse": "Cancel Transfer",
    "comfirm_move_storehouse": "Confirm Transfer",
    "storage_location_number": "Location Number",
    "storage_location_max_capacity_and_bearing_weight": "Location maximum capacity of volume/weight",
    "max_remaining_capacity_and_bearing_weight": "Rest maximum capacity of volume/weight",
    "number_of_boxes_stored": "Stocked carton quantity",
    "current_storage_location": "Current location",
    "inbound_plan_number": "Inbound Plan Number",
    "inbound_plan_name": "Inbound Plan Name",
    "inbound_target_warehouse": "Target warehouse for stocking in",
    "warehouse_address": "Warehouse address",
    "plan_status": "Plan status",
    "product_name_and_productNo": "Product Name/Item NO.",
    "inbound_status": "Inbound Status",
    "inbound_packing_size": "Inbound Packing Dimension",
    "inbound_packing_weight": "Inbound Packing Weight",
    "abnormal_state_operation": "Require operation for Exception Status.",
    "trajectory": "Track",
    "survey_state": "Investigation Status",
    "survey_result": "Investigation result",
    "inquirer": "Inquirer",
    "abandon": "Waive",
    "related_inbound_warehouse_plan": "Relevant Inbound Pland",
    "tray_number": "Pallet Quantity",
    "labeled": "Labeled",
    "receiving_storage_location": "Receiving Location",
    "product_info": "Product info",
    "label_service": "Label Service",
    "transport_address": "Delivery Address",
    "logistics_delivery_status": "Logistics handing over status",
    "reserved_Pick_up_information": "Reserved pick up info",
    "payment_method": "Payment Method",
    "warehouse_expenses": "Warehouse charges",
    "basic_operating_expenses": "Basic Handling Fee",
    "additional_packaging_materials": "Additional packaging material",
    "label_cost": "Label fee",
    "outbound_total_volume": "Outbound total volume",
    "outbound_total_number": "Outbound total quantity",
    "location_qualified_status": "Location Qualified status",
    "owning_customer_id": "Owner client ID",
    "inbound_warehouse_time": "Inbound Time",
    "last_updated": "Latest Update Time",
    "move_track": "Transferring track",
    "operate_console_no": "Operation Platform Number",
    "area_status_configuration": "Area status configuration",
    "area_name": "Area Name",
    "area_number": "Area Number",
    "admissible_state": "Permissable status",
    "location_id_or_code": "Location ID/Number",
    "location_id": "Location ID",
    "current_location_code": "Current Location Number",
    "new_location_code": "New Location Number",
    "task_trigger": "Task Triggering-man",
    "task_type": "Task Type",
    "trigger_time": "Triggering Time",
    "completed_time": "Completing Time",
    "location_type": "Location Type",
    "add_location_type": "Add Location Type",
    "specification": "Specification",
    "load_bearing_upper_limit": "Maximum Bearing Weight",
    "location": "Location",
    "add_location": "Add Location",
    "edit_location": "Edit Location",
    "select_the_location_type": "Select Location Type",
    "select_the_location": "Select Location",
    "select_the_region": "Select Area",
    "the_goods_transfer": "Cargo Transfer",
    "location_specification": "Location Specification",
    "add_location_count": "Add location quantity",
    "transfer_to": "Transfer to",
    "inbound_warehouse_specification": "Inbound Specification",
    "inbound_warehouse_weight": "Inbound Weight",
    "area_construction": "Area Structure",
    "current_area": "Staying Area",
    "load_bearing": "Bearing Weight",
    "position_code": "Location Number",
    "volume_usage": "Volume Occupied",
    "load_bearing_usage": "Bearing Weight Occupied",
    "can_enter_2_letters_or_numbers": "2 letters or numbers can be input",
    "add_the_library_location_area": "Add Location to Area",
    "related_task_details": "Relevant Task details",
    "location_number": "Location Number",
    "operation_tips": "Handling Tips",
    "update_area": "Update Area",
    "new_location_number": "New Location Number",
    "look_abnormal": "Check Inbound Exception",
    "anomaly_details": "Exception details",
    "request_time": "Requesting time",
    "please_scan_console_first": "Please scan Operation Platform first.",
    "please_scan_target_storage_locationfirst": "Please scan target location first.",
    "add_subdomain": "Add Subarea",
    "edit_subdomain": "Edit Subarea",
    "maximum_capacity": "Maximum capacity of volume/weight",
    "surplus_capacity": "Rest capacity of volume/weight",
    "import_location": "Input Location ID/Location Number",
    "apply_content": "Application content",
    "survey_dispose": "Action for investigation",
    "extra_prove_file": "Additional Proof Files",
    "occurrence_time": "Occurrence time",
    "SESKUNumber": "SESKU quantity",
    "delivery_status": "Hand Over Status",
    "type_name": "Name of Type",
    "in_storage_error": "Exceptional Carton(possible reason: 1.Carton Number doesn't exist. 2.Carton has been stocked in. 3.Carton received overtime.)",
    "current_box_not_belong_to_plan": "Current scanning carton is not assigned to this plan.",
    "whether_to_switch_plans": "If switch to the plan which scanning carton is assigned to?",
    "current_box_inexistence": "This carton doesn't exist.",
    "current_box_has_been_put_in_storage": "This carton has been stocked in.",
    "warehousing_success": "Stocked in successfully.",
    "out_warehous_success": "Stocked out successfully.",
    "has_been_put_in_storage": "Stocked in already.",
    "please_add_size_photos": "Please add measuring picture.",
    "storage_location_no_error": "Location Number doesn't exist.",
    "box_no_inexistence": "Carton Number doesn't exist.",
    "survey_sheet": "Measuring picture",
    "size_img": "Dimension picture",
    "other_proof_img": "Other proof pictures",
    "ignore_new_surveying_data": "Ignore new measuring data.",
    "update_new_surveying_data": "Update with new measuring data.",
    "warn_scan_data_exceed_threshold": "Warning: Scanned new data exceed the deviation threshold value.",
    "general_surveying_data": "General measuring data",
    "reserved_info": "Reserved info.",
    "input_reserved_info": "Please input reserved info.",
    "reserved_info_no_null": "Reserved info is required.",
    "planned_box_has_not_been_scanned": "Cartons of plan haven't been scanned completely.",
    "affiliation_location": "Staying location",
    "no_complete_labelling": "Labeling uncompleted.",
    "scaned": "Scanned",
    "unscan": "To be scanned",
    "please_scan_out_location": "Please scan the Location Number of Outbound Area about to stock.",
    "box_scaned": "Carton has been scanned.",
    "last_measurement_data": "Latest measuring data",
    "time_from_now": "Till now",
    "modified_data": "Modified data",
    "general_data": "General data",
    "modifier": "Modifier",
    "code_operation_tips_for_add": "Please click Operation Completed after labeling the location barcode at a convenient spot for scanning.",
    "code_operation_tips_for_change": "Please scan the new label and use it to cover the old one.",
    "code_operation_tips_for_remove": "Please click Operation Completed after transferring cargo out from this location and disposing the label.",
    "out_plan_name": "Outbound plan name",
    "out_warehouse_no": "Outbound warehouse number",
    "out_warehouse_type": "Outbound type",
    "fba_warehouse_code": "FBA warehouse code",
    "fbm_warehouse_code": "FBM warehouse code",
    "warehousing_number": "Inbound Number",
    "product_name": "Product name",
    "encasement_count": "Packing quantity",
    "out_warehouse_encasement_size": "Packing dimension for stocking out",
    "out_warehouse_encasement_weight": "Packing weight for stocking out",
    "outer_box_label": "Carton label",
    "box": "Carton",
    "out_total_weight": "Outbound total weight",
    "transportation_delivery_way": "Shipping handing over method",
    "target_warehouse_code": "Target warehouse code",
    "target_warehouse": "Target Warehouse",
    "selected_pallet_packing_serve": "Selected pallet service",
    "send_goods_address": "Consigner address",
    "logistics_way": "Logistics method",
    "tracking_shipment": "Tracking shipment",
    "encasement_size": "Packing dimension",
    "encasement_weight": "Packing weight",
    "label_processing": "Labeling process",
    "in_warehouse_img": "Pictures of stocking in",
    "error_state": "Exception Status",
    "need_operate": "Need to be handled",
    "total_volume": "Total volume",
    "total_encasement_weight": "Total packing weight",
    "total_encasement_count": "Total packing quantity",
    "tracking_no": "Tracking number",
    "track": "Tracking",
    "has_been_operating": "Handled",
    "local_time": "Local Time",
    "appointment_no": "Reservation Number",
    "appointment_time": "Reservation Date",
    "container_no": "Container Number",
    "container_type": "Container Type",
    "plan_num": "Including Inbound Plan Quantity",
    "driver_telephone": "Driver's Contact Information",
    "contact_information": "联系方式",
    "area_code": "区号",
    "phone_number": "号码",
    "please_enter_the_correct_number": "请输入正确的号码",
    "appointment_number_or_cabinet_number": "Reservation Number or Container Number",
    "additional_packing_material_selection": "Additional packaging material options",
    "average_unit_price": "Average unit price",
    "transport_frees": "Shipping fee",
    "cws_box_label": "SE label",
    "timed_out": "Timed Out",
    "customs_clearance_info": "Customs clearance info",
    "chinese_trade_name": "Chinese product name",
    "english_trade_name": "English product name",
    "declare_price": "Declared Price",
    "tracking_ticket_no": "Tracking Number",
    "charge": "Charges",
    "tooltip_text_1": "User definded product quantity per carton.",
    "tooltip_text_2": "User definded dimension and weight info.",
    "tooltip_text_3": "Warehouse or Logistics Center scanned dimension and weight info.",
    "tooltip_text_4": "User definded dimension info.",
    "tooltip_text_5": "User definded weight info.",
    "tooltip_text_6": "No any surcharges included in estimated freight.",
    "customer_number": "Client Order Number",
    "system_number": "System Order Number",
    "originating_country": "Origin Country",
    "operating_state": "Processing Status",
    "shipping_lines": "Shipping Route",
    "number": "Quantity",
    "channel_name": "Channel Name",
    "turn_single_track": "Tracking Route",
    "encasement_info": "Packing info",
    "check_the_track": "Check Tracking Info",
    "size": "Dimension",
    "girth": "Girth",
    "total_weight": "Total Weight",
    "plan_no_or_company_id": "Plan Number or Company ID",
    "consignment_code": "Distribution code",
    "consignment_warehouse_code": "Distribution warehouse-warehouse code",
    "storage_code": "Stock warehouse code",
    "plan_no": "Plan number",
    "planned_quantity": "Planned quantity",
    "planned_box_quantity": "Planned carton quantity",
    "product_quantity": "Product quantity",
    "receive_box_quantity": "Received carton quantity",
    "receive_quantity": "Received quantity",
    "storage_in_plan_no": "Inbound number of stock warehouse",
    "company_id": "Company ID",
    "allot_to_storage_plan_detail": "Allocation plan details to stock warehouse",
    "packaging_material_name": "Packaging material name",
    "packaging_box_weight": "Packing carton weight",
    "materials": "Material",
    "outer_diameter_size": "External dimension",
    "material_fee": "Material fee",
    "total_weight_after_packing": "Total packing up weight",
    "space_utilization": "Space utilization",
    "max_capacity_number": "Maximum capacity quantity",
    "allot_amount": "Allocation quantity",
    "product_label": "Product Label",
    "not_select_service": "Choose no service",
    "weight_after_packing": "Packing up weight",
    "p0_a_product": "{0} unit products.",
    "whether_to_keep_the_outer_packaging": "If retain the package material?",
    "need_to_keep_the_outer_packaging": "Need to retain the package material.",
    "do_not_keep_the_outer_packaging": "No need to retain the outer package.",
    "keep_the_outer_packaging": "Retain the package material.",
    "order_status": "Order Status",
    "warehouse_operation_status": "Warehouse Handling Status",
    "verification_number": "Verifying Number",
    "raw_data": "Origin Data",
    "correct_data": "Modified Data",
    "processing_time": "Processing Time",
    "outbound_time": "Outbound Time",
    "in_warehouse_packaging_size": "Inbound packing specification",
    "in_warehouse_packaging_weight": "Inbound packing weight",
    "await_in_warehouse_count": "Quantity wating for stocking in",
    "on_warehouse_usable_count": "Available quantity in stock",
    "await_out_warehouse_count": "Quantity to be stocked out",
    "encasement_specification": "Packing specification",
    "packaging_size": "Packaging specification",
    "product_gross_weight": "Product gross weight",
    "product_dimensions": "产品尺寸",
    "product_of_size": "产品包装尺寸",
    "product_weight": "Product Weight",
    "product_contains_the_weight_of_the_package": "产品含包装的重量",
    "product_no": "Product Item No.",
    "product_imag": "Product image",
    "storage_logistics_info": "Stocking and logistics info",
    "battery_type": "Battery type",
    "battery_ingredient": "Battery components",
    "battery_capacity": "Battery capacity",
    "packaging_weight": "Packaging weight",
    "customs_declare_info": "Customs declared info",
    "english_texture": "Texture in English",
    "chinese_texture": "Texture in Chinese",
    "texture": "Texture",
    "customs_no": "HS code",
    "can_identification_content": "Recognizable content",
    "survey_product_packing_data": "Measure product's packaging data.",
    "general_survey_size": "General measuring dimension",
    "general_survey_weight": "General measuring weight",
    "general_survey_img": "General measuring pictures",
    "survey_size": "Measured dimension",
    "survey_weight": "Measured weight",
    "error_customs_declaration": "Data line of Customs Application Form must be completed.",
    "at_least_one_survey_map_is_required": "At lease one measuring picture required.",
    "weight_measurement_needs_to_be_greater_than_0": "Measuring Weight must be more than 0.",
    "consignment_warehouse_verified_packaging_size": "Verified packaging dimension in distribution warehouse",
    "consignment_warehouse_verified_packaging_weight": "Verified packaging weight in distribution warehouse",
    "please_input_search_shop_name_or_number_or_id": "请输入公司名称或编号或标识",
    "please_input_search_product_no_name": "Please input product SESKU, name.",
    "user_defined_box_no": "User-defined Carton Number",
    "error_message": "Incorrect Info",
    "customer": "Client",
    "total_cost": "Total Charges",
    "warehouse_discount": "仓库折扣",
    "company": "公司",
    "name": "Name",
    "add_discount": "新增折扣",
    "print_sesku_lable": "Print SE label",
    "download_se_lable": "下载SE标签",
    "packing_material_discount": "包装材料折扣",
    "operating_cost_discount": "操作费折扣",
    "storage_charge_discount": "仓储费折扣",
    "fee_code": "费用代码",
    "material_type": "材质类型",
    "packing_material_size": "包装材料规格",
    "packing_capacity": "包装容量",
    "packing_load_bearing": "包装承重",
    "unit": "单位",
    "price": "价格",
    "discount": "折扣比例",
    "discount_price": "折扣价",
    "product_lable_name": "产品标签名称",
    "the_code_cannot_be_recognized_please_change_the_file": "无法识别编码,请更换文件",
    "application_logo": "应用图标",
    "application_name": "应用名称",
    "application_id": "应用ID",
    "application_description": "应用描述",
    "call_back_address": "回调地址",
    "set_the_authority": "设置权限项",
    "reset_key": "重置密钥",
    "app_secret": "应用密匙",
    "whether_to_confirm_the_reset_key": "是否确认重置密钥？",
    "app_secret_tips_1": "应用创建成功，请妥善保管应用密匙！",
    "app_secret_tips_2": "重置密钥成功，请妥善保管应用密匙！",
    "create_application": "创建应用",
    "edit_application": "编辑应用",
    "application": "应用",
    "authorized_tenant": "授权租户",
    "details_of_authorization_items": "授权项详情",
    "authorized_application_tenants_permissions": "授权应用租户权限",
    "cancel_the_authorization": "取消授权",
    "do_you_confirm_the_cancellation_authorization": "是否确认取消授权？",
    "authorized_customers": "授权客户",
    "please_check_at_least_one_item": "请至少勾选一项",
    "relation_no": "相关单号",
    "cancel_shipment_processing": "取消发货- 处理中",
    "cancel_shipment_succeed": "取消发货- 成功",
    "cancel_shipment_unsuccessful": "取消发货- 失败",
    "change_the_partner": "变更合作伙伴",
    "new_partner": "新合作伙伴",
    "old_partner": "旧合作伙伴",
    "purpose_description": "用途",
    "brand_info": "品牌信息",
    "brand_name": "品牌名称",
    "have_name": "有品牌",
    "no_brand": "无品牌",
    "purpose_chinese_description": "中文用途",
    "purpose_english_description": "英文用途",
    "max_volume_weight": "最大容重",
    "package_identification_information": "包裹识别信息",
    "temporary_storage_location_number": "暂存库位编号",
    "staging_time": "暂存时间",
    "staging_days": "暂存天数",
    "staging_operator": "暂存操作人",
    "no_matching_return_information": "无匹配的退货信息",
    "matched_return_information": "已匹配退货信息",
    "return_operation_completed": "已完成退货操作",
    "stock_transfer": "移库",
    "process_return_task": "处理退货任务",
    "package": "包裹",
    "day": "天",
    "no_return_temporary_storage_fee_within_p0_days": "{0}天内免退货暂存费",
    "p0_p1_will_calculate_the_return_temporary_deposit_fee": "{0}-{1}日将计算退货暂存费",
    "return_information_that_does_not_match_for_more_than_p0_days_can_be_destroyed": "超过{0}日无匹配的退货信息可销毁",
    "compensation": "赔付",
    "amount_of_compensation": "赔付金额",
    "whether_to_refund": "是否退款",
    "whether_to_compensate": "是否赔付",
    "please_enter_the_correct_amount": "请输入正确的金额",
    "is_it_abnormal": "Is it abnormal?",
    "abnormal": "Abnormal",
    "no_abnormality": "No Abnormality",
    "consolidated_packing_fee": "合并包装费用",
    "reconciliation": "对账",
    "reconciliation_type": "对账类型",
    "place_of_departure": "起运地",
    "select_import_file": "选择导入文件",
    "click_download_template": "点击下载模板",
    "number_of_waybills": "运单件数",
    "reconciliation_quantity": "对账件数",
    "billing_weight_of_waybill": "运单计费重",
    "reconciliation_billing_weight": "对账计费重",
    "reconciliation_amount": "对账金额",
    "order_amount": "订单金额",
    "profit_and_loss_amount": "盈亏金额",
    "number_of_successful_matches": "匹配成功数量",
    "number_of_matching_failures": "匹配失败数量",
    "is_reconciliation": "是否对账",
    "reconciliation_time": "对账时间",
    "reconciliation_information": "对账信息",
    "labels_cannot_be_scanned_handling_expense": "Label can not be scanned. Handling Fee",
    "standby_identification1": "备用识别信息1",
    "standby_identification2": "备用识别信息2",
    "cancel_reconciliation": "取消对账"
  }
}
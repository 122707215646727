<template>
  <a-card :title="$t('warehouse.track_info')" :loading="state.loading">
    <a-row
      class="pt-2 pb-2"
      :gutter="[16, 8]"
      v-for="(item, index) in state.list"
      :key="index"
    >
      <a-col flex="0 0 250px">
        <template v-if="item.trackStatus <= shippingSpaceStatusEnum.leavePort">
          <span>{{ $filters.utcToSpecificTime(item.trackTime, item.fromTimezone, "yyyy-MM-DD") }}</span>
          <span class="pl-2">{{ getWeek($filters.utcToSpecificTime(item.trackTime, item.fromTimezone, "yyyy-MM-DD")) }}</span>
        </template>
        <template v-else>
          <span>{{ $filters.utcToSpecificTime(item.trackTime, item.toTimezone, "yyyy-MM-DD") }}</span>
          <span class="pl-2">{{ getWeek($filters.utcToSpecificTime(item.trackTime, item.toTimezone, "yyyy-MM-DD")) }}</span>
        </template>
      </a-col>
      <a-col flex="0 0 300px">
        <span>【{{ item.trackStatus <= shippingSpaceStatusEnum.leavePort ? item.fromIOS2 : item.toIOS2 }}】</span>
        <span>{{ $t($enumLangkey('shippingSpaceStatus', item.trackStatus)) }}</span>
      </a-col>
      <a-col flex="1">
        <span v-if="item.etdTime">ETD {{ $filters.utcToSpecificTime(item.etdTime, item.fromTimezone, "yyyy-MM-DD") }}</span>
        <span v-else-if="item.eta">ETA {{ $filters.utcToSpecificTime(item.eta, item.toTimezone, "yyyy-MM-DD") }}</span>
      </a-col>
    </a-row>
    <a-empty v-if="state.list.length === 0" :image="state.simpleImage" />
  </a-card>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import { Card, Col, Empty, Row } from 'ant-design-vue';
import moment from 'moment-timezone';
import { useStore } from 'vuex';
import { shippingSpaceStatus as shippingSpaceStatusEnum } from '@/enum/enum.json';

export default defineComponent({
  expose: ['getList'],
  components: {
    ACard: Card,
    ARow: Row,
    ACol: Col,
    AEmpty: Empty,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => ([]),
    },
  },
  setup (props) {
    const { getters } = useStore();

    const state = reactive({
      loading: false,
      list: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    })

    watch(() => [props.loading, props.list], ([loading, list]) => {
      if (typeof loading === 'boolean') {
        state.loading = loading;
      }
      if (Array.isArray(list)) {
        state.list = list;
      }
    }, { immediate: true, deep: true })

    const getWeek = (date) => {
      if (!date) {
        return '-'
      }
      return moment(date).locale(getters.language).format('dddd');
    }

    return {
      state,
      shippingSpaceStatusEnum,
      getWeek,
    }
  }
})
</script>

<style scoped>
</style>
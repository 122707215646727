<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.logistics_channel_discount') }}
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px;" v-model:value="searchState.name" :placeholder="$t('logistics.logistics_scheme_name')" allow-clear></a-input>
            </a-col>
            <a-col>
              <a-input style="width: 250px;" v-model:value="searchState.code" :placeholder="$t('finance.channel_code')" allow-clear></a-input>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchState.shopLevelId"
                :loading="shopLevelLoading"
                :placeholder="$t('logistics.customer_level')"
                option-filter-prop="search-key"
                show-search
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in shopLevelList"
                  :key="index"
                  :value="item.id"
                  :title="item.name"
                  :search-key="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button @click="handleShowEditModal()">{{ $t('common.create') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 110 }"
        :rowKey="(record) => record.id"
        :loading="tableData.loading"
      >
        <template #logisticsName="{ record }">
          <div>{{ record.logisticsName }}</div>
          <a-tag color="green" v-if="record.isActive">{{ $t('logistics.enabled') }}</a-tag>
          <a-tag color="red" v-else>{{ $t('logistics.disabled') }}</a-tag>
        </template>
        <template #freightDiscountAmount="{ record }">
          {{ $filters.formatCurrencyAmount(record.currencySymbol, record.freightDiscountAmount, false) }}
        </template>
        <template #freightDiscountRatio="{ record }">
          {{ record.freightDiscountRatio }}%
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #lastModificationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown-button @click="handleShowEditModal(record.id)">
            {{ $t('common.edit') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :key="1" :record="record">{{ $t('common.delete') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>

      <EditModal v-model:visible="editModalState.visible" :id="editModalState.id" @confirm="handleSearch" />
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Col, Button, Row, Table, Input, Select, Dropdown, Menu, Modal, message, Tag } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import EditModal from './components/EditModal.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from 'vuex';
import { getName } from "@/utils/general.js";
import { getPagedList, deleteLogisticsDiscount } from "@/api/modules/logistics/discount.js";
import { getShopLevelList } from "@/api/modules/customer/level/index.js";

export default defineComponent({
  name: 'logistics_channel_discount',
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATable: Table,
    ATag: Tag,
    Content,
    CPager,
    EditModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const state = reactive({
      searchState: {
        name: null,
        code: null,
        shopLevelId: null,
      },
      searchStateCache: {},
      shopLevelLoading: false,
      shopLevelList: [],
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    })

    const editModalState = reactive({
      visible: false,
      id: null,
    })

    const columns = [
      {
        width: 180,
        title: () => vueI18n.t('logistics.logistics_scheme_name'),
        slots: {
          customRender: 'logisticsName'
        }
      },
      {
        dataIndex: "logisticsCode",
        width: 150,
        title: () => vueI18n.t('finance.channel_code'),
      },
      {
        dataIndex: "shopLevelName",
        width: 150,
        title: () => vueI18n.t("logistics.customer_level"),
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.discount_amount"),
        slots: {
          customRender: "freightDiscountAmount"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.discount"),
        slots: {
          customRender: "freightDiscountRatio"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.last_modification_time"),
        slots: {
          customRender: "lastModificationTime",
        },
      },
      {
        width: 150,
        slots: {
          customRender: "operation",
        },
      },
    ]

    const getLanguageName = (cnName, enName) => {
      return getName({ cnName, enName }, getters.language);
    }

    const handleShowEditModal = (id = null) => {
      editModalState.id = id;
      editModalState.visible = true;
    }

    const handleDeleteItem = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operate"),
        content: vueI18n.t("common.are_you_sure_delete"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return deleteLogisticsDiscount({ ids: [id] }).then(() => {
            message.success(vueI18n.t("common.succeed"));
            handleSearch();
          });
        },
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;

      switch (key) {
        case 1:
          handleDeleteItem(record.id);
          break;
        default:
          break;
      }
    }

    const getList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache,
      );
      getPagedList(data)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      initData();
    };

    const initData = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getList();
    };

    const getShopLevelListArray = () => {
      state.shopLevelLoading = true;
      getShopLevelList().then(({ result }) => {
        if (Array.isArray(result)) {
          state.shopLevelList = result;
        } else {
          state.shopLevelList = [];
        }
      }).catch().finally(() => {
        state.shopLevelLoading = false;
      })
    }

    onMounted(() => {
      getShopLevelListArray();
      initData();
    });

    return {
      ...toRefs(state),
      editModalState,
      columns,
      getLanguageName,
      handleShowEditModal,
      handleMenuClick,
      handleSearch,
      handlePage,
    }
  }
})
</script>

<style scoped>

</style>
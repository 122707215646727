export default {
  account: {
    essential_information: "基本信息",
    user_name: "用户名称",
    timezone: "时区",
    mobile_phone: "手机",
    last_login_time: "上次登陆时间",
    last_login_ip: "上次登陆IP",
    secure_mailbox: "安全邮箱",
    account_security: "账户安全",
    change_password: "修改密码",
    original_password: "原密码",
    new_password: "新密码",
    enter_the_new_password_again: "二次输入新密码",
    please_input: "请输入",
    the_passwords_entered_twice_do_not_match: "两次输入的密码不匹配",
    password_modified_successfully: "密码修改成功",
    amend: "修改",
    employee_number: "员工编号",

    all: "全部",
    in_the_activity: "活动中",

    company_name: "公司名称",
    third_party_company_name: "三方公司名称",
    international_area_code: "国际区号",
    company_phone: "公司电话",
    registration_documents: "注册文件",
    register_id: "注册号",
    company_id: "公司标识",

    the_head_of_a_company: "公司负责人",

    country: "所在国家",
    registration_date: "注册时间",
    approval_time: "审核通过时间",
    last_login_int: "最后登录时间",
    account_status: "账户状态",
    audit_status: "审核状态",
    business_manager: "业务负责人",
    service: "客服",
    operator: "操作人",

    audit: "审核",
    audit_result: "审核结果",

    freeze: "冻结",
    unfreeze: "解冻",

    account_audit: "账户审核",
    pass: "通过",
    reject: "拒绝",
    business_license: "营业执照",
    account_principal: "账户负责人",
    phone: "联系电话",
    audit_result_placeholder:'审核未通过的原因或审核通过的备注',
    account_balance: "账户余额",
    overdraft_limit: "透支限额",
    rxpire_date: "到期时间",
    
    overdraft_status: "透支状态",
    log_in_time: "登录时间",
    time: "时间",
    quota_adjustment_record: "额度调整记录",
    amount: "金额",
    adjustment_type: "调整类型",

    account_name: "账户名",
    locked: "已锁定",
    locking: "锁定",
    unlock: "解锁",

    login_account: "登录账号",
    you_are_performing_a_delete_operation: "您正在执行删除操作",

    update_timezone: "修改时区",
    choose_timezone: "选择时区",
    reset_pin_code: "重置Pin码",
    are_you_sure_reset_pin_code: "是否确认重置Pin码？",

    user_roles: "用户角色",
    account: "账户",
    role: "角色",
    refund: "退款",
    the_refund_amount: "退款金额",

    total_transaction_amount: "交易总金额",
    total_consumption: "总消费",
    total_top_up: "总充值",
    whether_the_money_owed: "是否欠款",
    responsible_for_account: "负责账号",
    view_registration_files: "查看注册文件",
    limit_adjustment: "额度调整",
    adjusting_records: "调整记录",
    account_security: "账号安全",
    account_adjustment: "账户调整",
    account_type: "账户类型",
    offline_services: "线下服务",
    department_administrator: "部门管理员",
    contract_status : "合同签署",
    signed: "已签署",
    unsigned: "未签署",
    agent: "代理",
    administrator: "管理员",
    customer_number: "客户编号",
  },
};

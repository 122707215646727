<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.transit_in_plan_detail") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="state.loading">
        <a-row>
          <a-col :span="20">
            <a-row class="mt-3" :gutter="[0, 12]">
              <a-col :span="24"
                >{{ $t("warehouse.warehousing_reservation_number") }}:
                {{ state.planNo }}</a-col
              >
              <a-col :span="8"
                >{{ $t("warehouse.transfer_warehouse") }}:
                {{ state.warehouseAddress?.warehouseNo }}</a-col
              >
              <a-col :span="16">
                {{ $t("warehouse.warehouse_address") }}:
                {{ getAddress() }}
                <span class="ml-2" v-if="state.warehouseAddress">
                  {{ $t("logistics.contacts") }}:
                  {{ getLinkman(state.warehouseAddress) }}
                </span>
              </a-col>
              <a-col :span="8">
                {{ $t("warehouse.type_of_shipping") }}:
                {{
                  $t(
                    $enumLangkey(
                      "transportationMethod",
                      state.transportationMethod
                    )
                  )
                }}
              </a-col>
              <a-col
                :span="8"
                v-if="
                  state.transportationMethod ==
                  transportationMethodEnum.commercialExpress
                "
              >
                {{ $t("warehouse.delivery_way") }}:
                {{ $t($enumLangkey("deliveryWay", state.deliveryType)) }}
              </a-col>
              <a-col
                :span="8"
                v-if="
                  state.transportationMethod ==
                  transportationMethodEnum.commercialExpress
                "
              >
                {{ $t("warehouse.tracking_number") }}: {{ state.trackingNo }}
              </a-col>
              <template v-if="
                state.transportationMethod ==
                transportationMethodEnum.container
              ">
                <a-col :span="8">
                  {{ $t("warehouse.container_type") }}: 
                  <span v-if="state.containerType">
                    {{
                      $t(
                        $enumLangkey(
                          "containerType",
                          state.containerType
                        )
                      )  
                    }}
                  </span>
                  <span v-else>-</span>
                </a-col>
                <a-col :span="8">
                  {{ $t("warehouse.container_no") }}: {{ state.containerNo }}
                </a-col>
              </template>
              <a-col :span="8">
                {{ $t("warehouse.estimated_arrival_date") }}:
                {{
                  $filters.utcToSpecificTime(
                    state.wareZoneAppointmentDate,
                    state?.wareTimeZone
                  )
                }}
                <a-tag color="warning" class="mt-1 ml-1">{{
                  $t("warehouse.local_time")
                }}</a-tag>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="4">
            <div class="text-center mt-4">
              <strong>
                {{ $t("warehouse.status") }}
              </strong>
              <div class="mt-3">
                <strong class="text-success">
                  {{ $t($enumLangkey("transitStatus", state.transitStatus)) }}
                </strong>
              </div>
            </div>
          </a-col>
        </a-row>

        <a-row type="flex" justify="space-between" align="middle" class="mt-3">
          <a-col>
            <a-tabs v-model:activeKey="state.tab" @change="handleChangeTab">
              <a-tab-pane
                key="packingList"
                :tab="$t('warehouse.container_loading_list')"
              >
              </a-tab-pane>
              <a-tab-pane
                key="shipmentList"
                :tab="$t('warehouse.shipment_list')"
              >
              </a-tab-pane>
            </a-tabs>
          </a-col>
        </a-row>

        <!-- 装箱清单 -->
        <div v-show="state.tab == 'packingList'">
          <a-row :gutter="[16, 16]" class="mb-1">
            <a-col>
              <a-input
                v-model:value="state.searchDataPacking.shipmentName"
                :placeholder="$t('warehouse.shipment_name')"
                :allowClear="true"
                style="width: 200px"
              />
            </a-col>
            <a-col>
              <a-input
                v-model:value="state.searchDataPacking.searchKey"
                :placeholder="
                  $t('warehouse.scannable_box_no') +
                  ',' +
                  $t('warehouse.box_id')
                "
                :allowClear="true"
                style="width: 200px"
              />
            </a-col>
            <a-col>
              <a-select
                style="width: 150px"
                v-model:value="state.searchDataPacking.containerBoxStatus"
                :placeholder="$t('warehouse.box_status')"
                allow-clear
              >
                <a-select-option
                  v-for="(value, key) in boxStatusEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('boxStatus', value))"
                >
                  {{ $t($enumLangkey("boxStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 150px"
                v-model:value="state.searchDataPacking.boxType"
                :placeholder="$t('warehouse.box_type')"
                allow-clear
              >
                <a-select-option
                  v-for="(value, key) in boxTypeEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('boxType', value))"
                >
                  {{ $t($enumLangkey("boxType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleSearchPackingList">{{
                $t("common.query")
              }}</a-button>
            </a-col>
          </a-row>

          <a-table
            :columns="columnsPackingList"
            :data-source="state.packingList"
            :scroll="{ x: true, y: 900 }"
            :pagination="false"
            size="small"
            :loading="state.tableLoading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #canScanedBoxNoTitle>
              {{ $t("warehouse.scannable_box_no") }}
              <a-tooltip>
                <template #title>
                  {{ $t("warehouse.scannable_box_no_hint") }}
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </template>

            <template #shipment_nameTitle>
              {{ $t("warehouse.shipment_name") }}
              <div>
                {{ $t("warehouse.se_shipment_number") }}
              </div>
            </template>

            <template #shipment_name="{ record }">
              {{ record.name }}
              <div>
                {{ record.seShipmentNo }}
              </div>
            </template>

            <template #representedShop="{ record }">
              <template v-if="record.representedShop">
                <div>{{ record.representedShop.shopName }}</div>
                <div>{{ record.representedShop.shopNo || '-' }}</div>
              </template>
              <span v-else>-</span>
            </template>

            <template #forecast_data="{ record }">
              {{ record.containerBoxSize?.length }} x
              {{ record.containerBoxSize.width }} x
              {{ record.containerBoxSize.height }} cm |
              {{ cmCubicToM(record.containerBoxSize.volume) }} m<sup>3</sup>
              <div>{{ gToKg(record.containerBoxSize.weight) }} kg</div>
            </template>

            <template #inbound_data="{ record }">
              <div v-if="record.checkBoxSize?.length">
                {{ record.checkBoxSize?.length }} x
                {{ record.checkBoxSize?.width }} x
                {{ record.checkBoxSize?.height }} cm |
                {{ cmCubicToM(record.checkBoxSize?.volume) }}m<sup>3</sup>
                <div>{{ gToKg(record.checkBoxSize?.weight) }} kg</div>
              </div>
              <div v-else>-</div>
            </template>

            <template #inbound_data_img="{ record }">
              <div
                v-if="
                  record.checkBoxSize?.imgs &&
                  record.checkBoxSize?.imgs?.length > 0
                "
              >
                <a-popover>
                  <template #content>
                    <a-space>
                      <div
                        v-for="(imgItem, index) in record.checkBoxSize?.imgs"
                        class="table-list-img-common"
                        :key="index"
                      >
                        <c-image
                          :src="imgItem"
                          :ossHeight="800"
                          :ossWidth="800"
                        />
                      </div>
                    </a-space>
                  </template>
                  <span class="text-primary" style="font-size: 20px">
                    <FileImageOutlined />
                  </span>
                </a-popover>
              </div>
              <span v-else>-</span>
            </template>

            <template #box_type="{ record }">
              <!-- 常規 -->
              <a-tag
                color="green"
                v-if="record.boxType == boxTypeEnum.conventional"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超规 -->
              <a-tag
                color="warning"
                v-if="record.boxType == boxTypeEnum.overgauge"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超大件 -->
              <a-tag
                color="error"
                v-if="record.boxType == boxTypeEnum.oversizeUnit"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
            </template>

            <template #box_status="{ record }">
              {{ $t($enumLangkey("boxStatus", record.containerBoxStatus)) }}
            </template>

            <template #operateCustom="{ record }">
              <a-spin
                :spinning="!(record.loading == undefined) && record.loading"
              >
                <a-button @click="handlePrintBoxSETB(record)">
                  {{ $t("warehouse.print_SETB_labels") }}
                </a-button>
              </a-spin>
            </template>
          </a-table>
          <a-row
            type="flex"
            justify="center"
            class="mt-3"
            v-if="state.tab == 'packingList'"
          >
            <a-col>
              <CPager
                @handlePage="handlePagePackingList"
                :page-data="state.packingListPageData"
              ></CPager>
            </a-col>
          </a-row>
        </div>

        <!-- 货件列表 -->
        <div v-show="state.tab == 'shipmentList'">
          <a-row :gutter="[16, 16]" class="mb-1">
            <a-col>
              <a-input
                v-model:value="state.searchDataShipment.name"
                :placeholder="$t('warehouse.shipment_name')"
                :allowClear="true"
                style="width: 200px"
              />
            </a-col>
            <a-col>
              <a-input
                v-model:value="state.searchDataShipment.seShipmentNo"
                :placeholder="$t('warehouse.se_shipment_number')"
                :allowClear="true"
                style="width: 200px"
              />
            </a-col>

            <a-col>
              <a-button type="primary" @click="handleSearchShipmentList">{{
                $t("common.query")
              }}</a-button>
            </a-col>
          </a-row>

          <a-table
            :columns="columnsShipmentList"
            :data-source="state.shipmentList"
            :scroll="{ x: true, y: 600 }"
            :pagination="false"
            size="small"
            :loading="state.tableLoading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #box_amount="{ record }">
              {{ record?.boxCount?.plan }}
            </template>
            <template #representedShop="{ record }">
              <template v-if="record.representedShop">
                <div>{{ record.representedShop.shopName }}</div>
                <div>{{ record.representedShop.shopNo || '-' }}</div>
              </template>
              <span v-else>-</span>
            </template>
            <template #volume="{ record }">
              {{ cmCubicToM(record?.volume?.plan) }}
              <span v-if="record?.volume?.plan">m<sup>3</sup></span>
            </template>
            <template #weight="{ record }">
              {{ gToKg(record.weight.plan) }}
              <span v-if="record.weight.plan">kg </span>
            </template>

            <template #operate>
            </template>
            <template #operateCustom="{ record }">
              <a-spin
                :spinning="!(record.loading == undefined) && record.loading"
              >
                <a-dropdown-button
                  @click="handleShowShipmentDetailModal(record)"
                >
                  {{ $t("common.details") }}
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="3"
                        @click="handlePrintShipmentInSETBAll(record)"
                      >
                        {{
                          $t("warehouse.print_SETB_labels_inside_the_shipment")
                        }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown-button>
              </a-spin>
            </template>
          </a-table>
          <a-row
            type="flex"
            justify="center"
            class="mt-3"
            v-if="state.tab == 'shipmentList'"
          >
            <a-col>
              <CPager
                @handlePage="handlePageShipmentList"
                :page-data="state.shipmentListPageData"
              ></CPager>
            </a-col>
          </a-row>
        </div>

        <!-- 费用明细 -->
        <div class="mt-3">
            <CFee :fees="state.cost?.fees" :totalFee="state.cost?.totalFee"></CFee>

          <a-row type="flex" justify="end" class="mt-2 mb-5">
            <a-col>
              <div style="width: 500px">
                <a-row type="flex" justify="center" :gutter="[24, 12]">
                  <!-- <a-col>
                    <a-popconfirm
                      v-if="state.cancelled"
                      :title="$t('common.are_you_sure')"
                      @confirm="handleCancelPlan"
                    >
                      <a-button class="ml-5">{{
                        $t("warehouse.cancel_plan")
                      }}</a-button>
                    </a-popconfirm>
                  </a-col> -->
                  <a-col>
                    <router-link :to="{ name: 'warehouse_monitor_transit_inbound' }">
                      <a-button class="ml-5">{{
                        $t("common.backtrack")
                      }}</a-button>
                    </router-link>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>

        <!-- 货件详情 -->
        <a-modal
          width="900px"
          v-model:visible="shipmentDetailModal.visible"
          :title="
            shipmentDetailModal.isDelete
              ? $t('warehouse.delete_shipment')
              : $t('warehouse.detail_shipment')
          "
          :cancelText="$t('common.close')"
          :okText="$t('common.confirm')"
          :confirm-loading="shipmentDetailModal.loading"
          :maskClosable="false"
          :okButtonProps="{ style: { display: 'none' } }"
          :cancel-button-props="{ style: { display: 'none' } }"
          :centered="true"
        >
          <div>
            {{ $t("warehouse.shipment_name") }}:{{ shipmentDetailModal.name }}
            <span class="ml-5"
              >{{ $t("warehouse.se_shipment_number") }}:{{
                shipmentDetailModal.seShipmentNo
              }}</span
            >
          </div>

          <a-table
            class="mt-3"
            :columns="columnsShipmentDetail"
            :data-source="shipmentDetailModal.list"
            :scroll="{ x: true, y: 600 }"
            :pagination="false"
            size="small"
            :loading="shipmentDetailModal.loading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #predictionSize="{ record }">
              {{ record.containerBoxSize?.length }} x
              {{ record.containerBoxSize?.width }} x
              {{ record.containerBoxSize?.height }} cm
            </template>
            <template #predictionWeight="{ record }">
              <span v-if="record.weight"
                >{{ gToKg(record.containerBoxSize?.weight) }} kg</span
              >
              <span v-else>-</span>
            </template>
            <template #status="{ record }">
              <span v-if="record.containerBoxStatus">
                {{ $t($enumLangkey("boxStatus", record.containerBoxStatus)) }}
              </span>
            </template>

            <template #boxType="{ record }">
              <!-- 常規 -->
              <a-tag
                color="green"
                v-if="record.boxType == boxTypeEnum.conventional"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超规 -->
              <a-tag
                color="warning"
                v-else-if="record.boxType == boxTypeEnum.overgauge"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超大件 -->
              <a-tag
                color="error"
                v-else-if="record.boxType == boxTypeEnum.oversizeUnit"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
            </template>
          </a-table>

          <a-row type="flex" justify="center" class="mt-3">
            <a-col>
              <CPager
                @handlePage="handlePageShipmentDetailModal"
                :page-data="shipmentDetailModal.pageData"
                :showLessItems="true"
                :showQuickJumper="false"
              ></CPager>
            </a-col>
          </a-row>

          <a-row
            type="flex"
            justify="center"
            v-if="shipmentDetailModal.isDelete"
            class="mt-3"
          >
            <a-col class="text-error">
              {{
                $t(
                  "warehouse.p0_transfer_warehouse_reservation_delete_prompt",
                  [shipmentDetailModal.pageData.totalCount]
                )
              }}
            </a-col>
          </a-row>

          <a-row type="flex" justify="center" :gutter="[64, 12]" class="mt-3">
            <a-col class="text-error" v-if="shipmentDetailModal.isDelete">
              <a-button
                @click="handleShipmentDelete"
                :loading="shipmentDetailModal.deleteLoading"
                >{{ $t("common.delete") }}</a-button
              >
            </a-col>
            <a-col class="text-error">
              <a-button @click="shipmentDetailModal.visible = false">{{
                $t("common.close")
              }}</a-button>
            </a-col>
          </a-row>
        </a-modal>

        <!-- 修改预约时间 -->
        <a-modal
          v-model:visible="updateDateModal.visible"
          :title="$t('warehouse.modify_reservation_time')"
          :cancelText="$t('common.close')"
          :okText="$t('common.confirm')"
          :confirm-loading="updateDateModal.loading"
          :maskClosable="false"
          @ok="handleSaveUpdateDateModal"
          :centered="true"
        >
          <a-form-item
            class="mb-0"
            :label="$t('warehouse.estimated_arrival_date')"
          >
            <a-date-picker
              :disabled="!updateDateModal.canBeModified"
              :disabled-date="disabledDate"
              :disabled-time="disabledDateTime"
              v-model:value="updateDateModal.wareZoneAppointmentDateMoment"
              :show-time="{
                format: 'HH:00',
                defaultValue: moment(
                  new Date().getHours() + 1 + ':00:00',
                  'HH:mm'
                ),
              }"
            />

            <a-tag color="warning" class="mt-1 ml-1">{{
              $t("warehouse.local_time")
            }}</a-tag>
            <div class="mt-2 text-warning">
              {{ $t("warehouse.modify_reservation_time_text1") }}
            </div>
          </a-form-item>
        </a-modal>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import moment from "moment-timezone";
import { useI18n } from "vue-i18n/index";
import { computed, defineComponent, reactive, onMounted } from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  Form,
  DatePicker,
  Space,
  Steps,
  Menu,
  Tabs,
  Dropdown,
  Popconfirm,
  Modal,
  Typography,
  Popover,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CImage from "../../components/CImage.vue";
import CFee from "../../components/CFee.vue";
import {
  getAddShipmentDetail,
  getShipmentList,
  getPackingList,
  getPrintBoxSETB,
  getShipmentDetail,
  getPrintShipmentSETableBatch,
  getPrintShipmentSETable,
  getPrintShipmentInSETBAll,
  cancelPlan,
} from "../../../api/modules/warehouseMonitor/transitInPlanDetail";
import {
  transportationMethod as transportationMethodEnum,
  deliveryWay as deliveryWayEnum,
  transitStatus as transitStatusEnum,
  boxStatus as boxStatusEnum,
  boxType as boxTypeEnum,
  transitInBoundErrorCustomerType as transitInBoundErrorCustomerTypeEnum,
} from "../../../enum/enum.json";
import {
  getLinkman,
  getAddressByLanguageV2,
  gToKg,
  kgToG,
  cmCubicToM,
} from "../../../utils/general";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import localPrint from "../../../utils/localPrint";
import { useTab } from "../../../hooks/tabs/index";

export default defineComponent({
  name: "warehouse_monitor_transit_inbound_detail",
  components: {
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ATooltip: Tooltip,
    Content,
    CPager,
    CImage,
    CFee,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ADatePicker: DatePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ASteps: Steps,
    AStep: Steps.Step,
    APopconfirm: Popconfirm,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATypographyText: Typography.Text,
    APopover: Popover,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const { delVisitedRoute } = useTab();
    const route = useRoute();

    const state = reactive({
      loading: false,
      tableLoading: false,
      planId: null,
      warehouseAddress: null,
      transportationMethod: null,
      deliveryType: null,
      trackingNo: null,
      containerType: null,
      containerNo: null,
      loading: false,
      warehouseAddress: null,
      wareZoneAppointmentDate: null,
      wareTimeZone: null, //仓库时区
      transitStatus: null,
      isRepresented: false,
      tab: "packingList",

      packingList: [],
      searchDataPacking: {
        shipmentName: null,
        searchKey: null,
        containerBoxStatus: null,
        boxType: null,
        inWarehouseErrorCustomerType: null,
      },
      packingListPageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },

      shipmentList: [],
      searchDataShipment: {
        name: null,
        seShipmentNo: null,
      },
      shipmentListPageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },

      cost: null,
      wareZoneAppointmentLimitHours: 48,
    });

    const _getPageData = () => {
      return {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      };
    };

    const getAddress = () => {
      if (!state.warehouseAddress) {
        return;
      }
      return getAddressByLanguageV2(state.warehouseAddress, getters.language);
    };

    const handleChangeTab = () => {
      if (state.tab == "packingList") {
        if (!state.packingList || state.packingList?.length == 0) {
          // funcGetPackingList();
          handleSearchPackingList();
        }
      } else {
        if (!state.shipmentList || state.shipmentList?.length == 0) {
          //funcGetShipmentList();
          handleSearchShipmentList();
        }
      }
    };

    //----------------------装箱清单 start---------------------------------------
    const columnsBeforePackingList = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return ((state.packingListPageData.currentIndex - 1) * state.packingListPageData.maxResultCount) + (index + 1);
        },
        fixed: "left",
        width: 60,
      },
      {
        dataIndex: "canScanedBoxNo",
        slots: {
          title: "canScanedBoxNoTitle",
        },
        width: 150,
      },
      {
        slots: {
          title: "shipment_nameTitle",
          customRender: "shipment_name",
        },
        width: 150,
      },
      {
        dataIndex: "boxNo",
        title: () => vueI18n.t("warehouse.box_id"),
        width: 120,
      },
    ];

    const columnsAfterPackingList = [
      {
        title: () => vueI18n.t("warehouse.forecast_data"),
        slots: {
          customRender: "forecast_data",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.inbound_data"),
        slots: {
          customRender: "inbound_data",
        },
        width: 150,
      },
      {
        title: "",
        slots: {
          customRender: "inbound_data_img",
        },
        width: 60,
      },
      {
        title: () => vueI18n.t("warehouse.box_type"),
        slots: {
          customRender: "box_type",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_status"),
        slots: {
          customRender: "box_status",
        },
        width: 100,
      },
      {
        dataIndex: "operate",
        title: () => vueI18n.t("common.operate"),
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
        width: 150,
      },
    ];

    const columnsPackingList = computed(() => {
      if (state.isRepresented) {
        return [
          ...columnsBeforePackingList,
          {
            width: 120,
            title: () => vueI18n.t("warehouse.third_party_customer"),
            slots: {
              customRender: "representedShop",
            },
          },
          ...columnsAfterPackingList,
        ];
      }
      return [
        ...columnsBeforePackingList,
        ...columnsAfterPackingList,
      ];
    });

    //打印-----------------------------
    const handlePrintBoxSETB = (record) => {
      let pdfUrl = getPrintBoxSETB({ ...record, planId: state.planId });
      localPrint(pdfUrl);
    };

    const handlePagePackingList = async (pageData) => {
      state.packingListPageData.skipCount = pageData.skipCount;
      state.packingListPageData.maxResultCount = pageData.maxResultCount;
      funcGetPackingList();
    };

    const handleSearchPackingList = () => {
      state.packingListPageData.skipCount = 0;
      state.packingListPageData.maxResultCount = 10;
      funcGetPackingList();
    };

    const funcGetPackingList = () => {
      state.tableLoading = true;
      getPackingList({
        planId: state.planId,
        ...state.packingListPageData,
        ...state.searchDataPacking,
      })
        .then((res) => {
          if (res.result) {
            let { items = [], totalCount = 0 } = res.result;
            state.packingList = items;
            state.packingListPageData.totalCount = totalCount * 1;
          }
          state.tableLoading = false;
        })
        .catch(() => {
          state.tableLoading = false;
        });
    };

    //-----------------------装箱清单 end--------------------------------------

    //-----------------------货件列表 start--------------------------------------
    const columnsBeforeShipmentList = [
    {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        dataIndex: "name",
        title: () => vueI18n.t("warehouse.shipment_name"),
        width: 150,
      },
      {
        dataIndex: "seShipmentNo",
        title: () => vueI18n.t("warehouse.se_shipment_number"),
        width: 120,
      },
    ];

    const columnsAfterShipmentList = [
    {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("warehouse.total_volume_of_shipment"),
        slots: {
          customRender: "volume",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.total_weight_of_the_shipment"),
        slots: {
          customRender: "weight",
        },
        width: 150,
      },

      {
        dataIndex: "operate",
        fixed: "right",
        slots: {
          title: "operate",
          customRender: "operateCustom",
        },
        width: 150,
      },
    ];

    const columnsShipmentList = computed(() => {
      if (state.isRepresented) {
        return [
          ...columnsBeforeShipmentList,
          {
            width: 120,
            title: () => vueI18n.t("warehouse.third_party_customer"),
            slots: {
              customRender: "representedShop",
            },
          },
          ...columnsAfterShipmentList,
        ];
      }
      return [
        ...columnsBeforeShipmentList,
        ...columnsAfterShipmentList,
      ];
    });

    const handlePrintShipmentSETableBatch = (record) => {
      let pdfUrl = getPrintShipmentSETableBatch(state.planId);
      localPrint(pdfUrl);
    };

    const handlePrintShipmentSETable = (record) => {
      let pdfUrl = getPrintShipmentSETable({ ...record, planId: state.planId });
      localPrint(pdfUrl);
    };

    const handlePrintShipmentInSETBAll = (record) => {
      let pdfUrl = getPrintShipmentInSETBAll({
        ...record,
        planId: state.planId,
      });
      localPrint(pdfUrl);
    };

    const handlePageShipmentList = async (pageData) => {
      state.shipmentListPageData.skipCount = pageData.skipCount;
      state.shipmentListPageData.maxResultCount = pageData.maxResultCount;
      funcGetShipmentList();
    };

    const handleSearchShipmentList = async (pageData) => {
      state.shipmentListPageData.skipCount = 0;
      state.shipmentListPageData.maxResultCount = 10;
      funcGetShipmentList();
    };

    const funcGetShipmentList = () => {
      state.tableLoading = true;
      getShipmentList({
        planId: state.planId,
        ...state.shipmentListPageData,
        ...state.searchDataShipment,
      })
        .then((res) => {
          if (res.result) {
            let { items = [], totalCount = 0 } = res.result;
            state.shipmentList = items;
            state.shipmentListPageData.totalCount = totalCount * 1;
          }
          state.tableLoading = false;
        })
        .catch(() => {
          state.tableLoading = false;
        });
    };
    //-----------------------货件列表 end--------------------------------------

    //-----------------------货件详情与删除 modal start--------------------------------------
    const columnsShipmentDetail = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        dataIndex: "canScanedBoxNo",
        title: () => vueI18n.t("warehouse.scannable_box_no"),
        width: 100,
      },
      {
        dataIndex: "boxNo",
        title: () => vueI18n.t("warehouse.box_id"),
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.prediction_size"),
        slots: {
          customRender: "predictionSize",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "status",
        },
        width: 100,
      },

      {
        title: () => vueI18n.t("warehouse.box_type"),
        slots: {
          customRender: "boxType",
        },
        width: 100,
      },
    ];

    const shipmentDetailModal = reactive({
      loading: false,
      visible: null,
      name: null,
      seShipmentNo: null,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      isDelete: false,
      deleteLoading: false,
    });

    const handleShowShipmentDetailModal = (record, isDelete) => {
      shipmentDetailModal.visible = true;
      shipmentDetailModal.planId = state.planId;
      shipmentDetailModal.shipmentId = record.shipmentId;
      shipmentDetailModal.name = record.name;
      shipmentDetailModal.seShipmentNo = record.seShipmentNo;
      shipmentDetailModal.isDelete = isDelete ?? false;
      shipmentDetailModal.pageData = _getPageData();
      //这里翻页有bug,第一个翻页选择100条/页.还另1货件, 会显示100条/页,但是实际是_getPageData()---todu.  DUG CY
      funcGetShipmentDetail();
    };

    const funcGetShipmentDetail = (record) => {
      shipmentDetailModal.loading = true;
      getShipmentDetail({
        ...shipmentDetailModal,
        ...shipmentDetailModal.pageData,
      })
        .then(({ result }) => {
          if (result) {
            let { items = [], totalCount = 0 } = result;
            shipmentDetailModal.list = items;
            shipmentDetailModal.pageData.totalCount = totalCount * 1;
          }
          shipmentDetailModal.loading = false;
        })
        .catch(() => {
          shipmentDetailModal.loading = false;
        });
    };

    const handlePageShipmentDetailModal = async (pageData) => {
      shipmentDetailModal.pageData.skipCount = pageData.skipCount;
      shipmentDetailModal.pageData.maxResultCount = pageData.maxResultCount;
      funcGetShipmentDetail();
    };
    //-----------------------货件详情与删除 modal start--------------------------------------

    const funcGetDetailById = () => {
      state.loading = true;
      getAddShipmentDetail(state?.planId)
        .then(({ result }) => {
          Object.assign(state, result);
            state.cost = result?.fee;

          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };

    const handleCancelPlan = () => {
      state.loading = true;
      cancelPlan(state.planId)
        .then(({ result }) => {
          state.loading = false;
          delVisitedRoute(router.currentRoute.value);
          router.push({
            name: "warehouse_monitor_transit_inbound",
          });
        })
        .catch(() => {
          state.loading = false;
        });
    };

    //-----------------修改预约时间 start--------------------

    const updateDateModal = reactive({
      loading: false,
      visible: false,
      wareZoneAppointmentDate: null,
      wareZoneAppointmentDateMoment: null,
      canBeModified: false,
    });

    const handleShowUpdateDateModal = (record) => {
      updateDateModal.visible = true;
      updateDateModal.wareZoneAppointmentDateMoment = null;
      //现在仓库时间与之前预约的仓库时间是否>48
      // console.log(getWareZoneNowMoment().format("YYYY-MM-DD HH:mm:ss"));
      // console.log(state?.wareTimeZone);
      updateDateModal.canBeModified =
        moment
          .tz(new Date(state.wareZoneAppointmentDate), state?.wareTimeZone)
          .diff(getWareZoneNowMoment(), "hours") >=
        state.wareZoneAppointmentLimitHours;
    };

    const handleSaveUpdateDateModal = () => {
      // if (!updateDateModal.canBeModified) {
      //   return (updateDateModal.visible = false);
      // }

      // updateDateModal.wareZoneAppointmentDate = currentTimeToUtc(
      //   updateDateModal.wareZoneAppointmentDateMoment?.format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   ),
      //   state.wareTimeZone
      // );

      // console.log(updateDateModal.wareZoneAppointmentDate);
      // updateDateModal.loading = true;
      // updateAppointmentTime(
      //   state.planId,
      //   updateDateModal.wareZoneAppointmentDate
      // )
      //   .then(({ result }) => {
      //     updateDateModal.visible = false;
      //     updateDateModal.loading = false;
      //     state.wareZoneAppointmentDate =
      //       updateDateModal.wareZoneAppointmentDate;
      //     message.success(vueI18n.t("common.succeed"));
      //   })
      //   .catch(() => {
      //     updateDateModal.loading = false;
      //   });
    };

    //计算仓库时区时间
    const getWareZoneNowMoment = () => {
      var now = moment();
      if (state.wareTimeZone) {
        now = moment().tz(state.wareTimeZone).local(true);
      }
      return now;
    };

    //start:禁用的开始小时
    //end:禁用的结束小时
    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };

    const disabledDate = (current) => {
      return (
        moment(current)
          .endOf("day")
          .startOf("hours")
          .diff(getWareZoneNowMoment(), "hours") <
        state.wareZoneAppointmentLimitHours
      );
    };

    const disabledDateTime = () => {
      return {
        disabledHours: () => {
          var afterXxHours = getWareZoneNowMoment().add(
            state.wareZoneAppointmentLimitHours,
            "hours"
          );
          var hour = afterXxHours.hour();
          if (
            moment(updateDateModal.wareZoneAppointmentDateMoment).isSame(
              afterXxHours,
              "day"
            )
          ) {
            return range(0, hour + 1);
          } else {
            return range(0, 0);
          }
        },
      };
    };
    //-----------------修改预约时间 end--------------------

    onMounted(() => {
      state.planId = route.params.id;
      if (state?.planId) {
        funcGetDetailById();
        handleChangeTab();
      }
    });

    return {
      state,
      transportationMethodEnum,
      deliveryWayEnum,
      transitStatusEnum,
      boxStatusEnum,
      boxTypeEnum,
      transitInBoundErrorCustomerTypeEnum,
      gToKg,
      kgToG,
      cmCubicToM,
      getAddress,
      getLinkman,
      handleChangeTab,
      moment,

      columnsPackingList,

      handlePrintBoxSETB,
      handlePagePackingList,
      handleSearchPackingList,

      columnsShipmentList,
      handlePrintShipmentSETableBatch,
      handlePrintShipmentSETable,
      handlePrintShipmentInSETBAll,
      handlePageShipmentList,
      handleSearchShipmentList,

      columnsShipmentDetail,
      shipmentDetailModal,
      handleShowShipmentDetailModal,
      handlePageShipmentDetailModal,

      updateDateModal,
      handleShowUpdateDateModal,
      handleSaveUpdateDateModal,
      disabledDate,
      disabledDateTime,

      handleCancelPlan,
    };
  },
});
</script>

<style lang="less" scoped></style>

<template>
  <a-spin size="large"
          :spinning="data.isShowSpinning">
    <Content>
      <template #contentTitle>
        {{ $t('finance.cost_record') }}
      </template>
      <template #contentBody>
        <div>
          <a-row :gutter=[0,16]>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('finance.business_time')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1"> {{$filters.utcToCurrentTime(data.creationTime)}}</a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('finance.transaction_type')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">
              {{$t($enumLangkey('transactionType',data.transactionType))}}
            </a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('finance.business_type')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">
              {{$t($enumLangkey('businessType',data.businessType))}}
            </a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('finance.business_no')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">
              {{data.businessNo}}
            </a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('warehouse.happen_warehouse_code')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1"> {{data.warehouseNo}}</a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('finance.business_country')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">
              {{getLanguageName({cnName:data.countryCnName,enName:data.countryEnName,})}}
            </a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('finance.affiliation_order')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">
              <span v-if="data.billNo">{{ data.billNo }}</span>
              <span v-else>-</span>
            </a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('finance.happen_currency')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">{{data.currencyCode}}</a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{$t('warehouse.customer')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">{{data.shopName}}</a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right"> {{$t('common.shop_mark')}}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">{{data.shopMark}}</a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">NO</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">{{data.shopNo}}</a-col>
            <a-col :xs="8"
                   :sm="6"
                   :md="5"
                   :lg="4"
                   :xl="3"
                   :xxl="2"
                   style="text-align:right">{{ $t('common.remark') }}</a-col>
            <a-col :xs="15"
                   :sm="17"
                   :md="18"
                   :lg="19"
                   :xl="20"
                   :xxl="21"
                   :offset="1">{{ data.manageRemark || '-' }}</a-col>
          </a-row>
        </div>
        <div style="width:300px;"
             class="mt-3">
          <p style="font-weight: 800;"> {{ $t('finance.fee_detail') }} </p>
          <div class="cost-details-between"
               v-for="(item,index) in data.costLogItems"
               :key="index">
            <span>{{$t($enumLangkey('orderLogisticsFee',item.feeType))}}</span>
            <span>
              {{$filters.formatCurrencyAmount( data.currencySymbol,item.costAmount)}}
            </span>
          </div>

          <div style="text-align:right">
            <span class="mr-4">{{$t('common.total')}}</span>
            <span>
              {{$filters.formatCurrencyAmount( data.currencySymbol,data.payAmount)}}
            </span>
          </div>
        </div>

        <!-- 退货暂存费 -->
        <a-collapse
          v-if="data.businessType==businessTypeEnum.rtsStore"
          class="mt-3"
          v-model:activeKey="collapseActiveKey"
          :bordered="true"
          @change="handleInitPage"
        >
          <template #expandIcon="{ isActive }">
            <caret-right-outlined :rotate="isActive ? 90 : 0" />
          </template>
          <a-collapse-panel key="1" style="background: transparent; border-radius: 4px; margin-bottom: 24px; overflow: hidden;">
            <template #header>
              <a-row type="flex" justify="space-between" align="middle">
                <a-col>
                  <span>{{ $t('finance.view_return_temporary_storage_fee_details') }}</span>
                </a-col>
                <a-col>
                  <a-button class="ml-3" type="primary" ghost  @click.stop="pinModalState.visible = true">
                    {{ $t('finance.export_return_temporary_storage_fee_details') }}
                  </a-button>
                </a-col>
              </a-row>
            </template>
            <a-table
              :columns="columns2"
              :data-source="tableData.tableList"
              :scroll="{ x: 500 }"
              :pagination="false"
              :loading="tableData.loading"
              :rowKey="(record, index) => index"
              size="small"
            >
              <template #count="{ record }">
                {{ record.count || '-' }}
              </template>
              <template #amount="{ record }">
                {{$filters.formatCurrencyAmount(record.currencySymbol, record.amount, true, 4)}}
              </template>
              <template #rtsCreationTime="{ record }">
                {{ $filters.utcToCurrentTime(record.rtsCreationTime) }}
              </template>
            </a-table>
          </a-collapse-panel>
        </a-collapse>


        <!-- 仓储费 -->

        <a-collapse
          v-else-if="isShowDetailTable(data.businessType)"
          class="mt-3"
          v-model:activeKey="collapseActiveKey"
          :bordered="true"
          @change="handleInitPage"
        >
          <template #expandIcon="{ isActive }">
            <caret-right-outlined :rotate="isActive ? 90 : 0" />
          </template>
          <a-collapse-panel key="1" style="border-radius: 4px; background: transparent; margin-bottom: 24px; overflow: hidden;">
            <template #header>
              <a-row type="flex" justify="space-between" align="middle">
                <a-col>
                  <span>{{ $t('finance.view_storage_fee_details') }}</span>
                </a-col>
                <a-col>
                  <a-button class="ml-3" type="primary" ghost @click.stop="pinModalState.visible = true">
                    {{ $t('finance.export_storage_fee_details') }}
                  </a-button>
                </a-col>
              </a-row>
            </template>
            <a-table
              :columns="getColumns"
              :data-source="tableData.tableList"
              :scroll="{ x: 500 }"
              :pagination="false"
              :loading="tableData.loading"
              :rowKey="(record, index) => index"
              size="small"
            >
              <template #volumnTitle>
                <span>{{ $t('finance.storage_capacity') }}(m<sup>3</sup>)</span>
              </template>
              <template #product="{ record }">
                <div>{{ record.productNo }}</div>
                <div>{{ record.productName }}</div>
              </template>
              <template #boxNo="{ record }">{{ record.boxNo || '-' }}</template>
              <template #count="{ record }">
                {{ record.count || '-' }}
              </template>
              <template #volumn="{ record }">{{ record.volumn || '-' }}</template>
              <template #storageDay="{ record }">{{ record.storageDay || '-' }}</template>
              <template #amount="{ record }">
                {{$filters.formatCurrencyAmount(record.currencySymbol, record.amount, true, 4)}}
              </template>
              <template #inWarehouseTime="{ record }">
                {{ $filters.utcToCurrentTime(record.inWarehouseTime) }}
              </template>
            </a-table>
          </a-collapse-panel>
        </a-collapse>
        <a-row type="flex" justify="end" class="mt-3">
          <a-col class="mb-5">
            <a-button type="primary" @click="handleGoBack">{{$t('common.backtrack')}}</a-button>
          </a-col>
        </a-row>
        <CPinTipsModal v-model:visible="pinModalState.visible" v-model:loading="pinModalState.loading" @confirm="handleDownloadExcel" />
      </template>
    </Content>
  </a-spin>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  computed
} from 'vue';
import {
  Table, Select, Button, Row, Col, Tag, Form, Spin, Collapse,
} from 'ant-design-vue';
import Content from '../components/Content.vue';
import CPager from '@/views/components/CPager.vue'
import CPinTipsModal from '@/views/components/CPinTipsModal.vue'
import { getDetail, getStorageFeeDetail,getTemporaryStorageFeeDetail } from '../../api/modules/finance/inventory.js';
import { exportStorageFeeDetail ,exportTemporaryStorageFeeDetail} from "@/api/modules/finance/index";
import { getName, dateString } from "../../utils/general";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { useTab } from "../../hooks/tabs/index";
import { businessType as businessTypeEnum } from '@/enum/enum.json'
import { downloadFile } from "@/utils/downloader";

export default defineComponent({
  name: "finance_inventory_details",
  components: {
    ATable: Table,
    ASelect: Select,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    ATag: Tag,
    AForm: Form,
    AFormItem: Form.Item,
    ASpin: Spin,
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel,
    Content,
    CPager,
    CPinTipsModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const data = reactive({
      id: null,
      isShowSpinning: false,
      creationTime: null,
      transactionType: null,
      businessType: null,
      businessNo: null,
      warehouseNo: null,
      countryEnName: null,
      countryCnName: null,
      billNo: null,
      currencyCode: null,
      costLogItems: [],
      currencySymbol: null,
      relevantBusiness: [],
      shopName: null,
      shopId: null,
      shopNo: null,
      manageRemark: null,
    });

    const state = reactive({
      collapseActiveKey: [],
      isLoadedTable: false,
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        }
      },
      pinModalState: {
        visible: false,
        loading: false,
      }
    })

    const columns = [
      {
        dataIndex: 'feeCode',
        title: () => vueI18n.t('warehouse.fee_code'),
        width: 120,
      },
      {
        id:"seSku",
        dataIndex: 'seSku',
        title: 'SESKU',
        width: 150,
      },
      {
        id:"product",
        title: () => vueI18n.t('menu.products'),
        width: 250,
        slots: {
          customRender: 'product',
        },
      },
      {
        id:"boxNo",
        title: () => vueI18n.t('warehouse.box_number'),
        width: 150,
        slots: {
          customRender: 'boxNo',
        },
      },
      {
        title: () => vueI18n.t('logistics.amount'),
        width: 100,
        slots: {
          customRender: 'count',
        },
      },
      {
        width: 120,
        slots: {
          title: 'volumnTitle',
          customRender: 'volumn',
        },
      },
      {
        title: () => vueI18n.t('finance.storage_time') + '(' + vueI18n.t('common.days') + ')',
        width: 120,
        slots: {
          customRender: 'storageDay',
        },
      },
      {
        title: () => vueI18n.t('warehouse.charge'),
        width: 120,
        slots: {
          customRender: 'amount',
        },
      },
      {
        title: () => vueI18n.t('warehouse.inbound_warehouse_time'),
        width: 150,
        slots: {
          customRender: 'inWarehouseTime',
        },
      },
       {
        title: () => vueI18n.t('warehouse.in_warehouse_no'),
        dataIndex: 'inWarehousePlanNo',
        width: 150,
      }
    ];

     const columns2 = [
      {
        dataIndex: 'feeCode',
        title: () => vueI18n.t('warehouse.fee_code'),
        width: 150,
      },
      {
        title: () => vueI18n.t('warehouse.return_package_number'),
        width: 150,
        dataIndex: 'rtsPackageNo',
      },
      {
        title: () => vueI18n.t('logistics.count'),
        width: 100,
        slots: {
          customRender: 'count',
        },
      },
      {
        title: () => vueI18n.t('warehouse.charge'),
        width: 150,
        slots: {
          customRender: 'amount',
        },
      },
      {
        title: () => vueI18n.t('warehouse.return_goods_in_warehouse_time'),
        width: 150,
        slots: {
          customRender: 'rtsCreationTime',
        },
      },
    ];

    const isShowDetailTable = (type) => {
      let arr = [
        businessTypeEnum.storageWarehouseStore,
        businessTypeEnum.deliveryWarehouseStore,
        businessTypeEnum.transitWarehouseStore,
      ]
      if (arr.includes(type)) {
        return true
      }
      return false
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const funcGetPageInfo = async () => {
      data.isShowSpinning = true;
      getDetail(data.id).then((res) => {
        Object.assign(data, res.result);
        data.isShowSpinning = false;
      }).catch(() => {
        data.isShowSpinning = false;
      })
    };

    const getPageList = async () => {
      try {
        state.tableData.loading = true;
         let res = null;
        if (data.businessType==businessTypeEnum.rtsStore) {
           res = await getTemporaryStorageFeeDetail({ costLogId: data.id });
        } else {
          res = await getStorageFeeDetail({ costLogId: data.id });
        }
        if (Array.isArray(res.result)) {
          state.isLoadedTable = true
          state.tableData.tableList = res.result
        } else {
          state.tableData.tableList = []
        }
      } catch (error) {
      } finally {
        state.tableData.loading = false;
      }
    }

    const handleInitPage = (e) => {
      if (e.length && !state.isLoadedTable) {
        state.tableData.pageData.skipCount = 0;
        state.tableData.pageData.currentIndex = 1;
        getPageList()
      }
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList()
    };

    const handleDownloadExcel = (pinCode) => {
      state.pinModalState.loading = true
      if (data.businessType==businessTypeEnum.rtsStore) {
        let url = exportTemporaryStorageFeeDetail()
        downloadFile(url, `Temporary_Storage_Fee_${data.businessNo}_${dateString()}.csv`, "POST", { costLogId: data.id,pinCode })
          .then(() => {
            state.pinModalState.visible = false
          })
          .catch(() => {})
          .finally(() => {
            state.pinModalState.loading = false
          })
      } else {
        let url = exportStorageFeeDetail()
        downloadFile(url, `Storage_Fee_${data.businessNo}_${dateString()}.csv`, "POST", { costLogId: data.id, pinCode })
          .then(() => {
            state.pinModalState.visible = false
          })
          .catch(() => {})
          .finally(() => {
            state.pinModalState.loading = false
          })
      }
    }

    const handleGoBack = async () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/finance/inventory" });
    }

    const getColumns = computed(() => {
      if (data.businessType==businessTypeEnum.storageWarehouseStore||
      data.businessType==businessTypeEnum.transitWarehouseStore
      ) {
        return columns.filter(x=>x.id!='seSku' && x.id!= 'product')
      }else{
        return columns.filter(x=>x.id!='boxNo');
      }
    })

    onMounted(() => {
      data.id = route.params.id;
      if (data.id) {
        funcGetPageInfo();
      }
    });

    return {
      businessTypeEnum,
      getColumns,
      columns2,
      data,
      ...toRefs(state),
      isShowDetailTable,
      getLanguageName,
      handleDownloadExcel,
      handleInitPage,
      handlePage,
      handleGoBack,
    };
  },
});
</script>
<style lang="less" scoped>
.moneyColor {
  color: red;
}

.cost-details-between {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee1e6;
}
</style>

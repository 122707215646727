import client from "../../client"

const Api = {

    //根据分页附加费配置
    getPagedList: "/api/SupplierService/SurchargeConfig/GetPagedList",

    //根据物流方案，获取附加费配置
    getListBySupplierId: "/api/SupplierService/SurchargeConfig/GetListBySupplierId",

    //根据Id获取附加费详情
    getById: "/api/SupplierService/SurchargeConfig/GetById",

    //删除费用配置
    deleteById: "/api/SupplierService/SurchargeConfig/Delete",

    //创建费用配置
    create: "/api/SupplierService/SurchargeConfig/Create",

    //更新费用配置
    update: "/api/SupplierService/SurchargeConfig/Update",
}

//根据分页附加费配置
export const getPagedList = (data) => {
    return client.request({
        url: Api.getPagedList,
        data,
        method: 'post'
    });
}


//根据物流方案，获取附加费配置
export const getListBySupplierId = (supplierId) => {
    return client.request({
        url: Api.getListBySupplierId,
        params: { supplierId },
        method: 'post'
    });
}


//根据Id获取详细
export const getById = (id) => {
    return client.request({
        url: Api.getById,
        params: { id },
        method: 'GET'
    });
}

//删除附加费模板
export const deleteById = (id) => {
    return client.request({
        url: Api.deleteById,
        data: { ids: [id] },
        method: 'POST'
    });
}

//创建附加费模板, data模型比较复杂，请在接口文档中查看详细
export const create = (data) => {
    return client.request({
        url: Api.create,
        data: data,
        method: 'POST'
    });
}

//更新附加费模板, data模型比较复杂，请在接口文档中查看详细
export const update = (data) => {
    return client.request({
        url: Api.update,
        data: data,
        method: 'POST'
    });
}
export default {
  menu: {
    dashboard: "工作空间",
    dashboard_monitor: "监控",

    account_info: "账户信息",

    warehouse_manager: "仓库管理",
    warehouse_device_manager: "仓库设施管理",
    warehouse_fee_setting: "费用配置",

    warehouse_monitor: "仓库任务监控",
    warehouse_monitor_storage_outbound_task: "存储仓-出库任务",
    warehouse_monitor_storage_outbound_task_detail: "存储仓-出库任务详情",
    warehouse_monitor_storage_warehousing_task: "存储仓-入库任务",
    warehouse_monitor_storage_warehousing_task_detail: "存储仓-入库任务详情",
    warehouse_monitor_storage_label_change: "存储仓-标签任务",
    warehouse_monitor_storage_label_change_detail: "存储仓-标签任务详情",
    warehouse_monitor_consignment_warehousing_task: "配送仓-入库任务",
    warehouse_monitor_consignment_warehousing_task_detail: "配送仓-入库任务详情",
    warehouse_monitor_consignment_order_task: "配送仓-配送订单",
    warehouse_monitor_consignment_order_task_detail: "配送仓-订单任务详情",
    warehouse_monitor_transit_inbound: "中转仓-入库预约",
    warehouse_monitor_transit_inbound_detail: "中转仓-入库预约详情",
    warehouse_monitor_transit_outbound: "中转仓-出库计划",
    warehouse_monitor_transit_outbound_detail: "中转仓-出库计划详情",
    warehouse_monitor_transit_label_change: "中转仓-标签任务",
    warehouse_monitor_transit_label_change_detail: "中转仓-标签任务详情",
    warehouse_monitor_unload: "卸货任务",
    warehouse_monitor_return: "退货入库任务",
    warehouse_monitor_return_detail: "退货计划详情",
    warehouse_monitor_print_waybill: "面单列表",
    warehouse_monitor_print_waybill_detail: "面单详情",
    warehouse_monitor_allot_plan: "配送仓-调拨任务",
    warehouse_monitor_allot_plan_detail: "调拨计划详情",
    warehouse_monitor_consignment_return_temporary_storage: "配送仓-退货暂存管理",
    warehouse_monitor_consignment_return_temporary_storage_shop: "配送仓-退货暂存店铺名称管理",

    products: "产品",
    product_management: "产品管理",
    products_verify_data: "核验数据申请",
    products_storage_inventory: "存储仓库存",
    products_consignment_inventory: "配送仓库存",
    products_transit_inventory: "中转仓库存",
    products_transit_shipment: "中转仓货件管理",
    products_edit: "修改产品信息",
    products_historical_version: "产品历史版本",
    products_check_history: "产品核验记录",
    products_inventory_adjustment: "库存调整",
    product_size_modification_notice: "产品尺寸修改通知",

    logistics_config: "物流配置",
    logistics_channel: "物流渠道",
    logistics_region: "物流分区",
    logistics_region_edit: "分区配置",
    logistics_fee_template: "运费模板",
    logistics_fee_config: "费用配置",
    logistics_surcharge: "附加费模板",
    logistics_surcharge_edit: "附加费配置",
    special_region: "特殊分区",
    special_region_edit: "特殊分区配置",
    logistics_account: "物流账户配置",
    logistics_embargo_configuration: "禁运配置",
    logistics_limited_transportation_configuration: "限运配置",
    logistics_discount: "物流折扣",
    logistics_address_api: "物流地址查询API",
    logistics_validation: "手动验证",
    logistics_rebate_configuration: "达标折扣",
    logistics_channel_discount: "渠道折扣",

    supplier_config: "供应商配置",
    supplier_channel: "供应商渠道",
    supplier_region: "供应商分区",
    supplier_region_edit: "供应商分区配置",
    supplier_fee_template: "供应商运费模板",
    supplier_fee_config: "供应商费用配置",
    supplier_surcharge: "供应商附加费模板",
    supplier_surcharge_edit: "供应商附加费配置",
    supplier_special_region: "供应商特殊分区",
    supplier_special_region_edit: "供应商特殊分区配置",
    supplier_built_in_channel: "供应商内置渠道管理",
    supplier_embargo_configuration: "供应商禁运配置",
    supplier_limited_transportation_configuration: "供应商限运配置",
    supplier_discount: "供应商折扣",
    supplier_address_api: "供应商地址查询API",
    supplier_print_waybill_api: "供应商打单API",
    supplier_channel_discount: "渠道折扣",

    right_module: "权限管理",
    role_manager: "角色管理",
    right_manager: "权限菜单管理",
    staff_organizational: "员工与组织结构",

    account_restpwd: "重置密码",
    account_center: "个人中心",

    settings: "设置",
    about: "关于",
    sdas: "SDAS",

    transport: "运输计划",
    transport_detail: "运输计划详情",
    transport_customer_list: "客户运输计划",
    transport_customer_detail: "客户运输计划详情",
    transport_shipment_transport: "运输列表",
    transport_shipment_cabin: "舱位列表",
    transport_shipment_cabin_detail: "舱位详情",

    operate_console: "操作台",
    operate_console_inbound: "入库操作台",
    operate_console_package: "包装操作台",
    operate_console_transfer: "移库操作台",
    operate_console_outbound: "出库操作台",
    operate_console_delivery: "交付操作台",

    task: "任务",
    task_inbound_task: "入库任务",
    task_outbound_task: "出库任务",
    task_transfer: "移库任务",
    task_survey: "调查任务",
    task_my_transfer: "我的移库任务",
    task_anomaly_of_scan: "扫描异常任务",

    error: "错误页面",
    error_403: "403",
    error_404: "404",
    error_500: "500",

    permission: "系统权限",
    warehouse_transferlog: "移库记录",
    inboundPlanDetails: "入库详情",
    outboundPlanDetails: "出库详情",
    warehouse_inventory_management: "库存管理",

    location_configuration: "库位配置",
    area_status: "区域状态设置",
    barcode_operation_task: "条码操作任务",

    location_management: "库位管理",
    create_the_move_task: "创建移库任务",

    area_management: "区域管理",

    location_barcode_manipulation: "库位条码操作",

    location_type_configuration: "库位类型配置",

    customer: "客户管理",
    customer_account_list: "账户列表",
    customer_account_details: "账户详情",
    customer_company_list: "公司列表",
    customer_company_details: "公司详情",
    customer_level_list: "账户等级",
    customer_company_discount_list: "公司仓库折扣",
    customer_company_discount_details: "折扣详情",
    customer_company_transaction_record: "交易记录",
    customer_company_accounting_period: "物流账期配置",

    finance: "财务",
    finance_accounting_list: "运输计划核算",
    finance_accounting_cost: "成本核算",
    finance_accounting_details: "成本详情",
    finance_recharge: "客户充值",
    finance_inventory: "费用清单",
    finance_details: "费用详情",
    finance_inventory_unsettled: "未结算费用清单",
    finance_customer_balance: "客户余额",
    finance_order_waybill: "配送订单运单",

    dev_ops: "运维",
    dev_ops_ali_log: "阿里日志",
    dev_box_edit_list: "箱数据修改记录",
    dev_scan_exception: "扫描异常",
    dev_api_manage: "API接口管理",
    dev_currency_configuration_list: "货币配置",
    dev_abbr_code_convert: "省份或州代码转换",
    dev_export_log: "导出记录",
    dev_port_management: "港口管理",
    dev_airline_management: "航司管理",
    dev_announcement: "公告",
    dev_logistics_payment_countdown: "物流支付倒计时",
    dev_cache_management: "缓存管理",
    dev_exchange_rate: "实时汇率",
    dev_exchange_rate_history: "汇率历史记录",
    dev_notice: "通知管理",
    dev_notice_list: "通知场景",
    dev_notice_template: "通知模板",
    dev_notice_attribute: "通知模板属性",

    partner: "合作伙伴",
    partner_list: "合作伙伴列表",
    partner_details: "合作伙伴详情",
    partner_global_set: "全局佣金设置",
    partner_group_manage: " 分组管理",
    partner_group_set: "分组佣金设置",
    partner_settlement_record: "结算记录",

    open_platform: "开放平台",
    open_platform_authorizes: "授权项列表",
    open_platform_authorize_api: "授权项API管理",
    open_platform_applys: "应用列表",
    open_platform_applys_company: "应用授权租户管理",
  },
};

import client from "../../client"

const Api = {
  // 获取禁运配置列表
  getPagedList: "/api/LogisticsService/LogisticEmbargoConfiguration/GetPagedList",
  // 依据地址类型获取地址名称/编号
  getAllCanChooseEmbargoConfigurationCode: "/api/LogisticsService/LogisticEmbargoConfiguration/GetAllCanChooseEmbargoConfigurationCode",
  // 新增禁运配置
  createLogisticEmbargoConfiguration: "/api/LogisticsService/LogisticEmbargoConfiguration/Create",
  // 修改禁运配置/编号
  updateLogisticEmbargoConfiguration: "/api/LogisticsService/LogisticEmbargoConfiguration/Update",
  // 删除禁运配置/编号
  deleteLogisticEmbargoConfiguration: "/api/LogisticsService/LogisticEmbargoConfiguration/Delete",
}

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: "POST",
  });
};

export const getAllCanChooseEmbargoConfigurationCode = (params) => {
  return client.request({
    url: Api.getAllCanChooseEmbargoConfigurationCode,
    params,
    method: "GET",
  });
};

export const createLogisticEmbargoConfiguration = (data) => {
  return client.request({
    url: Api.createLogisticEmbargoConfiguration,
    data,
    method: "POST",
  });
};

export const updateLogisticEmbargoConfiguration = (data) => {
  return client.request({
    url: Api.updateLogisticEmbargoConfiguration,
    data,
    method: "POST",
  });
};

export const deleteLogisticEmbargoConfiguration = (data) => {
  return client.request({
    url: Api.deleteLogisticEmbargoConfiguration,
    data,
    method: "POST",
  });
};
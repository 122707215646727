<template>
  <a-modal
    v-model:visible="visible"
    :confirmLoading="loading"
    :title="$t('common.edit') + $t('menu.customer_level_list')"
    @ok="handleOk"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('warehouse.name')" name="name">
        <a-input v-model:value="formState.name" />
      </a-form-item>
      <a-form-item :label="$t('common.remark')" name="remark">
        <a-textarea v-model:value="formState.remark" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick } from "vue";
import { Form, Modal, Input, InputNumber, message } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { createShopLevel, updateShopLevel } from "@/api/modules/customer/level";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputNumber: InputNumber,
    ATextarea: Input.TextArea,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref();

    const state = reactive({
      visible: false,
      loading: false,
      formState: {
        id: null,
        name: "",
        remark: "",
      },
    });

    const rules = {
      name: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.name")]),
      },
    };

    const handleOk = async () => {
      try {
        await formRef.value.validate();
        state.loading = true;
        const data = JSON.parse(JSON.stringify(state.formState));
        if (data.id) {
          await updateShopLevel(data);
        } else {
          await createShopLevel(data);
        }
        message.success(vueI18n.t("common.succeed"));
        state.visible = false;
        emit("refresh");
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    const init = (record) => {
      state.visible = true;
      nextTick(() => {
        formRef.value.resetFields();
        state.formState.id = null;

        if (record) {
          let formState = JSON.parse(JSON.stringify(state.formState));
          for (const key in record) {
            if (Object.hasOwnProperty.call(formState, key)) {
              state.formState[key] = record[key];
            }
          }
        }
      });
    };

    return {
      ...toRefs(state),
      rules,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      init,
      handleOk,
    };
  },
});
</script>

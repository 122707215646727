<template>
  <Content>
    <template #contentTitle>{{ $t('menu.finance_order_waybill') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input
            v-model:value="searchState.orderNo"
            :placeholder="$t('logistics.consignment_order_no')"
            allow-clear
            style="width: 200px;"
          ></a-input>
        </a-col>
        <a-col>
          <a-input
            v-model:value="searchState.trackNo"
            :placeholder="$t('logistics.logistics_tracking_number')"
            allow-clear
            style="width: 200px;"
          ></a-input>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.warehouseId"
            :placeholder="$t('warehouse.consignment_code')"
            optionFilterProp="search-key"
            allow-clear
            show-search
            style="width: 200px;"
          >
            <a-select-option
            v-for="(item, index) in warehouseList"
              :key="index"
              :value="item.id"
              :title="item.warehouseNo + '('+ item.warehouseName +')'"
              :search-key="item.warehouseNo + item.warehouseName"
            >{{ item.warehouseNo + '('+ item.warehouseName +')' }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.openApiCode"
            :placeholder="$t('devops.api_code')"
            optionFilterProp="search-key"
            allow-clear
            show-search
            style="width: 200px;"
          >
            <a-select-option
              v-for="(item, index) in openApiCodeList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('openApiCode', item))"
              :search-key="$t($enumLangkey('openApiCode', item))"
            >{{ $t($enumLangkey('openApiCode', item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="searchState.isReconciliation"
            :placeholder="$t('warehouse.is_reconciliation')"
            allow-clear
            style="width: 200px;"
          >
            <a-select-option :value="1" :title="$t('common.yes')">{{ $t('common.yes') }}</a-select-option>
            <a-select-option :value="0" :title="$t('common.no')">{{ $t('common.no') }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-range-picker
            style="min-width: 150px"
            allowClear
            @change="handleDateChange"
          />
        </a-col>
        <a-col>
          <a-space>
            <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
            <a-button type="ghost" @click="handleShowPinModal(0)">{{ $t('common.export') }}</a-button>
            <a-button type="ghost" @click="handleUploadReconciliationExcel">{{ $t('warehouse.reconciliation') }}</a-button>
          </a-space>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 110 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #orderNoTitle>
          <div>{{ $t('logistics.consignment_order_no') }}</div>
          <div>{{ $t('logistics.relation_order_no') }}</div>
        </template>
        <template #countTitle>
          <div>{{ $t("warehouse.number_of_waybills") }} /</div>
          <div>{{ $t("warehouse.reconciliation_quantity") }}</div>
        </template>
        <template #weightTitle>
          <div>{{ $t("warehouse.billing_weight_of_waybill") }} /</div>
          <div>{{ $t("warehouse.reconciliation_billing_weight") }}</div>
        </template>
        <template #accountCurrencyTitle>
          <div>{{ $t("warehouse.reconciliation_amount") }} /</div>
          <div>{{ $t("finance.exchange_rate") }}</div>
        </template>
        <template #orderNo="{ record }">
          <div>{{ record.orderNo }}</div>
          <div>{{ record.relationOrderNo }}</div>
        </template>
        <template #openApiCode="{ record }">
          <span v-if="record.openApiCode">{{ $t($enumLangkey('openApiCode', record.openApiCode)) }}</span>
          <span v-else>-</span>
        </template>
        <template #freight="{ record }">
          <span v-if="record.freight">{{ $filters.formatCurrencyAmount( record.currencySymbol, record.freight, false) }}</span>
          <span v-else>-</span>
        </template>
        <template #trackTime="{ record }">{{ $filters.utcToCurrentTime(record.trackTime) }}</template>
        <template #isReconciliation="{ record }">
          <span v-if="record.isReconciliation"><CheckOutlined class="text-primary" /></span>
          <span v-else>-</span>
        </template>
        <template #reconciliationTime="{ record }">
          <span v-if="record.wayBillCheck">{{ $filters.utcToCurrentTime(record.wayBillCheck.reconciliationTime) }}</span>
          <span v-else>-</span>
        </template>
        <template #count="{ record }">
          <span v-if="record.waybillCount">{{ record.waybillCount }}</span>
          <span v-else>-</span> /
          <span v-if="record.wayBillCheck">{{ record.wayBillCheck.accountWaybillCount }}</span>
          <span v-else>-</span>
        </template>
        <template #weight="{ record }">
          <span v-if="record.chargeableWeight">{{ gToKg(record.chargeableWeight) }}kg</span>
          <span v-else>-</span> /
          <span v-if="record.wayBillCheck">{{ gToKg(record.wayBillCheck.accountChargeableWeight) }}kg</span>
          <span v-else>-</span>
        </template>
        <template #account="{ record }">
          <span v-if="record.wayBillCheck">
            <div v-if="record.wayBillCheck.accountCurrency && record.wayBillCheck.conversionAmount">
              <span>{{
                $filters.formatCurrencyAmount(
                  record.wayBillCheck.accountCurrency.currencySymbol,
                  record.wayBillCheck.accountCurrency.amount,
                  false
                )
              }}</span>
              (<span class="text-success">{{
                $filters.formatCurrencyAmount(
                  record.wayBillCheck.conversionAmount.currencySymbol,
                  record.wayBillCheck.conversionAmount.amount,
                  false
                )
              }}</span
              >) /
            </div>
            <div v-else>- /</div>
            <div v-if="record.wayBillCheck.currencyRate > 0">{{ record.wayBillCheck.currencyRate }}</div>
            <div v-else>{{ record.wayBillCheck.currencyRate }}</div>
          </span>
          <span v-else>- / -</span>
          <div v-if="record.isReconciliation" class="text-primary" style="cursor: pointer;" @click="handleShowReconciliationTableModal(record)">{{ $t('common.details') }}</div>
        </template>
        <template #profitAndLoss="{ record }">
          <span v-if="record.wayBillCheck && record.wayBillCheck.profitLossCurrency">
            <span
              v-if="record.wayBillCheck.profitLossCurrency.amount > 0"
              class="text-success"
              >{{
                $filters.formatCurrencyAmount(
                  record.wayBillCheck.profitLossCurrency.currencySymbol,
                  record.wayBillCheck.profitLossCurrency.amount
                )
              }}</span
            >
            <span v-else class="text-error">{{
              $filters.formatCurrencyAmount(
                record.wayBillCheck.profitLossCurrency.currencySymbol,
                record.wayBillCheck.profitLossCurrency.amount
              )
            }}</span>
          </span>
          <span v-else>-</span>
        </template>
        <template #employee="{ record }">
          <span v-if="record.wayBillCheck">
            <CEmployeeInfo v-if="record.wayBillCheck.employee" :employee="record.wayBillCheck.employee" />
            <span v-else-if="record.wayBillCheck.operationUserName">
              {{ record.wayBillCheck.operationUserName }}
            </span>
            <span v-else>-</span>
          </span>
          <span v-else>-</span>
        </template>
        <template #operate="{ record }">
          <a-button v-if="record.isReconciliation" type="ghost" @click="handleShowPinModal(1, record)">{{ $t("warehouse.cancel_reconciliation") }}</a-button>
        </template>
      </a-table>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePicCodeConfirm"
      />
      <UploadReconciliationModal ref="uploadReconciliationModalRef" :recordType="accountRecordTypeEnum.agentOrder" @confirm="handleShowReconciliationModal" />
      <ReconciliationModal ref="reconciliationModalRef" :recordType="accountRecordTypeEnum.agentOrder" @confirm="initData" />
      <ReconciliationTableModal v-model:visible="reconciliationTableModalState.visible" :id="reconciliationTableModalState.id" />
    </template>
    <template #contentFooter>
      <c-pager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { Col, Input, Row, Table, DatePicker, Button, Select, Space, message } from 'ant-design-vue'
import Content from '@/views/components/Content';
import CPager from '@/views/components/CPager';
import CPinTipsModal from '@/views/components/CPinTipsModal.vue';
import CEmployeeInfo from '@/views/components/CEmployeeInfo.vue';
import UploadReconciliationModal from '@/views/warehouseMonitor/printwaybill/components/UploadReconciliationModal.vue'
import ReconciliationModal from '@/views/warehouseMonitor/printwaybill/components/ReconciliationModal.vue'
import ReconciliationTableModal from './components/ReconciliationTableModal.vue';
import { useI18n } from "vue-i18n/index"
import { useStore } from 'vuex'
import { openApiCode as openApiCodeEnum, accountRecordType as accountRecordTypeEnum } from '@/enum/enum.json'
import { getWaybillList, exportAgentOrderWayBill, cancelReconciliation } from '@/api/modules/finance/orderWaybill'
import { getWarehouses } from '@/api/modules/common/index'
import { currentTimeToUtc, dateString, gToKg } from '@/utils/general'
import { downloadFile } from "@/utils/downloader"

export default defineComponent({
  name: "finance_order_waybill",
  components: {
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    ATable: Table,
    ARangePicker: DatePicker.RangePicker,
    ASpace: Space,
    AButton: Button,
    Content,
    CPager,
    CPinTipsModal,
    CEmployeeInfo,
    UploadReconciliationModal,
    ReconciliationModal,
    ReconciliationTableModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const uploadReconciliationModalRef = ref(null);
    const reconciliationModalRef = ref(null);
    const { getters } = useStore();

    const state = reactive({
      warehouseList: [],
      openApiCodeList: [],
      searchState: {
        orderNo: null,
        trackNo: null,
        warehouseId: null,
        openApiCode: null,
        isReconciliation: null,
        utcBeginTime: null,
        utcEndTime: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      pinModalState: {
        visible: false,
        loading: false,
        // 0: 导出; 1: 取消对账;
        type: null,
        dataTemp: null,
      }
    })

    const reconciliationTableModalState = reactive({
      visible: false,
      id: null,
    })

    state.openApiCodeList = Object.values(openApiCodeEnum)

    const columns = [
      {
        width: 150,
        slots: {
          title: "orderNoTitle",
          customRender: "orderNo"
        }
      },
      {
        dataIndex: "warehouseNo",
        title: () => vueI18n.t('warehouse.warehouse_name'),
        width: 150
      },
      {
        title: () => vueI18n.t('devops.api_code'),
        width: 100,
        slots: {
          customRender: "openApiCode"
        }
      },
      {
        title: () => vueI18n.t('logistics.freight'),
        width: 100,
        slots: {
          customRender: "freight"
        }
      },
      {
        dataIndex: "logisticsName",
        title: () => vueI18n.t('logistics.transport_scheme'),
        width: 150
      },
      {
        dataIndex: "logisticsTrackNo",
        title: () => vueI18n.t('logistics.logistics_tracking_number'),
        width: 150
      },
      {
        title: () => vueI18n.t('logistics.print_waybill_creation_time'),
        width: 120,
        slots: {
          customRender: "trackTime"
        }
      },
      {
        title: () => vueI18n.t('warehouse.is_reconciliation'),
        width: 80,
        slots: {
          customRender: "isReconciliation"
        }
      },
      {
        title: () => vueI18n.t('warehouse.reconciliation_time'),
        width: 120,
        slots: {
          customRender: "reconciliationTime"
        }
      },
      {
        width: 120,
        slots: {
          title: "countTitle",
          customRender: "count"
        }
      },
      {
        width: 150,
        slots: {
          title: "weightTitle",
          customRender: "weight"
        }
      },
      {
        width: 130,
        slots: {
          title: "accountCurrencyTitle",
          customRender: "account"
        }
      },
      {
        title: () => vueI18n.t('warehouse.profit_and_loss_amount'),
        width: 130,
        slots: {
          customRender: "profitAndLoss"
        }
      },
      {
        title: () => vueI18n.t('account.operator'),
        width: 150,
        slots: {
          customRender: "employee"
        }
      },
      {
        title: () => vueI18n.t('common.operate'),
        fixed: 'right',
        width: 150,
        slots: {
          customRender: "operate"
        }
      },
    ]

    const handleDateChange = (e) => {
      let startTime = null;
      let endTime = null;
      if (Array.isArray(e) && e?.length > 1) {
        startTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.utcBeginTime = startTime;
      state.searchState.utcEndTime = endTime;
    }

    const handleDownloadExcel = (pinCode) => {
      state.pinModalState.loading = true
      let url = exportAgentOrderWayBill();
      const data = Object.assign({}, state.searchStateCache, { pinCode })
      downloadFile(url, `order_waybill_${dateString()}.csv`, "POST", data).then(() => {
        state.pinModalState.visible = false
      }).catch(() => { }).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handleCancelReconciliation = (pinCode) => {
      const data = {
        pinCode,
        id: state.pinModalState.dataTemp
      }
      state.pinModalState.loading = true
      cancelReconciliation(data).then(() => {
        state.pinModalState.visible = false
        message.success(vueI18n.t('common.succeed'))
        handleSearch()
      }).catch(() => { }).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handlePage = async (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getList();
    };

    const getList = () => {
      state.tableData.loading = true;
      // 列表
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      // 是否对账
      data.isReconciliation = data.isReconciliation === 0 ? false : data.isReconciliation === 1 ? true : null
      getWaybillList(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch((err) => {
          state.tableData.loading = false;
        });
    };

    const initData = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      initData();
    };

    const getWarehousesList = () => {
      getWarehouses({warehouseType: "2"}).then(({ result }) => {
        state.warehouseList = result || []
      }).catch(() => {})
    }

    const handleShowPinModal = (type, record) => {
      state.pinModalState.type = type
      state.pinModalState.visible = true
      if (record) {
        state.pinModalState.dataTemp = record.orderId
      }
    }

    const handleUploadReconciliationExcel = () => {
      uploadReconciliationModalRef.value.open()
    }

    const handleShowReconciliationModal = (list) => {
      reconciliationModalRef.value.open(list)
    }

    const handlePicCodeConfirm = (pinCode) => {
      switch (state.pinModalState.type) {
        case 0:
          handleDownloadExcel(pinCode)
          break;
        case 1:
          handleCancelReconciliation(pinCode)
          break;
        default:
          break;
      }
    }

    const handleShowReconciliationTableModal = (record) => {
      reconciliationTableModalState.id = record.orderId;
      reconciliationTableModalState.visible = true;
    }

    onMounted(() => {
      initData()
      getWarehousesList()
    })

    return {
      ...toRefs(state),
      uploadReconciliationModalRef,
      reconciliationModalRef,
      accountRecordTypeEnum,
      columns,
      gToKg,
      reconciliationTableModalState,
      handleDateChange,
      handleDownloadExcel,
      handleSearch,
      handlePage,
      initData,
      handleShowPinModal,
      handleUploadReconciliationExcel,
      handleShowReconciliationModal,
      handlePicCodeConfirm,
      handleShowReconciliationTableModal,
    }
  }
})
</script>

<style scoped>

</style>
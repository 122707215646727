<template>
  <a-modal
    width="600px"
    v-model:visible="visible"
    :title="formState.id ? $t('common.edit') : $t('common.add')"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="confirmLoading"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('common.country')" name="countryId">
        <a-select
          v-model:value="formState.countryId"
          :placeholder="$t('common.country')"
          @change="handleChangeCountry"
        >
          <a-select-option
            v-for="(item, index) in countryList"
            :key="index"
            :value="item.id"
            :title="getLanguageName(item)"
          >{{ getLanguageName(item) }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('common.province')" name="provinceId">
        <a-select
          v-model:value="formState.provinceId"
          :placeholder="$t('common.province')"
          :loading="provinceLoading"
        >
          <a-select-option
            v-for="(item, index) in provinceList"
            :key="index"
            :value="item.id"
            :title="getLanguageName(item, 'province')"
          >{{ getLanguageName(item, 'province') }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('devops.api_code')" name="openApiCode">
        <a-select
          v-model:value="formState.openApiCode"
          :placeholder="$t('devops.api_code')"
        >
          <a-select-option
            v-for="(item, index) in openApiCodeEnum"
            :key="index"
            :value="item"
            :title="$t($enumLangkey('openApiCode', item))"
          >{{ $t($enumLangkey('openApiCode', item)) }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('devops.target_province_code')" name="targetAbbrCode">
        <a-input v-model:value="formState.targetAbbrCode" :maxlength="10" :placeholder="$t('devops.target_province_code')"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { Form, Input, Modal, Select, message } from 'ant-design-vue';
import { defineComponent, nextTick, reactive, ref, toRefs } from 'vue'
import { useI18n } from "vue-i18n/index"
import { useStore } from 'vuex'
import { getName } from '@/utils/general.js'
import { getProvinces } from '@/api/modules/common/index.js'
import { openApiCode as openApiCodeEnum } from '@/enum/enum.json'
import { createShipmentProvinceExchangeConfig, updateShipmentProvinceExchangeConfig } from '@/api/modules/devops/abbrCodeConvert.js'
import _ from "lodash"

export default defineComponent({
  expose: ['open'],
  emits: ['refresh'],
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
  },
  props: {
    countryList: {
      type: Array,
      default: () => ([])
    },
    provinceCache: {
      type: Object,
      default: () => ({})
    },
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" })
    const { getters } = useStore()

    const formRef = ref(null)

    const state = reactive({
      visible: false,
      confirmLoading: false,
      provinceLoading: false,
      provinceList: [],
      formState: {
        id: null,
        countryId: null,
        provinceId: null,
        targetAbbrCode: null,
        openApiCode: null,
      }
    })


    const rules = {
      countryId: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('common.country')])
      },
      provinceId: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('common.province')])
      },
      targetAbbrCode: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.target_province_code')])
      },
      openApiCode: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.api_code')])
      },
    }

    const getLanguageName = (item, key) => {
      if (key === 'province') {
        return `${getName(item, getters.language)}(${item.abbrCode})`
      }
      return getName(item, getters.language)
    }

    const getProvinceList = () => {
      let countryId = state.formState.countryId
      if (countryId in props.provinceCache) {
        state.provinceList = props.provinceCache[countryId]
      } else {
        state.provinceLoading = true
        getProvinces({ countryId }).then(({ result }) => {
          if (Array.isArray(result)) {
            state.provinceList = result
            props.provinceCache[countryId] = result
          } else {
            state.provinceList = []
          }
        }).catch(() => {}).finally(() => {
          state.provinceLoading = false
        })
      }
    }

    const handleChangeCountry = () => {
      state.formState.provinceId = null
      getProvinceList()
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate()
        state.confirmLoading = true
        const data = JSON.parse(JSON.stringify(state.formState))
        if (data.id) {
          await updateShipmentProvinceExchangeConfig(data)
        } else {
          await createShipmentProvinceExchangeConfig(data)
        }
        message.success(vueI18n.t('common.succeed'))
        emit('refresh')
        close()
      } catch (error) {
      } finally {
        state.confirmLoading = false
      }
    }

    const setFormStateValue = (form, result) => {
      if (_.isPlainObject(form)) {
        Object.keys(form).forEach((key) => {
          if (Object.hasOwnProperty.call(result, key)) {
            if (_.isPlainObject(form[key])) {
              if (result[key]) {
                setFormStateValue(form[key], result[key]);
              }
            } else if (_.isArray(form[key])) {
              if (result[key]) {
                form[key] = result[key];
              }
            } else {
              form[key] = result[key];
            }
          }
        })
      }
    }

    const open = (record) => {
      state.visible = true
      nextTick(() => {
        formRef.value.resetFields()
        if (record) {
          state.formState.id = record.id
          setFormStateValue(state.formState, record)
          getProvinceList()
        } else {
          delete state.formState.id
        }
      })
    }

    const close = () => {
      state.visible = false
    }

    return {
      ...toRefs(state),
      formRef,
      rules,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      openApiCodeEnum,
      getLanguageName,
      handleChangeCountry,
      handleConfirm,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>
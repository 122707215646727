<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.customer_account_list") }}
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="[8,8]">
        <a-col>
          <a-input style="width: 250px;" v-model:value="searchData.searchKey" :placeholder="$t('account.account_name')" allow-clear></a-input>
        </a-col>
        <a-col>
          <CSearchShop v-model:shopId="searchData.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleSearch">{{$t('common.query')}}</a-button>
        </a-col>
      </a-row>
      <a-tabs
        v-model:activeKey="searchData.isLocked"
        :animated="false"
        @change="handleTabChange"
      >
        <a-tab-pane
          v-for="item in isLockedList"
          :key="item.key"
          :tab="`${item.name} ${item.count}`"
        ></a-tab-pane>
      </a-tabs>
      <a-table
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 140 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #userName="{ record }">
          <div>{{ record.userName }}</div>
          <div>{{ record.mail || '-' }}</div>
          <div>{{ record.userNo }}</div>
        </template>
        <template #shopName="{ record }">
          <div>{{ record.shopName }}</div>
          <div>{{ record.businessLicenseCode }}</div>
        </template>
        <template #noShopMark="{ record }">
          <div>{{ record.shopNo }}</div>
          <div>{{ record.shopMark }}</div>
        </template>
        <template #countryName="{ record }">
          {{ getCountryName(record.countryCnName, record.countryEnName) }}
        </template>
        <template #createTime="{ record }">
          {{ $filters.utcToCurrentTime(record.createTime) }}
        </template>
        <template #lastLoginTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastLoginTime) }}
        </template>
        <template #isLocked="{ record }">
          <a-tag color="red" v-if="record.isLocked">{{ $t("account.locked") }}</a-tag>
          <a-tag color="green" v-else>{{ $t("account.in_the_activity") }}</a-tag>
        </template>
        <template #operation="{ record }">
          <a-dropdown-button>
            <router-link
              :to="{
                name: 'customer_account_details',
                params: { id: record.userId },
              }"
            >
              {{ $t("common.details") }}
            </router-link>
            <template #overlay>
              <a-menu @click="handleClickOperatMenu">
                <a-menu-item :key="4" :record="record">
                  {{ $t("account.user_roles") }}
                </a-menu-item>
                <a-menu-item :key="1" v-if="record.isLocked" :record="record">
                  {{ $t("account.unlock") }}
                </a-menu-item>
                <a-menu-item :key="2" :record="record" v-else>
                  {{ $t("account.locking") }}
                </a-menu-item>
                <a-menu-item :key="3" :record="record">
                  {{ $t("common.delete") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <UserRolesModal ref="userRolesModalRef" @refresh="handleTabChange" />
    </template>
    <template #contentFooter>
      <c-pager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRaw, toRefs, ref } from "vue";
import {
  Row,
  Col,
  Tabs,
  Table,
  Tag,
  Select,
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  Input,
} from "ant-design-vue";
import Content from "@/views/components/Content";
import CPager from "@/views/components/CPager";
import UserRolesModal from './components/UserRolesModal.vue';
import CSearchShop from "../../components/CSearchShop.vue"
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { getName } from "@/utils/general";
import {
  getSelectOptionList,
  getShopUserStatistics,
  getShopUserListPaged,
  lockShopUser,
  continueShopUser,
  deleteShopUser,
} from "@/api/modules/customer/account";

export default defineComponent({
  name: 'customer_account_list',
  components: {
    CSearchShop,
    ARow: Row,
    ACol: Col,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATable: Table,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AButton: Button,
    AInput: Input,
    ATag: Tag,
    Content,
    CPager,
    UserRolesModal,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const userRolesModalRef = ref(null);

    const state = reactive({
      companyList: [],
      searchData: {
        shopId: null,
        isLocked: "allCount",
      },
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const isLockedList = reactive([
      {
        name: vueI18n.t("account.all"),
        value: null,
        key: "allCount",
        count: 0,
      },
      {
        name: vueI18n.t("account.in_the_activity"),
        value: false,
        key: "activeCount",
        count: 0,
      },
      {
        name: vueI18n.t("account.locked"),
        value: true,
        key: "isLockCount",
        count: 0,
      },
    ]);

    const columns = [
      {
        title: () => vueI18n.t("account.account_name") + '/' + vueI18n.t('account.secure_mailbox') + '/' + 'NO',
        width: 200,
        slots: {
          customRender: "userName",
        },
      },
      {
        title: () =>
          vueI18n.t("account.company_name") +
          "/" +
          vueI18n.t("account.register_id"),
        width: 200,
        slots: {
          customRender: "shopName",
        },
      },
      {
        dataIndex: "userNo",
        title: () => "NO"+"/"+vueI18n.t("common.shop_mark"),
         slots: {
          customRender: "noShopMark",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("account.country"),
        width: 150,
        slots: {
          customRender: "countryName",
        },
      },
      {
        dataIndex: "timezone",
        title: () => vueI18n.t("account.timezone"),
        width: 150,
      },
      {
        title: () => vueI18n.t("account.registration_date"),
        width: 150,
        slots: {
          customRender: "createTime",
        },
      },
      {
        title: () => vueI18n.t("account.last_login_int"),
        width: 150,
        slots: {
          customRender: "lastLoginTime",
        },
      },
      {
        title: () => vueI18n.t("account.account_status"),
        width: 100,
        slots: {
          customRender: "isLocked",
        },
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 150,
        fixed: 'right',
        slots: {
          customRender: "operation",
        },
      },
    ];

    const getPageList = () => {
      state.tableData.loading = true;
      // 列表
      let isLocked = isLockedList.filter(
        (item) => item.key === state.searchData.isLocked
      )[0].value;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchData,
        {
          isLocked,
          isManager: true
        }
      );
      getShopUserListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const handleSearch = async () => {
      try {
        let { result: statisticsRes } = await getShopUserStatistics({
          shopId: state.searchData.shopId ?? 0,
          isManager: true
        });
        if (statisticsRes) {
          toRaw(isLockedList).map((item, index) => {
            isLockedList[index].count = statisticsRes[item.key]
          })
        }

        handleTabChange();
      } catch (error) {
      }
    };

    const handleTabChange = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getPageList();
    };

    // 锁定
    const handleLocking = (userId) => {
      lockShopUser({ userId }).then((res) => {
        message.success(vueI18n.t("common.succeed"));
        handleSearch();
      });
    };

    // 解锁
    const handleUnLocking = (id) => {
      continueShopUser([id]).then((res) => {
        message.success(vueI18n.t("common.succeed"));
        handleSearch();
      });
    };

    // 删除
    const handleDelete = (record) => {
      Modal.confirm({
        title: () => vueI18n.t('warehouse.operation_tips'),
        content: () => vueI18n.t('account.you_are_performing_a_delete_operation'),
        onOk() {
          deleteShopUser({ shopId: record.shopId, userId: record.userId }).then(() => {
            message.success(vueI18n.t("common.succeed"));
            handleSearch();
          });
        },
      });
    };

    const handleClickOperatMenu = (val) => {
      let record = val.item.record;
      switch (val.key) {
        case 1:
          handleUnLocking(record.userId);
          break;
        case 2:
          handleLocking(record.userId);
          break;
        case 3:
          handleDelete(record);
          break;
        case 4:
          userRolesModalRef.value.init(record);
          break;
        default:
          break;
      }
    };

    const initPage = async () => {
      try {
        let { result: companyList } = await getSelectOptionList({
          shopAccountStatus: 10
        });
        state.companyList = companyList ?? [];

        handleSearch()
      } catch (error) {}
    };

    const getCountryName = (cnName, enName) => {
      return getName({ cnName, enName }, getters.language);
    };

    onMounted(initPage);

    return {
      ...toRefs(state),
      isLockedList,
      columns,
      userRolesModalRef,
      handleSearch,
      handleTabChange,
      handlePage,
      getPageList,
      getCountryName,
      handleClickOperatMenu,
    };
  },
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('warehouse.reconciliation')"
    :mask-closable="false"
    centered
  >
    <a-row>
      <a-col>
        <a-upload
          accept=".xlsx"
          :customRequest="handleUpdateExcel"
          :showUploadList="false"
          :multiple="true"
        >
          <a-button :loading="loading">
            <upload-outlined></upload-outlined>
            {{ $t("warehouse.select_import_file") }}
          </a-button>
        </a-upload>
      </a-col>
      <a-col>
        <a-button
          type="link"
          :loading="downloadLoading"
          @click="handleDownloadExample"
          >{{ $t("warehouse.click_download_template") }}</a-button
        >
      </a-col>
    </a-row>
    <template #footer>
      <a-button key="back" @click="handleCancel">{{
        $t("common.close")
      }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { Button, Col, Modal, Row, Upload, message } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import {
  getAccountRecordTemplate,
  parseExcelDataForWaybill,
  parseExcelDataForAgentOrder,
} from "@/api/modules/finance/orderWaybill.js";
import { accountRecordType as accountRecordTypeEnum } from "@/enum/enum.json";
import { downloadFile } from "@/utils/downloader";
import { maxUploadFileSizeByte } from '../../../../utils/common';

export default defineComponent({
  emits: ["confirm"],
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    AUpload: Upload,
  },
  props: {
    recordType: Number,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      loading: false,
      downloadLoading: false,
    });

    const handleUpdateExcel = async (files) => {
      try {
        if (files?.file?.size > maxUploadFileSizeByte() ) {
          message.error(vueI18n.t('common.please_upload_file_with_size_less_than_50MB'));
          return
        }

        let parameter = new FormData();
        parameter.append("file", files.file);
        state.loading = true;
        let res = null;
        if (props.recordType === accountRecordTypeEnum.waybill) {
          res = await parseExcelDataForWaybill(parameter);
        } else if (props.recordType === accountRecordTypeEnum.agentOrder) {
          res = await parseExcelDataForAgentOrder(parameter);
        }
        let { result } = res;
        if (Array.isArray(result) && result.length) {
          emit("confirm", result);
          state.visible = false;
        } else {
          message.error(vueI18n.t("common.exception"));
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    const handleDownloadExample = () => {
      state.downloadLoading = true;
      let url = getAccountRecordTemplate();
      let name =
        props.recordType === accountRecordTypeEnum.waybill
          ? "Waybill reconciliation template"
          : props.recordType === accountRecordTypeEnum.agentOrder
          ? "Agent order reconciliation template"
          : "Reconciliation template";
      downloadFile(url, `${name}.xlsx`, "GET")
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .catch(() => {})
        .finally(() => {
          state.downloadLoading = false;
        });
    };

    const handleCancel = () => {
      state.visible = false;
    };

    const open = () => {
      state.visible = true;
    };

    return {
      ...toRefs(state),
      handleUpdateExcel,
      handleDownloadExample,
      handleCancel,
      open,
    };
  },
});
</script>

<style scoped></style>

<template>
  <Content>
    <template #contentTitle>{{ $t("menu.warehouse_monitor_print_waybill") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 8]">
        <a-col>
          <a-input style="width:250px"
            v-model:value="searchState.searchKey"
            :placeholder="`${$t('warehouse.customer_number')}/${$t('logistics.logistics_track_no')}`"
            allowClear
          />
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.planNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="18"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('warehouse.system_number')"
            allowClear
            @change="handleSelectValueChange('planNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.destinationType"
            :placeholder="$t('logistics.destination_type')"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in destinationTypeList"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('destinationType', item))"
              >{{
                $t($enumLangkey("destinationType", item))
              }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.fromCountryId"
            :placeholder="$t('warehouse.originating_country')"
            optionFilterProp="search-key"
            allowClear
            show-search
          >
            <a-select-option
              v-for="(item, index) in fromCountryList"
              :key="index"
              :value="item.countryId"
              :search-key="item.ioS2 + item.countryCnName + item.countryEnName"
              :title="`${getLanguageName(item.countryCnName, item.countryEnName)}(${item.ioS2})`"
              >{{ getLanguageName(item.countryCnName, item.countryEnName) }}({{ item.ioS2 }})</a-select-option
            >
          </a-select>
        </a-col>
        <a-col>
          <a-input-group compact>
            <a-select
              style="width: 150px"
              v-model:value="searchState.toCountryId"
              :placeholder="$t('common.destination_country')"
              @change="handleToCountryChange"
              show-search
              optionFilterProp="search-key"
              allowClear
            >
              <a-select-option
                v-for="(item, index) in toCountryList"
                :key="index"
                :search-key="item.ioS2 + item.cnName + item.enName"
                :title="`${getLanguageName(item.cnName, item.enName)}(${item.ioS2})`"
                :value="item.id"
                >{{ getLanguageName(item.cnName, item.enName) }}({{ item.ioS2 }})</a-select-option>
            </a-select>
            <a-select
              style="width: 150px"
              v-model:value="searchState.toCityId"
              :placeholder="$t('common.city')"
              :filter-option="false"
              show-search
              optionFilterProp="search-key"
              @search="getCityList"
              :disabled="!searchState.toCountryId"
              allowClear
            >
              <a-select-option
                v-for="(item, index) in toCityList"
                :key="index"
                :value="item.id"
                :search-key="item.cnName + item.enName"
                :title="getLanguageName(item.cnName, item.enName)+ '(' + getLanguageName( item.provinceCnName, item.provinceEnName) + ')'"
              >
                <template v-if="toCityListLoading" #notFoundContent>
                  <a-spin size="small" />
                </template>
                {{ getLanguageName(item.cnName, item.enName) }}({{
                      item.provinceAbbrCode
                    }})
              </a-select-option>
            </a-select>
          </a-input-group>
        </a-col>
        <a-col>
          <a-space>
            <a-select
              style="width: 250px"
              v-model:value="searchState.waybillOperationStatus"
              :placeholder="$t('warehouse.operating_state')"
              allowClear
            >
              <a-select-option
                v-for="(item, index) in waybillOperationStatusList"
                :key="index"
                :value="item"
                :title="$t($enumLangkey('waybillOperationStatus', item))"
                >{{
                  $t($enumLangkey("waybillOperationStatus", item))
                }}</a-select-option
              >
            </a-select>
          </a-space>
        </a-col>
        <a-col>
          <a-space>
            <a-select
            style="width: 250px"
              v-model:value="searchState.isReconciliation"
              :placeholder="$t('warehouse.is_reconciliation')"
              allowClear
            >
              <a-select-option :value="1" :title="$t('common.yes')">{{ $t('common.yes') }}</a-select-option>
              <a-select-option :value="0" :title="$t('common.no')">{{ $t('common.no') }}</a-select-option>
            </a-select>
          </a-space>
        </a-col>
        <a-col>
          <a-space>
            <span>{{ $t("warehouse.creation_time") }}</span>
            <a-range-picker style="width: 200px" @change="handleDateChange" />
          </a-space>
        </a-col>
        <a-col>
          <a-space>
            <a-button type="primary" @click="handleSearch">{{
              $t("common.query")
            }}</a-button>
            <a-button type="ghost" @click="handleShowPinModal(0)">{{
              $t("common.export")
            }}</a-button>
            <a-button type="ghost" @click="handleUploadReconciliationExcel">{{
              $t("warehouse.reconciliation")
            }}</a-button>
          </a-space>
        </a-col>
      </a-row>
      <a-table
        class="mt-2"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 150 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #orderNo>
          <div>{{ $t("warehouse.customer_number") }}</div>
          <div>{{ $t("warehouse.system_number") }}</div>
        </template>
        <template #channelTitle>
          <div>{{ $t("finance.channel_code") }}</div>
          <div>{{ $t("warehouse.channel_name") }}</div>
        </template>
        <template #countTitle>
          <div>{{ $t("warehouse.number_of_waybills") }} /</div>
          <div>{{ $t("warehouse.reconciliation_quantity") }}</div>
        </template>
        <template #weightTitle>
          <div>{{ $t("warehouse.billing_weight_of_waybill") }} /</div>
          <div>{{ $t("warehouse.reconciliation_billing_weight") }}</div>
        </template>
        <template #accountCurrencyTitle>
          <div>{{ $t("warehouse.reconciliation_amount") }} /</div>
          <div>{{ $t("finance.exchange_rate") }}</div>
        </template>

        <template #orderNoCustom="{ record }">
          <div>{{ record.customerNo }}</div>
          <div>{{ record.planNo }}</div>
        </template>
        <template #transportRoutes="{ record }">
          <div>
            {{ $t($enumLangkey("transportRoutes", record.transportRoutes)) }}
          </div>
        </template>
        <template #destinationType="{ record }">
          <div>
            {{ $t($enumLangkey("destinationType", record.destinationType)) }}
          </div>
        </template>
        <template #from="{ record }">
          <div>
            {{
              getLanguageName(
                record.from.countryCnName,
                record.from.countryEnName
              )
            }}
          </div>
        </template>
        <template #to="{ record }">
          {{
            getLanguageName(record.to.countryCnName, record.to.countryEnName)
          }}
          /
          {{ getLanguageName(record.to.cityCnName, record.to.cityEnName) }}
        </template>
        <template #totalWeight="{ record }">
          {{ handleGToKg(record.totalWeight) }}kg
        </template>
        <template #totalVolume="{ record }">
          {{ handleCmCubicToM(record.totalVolume) }}m<sup>3</sup>
        </template>
        <template #totalBillingWeight="{ record }">
          {{ handleGToKg(record.totalBillingWeight) }}kg
        </template>
        <template #totalPrice="{ record }">
          <span v-if="record.totalPrice">{{ $filters.formatCurrencyAmount( record.symbol, record.totalPrice, false) }}</span>
          <span v-else>-</span>
        </template>
        <template #openApiCode="{ record }">
          <span v-if="record.openApiCode">{{ $t($enumLangkey('openApiCode', record.openApiCode)) }}</span>
          <span v-else>-</span>
        </template>
        <template #supplierLogistic="{ record }">
          <template v-if="record.supplierLogisticCode && record.supplierLogisticName">
            <div>{{ record.supplierLogisticCode }}</div>
            <div>{{ record.supplierLogisticName }}</div>
          </template>
          <span v-else>-</span>
        </template>
         <template #customer="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
            <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
        <template #transferOrderNo="{ record }">
          <span v-if="record.transferOrderNo">{{ record.transferOrderNo }}</span>
          <span v-else>-</span>
        </template>
        <template #waybillOperationStatus="{ record }">
          <span v-if="record.waybillOperationStatus">{{
            $t(
              $enumLangkey(
                "waybillOperationStatus",
                record.waybillOperationStatus
              )
            )
          }}</span>
          <span v-else>-</span>
          <span v-if="record.hasError" style="cursor: pointer; margin-left: 5px;" @click="handleShowErrorMessage(record)"><ExclamationCircleOutlined style="color: red;" /></span>
        </template>
        <template #track="{ record }">
          <a-row :gutter="8">
            <a-col><small style="color: #aaa;">{{ $filters.utcToCurrentTime(record.track?.trackTime) }}</small></a-col>
            <a-col><small style="color: #aaa;">{{ record.track?.trackRemark }}</small></a-col>
          </a-row>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <!-- 对账相关 -->
        <template #isReconciliation="{ record }">
          <span v-if="record.isReconciliation"><CheckOutlined class="text-primary" /></span>
          <span v-else>-</span>
        </template>
        <template #reconciliationTime="{ record }">
          <span v-if="record.wayBillCheck">{{ $filters.utcToCurrentTime(record.wayBillCheck.reconciliationTime) }}</span>
          <span v-else>-</span>
        </template>
        <template #count="{ record }">
          <span v-if="record.waybillCount">{{ record.waybillCount }}</span>
          <span v-else>-</span> /
          <span v-if="record.wayBillCheck">{{ record.wayBillCheck.accountWaybillCount }}</span>
          <span v-else>-</span>
        </template>
        <template #weight="{ record }">
          <span v-if="record.chargeableWeight">{{ handleGToKg(record.chargeableWeight) }}kg</span>
          <span v-else>-</span> /
          <span v-if="record.wayBillCheck">{{ handleGToKg(record.wayBillCheck.accountChargeableWeight) }}kg</span>
          <span v-else>-</span>
        </template>
        <template #account="{ record }">
          <span v-if="record.wayBillCheck">
            <div v-if="record.wayBillCheck.accountCurrency && record.wayBillCheck.conversionAmount">
              <span>{{
                $filters.formatCurrencyAmount(
                  record.wayBillCheck.accountCurrency.currencySymbol,
                  record.wayBillCheck.accountCurrency.amount,
                  false
                )
              }}</span>
              (<span class="text-success">{{
                $filters.formatCurrencyAmount(
                  record.wayBillCheck.conversionAmount.currencySymbol,
                  record.wayBillCheck.conversionAmount.amount,
                  false
                )
              }}</span
              >) /
            </div>
            <div v-else>- /</div>
            <div v-if="record.wayBillCheck.currencyRate > 0">{{ record.wayBillCheck.currencyRate }}</div>
            <div v-else>{{ record.wayBillCheck.currencyRate }}</div>
          </span>
          <span v-else>- / -</span>
          <div v-if="record.isReconciliation" class="text-primary" style="cursor: pointer;" @click="handleShowReconciliationTableModal(record)">{{ $t('common.details') }}</div>
        </template>
        <template #profitAndLoss="{ record }">
          <span v-if="record.wayBillCheck && record.wayBillCheck.profitLossCurrency">
            <span
              v-if="record.wayBillCheck.profitLossCurrency.amount > 0"
              class="text-success"
              >{{
                $filters.formatCurrencyAmount(
                  record.wayBillCheck.profitLossCurrency.currencySymbol,
                  record.wayBillCheck.profitLossCurrency.amount
                )
              }}</span
            >
            <span v-else class="text-error">{{
              $filters.formatCurrencyAmount(
                record.wayBillCheck.profitLossCurrency.currencySymbol,
                record.wayBillCheck.profitLossCurrency.amount
              )
            }}</span>
          </span>
          <span v-else>-</span>
        </template>
        <template #employee="{ record }">
          <template v-if="record.wayBillCheck">
            <CEmployeeInfo v-if="record.wayBillCheck.employee" :employee="record.wayBillCheck.employee" />
            <span v-else-if="record.wayBillCheck.operationUserName">
              {{ record.wayBillCheck.operationUserName }}
            </span>
            <span v-else>-</span>
          </template>
          <span v-else>-</span>
        </template>
        <!-- 操作 -->
        <template #operation="{ record }">
          <a-dropdown-button>
            <router-link :to="{ name: 'warehouse_monitor_print_waybill_detail', params: { id: record.id } }">
              {{ $t("common.details") }}
            </router-link>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :key="1" :record="record">
                  {{ $t("warehouse.check_the_track") }}
                </a-menu-item>
                <a-menu-item :key="2" :record="record" :disabled="!record.hasError">
                  {{ $t("warehouse.error_message") }}
                </a-menu-item>
                <a-menu-item :key="3" :record="record" :disabled="!record.canCanelPlan">
                  {{ $t("common.cancel") }}
                </a-menu-item>
                <a-menu-item :key="4" :record="record" :disabled="!record.isReconciliation">
                  {{ $t("warehouse.cancel_reconciliation") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>

       <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("logistics.waybill_number") }}
        </template>
         <template v-slot:no>
          {{trackModal.customerNo}}/{{ trackModal.planNo }}
        </template>
      </CTrackInfoModal>

      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />
      <UploadReconciliationModal ref="uploadReconciliationModalRef" :recordType="accountRecordTypeEnum.waybill" @confirm="handleShowReconciliationModal" />
      <ReconciliationModal ref="reconciliationModalRef" :recordType="accountRecordTypeEnum.waybill" @confirm="handleInitSearch" />
      <ReconciliationTableModal v-model:visible="reconciliationTableModalState.visible" :id="reconciliationTableModalState.id" />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  onActivated,
  onMounted,
  reactive,
  toRefs,
  ref,
} from "vue";
import {
  Row,
  Col,
  Input,
  Space,
  Select,
  DatePicker,
  Button,
  Table,
  Dropdown,
  Menu,
  Spin,
  Modal,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CSearchShop from "@/views/components/CSearchShop.vue"
import CPinTipsModal from "@/views/components/CPinTipsModal.vue"
import CEmployeeInfo from "@/views/components/CEmployeeInfo.vue"
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import UploadReconciliationModal from './components/UploadReconciliationModal.vue'
import ReconciliationModal from './components/ReconciliationModal.vue'
import ReconciliationTableModal from './components/ReconciliationTableModal.vue'
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { downloadFile } from "@/utils/downloader"
import { currentTimeToUtc, gToKg, cmCubicToM, debounce, dateString } from "@/utils/general";
import { getGlobalCountrys, searchCities } from "@/api/modules/common";
import {
  getWayBillListPaged,
  getSourceCountrys,
  cancelWayBill,
  exportWayBill,
  cancelReconciliation,
  getWaybillTrackList,
} from "@/api/modules/warehouseMonitor/printWaybill";
import {
  destinationType as destinationTypeEnum,
  waybillOperationStatus as waybillOperationStatusEnum,
  accountRecordType as accountRecordTypeEnum
} from "@/enum/enum.json";

export default defineComponent({
  name: "warehouse_monitor_print_waybill",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpin: Spin,
    Content,
    CPager,
    CSearchShop,
    CPinTipsModal,
    CEmployeeInfo,
    CTrackInfoModal,
    UploadReconciliationModal,
    ReconciliationModal,
    ReconciliationTableModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const uploadReconciliationModalRef = ref(null);
    const reconciliationModalRef = ref(null);

    const state = reactive({
      // 目的地类型
      destinationTypeList: [],
      // 始发国家
      fromCountryList: [],
      // 目的国家/城市
      toCountryList: [],
      toCityListLoading: false,
      toCityList: [],
      // 操作状态
      waybillOperationStatusList: [],
      searchState: {
        searchKey: null,
        planNoes: [],
        shopId: null,
        destinationType: null,
        fromCountryId: null,
        toCountryId: null,
        toCityId: null,
        waybillOperationStatus: null,
        isReconciliation: null,
        beginCreationTime: null,
        endCreationTime: null,
      },
      searchStateCache: {},
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      pinModalState: {
        visible: false,
        loading: false,
        // 0: 下载; 1: 取消; 2: 取消对账
        type: 0,
        dataTemp: null,
      }
    });

    const reconciliationTableModalState = reactive({
      visible: false,
      id: null
    });

    state.destinationTypeList = Object.values(destinationTypeEnum);
    state.waybillOperationStatusList = Object.values(
      waybillOperationStatusEnum
    );

    const columns = [
      {
        width: 150,
        fixed: "left",
        slots: {
          title: "orderNo",
          customRender: "orderNoCustom",
        },
      },
      {
        width: 180,
        fixed: "left",
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
      },
      {
        width: 90,
        title: () => vueI18n.t("warehouse.shipping_lines"),
        slots: {
          customRender: "transportRoutes",
        },
      },
      {
        width: 90,
        title: () => vueI18n.t("logistics.destination_type"),
        slots: {
          customRender: "destinationType",
        },
      },
      {
        width: 90,
        title: () => vueI18n.t("warehouse.originating_country"),
        slots: {
          customRender: "from",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.destination_country_city"),
        slots: {
          customRender: "to",
        },
      },
      {
        dataIndex: "totalBoxCount",
        width: 60,
        title: () => vueI18n.t("warehouse.number"),
      },
      {
        width: 70,
        title: () => vueI18n.t("logistics.actual_weight"),
        slots: {
          customRender: "totalWeight",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("common.volume"),
        slots: {
          customRender: "totalVolume",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.chargeable_weight"),
        slots: {
          customRender: "totalBillingWeight",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.total_cost"),
        slots: {
          customRender: "totalPrice",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("devops.api_code"),
        slots: {
          customRender: "openApiCode",
        },
      },
      {
        width: 120,
        slots: {
          title: "channelTitle",
          customRender: "supplierLogistic",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.logistics_track_no"),
        slots: {
          customRender: "transferOrderNo",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.operating_state"),
        slots: {
          customRender: "waybillOperationStatus",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.turn_single_track"),
        slots: {
          customRender: "track",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        title: () => vueI18n.t('warehouse.is_reconciliation'),
        width: 80,
        slots: {
          customRender: "isReconciliation"
        }
      },
      {
        title: () => vueI18n.t('warehouse.reconciliation_time'),
        width: 120,
        slots: {
          customRender: "reconciliationTime"
        }
      },
      {
        width: 100,
        slots: {
          title: "countTitle",
          customRender: "count"
        }
      },
      {
        width: 100,
        slots: {
          title: "weightTitle",
          customRender: "weight"
        }
      },
      {
        width: 120,
        slots: {
          title: "accountCurrencyTitle",
          customRender: "account"
        }
      },
      {
        title: () => vueI18n.t('warehouse.profit_and_loss_amount'),
        width: 100,
        slots: {
          customRender: "profitAndLoss"
        }
      },
      {
        title: () => vueI18n.t('account.operator'),
        width: 150,
        slots: {
          customRender: "employee"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("common.operate"),
        fixed: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const handleSelectValueChange = (key) => {
      let value = state.searchState[key];
      if (Array.isArray(value)) {
        state.searchState[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handleShowPinModal = (type) => {
      state.pinModalState.type = type
      state.pinModalState.visible = true
    }

    const handlePinModalConfirm = (pinCode) => {
      switch (state.pinModalState.type) {
        case 0:
          handleDownloadExcel(pinCode)
          break;
        case 1:
          handleCancelOrder(pinCode)
          break;
        case 2:
          handleCancelReconciliation(pinCode)
          break;
        default:
          break;
      }
    }

    const getLanguageName = (cnName, enName) => {
      if (getters.language === "zh-CN") {
        return cnName;
      }
      return enName;
    };

    const handleGToKg = (val) => {
      if (!val) {
        return 0;
      }
      return gToKg(val);
    };

    const handleCmCubicToM = (val) => {
      if (!val) {
        return 0;
      }
      return cmCubicToM(val);
    };

    const handleDownloadExcel = (pinCode) => {
      state.pinModalState.loading = true
      let url = exportWayBill();
      const data = Object.assign({}, state.searchStateCache, { pinCode })
      downloadFile(url, `print_waybill_${dateString()}.csv`, "POST", data).then(() => {
        state.pinModalState.visible = false
      }).catch(() => { }).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handleDateChange = (e) => {
      let beginTime = null,
        endTime = null;
      if (e?.length === 2) {
        beginTime = currentTimeToUtc(e[0]?.format("YYYY-MM-DD 00:00:00"));
        endTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.beginCreationTime = beginTime;
      state.searchState.endCreationTime = endTime;
    };

    const getPageData = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.searchStateCache,
        state.tableData.pageData
      );
      data.isReconciliation = data.isReconciliation === 0 ? false : data.isReconciliation === 1 ? true : null
      getWayBillListPaged(data)
        .then(({ result }) => {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        }).catch(() => {})
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const handleToCountryChange = () => {
      state.toCityList = [];
      state.searchState.toCityId = null;
    };

    const getCityList = debounce((searchKey) => {
      if (searchKey) {
        let countryId = state.searchState.toCountryId;
        state.toCityList = [];
        if (countryId) {
          state.toCityListLoading = true;
          const data = {
            countryId,
            searchKey,
            skipCount: 0,
            maxResultCount: 10,
          };
          searchCities(data)
            .then(({ result }) => {
              if (result) {
                let { items = [] } = result;
                state.toCityList = items;
              }
            })
            .finally(() => {
              state.toCityListLoading = false;
            });
        }
      }
    }, 500);

    const handleShowErrorMessage = (record) => {
      Modal.error({
        title: () => vueI18n.t('warehouse.error_message'),
        content: () => record.errorMsg ?? ''
      })
    }

    const handleMenuClick = ({ key, item }) => {
      let { record } = item;
      switch (key) {
        case 1:
          handleShowTrackModal(record);
          break;
        case 2:
          handleShowErrorMessage(record)
          break;
        case 3:
          state.pinModalState.dataTemp = record.id
          handleShowPinModal(1)
          break;
        case 4:
          state.pinModalState.dataTemp = record.id
          handleShowPinModal(2)
          break;
        default:
          break;
      }
    };

     const trackModal = reactive({
      visible: false,
      loading: false,
      customerNo: null,
      planNo: null,
      list: [],
      id: null,
    });

    const handleShowTrackModal = (record) => {
      trackModal.visible = true;
      trackModal.customerNo = record.customerNo;
      trackModal.planNo = record.planNo;
      trackModal.planId = record.id;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getWaybillTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    const handleCancelOrder = (pinCode) => {
      const data = {
        pinCode,
        id: state.pinModalState.dataTemp
      }
      state.pinModalState.loading = true
      cancelWayBill(data).then(() => {
        state.pinModalState.visible = false
        message.success(vueI18n.t('common.succeed'))
        getPageData()
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    // 取消对账
    const handleCancelReconciliation = (pinCode) => {
      const data = {
        pinCode,
        id: state.pinModalState.dataTemp
      }
      state.pinModalState.loading = true
      cancelReconciliation(data).then(() => {
        state.pinModalState.visible = false
        message.success(vueI18n.t('common.succeed'))
        getPageData()
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handleUploadReconciliationExcel = () => {
      uploadReconciliationModalRef.value.open()
    }

    const handleShowReconciliationModal = (list) => {
      reconciliationModalRef.value.open(list)
    }

    const handleShowReconciliationTableModal = (record) => {
      reconciliationTableModalState.id = record.id;
      reconciliationTableModalState.visible = true;
    }

    onMounted(async () => {
      try {
        let { result: fromCountryList } = await getSourceCountrys();
        if (fromCountryList) {
          state.fromCountryList = fromCountryList;
        }

        let { result: toCountryList } = await getGlobalCountrys({
          isActive: true,
        });
        if (toCountryList) {
          state.toCountryList = toCountryList;
        }
      } catch (error) {}
    });

    onActivated(handleInitSearch);

    return {
      ...toRefs(state),
      reconciliationTableModalState,
      columns,
      trackModal,
      uploadReconciliationModalRef,
      reconciliationModalRef,
      waybillOperationStatusEnum,
      accountRecordTypeEnum,
      handleDownloadExcel,
      handleInitSearch,
      handlePage,
      handleDateChange,
      handleSearch,
      getLanguageName,
      handleGToKg,
      handleCmCubicToM,
      handleToCountryChange,
      getCityList,
      handleShowErrorMessage,
      handleMenuClick,
      handleShowPinModal,
      handlePinModalConfirm,
      handleSelectValueChange,
      handleUploadReconciliationExcel,
      handleShowReconciliationModal,
      handleShowReconciliationTableModal,
    };
  },
});
</script>

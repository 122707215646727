import client from "../../client";
import urlHelper from "@/utils/urlHelper.js";
import siteOptions from "@/siteconfigs/index";

const Api = {
  // 存储库存列表
  getManageInventoryListPaged: "/api/WarehouseService/Box/GetManageInventoryListPaged",
  // 配送仓库存列表
  getManageDeliveryWarehouseInventoryPaged: "/api/WarehouseService/ProductInventory/GetManageDeliveryWarehouseInventoryPaged",
  // 存储仓 导出产品库龄
  exportInventoryListWithStockAge:'/api/WarehouseService/Box/ExportManagerInventoryListWithStockAge',
  // 配送仓 导出产品库龄
  exportInventoryListWithStockAgeConsignment:"/api/WarehouseService/ProductInventory/GetManagerDelegateProductStockAgeConsole",
  // 中转仓仓库存列表
  getTransitShipmentDetailListPaged: "/api/WarehouseService/TransitShipmentDetail/GetListPagedByConsole",
  // 中转仓货件列表
  getTransitShipmentListPaged: "/api/WarehouseService/TransitShipment/GetListPagedByConsole",
  // 中转仓货件列表-打印
  batchSeShipmentBoxLabel: "/api/WarehouseService/TransitShipmentPrint/BatchPrintSEShpimentBox/BatchSeShipmentBoxLabel.pdf",
};

export const getManageInventoryListPaged = (data) => {
  return client.request({
    url: Api.getManageInventoryListPaged,
    data,
    method: "POST",
  });
};

export const getManageDeliveryWarehouseInventoryPaged = (data) => {
  return client.request({
    url: Api.getManageDeliveryWarehouseInventoryPaged,
    data,
    method: "POST",
  });
};

export const exportInventoryListWithStockAge = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportInventoryListWithStockAge);
};



export const exportInventoryListWithStockAgeConsignment = () => {
  return urlHelper.combineURLs(siteOptions.baseURL, Api.exportInventoryListWithStockAgeConsignment);
};

export const getTransitShipmentDetailListPaged = (data) => {
  return client.request({
    url: Api.getTransitShipmentDetailListPaged,
    data,
    method: "POST",
  });
};

export const getTransitShipmentListPaged = (data) => {
  return client.request({
    url: Api.getTransitShipmentListPaged,
    data,
    method: "POST",
  });
};

export const batchSeShipmentBoxLabel = (data) => {
  let url = urlHelper.combineURLs(
    siteOptions.baseURL,
    Api.batchSeShipmentBoxLabel
  );
  url = urlHelper.addQueryString(url, "planId", data.planId);
  url = urlHelper.addQueryString(url, "shipmentId", data.shipmentId );
  return url;
};
import client from "../../client"

// API管理
const Api = {
  // 获取币种缓存数量
  getCurrencyCacheCount: "/api/BasicDataService/Currency/GetCacheCount",
  // 清空币种缓存
  clearCurrencyCache: "/api/BasicDataService/Currency/ClearCache",
  // 获取时区缓存数量
  getCustomTimeZoneCount: "/api/BasicDataService/CustomTimeZone/GetCacheCount",
  // 清空时区缓存
  clearCustomTimeZoneCache: "/api/BasicDataService/CustomTimeZone/ClearCache",
  // 获取平台缓存数量
  getPlatformAddressCacheCount: "/api/BasicDataService/PlatformAddress/GetCacheCount",
  // 清空平台地址缓存
  clearPlatformAddressCache: "/api/BasicDataService/PlatformAddress/ClearCache",
  // 获取国家缓存数量
  getCountryCacheCount: "/api/BasicDataService/Country/GetCacheCount",
  // 清空国家缓存
  clearCountryCache: "/api/BasicDataService/Country/ClearCache",
  // 清理Oss临时空间
  clearAliCloudOSSFiles: "/api/BasicDataService/AliyunOSS/ClearFiles",
}

export const getCurrencyCacheCount = (data) => {
  return client.request({
    url: Api.getCurrencyCacheCount,
    data,
    method: 'POST'
  })
}

export const clearCurrencyCache = (data) => {
  return client.request({
    url: Api.clearCurrencyCache,
    data,
    method: 'POST'
  })
}

export const getCustomTimeZoneCount = (data) => {
  return client.request({
    url: Api.getCustomTimeZoneCount,
    data,
    method: 'POST'
  })
}

export const clearCustomTimeZoneCache = (data) => {
  return client.request({
    url: Api.clearCustomTimeZoneCache,
    data,
    method: 'POST'
  })
}

export const getPlatformAddressCacheCount = (data) => {
  return client.request({
    url: Api.getPlatformAddressCacheCount,
    data,
    method: 'POST'
  })
}

export const clearPlatformAddressCache = (data) => {
  return client.request({
    url: Api.clearPlatformAddressCache,
    data,
    method: 'POST'
  })
}

export const getCountryCacheCount = (data) => {
  return client.request({
    url: Api.getCountryCacheCount,
    data,
    method: 'POST'
  })
}

export const clearCountryCache = (data) => {
  return client.request({
    url: Api.clearCountryCache,
    data,
    method: 'POST'
  })
}

export const clearAliCloudOSSFiles = (data) => {
  return client.request({
    url: Api.clearAliCloudOSSFiles,
    data,
    method: 'POST'
  })
}
import client from "../../client"

const Api = {
  // 获取佣金列表
  getAllGlobalCommission: "/api/SupplierService/PartnerGlobalCommission/GetAllGlobalCommission",
  // 修改/添加佣金-仓储服务佣金
  createOrUpdateGlobalStorageCommission: "/api/SupplierService/PartnerGlobalCommission/CreateOrUpdateGlobalStorageCommission",
  // 修改/添加佣金-运输服务佣金
  createOrUpdateGlobalTransportCommission: "/api/SupplierService/PartnerGlobalCommission/CreateOrUpdateGlobalTransportCommission",
}

export const getAllGlobalCommission = () => {
    return client.request({
      url: Api.getAllGlobalCommission,
      method: 'POST'
    })
}

export const createOrUpdateGlobalStorageCommission = (data) => {
    return client.request({
      url: Api.createOrUpdateGlobalStorageCommission,
      data: data,
      method: 'POST'
    })
}

export const createOrUpdateGlobalTransportCommission = (data) => {
    return client.request({
      url: Api.createOrUpdateGlobalTransportCommission,
      data: data,
      method: 'POST'
    })
}

<template>
  <!-- 物流渠道 -->
  <a-table
    class="mt-2"
    :columns="columnsLogisticsScheme"
    size="small"
    :data-source="tableData.list"
    :pagination="false"
    :rowKey="(record) => record.logisticsBaseInfo.id"
    :scroll="{ x: 500 }"
  >
    <template #transportTitle>
      <span>{{$t('logistics.logistics_scheme')}} / {{ $t('logistics.logistics_code') }}</span>
    </template>
    <template v-slot:transportName="{ record }">
      <div style="color: orange">{{ record.logisticsBaseInfo.name }} / {{ record.logisticsBaseInfo.code }}</div>
      <a-row :gutter="[8, 4]">
        <a-col v-for="item in record.logisticsBaseInfo.descriptionItems" :key="item">
          <CheckCircleOutlined :style="{ fontSize: '12px', color: 'orange' }" />
          <span style="font-size: 12px; margin-left: 4px; color: rgba(102, 102, 102, 1)">{{ item }}</span>
        </a-col>
      </a-row>
    </template>
    <template v-slot:logisticsType="{ record }">
      <span>{{ $t($enumLangkey('logisticsWay', record.logisticsBaseInfo.logisticsType)) }}</span>
    </template>
    <template v-slot:deliveryMethod="{ record }">
      <span>{{ $t($enumLangkey('deliveryWay', record.logisticsBaseInfo.deliveryType)) }}</span>
    </template>
    <template v-slot:referencePrescription="{ record }">
      <span
        >{{ record.logisticsBaseInfo.daysMin }} -
        {{ record.logisticsBaseInfo.daysMax }}{{ $t("logistics.workday") }}</span>
    </template>
    <template v-slot:details="{ record }">
      <div>
        <div
          v-if="
            record.logisticsBaseInfo.logisticsCalcMethod ===
            logisticsCalcMethodEnum.weight
          "
        >
          {{ $t("logistics.chargeable_weight") }}:
          <span v-if="record.totalChargeableWeight"
            >{{
              handleGToKg(record.totalChargeableWeight)
            }}
            kg</span
          >
        </div>
        <div
          v-else-if="
            record.logisticsBaseInfo.logisticsCalcMethod ===
            logisticsCalcMethodEnum.volume
          "
        >
          {{ $t("logistics.chargeable_volume") }}:
          <span v-if="record.totalChargeableVolume"
            >{{
              handleCmCubicToM(record.totalChargeableVolume)
            }}
            m³</span
          >
        </div>
        <div>
          {{ $t("logistics.volume_weight_coefficient")
          }}{{ record.logisticsBaseInfo.volumeWeightParam }}
        </div>
      </div>
    </template>
    <template v-slot:selectedService="{ record }">
      <div v-if="record.logisticsRegionInfo.isDdpSupported || record.logisticsRegionInfo.isSignSupported">
        <div v-if="record.logisticsRegionInfo.isDdpSupported">
          {{
            $t(
              $enumLangkey(
                "orderLogisticsFee",
                record.logisticsRegionInfo.ddpFeeType
              )
            )
          }}({{ $filters.formatCurrencyAmount(record.logisticsBaseInfo.currencySymbol, record.logisticsRegionInfo.ddpFee, false) }})
        </div>
        <div v-if="record.logisticsRegionInfo.isSignSupported">
          <span>{{ $t("common.signature") }}:</span>
          <div class="pl-2" v-for="(item, index) in record.logisticsRegionInfo.signFeeDtos" :key="index">
            {{ $t($enumLangkey("signatureType", item.signatureType)) }} ({{
              $t("logistics.service_charge")
            }} {{ $filters.formatCurrencyAmount(record.logisticsBaseInfo.currencySymbol, item.fee, false) }})
          </div>
        </div>
      </div>
      <span v-else>-</span>
    </template>
  </a-table>
</template>

<script>
import { reactive, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { Table, Row, Col, Tag } from "ant-design-vue";
import { gToKg, cmCubicToM } from "@/utils/general";
import { logisticsCalcMethod as logisticsCalcMethodEnum } from "@/enum/enum.json";

export default {
  name: "logistics-channel-table",
  components: {
    ATable: Table,
    ARow: Row,
    ACol: Col,
    ATag: Tag,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const vueI18n = useI18n({ useScope: "global" });

    const tableData = reactive({
      list: []
    });

    const columnsLogisticsScheme = [
      {
        width: 300,
        slots: {
          title: "transportTitle",
          customRender: "transportName",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.logistics_type"),
        slots: {
          customRender: "logisticsType",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.delivery_way"),
        slots: {
          customRender: "deliveryMethod",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.reference_aging"),
        slots: {
          customRender: "referencePrescription",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("common.details"),
        slots: {
          customRender: "details",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("return.optional_services"),
        slots: {
          customRender: "selectedService",
        },
      },
    ];

    const handleGToKg = (val) => {
      if (!val) return 0;
      return gToKg(val);
    };

    const handleCmCubicToM = (val) => {
      if (!val) return 0;
      return cmCubicToM(val);
    };

    watch(() => props.info, (value) => {
      if (value) {
        tableData.list = [value]
      }
    }, { immediate: true })

    return {
      columnsLogisticsScheme,
      tableData,
      logisticsCalcMethodEnum,
      handleGToKg,
      handleCmCubicToM,
    };
  },
};
</script>
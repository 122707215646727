<template>
  <Content>
    <template #contentTitle>
      <span v-if="state.dataId">{{ $t("menu.customer_company_discount_details") }}</span>
      <span v-else>{{ $t("warehouse.add_discount") }}</span>
    </template>
    <template v-slot:contentBody="wrap">
      <!-- 基本信息 -->
      <a-row :gutter="[0,16]">
        <a-col :xs="24"
               :sm="21"
               :md="19"
               :lg="17"
               :xxl="8">
          <a-row :gutter="[0,16]">
            <a-col :span="3"
                   style="text-align:right">{{$t('warehouse.name')}}</a-col>
            <a-col :span="20"
                   :offset="1">
              <a-input v-model:value="state.name"
                       style="width: 200px"></a-input>
            </a-col>
            <a-col :span="3"
                   style="text-align:right">{{$t('warehouse.company')}}</a-col>
            <a-col :span="20"
                   :offset="1">{{state.shopName}}</a-col>
            <a-col :span="3"
                   style="text-align:right">{{$t('warehouse.warehouse')}}</a-col>
            <a-col :span="20"
                   :offset="1">
              <a-select v-model:value="state.warehouseId"
                        show-search
                        @change="funcGetTable"
                        :loading="state.warehousesLoading"
                        optionFilterProp="search-key"
                        :placeholder="$t('common.please_select')"
                        style="width: 200px">
                <a-select-option v-for="item in state.warehouses"
                                 :value="item.value"
                                 :search-key="item.text1 +item.text"
                                 :title="item.text1 +'('+item.text+')'"
                                 :key="item.value">
                  {{ item.text1 +'('+item.text+')'}}
                </a-select-option>
              </a-select>

            </a-col>
          </a-row>
        </a-col>

      </a-row>
      <a-tabs v-model:activeKey="state.activeKey"
              @change="handleChangeTab">
        <a-tab-pane :key="feeConfigTypeEnum.packingMaterialDiscount"
                    :tab="$t('warehouse.packing_material_discount')"></a-tab-pane>
        <a-tab-pane :key="feeConfigTypeEnum.operatingCostDiscount"
                    :tab="$t('warehouse.operating_cost_discount')"
                    force-render></a-tab-pane>
        <a-tab-pane :key="feeConfigTypeEnum.storageChargeDiscount"
                    :tab="$t('warehouse.storage_charge_discount')"></a-tab-pane>
      </a-tabs>
      <a-table :columns="state.columns"
               :data-source="state.list"
               :scroll="{ x:true,y: wrap.contentHeight - 230}"
               :pagination="false"
               size="small"
               :loading="state.loading"
               :rowKey="(record, index) => {return index;}"
               class="product-table">
        <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
        <template #packing_material_size="{ record }">
          {{record.packMaterialLength}}x{{record.packMaterialWidth}}x{{record.packMaterialHeight}}cm
        </template>
        <template #packMaterialWeight="{ record }">
          {{gToKg(record.packMaterialWeight)}}kg
        </template>
        <template #packMaterialVolume="{ record }">
          {{cmCubicToM(record.packMaterialVolume)}}m<sup>3</sup>
        </template>

        <template #packBearingQuantity="{ record }">
          {{gToKg(record.packBearingQuantity)}}kg
        </template>
        <template #price="{ record }">
          {{(record.currencySymbol)}}{{roundNumber(record.price)}}
        </template>
        <template #discountAmount="{ record }">
          <a-input-group>
            <span class="ant-input-group-addon">{{record.currencySymbol}}</span>
            <a-input-number class="input-group-mid-number-end"
                            style="min-width:60px"
                            :min=0
                            :max="record.price"
                            :precision="2"
                            @change="handleChangeDiscountAmount(record)"
                            v-model:value="record.discountAmount"></a-input-number>
          </a-input-group>
        </template>

        <template #discount="{ record }">
          <a-input-group>
            <a-input-number class="input-group-mid-number"
                            style="min-width:60px"
                            :min=0
                            :max=1000
                            @change="handleChangeDiscount(record)"
                            :defaultValue="100"
                            v-model:value="record.discountRate"></a-input-number>
            <span class="ant-input-group-addon">%</span>
          </a-input-group>
        </template>
        <template #discount_price="{ record }">
          {{(record.currencySymbol)}}{{handleComputeDiscountedPrice(record)}}
        </template>

        <template v-slot:isActive="{ record }">
          <a-switch v-model:checked="record.isActive"
                    size="small"
                    :loading="record.saving"></a-switch>
        </template>

        <template #feeType="{ record }">
          {{$t($enumLangkey('orderLogisticsFee',record.feeType))}}
        </template>

      </a-table>
    </template>
    <template #contentFooter>
      <div>
        <a-row type="flex"
               justify="center"
               align="top">
          <a-col>
            <a-button class="mr-5"
                      @click="hanldeCancel"
                      :loading="state.addLoading">{{$t("common.cancel")}}</a-button>
          </a-col>
          <a-col>
            <a-button type="primary"
                      :loading="state.addLoading"
                      @click="handleSave">{{$t("common.save")}}</a-button>
          </a-col>
        </a-row>
      </div>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { reactive, onMounted, } from "vue";
import {
  Row, Col, message, Select, Tabs,
  Table, Button, Input, Popover, InputNumber, Switch
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import { orderLogisticsFee as orderLogisticsFeeEnum } from "../../../enum/enum.json";
import { useRoute, useRouter } from 'vue-router';
import { gToKg, cmCubicToM, roundNumber } from "../../../utils/general";
import { getWarehouses, getDiscountInfo, getShopInfo, getDiscountList, add, update } from "../../../api/modules/customer/companyDiscount/index";
import { useTab } from "../../../hooks/tabs/index";

export default ({
  name: "customer_company_discount_details",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASelectOption: Select.Option,
    APopover: Popover,
    Content,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ASwitch: Switch
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const feeConfigTypeEnum = {
      operatingCostDiscount: 1,
      storageChargeDiscount: 2,
      packingMaterialDiscount: 3,
    }

    const packMaterialColumns = [
      {
        width: 40,
        title: () => vueI18n.t("common.serial_number"),
        slots: {
          customRender: "tableIndex"
        }
      },
      {
        title: () => vueI18n.t('warehouse.fee_code'),
        dataIndex: 'feeCode',
        width: 70,
      },
      {
        title: () => vueI18n.t('warehouse.packaging_material_name'),
        dataIndex: 'packMaterialName',
        width: 120,
      },
      {
        title: () => vueI18n.t('warehouse.material_type'),
        dataIndex: 'materialTypeName',
        width: 100,
      },
      {
        title: () => vueI18n.t('warehouse.packing_material_size'),
        width: 100,
        slots: {
          customRender: "packing_material_size"
        },
      },
      {
        title: () => vueI18n.t('logistics.weight_packing_material'),
        width: 80,
        slots: {
          customRender: "packMaterialWeight"
        },
      },
      {
        title: () => vueI18n.t('warehouse.packing_capacity'),
        width: 80,
        slots: {
          customRender: "packMaterialVolume"
        },
      },
      {
        title: () => vueI18n.t('warehouse.packing_load_bearing'),
        width: 80,
        slots: {
          customRender: "packBearingQuantity"
        },
      },
      {
        title: () => vueI18n.t('warehouse.packing_load_bearing'),
        width: 80,
        slots: {
          customRender: "packMaterialVolume"
        },
      },
      {
        title: () => vueI18n.t('warehouse.unit'),
        dataIndex: 'feeUnitName',
        width: 80,
      },
      {
        title: () => vueI18n.t('warehouse.price'),
        width: 80,
        slots: {
          customRender: "price"
        },
      },
      {
        title: () => "折扣金额",
        width: 110,
        slots: {
          customRender: "discountAmount"
        },
      },
      {
        title: () => vueI18n.t('warehouse.discount'),
        width: 110,
        slots: {
          customRender: "discount"
        },
      },
      {
        title: () => vueI18n.t('warehouse.discount_price'),
        width: 80,
        slots: {
          customRender: "discount_price"
        },
      },
      {
        title: () => vueI18n.t('finance.status'),
        width: 60,
        slots: {
          customRender: "isActive"
        },
      },
    ];

    const operatingFeeColumns = [
      {
        width: 50,
        title: () => vueI18n.t("common.serial_number"),
        slots: {
          customRender: "tableIndex"
        }
      },
      {
        title: () => vueI18n.t('warehouse.fee_code'),
        dataIndex: 'feeCode',
        width: 80,
      },
      {
        title: () => vueI18n.t('费用类型'),
        slots: {
          customRender: "feeType"
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('费用描述'),
        dataIndex: "feeDescription",
        width: 100,
      },
      {
        title: () => vueI18n.t('适用服务类型'),
        dataIndex: "serviceTypeNames",
        width: 150,
      },
      {
        title: () => vueI18n.t('收费单位'),
        dataIndex: "feeUnitName",
        width: 100,
      },
      {
        title: () => vueI18n.t('价格'),
        slots: {
          customRender: "price"
        },
        width: 100,
      },
       {
        title: () => "折扣金额",
        width: 110,
        slots: {
          customRender: "discountAmount"
        },
      },
      {
        title: () => vueI18n.t('warehouse.discount'),
        width: 110,
        slots: {
          customRender: "discount"
        },
      },
      {
        title: () => vueI18n.t('warehouse.discount_price'),
        width: 80,
        slots: {
          customRender: "discount_price"
        },
      },
      {
        title: () => vueI18n.t('finance.status'),
        width: 60,
        slots: {
          customRender: "isActive"
        },
      },

    ];

    const storageFeeColumns = [
      {
        width: 50,
        title: () => vueI18n.t("common.serial_number"),
        slots: {
          customRender: "tableIndex"
        }
      },
      {
        title: () => vueI18n.t('warehouse.fee_code'),
        dataIndex: 'feeCode',
        width: 80,
      },
      {
        title: () => vueI18n.t('费用类型'),
        slots: {
          customRender: "feeType"
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('费用描述'),
        dataIndex: "feeDescription",
        width: 100,
      },
      {
        title: () => vueI18n.t('适用服务类型'),
        dataIndex: "serviceTypeNames",
        width: 150,
      },
      {
        title: () => vueI18n.t('收费单位'),
        dataIndex: "feeUnitName",
        width: 100,
      },
      {
        title: () => vueI18n.t('价格'),
        slots: {
          customRender: "price"
        },
        width: 100,
      },
       {
        title: () => "折扣金额",
        width: 110,
        slots: {
          customRender: "discountAmount"
        },
      },
      {
        title: () => vueI18n.t('warehouse.discount'),
        width: 120,
        slots: {
          customRender: "discount"
        },
      },
      {
        title: () => vueI18n.t('warehouse.discount_price'),
        width: 80,
        slots: {
          customRender: "discount_price"
        },
      },
      {
        title: () => vueI18n.t('finance.status'),
        width: 60,
        slots: {
          customRender: "isActive"
        },
      },
    ];

    const state = reactive({
      shopId: null,
      shopName: null,
      dataId: null,
      warehouses: [],
      warehouseId: null,
      warehousesLoading: false,
      loading: false,
      addLoading: false,
      activeKey: feeConfigTypeEnum.packingMaterialDiscount,

      list: [],
      columns: [],

      packMaterialConfigWithDiscounts: [],
      oldPackMaterialConfigWithDiscounts: [],
      operatingFeeConfigWithDiscounts: [],
      oldOperatingFeeConfigWithDiscounts: [],
      storageFeeConfigWithDiscounts: [],
      oldStorageFeeConfigWithDiscounts: [],
    });

    //获取仓库
    const funcGetWarehouses = () => {
      state.warehousesLoading = true;
      getWarehouses(state.shopId, state.warehouseId ? [state.warehouseId] : []).then(({ result }) => {
        state.warehouses = result ?? [];
        state.warehousesLoading = false;
      }).catch(() => {
        state.warehousesLoading = false;
      })
    }

    //编辑的时候 获得name 和 warehousesId
    const funcGetDiscountInfo = () => {
      getDiscountInfo(state.dataId).then(({ result }) => {
        state.shopName = result.shopName ?? null;
        state.name = result.name ?? null;
        state.warehouseId = result.warehouseId ?? null;
        if (state.warehouseId) {
          funcGetTable();
          funcGetWarehouses();
        }
      })
    }

    //获取表格的值
    const funcGetTable = () => {
      if (state.packMaterialConfigWithDiscounts && state.packMaterialConfigWithDiscounts.length > 0) {
        state.oldPackMaterialConfigWithDiscounts = state.packMaterialConfigWithDiscounts;
        state.oldOperatingFeeConfigWithDiscounts = state.operatingFeeConfigWithDiscounts;
        state.oldStorageFeeConfigWithDiscounts = state.storageFeeConfigWithDiscounts;
      }

      let params = {
        "shopId": state.shopId,
        "warehouseId": state.warehouseId,
      };
      state.loading = true;
      getDiscountList(params).then(({ result }) => {
        _setDefaultDiscountRate100(result);
        handleChangeTab(state.activeKey);
        state.loading = false;
      }).catch((error) => {
        state.loading = false;
      })
    }

    //设置表格的折扣 默认值为100或改变仓库前填的折扣值
    const _setDefaultDiscountRate100 = (result) => {
      state.packMaterialConfigWithDiscounts = (result.packMaterialConfigWithDiscounts ?? []);
      state.operatingFeeConfigWithDiscounts = (result.operatingFeeConfigWithDiscounts ?? []);
      state.storageFeeConfigWithDiscounts = (result.storageFeeConfigWithDiscounts ?? []);
      if (state.oldPackMaterialConfigWithDiscounts && state.oldPackMaterialConfigWithDiscounts.length > 0) {
        state.packMaterialConfigWithDiscounts?.forEach(x => {
          x.discountRate = state.oldPackMaterialConfigWithDiscounts.find(o => x.id == o.id).discountRate;
        });

        state.operatingFeeConfigWithDiscounts?.forEach(x => {
          x.discountRate = state.oldOperatingFeeConfigWithDiscounts.find(o => x.id == o.id).discountRate;
        });

        state.storageFeeConfigWithDiscounts?.forEach(x => {
          x.discountRate = state.oldStorageFeeConfigWithDiscounts.find(o => x.id == o.id).discountRate;
        });

      } else {
        state.packMaterialConfigWithDiscounts?.forEach(x => {
          x.discountRate = x.discountRate ?? 100;
        });

        state.operatingFeeConfigWithDiscounts?.forEach(x => {
          x.discountRate = x.discountRate ?? 100;
        });

        state.storageFeeConfigWithDiscounts?.forEach(x => {
          x.discountRate = x.discountRate ?? 100;
        });
      }
    }

    const handleChangeTab = (activeKey) => {
      switch (activeKey) {
        case feeConfigTypeEnum.packingMaterialDiscount:
          state.list = state.packMaterialConfigWithDiscounts;
          state.columns = packMaterialColumns;
          break;
        case feeConfigTypeEnum.operatingCostDiscount:
          state.list = state.operatingFeeConfigWithDiscounts;
          state.columns = operatingFeeColumns;
          break;
        case feeConfigTypeEnum.storageChargeDiscount:
          state.list = state.storageFeeConfigWithDiscounts;
          state.columns = storageFeeColumns;
          break;
      }
    }

    //新增的时候 只获得name
    const funcGetShopInfo = () => {
      getShopInfo(state.shopId)
        .then((res) => {
          state.shopName = res.result.shopName ?? '';
        })
    }

    //-----------------start 保存和更新 start------------------------------

    const handleSave = () => {
      if (!state.name) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.name")]))
        return
      }

      if (!state.warehouseId) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.warehouse")]))
        return
      }
      let temp1 = state.packMaterialConfigWithDiscounts.map(x => {
        return {
          discountAmount: x.discountAmount,
          discountRate: x.discountRate,
          feeConfigId: x.id,
          feeConfigType: feeConfigTypeEnum.packingMaterialDiscount,
          isActive: x.isActive
        }
      })

      let temp2 = state.operatingFeeConfigWithDiscounts.map(x => {
        return {
          discountAmount: x.discountAmount,
          discountRate: x.discountRate,
          feeConfigId: x.id,
          feeConfigType: feeConfigTypeEnum.operatingCostDiscount,
          isActive: x.isActive
        }
      })

      let temp3 = state.storageFeeConfigWithDiscounts.map(x => {
        return {
          discountAmount: x.discountAmount,
          discountRate: x.discountRate,
          feeConfigId: x.id,
          feeConfigType: feeConfigTypeEnum.storageChargeDiscount,
          isActive: x.isActive
        }
      })

      let tableList = [...temp1, ...temp2, ...temp3];
      if (!state.dataId) {
        _funcAddSave(tableList);
      } else {
        _funcEditSave(tableList);
      }
    }

    const _funcAddSave = (tableList) => {
      let pra = {
        name: state.name,
        shopId: state.shopId,
        warehouseId: state.warehouseId,
        warehouseShopDiscountFeeConfigRls: tableList
      };

      state.addLoading = true;
      add(pra).then(() => {
        message.success(vueI18n.t("common.succeed"));
        _funcToDiscountPage();
        state.addLoading = false;
      }).catch(() => {
        state.addLoading = false;
      })
    }

    const _funcEditSave = (tableList) => {
      let pra = {
        id: state.dataId,
        name: state.name,
        shopId: state.shopId,
        warehouseId: state.warehouseId,
        warehouseShopDiscountFeeConfigRls: tableList
      };

      state.addLoading = true;
      update(pra).then(() => {
        message.success(vueI18n.t("common.succeed"));
        _funcToDiscountPage();
        state.addLoading = false;
      }).catch(() => {
        state.addLoading = false;
      })
    }
    //-----------------end 保存和更新 end------------------------------

    const handleChangeDiscount = (record) => {
      if (!record.discountRate) {
        record.discountRate = 0;
      }
    }

     const handleChangeDiscountAmount = (record) => {
      if (!record.discountAmount) {
        record.discountAmount = 0.00;
      }
    }

    

    onMounted(async () => {
      state.shopId = route.params.shopId;
      state.dataId = route.params?.dataId;
      if (state.shopId) {
        if (!state.dataId) {
          //add
          funcGetShopInfo();
          funcGetWarehouses();
        } else {
          //edit
          funcGetDiscountInfo();
        }
        handleChangeTab(feeConfigTypeEnum.packingMaterialDiscount);
      }
    })

    const hanldeCancel = () => {
      _funcToDiscountPage();
    }

    const _funcToDiscountPage = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({
        name: "customer_company_discount_list", params: { id: state.shopId }
      });
    }

    const handleComputeDiscountedPrice = (record) => {
      return roundNumber((record.price-record.discountAmount) * (record.discountRate / 100))
    }

    return {
      state,
      gToKg,
      cmCubicToM,
      funcGetWarehouses,
      handleChangeTab,
      funcGetTable,
      handleSave,
      hanldeCancel,
      handleChangeDiscount,
      handleChangeDiscountAmount,
      feeConfigTypeEnum,
      orderLogisticsFeeEnum,
      handleComputeDiscountedPrice,
      roundNumber,
    };
  }
})
</script>

<style lang="less" scoped>
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
:deep(.input-group-mid-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
:deep(.input-group-mid-number-end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
</style>
<template>
  <a-modal
    v-model:visible="state.visible"
    :title="state.formState.id ? $t('common.edit') : $t('common.add')"
    :mask-closable="false"
    :centered="true"
    :confirm-loading="state.confirmLoading"
    @ok="handleConfirm"
    @cancel="close"
  >
    <a-spin :spinning="state.loading">
      <a-form
        ref="formRef"
        :model="state.formState"
        :rules="rules"
        :label-col="{ style: { width: '150px' } }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-item :label="$t('devops.plan_name')" name="name">
          <a-input v-model:value="state.formState.name"></a-input>
        </a-form-item>
        <a-form-item :label="$t('devops.scheme_type')" name="type">
          <a-radio-group v-model:value="state.formState.type" button-style="solid">
            <a-radio-button
              v-for="(item, index) in logisticsRebateTypeEnum"
              :key="index"
              :value="item"
            >{{ $t($enumLangkey('logisticsRebateType', item)) }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="$t('common.volume')" name="volume" v-if="state.formState.type === logisticsRebateTypeEnum.volume">
          <a-input-group>
            <a-input-number
              class="input-group-mid-number"
              v-model:value="state.formState.volume"
              :min="0.000001"
              :precision="6"
            />
            <span class="ant-input-group-addon">m<sup>3</sup></span>
          </a-input-group>
        </a-form-item>
        <a-form-item :label="$t('common.weight')" name="weight" v-else-if="state.formState.type === logisticsRebateTypeEnum.weight">
          <a-input-group>
            <a-input-number
              class="input-group-mid-number"
              v-model:value="state.formState.weight"
              :min="0.001"
              :precision="3"
            />
            <span class="ant-input-group-addon">kg</span>
          </a-input-group>
        </a-form-item>
        <a-form-item :label="$t('devops.weeks_count')" name="week">
          <a-input-number
            style="width: 100%;"
            v-model:value="state.formState.week"
            :min="1"
            :precision="0"
          />
        </a-form-item>
        <a-form-item :label="$t('devops.discount_ratio')" name="ratio">
          <a-input-group>
            <a-input-number
              class="input-group-mid-number"
              v-model:value="state.formState.ratio"
              :min="0"
              :max="100"
              :precision="2"
            />
            <span class="ant-input-group-addon">%</span>
          </a-input-group>
        </a-form-item>
        <a-form-item :label="$t('logistics.logistics_channel')" name="logisticsIds">
          <a-select
            v-model:value="state.formState.logisticsIds"
            mode="multiple"
            optionFilterProp="searchKey"
            allow-clear
          >
            <a-select-option
              v-for="item in state.logisticsList"
              :key="item.id"
              :value="item.id"
              :searchKey="item.name + item.code"
              :title="item.name + '(' + item.code + ')'"
            >{{ item.name }} ({{ item.code }})</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('devops.running_state')" name="isActive">
          <a-switch :checked-children="$t('common.enabled')" :un-checked-children="$t('common.disabled')" v-model:checked="state.formState.isActive" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from "vue";
import { Button, Form, Input, InputNumber, message, Modal, Radio, Select, Spin, Switch } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { cmCubicToM, mCubicToCm, gToKg, kgToG, setFormStateValue } from "@/utils/general.js";
import { getLogisticsValidBasicData } from "@/api/modules/logistics/index.js";
import {
  getLogisticsRebateConfigDetailById,
  createLogisticsRebateConfig,
  updateLogisticsRebateConfig
} from "@/api/modules/devops/logisticsRebateConfiguration.js";
import { logisticsRebateType as logisticsRebateTypeEnum } from "@/enum/enum.json";

export default defineComponent({
  emits: ["update:visible", "refresh"],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputNumber: InputNumber,
    AInputGroup: Input.Group,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASwitch: Switch,
    AButton: Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
      default: null,
    },
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      logisticsLoading: false,
      logisticsList: [],
      formState: {
        id: null,
        name: null,
        type: logisticsRebateTypeEnum.volume,
        volume: null,
        weight: null,
        week: null,
        ratio: null,
        logisticsIds: [],
        isActive: true,
      },
    })

    const rules = {
      name: [
        {
          required: true,
          message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("devops.plan_name")]),
        },
      ],
      volume: [
        {
          required: true,
          message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("common.volume")]),
        },
      ],
      weight: [
        {
          required: true,
          message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("common.weight")]),
        },
      ],
      week: [
        {
          required: true,
          message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("devops.weeks_count")]),
        },
      ],
      ratio: [
        {
          required: true,
          message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("devops.discount_ratio")]),
        },
      ],
      logisticsIds: [
        {
          required: true,
          message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.logistics_channel")]),
        },
      ],
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        const data = Object.assign({}, state.formState);
        state.confirmLoading = true;
        if (data.type === logisticsRebateTypeEnum.volume && data.volume) {
          data.volume = mCubicToCm(data.volume);
          data.weight = null;
        } else if (data.type === logisticsRebateTypeEnum.weight && data.weight) {
          data.volume = null;
          data.weight = kgToG(data.weight);
        }
        if (data.id) {
          await updateLogisticsRebateConfig(data);
        } else {
          delete data.id;
          await createLogisticsRebateConfig(data);
        }
        message.success(vueI18n.t("common.succeed"));
        emit("refresh");
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const getDetail = () => {
      state.loading = true;
      getLogisticsRebateConfigDetailById({ id: props.id }).then(({ result }) => {
        if (result) {
          if (result.volume) {
            result.volume = cmCubicToM(result.volume);
          }
          if (result.weight) {
            result.weight = gToKg(result.weight);
          }
          if (Array.isArray(result.items)) {
            result.logisticsIds = result.items.map(item => item.logisticsId);
          } else {
            result.logisticsIds = [];
          }
          setFormStateValue(state.formState, result);
        }
      }).catch((error) => {
      }).finally(() => {
        state.loading = false;
      });
    }

    const getLogisticsList = () => {
      state.logisticsLoading = true;
      getLogisticsValidBasicData({ isActived: true }).then(({ result }) => {
        if (Array.isArray(result)) {
          state.logisticsList = result;
        } else {
          state.logisticsList = [];
        }
      }).catch(() => {
      }).finally(() => {
        state.logisticsLoading = false;
      });
    }

    const close = () => {
      emit("update:visible", false);
    }

    watch(() => [props.visible, props.id], ([visible, id]) => {
      if (typeof visible === "boolean") {
        state.visible = visible;
        if (visible) {
          getLogisticsList();
          nextTick(() => {
            formRef.value.resetFields();
            if (id) {
              state.formState.id = id;
              getDetail();
            } else {
              state.formState.id = null;
            }
          });
        }
      }
    })

    return {
      logisticsRebateTypeEnum,
      formRef,
      state,
      rules,
      handleConfirm,
      close,
    }
  }
})
</script>

<style lang="less" scoped>
:deep(.input-group-mid-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}

:deep(.input-group-mid-number-end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
</style>
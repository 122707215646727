<template>
  <Content>
    <template #contentTitle>{{ $t('menu.logistics_validation') }}</template>
    <template #contentBody>
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane :key="tabsObj.simple" :tab="$t('warehouse.existing_order')">
          <a-form
            ref="simpleFormRef"
            :model="simpleFormState"
            :rules="simpleRules"
            :label-col="{ style: { width: '180px' } }"
          >
            <a-form-item :label="$t('warehouse.order_type')" name="planType">
              <a-select v-model:value="simpleFormState.planType" style="width: 250px;">
                <a-select-option
                  v-for="(item, index) in channelIsSupportForPlanTypeEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('channelIsSupportForPlanType', item))"
                >
                  {{ $t($enumLangkey('channelIsSupportForPlanType', item)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('warehouse.plan_no')" name="planNo">
              <a-input v-model:value="simpleFormState.planNo" style="width: 250px;"></a-input>
            </a-form-item>
            <a-form-item :label="$t('common.signature')">
              <a-select v-model:value="simpleFormState.signatureType" style="width: 250px;" allowClear>
                <a-select-option
                  v-for="(item, index) in signatureTypeEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('signatureType', item))"
                >
                  {{ $t($enumLangkey("signatureType", item)) }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('logistics.logistics_channel')" name="channelId">
              <a-select v-model:value="simpleFormState.channelId" style="width: 250px;"
                show-search
                optionFilterProp="search-key"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in channelList"
                  :key="index"
                  :value="item.id"
                  :title="item.name + '(' + item.code + ')'"
                  :search-key="item.name + item.code + item.countryCnName + item.countryEnName + item.ios2"
                  :label="item.name + '(' + item.code + ')'"
                >
                  <a-row justify="space-between">
                    <a-col>{{ item.name }}</a-col>
                  </a-row>
                  <a-row justify="space-between">
                    <a-col>{{ item.code }}</a-col>
                    <a-col>{{ getLanguageName(item.countryCnName, item.countryEnName) }}({{ item.ios2 }})</a-col>
                  </a-row>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label=" " :colon="false">
              <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('warehouse.start_verification') }}</a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane :key="tabsObj.custom" :tab="$t('warehouse.custom_validation')">
          <a-form
            ref="customFormRef"
            :model="customFormState"
            :rules="customRules"
            :label-col="{ style: { width: '180px' } }"
          >
            <a-row type="flex" :wrap="true" :gutter="16">
              <!-- <a-col flex="1 0 700px">
                <a-form-item :label="$t('warehouse.logistics_center')" name="logisticCenterId">
                  <a-select v-model:value="customFormState.logisticCenterId" style="width: 250px;">
                    <a-select-option
                      v-for="(item, index) in warehouseCenterList"
                      :key="index"
                      :value="item.id"
                      :title="item.warehouseNo + '(' + item.warehouseName + ')'"
                    >{{ item.warehouseNo }}({{ item.warehouseName }})</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <a-col flex="1 0 700px">
                <a-form-item :label="$t('logistics.ordinary_trade_clearance')" name="isDeclareSelected">
                  <a-switch
                    :checked-children="$t('common.yes')"
                    :un-checked-children="$t('common.no')"
                    v-model:checked="customFormState.isDeclareSelected"
                  />
                </a-form-item>
              </a-col>
              <a-col flex="1 0 700px">
                <a-form-item :label="$t('warehouse.other_weights')" name="additionalWeight">
                  <a-input-number v-model:value="customFormState.additionalWeight" :min="0" style="width: 250px;"></a-input-number> kg
                </a-form-item>
              </a-col>
              <a-col flex="1 0 700px">
                <a-form-item :label="$t('common.signature')">
                  <a-select v-model:value="customFormState.signatureType" style="width: 250px;" allowClear>
                    <a-select-option
                      v-for="(item, index) in signatureTypeEnum"
                      :key="index"
                      :value="item"
                      :title="$t($enumLangkey('signatureType', item))"
                    >
                      {{ $t($enumLangkey("signatureType", item)) }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col flex="1 0 700px">
                <a-form-item :label="$t('logistics.transport_characteristics')" name="transportCharacteristics">
                  <a-select v-model:value="customFormState.transportCharacteristics" style="width: 250px;" mode="multiple" :maxTagCount="1">
                    <a-select-option
                      v-for="(item, index) in transportCharacteristicsEnum"
                      :key="index"
                      :value="item"
                      :title="$t($enumLangkey('transportCharacteristics', item))"
                    >
                      {{ $t($enumLangkey("transportCharacteristics", item)) }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :wrap="true" :gutter="[16, 16]">
              <!-- 发货地址 -->
              <a-col flex="1 0 700px">
                <a-typography-text strong>{{ $t('logistics.shipments_address') }}</a-typography-text>
                <a-divider style="margin: 0;" class="mt-2 mb-3"></a-divider>
                <a-form-item :label="$t('logistics.shipments_address')" :name="['fromAddress', 'detail']">
                  <AddressSelectControl
                    :address-cache="addressCache"
                    v-model:countryId="customFormState.fromAddress.countryId"
                    v-model:provinceId="customFormState.fromAddress.provinceId"
                    v-model:cityId="customFormState.fromAddress.cityId"
                    v-model:countyId="customFormState.fromAddress.countyId"
                  />
                </a-form-item>
                <a-form-item :label="$t('common.address_line')" :name="['fromAddress', 'address']">
                  <a-input v-model:value="customFormState.fromAddress.address" v-address-filter style="width: 250px;"></a-input>
                </a-form-item>
                <a-form-item :label="$t('common.address_line') + 2">
                  <a-input v-model:value="customFormState.fromAddress.address2" v-address-filter style="width: 250px;"></a-input>
                </a-form-item>
                <a-form-item :label="$t('common.post_code')" :name="['fromAddress', 'postCode']">
                  <a-space>
                    <a-input v-model:value="customFormState.fromAddress.postCode" style="width: 250px;"></a-input>
                    <a-button @click="handlePostCodeQuery(customFormState.fromAddress.postCode, 'fromAddress')">{{ $t('logistics.check_the_postcode') }}</a-button>
                  </a-space>
                </a-form-item>
              </a-col>
              <!-- 收货地址 -->
              <a-col flex="1 0 700px">
                <a-typography-text strong>{{ $t('logistics.receiving_address') }}</a-typography-text>
                <a-divider style="margin: 0;" class="mt-2 mb-3"></a-divider>
                <a-form-item :label="$t('logistics.receiving_address')" :name="['toAddress', 'detail']">
                  <AddressSelectControl
                    :address-cache="addressCache"
                    v-model:countryId="customFormState.toAddress.countryId"
                    v-model:provinceId="customFormState.toAddress.provinceId"
                    v-model:cityId="customFormState.toAddress.cityId"
                    v-model:countyId="customFormState.toAddress.countyId"
                  />
                </a-form-item>
                <a-form-item :label="$t('common.address_line')" :name="['toAddress', 'address']">
                  <a-input v-model:value="customFormState.toAddress.address" v-address-filter style="width: 250px;"></a-input>
                </a-form-item>
                <a-form-item :label="$t('common.address_line') + 2">
                  <a-input v-model:value="customFormState.toAddress.address2" v-address-filter style="width: 250px;"></a-input>
                </a-form-item>
                <a-form-item :label="$t('common.post_code')" :name="['toAddress', 'postCode']">
                  <a-space>
                    <a-input v-model:value="customFormState.toAddress.postCode" style="width: 250px;"></a-input>
                    <a-button @click="handlePostCodeQuery(customFormState.toAddress.postCode, 'toAddress')">{{ $t('logistics.check_the_postcode') }}</a-button>
                  </a-space>
                </a-form-item>
                <a-form-item :label="$t('warehouse.address_type')">
                  <a-select v-model:value="customFormState.toAddress.platformAddrType" style="width: 250px;" @change="handleGetPlatformAddrCodeList">
                    <a-select-option :value="0" :title="$t($enumLangkey('platformAddrType', 0))">
                      {{ $t($enumLangkey('platformAddrType', 0)) }}
                    </a-select-option>
                    <a-select-option
                      v-for="(item, index) in platformAddrTypeEnum"
                      :key="index"
                      :value="item"
                      :title="$t($enumLangkey('platformAddrType', item))"
                    >
                      {{ $t($enumLangkey('platformAddrType', item)) }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item v-if="needPlatformAddrCode" :name="['toAddress', 'platformAddrCode']">
                  <template #label>
                    <span class="text-error mr-1">*</span>
                    <span>{{ $t('warehouse.address_code') }}</span>
                  </template>
                  <a-auto-complete
                    v-if="customFormState.toAddress.platformAddrType === platformAddrTypeEnum.FBA || customFormState.toAddress.platformAddrType === platformAddrTypeEnum.meikeduo"
                    v-model:value="customFormState.toAddress.platformAddrCode"
                    style="width: 250px;"
                    :options="platformAddrCodeList.map(x => ({ value: x.name }))"
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                    @change="handlePlatformAddrCodeChange('name')"
                  >
                  </a-auto-complete>
                  <a-select
                    v-else-if="customFormState.toAddress.platformAddrType === platformAddrTypeEnum.SE"
                    v-model:value="customFormState.toAddress.platformAddrCode"
                    style="width: 250px;"
                    option-filter-prop="search-key"
                    show-search
                    @change="handlePlatformAddrCodeChange('warehouseNo')"
                  >
                    <a-select-option
                      v-for="(item, index) in platformAddrCodeList"
                      :key="index"
                      :value="item.warehouseNo"
                      :title="item.warehouseName + '(' + item.warehouseNo + ')'"
                      :search-key="item.warehouseName + item.warehouseNo"
                    >{{ item.warehouseName }}({{ item.warehouseNo }})</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <!-- 添加产品 -->
            <a-typography-text strong>{{ $t('warehouse.add_product') }}</a-typography-text>
            <a-divider style="margin: 0;" class="mt-2 mb-3"></a-divider>
            <ProductTableContent v-model:items="customFormState.items" />

            <a-form-item class="mt-3" :label="$t('logistics.logistics_channel')" name="channelId">
              <a-select v-model:value="customFormState.channelId" style="width: 250px;"
                show-search
                optionFilterProp="search-key"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in channelList"
                  :key="index"
                  :value="item.id"
                  :title="item.name + '(' + item.code + ')'"
                  :search-key="item.name + item.code + item.countryCnName + item.countryEnName + item.ios2"
                  :label="item.name + '(' + item.code + ')'"
                >
                  <a-row justify="space-between">
                    <a-col>{{ item.name }}</a-col>
                  </a-row>
                  <a-row justify="space-between">
                    <a-col>{{ item.code }}</a-col>
                    <a-col>{{ getLanguageName(item.countryCnName, item.countryEnName) }}({{ item.ios2 }})</a-col>
                  </a-row>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label=" " :colon="false">
              <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('warehouse.start_verification') }}</a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
      <template v-if="hasValidateRes">
        <a-typography-text strong>{{ $t('warehouse.verification_results') }}</a-typography-text>
        <a-divider style="margin: 0;" class="mt-2 mb-3"></a-divider>
        <a-card>
          <template v-if="validateResult[activeKey].isSuccess">
            <a-typography-title type="success" :level="3">{{ $t('common.succeed') }}</a-typography-title>
            <LogisticsChannelTable :info="validateResult[activeKey].channel" />
          </template>
          <template v-else-if="validateResult[activeKey].isSuccess === false">
            <a-typography-title type="danger" :level="3">{{ $t('common.failed') }}</a-typography-title>
            <a-typography-text
              type="danger"
              v-for="(item, index) in validateResult[activeKey].errors"
              :key="index"
            >【{{ item.code }}】{{ item.message }}</a-typography-text>
          </template>
        </a-card>
      </template>
      <CPostQuery ref="postCodeQueryRef" v-model:visible="postCodeQueryData.visible" :postCode="postCodeQueryData.postCode" @onSelected="handleOnPostQuerySelected" />
    </template>
  </Content>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { Button, Card, Checkbox, Col, Form, Row, Select, Tabs, Input, InputNumber, Divider, Typography, Space, Switch, message, AutoComplete } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPostQuery from '@/views/components/CPostQuery.vue';
import ProductTableContent from './components/ProductTableContent.vue';
import AddressSelectControl from './components/AddressSelectControl.vue';
import LogisticsChannelTable from './components/LogisticsChannelTable.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from 'vuex';
import { getWarehouses } from "@/api/modules/common/index.js";
import { getPlatformAddressList, getLogisticsValidBasicData } from '@/api/modules/logistics/index.js';
import { getAllWarehouses, validationChannelIsSupportPlan, validationChannelIsSupportOther } from '@/api/modules/warehouse/index.js';
import { kgToG, gToKg, getName } from "@/utils/general.js";
import {
  channelIsSupportForPlanType as channelIsSupportForPlanTypeEnum,
  destinationType as destinationTypeEnum,
  warehouseType as warehouseTypeEnum,
  platformAddrType as platformAddrTypeEnum,
  signatureType as signatureTypeEnum,
  transportCharacteristics as transportCharacteristicsEnum,
} from '@/enum/enum.json';

export default defineComponent({
  name: "logistics_validation",
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputGroup: Input.Group,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ADivider: Divider,
    ACard: Card,
    ASpace: Space,
    ATypographyText: Typography.Text,
    ATypographyTitle: Typography.Title,
    ASwitch: Switch,
    ACheckboxGroup: Checkbox.Group,
    AAutoComplete: AutoComplete,
    Content,
    CPostQuery,
    ProductTableContent,
    AddressSelectControl,
    LogisticsChannelTable,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const simpleFormRef = ref(null);
    const customFormRef = ref(null);
    const postCodeQueryRef = ref(null);

    const tabsObj = {
      // 现有订单
      simple: 1,
      // 自定义验证
      custom: 2,
    }

    const state = reactive({
      activeKey: tabsObj.simple,
      simpleFormState: {
        channelId: null,
        planType: null,
        planNo: null,
        signatureType: null,
      },
      customFormState: {
        channelId: null,
        // logisticCenterId: null,
        isDeclareSelected: false,
        additionalWeight: 0,
        signatureType: null,
        transportCharacteristics: [],
        fromAddress: {
          countryId: null,
          provinceId: null,
          cityId: null,
          countyId: null,
          address: null,
          address2: null,
          postCode: null,
        },
        toAddress: {
          countryId: null,
          provinceId: null,
          cityId: null,
          countyId: null,
          address: null,
          address2: null,
          postCode: null,
          platformAddrType: 0,
          platformAddrCode: null,
        },
        items: [],
      },
      // 物流渠道
      channelLoading: false,
      channelList: [],
      // 物流中心
      // warehouseCenterLoading: false,
      // warehouseCenterList: [],
      // 国家省市区
      addressCache: {},
      postCodeQueryData: {
        visible: false,
        postCode: null,
        type: null,
      },
      // 地址编号列表
      platformAddrCodeList: [],
      confirmLoading: false,
      // 是否完成验证
      isValidate: [],
      validateResult: {
        [tabsObj.simple]: {
          isSuccess: null,
          channel: null,
          errors: [],
        },
        [tabsObj.custom]: {
          isSuccess: null,
          channel: null,
          errors: [],
        }
      },
    })

    const platformAddrCodeListCache = {}

    const simpleRules = {
      planType: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.order_type')]),
      },
      planNo: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.plan_no')]),
      },
      channelId: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.logistics_channel')]),
      },
    }

    const validateFromAddress = (rule, value) => {
      if (!state.customFormState.fromAddress.countryId) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.country')]));
      }
      if (!state.customFormState.fromAddress.provinceId) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.province')]));
      }
      if (!state.customFormState.fromAddress.cityId) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.city')]));
      }
      return Promise.resolve();
    }

    const validateToAddress = (rule, value) => {
      if (!state.customFormState.toAddress.countryId) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.country')]));
      }
      if (!state.customFormState.toAddress.provinceId) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.province')]));
      }
      if (!state.customFormState.toAddress.cityId) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.city')]));
      }
      return Promise.resolve();
    }

    const validateToAddrCode = (rule, value) => {
      if (needPlatformAddrCode.value) {
        if (!state.customFormState.toAddress.platformAddrCode) {
          return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.address_code')]));
        }
      }
      return Promise.resolve();
    }

    const validatePostCode = (rule, value) => {
      value = value?.trim();
      if (!value) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('common.post_code')]));
      }
      if (value.length < 5) {
        return Promise.reject(vueI18n.t('common.p0_please_enter_at_least_p1_digits', [vueI18n.t('common.post_code'), 5]));
      }
      return Promise.resolve();
    }

    const customRules = {
      channelId: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.logistics_channel')]),
      },
      // logisticCenterId: {
      //   required: true,
      //   message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.logistics_center')]),
      // },
      transportCharacteristics: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.transport_characteristics')]),
      },
      fromAddress: {
        detail: {
          required: true,
          validator: validateFromAddress,
        },
        address: {
          required: true,
          message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('common.address_line')]),
        },
        postCode: {
          required: true,
          validator: validatePostCode,
        },
      },
      toAddress: {
        detail: {
          required: true,
          validator: validateToAddress,
        },
        address: {
          required: true,
          message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('common.address_line')]),
        },
        postCode: {
          required: true,
          validator: validatePostCode,
        },
        platformAddrCode: {
          validator: validateToAddrCode,
        },
      },
    }

    const getLanguageName = (cnName, enName) => {
      return getName({ cnName, enName }, getters.language);
    }

    const handlePostCodeQuery = (postCode, type = 'fromAddress') => {
      state.postCodeQueryData.postCode = postCode;
      state.postCodeQueryData.type = type;
      state.postCodeQueryData.visible = true;
    }

    const handleOnPostQuerySelected = (data) => {
      if (data && state.postCodeQueryData.type) {
        state.customFormState[state.postCodeQueryData.type].countryId = data.countryId ?? null;
        state.customFormState[state.postCodeQueryData.type].provinceId = data.provinceId ?? null;
        state.customFormState[state.postCodeQueryData.type].cityId = data.cityId ?? null;
        state.customFormState[state.postCodeQueryData.type].countyId = data.countyId ?? null;
        state.customFormState[state.postCodeQueryData.type].address = data.address ?? data.addressLine1 ?? null;
        state.customFormState[state.postCodeQueryData.type].address2 = data.address2 ?? data.addressLine2 ?? null;
        state.customFormState[state.postCodeQueryData.type].postCode = data.postCode ?? null;

        customFormRef.value.validate([[state.postCodeQueryData.type, 'detail']]);
        customFormRef.value.validate([[state.postCodeQueryData.type, 'address']]);
        customFormRef.value.validate([[state.postCodeQueryData.type, 'postCode']]);
      }
    }

    const handlePlatformAddrCodeChange = (key) => {
      let code = state.customFormState.toAddress.platformAddrCode;
      if (code) {
        let codeInfo = state.platformAddrCodeList.find(item => item[key] === code);
        if (codeInfo) {
          state.postCodeQueryData.type = "toAddress";
          handleOnPostQuerySelected(codeInfo);
        }
      }
    }

    // 获取地址编码列表
    const handleGetPlatformAddrCodeList = async () => {
      try {
        state.customFormState.toAddress.platformAddrCode = null;
        if (needPlatformAddrCode.value) {
          let platform = state.customFormState.toAddress.platformAddrType;
          if (platform in platformAddrCodeListCache) {
            state.platformAddrCodeList = platformAddrCodeListCache[platform];
          } else {
            let codeList = [];
            if (platform === platformAddrTypeEnum.FBA || platform === platformAddrTypeEnum.meikeduo) {
              let { result } = await getPlatformAddressList({ platform, isActive: true });
              if (Array.isArray(result)) {
                codeList = result;
              }
            } else if (platform === platformAddrTypeEnum.SE) {
              let { result } = await getWarehouses({ isActive: true });
              if (Array.isArray(result)) {
                codeList = result;
              }
            }
            platformAddrCodeListCache[platform] = codeList;
            state.platformAddrCodeList = codeList;
          }
        }
      } catch (error) {
        state.platformAddrCodeList = [];
      } finally {
        
      }
    }

    const validateItems = (items) => {
      let result = true;
      let list = [];
      for (let i = 0; i < items.length; i++) {
        let item = Object.assign({}, items[i]);
        if (!item.length || !item.width || !item.height || !item.weight) {
          result = false;
          return;
        } else {
          let sizeArr = [item.length, item.width, item.height].sort((a, b) => b - a);
          item.length = sizeArr[0];
          item.width = sizeArr[1];
          item.height = sizeArr[2];
          item.weight = kgToG(item.weight);
          list.push(item);
        }
      }
      if (result && list.length > 0) {
        return list;
      } else {
        return false;
      }
    }

    const handleConfirm = async () => {
      try {
        let res = null;
        if (state.activeKey === tabsObj.simple) {
          // 现有订单
          await simpleFormRef.value.validate();
          let condition = Object.assign({}, state.simpleFormState);
          delete condition.channelId
          const simpleData = Object.assign({}, { condition, channelId: state.simpleFormState.channelId });
          state.confirmLoading = true;
          let simpleRes = await validationChannelIsSupportPlan(simpleData);
          res = simpleRes.result;
        } else {
          // 自定义订单
          await customFormRef.value.validate();
          let condition = Object.assign({}, state.customFormState);
          let items = validateItems(condition.items);
          if (Array.isArray(items)) {
            condition.items = items;
          } else {
            message.error(vueI18n.t('warehouse.the_box_information_is_incorrect_please_confirm'));
            return;
          }
          if (!!condition.additionalWeight) {
            condition.additionalWeight = gToKg(condition.additionalWeight);
          } else {
            condition.additionalWeight = 0;
          }
          delete condition.channelId
          const customData = Object.assign({}, { condition, channelId: state.customFormState.channelId });
          state.confirmLoading = true;
          let customRes = await validationChannelIsSupportOther(customData);
          res = customRes.result;
        }
        if (res) {
          state.validateResult[state.activeKey].isSuccess = res.isSuccess;
          state.validateResult[state.activeKey].channel = res.channel;
          state.validateResult[state.activeKey].errors = res.errors;
          state.isValidate = Array.from(new Set([...state.isValidate, state.activeKey]));
        }
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    watch(() => state.customFormState.toAddress.platformAddrType, (value, oldValue) => {
      if (value !== oldValue && state.activeKey === tabsObj.custom) {
        nextTick(() => {
          customFormRef.value.validate([['toAddress', 'platformAddrCode']])
        })
      }
    }, { immediate: true });

    // 获取物流中心
    const getAllWarehousesCenter = () => {
      state.warehouseCenterLoading = true;
      getAllWarehouses({ warehouseType: warehouseTypeEnum.logisticsCenter }).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseCenterList = result;
        } else {
          state.warehouseCenterList = [];
        }
      }).catch(() => {}).finally(() => {
        state.warehouseCenterLoading = false;
      })
    }

    const needPlatformAddrCode = computed(() => {
      return Object.values(platformAddrTypeEnum).includes(state.customFormState.toAddress.platformAddrType);
    })

    const hasValidateRes = computed(() => {
      if (state.isValidate.includes(state.activeKey)) {
        return true;
      }
      return false;
    })

    // 获取所有物流渠道
    const getAllChannel = () => {
      state.channelLoading = true;
      getLogisticsValidBasicData({ isActived: true }).then(({ result }) => {
        if (Array.isArray(result)) {
          let channelList = result.sort((a, b) => {
            if (a.countryCnName === b.countryCnName) {
              return a.name.localeCompare(b.name);
            }
            return a.countryCnName.localeCompare(b.countryCnName)
          });
          state.channelList = channelList;
        } else {
          state.channelList = [];
        }
      }).catch(() => {}).finally(() => {
        state.channelLoading = false;
      });
    }

    onMounted(() => {
      getAllChannel();
      // getAllWarehousesCenter();
    })

    return {
      ...toRefs(state),
      channelIsSupportForPlanTypeEnum,
      destinationTypeEnum,
      platformAddrTypeEnum,
      signatureTypeEnum,
      transportCharacteristicsEnum,
      tabsObj,
      simpleFormRef,
      customFormRef,
      postCodeQueryRef,
      simpleRules,
      customRules,
      getLanguageName,
      handlePostCodeQuery,
      handleOnPostQuerySelected,
      handlePlatformAddrCodeChange,
      handleGetPlatformAddrCodeList,
      handleConfirm,
      needPlatformAddrCode,
      hasValidateRes,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.dev_scan_exception") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div>
        <a-table :columns="columns" size="small" :data-source="tableData.tableList" :pagination="false"
          :scroll="{ x: 500, y: wrap.contentHeight }" :rowKey="
            (record, index) => {
              return index;
            }
          " :loading="tableData.loading">
          <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
          <template v-slot:normal="{ record }">
            <span
              v-if="record.normal.checkContainerLength && record.normal.checkContainerWidth && record.normal.checkContainerHeight">
              {{ record.normal.checkContainerLength }}x{{ record.normal.checkContainerWidth }}x{{
                  record.normal.checkContainerHeight
              }}cm/{{ setGToKg(record.normal.checkContainerWeight) }}kg
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:newFilled="{ record }">
            <span
              v-if="record.newFilled.checkContainerLength && record.newFilled.checkContainerWidth && record.newFilled.checkContainerHeight">
              {{ record.newFilled.checkContainerLength }}x{{ record.newFilled.checkContainerWidth }}x{{
                  record.newFilled.checkContainerHeight
              }}cm/{{ setGToKg(record.newFilled.checkContainerWeight) }}kg
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:dateTime="{ record }">{{ $filters.utcToCurrentTime(record.dateTime) }}</template>
          <template v-slot:fromNowOn="{ record }">
            <span v-if="record.rangeNowDay > 0">{{ record.rangeNowDay }}{{ $t("common.p0_days_ago") }}</span>
            <span v-else-if="record.rangeNowHour > 0">{{ record.rangeNowHour }}{{ $t("common.p0_hours_ago") }}</span>
            <span v-else-if="record.rangeNowMinute > 0">{{ record.rangeNowMinute }}{{ $t("common.p0_minutes_ago") }}</span>
          </template>
        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <c-pager class="text-center" @handlePage="handlePage" :page-data="tableData.pageData" />
    </template>
  </Content>
</template>
<script>
import {
  Table,
  Button,
} from "ant-design-vue";
import CPager from "../components/CPager.vue";
import Content from "../components/Content.vue";
import {
  defineComponent,
  onMounted,
  reactive,
} from "vue";
import { useI18n } from 'vue-i18n/index';
import { getWhBoxHasException } from "@/api/modules/devops/index";
import { gToKg } from "@/utils/general";

export default defineComponent({
  name: "data_scan_exception",
  components: {
    ATable: Table,
    AButton: Button,
    CPager,
    Content
  },
  setup() {
    const vueI18n = useI18n({ useScope: 'global' });
    const tableData = reactive({
      tableList: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const columns = [
      {
        width: 80,
        fixed: "left",
        title: () => vueI18n.t('common.serial_number'),
        slots: {
          customRender: "tableIndex"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('devops.box_no'),
        dataIndex: 'boxNo'
      },
      {
        width: 150,
        title: () => vueI18n.t('devops.generating_facilities'),
        dataIndex: 'warehouseNo'
      },
      {
        width: 150,
        title: () => vueI18n.t('devops.scanning_device_no'),
        dataIndex: 'symbol'
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.general_surveying_data'),
        slots: {
          customRender: "normal"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.last_measurement_data'),
        slots: {
          customRender: "newFilled"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.occurrence_time'),
        slots: {
          customRender: "dateTime"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.time_from_now'),
        slots: {
          customRender: "fromNowOn"
        }
      },
    ]

    const getTaskList = () => {
      tableData.loading = true;
      getWhBoxHasException(tableData.pageData)
        .then(res => {
          tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          tableData.pageData.totalCount = parseInt(totalCount);
          tableData.tableList = items;
        })
        .catch(() => {
          tableData.loading = false;
        })
    };

    const handlePage = (pageData) => {
      tableData.pageData.skipCount = pageData.skipCount;
      tableData.pageData.maxResultCount = pageData.maxResultCount;
      getTaskList();
    };

    const searchLogistics = () => {
      tableData.pageData.skipCount = 0;
      tableData.pageData.currentIndex = 1;
      getTaskList();
    };

    const setGToKg = (num = 0) => {
      return gToKg(num)
    }

    onMounted(searchLogistics)

    return {
      tableData,
      columns,
      handlePage,
      searchLogistics,
      setGToKg,
    };
  },
});
</script>

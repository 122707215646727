export default {
  logistics: {
    logistics_scheme_name: "物流方案名称",
    logistics_scheme_code: "物流方案代码",
    create_template: "创建模板",
    freight_fee_config: "运费配置",
    config: "配置",
    template_name: "模板名称",
    freight_fee_config_cue_words:
      "注意: 区间为左开右闭。如: 20-50kg,不包含20kg,包含50kg",
      volume_freight_fee_config_cue_words:
      "注意: 区间为左开右闭。如: 20-50m³,不包含20m³,包含50m³",
    seller_id:"Seller ID",
    count: "数量",

    first_weight: "首重",
    first_volume: "首体积",
    first_weight_canceled: "取消首重",
    first_volume_canceled: "取消首体积",
    pre_each: "前置",
    fuel_surcharge_canceled: "取消燃油附加费",
    effect_subarea: "适用分区",
    subarea_warning_title:
      "注意: 若删除已存在的适用分区, 将会导致原分区没有附加费模板而变为不可用, 请及时更新原分区的附加费模板",
    transportation_plan_number: "运输计划编号",
    transport_route: "运输路线",
    destination_type: "目的地类型",
    start_country_city: "始发国家/城市",
    destination_country_city: "目的国家/城市",
    logistics_scheme: "物流方案",
    logistics_code: "物流代码",
    supply_channel_scheme: "供应渠道方案",
    accounting_status: "核算状态",
    have_accounting: "已核算",
    not_accounting: "未核算",
    entry_accounting: "进入核算时间",
    created_at: "创建于",
    update_on: "更新于",
    view_details: "查看详细",
    transport_plan_name: "运输计划名称",
    update_time: "更新时间",
    warehouse_code: "仓库代码",
    receiving_address: "收货地址",
    originate: "发自",
    shipments_address: "发货地址",
    logistics_center_harvest_address: "物流中心收货地址",
    contacts: "联系人",
    shipments_contacts: "发货人",
    last_modification_time: "更新时间",
    recipients: "收件人",
    the_sender: "发件人",
    rests: "其他",
    box_no: "箱号",
    picture: "图片",
    transport_info: "运输信息",
    total: "合计",
    transport_characteristics: "运输特征",
    delivery_way: "派送方式",
    logistics_type: "物流类型",
    reference_aging: "参考时效",
    freight_forecast: "运费预估",
    freight_forecast_no_audit: "运费(预估)",
    freight: "运费",
    selected_service: "已选服务",
    cost_accounting: "成本核算",
    international_transport: "国际运输",
    door_took: "上门揽件",
    summary_customs_clearance_information: "清关信息汇总",
    trade_name: "品名",
    apply_middle_rate: "申报平均价",
    subtotal: "小计",
    clearance_way: "报关方式",
    ordinary_trade_clearance: "一般贸易报关",
    charged_weight: "计费重量",
    total_volume_of_transport: "运输总体积",
    gross_shipping_weight: "运输总重量",

    remote_site: "偏远地址",
    business_site: "商业地址",
    residence: "住宅地址",
    chargeable_weight: "计费重",
    chargeable_total_weight: "计费总重量",
    volume_weight_coefficient: "体积重系数: 除",
    workday: "工作日",

    discount: "折扣",
    total_income: "收入总计",
    supply_channels_have_been_allocated: "已分配供应渠道",
    channel_business_no: "渠道业务号",
    cost_of_combined: "成本合计",
    profit: "利润",
    selected_supporting_service: "已选独立打托服务",
    selected_supporting: "已打托",
    complete_cost_accounting: "完成成本核算",
    temporary_additional_fee: "临时派送附加费",
    collect_signature_fee: "签收签名费",
    reload_check: "重新审核",
    logistics_select_hint: "系统根据您的发货地自动匹配最优物流中心",
    amazon_shipping_number: "亚马逊运输编号",
    shipment_tracking_number: "货件追踪编号",
    address_verification: "地址验证",
    print_sheet: "打印面单",
    name_or_keyword: "名称或关键字",
    applicable_type: "适用类型",
    third_party_channels: "第三方渠道",
    third_party: "第三方",
    interaction_mode: "交互方式",
    configuration_data: "配置数据",
    enabled: "已启用",
    disabled: "已禁用",
    none: "无",
    create_logistics_account: "创建物流账号",
    applicable_delivery_method: "适用交付方式",
    data: "数据",
    describe: "描述",
    inner_channels: "内置渠道",
   
    create_built_in_channel: "创建内部渠道",
    edit_built_in_channel: "修改内部渠道",

    number_of_companies: "公司数量",
    level: "等级",
    pack_material: "包装材料",
    
    platform: "平台",
    code: "代码",
    to: "至",
    customer_level: "客户等级",

    received: "已收货",
    await_visit_fetch: "等待揽件",
    visit_fetching: "揽件中",
    console_await_receiving: "操作中心等待收货",
    received_goods: "等待账单确认/已收货",
    waiting_for_payment: "等待支付",
    account_paid: "发货处理中/已付款",
    await_bill_confirm: "等待账单确认",
    send_goods_ing: "发货处理中",
    in_transit: "已发运",
    have_arrived: "已送达目的地",
    canceled: "已取消",
    picked_up: "已揽件",
    processing_status: "处理状态",
    transport_box_count: "运输箱数",
    receiving_point: "揽收网点",
    create_date: "创建日期",
    update_date: "更新日期",
    please_input_plan_name_or_no: "请输入计划名称或编号",


    consignment_out_task: "配送出库任务",
    source_of_sales: "销售来源",
    consignment_order_no: "配送订单号",
    relation_order_no: "关联订单号",
    consignment_order: "配送订单",
    transport_scheme: "运输方案",
    logistics_track_no: "跟踪号",
    logistics_tracking_number: "物流跟踪号",
    destination_address: "目的地址",
    placeholder_order_recipients: "运单号/关联订单号/收件人",
    create_waves_task: "生成波次任务",

    shipments_warehouse: "发货仓",
    order_info: "订单信息",
    business_packaging_size: "商品包装尺寸",
    amount: "数量",
    max_capacity: "最大容量",
    weight_packing_material: "包装材料重量",
    volume_weight_coefficient_p0: "体积重系数: 除{0}",
    chargeable_volume: "计费体积",
    chargeable_total_volume: "计费总体积",

    volume_weight:"体积重",
    actual_weight:"实重",
    density_volume:"密度体积",
    actual_volume:"实际体积",

    supplier_channel_name: "供应商渠道名称",
    supplier_scheme_code: "供应商方案代码",

    api_account_number: "API账号",
    billing_name: "打单名称",
    receiving_channel_code: "收货渠道代码",
    receiving_supply_code: "收货供应代码",
    api_status: "API状态",
    supply_channels: "供应渠道",
    interface_name: "接口名称",

    support_address: "支持地址",
    receipt_of_information: "收件信息",
    send_message: "发件信息",
    waybill_number: "运单号",
    receipt_company: "收件公司",
    phone: "电话",
    packaging_figure: "包装图",
    customer_cancels_shipment: "客户取消发运",
    end_receipt: "结束收货",
    are_you_sure_cancels_shipment: "是否确认取消发运？",
    pay_on_arrival: "物流到付",
    cancel_box_count: "取消箱数",

    select_logistics_scheme: "选择物流方案",
    
    relevance_out_no: "关联出库编号",
    relevance_in_no: "关联入库编号",

    four_in_one_file: "四合一文件",
    customs_declaration: "报关单",
    contract: "合同",
    invoice: "发票",
    packing_documents: "装箱单",

    print_waybill_creation_time: "运单创建时间",
    print_booking_note: "打印物流面单",
    reset_print: "重置打单",
    fee_algorithm: "费用算法",
    interface_account: "接口账号",
    carrier: "承运商",
    price: "价格",
    aging: "时效",
    logistics: "物流",
    truck: "卡车",
    quote: "报价",
    quote_options: "报价选项",
    quote_details: "报价详情",
    quote_options_tips: "卡车价格波动较大，需实时询价",
    quote_type: "报价类型",
    service_level: "服务等级",
    additional_options: "附加选项",
    inquiry_immediately: "立即询价",

    select_quote: "选择报价",
    return_contact_name: "退货联系人",
    return_contact_phone_number: "退货联系电话",
    logistics_channel: "物流渠道",
    service_charge: "手续费",
    re_select: "重新选择",
    flight_number: "航班号",
    take_off_time: "起飞时间",
    city_search: "城市(请搜索)",
    view_cabin: "查看舱位",
    accounting_period_configuration: "账期配置",
    logistics_node: "物流节点",
    accounting_period_calculation_method: "账期计算方式",
    accounting_period_days: "账期天数",
    next_month: "次月",
    check_the_postcode: "检查邮编",
    region: "地区",
    customer_payment_return_time: "客户付款退回时间",
    payment_countdown: "支付倒计时",
    forecast_volume: "预报体积",
    forecast_weight: "预报重量",
    print_warehouse_receipt: "打印入仓单",
    total_cost: "总费用",
    print_bill: "打印账单",
    export_bill: "导出账单",
    finance: "财务",
    purchase_insurance: "购买保险",
    discount_tips: "注意: 折扣只会影响单价 (每方或者每千克)",
    final_delivery: "尾程派送",
    delivery_type: "派送类型",
    final_delivery_info: "派送信息",
    edit_final_delivery: "修改派送信息",
    express_delivery_company: "快递公司",
    express_delivery_number: "快递单号",
    transportation_tracking_number: "运输单号",
    order_signing: "订单签收",
    are_you_sure_to_sign_for_the_order: "是否确认签收订单?",
    single_number: "单号",
  },
};

<template>
  <div>
    <a-modal :visible="visible"
             :title="$t('common.take_photo')"
             :maskClosable="false"
             :width="600"
             @cancel="handleCancel">
      <a-spin :spinning="takePhotoModal.loading">
        <div class="mb-2" style="display:flex; justify-content: flex-end;" v-if="takePhotoModal.cameras && takePhotoModal.cameras.length>1">
          <a-select style="width: 200px;max-width:100%;" v-model:value="takePhotoModal.deviceId">
            <a-select-option
              v-for="item in takePhotoModal.cameras"
              :key="item"
              :title="item.label"
              :value="item.deviceId"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="video-camera"
             v-if="!takePhotoModal.notSupported">

          <CWebCam ref="webcamRef"
                   :width="takePhotoModal.videoWidth"
                   :height="takePhotoModal.videoHeight"
                   v-model:deviceId="takePhotoModal.deviceId"
                   @error="handleCamError"
                   @started="handleStarted" 
                   @cameras="handleCamerasGetted"/>

          <div v-if="takePhotoModal.file"
               class="img-preview">
            <img :src="takePhotoModal.file" />
          </div>
        </div>
        <div class="pt-5 pb-5 text-center"
             v-if="takePhotoModal.notSupported">
          {{$t("warehouse.device_not_found_or_browser_not_supported")}}
        </div>
        <!-- <a-row type="flex"
              justify="center"
              align="middle"
              class="mt-3">
          <a-col>
            <a-button @click="handleTakePhoto"
                      :disabled="takePhotoModal.loading">
              <span v-if="takePhotoModal.file">重拍</span>
              <span v-else>拍照</span>
            </a-button>
          </a-col>
          <a-col>
            <a-button @click="hanldeUpdateFile"
                      class="ml-5"
                      v-if="takePhotoModal.file"
                      :loading="takePhotoModal.loading">上传</a-button>
          </a-col>
        </a-row> -->
      </a-spin>

      <template #footer>
        <div class="text-center">
          <a-space v-if="!takePhotoModal.file">
            <a-button @click="handleCancel">
              {{$t('common.cancel')}}
            </a-button>
            <a-button type="primary"
                      @click="handleTakePhoto"
                      :disabled="takePhotoModal.loading || takePhotoModal.notSupported">
              {{$t('common.take_photo')}}
            </a-button>

          </a-space>

          <a-space v-if="takePhotoModal.file">
            <a-button @click="()=>takePhotoModal.file=''"
                      :disabled="takePhotoModal.loading || takePhotoModal.notSupported">
              {{$t('common.rephotograph')}}
            </a-button>
            <a-button type="primary"
                      @click="hanldeConfirmImg"
                      :loading="takePhotoModal.loading || takePhotoModal.notSupported">
              {{$t('common.confirm')}}
            </a-button>
          </a-space>

        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { ref, reactive, onBeforeUnmount, onMounted, nextTick, watch } from 'vue'
import {
  Modal, Button, message, Row, Col, Spin, Space, Select, 
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CWebCam from "./CWebCam.vue";

export default ({
  name: "TakePhotoModal",
  components: {
    AModal: Modal,
    AButton: Button,
    CWebCam,
    ARow: Row,
    ACol: Col,
    ASpin: Spin,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  props: {
    // updateLoading: {
    //   type: Boolean,
    //   default: false,
    //   required: true
    // },
    visible: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    cancel: {
      type: Function,
      default: null,
    },
    ok: {
      type: Function,
      default: null,
    },
    error: {
      type: Function,
      default: null,
    }
  },
  setup (props, { emit }) {
    let webcamRef = ref();
    const i18n = useI18n({ useScope: "global" });
    let videoInitFlag = false;

    watch(() => { return props.visible; }, () => {
      if (props.visible == true) {
        afterModalShown();
      } else {
        if (videoInitFlag) {
          beforeModalClose();
        }
      }
      videoInitFlag = true;
    });

    const takePhotoModal = reactive({
      //visible: false,
      loading: false,
      notSupported: false,
      deviceId: null,
      file: null,
      videoWidth: "100%",
      videoHeight: "100%",
      cameras:[],
    })

    const afterModalShown = () => {
      // if (!takePhotoModal.deviceId) {
      //   takePhotoModal.loading = true;
      // }      
      takePhotoModal.loading = true;
      //takePhotoModal.visible = true;
      takePhotoModal.file = null;
      takePhotoModal.notSupported = false;
      setDeviceIdFromCache();
      nextTick(() => {
        if (webcamRef.value && webcamRef.value.start) {
          webcamRef.value.start();
        }
      })
    }

    // const handleShowModal = () => {
    //   if (!takePhotoModal.deviceId) {
    //     takePhotoModal.loading = true;
    //   }
    //   //takePhotoModal.visible = true;
    //   takePhotoModal.file = null;
    //   takePhotoModal.notSupported = false;
    //   if(webcamRef.value && webcamRef.value.start) {
    //     webcamRef.value.start();
    //   }
    // }

    const beforeModalClose = () => {
      //takePhotoModal.visible = false;
      if (webcamRef.value && webcamRef.value.stop) {
        webcamRef.value.stop();
      }
    }

    // const handleCloseModal = () => {
    //   takePhotoModal.loading = false;
    //   //takePhotoModal.visible = false;
    //   message.success(i18n.t("common.succeed"));
    //   if(webcamRef.value) {
    //     console.log(webcamRef)
    //     webcamRef.value.stop();
    //     console.log("stop")
    //   }
    // }

    //视频加载完
    const handleStarted = (stream) => {
      takePhotoModal.loading = false;
    }

    //加载失败
    const handleCamError = (error) => {
      message.error(i18n.t("warehouse.device_not_found_or_browser_not_supported"));
      takePhotoModal.notSupported = true;
      takePhotoModal.loading = false;
      emit("error", error);
    }

    //拍照
    const handleTakePhoto = () => {
      takePhotoModal.file = webcamRef.value.takePicture();

    }

    //确定
    const hanldeConfirmImg = () => {
      emit("ok", takePhotoModal.file);
      if (props.autoClose) {
        beforeModalClose();
        emit("update:visible", false);
      }
    }

    // const funcBase64ToFile = (base64String) => {
    //   var bytes = window.atob(base64String.split(',')[1]);
    //   var ab = new ArrayBuffer(bytes.length);
    //   var ia = new Uint8Array(ab);
    //   for (var i = 0; i < bytes.length; i++) {
    //     ia[i] = bytes.charCodeAt(i);
    //   }
    //   var blobData = new File([ia], Date.now() + "_inwh_photo.jpg", { type: 'image/jpeg' });
    //   return blobData;
    // }

    const handleCancel = () => {
      beforeModalClose();
      emit("update:visible", false);
      emit("cancel");
    }

    const cacheDeviceKey = "_cache_take_photo_device_id";
    const cacheDeviceId = ()=>{
      if( takePhotoModal.deviceId){
        localStorage.setItem(cacheDeviceKey, takePhotoModal.deviceId);
      }else{
        localStorage.removeItem(cacheDeviceKey);
      }
    }

    const setDeviceIdFromCache = ()=>{
      let deviceId = localStorage.getItem(cacheDeviceKey);
      if(deviceId && takePhotoModal.cameras && takePhotoModal.cameras.length>1){
        if(takePhotoModal.cameras.find(x=>x.deviceId == deviceId) != undefined){
          takePhotoModal.deviceId = deviceId;
        }
      }
    }

    watch(()=>takePhotoModal.deviceId, ()=>{ cacheDeviceId(); });

    const handleCamerasGetted = (cameras)=>{
      var index = 1;
      takePhotoModal.cameras = (cameras||[]).map((x,i)=>{
        return {
          deviceId:x.deviceId,
          label:x.label||("Camera-"+(index++)),
          kind:x.kind,
          groupId:x.groupId,
        }
      });

      setDeviceIdFromCache();
    }
    return {
      webcamRef,
      props,
      takePhotoModal,
      handleCamError,
      handleStarted,
      handleTakePhoto,
      hanldeConfirmImg,
      // handleShowModal,
      // handleCloseModal,
      // hanldeUpdateFile,


      handleCancel,
      handleCamerasGetted,
    }
  },

})
</script>
<style lang="less">
.video-camera {
  position: relative;
  width: 100%;
  max-width: 100%;
  .img-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // display: flex;
    // justify-content:center;
    // align-items:center;
    background: white;
    img {
      display: table-cell;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
import client from "../../client";

const Api = {
  // 应用租户列表
  getAuthorizeShopByAuthorizeAppId: "/api/OpenPlatformService/AuthorizeApplication/GetAuthorizeShopByAuthorizeAppId",
  // 授权租户
  allowAppAuthShop: "/api/OpenPlatformService/AuthorizeApplication/AllowAppAuthShop",
  // 取消授权
  cancelAppAuthShop: "/api/OpenPlatformService/AuthorizeApplication/CancelAppAuthShop",
};

export const getAuthorizeShopByAuthorizeAppId = (data) => {
  return client.request({
    url: Api.getAuthorizeShopByAuthorizeAppId,
    params: data,
    method: "GET",
  });
};

export const allowAppAuthShop = (data) => {
  return client.request({
    url: Api.allowAppAuthShop,
    data,
    method: "POST",
  });
};

export const cancelAppAuthShop = (data) => {
  return client.request({
    url: Api.cancelAppAuthShop,
    data,
    method: "POST",
  });
};

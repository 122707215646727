import client from "../../../client";

const Api = {
  getInfo: "/api/ProductService/Shop/GetById",
  getBalanceList: "/api/ProductService/Balance/GetList",
  getSafetyList: "/api/URCService/UserLoginLog/GetList",
  getLimitRecords: "/api/ProductService/OverdraftFacilityHistory/GetListPaged",
  updateLimit: "/api/ProductService/Balance/UpdateOverdraftFacility",
  // 手动退款
  rechargeRefund: "/api/ProductService/RechargeLog/Refund",

  updateBalance: "/api/ProductService/Balance/UpdateBalance",
  updateBusinessInfo: "/api/ProductService/Shop/UpdateBusinessInfo",
  offlineService: "/api/ProductService/RechargeLog/OfflineService",
  updateIsAgent: "/api/ProductService/Shop/UpdateIsAgent",
};

export const updateBalance = ({balanceId,paymentDays,isLogisticsArrivePay}) => {
  return client.request({
    url: Api.updateBalance,
    data: { balanceId,paymentDays,isLogisticsArrivePay },
    method: "post",
  });
};

export const getInfo = (id) => {
  return client.request({
    url: Api.getInfo,
    params: { id },
    method: "get",
  });
};

export const getBalanceList = (shopId) => {
  return client.request({
    url: Api.getBalanceList,
    data: { shopId },
    method: "post",
  });
};

export const getSafetyList = ({ shopId, currentIndex, maxResultCount }) => {
  return client.request({
    url: Api.getSafetyList,
    data: { shopId, pageIndex: currentIndex, pageSize: maxResultCount },
    method: "post",
  });
};

export const getLimitRecords = ({
  balanceId,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getLimitRecords,
    data: { balanceId, skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const updateLimit = (balanceId, overdraftFacility ) => {
  return client.request({
    url: Api.updateLimit,
    data: { balanceId, overdraftFacility },
    method: "post",
  });
};

export const rechargeRefund = (data) => {
  return client.request({
    url: Api.rechargeRefund,
    data,
    method: "POST",
  });
};

export const updateBusinessInfo = (data) => {
  return client.request({
    url: Api.updateBusinessInfo,
    data,
    method: "POST",
  });
};

export const offlineService = (data) => {
  return client.request({
    url: Api.offlineService,
    data,
    method: "POST",
  });
};

export const updateIsAgent = (data) => {
  return client.request({
    url: Api.updateIsAgent,
    data,
    method: "POST",
  });
};

<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.product_management") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div>
        <a-row :gutter="[16, 16]">
          <a-col>
            <a-input-group compact style="width: 300px">
              <a-select
                v-model:value="selectSearchType"
                style="width: 40%"
                class="selectDiv"
              >
                <a-select-option
                  value="productNo"
                  :title="$t('warehouse.product_no')"
                >
                  {{ $t("warehouse.product_no") }}</a-select-option
                >
                <a-select-option
                  value="productName"
                  :title="$t('warehouse.product_name')"
                  >{{ $t("warehouse.product_name") }}</a-select-option
                >
              </a-select>
              <a-input
                style="width: 60%"
                allowClear
                v-model:value="inputSearchValue"
              />
            </a-input-group>
          </a-col>

          <a-col>
            <CSearchShop v-model:shopId="shopId"></CSearchShop>
          </a-col>

          <a-col>
            <a-input
              v-model:value="seSku"
              style="width: 250px"
              placeholder="SESKU"
              allow-clear
            ></a-input>
          </a-col>
          <a-col>
            <a-button
              type="primary"
              :loading="inputSearchProductLoading"
              @click="handleSearch"
              >{{ $t("common.query") }}</a-button
            >
          </a-col>
          <a-col>
            <a-button type="ghost" @click="pinModalState.visible = true">{{
              $t("common.export")
            }}</a-button>
          </a-col>
        </a-row>
        <!-- list表格 -->
        <div class="mt-3">
          <a-table
            :columns="columns"
            :data-source="productList"
            :scroll="{ x: true, y: wrap.contentHeight - 100 }"
            size="small"
            :pagination="false"
            :loading="productListLoading"
            class="product-table"
          >
            <template #creationTimeCustom="{ record }">
              {{ $filters.utcToCurrentTime(record.creationTime) }}
            </template>

            <template #shopNameAndShopId="{ record }">
              {{ record.shopName }}
              <div v-if="record.shopNo">NO: {{ record.shopNo }}</div>
              <div>
                {{ $t("common.shop_mark") }}: {{ record.shopMark ?? "-" }}
              </div>
            </template>
            <template #nameAndNo>
              <div>
                {{ $t("warehouse.product_name") }}/{{
                  $t("warehouse.product_no")
                }}
              </div>
            </template>
            <template #productInfo="{ record }">
              <a-space>
                <div class="table-list-img-common">
                  <c-image :src="record.imgSrc"></c-image>
                </div>
                <div>
                  <div>{{ record.productName }}</div>
                  <div>{{ record.productNo }}</div>
                </div>
              </a-space>
            </template>
            <template #packagingSize>
              <span>
                {{ $t("warehouse.product_dimensions") }}
              </span>
              <a-tooltip>
                <template #title>{{
                  $t("warehouse.product_of_size")
                }}</template>
                <span class="ml-1 mr-1">
                  <QuestionCircleOutlined />
                </span>
              </a-tooltip>
            </template>

            <template #packagingSizeCustomRender="{ record }">
              <a-row :gutter="[8, 8]">
                <a-col>
                  {{ record.packagingSize }}
                </a-col>
                <a-col> (客户)</a-col>
              </a-row>
              <a-row
                v-if="
                  record.packingVerifyInfo?.packingLength &&
                  record.packingVerifyInfo.packingWidth &&
                  record.packingVerifyInfo.packingHeight
                "
                :gutter="[8, 8]"
              >
                <a-col>
                  {{ record.packingVerifyInfo.packingLength }}x{{
                    record.packingVerifyInfo.packingWidth
                  }}x{{ record.packingVerifyInfo.packingHeight }}cm
                </a-col>
                <a-col> (实际) </a-col>
              </a-row>
            </template>

            <template #material="{ record }">
                {{ record.customsDeclaration.customsCnName }}
              <div>
              {{ record.customsDeclaration.customsEnName }}
              </div>
            </template>
            <template #brand="{ record }">
              <div v-if="record.customsDeclaration.hasBrand">
                <a-tag color="processing">{{
                  record.customsDeclaration.brand
                }}</a-tag>
              </div>
              <span v-else>-</span>
            </template>

            <template #packingImgUrl="{ record }">
              <div class="table-list-img-common" v-if="record.packingImgUrl">
                <c-image :src="record.packingImgUrl"></c-image>
              </div>
            </template>
            <template #operate="{ record }">
              <!-- <a-button type="link"
                        @click="handleEdit(record.key)">
                {{ $t("common.edit") }}
              </a-button> -->
              <a-dropdown-button @click="handleEdit(record.key)">
                {{ $t("common.edit") }}
                <template #overlay>
                  <a-menu @click="handleClickOperateMenu">
                    <a-menu-item :key="2" :record="record">
                      {{ $t("warehouse.print_sesku_lable") }}
                    </a-menu-item>
                    <a-menu-item :key="3" :record="record">
                      {{ $t("warehouse.download_se_lable") }}
                    </a-menu-item>
                    <a-popconfirm
                      :title="$t('common.are_you_sure')"
                      :ok-text="$t('common.confirm')"
                      :cancel-text="$t('common.cancel')"
                      @confirm="handleDeleteProduct(record)"
                    >
                      <a-menu-item key="trigger" :record="record">
                        {{ $t("common.delete") }}
                      </a-menu-item>
                    </a-popconfirm>
                    <a-menu-item key="historical" :record="record">
                      {{ $t("menu.products_historical_version") }}
                    </a-menu-item>
                    <a-menu-item key="productCheckHistory" :record="record">
                      产品核验记录
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown-button>
            </template>

            <template #transportCharacteristicsCustom="{ record }">
              <a-row
                :gutter="[0, 3]"
                v-if="record.transportCharacteristics.length > 0"
              >
                <a-col
                  v-for="item in record.transportCharacteristics"
                  :key="item"
                >
                  <a-tag color="default">
                    {{
                      $t($enumLangkey("transportCharacteristics", item))
                    }}</a-tag
                  >
                </a-col>
              </a-row>
              <span v-else>-</span>
            </template>
          </a-table>
        </div>
      </div>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleDownloadExcel"
      />
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle" class="mb-1">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import Content from "../components/Content.vue";
import {
  message,
  Row,
  Popconfirm,
  Col,
  Table,
  Input,
  Select,
  Button,
  Tag,
  Space,
  Form,
  Tooltip,
  Dropdown,
  Menu,
} from "ant-design-vue";
import { toRefs, reactive, onActivated } from "vue";
import CPager from "../components/CPager.vue";
import {
  getProductList,
  getDefaultProductSeLable,
  deleteProduct,
  exportInventoryList,
} from "../../api/modules/products/list";
import { transportCharacteristics } from "../../enum/enum.json";
import { useRouter } from "vue-router";
import CImage from "../components/CImage.vue";
import CSearchShop from "../components/CSearchShop.vue";
import { useI18n } from "vue-i18n/index";
import { gToKg, dateString } from "../../utils/general";

import localPrint from "../../utils/localPrint";
import { downloadFile } from "../../utils/downloader";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";

export default {
  name: "products_list",
  components: {
    CPinTipsModal,
    Content,
    CSearchShop,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    AInputSearch: Input.Search,
    ASelect: Select,
    AButton: Button,
    ATag: Tag,
    ASelectOption: Select.Option,
    ASpace: Space,
    CImage,
    CPager,
    AForm: Form,
    AFormItem: Form.Item,
    ATooltip: Tooltip,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    APopconfirm: Popconfirm,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    //#region columns
    const columns = [
      {
        title: () => vueI18n.t("warehouse.customer"),
        dataIndex: "shopNameAndShopId",
        slots: {
          customRender: "shopNameAndShopId",
        },
        fixed: "left",
        width: 150,
      },
      {
        slots: {
          title: "nameAndNo",
          customRender: "productInfo",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("common.product_packing_imag"),
        slots: {
          customRender: "packingImgUrl",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("材质"),
        slots: {
          customRender: "material",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("品牌"),
        slots: {
          customRender: "brand",
        },
        width: 100,
      },
      {
        dataIndex: "seSku",
        title: "SESKU",
        width: 90,
      },
      {
        dataIndex: "encasementSpecification",
        title: () => vueI18n.t("warehouse.encasement_specification"),
        width: 100,
      },
      {
        dataIndex: "encasementAmount",
        title: () => vueI18n.t("warehouse.encasement_count"),
        width: 80,
      },
      {
        dataIndex: "encasementGrossWeight",
        title: () => vueI18n.t("warehouse.encasement_weight"),
        width: 80,
      },
      {
        dataIndex: "packagingSize",
        slots: {
          title: "packagingSize",
          customRender: "packagingSizeCustomRender",
        },
        width: 130,
      },
      {
        dataIndex: "productGrossWeight",
        title: () => vueI18n.t("warehouse.product_gross_weight"),
        width: 80,
      },
      {
        dataIndex: "transportCharacteristics",
        title: () => vueI18n.t("logistics.transport_characteristics"),
        slots: {
          customRender: "transportCharacteristicsCustom",
        },
        width: 200,
      },
      {
        dataIndex: "creationTime",
        title: () => vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTimeCustom",
        },
        width: 100,
      },
      {
        dataIndex: "operate",
        fixed: "right",
        slots: {
          customRender: "operate",
        },
        width: 120,
      },
    ];
    //#endregion
    const state = reactive({
      transportCharacteristics: transportCharacteristics,
    });

    const data = reactive({
      selectSearchType: "productNo",
      inputSearchValue: "",
      shopKeyWord: "",
      shopId: null,
      seSku: null,
      productListLoading: false,
      inputSearchProductLoading: false,
      productList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      pinModalState: {
        visible: false,
        loading: false,
      },
    });

    const funcSearch = () => {
      let searchData = {
        shopId: data.shopId,
        seSku: data.seSku,
        skipCount: data.pageData.skipCount,
        maxResultCount: data.pageData.maxResultCount,
        [data.selectSearchType]: data.inputSearchValue,
      };
      data.productListLoading = true;
      data.inputSearchProductLoading = true;
      getProductList(searchData)
        .then((res) => {
          let { items, totalCount } = res.result;
          if (items && items.length > 0) {
            data.productList = items.map((x) => {
              return {
                key: x.id,
                imgSrc: x.imgUrl,
                previewImage:
                  x.imgUrl + "?x-oss-process=image/resize,m_lfit,h_60,w_60",
                packingImgUrl: x.packingImgUrl,
                productName: x.productName,
                productNo: x.productNo,
                shopName: x.shopName,
                shopId: x.shopId,
                shopNo: x.shopNo,
                shopMark: x.shopMark,
                seSku: x.seSku,
                encasementSpecification:
                  x.containerLength && x.containerWidth && x.containerHeight
                    ? x.containerLength +
                      "x" +
                      x.containerWidth +
                      "x" +
                      x.containerHeight +
                      "cm"
                    : "-",
                encasementAmount: x.containerCount,
                encasementGrossWeight: gToKg(x.containerWeight) + "kg",
                packagingSize:
                  x.packingLength && x.packingWidth && x.packingHeight
                    ? x.packingLength +
                      "x" +
                      x.packingWidth +
                      "x" +
                      x.packingHeight +
                      "cm"
                    : "-",
                productGrossWeight: gToKg(x.packingWeight) + "kg",
                transportCharacteristics: x.transportCharacteristics,
                creationTime: x.creationTime,
                packingVerifyInfo: x.packingVerifyInfo,
                customsDeclaration: x.customsDeclarations.find(
                  (x) => x.isDefault
                ),
              };
            });
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
            data.productList = [];
          }
          data.productListLoading = false;
          data.inputSearchProductLoading = false;
        })
        .catch(() => {
          data.productListLoading = false;
          data.inputSearchProductLoading = false;
        });
    };

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleSearch = async (pageData) => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcSearch();
    };

    const handleEdit = async (productId) => {
      router.push({ path: "/products/edit/" + productId });
    };

    const handlePrintOrDownload = async (produtcId, menukey) => {
      getDefaultProductSeLable(produtcId)
        .then(({ result }) => {
          if (result && result.imgUrl) {
            if (menukey == 2) {
              localPrint(result.imgUrl).catch((error) => {});
            } else {
              downloadFile(result.imgUrl, result.code);
            }
          }
        })
        .catch(() => {});
    };

    const handleClickOperateMenu = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case "trigger":
          break;
        case "historical":
          router.push({ path: "/products/historical/" + record.key });
          break;
        case "productCheckHistory":
          router.push({ path: "/products/checkhistory/" + record.key });
          break;
        default:
          const eventObj = {
            2: handlePrintOrDownload,
            3: handlePrintOrDownload,
          };
          eventObj[key](record.key, key);
          break;
      }
    };

    const handleDeleteProduct = (record) => {
      deleteProduct(record.key)
        .then(() => {
          message.success(vueI18n.t("common.delete_success"));
          handleSearch();
        })
        .catch(() => {});
    };

    const handleDownloadExcel = (pinCode) => {
      data.pinModalState.loading = true;
      let url = exportInventoryList();
      const postData = {
        shopId: data.shopId,
        pinCode,
        seSku: data.seSku,
        [data.selectSearchType]: data.inputSearchValue,
      };
      downloadFile(
        url,
        `products_${dateString()}.csv`,
        "POST",
        postData
      ).finally(() => {
        data.pinModalState.visible = false;
        data.pinModalState.loading = false;
      });
    };

    onActivated(async () => {
      if (data.productListLoading) {
        return;
      }
      handleSearch();
    });

    return {
      ...toRefs(state),
      ...toRefs(data),
      columns,

      handleEdit,
      handlePage,
      handleSearch,
      handleClickOperateMenu,
      handleDeleteProduct,
      handleDownloadExcel,
    };
  },
};
</script>
<style lang="less" scoped></style>

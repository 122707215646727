import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";


const Api = {
  getListPaged: "/api/ProductService/Balance/GetListPaged",
  exportListPaged: "/api/ProductService/Balance/ExportListPaged",
};

export const getListPaged = (data) => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: "POST",
  });
};

export const exportListPaged = (data) => {
  return client.request({
    url: Api.exportListPaged,
    data,
    method: "POST",
  });
};

export const getDownloadBillUrl = ()=>{
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportListPaged);
  return url;
}
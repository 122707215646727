export default {
  "common": {
    "forbidden": "Without authorization",
    "incorrect_username_or_password": "Incorrect username or password",
    "username": "Username",
    "password": "Password",
    "login": "Login",
    "logout": "Logout",
    "submit": "Submit",
    "re_login": "Login again",
    "remeber_me": "Remeber me",
    "required": "Required.",
    "p0_is_required": "{0} is required",
    "p0_must_be_at_least_p1_characters": "{0} requires at least {1} character",
    "p0_should_be_p1_to_p2_characters": "{0} should be {1} to {2} characters.",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "print": "Print",
    "please_scan_or_enter_the_box_number": "Please scan or input carton number",
    "please_scan_the_operate_console_number": "Please scan operation platform number.",
    "target_storage_location_number": "Target location number",
    "no_data": "No Data",
    "product_packing_imag": "Product package picture.",
    "must_be_an_integer": "must be an integer",
    "p0_must_be_an_integer": "{0} must be an integer",
    "delete_success": "Deleted Successfully",
    "reset_succeed": "Successfully reset",
    "scan_succeed": "Successfully scanned",
    "close": "Close",
    "save": "Save",
    "days": "天",
    "succeed": "Succeed",
    "succeed_delete": "成功删除",
    "are_you_sure": "Are you sure?",
    "are_you_sure_you_want_to_delete_this_item": "Confirm to delete this item?",
    "confirm_move_task": "Total quantity of the warehouse shifting task you are adding is:",
    "successfully_canceled": "Cancelled successfully",
    "back_to_previous_page": "Return to last page",
    "backtrack": "Back",
    "details": "Details",
    "operate": "Operate",
    "length": "Length",
    "width": "Width",
    "height": "Height",
    "volume": "Volume",
    "weight": "Weight",
    "max_length_side": "The longest side",
    "max_width_side": "Second longest side",
    "max_height_side": "Shortest side",
    "inbound_warehouse": "Stock in",
    "confirm_scan": "Confirm scan",
    "confirm_delivery": "Confirm delivery",
    "delivered": "Handed over",
    "add_img": "Add image",
    "confirm_outbound_warehouse": "Confirm Stocking Out",
    "print_success": "Printed Successfully",
    "search": "Search",
    "print_the_list_of_executable_tasks": "Print executable task statement",
    "product_label_manage": "Product label management",
    "upload_img": "Upload image",
    "are_you_sure_delete": "你确定要删除吗?",
    "enabled": "Enable",
    "disabled": "Disable",
    "edit": "Edit",
    "normal": "Normal",
    "exception": "Exception",
    "create": "Create",
    "remove": "Remove",
    "delete": "Delete",
    "print_position_coding": "Print location code",
    "binding_location": "Assign location",
    "addition": "Add",
    "added": "Added",
    "complete_the_operation": "Operation completed",
    "remark": "Remarks",
    "select": "Select",
    "403_cue_words": "Sorry, you have no authority on this page.",
    "404_cue_words": "Sorry, the page does not exist.",
    "500_cue_words": "Sorry, there are some problems with the server.",
    "back_home": "Back to home page",
    "undelivered_warehouse": "Undelivered to warehouse",
    "warehouse_lose": "Missing in warehouse",
    "Please_input_number_or_storage_plan_number": "Please input Carton Number or Inbound Plan Number.",
    "close_current": "Colse current section",
    "close_others": "Close other sections",
    "close_all": "Close all sections",
    "total": "Total",
    "item": "Item",
    "upload": "Upload",
    "upload_file": "Upload file",
    "query": "Query",
    "receive": "Take",
    "p0_is_not_complete": "{0} is not completed.",
    "operate_console_no_inexistence": "Scanned operation platform doesn't exist.",
    "invalid_operation": "Invalid operation",
    "operate_no_inexistence": "Scanned number doesn't exist.",
    "look_general_surveying_data": "Check general measuring data",
    "plan_not_finished_scanning": "Plan scanning uncompleted.",
    "p0_days_ago": "{0} days ago",
    "p0_hours_ago": "{0} hours ago",
    "p0_minutes_ago": "{0} minutes ago",
    "data_not_found": "Data not found.",
    "p0_incorrect_format": "{0} format incorrect",
    "country": "Country",
    "destination_country": "Destination Country",
    "province": "Province/State",
    "city": "City",
    "county": "County",
    "address_line": "Detailed address",
    "post_code": "Postcode",
    "please_select": "Please Select",
    "take_photo": "Photo",
    "rephotograph": "Re-photo",
    "local_upload": "Upload from device",
    "photo_upload": "Photo and upload",
    "failed": "Failed",
    "p0_is_wrong": "{0}错误",
    "add": "Add",
    "serial_number": "序号",
    "product_statistics": "产品统计",
    "product_no": "货号",
    "number_of_boxes": "箱数",
    "the_total_number_of_boxes": "总箱数",
    "total_product": "产品总数",
    "export": "导出",
    "export_product_in_the_warehouse_time": "导出产品库龄",
    "yes": "是",
    "no": "否",
    "version_tips": "当前为非正式版本",
    "match_or_not": "是否匹配",
    "shop_mark": "标识",
    "are_you_sure_cancel": "Confirm to cancel?",
    "good_morning": "Good Morning",
    "good_afternoon": "Good Afternoon",
    "good_evening": "Good Evening",
    "welcome": "Welcome",
  }
}
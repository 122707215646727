import client from "../../client"

const Api = {
  //获取阿里云日志分页列表
  getAliLogPageList: "/api/BasicDataService/AliLog/GetListPaged",
  // 箱数据修改记录
  getListPaged: "/api/WarehouseService/BoxCheckUpdateRecord/GetConsoleListPaged",
  // 扫描异常列表
  getWhBoxHasException: "/api/WarehouseService/Box/GetWhBoxHasExceptionForConsole",
  // 导出日志记录列表
  getExportLogListPaged: "/api/ProductService/ExportLog/GetListPaged",
}

//获取权限得分页列表
export const getAliLogPageList = ({ projectName, logstoreName, beginDate, endDate,query,reverse,skipCount,maxResultCount}) => {
  return client.request({
    url: Api.getAliLogPageList,
    data: { projectName, logstoreName, beginDate, endDate,query,reverse,skipCount,maxResultCount },
    method: 'post'
  })
}

// 获取箱数据修改记录
export const getListPaged = data => {
  return client.request({
    url: Api.getListPaged,
    data,
    method: 'POST'
  })
}

// 获取扫描异常列表
export const getWhBoxHasException = data => {
  return client.request({
    url: Api.getWhBoxHasException,
    data,
    method: 'POST'
  })
}

// 导出日志记录列表
export const getExportLogListPaged = data => {
  return client.request({
    url: Api.getExportLogListPaged,
    data,
    method: 'POST'
  })
}
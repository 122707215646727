<template>
    <Content>
        <template #contentTitle>{{ $t('menu.supplier_surcharge') }}</template>
        <template v-slot:contentBody="wrap">
            <div class="lg-row mb-3">
                <div class="content">
                    <div class="d-inline-block">
                        <span class="d-inline-block mr-2">{{ $t('logistics.supplier_channel_name') }}:</span>
                        <b
                            class="d-inline-block mr-2"
                            v-if="tableData.logisticsData.code"
                        >{{ tableData.logisticsData.code }} ({{ tableData.logisticsData.name }})</b>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="actions">
                    <a-button size="small">
                        <router-link
                            :to="{ name: 'supplier_surcharge_edit', params: { supplierId: supplierId } }"
                        >
                            <span>+</span>
                            <small>{{ $t('common.addition') }}</small>
                        </router-link>
                    </a-button>
                </div>
            </div>
            <div>
                <a-input
                    v-model:value="tableData.searchData.searchKey"
                    allow-clear
                    class="mr-2 mb-2"
                    style="max-width:200px"
                    placeholder="名称"
                    @pressEnter="handleSearch"
                ></a-input>
                <a-button
                    type="primary"
                    class="mr-2 mb-2"
                    :loading="tableData.loading"
                    @click="handleSearch"
                >{{ $t("common.query") }}</a-button>
            </div>
            <div>
                <a-table
                    :columns="columns"
                    size="small"
                    :data-source="tableData.tableList"
                    :pagination="false"
                    :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
                    :rowKey="(record, index) => { return index; }"
                    :loading="tableData.loading"
                >
                    <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
                    <template #creationTime="{ record }">
                        <span>{{ $filters.utcToCurrentTime(record.creationTime) }}</span>
                    </template>
                    <template #lastModificationTime="{ record }">
                        <span>{{ $filters.utcToCurrentTime(record.lastModificationTime) }}</span>
                    </template>
                    <template v-slot:operation="{ record }">
                        <a-button type="link" size="small">
                            <router-link
                                :to="{ name: 'supplier_surcharge_edit', params: { supplierId: record.supplierId, id: record.id } }"
                            >
                                <small>配置</small>
                            </router-link>
                        </a-button>
                        <a-popconfirm
                            :title="$t('common.are_you_sure_you_want_to_delete_this_item')"
                            @confirm="handleDeleteSurchargeConfig(record.id)"
                        >
                            <a-button type="link" size="small" class="text-error">
                                <small>删除</small>
                            </a-button>
                        </a-popconfirm>
                    </template>
                </a-table>
            </div>
        </template>
        <template #contentFooter>
            <CPager class="text-center" @handlePage="handlePage" :page-data="tableData.pageData"></CPager>
        </template>
    </Content>
</template>
<script>
import { Table, Button, Row, Col, Input, Popconfirm } from 'ant-design-vue'
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue'
import { useRoute, useRouter } from "vue-router"
import { defineComponent, onMounted, reactive, toRefs, ref, onActivated, } from 'vue'
import { getById as getLogisticsById } from '../../api/modules/supplier/index'
import { getPagedList, deleteById } from '../../api/modules/supplier/surchargeconfig'
export default defineComponent({
    name: "supplier_surcharge",
    components: {
        ATable: Table,
        AButton: Button,
        CPager,
        Content,
        ARow: Row,
        ACol: Col,
        AInput: Input,
        APopconfirm: Popconfirm,
    },
    setup() {
        const route = useRoute();
        const supplierId = route.params.supplierId;
        const tableData = reactive({
            loading: false,
            tableList: [],
            pageData: {
                currentIndex: 1,
                skipCount: 0,
                totalCount: 0,
                maxResultCount: 10
            },
            logisticsData: {
                name: null,
                code: null
            },
            searchData: {
                searchKey: null,
            }
        });
        const columns = [
            {
                width: 80,
                fixed: "left",
                title: () => "序号",
                slots: {
                    customRender: "tableIndex"
                }
            },
            {
                dataIndex: "name",
                width: 200,
                fixed: "left",
                title: () => "模板名称",
            },
            {
                dataIndex: "creationTime",
                width: 200,
                title: () => "创建时间",
                slots: {
                    customRender: "creationTime"
                }
            },
            {
                dataIndex: "lastModificationTime",
                width: 200,
                title: () => "更新时间",
                slots: {
                    customRender: "lastModificationTime"
                }
            },
            {
                align: "center",
                width: 150,
                slots: {
                    customRender: "operation"
                }
            },

        ];


        const getLogisticsInfo = () => {
            tableData.loaging = true;
            getLogisticsById(supplierId)
                .then((res) => {
                    tableData.loading = false;
                    let { result } = res;
                    tableData.logisticsData.name = result.name;
                    tableData.logisticsData.code = result.code;
                })
                .catch(() => {
                    tableData.loading = false;
                });
        }


        const getSurchargeConfigList = () => {
            tableData.loading = true;
            getPagedList({
                supplierId: supplierId,
                searchKey: tableData.searchData.searchKey,
                skipCount: tableData.pageData.skipCount,
                maxResultCount: tableData.pageData.maxResultCount,
            })
                .then((res) => {
                    let { totalCount, items } = res.result;
                    tableData.pageData.totalCount = parseInt(totalCount);
                    tableData.tableList = items;
                    tableData.loading = false;
                })
                .catch(() => {
                    tableData.loading = false;
                })
        }

        const handlePage = (pageData) => {
            tableData.pageData.skipCount = pageData.skipCount;
            tableData.pageData.maxResultCount = pageData.maxResultCount;
            getSurchargeConfigList();
        };

        const handleSearch = () => {
            tableData.pageData.currentIndex = 1;
            tableData.pageData.skipCount = 0;
            getSurchargeConfigList();
        }

        const handleDeleteSurchargeConfig = (id) => {
            deleteById(id)
            .then(() => {
                getSurchargeConfigList();
            })
        }

        onActivated(() => {
            getLogisticsInfo();
            getSurchargeConfigList();
        });

        return {
            supplierId,
            columns,
            tableData,

            handlePage,
            handleSearch,
            handleDeleteSurchargeConfig,
        }
    }
})
</script>
<style lang="less" scoped>
.border-b {
    border-bottom: 1px solid #f0f0f0;
}
.lg-row {
    display: flex;
    flex-wrap: wrap;
    // justify-content: stretch;
    align-items: stretch;
    .content {
        flex-grow: 1;
    }
    .actions {
        flex-grow: 0;
    }
}
</style>
<template>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="$t('logistics.accounting_period_configuration')"
    :mask-closable="false"
    :closable="false"
    :centered="true"
    :confirm-loading="confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-item :label="$t('logistics.logistics_scheme_name')">{{ info.name || '-' }}</a-form-item>
        <a-form-item :label="$t('finance.channel_code')">{{ info.code || '-' }}</a-form-item>
        <a-form-item :label="$t('logistics.logistics_node')" name="logisticsNode">
          <a-select v-model:value="formState.logisticsNode">
            <a-select-option
              v-for="(item, index) in logisticsNodeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('logisticsNode', item))"
            >{{ $t($enumLangkey("logisticsNode", item)) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('logistics.accounting_period_calculation_method')" name="accountingPeriodCalculationWay">
          <a-select v-model:value="formState.accountingPeriodCalculationWay">
            <a-select-option
              v-for="(item, index) in logisticsAccountingPeriodCalculationWayEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('logisticsAccountingPeriodCalculationWay', item))"
            >{{ $t($enumLangkey("logisticsAccountingPeriodCalculationWay", item)) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('logistics.accounting_period_days')" name="accountingPeriodDays">
          <a-input-number v-model:value="formState.accountingPeriodDays" :min="1" :max="999" :precision="0" style="width: 100%;"></a-input-number>
        </a-form-item>
        <a-form-item :label="$t('warehouse.status')">
          <a-switch v-model:checked="formState.isActive" :checked-children="$t('common.enabled')" :un-checked-children="$t('common.disabled')"></a-switch>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, toRefs, watch } from 'vue';
import { Form, InputNumber, Modal, Select, Spin, Switch, message } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { setFormStateValue } from '@/utils/general.js';
import { getByLogisticsId, addOrUpdate } from '@/api/modules/logistics/index.js';
import {
  logisticsNode as logisticsNodeEnum,
  logisticsAccountingPeriodCalculationWay as logisticsAccountingPeriodCalculationWayEnum,
} from '@/enum/enum.json';

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInputNumber: InputNumber,
    ASwitch: Switch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({
        name: null,
        code: null,
      })
    },
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        logisticsId: null,
        logisticsNode: null,
        accountingPeriodCalculationWay: null,
        accountingPeriodDays: null,
        isActive: true,
      },
      info: {
        name: null,
        code: null,
      }
    })

    const rules = {
      logisticsNode: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.logistics_node')]),
      },
      accountingPeriodCalculationWay: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.accounting_period_calculation_method')]),
      },
      accountingPeriodDays: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.accounting_period_days')]),
      },
    }

    const getDetail = () => {
      state.loading = true;
      getByLogisticsId({ logisticsId: state.formState.logisticsId })
        .then(({ result }) => {
          if (result) {
            setFormStateValue(state.formState, result);
          }
        })
        .catch(() => {})
        .finally(() => {
          state.loading = false;
        });
    }

    watch(() => [props.visible, props.info], ([visible, info]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          if (!!info) {
            nextTick(() => {
              formRef.value?.resetFields();
              state.formState.logisticsId = info.logisticsId;
              setFormStateValue(state.info, info);
              getDetail();
            });
          }
        }
      }
    }, { immediate: true, deep: true });

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        await addOrUpdate(data);
        message.success(vueI18n.t('common.succeed'));
        emit('confirm');
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    return {
      ...toRefs(state),
      logisticsNodeEnum,
      logisticsAccountingPeriodCalculationWayEnum,
      formRef,
      rules,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>
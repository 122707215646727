<template>
  <a-select
    allowClear
    v-model:value="id"
    show-search
    placeholder="请输入员工名称、编号或邮箱"
    style="width: 250px"
    :filter-option="false"
    :not-found-content="loading ? undefined : null"
    :options="list"
    @search="handleSearch"
    @change="handleChange"
  >
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { Select, message, Spin } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { getEmployeeList } from "@/api/modules/customer/company/list.js";
import { debounce, getNameByLanguage } from "@/utils/general";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpin: Spin,
  },
  props: {
    employeeId: Array,
  },
  emits: ["update:employeeId"],
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const state = reactive({
      loading: false,
      btnLoading: false,
      id: null,
      list: [],
    });

    const getFullName = (user) => {
      if (!user) {
        return "";
      }
      let userName = getNameByLanguage(user, getters.language);
      return `${userName}(${user.employeeNo})`;
    };

    const funcGetShopInfo = (value) => {
      state.loading = true;
      getEmployeeList({ maxResultCount: 50, searchKey: value })
        .then(({ result }) => {
          let list = result.items?.map((x) => {
            return {
              label: getFullName(x),
              value: x.id,
            };
          });
          state.list = list;
        })
        .finally(() => {
          state.loading = false;
        });
    };
    const handleSearch = debounce(funcGetShopInfo, 500);

    const handleChange = () => {
      emit("update:employeeId", state.id ? [state.id] : []);
    };

    return {
      ...toRefs(state),
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      handleSearch,
      handleChange,
    };
  },
});
</script>

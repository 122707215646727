<template>
  <Content>
    <template #contentTitle
      >{{ $t("menu.logistics_region_edit") }}-{{
        regionId ? $t("common.edit") : $t("common.create")
      }}</template
    >
    <template v-slot:contentBody="wrap">
      <div class="lg-row border-b pb-3 mb-3">
        <div class="content">
          <div class="d-inline-block">
            <span class="d-inline-block mr-2">{{ $t("物流方案") }}:</span>
            <b class="d-inline-block mr-2" v-if="data.logisticsData.code"
              >{{ data.logisticsData.code }} ({{ data.logisticsData.name }})</b
            >
            <span v-else>-</span>
          </div>
        </div>
        <!-- <div class="actions">
                    <a-button>按钮</a-button>
                </div>-->
      </div>
      <div>
        <a-form
          :model="data.formData"
          ref="refForm"
          :label-col="{ span: 6 }"
          :rules="regionRules"
        >
          <a-row>
            <a-col :span="24" :md="12" :xl="8">
              <a-form-item :label="'分区名称'" name="name">
                <a-input v-model:value="data.formData.name"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item
            :label-col="{ span: 6, md: { span: 3 }, xl: { span: 2 } }"
            :label="'Vat填写'"
            name="isVatRequired"
          >
            <a-switch
              v-model:checked="data.formData.isVatRequired"
              size="small"
            ></a-switch>
          </a-form-item>
          <!-- <a-row>
                        <a-col :span="24" :md="12" :xl="8"></a-col>
                    </a-row>-->
          <div>
            <div class="pt-2 pb-2 mb-2 border-b">增值服务设置</div>
            <a-row :gutter="16">
              <a-col>
                <a-form-item name="isDdpSupported">
                  <label>
                    <span class="mr-2">关税预付</span>
                    <a-switch
                      v-model:checked="data.formData.isDdpSupported"
                      size="small"
                    ></a-switch>
                  </label>
                </a-form-item>
              </a-col>
              <a-col>
                <a-form-item name="ddpFee">
                  <a-input-group style="width: 300px">
                    <span class="ant-input-group-addon"
                      >费用 {{ data.formData.currencyCode }}
                      {{ data.formData.currencySymbol }}</span
                    >
                    <a-input-number
                      class="input-group-end-number"
                      type="number"
                      :min="0"
                      v-model:value="data.formData.ddpFee"
                    ></a-input-number>
                  </a-input-group>
                </a-form-item>
              </a-col>
              <a-col>
                <a-form-item name="isDdpIncludeFuelSurcharge">
                  <a-checkbox
                    v-model:checked="data.formData.isDdpIncludeFuelSurcharge"
                    >{{ $t("计算燃油") }}</a-checkbox
                  >
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col>
                <a-form-item name="isSignSupported">
                  <label>
                    <span class="mr-2">签名签收</span>
                    <a-switch
                      v-model:checked="data.formData.isSignSupported"
                      size="small"
                    ></a-switch>
                  </label>
                </a-form-item>
              </a-col>
              <a-col>
                <a-row
                  :gutter="16"
                  v-for="(item, index) in data.formData.signatureDtos"
                >
                  <a-col>
                    <a-form-item
                      :name="['signatureDtos', index, 'signatureType']"
                      :rules="signatureTypeRules"
                    >
                      <a-select
                        style="width: 300px"
                        v-model:value="item.signatureType"
                        :getPopupContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      >
                        <a-select-option
                          v-for="item in signatureTypeEnum"
                          :key="item"
                          >{{
                            $t($enumLangkey("signatureType", item))
                          }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item
                      :name="['signatureDtos', index, 'fee']"
                      :rules="feeRequiredRules"
                    >
                      <a-input-group style="width: 300px">
                        <span class="ant-input-group-addon"
                          >费用 {{ data.formData.currencyCode }}
                          {{ data.formData.currencySymbol }}</span
                        >
                        <a-input-number
                          class="input-group-mid-number"
                          type="number"
                          :min="0"
                          v-model:value="item.fee"
                        ></a-input-number>
                        <span class="ant-input-group-addon">每件</span>
                      </a-input-group>
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item
                      :name="['signatureDtos', index, 'feeTopLimit']"
                    >
                      <a-input-group style="width: 300px">
                        <span class="ant-input-group-addon"
                          >单票费用上限 {{ data.formData.currencyCode }}
                          {{ data.formData.currencySymbol }}</span
                        >
                        <a-input-number
                          class="input-group-end-number"
                          type="number"
                          :min="0"
                          v-model:value="item.feeTopLimit"
                        ></a-input-number>
                      </a-input-group>
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item
                      :name="['signatureDtos', index, 'includeFuelSurcharge']"
                    >
                      <a-checkbox v-model:checked="item.includeFuelSurcharge">{{
                        $t("计算燃油")
                      }}</a-checkbox>
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-button type="link" @click="handleRemoveSignature(index)"
                      ><MinusCircleOutlined
                    /></a-button>
                  </a-col>
                </a-row>
                <div>
                  <a-button @click="handleAddSignature">+添加</a-button>
                </div>
              </a-col>
            </a-row>
          </div>
          <div>
            <div class="pt-2 pb-2 mb-2 border-b">费用配置</div>
            <a-row>
              <a-col :span="24" :md="12" :xl="8">
                <a-form-item :label="'费用模板'" name="feeTemplateId">
                  <a-select
                    placeholder="费用模板"
                    v-model:value="data.formData.feeTemplateId"
                    allow-clear
                  >
                    <a-select-option
                      v-for="item in data.feeTemplates"
                      :key="item.value"
                      >{{ item.text }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div class="pt-2 pb-2 mb-2 border-b">附加费配置</div>
            <a-row>
              <a-col :span="24" :md="12" :xl="8">
                <a-form-item :label="'附加费'" name="surchargeConfigId">
                  <a-select
                    placeholder="附加费"
                    v-model:value="data.formData.surchargeConfigId"
                    allow-clear
                  >
                    <a-select-option
                      v-for="item in data.surchargeConfigs"
                      :key="item.value"
                      >{{ item.text }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <div class="pt-2 pb-2 mb-2 border-b">适用区域</div>
          <a-form-item name="regionCountryRls">
            <div class="mb-2">
              <label>
                <b class="mr-2">{{ $t("common.country") }}</b>
                <a-switch
                  v-model:checked="data.formData.regionCountryEnabled"
                  size="small"
                  @change="validRegionCountryRls"
                ></a-switch>
              </label>
            </div>
            <div>
              <a-select
                :placeholder="$t('common.country')"
                allow-clear
                :loading="addressData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @select="handleCountrySelect"
              >
                <a-select-option
                  v-for="item in addressData.countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.ioS2 + item.cnName + item.enName"
                  :title="`${getLanguageName(item)}(${item.ioS2})`"
                  >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option
                >
              </a-select>
            </div>
            <a-card size="small">
              <a-tag
                class="mb-1 mt-1"
                v-for="item in data.formData.regionCountryRls"
                closable
                @close.prevent="handleCountryTagClose(item)"
                >{{
                  getLanguageName({
                    cnName: item.countryCnName,
                    enName: item.countryEnName,
                  })
                }}</a-tag
              >
            </a-card>
          </a-form-item>
          <a-form-item name="regionProvinceRls">
            <div class="mb-2">
              <label>
                <b class="mr-2">{{ $t("common.province") }}</b>
                <a-switch
                  v-model:checked="data.formData.regionProvinceEnabled"
                  size="small"
                  @change="validRegionProvinceRls"
                ></a-switch>
              </label>
            </div>
            <div>
              <a-select
                v-model:value="addressData.provinceRegion.countryId"
                :placeholder="$t('common.country')"
                allow-clear
                :loading="addressData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @change="handleCountryChangeForProvinceRegion"
              >
                <a-select-option
                  v-for="item in addressData.countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.ioS2 + item.cnName + item.enName"
                  :title="`${getLanguageName(item)}(${item.ioS2})`"
                  >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option
                >
              </a-select>

              <a-select
                v-model:value="addressData.provinceRegion.provinceId"
                :placeholder="$t('common.province')"
                allow-clear
                :loading="addressData.provinceRegion.provinceLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @select="handleProvinceSelect"
              >
                <a-select-option
                  v-for="item in addressData.provinceRegion.provinces"
                  :search-key="item.abbrCode + item.cnName + item.enName"
                  :key="item.id"
                  :title="getLanguageName(item, 'province')"
                  >{{ getLanguageName(item, "province") }}</a-select-option
                >
              </a-select>
            </div>
            <a-card size="small">
              <a-tag
                class="mb-1 mt-1"
                v-for="item in data.formData.regionProvinceRls"
                closable
                @close.prevent="handleProvinceTagClose(item)"
                >{{
                  getLanguageName({
                    cnName: item.provinceCnName,
                    enName: item.provinceEnName,
                  })
                }}({{
                  getLanguageName({
                    cnName: item.countryCnName,
                    enName: item.countryEnName,
                  })
                }})</a-tag
              >
            </a-card>
          </a-form-item>

          <a-form-item name="regionCityRls">
            <div class="mb-2">
              <label>
                <b class="mr-2">{{ $t("common.city") }}</b>
                <a-switch
                  v-model:checked="data.formData.regionCityEnabled"
                  size="small"
                  @change="validRegionCityRls"
                ></a-switch>
              </label>
            </div>
            <div>
              <a-select
                v-model:value="addressData.cityRegion.countryId"
                :placeholder="$t('common.country')"
                allow-clear
                :loading="addressData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @change="handleCountryChangeForCityRegion"
              >
                <a-select-option
                  v-for="item in addressData.countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.ioS2 + item.cnName + item.enName"
                  :title="`${getLanguageName(item)}(${item.ioS2})`"
                  >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option
                >
              </a-select>

              <a-select
                v-model:value="addressData.cityRegion.provinceId"
                :placeholder="$t('common.province')"
                allow-clear
                :loading="addressData.cityRegion.provinceLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @change="handleProviceChangeForCityRegion"
              >
                <a-select-option
                  v-for="item in addressData.cityRegion.provinces"
                  :search-key="item.abbrCode + item.cnName + item.enName"
                  :key="item.id"
                  :title="getLanguageName(item, 'province')"
                  >{{ getLanguageName(item, "province") }}</a-select-option
                >
              </a-select>

              <a-select
                :placeholder="$t('common.city')"
                v-model:value="addressData.cityRegion.cityId"
                allow-clear
                :loading="addressData.cityRegion.cityLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @select="handleCitySelect"
              >
                <a-select-option
                  v-for="item in addressData.cityRegion.cities"
                  :search-key="item.cnName + item.enName"
                  :key="item.id"
                  :title="getLanguageName(item)"
                  >{{ getLanguageName(item) }}</a-select-option
                >
              </a-select>
            </div>
            <a-card size="small">
              <a-tag
                class="mb-1 mt-1"
                v-for="item in data.formData.regionCityRls"
                closable
                @close.prevent="handleCityTagClose(item)"
                >{{
                  getLanguageName({
                    cnName: item.cityCnName,
                    enName: item.cityEnName,
                  })
                }}({{
                  getLanguageName({
                    cnName: item.countryCnName,
                    enName: item.countryEnName,
                  })
                }})</a-tag
              >
            </a-card>
          </a-form-item>

          <a-form-item name="regionCountyRls">
            <div class="mb-2">
              <label>
                <b class="mr-2">{{ $t("common.county") }}</b>
                <a-switch
                  v-model:checked="data.formData.regionCountyEnabled"
                  size="small"
                  @change="validRegionCountyRls"
                ></a-switch>
              </label>
            </div>
            <div>
              <a-select
                v-model:value="addressData.countyRegion.countryId"
                :placeholder="$t('common.country')"
                allow-clear
                :loading="addressData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @change="handleCountryChangeForCountyRegion"
              >
                <a-select-option
                  v-for="item in addressData.countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.ioS2 + item.cnName + item.enName"
                  :title="`${getLanguageName(item)}(${item.ioS2})`"
                  >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option
                >
              </a-select>

              <a-select
                v-model:value="addressData.countyRegion.provinceId"
                :placeholder="$t('common.province')"
                allow-clear
                :loading="addressData.countyRegion.provinceLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @change="handleProviceChangeForCountyRegion"
              >
                <a-select-option
                  v-for="item in addressData.countyRegion.provinces"
                  :search-key="item.abbrCode + item.cnName + item.enName"
                  :key="item.id"
                  :title="getLanguageName(item, 'province')"
                  >{{ getLanguageName(item, "province") }}</a-select-option
                >
              </a-select>

              <a-select
                :placeholder="$t('common.city')"
                v-model:value="addressData.countyRegion.cityId"
                allow-clear
                :loading="addressData.countyRegion.cityLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @select="handleCityChangeForCountyRegion"
              >
                <a-select-option
                  v-for="item in addressData.countyRegion.cities"
                  :search-key="item.cnName + item.enName"
                  :key="item.id"
                  :title="getLanguageName(item)"
                  >{{ getLanguageName(item) }}</a-select-option
                >
              </a-select>

              <a-select
                :placeholder="$t('common.county')"
                v-model:value="addressData.countyRegion.countyId"
                allow-clear
                :loading="addressData.countyRegion.countyLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
                @select="handleCountySelect"
              >
                <a-select-option
                  v-for="item in addressData.countyRegion.counties"
                  :search-key="item.cnName + item.enName"
                  :key="item.id"
                  :title="getLanguageName(item)"
                  >{{ getCountyName(item) }}</a-select-option
                >
              </a-select>
            </div>
            <a-card size="small">
              <a-tag
                class="mb-1 mt-1"
                v-for="item in data.formData.regionCountyRls"
                closable
                @close.prevent="handleCountyTagClose(item)"
                >{{
                  getCountyName({
                    cnName: item.countyCnName,
                    enName: item.countyEnName,
                    cityCnName: item.cityCnName,
                    cityEnName: item.cityEnName,
                  })
                }}({{
                  getLanguageName({
                    cnName: item.countryCnName,
                    enName: item.countryEnName,
                  })
                }})</a-tag
              >
            </a-card>
          </a-form-item>

          <a-form-item name="regionPostCodeRls">
            <div class="mb-2">
              <label>
                <b class="mr-2">{{ $t("common.post_code") }}</b>
                <a-switch
                  v-model:checked="data.formData.regionPostEnabled"
                  size="small"
                  @change="validRegionPostCodeRls"
                ></a-switch>
              </label>
            </div>
            <div>
              <a-select
                v-model:value="addressData.postRegion.countryId"
                :placeholder="$t('common.country')"
                allow-clear
                :loading="addressData.countryLoading"
                :showSearch="true"
                optionFilterProp="search-key"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                class="mr-2 mb-2"
                style="width: 200px"
              >
                <a-select-option
                  v-for="item in addressData.countries"
                  :key="item.id"
                  :value="item.id"
                  :search-key="item.ioS2 + item.cnName + item.enName"
                  :title="`${getLanguageName(item)}(${item.ioS2})`"
                  >{{ getLanguageName(item) }}({{ item.ioS2 }})</a-select-option
                >
              </a-select>
              <a-input
                v-model:value="addressData.postRegion.postStartWith"
                style="width: 200px"
                class="mr-2 mb-2"
                @pressEnter="handlePostAdd"
                addonBefore="邮编开头为:"
              ></a-input>

              <a-button
                size="small"
                class="mr-3 mb-2"
                :disabled="
                  !(
                    addressData.postRegion.countryId &&
                    addressData.postRegion.postStartWith
                  )
                "
                @click="handlePostAdd"
                >+{{ $t("common.addition") }}</a-button
              >

              <a-button
                size="small"
                class="mr-3 mb-2"
                :disabled="!addressData.postRegion.countryId"
                @click="handlePoseBathAddClick"
                >+{{ $t("批量添加") }}</a-button
              >

              <a-button
                size="small"
                class="mr-1 mb-2"
                :disabled="
                  !(
                    data.formData.regionPostCodeRls &&
                    data.formData.regionPostCodeRls.length > 0
                  )
                "
                @click="handlePostTagClear"
                >{{ $t("清除全部") }}</a-button
              >
            </div>
            <a-card size="small">
              <a-tag
                class="mb-1 mt-1"
                v-for="item in data.formData.regionPostCodeRls"
                closable
                @close.prevent="handlePostTagClose(item)"
              >
                {{
                  getLanguageName({
                    cnName: item.countryCnName,
                    enName: item.countryEnName,
                  })
                }}
                {{ item.postStartWith }}
                开头
              </a-tag>
            </a-card>
          </a-form-item>
        </a-form>
      </div>
      <a-modal
        v-model:visible="data.bathAddPostModalVisible"
        :title="$t('批量添加')"
        :maskClosable="false"
        @ok="handlePoseBathAddSave"
      >
        <div>
          <a-textarea
            v-model:value="data.bathAddPostText"
            :auto-size="{ minRows: 5, maxRows: 10 }"
          ></a-textarea>
        </div>
        <div style="text-align: right">
          <small style="color: gray">{{ bathPostArr.length }}</small>
        </div>
      </a-modal>
    </template>
    <template #contentFooter>
      <div class="text-center">
        <a-button
          type="primary"
          class="m-1"
          @click="handleSaveRegion"
          :loading="data.saving"
          >{{ $t("common.save") }}</a-button
        >
        <a-button class="m-1" @click="handleBackToRegions">{{
          $t("common.cancel")
        }}</a-button>
      </div>
    </template>
  </Content>
</template>
<script>
import {
  Table,
  Button,
  Modal,
  Input,
  Select,
  Card,
  Row,
  Col,
  Form,
  Radio,
  Switch,
  InputNumber,
  Checkbox,
  Tag,
} from "ant-design-vue";
import Content from "../components/Content.vue";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  computed,
  watch,
  nextTick,
} from "vue";
import { useI18n } from "vue-i18n/index";
import { getName, roundNumber } from "../../utils/general";
import { useStore } from "vuex";
import {
  logisticsRegionType as regionTypeEnum,
  signatureType as signatureTypeEnum,
} from "../../enum/enum.json";
import { create, update, getById } from "../../api/modules/logistics/region";
import { getById as getLogisticsById } from "../../api/modules/logistics/index";
import {
  getCountriesParams,
  getProvinces,
  getCitys,
  getCountys,
} from "../../api/modules/common/index";
import { getListByLogisticsId as getFeeTemplates } from "../../api/modules/logistics/feetemplate";
import { getListByLogisticsId as getSurchargeConfigs } from "../../api/modules/logistics/surchargeconfig";
import { useTab } from "@/hooks/tabs/index";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "logistics_region_edit",
  components: {
    ATable: Table,
    AButton: Button,
    AModal: Modal,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    Content,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    ARadio: Radio,
    ARadioOption: Radio.Option,
    ARadioButton: Radio.Button,
    ARadioGroup: Radio.Group,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ATag: Tag,
    ATextarea: Input.TextArea,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const regionId = route.params.id;
    // const logisticsId = route.params.logisticsId;

    const refForm = ref();
    const { delVisitedRoute } = useTab();
    const data = reactive({
      loading: false,
      saving: false,
      logisticsData: {
        name: null,
        code: null,
      },
      formData: {
        id: regionId,
        logisticsId: route.params.logisticsId,
        currencyCode: null,
        currencySymbol: null,
        name: null,
        isVatRequired: false,

        isDdpSupported: false,
        ddpFee: 0,
        isDdpIncludeFuelSurcharge: false,

        isSignSupported: false,
        // signFee: 0,
        // signFeeToplimit: 0,
        // isSignIncludeFuelSurcharge: false,
        signatureDtos: [],

        feeTemplateId: null,
        surchargeConfigId: null,

        regionTypes: [],

        regionCountryEnabled: false,
        regionCountryRls: [],

        regionProvinceEnabled: false,
        regionProvinceRls: [],

        regionCityEnabled: false,
        regionCityRls: [],

        regionCountyEnabled: false,
        regionCountyRls: [],

        regionPostEnabled: false,
        regionPostCodeRls: [],
      },
      feeTemplateLoading: false,
      feeTemplates: [],
      surchargeConfigLoading: false,
      surchargeConfigs: [],
      bathAddPostModalVisible: false,
      bathAddPostText: "",
    });

    const addressData = reactive({
      countryLoading: false,
      countries: [],
      provinceRegion: {
        countryId: null,
        provinceId: null,
        provinceLoading: false,
        provinces: [],
      },
      cityRegion: {
        countryId: null,
        provinceId: null,
        cityId: null,
        provinceLoading: false,
        provinces: [],
        cityLoading: false,
        cities: [],
      },
      countyRegion: {
        countryId: null,
        provinceId: null,
        cityId: null,
        countyId: null,
        provinceLoading: false,
        provinces: [],
        cityLoading: false,
        cities: [],
        countyLoading: false,
        counties: [],
      },
      postRegion: {
        countryId: null,
        postStartWith: null,
      },
    });

    const addressCache = {
      provinces: {},
      cities: {},
      counties: {},
    };

    const calcRegionTypeEnabled = () => {
      if (data.formData.regionTypes) {
        data.formData.regionCountryEnabled =
          data.formData.regionTypes.findIndex(
            (x) => x == regionTypeEnum.country
          ) >= 0;
        data.formData.regionProvinceEnabled =
          data.formData.regionTypes.findIndex(
            (x) => x == regionTypeEnum.province
          ) >= 0;
        data.formData.regionCityEnabled =
          data.formData.regionTypes.findIndex(
            (x) => x == regionTypeEnum.city
          ) >= 0;
        data.formData.regionCountyEnabled =
          data.formData.regionTypes.findIndex(
            (x) => x == regionTypeEnum.county
          ) >= 0;
        data.formData.regionPostEnabled =
          data.formData.regionTypes.findIndex(
            (x) => x == regionTypeEnum.post
          ) >= 0;
      } else {
        data.formData.regionCountryEnabled = false;
        data.formData.regionProvinceEnabled = false;
        data.formData.regionCityEnabled = false;
        data.formData.regionCountyEnabled = false;
        data.formData.regionPostEnabled = false;
      }
    };

    const getRegionTypes = () => {
      let types = [];
      if (data.formData.regionCountryEnabled) {
        types.push(regionTypeEnum.country);
      }
      if (data.formData.regionProvinceEnabled) {
        types.push(regionTypeEnum.province);
      }
      if (data.formData.regionCityEnabled) {
        types.push(regionTypeEnum.city);
      }
      if (data.formData.regionCountyEnabled) {
        types.push(regionTypeEnum.county);
      }
      if (data.formData.regionPostEnabled) {
        types.push(regionTypeEnum.post);
      }
      return types;
    };

    const getLanguageName = (item, other = "") => {
      if (other === "province") {
        return `${getName(item, getters.language)}(${item.abbrCode})`;
      }
      return getName(item, getters.language);
    };

    const getCountyName = (item) => {
      let countyName = getLanguageName(item);
      let cityName = getLanguageName({
        cnName: item.cityCnName,
        enName: item.cityEnName,
      });
      return cityName ? countyName + "-" + cityName : countyName;
    };

    const getRegionInfo = () => {
      data.loading = true;
      getById(regionId)
        .then((res) => {
          data.loading = false;
          let { result } = res;
          data.formData = result;
          calcRegionTypeEnabled();
          getLogisticsInfo();
        })
        .catch(() => {
          data.loading = false;
        });
    };

    const getLogisticsInfo = (needUpdateRegion) => {
      data.loagind = true;
      getLogisticsById(data.formData.logisticsId)
        .then((res) => {
          data.loading = false;
          let { result } = res;
          data.logisticsData.name = result.name;
          data.logisticsData.code = result.code;
          if (needUpdateRegion) {
            data.formData.logisticsId = result.id;
            data.formData.currencyCode = result.currencyCode;
            data.formData.currencySymbol = result.currencySymbol;
          }
        })
        .catch(() => {
          data.loading = false;
        });

      getFeeTemplateList();
      getSurchargeConfigList();
    };

    // const addRegionType = (regionType) => {
    //     data.formData.regionTypes = data.formData.regionTypes || [];
    //     if (data.formData.regionTypes.findIndex(x => x == regionType) == -1) {
    //         data.formData.regionTypes.push(regionType);
    //     }
    // }

    // const removeRegionType = (regionType) => {
    //     if (data.formData.regionTypes) {
    //         data.formData.regionTypes = data.formData.regionTypes.filter(x => x != regionType);
    //     }
    // }

    const getFeeTemplateList = () => {
      data.feeTemplateLoading = true;
      getFeeTemplates(data.formData.logisticsId)
        .then((res) => {
          data.feeTemplateLoading = false;
          data.feeTemplates = res.result;
        })
        .catch(() => {});
    };

    const handleAddSignature = () => {
      if (!data.formData.signatureDtos) {
        data.formData.signatureDtos = [];
      }
      data.formData.signatureDtos.push({
        signatureType: signatureTypeEnum.direct,
        fee: null,
        feeTopLimit: null,
        includeFuelSurcharge: false,
      });
    };

    const handleRemoveSignature = (index) => {
      data.formData.signatureDtos.splice(index, 1);
    };

    const getSurchargeConfigList = () => {
      data.surchargeConfigLoading = true;
      getSurchargeConfigs(data.formData.logisticsId)
        .then((res) => {
          data.surchargeConfigLoading = false;
          data.surchargeConfigs = res.result;
        })
        .catch(() => {});
    };

    const getCountryList = () => {
      addressData.countryLoading = true;
      getCountriesParams()
        .then((res) => {
          addressData.countries = res.result;
        })
        .finally(() => {
          addressData.countryLoading = false;
        });
    };

    const getProvinceList = async (countryId) => {
      if (countryId) {
        var cacheKey = countryId + "";
        if (addressCache.provinces[cacheKey]) {
          return addressCache.provinces[cacheKey];
        } else {
          let { result } = await getProvinces({ countryId: countryId });
          addressCache.provinces[cacheKey] = result;
          return result;
        }
      } else {
        return [];
      }
    };

    const getCityList = async (provinceId) => {
      if (provinceId) {
        var cacheKey = provinceId + "";
        if (addressCache.cities[cacheKey]) {
          return addressCache.cities[cacheKey];
        } else {
          let { result } = await getCitys({ provinceId: provinceId });
          addressCache.cities[cacheKey] = result;
          return result;
        }
      } else {
        return [];
      }
    };

    const getCountyList = async (cityId) => {
      if (cityId) {
        var cacheKey = cityId + "";
        if (addressCache.counties[cacheKey]) {
          return addressCache.counties[cacheKey];
        } else {
          let { result } = await getCountys({ cityId: cityId });
          addressCache.counties[cacheKey] = result;
          return result;
        }
      } else {
        return [];
      }
    };

    //------------Country Region-------------//

    const validRegionCountryRls = () => {
      refForm.value.validate("regionCountryRls");
    };

    const handleCountryTagClose = (item) => {
      if (data.formData.regionCountryRls) {
        data.formData.regionCountryRls = data.formData.regionCountryRls.filter(
          (x) => x.countryId != item.countryId
        );
        validRegionCountryRls();
      }
    };

    const handleCountrySelect = (key) => {
      if (
        key &&
        data.formData.regionCountryRls.findIndex((x) => x.countryId == key) < 0
      ) {
        let country = addressData.countries.find((x) => x.id == key);
        if (country) {
          data.formData.regionCountryRls.push({
            countryId: country.id,
            countryCnName: country.cnName,
            countryEnName: country.enName,
          });
          validRegionCountryRls();
        }
      }
    };

    //------------End Country Region-------------//

    //------------Province Region-------------//

    const validRegionProvinceRls = () => {
      refForm.value.validate("regionProvinceRls");
    };

    const handleCountryChangeForProvinceRegion = () => {
      addressData.provinceRegion.provinceLoading = true;
      getProvinceList(addressData.provinceRegion.countryId)
        .then((res) => {
          addressData.provinceRegion.provinces = res;
        })
        .catch(() => {
          addressData.provinceRegion.provinces = [];
        })
        .finally(() => {
          addressData.provinceRegion.provinceLoading = false;
          if (
            addressData.provinceRegion.provinces &&
            addressData.provinceRegion.provinces.findIndex(
              (x) => x.id == addressData.provinceRegion.provinceId
            ) >= 0
          ) {
            //keep it
          } else {
            addressData.provinceRegion.provinceId = null;
          }
        });
    };

    const handleProvinceSelect = (key) => {
      if (
        key &&
        data.formData.regionProvinceRls.findIndex((x) => x.provinceId == key) <
          0
      ) {
        let item = addressData.provinceRegion.provinces.find(
          (x) => x.id == key
        );
        if (item) {
          let country =
            addressData.countries.find(
              (x) => x.id == addressData.provinceRegion.countryId
            ) || {};
          data.formData.regionProvinceRls.push({
            provinceId: item.id,
            provinceCnName: item.cnName,
            provinceEnName: item.enName,
            countryId: country.id,
            countryCnName: country.cnName,
            countryEnName: country.enName,
          });
          validRegionProvinceRls();
        }
      }
    };

    const handleProvinceTagClose = (item) => {
      if (data.formData.regionProvinceRls) {
        data.formData.regionProvinceRls =
          data.formData.regionProvinceRls.filter(
            (x) => x.provinceId != item.provinceId
          );
        validRegionProvinceRls();
      }
    };
    //------------End Province Region-------------//

    //---------------City Region ---------------//

    const validRegionCityRls = () => {
      refForm.value.validate("regionCityRls");
    };

    const handleProviceChangeForCityRegion = () => {
      addressData.cityRegion.cityLoading = true;
      getCityList(addressData.cityRegion.provinceId)
        .then((res) => {
          addressData.cityRegion.cities = res;
        })
        .catch(() => {
          addressData.cityRegion.cities = [];
        })
        .finally(() => {
          addressData.cityRegion.cityLoading = false;
          if (
            addressData.cityRegion.cities &&
            addressData.cityRegion.cities.findIndex(
              (x) => x.id == addressData.cityRegion.cityId
            ) >= 0
          ) {
            //keep it
          } else {
            addressData.cityRegion.cityId = null;
          }
        });
    };

    const handleCountryChangeForCityRegion = () => {
      addressData.cityRegion.provinceLoading = true;
      getProvinceList(addressData.cityRegion.countryId)
        .then((res) => {
          addressData.cityRegion.provinces = res;
        })
        .catch(() => {
          addressData.cityRegion.provinces = [];
        })
        .finally(() => {
          addressData.cityRegion.provinceLoading = false;
          if (
            addressData.cityRegion.provinces &&
            addressData.cityRegion.provinces.findIndex(
              (x) => x.id == addressData.cityRegion.provinceId
            ) >= 0
          ) {
            //keep it
          } else {
            addressData.cityRegion.provinceId = null;
            handleProviceChangeForCityRegion();
          }
        });
    };

    const handleCitySelect = (key) => {
      if (
        key &&
        data.formData.regionCityRls.findIndex((x) => x.cityId == key) < 0
      ) {
        let item = addressData.cityRegion.cities.find((x) => x.id == key);
        if (item) {
          let country =
            addressData.countries.find(
              (x) => x.id == addressData.cityRegion.countryId
            ) || {};
          data.formData.regionCityRls.push({
            cityId: item.id,
            cityCnName: item.cnName,
            cityEnName: item.enName,
            countryId: country.id,
            countryCnName: country.cnName,
            countryEnName: country.enName,
          });
          validRegionCityRls();
        }
      }
    };

    const handleCityTagClose = (item) => {
      if (data.formData.regionCityRls) {
        data.formData.regionCityRls = data.formData.regionCityRls.filter(
          (x) => x.cityId != item.cityId
        );
        validRegionCityRls();
      }
    };

    //---------------End City Region ---------------//

    //---------------County Region ---------------//

    const validRegionCountyRls = () => {
      refForm.value.validate("regionCountyRls");
    };

    const handleCityChangeForCountyRegion = () => {
      addressData.countyRegion.countyLoading = true;
      getCountyList(addressData.countyRegion.cityId)
        .then((res) => {
          addressData.countyRegion.counties = res;
        })
        .catch(() => {
          addressData.countyRegion.counties = [];
        })
        .finally(() => {
          addressData.countyRegion.countyLoading = false;
          if (
            addressData.countyRegion.counties &&
            addressData.countyRegion.counties.findIndex(
              (x) => x.id == addressData.countyRegion.countyId
            ) >= 0
          ) {
            //keep it
          } else {
            addressData.countyRegion.countyId = null;
          }
        });
    };

    const handleProviceChangeForCountyRegion = () => {
      addressData.countyRegion.cityLoading = true;
      getCityList(addressData.countyRegion.provinceId)
        .then((res) => {
          addressData.countyRegion.cities = res;
        })
        .catch(() => {
          addressData.countyRegion.cities = [];
        })
        .finally(() => {
          addressData.countyRegion.cityLoading = false;
          if (
            addressData.countyRegion.cities &&
            addressData.countyRegion.cities.findIndex(
              (x) => x.id == addressData.countyRegion.cityId
            ) >= 0
          ) {
            //keep it
          } else {
            addressData.countyRegion.cityId = null;
            handleCityChangeForCountyRegion();
          }
        });
    };

    const handleCountryChangeForCountyRegion = () => {
      addressData.countyRegion.provinceLoading = true;
      getProvinceList(addressData.countyRegion.countryId)
        .then((res) => {
          addressData.countyRegion.provinces = res;
        })
        .catch(() => {
          addressData.countyRegion.provinces = [];
        })
        .finally(() => {
          addressData.countyRegion.provinceLoading = false;
          if (
            addressData.countyRegion.provinces &&
            addressData.countyRegion.provinces.findIndex(
              (x) => x.id == addressData.countyRegion.provinceId
            ) >= 0
          ) {
            //keep it
          } else {
            addressData.countyRegion.provinceId = null;
            handleProviceChangeForCountyRegion();
          }
        });
    };

    const handleCountySelect = (key) => {
      if (
        key &&
        data.formData.regionCountyRls.findIndex((x) => x.countyId == key) < 0
      ) {
        let item = addressData.countyRegion.counties.find((x) => x.id == key);
        if (item) {
          let country =
            addressData.countries.find(
              (x) => x.id == addressData.countyRegion.countryId
            ) || {};
          data.formData.regionCountyRls.push({
            countyId: item.id,
            countyCnName: item.cnName,
            countyEnName: item.enName,
            countryId: country.id,
            countryCnName: country.cnName,
            countryEnName: country.enName,
            cityCnName: item.cityCnName,
            cityEnName: item.cityEnName,
            cityId: item.cityId,
          });
          validRegionCountyRls();
        }
      }
    };

    const handleCountyTagClose = (item) => {
      if (data.formData.regionCountyRls) {
        data.formData.regionCountyRls = data.formData.regionCountyRls.filter(
          (x) => x.countyId != item.countyId
        );
        validRegionCountyRls();
      }
    };

    //---------------End County Region ---------------//

    //------------Post Region-------------//

    const validRegionPostCodeRls = () => {
      refForm.value.validate("regionPostCodeRls");
    };

    const handlePostAdd = () => {
      let countryId = addressData.postRegion.countryId;
      let postStartWith = addressData.postRegion.postStartWith;
      if (
        countryId &&
        postStartWith &&
        data.formData.regionPostCodeRls.findIndex(
          (x) => x.countryId == countryId && x.postStartWith == postStartWith
        ) < 0
      ) {
        let country = addressData.countries.find((x) => x.id == countryId);
        if (country) {
          data.formData.regionPostCodeRls.push({
            countryId: country.id,
            countryCnName: country.cnName,
            countryEnName: country.countryEnName,
            postStartWith: postStartWith,
          });
          validRegionPostCodeRls();
        }
        addressData.postRegion.postStartWith = null;
      }
    };

    const handlePostTagClose = (item) => {
      if (data.formData.regionPostCodeRls) {
        data.formData.regionPostCodeRls =
          data.formData.regionPostCodeRls.filter(
            (x) =>
              !(
                x.countryId == item.countryId &&
                x.postStartWith == item.postStartWith
              )
          );
        validRegionPostCodeRls();
      }
    };

    const handlePostTagClear = () => {
      data.formData.regionPostCodeRls = [];
      validRegionPostCodeRls();
    };

    const handlePoseBathAddClick = () => {
      data.bathAddPostText = "";
      data.bathAddPostModalVisible = true;
    };

    const handlePoseBathAddSave = () => {
      let countryId = addressData.postRegion.countryId;
      if (countryId && bathPostArr.value.length > 0) {
        let country = addressData.countries.find((x) => x.id == countryId);
        if (country) {
          let existedArr = data.formData.regionPostCodeRls.filter(
            (x) => x.countryId == countryId
          );
          let items = [];
          bathPostArr.value.forEach((postStartWith) => {
            if (existedArr.length > 0) {
              if (
                existedArr.findIndex((x) => x.postStartWith == postStartWith) <
                0
              ) {
                items.push({
                  countryId: country.id,
                  countryCnName: country.cnName,
                  countryEnName: country.countryEnName,
                  postStartWith: postStartWith,
                });
              }
            } else {
              items.push({
                countryId: country.id,
                countryCnName: country.cnName,
                countryEnName: country.countryEnName,
                postStartWith: postStartWith,
              });
            }
          });
          data.formData.regionPostCodeRls =
            data.formData.regionPostCodeRls.concat(items);
          validRegionPostCodeRls();
        }
      }
      data.bathAddPostText = "";
      data.bathAddPostModalVisible = false;
    };

    const bathPostArr = computed(() => {
      if (data.bathAddPostText) {
        return Array.from(
          new Set(data.bathAddPostText.split(/[ ,;，\r\n]/).filter((x) => !!x))
        );
      }
      return [];
    });
    //------------End Post Region-------------//

    const handleBackToRegions = () => {
      if (data.formData.logisticsId) {
        router.push({
          name: "logistics_region",
          params: { logisticsId: data.formData.logisticsId },
        });
      } else {
        router.push({ name: "logistics_channel" });
      }
      delVisitedRoute(route);
    };

    const handleSaveRegion = () => {
      refForm.value
        .validate()
        .then(async () => {
          data.saving = true;
          var postData = {};
          Object.assign(postData, data.formData);
          //   postData.signFee = roundNumber(postData.signFee || 0);
          //   postData.signFeeToplimit = roundNumber(postData.signFeeToplimit || 0);
          postData.signatureDtos?.forEach(item=>{
            item.fee = roundNumber(item.fee || 0);
            if(item.feeTopLimit != null){
                item.feeTopLimit = roundNumber(item.feeTopLimit || 0);
            }
          });
          postData.ddpFee = roundNumber(postData.ddpFee || 0);
          postData.regionTypes = getRegionTypes();
          return regionId ? update(postData) : create(postData);
        })
        .then(() => {
          data.saving = false;
          //close current
          handleBackToRegions();
        })
        .catch(() => {
          data.saving = false;
        });
    };

    const regionRules = {
      name: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("名称")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      ddpFee: [
        {
          type: "number",
          required: true,
          min: 0,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("费用")]);
          },
          trigger: ["blur", "change"],
        },
      ],
      isSignSupported: [
        {
          type: "boolean",
          validator: async (rule, value) => {
            if (value) {
              if (
                data.formData.signatureDtos == null ||
                data.formData.signatureDtos.length == 0
              ) {
                return Promise.reject();
              }
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("请添加签收费");
          },
          trigger: ["blur", "change"],
        },
      ],
      regionCountryRls: [
        {
          type: "array",
          validator: async (rule, value) => {
            if (data.formData.regionCountryEnabled) {
              if (!value || value.length <= 0) {
                return Promise.reject();
              }
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("common.required");
          },
        },
      ],
      regionProvinceRls: [
        {
          type: "array",
          validator: async (rule, value) => {
            if (data.formData.regionProvinceEnabled) {
              if (!value || value.length <= 0) {
                return Promise.reject();
              }
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("common.required");
          },
        },
      ],
      regionCityRls: [
        {
          type: "array",
          validator: async (rule, value) => {
            if (data.formData.regionCityEnabled) {
              if (!value || value.length <= 0) {
                return Promise.reject();
              }
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("common.required");
          },
        },
      ],
      regionCountyRls: [
        {
          type: "array",
          validator: async (rule, value) => {
            if (data.formData.regionCountyEnabled) {
              if (!value || value.length <= 0) {
                return Promise.reject();
              }
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("common.required");
          },
        },
      ],
      regionPostCodeRls: [
        {
          type: "array",
          validator: async (rule, value) => {
            if (data.formData.regionPostEnabled) {
              if (!value || value.length <= 0) {
                return Promise.reject();
              }
            }
            return Promise.resolve();
          },
          message: () => {
            return vueI18n.t("common.required");
          },
        },
      ],
    };

    const feeRequiredRules = {
      type: "number",
      required: true,
      min: 0,
      message: () => {
        return vueI18n.t("common.p0_is_required", [vueI18n.t("费用")]);
      },
      trigger: ["blur", "change"],
    };

    const signatureTypeRules = {
      type: "number",
      validator: async (rule, value) => {
        if (
          data.formData.signatureDtos &&
          data.formData.signatureDtos.length > 0
        ) {
          let duplicateItems = data.formData.signatureDtos.filter(
            (x) => x.signatureType == value
          );
          if (duplicateItems.length > 1) {
            return Promise.reject();
          }
        }
        return Promise.resolve();
      },
      message: () => {
        return vueI18n.t("签名签收费类型重复");
      },
      trigger: ["blur", "change"],
    };

    watch(
      [
        () => data.formData.isSignSupported,
        () => data.formData.signatureDtos?.length,
      ],
      () => {
        refForm.value?.validate("isSignSupported");
      }
    );

    watch(
      () => data.formData.signatureDtos,
      (newVal) => {
        
        if (
          data.formData.signatureDtos &&
          data.formData.signatureDtos.length > 0
        ) {
          let needValidationArray = data.formData.signatureDtos.map(
            (item, index) => {
              return ["signatureDtos", index, "signatureType"];
            }
          );
          nextTick(() => {
            refForm.value?.validate(needValidationArray);  
          })
        }
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      if (regionId) {
        getRegionInfo();
      } else {
        getLogisticsInfo(true);
      }
      getCountryList();
    });

    return {
      regionTypeEnum,
      signatureTypeEnum,

      refForm,
      regionId,
      data,
      addressData,
      regionRules,
      feeRequiredRules,
      signatureTypeRules,

      handleAddSignature,
      handleRemoveSignature,

      handleCountryTagClose,
      handleCountrySelect,
      validRegionCountryRls,

      handleCountryChangeForProvinceRegion,
      handleProvinceSelect,
      handleProvinceTagClose,
      validRegionProvinceRls,

      handleProviceChangeForCityRegion,
      handleCountryChangeForCityRegion,
      handleCitySelect,
      handleCityTagClose,
      validRegionCityRls,

      handleCityChangeForCountyRegion,
      handleProviceChangeForCountyRegion,
      handleCountryChangeForCountyRegion,
      handleCountySelect,
      handleCountyTagClose,
      validRegionCountyRls,

      handlePostAdd,
      handlePostTagClose,
      handlePoseBathAddClick,
      handlePoseBathAddSave,
      bathPostArr,
      validRegionPostCodeRls,
      handlePostTagClear,

      handleSaveRegion,
      handleBackToRegions,
      getLanguageName,
      getCountyName,
    };
  },
});
</script>
<style lang="less" scoped>
.border-b {
  border-bottom: 1px solid #f0f0f0;
}
.lg-row {
  display: flex;
  flex-wrap: wrap;
  // justify-content: stretch;
  align-items: stretch;
  .content {
    flex-grow: 1;
  }
  .actions {
    flex-grow: 0;
  }
}
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
:deep(.input-group-mid-number) {
  border-radius: 0;
  width: 100%;
}
:deep(.input-group-mid-number-half) {
  border-radius: 0;
  flex: 1;
}
:deep(.input-group-end-number) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
:deep(.input-group-start-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
</style>

<template>
  <Content>
    <template #contentTitle>{{ $t('menu.logistics_fee_template') }}</template>
    <template v-slot:contentBody="wrap">
      <div class="lg-row mb-3">
        <div class="content">
          <div class="d-inline-block">
            <span class="d-inline-block mr-2">{{  $t('logistics.logistics_scheme_name')  }}:</span>
            <b class="d-inline-block mr-2"
               v-if="tableData.logisticsData.code">{{ tableData.logisticsData.code }} ({{ tableData.logisticsData.name }})</b>
            <span v-else>-</span>
          </div>
        </div>
        <div class="actions">
          <a-button size="small">
            <router-link :to="{ name: 'logistics_fee_config', params: { logisticsId: logisticsId } }">
              <span>+</span>
              <small>{{ $t('common.addition') }}</small>
            </router-link>
          </a-button>
        </div>
      </div>
      <div>
        <a-input v-model:value="tableData.searchData.searchKey"
                 allow-clear
                 class="mr-2 mb-2"
                 style="max-width:200px"
                 :placeholder="$t('warehouse.name')"
                 @pressEnter="handleSearch"></a-input>
        <a-button type="primary"
                  class="mr-2 mb-2"
                  :loading="tableData.loading"
                  @click="handleSearch">{{ $t("common.query") }}</a-button>
      </div>
      <div>
        <a-table :columns="columns"
                 size="small"
                 :data-source="tableData.tableList"
                 :pagination="false"
                 :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
                 :rowKey="(record, index) => { return index; }"
                 :loading="tableData.loading">
          <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
          <template #creationTime="{ record }">
            <span>{{ $filters.utcToCurrentTime(record.creationTime) }}</span>
          </template>
          <template #lastModificationTime="{ record }">
            <span>{{ $filters.utcToCurrentTime(record.lastModificationTime) }}</span>
          </template>
          <template v-slot:operation="{ record }">
            <a-button type="link"
                      size="small">
              <router-link :to="{ name: 'logistics_fee_config', params: { logisticsId: record.logisticsId, id: record.id } }">
                <small>{{ $t("logistics.config") }}</small>
              </router-link>
            </a-button>
            <a-popconfirm :title="$t('common.are_you_sure_you_want_to_delete_this_item')"
                          @confirm="handleDeleteSurchargeConfig(record.id)">
              <a-button type="link"
                        size="small"
                        class="text-error">
                <small>{{$t("common.delete") }}</small>
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data="tableData.pageData"></CPager>
    </template>
  </Content>
</template>
<script>
import { Table, Button, Row, Col, Input, Popconfirm } from 'ant-design-vue'
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue'
import { useRoute } from "vue-router"
import { defineComponent, reactive, onActivated, } from 'vue'
import { getById as getLogisticsById } from '../../api/modules/logistics/index'
import { getFeeTemplates, deleteById } from '../../api/modules/logistics/feetemplate'
import { useI18n } from 'vue-i18n/index';

export default defineComponent({
  name: "logistics_fee_template",
  components: {
    ATable: Table,
    AButton: Button,
    CPager,
    Content,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    APopconfirm: Popconfirm,
  },
  setup () {
    const vueI18n = useI18n({ useScope: 'global' });
    const route = useRoute();
    const logisticsId = route.params.logisticsId;
    const tableData = reactive({
      loading: false,
      tableList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      logisticsData: {
        name: null,
        code: null
      },
      searchData: {
        searchKey: null,
      }
    });
    const columns = [
      {
        width: 80,
        fixed: "left",
        title: () => vueI18n.t('common.serial_number'),
        slots: {
          customRender: "tableIndex"
        }
      },
      {
        dataIndex: "name",
        width: 200,
        fixed: "left",
        title: () => vueI18n.t('logistics.template_name'),
      },
      {
        dataIndex: "creationTime",
        width: 200,
        title: () => vueI18n.t('warehouse.creation_time'),
        slots: {
          customRender: "creationTime"
        }
      },
      {
        dataIndex: "lastModificationTime",
        width: 200,
        title: () => vueI18n.t('logistics.last_modification_time'),
        slots: {
          customRender: "lastModificationTime"
        }
      },
      {
        align: "center",
        width: 150,
        slots: {
          customRender: "operation"
        }
      },

    ];


    const getLogisticsInfo = () => {
      tableData.loaging = true;
      getLogisticsById(logisticsId)
        .then((res) => {
          tableData.loading = false;
          let { result } = res;
          tableData.logisticsData.name = result.name;
          tableData.logisticsData.code = result.code;
        })
        .catch(() => {
          tableData.loading = false;
        });
    }

    const funcGetFeeTemplates = () => {
      tableData.loading = true;
      getFeeTemplates({
        logisticsId: logisticsId,
        searchKey: tableData.searchData.searchKey,
        skipCount: tableData.pageData.skipCount,
        maxResultCount: tableData.pageData.maxResultCount,
      })
        .then((res) => {
          let { totalCount, items } = res.result;
          tableData.pageData.totalCount = parseInt(totalCount);
          tableData.tableList = items;
          tableData.loading = false;
        })
        .catch(() => {
          tableData.loading = false;
        })
    }

    const handlePage = (pageData) => {
      tableData.pageData.skipCount = pageData.skipCount;
      tableData.pageData.maxResultCount = pageData.maxResultCount;
      funcGetFeeTemplates();
    };

    const handleSearch = () => {
      tableData.pageData.currentIndex = 1;
      tableData.pageData.skipCount = 0;
      funcGetFeeTemplates();
    }

    const handleDeleteSurchargeConfig = (id) => {
      let ids = [id];
      deleteById(ids)
        .then(() => {
          funcGetFeeTemplates();
        })
    }


    onActivated(() => {
      getLogisticsInfo();
      funcGetFeeTemplates();
    })

    return {
      logisticsId,
      columns,
      tableData,

      handlePage,
      handleSearch,
      handleDeleteSurchargeConfig,
    }
  }
})
</script>
<style lang="less" scoped>
.border-b {
  border-bottom: 1px solid #f0f0f0;
}
.lg-row {
  display: flex;
  flex-wrap: wrap;
  // justify-content: stretch;
  align-items: stretch;
  .content {
    flex-grow: 1;
  }
  .actions {
    flex-grow: 0;
  }
}
</style>
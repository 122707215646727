import client from "../../client";

const Api = {
  // 配送订单产品统计
  getAgentOrderSummary: "/api/WarehouseService/AgentOrderConsole/GetSummary",
  // 调拨计划产品统计
  getTransferToWarehousePlanSummary: "/api/WarehouseService/TransferToWarehousePlanConsole/GetSummary",
  // 入库计划取消箱
  inPlaneBoxCancel: "/api/WarehouseService/WarehouseTaskMonitoring/UpdateCancelInPlanForDetail",
};

// 配送订单产品统计
export const getAgentOrderSummary = (data) => {
  return client.request({
    url: Api.getAgentOrderSummary,
    data,
    method: "POST",
  });
};

// 调拨计划产品统计
export const getTransferToWarehousePlanSummary = (data) => {
  return client.request({
    url: Api.getTransferToWarehousePlanSummary,
    data,
    method: "POST",
  });
};

// 入库计划取消箱
export const inPlaneBoxCancel = (data) => {
  return client.request({
    url: Api.inPlaneBoxCancel,
    data,
    method: "POST",
  });
};
<template>
  <a-modal
    width="700px"
    :title="$t('devops.mail_template')"
    v-model:visible="state.visible"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-spin :spinning="state.loading">
      <a-form
        ref="formRef"
        layout="vertical"
        :model="state.formState"
        :rules="rules"
      >
        <a-form-item :label="$t('logistics.template_name')" name="templateName">
          <a-input v-model:value="state.formState.templateName" />
        </a-form-item>
        <a-form-item :label="$t('devops.notification_business_type')" name="notificationBusinessType">
          <a-select
            v-model:value="state.formState.notificationBusinessType"
            :disabled="!!props.type"
          >
            <a-select-option
              v-for="(item, index) in notificationBusinessTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('notificationBusinessType', item))"
            >{{ $t($enumLangkey('notificationBusinessType', item)) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('devops.mail_template_subject')" name="templateSubject">
          <a-input v-model:value="state.formState.templateSubject" />
          <div class="mt-2">
            <AttributeList :type="state.formState.notificationBusinessType" @confirm="handleSetSubjectAttribute" />
          </div>
        </a-form-item>
        <a-form-item :label="$t('devops.template_content')" name="templateContent">
          <QuillEditor
            ref="quillEditorRef"
            style="min-height: 200px;"
            content-type="html"
            v-model:content="state.formState.templateContent"
            :options="editorOptions"
            @update:content="handleValidate('content')"
          />
          <div class="mt-2">
            <AttributeList :type="state.formState.notificationBusinessType" @confirm="handleSetContentAttribute" />
          </div>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, message, Modal, Select, Spin } from 'ant-design-vue';
import AttributeList from './AttributeList.vue';
import { QuillEditor, Quill } from '@vueup/vue-quill';
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useI18n } from "vue-i18n/index";
import { setFormStateValue } from '@/utils/general.js';
import {
  getShopMailNotificationTemplateById,
  createOrUpdateShopMailNotificationTemplate,
} from '@/api/modules/devops/notice.js';
import { notificationBusinessType as notificationBusinessTypeEnum } from '@/enum/enum.json';

Quill.register('modules/blotFormatter', BlotFormatter);

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AttributeList,
    QuillEditor,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: Number,
    id: String,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);
    const quillEditorRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        id: null,
        templateName: null,
        notificationBusinessType: null,
        templateSubject: '',
        templateContent: '',
      },
    })

    const editorOptions = reactive({
      modules: {
        blotFormatter: {
          toolbar: {
            mainClassName: 'blot-formatter__toolbar'
          }
        },
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'font': [] }],
            ['clean'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'header': 1 }, { 'header': 2 }],
            ['link'],
            [{ 'direction': 'rtl' }],
            [{ 'color': [] }],
            [{ 'background': [] }]
          ],
        }
      },
      theme: 'snow',
    })

    const rules = {
      templateName: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.template_name')]),
      },
      notificationBusinessType: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.notification_business_type')]),
      },
      templateSubject: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.mail_template_subject')]),
      },
      templateContent: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.template_content')]),
      },
    }

    const handleSetSubjectAttribute = (item) => {
      state.formState.templateSubject += ('${' + item.attributeCode + '}');
      handleValidate('templateSubject');
    }

    const handleSetContentAttribute = (item) => {
      state.formState.templateContent += ('${' + item.attributeCode + '}');
      handleValidate('templateContent');
    }

    const handleValidate = (name) => {
      nextTick(() => {
        formRef.value.validate([name]);
      });
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        await createOrUpdateShopMailNotificationTemplate(data);
        message.success(vueI18n.t('common.succeed'));
        emit('confirm');
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    const init = async (id) => {
      try {
        state.loading = true;
        if (id) {
          let { result } = await getShopMailNotificationTemplateById({ id });
          if (result) {
            setFormStateValue(state.formState, result);
          }
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    watch(() => [props.visible, props.type, props.id], ([visible, type, id]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            formRef.value.resetFields();
            state.formState.id = id || null;
            state.formState.notificationBusinessType = type || null;
            quillEditorRef.value.setHTML('');
            init(id);
          });
        }
      }
    }, { immediate: true })

    return {
      state,
      notificationBusinessTypeEnum,
      props,
      formRef,
      quillEditorRef,
      editorOptions,
      rules,
      handleSetSubjectAttribute,
      handleSetContentAttribute,
      handleValidate,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.partner_group_manage') }}
    </template>
    <!-- 下拉按钮 and table -->
    <template v-slot:contentBody='wrap'>
      <div class="mb-3">
        <a-button @click="hanldeShowModal">{{ $t('finance.create_group') }}</a-button>
      </div>
      <div>
        <!-- 表格 -->
        <a-table :columns="columns"
                 :data-source="data.list"
                 :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
                 :pagination="false"
                 :rowKey="
          (record, id) => {
            return id;
          }
        "
                 size="small"
                 :loading="data.loading">

          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #groupName="{record}">
            <div class="editable-cell">
              <div
                v-if="editableData[record.key]"
                class="editable-cell-input-wrapper"
              >
                <a-input
                  v-model:value="editableData[record.key].groupName"
                  @pressEnter="handleUpdate(record.key, record)"
                  allowClear
                />
                <check-outlined
                  class="editable-cell-icon-check"
                  @click="handleUpdate(record.key, record)"
                />
              </div>
              <div v-else class="editable-cell-text-wrapper">
                {{ record.groupName || " " }}
                <edit-outlined
                  class="editable-cell-icon"
                  @click="handleShowEdit(record.key, record)"
                />
              </div>
            </div>
          </template>
          <template #operate="{ record }">
            <router-link :to="{name:'partner_group_set', params:{id: record.id}}">
              <a-button>{{$t('menu.partner_group_set')}}</a-button>
            </router-link>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="modal.visible"
               :title="$t('finance.create_group')"
               :confirmLoading="modal.loading"
               :centered="true"
               :maskClosable="false"
               :footer="false">
        <a-form ref="refForm"
                :model="modal"
                :rules="rules"
                :label-col="{
        style: { width: '120px' },
      }">
          <a-form-item name="groupName"
                       :label="$t('warehouse.name')">
            <a-input allow-clear
                     v-model:value="modal.groupName"></a-input>
          </a-form-item>

        </a-form>
        <a-row type="flex"
               justify="center"
               align="middle"
               class="mt-3">
          <a-col>
            <a-button type="primary"
                      :loading="modal.loading"
                      @click="handleAddGroup">{{$t('common.confirm')}}</a-button>
          </a-col>
          <a-col>
            <a-button class="ml-5"
                      :loading="modal.loading"
                      @click="modal.visible=false">{{$t('common.cancel')}}</a-button>
          </a-col>
        </a-row>
      </a-modal>

    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data='data.pageData'> </CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, ref } from 'vue';
import {
  Table,
  Button,
  Select,
  Row,
  Col, Space,
  Input, Form, Modal, message
} from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue';
import {
  createPartnerGroup, queryPartnerGroup, editPartnerGroup
} from '../../api/modules/partner/groupManage';
import {
  containerType as containerTypeEnum
} from '../../enum/enum.json'
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "partner_group_manage",
  components: {
    ATable: Table,
    AButton: Button,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpace: Space,
    Content,
    CPager,
  },

  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const refForm = ref();

    const columns = [
      {
        title: () => vueI18n.t('common.serial_number'),
        slots: {
          customRender: 'serialNumber'
        },
        width: 80,
      },
      {
        title: () => vueI18n.t('finance.group_name'),
        width: 250,
        slots: {
          customRender: 'groupName',
        },
      },
      {
        slots: {
          customRender: 'operate',
        },
      },
    ];

    const data = reactive({
      list: [],
      status: null,
      searchKey: null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      loading: false,
    });

    const modal = reactive({
      loading: false,
      visible: false,
      groupName: null,
    });

    const editableData = reactive({});

    const handleShowEdit = (key, record) => {
      editableData[key] = Object.assign({}, record);
    };

    const handleUpdate = (key, record) => {
      const data = {
        id: editableData[key].id,
        groupName: editableData[key].groupName,
      };
      if (!data.groupName) {
        message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("finance.group_name")])
        );
        return;
      }
      editPartnerGroup(data).then(() => {
        Object.assign(record, editableData[key]);
        delete editableData[key];
      });
    };

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      getTable();
    }

    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      getTable()
    }

    const getTable = async () => {
      data.loading = true;
      let params = Object.assign({}, data.pageData)
      queryPartnerGroup(params).then(({ result }) => {
        if (result) {
          let { items = [], totalCount = 0 } = result;
          data.pageData.totalCount = parseInt(totalCount);
          data.list = items.map((item, index) => {
            item.key = String(index)
            return item
          });
        } else {
          data.pageData.totalCount = 0;
          data.list = [];
        }
      }).finally(() => {
        data.loading = false;
      })
    }

    const hanldeShowModal = () => {
      modal.visible = true;
      modal.name = null;
    }

    const handleAddGroup = async () => {
      try {
        await refForm.value.validate()
        modal.loading = true;
        await createPartnerGroup({ groupName: modal.groupName })
        message.success(vueI18n.t('common.succeed'));
        modal.visible = false
        handleSearch()
      } catch (error) {
        
      } finally {
        modal.loading = false;
      }
    }

    const rules = {
      groupName: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.name")])
        }
      }],
    };

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      data,
      modal,
      editableData,
      rules,
      refForm,
      containerTypeEnum,
      handlePage,
      handleSearch,
      hanldeShowModal,
      handleAddGroup,
      handleShowEdit,
      handleUpdate,
    };
  },
});
</script>
<style lang="less" scoped>
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 32px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #2db7f5;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>

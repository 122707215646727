import client from "../../../client";

const Api = {
  // 账户余额
  getBalanceList: "/api/ProductService/Balance/GetList",
  // 交易详细记录
  getManagerListPaged: "/api/ProductService/BalanceLog/GetManagerListPaged",
};

export const getBalanceList = (data) => {
  return client.request({
    url: Api.getBalanceList,
    data,
    method: "POST",
  });
};

export const getManagerListPaged = (data) => {
  return client.request({
    url: Api.getManagerListPaged,
    data,
    method: "POST",
  });
};

<template>
  <a-result>
    <template #icon>
      <smile-twoTone />
    </template>
    <template #title> {{ greeting }}, {{ $t('common.welcome') }} {{ (userInfo?.userName || userInfo?.employeeNo) ?? "" }} </template>
  </a-result>
</template>

<script>
import { computed, defineComponent, onActivated, onMounted, ref } from 'vue';
import { Result } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";

export default defineComponent({
  name: "dashboard_index",
  components: {
    AResult: Result,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { dispatch, getters } = useStore();

    const userInfo = ref(null);

    const greeting = computed(() => {
      const hours = new Date().getHours();
      if (hours >= 5 && hours < 12) {
        return vueI18n.t("common.good_morning");
      } else if (hours >= 12 && hours < 18) {
        return vueI18n.t("common.good_afternoon");
      } else {
        return vueI18n.t("common.good_evening");
      }
    });

    onActivated(() => {
      userInfo.value = getters.userInfo;
    })

    onMounted(async () => {
      try {
        userInfo.value = await dispatch("user/reloadUserInfo");
      } catch (error) {
      }
    })

    return {
      userInfo,
      greeting,
    }
  }
})
</script>

<style scoped>

</style>
export default {
  projectName: "SALEASY",
  pageTitleAdditional: "-console.saleasy.com",
  appId:"1116580235832332288",
  keepAlive: true,
  baseURL:process.env.VUE_APP_APIURL,
  //baseURL: process.env.NODE_ENV === 'production' ? "http://cwsapi.promotiongo.cn/":"http://192.168.1.125:5001/",
  //baseURL: "http://192.168.1.125:5001/",
  //baseURL:"http://localhost:8080",
  //baseURL: "http://cwsapi.promotiongo.cn/",

  /* ******APP SETTING****** */
  theme: "theme-dark",
  layout: "layout-head",
  // theme:"theme-light",
  // layout:"layout-inline",
  collapsed: false,
  tab: true,
  // keepAlive:true,
  fiexdHeader: false,
  routerAnimate: "fadeTop",
  // defaultLanguage:"zh-CN"
  defaultLanguage: "en-US",
  /* ****END APP SETTING**** */
};

import client from "../../client"

const Api = {
  // 获取分组佣金列表
  getAllGroupCommission: "/api/SupplierService/PartnerGroupCommission/GetAllGroupCommission",
  // 修改佣金
  createOrUpdateGroupCommission: "/api/SupplierService/PartnerGroupCommission/CreateOrUpdateGroupCommission",
  // 设置启用/弃用
  editPartnerGroupCommissionActive: "/api/SupplierService/PartnerGroupCommission/EditActive",
}

export const getAllGroupCommission = (data) => {
  return client.request({
    url: Api.getAllGroupCommission,
    data: data,
    method: 'POST'
  })
}

export const createOrUpdateGroupCommission = (data) => {
  return client.request({
    url: Api.createOrUpdateGroupCommission,
    data: data,
    method: 'POST'
  })
}

export const editPartnerGroupCommissionActive = (data) => {
  return client.request({
    url: Api.editPartnerGroupCommissionActive,
    data: data,
    method: 'POST'
  })
}
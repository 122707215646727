<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.dev_abbr_code_convert') }}
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input-group style="width: 250px;" compact>
                <a-select
                  style="width: 40%;"
                  v-model:value="searchState.countryId"
                  :loading="countryLoading"
                  :placeholder="$t('common.country')"
                  allow-clear
                  @change="handleCountryChange"
                >
                  <a-select-option
                    v-for="(item, index) in countryList"
                    :key="index"
                    :value="item.id"
                    :title="getLanguageName(item)"
                  >{{ getLanguageName(item) }}</a-select-option>
                </a-select>
                <a-select
                  style="width: 60%;"
                  v-model:value="searchState.provinceId"
                  :loading="provinceLoading"
                  :placeholder="$t('common.province')"
                  :disabled="searchState.countryId === null"
                  allow-clear
                >
                  <a-select-option
                    v-for="(item, index) in provinceList"
                    :key="index"
                    :value="item.id"
                    :title="getLanguageName(item, 'province')"
                  >{{ getLanguageName(item, 'province') }}</a-select-option>
                </a-select>
              </a-input-group>
            </a-col>
            <a-col>
              <a-input style="width: 250px" v-model:value="searchState.targetAbbrCode" :placeholder="$t('devops.target_province_code')" allow-clear></a-input>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchState.openApiCode"
                :placeholder="$t('devops.api_code')"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in openApiCodeEnum"
                  :key="index"
                  :title="$t($enumLangkey('openApiCode', item))"
                >{{ $t($enumLangkey('openApiCode', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleSearch">{{ $t('common.search') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="ghost" @click="handleOpenEditModal()">{{ $t('common.add') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
        :pagination="false"
        :rowKey="(record, index) => index"
        :data-source="tableData.tableList"
        :loading="tableData.loading"
      >
        <template #openApiCode="{ record }">
          {{ $t($enumLangkey('openApiCode', record.openApiCode)) }}
        </template>
        <template #operate="{ record }">
          <a-dropdown-button @click="handleOpenEditModal(record)">
            {{ $t('common.edit') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1" :record="record">{{ $t('common.delete') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleDeleteItem"
      />
      <EditModal
        ref="editModalRef"
        :country-list="countryList"
        :province-cache="provinceCache"
        @refresh="handleInitSearch"
      />
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { Button, Col, Dropdown, Input, Menu, Row, Select, Table, message } from 'ant-design-vue'
import Content from '@/views/components/Content.vue'
import CPager from '@/views/components/CPager.vue'
import CPinTipsModal from '@/views/components/CPinTipsModal.vue'
import EditModal from './components/EditModal.vue'
import { openApiCode as openApiCodeEnum } from '@/enum/enum.json'
import { useI18n } from "vue-i18n/index"
import { useStore } from 'vuex'
import { getShipmentProvinceExchangeConfigPagedList, deleteShipmentProvinceExchangeConfig } from '@/api/modules/devops/abbrCodeConvert.js'
import { getCountrys, getProvinces } from '@/api/modules/common/index.js'
import { getName } from '@/utils/general.js'

export default defineComponent({
  name: "dev_abbr_code_convert",
  components: {
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    AInputGroup: Input.Group,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CPinTipsModal,
    EditModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" })
    const { getters } = useStore()

    const editModalRef = ref(null)

    const state = reactive({
      countryLoading: false,
      countryList: [],
      provinceLoading: false,
      provinceList: [],
      searchState: {
        countryId: null,
        provinceId: null,
        targetAbbrCode: null,
        openApiCode: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      pinModalState: {
        visible: false,
        loading: false,
        dataTemp: null,
      }
    })

    const columns = [
      {
        dataIndex: 'ios2',
        width: 150,
        title: 'ISO2',
      },
      {
        dataIndex: 'sourceAbbrCode',
        width: 150,
        title: () => vueI18n.t('devops.original_province_code'),
      },
      {
        dataIndex: 'targetAbbrCode',
        width: 150,
        title: () => vueI18n.t('devops.target_province_code'),
      },
      {
        width: 150,
        title: () => vueI18n.t('devops.api_code'),
        slots: {
          customRender: 'openApiCode'
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: 'operate'
        }
      },
    ]

    const provinceCache = {}

    const getLanguageName = (item, key) => {
      if (key === 'province') {
        return `${getName(item, getters.language)}(${item.abbrCode})`
      }
      return getName(item, getters.language)
    }

    const handleOpenEditModal = (record = null) => {
      editModalRef.value.open(record)
    }

    const handleDeleteItem = (pinCode) => {
      const data = {
        pinCode,
        id: state.pinModalState.dataTemp
      }
      state.pinModalState.loading = true
      deleteShipmentProvinceExchangeConfig(data).then(() => {
        message.success(vueI18n.t("common.succeed"))
        state.pinModalState.visible = false
        handleInitSearch()
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          state.pinModalState.dataTemp = record.id
          state.pinModalState.visible = true
          break;
        default:
          break;
      }
    }

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList()
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    }

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState)
      handleInitSearch()
    }

		const getPageList = () => {
			state.tableData.loading = true
      const data = Object.assign({}, state.searchStateCache, state.tableData.pageData)
			getShipmentProvinceExchangeConfigPagedList(data).then(({ result }) => {
				if (result) {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
				} else {
					state.tableData.tableList = []
				}
			}).catch(() => { }).finally(() => {
				state.tableData.loading = false
			})
		}

    const getCountryList = () => {
      state.countryLoading = true
      getCountrys().then(({ result }) => {
        if (Array.isArray(result)) {
          state.countryList = result
        } else {
          state.countryList = []
        }
      }).catch(() => {}).finally(() => {
        state.countryLoading = false
      })
    }

    const getProvinceList = () => {
      let countryId = state.searchState.countryId
      if (countryId in provinceCache) {
        state.provinceList = provinceCache[countryId]
      } else {
        state.provinceLoading = true
        getProvinces({ countryId }).then(({ result }) => {
          if (Array.isArray(result)) {
            state.provinceList = result
            provinceCache[countryId] = result
          } else {
            state.provinceList = []
          }
        }).catch(() => {}).finally(() => {
          state.provinceLoading = false
        })
      }
    }

    const handleCountryChange = () => {
      state.searchState.provinceId = null
      getProvinceList()
    }

    onMounted(() => {
      handleInitSearch()
      getCountryList()
    })

    return {
      ...toRefs(state),
      openApiCodeEnum,
      editModalRef,
      columns,
      provinceCache,
      getLanguageName,
      handleOpenEditModal,
      handleDeleteItem,
      handleMenuClick,
      handleInitSearch,
      handleSearch,
      handlePage,
      handleCountryChange,
    }
  }
})
</script>

<style scoped>

</style>
import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  // 配送订单运单列表
  getWaybillList: "/api/WarehouseService/AgentOrderConsole/GetWaybillList",
  // 导出配送订单运单列表
  exportAgentOrderWayBill: "/api/WarehouseService/AgentOrderConsole/ExportAgentOrderWayBill",
  // 下载对账模板
  getAccountRecordTemplate: "/api/WarehouseService/AgentOrderConsole/GetAccountRecordTemplate",
  // 面单对账导入
  parseExcelDataForWaybill: "/api/WarehouseService/WarehouseTaskMonitoring/ParseExcelData",
  // 确认面单对账导入
  createAccountRecordForWaybill: "/api/WarehouseService/WarehouseTaskMonitoring/CreateAccountRecord",
  // 运单对账导入
  parseExcelDataForAgentOrder: "/api/WarehouseService/AgentOrderConsole/ParseExcelData",
  // 确认运单对账导入
  createAccountRecordForAgentOrder: "/api/WarehouseService/AgentOrderConsole/CreateAccountRecord",
  // 取消对账
  cancelReconciliation: "/api/WarehouseService/AgentOrderConsole/CancelReconciliation",
  // 面单获取对账详细
  getAccountRecordDetail: "/api/WarehouseService/AgentOrderConsole/GetAccountRecordDetail",
};

export const getWaybillList = (data) => {
  return client.request({
    url: Api.getWaybillList,
    data,
    method: "POST",
  });
}

export const exportAgentOrderWayBill = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.exportAgentOrderWayBill);
  return url;
}

export const getAccountRecordTemplate = () => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getAccountRecordTemplate);
  return url;
}

export const parseExcelDataForWaybill = (data) => {
  return client.request({
    url: Api.parseExcelDataForWaybill,
    data,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export const createAccountRecordForWaybill = (data) => {
  return client.request({
    url: Api.createAccountRecordForWaybill,
    data,
    method: "POST",
  });
}

export const parseExcelDataForAgentOrder = (data) => {
  return client.request({
    url: Api.parseExcelDataForAgentOrder,
    data,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export const createAccountRecordForAgentOrder = (data) => {
  return client.request({
    url: Api.createAccountRecordForAgentOrder,
    data,
    method: "POST",
  });
}

export const cancelReconciliation = (data) => {
  return client.request({
    url: Api.cancelReconciliation,
    data,
    method: "POST",
  });
}

export const getAccountRecordDetail = (params) => {
  return client.request({
    url: Api.getAccountRecordDetail,
    params,
    method: "GET",
  });
}
<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.customer_company_discount_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row type="flex"
             justify="space-between"
             align="bottom">
        <a-col>
          <span>{{$t("warehouse.company")}}: {{state.shopName}}</span>
        </a-col>
        <a-col :span="3"
               v-if="state.shopId">
          <router-link :to="{
            name: 'customer_company_discount_details',
            params: { 'shopId': state.shopId }
            }">
            <a-button>{{$t("common.addition")}}</a-button>
          </router-link>
        </a-col>
      </a-row>
      <div class="inventory-table mt-2">
        <a-table :columns="columns"
                 :data-source="state.tableList"
                 :scroll="{ x:true,y: wrap.contentHeight - 80}"
                 :pagination="false"
                 size="small"
                 :rowKey="(record, index) => {return index;}"
                 :loading="state.listLoading">

          <template #warehouse="{ record }">
            <div>{{record.warehouseNo}}
              <span v-if="record.warehouseName">({{record.warehouseName}})</span>
            </div>
          </template>
          <template #creation_time="{ record }">
            <div>{{$filters.utcToCurrentTime(record.creationTime) }}</div>
          </template>
          <template #lastModificationTime="{ record }">
            {{$filters.utcToCurrentTime(record.lastModificationTime) }}
          </template>

          <template #operat="{ record }">
            <a-row>
              <a-col>
                <a-popconfirm :title="$t('common.are_you_sure_delete')"
                              :ok-text="$t('common.confirm')"
                              :cancel-text="$t('common.cancel')"
                              @confirm="handleDelete(record)">
                  <a-button type="link"
                            :loading="record.deleteLoading">{{$t('common.delete')}}</a-button>
                </a-popconfirm>
              </a-col>
              <a-col>
                <router-link :to="{
                  name: 'customer_company_discount_details',
                   params: { 
                    'shopId': state.shopId,
                    'dataId': record.id,
                   }
                   }">
                  <a-button type="link">{{$t('common.edit')}}</a-button>
                </router-link>
              </a-col>
            </a-row>

          </template>

        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="state.pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { reactive, onActivated, } from "vue";
import {
  Row, Col, message, Popconfirm,
  Table, Button,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import { getList, getShopInfo, deleteDiscount } from "../../../api/modules/customer/companyDiscount/index";
import { useRoute } from 'vue-router';

export default ({
  name: "customer_company_discount_list",
  components: {
    Content,
    CPager,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    APopconfirm: Popconfirm,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();

    const columns = [
      {
        title: () => vueI18n.t('warehouse.name'),
        dataIndex: "name",
        width: 100,
      },
      {
        title: () => vueI18n.t('warehouse.warehouse'),
        slots: {
          customRender: "warehouse"
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('warehouse.creation_time'),
        slots: {
          customRender: "creation_time"
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('logistics.last_modification_time'),
        slots: {
          customRender: "lastModificationTime"
        },
        width: 100,
      },
      {
        dataIndex: "operat",
        fixed: 'right',
        slots: {
          customRender: "operat"
        },
        width: 100,
      },
    ];

    const state = reactive({
      shopId: null,
      shopName: null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      listLoading: false,
      tableList: [],
    });

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    }

    const handleRefresh = () => {
      state.pageData.skipCount = 0;
      handleSearch();
      funcGetShopInfo();
    }

    const handleSearch = async () => {
      let searchData = {
        ...state.pageData,
        shopId: state.shopId,
      };

      state.listLoading = true;
      getList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            state.tableList = items.map(item => {
              item.loading = false
              return item
            });
            state.pageData.totalCount = parseInt(totalCount);
          } else {
            state.pageData.totalCount = 0;
            state.tableList = [];
          }
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        });
    }

    const handleDelete = (record) => {
      record.deleteLoading = true;
      deleteDiscount([record.id]).then(() => {
        message.success(vueI18n.t("common.succeed_delete"));
        handleSearch();
        record.deleteLoading = false;
      }).catch(() => {
        record.deleteLoading = false;
      })
    }

    const funcGetShopInfo = () => {
      getShopInfo(state.shopId)
        .then((res) => {
          state.shopName = res.result.shopName ?? '';
        })
    }

    onActivated(async () => {
      state.shopId = route.params.id;
      if (state.shopId) {
        funcGetShopInfo();
        handleSearch();
      }
    })

    return {
      columns,
      state,

      handlePage,

      handleSearch,

      handleRefresh,
      handleDelete
    };
  }
})
</script>

<style lang="less" scoped>
</style>
<template>
  <a-modal
    :visible="visible"
    :confirmLoading="btnLoading"
    :title="type == 1 ? '编辑业务负责人' : '编辑客服'"
    @ok="handleOk"
    @cancel="close"
  >
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item :label="type == 1 ? '业务负责人' : '客服'">
        <a-select
          v-model:value="employeeId"
          show-search
          placeholder="请输入员工名称、编号或邮箱"
          style="width: 100%"
          :filter-option="false"
          :not-found-content="loading ? undefined : null"
          :options="list"
          @search="handleSearch"
        >
          <template v-if="loading" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { Modal, Form, Select, message, Spin } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import {
  getEmployeeList,
  updateBusinessLeader,
  updateSupportManager,
} from "@/api/modules/customer/company/list.js";
import { debounce, getNameByLanguage } from "@/utils/general";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpin: Spin,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      //type 1:修改业务负责人 2:修改客服负责人
      type: Number,
      default: 1,
    },
    shopId: String, //店铺Id
    employeeId: String, //之前的id
  },
  emits: ["update:visible", "ok"],
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const state = reactive({
      loading: false,
      btnLoading: false,
      employeeId: null,
      list: [],
    });

    watch(
      () => props.visible,
      (value) => {
        if (value) {
          if (props.employeeId) {
            funcGetShopInfo("", props.employeeId);
            state.employeeId = props.employeeId;
          } else {
            state.employeeId = null;
            state.list = [];
          }
        }
      }
    );

    const handleOk = async () => {
      if (props.type == 1) {
        funcUpdateBusinessLeader();
      } else if (props.type == 2) {
        funcUpdateCustomerServiceManager();
      }
    };

    const funcUpdateBusinessLeader = () => {
      state.btnLoading = true;
      updateBusinessLeader({
        id: props.shopId,
        businessPersonLiableId: state.employeeId,
      })
        .then(() => {
          state.btnLoading = false;
          message.success(vueI18n.t("common.succeed"));
          emit("ok");
          close();
        })
        .catch(() => {
          state.btnLoading = false;
        });
    };

    const funcUpdateCustomerServiceManager = () => {
      state.btnLoading = true;
      updateSupportManager({
        id: props.shopId,
        customerServiceStaffId: state.employeeId,
      })
        .then(() => {
          state.btnLoading = false;
          message.success(vueI18n.t("common.succeed"));
          emit("ok");
          close();
        })
        .catch(() => {
          state.btnLoading = false;
        });
    };

    const getFullName = (user) => {
      if (!user) {
        return "";
      }
      let userName = getNameByLanguage(user, getters.language);
      return `${userName}(${user.employeeNo})`;
    };

    const funcGetShopInfo = (value, id) => {
      state.loading = true;
      getEmployeeList({
        maxResultCount: 50,
        searchKey: value,
        ids: id ? [id] : [],
      })
        .then(({ result }) => {
          let list = result.items?.map((x) => {
            return {
              label: getFullName(x),
              value: x.id,
            };
          });
          state.list = list;
        })
        .finally(() => {
          state.loading = false;
        });
    };
    const handleSearch = debounce(funcGetShopInfo, 500);

    const handleChange = () => {};

    const close = () => {
      emit("update:visible", false);
    };

    return {
      ...toRefs(state),
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      handleSearch,
      handleChange,
      handleOk,
      close,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.finance_accounting_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <CSearchShop v-model:shopId="shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-input v-model:value="planNo"
            :allowClear="true"
            style="width: 250px"
            :placeholder="$t('logistics.transportation_plan_number')"
            ></a-input>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleSearch">{{ $t('common.query') }}</a-button>
        </a-col>
      </a-row>
      <div class="inventory-table mt-3">
        <a-table :columns="columns"
                 :data-source="planList"
                 :scroll="{ x:true,y: wrap.contentHeight - 120}"
                 :pagination="false"
                 size="small"
                 :rowKey="(record, index) => {return index;}"
                 :loading="listLoading"
                 class="product-table">
  <template #relevanceNoTitle>
            <div>
              {{$t('logistics.relevance_in_no')}}
            </div>
            <div>
              {{$t('logistics.relevance_out_no')}}
            </div>
          </template>
          <template #relevanceNo="{ record }">
            <div>{{record.inWarehouseNo??"-"}}</div>
            <div>{{record.outWarehouseNo??"-"}}</div>
          </template>
        
          <template #isCostEstimates="{ record }">
            <span v-if="record.isCostEstimates">{{$t('logistics.have_accounting')}}</span>
            <span v-else>{{$t('logistics.not_accounting')}}</span>
          </template>

          <template #transportRoutes="{ record }">
            {{$t($enumLangkey('transportRoutes',record.transportRoutes))}}
          </template>
          <template #customer="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
             <div >
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
          <template #destinationType="{ record }">
            {{$t($enumLangkey('destinationType',record.destinationType))}}
          </template>
          <template #destinationCountryCityCustom="{ record }">
            {{getLanguageName({ cnName:record.toAddress?.countryCnName, enName:record.toAddress?.countryEnName })}}
            /
            {{getLanguageName({ cnName:record.toAddress?.cityCnName, enName:record.toAddress?.cityEnName})}}
          </template>

          <template #planStatus="{ record }">
            {{$t($enumLangkey('transportStatus',record.planStatus))}}
          </template>

          <template #entryAccounting="{ record }">
            <div>
              {{$t('logistics.created_at')}} {{$filters.utcToCurrentTime(record.creationTime)}}
            </div>
            <div>
              {{$t('logistics.update_on')}} {{$filters.utcToCurrentTime(record.lastModificationTime)}}
            </div>
          </template>

          <template #operatorName="{ record }">
            <CEmployeeInfo v-if="record.employee" :employee="record.employee" />
            <span v-else>{{ record.operatorName }}</span>
          </template>

          <template #operateCustom="{ record }">
            <router-link v-if="!record.isCostEstimates"
                         :to="{name: 'finance_accounting_cost', params: { id: record.id }}">
              <a-button type="ghost">{{$t('menu.finance_accounting_cost')}}</a-button>
            </router-link>
            <router-link v-else
                         :to="{name: 'finance_accounting_details', params: { id: record.id }}">
              <a-button type="ghost">{{$t('logistics.view_details')}}</a-button>
            </router-link>
          </template>

        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onActivated, } from "vue";
import {
  Row, Col,Input,
  Table, Button
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CSearchShop from "../../components/CSearchShop.vue";
import CEmployeeInfo from "@/views/components/CEmployeeInfo.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { getName, getAddressByLanguageV2 } from "../../../utils/general";
import { getList } from "../../../api/modules/finance/accounting";

export default ({
  name: "finance_accounting_list",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    Content,
    CPager,
    CSearchShop,
    CEmployeeInfo,
  },
  setup () {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        title: () => vueI18n.t('logistics.transportation_plan_number'),
        dataIndex: "planNo",
        fixed: "left",
        width: 170,
      },
      {
        fixed: "left",
        slots: {
          title: "relevanceNoTitle",
          customRender: "relevanceNo"
        },
        width: 170,
      },
      {
        fixed: "left",
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 180,
      },
      {
        title: () => vueI18n.t('logistics.transport_route'),
        dataIndex: "transportRoutes",
        slots: {
          customRender: "transportRoutes"
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('logistics.destination_type'),
        dataIndex: "destinationType",
        slots: {
          customRender: "destinationType"
        },
        width: 100,
      },
      {
        dataIndex: "destinationCountryCity",
        title: () => vueI18n.t('logistics.destination_country_city'),
        slots: {
          customRender: "destinationCountryCityCustom"
        },
        width: 150,
      },
      {
        dataIndex: "planStatus",
        title: () => vueI18n.t('finance.status'),
        slots: {
          customRender: "planStatus"
        },
        width: 80,
      },
      {
        dataIndex: "logisticsScheme",
        title: () => vueI18n.t('logistics.logistics_scheme'),
        width: 150,
      },
     
      {
        dataIndex: "isCostEstimates",
        title: () => vueI18n.t('logistics.accounting_status'),
        slots: {
          customRender: "isCostEstimates"
        },
        width: 100,
      },

      {
        dataIndex: "entryAccounting",
        title: () => vueI18n.t('logistics.entry_accounting'),
        slots: {
          customRender: "entryAccounting"
        },
        width: 180,
      },

      {
        title: () => vueI18n.t('account.operator'),
        width: 140,
        slots: {
          customRender: "operatorName"
        },
      },

      {
        dataIndex: "operate",
        title: '',
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
        width: 120,
      },
    ];

    const state = reactive({
      shopId: null,
      planNo:null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      listLoading: false,
      planList: [],
    });

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    }

    const handleSearch = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcSearch();
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const funcSearch = async () => {
      let searchData = {
        planNo:state.planNo,
        shopId: state.shopId,
        ...state.pageData
      };

      state.listLoading = true;
      getList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            state.planList = items;
            state.pageData.totalCount = parseInt(totalCount);
          } else {
            state.pageData.totalCount = 0;
            state.planList = [];
          }
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        });
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    onActivated(async () => {
      funcSearch();
    })

    return {
      columns,
      ...toRefs(state),

      handlePage,
      funcSearch,
      getLanguageName,
      getAddress,
      handleSearch
    };
  }
})
</script>

<style lang="less" scoped>
</style>
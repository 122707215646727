<template>
  <Content>
    <template #contentTitle>{{ $t("menu.open_platform_applys") }}</template>
    <template #contentBody="wrap">
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-row :gutter="[16, 16]">
            <a-col
              ><a-input
                v-model:value="searchKey"
                style="min-width: 150px"
                :placeholder="
                  $t('warehouse.application_name') +
                  '/' +
                  $t('warehouse.application_id')
                "
                allowClear
            /></a-col>
            <a-col
              ><a-button
                type="primary"
                :loading="tableData.loading"
                @click="handleSearch"
                >{{ $t("common.query") }}</a-button
              ></a-col
            >
          </a-row>
        </a-col>
        <a-col
          ><a-button @click="handleCreate">{{
            $t("common.create")
          }}</a-button></a-col
        >
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 90 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #appName="{ record }">
          <a-space>
            <div class="table-list-img-common" v-if="record.logo">
              <c-image :src="record.logo"></c-image>
            </div>
            <span>{{ record.appName }}</span>
          </a-space>
        </template>
        <template #description="{ record }">
          <span v-if="record.description">{{ record.description }}</span>
          <span v-else>-</span>
        </template>
        <template #isActive="{ record }">
          <a-switch
            :loading="record.loading"
            v-model:checked="record.isActive"
            @change="handleActiveChange(record)"
          />
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #operate="{ record }">
          <a-dropdown-button @click="handleEdit(record)">
            {{ $t("common.edit") }}
            <template #overlay>
              <a-menu @click="handleOperateMenuClick">
                <a-menu-item :key="1" :record="record">
                  {{ $t("warehouse.set_the_authority") }}
                </a-menu-item>
                <a-menu-item :key="2" :record="record">
                  {{ $t("warehouse.reset_key") }}
                </a-menu-item>
                <a-menu-item :key="3" :record="record">
                  {{ $t("menu.open_platform_applys_company") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <EditApplysModal ref="editApplysModalRef" @refresh="handleShowSecretModal" />
      <SetAuthorityModal ref="setAuthorityModalRef" />
      <AppSecretModal ref="appSecretModalRef" />
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager
            @handlePage="handlePage"
            :page-data="tableData.pageData"
          ></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, onMounted, toRefs, ref, createVNode } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import Content from "@/views/components/Content";
import CPager from "@/views/components/CPager";
import CImage from "@/views/components/CImage";
import EditApplysModal from "./components/EditApplysModal.vue";
import SetAuthorityModal from "./components/SetAuthorityModal.vue";
import AppSecretModal from "./components/AppSecretModal.vue";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Dropdown,
  Switch,
  Menu,
  Modal,
  Space,
  message,
} from "ant-design-vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getListPaged, updateAuthorizeApplicationIsActive, resetAuthorizeApplicationAppSecret } from "@/api/modules/openPlatform/applys";

export default defineComponent({
  name: "open_platform_applys",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    ASwitch: Switch,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpace: Space,
    Content,
    CPager,
    CImage,
    EditApplysModal,
    SetAuthorityModal,
    AppSecretModal,
  },
  setup() {
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });
    const editApplysModalRef = ref(null);
    const setAuthorityModalRef = ref(null);
    const appSecretModalRef = ref(null);

    const state = reactive({
      searchKey: null,
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: "appId",
        width: 100,
        title: () => vueI18n.t("warehouse.application_id"),
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.application_name"),
        slots: {
          customRender: "appName"
        }
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.application_description"),
        slots: {
          customRender: "description",
        }
      },
      {
        dataIndex: "callBackUrl",
        width: 100,
        title: () => vueI18n.t("warehouse.call_back_address"),
      },
      {
        width: 50,
        title: () => vueI18n.t("finance.status"),
        slots: {
          customRender: "isActive",
        },
      },
      {
        width: 80,
        title: () => vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 60,
        title: "",
        slots: {
          customRender: "operate",
        },
      },
    ];

    const handleCreate = () => {
      editApplysModalRef.value.show();
    };

    const handleEdit = (record) => {
      editApplysModalRef.value.show(record.id);
    };

    const handleActiveChange = (record) => {
      record.loading = true
      const data = Object.assign({}, {
        id: record.id,
        isActive: record.isActive,
      })
      updateAuthorizeApplicationIsActive(data).then(() => {}).catch(() => {
        record.isActive = !data.isActive
      }).finally(() => {
        record.loading = false
      })
    };

    const handleShowSecretModal = (data) => {
      if (data) {
        appSecretModalRef.value.show(data.appId, data.appSecret, 'warehouse.app_secret_tips_1');
      }
      handleSearch()
    }

    const resetKey = (record) => {
      state.tableData.loading = true
      resetAuthorizeApplicationAppSecret({ id: record.id }).then(({ result }) => {
        if (result) {
          message.success(vueI18n.t("common.succeed"))
          appSecretModalRef.value.show(record.appId, result, 'warehouse.app_secret_tips_2');
        } else {
          message.error(vueI18n.t("warehouse.error_message"))
        }
      }).catch(() => {}).finally(() => {
        state.tableData.loading = false
      })
    }

    const handleOperateMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 1:
          setAuthorityModalRef.value.show(record.id)
          break;
        case 2:
          Modal.confirm({
            icon: createVNode(ExclamationCircleOutlined),
            title: () => vueI18n.t("warehouse.operation_tips"),
            content: () => vueI18n.t("warehouse.whether_to_confirm_the_reset_key"),
            onOk() {
              resetKey(record)
            }
          });
          break;
        case 3:
          router.push({ name: "open_platform_applys_company", params: { id: record.id } });
          break;
      
        default:
          break;
      }
    };

    const getData = async () => {
      try {
        state.tableData.loading = true;
        const data = Object.assign({}, state.tableData.pageData, {
          searchKey: state.searchKey,
        })
        let { result } = await getListPaged(data);
        if (result) {
          let { totalCount = 0, items = [] } = result;
          items = items.map((item) => {
            item.loading = false;
            return item;
          });
          state.tableData.tableList = items;
          state.tableData.pageData.totalCount = parseInt(totalCount);
        }
      } catch (error) {
      } finally {
        state.tableData.loading = false;
      }
    };

    const handleSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getData();
    };

    onMounted(handleSearch);

    return {
      ...toRefs(state),
      editApplysModalRef,
      setAuthorityModalRef,
      appSecretModalRef,
      columns,
      handleCreate,
      handleEdit,
      handleSearch,
      handlePage,
      handleActiveChange,
      handleOperateMenuClick,
      handleShowSecretModal,
    };
  },
});
</script>


    <template>
  <ACard>
    <template #title>
      {{$t('warehouse.organization_structure')}}
    </template>

    <a-button v-if="!organTree||organTree.length==0"
              size="small"
              @click="handleAddRootNode()">
      {{ $t('创建') }}
    </a-button>
    <a-tree v-else
            :tree-data="organTree"
            v-model:expandedKeys="expandedKeys"
            v-model:selectedKeys="selectedKeys"
            @select="handlerSelectTree">
      <template v-slot:subLevel='record'>
        <div>
          <span> {{ record.title }} </span>
          <a-button type="link"
                    size="small"
                    class="edit"
                    @click.stop="handleClickEdit(record)">
            <FormOutlined />
          </a-button>

          <a-button type="link"
                    size="small"
                    @click.stop="handleClickAdd(record)">
            <PlusCircleOutlined />
          </a-button>
          <a-popconfirm v-if="(record?.children?.length ==0)"
                        :title="$t('common.are_you_sure') "
                        :ok-text="$t('common.confirm')"
                        :cancel-text="$t('common.cancel')"
                        @confirm="handleClickDelete(record)">
            <a-button type="link"
                      size="small"
                      danger
                      :disabled="(record.children && record.children.length > 0)">
              <CloseCircleOutlined />
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-tree>

  </ACard>
  <a-modal v-model:visible="addOrEditData.visible"
           :title="addOrEditData.id ? $t('warehouse.edit_subdomain'):$t('warehouse.add_subdomain')"
           @ok="handleBtnOk"
           :confirm-loading="addOrEditData.loading"
           :maskClosable="false">
    <a-form :model="addOrEditData"
            ref="addOrEditFormRef"
            :label-col="{span: 4}"
            :rules="addOrEditModalRules">
      <a-form-item :label="$t('region.parent_route')"> {{addOrEditData.parentName??"-"}} </a-form-item>
      <a-form-item :label="$t('warehouse.name')"
                   name="name">
        <a-input v-model:value="addOrEditData.name"
                 @pressEnter="handleBtnOk"></a-input>
        <input class="d-none">
      </a-form-item>
    </a-form>
  </a-modal>

</template>

<script>
import Content from '../../components/Content.vue';
import { reactive, ref, onMounted } from 'vue';
import {
  getCompanyOrganTreeList, createDepartment as create,
  updateDepartment as update, deleteDepartment
} from '../../../api/modules/company/index.js'
import { useI18n } from "vue-i18n/index";
import {
  Modal,
  Card,
  Row,
  Col,
  Tree,
  Button,
  Input,
  Form, Popconfirm,
} from 'ant-design-vue';
export default {
  components: {
    Content,
    AModal: Modal,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    ATree: Tree,
    AButton: Button,
    AInput: Input,
    AForm: Form,
    AFormItem: Form.Item,
    APopconfirm: Popconfirm
  },
  emits: ["changeDepartment","selectDepartment"],

  setup (props, { emit }) {
    const expandedKeys = ref([]);
    const selectedKeys = ref([]);

    const addOrEditFormRef = ref();
    const vueI18n = useI18n();
    const organTree = ref([]);
    var originalData = [];
    const addOrEditData = reactive({
      visible: false,
      id: "",
      parentDepartmentId: "",
      parentName: "",
      name: "",
      loading: false,
    });

    const getTree = async () => {
      getCompanyOrganTreeList().then((res) => {
        let r = res.result;
        if (r && r.length) {
          originalData = r;
          r = r.map((x, index) => {
            return {
              title: x.name,
              id: x.id,
              key: x.id,
              value: x.id,//StaffRight.vue 添加部门选择框用
              label: x.name,//StaffRight.vue 添加部门选择框用
              parentDepartmentId: x.pid,
              children: [],
              slots: {
                title: 'subLevel'
              },
            }
          });
          let subTree = toTree(r);
          if (subTree.length > 0) {
            organTree.value = subTree;
            expandedKeys.value.push(subTree[0].id)
          }
        } else {
          organTree.value = [];
        }
        emit('changeDepartment', organTree.value)
      })
    };

    const toTree = list => {
      let node;
      const map = {}
      const tree = []
      for (let i = 0; i < list.length; i++) {
        map[list[i].id] = i;
      }
      for (let i = 0; i < list.length; i++) {
        node = list[i];
        if (node.parentDepartmentId > 0) {
          const children = list[map[node.parentDepartmentId]]?.children || [];
          if (list[map[node.parentDepartmentId]]) {
            list[map[node.parentDepartmentId]].children = [...children, node];
          }
        } else {
          tree.push(node)
        }
      }
      return tree
    }

    const handleClickEdit = (record) => {
      let parentNode = originalData.find(x => x.id == record.parentDepartmentId);
      let isRootNode = !parentNode;

      addOrEditData.visible = true;
      addOrEditData.id = record.id;
      addOrEditData.name = record.title;
      addOrEditData.parentDepartmentId = isRootNode ? "0" : record.parentDepartmentId;
      addOrEditData.parentName = parentNode?.name;
    };

    const handleClickAdd = (record) => {
      addOrEditData.visible = true;
      addOrEditData.id = "";
      addOrEditData.name = "";
      addOrEditData.parentDepartmentId = record.id;
      addOrEditData.parentName = record.title;
    };

    const handleAddRootNode = (record) => {
      addOrEditData.visible = true;
      addOrEditData.isRootNode = true;
      addOrEditData.parentDepartmentId = "";
      addOrEditData.parentName = null;
      addOrEditData.id = "";
      addOrEditData.name = null;
    };

    const handleBtnOk = () => {
      addOrEditData.loading = true;
      addOrEditFormRef.value.validate()
        .then(() => {
          return addOrEditData.id
            ? update(addOrEditData)
            : create(addOrEditData);
        })
        .then(async () => {
          addOrEditData.visible = false;
          addOrEditData.loading = false;

          if (addOrEditData.parentDepartmentId) {
            expandedKeys.value.push(addOrEditData.parentDepartmentId)
          }
          await getTree();
        })
        .catch(() => {
          addOrEditData.loading = false;
        })
    };

    const handlerSelectTree = (keys)=>{
      //console.log("选中项:"+keys);
      emit('selectDepartment', keys)
    }

    const handleClickDelete = (record) => {
      let ids = [record.id];
      deleteDepartment(ids).then((res) => {
        getTree();
      })
    };

    onMounted(async () => {
      getTree();
    });

    const addOrEditModalRules = {
      name: [{
        required: true, message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.name")])
        }, trigger: 'blur'
      }],
    };

    return {
      expandedKeys,
      organTree,
      addOrEditData,
      addOrEditModalRules,
      addOrEditFormRef,
      selectedKeys,
      handleClickEdit,
      handleClickAdd,
      handleBtnOk,

      handleClickDelete,
      handleAddRootNode,
      handlerSelectTree
    };
  },
};
</script>

<style lang="less" scoped>
.edit {
  color: #f5aa6c;
}
</style>
import client from "../../client";

const Api = {
  getList: "/api/WarehouseService/Port/GetListPaged",
  add: "/api/WarehouseService/Port/Create",
  update: "/api/WarehouseService/Port/Update",
  deleteData: "/api/WarehouseService/Port/Delete",
  detail: "/api/WarehouseService/Port/Get",
};

export const getList = ({
  transportType,
  searchKey,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: { transportType, searchKey, skipCount, maxResultCount, sorting },
    method: "POST",
  });
};

export const add = ({
  transportType,
  portName,
  portCode,
  timezone,
  countryId,
}) => {
  return client.request({
    url: Api.add,
    data: { transportType, portName, portCode, timezone, countryId },
    method: "POST",
  });
};

export const update = ({
  id,
  transportType,
  portName,
  portCode,
  timezone,
  countryId,
}) => {
  return client.request({
    url: Api.update,
    data: { id, transportType, portName, portCode, timezone, countryId },
    method: "POST",
  });
};

export const deleteData = (ids) => {
  return client.request({
    url: Api.deleteData,
    data: { ids },
    method: "POST",
  });
};

export const detail = (id) => {
  return client.request({
    url: Api.detail,
    params: { id },
    method: "get",
  });
};

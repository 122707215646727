<template>
  <a-table
    size="small"
    :columns="columns"
    :data-source="state.data"
    :pagination="false"
    :scroll="{ x: 1200 }"
    :row-key="(record) => record.rowKey"
  >
    <template #category="{ record }">
      <span v-if="record.category">{{ $t(record.category) }}</span>
    </template>
  </a-table>
</template>

<script>
import { defineComponent, reactive, watch, h } from "vue";
import { Table } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import filters from "@/filters/index.js"
import { getEnumLangkey, randomString } from "@/utils/general.js";
import { orderLogisticsFee as orderLogisticsFeeEnum } from "@/enum/enum.json";

// 用于表格需要展示的列
const columnsKeys = [
  "express",
  "expressDdp",
  "expressSign",
  "surchargeGeneralDeclaration",
  "surchargeSpecialProduct",
  "surchargeProductNameLimit",
  "surchargeContinuePage",
  "surchargeNotFbaAddr",
  "surchargeRemoteDistricts",
  "surchargeUltraRemoteDistricts",
  "surchargeRemoteDelivery",
  "surchargeOverStandard",
  "surchargeLargePackage",
  "surchargeTailDelivery",
  "surchargeOthers",
  "surchargeExtra",
  "otherFee",
  "amount",
];

export default defineComponent({
  components: {
    ATable: Table,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
  },
  setup (props) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      data: [],
    });

    const columns = [];

    const initTable = (dataSource) => {
      columns.length = 0;
      columns.push({
        title: "",
        width: 150,
        align: "center",
        fixed: "left",
        slots: {
          customRender: "category",
        }
      });
      if ('accountCurrency' in dataSource && 'conversionAmount' in dataSource && 'profitLossCurrency' in dataSource && 'targetCurrency' in dataSource) {
        let { accountCurrency, conversionAmount, profitLossCurrency, targetCurrency } = dataSource;
        const [row1, row2, row3] = [
          { rowKey: randomString(), category: "warehouse.system" },
          { rowKey: randomString(), category: "warehouse.reconciliation" },
          { rowKey: randomString() }
        ];
        row1.currencySymbol = targetCurrency.currencySymbol;
        row2.currencySymbol = accountCurrency.currencySymbol;
        row2.conversion_currencySymbol = conversionAmount.currencySymbol;
        row3.currencySymbol = profitLossCurrency.currencySymbol;

        columnsKeys.forEach((key, index) => {
          let title = '';
          if (key === 'otherFee') {
            title = 'finance.other_expenses';
          } else if (key === 'amount') {
            title = 'logistics.total_cost';
          } else {
            title = getEnumLangkey('orderLogisticsFee', orderLogisticsFeeEnum[key]);
          }
          columns.push({
            dataIndex: key,
            title: () => vueI18n.t(title),
            width: 150,
            customRender: ({text, record, index}) => {
              if (index === 0) {
                return filters.formatCurrencyAmount(record.currencySymbol, text, false);
              } else if (index === 1) {
                if (record.currencySymbol !== record.conversion_currencySymbol) {
                  return h("span", {}, [filters.formatCurrencyAmount(record.currencySymbol, text, false), "(", h("span", { class: "text-success" }, filters.formatCurrencyAmount(record.conversion_currencySymbol, record['conversion_' + key], false)), ")"]);
                } else {
                  return filters.formatCurrencyAmount(record.currencySymbol, text, false);
                }
              } else if (index === 2) {
                if (text > 0) {
                  return h("span", { class: "text-success" }, filters.formatCurrencyAmount(record.currencySymbol, text));
                } else if (text === 0) {
                  return h("span", {}, "-");
                } else {
                  return h("span", { class: "text-error" }, filters.formatCurrencyAmount(record.currencySymbol, text));
                }
              }
            }
          });
          if (Object.prototype.hasOwnProperty.call(targetCurrency, key)) {
            row1[key] = targetCurrency[key];
          }
          if (Object.prototype.hasOwnProperty.call(accountCurrency, key)) {
            row2[key] = accountCurrency[key];
            if (Object.prototype.hasOwnProperty.call(conversionAmount, key)) {
              row2['conversion_' + key] = conversionAmount[key];
            }
          }
          if (Object.prototype.hasOwnProperty.call(profitLossCurrency, key)) {
            row3[key] = profitLossCurrency[key];
          }
        });
        state.data = [row1, row2, row3];
      }
    }

    watch(() => props.dataSource, (value) => {
      if (!!value) {
        initTable(value);
      }
    }, { immediate: true, deep: true })

    return {
      state,
      columns,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <Content style="margin-top: -20px; height:calc(100% + 20px);">
    <template #contentBody="wrap">
      <a-tabs size="small" v-model:activeKey="searchState.planStatus" @change="handleFilterSearch">
        <a-tab-pane key="" :tab="$t('account.all')"></a-tab-pane>
        <a-tab-pane key='20' :tab="$t('logistics.await_visit_fetch')" force-render></a-tab-pane>
        <a-tab-pane key="30" :tab="$t('logistics.visit_fetching')"></a-tab-pane>
        <a-tab-pane key="50" :tab="$t('logistics.console_await_receiving')"></a-tab-pane>
        <a-tab-pane key="60" :tab="$t('logistics.received_goods')"></a-tab-pane>
        <a-tab-pane key="80" :tab="$t('logistics.waiting_for_payment')"></a-tab-pane>
        <a-tab-pane key="90" :tab="$t('logistics.account_paid')"></a-tab-pane>
        <a-tab-pane key="100" :tab="$t('logistics.in_transit')"></a-tab-pane>
        <a-tab-pane key="110" :tab="$t($enumLangkey('transportStatus', transportStatusEnum.hasDelivered))"></a-tab-pane>
        <a-tab-pane key="120" :tab="$t($enumLangkey('transportStatus', transportStatusEnum.outForDelivery))"></a-tab-pane>
        <a-tab-pane key="130" :tab="$t($enumLangkey('transportStatus', transportStatusEnum.signed))"></a-tab-pane>
        <a-tab-pane key="999" :tab="$t('logistics.canceled')"></a-tab-pane>
      </a-tabs>

      <div class="search-inputs">
        <a-row :gutter="[16, 8]">
          <a-col>
            <a-input
              style="width: 250px;"
              v-model:value="searchState.planName"
              :placeholder="$t('logistics.transport_plan_name')"
              :allowClear="true"
            />
          </a-col>
          <a-col>
            <a-select
              style="width: 250px"
              v-model:value="searchState.planNoes"
              mode="tags"
              :maxTagCount="1"
              :maxTagTextLength="12"
              :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
              :placeholder="$t('logistics.transportation_plan_number')"
              allowClear
              @change="handleSelectValueChange('planNoes')"
            ></a-select>
          </a-col>
          <a-col>
            <a-input
              style="width: 250px;"
              v-model:value="searchState.inOrOutWarehouseNo"
              :placeholder="$t('logistics.relevance_in_no') + '/' + $t('logistics.relevance_out_no')"
              :allowClear="true"
            />
          </a-col>
          <a-col>
            <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
          </a-col>
          <a-col>
            <a-select
              style="width: 250px"
              v-model:value="searchState.boxNoes"
              mode="tags"
              :maxTagCount="1"
              :maxTagTextLength="18"
              :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
              :placeholder="$t('warehouse.box_number')"
              allowClear
              @change="handleSelectValueChange('boxNoes')"
            ></a-select>
          </a-col>
          <a-col>
            <a-select
              style="width: 250px;"
              v-model:value="searchState.isLoseMoney"
              :allowClear="true"
              :placeholder="$t('warehouse.whether_to_compensate')"
            >
              <a-select-option
                v-for="(item, index) in refundAndCompensationStatusList"
                :key="index"
                :title="$t(item.label)"
                :value="item.value"
                :data-index="index"
              >
                {{ $t(item.label) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
              v-model:value="searchState.logisticsId"
              style="width: 250px;"
              :placeholder="$t('logistics.logistics_channel')"
              optionFilterProp="search-key"
              option-label-prop="label"
              show-search
              allow-clear
            >
              <a-select-option
                v-for="(item, index) in channelList"
                :key="index"
                :value="item.id"
                :title="item.name + '(' + item.code + ')'"
                :search-key="item.name + item.code + item.countryCnName + item.countryEnName + item.ios2"
                :label="item.name + '(' + item.code + ')'"
              >
                <a-row justify="space-between">
                  <a-col>{{ item.name }}</a-col>
                </a-row>
                <a-row justify="space-between">
                  <a-col>{{ item.code }}</a-col>
                  <a-col>{{ getLanguageName({ cnName: item.countryCnName, enName: item.countryEnName }) }}({{ item.ios2 }})</a-col>
                </a-row>
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-input-group compact>
              <a-select v-model:value="searchState.queryTimeType" style="width: 120px">
                <a-select-option value="1" :title="$t('logistics.create_date')">{{$t('logistics.create_date')}}</a-select-option>
                <a-select-option value="2" :title="$t('logistics.update_date')">{{$t('logistics.update_date')}}</a-select-option>
              </a-select>
              <a-range-picker style="width: 230px" @change="handleSelectedDate" />
            </a-input-group>
          </a-col>
          <a-col>
            <a-button @click="handleFilterSearch" :loading="listLoading" type="primary">{{$t('common.query')}}</a-button>
          </a-col>
          <a-col>
            <a-button @click="handleShowPinModal(10)" type="ghost">{{$t('common.export')}}</a-button>
          </a-col>
          <a-col>
            <a-button type="primary" :disabled="selectedRows.length === 0" @click="handleExportWarehouseReceipt">{{$t('logistics.print_warehouse_receipt')}}</a-button>
          </a-col>
          <a-col>
            <a-button type="primary" :disabled="selectedRows.length === 0 || disabledPrintBill" @click="handlePrintBill">{{$t('logistics.print_bill')}}</a-button>
          </a-col>
          <a-col>
            <a-button type="primary" :loading="listLoading" :disabled="selectedRows.length === 0 || disabledPrintBill" @click="handleExportBill">{{$t('logistics.export_bill')}}</a-button>
          </a-col>
        </a-row>
      </div>
      <div class="inventory-table mt-2">
        <a-table :columns="columns"
                 :data-source="planList"
                 :scroll="{ x: 500, y: wrap.contentHeight - 226 }"
                 :pagination="false"
                  :row-selection="{
                    columnTitle: tableColumnTitle,
                    selectedRowKeys: selectedRowKeys,
                    onChange: onTableSelectedChange,
                    getCheckboxProps: (record) => ({ disabled: record.disabled })
                 }"
                 size="small"
                 :loading="listLoading"
                 :row-key="(record) => record.planId">
          <template #planNameAndNo>
            <div>
              {{$t('logistics.transport_plan_name')}}
            </div>
            <div>
              {{$t('logistics.transportation_plan_number')}}
            </div>
          </template>
          <template #relevanceNoTitle>
            <div>
              {{$t('warehouse.source_related_doc_no')}}
            </div>
            <div>
              {{$t('warehouse.target_related_doc_no')}}
            </div>
          </template>
          <template #relevanceNo="{ record }">
            <div>
              <CPlanNumberItem :no="record.inWarehouseNo || record.outWarehouseNo" />
            </div>
            <div>
              <CPlanNumberItem :no="record.toInWarehouseNo" />
            </div>
          </template>
          <template #transportRouteAndDestinationType>
            <div>{{$t('logistics.transport_route')}}</div>
            <div>{{$t('logistics.destination_type')}}</div>
          </template>
          <template #destinationCountryCityTitle>
            <div>{{$t('common.destination_country')}}</div>
            <div>{{$t('common.city')}}</div>
          </template>
          <template #transportBoxesTotalTitle>
            <div>{{ $t("logistics.forecast_volume") }}</div>
            <div>{{ $t("logistics.forecast_weight") }}</div>
          </template>
          <template #chargeableTitle>
            <div>{{ $t("logistics.chargeable_volume") }}</div>
            <div>{{ $t("logistics.charged_weight") }}</div>
          </template>
          <template #receivingPointTitle>
            <div>{{ $t('enum.warehouse_type_4') }}</div>
            <div>{{ $t('logistics.receiving_point') }}</div>
          </template>
          <template #planNameAndNoCustom="{ record }">
            <div>{{record.planName}}</div>
            <div>{{record.planNo}}</div>
          </template>
          <template #shopCustomer="{ record }">
            <a-popover>
              <template #content>
                <div style="max-width: 300px;">{{ record.shopName }} <a-tag color="error" v-if="!record.hasContract">{{ $t('finance.contract_not_signed') }}</a-tag></div>
                <div class="mt-1" v-if="record.shopNo">NO: {{ record.shopNo }}</div>
                <div class="mt-1">{{ $t('common.shop_mark') }}: {{ record.shopMark ?? "-" }}</div>
              </template>
              <small v-if="record.hasContract">{{ record.shopName }}</small>
              <small v-else class="text-error">{{ record.shopName }}</small>
              <div v-if="record.shopNo">
                NO: {{record.shopNo}}
              </div>
            </a-popover>
          </template>
          <template #transportRouteAndDestinationTypeCustom="{ record }">
             <a-row :gutter="[8,8]">
              <a-col>{{$t($enumLangkey('transportRoutes',record.transportRoute))}}
            <div>
              {{$t($enumLangkey('destinationType',record.destinationType))}}
            </div>
              </a-col>
              <a-col v-if="record.isUploadHsInfo" :span="24">
             <a-tag color="processing">{{$t('warehouse.declare')}}</a-tag>
              </a-col>
            </a-row>
          </template>

          <template #destinationCountryCityCustom="{ record }">
            <div>{{getLanguageName({ cnName:record.toCountryCnName, enName:record.toCountryEnName })}}</div>
            <div>{{getLanguageName({ cnName:record.toCityCnName, enName:record.toCityEnName })}}</div>
          </template>

          <template #transportBoxCount="{ record }">
            <span>{{ record.transportBoxCount }}</span>
            <span v-if="record.cancelTransportBoxCount > 0">
              <CTooltip :text="`(-${record.cancelTransportBoxCount})`"
                        :title="$t('logistics.cancel_box_count')" />
            </span>
          </template>

          <template #transportBoxesTotalCustomer="{ record }">
            <div>
              <span v-if="record.transportBoxesTotal">{{ cmCubicToM(record.transportBoxesTotal?.totalContainerVolume ?? 0) }}m<sup>3</sup></span>
              <span v-else>-</span>
            </div>
            <div>
              <span v-if="record.transportBoxesTotal">{{ gToKg(record.transportBoxesTotal?.totalContainerWeight ?? 0) }}kg</span>
              <span v-else>-</span>
            </div>
          </template>

          <template #chargeableCustomer="{ record }">
            <div>
              <span v-if="record.chargeableInfo && record.chargeableInfo.logisticsCalcMethod === logisticsCalcMethodEnum.volume">{{ cmCubicToM(record.chargeableInfo?.chargeableVolume ?? 0) }}m<sup>3</sup></span>
              <span v-else>-</span>
            </div>
            <div>
              <span v-if="record.chargeableInfo && record.chargeableInfo.logisticsCalcMethod === logisticsCalcMethodEnum.weight">{{ gToKg(record.chargeableInfo?.chargeableWeight ?? 0) }}kg</span>
              <span v-else>-</span>
            </div>
          </template>

          <template #costCustomer="{ record }">
            <span v-if="record.chargeableInfo && record.chargeableInfo.fee">
              {{ $filters.formatCurrencyAmount(record.chargeableInfo.fee.currencySymbol, record.chargeableInfo.fee.totalPrice, false) }}
            </span>
            <span v-else>-</span>
          </template>

          <template #logisticsScheme="{ record }">
            <span v-if="record.logisticsScheme">{{ record.logisticsScheme }}</span>
            <span v-else>-</span>
          </template>

          <template #receivingPointCustom="{ record }">
            <div>{{ record.logisticsWarehouseNo || '-' }}</div>
            <div>{{ record.receivingPoint || '-' }}</div>
          </template>

          <template #statusCustom="{ record }">
            <a-space direction="vertical">
              <div>{{ $t($enumLangkey('transportStatus', record.status)) }}</div>
              <PaymentCountdown
                v-if="record.status === transportStatusEnum.awaitPay"
                :value="record.countdownPaidSeconds"
                @finish="handleSearch"
              />
              <div v-if="record.isPayOnArrival && record.logisticsNode">
                <a-tag color="blue" v-if="record.logisticsNode.accountingPeriodCalculationWay === logisticsAccountingPeriodCalculationWayEnum.nextMonth">
                  {{ $t($enumLangkey("logisticsNode", record.logisticsNode.logisticsNode)) }}, {{ $t("logistics.next_month") }}, {{ record.logisticsNode.accountingPeriodDays }}{{ $t('common.days') }}
                </a-tag>
                <a-tag color="blue" v-else>{{ $t($enumLangkey("logisticsNode", record.logisticsNode.logisticsNode)) }}, {{ record.logisticsNode.accountingPeriodDays }}{{ $t('common.days') }}</a-tag>
              </div>
              <a-tag color="orange"
                     v-if="record.loseMoney > 0">{{ $t('warehouse.compensation') }} {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.loseMoney) }}</a-tag>
            </a-space>
          </template>

          <template #createTimeAndUpdateTimeTitle>
            <div>{{ $t('warehouse.creation_time') }}</div>
            <div>{{ $t('logistics.update_time') }}</div>
          </template>

          <template #createTimeAndUpdateTimeCustom="{ record }">
            <div>
              {{$filters.utcToCurrentTime(record.creationTime)}}
            </div>
            <div>
              {{$filters.utcToCurrentTime(record.updateTime)}}
            </div>
          </template>

          <template #operateCustom="{ record }">
            <a-dropdown-button>
              <router-link :to="{ name: 'transport_customer_detail', params: { id: record.planId } }">
                {{ $t('common.details') }}
              </router-link>
              <template #overlay>
                <a-menu @click="handleOperateMenuClick">
                  <a-menu-item :key="1"
                               :record="record"
                               :disabled="!record.isShowCancel">
                    {{ $t("logistics.customer_cancels_shipment") }}
                  </a-menu-item>
                  <a-menu-item :key="2"
                               :record="record"
                               :disabled="!record.isShowEndCollection">
                    {{ $t("logistics.end_receipt") }}
                  </a-menu-item>
                  <a-menu-item :key="3"
                               :record="record"
                               :disabled="!record.isShowLoseMoney">
                    {{ $t("warehouse.compensation") }}
                  </a-menu-item>
                  <a-menu-item :key="4"
                               :record="record"
                               :disabled="
                               record.logisticsType != logisticsWayEnum.commercialExpress&&
                               !(record.status >= transportStatusEnum.inTransit && record.status <= transportStatusEnum.signed )">
                    {{ $t("warehouse.track_info") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>
      <a-row type="flex" justify="end" :gutter="32" class="pt-2 pb-2">
        <a-col><small>{{ $t('warehouse.box_amount') }}: {{ tableTotalState.boxes }}</small></a-col>
        <a-col><small>{{ $t('logistics.forecast_volume') }}: {{ tableTotalState.forecastVolume }}m<sup>3</sup></small></a-col>
        <a-col><small>{{ $t('logistics.forecast_weight') }}: {{ tableTotalState.forecastWeight }}kg</small></a-col>
        <a-col><small>{{ $t('logistics.chargeable_volume') }}: {{ tableTotalState.billingVolume }}m<sup>3</sup></small></a-col>
        <a-col><small>{{ $t('logistics.charged_weight') }}: {{ tableTotalState.billingWeight }}kg</small></a-col>
        <a-col style="display: flex; align-items: center;">
          <a-popover v-if="tableTotalState.currencySymbolList.length > 0">
            <template #content>
              <a-row :gutter="[0, 8]" style="max-width: 150px;">
                <a-col v-for="(item, index) in tableTotalState.currencySymbolList" :key="index">
                  <a-tag>{{ $filters.formatCurrencyAmount(item, tableTotalState.totalCostList[index], false) }}</a-tag>
                </a-col>
              </a-row>
            </template>
            <div class="total-cost">
              <small>
                {{ $t('logistics.total_cost') }}: 
                <span class="total-cost-item" v-for="(item, index) in tableTotalState.currencySymbolList" :key="index">{{ $filters.formatCurrencyAmount(item, tableTotalState.totalCostList[index], false) }}</span>
                <span v-if="tableTotalState.currencySymbolList.length === 0">-</span>
              </small>
            </div>
          </a-popover>
          <small v-else>{{ $t('logistics.total_cost') }}: -</small>
        </a-col>
      </a-row>

      <CPinTipsModal v-model:visible="partialReceiptState.visible" :loading="partialReceiptState.loading" @confirm="handlePinCodeModalCallback" />
      <LoseMoneyModal ref="loseMoneyModalRef" @confirm="handleShowNextModal" />

       <CTrackInfoModal 
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
         dateFormat="yyyy-MM-DD"
        :loading="trackModal.loading"
       >
        <template v-slot:numberName>
          {{ $t("logistics.transportation_plan_number") }}
        </template>
         <template v-slot:no>
          {{trackModal.no}}
        </template>
      </CTrackInfoModal>
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager :page-data="pageData" @handlePage="handlePage"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { computed, defineComponent, h, onActivated, onMounted, reactive, ref, toRefs } from "vue";
import {
  Row, Col, Tag, Spin, Tabs, Checkbox,
  Table, Input, Tooltip, Popover,
  Select, Button, Form, DatePicker, Dropdown, Menu, Space, message,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import CSearchShop from "@/views/components/CSearchShop.vue"
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import LoseMoneyModal from '@/views/warehouseMonitor/components/LoseMoneyModal.vue';
import PaymentCountdown from "../components/PaymentCountdown.vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from 'vue-router';
import { useStore } from "vuex";
import { downloadFile } from "@/utils/downloader"
import localPrint from "@/utils/localPrint.js";
import { getName, currentTimeToUtc, dateString, cmCubicToM, gToKg } from "@/utils/general";
import { getLogisticsValidBasicData } from '@/api/modules/logistics/index.js';
import {
  getSelfManagePlanPaged,
  consoleCancel,
  partialReceipt,
  loseMoneyTransport,
  exportSelfManage,
  getTrackList,
  batchSeInWarehouseOrder,
  printTransportBill,
  exportTransportBill,
} from "@/api/modules/transport/index";
import {
  transportStatus as transportStatusEnum,
  logisticsWay as logisticsWayEnum,
  logisticsAccountingPeriodCalculationWay as logisticsAccountingPeriodCalculationWayEnum,
  logisticsCalcMethod as logisticsCalcMethodEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "transport_customer_list",
  components: {
    CTrackInfoModal,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ARangePicker: DatePicker.RangePicker,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASpace: Space,
    APopover: Popover,
    Content,
    CPager,
    CSearchShop,
    CPinTipsModal,
    CTooltip,
    CPlanNumberItem,
    LoseMoneyModal,
    PaymentCountdown,
  },
  setup () {
    const loseMoneyModalRef = ref(null);

    const route = useRoute();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        fixed: "left",
        width: 170,
        slots: {
          title: "planNameAndNo",
          customRender: "planNameAndNoCustom"
        },
      },
      {
        fixed: "left",
        width: 170,
        slots: {
          title: "relevanceNoTitle",
          customRender: "relevanceNo"
        },
      },
      {
        fixed: "left",
        title: () => vueI18n.t("warehouse.customer"),
        width: 150,
        slots: {
          customRender: "shopCustomer"
        },
      },
      {
        width: 120,
        slots: {
          title: 'transportRouteAndDestinationType',
          customRender: "transportRouteAndDestinationTypeCustom"
        },
      },
      {
        width: 120,
        slots: {
          title: 'destinationCountryCityTitle',
          customRender: "destinationCountryCityCustom"
        },
      },
      {
        title: () => vueI18n.t('logistics.transport_box_count'),
        width: 80,
        slots: {
          customRender: "transportBoxCount"
        }
      },
      {
        width: 100,
        slots: {
          title: "transportBoxesTotalTitle",
          customRender: "transportBoxesTotalCustomer",
        },
      },
      {
        width: 100,
        slots: {
          title: "chargeableTitle",
          customRender: "chargeableCustomer",
        },
      },
      {
        title: () => vueI18n.t("logistics.total_cost"),
        width: 100,
        slots: {
          customRender: "costCustomer",
        },
      },
      {
        title: () => vueI18n.t('logistics.logistics_scheme'),
        width: 120,
        slots: {
          customRender: "logisticsScheme"
        }
      },
      {
        width: 100,
        slots: {
          title: 'receivingPointTitle',
          customRender: 'receivingPointCustom',
        }
      },
      {
        title: () => vueI18n.t('finance.status'),
        width: 100,
        slots: {
          customRender: "statusCustom"
        },
      },
      {
        width: 120,
        slots: {
          title: "createTimeAndUpdateTimeTitle",
          customRender: "createTimeAndUpdateTimeCustom"
        },
      },
      {
        title: () => vueI18n.t('common.operate'),
        fixed: "right",
        width: 120,
        slots: {
          customRender: "operateCustom"
        },
      },
    ];

    const state = reactive({
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      transportStatusEnum: transportStatusEnum,
      listLoading: false,
      exportLoading: false,
      planList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 渠道列表
      channelList: [],
      searchState: {
        planStatus: "",
        logisticsId: null,
        queryTimeType: '1',
        startCreationTime: null,
        endCreationTime: null,
        shopId: '',
        searchKey: null,
        planName: null,
        planNoes: [],
        inOrOutWarehouseNo: null,
        isLoseMoney: null,
        boxNoes: [],
      },
      searchStateCache: {},
      partialReceiptState: {
        visible: false,
        loading: false,
        menuCode: null,
        planId: null,
        loseMoney: null,
      },
      refundAndCompensationStatusList: [
        {
          label: 'common.yes',
          value: 1
        },
        {
          label: 'common.no',
          value: 0
        },
      ],
    });

    const tableTotalState = reactive({
      boxes: 0,
      forecastVolume: 0,
      forecastWeight: 0,
      billingVolume: 0,
      billingWeight: 0,
      currencySymbolList: [],
      totalCostList: [],
    })

    const getTotalCostStr = computed(() => {
      let str = vueI18n.t("logistics.total_cost") + ": ";
      if (tableTotalState.currencySymbolList.length > 0) {
        tableTotalState.currencySymbolList.forEach((item, index) => {
          if (index !== 0) {
            str += ", ";
          }
          str += filters.formatCurrencyAmount(item, tableTotalState.totalCostList[index], false);
        })
      } else {
        str += "-";
      }
      return str;
    })

    const getTotalData = () => {
      let [boxes, forecastVolume, forecastWeight, billingVolume, billingWeight] = new Array(5).fill(0);
      let currencySymbolList = Array.from(new Set(state.planList.map(item => item.chargeableInfo?.fee?.currencySymbol).filter(Boolean)));
      let totalCostList = new Array(currencySymbolList.length).fill(0);
      state.planList.forEach(item => {
        boxes += item.transportBoxCount ?? 0;
        forecastVolume += item.transportBoxesTotal?.totalContainerVolume ?? 0;
        forecastWeight += item.transportBoxesTotal?.totalContainerWeight ?? 0;
        billingVolume += item.chargeableInfo?.chargeableVolume ?? 0;
        billingWeight += item.chargeableInfo?.chargeableWeight ?? 0;
        if (item.chargeableInfo?.fee?.totalPrice) {
          let symbolIndex = currencySymbolList.findIndex(symbol => item.chargeableInfo?.fee?.currencySymbol === symbol);
          if (symbolIndex > -1) {
            totalCostList[symbolIndex] += item.chargeableInfo.fee?.totalPrice ?? 0;
          }
        }
      });
      
      tableTotalState.boxes = boxes;
      tableTotalState.forecastVolume = cmCubicToM(forecastVolume);
      tableTotalState.forecastWeight = gToKg(forecastWeight);
      tableTotalState.billingVolume = cmCubicToM(billingVolume);
      tableTotalState.billingWeight = gToKg(billingWeight);
      tableTotalState.currencySymbolList = currencySymbolList;
      tableTotalState.totalCostList = totalCostList.map(item => item.toFixed(2));
    }

    const handleSelectValueChange = (key) => {
      let value = state.searchState[key];
      if (Array.isArray(value)) {
        state.searchState[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handleSelectedDate = (e) => {
      let startCreationTime = null
      let endCreationTime = null
      if (e.length === 2) {
        startCreationTime = currentTimeToUtc(e[0]?.format('YYYY-MM-DD 00:00:00'));
        endCreationTime = currentTimeToUtc(e[1]?.format('YYYY-MM-DD 00:00:00'));
      }
      state.searchState.startCreationTime = startCreationTime
      state.searchState.endCreationTime = endCreationTime
    }

    const handleShowPinModal = (code) => {
      state.partialReceiptState.visible = true
      state.partialReceiptState.menuCode = code
    }

    const handleExport = (pinCode) => {
      const searchData = Object.assign({}, state.searchStateCache, { pinCode })
      searchData.isLoseMoney = searchData.isLoseMoney === 1 ? true : searchData.isLoseMoney === 0 ? false : null

      state.partialReceiptState.loading = true
      let url = exportSelfManage();
      downloadFile(url, `customer_transport_plan_${dateString()}.csv`, "POST", searchData).then(() => {
        state.partialReceiptState.visible = false
        message.success(vueI18n.t('common.succeed'))
      }).catch((err) => {
      }).finally(() => {
        state.partialReceiptState.loading = false
      })
    }

    const handleExportWarehouseReceipt = () => {
      let printUrl = batchSeInWarehouseOrder({ planIds: state.selectedRowKeys });
      localPrint(printUrl, "pdf");
    }

    const handlePrintBill = () => {
      let printUrl = printTransportBill({ planIds: state.selectedRowKeys });
      localPrint(printUrl, "pdf");
    }

    const handleExportBill = () => {
      state.listLoading = true;
      let url = exportTransportBill({ planIds: state.selectedRowKeys })
      downloadFile(url, `customer_transport_plan_bill_list_${dateString()}.xlsx`, "GET")
        .then(() => {
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        })
    }

    const isDisabledRowSelect =  (record, selectedRow) => {
      if (record.status === transportStatusEnum.canceled) {
        return true;
      }
      if (selectedRow) {
        return !(record.shopNo === selectedRow.shopNo && record.logisticsWarehouseId === selectedRow.logisticsWarehouseId);
      } else {
        return false;
      }
    }

    const handleSetTableDisabled = (selectedRow) => {
      let cacheTable = state.planList.map(item => {
        item.disabled = isDisabledRowSelect(item, selectedRow);
        return item;
      });
      state.planList = cacheTable;
     }

    const onTableSelectedChange = (selectedRowKeys, selectedRows) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;

      let selectedRow = selectedRows.length > 0 ? selectedRows[0] : null;
      handleSetTableDisabled(selectedRow);
    }

    const totalNumberAvailable = computed(() => {
      return state.planList.filter(item => !item.disabled).length;
    })

    const tableColumnTitle = () => {
      return h(
        Checkbox, {
          onChange: (e) => {
            let checked = e.target.checked;
            
            if (checked) {
              if (state.selectedRowKeys.length === 0) {
                // 未有选中项，判断第一个可选项
                let firstAvailableItem = state.planList.find(item => item.status !== transportStatusEnum.planing && item.status !== transportStatusEnum.canceled);
                // 根据第一项标记所有可选项
                handleSetTableDisabled(firstAvailableItem);
              }
              // 然后把所有可选的标记为选中
              state.selectedRowKeys = state.planList.filter(item => !item.disabled).map(item => item.planId);
              state.selectedRows = state.planList.filter(item => !item.disabled);
            } else {
              state.selectedRowKeys = [];
              state.selectedRows = [];
              handleSetTableDisabled(null);
            }
          },
          indeterminate: state.selectedRowKeys.length > 0 && state.selectedRowKeys.length < totalNumberAvailable.value,
          checked: state.selectedRowKeys.length === totalNumberAvailable.value && totalNumberAvailable.value !== 0,
          disabled: totalNumberAvailable.value === 0,
        }
      );
    }

    const handlePage = (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    }

    const handleFilterSearch = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      Object.assign(state.searchStateCache, state.searchState)
      handleSearch();
    }

    const handleSearch = () => {
      const searchData = Object.assign({}, state.pageData, state.searchStateCache, {
        isNeedTransportBoxesTotal: true,
      })
      searchData.isLoseMoney = searchData.isLoseMoney === 1 ? true : searchData.isLoseMoney === 0 ? false : null

      state.listLoading = true;

      getSelfManagePlanPaged(searchData)
        .then((res) => {
          let { items, totalCount } = res.result;
          state.planList = items.map((x, index) => {
            return {
              key: index + 1,
              planId: x.id,
              planName: x.planName,
              planNo: x.planNo,

              inWarehouseNo: x.inWarehouseNo,
              toInWarehouseNo: x.toInWarehouseNo,
              outWarehouseNo: x.outWarehouseNo,
              shopName: x.shopName,
              shopId: x.shopId,
              shopNo: x.shopNo,
              shopMark: x.shopMark,
              transportRoute: x.transportRoutes,
              destinationType: x.destinationType,
              toCountryCnName: x.toAddress?.countryCnName,
              toCountryEnName: x.toAddress?.countryEnName,
              toCityCnName: x.toAddress?.cityCnName,
              toCityEnName: x.toAddress?.cityEnName,
              transportAmount: x.transportCount,
              transportBoxCount: x.transportBoxCount,
              cancelTransportBoxCount: x.cancelTransportBoxCount,
              logisticsScheme: x.logisticsScheme,
              receivingPoint: x.collectionOutletInfo?.collectionOutletsName,
              status: x.planStatus,
              countdownPaidSeconds: x.countdownPaidSeconds,
              logisticsType: x.logisticsType,
              creationTime: x.creationTime,
              updateTime: x.lastModificationTime,
              createPlanStatus: x.planDraftStatus,
              expectArriveTime: x.expectArriveTime,
              planStatus: x.planStatus,
              logisticsWarehouseNo: x.logisticsWarehouseNo,
              logisticsWarehouseId: x.logisticsWarehouseId,
              isShowCancel: x.isShowCancel,
              isShowEndCollection: x.isShowEndCollection,
              isPayOnArrival: x.isPayOnArrival,
              currencySymbol: x.currencySymbol,
              loseMoney: x.loseMoney,
              isShowLoseMoney: x.isShowLoseMoney,
              logisticsNode: x.logisticsNode,
              isUploadHsInfo: x.isUploadHsInfo,
              transportBoxesTotal: x.transportBoxesTotal,
              chargeableInfo: x.chargeableInfo,
              disabled: x.planStatus === transportStatusEnum.canceled ? true: false,
            }
          })
          state.pageData.totalCount = parseInt(totalCount);
        })
        .finally(() => {
          state.selectedRowKeys = [];
          state.selectedRows = [];
          state.listLoading = false;
          getTotalData();
        });
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    // 客户取消发运
    const handleCancelOrder = (pinCode) => {
      state.partialReceiptState.loading = true;
      const data = {
        planId: state.partialReceiptState.planId,
        pinCode
      }
      consoleCancel(data).then(() => {
        message.success(vueI18n.t('common.succeed'));
        state.partialReceiptState.visible = false;
        handleSearch();
      }).catch(() => { }).finally(() => {
        state.partialReceiptState.loading = false
      })
    }

    // 结束收货
    const handleStopCollecting = (pinCode) => {
      state.partialReceiptState.loading = true;
      const data = {
        planId: state.partialReceiptState.planId,
        pinCode
      }
      partialReceipt(data).then(() => {
        message.success(vueI18n.t('common.succeed'));
        state.partialReceiptState.visible = false;
        handleSearch();
      }).catch(() => { }).finally(() => {
        state.partialReceiptState.loading = false
      })
    }

    // 赔偿
    const handleLoseOrderMoney = (pinCode) => {
      const postData = {
        planId: state.partialReceiptState.planId,
        loseMoney: state.partialReceiptState.loseMoney,
        pinCode,
      }
      state.partialReceiptState.loading = true
      loseMoneyTransport(postData).then(() => {
        message.success(vueI18n.t("common.succeed"))
        state.partialReceiptState.visible = false
        handleSearch()
      }).catch(() => { }).finally(() => {
        state.partialReceiptState.loading = false
      })
    }

    const handleShowNextModal = (loseMoney) => {
      state.partialReceiptState.loseMoney = loseMoney
      state.partialReceiptState.visible = true
    }

    const handlePinCodeModalCallback = (pinCode) => {
      switch (state.partialReceiptState.menuCode) {
        case 1:
          handleCancelOrder(pinCode)
          break;
        case 2:
          handleStopCollecting(pinCode)
          break;
        case 3:
          handleLoseOrderMoney(pinCode)
          break;
        case 10:
          handleExport(pinCode)
          break;
        default:
          break;
      }
    }

    const handleOperateMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      state.partialReceiptState.planId = record.planId;
      state.partialReceiptState.menuCode = key;

      if (key === 3) {
        state.partialReceiptState.loseMoney = null
        loseMoneyModalRef.value.open(record.currencySymbol)
      }else if(key === 4){
        handleShowTrackModal(record)
      }else {
        state.partialReceiptState.visible = true;
      }
    }

     const trackModal = reactive({
      visible: false,
      loading: false,
      planId: null,
      warehouseId: null,
      list: [],
      no: null,
    })

     const handleShowTrackModal = (record) => {
        trackModal.visible=true;
        trackModal.planId=record.planId;
        trackModal.warehouseId=record.logisticsWarehouseId;
        trackModal.no=record.planNo;
        funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    // 获取所有物流渠道
    const getAllChannel = () => {
      state.channelLoading = true;
      getLogisticsValidBasicData().then(({ result }) => {
        if (Array.isArray(result)) {
          let channelList = result.sort((a, b) => {
            if (a.countryCnName === b.countryCnName) {
              return a.name.localeCompare(b.name);
            }
            return a.countryCnName.localeCompare(b.countryCnName)
          });
          state.channelList = channelList;
        } else {
          state.channelList = [];
        }
      }).catch(() => {}).finally(() => {
        state.channelLoading = false;
      });
    }

    const disabledPrintBill = computed(() => {
      let result = false;
      for (let i = 0; i < state.selectedRows.length; i++) {
        if (state.selectedRows[i].planStatus < transportStatusEnum.awaitPay) {
          result = true;
          break;
        }
      }
      return result;
    })

    // onActivated(async () => {
    //   if (route.query.planstatus) {
    //     state.planStatus = route.query.planstatus;
    //   }
    //   handleSearch();
    // })

    onMounted(() => {
      getAllChannel();
      handleSearch();
    });

    return {
      ...toRefs(state),
      cmCubicToM,
      gToKg,
      logisticsWayEnum,
      logisticsAccountingPeriodCalculationWayEnum,
      logisticsCalcMethodEnum,
      columns,
      loseMoneyModalRef,
      tableTotalState,
      getTotalCostStr,
      onTableSelectedChange,

      handleSelectValueChange,
      handleSelectedDate,
      handleShowPinModal,
      handleExportWarehouseReceipt,
      handlePrintBill,
      handleExportBill,
      tableColumnTitle,
      handlePage,
      handleSearch,
      getLanguageName,
      handleFilterSearch,
      handleOperateMenuClick,
      handlePinCodeModalCallback,
      handleShowNextModal,

      trackModal,
      handleShowTrackModal,
      disabledPrintBill,
    };
  }
})
</script>

<style lang="less" scoped>
:deep(.ant-tabs-bar) {
  margin-bottom: 8px;
}

.total-cost {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total-cost-item+.total-cost-item::before{
  content: ', '
}
</style>
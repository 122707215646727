<template>
    <Content>
        <template #contentTitle>
            {{ $t('menu.special_region') }}
        </template>
        <template v-slot:contentBody="wrap">
            <div class="lg-row border-b pb-3 mb-3">
                <div class="content"></div>
                <div class="actions">
                    <a-button size="small">
                        <router-link :to="{ name: 'special_region_edit' }">
                            <span>+</span>
                            <small>{{ $t('common.addition') }}</small>
                        </router-link>
                    </a-button>
                </div>
            </div>
            <div>
                <a-table
                    :columns="columns"
                    size="small"
                    :data-source="tableData.tableList"
                    :pagination="false"
                    :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
                    :rowKey="(record, index) => { return index; }"
                    :loading="tableData.loading"
                >
                    <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
                    <template v-slot:regionName="{ record }">
                        <div>{{ record.name }}</div>
                    </template>
                    <template v-slot:logistics="{ record }">
                    <div v-if="checkArrAny(record.specialRegionLogisticsRls)">
                            <span class="items-wrap">
                                <small
                                    v-for="logistics in record.specialRegionLogisticsRls"
                                    :key="logistics.countryId"
                                    class="mr-1 d-inline-block"
                                >{{ logistics.logisticsCode }}({{logistics.logisticsName}})</small>
                                <small v-if="record.hasMoreLogistics">...</small>
                            </span>
                            <div>
                                <a @click="handleViewSpecialRegions(record.id)">
                                    <small>{{ $t('查看') }}</small>
                                </a>
                            </div>
                    </div>
                    <span v-else>-</span>
                    </template>
                    <template v-slot:regions="{ record }">
                        <div
                            v-if="record.specialRegionCountryRls
                            && record.specialRegionCountryRls.length > 0
                            && (record.regionType & regionTypeEnum.country) > 0"
                        >
                            <small class="mr-1">{{ $t('common.country') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="country in record.specialRegionCountryRls"
                                    :key="country.countryId"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: country.countryCnName, enName: country.countryEnName }) }}</small>
                                <small v-if="record.hasMoreCountryRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.specialRegionProvinceRls
                            && record.specialRegionProvinceRls.length > 0
                            && (record.regionType & regionTypeEnum.province) > 0"
                        >
                            <small class="mr-1">{{ $t('common.province') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="province in record.specialRegionProvinceRls"
                                    :key="province.provinceId"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: province.provinceCnName, enName: province.provinceEnName }) }}({{ getLanguageName({ cnName: province.countryCnName, enName: province.countryEnName }) }})</small>
                                <small v-if="record.hasMoreProvinceRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.specialRegionCityRls
                            && record.specialRegionCityRls.length > 0
                            && (record.regionType & regionTypeEnum.city) > 0"
                        >
                            <small class="mr-1">{{ $t('common.city') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="city in record.specialRegionCityRls"
                                    :key="city.cityId"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: city.cityCnName, enName: city.cityEnName }) }}({{ getLanguageName({ cnName: city.countryCnName, enName: city.countryEnName }) }})</small>
                                <small v-if="record.hasMoreCityRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.specialRegionCountyRls
                            && record.specialRegionCountyRls.length > 0
                            && (record.regionType & regionTypeEnum.county) > 0"
                        >
                            <small class="mr-1">{{ $t('common.county') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="county in record.specialRegionCountyRls"
                                    :key="county.countyId"
                                    class="mr-1 d-inline-block"
                                >{{ getCountyName({ cnName: county.countyCnName, enName: county.countyEnName, cityCnName: county.cityCnName, cityEnName: county.cityEnName }) }}({{ getLanguageName({ cnName: county.countryCnName, enName: county.countryEnName }) }})</small>
                                <small v-if="record.hasMoreCountyRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.specialRegionPostCodeRls
                            && record.specialRegionPostCodeRls.length > 0
                            && (record.regionType & regionTypeEnum.post) > 0"
                        >
                            <small class="mr-1">{{ $t('common.post_code') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="(postCode, index) in record.specialRegionPostCodeRls"
                                    :key="index"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: postCode.countryCnName, enName: postCode.countryEnName }) }}{{ postCode.postStartWith }}开头</small>
                                <small v-if="record.hasMorePostCodeRl">...</small>
                            </span>
                        </div>
                        <!-- <div v-if="record.hasMoreRegionRl">
                            <a @click="handleViewSpecialRegions(record.id)">
                                <small>{{ $t('更多') }}</small>
                            </a>
                        </div>-->
                        <div>
                            <a @click="handleViewSpecialRegions(record.id)">
                                <small>{{ $t('查看') }}</small>
                            </a>
                        </div>
                    </template>
                    <template v-slot:regionTags="{ record }">
                        <div v-for="tag in record.regionTags" :key="tag">
                            <small>{{ $t($enumLangkey("regionTag", tag)) }}</small>
                        </div>
                    </template>
                    <template v-slot:operation="{ record }">
                        <a-dropdown-button @click="handleButtonClick(record)">
                            {{ $t('logistics.config') }}
                            <template #overlay>
                                <a-menu @click="handleMenuClick">
                                  <a-menu-item key="1" :record="record">{{ $t('common.delete') }}</a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown-button>
                    </template>
                </a-table>
            </div>
            <a-modal
                v-model:visible="viewModalData.visible"
                :title="'分区信息'"
                :width="600"
                :footer="null"
                @cancel="handleCloseViewSpecialRegionsModal"
                :destroyOnClose="true"
            >
                <a-spin size="small" :spinning="viewModalData.loading">
                    <div v-if="viewModalData.regionInfo && viewModalData.regionInfo.id">
                        <a-descriptions :column="1">
                            <a-descriptions-item label="分区名称">{{ viewModalData.regionInfo.name }}</a-descriptions-item>
                            <a-descriptions-item label="物流渠道">
                                <div class="items-wrap">
                                    <small
                                        v-for="logistics in viewModalData.regionInfo.specialRegionLogisticsRls"
                                        :key="logistics.logisticsId"
                                        class="mr-1 d-inline-block"
                                    >{{ logistics.logisticsCode }}({{ logistics.logisticsName }})</small>
                                </div>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.country)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.country') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.specialRegionCountryRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="country in viewModalData.regionInfo.specialRegionCountryRls"
                                        :key="country.countryId"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: country.countryCnName, enName: country.countryEnName }) }}</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.province)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.province') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.specialRegionProvinceRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="province in viewModalData.regionInfo.specialRegionProvinceRls"
                                        :key="province.provinceId"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: province.provinceCnName, enName: province.provinceEnName }) }}({{ getLanguageName({ cnName: province.countryCnName, enName: province.countryEnName }) }})</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.city)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.city') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.specialRegionCityRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="city in viewModalData.regionInfo.specialRegionCityRls"
                                        :key="city.cityId"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: city.cityCnName, enName: city.cityEnName }) }}({{ getLanguageName({ cnName: city.countryCnName, enName: city.countryEnName }) }})</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.county)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.county') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.specialRegionCountyRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="county in viewModalData.regionInfo.specialRegionCountyRls"
                                        :key="county.countyId"
                                        class="mr-1 d-inline-block"
                                    >{{ getCountyName({ cnName: county.countyCnName, enName: county.countyEnName, cityCnName: county.cityCnName, cityEnName: county.cityEnName }) }}({{ getLanguageName({ cnName: county.countryCnName, enName: county.countryEnName }) }})</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.post)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.post_code') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.specialRegionPostCodeRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="(postCode, index) in viewModalData.regionInfo.specialRegionPostCodeRls"
                                        :key="index"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: postCode.countryCnName, enName: postCode.countryEnName }) }}{{ postCode.postStartWith }}开头</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                    <a-empty v-else :image="simpleImage" />
                </a-spin>
            </a-modal>
        </template>
        <template #contentFooter>
            <CPager class="text-center" @handlePage="handlePage" :page-data="tableData.pageData"></CPager>
        </template>
    </Content>
</template>

<script>
import { Table, Button, Row, Col, Popconfirm, Modal, Empty, Spin, Descriptions, Dropdown, Menu, message } from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue'
import { useRoute, useRouter } from "vue-router";
import { defineComponent, reactive, onActivated, } from 'vue'
import { getPagedList, deleteById, getById } from '../../api/modules/logistics/specialregion'
import { getName } from "../../utils/general"
import { useStore } from "vuex"
import { useI18n } from 'vue-i18n/index'
import { logisticsRegionType as regionTypeEnum, regionTag as regionTagEnum } from '../../enum/enum.json'

export default defineComponent({
    name: "special_region",
    components: {
        ATable: Table,
        AButton: Button,
        CPager,
        Content,
        ARow: Row,
        ACol: Col,
        APopconfirm: Popconfirm,
        AModal: Modal,
        AEmpty: Empty,
        ASpin: Spin,
        ADescriptions: Descriptions,
        ADescriptionsItem: Descriptions.Item,
        ADropdownButton: Dropdown.Button,
        AMenu: Menu,
        AMenuItem: Menu.Item,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { getters } = useStore();
        const vueI18n = useI18n({ useScope: 'global' });
        const logisticsId = route.params.logisticsId;

        const columns = [
            {
                width: 50,
                fixed: "left",
                title: () => "序号",
                slots: {
                    customRender: "tableIndex"
                }
            },
            {
                dataIndex: "name",
                width: 150,
                fixed: "left",
                title: () => "分区名称",
                slots: {
                    customRender: "regionName"
                }
            },
            {
                width: 200,
                title: () => "物流渠道",
                slots: {
                    customRender: "logistics"
                }
            },
            {
                width: 200,
                title: () => "适用区域",
                slots: {
                    customRender: "regions"
                }
            },
            {
                width: 150,
                title: () => "特殊区域",
                slots: {
                    customRender: "regionTags"
                }
            },
            {
                width: 80,
                title: () => '',
                slots: {
                    customRender: "operation",
                }
            },

        ];

        const tableData = reactive({
            loading: false,
            tableList: [],
            pageData: {
                currentIndex: 1,
                skipCount: 0,
                totalCount: 0,
                maxResultCount: 10
            },
            searchData: {

            }
        });

        const viewModalData = reactive({
            visible: false,
            loading: false,
            regionId: null,
            regionInfo: {}
        });

        const getLanguageName = (item) => {
            return getName(item, getters.language);
        }

        const getCountyName = (item) => {
            let countyName = getLanguageName(item);
            let cityName = getLanguageName({ cnName: item.cityCnName, enName: item.cityEnName })
            return cityName
                ? (countyName + "-" + cityName)
                : countyName;
        };

        const getSpecialRegionList = () => {
            tableData.loading = true;
            getPagedList({
                logisticsId: logisticsId,
                skipCount: tableData.pageData.skipCount,
                maxResultCount: tableData.pageData.maxResultCount,
            })
                .then((res) => {
                    let { totalCount, items } = res.result;
                    tableData.pageData.totalCount = parseInt(totalCount);
                    tableData.tableList = items;
                    tableData.loading = false;
                }).catch(() => {
                    tableData.loading = false;
                })
        }

        const getSpecialRegionInfo = () => {
            if (viewModalData.regionId) {
                viewModalData.loading = true;
                getById(viewModalData.regionId)
                    .then((res) => {
                        viewModalData.loading = false;
                        let { result } = res;
                        viewModalData.regionInfo = result;
                    }).catch(() => {
                        viewModalData.loading = false;
                    })
            } else {
                viewModalData.regionInfo = {};
            }
        }

        const handlePage = (pageData) => {
            tableData.pageData.skipCount = pageData.skipCount;
            tableData.pageData.maxResultCount = pageData.maxResultCount;
            getSpecialRegionList();
        };

        const handleRefresh = () => {
            tableData.pageData.skipCount = 0;
            tableData.pageData.currentIndex = 1;
            getSpecialRegionList();
        };

        const handleViewSpecialRegions = (id) => {
            // if (viewModalData.regionId != id) {
            //     viewModalData.regionId = id;
            //     getSpecialRegionInfo();
            // }

            viewModalData.regionId = id;
            getSpecialRegionInfo();
            viewModalData.visible = true;
        }

        const handleCloseViewSpecialRegionsModal = ()=>{
            viewModalData.regionId = null;
            viewModalData.regionInfo = {};
        }

        const checkArrAny = (arr) => {
            return arr && arr.length > 0;
        }

        const checkHasType = (item, regionType) => {
            if (item && item.regionTypes && item.regionTypes.length > 0) {
                return item.regionTypes.findIndex(x => x == regionType) >= 0;
            }
            return false;
        }

        const handleButtonClick = (record) => {
            router.push({ name: 'special_region_edit', params: { id: record.id } })
        }

        const handleMenuClick = (e) => {
            let { key, item } = e
            let { record } = item
            switch (key) {
                case '1':
                    Modal.confirm({
                        title: vueI18n.t("common.operate"),
                        content: vueI18n.t("common.are_you_sure_you_want_to_delete_this_item"),
                        okText: vueI18n.t("common.confirm"),
                        cancelText: vueI18n.t("common.cancel"),
                        onOk: () => {
                            return deleteById(record.id).then(() => {
                                message.success(vueI18n.t("common.succeed"));
                                getSpecialRegionList();
                            });
                        },
                    });
                    break;
                default:
                    break;
            }
        }

        onActivated(() => {
            getSpecialRegionList();
        });
        return {
            logisticsId,
            regionTypeEnum,

            columns,
            tableData,
            viewModalData,

            handlePage,
            handleRefresh,
            handleViewSpecialRegions,
            handleCloseViewSpecialRegionsModal,
            getLanguageName,
            getCountyName,
            checkArrAny,
            checkHasType,
            handleButtonClick,
            handleMenuClick,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
        }
    }
})
</script>
<style lang="less" scoped>
.border-b {
    border-bottom: 1px solid #f0f0f0;
}
.lg-row {
    display: flex;
    flex-wrap: wrap;
    // justify-content: stretch;
    align-items: stretch;
    .content {
        flex-grow: 1;
    }
    .actions {
        flex-grow: 0;
    }
}
.items-wrap > *::after {
    content: ",";
    display: inline-block;
}
.items-wrap > *:last-child::after {
    content: none;
}
</style>
import client from "../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";
const Api = {
    //获取中转仓入库预约列表
    getTransitInBounds: "/api/WarehouseService/TransitConsoleInBound/GetPagedList",
    //获得中转仓仓库列表
    getWarehouseList: "/api/WarehouseService/Warehouse/GetList",
    //获得入库预约详情
    getInBoundDetails: "/api/WarehouseService/TransitConsoleInBound/Get",
    //获取入库预约货件列表
    getShipmentsOfInBound: "/api/WarehouseService/TransitConsoleInBound/GetShipmentPagedList",
    //获取货件箱
    getBoxesOfShipment: "/api/WarehouseService/TransitConsoleInBound/GetShipmentPagedList",
    //获取入库预约箱列表
    getBoxesOfInBound: "/api/WarehouseService/TransitConsoleInBound/GetShipmentBoxPagedList",
    // 中转仓出库-列表
    getTransitOutBoundConsolePagedList: "/api/WarehouseService/TransitOutBoundConsole/GetPagedList",
    // 预约审核操作
    auditTransitWareInBound: "/api/WarehouseService/TransitConsoleInBound/Audit",

    getPrintAppointmentLetterUrl: "/api/WarehouseService/TransitShipmentPrint/GetTuckAppointmentLetter/tuckAppointmentLetter.pdf",

    getShipmentDetailList: "/api/WarehouseService/TransitOutBoundConsole/GetShipmentDetailList",
    cancelOutPlan: "/api/WarehouseService/TransitOutBoundConsole/Cancel",
    getDetail: "/api/WarehouseService/TransitOutBoundConsole/GetById",

    // 控制台-中转仓-换标任务列表
    getTransitsLabelChangeTaskListPaged: "/api/WarehouseService/TransitsLabelChangeTask/GetListPaged",
    // 控制台-中转仓-换标任务详情
    getTransitsLabelChangeTaskById: "/api/WarehouseService/TransitsLabelChangeTask/GetById",
    // 控制台-中转仓-换标任务箱列表
    getTransitsLabelChangeTaskDetailList: "/api/WarehouseService/TransitsLabelChangeTaskDetail/GetList",
    // 控制台-中转仓-换标任务取消
    cancelTransitsLabelChangeTaskConsole: "/api/WarehouseService/TransitsLabelChangeTaskConsole/Cancel",
    // 控制台-中转仓-换标任务箱取消
    cancelTransitsLabelChangeTaskConsoleBox: "/api/WarehouseService/TransitsLabelChangeTaskConsole/CancelBox",
  };

  export const getPrintAppointmentLetterUrl = ( id ) => {
    let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getPrintAppointmentLetterUrl);
    url = urlHelper.addQueryString(url, "planId", id);
    return url;
  };

  export const getDetail = (id) => {
    return client.request({
      url: Api.getDetail,
      params: { id },
      method: "get",
    });
  };

  export const getShipmentDetailList = (data) => {
    return client.request({
      url: Api.getShipmentDetailList,
      data,
      method: "POST",
    });
  };

  export const cancelOutPlan = (id) => {
    return client.request({
      url: Api.cancelOutPlan,
      data: { id },
      method: "post",
    });
  };

  export const auditTransitWareInBound = (data) => {
    return client.request({
      url: Api.auditTransitWareInBound,
      data,
      method: "POST",
    });
  };

//获得中转仓仓库列表
export const getWarehouseList = ({ isActive, warehouseType = 8 }) => {
  return client.request({
    url: Api.getWarehouseList,
    data: {
      warehouseType,
      isActive,
    },
    method: "post",
  });
};

//获取中转仓入库预约列表
export const getTransitInBounds = ({
    planNo,
    planNoes,
    planName,
    boxNoes,
    warehouseId,
    beginWareZoneAppointmentDate,
    endWareZoneAppointmentDate,
    seShipmentNoes,
    shipmentNameOrNo,
    shopId,
    transitStatus,
    transportationMethod,
    beginCreationTime,
    endCreationTime,
    skipCount,
    maxResultCount,
    sorting
})=>{
    return client.request({
      url: Api.getTransitInBounds,
      data: {
        seShipmentNoes,
        shipmentNameOrNo,
        planNo,
        planNoes,
        planName,
        boxNoes,
        warehouseId,
        beginWareZoneAppointmentDate,
        endWareZoneAppointmentDate,
        shopId,
        transitStatus,
        transportationMethod,
        beginCreationTime,
        endCreationTime,
        skipCount,
        maxResultCount,
        sorting
      },
      method: "post",
    });
}

export const getTransitOutBoundConsolePagedList = (data) => {
  return client.request({
    url: Api.getTransitOutBoundConsolePagedList,
    data,
    method: "POST",
  });
};

export const getTransitsLabelChangeTaskListPaged = (data) => {
  return client.request({
    url: Api.getTransitsLabelChangeTaskListPaged,
    data,
    method: "POST",
  });
};

export const getTransitsLabelChangeTaskById = (params) => {
  return client.request({
    url: Api.getTransitsLabelChangeTaskById,
    params,
    method: "GET",
  });
};

export const getTransitsLabelChangeTaskDetailList = (data) => {
  return client.request({
    url: Api.getTransitsLabelChangeTaskDetailList,
    data,
    method: "POST",
  });
};

export const cancelTransitsLabelChangeTaskConsole = (data) => {
  return client.request({
    url: Api.cancelTransitsLabelChangeTaskConsole,
    data,
    method: "POST",
  });
};

export const cancelTransitsLabelChangeTaskConsoleBox = (data) => {
  return client.request({
    url: Api.cancelTransitsLabelChangeTaskConsoleBox,
    data,
    method: "POST",
  });
};
<template>
  <a-popover v-if="employee">
    <template #content>
      <a-row :gutter="[16, 16]" type="flex" align="middle">
        <a-col>
          <CFileDisplay
            v-if="employee?.imageUrl"
            :fileUrl="employee.imageUrl"
          ></CFileDisplay>
          <a-avatar v-else shape="square">
            <template #icon><UserOutlined /></template>
          </a-avatar>
        </a-col>
        <a-col type="flex">
          <div>{{ getEmployeeName }}</div>
          <div>{{ employee?.employeeNo }}</div>
        </a-col>
      </a-row>
    </template>

    <div class="employee-wrap">
      <a-row type="flex" align="middle" :gutter="[4, 4]">
        <a-col>
          <div class="img-wrap" v-if="employee.imageUrl">
            <CImage
              :src="employee.imageUrl"
              :thumbWidth="25"
              :thumbHeight="25"
              :preview="false"
            ></CImage>
          </div>
          <a-avatar v-else shape="square">
            <template #icon><UserOutlined /></template>
          </a-avatar>
        </a-col>
        <a-col>
          <div class="employee-no">
            {{ employee?.employeeNo }}
          </div>
        </a-col>
      </a-row>
    </div>
  </a-popover>
</template>

<script>
import { computed, defineComponent } from "vue";
import { Avatar, Col, Popover, Row, Tag, Typography } from "ant-design-vue";
import { useStore } from "vuex";
import { getNameByLanguage } from "@/utils/general.js";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import CImage from "@/views/components/CImage.vue";

export default defineComponent({
  props: {
    employee: {
      type: Object,
      required: true,
    },
    // employee对象必有字段:
    // employee:{
    //   imageUrl:http:XXXX,
    //   employeeNo:EP00000001,
    //   firstName:陈,
    //   middleName:优,
    //   lastName:秀
    // }
  },
  components: {
    CImage,
    CFileDisplay,
    ARow: Row,
    ACol: Col,
    AAvatar: Avatar,
    ATag: Tag,
    APopover: Popover,
    ATypographyText: Typography.Text,
  },
  setup(props) {
    const { getters } = useStore();

    const getEmployeeName = computed(() => {
      if (props.employee) {
        return getNameByLanguage(props.employee, getters.language);
      }
      return "-";
    });

    return {
      getEmployeeName,
    };
  },
});
</script>

<style lang="less" scoped>
.employee-wrap {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 2px;
  width: 130px;
  display: flex; /* 保持行内元素不换行 */
  align-items: center; /* 垂直居中 */
}

.img-wrap {
  width: 30px; /* 容器的宽度 */
  height: 30px; /* 容器的高度 */
  overflow: hidden; /* 隐藏超出容器的部分 */
  border: 1px solid #f0f0f0; /* 添加2px的浅灰色边框 */
  display: flex; /* 使图片在容器内居中 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  border-radius: 4px;
}

.employee-no {
  margin: 1px;
  white-space: nowrap; /* 阻止文本换行 */
  overflow: hidden;
  text-overflow: ellipsis; /* 如果文本过长，显示省略号 */
}
</style>

<template>
  <ACard>
    <template #title>
      {{$t('warehouse.staff_management')}}
    </template>
    <template #extra>
      <a-button size="small"
                @click="handleShowAddStaffModal">
        {{ $t('warehouse.add_staff') }}
      </a-button>
    </template>

    <template #default>
      <a-form layout="inline"
              class="mb-3">
        <a-form-item>
          <a-input v-model:value="tableData.searchKey"
                   @pressEnter="handleSearchStaffTable"
                   allow-clear
                   placeholder="姓名或员工ID"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select ref="selectNationality"
                    :allowClear="true"
                    v-model:value="tableData.selectedNationalityId"
                    style="min-width: 200px"
                    show-search
                    optionFilterProp="search-key"
                    placeholder="国籍">
            <a-select-option :value="item.id"
                             v-for="item in dropDownData.countrys"
                             :key="item.id"
                             :title="`${getLanguageName(item)}(${item.ioS2})`"
                             :search-key="item.ioS2 + item.cnName + item.enName">
              {{getLanguageName(item)}}({{ item.ioS2 }})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select ref="selectWorkCountry"
                    :allowClear="true"
                    v-model:value="tableData.selectedWorkCountryId"
                    style="min-width: 200px"
                    show-search
                    optionFilterProp="search-key"
                    placeholder="工作国家">
            <a-select-option :value="item.id"
                             v-for="item in dropDownData.countrys"
                             :key="item.id"
                             :title="`${getLanguageName(item)}(${item.ioS2})`"
                             :search-key="item.ioS2 + item.cnName + item.enName">
              {{getLanguageName(item)}}({{ item.ioS2 }})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary"
                    @click="handleButSearchTable">查询</a-button>
        </a-form-item>
      </a-form>

      <a-table :columns="columns"
               :scroll="{ x: 1000,y:470 }"
               :pagination="false"
               :data-source="tableData.tableList"
               :loading="tableData.loading"
               size="small"
               :rowKey="(record) => record.id">

        <template #nationalityCountry="{ record }">
          {{getLanguageName({ cnName:record.nationalityCountryCnName, enName:record.nationalityCountryEnName })}}
        </template>

        <template #workCountry="{ record }">
          {{getLanguageName({ cnName:record.workCountryCnName, enName:record.workCountryEnName })}}
        </template>

        <template #email="{ record }">
          {{record.employeeNo}}
          <div>
            {{record.email}}
          </div>
        </template>

        <template #departmentName="{ record }">
          {{ record.departmentName }} <a-tag color="red" v-if="record.isManager">{{ $t('account.administrator') }}</a-tag>
        </template>

        <template #jobStatu="{ record }">
          {{$t($enumLangkey('jobStatu',record.jobStatu))}}
        </template>

        <template #isActive="{ record }">
          <a-switch :loading='record.loading'
                    @click="funcUpdateStaffAccountStatus(record)"
                    v-model:checked="record.isActive" />
        </template>

        <template v-slot:operations='item'>
          <a-tooltip>
            <template #title>修改员工</template>
            <a-button type="link"
                      size="small"
                      @click="showEditStaffModal(item.record)">
              <FormOutlined  class="text-warning" />
            </a-button>
          </a-tooltip>
          <a-tooltip>
            <template #title>重置员工PIN码</template>
            <a-button type="link"
                      size="small"
                      danger
                      :loading="item.record.resetPinLoading"
                      @click="showPinCodeModal(item.record, '1')">
              <UndoOutlined />
            </a-button>
          </a-tooltip>
          <a-tooltip>
            <template #title>重置员工密码</template>
            <a-button type="link"
                      size="small"
                      :loading="item.record.resetPinLoading"
                      @click="showPinCodeModal(item.record, '2')">
              <KeyOutlined class="text-success" />
            </a-button>
          </a-tooltip>

          <!-- <a-button type="link"
                    size="small">
            <EyeOutlined />
          </a-button> -->
        </template>
        <template #footer>
          <CPager class="text-center"
                  @handlePage="handlePage"
                  :page-data="tableData.pageData"></CPager>
        </template>
      </a-table>

    </template>
  </ACard>

  <!-- 编辑员工 -->
  <a-modal v-model:visible="editStaffModal.visible"
           title="编辑员工"
           :footer="null"
           :maskClosable="false"
           @ok="updateStaffModal"
           :z-index="1000"
           width="800px">
    <a-spin :spinning="editStaffModal.modalLoading">
      <a-form :model="editStaffModal"
              ref="editStaffFormRef"
              :rules="editStaffModalRules">
        <a-row>
          <a-col :span="4">
            <a-form-item name="imageUrl">
              <a-upload name="img"
                  accept=" .jpeg,.jpg,.png"
                        list-type="picture-card"
                        :customRequest="handleUpdate"
                        :showUploadList="false">
                <div v-if="editStaffModal.imageUrl"
                     class="pabi-img">
                  <loading-outlined v-if="editStaffModal.uploading"></loading-outlined>
                  <img :src="editStaffModal.imageUrl" />
                </div>
                <div v-else>
                  <loading-outlined v-if="editStaffModal.uploading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">{{$t('common.upload')}}</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="20">
            <a-row :gutter="[16,0]">
              <a-col :span="8">
                <a-form-item :label="$t('员工ID')"
                             :label-col="{span: 9}">
                  {{editStaffModal.employeeNo}}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="$t('工作状态')"
                             :label-col="{span: 9}"
                             name="jobStatu">
                  <a-select :allowClear="true"
                            v-model:value="editStaffModal.jobStatu"
                            placeholder="状态">
                    <a-select-option :value="item"
                                     v-for="item in dropDownData.jobStatuEnum"
                                     :key="item">
                      {{$t($enumLangkey('jobStatu',item))}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="$t('账号状态')"
                             :label-col="{span: 9}">
                  <a-switch v-model:checked="editStaffModal.isActive" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="$t('姓名')"
                             :label-col="{span: 9}">
                  {{getStaffName(editStaffModal)}}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="$t('国籍')"
                             :label-col="{span: 9}">
                  {{getLanguageName({ cnName:editStaffModal.nationalityCountryCnName, enName:editStaffModal.nationalityCountryEnName })}}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="$t('出生日期')"
                             :label-col="{span: 9}">
                  <!-- {{$filters.utcToCurrentTime(editStaffModal.birthday)}} -->
                  {{editStaffModal.birthday}}
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row :gutter="[32,16]">
          <a-col :span="9">
            <a-form-item :label="$t('工作国家')"
                         :label-col="{span: 7}"
                         name="workCountryId">
              <a-select :allowClear="true"
                        show-search
                        optionFilterProp="search-key"
                        v-model:value="editStaffModal.workCountryId"
                        @change="changeWorkCountrys"
                        placeholder="国家">
                <a-select-option :value="item.id"
                                 v-for="item in dropDownData.countrys"
                                 :key="item.id"
                                 :search-key="item.ioS2 + item.cnName + item.enName"
                                 :phoneCountryCode="item.telCode"
                                 :title="`${getLanguageName(item)}(${item.ioS2})`">
                  {{getLanguageName(item)}}({{ item.ioS2 }})
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="15">
            <a-row :gutter="[8,8]">
              <a-col :span="8">
                <a-form-item :label="$t('联系电话')"
                             :label-col="{span: 12}"
                             name="phoneCountryCode">
                  <a-input placeholder="区号"
                           prefix="+"
                           v-model:value="editStaffModal.phoneCountryCode" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item name="phone">
                  <a-input placeholder="号码"
                           v-model:value="editStaffModal.phone" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>

        <a-row :gutter="[32,16]">
          <a-col :span="9">
            <a-form-item :label="$t('工作邮箱')"
                         :label-col="{span: 7}"
                         name="email">
              <a-input v-model:value="editStaffModal.email"
                       placeholder="邮箱"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="15">
            <a-row :gutter="8">
              <a-col :span="16">
                <a-form-item :label="$t('所属部门')"
                         :label-col="{span: 6}"
                         name="departmentId">
                  <a-tree-select v-model:value="editStaffModal.departmentId"
                                style="width: 100%"
                                :allowClear="true"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="dropDownData.departments"
                                placeholder="部门"
                                tree-default-expand-all>
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item>
                  <a-checkbox v-model:checked="editStaffModal.isManager">{{ $t('account.department_administrator') }}</a-checkbox>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>

        <strong>权限设置</strong>
        <div class="border-b mb-3"></div>

        <a-row :gutter="[32,16]">
          <a-col :span="20">
            <a-form-item :label="$t('后台管理员')"
                         :label-col="{span: 5}">
              <a-switch @change="funcGetRolePermissionList"
                        v-model:checked="editStaffModal.isShowManageRole" />
            </a-form-item>
          </a-col>

          <a-col :span="20">
            <a-form-item :label="$t('角色权限')"
                         :label-col="{span: 5}"
                         name="roleIds">
              <a-select :allowClear="true"
                        v-model:value="editStaffModal.roleIds"
                        mode="multiple"
                        :loading="dropDownData.roleLoading"
                        :show-search="true"
                        option-filter-prop="search-key"
                        placeholder="角色权限">
                <a-select-option :value="item.roleId"
                                 v-for="item in dropDownData.roleList"
                                 :search-key="item.roleName"
                                 :key="item.roleId">
                  {{item.roleName}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="20">
            <a-form-item :label="$t('站点权限')"
                         :label-col="{span: 5}"
                         name="warehouseIds">
              <a-select :allowClear="true"
                        mode="multiple"
                        :loading="dropDownData.warehouseLoading"
                        v-model:value="editStaffModal.warehouseIds"
                        :show-search="true"
                        option-filter-prop="search-key"
                        placeholder="站点权限">
                <a-select-option :value="item.id"
                                 v-for="item in dropDownData.warehouseList"
                                 :key="item.id"
                                 :search-key="item.warehouseNo + item.warehouseName">
                  {{item.warehouseNo}}({{item.warehouseName}})
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row type="flex"
               justify="center"
               class="mt-4">
          <a-col>
            <a-button type="primary"
                      :loading="editStaffModal.createLoading"
                      @click="updateStaffModal">保存</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
  <!-- 添加员工 -->
  <a-modal v-model:visible="addStaffModal.visible"
           :title="$t('warehouse.add_staff')"
           :footer="null"
           :maskClosable="false"
           @ok="handleAddStaffOk"
           :confirm-loading="addStaffModal.loading"
           :z-index="1000"
           width="800px">
    <a-form :model="addStaffModal"
            ref="addStaffFormRef"
            :rules="addStaffModalRules">
      <a-row>
        <a-col :span="4">
          <a-form-item name="imageUrl">
            <a-upload name="img"
                  accept=" .jpeg,.jpg,.png"
                      list-type="picture-card"
                      :customRequest="handleUpdate"
                      :showUploadList="false">
              <div v-if="addStaffModal.imageUrl"
                   class="pabi-img">
                <loading-outlined v-if="addStaffModal.uploading"></loading-outlined>
                <img :src="addStaffModal.imageUrl" />
              </div>
              <div v-else>
                <loading-outlined v-if="addStaffModal.uploading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">{{$t('common.upload')}}</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="20">
          <a-row :gutter="[16,0]">
            <a-col :span="8">
              <a-form-item v-if="getters.language == 'zh-CN'"
                           :label="$t('姓')"
                           :label-col="{span: 7}"
                           name="lastName">
                <a-input v-model:value="addStaffModal.lastName"></a-input>
              </a-form-item>
              <a-form-item v-else
                           :label="$t('名')"
                           name="firstName">
                <a-input v-model:value="addStaffModal.firstName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item :label="$t('中间名')"
                           name="middleName">
                <a-input v-model:value="addStaffModal.middleName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item v-if="getters.language != 'zh-CN'"
                           :label="$t('姓')"
                           :label-col="{span: 7}"
                           name="lastName">
                <a-input v-model:value="addStaffModal.lastName"></a-input>
              </a-form-item>
              <a-form-item v-else
                           :label="$t('名')"
                           name="firstName">
                <a-input v-model:value="addStaffModal.firstName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item :label="$t('国籍')"
                           :label-col="{span: 5}"
                           name="nationalityCountryId">
                <a-select :allowClear="true"
                          v-model:value="addStaffModal.nationalityCountryId"
                          show-search
                          optionFilterProp="search-key"
                          placeholder="国籍">
                  <a-select-option :value="item.id"
                                   v-for="item in dropDownData.countrys"
                                   :key="item.id"
                                   :search-key="item.ioS2 + item.cnName + item.enName"
                                   :title="`${getLanguageName(item)}(${item.ioS2})`">
                    {{getLanguageName(item)}}({{ item.ioS2 }})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <a-form-item :label="$t('出生日期')"
                           :label-col="{span: 9}"
                           name="selectBirthday">
                <a-date-picker :showToday="false"
                               v-model:value="addStaffModal.selectBirthday" />
              </a-form-item>

            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row :gutter="[32,16]">
        <a-col :span="9">
          <a-form-item :label="$t('工作国家')"
                       :label-col="{span: 7}"
                       name="workCountryId">
            <a-select :allowClear="true"
                      show-search
                      optionFilterProp="search-key"
                      v-model:value="addStaffModal.workCountryId"
                      @change="changeWorkCountrys"
                      placeholder="国家">
              <a-select-option :value="item.id"
                               v-for="item in dropDownData.countrys"
                               :key="item.id"
                               :search-key="item.ioS2 + item.cnName + item.enName"
                               :title="`${getLanguageName(item)}(${item.ioS2})`"
                               :phoneCountryCode="item.telCode">
                {{getLanguageName(item)}}({{ item.ioS2 }})
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="15">
          <a-row :gutter="[8,8]">
            <a-col :span="8">
              <a-form-item :label="$t('联系电话')"
                           :label-col="{span: 12}"
                           name="phoneCountryCode">
                <a-input placeholder="区号"
                         prefix="+"
                         v-model:value="addStaffModal.phoneCountryCode" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item name="phone">
                <a-input placeholder="号码"
                         v-model:value="addStaffModal.phone" />
              </a-form-item>
            </a-col>
          </a-row>

        </a-col>
      </a-row>

      <a-row :gutter="[32,16]">
        <a-col :span="9">
          <a-form-item :label="$t('工作邮箱')"
                       :label-col="{span: 7}"
                       name="email">
            <a-input v-model:value="addStaffModal.email"
                     placeholder="邮箱"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="15">
          <a-row :gutter="8">
            <a-col :span="16">
              <a-form-item :label="$t('所属部门')"
                       :label-col="{span: 6}"
                       name="departmentId">
                <a-tree-select v-model:value="addStaffModal.departmentId"
                              style="width: 100%"
                              :allowClear="true"
                              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                              :tree-data="dropDownData.departments"
                              placeholder="部门"
                              tree-default-expand-all>
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item>
                <a-checkbox v-model:checked="addStaffModal.isManager">{{ $t('account.department_administrator') }}</a-checkbox>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>

      </a-row>

      <a-row :gutter="[32,16]">
        <a-col :span="9">
          <a-form-item :label="$t('工作状态')"
                       :label-col="{span: 7}"
                       name="jobStatu">
            <a-select :allowClear="true"
                      v-model:value="addStaffModal.jobStatu"
                      placeholder="状态">
              <a-select-option :value="item"
                               v-for="item in dropDownData.jobStatuEnum"
                               :key="item">
                {{$t($enumLangkey('jobStatu',item))}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="15">
          <a-form-item :label="$t('时区')"
                       :label-col="{span: 4}"
                       name="timezone">
            <a-select :allowClear="true"
                      v-model:value="addStaffModal.timezone"
                      show-search
                      optionFilterProp="search-key"
                      placeholder="员工所在工作时区">
              <a-select-option :value="item.zoneName"
                               v-for="item in dropDownData.timezones"
                               :search-key="item.zoneName + item.countryCnName + item.countryEnName + item.gmtOffset + item.countryCode"
                               :key="item.id">
                {{item.zoneName}}({{getLanguageName({ cnName:item.countryCnName, enName:item.countryEnName })}} {{item.gmtOffset}})
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[32,16]">
        <a-col :span="9">
          <a-form-item :label="$t('账号状态')"
                       :label-col="{span: 7}">
            <a-switch v-model:checked="addStaffModal.isActive" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex"
             justify="center"
             class="mt-4">
        <a-col>
          <a-button type="primary"
                    :loading="addStaffModal.createLoading"
                    @click="handleAddStaffOk">创建</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>

  <CPinTipsModal
    v-model:visible="pinModalState.visible"
    v-model:loading="pinModalState.loading"
    @confirm="handlePinCodeConfirm"
  />

</template>

<script>
import Content from '../../components/Content.vue';
import { reactive, ref, onMounted, watch, toRefs, nextTick } from 'vue';
import CPager from '../../components/CPager.vue';
import { getNameByLanguage, getName, currentTimeToUtc, isValidGenericPhoneNumber } from "../../../utils/general.js";
import { useI18n } from "vue-i18n/index";
import { getGlobalCountrys, uploadFile } from "../../../api/modules/common/index"
import {
  getTimezones, createEmployee, updateStaff, setStaffPermission,
  updateStaffAccountStatus, getStaffInfoById, resetPinCode,
  resetPwd
} from "../../../api/modules/company/index"

import {
  Table,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Form,
  Card,
  Switch,
  Upload, DatePicker, message, TreeSelect, Spin, Tooltip,
  Checkbox, Tag
} from 'ant-design-vue';
import {
  getEmployeeListPaged as getTableList, getRolePermissionList
} from '../../../api/modules/company/index.js';
import {
  getAllWarehouses
} from '../../../api/modules/warehouse/index.js';
import { useStore } from "vuex";
import CImage from '../../components/CImage.vue';
import CPinTipsModal from '@/views/components/CPinTipsModal.vue'
import { jobStatu } from '../../../enum/enum.json';
import moment from "moment-timezone";

export default {
  components: {
    ATable: Table,
    AButton: Button,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ACard: Card,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    AUpload: Upload,
    ADatePicker: DatePicker,
    ATreeSelect: TreeSelect,
    ASpin: Spin,
    ATooltip: Tooltip,
    ACheckbox: Checkbox,
    ATag: Tag,
    Content,
    CPager,
    CImage,
    CPinTipsModal,
  },
  props: {
    departments: {
      type: Array,
      default: [],
      required: true
    },
    selectdepartmentId: {
      type: String,
      default: null,
      required: true
    }
  },
  setup (props) {
    const vueI18n = useI18n();
    const { getters } = useStore();
    const addStaffFormRef = ref();
    const editStaffFormRef = ref();

    const state = reactive({
      pinModalState: {
        visible: false,
        loading: false,
        type: null,
        dataTemp: null,
      }
    })

    const columns = [
      {
        title: vueI18n.t('姓'),
        dataIndex: 'lastName',
        width: 70,
      },

      {
        title: vueI18n.t('中间名'),
        dataIndex: 'middleName',
        width: 70,
      },
      {
        title: vueI18n.t('名'),
        dataIndex: 'firstName',
        width: 70,
      },
      {
        title: vueI18n.t('国籍'),
        dataIndex: 'nationalityCountry',
        width: 100,
        slots: {
          customRender: 'nationalityCountry',
        },
      },
      {
        title: vueI18n.t('工作国家'),
        dataIndex: 'workCountry',
        width: 100,
        slots: {
          customRender: 'workCountry',
        },
      },
      {
        title: vueI18n.t('员工编号/工作邮箱'),
        dataIndex: 'email',
        slots: {
          customRender: 'email',
        },
        width: 150,
      },
      {
        title: vueI18n.t('所属部门'),
        width: 100,
        slots: {
          customRender: 'departmentName',
        },
      },
      {
        title: vueI18n.t('状态'),
        dataIndex: 'jobStatu',
        width: 100,
        slots: {
          customRender: 'jobStatu',
        },
      },
      {
        title: vueI18n.t('账号状态'),
        dataIndex: 'isActive',
        width: 100,
        slots: {
          customRender: 'isActive',
        },
      },
      {
        dataIndex: 'operation',
        width: 80,
        slots: {
          title: 'operationTitle',
          customRender: 'operations',
        },
      },
    ];

    watch(() => [...props.departments], (newV, oldV) => {
      dropDownData.departments = newV;
    })

    watch(() => props.selectdepartmentId, (newV, oldV) => {
      tableData.departmentId = newV;
      // console.log("tableData.departmentId:"+tableData.departmentId );
      handleButSearchTable();
    })

    const dropDownData = reactive({
      countrys: [],
      departments: [],
      jobStatuEnum: jobStatu,
      roleList: [],//角色
      roleLoading: false,
      warehouseList: [],//仓库(站点)
      warehouseLoading: false,
      timezones: [],
      timezoneLoading: false,
    })

    const tableData = reactive({
      tableList: [],
      loading: false,
      searchKey: null,
      departmentId:null,
      selectedNationalityId: null,
      selectedWorkCountryId: null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      }
    });

    const editStaffModal = reactive({
      visible: false,
      loading: false,
      modalLoading: false,
      uploading: false,
      createLoading: false,
      imageUrl: null,
      firstName: null,
      middleName: null,
      lastName: null,
      birthday: null,
      nationalityCountryId: null,
      nationalityCountryCnName: null,
      nationalityCountryEnName: null,
      phoneCountryCode: null,
      phone: null,
      workCountryId: null,
      email: null,
      departmentId: null,
      isManager: false,
      jobStatu: null,
      isActive: null,
      employeeNo: null,
      id: null,//员工Id
      // employeeId: null,//员工Id
      roleIds: [],//选择的角色Id
      warehouseIds: [],//选择的仓库Id(站点)

    });

    const addStaffModal = reactive({
      visible: false,
      // uploading: false,
      // createLoading: false,
      // imageUrl: null,
      // firstName: null,
      // middleName: null,
      // lastName: null,
      // selectBirthday: null,
      // birthday: null,
      // nationalityCountryId: null,
      // phoneCountryCode: null,
      // phone: null,
      // workCountryId: null,
      // email: null,
      // departmentId: null,
      // jobStatu: null,
      // isActive: null,
      // timezone: null,
    });

    const getAddStaffModal = () => {
      return {
        uploading: false,
        createLoading: false,
        imageUrl: null,
        firstName: null,
        middleName: null,
        lastName: null,
        selectBirthday: null,
        birthday: null,
        nationalityCountryId: null,
        phoneCountryCode: null,
        phone: null,
        workCountryId: null,
        email: null,
        departmentId: null,
        isManager: false,
        jobStatu: null,
        isActive: true,
        timezone: null,
      }
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }
    const getStaffName = (item) => {
      return getNameByLanguage(item, getters.language);
    }

    const isEmail = (str) => {
      var reg = /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/;
      return reg.test(str);
    }

    //------------- 表格 begin-----------------
    const handlePage = (pageData) => {
      tableData.pageData.skipCount = pageData.skipCount;
      tableData.pageData.maxResultCount = pageData.maxResultCount;
      handleSearchStaffTable();
    };

    const handleButSearchTable = () => {
      tableData.pageData.currentIndex = 1;
      tableData.pageData.skipCount = 0;
      handleSearchStaffTable();
    }

    const handleSearchStaffTable = () => {
      let pra = {
        searchKey: tableData.searchKey,
        nationalityCountryId: tableData.selectedNationalityId,
        workCountryId: tableData.selectedWorkCountryId,
        departmentId:tableData.departmentId,
        ...tableData.pageData
      }
      tableData.loading = true;
      getTableList(pra).then((res) => {
        tableData.loading = false;
        let { items = [], totalCount = 0 } = res.result;
        tableData.pageData.totalCount = parseInt(totalCount);
        tableData.tableList = items;
      }).catch(() => {
        tableData.loading = false;
      })
    }

    const funcUpdateStaffAccountStatus = (record) => {
      record.loading = true;
      updateStaffAccountStatus(record).then(() => {
        setTimeout(() => {
          record.loading = false;
        }, 3000)
      }).catch(() => {
        setTimeout(() => {
          record.loading = false;
        }, 5000)
      })
    }

    /**
     * @description 
     * @params record
     * @params type 1：重置pin码；2：重置密码
     **/
    const showPinCodeModal = (record, type) => {
      state.pinModalState.type = type
      state.pinModalState.dataTemp = record.id
      state.pinModalState.visible = true
    }

    const handlePinCodeConfirm = (pinCode) => {
      switch (state.pinModalState.type) {
        case '1':
          handleResetPinCode(pinCode)
          break;
        case '2':
          handleResetPassword(pinCode)
          break;
        default:
          break;
      }
    }

    // 重置pin码
    const handleResetPinCode = (pinCode) => {
      state.pinModalState.loading = true;
      const data = {
        employeeId: state.pinModalState.dataTemp,
        pinCode
      }
      resetPinCode(data).then(() => {
        message.success(vueI18n.t('common.succeed'))
        state.pinModalState.dataTemp = null
        state.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    // 重置密码
    const handleResetPassword = (pinCode) => {
      state.pinModalState.loading = true;
      const data = {
        employeeId: state.pinModalState.dataTemp,
        pinCode
      }
      resetPwd(data).then(({ result }) => {
        state.pinModalState.dataTemp = null
        state.pinModalState.visible = false
        // console.log('result', result);
        Modal.success({
          title: () => vueI18n.t('common.succeed'),
          content: () => vueI18n.t('account.new_password') + ': ' + result,
        })
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }
    //------------- 表格 end-----------------

    //------------- 添加员工 begin-----------------
    const addStaffModalRules = {
      firstName: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("名")])
          },
          trigger: 'blur'
        },
      ],
      lastName: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("姓")])
          },
          trigger: 'blur'
        },
      ],
      nationalityCountryId: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("国籍")])
          },
          trigger: 'blur'
        },
      ],
      phoneCountryCode: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("区号")])
          },
          trigger: 'blur'
        },
      ],
      phone: [
        {
          required: true,
          validator: async (rule, value) => {
            if (!value) {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("电话")]))
            } else {
              let phoneCode = addStaffModal.phoneCountryCode ?? ''
              if (!isValidGenericPhoneNumber(phoneCode + value)) {
                return Promise.reject(vueI18n.t("common.p0_is_wrong", [vueI18n.t("电话")]))
              }
              return Promise.resolve()
            }
          },
          trigger: ['change', 'blur'],
        },
      ],
      workCountryId: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("工作国家")])
          },
          trigger: 'blur'
        },
      ],
      email: [
        {
          required: true,
          validator: async (rule, value) => {
            if (!value) {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("工作邮箱")]));
            } else if (!isEmail(value)) {
              return Promise.reject(vueI18n.t("common.p0_incorrect_format", [vueI18n.t("工作邮箱")]));
            }
            return Promise.resolve();
          },
          trigger: 'blur'
        },
      ],
      departmentId: [
        {
          required: true,
          type: "string",
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("所属部门")])
          },
          trigger: ['change', 'blur']
        },
      ],
      jobStatu: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("工作状态")])
          },
          trigger: 'blur'
        },
      ],
      timezone: [
        {
          type: "string",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("时区")])
          },
          trigger: 'blur'
        },
      ],
      selectBirthday: [
        {
          validator: async (rule, value) => {
            //var e=value.local().format('YYYY/MM/DD');
            if (value?.diff(moment(new Date()), 'days') >= 0) {
              return Promise.reject('出生日期选择错误');
            }
            return Promise.resolve();
          },
          trigger: 'blur'
        },
      ]
    };

    const handleAddStaffOk = () => {
      addStaffModal.createLoading = true;
      addStaffFormRef.value.validate().then(() => {
        if (addStaffModal.selectBirthday) {
          addStaffModal.birthday = currentTimeToUtc(addStaffModal.selectBirthday?.format('YYYY-MM-DD 00:00:00'), addStaffModal.timezone)
        }
        return createEmployee(addStaffModal);
      })
        .then((res) => {
          addStaffModal.visible = false;
          addStaffModal.createLoading = false;
          handleSearchStaffTable();
          message.success(vueI18n.t('common.succeed'))
        })
        .catch((err) => {
          addStaffModal.createLoading = false;
        })
    };

    const handleShowAddStaffModal = () => {
      addStaffModal.visible = true;
      nextTick(() => {
        addStaffFormRef.value.clearValidate();
      })
      Object.assign(addStaffModal, getAddStaffModal())
      addStaffModal.departmentId = tableData.departmentId;
    };

    const funcGetTimezones = () => {
      dropDownData.timezoneLoading = true;
      getTimezones().then((res) => {
        if (res.result && res.result.length > 0) {
          dropDownData.timezones = res.result;
          // console.log(dropDownData.timezones);
        }
        dropDownData.timezoneLoading = false;
      }).catch(() => {
        dropDownData.timezoneLoading = false;
      })
    }
    //------------- 添加员工 end-------------------

    //------------- 编辑员工 begin-----------------
    const showEditStaffModal = (record) => {
      editStaffModal.visible = true;
      editStaffModal.modalLoading = true;
      editStaffModal.isShowManageRole = false;
      nextTick(()=>{
        editStaffFormRef.value.clearValidate();
      })

      if (dropDownData.warehouseList.length == 0) {
        funcGetWarehouses();
        //Promise.all([funcGetWarehouses(), funcGetRolePermissionList()]);
      }
      getStaffInfoById(record.id).then((res) => {
        if (res.result) {
          Object.assign(editStaffModal, res.result);
        }
        if (editStaffModal.birthday) {
          editStaffModal.birthday = moment.tz(editStaffModal.birthday, res.timezone).format("yyyy-MM-DD")
        }
        editStaffModal.employeeId = editStaffModal.id;
        editStaffModal.roleIds = editStaffModal.roleIds ?? [];
        editStaffModal.warehouseIds = editStaffModal.warehouseIds ?? [];
        editStaffModal.modalLoading = false;
        editStaffModal.isShowManageRole = editStaffModal.isManagerUser;
      }).then(() => {
        funcGetRolePermissionList();
      }).catch(() => {
        editStaffModal.modalLoading = false;
      })
    };

    const editStaffModalRules = {
      jobStatu: [
        {
          type: "number",
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("工作状态")])
          },
          trigger: 'blur'
        },
      ],
      nationalityCountryId: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("国籍")])
          },
          trigger: 'blur'
        },
      ],
      phoneCountryCode: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("区号")])
          },
          trigger: 'blur'
        },
      ],
      phone: [
        {
          required: true,
          validator: async (rule, value) => {
            if (!value) {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("电话")]))
            } else {
              let phoneCode = editStaffModal.phoneCountryCode ?? ''
              if (!isValidGenericPhoneNumber(phoneCode + value)) {
                return Promise.reject(vueI18n.t("common.p0_is_wrong", [vueI18n.t("电话")]))
              }
              return Promise.resolve()
            }
          },
          trigger: ['change', 'blur'],
        },
      ],
      workCountryId: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("工作国家")])
          },
          trigger: 'blur'
        },
      ],
      email: [
        {
          required: true,
          validator: async (rule, value) => {
            if (!value) {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("工作邮箱")]));
            } else if (!isEmail(value)) {
              return Promise.reject(vueI18n.t("common.p0_incorrect_format", [vueI18n.t("工作邮箱")]));
            }
            return Promise.resolve();
          },
          // message: () => {
          //   return vueI18n.t("common.p0_is_required", [vueI18n.t("工作邮箱")])
          // },
          trigger: 'blur'
        },
      ],
      departmentId: [
        {
          required: true,
          type: "string",
          message: () => {
            return vueI18n.t("common.p0_is_required", [vueI18n.t("所属部门")])
          },
          trigger: ['change', 'blur']
        },
      ],
      // roleIds: [
      //   {
      //     required: true,
      //     type: "Array",
      //     validator: async (rule, value) => {
      //       if (value.length > 0) {
      //         return Promise.resolve();
      //       }
      //       return Promise.reject();
      //     },
      //     message: () => {
      //       return vueI18n.t("common.p0_is_required", [vueI18n.t("角色权限")])
      //     },
      //     trigger: ['change', 'blur']
      //   },
      // ],
      // warehouseIds: [
      //   {
      //     required: true,
      //     type: "Array",
      //     validator: async (rule, value) => {
      //       if (value.length > 0) {
      //         return Promise.resolve();
      //       }
      //       return Promise.reject();
      //     },
      //     message: () => {
      //       return vueI18n.t("common.p0_is_required", [vueI18n.t("站点权限")])
      //     },
      //     trigger: ['change', 'blur']
      //   },
      // ],
    };

    const handleUpdate = async (file) => {
      editStaffModal.imageUrl = [];
      editStaffModal.uploading = true;

      addStaffModal.imageUrl = [];
      addStaffModal.uploading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", 11);
      uploadFile(parameter).then((res) => {
        addStaffModal.imageUrl = res.result[0].url;
        addStaffModal.uploading = false;
        editStaffModal.imageUrl = res.result[0].url;
        editStaffModal.uploading = false;
      })
        .catch(() => {
          addStaffModal.uploading = false;
          editStaffModal.uploading = false;
        })
    }
    //------------- 编辑员工 end-----------------

    //------------- 下拉框数据 begin-----------------
    const funcGetCountrys = () => {
      getGlobalCountrys().then((res) => {
        if (res && res.result) {
          dropDownData.countrys = res.result;
        }
      }).catch()
    }

    const changeWorkCountrys = (value, option) => {
      if (!addStaffModal.phoneCountryCode && addStaffModal.visible == true) {
        addStaffModal.phoneCountryCode = option.phoneCountryCode;
      }
    }

    const funcGetWarehouses = () => {
      dropDownData.warehouseLoading = true;
      getAllWarehouses().then((res) => {
        if (res.result) {
          dropDownData.warehouseList = res.result;
          dropDownData.warehouseLoading = false;
        }
      }).catch(() => {
        dropDownData.warehouseLoading = false;
      })
    }

    const funcGetRolePermissionList = () => {
      dropDownData.roleLoading = true;
      getRolePermissionList(editStaffModal.isShowManageRole).then((res) => {
        if (res.result) {
          dropDownData.roleList = res.result;
          dropDownData.roleLoading = false;
        }
      }).catch(() => {
        dropDownData.roleLoading = false;
      })
    }

    const updateStaffModal = () => {
      editStaffModal.isChangeUserToManagerUser = editStaffModal.isShowManageRole;
      editStaffModal.createLoading = true;
      editStaffFormRef.value.validate().then(() => {
        return Promise.all([updateStaff(editStaffModal), setStaffPermission(editStaffModal)])
      })
        .then((res) => {
          editStaffModal.visible = false;
          editStaffModal.createLoading = false;
          handleSearchStaffTable();
          message.success(vueI18n.t('common.succeed'))
        })
        .catch(() => {
          editStaffModal.createLoading = false;
        })
    }
    //------------- 下拉框数据 end-----------------

    onMounted(() => {
      handleSearchStaffTable();
      if (!dropDownData.countrys || dropDownData.countrys.length == 0) {
        funcGetCountrys();
      }
      if (!dropDownData.timezones || dropDownData.timezones.length == 0) {
        funcGetTimezones();
      }
    })
    return {
      ...toRefs(state),
      columns,
      tableData,
      editStaffModal,
      dropDownData,
      handleShowAddStaffModal,
      handlePage,
      getters,

      addStaffModal,
      addStaffFormRef,
      editStaffFormRef,
      showEditStaffModal,
      handleAddStaffOk,
      handleSearchStaffTable,
      addStaffModalRules,
      editStaffModalRules,
      getLanguageName,
      handleUpdate,
      changeWorkCountrys,
      updateStaffModal,
      getStaffName,
      handleButSearchTable,
      funcUpdateStaffAccountStatus,
      funcGetTimezones,
      funcGetRolePermissionList,
      handleResetPinCode,
      handlePinCodeConfirm,
      showPinCodeModal,
      handleResetPassword,
    };
  },
};
</script>

<style lang="less" scoped>
.edit {
  color: #f5aa6c;
}

.pabi-img {
  max-width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(img) {
    max-height: 86px;
    max-width: 86px;
  }
}
.border-b {
  border-bottom: 1px solid #f0f0f0;
}
</style>
<template>
  <Content>
    <template #contentTitle>
      {{ isEditPage&&!isAddCopyPage ? $t("menu.products_edit") : $t("menu.product_create") }}
    </template>
    <template #contentBody>
      <a-spin size="large"
              :spinning="pageLoading">
        <div>
          <!-- 产品信息 -->
          <div class=" mb-3 product-Info">
            <a-row :gutter="[16,16]">
              <a-col>
                <a-upload name="product image"
                          :key="1"
                          accept=" .jpeg,.jpg,.png"
                          list-type="picture-card"
                          :customRequest="handleUpdate"
                          :showUploadList="false">
                  <div v-if="product.imgUrl"
                       class="pabi-img">
                    <loading-outlined v-if="uploadLoading"></loading-outlined>
                    <img :src="product.imgUrl"
                         alt="product image" />
                  </div>
                  <div v-else>
                    <loading-outlined v-if="uploadLoading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                    <div class="ant-upload-text">{{ $t('common.upload_img') }}</div>
                  </div>
                </a-upload>
                <div class="text-center">
                  {{$t('warehouse.product_imag')}}
                </div>
              </a-col>
              <a-col>
                <a-upload name="product image"
                          :key="2"
                          accept=" .jpeg,.jpg,.png"
                          list-type="picture-card"
                          :customRequest="handleUpdatePacking"
                          :showUploadList="false">
                  <div v-if="product.packingImgUrl"
                       class="pabi-img">
                    <!-- 这里开始、修改变量、验证、------------------------------------- -->
                    <loading-outlined v-if="packingUploadLoading"></loading-outlined>
                    <img :src="product.packingImgUrl"
                         alt="product image" />
                  </div>
                  <div v-else>
                    <loading-outlined v-if="packingUploadLoading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                    <div class="ant-upload-text">{{ $t('common.upload_img') }}</div>
                  </div>
                </a-upload>

                <div class="text-center">
                  {{$t('common.product_packing_imag')}}
                </div>
              </a-col>

              <a-col>
                <a-form :label-col="{span: 8}"
                :wrapper-col="{span: 16}">
                  <a-form-item required>
                    <template #label>
                      <div>
                        <label>{{ $t('warehouse.product_name') }}</label>
                      </div>
                    </template>
                    <a-input v-model:value="product.productName"
                              :maxlength="120"
                             style="max-width: 400px" />
                  </a-form-item>
                  <a-form-item :required="!isEditPage||isAddCopyPage">
                    <template #label>
                      <div>
                        <label>{{ $t('warehouse.product_no') }}</label>
                      </div>
                    </template>
                    <span v-if="isEditPage&&!isAddCopyPage"
                          >{{ product.productNo }}</span>
                    <a-input v-else
                             v-model:value="product.productNo"
                             style="max-width: 400px" />
                  </a-form-item>
                     <a-form-item v-if="isEditPage&&!isAddCopyPage">
                    <template #label>
                      <div>
                        <label>SESKU</label>
                      </div>
                    </template>
                    <span>
                      {{ product.seSku }}
                    </span>
                  </a-form-item>
                </a-form>
              </a-col>
            </a-row>
          </div>
          <!-- 仓储物流信息 -->
          <div class="logistics-info">
            <div class="logistics-title">
              <strong>
                <span style="color:red">*</span>
                {{ $t('warehouse.storage_logistics_info') }}
              </strong>
            </div>
            <div class="logistics-body">
              <a-form>
                <div>
                  <a-form-item :label="$t('logistics.transport_characteristics')"
                               class="mt-2 mb-2">
                    <a-checkbox-group v-model:value="logistics.transportCharacteristics">
                      <a-checkbox v-for="(value, key) in transportCharacteristics"
                                  :key="key"
                                  :value="value">{{ $t($enumLangkey('transportCharacteristics', value)) }}</a-checkbox>
                    </a-checkbox-group>
                  </a-form-item>
                  <div class="ant-form-inline mb-2"
                       v-if="isIncludebattery()">
                    <!-- 电池类型 -->
                    <a-form-item :label="$t('warehouse.battery_type')">
                      <a-select v-model:value="logistics.batteryType"
                                :allowClear="true"
                                show-search
                                optionFilterProp="search-key"
                                style="max-width:200px;min-width: 150px;">
                        <a-select-option v-for="(value, key, index) in batteryType"
                                         :key="key"
                                         :value="value"
                                         :data-index="index"
                                         :search-key="$t($enumLangkey('batteryType', value))">{{ $t($enumLangkey('batteryType', value)) }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <!-- 电池成分 -->
                    <a-form-item :label="$t('warehouse.battery_ingredient')">
                      <a-select v-model:value="logistics.batteryIngredient"
                                :allowClear="true"
                                show-search
                                optionFilterProp="search-key"
                                style="max-width:200px;min-width: 150px;">
                        <a-select-option v-for="(value, key, index) in batteryIngredientEnum"
                                         :key="key"
                                         :value="value"
                                         :data-index="index"
                                         :search-key="$t($enumLangkey('batteryIngredient', value))">{{ $t($enumLangkey('batteryIngredient', value)) }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <!-- 电池容量 -->
                    <a-form-item :label="$t('warehouse.battery_capacity')">
                      <a-input-number v-model:value="logistics.batteryCapacity"
                                      :step="100"
                                      :precision="0"
                                      :min="1" /> mA
                    </a-form-item>
                  </div>
                  <div class="ant-form-inline mb-2">
                    <a-form-item :label="$t('warehouse.encasement_size')"
                                 class="mb-2">
                      <a-input-number v-model:value="logistics.containerLength"
                                      class="mr-1"
                                      :placeholder="$t('common.max_length_side')"
                                      :step="1"
                                      :precision="0"
                                      :min="1" />
                      <a-input-number v-model:value="logistics.containerWidth"
                                      class="mr-1"
                                      :placeholder="$t('common.max_width_side')"
                                      :step="1"
                                      :precision="0"
                                      :min="1" />
                      <a-input-number v-model:value="logistics.containerHeight"
                                      :placeholder="$t('common.max_height_side')"
                                      :step="1"
                                      :precision="0"
                                      :min="1" /> cm
                    </a-form-item>
                    <a-form-item :label="$t('warehouse.encasement_weight')"
                                 class="mb-2">
                      <a-input-number v-model:value="logistics.containerWeight"
                                      :step="1"
                                      :precision="3"
                                      :min="0.001" /> kg
                    </a-form-item>
                    <a-form-item :label="$t('warehouse.encasement_count')">
                      <a-input-number v-model:value="logistics.containerCount"
                                      :step="1"
                                      :precision="0"
                                      :min="1" /> Unit
                    </a-form-item>
                  </div>
                  <div class="ant-form-inline mb-2">
                    <a-form-item 
                                 class="mb-2">
                                  <template #label>
                                {{$t('warehouse.product_dimensions')}} 
                            <a-tooltip>
                                <template #title>{{$t('warehouse.product_of_size')}}</template>
                                  <span class="ml-1 mr-1"><QuestionCircleOutlined /></span>
                              </a-tooltip>
                                 </template>
                      <a-input-number v-model:value="logistics.packingLength"
                                      class="mr-1"
                                      :placeholder="$t('common.max_length_side')"
                                      :step="1"
                                      :precision="0"
                                      :min="1" />
                      <a-input-number v-model:value="logistics.packingWidth"
                                      class="mr-1"
                                      :placeholder="$t('common.max_width_side')"
                                      :step="1"
                                      :precision="0"
                                      :min="1" />
                      <a-input-number v-model:value="logistics.packingHeight"
                                      :placeholder="$t('common.max_height_side')"
                                      :step="1"
                                      :precision="0"
                                      :min="1" /> cm
                    </a-form-item>
                    <a-form-item >
                      <template #label>
                                {{$t('warehouse.product_weight')}} 
                            <a-tooltip>
                                <template #title>{{$t('warehouse.product_contains_the_weight_of_the_package')}}</template>
                                  <span class="ml-1 mr-1"><QuestionCircleOutlined /></span>
                              </a-tooltip>
                                 </template>
                      <a-input-number v-model:value="logistics.packingWeight"
                                      :step="1"
                                      :precision="3"
                                      :min="0" /> kg
                    </a-form-item>
                  </div>
                  <div class="ant-form-inline"
                       v-if="isCheckedPackaging&&!isAddCopyPage">
                    <a-form-item :label="$t('warehouse.consignment_warehouse_verified_packaging_size')"
                                 class="mb-2">
                      <span v-if="!logistics.checkedPackagingLength || !logistics.checkedPackagingWidth || !logistics.checkedPackagingHeight">-</span>
                      <span v-else>{{ logistics.checkedPackagingLength }} x {{ logistics.checkedPackagingWidth }} x {{ logistics.checkedPackagingHeight }} cm</span>
                    </a-form-item>
                    <a-form-item :disabled="isEditPage"
                                 class="mb-2"
                                 :label="$t('warehouse.consignment_warehouse_verified_packaging_weight')">{{ logistics.checkedPackagingWeight > 0 ? logistics.checkedPackagingWeight + 'kg' : "-" }}</a-form-item>
                    <a-button @click="handleShowMeasureModal">{{ $t('common.edit') }}</a-button>
                  </div>
                   <div class="ant-form-inline">
                    <a-form-item :label="$t('warehouse.is_the_wrapping_film_supported')" class="mb-2">
                      <a-switch v-model:checked="logistics.isWrappingFilm  " />
                    </a-form-item>
                  </div>
                  <div class="ant-form-inline">
                    <a-form-item :label="$t('warehouse.does_it_support_not_selecting_packaging')" class="mb-2">
                      <a-switch v-model:checked="logistics.isOptionalPacking  " />
                      <small class="ml-3">提示: 主要用于调拨计划</small>
                    </a-form-item>
                  </div>
                  <div class="ant-form-inline">
                    <a-form-item :label="$t('warehouse.combined_packaging_logo')" class="mb-2">
                      <a-select v-model:value="logistics.mergeMark" :placeholder="$t('warehouse.combined_packaging_logo')" style="width: 200px;">
                        <a-select-option
                          v-for="(item, index) in productMergeMarkEnum"
                          :key="index"
                          :value="item"
                          :title="$t($enumLangkey('productMergeMark', item))"
                        >{{ $t($enumLangkey('productMergeMark', item)) }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                </div>
                <!-- tabs -->
                <div>
                  <a-tabs v-model:activeKey="tabsActiveKey">
                    <!-- 海关申报信息 -->
                    <a-tab-pane key="declareInfo"
                                :tab="$t('warehouse.customs_declare_info')">
                      <!-- 默认报关信息 -->
                      <div>
                        <div class="ant-form-inline mb-2">
                            <a-form-item class="mb-2">
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.customs_no') }}</label>
                            </template>
                                <a-input
                                    v-model:value="defaultCustomsInfo.hsCode" />
                          </a-form-item>
                          <a-form-item >
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.declare_price') }}</label>
                            </template>
                            <a-input-group >
                              <!-- <a-select v-model:value="defaultCustomsInfo.currencyId"
                                        style="min-width:50px">
                                <a-select-option v-for="item in currencys"
                                                 :key="item.id"
                                                 :title="item.code "
                                                 :value="item.id">{{ item.code }}</a-select-option>
                              </a-select> -->
                              <span class="ant-input-group-addon">USD</span>
                              <a-input-number :min="0"
                              :step="1"
                                    :precision="2"
                                              style="width:73%"
                                    class="input-group-end-number"
                                              v-model:value="defaultCustomsInfo.declarePrice" />
                            </a-input-group>
                          </a-form-item>
                        </div>
                          <div class="ant-form-inline mb-2">
                          <a-form-item class="mb-2">
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.brand_info') }}</label>
                            </template>
                            <a-select v-model:value="defaultCustomsInfo.hasBrand"
                                      :placeholder="$t('common.please_select')"
                                      style="min-width: 183px;">
                              <a-select-option value="true">{{ $t('warehouse.have_name') }}</a-select-option>
                              <a-select-option value="false">{{ $t('warehouse.no_brand') }}</a-select-option>
                            </a-select>
                          </a-form-item>
                          <a-form-item v-if="defaultCustomsInfo.hasBrand==='true'">
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.brand_name') }}</label>
                            </template>
                            <a-input v-model:value="defaultCustomsInfo.brand" />
                          </a-form-item>
                        </div>
                        <div class="ant-form-inline mb-2">
                          <a-form-item class="mb-2">
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.english_trade_name') }}</label>
                            </template>
                            <a-input v-model:value="defaultCustomsInfo.customsEnName" />
                          </a-form-item>
                          <a-form-item>
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.chinese_trade_name') }}</label>
                            </template>
                            <a-input v-model:value="defaultCustomsInfo.customsCnName" />
                          </a-form-item>
                        </div>
                        <div class="ant-form-inline mb-2">
                          <a-form-item class="mb-2">
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.english_texture') }}</label>
                            </template>
                            <!-- <a-input v-model:value="defaultCustomsInfo.enMaterial" /> -->
                            <a-auto-complete v-model:value="defaultCustomsInfo.enMaterial"
                                             :options="materialList.map(x=>({value:x.enName}))"
                                             :getPopupContainer="triggerNode => triggerNode.parentNode"
                                             :filter-option="(input,option)=>{return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;}"
                                             @select="onMaterialSelected"
                                             @blur="onMaterialBlured('en')"></a-auto-complete>
                          </a-form-item>
                          <a-form-item>
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.chinese_texture') }}</label>
                            </template>
                            <!-- <a-input v-model:value="defaultCustomsInfo.cnMaterial" /> -->
                            <a-auto-complete v-model:value="defaultCustomsInfo.cnMaterial"
                                             :options="materialList.map(x=>({value:x.cnName}))"
                                             :getPopupContainer="triggerNode => triggerNode.parentNode"
                                             :filter-option="(input,option)=>{return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;}"
                                             @select="onMaterialSelected"
                                             @blur="onMaterialBlured('cn')"></a-auto-complete>
                          </a-form-item>
                        </div>
                          <div class="ant-form-inline mb-2">
                          <a-form-item class="mb-2">
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.purpose_english_description') }}</label>
                            </template>
                            <a-input v-model:value="defaultCustomsInfo.purposeEnDescription" />
                          </a-form-item>
                          <a-form-item>
                            <template #label>
                              <span style="color:red">*</span>
                              <label>{{ $t('warehouse.purpose_chinese_description') }}</label>
                            </template>
                            <a-input v-model:value="defaultCustomsInfo.purposeCnDescription" />
                          </a-form-item>
                        </div>
                      </div>
                      <!-- 报关table -->
                      <div class="table">
                        <a-table :columns="columns"
                                 :data-source="declareDataSource"
                                 :scroll="{ x: 800 }"
                                 size="small"
                                 :pagination="false">
                          <template #serialNumber>
                            <span>{{ $t("common.serial_number") }}</span>
                          </template>
                          <template #stateTitle>{{ $t('common.country') }}</template>
                          <template #chineseTradeNameTitle>{{ $t('warehouse.chinese_trade_name') }}</template>
                          <template #englishTradeNameTitle>{{ $t('warehouse.english_trade_name') }}</template>
                          <template #declareUnitPriceTitle>{{ $t('warehouse.declare_price') }}</template>
                          <template #customsNoTitle>{{ $t('warehouse.customs_no') }}</template>

                          <template #state="{ record }">{{ getCountryName(record) }}</template>
                          <template #chineseTradeName="{ record }">
                            <div :class="[record.customsCnName || checkTableErrorLine(record) ? '' : 'input-error-border']">
                              <a-input style="max-width:400px;"
                                       v-model:value="record.customsCnName" />
                            </div>
                          </template>
                          <template #englishTradeName="{ record }">
                            <div :class="[record.customsEnName || checkTableErrorLine(record) ? '' : 'input-error-border']">
                              <a-input style="max-width:400px;"
                                       v-model:value="record.customsEnName" />
                            </div>
                          </template>
                          <template #customsNo="{ record }">
                            <div :class="[record.hsCode || checkTableErrorLine(record) ? '' : 'input-error-border']">
                              <a-input style="max-width:400px;"
                                       v-model:value="record.hsCode" />
                            </div>
                          </template>
                          <template #declareUnitPrice="{ record }">
                            <div>
                              <a-input-group >
                                <!-- <a-select v-model:value="record.currencyId">
                                  <a-select-option v-for="item in currencys"
                                                   :key="item.id"
                                                   :value="item.id">{{ item.code }}</a-select-option>
                                </a-select> -->
                                <span class="ant-input-group-addon">USD</span>
                                <a-input-number style="min-width: 115px;"
                                                class="input-group-end-number"
                                                :class="[record.declarePrice || checkTableErrorLine(record) ? '' : 'input-error-border']"
                                                :min="0"
                                                :step="1"
                                    :precision="2"
                                                v-model:value="record.declarePrice" />
                              </a-input-group>
                            </div>
                          </template>
                        </a-table>
                      </div>
                    </a-tab-pane>
                    <!-- 标签管理 -->
                    <a-tab-pane key="labelManage"
                                :tab="$t('common.product_label_manage')"
                                force-render>
                      <UploadLabel ref="refUploadLabel"
                      ></UploadLabel>
                    </a-tab-pane>
                  </a-tabs>
                </div>
                <!-- button -->
                <div class="mt-4 mb-4">
                  <a-row type="flex"
                         justify="center"
                         align="middle">
                    <a-col class="mr-5">
                      <a-button type="primary"
                                :loading="cancelLoading"
                                ghost
                                @click="handleCancel">{{ $t('common.cancel') }}</a-button>
                    </a-col>
                    <a-col>
                      <a-button type="primary"
                                :loading="saveLoading"
                                @click="handleSaveButton">{{ $t('common.save') }}</a-button>
                    </a-col>
                  </a-row>
                </div>
              </a-form>
            </div>
          </div>
        </div>

        <!-- 更新测量数据modal -->
        <a-modal v-model:visible="measureModal.visible"
                 :title="$t('warehouse.survey_product_packing_data')"
                 :cancelText="$t('common.close')"
                 :okText="$t('common.confirm')"
                 :confirm-loading="measureModal.loading"
                 :maskClosable="false"
                 @ok="handleUpdateModal"
                 @cancel="handleCancelModal"
                 :centered="true">
          <div>
            <a-row>
              <a-col :span="24">
                <div>
                  <a-row :gutter="[0,8]">
                    <a-col :span="24">
                      <a-row >
                        <a-col :span="12">
                          <p>{{$t('warehouse.general_survey_size')}}:
                            {{(!measureModal.oldPackingLength || !measureModal.oldPackingWidth || !measureModal.oldPackingHeight) ? "-" : measureModal.oldPackingLength + "x" + measureModal.oldPackingWidth + "x" + measureModal.oldPackingHeight+"cm"}}</p>
                          <p>
                            {{$t('warehouse.general_survey_weight')}}:
                            {{measureModal.oldPackingWeight?measureModal.oldPackingWeight+"kg":"-"}}
                          </p>
                        </a-col>
                        <a-col :span="12">
                          <div>{{$t('warehouse.general_survey_img')}}:</div>
                          <a-row :gutter="[6,6]">
                            <a-col 
                              v-for="(item,index) in measureModal.oldCheckImgUrls"
                              :key="index"
                              >
                              <div class="general-img" v-if="item">
                                <CImage :src="item" :oss-width="800" :oss-height="800" />
                              </div>
                            </a-col>
                          </a-row>
                        </a-col>
                      </a-row>

                    </a-col>
                    <a-col :span="24">
                      <span>{{$t("warehouse.survey_size")}}:</span>
                      <a-row type="flex"
                             :gutter="[0,4]">
                        <a-col flex="100px">
                          <a-input-number v-model:value="measureModal.packingLength"
                                          :precision="0"
                                          :placeholder="$t('common.max_length_side')"
                                          :min="0" />
                        </a-col>
                        <a-col flex="100px">
                          <a-input-number v-model:value="measureModal.packingWidth"
                                          :precision="0"
                                          :placeholder="$t('common.max_width_side')"
                                          :min="0" />
                        </a-col>
                        <a-col flex="auto">
                          <a-input-number v-model:value="measureModal.packingHeight"
                                          :precision="0"
                                          :placeholder="$t('common.max_height_side')"
                                          :min="0" /> cm
                        </a-col>
                      </a-row>
                    </a-col>
                    <a-col :span="24">
                      <span>{{$t("warehouse.survey_weight")}}:</span>
                      <a-row>
                        <a-col>
                          <a-input-number v-model:value="measureModal.packingWeight"
                                          :precision="3"
                                          :min="0" /> kg

                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </div>
                <!-- <div style="min-height:25px">
                  <a-typography-text type="danger"
                                     v-show="isShowLimitTip">
                    {{$t("warehouse.warn_scan_data_exceed_threshold")}}
                  </a-typography-text>
                </div> -->
              </a-col>
              <a-col :span="24">
                <div>{{$t('warehouse.survey_sheet')}}:</div>
                <InboundAddImg :allowUploadCount="4"
                  accept=" .jpeg,.jpg,.png"
                               v-model:fileList="measureModal.checkImgUrls"></InboundAddImg>
              </a-col>
            </a-row>
          </div>
        </a-modal>

      </a-spin>
    </template>
  </Content>
</template>

<script>
import Content from "../components/Content.vue";
import UploadLabel from "./components/Upload.vue";
import { useRoute, useRouter } from 'vue-router';
import { h, toRefs, reactive, onMounted, ref, nextTick } from "vue";
import {
  Row, Col, Table, Spin, message,Switch,
  Input, InputNumber, Select, Upload,
  Button, Form, Checkbox, Tabs, Modal, AutoComplete,Tooltip
} from "ant-design-vue";
import { updateProduct, getProducInfo, getPackingVerify, updateProductPacking } from "../../api/modules/products/edit";
import { getCountrys, getCurrencys, uploadFile } from "../../api/modules/common/index";
import { transportCharacteristics, batteryType, batteryIngredient as batteryIngredientEnum, productMergeMark as productMergeMarkEnum } from "../../enum/enum.json";
import { getName, isInteger, kgToG,gToKg } from "../../utils/general";
import { useI18n } from "vue-i18n/index";
import { useTab } from "@/hooks/tabs/index";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from "vuex";
import { getMaterials } from "../../consts/material.js";
import CImage from "../components/CImage.vue"
import InboundAddImg from "./components/InboundAddImg.vue"
import { number } from "echarts";



export default {
  name: "products_edit",
  components: {
    CImage, InboundAddImg,
    Content, UploadLabel,
    ACol: Col, ARow: Row, AModal: Modal, ASpin: Spin,
    AInput: Input, AInputNumber: InputNumber, AInputGroup: Input.Group, ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group, AFormItem: Form.Item, AForm: Form,
    ASelect: Select, ASelectOption: Select.Option,
    AButton: Button, ATabs: Tabs, ATabPane: Tabs.TabPane,
    ATooltip: Tooltip,
    ASwitch: Switch,
    ATable: Table, AUpload: Upload, AAutoComplete: AutoComplete,
  },
  props: {
    goodsId: String,
  },
  setup (props, { emit }) {

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, index }) => {
          return index + 1;
        },
        width: 60,
      },
      {
        dataIndex: 'state',
        width: 100,
        slots: {
          title: 'stateTitle',
          customRender: 'state',
        },
      },
      {

        dataIndex: 'chineseTradeName',
        slots: {
          title: 'chineseTradeNameTitle',
          customRender: 'chineseTradeName',
        },
        width: 230,
      },
      {
        dataIndex: 'englishTradeName',
        slots: {
          title: 'englishTradeNameTitle',
          customRender: 'englishTradeName',
        },
        width: 230,
      },
      {
        dataIndex: 'declareUnitPrice',
        width: 200,
        slots: {
          title: 'declareUnitPriceTitle',
          customRender: 'declareUnitPrice',
        },
      },
      {
        dataIndex: 'customsNo',
        width: 250,
        slots: {
          title: 'customsNoTitle',
          customRender: 'customsNo',
        },
      },
    ];

    let product = {
      productNo: null,
      productName: null,
      imgUrl: null,
      packingImgUrl: null
    }

    let logistics = {
      transportCharacteristics: [],
      batteryType: null,//电池类型
      batteryIngredient: null,
      batteryCapacity: null,//电池容量

      containerLength: null,//装箱
      containerWidth: null,
      containerHeight: null,
      containerWeight: null,
      containerCount: null,

      packingLength: null,//包装
      packingWidth: null,
      packingHeight: null,
      packingWeight: null,

      isWrappingFilm: null,
      // 合并包装标识
      isOptionalPacking: null,
      mergeMark: null,

      checkedPackagingLength: null,//已检验包装
      checkedPackagingWidth: null,
      checkedPackagingHeight: null,
      checkedPackagingWeight: null,
    }

    let customsInfo = {  //添加和修改的modal
      id: 0,
      productId: 0,
      countryId: 0,//国家
      currencyId: "2",//货币,默认UDS的id
      declarePrice: "",
      hsCode: "",//邮编
      cnMaterial: "",//中文材质
      enMaterial: "",
      customsCnName: "",
      customsEnName: "",
      isDefault: true,
      isDelete: false,

      hasBrand: null,
      brand: null,
      purposeCnDescription: null,
      purposeEnDescription: null,
    }

    const route = useRoute();
    const i18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    const refUploadLabel = ref();
    const { getters } = useStore();

    const state = reactive({
      // 页面类型：创建类似产品为【copy】
      isAddCopyPage: false,
      transportCharacteristics: transportCharacteristics,
      batteryType: batteryType,
      countrys: [],
      currencys: [],
      pageLoading: false,
      cancelLoading: false,
      saveLoading: false,
      uploadLoading: false,
      packingUploadLoading: false,
      isEditPage: false,//是否编辑页面
      productId: null,
      tabsActiveKey: "declareInfo",
      invalidTableKeys: [],//没填完整的行数据key,
      validTableKeys: [],//完整的行数据key,
      isCheckedPackaging: false
    })

    const data = reactive({
      id: 0,
      product: product,
      //运输特征
      // transportCharacteristics: [],
      logistics: logistics,
      //默认海关信息
      defaultCustomsInfo: customsInfo,
      //海关信息 表格数据
      declareDataSource: [],
      materialList: getMaterials(),
    });

    //币种
    const funcGetCurrencys = () => {
      return getCurrencys({ "isActive": true }).then((obj) => {
        let { result } = obj;
        state.currencys = result;
      });
    }

    const getCountryName = function (item) {
      return getName(item, getters.language);
    }

    //国家
    const funcGetCountryTypes = () => {
      return getCountrys().then((obj) => {
        let { result } = obj;
        state.countrys = result;
      });
    }

    const _funcResetContainerSize = () => {
      let tempArray = [
        data.logistics.containerLength,
        data.logistics.containerWidth,
        data.logistics.containerHeight];
      //降序
      tempArray.sort((a, b) => b - a);
      data.logistics.containerLength = tempArray[0];
      data.logistics.containerWidth = tempArray[1];
      data.logistics.containerHeight = tempArray[2];
    }

    const _funcResetPackingSize = () => {
      let tempArray = [
        data.logistics.packingLength,
        data.logistics.packingWidth,
        data.logistics.packingHeight];
      //降序
      tempArray.sort((a, b) => b - a);
      data.logistics.packingLength = tempArray[0];
      data.logistics.packingWidth = tempArray[1];
      data.logistics.packingHeight = tempArray[2];
    }

    const _funcValidationRequired = () => {
      if (!data.product.imgUrl) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.product_imag")]));
        return false
      }
      if (!data.product.packingImgUrl) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("common.product_packing_imag")]));
        return false
      }

      if (!data.product.productName) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.product_name")]));
        return false
      }
      
      if (data.product?.productName?.length > 120) {
        message.error(i18n.t("common.product_name_length_limit"));
        return false
      }
      
      if (!data.product.productNo) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.product_no")]));
        return false
      }

      if (data.logistics.transportCharacteristics?.length == 0) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("logistics.transport_characteristics")]));
        return false
      }

      let isIncludebatteryValue = isIncludebattery();
      if (isIncludebatteryValue && !data.logistics.batteryType) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.battery_type")]));
        return false
      }

      if (isIncludebatteryValue && !data.logistics.batteryIngredient) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.battery_ingredient")]));
        return false
      }

      if (isIncludebatteryValue && (!data.logistics.batteryCapacity || data.logistics.batteryCapacity == 0)) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.battery_capacity")]));
        return false
      }

      if (!isIncludebatteryValue) {
        data.logistics.batteryType = null;
        data.logistics.batteryIngredient = null;
        data.logistics.batteryCapacity = null;
      }

      if (!data.logistics.containerLength
        || !data.logistics.containerWidth
        || !data.logistics.containerHeight) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.encasement_size")]));
        return false
      }

      if (!isInteger(data.logistics.containerLength)
        || !isInteger(data.logistics.containerWidth)
        || !isInteger(data.logistics.containerHeight)) {
        message.error(i18n.t("common.p0_must_be_an_integer", [i18n.t("warehouse.encasement_size")]));
        return false
      }

      if (!data.logistics.containerWeight) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.encasement_weight")]));
        return false
      }

      if (!data.logistics.containerCount) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.encasement_count")]));
        return false
      }

      if (!data.logistics.packingLength
        || !data.logistics.packingWidth
        || !data.logistics.packingHeight) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.product_dimensions")]));
        return false
      }

      if (!isInteger(data.logistics.packingLength)
        || !isInteger(data.logistics.packingWidth)
        || !isInteger(data.logistics.packingHeight)) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.product_dimensions")]));
        return false
      }

      if (!data.logistics.packingWeight) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.product_weight")]));
        return false
      }

      if (typeof data.logistics.mergeMark !== 'number') {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.combined_packaging_logo")]));
        return false
      }

      if (!data.defaultCustomsInfo.hsCode) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.customs_no")]));
        return false
      }

      if (!data.defaultCustomsInfo.declarePrice) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.declare_price")]));
        return false
      }


       if (data.defaultCustomsInfo.hasBrand === undefined || data.defaultCustomsInfo.hasBrand === null) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.brand_info")]));
        return false
      }

      if (data.defaultCustomsInfo.hasBrand === 'true' && !data.defaultCustomsInfo.brand) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.brand_name")]));
        return false
      }

      if (!data.defaultCustomsInfo.customsEnName) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.english_trade_name")]));
        return false
      }

      if (!data.defaultCustomsInfo.customsCnName) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.chinese_trade_name")]));
        return false
      }

      if (!data.defaultCustomsInfo.enMaterial) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.english_texture")]));
        return false
      }

      if (!data.defaultCustomsInfo.cnMaterial) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.chinese_texture")]));
        return false
      }

       if (!data.defaultCustomsInfo.purposeEnDescription) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.purpose_english_description")]));
        return false
      }

      if (!data.defaultCustomsInfo.purposeCnDescription) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.purpose_chinese_description")]));
        return false
      }
      return true
    }

    //检查海关申报信息的完整
    const _funcVerifyTableData = () => {
      let keys = ["customsCnName", "customsEnName", "declarePrice", "hsCode"];
      let invalidTableKeys = [];//数据不完整的key
      let validTableKeys = [];//数据完整的key
      data.declareDataSource.forEach(x => {
        let hasVlaueCount = 0;//行数据要填的input值
        keys.forEach(k => {
          if (x[k]) {
            hasVlaueCount++;
          }
        })
        if (hasVlaueCount != 0 && hasVlaueCount != 4) {
          invalidTableKeys.push(x.key);
        } else if (hasVlaueCount == 4) {
          validTableKeys.push(x.key);
        }
        hasVlaueCount = 0;
      })
      state.invalidTableKeys = invalidTableKeys;
      state.validTableKeys = validTableKeys;

      if (invalidTableKeys.length > 0) {
        message.error(
          i18n.t("warehouse.error_customs_declaration"));
        return false;
      }
      return true;
    }

    const _funcVerifyTableLabel = () => {
      return refUploadLabel.value.showLableList.findIndex(x => !x.name) == -1
    }


    //创建表格对象
    const buildTableList = function (remoteDeclareData) {
      data.declareDataSource = state.countrys.map((item) => {
        //编辑页面
        if (remoteDeclareData && remoteDeclareData.length > 0) {
          let remoteDeclare = remoteDeclareData.find((declareItem) => declareItem.countryId == item.id);
          if (remoteDeclare != null) {
            remoteDeclare.key = remoteDeclare.countryId;
            remoteDeclare.cnName = remoteDeclare.countryCnName;
            remoteDeclare.enName = remoteDeclare.countryEnName;

            // remoteDeclare.id = null;
            // remoteDeclare.productId = null;
            return remoteDeclare
          }
        }

        //创建页面
        let copyCustomsInfo = Object.assign({}, customsInfo);
        let { cnName, enName } = item;
        copyCustomsInfo.cnName = cnName;
        copyCustomsInfo.enName = enName;
        copyCustomsInfo.isDefault = false;
        copyCustomsInfo.key = item.id;
        copyCustomsInfo.countryId = item.id;
        copyCustomsInfo.hasBrand = false;
        return copyCustomsInfo;
      });
    };

    //上传产品图片
    const handleUpdate = async (file) => {
      data.product.imgUrl = null;
      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", 1);
      uploadFile(parameter).then((res) => {
        data.product.imgUrl = res?.result[0]?.url;
        state.uploadLoading = false;
      }).catch(() => {
        state.uploadLoading = false;
      });
    }

    //上传包装图片
    const handleUpdatePacking = async (file) => {
      data.product.packingImgUrl = null;
      state.packingUploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", 1);
      uploadFile(parameter).then((res) => {
        data.product.packingImgUrl = res?.result[0]?.url;
        state.packingUploadLoading = false;
      }).catch(() => {
        state.packingUploadLoading = false;
      });
    }


    //判断是否为一条完整的数据
    const checkTableErrorLine = (record) => {
      //全部为空 || key不存在errorIds中 ||有值 ? "":"input-error-border"
      return (
        state.invalidTableKeys.indexOf(record.key) == -1) ||
        (
          !record.customsCnName &&
          !record.customsEnName &&
          !record.declarePrice &&
          !record.hsCode
        );
    }

    //代发仓校验的尺寸
    const funcPackingVerify = async (productId) => {
      getPackingVerify({ 'id': productId }).then((res) => {
        let { result } = res;
        if (result) {
          data.logistics.checkedPackagingLength = result.packingLength;
          data.logistics.checkedPackagingWidth = result.packingWidth;
          data.logistics.checkedPackagingHeight = result.packingHeight;
          data.logistics.checkedPackagingWeight = gToKg(result.packingWeight );
          data.logistics.checkImgUrls = result.checkImgUrls ?? [];
          data.logistics.id = result.id;
          state.isCheckedPackaging = true;
        }
      });
    }

    //请求页面数据
    const funcProducInfo = async (productId) => {
      let searchData = {
        "id": productId,
        "isDeclarationInfo": true,
        "isLabel": true
      };
      let res = await getProducInfo(searchData);
      let { result } = res;
      //#region 数据映射
      if (state.isAddCopyPage) {
        //避免上次页面的缓存信息没有清除
        data.id = null;
        data.product.productNo = null;
        data.product.productName = null;
        data.product.imgUrl = null;
        data.product.packingImgUrl = null;
        refUploadLabel.value.showLableList = []
      } else {
        data.id = result.id;
        data.product.productNo = result.productNo;
        data.product.productName = result.productName;
        data.product.seSku = result.seSku;
        data.product.imgUrl = result.imgUrl;
        data.product.packingImgUrl = result.packingImgUrl;
        refUploadLabel.value.showLableList = result.labels.map(x => {
          x.key = x.id;
          return x
        });
      }

      data.logistics.transportCharacteristics = result.transportCharacteristics;
      data.logistics.batteryType = result.batteryType;
      data.logistics.batteryIngredient = result.batteryIngredient;
      data.logistics.batteryCapacity = result.batteryCapacity;
      data.logistics.containerLength = result.containerLength;
      data.logistics.containerWidth = result.containerWidth;
      data.logistics.containerHeight = result.containerHeight;
      data.logistics.containerWeight = gToKg(result.containerWeight );
      data.logistics.containerCount = result.containerCount;
      data.logistics.packingLength = result.packingLength;
      data.logistics.packingWidth = result.packingWidth;
      data.logistics.packingHeight = result.packingHeight;
      data.logistics.packingWeight = gToKg(result.packingWeight);
      data.logistics.mergeMark = result.mergeMark;
      data.logistics.isWrappingFilm = result.isWrappingFilm;
      data.logistics.isOptionalPacking = result.isOptionalPacking;

      data.defaultCustomsInfo = result.customsDeclarations.find(x => x.isDefault == true) || { isDefault: true };
      data.defaultCustomsInfo.hasBrand = data.defaultCustomsInfo.hasBrand?.toString();
      // data.defaultCustomsInfo.id = null;
      // data.defaultCustomsInfo.productId = null;

      let tempDeclareDataSource = result.customsDeclarations.filter(x => x.isDefault == false) || []
      //#endregion
      return tempDeclareDataSource;
    }

    const compare = (str1, str2) => {
      var s1 = str1;
      var s2 = str2;
      if (str1) {
        s1 = str1.toUpperCase();
      }
      if (s2) {
        s2 = str2.toUpperCase();
      }
      return s1 == s2;
    }

    const onMaterialSelected = (value) => {
      for (var i = 0; i < data.materialList.length; i++) {
        if (compare(data.materialList[i].cnName, value)) {
          data.defaultCustomsInfo.enMaterial = data.materialList[i].enName;
          break;
        }
        if (compare(data.materialList[i].enName, value)) {
          data.defaultCustomsInfo.cnMaterial = data.materialList[i].cnName;
          break;
        }
      }
    }

    const onMaterialBlured = (lang) => {
      if (lang == "cn") {
        onMaterialSelected(data.defaultCustomsInfo?.cnMaterial);
      } else {
        onMaterialSelected(data.defaultCustomsInfo?.enMaterial);
      }
    }

    const handleSaveButton = async () => {

      //#region  验证参数和过滤参数
      if (!_funcValidationRequired()) {
        return false
      }
      if (!_funcVerifyTableData()) {
        return false
      }

      if (!_funcVerifyTableLabel()) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.product_lable_name")]));
        return false
      }

      if (_whetherNeedRestSize()) {
        handleShowConfim();
      } else {
        handleSave();
      }

    }

    const handleSave = async () => {

      let filteredTableData = [...data.declareDataSource].filter(x => state.validTableKeys.includes(x.key));
      let tempLogistics = Object.assign({}, data.logistics);
      tempLogistics.containerWeight =kgToG( tempLogistics.containerWeight);
      tempLogistics.packingWeight = kgToG(tempLogistics.packingWeight );
      tempLogistics.checkedPackagingWeight = kgToG(tempLogistics.checkedPackagingWeight );
      let saveData = Object.assign(
        data.product,
        tempLogistics,
        {
          customsDeclarations: filteredTableData.concat(data.defaultCustomsInfo),//合并默认报关信息
          labels: refUploadLabel.value.showLableList?.filter((x) => !x.isDefaultLabel)
        }
      );
      saveData.id = data.id;

      state.saveLoading = true;
      _funcSave(saveData)
        .then((res) => {
          message.success(i18n.t("common.succeed"));
          setTimeout(() => {
            handleCancel();
          }, 1000)
          state.saveLoading = false;
        })
        .catch(() => {
          state.saveLoading = false;
        })
    }

    const _funcSave = (saveData) => {
      if (state.isEditPage && !state.isAddCopyPage) {
        return updateProduct(saveData);
      }
    }

    const _whetherNeedRestSize = () => {
      return (data.logistics.containerLength < data.logistics.containerWidth ||
        data.logistics.containerWidth < data.logistics.containerHeight)
        ||
        (data.logistics.packingLength < data.logistics.packingWidth ||
          data.logistics.packingWidth < data.logistics.packingHeight)
    }

    const handleShowConfim = () => {
      Modal.confirm({
        title: i18n.t("common.system_automatically_helps_repair"),
        icon: h(ExclamationCircleOutlined),
        content: i18n.t("common.current_size_fill_in_error"),
        centered: true,
        mask: true,
        maskClosable: false,
        keyboard: false,
        okText: i18n.t("common.confirm"),
        cancelText: i18n.t("common.cancel"),
        onOk () {
          _funcResetContainerSize();
          _funcResetPackingSize();
          message.success(i18n.t("common.data_modification_succeeded"));
        },
      });
    }

    const handleCancel = async () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/products/list" });
    }

    const isIncludebattery = () => {
      let batteryArray = [
        transportCharacteristics.supportingBattery,
        transportCharacteristics.builtInBattery,
        transportCharacteristics.independenceBattery,
      ];
      return batteryArray.some((x) => {
        return data.logistics.transportCharacteristics.includes(x);
      })
    }

    //--------------------修改测量数据modal-------------------------

    const getMeasureModal = () => {
      return {
        id: logistics.id,
        productId: state.productId,

        oldPackingLength: logistics.checkedPackagingLength,
        oldPackingWidth: logistics.checkedPackagingWidth,
        oldPackingHeight: logistics.checkedPackagingHeight,
        oldPackingWeight: logistics.checkedPackagingWeight,
        oldCheckImgUrls: logistics.checkImgUrls,

        packingLength: null,
        packingWidth: null,
        packingHeight: null,
        packingWeight: null,
        checkImgUrls: [],
      }
    }

    const measureModal = reactive({
      loading: false,
      visible: false,
    });

    const handleShowMeasureModal = () => {
      measureModal.visible = true;
      Object.assign(measureModal, getMeasureModal(), {
        packingLength: logistics.checkedPackagingLength || 0,
        packingWidth: logistics.checkedPackagingWidth || 0,
        packingHeight: logistics.checkedPackagingHeight || 0,
        packingWeight: Number.isNaN(parseFloat(logistics.checkedPackagingWeight)) ? 0 : parseFloat(logistics.checkedPackagingWeight),
        checkImgUrls: logistics.checkImgUrls || [],
      });
    }

    const handleUpdateModal = () => {
      if (!measureModal.packingLength || !measureModal.packingWidth || !measureModal.packingHeight) {
        message.error(i18n.t("common.p0_is_required", [i18n.t("warehouse.survey_size")]));
        return false
      }

      if (!isInteger(measureModal.packingLength) || !isInteger(measureModal.packingWidth) || !isInteger(measureModal.packingHeight)) {
        message.error(i18n.t("common.p0_must_be_an_integer", [i18n.t("warehouse.survey_size")]));
        return false
      }

      if (measureModal.checkImgUrls.length == 0) {
        message.error(i18n.t("warehouse.at_least_one_survey_map_is_required"))
        return false
      }

      if (!measureModal.packingWeight || measureModal.packingWeight == 0) {
        message.error(i18n.t("warehouse.weight_measurement_needs_to_be_greater_than_0"))
        return false
      }

      let par = {
        id: measureModal.id,
        productId: measureModal.productId,
        packingLength: measureModal.packingLength,
        packingWidth: measureModal.packingWidth,
        packingHeight: measureModal.packingHeight,
        packingWeight: kgToG(measureModal.packingWeight),
        checkImgUrls: measureModal.checkImgUrls,
      };

      measureModal.loading = true;
      updateProductPacking(par).then(() => {
        funcPackingVerify(measureModal.productId);
        measureModal.visible = false;
        measureModal.loading = false;
      }).catch(() => {
        measureModal.loading = false;
      })

    }

    const handleCancelModal = () => {
      measureModal.visible=false;
    }

    //--------------------修改测量数据modal-------------------------

    onMounted(async () => {
      let productId = route.params.id;
      state.isAddCopyPage = props.goodsId ? true : false
      productId = props.goodsId ?? productId

      state.isEditPage = productId;
      state.productId = productId;
      state.pageLoading = !!productId;
      Promise.all([funcGetCountryTypes(), funcGetCurrencys()]).then(() => {
        if (!productId) {
          //创建
          buildTableList();
          return Promise.resolve();
        } else {
          //编辑
          return funcProducInfo(productId).then((remoteDeclareData) => {
            buildTableList(remoteDeclareData);
            funcPackingVerify(productId);
          });
        }
      }).finally(() => {
        state.pageLoading = false;
      })
    })

    return ({
      columns,
      refUploadLabel,
      batteryIngredientEnum,
      productMergeMarkEnum,
      ...toRefs(state),
      ...toRefs(data),
      getCountryName,
      handleSaveButton,
      handleCancel,
      handleUpdate,
      handleUpdatePacking,
      checkTableErrorLine,
      isIncludebattery,
      onMaterialSelected,
      onMaterialBlured,


      measureModal,
      handleShowMeasureModal,
      handleUpdateModal,
      handleCancelModal,
    })
  },
}
</script>
<style lang="less" scoped>
.pabi-img {
  max-width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(img) {
    max-height: 86px;
    max-width: 86px;
  }
}

.logistics-info {
  .logistics-title {
    border-bottom: 1px solid #f0f0f0;
  }
}

.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}

.general-img {
  width: 50px;
  height: 50px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  :deep(.ant-image img) {
    max-height: 40px;
  }
}


:deep(.ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

:deep(.input-group-end-number) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
</style>


import client from "../../client";

const Api = {
  getList: "/api/ProductService/CostLog/GetManagerListPaged",
  getDetail: "/api/ProductService/CostLog/GetManager",
  getStorageFeeDetail: "/api/WarehouseService/StorageFeeDetail/GetStorageFeeDetail",
  getTemporaryStorageFeeDetail:"/api/WarehouseService/RtsFeeDetail/GetRtsFeeDetail",
  // 获取未结算费用清单
  getUnCompleteListPaged: "/api/ProductService/CostLogManage/GetUnCompleteListPaged",
};

export const getList = (data) => {
  return client.request({
    url: Api.getList,
    data,
    method: "POST",
  });
};

export const getDetail = (id) => {
  return client.request({
    url: Api.getDetail,
    params: { id },
    method: "get",
  });
};

export const getStorageFeeDetail = (data) => {
  return client.request({
    url: Api.getStorageFeeDetail,
    data,
    method: "POST",
  });
};

export const getTemporaryStorageFeeDetail = (data) => {
  return client.request({
    url: Api.getTemporaryStorageFeeDetail,
    data,
    method: "POST",
  });
};

export const getUnCompleteListPaged = (data) => {
  return client.request({
    url: Api.getUnCompleteListPaged,
    data,
    method: "POST",
  });
};
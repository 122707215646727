<template>
  <Content>
    <template #contentTitle>{{ $t('menu.warehouse_monitor_transit_label_change') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input
            style="width: 250px;"
            v-model:value="searchState.searchKey"
            :placeholder="$t('warehouse.label_task_name')+'/'+$t('warehouse.tag_task_number')+'/'+$t('warehouse.shipment_name')"
            :title="$t('warehouse.label_task_name')+'/'+$t('warehouse.tag_task_number')+'/'+$t('warehouse.shipment_name')"
            allow-clear
          ></a-input>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px;"
            v-model:value="searchState.warehouseId"
            :placeholder="$t('logistics.warehouse_code')"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :value="item.id"
              :title="item.warehouseNo + '('+ item.warehouseName +')'"
            >{{ item.warehouseNo + '('+ item.warehouseName +')' }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px;"
            v-model:value="searchState.labelTaskType"
            :placeholder="$t('warehouse.label_service_type')"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in labelTaskTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('labelTaskType', item))"
            >{{ $t($enumLangkey('labelTaskType', item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px;"
            v-model:value="searchState.status"
            :placeholder="$t('warehouse.task_status')"
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in labelTaskStatusEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('labelTaskStatus', item))"
            >{{ $t($enumLangkey('labelTaskStatus', item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 140 }"
        :pagination="false"
        :row-key="(record) => record.id"
        :loading="tableData.loading"
      >
        <template #labelTaskTitle>
          <div>{{ $t('warehouse.label_task_name') }}</div>
          <div>{{ $t('warehouse.tag_task_number') }}</div>
        </template>
        <template #shipmentInformationTitle>
          <div>{{ $t('warehouse.shipment_name') }}</div>
          <div>{{ $t('warehouse.se_shipment_number') }}</div>
        </template>
        <template #newShipmentNameTitle>
          <div>{{ $t('warehouse.change_the_shipment_name') }}</div>
          <div>{{ $t('warehouse.new_shipment_name') }}</div>
        </template>
        <template #boxCountTitle>
          <div>{{ $t('warehouse.number_of_task_boxes') }}</div>
          <div>{{ $t('warehouse.number_of_cargo_boxes') }}</div>
        </template>
        <template #skuCountTitle>
          <div>{{ $t('warehouse.sku_number') }}</div>
          <div>{{ $t('warehouse.product_quantity') }}</div>
        </template>
        <template #dateTitle>
          <div>{{ $t('warehouse.task_time') }}</div>
          <div>{{ $t('warehouse.update_time') }}</div>
        </template>
        <template #warehouse="{ record }">
          {{ record.warehouse?.warehouseNo || '-' }}
        </template>
        <template #shopInfo="{ record }">
          <template v-if="record.shop">
            <div>{{ record.shop.shopName }}</div>
            <div>NO: {{ record.shop.shopNo }}</div>
            <div>{{ $t('common.shop_mark') }}: {{ record.shop.shopMark }}</div>
          </template>
          <span v-else>-</span>
        </template>
        <template #taskLabel="{ record }">
          <div>{{ record.taskName || '-' }}</div>
          <div>{{ record.taskNo || '-' }}</div>
        </template>
        <template #labelTaskType="{ record }">
          {{ $t($enumLangkey('labelTaskType', record.labelType)) }}
        </template>
        <template #shipmentInfo="{ record }">
          <div>{{ record.shipment?.shipmentName || '-' }}</div>
          <div>{{ record.shipment?.seShipmentNo || '-' }}</div>
        </template>
        <template #newShipmentName="{ record }">
          <div>
            <span v-if="record.isChangeShipmentName === true" class="text-success">{{ $t('common.yes') }}</span>
            <span v-else-if="record.isChangeShipmentName === false">{{ $t('common.no') }}</span>
            <span v-else>-</span>
          </div>
          <div>{{ record.newShipmentName || '-' }}</div>
        </template>
        <template #boxCount="{ record }">
          <div>{{ record.boxCount || '-' }}</div>
          <div>{{ record.shipmentBoxCount || '-' }}</div>
        </template>
        <template #labelCount="{ record }">
          {{ record.labelCount || '-' }}
        </template>
        <template #skuCount="{ record }">
          <div>{{ record.skuCount || '-' }}</div>
          <div>{{ record.productCount || '-' }}</div>
        </template>
        <template #status="{ record }">
          {{ $t($enumLangkey('labelTaskStatus', record.status)) }}
        </template>
        <template #dateCustomer="{ record }">
          <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
          <div>{{ $filters.utcToCurrentTime(record.lastModificationTime) }}</div>
        </template>
        <template #operation="{ record }">
          <a-button type="ghost">
            <router-link :to="'/warehousemonitor/transit/labelchange/' + record.id">{{ $t('common.details') }}</router-link>
          </a-button>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, onMounted, reactive, toRefs } from 'vue';
import { Button, Col, Input, Row, Select, Table } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import CSearchShop from '@/views/components/CSearchShop.vue';
import { useI18n } from "vue-i18n/index";
import {
  labelTaskStatus as labelTaskStatusEnum,
  labelTaskType as labelTaskTypeEnum,
  warehouseType as warehouseTypeEnum,
} from '@/enum/enum.json';
import { getWarehouses } from '@/api/modules/common/index.js';
import { getTransitsLabelChangeTaskListPaged } from '@/api/modules/warehouseMonitor/transit.js';

export default defineComponent({
  name: 'warehouse_monitor_transit_label_change',
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
    CSearchShop,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      searchState: {
        searchKey: null,
        labelTaskType: null,
        status: null,
        isNeedWarehouse: true,
        isNeedTransitsShipment: true,
        isNeedShop: true,
      },
      searchStateCache: {},
      warehouseLoading: false,
      warehouseList: [],
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        width: 120,
        title: () => vueI18n.t('logistics.warehouse_code'),
        slots: {
          customRender: "warehouse",
        }
      },
      {
        title: () => vueI18n.t('warehouse.customer'),
        width: 150,
        slots: {
          customRender: "shopInfo",
        }
      },
      {
        width: 150,
        slots: {
          title: "labelTaskTitle",
          customRender: "taskLabel",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.label_service_type'),
        slots: {
          customRender: "labelTaskType",
        }
      },
      {
        width: 150,
        slots: {
          title: "shipmentInformationTitle",
          customRender: "shipmentInfo",
        }
      },
      {
        width: 150,
        slots: {
          title: "newShipmentNameTitle",
          customRender: "newShipmentName",
        }
      },
      {
        width: 100,
        slots: {
          title: "boxCountTitle",
          customRender: "boxCount",
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.number_of_labels_per_box'),
        slots: {
          customRender: "labelCount",
        }
      },
      {
        width: 100,
        slots: {
          title: "skuCountTitle",
          customRender: "skuCount",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.task_status'),
        slots: {
          customRender: "status",
        }
      },
      {
        width: 120,
        slots: {
          title: "dateTitle",
          customRender: "dateCustomer",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('common.operate'),
        align: 'center',
        fixed: 'right',
        slots: {
          customRender: "operation",
        }
      },
    ]

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      if (data.shopId) {
        data.shopIds = [data.shopId];
      }
      if (data.warehouseId) {
        data.warehouseIds = [data.warehouseId];
      }
      delete data.shopId;
      delete data.warehouseId;
      getTransitsLabelChangeTaskListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0
      state.tableData.pageData.currentIndex = 1
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const getWarehouseList = () => {
      state.warehouseLoading = true;
      const data = {
        warehouseType: warehouseTypeEnum.transitWarehouse,
        isActive: true,
      }
      getWarehouses(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        } else {
          state.warehouseList = [];
        }
      }).catch(() => {}).finally(() => {
        state.warehouseLoading = false;
      })
    };

    onActivated(handleSearch);

    onMounted(getWarehouseList);

    return {
      ...toRefs(state),
      labelTaskStatusEnum,
      labelTaskTypeEnum,
      columns,
      handleSearch,
      handlePage,
    }
  }
})
</script>

<style scoped></style>
<template>
  <a-form-item :label="$t('devops.time_zone_cache')">
    <a-row type="flex" align="middle" :gutter="16">
      <a-col style="width: 250px;">{{ state.count }} {{ $t('common.item') }}</a-col>
      <a-col>
        <a-button :disabled="state.loading" @click="handleEmptyCache()">{{ $t('devops.empty_cache') }}</a-button>
      </a-col>
    </a-row>
  </a-form-item>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import { Button, Col, Form, Row } from "ant-design-vue";
import { getCustomTimeZoneCount } from "@/api/modules/devops/cacheManagement";

export default defineComponent({
  emits: ["confirm"],
  expose: ["getCacheInfo"],
  components: {
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    AButton: Button,
  },
  setup (props, { emit }) {
    const state = reactive({
      loading: false,
      count: 0,
    })

    const handleEmptyCache = () => {
      emit("confirm");
    }

    const getCacheInfo = () => {
      state.loading = true;
      getCustomTimeZoneCount().then(({ result }) => {
        if (result) {
          state.count = result;
        } else {
          state.count = 0;
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    onMounted(getCacheInfo);

    return {
      state,
      handleEmptyCache,
      getCacheInfo,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <a-modal
    v-model:visible="visible"
    :confirmLoading="loading"
    :title="getModalTitle"
    @ok="handleOk"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('logistics.platform')" name="addressType">
        <a-select
          v-model:value="formState.addressType"
          optionFilterProp="search-key"
          show-search
          @change="handlePlatformChange()"
        >
          <a-select-option
            v-for="(item, index) in platformList"
            :key="index"
            :value="item"
            :title="$t($enumLangkey('platformAddrType', item))"
            :search-key="$t($enumLangkey('platformAddrType', item))"
            >{{ $t($enumLangkey("platformAddrType", item)) }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('logistics.code')" name="codes">
        <a-select v-model:value="formState.codes" mode="tags">
          <a-select-option
            v-for="(item, index) in codeList"
            :key="index"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  nextTick,
  computed,
} from "vue";
import { Modal, Select, Form, message } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { platformAddrType as platformAddrTypeEnum } from "@/enum/enum.json";
import {
  getAllCanChooseEmbargoConfigurationCode,
  createLogisticEmbargoConfiguration,
  updateLogisticEmbargoConfiguration,
} from "@/api/modules/logistics/rmbargoConfiguration.js";
import {
  getAllCanChooseLimitedConfigurationCode,
  createLogisticLimitedConfiguration,
  updateLogisticLimitedConfiguration,
} from "@/api/modules/logistics/limitedConfiguration.js";
import {
getWarehouses
} from "@/api/modules/common/index.js";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref();

    const state = reactive({
      visible: false,
      loading: false,
      formState: {
        logisticId: "",
        id: null,
        addressType: "",
        codes: [],
      },
      platformList: [],
      codeList: [],
    });

    state.platformList = Object.keys(platformAddrTypeEnum).map((item) => {
      return platformAddrTypeEnum[item];
    });

    const getModalTitle = computed(() => {
      let title = state.formState.id
        ? vueI18n.t("account.amend")
        : vueI18n.t("enum.task_type_1");
      title +=
        props.type === 0
          ? vueI18n.t("menu.logistics_embargo_configuration")
          : vueI18n.t("menu.logistics_limited_transportation_configuration");
      return title;
    });

    const rules = {
      addressType: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.platform")]),
      },
      codes: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.code")]),
      },
    };

    const handlePlatformChange = async (codes) => {
      state.codeList = [];
      state.formState.codes = [];
      try {
        let res = null;
        if (state.formState.addressType == platformAddrTypeEnum.SE) {
            res = await getWarehouses({
            "warehouseType": "15",
            "isActive": true
            })
        }else{
           if (props.type === 0) {
            res = await getAllCanChooseEmbargoConfigurationCode({
              addressType: state.formState.addressType,
            })
          }else {
            res = await getAllCanChooseLimitedConfigurationCode({
              addressType: state.formState.addressType,
            })
          }
        }

       

        let { result } = res
        if (result) {
            if (state.formState.addressType == platformAddrTypeEnum.SE) {
              let list = result.map(x=>{
              return{
                value:x.warehouseNo,
                text:x.warehouseNo,
              }
            });
            state.codeList = list;
          }else{
            state.codeList = result;
          }
        }
        if (codes) {
          state.formState.codes = codes;
        }
      } catch (error) {
      }
    };

    const handleOk = async () => {
      try {
        await formRef.value.validate();
        state.loading = true;
        const data = JSON.parse(JSON.stringify(state.formState));
        if (data.id) {
          if (props.type === 0) {
            await updateLogisticEmbargoConfiguration(data);
          } else {
            await updateLogisticLimitedConfiguration(data);
          }
        } else {
          if (props.type === 0) {
            await createLogisticEmbargoConfiguration(data);
          } else {
            await createLogisticLimitedConfiguration(data);
          }
        }
        message.success(vueI18n.t("common.succeed"));
        state.visible = false;
        emit("refresh");
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    const init = (logisticId, record) => {
      state.formState.logisticId = logisticId;
      state.visible = true;
      nextTick(() => {
        formRef.value.resetFields();
        state.formState.id = null;
        if (record) {
          state.formState.id = record.id;
          state.formState.addressType = record.addressType;
          handlePlatformChange([record.code]);
        }
      });
    };

    return {
      ...toRefs(state),
      getModalTitle,
      formRef,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      rules,
      init,
      handlePlatformChange,
      handleOk,
    };
  },
});
</script>

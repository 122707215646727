<template>
  <a-modal
    :title="$t('warehouse.modify_identifiable_information')"
    v-model:visible="state.visible"
    width="600px"
    :closable="false"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="state.formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('warehouse.package_identification_information')" name="packageNo">
        <a-input ref="packageNoRef" v-model:value="state.formState.packageNo" />
      </a-form-item>
      <a-form-item :label="$t('warehouse.additional_identification_information')">
        <a-input v-model:value="state.formState.additionalIdentify" />
      </a-form-item>
      <a-form-item :label="$t('logistics.picture')" name="packageImgs">
        <InboundAddImg
          :allowUploadCount="2"
          :functionModule="29"
          accept=" .jpeg,.jpg,.png"
          v-model:fileList="state.formState.packageImgs"
        ></InboundAddImg>
      </a-form-item>
      <a-form-item label="PIN" name="pinCode">
        <a-input-password v-model:value="state.formState.pinCode" @focus="(event) => { event.currentTarget.select() }" @pressEnter="handleConfirm" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, watch } from 'vue';
import { Form, Input, Modal, message } from 'ant-design-vue';
import InboundAddImg from "../../products/components/InboundAddImg.vue";
import { useI18n } from "vue-i18n/index";
import { refInputElemFocus } from '@/utils/domoperate';
import { updatePackageInfoWithConsole } from '@/api/modules/warehouseMonitor/returnTemporaryStorage.js';

export default defineComponent({
  props: ['visible', 'id'],
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputPassword: Input.Password,
    InboundAddImg,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => ({})
    },
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);
    const packageNoRef = ref(null);

    const state = reactive({
      visible: false,
      confirmLoading: false,
      formState: {
        packageNo: null,
        additionalIdentify: null,
        packageImgs: [],
        pinCode: null,
      }
    });

    const rules = {
      packageNo: {
        required: true,
        whitespace: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.package_identification_information')]),
      },
      packageImgs: {
        required: true,
        validator: (rule, value, callback) => {
          if (value.length === 0) {
            return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.picture')]));
          }
          if (value.length < 2) {
            return Promise.reject(vueI18n.t('common.please_upload_p0_pictures', [2]));
          }
          return Promise.resolve();
        }
      },
      pinCode: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', ['PIN']),
      },
    }

    const elemFocusInput = (elem) => {
      nextTick(() => {
        elem.value.blur();
        refInputElemFocus(elem.value.input);
      });
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState, { id: props.record.id });
        data.packageNo = data.packageNo?.trim();
        data.additionalIdentify = data.additionalIdentify?.trim();
        await updatePackageInfoWithConsole(data);
        message.success(vueI18n.t('common.succeed'));
        emit('confirm');
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => state.formState.packageImgs, (value, oldValue) => {
      if (Array.isArray(value) && Array.isArray(oldValue)) {
        if (value.length !== oldValue.length) {
          formRef.value.validate(['packageImgs']);
        }
      }
    }, { immediate: true, deep: true });

    watch(() => [props.visible, props.record], ([visible, record]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            state.formState.additionalIdentify = null;
            state.formState.packageImgs = [];
            formRef.value.resetFields();

            state.formState.packageNo = record.packageNo;
            state.formState.additionalIdentify = record.additionalIdentify;
            state.formState.packageImgs = record.packageImgs;
          })
          elemFocusInput(packageNoRef);
        }
      }
    }, { immediate: true, deep: true });

    return {
      state,
      rules,
      formRef,
      packageNoRef,
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      close,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>
//仓库信息相关:中文
export default {
  devops: {
    // 揽件任务
    box_no: "箱号",
    // 箱数据
    scanning_device_no: "扫描设备编号",
    generating_facilities: "发生设施",

    account_name: "账号名称",
    running_state: "运行状态",
    api_classification: "API分类",
    api_code: "API代码",
    api_code_json: "API代码JSON",
    example: "示例",
    copy: "复制",
    
    currency: "货币",
    choose_currency: "选择货币",
    configuration_item: "配置项",
    edit_configuration: "编辑配置",
    support_logistics_to_pay: "支持物流到付",

    export_number: "导出条数",
    query_parameters: "查询参数",

    original_province_code: "原始省份CODE",
    target_province_code: "目标省份CODE",
    overview: "简述",
    release_time: "发布时间",
    content: "内容",
    plan_name: "方案名称",
    scheme_type: "方案类型",
    weeks_count: "周数",
    discount_ratio: "折扣比例",

    currency_cache: "币种缓存",
    time_zone_cache: "时区缓存",
    platform_address_cache: "平台地址缓存",
    national_cache: "国家缓存",
    empty_cache: "清空缓存",
    cache_cleanup: "缓存清理",
    storage_space_cleanup: "存储空间清理",
    alibaba_cloud_oss_temporary_space: "阿里云OSS临时空间",
    hours_ago: "小时前",
    
    notification_business_type: "通知业务类型",
    sms_notification: "短信通知",
    email_notification: "邮箱通知",
    notification_template: "通知模板",
    edit_template: "编辑模板",
    edit_sms_template: "编辑短信模板",
    edit_email_template: "编辑箱通模板",
    applicable_area_code: "适用区号",
    template_content: "模板内容",
    create_sms_template: "创建短信模板",
    create_mail_template: "创建邮件模板",
    sms_template: "短信模板",
    mail_template: "邮件模板",
    mail_template_subject: "邮件模板主题",
    sms_platform_template_id: "短信平台模板Id",
    edit_attribute: "编辑属性",
    attribute_name: "属性名称",
    attribute_code: "属性代码",
  }
};
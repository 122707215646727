export default {
  "return": {
    "return_of_the_source": "Return From",
    "the_goods_warehouse": "Cargo Receiving Warehouse",
    "return_package_no": "Return Parcel Number",
    "return_plan_no": "Return Plan Number",
    "returns_number": "Return Quantity",
    "received_quantity": "Recevied Quantity",
    "the_parcel_status": "Parcel Status",
    "return_the_parcel": "Return Parcel",
    "identifiable_information": "Recognizable Information",
    "approved_packing_information": "Confirmed Package Information",
    "have_received": "Received",
    "destroyed": "Disposed",
    "product_SKU": "Product SKU",
    "returns_the_total": "Total Return Quantity",
    "optional_services": "Optional Service",
    "return_service_fee": "Return Service Fee",
    "standard_quality_inspection_service": "Standard Inspection Service",
    "additional_functional_quality_inspection_services": "Extra Functional Inspection Service",
    "quality_inspection_results": "Inspection Result",
    "the_processing_results": "Processing Result",
    "destruction_handling_charge": "Disposal Handling Fee",
    "deducted_automatically": "Automatically Deduct",
    "is_qualified_1": "Qualified",
    "is_qualified_2": "Unqualified",
    "trajectory_status": "Tracking Status",
    "untraceable": "Untrackable"
  }
}
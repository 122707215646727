import client from "../../client"

const Api = {
  // 获取通知场景列表
  getShopNotificationSceneList: "/api/ProductService/ShopNotificationScene/GetSceneList",
  // 开启/关闭短信通知
  isActiveSms: "/api/ProductService/ShopNotificationScene/IsActiveSms",
  // 开启/关闭邮件通知
  isActiveMail: "/api/ProductService/ShopNotificationScene/IsActiveMail",

  // 获取短信模板分页列表
  getShopSmsNotificationTemplateListPaged: "/api/ProductService/ShopSmsNotificationTemplate/GetListPaged",
  // 根据Id获取单个短信模板信息
  getShopSmsNotificationTemplateById: "/api/ProductService/ShopSmsNotificationTemplate/GetById",
  // 创建或修改短信模板
  createOrUpdateShopSmsNotificationTemplate: "/api/ProductService/ShopSmsNotificationTemplate/CreateOrUpdate",
  // 删除短信模板
  deleteShopSmsNotificationTemplate: "/api/ProductService/ShopSmsNotificationTemplate/Delete",
  // 获取短信通知可用的国际区号
  getAvailablePhoneCountryCodeList: "/api/ProductService/ShopSmsNotificationTemplate/GetAvailablePhoneCountryCodeList",

  // 获取邮箱模板分页列表
  getShopMailNotificationTemplateListPaged: "/api/ProductService/ShopMailNotificationTemplate/GetListPaged",
  // 获取单个邮箱模板信息
  getShopMailNotificationTemplateById: "/api/ProductService/ShopMailNotificationTemplate/GetById",
  // 创建或修改邮箱模板
  createOrUpdateShopMailNotificationTemplate: "/api/ProductService/ShopMailNotificationTemplate/CreateOrUpdate",
  // 删除邮箱模板
  deleteShopMailNotificationTemplate: "/api/ProductService/ShopMailNotificationTemplate/Delete",

  // 获取通知属性分页列表
  getShopNotificationAttributeListPaged: "/api/ProductService/ShopNotificationAttribute/GetListPaged",
  // 获取通知模板属性列表
  getShopNotificationAttributeList: "/api/ProductService/ShopNotificationAttribute/GetList",
  // 创建/修改通知属性
  createOrUpdateShopNotificationAttribute: "/api/ProductService/ShopNotificationAttribute/CreateOrUpdate",
  // 删除通知属性
  deleteShopNotificationAttribute: "/api/ProductService/ShopNotificationAttribute/Delete",
  // 根据ld获取单个通知属性信息
  getShopNotificationAttributeById: "/api/ProductService/ShopNotificationAttribute/GetById",
}

// 场景相关

export const getShopNotificationSceneList = (data) => {
  return client.request({
    url: Api.getShopNotificationSceneList,
    data,
    method: 'POST'
  })
}

export const isActiveSms = (data) => {
  return client.request({
    url: Api.isActiveSms,
    data,
    method: 'POST'
  })
}

export const isActiveMail = (data) => {
  return client.request({
    url: Api.isActiveMail,
    data,
    method: 'POST'
  })
}

// 短信模板相关

export const getShopSmsNotificationTemplateListPaged = (data) => {
  return client.request({
    url: Api.getShopSmsNotificationTemplateListPaged,
    data,
    method: 'POST'
  })
}

export const getShopSmsNotificationTemplateById = (params) => {
  return client.request({
    url: Api.getShopSmsNotificationTemplateById,
    params,
    method: 'GET'
  })
}

export const createOrUpdateShopSmsNotificationTemplate = (data) => {
  return client.request({
    url: Api.createOrUpdateShopSmsNotificationTemplate,
    data,
    method: 'POST'
  })
}

export const deleteShopSmsNotificationTemplate = (data) => {
  return client.request({
    url: Api.deleteShopSmsNotificationTemplate,
    data,
    method: 'POST'
  })
}

export const getAvailablePhoneCountryCodeList = (params) => {
  return client.request({
    url: Api.getAvailablePhoneCountryCodeList,
    params,
    method: 'GET'
  })
}

// 邮箱模板相关
export const getShopMailNotificationTemplateListPaged = (data) => {
  return client.request({
    url: Api.getShopMailNotificationTemplateListPaged,
    data,
    method: 'POST'
  })
}

export const getShopMailNotificationTemplateById = (params) => {
  return client.request({
    url: Api.getShopMailNotificationTemplateById,
    params,
    method: 'GET'
  })
}

export const createOrUpdateShopMailNotificationTemplate = (data) => {
  return client.request({
    url: Api.createOrUpdateShopMailNotificationTemplate,
    data,
    method: 'POST'
  })
}

export const deleteShopMailNotificationTemplate = (data) => {
  return client.request({
    url: Api.deleteShopMailNotificationTemplate,
    data,
    method: 'POST'
  })
}

// 属性列表相关

export const getShopNotificationAttributeListPaged = (data) => {
  return client.request({
    url: Api.getShopNotificationAttributeListPaged,
    data,
    method: 'POST'
  })
}

export const getShopNotificationAttributeList = (data) => {
  return client.request({
    url: Api.getShopNotificationAttributeList,
    data,
    method: 'POST'
  })
}

export const createOrUpdateShopNotificationAttribute = (data) => {
  return client.request({
    url: Api.createOrUpdateShopNotificationAttribute,
    data,
    method: 'POST'
  })
}

export const deleteShopNotificationAttribute = (data) => {
  return client.request({
    url: Api.deleteShopNotificationAttribute,
    data,
    method: 'POST'
  })
}

export const getShopNotificationAttributeById = (params) => {
  return client.request({
    url: Api.getShopNotificationAttributeById,
    params,
    method: 'GET'
  })
}
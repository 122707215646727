<template>
  <Content>
    <template #contentTitle>{{ $t("menu.customer_level_list") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 8]" type="flex" justify="space-between">
        <a-col :xl="6" :lg="8" :md="10" :sm="12" :xs="24">
          <a-space>
            <a-input
              style="width: 250px"
              v-model:value="formState.searchKey"
              :placeholder="$t('warehouse.name')"
              allowClear
            />
            <a-button type="primary" @click="handleSearch">{{
              $t("common.query")
            }}</a-button>
          </a-space>
        </a-col>
        <a-col>
          <a-button @click="handleOpenModal()">{{
            $t("common.addition")
          }}</a-button>
        </a-col>
      </a-row>
      <a-table
        style="margin-top: 30px"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ y: wrap.contentHeight - 110 }"
        :pagination="false"
        size="small"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #shopCount="{ record }">
          <router-link :to="{
                  name: 'customer_company_list',
                  params: { levelId: record.id },
                }">
            {{record.shopCount}}
          </router-link>
        </template>
        
        <template #operation="{ record }">
          <a-dropdown-button @click="handleOpenModal(record)">
            {{ $t('common.edit') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1" :record="record" :disabled="record.shopCount !== 0">{{ $t('common.delete') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <FormModal ref="formModalRef" @refresh="getList" />
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager
            @handlePage="handlePage"
            :page-data="tableData.pageData"
          ></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Space,
  Table,
  Popconfirm,
  Dropdown,
  Menu,
  Modal,
  message,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import FormModal from "./components/FormModal.vue";
import { getShopLevelListPaged, deleteShopLevel } from "@/api/modules/customer/level";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "customer_level_list",
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    ASpace: Space,
    ATable: Table,
    APopconfirm: Popconfirm,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    FormModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const formModalRef = ref(null);

    const state = reactive({
      formState: {
        searchKey: null,
      },
      formStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: "name",
        title: () => vueI18n.t("warehouse.name"),
        width: 200,
      },
      {
        dataIndex: "remark",
        title: () => vueI18n.t("common.remark"),
        width: 200,
      },
      {
        title: () => vueI18n.t("warehouse.creation_time"),
        width: 150,
        slots: {
          customRender: "creationTime",
        },
      },
      {
        dataIndex: "shopCount",
        title: () => vueI18n.t("logistics.number_of_companies"),
        width: 100,
         slots: {
          customRender: "shopCount",
        },
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 100,
        flex: "right",
        slots: {
          customRender: "operation",
        },
      },
    ];

    const handleOpenModal = (record) => {
      formModalRef.value.init(record);
    };

    const handlePage = async (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getList();
    };

    const getList = () => {
      state.tableData.loading = true;
      // 列表
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.formStateCache
      );
      getShopLevelListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const initData = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getList();
    };

    const handleSearch = () => {
      Object.assign(state.formStateCache, state.formState);
      initData();
    };

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          Modal.confirm({
            title: vueI18n.t("common.operate"),
            content: vueI18n.t("common.are_you_sure_delete"),
            okText: vueI18n.t("common.confirm"),
            cancelText: vueI18n.t("common.cancel"),
            onOk: () => {
              return deleteShopLevel({ ids: [record.id] }).then(() => {
                message.success(vueI18n.t("common.succeed"));
                initData();
              });
            },
          });
          break;
        default:
          break;
      }
    }

    onMounted(initData);

    return {
      ...toRefs(state),
      formModalRef,
      columns,
      handleSearch,
      handleOpenModal,
      handlePage,
      getList,
      handleMenuClick,
    };
  },
});
</script>
<template>
  <Content>
    <template #contentTitle>{{
      $t("menu.customer_company_accounting_period")
    }}</template>
    <template #contentBody="wrap">
      <a-row
        :gutter="[16, 16]"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <a-col> {{ $t("warehouse.company") }}: {{ shopName || "-" }} </a-col>
        <a-col>
          <a-button type="primary" ghost @click="handleOPenModal()">{{
            $t("common.add")
          }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="tableData.tableList"
        :loading="tableData.loading"
        :scroll="{ x: 500, y: wrap.contentHeight - 110 }"
        :pagination="false"
        :rowKey="(record) => record.logisticsId"
        size="small"
      >
        <template #logisticsName="{ record }">
          <div>{{ record.logistics?.logisticsName || '-' }}</div>
          <div>{{ record.logistics?.logisticsCode || '-' }}</div>
        </template>
        <template #logisticsNode="{ record }">
          {{ $t($enumLangkey('logisticsNode', record.logisticsNode)) }}
        </template>
        <template #accountingPeriodCalculationWay="{ record }">
          {{ $t($enumLangkey('logisticsAccountingPeriodCalculationWay', record.accountingPeriodCalculationWay)) }}
        </template>
        <template #accountingPeriodDays="{ record }">
          {{ record.accountingPeriodDays || '-' }}
        </template>
        <template #lastModificationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown-button @click="handleOPenModal(record.id)">
            {{ $t('common.edit') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :key="1" :record="record">{{ $t('common.delete') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <AccountingPeriodConfigurationModal
        v-model:visible="configState.visible"
        :id="configState.id"
        :shop-info="{ shopId, shopName }"
        @confirm="handleSearch"
      />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="tableData.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, toRefs } from "vue";
import { Button, Col, Dropdown, Menu, Modal, Row, Table, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import AccountingPeriodConfigurationModal from './components/AccountingPeriodConfigurationModal.vue';
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { getInfo } from "@/api/modules/customer/company/details.js";
import {
  getPagedList,
  deleteShopAccountingPeriodConfig,
} from "@/api/modules/customer/company/logisticsAccountingPeriod.js";

export default defineComponent({
  name: "customer_company_accounting_period",
  components: {
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ATable: Table,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    AccountingPeriodConfigurationModal,
  },
  setup() {
    const route = useRoute();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      shopId: null,
      shopName: null,
      searchState: {},
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      configState: {
        visible: false,
        id: null,
      }
    });

    const columns = [
      {
        width: 250,
        title: () => vueI18n.t('logistics.logistics_scheme'),
        slots: {
          customRender: 'logisticsName'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('logistics.logistics_node'),
        slots: {
          customRender: 'logisticsNode'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('logistics.accounting_period_calculation_method'),
        slots: {
          customRender: 'accountingPeriodCalculationWay'
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('logistics.accounting_period_days'),
        slots: {
          customRender: 'accountingPeriodDays'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('logistics.update_time'),
        slots: {
          customRender: 'lastModificationTime'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: 'operation'
        }
      },
    ]

    const handleOPenModal = (id = null) => {
      state.configState.visible = true;
      state.configState.id = id;
    };

    const handleDelete = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operate"),
        content: vueI18n.t("common.are_you_sure_delete"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return deleteShopAccountingPeriodConfig({ ids: [id] }).then(() => {
            message.success(vueI18n.t("common.succeed"));
            handleSearch();
          });
        },
      });
    };

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 1:
          // 删除
          handleDelete(record.id);
          break;
        default:
          break;
      }
    };

    const getCompanyDetail = () => {
      getInfo(state.shopId)
        .then(({ result }) => {
          if (result) {
            state.shopName = result.shopName;
          }
        })
        .catch(() => {});
    };

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache,
        { shopId: state.shopId, isNeedLogistics: true }
      );
      getPagedList(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    onActivated(() => {
      let shopId = route.params?.id;
      state.shopId = shopId;
      if (shopId) {
        getCompanyDetail();
        handleSearch();
      }
    });

    return {
      ...toRefs(state),
      columns,
      handleOPenModal,
      handleDelete,
      handleMenuClick,
      handleSearch,
      handlePage,
    };
  },
});
</script>

<style scoped></style>

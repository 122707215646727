<template>
  <Content>
    <template #contentTitle>{{ $t("menu.dev_announcement") }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px;" v-model:value="searchState.searchKey" :placeholder="$t('warehouse.name')" allow-clear></a-input>
            </a-col>
            <a-col>
              <a-select style="width: 250px;" v-model:value="searchState.announceType" :placeholder="$t('finance.type')" allow-clear>
                <a-select-option
                  v-for="(item, index) in announceTypeEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('announceType', item))"
                >{{ $t($enumLangkey('announceType', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select style="width: 250px;" v-model:value="searchState.status" :placeholder="$t('finance.status')" allow-clear>
                <a-select-option
                  v-for="(item, index) in announceStatusEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('announceStatus', item))"
                >{{ $t($enumLangkey('announceStatus', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-row :gutter="8" type="flex" align="middle">
                <a-col>{{ $t('devops.release_time') }}</a-col>
                <a-col>
                  <a-range-picker style="width: 250px" @change="e => handleDateChange(e, ['beginSendTime', 'endSendTime'])" />
                </a-col>
              </a-row>
            </a-col>
            <a-col>
              <a-row :gutter="8" type="flex" align="middle">
                <a-col>{{ $t('warehouse.creation_time') }}</a-col>
                <a-col>
                  <a-range-picker style="width: 250px" @change="e => handleDateChange(e, ['beginCreationTime', 'endCreationTime'])" />
                </a-col>
              </a-row>
            </a-col>
            <a-col>
              <a-button
                type="primary"
                :loading="tableData.loading"
                @click="handleSearch"
              >{{ $t("common.search") }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" ghost @click="handleOpenEditModal()">{{ $t('common.create') }}</a-button>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 110 }"
        :pagination="false"
        size="small"
        :loading="tableData.loading"
        :row-key="(record) => record.id"
      >
        <template #itemTitle="{ record }">
          <div style="width: 160px;">
            <a-typography-paragraph style="margin: 0;" :ellipsis="true" :content="record.title"/>
          </div>
        </template>
        <template #digest="{ record }">
          <div style="width: 180px;">
            <a-typography-paragraph style="margin: 0;" :ellipsis="true" :content="record.digest"/>
          </div>
        </template>
        <template #type="{ record }">
          <a-tag color="green" v-if="record.announceType === announceTypeEnum.updateLog">{{ $t($enumLangkey("announceType", record.announceType)) }}</a-tag>
          <a-tag color="orange" v-else-if="record.announceType === announceTypeEnum.systemAnnouncement">{{ $t($enumLangkey("announceType", record.announceType)) }}</a-tag>
        </template>
        <template #status="{ record }">
          {{ $t($enumLangkey('announceStatus', record.status)) }}
        </template>
        <template #sendTime="{ record }">
          {{ $filters.utcToCurrentTime(record.sendTime) }}
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown-button @click="handleOpenDetailModal(record.id)">
            {{ $t('common.details') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :key="1" :record="record">{{ $t('common.edit') }}</a-menu-item>
                <a-menu-item :key="2" :record="record">{{ $t('common.delete') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <EditModal v-model:visible="editModalState.visible" :id="editModalState.id" @confirm="handleSearch" />
      <AnnouncementContentModal v-model:visible="announcementState.visible" :id="announcementState.id" />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="tableData.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Button, Col, DatePicker, Dropdown, Input, Menu, Modal, Row, Select, Table, Tag, Typography, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import EditModal from './components/EditModal.vue';
import AnnouncementContentModal from './components/AnnouncementContentModal.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from 'vuex';
import { currentTimeToUtc } from '@/utils/general.js';
import { getAnnounceListPaged, deleteAnnounce } from '@/api/modules/devops/announcement.js';
import { announceType as announceTypeEnum, announceStatus as announceStatusEnum } from '@/enum/enum.json';

export default defineComponent({
  name: "dev_announcement",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ATag: Tag,
    ATypographyParagraph: Typography.Paragraph,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    EditModal,
    AnnouncementContentModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    let userInfo = getters.userInfo;

    const state = reactive({
      searchState: {
        searchKey: null,
        announceType: null,
        status: null,
        beginCreationTime: null,
        endCreationTime: null,
        beginSendTime: null,
        endSendTime: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      editModalState: {
        visible: false,
        id: null,
      },
      announcementState: {
        visible: false,
        id: null,
      },
    });

    const columns = [
      {
        width: 180,
        title: () => vueI18n.t('warehouse.name'),
        slots: {
          customRender: 'itemTitle'
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('devops.overview'),
        slots: {
          customRender: 'digest'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('finance.type'),
        slots: {
          customRender: 'type'
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('finance.status'),
        slots: {
          customRender: 'status'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('devops.release_time'),
        slots: {
          customRender: 'sendTime'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.creation_time'),
        slots: {
          customRender: 'creationTime'
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: 'operation'
        }
      },
    ]

    const handleDateChange = (e, keys) => {
      if (e.length === 2) {
        state.searchState[keys[0]] = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00"),
          userInfo.timezone,
        );
        state.searchState[keys[1]] = currentTimeToUtc(
          e[1]?.format("YYYY-MM-DD 00:00:00"),
          userInfo.timezone,
        );
      } else {
        state.searchState[keys[0]] = null;
        state.searchState[keys[1]] = null;
      }
    }

    const handleOpenEditModal = (id = null) => {
      state.editModalState.visible = true
      state.editModalState.id = id
    }

    const handleOpenDetailModal = (id = null) => {
      state.announcementState.visible = true
      state.announcementState.id = id
    }

    const handleDelete = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operate"),
        content: vueI18n.t("common.are_you_sure_delete"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return deleteAnnounce({ ids: [id] }).then(() => {
            message.success(vueI18n.t("common.succeed"));
            handleSearch();
          });
        },
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 1:
          // 编辑
          handleOpenEditModal(record.id);
          break;
        case 2:
          handleDelete(record.id);
          // 删除
          break;
        default:
          break;
      }
    }

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getAnnounceListPaged(data)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {})
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    onMounted(handleSearch);

    return {
      ...toRefs(state),
      announceTypeEnum,
      announceStatusEnum,
      columns,
      handleDateChange,
      handleOpenEditModal,
      handleOpenDetailModal,
      handleMenuClick,
      handleSearch,
      handlePage,
    };
  },
});
</script>

<style scoped></style>

import client from "../../client";

const Api = {
  // 获取退款暂存店铺名称分页列表
  getReturnStorageStoreListPaged: "/api/WarehouseService/ReturnStorageStoreManager/GetListPaged",
  // 获取退款暂存店铺名称详细
  getReturnStorageStore: "/api/WarehouseService/ReturnStorageStoreManager/Get",
  // 修改退款暂存店铺名称
  updateReturnStorageStore: "/api/WarehouseService/ReturnStorageStoreManager/Update",
  // 删除退款暂存店铺名称
  deleteReturnStorageStore: "/api/WarehouseService/ReturnStorageStoreManager/Delete",
};

export const getReturnStorageStoreListPaged = (data) => {
  return client.request({
    url: Api.getReturnStorageStoreListPaged,
    data,
    method: "POST",
  });
};

export const getReturnStorageStore = (params) => {
  return client.request({
    url: Api.getReturnStorageStore,
    params,
    method: "GET",
  });
};

export const updateReturnStorageStore = (data) => {
  return client.request({
    url: Api.updateReturnStorageStore,
    data,
    method: "POST",
  });
};

export const deleteReturnStorageStore = (data) => {
  return client.request({
    url: Api.deleteReturnStorageStore,
    data,
    method: "POST",
  });
};


import siteConfigs from "@/siteconfigs/index"
import { login, logout, getUserInfo, getMenusOfUser } from "../../api/modules/user";
import { nameKeys } from "../../consts/index"
import { createRouteByList } from "../../router/guards"
import {getDynamicRoutes} from "../../router/module/dynamic-routes";

const clearLoginInfo = (commit) => {
    commit('SET_USER_MENU');
    commit('SET_USER_TOKEN');
    commit('SET_USER_INFO');
    commit('SET_USER_TIMEZONE');
}

const getLocalRoutes = () => {
    try {
        let rList = localStorage.getItem(nameKeys.userRoutes)
            ? JSON.parse(localStorage.getItem(nameKeys.userRoutes))
            : [];
        return rList;
    } catch (e) {
        return [];
    }
}

const getLocalInfo = () => {
    try {
        let info = localStorage.getItem(nameKeys.userInfo)
            ? JSON.parse(localStorage.getItem(nameKeys.userInfo))
            : null;
        return info;
    } catch (e) {
        return [];
    }
}

const setUserRoutes = async (commit) => {
    let menuArr = [];
    try {
        let res = await getMenusOfUser();
        menuArr = res.result;
    } catch (e) {
    }
    let fullRoutes = getDynamicRoutes();
    let intersectMenuArr = [];
    let item;
    fullRoutes.forEach(x=>{
        item = menuArr.find(m=>m.rightUniKey == x.name);
        if(item){
            x.icon = item.piCon||x.icon;
            x.sort = item.pOrder||0;
            intersectMenuArr.push(x);
        }
    })
    // let menuKeySet = new Set(menuArr.map(x => x.rightUniKey));
    // let intersectMenuArr = getDynamicRoutes().filter(x => menuKeySet.has(x.name))
    //const userMenus = createRouteByList(getDynamicRoutes());
    const userMenus = createRouteByList(intersectMenuArr);
    commit('SET_USER_MENU', userMenus);
}

const loadUserInfo = async (commit) => {
    let res = await getUserInfo();
    if (res.code == 0 && res.result) {
        commit('SET_USER_INFO', res.result);
        commit('SET_USER_TIMEZONE', (res.result || {}).timezone);
        await setUserRoutes(commit);
    }
}

const state = {
    token: localStorage.getItem(nameKeys.token) ? localStorage.getItem(nameKeys.token) : "",
    userInfo: getLocalInfo(),
    userRoutes: getLocalRoutes(),
    userTimezone: localStorage.getItem(nameKeys.timezone) ? localStorage.getItem(nameKeys.timezone) : "",
}

const mutations = {
    SET_USER_TOKEN(state, token) {
        if (token) {
            state.token = token;
            localStorage.setItem(nameKeys.token, token);
        } else {
            state.token = "";
            localStorage.removeItem(nameKeys.token);
        }
    },
    SET_USER_INFO(state, userInfo) {
        if (userInfo) {
            state.userInfo = userInfo
            localStorage.setItem(nameKeys.userInfo, JSON.stringify(userInfo))
        } else {
            state.userInfo = null
            localStorage.removeItem(nameKeys.userInfo)
        }
    },
    SET_USER_MENU(state, menuList) {
        if (menuList && menuList.length > 0) {
            const finalMenu = menuList
            state.userRoutes = finalMenu
            localStorage.setItem(nameKeys.userRoutes, JSON.stringify(finalMenu))
        } else {
            state.userRoutes = []
            localStorage.removeItem(nameKeys.userRoutes)
        }
    },
    SET_USER_TIMEZONE(state, timezone) {
        if (timezone) {
            state.userTimezone = timezone;
            localStorage.setItem(nameKeys.timezone, timezone);
        } else {
            state.userTimezone = "";
            localStorage.removeItem(nameKeys.timezone);
        }
    }
}


const actions = {

    async logout({ commit }) {

        logout(); //api logout
        clearLoginInfo(commit);
    },

    async login({ state, commit }, data) {
        try {
            let res = await login(data); //api login
            if (res.code == 0) {
                const token = res.result.accessToken;
                await commit('SET_USER_TOKEN', token);
                await loadUserInfo(commit);
                return;
            }
            clearLoginInfo(commit);
            throw res;
            // const response = await login(data); //api login
            // const {success, message, result: userInfo} = response;
            // if(success){
            //     const {token} = userInfo;
            //     delete userInfo.token;
            //     commit('SET_USER_TOKEN', token)
            //     commit('SET_USER_INFO', userInfo)
            //     return Promise.resolve()

            // }else{
            //     return Promise.reject(message)
            // }

        }
        catch (e) {
            // console.log(e);
            clearLoginInfo(commit);
            throw e;
            //return Promise.reject(message)
        }
    },

    async addUserRoutes({ state, commit }) {
        await setUserRoutes(commit);
    },

    async reloadUserInfo({ state, commit }) {
        await loadUserInfo(commit);
        return state.userInfo;
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

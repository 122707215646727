import client from "../../client";

const Api = {
  getList: "/api/SupplierService/Partner/GetListPaged ",
  openAccount: "/api/SupplierService/Partner/Create",
  getPartnerInfo: "/api/SupplierService/Partner/GetById",
  payCommission: "/api/SupplierService/PartnerSettlementRecord/Create",
  //更新账户状态
  updateStatus:"/api/SupplierService/Partner/UpdateIsActive"
};

export const getList = ({
  searchKey,
  isActive,
  partnerType,
  partnerGroupId,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getList,
    data: {
      searchKey,
      isActive,
      partnerType,
      partnerGroupId,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "POST",
  });
};

export const openAccount = ({
  partnerType,
  name,
  businessLicenseCode,
  businessLicense,
  partnerGroupId,
  timezone,
  countryId,
  settlementChannelType,
  partnerSettlementChannel,
  phoneCountryCode,
  phone
}) => {
  return client.request({
    url: Api.openAccount,
    data: {
      partnerType,
      name,
      businessLicenseCode,
      businessLicense,
      partnerGroupId,
      timezone,
      countryId,
      settlementChannelType,
      partnerSettlementChannel,
      phoneCountryCode,
      phone
    },
    method: "POST",
  });
};

export const getPartnerInfo = (id) => {
  return client.request({
    url: Api.getPartnerInfo,
    params: {
      id,
    },
    method: "get",
  });
};

export const payCommission = ({
  partnerId,
  settlementNo,
  amount,
  settlementChannelId,
  settlementChannelType,
  accountName,
  bankName,
  collectionAccountNumber,
}) => {
  return client.request({
    url: Api.payCommission,
    data: {
      partnerId,
      settlementNo,
      amount,
      settlementChannelId,
      settlementChannelType,
      accountName,
      bankName,
      collectionAccountNumber,
    },
    method: "POST",
    disableAutoRedirect: true,
  });
};


export const updateStatus = ({
  pinCode,
  id,
  isActive,
}) => {
  return client.request({
    url: Api.updateStatus,
    data: {
      pinCode,
      id,
      isActive,
    },
    method: "POST",
    disableAutoRedirect: true,
  });
};

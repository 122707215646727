import client from "../../client"

const Api = {
    //获取分区分页列表
    getPagedList: "/api/LogisticsService/Region/GetPagedList",

    //创建分区信息
    create: "/api/LogisticsService/Region/Create",

    //更新分区信息
    update: "/api/LogisticsService/Region/Update",

    //根据Id获取详细
    getById: "/api/LogisticsService/Region/GetById",

    //删除分区
    deleteById: "/api/LogisticsService/Region/Delete",
    
    //根据物流方案，获取可用分区
    getListByLogisticsId: "/api/LogisticsService/Region/GetListByLogisticsId",
}

//获取分区分页列表
export const getPagedList = ({ logisticsId, sorting, skipCount, maxResultCount }) => {
    return client.request({
        url: Api.getPagedList,
        data: { logisticsId, sorting, skipCount, maxResultCount },
        method: 'POST'
    });
}

//创建分区信息
export const create = ({
    id,
    logisticsId,
    name,
    regionTags,
    isVatRequired,
    isDdpSupported,
    ddpFee,
    isDdpIncludeFuelSurcharge,
    isSignSupported,
    signatureDtos,
    feeTemplateId,
    surchargeConfigId,
    regionTypes,
    regionType,
    regionCountryRls,
    regionProvinceRls,
    regionCityRls,
    regionCountyRls,
    regionPostCodeRls
}) => {
    return client.request({
        url: Api.create,
        data: { id, logisticsId, name, regionTags, isVatRequired, isDdpSupported, ddpFee, isDdpIncludeFuelSurcharge, isSignSupported, signatureDtos, feeTemplateId, surchargeConfigId, regionTypes, regionType, regionCountryRls, regionProvinceRls, regionCityRls, regionCountyRls, regionPostCodeRls },
        method: 'POST'
    });
}

//更新分区信息
export const update = ({
    id,
    logisticsId,
    name,
    regionTags,
    isVatRequired,
    isDdpSupported,
    ddpFee,
    isDdpIncludeFuelSurcharge,
    isSignSupported,
    signatureDtos,
    feeTemplateId,
    surchargeConfigId,
    regionTypes,
    regionType,
    regionCountryRls,
    regionProvinceRls,
    regionCityRls,
    regionCountyRls,
    regionPostCodeRls
}) => {
    return client.request({
        url: Api.update,
        data: { id, logisticsId, name, regionTags, isVatRequired, isDdpSupported, ddpFee, isDdpIncludeFuelSurcharge, isSignSupported, signatureDtos, feeTemplateId, surchargeConfigId, regionTypes, regionType, regionCountryRls, regionProvinceRls, regionCityRls, regionCountyRls, regionPostCodeRls },
        method: 'POST'
    });
}

//根据Id获取详细
export const getById = (id) => {
    return client.request({
        url: Api.getById,
        params: { regionId: id },
        method: 'GET'
    });
}

//删除分区
export const deleteById = (id) =>{
    return client.request({
        url: Api.deleteById,
        data: { ids:[id]},
        method: 'POST'
    });
}


//根据物流方案，获取可用分区
export const getListByLogisticsId = (logisticsId) => {
    return client.request({
      url: Api.getListByLogisticsId,
      params: { logisticsId: logisticsId },
      method: "post",
    });
  };
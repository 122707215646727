<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('logistics.select_logistics_scheme')"
    :centered="true"
    :mask-closable="false"
    width="auto"
    :footer="null"
  >
    <a-tabs v-if="isShowCalculationMethod" v-model:activeKey="calculationMethod" @change="handleChangeLogisticsScheme">
      <a-tab-pane :key="0" :disabled="logisticsSchemeLoading" :tab="$t('logistics.logistics')"></a-tab-pane>
      <a-tab-pane :key="1" :disabled="logisticsSchemeLoading" :tab="$t('logistics.truck')"></a-tab-pane>
    </a-tabs>
    <a-table
      :columns="logistics.columnsLogisticsScheme"
      :data-source="logistics.logisticsSchemeExhibitionList"
      :scroll="{ x: 500, y: 1000 }"
      :pagination="false"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      size="small"
      :loading="logisticsSchemeLoading"
    >
      <template #logisticsSchemeTitle>
        <span>{{$t('logistics.logistics_scheme')}} / {{ $t('logistics.logistics_code') }}</span>
      </template>
      <template #optionCustom="{ record }">
        <a-button v-if="!selectedLogisticsId" @click="getTransportPlanDetail(record)">
          {{ $t("common.select") }}
        </a-button>
      </template>
      <template #logisticsSchemeCustom="{ record }">
        <div class="text-warning">{{ record.logisticsBaseInfo.name }} / {{ record.logisticsBaseInfo.code }}</div>
        <a-row :gutter="[8, 8]">
          <a-col v-for="item in record.logisticsBaseInfo.descriptionItems" :key="item">
            <CheckCircleOutlined
              class="text-warning mr-1"
              :style="{ fontSize: '12px' }"
            />
            <span style="font-size: 12px; color: rgba(102, 102, 102, 1)">
              {{ item }}
            </span>
          </a-col>
        </a-row>
      </template>
      <template #logisticsWayCustom="{ record }">
        {{ $t($enumLangkey("logisticsWay", record.logisticsBaseInfo.logisticsType)) }}
      </template>
      <template #deliveryWayCustom="{ record }">
        {{ $t($enumLangkey("deliveryWay", record.logisticsBaseInfo.deliveryType)) }}
      </template>
      <template #referenceAgingCustom="{ record }">
        <div>
          {{ record.logisticsBaseInfo.daysMin }}-{{ record.logisticsBaseInfo.daysMax }}
          {{ $t("logistics.workday") }}
          ({{ $t($enumLangkey("aging", record.logisticsBaseInfo.channelTimeType)) }})
        </div>
      </template>
      <template #freightsCustom="{ record }">
        <div class="text-warning">
          {{ $t("warehouse.average_unit_price") }}: {{ record.logisticsBaseInfo.currencySymbol
          }}{{ record.logisticsBaseInfo.averagePrice }}
        </div>
        <div class="text-warning">
          {{ $t("logistics.total") }}: {{ record.logisticsBaseInfo.currencySymbol
          }}{{ record.logisticsBaseInfo.totalFeeWithoutServices }}
        </div>
        <div style="font-size: 10px; color: #808080">
          {{ $t("warehouse.tooltip_text_6") }}
        </div>
      </template>
      <template #detailsCustom="{ record }">
        <div>
          {{ $t("logistics.chargeable_weight") }}:
          {{ handleGToKg(record.logisticsBaseInfo.chargeableWeight) }}kg
        </div>
        <div>
          {{ $t("logistics.volume_weight_coefficient")
          }}{{ handleCmToM(record.logisticsBaseInfo.volumeWeightParam) }}
        </div>
      </template>
      <template #optionalServicesCustom="{ record }">
        <template v-if="selectedLogisticsId">
          <div v-if="record.logisticsRegionInfo.isDdpSupported">
            <a-checkbox v-model:checked="record.isChooseDdp">DDP({{ record.logisticsBaseInfo.currencySymbol }}{{ record.logisticsRegionInfo.ddpFee }})</a-checkbox>
          </div>
          <CSignSupported
            v-if="record.logisticsRegionInfo.isSignSupported"
            v-model:value="record.signatureType"
            type="editable"
            :symbol="record.logisticsBaseInfo.currencySymbol"
            :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
          />
        </template>
        <template v-else>
          <div v-if="record.logisticsRegionInfo.isDdpSupported">
            DDP({{ record.logisticsBaseInfo.currencySymbol }}{{ record.logisticsRegionInfo.ddpFee }})
          </div>
          <CSignSupported
            v-if="record.logisticsRegionInfo.isSignSupported"
            type="preview"
            :symbol="record.logisticsBaseInfo.currencySymbol"
            :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
          />
        </template>
      </template>
      <!-- 卡车专属 -->
      <template #quoteOptionsTitle="{ record }">
        {{ $t('logistics.quote_options') }}
        <CTooltip :title="$t('logistics.quote_options_tips')" />
      </template>
      <template #quoteOptions="{ record }">
        <a-row :gutter="[16, 8]">
          <a-col :span="24">
            <a-row type="flex">
              <a-col flex="100px"><span class="text-error">*</span>{{ $t('logistics.quote_type') }}</a-col>
              <a-col flex="auto">
                <a-select
                  v-model:value="record.quoteTypeId"
                  size="small"
                  style="width: 150px;"
                  :disabled="record.loading"
                  @change="record.quoteDetail = null"
                >
                  <a-select-option
                    v-for="(item, index) in quoteTypeList"
                    key="index"
                    :value="item.id"
                    :title="item.name"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24" v-if="handleShowServiceLevelSelect(record)">
            <a-row type="flex">
              <a-col flex="100px"><span class="text-error">*</span>{{ $t('logistics.service_level') }}</a-col>
              <a-col flex="1">
                <a-select
                  v-model:value="record.serviceLevel"
                  size="small"
                  style="width: 150px;"
                  :disabled="record.loading"
                  @change="record.quoteDetail = null"
                >
                  <a-select-option
                    v-for="(item, index) in serviceLevelList"
                    key="index"
                    :value="item.id"
                    :title="item.name"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24">
            <a-row type="flex">
              <a-col flex="100px"><span>&nbsp;&nbsp;</span>{{ $t('logistics.additional_options') }}</a-col>
              <a-col flex="1">
                <a-select
                  v-model:value="record.accessorials"
                  mode="multiple"
                  size="small"
                  style="width: 150px;"
                  :disabled="record.loading"
                  @change="record.quoteDetail = null"
                >
                  <a-select-option
                    v-for="(item, index) in additionalOptionsList"
                    key="index"
                    :value="item.id"
                    :title="item.name"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24">
            <a-row type="flex">
              <a-col flex="100px"></a-col>
              <a-col flex="1">
                <a-button size="small" type="ghost" :loading="record.loading" @click="handleSearchTruckFee(record)">{{ $t('logistics.inquiry_immediately') }}</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </template>
      <template #quoteDetails="{ record }">
        <a-space>
          <span>{{ $t('logistics.quote') }}:</span>
          <span v-if="record.quoteDetail">{{ record.quoteDetail.carrierName }} [{{ $filters.formatCurrencyAmount(record.quoteDetail.currencySymbol, record.quoteDetail.totalCharge, false) }}, {{ handleParseTime(record.quoteDetail.transitTime) }} day(s)]</span>
          <span v-else>-</span>
        </a-space>
        <div class="mt-3" v-if="record.quoteDetail">
          <a-button type="ghost" :disabled="record.loading" @click="handleSelectedLogistics(record)">{{ $t('common.confirm') }}</a-button>
        </div>
      </template>
      <template #title v-if="selectedLogisticsId">
        <a-button type="ghost" @click="getTransportPlanGetLogisticList">
          {{ $t('logistics.re_select') }}
        </a-button>
      </template>
      <template #footer v-if="selectedLogisticsId">
        <a-row type="flex" justify="end">
          <a-col>
            <a-button type="primary" @click="handleSelectedLogistics()">
              {{ $t("common.confirm") }}
            </a-button>
          </a-col>
        </a-row>
      </template>
    </a-table>
    <VatFormModal ref="vatFormModalRef" @confirm="handleConfirmLogisticsChannel" />
    <QuotedPriceModal ref="quotedPriceModalRef" @confirm="handleConfirmQuotedPrice" />
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, toRefs } from "vue";
import { Modal, Table, Button, Row, Col, Checkbox, message, Tabs, Space, Select, Input } from "ant-design-vue";
import VatFormModal from './VatFormModal.vue';
import QuotedPriceModal from './QuotedPriceModal.vue';
import CTooltip from '@/views/components/CTooltip.vue'
import CSignSupported from '@/views/components/CSignSupported.vue'
import {
  transportPlanGetLogistic,
  getTransportPlanLogisticesFeeDetail,
  costChangeTransportPlanLogisticesFee,
} from "@/api/modules/transport/index";
import { useI18n } from "vue-i18n/index";
import { gToKg, cmCubicToM } from "@/utils/general"
import { useSupplierHelper } from '@/utils/supplierHelper.js'
import { feeCalculationMethod as feeCalculationMethodEnum } from "@/enum/enum.json";

export default defineComponent({
  components: {
    AModal: Modal,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATable: Table,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    ACheckbox: Checkbox,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    ARow: Row,
    ACol: Col,
    VatFormModal,
    QuotedPriceModal,
    CTooltip,
    CSignSupported,
  },
  emits: ['refresh'],
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const vatFormModalRef = ref(null);
    const quotedPriceModalRef = ref(null);
    const supplierHelper = useSupplierHelper();

    const state = reactive({
      planId: '',
      visible: false,
      logisticsSchemeLoading: true,
      logistics: {
        columnsLogisticsScheme: [],
        logisticsSchemeExhibitionList: [],
        logisticsSchemeList: [],
      },
      isShowCalculationMethod: true,
      calculationMethod: 0,
      // 卡车-报价类型
      quoteTypeList: [],
      // 卡车-服务等级
      serviceLevelList: [],
      // 卡车-附加选项
      additionalOptionsList: [],
      // 弹窗表格数据
      quotedPriceList: [],
      // 选择的物流ID
      selectedLogisticsId: null,
    });

    const columns = [
      {
        width: 80,
        slots: {
          title: "option",
          customRender: "optionCustom",
        },
      },
      {
        width: 350,
        slots: {
          title: "logisticsSchemeTitle",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.reference_aging"),
        slots: {
          customRender: "referenceAgingCustom",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.freight_forecast"),
        slots: {
          customRender: "freightsCustom", //平均单价和合计
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("common.details"),
        slots: {
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
      },
      {
        width: 200,
        title: () => vueI18n.t('return.optional_services'),
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
      },
    ]

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsSchemeTitle",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.logistics_type"),
        slots: {
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("logistics.delivery_way"),
        slots: {
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 300,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t('logistics.quote_details'),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ]

    const handleGToKg = (val) => {
      if (!val) {
        return 0
      }
      return gToKg(val)
    }

    const handleCmToM = (val) => {
      if (!val) {
        return 0
      }
      return cmCubicToM(val)
    }

    const handleParseTime = (num) => {
      let number = Number.parseInt(num)
      return Number.isNaN(number) ? num : number
    }

    const handleShowServiceLevelSelect = (record) => {
      let item = state.quoteTypeList.find(item => item.id === record.quoteTypeId)
      if (item) {
        return item.needService
      }
      return false
    }

    const handleSearchTruckFee = (record) => {
      if (!record.quoteTypeId) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.quote_type')]))
        return 
      }
      if (!handleShowServiceLevelSelect(record)) {
        record.serviceLevel = null
      } else {
        if (!record.serviceLevel) {
          message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('logistics.service_level')]))
          return 
        }
      }
      const searchData = {
        planId: state.planId,
        logisticsId: record.logisticsBaseInfo.id,
        truckApiLogistics: {
          quoteTypeId: record.quoteTypeId,
          serviceLevel: record.serviceLevel ?? null,
          accessorials: record.accessorials ?? [],
        }
      }
      quotedPriceModalRef.value.open(searchData)
    }

    const getSupplierList = async () => {
      try {
        state.quoteTypeList = await supplierHelper.getQuoteTypeList()
        state.serviceLevelList = await supplierHelper.getServiceLevelList()
        state.additionalOptionsList = await supplierHelper.getAdditionalOptionsList()
      } catch (error) {
        // console.log(error);
      }
    }

    const handleChangeLogisticsScheme = () => {
      state.logistics.logisticsSchemeExhibitionList = state.logistics.logisticsSchemeList.filter(item => item.logisticsBaseInfo.feeCalculationMethod === state.calculationMethod)
      if (state.logistics.logisticsSchemeList.length) {
        // 如果只有（物流或卡车中的）一种方案就不显示tab
        if (state.logistics.logisticsSchemeExhibitionList.length === 0 || state.logistics.logisticsSchemeExhibitionList.length === state.logistics.logisticsSchemeList.length) {
          state.isShowCalculationMethod = false
        } else {
          state.isShowCalculationMethod = true
        }
        if (state.logistics.logisticsSchemeExhibitionList.length === 0) {
          if (state.calculationMethod === feeCalculationMethodEnum.template) {
            state.calculationMethod = feeCalculationMethodEnum.truckApi
          } else {
            state.calculationMethod = feeCalculationMethodEnum.template
          }
          state.logistics.logisticsSchemeExhibitionList = state.logistics.logisticsSchemeList.filter(item => item.logisticsBaseInfo.feeCalculationMethod === state.calculationMethod)
        }
      } else {
        state.isShowCalculationMethod = false
      }
      if (state.calculationMethod === feeCalculationMethodEnum.template) {
        // 物流
        state.logistics.columnsLogisticsScheme = columns;
      } else {
        // 卡车
        state.logistics.columnsLogisticsScheme = columnsLogisticsTruck;
        getSupplierList()
      }
    }

    const getTransportPlanDetail = (record) => {
      state.selectedLogisticsId = record.logisticsBaseInfo.id;
      state.logisticsSchemeLoading = true;
      const data = {
        planId: state.planId,
        logisticsId: record.logisticsBaseInfo.id,
      }
      getTransportPlanLogisticesFeeDetail(data).then(({ result }) => {
        if (result) {
          state.logistics.logisticsSchemeExhibitionList = [result]
        } else {
          state.logistics.logisticsSchemeExhibitionList = []
        }
      }).catch(() => {}).finally(() => {
        state.logisticsSchemeLoading = false
      })
    }

    const handleSelectedLogistics = (record) => {
      if (!record) {
        record = state.logistics.logisticsSchemeExhibitionList.find(item => item.logisticsBaseInfo.id === state.selectedLogisticsId)
        if (!record) {
          return
        }
      }
      const data = {
        planId: state.planId,
        logisticsId: record.logisticsBaseInfo.id,
        deliveryType: record.logisticsBaseInfo.deliveryType,
        isChooseDdp: record.isChooseDdp,
        signatureType: record.signatureType,
        isChooseSign: record.signatureType > 0 ? true : false,
        vatCode: record.vatCode,
        truckApiLogistics: null
      }
      // 卡车判断
      if (state.calculationMethod === feeCalculationMethodEnum.truckApi) {
        data.truckApiLogistics = {}
        data.truckApiLogistics['quoteDetailId'] = record.quoteDetail.quoteDetailId
        data.truckApiLogistics['quoteTypeId'] = record.quoteTypeId
        data.truckApiLogistics['serviceLevel'] = record.serviceLevel ?? null
        data.truckApiLogistics['accessorials'] = record.accessorials ?? []
      }
      if (record.logisticsRegionInfo.isVatRequired) {
        vatFormModalRef.value.show(data)
      } else {
        handleConfirmLogisticsChannel(data)
      }
    }

    const handleConfirmLogisticsChannel = (data) => {
      state.logisticsSchemeLoading = true
      costChangeTransportPlanLogisticesFee(data).then((res) => {
        let { result } = res
        if (result) {
          message.success(vueI18n.t('common.succeed'))
          emit('refresh')
          nextTick(() => {
            state.visible = false
          })
        } else {
          message.error(vueI18n.t('common.failed'))
        }
      }).finally(() => {
        state.logisticsSchemeLoading = false
      })
    }

    const getTransportPlanGetLogisticList = () => {
      state.selectedLogisticsId = null;
      state.logisticsSchemeLoading = true;
      transportPlanGetLogistic({ planId: state.planId })
        .then(({ result }) => {
          if (Array.isArray(result)) {
            state.logistics.logisticsSchemeList = result.map(item => {
              item.isChooseDdp = false
              item.isChooseSign = false
              item.signatureType = null
              item.vatCode = null
              item.quoteDetail = null,
              item.loading = false
              item.quoteTypeId = null
              item.serviceLevel = null
              item.accessorials = []
              // if (oldData && oldData.logisticsId === item.logisticsBaseInfo.id) {
              //   item.isChooseDdp = oldData.isChooseDdp
              //   item.isChooseSign = oldData.isChooseSign
              //   item.signatureType = oldData.signatureType
              //   item.vatCode = oldData.vatCode
              // }
              return item
            })

            handleChangeLogisticsScheme()
          } else {
            state.logistics.logisticsSchemeList = []
          }
        })
        .catch((err) => { })
        .finally(() => {
          state.logisticsSchemeLoading = false;
        });
    }

    const init = (planId, oldData) => {
      state.visible = true;
      state.planId = planId;
      getTransportPlanGetLogisticList();
    };

    const handleConfirmQuotedPrice = ({ logisticsId, record }) => {
      let index = state.logistics.logisticsSchemeExhibitionList.findIndex(item => item.logisticsBaseInfo.id === logisticsId)
      if (index > -1) {
        state.logistics.logisticsSchemeExhibitionList[index].quoteDetail = record
      }
    }

    return {
      ...toRefs(state),
      columns,
      vatFormModalRef,
      quotedPriceModalRef,
      handleGToKg,
      handleCmToM,
      handleParseTime,
      handleSearchTruckFee,
      handleShowServiceLevelSelect,
      handleChangeLogisticsScheme,
      getTransportPlanDetail,
      handleSelectedLogistics,
      handleConfirmLogisticsChannel,
      handleConfirmQuotedPrice,
      getTransportPlanGetLogisticList,
      init,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.warehouse_monitor_allot_plan") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input v-model:value="searchKey"
                   :placeholder="$t('warehouse.plan_no_or_company_id')"
                   style="width: 250px;"
                   allowClear />
        </a-col>
         <a-col >
              <a-input style="width: 250px;"
                       v-model:value="box"
                       :placeholder="`${$t('warehouse.box_number')}`"
                       allowClear />
            </a-col>
        <a-col>
          <CSearchShop v-model:shopId="shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-select v-model:value="selectedPlanStatus"
                    :placeholder="$t('finance.status')"
                    allow-clear
                    style="width: 250px;">
            <a-select-option v-for="(value,key) in allotPlanStatusEnum"
                             :key="key"
                             :title="$t($enumLangkey('allotPlanStatus',value))"
                             :value="value">
              {{$t($enumLangkey('allotPlanStatus',value))}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select v-model:value="selectedConsignment"
                    allow-clear
                    optionFilterProp="search-key"
                    show-search
                    :placeholder="$t('warehouse.consignment_code')"
                    style="width: 250px;">
            <a-select-option v-for="item in consignments"
                             :key="item.value"
                             :title="`${item.text1}(${item.text})`"
                             :value="item.value"
                             :search-key="item.text1 + item.text">
              {{item.text1}}({{item.text}})
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select v-model:value="selectedStorage"
                    allow-clear
                    show-search
                    optionFilterProp="search-key"
                    :placeholder="$t('warehouse.storage_code')"
                    style="width: 250px;">
            <a-select-option v-for="item in storages"
                             :key="item.value"
                             :title="`${item.text1}(${item.text})`"
                             :value="item.value"
                             :search-key="item.text1 + item.text">
              {{item.text1}}({{item.text}})
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <SearchProduct ref="searchProductRef" />
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleChange">{{$t('common.search')}}</a-button>
        </a-col>
        <a-col>
          <a-button type="ghost" @click="handleShowPinModal(1)">{{$t('common.export')}}</a-button>
        </a-col>
      </a-row>

      <div class="inventory-table mt-3">
        <a-table :columns="columns"
                 size="small"
                 :data-source="planList"
                 :scroll="{ x:1000, y: wrap.contentHeight - 150}"
                 :pagination="false"
                 :loading="planListLoading"
                 class="product-table">

          <template #planNo>
            <div>{{$t('warehouse.plan_no')}}</div>
          </template>
          <template #shopId>
            <div>{{$t('warehouse.company_id')}}</div>
          </template>

          <template #consignmentCode>
            <span>
              {{ $t("warehouse.consignment_code") }}
            </span>
          </template>

          <template #storageCode>
            <span>
              {{ $t("warehouse.storage_code") }}
            </span>
          </template>
          <template #plannedQuantity>
            <span>
              {{ $t("warehouse.planned_quantity") }}
            </span>
          </template>
          <template #plannedBoxQuantity>
            <span>
              {{ $t("warehouse.planned_box_quantity") }}
            </span>
          </template>
          <template #productQuantity>
            <span>
              {{ $t("warehouse.product_quantity") }}
            </span>
          </template>
          <template #inPlanStatus>
            <span>
              {{ $t("finance.status") }}
            </span>
          </template>
          <template #storageInPlanNo>
            <span>
              {{ $t("warehouse.storage_in_plan_no") }}
            </span>
          </template>
          <template #creationTime>
            <span>
              {{ $t("warehouse.creation_time") }}
            </span>
          </template>
          <template #updateTime>
            <span>
              {{ $t("logistics.last_modification_time") }}
            </span>
          </template>
        <template #customer="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
            <div>
              {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>
          <template #planStatusCustom="{ record }">
            {{$t($enumLangkey('allotPlanStatus',record.planStatus))}}
          </template>
          <template #storageInPlanNoCustomer="{ record }">
            {{ record.storageInPlanNo || '-' }}
          </template>

          <template #creationTimeCustom="{ record }">
            {{$filters.utcToCurrentTime(record.creationTime)}}
          </template>
          <template #updateTimeCustom="{ record }">
            {{$filters.utcToCurrentTime(record.updateTime)}}
          </template>

          <template #operate="{ record }">
            <router-link :to="{name:'warehouse_monitor_allot_plan_detail',  params: {'id': record.planId}}">
              <a-button type="ghost">
                {{$t('common.details')}}</a-button>
            </router-link>
          </template>

        </a-table>
      </div>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, ref, onActivated } from "vue";
import {
  Row, Col,
  Table, Input,
  Select, Button, Form, message,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CSearchShop from "../../components/CSearchShop.vue"
import SearchProduct from "@/views/components/SearchProduct.vue"
import CPinTipsModal from '@/views/components/CPinTipsModal.vue'

import { getAllotPlans, exportTransferToWarehousePlanConsole } from "../../../api/modules/warehouseMonitor/allot";
import { getOptionWarehouses } from "../../../api/modules/common/index";
import { allotPlanStatus as allotPlanStatusEnum } from "../../../enum/enum.json";
import { useI18n } from "vue-i18n/index";
import { dateString } from "@/utils/general";
import { downloadFile } from '@/utils/downloader.js';

export default defineComponent({
  name: "warehouse_monitor_allot_plan",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AButton: Button,
    ASelect: Select,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    Content,
    CPager, 
    CSearchShop,
    SearchProduct,
    CPinTipsModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const refPage = ref();
    const searchProductRef = ref(null);

    const state = reactive({
      allotPlanStatusEnum: allotPlanStatusEnum,
      planListLoading: false,
      searchKey: null,
      shopId: null,
      productId: null,
      selectedPlanStatus: null,
      selectedConsignment: null,
      selectedStorage: null,
      consignments: [],
      storages: [],
      planList: [],
      box:null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      pinModalState: {
        visible: false,
        loading: false,
        key: null,
        dataTemp: null,
      }
    });

    const columns = [
      {
        dataIndex: "planNo",
        slots: {
          title: "planNo",
        },
        fixed: "left",
        width: 170,
      },
     {
        title: () => vueI18n.t("warehouse.customer"),
        slots: {
          customRender: "customer"
        },
        width: 180,
      },
      {
        dataIndex: "consignmentCode",
        slots: {
          title: "consignmentCode",
        },
        width: 100,
      },
      {
        dataIndex: "storageCode",
        slots: {
          title: "storageCode",
        },
        width: 100,
      },
      {
        dataIndex: "plannedQuantity",
        slots: {
          title: "plannedQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "plannedBoxQuantity",
        slots: {
          title: "plannedBoxQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "productQuantity",
        slots: {
          title: "productQuantity",
        },
        width: 100,
      },

      {
        dataIndex: "inPlanStatus",
        slots: {
          title: "inPlanStatus",
          customRender: "planStatusCustom",
        },
        width: 100,
      },
      {
        slots: {
          title: "storageInPlanNo",
          customRender: "storageInPlanNoCustomer"
        },
        width: 150,
      },
      {
        dataIndex: "creationTime",
        slots: {
          title: "creationTime",
          customRender: "creationTimeCustom",
        },
        width: 150,
      },
      {
        dataIndex: "updateTime",
        slots: {
          title: "updateTime",
          customRender: "updateTimeCustom",
        },
        width: 150,
      },
      {
        title: "",
        slots: {
          customRender: "operate",
        },
        fixed: "right",
        width: 100,
      },
    ];

    const handleShowPinModal = (key) => {
      state.pinModalState.key = key
      state.pinModalState.visible = true
    }

    const handlePinModalConfirm = (pinCode) => {
      switch (state.pinModalState.key) {
        // 导出
        case 1:
          handleExport(pinCode)
          break;
        default:
          break;
      }
    }
    
    const handleExport = (pinCode) => {
      state.pinModalState.loading = true
      let url = exportTransferToWarehousePlanConsole();
      let searchData = {
        status: state.selectedPlanStatus,
        delegateWarehouseId: state.selectedConsignment,
        storageWarehouseId: state.selectedStorage,
        searchKey: state.searchKey,
        shopId: state.shopId,
        pinCode,
      };
      downloadFile(url, `allot_plans_${dateString()}.csv`, "POST", searchData).then(() => {
        state.pinModalState.visible = false
        message.success(vueI18n.t('common.succeed'))
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcSearchPlans();
    }

    const handleChange = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcSearchPlans();
    }

    const funcSearchPlans = async () => {
      let searchData = {
        "status": state.selectedPlanStatus,
        "delegateWarehouseId": state.selectedConsignment,
        "storageWarehouseId": state.selectedStorage,
        "searchKey": state.searchKey,
        "boxNo": state.box,
        "shopId": state.shopId,
        ...state.pageData
      };
      let productId = searchProductRef.value.selectedProductId;
      if (productId) {
        searchData.productId = productId;
      } else {
        searchData.productId = null;
      }
      state.planListLoading = true;
      getAllotPlans(searchData).then((res) => {
        if (res.result) {

          let { items, totalCount } = res.result;
          state.planList = items.map((x, index) => {
            return {
              key: index + 1,
              planId: x.id,
               shopName: x.shopName,
                shopId: x.shopId,
                shopNo: x.shopNo,
                shopMark: x.shopMark,

              planNo: x.planNo,
              consignmentCode: x.agentWarehouseNo,
              storageCode: x.storeWarehouseNo,
              plannedQuantity: x.planCount,
              plannedBoxQuantity: x.planBoxCount,
              productQuantity: x.productCount,
              planStatus: x.status,
              storageInPlanNo: x.inWarehousePlanNo,
              creationTime: x.creationTime,
              updateTime: x.lastModificationTime,
            }
          })
          state.pageData.totalCount = parseInt(totalCount);
        } else {
          state.pageData.totalCount = 0;
        }
        state.planListLoading = false;
      }).catch(() => {
        state.planListLoading = false;
      });
    }

    const funcGetConsignments = async () => {
      getOptionWarehouses({ warehouseType: 2 }).then((res) => {
        if (res.result && res.result.length > 0) {
          state.consignments = res.result;
        }
      })
    }

    const funcGetStorages = async () => {
      getOptionWarehouses({ warehouseType: 1 }).then((res) => {
        if (res.result && res.result.length > 0) {
          state.storages = res.result;
        }
      })
    }

    onMounted(async () => {
      funcGetConsignments();
      funcGetStorages();
    })

    onActivated(() => {
      funcSearchPlans();
    })
    return {
      columns,
      ...toRefs(state),
      refPage,
      searchProductRef,

      handleShowPinModal,
      handlePinModalConfirm,
      handlePage,
      funcSearchPlans,
      handleChange
    };
  }
})
</script>

<style lang="less" scoped>
</style>
<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('common.edit')"
    width="700px"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 10 }"
    >
      <a-form-item :label="$t('account.company_name')" name="shopName">
        <a-input v-model:value="formState.shopName" allow-clear></a-input>
      </a-form-item>
      <a-form-item
        :label="$t('account.registration_documents')"
        name="businessLicense"
      >
        <a-upload
          list-type="picture-card"
          :show-upload-list="false"
          :customRequest="handleUpdateLicense"
        >
          <div v-if="formState.businessLicense" class="licence-img">
            <img
              v-if="urlHelper.isImageUrl(formState.businessLicense)"
              :src="formState.businessLicense"
            />
            <span
              v-else-if="urlHelper.isPdfUrl(formState.businessLicense)"
              class="icon-link"
            >
              <FilePdfOutlined />
            </span>
            <span v-else class="icon-link">
              <LinkOutlined />
            </span>
          </div>
          <div v-else>
            <plus-outlined></plus-outlined>
            <div class="ant-upload-text">{{ $t("common.upload_img") }}</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item
        :label="$t('account.register_id')"
        name="businessLicenseCode"
      >
        <a-input
          v-model:value="formState.businessLicenseCode"
          allow-clear
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick } from "vue";
import { Button, Form, Input, Modal, Upload } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { uploadFile } from "@/api/modules/common/index";
import urlHelper from "@/utils/urlHelper.js";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AButton: Button,
    AUpload: Upload,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);

    const state = reactive({
      visible: false,
      formState: {
        id: null,
        shopName: null,
        businessLicense: null,
        businessLicenseCode: null,
      },
      uploading: false,
    });

    const rules = {
      shopName: [
        {
          required: true,
          message: vueI18n.t(
            "common.p0_is_required",
            vueI18n.t("account.company_name")
          ),
        },
      ],
      businessLicense: [
        {
          required: true,
          message: vueI18n.t(
            "common.p0_is_required",
            vueI18n.t("account.registration_documents")
          ),
        },
      ],
      businessLicenseCode: [
        {
          required: true,
          message: vueI18n.t(
            "common.p0_is_required",
            vueI18n.t("account.register_id")
          ),
        },
      ],
    };

    const handleUpdateLicense = (file) => {
      let uploadData = new FormData();
      uploadData.append("file", file.file);
      uploadData.append("functionModule", 12);
      state.uploading = true;
      uploadFile(uploadData)
        .then(({ result }) => {
          state.formState.businessLicense = result[0].url;
          if (formRef.value && formRef.value.validate) {
            formRef.value.validate("businessLicense");
          }
        })
        .catch(() => {})
        .finally(() => {
          state.uploading = false;
        });
    };

    const open = (info) => {
      state.visible = true;
      nextTick(() => {
        formRef.value.resetFields()
        for (const key in state.formState) {
          if (Object.hasOwnProperty.call(info, key)) {
            state.formState[key] = info[key];
          }
        }
      })
    };

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        const data = Object.assign({}, state.formState)
        emit("confirm", data);
        state.visible = false
      } catch (error) {
      }
    };

    return {
      ...toRefs(state),
      formRef,
      rules,
      urlHelper,
      handleUpdateLicense,
      open,
      handleConfirm,
    };
  },
});
</script>

<style lang="less" scoped>
.licence-img {
  max-width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(img) {
    max-height: 86px;
    max-width: 86px;
  }
}

.icon-link {
  font-size: 40px;
  color: @primary-color;
}
</style>

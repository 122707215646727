<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.in_warehouse_plan_detail") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="pageLoading">
        <a-row :gutter="[0, 8]">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.inbound_plan_number')}}: {{planNo}}
                <template v-if="hasException">
                  <a-tag class="ml-1" v-if="planStatus === inWarehouseStatusEnum.complete || planStatus === inWarehouseStatusEnum.cancel">{{ $t('common.exception') }}</a-tag>
                  <a-tag class="ml-1" color="error" v-else>{{ $t('common.exception') }}</a-tag>
                </template>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.inbound_plan_name')}}: {{planName}}
              </a-col>
              <a-col :span="24" v-if="relation">
                {{ $t('warehouse.source_related_doc_no') }}: <CPlanNumberItem :no="relation"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" v-else-if="toRelationPlanNo">
                {{ $t('warehouse.target_related_doc_no') }}: <CPlanNumberItem :no="toRelationPlanNo"></CPlanNumberItem>
              </a-col>
              <a-col :span="24" v-else>
                {{ $t('warehouse.relation_no') }}: -
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.creation_time") }}: {{ $filters.utcToCurrentTime(creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.last_modification_time") }}: {{ $filters.utcToCurrentTime(lastModificationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.logistics_way')}}:
                <span v-if="selectedLogisticsWay">{{$t($enumLangkey('logisticsWay',selectedLogisticsWay))}}</span>
                <span v-else>-</span>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('logistics.delivery_way')}}:
                <span v-if="selectedDeliveryWay"> {{$t($enumLangkey('deliveryWay',selectedDeliveryWay))}}</span>
                <span v-else>-</span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]" style="height: 100%;" type="flex" align="middle">
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t('warehouse.plan_status') }}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <span v-if="planStatus">
                    <a-typography-text type="secondary" v-if="planStatus === inWarehouseStatusEnum.cancel">{{ $t($enumLangkey('inWarehouseStatus', planStatus)) }}</a-typography-text>
                    <a-typography-text type="success" v-else>{{ $t($enumLangkey('inWarehouseStatus', planStatus)) }}</a-typography-text>
                  </span>
                  <span v-else>-</span>
                </div>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{ $t('warehouse.inbound_type') }}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <a-typography-text type="success" v-if="inWarehouseType">{{ $t($enumLangkey('inWarehouseType', inWarehouseType)) }}</a-typography-text>
                  <span v-else>-</span>
                </div>
              </a-col>
              <a-col :span="24">
                <div style="text-align: center;">
                  <a-button type="primary" @click="handleTrackShowModal" ghost>{{ $t('warehouse.tracking_shipment') }}</a-button>
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{ $t('warehouse.customer') }}:
            <span v-if="shopName">{{ shopName || '-' }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
             {{$t('common.shop_mark')}}:
            <span v-if="shopMark">{{ shopMark || '-' }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            NO:
            <span v-if="shopNo">{{ shopNo || '-' }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{$t('logistics.originate')}}: {{ originate || '-' }}
            <span v-if="originateType">({{ $t($enumLangkey('warehouseType', originateType)) }})</span>
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            {{$t('warehouse.send_goods_address')}}: {{ getAddress(originateAddress) }}
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            {{$t('warehouse.inbound_target_warehouse')}}: {{ inTargetWarehouse || '-' }}
            <span v-if="inTargetWarehouseType">({{ $t($enumLangkey('warehouseType', inTargetWarehouseType)) }})</span>
          </a-col>
          <a-col :md="16" :sm="24" :xs="24">
            {{$t('warehouse.warehouse_address')}}: {{ getAddress(warehouseAddress)}}
            <span class="ml-5" v-if="warehouseAddress">{{ $t('logistics.contacts') }}: {{getLinkman(warehouseAddress)}}</span>
          </a-col>
        </a-row>
        <div class="plan-table mt-2">
          <a-table
            style="overflow-x: auto;"
            :columns="columns"
            :data-source="planDetails"
            size="small"
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
            :pagination="{
              defaultPageSize:defaultPageSize,
              hideOnSinglePage:true,
              current:currentPageIndex,
              position:'both',
            }"
            :row-key="(record) => record.detailId"
            @change='(page) => {currentPageIndex= page.current;}'
          >
            <template #boxNo="{ record }">
              {{ record.box.boxNo || '-' }}
              <CTagMixedOrSingle :productKindCount="record.box.container.productKindCount * 1"></CTagMixedOrSingle>
            </template>
            <template #expandIcon="{ expanded, record, onExpand }">
              <CHaveProductInformation :disabled="!record.box.container?.hasRelationProduct" :open="expanded" @click="e => onExpand(record, e)" />
            </template>
            <template #encasementSize="{ record }">
              <span v-if="record.box.container">{{ record.box.container.containerLength }}x{{ record.box.container.containerWidth }}x{{ record.box.container.containerHeight }}cm</span>
              <span v-else>-</span>
            </template>
            <template #encasementWeight="{ record }">
              <span v-if="record.box.container">{{ gToKg(record.box.container.containerWeight) }}kg</span>
              <span v-else>-</span>
            </template>
            <template #encasementCount="{ record }">
              {{ record.box.container ? record.box.container.containerCount : '-' }} Unit
            </template>
            <template #labelDispose="{ record }">
              {{ $t($enumLangkey('labelDispose', record.productLabelProcessing)) }}
            </template>
            <template #inWarehouseStatus="{ record }">
              {{ $t($enumLangkey('inWarehouseStatus', record.inWarehouseStatus)) }}
            </template>
            <template #inWarehouseCount="{ record }">
              {{record.box.inWarehouseCount ? record.box.inWarehouseCount+ 'Unit' :"-"}}
            </template>
            <template #errorState="{ record }">
              <div class="text-error" v-if="record.box.exception.currentErrorType">
                <a-row type="flex"  align="middle">
                  <a-col>
                    {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.currentErrorType)) }}
                  </a-col>
                </a-row>
              </div>
              <span class="text-error" v-else-if="record.box.exception.boxErrorType">
                {{ $t($enumLangkey('inWarehouseOperationErrorType', record.box.exception.boxErrorType)) }} 
              </span>
              <span v-else>-</span>
            </template>
            <template #needOperate="{ record }">
              <div v-if="record.box.exception.boxErrorType">
                <!-- 1 : 接收超时(漏发 和请求调查)-->
                <div v-if="record.box.exception.boxErrorType == inWarehouseOperationErrorTypeEnum.receiveTimeout">
                  {{ $t($enumLangkey('productErrorDisposeType', record.box.exception.timeOut.inWarehouseErrorCustomerType)) }}
                </div>
                <!-- 2 : 错误产品 (销毁 和更改箱信息) -->
                <div v-if="record.box.exception.boxErrorType == inWarehouseOperationErrorTypeEnum.errorProduct">
                  {{ $t($enumLangkey('inWarehouseErrorForErrorProductCustomerType', record.box.exception.errorProduct?.inWarehouseErrorCustomerType)) }}
                
                </div>
              </div>
              <span v-else>-</span>
            </template>
            <template #operateCustomer="{ record }">
              <a-button
                v-if="record.inWarehouseStatus === inWarehouseStatusEnum.waitStorage"
                type="ghost"
                @click="handleShowPinModal(record)"
              >{{ $t('common.cancel') }}</a-button>
            </template>
            <template #title>
              <a-row justify="end">
                <a-col><a-button type="primary" ghost @click="handleShowProductStatisticsModal">{{ $t('common.product_statistics') }}</a-button></a-col>
              </a-row>
            </template>
            <template #footer>
              <a-row  type="flex" justify="space-between" v-if="total">
                <a-col :span="8">{{ $t('common.total') }}: {{ total.plan?.totalContainerBoxCount }}{{ $t('warehouse.box') }}</a-col>
                <a-col :span="8">
                  <a-row :gutter="[16,0]">
                    <a-col>{{ total.plan ? cmCubicToM(total.plan?.totalVolume) : '-' }} m<sup>3</sup></a-col>
                    <a-col>{{ total.plan ? gToKg(total?.plan?.totalWeight) : '-' }} kg</a-col>
                    <a-col>{{ total.plan ? total.plan?.totalWarehouseCount : '-' }} Unit</a-col>
                  </a-row>
                </a-col>
                <a-col :span="8">
                   <a-row :gutter="[16,0]">
                   <a-col v-if="total?.check?.totalVolume > 0">{{cmCubicToM(total.check.totalVolume)}} m<sup>3</sup></a-col>
                   <a-col v-if="total?.check?.totalWeight > 0">{{ gToKg(total.check.totalWeight) }} kg</a-col>
                   <a-col v-if="total?.check?.totalWarehouseCount > 0">{{total.check.totalWarehouseCount}} Unit</a-col>
                  </a-row>
                </a-col>
             </a-row> 
            </template>

            <template #expandedRowRender="{ record }">
              <a-table
                :columns="innerColumns"
                :data-source="record.box.items"
                :pagination="false"
                :row-key="(record, index) => index"
                size="small"
              >
                <template #productInfo="{ record }">
                  <a-row :gutter="8" type="flex" align="middle">
                    <a-col>
                      <div class="table-list-img-common">
                        <c-image
                          :src="record.item.productImgUrl"
                          :thumbWidth="600"
                          :thumbHeight="600"
                        />
                      </div>
                    </a-col>
                    <a-col flex="1">
                      <div>{{ record.item.productName }}</div>
                      <div>{{ record.item.productNo }}</div>
                    </a-col>
                  </a-row>
                </template>
                <template #encasementCount="{ record }">
                  {{ record.item.containerCount }} Unit
                </template>
                <template #checkSize="{ record }">
                  <span v-if="record.packing && record.packing?.checkContainerLength">
                    {{ record.packing?.checkContainerLength || '-' }}x{{ record.packing?.checkContainerWidth || '-' }}x{{ record.packing?.checkContainerHeight || '-' }}cm
                  </span>
                  <span v-else>-</span>
                </template>
                <template #checkWeight="{ record }">
                  <span v-if="record.packing && record.packing?.checkContainerWeight">{{ gToKg(record.packing?.checkContainerWeight) }}kg</span>
                  <span v-else>-</span>
                </template>
                <template #checkCount="{ record }">
                  <span v-if="record.packing?.checkCount">{{ record.packing?.checkCount }} Unit</span>
                  <span v-else>-</span>
                </template>
                <template #checkImgUrls="{ record }">
                  <a-popover v-if="record.packing?.checkImgUrls && record.packing.checkImgUrls.length > 0" :getPopupContainer="trigger => trigger.parentNode">
                    <template #content>
                      <a-space>
                        <div v-for="(imgItem, index) in record.packing.checkImgUrls"
                             class="table-list-img-common"
                             :key="index">
                          <c-image :src="imgItem" />
                        </div>
                      </a-space>
                    </template>
                    <span class="text-primary" style="font-size:20px">
                      <FileImageOutlined />
                    </span>
                  </a-popover>
                  <span v-else>-</span>
                </template>
                <template #errorLabelExplain="{ record }">
                  <span v-if="record.labelFeeRemark">
                    {{$t('warehouse.labels_cannot_be_scanned_handling_expense_p0', [record.labelFeeRemark])}}
                  </span>
                </template>
                <template #operator="{record}">
                  <span v-if="record.operator">{{ getFullName(record.operator) }}</span>
                  <span v-else>{{ record?.operatorName ?? "-" }}</span>
                </template>
              </a-table>
            </template>
          </a-table>
          <!-- 费用明细 -->
          <div class="footer-button mt-3">
            <CFee :fees="fee?.fees" :totalFee="fee?.totalFee"></CFee>

            <a-row type="flex"
                   justify="end">
              <a-col class="mr-3">
                <a-button type="primary"
                          @click="handleBack"
                          ghost>{{$t('common.backtrack')}}</a-button>
              </a-col>
            </a-row>
          </div>
        </div>
        <Trace ref="refTrace"></Trace>
      </a-spin>
      <CProductStatisticsModal ref="productStatisticsModalRef" :show-inbound="true" />
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handleCancelBox"
      />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import {
  Row, Col, Tag, Typography, Upload, Tooltip, Table,
  Button, Spin, Popover, Space, Form, message
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CImage from "@/views/components/CImage.vue";
import CProductStatisticsModal from '@/views/components/CProductStatisticsModal.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import CTagMixedOrSingle from '@/views/components/CTagMixedOrSingle.vue';
import CPinTipsModal from '@/views/components/CPinTipsModal.vue';
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CFee from "@/views/components/CFee.vue";
import Trace from "./TraceProductNo.vue";
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useTab } from "@/hooks/tabs/index";
import { inPlaneBoxCancel } from "@/api/modules/warehouseMonitor/index";
import { getInPlanDetail } from "@/api/modules/warehouseMonitor/consignmentWarehousing";
import { getAddressByLanguageV2, gToKg, cmCubicToM, getNameByLanguage, getLinkman } from "@/utils/general";
import {
  inWarehouseStatus as inWarehouseStatusEnum,
  productErrorDisposeType as disposeTypeEnum,
  warehouseType as warehouseTypeEnum,
  labelDispose as labelDisposeEnum,
  productStatisticsType as productStatisticsTypeEnum,
  inWarehouseOperationErrorType as inWarehouseOperationErrorTypeEnum,

} from "@/enum/enum.json";

export default ({
  name: "warehouse_monitor_consignment_warehousing_task_detail",
  components: {
    ATooltip: Tooltip,
    AUpload: Upload,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    ATag: Tag,
    ATypographyText: Typography.Text,
    AButton: Button,
    ASpin: Spin,
    APopover: Popover,
    ASpace: Space,
    AForm: Form,
    AFormItem: Form.Item,
    Content,
    CImage,
    CFee,
    CProductStatisticsModal,
    CHaveProductInformation,
    CTagMixedOrSingle,
    CPinTipsModal,
    CPlanNumberItem,
    Trace,
  },
  setup () {
    const i18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();

    const refTrace = ref();
    const refPage = ref();
    const productStatisticsModalRef = ref();
    const state = reactive({
      inWarehouseStatusEnum,
      warehouseTypeEnum: warehouseTypeEnum,
      disposeTypeEnum: disposeTypeEnum,
      labelDisposeEnum: labelDisposeEnum,
      pageLoading: false,
      pageSaveLaoding: false,
      hasException: false,
      accomplishDisposeError: false,

      showAlertButtonSurveyModalIds: [],

      showAlertButtonChangeProductModalIds: [],
      currentOptiongErrorDisposeProductId: "",//操作的当前下拉框的行数据产品Id
      currentOptiongErrorDisposeId: "",//操作的当前下拉框的行数据id
      changeProductModalSelectedId: "",//当前箱(更改)已选的产品Id
      pinModalState: {
        visible: false,
        loading: false,
        id: null,
      },
    });

    const saveSurveyData = [];
    const saveChangeProductData = [];
    const data = reactive({
      planDetails: [],
      planId: "",
      planNo: "",
      creationTime: null,
      lastModificationTime: null,
      planName: "",
      planStatus: "",
      inWarehouseType: "",
      selectedLogisticsWay: "",
      selectedDeliveryWay: "",

      originate: "",
      originateType: "",
      inTargetWarehouse: "",
      inTargetWarehouseType: "",
      inTargetWarehouseEnum: "",
      originateAddress: "",
      warehouseAddress: "",
      relation: null,
      toRelationPlanNo: null,
      totalBoxCount: "",
      totalVolume: "",
      totalEncasementWeight: "",
      totalEncasementCount: "",

      fee: [],

      shopName: "",
      shopId: "",
      shopNo: "",
      shopMark:""
    });

    const total = reactive({
    })

    let surveyModel = reactive({
      id: "",//dataId
      surveyBoxNo: "",
      surveyPlanNo: "",
      surveyLogisticsType: "",
      surveyDeliveryType: "",
      surveyTrackingNo: "",

      surveyApplyContent: "",
      surveyCredentials: []
    })

    let columns = [
      {
        dataIndex: 'serialNumber',
        width: 60,
        title: () => i18n.t("warehouse.serial_number"),
      },
      {
        width: 200,
        title: () => i18n.t("warehouse.box_number"),
        slots: {
          customRender: "boxNo",
        }
      },
      { width: 50, },
      {
        width: 150,
        title: () => i18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 120,
        title: () => i18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 100,
        title: () => i18n.t("warehouse.number"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 120,
        title: () => i18n.t("warehouse.label_processing"),
        slots: {
          customRender: "labelDispose"
        }
      },
      {
        width: 120,
        title: () => i18n.t("warehouse.inbound_status"),
        slots: {
          customRender: "inWarehouseStatus"
        }
      },
       {
        width: 100,
        title: () => i18n.t("warehouse.inbound_receipt_quantity"),
        slots: {
          customRender: "inWarehouseCount"
        }
      },
      {
        width: 150,
        title: () => i18n.t("warehouse.error_state"),
        slots: {
          customRender: "errorState"
        }
      },
      {
        width: 180,
        title: () => i18n.t("warehouse.need_operate"),
        slots: {
          customRender: "needOperate",
        },
      },
      {
        width: 120,
        slots: {
          customRender: "operateCustomer",
        },
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        title: 'SESKU',
        dataIndex: "item[seSku]",
        width: 150,
      },
      {
        width: 150,
        title: () => i18n.t("warehouse.number"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 250,
        title: () => i18n.t("warehouse.approved_package_size"),
        slots: {
          customRender: "checkSize"
        }
      },
      {
        width: 150,
        title: () => i18n.t("warehouse.approved_package_weight"),
        slots: {
          customRender: "checkWeight"
        }
      },
      {
        width: 150,
        title: () => i18n.t("warehouse.inbound_receipt_quantity"),
        slots: {
          customRender: "checkCount"
        }
      },
      {
        width: 150,
        title: () => i18n.t("warehouse.in_warehouse_img"),
        slots: {
          customRender: "checkImgUrls"
        }
      },
       {
        width: 120,
        title: () => '',
        slots: {
          customRender: "errorLabelExplain"
        }
      },
      {
        width: 200,
        title: () => i18n.t('account.operator'),
        slots: {
          customRender: "operator",
        },
      },
    ];

    const handleShowPinModal = (record) => {
      state.pinModalState.visible = true;
      state.pinModalState.loading = false;
      state.pinModalState.id = record.detailId;
    }

    const handleCancelBox = (pin) => {
      state.pinModalState.loading = true
      const requestData = {
        planId: data.planId,
        detailId: state.pinModalState.id,
        pin,
        warehouseType: warehouseTypeEnum.consignment
      }
      inPlaneBoxCancel(requestData).then(() => {
        message.success(i18n.t('common.succeed'))
        state.pinModalState.visible = false
        init()
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const funcSearchPlanDetail = async (planId) => {
      let searchData = {
        "id": planId,
      };
      state.pageLoading = true;
      getInPlanDetail(searchData)
        .then((res) => {
          let r = res.result;
          data.planName = r.planName;
          data.planNo = r.planNo;
          data.relation = r.relation;
          data.toRelationPlanNo = r.toRelationPlanNo;
          
          data.creationTime = r.creationTime;
          data.lastModificationTime = r.lastModificationTime;
          data.planId = r.id;
          state.isConfirm = r.isConfirm;
          state.isDespatch = r.isDeliver;
          data.targetWarehouseType = r.to.warehouseType;
          data.selectedLogisticsWay = r.logisticsType;
          data.selectedDeliveryWay = r.deliveryType;
          state.hasException = r.hasException;
          data.planStatus = r.inWarehouseStatus;
          data.inWarehouseType = r.inWarehouseType;
          data.shopName = r.shopName;
          data.shopId = r.shopId;
          data.shopNo = r.shopNo;
          data.shopMark = r.shopMark;
          if (r.to.no && r.to.warehouseType > 0) {
            data.inTargetWarehouse = r.to.no ?? "";
            data.inTargetWarehouseType = r.to.warehouseType ?? "";
          } else {
            data.inTargetWarehouse = "";
          }
          data.inTargetWarehouseEnum = r.to.warehouseType;
          if (r.from.isWarehouse && r.from.no && r.from.warehouseType > 0) {
            data.originate = r.from.no ?? "";
            data.originateType = r.from.warehouseType ?? "";
          } else {
            data.originate = r.from.name ? r.from.name : "";
          }

          data.originateAddress = r.from;
          data.warehouseAddress = r.to;

          data.fee = r.fee;

          data.planDetails = r.items.map((x, index) => {
            x.box.items.forEach(product => {
              let imgUrls = []
              if (Array.isArray(product.packing?.checkImgUrls)) {
                imgUrls = imgUrls.concat(product.packing?.checkImgUrls);
              }
              if (Array.isArray(product.errorProductUrls)) {
                imgUrls = imgUrls.concat(product.errorProductUrls);
              }
              if (Array.isArray(product.loseCountUrls)) {
                imgUrls = imgUrls.concat(product.loseCountUrls);
              }
              if (!product.packing) {
                product.packing = {}
              }
              product.packing.checkImgUrls = imgUrls;
            });
            x.serialNumber = index + 1;
            return x;
          })
          Object.assign(total,r.total)
          

          _funcFilterTableColumns(r.to.warehouseType);
          //判断:异常处理是否都处理了(找到有错误的,并且没有处理的)
          state.accomplishDisposeError = data.planDetails.findIndex(
            x => x.productInWarehouseErrorType && !x.productErrorDisposeResut) == -1;

          state.pageLoading = false;
        })
        .catch((res) => {
          state.pageLoading = false;
        })
      state.pageLoading = true;
    }

    //过滤table的errorType||operate列
    const _funcFilterTableColumns = (fromWarehouseType) => {
      let hasException = (data.planDetails.findIndex(x => x.productInWarehouseErrorType) > -1) ||
        (data.planDetails.findIndex(x => x.labelFeeRemark) > -1)

      if (!hasException) {
        columns = columns.filter(x =>
          (x.dataIndex != "errorType") && (x.dataIndex != "operate"));
      }

      if (fromWarehouseType != warehouseTypeEnum.consignment) {
        columns = columns.filter(x =>
          (x.dataIndex != "inProductCount") && (x.dataIndex != "labelProcessing"));
      }

    }

    const handleBack = async () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "warehouse_monitor_consignment_warehousing_task" });
    }

    const handleTrackShowModal = async () => {
      refTrace.value.handleTrackShowModal(data.planId);
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const funcGetFileType = function (file) {
      if (file && file.suffix) {
        if ([".png", ".jpg", ".jpeg", ".gif", ".bmp"].findIndex(x => x == file.suffix) > -1) {
          return "img"
        } else if (file.suffix == ".pdf") {
          return "pdf"
        } else {
          return "link"
        }
      }
    }

    const deleteFile = async (itemIndex) => {
      surveyModel.surveyCredentials = surveyModel.surveyCredentials.filter((x, index) => index != itemIndex);
    }

    const handelCancelChangeProductModal = () => {
      //判断是否为'修改modal'情况下的取消
      let isAlterOfCancel = saveChangeProductData.findIndex(x => x.id == state.currentOptiongErrorDisposeId) > -1
      if (!isAlterOfCancel) {
        data.planDetails
          .find(x => x.id == state.currentOptiongErrorDisposeId)
          .inWarehouseErrorCustomerType = null;
      }
    }

    const getFullName = (user) => {
      if (!user) {
        return ''
      }
      let userName = getNameByLanguage(user, getters.language)
      return `${userName}(${user.employeeNo})`
    }

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(productStatisticsTypeEnum.inWarehouse, route.params.id, warehouseTypeEnum.consignment)
    }



    const init = () => {
      let planId = route.params.id;
      if (planId) {
        funcSearchPlanDetail(planId);
      }
    }

    onMounted(init)

    const currentPageIndex = ref(1);
    const defaultPageSize = ref(50);

    return {
      ...toRefs(state),
      ...toRefs(data),
      total,
      gToKg,
      cmCubicToM,

      inWarehouseOperationErrorTypeEnum,

      currentPageIndex,
      defaultPageSize,
      columns,
      innerColumns,

      saveSurveyData,
      saveChangeProductData,
      
      surveyModel,
      refPage,
      refTrace,
      productStatisticsModalRef,
      getLinkman,
      handleShowPinModal,
      handleCancelBox,
      handleTrackShowModal,
      handleBack,
      getAddress,
      funcGetFileType,
      deleteFile,
      handelCancelChangeProductModal,
      getFullName,
      handleShowProductStatisticsModal,
    };
  }
})
</script>

<style lang="less" scoped>
.file-imgs {
  width: 50px;
  height: 50px;
  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :deep(.ant-image img) {
    max-height: 40px;
  }
}

.delete-label {
  position: absolute;
  top: 90%;
  left: 35%;
  font-size: 15px;
  color: #d9d9d9;
  :hover {
    color: #04befe;
    //font-size: 20px;
  }
}
</style>
import client from "../../client"

const Api = {
    //获取特殊分区分页列表
    getPagedList: "/api/LogisticsService/SpecialRegion/GetPagedList",

    //创建特殊分区信息
    create: "/api/LogisticsService/SpecialRegion/Create",

    //更新特殊分区信息
    update: "/api/LogisticsService/SpecialRegion/Update",

    //根据Id获取详细
    getById: "/api/LogisticsService/SpecialRegion/GetById",

    //删除特殊分区
    deleteById: "/api/LogisticsService/SpecialRegion/Delete",

    //获取所有的物流分区选项
    getAllList: "/api/LogisticsService/SpecialRegion/GetAllList",
}

//获取特殊分区分页列表
export const getPagedList = ({ logisticsId, keyword, sorting, skipCount, maxResultCount }) => {
    return client.request({
        url: Api.getPagedList,
        data: { logisticsId, keyword, sorting, skipCount, maxResultCount },
        method: 'POST'
    });
}


//创建特殊分区信息
export const create = ({
    id,
    name,
    regionTags,
    regionTypes,
    logisticsIds,
    specialRegionCountryRls,
    specialRegionProvinceRls,
    specialRegionCityRls,
    specialRegionCountyRls,
    specialRegionPostCodeRls
}) => {
    return client.request({
        url: Api.create,
        data: { id, name, regionTags, regionTypes, logisticsIds, specialRegionCountryRls, specialRegionProvinceRls, specialRegionCityRls, specialRegionCountyRls, specialRegionPostCodeRls },
        method: 'POST'
    });
}

//更新特殊分区信息
export const update = ({
    id,
    name,
    regionTags,
    regionTypes,
    logisticsIds,
    specialRegionCountryRls,
    specialRegionProvinceRls,
    specialRegionCityRls,
    specialRegionCountyRls,
    specialRegionPostCodeRls
}) => {
    return client.request({
        url: Api.update,
        data: { id, name, regionTags, regionTypes, logisticsIds, specialRegionCountryRls, specialRegionProvinceRls, specialRegionCityRls, specialRegionCountyRls, specialRegionPostCodeRls },
        method: 'POST'
    });
}

//根据Id获取详细
export const getById = (id) => {
    return client.request({
        url: Api.getById,
        params: { regionId: id },
        method: 'GET'
    });
}

//删除分区
export const deleteById = (id) =>{
    return client.request({
        url: Api.deleteById,
        data: { ids:[id]},
        method: 'POST'
    });
}

//获取全部的列表-简单数据，用于下拉选择
export const getAllList = () => {
    return client.request({
        url: Api.getAllList,
        method: 'GET'
    });
}
import client from "../../client"

const Api = {
  // 列表
  getConfigurationPagedList: "/api/SupplierService/OpenApiPrintConfigure/GetConfigurationPagedList",
  // 新增打单API
  createOpenApiInterfaceConfiguration: "/api/SupplierService/OpenApiPrintConfigure/CreateOpenApiInterfaceConfiguration",
  // 修改打单API
  updateOpenApiInterfaceConfiguration: "/api/SupplierService/OpenApiPrintConfigure/UpdateOpenApiInterfaceConfiguration",
  // 删除打单API
  deleteOpenApiInterfaceConfiguration: "/api/SupplierService/OpenApiAddressSupplierConfigure/DeleteOpenApiInterfaceConfiguration",
  // 详情
  getConfigurationById: "/api/SupplierService/OpenApiPrintConfigure/GetConfigurationById",
  // 启用/禁用打单API
  updateOpenApiInterfaceConfigurationActive: "/api/SupplierService/OpenApiPrintConfigure/UpdateOpenApiInterfaceConfigurationActive",
  // 查询所有供应商方案
  getAllCanChooseSupplier: "/api/SupplierService/OpenApiPrintConfigure/GetAllCanChooseSupplier",
  // 查询可选择的API账户
  getAllCanChooseSetting: "/api/SupplierService/OpenApiManagement/GetAllCanChooseSetting",
  // 获取所有的渠道代码
  getReceivingChannels: "/api/SupplierService/OpenApiPrintConfigure/GetReceivingChannels",
}

export const getConfigurationPagedList = (data) => {
  return client.request({
    url: Api.getConfigurationPagedList,
    data,
    method: 'POST'
  });
}

export const createOpenApiInterfaceConfiguration = (data) => {
  return client.request({
    url: Api.createOpenApiInterfaceConfiguration,
    data,
    method: 'POST'
  });
}

export const updateOpenApiInterfaceConfiguration = (data) => {
  return client.request({
    url: Api.updateOpenApiInterfaceConfiguration,
    data,
    method: 'POST'
  });
}

export const deleteOpenApiInterfaceConfiguration = (data) => {
  return client.request({
    url: Api.deleteOpenApiInterfaceConfiguration,
    data,
    method: 'POST'
  });
}

export const getConfigurationById = (data) => {
  return client.request({
    url: Api.getConfigurationById,
    data,
    method: 'POST'
  });
}

export const updateOpenApiInterfaceConfigurationActive = (data) => {
  return client.request({
    url: Api.updateOpenApiInterfaceConfigurationActive,
    data,
    method: 'POST'
  });
}

export const getAllCanChooseSupplier = (data) => {
  return client.request({
    url: Api.getAllCanChooseSupplier,
    data,
    method: 'GET'
  });
}

export const getAllCanChooseSetting = () => {
  return client.request({
    url: Api.getAllCanChooseSetting,
    method: 'GET'
  });
}

export const getReceivingChannels = (data) => {
  return client.request({
    url: Api.getReceivingChannels,
    params: data,
    method: 'GET'
  });
}
<template>
  <a-modal
    v-model:visible="visible"
    :centered="true"
    :title="$t('warehouse.compensation')"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('warehouse.amount_of_compensation')" name="money">
        <a-input
          ref="inputRef"
          style="min-width: 210px"
          flex="1"
          v-model:value="formState.money"
          :min="0"
          :prefix="prefix"
          :placeholder="$t('warehouse.amount_of_compensation')"
          :parser="value => value.replace(/\$\s?|(,*)/g, '')"
          @focus="(event) => {event.currentTarget.select()}"
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, toRefs, ref } from 'vue'
import { Form, Input, Modal } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { refInputElemFocus } from '@/utils/domoperate';

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" })

    const formRef = ref(null)
    const inputRef = ref(null)

    const state = reactive({
      visible: false,
      formState: {
        money: null
      },
      prefix: "",
      labelCol: { span: 8 },
      wrapperCol: { span: 14 }
    })

    const rules = {
      money: [{
        required: true,
        pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{0,2}$)/,
        message: vueI18n.t("warehouse.please_enter_the_correct_amount"),
      }]
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate()
        close()
        nextTick(() => {
          emit('confirm', Number.parseFloat(state.formState.money))
        })
      } catch (error) {
      }
    }

    const elemFocusInput = (elem) => {
      nextTick(() => {
        elem.value.blur();
        refInputElemFocus(elem.value.input);
      });
    }

    const open = (prefix = '') => {
      state.prefix = prefix
      state.formState.money = null
      state.visible = true
      nextTick(() => {
        formRef.value.resetFields()
        elemFocusInput(inputRef);
      })
    }

    const close = () => {
      state.visible = false
    }

    return {
      ...toRefs(state),
      formRef,
      inputRef,
      rules,
      open,
      close,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>
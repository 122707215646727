import client from "../../client"

const Api = {
  // 获取限运配置列表
  getPagedList: "/api/SupplierService/SupplierRestrictedConfiguration/GetPagedList",
  // 依据地址类型获取地址名称/编号
  getAllCanChooseLimitedConfigurationCode: "/api/SupplierService/SupplierRestrictedConfiguration/GetAllCanChooseRestrictedConfigurationCode",
  // 新增限运配置
  createLogisticLimitedConfiguration: "/api/SupplierService/SupplierRestrictedConfiguration/Create",
  // 修改限运配置/编号
  updateLogisticLimitedConfiguration: "/api/SupplierService/SupplierRestrictedConfiguration/Update",
  // 删除限运配置/编号
  deleteLogisticLimitedConfiguration: "/api/SupplierService/SupplierRestrictedConfiguration/Delete",
}

export const getPagedList = (data) => {
  return client.request({
    url: Api.getPagedList,
    data,
    method: "POST",
  });
};

export const getAllCanChooseLimitedConfigurationCode = (params) => {
  return client.request({
    url: Api.getAllCanChooseLimitedConfigurationCode,
    params,
    method: "GET",
  });
};

export const createLogisticLimitedConfiguration = (data) => {
  return client.request({
    url: Api.createLogisticLimitedConfiguration,
    data,
    method: "POST",
  });
};

export const updateLogisticLimitedConfiguration = (data) => {
  return client.request({
    url: Api.updateLogisticLimitedConfiguration,
    data,
    method: "POST",
  });
};

export const deleteLogisticLimitedConfiguration = (data) => {
  return client.request({
    url: Api.deleteLogisticLimitedConfiguration,
    data,
    method: "POST",
  });
};
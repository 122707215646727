import client from "../../client"

const Api = {
    // 获取供应商分页列表
    getListPaged: "/api/SupplierService/Supplier/GetListPaged",

    // 创建供应商方案
    create: "/api/SupplierService/Supplier/Create",

    // 修改供应商
    update: "/api/SupplierService/Supplier/Update",

    // 更新供应商状态
    updateStatus: "/api/SupplierService/Supplier/UpdateStatus",

    // 获取供应商信息
    getById: "/api/SupplierService/Supplier/GetById",

    // 获取所有供应商方案选项
    getAllList: "/api/SupplierService/Supplier/GetAllList",

    // 复制渠道
    copy: "/api/SupplierService/Supplier/Copy",
}

export const getListPaged = (data) => {
    return client.request({
        url: Api.getListPaged,
        data,
        method: 'POST'
    });
}

export const create = (data) => {
    return client.request({
        url: Api.create,
        data,
        method: 'POST'
    });
}

export const update = (data) => {
    return client.request({
        url: Api.update,
        data,
        method: 'POST'
    });
}

export const updateStatus = (data) => {
    return client.request({
        url: Api.updateStatus,
        data,
        method: 'POST'
    });
}

export const getById = (id) => {
    return client.request({
        url: Api.getById,
        params: { id },
        method: 'GET'
    });
}

export const getAllList = () => {
    return client.request({
        url: Api.getAllList,
        method: 'GET'
    });
}

export const copy = ({ id }) => {
    return client.request({
        url: Api.copy,
        data: { id },
        method: 'POST'
    });
}
export const getDynamicRoutes = () => {
  return [
    {
      id: 1,
      parent: 0,
      path: "/",
      name: "dashboard",
      type: "group",
      icon: "DashboardOutlined",
      title: "Dashboard",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.dashboard",
    },
    {
      id: 101,
      parent: 1,
      path: "/dashboard",
      name: "dashboard_index",
      type: "router",
      icon: "CoffeeOutlined",
      title: "Dashboard",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.dashboard",
    },
    {
      id: 102,
      parent: 1,
      path: "/dashboard/monitor",
      name: "dashboard_monitor",
      type: "router",
      icon: "DashboardOutlined",
      title: "Monitor",
      sort: 1,
      link: "",
      hidden: false,
      text: "menu.dashboard_monitor",
    },
    {
      id: 8,
      parent: 0,
      path: "/account",
      name: "account",
      type: "group",
      icon: "UserOutlined",
      title: "Account",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.account",
    },
    {
      id: 800,
      parent: 8,
      path: "/account/info",
      name: "account_info",
      type: "router",
      icon: "",
      title: "",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.account_info",
    },
    {
      id: 20,
      parent: 0,
      path: "/warehousemonitor",
      name: "warehouse_monitor",
      type: "group",
      icon: "BankOutlined",
      title: "Warehouse Monitor",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor",
    },
    {
      id: 2010,
      parent: 20,
      path: "/warehousemonitor/storage/warehousingtask",
      name: "warehouse_monitor_storage_warehousing_task",
      type: "router",
      icon: "ClusterOutlined",
      title: "Warehouse Monitor Warehousing Task",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_storage_warehousing_task",
    },
    {
      id: 2011,
      parent: 30,
      path: "/warehousemonitor/storage/warehousingtask/:id",
      name: "warehouse_monitor_storage_warehousing_task_detail",
      type: "router",
      icon: "ClusterOutlined",
      title: "Warehouse Monitor Warehousing Task Detail",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_storage_warehousing_task_detail",
    },
    {
      id: 2012,
      parent: 20,
      path: "/warehousemonitor/storage/outboundtask",
      name: "warehouse_monitor_storage_outbound_task",
      type: "router",
      icon: "ClusterOutlined",
      title: "Warehouse Monitor Outbound Task",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_storage_outbound_task",
    },
    {
      id: 2013,
      parent: 20,
      path: "/warehousemonitor/storage/outboundtask/:id",
      name: "warehouse_monitor_storage_outbound_task_detail",
      type: "router",
      icon: "ClusterOutlined",
      title: "Warehouse Monitor Outbound Task Detail",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_storage_outbound_task_detail",
    },
    {
      id: 2014,
      parent: 20,
      path: "/warehousemonitor/storage/labelchange",
      name: "warehouse_monitor_storage_label_change",
      type: "router",
      icon: "InteractionOutlined",
      title: "Warehouse Monitor Storage Label Change",
      sort: 15,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_storage_label_change",
    },
    {
      id: 2015,
      parent: 20,
      path: "/warehousemonitor/storage/labelchange/:id",
      name: "warehouse_monitor_storage_label_change_detail",
      type: "router",
      icon: "InteractionOutlined",
      title: "Warehouse Monitor Storage Label Change Detail",
      sort: 15,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_storage_label_change_detail",
    },
    {
      id: 2020,
      parent: 20,
      path: "/warehousemonitor/consignment/warehousingtask",
      name: "warehouse_monitor_consignment_warehousing_task",
      type: "router",
      icon: "BorderOuterOutlined",
      title: "Warehouse Monitor consignment Task",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_consignment_warehousing_task",
    },
    {
      id: 2021,
      parent: 20,
      path: "/warehousemonitor/consignment/warehousingtask/:id",
      name: "warehouse_monitor_consignment_warehousing_task_detail",
      type: "router",
      icon: "BorderOuterOutlined",
      title: "Warehouse Monitor consignment Task Detail",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_consignment_warehousing_task_detail",
    },
    {
      id: 2022,
      parent: 20,
      path: "/warehousemonitor/consignment/ordertask",
      name: "warehouse_monitor_consignment_order_task",
      type: "router",
      icon: "DeploymentUnitOutlined",
      title: "Warehouse Monitor Order Task",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_consignment_order_task",
    },
    {
      id: 2023,
      parent: 20,
      path: "/warehousemonitor/consignment/ordertask/:id",
      name: "warehouse_monitor_consignment_order_task_detail",
      type: "router",
      icon: "ClusterOutlined",
      title: "Warehouse Monitor Order Task Detail",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_consignment_order_task_detail",
    },
    {
      id: 2024,
      parent: 20,
      path: "/warehousemonitor/allotplan",
      name: "warehouse_monitor_allot_plan",
      type: "router",
      icon: "FullscreenOutlined",
      title: "Print the waybill",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_allot_plan",
    },
    {
      id: 2025,
      parent: 20,
      path: "/warehousemonitor/allotplan/:id",
      name: "warehouse_monitor_allot_plan_detail",
      type: "router",
      icon: "FileTextOutlined",
      title: "Print the waybill details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_allot_plan_detail",
    },
    {
      id: 2030,
      parent: 20,
      path: "/warehousemonitor/consignment/returntemporarystorage",
      name: "warehouse_monitor_consignment_return_temporary_storage",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "return temporary storage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_consignment_return_temporary_storage",
    },
    {
      id: 2032,
      parent: 20,
      path: "/warehousemonitor/consignment/returntemporarystorageshop",
      name: "warehouse_monitor_consignment_return_temporary_storage_shop",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "return temporary storage Shop",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_consignment_return_temporary_storage_shop",
    },
    {
      id: 2035,
      parent: 20,
      path: "/warehousemonitor/transit/inbound",
      name: "warehouse_monitor_transit_inbound",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "inbound of transit warehouse",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_transit_inbound",
    },
    {
      id: 2036,
      parent: 20,
      path: "/warehousemonitor/transit/inbound/:id",
      name: "warehouse_monitor_transit_inbound_detail",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "inbound detail of transit warehouse",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_transit_inbound_detail",
    },
    {
      id: 2037,
      parent: 20,
      path: "/warehousemonitor/transit/outbound",
      name: "warehouse_monitor_transit_outbound",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "outbound of transit warehouse",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_transit_outbound",
    },
    {
      id: 2038,
      parent: 20,
      path: "/warehousemonitor/transit/outbound/:id",
      name: "warehouse_monitor_transit_outbound_detail",
      type: "router",
      icon: "DeliveredProcedureOutlined",
      title: "outbound detail of transit warehouse",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_transit_outbound_detail",
    },
    {
      id: 2039,
      parent: 20,
      path: "/warehousemonitor/transit/labelchange",
      name: "warehouse_monitor_transit_label_change",
      type: "router",
      icon: "InteractionOutlined",
      title: "Warehouse Monitor Transit Label Change",
      sort: 39,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_transit_label_change",
    },
    {
      id: 2040,
      parent: 20,
      path: "/warehousemonitor/transit/labelchange/:id",
      name: "warehouse_monitor_transit_label_change_detail",
      type: "router",
      icon: "InteractionOutlined",
      title: "Warehouse Monitor Transit Label Change Detail",
      sort: 40,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_transit_label_change_detail",
    },
    {
      id: 2045,
      parent: 20,
      path: "/warehousemonitor/unload",
      name: "warehouse_monitor_unload",
      type: "router",
      icon: "NodeIndexOutlined",
      title: "Warehouse Monitor Task Unload",
      sort: 45,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_unload",
    },
    {
      id: 2050,
      parent: 20,
      path: "/warehousemonitor/return",
      name: "warehouse_monitor_return",
      type: "router",
      icon: "FileTextOutlined",
      title: "Return warehousing task list",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_return",
    },
    {
      id: 2051,
      parent: 20,
      path: "/warehousemonitor/return/:id",
      name: "warehouse_monitor_return_detail",
      type: "router",
      icon: "FileTextOutlined",
      title: "Return warehousing task details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_return_detail",
    },
    {
      id: 2060,
      parent: 20,
      path: "/warehousemonitor/printwaybill",
      name: "warehouse_monitor_print_waybill",
      type: "router",
      icon: "InteractionOutlined",
      title: "Print the waybill",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_monitor_print_waybill",
    },
    {
      id: 2061,
      parent: 20,
      path: "/warehousemonitor/printwaybill/:id",
      name: "warehouse_monitor_print_waybill_detail",
      type: "router",
      icon: "FileTextOutlined",
      title: "Print the waybill details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_monitor_print_waybill_detail",
    },
    {
      id: 30,
      parent: 0,
      path: "/warehouse",
      name: "warehouse",
      type: "group",
      icon: "BankOutlined",
      title: "Warehouse",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_manager",
    },
    {
      id: 3001,
      parent: 30,
      path: "/warehouse/manager",
      name: "warehouse_device_manager",
      type: "router",
      icon: "ClusterOutlined",
      title: "Warehouse manager",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.warehouse_device_manager",
    },
    {
      id: 3002,
      parent: 30,
      path: "/warehouse/feesetting/:id",
      name: "warehouse_fee_setting",
      type: "router",
      icon: "SettingOutlined",
      title: "Warehouse fee setting",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.warehouse_fee_setting",
    },
    {
      id: 35,
      parent: 0,
      path: "/products",
      name: "products",
      type: "group",
      icon: "SkinOutlined",
      title: "Products",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.products",
    },
    // {
    //   id: 3501,
    //   parent: 35,
    //   path: "/products/manager",
    //   name: "products_manager",
    //   type: "router",
    //   icon: "ProfileOutlined",
    //   title: "Products manager",
    //   sort: 0,
    //   link: "",
    //   hidden: false,
    //   text: "menu.products_manager",
    // },
    {
      id: 3505,
      parent: 35,
      path: "/products/verifydata",
      name: "products_verify_data",
      type: "router",
      icon: "AuditOutlined",
      title: "Products verify data",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.products_verify_data",
    },
    {
      id: 3515,
      parent: 35,
      path: "/products/storageinventory",
      name: "products_storage_inventory",
      type: "router",
      icon: "CloudUploadOutlined",
      title: "Products storage inventory",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.products_storage_inventory",
    },
    {
      id: 3520,
      parent: 35,
      path: "/products/consignmentinventory",
      name: "products_consignment_inventory",
      type: "router",
      icon: "CloudDownloadOutlined",
      title: "Products consignment inventory",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.products_consignment_inventory",
    },
    {
      id: 3525,
      parent: 35,
      path: "/products/transitinventory",
      name: "products_transit_inventory",
      type: "router",
      icon: "CloudSyncOutlined",
      title: "Products transit inventory",
      sort: 25,
      link: "",
      hidden: false,
      text: "menu.products_transit_inventory",
    },
    {
      id: 3526,
      parent: 35,
      path: "/products/transitshipment",
      name: "products_transit_shipment",
      type: "router",
      icon: "FolderOpenOutlined",
      title: "Products transit shipment",
      sort: 26,
      link: "",
      hidden: false,
      text: "menu.products_transit_shipment",
    },
    {
      id: 3530,
      parent: 35,
      path: "/products/list",
      name: "products_list",
      type: "router",
      icon: "BarsOutlined",
      title: "Products List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.product_management",
    },
    {
      id: 3531,
      parent: 35,
      path: "/products/edit/:id",
      name: "products_edit",
      type: "router",
      icon: "DashboardOutlined",
      title: "Products Edit",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.products_edit",
    },
    {
      id: 3535,
      parent: 35,
      path: "/products/historical/:id",
      name: "products_historical_version",
      type: "router",
      icon: "DashboardOutlined",
      title: "Products Historical Version",
      sort: 35,
      link: "",
      hidden: true,
      text: "menu.products_historical_version",
    },
    {
      id: 3536,
      parent: 35,
      path: "/products/checkhistory/:id",
      name: "products_check_history",
      type: "router",
      icon: "DashboardOutlined",
      title: "Products Check History",
      sort: 35,
      link: "",
      hidden: true,
      text: "menu.products_check_history",
    },
    {
      id: 3540,
      parent: 35,
      path: "/products/inventoryadjustment",
      name: "products_inventory_adjustment",
      type: "router",
      icon: "OneToOneOutlined",
      title: "Inventory Adjustment",
      sort: 40,
      link: "",
      hidden: false,
      text: "menu.products_inventory_adjustment",
    },
    {
      id: 3600,
      parent: 35,
      path: "/products/sizemodificationnotice",
      name: "product_size_modification_notice",
      type: "router",
      icon: "NotificationOutlined",
      title: "Products size modification notification",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.product_size_modification_notice",
    },
    {
      id: 40,
      parent: 0,
      path: "/logistics",
      name: "logistics",
      type: "group",
      icon: "GatewayOutlined",
      title: "Logistics config",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.logistics_config",
    },
    {
      id: 4001,
      parent: 40,
      path: "/logistics/channel",
      name: "logistics_channel",
      type: "router",
      icon: "ForkOutlined",
      title: "Logistics Channel",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.logistics_channel",
    },
    {
      id: 4002,
      parent: 40,
      path: "/logistics/region/:logisticsId",
      name: "logistics_region",
      type: "router",
      icon: "ClusterOutlined",
      title: "Logistics Region",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.logistics_region",
    },
    {
      id: 4003,
      parent: 40,
      path: "/logistics/region/:logisticsId/edit/:id?",
      name: "logistics_region_edit",
      type: "router",
      icon: "ClusterOutlined",
      title: "Logistics Region Edit",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.logistics_region_edit",
    },
    {
      id: 4004,
      parent: 40,
      path: "/logistics/feetemplate/:logisticsId",
      name: "logistics_fee_template",
      type: "router",
      icon: "ClusterOutlined",
      title: "Logistics Fee Template",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.logistics_fee_template",
    },
    {
      id: 4005,
      parent: 40,
      path: "/logistics/feetemplate/config/:logisticsId/edit/:id?",
      name: "logistics_fee_config",
      type: "router",
      icon: "ClusterOutlined",
      title: "Logistics Fee Config",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.logistics_fee_config",
    },
    {
      id: 4006,
      parent: 40,
      path: "/logistics/surcharge/:logisticsId",
      name: "logistics_surcharge",
      type: "router",
      icon: "ClusterOutlined",
      title: "Logistics Surcharge",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.logistics_surcharge",
    },
    {
      id: 4007,
      parent: 40,
      path: "/logistics/surcharge/:logisticsId/edit/:id?",
      name: "logistics_surcharge_edit",
      type: "router",
      icon: "ClusterOutlined",
      title: "Logistics Surcharge Edit",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.logistics_surcharge_edit",
    },
    {
      id: 4008,
      parent: 40,
      path: "/logistics/special",
      name: "special_region",
      type: "router",
      icon: "ClusterOutlined",
      title: "Special Region",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.special_region",
    },
    {
      id: 4009,
      parent: 40,
      path: "/logistics/special/edit/:id?",
      name: "special_region_edit",
      type: "router",
      icon: "ClusterOutlined",
      title: "Edit Special Region",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.special_region_edit",
    },
    {
      id: 4010,
      parent: 40,
      path: "/logistics/account",
      name: "logistics_account",
      type: "router",
      icon: "AppstoreAddOutlined",
      title: "Logistics account",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.logistics_account",
    },
    {
      id: 4012,
      parent: 40,
      path: "/logistics/embargo/:id",
      name: "logistics_embargo_configuration",
      type: "router",
      icon: "FileExcelOutlined",
      title: "Logistics Embargo Configuration",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.logistics_embargo_configuration",
    },
    {
      id: 4013,
      parent: 40,
      path: "/logistics/limited/:id",
      name: "logistics_limited_transportation_configuration",
      type: "router",
      icon: "FileExcelOutlined",
      title: "Limited Transportation Configuration",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.logistics_limited_transportation_configuration",
    },
    {
      id: 4014,
      parent: 40,
      path: "/logistics/discount/:id",
      name: "logistics_discount",
      type: "router",
      icon: "MoneyCollectOutlined",
      title: "Logistics Discount",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.logistics_channel_discount",
    },
    {
      id: 4015,
      parent: 40,
      path: "/logistics/addressapi",
      name: "logistics_address_api",
      type: "router",
      icon: "GlobalOutlined",
      title: "Logistics Address Api",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.logistics_address_api",
    },
    {
      id: 4016,
      parent: 40,
      path: "/logistics/validation",
      name: "logistics_validation",
      type: "router",
      icon: "BulbOutlined",
      title: "Logistics Validation",
      sort: 16,
      link: "",
      hidden: false,
      text: "menu.logistics_validation",
    },
    {
      id: 4017,
      parent: 40,
      path: "/logistics/rebateconfiguration",
      name: "logistics_rebate_configuration",
      type: "router",
      icon: "AccountBookOutlined",
      title: "Logistics Rebate Configuration",
      sort: 17,
      link: "",
      hidden: false,
      text: "menu.logistics_rebate_configuration",
    },
    {
      id: 4018,
      parent: 40,
      path: "/logistics/channeldiscount",
      name: "logistics_channel_discount",
      type: "router",
      icon: "MoneyCollectOutlined",
      title: "Logistics Channel Discount",
      sort: 18,
      link: "",
      hidden: false,
      text: "menu.logistics_channel_discount",
    },
    {
      id: 45,
      parent: 0,
      path: "/supplier",
      name: "supplier",
      type: "group",
      icon: "GoldOutlined",
      title: "Supplier config",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_config",
    },
    {
      id: 4501,
      parent: 45,
      path: "/supplier/channel",
      name: "supplier_channel",
      type: "router",
      icon: "ClusterOutlined",
      title: "Supplier Channel",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_channel",
    },
    {
      id: 4502,
      parent: 45,
      path: "/supplier/region/:supplierId",
      name: "supplier_region",
      type: "router",
      icon: "PartitionOutlined",
      title: "Supplier Region",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.supplier_region",
    },
    {
      id: 4503,
      parent: 45,
      path: "/supplier/region/:supplierId/edit/:id?",
      name: "supplier_region_edit",
      type: "router",
      icon: "ClusterOutlined",
      title: "Supplier Region Edit",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.supplier_region_edit",
    },
    {
      id: 4504,
      parent: 45,
      path: "/supplier/feetemplate/:supplierId",
      name: "supplier_fee_template",
      type: "router",
      icon: "ClusterOutlined",
      title: "Supplier Fee Template",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.supplier_fee_template",
    },
    {
      id: 4505,
      parent: 45,
      path: "/supplier/feetemplate/config/:supplierId/edit/:id?",
      name: "supplier_fee_config",
      type: "router",
      icon: "ClusterOutlined",
      title: "Supplier Fee Config",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.supplier_fee_config",
    },
    {
      id: 4506,
      parent: 45,
      path: "/supplier/surcharge/:supplierId",
      name: "supplier_surcharge",
      type: "router",
      icon: "ClusterOutlined",
      title: "Supplier Surcharge",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.supplier_surcharge",
    },
    {
      id: 4507,
      parent: 45,
      path: "/supplier/surcharge/:supplierId/edit/:id?",
      name: "supplier_surcharge_edit",
      type: "router",
      icon: "ClusterOutlined",
      title: "Supplier Surcharge Edit",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.supplier_surcharge_edit",
    },
    {
      id: 4508,
      parent: 45,
      path: "/supplier/special",
      name: "supplier_special_region",
      type: "router",
      icon: "ClusterOutlined",
      title: "Special Region",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_special_region",
    },
    {
      id: 4509,
      parent: 45,
      path: "/supplier/special/edit/:id?",
      name: "supplier_special_region_edit",
      type: "router",
      icon: "ClusterOutlined",
      title: "Edit Special Region",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.supplier_special_region_edit",
    },
    {
      id: 4512,
      parent: 45,
      path: "/supplier/embargo/:id",
      name: "supplier_embargo_configuration",
      type: "router",
      icon: "FileExcelOutlined",
      title: "Supplier Embargo Configuration",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_embargo_configuration",
    },
    {
      id: 4513,
      parent: 45,
      path: "/supplier/limited/:id",
      name: "supplier_limited_transportation_configuration",
      type: "router",
      icon: "FileExcelOutlined",
      title: "Limited Transportation Configuration",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_limited_transportation_configuration",
    },
    {
      id: 4514,
      parent: 45,
      path: "/supplier/discount/:id",
      name: "supplier_discount",
      type: "router",
      icon: "MoneyCollectOutlined",
      title: "Supplier Discount",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_channel_discount",
    },
    {
      id: 4515,
      parent: 45,
      path: "/supplier/printwaybillapi",
      name: "supplier_print_waybill_api",
      type: "router",
      icon: "LaptopOutlined",
      title: "Supplier Print Waybill Api",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_print_waybill_api",
    },
    {
      id: 4516,
      parent: 45,
      path: "/supplier/addressapi",
      name: "supplier_address_api",
      type: "router",
      icon: "GlobalOutlined",
      title: "Supplier Address Api",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.supplier_address_api",
    },
    {
      id: 4517,
      parent: 45,
      path: "/supplier/channeldiscount",
      name: "supplier_channel_discount",
      type: "router",
      icon: "MoneyCollectOutlined",
      title: "Supplier Channel Discount",
      sort: 17,
      link: "",
      hidden: false,
      text: "menu.supplier_channel_discount",
    },
    {
      id: 50,
      parent: 0,
      path: "/customer",
      name: "customer",
      type: "group",
      icon: "AppstoreOutlined",
      title: "customer manage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.customer",
    },
    {
      id: 5001,
      parent: 50,
      path: "/customer/account/list",
      name: "customer_account_list",
      type: "router",
      icon: "MonitorOutlined",
      title: "Customer Account List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.customer_account_list",
    },
    {
      id: 5002,
      parent: 50,
      path: "/customer/account/details/:id",
      name: "customer_account_details",
      type: "router",
      icon: "MonitorOutlined",
      title: "Customer Account Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.customer_account_details",
    },
    {
      id: 5003,
      parent: 50,
      path: "/customer/company/list",
      name: "customer_company_list",
      type: "router",
      icon: "BarsOutlined",
      title: "Customer Company List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.customer_company_list",
    },
    {
      id: 5004,
      parent: 50,
      path: "/customer/company/details/:id",
      name: "customer_company_details",
      type: "router",
      icon: "MonitorOutlined",
      title: "Customer Company Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.customer_company_details",
    },
    {
      id: 5005,
      parent: 50,
      path: "/customer/level/list",
      name: "customer_level_list",
      type: "router",
      icon: "BarsOutlined",
      title: "Customer Level List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.customer_level_list",
    },
    {
      id: 5006,
      parent: 50,
      path: "/customer/companydiscount/list/:id",
      name: "customer_company_discount_list",
      type: "router",
      icon: "DollarCircleOutlined",
      title: "Customer Company Discount List",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.customer_company_discount_list",
    },
    {
      id: 5007,
      parent: 50,
      path: "/customer/companydiscount/details/:shopId/:dataId?",
      name: "customer_company_discount_details",
      type: "router",
      icon: "MonitorOutlined",
      title: "Customer Company Discount Details",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.customer_company_discount_details",
    },
    {
      id: 5008,
      parent: 50,
      path: "/customer/company/transactionrecord/:id",
      name: "customer_company_transaction_record",
      type: "router",
      icon: "SnippetsOutlined",
      title: "Customer Company Transaction Record",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.customer_company_transaction_record",
    },
    {
      id: 5009,
      parent: 50,
      path: "/customer/company/accountingperiod/:id",
      name: "customer_company_accounting_period",
      type: "router",
      icon: "SnippetsOutlined",
      title: "Customer Company Accounting Period",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.customer_company_accounting_period",
    },
    {
      id: 60,
      parent: 0,
      path: "/transport",
      name: "transport",
      type: "group",
      icon: "CarOutlined",
      title: "transport plan",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.transport",
    },
    {
      id: 6010,
      parent: 60,
      path: "/transport/list",
      name: "transport_list",
      type: "router",
      icon: "CarOutlined",
      title: "transport plan",
      sort: 10,
      link: "",
      hidden: false,
      text: "menu.transport",
    },
    {
      id: 60121,
      parent: 60,
      path: "/transport/detail/:id",
      name: "transport_detail",
      type: "router",
      icon: "FieldTimeOutlined",
      title: "transport plan detail",
      sort: 12,
      link: "",
      hidden: true,
      text: "menu.transport_detail",
    },
    {
      id: 6015,
      parent: 60,
      path: "/transport/customer/list",
      name: "transport_customer_list",
      type: "router",
      icon: "CarOutlined",
      title: "Customer Transport Plan",
      sort: 15,
      link: "",
      hidden: false,
      text: "menu.transport_customer_list",
    },
    {
      id: 6016,
      parent: 60,
      path: "/transport/customer/detail/:id",
      name: "transport_customer_detail",
      type: "router",
      icon: "FieldTimeOutlined",
      title: "Customer Transport Plan Detail",
      sort: 16,
      link: "",
      hidden: true,
      text: "menu.transport_customer_detail",
    },
    {
      id: 6020,
      parent: 60,
      path: "/transport/shipment/transport",
      name: "transport_shipment_transport",
      type: "router",
      icon: "FieldTimeOutlined",
      title: "Transport Shipment Transport",
      sort: 20,
      link: "",
      hidden: false,
      text: "menu.transport_shipment_transport",
    },
    {
      id: 6025,
      parent: 60,
      path: "/transport/shipment/cabin",
      name: "transport_shipment_cabin",
      type: "router",
      icon: "DatabaseOutlined",
      title: "Transport Shipment Cabin",
      sort: 25,
      link: "",
      hidden: false,
      text: "menu.transport_shipment_cabin",
    },
    {
      id: 6026,
      parent: 60,
      path: "/transport/shipment/cabin/detail/:id",
      name: "transport_shipment_cabin_detail",
      type: "router",
      icon: "DatabaseOutlined",
      title: "Transport Shipment Cabin Detail",
      sort: 26,
      link: "",
      hidden: true,
      text: "menu.transport_shipment_cabin_detail",
    },
    {
      id: 70,
      parent: 0,
      path: "/finance",
      name: "finance",
      type: "group",
      icon: "EuroCircleOutlined",
      title: "finance Manage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.finance",
    },
    {
      id: 7010,
      parent: 70,
      path: "/finance/recharge",
      name: "finance_recharge",
      type: "router",
      icon: "DollarCircleOutlined",
      title: "finance recharge",
      sort: 10,
      link: "",
      hidden: false,
      text: "menu.finance_recharge",
    },
    {
      id: 7020,
      parent: 70,
      path: "/finance/inventory",
      name: "finance_inventory",
      type: "router",
      icon: "UnorderedListOutlined",
      title: "finance inventory",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.finance_inventory",
    },
    {
      id: 7021,
      parent: 70,
      path: "/finance/inventorydetails/:id",
      name: "finance_inventory_details",
      type: "router",
      icon: "TransactionOutlined",
      title: "Fee Inventory Details",
      sort: 21,
      link: "",
      hidden: true,
      text: "menu.finance_details",
    },
    {
      id: 7030,
      parent: 70,
      path: "/finance/inventoryunsettled",
      name: "finance_inventory_unsettled",
      type: "router",
      icon: "ExceptionOutlined",
      title: "finance inventory unsettled",
      sort: 30,
      link: "",
      hidden: false,
      text: "menu.finance_inventory_unsettled",
    },
    {
      id: 7040,
      parent: 70,
      path: "/finance/customerbalance",
      name: "finance_customer_balance",
      type: "router",
      icon: "CreditCardOutlined",
      title: "finance customer balance",
      sort: 40,
      link: "",
      hidden: false,
      text: "menu.finance_customer_balance",
    },
    {
      id: 7050,
      parent: 70,
      path: "/finance/accounting/list",
      name: "finance_accounting_list",
      type: "router",
      icon: "BarsOutlined",
      title: "finance accounting list",
      sort: 50,
      link: "",
      hidden: false,
      text: "menu.finance_accounting_list",
    },
    {
      id: 7051,
      parent: 70,
      path: "/finance/accounting/cost/:id",
      name: "finance_accounting_cost",
      type: "router",
      icon: "DollarCircleOutlined",
      title: "finance accounting cost",
      sort: 51,
      link: "",
      hidden: true,
      text: "menu.finance_accounting_cost",
    },
    {
      id: 7052,
      parent: 70,
      path: "/finance/accounting/details/:id",
      name: "finance_accounting_details",
      type: "router",
      icon: "DollarCircleOutlined",
      title: "finance accounting details",
      sort: 52,
      link: "",
      hidden: true,
      text: "menu.finance_accounting_details",
    },
    {
      id: 7060,
      parent: 70,
      path: "/finance/orderwaybill",
      name: "finance_order_waybill",
      type: "router",
      icon: "InteractionOutlined",
      title: "Finance order waybill",
      sort: 60,
      link: "",
      hidden: false,
      text: "menu.finance_order_waybill",
    },
    {
      id: 80,
      parent: 0,
      path: "/right",
      name: "right",
      type: "group",
      icon: "LockOutlined",
      title: "Right Manage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.right_module",
    },
    {
      id: 8001,
      parent: 80,
      path: "/right/rolemanage",
      name: "role_manager",
      type: "router",
      icon: "TeamOutlined",
      title: "role manage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.role_manager",
    },
    {
      id: 8002,
      parent: 80,
      path: "/right/rightmanage",
      name: "right_manager",
      type: "router",
      icon: "TableOutlined",
      title: "right manage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.right_manager",
    },
    {
      id: 90,
      parent: 0,
      path: "/stafforganizational",
      name: "staff_organizational",
      type: "router",
      icon: "BranchesOutlined",
      title: "Staff and organizational",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.staff_organizational",
    },
    {
      id: 91,
      parent: 0,
      path: "/devops",
      name: "devops",
      type: "router",
      icon: "BranchesOutlined",
      title: "DevOps",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.dev_ops",
    },
    {
      id: 9101,
      parent: 91,
      path: "/devops/alilog",
      name: "dev_ops_ali_log",
      type: "router",
      icon: "BranchesOutlined",
      title: "AliLog",
      sort: 10,
      link: "",
      hidden: false,
      text: "menu.dev_ops_ali_log",
    },
    {
      id: 9102,
      parent: 91,
      path: "/devops/scanexception",
      name: "dev_scan_exception",
      type: "router",
      icon: "SecurityScanOutlined",
      title: "data of scan exception",
      sort: 20,
      link: "",
      hidden: false,
      text: "menu.dev_scan_exception",
    },
    {
      id: 9103,
      parent: 91,
      path: "/devops/boxeditlist",
      name: "dev_box_edit_list",
      type: "router",
      icon: "InboxOutlined",
      title: "box data edit list",
      sort: 30,
      link: "",
      hidden: false,
      text: "menu.dev_box_edit_list",
    },
    {
      id: 9104,
      parent: 91,
      path: "/devops/apimanage",
      name: "dev_api_manage",
      type: "router",
      icon: "CodeOutlined",
      title: "api manage",
      sort: 40,
      link: "",
      hidden: false,
      text: "menu.dev_api_manage",
    },
    {
      id: 9105,
      parent: 91,
      path: "/devops/currencyconfiguration",
      name: "dev_currency_configuration_list",
      type: "router",
      icon: "PayCircleOutlined",
      title: "currency configuration list",
      sort: 50,
      link: "",
      hidden: false,
      text: "menu.dev_currency_configuration_list",
    },
    {
      id: 9106,
      parent: 91,
      path: "/devops/currencyconfiguration/edit/:id?",
      name: "dev_currency_configuration_edit",
      type: "router",
      icon: "",
      title: "currency configuration edit",
      sort: 60,
      link: "",
      hidden: true,
      text: "devops.edit_configuration",
    },
    {
      id: 9107,
      parent: 91,
      path: "/devops/abbrcodeconvert",
      name: "dev_abbr_code_convert",
      type: "router",
      icon: "",
      title: "Abbr Code Convert",
      sort: 70,
      link: "",
      hidden: false,
      text: "menu.dev_abbr_code_convert",
    },
    {
      id: 9108,
      parent: 91,
      path: "/devops/exportlog",
      name: "dev_export_log",
      type: "router",
      icon: "",
      title: "Export Log",
      sort: 80,
      link: "",
      hidden: false,
      text: "menu.dev_export_log",
    },
    {
      id: 9109,
      parent: 91,
      path: "/devops/portmanagement",
      name: "dev_port_management",
      type: "router",
      icon: "",
      title: "Export Log",
      sort: 90,
      link: "",
      hidden: false,
      text: "menu.dev_port_management",
    },
    {
      id: 9110,
      parent: 91,
      path: "/devops/airlinemanagement",
      name: "dev_airline_management",
      type: "router",
      icon: "",
      title: "Export Log",
      sort: 100,
      link: "",
      hidden: false,
      text: "menu.dev_airline_management",
    },
    {
      id: 9111,
      parent: 91,
      path: "/devops/announcement",
      name: "dev_announcement",
      type: "router",
      icon: "BellOutlined",
      title: "Announcement",
      sort: 110,
      link: "",
      hidden: false,
      text: "menu.dev_announcement",
    },
    {
      id: 9112,
      parent: 91,
      path: "/devops/logisticspaymentcountdown",
      name: "dev_logistics_payment_countdown",
      type: "router",
      icon: "CoffeeOutlined",
      title: "Logistics Payment Countdown",
      sort: 120,
      link: "",
      hidden: false,
      text: "menu.dev_logistics_payment_countdown",
    },
    {
      id: 9113,
      parent: 91,
      path: "/devops/cachemanagement",
      name: "dev_cache_management",
      type: "router",
      icon: "CloudServerOutlined",
      title: "Cache Management",
      sort: 130,
      link: "",
      hidden: false,
      text: "menu.dev_cache_management",
    },
    {
      id: 9114,
      parent: 91,
      path: "/devops/exchangerate",
      name: "dev_exchange_rate",
      type: "router",
      icon: "TransactionOutlined",
      title: "Exchange Rate List",
      sort: 140,
      link: "",
      hidden: false,
      text: "menu.dev_exchange_rate",
    },
    {
      id: 9115,
      parent: 91,
      path: "/devops/exchangerate/history/:code",
      name: "dev_exchange_rate_history",
      type: "router",
      icon: "",
      title: "Exchange Rate History",
      sort: 150,
      link: "",
      hidden: true,
      text: "menu.dev_exchange_rate_history",
    },
    {
      id: 9116,
      parent: 91,
      path: "/devops/notice",
      name: "dev_notice",
      type: "router",
      icon: "NotificationOutlined",
      title: "Notice Management",
      sort: 160,
      link: "",
      hidden: false,
      text: "menu.dev_notice",
    },
    {
      id: 911601,
      parent: 9116,
      path: "/devops/notice/list",
      name: "dev_notice_list",
      type: "router",
      icon: "",
      title: "Notification Scenario",
      sort: 10,
      link: "",
      hidden: false,
      text: "menu.dev_notice_list",
    },
    {
      id: 911602,
      parent: 9116,
      path: "/devops/notice/template",
      name: "dev_notice_template",
      type: "router",
      icon: "",
      title: "Notice Template",
      sort: 20,
      link: "",
      hidden: false,
      text: "menu.dev_notice_template",
    },
    {
      id: 100,
      parent: 0,
      path: "/partner",
      name: "partner",
      type: "router",
      icon: "DeploymentUnitOutlined",
      title: "Partner",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.partner",
    },
    {
      id: 10001,
      parent: 100,
      path: "/partner/list",
      name: "partner_list",
      type: "router",
      icon: "OrderedListOutlined",
      title: "Partner List",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.partner_list",
    },
    {
      id: 10002,
      parent: 100,
      path: "/partner/details/:id",
      name: "partner_details",
      type: "router",
      icon: "BranchesOutlined",
      title: "Partner Details",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.partner_details",
    },
    {
      id: 10003,
      parent: 100,
      path: "/partner/globalset",
      name: "partner_global_set",
      type: "router",
      icon: "GoogleOutlined",
      title: "Partner Global Set",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.partner_global_set",
    },
    {
      id: 10004,
      parent: 100,
      path: "/partner/groupmanage",
      name: "partner_group_manage",
      type: "router",
      icon: "AppstoreOutlined",
      title: "Partner Group Manage",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.partner_group_manage",
    },
    {
      id: 10005,
      parent: 100,
      path: "/partner/groupset/:id",
      name: "partner_group_set",
      type: "router",
      icon: "BranchesOutlined",
      title: "Partner Group Set",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.partner_group_set",
    },
    {
      id: 10006,
      parent: 100,
      path: "/partner/settlementrecord",
      name: "partner_settlement_record",
      type: "router",
      icon: "PropertySafetyOutlined",
      title: "Partner Settlement Record",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.partner_settlement_record",
    },
    {
      id: 200,
      parent: 0,
      path: "/openplatform",
      name: "open_platform",
      type: "router",
      icon: "UngroupOutlined",
      title: "Partner",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.open_platform",
    },
    {
      id: 20001,
      parent: 200,
      path: "/openplatform/authorizes",
      name: "open_platform_authorizes",
      type: "router",
      icon: "BarsOutlined",
      title: "open platform authorizes",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.open_platform_authorizes",
    },
    {
      id: 20002,
      parent: 200,
      path: "/openplatform/authorizeAPI/:id",
      name: "open_platform_authorize_api",
      type: "router",
      icon: "OrderedListOutlined",
      title: "open platform authorize api",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.open_platform_authorize_api",
    },
    {
      id: 20003,
      parent: 200,
      path: "/openplatform/applys",
      name: "open_platform_applys",
      type: "router",
      icon: "OrderedListOutlined",
      title: "open platform applys",
      sort: 0,
      link: "",
      hidden: false,
      text: "menu.open_platform_applys",
    },
    {
      id: 20004,
      parent: 200,
      path: "/openplatform/applyscompany/:id",
      name: "open_platform_applys_company",
      type: "router",
      icon: "",
      title: "open platform applys company",
      sort: 0,
      link: "",
      hidden: true,
      text: "menu.open_platform_applys_company",
    },
  ];
};

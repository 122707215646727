import client from "../../client"

const Api = {
  // 退货暂存
  // 获取退货暂存配置
  getRtsConfig: "/api/WarehouseService/ReturnTemporaryStorage/GetRtsConfig",
  // 获取退货暂存分页列表
  getReturnTemporaryStorageListPaged: "/api/WarehouseService/ReturnTemporaryStorage/GetConsoleListPaged",
  // 根据Id销毁退货暂存记录
  destroyRtsWithConsole: "/api/WarehouseService/ReturnTemporaryStorage/DestroyRtsWithConsole",
  // 修改退货暂存管理
  updatePackageInfoWithConsole: "/api/WarehouseService/ReturnTemporaryStorage/UpdatePackageInfoWithConsole",
  // 取消暂存管理
  cancelRts: "/api/WarehouseService/ReturnTemporaryStorage/CancelRts",
}

// 获取退货暂存配置
export const getRtsConfig = (data) => {
  return client.request({
    url: Api.getRtsConfig,
    data,
    method: 'POST'
  })
}

// 获取退货暂存分页列表
export const getReturnTemporaryStorageListPaged = (data) => {
  return client.request({
    url: Api.getReturnTemporaryStorageListPaged,
    data,
    method: 'POST'
  })
}

// 根据Id销毁退货暂存记录
export const destroyRtsWithConsole = (data) => {
  return client.request({
    url: Api.destroyRtsWithConsole,
    data,
    method: 'POST'
  })
}

// 修改退货暂存管理
export const updatePackageInfoWithConsole = (data) => {
  return client.request({
    url: Api.updatePackageInfoWithConsole,
    data,
    method: 'POST'
  })
}

// 取消退货暂存管理
export const cancelRts = (data) => {
  return client.request({
    url: Api.cancelRts,
    data,
    method: 'POST'
  })
}

<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.partner_details') }}
    </template>
    <template #contentBody>
      <a-spin size="large"
              :spinning="pageInfo.loading">
        <div>
          <div><strong>{{$t('account.essential_information')}}</strong></div>
          <div class="p-3">
            <a-row :gutter=[32,16]>
              <a-col :xs="8"
                     :sm="6"
                     :md="5"
                     :lg="4"
                     :xl="3"
                     :xxl="2"
                     style="text-align:right">{{$t('finance.partner_type')}}</a-col>
              <a-col :xs="15"
                     :sm="17"
                     :md="18"
                     :lg="19"
                     :xl="20"
                     :xxl="22">
                {{$t($enumLangkey('partnerType',pageInfo.partnerType))}}
              </a-col>
              <a-col :xs="8"
                     :sm="6"
                     :md="5"
                     :lg="4"
                     :xl="3"
                     :xxl="2"
                     style="text-align:right">
                <span v-if="pageInfo.partnerType==partnerTypeEnum.company">
                  {{$t('finance.company_name')}}</span>
                <span v-else>{{$t('warehouse.name')}}</span>
              </a-col>
              <a-col :xs="15"
                     :sm="17"
                     :md="18"
                     :lg="19"
                     :xl="20"
                     :xxl="22"> {{pageInfo.name}}</a-col>
              <a-col :xs="8"
                     :sm="6"
                     :md="5"
                     :lg="4"
                     :xl="3"
                     :xxl="2"
                     style="text-align:right">
                <span v-if="pageInfo.partnerType==partnerTypeEnum.company">
                  {{$t('finance.license_number')}}</span>
                <span v-else>{{$t('finance.id_card_No')}}</span>
              </a-col>
              <a-col :xs="15"
                     :sm="17"
                     :md="18"
                     :lg="19"
                     :xl="20"
                     :xxl="22">
                {{pageInfo.businessLicenseCode}}
              </a-col>

              <a-col :xs="8"
                     :sm="6"
                     :md="5"
                     :lg="4"
                     :xl="3"
                     :xxl="2"
                     style="text-align:right">{{$t('finance.documents')}}</a-col>
              <a-col :xs="15"
                     :sm="17"
                     :md="18"
                     :lg="19"
                     :xl="20"
                     :xxl="22">
                <div v-if="pageInfo.businessLicense">
                  <CFileDisplay :fileUrl='pageInfo.businessLicense'
                  :disImgProcess="true"
                               ></CFileDisplay>
                </div>
              </a-col>

              <a-col :xs="8"
                     :sm="6"
                     :md="5"
                     :lg="4"
                     :xl="3"
                     :xxl="2"
                     style="text-align:right">{{$t('finance.commission_grouping')}}</a-col>
              <a-col :xs="15"
                     :sm="17"
                     :md="18"
                     :lg="19"
                     :xl="20"
                     :xxl="22">
                <a-row>
                  <a-col>
                    <span v-if="!pageInfo.partnerGroupId">
                      {{$t('finance.no_group')}}
                    </span>
                    <span v-else>
                      {{pageInfo.partnerGroupName}}
                    </span>
                  </a-col>
                  <a-col>
                    <a-button class="ml-5"
                              @click="handelShowGroupModal"
                              size="small"> {{$t('finance.change_group')}}</a-button>
                  </a-col>
                </a-row>
              </a-col>

              <a-col :xs="8"
                     :sm="6"
                     :md="5"
                     :lg="4"
                     :xl="3"
                     :xxl="2"
                     style="text-align:right">
                <span >{{$t('warehouse.contact_information')}}</span>
              </a-col>
              <a-col :xs="15"
                     :sm="17"
                     :md="18"
                     :lg="19"
                     :xl="20"
                     :xxl="22">
                <span v-if="pageInfo.phoneCountryCode">+</span>{{ pageInfo.phoneCountryCode }}
                  <span>{{ pageInfo.phoneNumber }}</span>
              </a-col>
              


            </a-row>
          </div>
        </div>

        <div>
          <div><strong>结算信息</strong></div>
          <div class="p-3"
               style="max-width:600px">
            <a-card hoverable>
              <a-row :gutter=[32,16]>
                <a-col :xs="24"
                       :sm="5"
                       style="text-align:right">{{$t('finance.settlement_channel')}}</a-col>
                <a-col :xs="24"
                       :sm="19">{{$t($enumLangkey('settlementChannelType',pageInfo.partnerSettlementChannel.settlementChannelType))}}</a-col>

                <a-col :xs="24"
                       :sm="5"
                       style="text-align:right">{{$t('finance.account_name')}}</a-col>
                <a-col :xs="24"
                       :sm="19"> {{pageInfo.partnerSettlementChannel.accountName}}</a-col>

                <a-col :xs="24"
                       :sm="5"
                       style="text-align:right">{{$t('finance.depositary_bank')}}</a-col>
                <a-col :xs="24"
                       :sm="19"> {{pageInfo.partnerSettlementChannel.bankName}}</a-col>

                <a-col :xs="24"
                       :sm="5"
                       style="text-align:right">{{$t('finance.receiving_account')}}</a-col>
                <a-col :xs="24"
                       :sm="19">
                  <a-row>
                    <a-col>{{pageInfo.partnerSettlementChannel.collectionAccountNumber}}</a-col>
                    <a-col>
                      <a-button class="ml-5"
                                @click="handelShowBankInfoModal"
                                size="small">更换结算渠道</a-button>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-card>
          </div>
        </div>

        <div>
          <div><strong>特殊佣金渠道</strong></div>
          <a-button class="ml-3 mt-3"
                    @click="handleShowChannelModal">添加渠道</a-button>
          <!-- 表格 -->
          <a-table class="p-3"
                   :columns="pageInfo.columns"
                   :data-source="pageInfo.list"
                   :scroll="{ x: 500}"
                   :pagination="{
                   pageSize:5
                 }"
                   :rowKey="
          (record, id) => {
            return id;
          }
        "
                   size="small"
                   :loading="pageInfo.listLoading">

            <template #businessType="{ record }">
              {{$t($enumLangkey('commissionBusinessType',record.businessType))}}
            </template>
            <template #logisticName="{ record }">
              <span class="text-warning"
                    v-if="record.logisticName">{{record.logisticName}}</span>
              <span class="text-warning"
                    v-else>---</span>
            </template>
            <template #mark="{ record }">
              <span class="text-warning"
                    v-if="record.mark">{{record.mark}}</span>
              <span class="text-warning"
                    v-else>---</span>
            </template>
            <template #percent="{ record }">
              {{record.percent}}%
            </template>
            <template #operate="{ record }">
              <a-row :gutter=[8,8]>
                <a-col>
                  <div class="editable-row-operations">
                    <span v-if="editableData[record.key]">
                      <a-button type="link"
                                :loading="record.updateChannelLoading"
                                @click="hanldeSaveChannel(record)">{{$t('common.save')}}</a-button>

                      <a-button type="link"
                                :loading="record.updateChannelLoading"
                                @click="hanldeCancelChannel(record.key)">{{$t('common.cancel')}}</a-button>
                    </span>
                    <span v-else>
                      <a-button type="link"
                                :loading="record.updateChannelLoading"
                                @click="hanldeEditChannel(record.key)">{{$t('common.edit')}}</a-button>
                    </span>
                  </div>
                </a-col>
                <a-col v-if="!editableData[record.key]">
                  <a-popconfirm :title="$t('common.are_you_sure')"
                                :loading="record.updateChannelLoading"
                                @confirm="hanldeDeleteChannel(record)">
                    <a-button type="link">{{$t('common.delete')}}</a-button>
                  </a-popconfirm>
                </a-col>
              </a-row>

            </template>

            <template v-for="col in ['mark', 'percent']"
                      #[col]="{ text, record }"
                      :key="col">
              <div v-if="col=='mark'">
                <a-input v-if="editableData[record.key]"
                         v-model:value="editableData[record.key][col]"
                         style="margin: -5px 0" />
                <template v-else>
                  {{ text }}
                </template>
              </div>

              <div v-else>
                <template v-if="editableData[record.key]">
                  <a-input-number v-model:value="editableData[record.key][col]"
                                  :min="0"
                                  :max="100"
                                  :step="0.01"
                                  style="margin: -5px 0" /> %
                </template>

                <template v-else>
                  {{ text }}%
                </template>
              </div>

            </template>

          </a-table>
        </div>
      </a-spin>
      <!-- 更换分组 -->
      <a-modal v-model:visible="groupModal.visible"
               :title="$t('finance.change_group')"
               :confirmLoading="groupModal.loading"
               :centered="true"
               :maskClosable="false"
               :footer="false">
        <a-form :label-col="{
        span: 6,
      }">
          <a-form-item :label="$t('finance.commission_grouping')"
                       name="partnerType"
                       :wrapper-col="{ span: 9}">
            <a-select v-model:value="groupModal.partnerGroupId"
                      :placeholder="$t('finance.no_group')"
                      optionFilterProp="search-key"
                      show-search
                      :loading="groupModal.loading"
                      allow-clear>
              <a-select-option v-for="(item,index) in groupModal.list"
                               :key="index"
                               :search-key="item.groupName"
                               :title="item.groupName"
                               :value="item.id">
                {{item.groupName}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <a-row type="flex"
               justify="center"
               align="middle"
               class="mt-3">
          <a-col>
            <a-button type="primary"
                      :loading="groupModal.loading"
                      @click="hanldeChangePartnerGroup">{{$t('common.confirm')}}</a-button>
          </a-col>
          <a-col>
            <a-button class="ml-5"
                      :loading="groupModal.loading"
                      @click="groupModal.visible=false">{{$t('common.cancel')}}</a-button>
          </a-col>
        </a-row>
      </a-modal>

      <!-- 更换结算渠道 -->
      <a-modal v-model:visible="bankModal.visible"
               :title="$t('finance.billing_info')"
               :confirmLoading="bankModal.loading"
               :centered="true"
               :maskClosable="false"
               :footer="false">
        <a-form ref="refFormBankModal"
                :model="bankModal"
                :rules="rules"
                :label-col="{
        span: 6,
      }">
          <a-form-item :label="$t('finance.settlement_channel')"
                       name="settlementChannelType"
                       :wrapper-col="{ span: 9}">
            <a-select v-model:value="bankModal.settlementChannelType"
                      allow-clear>
              <a-select-option v-for="(value,index) in settlementChannelTypeEnum"
                               :key="index"
                               :title="$t($enumLangkey('settlementChannelType',value))"
                               :value="value">
                {{$t($enumLangkey('settlementChannelType',value))}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="accountName"
                       :label="$t('finance.account_name')">
            <a-input allow-clear
                     v-model:value="bankModal.accountName"></a-input>
          </a-form-item>
          <a-form-item name="bankName"
                       :label="$t('finance.depositary_bank')">
            <a-input allow-clear
                     v-model:value="bankModal.bankName"></a-input>
          </a-form-item>
          <a-form-item name="collectionAccountNumber"
                       :label="$t('finance.receiving_account')">
            <a-input allow-clear
                     v-model:value="bankModal.collectionAccountNumber"></a-input>
          </a-form-item>

        </a-form>
        <a-row type="flex"
               justify="center"
               align="middle"
               class="mt-3">
          <a-col>
            <a-button type="primary"
                      :loading="bankModal.loading"
                      @click="hanldeChangeBankInfo">{{$t('common.confirm')}}</a-button>
          </a-col>
          <a-col>
            <a-button class="ml-5"
                      :loading="bankModal.loading"
                      @click="bankModal.visible=false">{{$t('common.cancel')}}</a-button>
          </a-col>
        </a-row>
      </a-modal>

      <!-- 可添加渠道modal列表 -->
      <a-modal v-model:visible="channelModal.visible"
               :title="$t('finance.add_channel')"
               :confirmLoading="channelModal.loading"
               :centered="true"
               :maskClosable="false"
               width="600"
               :footer="false">
        <a-spin size="large"
                :spinning="channelModal.loading">
          <div>
            <span>
              {{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.storageService)) }} :
              <a-button class="ml-3"
                        size="small"
                        :disabled="channelModal.storageCommission.isAdd"
                        @click="hanldeShowSetChannelModal(commissionBusinessTypeEnum.storageService,channelModal.storageCommission)">
                <span v-if="!channelModal.storageCommission.isAdd">{{$t('common.addition')}}</span>
                <span v-else>{{$t('common.added')}}</span>
              </a-button>
            </span>
          </div>
          <div class="mt-2">
            {{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.transportService)) }}
          </div>
          <a-table class="mt-1"
                   :columns="channelModal.columns"
                   :data-source="channelModal.list"
                   :scroll="{ x: 600,}"
                   size="small"
                   :pagination="{
                   pageSize:10
                 }"
                   :rowKey="
          (record, id) => {
            return id;
          }
        "
                   :loading="channelModal.loading">
            <template #operate="{ record }">
              <a-button size="small"
                        :disabled="record.isAdd"
                        @click="hanldeShowSetChannelModal(commissionBusinessTypeEnum.transportService,record)">
                <span v-if="!record.isAdd">{{$t('common.addition')}}</span>
                <span v-else>{{$t('common.added')}}</span>
              </a-button>
            </template>

          </a-table>

          <a-row type="flex"
                 justify="center"
                 align="middle">
            <a-col>
              <a-button class="ml-5"
                        :loading="channelModal.loading"
                        @click="channelModal.visible=false">{{$t('common.close')}}</a-button>
            </a-col>
          </a-row>
        </a-spin>
      </a-modal>

      <!-- 添加渠道时设置佣金 -->
      <a-modal v-model:visible="channelModal.isShowSetChannelModal"
               :centered="true"
               :maskClosable="false"
               :footer="false">
        <template #title>
          <span v-if="channelModal.setChannelModalData.businessType==commissionBusinessTypeEnum.storageService">
            {{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.storageService)) }}
          </span>
          <span v-else>
            {{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.transportService)) }}({{channelModal.setChannelModalData?.logisticName}})
          </span>
        </template>
        <a-form :label-col="{
        span: 5,
      }">
          <a-form-item :label="$t('finance.commission_rate')">
            <a-input-number v-model:value="channelModal.setChannelModalData.percent"
                            :min="0"
                            :max="100"
                            :step="0.01" /> %
          </a-form-item>
          <a-form-item :label="$t('common.remark')"
                       :wrapper-col="{ span: 15}">
            <a-textarea v-model:value="channelModal.setChannelModalData.mark"
                        :rows="3" />
          </a-form-item>
        </a-form>
        <a-row type="flex"
               justify="center"
               align="middle"
               class="mt-3">
          <a-col>
            <a-button type="primary"
                      :loading="channelModal.saveing"
                      @click="handleAddChannel">{{$t('common.confirm')}}</a-button>
          </a-col>
          <a-col>
            <a-button class="ml-5"
                      :loading="channelModal.saveing"
                      @click="channelModal.isShowSetChannelModal=false">{{$t('common.cancel')}}</a-button>
          </a-col>
        </a-row>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import {
  Table,
  Button,
  Select,
  Row, Spin,
  Col, Card,
  Input, Form, Modal, InputNumber, message, Popconfirm
} from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue';
import {
  getPartnerGroupList,
  updateGroupCommission,
  updateBankAccountInfo,
  getChannels,
  getAddChannelsList,
  updateChannel,
  deleteChannel,
} from '../../api/modules/partner/details';
import {
  partnerType as partnerTypeEnum,
  settlementChannelType as settlementChannelTypeEnum,
  commissionBusinessType as commissionBusinessTypeEnum,
} from '../../enum/enum.json'
import { useRoute } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import moment from "moment-timezone";
import { useStore } from "vuex";
import CImage from "../components/CImage.vue"
import {
  getPartnerInfo
} from '../../api/modules/partner/list';
import CFileDisplay from "../components/CFileDisplay.vue"
import _ from "lodash"

export default defineComponent({
  name: "partner_details",
  components: {
    ATable: Table,
    AButton: Button,
    CPager,
    CImage,
    CFileDisplay,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    Content,
    AInput: Input,
    AInputNumber: InputNumber,
    APopconfirm: Popconfirm,
    AFormItem: Form.Item,
    AModal: Modal,
    AForm: Form,
    ACard: Card,
    ASpin: Spin,
    ATextarea: Input.TextArea,
  },

  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();

    //-----------更换分组modal start-----------------
    const groupModal = reactive({
      loading: false,
      visible: false,
      list: [],
      partnerGroupId: null,
    });

    const funcGetGroups = () => {
      groupModal.loading = true;
      getPartnerGroupList().then(({ result }) => {
        if (Array.isArray(result)) {
          groupModal.list = result;
        } else {
          groupModal.list = [];
        }
        groupModal.loading = false;
      }).catch(() => {
        groupModal.loading = false;
      })
    }

    const handelShowGroupModal = () => {
      groupModal.visible = true;
      funcGetGroups();
      groupModal.partnerGroupId = pageInfo.partnerGroupId;
    }

    const hanldeChangePartnerGroup = () => {
      groupModal.loading = true;
      updateGroupCommission(pageInfo.id, groupModal.partnerGroupId).then(({ result }) => {
        pageInfo.partnerGroupId = groupModal.partnerGroupId;
        pageInfo.partnerGroupName = groupModal.list.find(x => x.id == groupModal.partnerGroupId)?.groupName;

        groupModal.loading = false;
        groupModal.visible = false;
      }).catch(() => {
        groupModal.loading = false;
      })
    }
    //-----------分组end-----------------

    //-----------更换结算渠道 start-----------------

    const refFormBankModal = ref();

    const bankModal = reactive({
      loading: false,
      visible: false,
    });

    const getBankModal = () => {
      return {
        settlementChannelType: null,
        accountName: null,
        bankName: null,
        collectionAccountNumber: null,
      }
    }
    const handelShowBankInfoModal = () => {
      let tempModalData = getBankModal();
      Object.assign(tempModalData, pageInfo.partnerSettlementChannel);
      Object.assign(bankModal, tempModalData);
      bankModal.settlementChannelType = pageInfo.partnerSettlementChannel.settlementChannelType;
      bankModal.partnerId = pageInfo.id;
      bankModal.visible = true;
      if (refFormBankModal.value && refFormBankModal.value.resetFields) {
        refFormBankModal.value.resetFields();
      }
    }

    // const maskMid = (stringV) => {
    //   if (!stringV || stringV.length == 0) {
    //     return null
    //   }
    //   let strV
    //   if (stringV.length > 1 && stringV.length <= 2) {
    //     strV = stringV.substr(0, 1) + '*';
    //   } else if (stringV.length > 2 && stringV.length <= 4) {
    //     strV = stringV.substr(0, 1) + '*';
    //     strV = strV + stringV.substr(-1);
    //   } else if (stringV.length > 4) {
    //     strV = stringV.substr(0, 2) + '******';
    //     strV = strV + stringV.substr(-2);
    //   } else {
    //     strV = '*';
    //   }
    //   return strV
    // }

    const hanldeChangeBankInfo = () => {
      refFormBankModal.value
        .validate()
        .then(() => {
          bankModal.loading = true;
          updateBankAccountInfo({ ...bankModal }).then(() => {
            bankModal.loading = false;
            bankModal.visible = false;
            message.success(vueI18n.t('common.succeed'));
            bankModal.collectionAccountNumber = bankModal.collectionAccountNumber;
            Object.assign(pageInfo.partnerSettlementChannel, bankModal);
          }).catch(() => {
            bankModal.loading = false;
          })
        })
    }

    const rules = {
      settlementChannelType: [{
        required: true,
        type: "number",
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.settlement_channel")])
        }
      }],
      accountName: [{
        required: true,
        trigger: 'blur',
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.account_name")])
        }
      }],
      bankName: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.depositary_bank")])
        }
      }],
      collectionAccountNumber: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.receiving_account")])
        }
      }],
    };
    //-----------更换结算渠道 end-----------------


    //-----------添加特殊渠道modal-----------------
    const channelColumns = [
      {
        title: () => vueI18n.t('common.serial_number'),
        dataIndex: 'key',
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        width: 60,
      },
      {
        title: () => vueI18n.t('logistics.logistics_scheme_name'),
        dataIndex: 'logisticName',
        width: 200,
      },
      {
        title: () => vueI18n.t('finance.channel_code'),
        dataIndex: 'logisticCode',
        width: 200,
      },
      {
        title: '',
        width: 100,
        dataIndex: 'operate',
        slots: {
          customRender: "operate"
        },
      },
    ];

    const channelModal = reactive({
      loading: false,
      visible: false,
      list: [],
      storageCommission: {
        isAdd: null,
        percent: null,
      },
      columns: channelColumns,

      //点添加后的modal
      isShowSetChannelModal: false,
      setChannelModalData: {
        businessType: null,
        percent: null,
        mark: null,
      },
      saveing: false
    });

    const handleShowChannelModal = (record) => {
      channelModal.visible = true;
      if (!channelModal.list || channelModal.list.length == 0) {
        funcGetAddChannels();
      }
    }

    const hanldeShowSetChannelModal = (businessType, record) => {
      channelModal.isShowSetChannelModal = true;
      channelModal.setChannelModalData = record;
      channelModal.setChannelModalData.businessType = businessType;
      channelModal.setChannelModalData.businessId = record.logisticId;
    }

    const handleAddChannel = () => {
      if (!channelModal.setChannelModalData.percent) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.commission_rate")]))
        return
      }
      channelModal.setChannelModalData.partnerId = pageInfo.id;
      channelModal.saveing = true;
      updateChannel({
        ...channelModal.setChannelModalData
      }).then((result) => {
        channelModal.setChannelModalData.isAdd = true;
        channelModal.isShowSetChannelModal = false;
        channelModal.saveing = false;
        message.success(vueI18n.t('common.succeed'));
        funcGetTableChannels(pageInfo.id);
      }).catch(() => {
        channelModal.saveing = false;
      })
    }

    const funcGetAddChannels = () => {
      channelModal.loading = true;
      getAddChannelsList(pageInfo.id).then(({ result }) => {
        if (result) {
          channelModal.storageCommission = result?.storageCommission;
          channelModal.list = result?.transportCommissions;
        }
        channelModal.loading = false;
      }).catch(() => {
        channelModal.loading = false;
      })
    }


    //-----------佣金渠道 end-----------------

    //-----------页面基本信息和特殊渠道表格及操作 start-----------------

    const pageColumns = [
      {
        title: () => vueI18n.t('common.serial_number'),
        dataIndex: 'key',
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        width: 60,
      },
      {
        title: () => vueI18n.t('finance.business_type'),
        dataIndex: 'businessType',
        slots: {
          customRender: "businessType"
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('finance.channel'),
        dataIndex: 'logisticName',
        slots: {
          customRender: "logisticName"
        }, width: 300,
      },
      {
        title: () => vueI18n.t('common.remark'),
        slots: {
          customRender: "mark"
        }, dataIndex: 'mark',
        width: 300,
      },
      {
        title: () => vueI18n.t('finance.commission_rate'),
        slots: {
          customRender: "percent"
        }, dataIndex: 'percent',
        width: 200,
      },
      {
        title: '',
        width: 200,
        dataIndex: 'operate',
        slots: {
          customRender: "operate"
        },
      },
    ];

    const editableData = reactive({});
    const pageInfo = reactive({
      id: null,//partnerid
      loading: false,
      partnerType: null,
      name: null,
      businessLicenseCode: null,
      businessLicense: null,
      partnerGroupId: null,
      partnerGroupName: null,
      partnerSettlementChannel: {
        settlementChannelType: null,
        accountName: null,
        bankName: null,
        collectionAccountNumber: null,
        id: null,
        partnerId: null,
      },

      //特殊表格渠道
      list: [],
      columns: pageColumns,
      listLoading: false,
      updateChannelLoading: false,
    });

    const funcGetTableChannels = (id) => {
      return getChannels(id).then(({ result }) => {
        pageInfo.list = [];
        if (result.storageCommission) {
          pageInfo.list.push({
            key: 1,
            partnerid: result.partnerid,
            businessType: commissionBusinessTypeEnum.storageService,
            mark: result.storageCommission.mark,
            percent: result.storageCommission.percent,
            commissionId: result.storageCommission.commissionId,
            logisticName: null,
            logisticId: null,
          });
        }

        let transportCommissions = result?.transportCommissions.map((x, index) => {
          return {
            key: index + 3,
            businessType: commissionBusinessTypeEnum.transportService,
            mark: x.mark,
            percent: x.percent,
            commissionId: x.commissionId,
            logisticName: x.logisticName,
            logisticId: x.logisticId,
          }
        });
        pageInfo.list.push.apply(pageInfo.list, transportCommissions);

      })
    }

    const funcGetPartnerInfo = (id) => {
      return getPartnerInfo(id).then(({ result }) => {
        if (result) {
          Object.assign(pageInfo, result);
        }
      })
    }

    const hanldePageInfo = (id) => {
      pageInfo.loading = true;
      Promise.all([funcGetPartnerInfo(id), funcGetTableChannels(id)])
        .then(() => {
          pageInfo.loading = false;
        }).catch(() => {
          pageInfo.loading = false;
        })
    }


    const hanldeEditChannel = key => {
      editableData[key] = _.cloneDeep(pageInfo.list.filter(item => key === item.key)[0]);
    };


    const hanldeSaveChannel = (record) => {
      //  record :原行对象
      //   editableData[record.key] 克隆的行对象
      record.updateChannelLoading = true;
      editableData[record.key].partnerId = pageInfo.id;
      editableData[record.key].businessId = editableData[record.key].logisticId;
      updateChannel({ ...editableData[record.key] }).then(({ result }) => {
        Object.assign(pageInfo.list.filter(item => record.key === item.key)[0], editableData[record.key]);
        delete editableData[record.key];
        record.updateChannelLoading = false;
        message.success(vueI18n.t('common.succeed'));
      }).catch(() => {
        record.updateChannelLoading = false;
      })
    }

    const hanldeCancelChannel = (key) => {
      delete editableData[key];
    }

    const hanldeDeleteChannel = (record) => {

      record.updateChannelLoading = true;
      deleteChannel(record.commissionId).then(({ result }) => {
        record.updateChannelLoading = false;
        message.success(vueI18n.t('common.succeed'));
        funcGetAddChannels();
        funcGetTableChannels(pageInfo.id);
      }).catch(() => {
        record.updateChannelLoading = false;
      })
    }

    //-----------页面基本信息和特殊渠道表格及操作 start-----------------

    onMounted(() => {
      let id = route.params.id;
      if (id) {
        hanldePageInfo(id);
      }
    });

    return {
      groupModal,
      bankModal,
      channelModal,
      moment,
      rules,
      refFormBankModal,
      pageInfo,
      partnerTypeEnum,
      commissionBusinessTypeEnum,
      settlementChannelTypeEnum,
      handelShowGroupModal,
      hanldeChangePartnerGroup,
      handelShowBankInfoModal,
      hanldeChangeBankInfo,
      handleShowChannelModal,
      handleAddChannel,
      hanldeShowSetChannelModal,
      hanldeEditChannel,
      hanldeSaveChannel,
      hanldeCancelChannel,
      hanldeDeleteChannel,
      editableData,
    };
  },
});
</script>
<style lang="less" scoped>
</style>

export default {
  "finance": {
    "payment_serial_number": "Payment serial number",
    "collection_serial_number": "Serial Number of Receipt",
    "recharge_serial_number": "Recharging serial number",
    "top_up_currency": "Recharge Currency",
    "payment_money": "Recharge Amount",
    "currency_of_payment": "Payment Currency",
    "exchange_rate": "Exchange rate",
    "payment_method": "Payment Method",
    "account_to_be_credited": "Receipt Account",
    "serial_number": "Serial number",
    "top_up_status": "Recharge Status",
    "remark_info": "Remark Information",
    "confirm_the_inventory": "Confirm Receipt",
    "in_account_of_failure": "Failed Receipt",
    "please_check_the_payment_serial_number_and_amount_carefully_and_cannot_be_modified_after_submission": "P.S.: Please check the Serial Number of Receipt and amount carefully, it cannot be modified again after submitting.",
    "currency": "Currency",
    "company_name_or_id": "Company Name or ID",
    "unlimited": "Unlimited",
    "minimum": "Minimum",
    "highest": "Maximum",
    "select_a_failure_cause": "Please select the reason of receipt failure.",
    "partner_name_or_id": "Partner Name or ID.",
    "commission_grouping": "Commission Group",
    "partner_account_opening": "Partner Registration",
    "partner_commission": "Partner Commission",
    "partner_name": "Partner Name",
    "account_status": "Account Status",
    "type": "Type",
    "total_commission": "Total Commission",
    "commission_to_be_confirmed": "Commission to be confirmed",
    "confirmed_commission": "Confirmed Commission",
    "commission_cancelled": "Cancelled Commission",
    "unsettled_commission": "Unsettled Commission",
    "partner_type": "Partner Type",
    "company_name": "Company Full Name",
    "license_number": "License Number",
    "id_card_No": "ID card Number",
    "documents": "Credentials Documents",
    "settlement_channel": "Channel of Settlement",
    "account_name": "Account Name",
    "depositary_bank": "Bank Of Deposit",
    "receiving_account": "Receipt Account",
    "logistics_plan_name": "Logistics Solution Name",
    "channel_code": "Channel Code",
    "warehousing_services": "Storage Service",
    "transport_service": "Shipping Service",
    "origin_support": "Origin Support",
    "name": "Name",
    "group_name": "Group Name",
    "partner_group_set": "Group Commission Settings",
    "create_group": "Create Group",
    "change_group": "Change Group",
    "billing_info": "Settlement Information",
    "add_channel": "Add Channel",
    "normal": "Normal",
    "pay_commission": "Pay Commission",
    "total_amount_confirmed": "Confirmed Total Amount",
    "settled_total": "Total Settled Amount",
    "unsettled_total": "Total Unsettled Amount",
    "settlement_serial_number": "Settlement Serial Number",
    "bank_account_no": "Bank Account",
    "date": "Date",
    "settlement_object": "Settlement Target",
    "payee": "Payee",
    "partner": "Partner",
    "status": "Status",
    "set": "Settings",
    "channel": "Channel",
    "country": "Country",
    "timezone": "Timezone",
    "settle_commission": "Settle Commission",
    "settle_sum": "Settle Amount",
    "settle_accounts": "Settlement",
    "commission_rate": "Commission Ratio",
    "business_type": "Business type",
    "no_group": "No Group",
    "normal_commission_distribution": "Normal Commission",
    "termination_of_commission_distribution": "Terminate Commission",
    "the_settlement_amount_cannot_be_greater_than_the_unsettled_commission": "Settle Amount cannot be more than Unsettled Commission.",
    "cost_record": "Charges record",
    "cost_type": "Charges type",
    "business_no": "Business number",
    "business_time": "Business time",
    "transaction_type": "Transaction type",
    "business_country": "Business country",
    "happen_currency": "Currency of occurrence",
    "affiliation_order": "Attributed bill",
    "fee_detail": "Charges details",
    "facility_code": "Facility code",
    "balance_transaction_details": "Transaction details of balance",
    "trading_flow_number": "交易流水号",
    "related_business_number": "相关业务编号",
    "balance": "Balance",
    "accounting_period": "账期",
    "view_storage_fee_details": "查看仓储费明细",
    "export_storage_fee_details": "导出仓储费明细"
  }
}
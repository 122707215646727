<template>
  <a-modal
    v-model:visible="state.visible"
    :title="$t('account.offline_services')"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="state.formState"
      :rules="rules"
      :label-col="{ style: { width: '150px' } }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item :label="$t('finance.related_business_number')" name="businessNo">
        <a-input v-model:value="state.formState.businessNo" />
      </a-form-item>
      <a-form-item :label="$t('account.amount') + ' ( ' + record.currencySymbol + ' ) '" name="refundAmount">
        <a-input-number style="width: 100%;" :min="0.01" :precision="2" v-model:value="state.formState.refundAmount" />
      </a-form-item>
      <a-form-item :label="$t('warehouse.warehouse_name')" name="warehouseId">
        <a-select
          v-model:value="state.formState.warehouseId"
          :loading="state.warehouseLoading"
          show-search
          option-filter-prop="search-key"
        >
          <a-select-option
            v-for="(item, index) in state.warehouseList"
            :key="index"
            :value="item.id"
            :title="`${item.warehouseNo}(${item.warehouseName})`"
            :search-key="item.warehouseNo + item.warehouseName"
          >{{ item.warehouseNo }}({{ item.warehouseName }})</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('common.remark')">
        <a-textarea v-model:value="state.formState.manageRemark" :autoSize="{ minRows: 3, maxRows: 6 }" showCount :maxlength="128"></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, InputNumber, Modal, Select } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { getWarehouses } from "@/api/modules/common/index.js";

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputNumber: InputNumber,
    ATextarea: Input.TextArea,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);

    const state = reactive({
      visible: false,
      confirmLoading: false,
      formState: {
        businessNo: null,
        refundAmount: null,
        warehouseId: null,
        manageRemark: null,
      },
      warehouseLoading: false,
      warehouseList: [],
    })

    const rules = {
      businessNo: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('finance.related_business_number')]),
      },
      refundAmount: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('account.amount')]),
      },
      warehouseId: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.warehouse_name')]),
      },
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        const data = Object.assign({}, state.formState, { balanceId: props.record.id });
        emit('confirm', data);
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const getWarehouseList = () => {
      state.warehouseList = [];
      state.warehouseLoading = true;
      getWarehouses({ currencyId: props.record.currencyId }).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        }
      }).catch(() => {
      }).finally(() => {
        state.warehouseLoading = false;
      });
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => props.visible, (visible) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          if (props.record) {
            getWarehouseList();
          }
          nextTick(() => {
            formRef.value.resetFields();
            state.formState.manageRemark = null;
          });
        }
      }
    }, { immediate: true })

    return {
      state,
      formRef,
      rules,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>
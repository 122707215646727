<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.dev_notice_list') }}
    </template>
    <template #contentBody="wrap">
      <a-table
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 50 }"
        :pagination="false"
        size="small"
        :loading="tableData.loading"
        :rowKey="(record) => record.id"
      >
        <template #notificationBusinessType="{ record }">
          {{ $t($enumLangkey('notificationBusinessType', record.notificationBusinessType)) }}
        </template>
        <template #isEnablePhone="{ record }">
          <a-switch v-model:checked="record.isEnablePhone" :loading="record.loading" @change="handleUpdateRecord(record, 'isEnablePhone')"></a-switch>
        </template>
        <template #isEnableMail="{ record }">
          <a-switch v-model:checked="record.isEnableMail" :loading="record.loading" @change="handleUpdateRecord(record, 'isEnableMail')"></a-switch>
        </template>
        <template #operation="{ record }">
          <a-button @click="handleNavigate(record.notificationBusinessType)">{{ $t('devops.edit_template') }}</a-button>
          <!-- <a-dropdown-button @click="handleNavigate(record.notificationBusinessType)">
            {{ $t('devops.edit_template') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :key="1" :record="record">{{ $t('devops.edit_attribute') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button> -->
        </template>
      </a-table>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, toRefs } from 'vue';
import { Button, Dropdown, Menu, message, Switch, Table } from 'ant-design-vue';
import Content from "@/views/components/Content.vue";
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router'; 
import {
  getShopNotificationSceneList,
  isActiveSms,
  isActiveMail,
} from '@/api/modules/devops/notice.js';
import { notificationBusinessType as notificationBusinessTypeEnum } from '@/enum/enum.json';

export default defineComponent({
  name: "dev_notice_list",
  components: {
    ATable: Table,
    ASwitch: Switch,
    AButton: Button,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
  },
  setup () {
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      tableData: {
        tableList: [],
        loading: false,
      },
    })
    
    const columns = [
      {
        width: 200,
        title: () => vueI18n.t('devops.notification_business_type'),
        slots: {
          customRender: "notificationBusinessType",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('devops.sms_notification'),
        slots: {
          customRender: "isEnablePhone",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('devops.email_notification'),
        slots: {
          customRender: "isEnableMail",
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: "operation",
        }
      },
    ]

    const handleNavigate = (type) => {
      router.push('/devops/notice/template?t=' + type);
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case 1:
          // 进入属性页面
          router.push('/devops/notice/attribute?t=' + record.notificationBusinessType);
          break;
        default:
          break;
      }
    } 

    const handleUpdateRecord = async (record, key) => {
      try {
        record.loading = true;
        const data = {
          notificationBusinessType: record.notificationBusinessType,
          isActive: record[key],
        };
        if (key === 'isEnablePhone') {
          await isActiveSms(data);
        } else if (key === 'isEnableMail') {
          await isActiveMail(data);
        }
        message.success(vueI18n.t('common.succeed'));
      } catch (error) {
        record[key] = !record[key];
      } finally {
        record.loading = false;
      }
    }

    const getPageList = () => {
      state.tableData.loading = true;
      getShopNotificationSceneList()
        .then(({ result }) => {
          if (Array.isArray(result)) {
            state.tableData.tableList = result;
          } else {
            state.tableData.tableList = [];
          }
        })
        .catch(() => {}).finally(() => {
          state.tableData.loading = false;
        });
    };

    onActivated(getPageList);

    return {
      ...toRefs(state),
      notificationBusinessTypeEnum,
      columns,
      handleNavigate,
      handleMenuClick,
      handleUpdateRecord,
      getPageList,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <a-row :gutter="[8, 8]" type="flex" align="middle">
    <a-col style="white-space: nowrap;">
      <a-typography-text type="secondary" v-if="!status || status === labelTaskDetailStatusEnum.canceled">{{ name }}</a-typography-text>
      <a-typography-text type="success" v-else>{{ name }}</a-typography-text>
      <span style="margin-left: 2px;">
        <template v-if="status">
          <CheckCircleFilled class="text-success" v-if="status !== labelTaskDetailStatusEnum.canceled" />
          <CloseCircleFilled v-else class="text-warning" />
        </template>
        <CheckCircleOutlined v-else style="color: #aaa;" />
      </span>
    </a-col>
    <a-col>
      <a-popover v-if="status && userInfo">
        <template #content>
          <a-row :gutter="[16, 16]" type="flex" align="middle">
            <a-col>
              <a-avatar shape="square" :src="userInfo.imageUrl"></a-avatar>
            </a-col>
            <a-col type="flex">
              <div>{{ getEmployeeName }}</div>
              <div>{{ userInfo.employeeNo }}</div>
            </a-col>
          </a-row>
        </template>
        <a-tag style="padding: 2px 6px 2px 2px;">
          <a-row :gutter="8" type="flex" align="middle" style="flex-wrap: nowrap;">
            <a-col>
              <a-avatar shape="square" size="small">
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </a-col>
            <a-col>{{ userInfo.employeeNo }}</a-col>
          </a-row>
        </a-tag>
      </a-popover>
    </a-col>
  </a-row>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { Avatar, Col, Popover, Row, Tag, Typography } from 'ant-design-vue';
import { useStore } from 'vuex';
import { getNameByLanguage } from '@/utils/general.js';
import { labelTaskDetailStatus as labelTaskDetailStatusEnum } from '@/enum/enum.json';

export default defineComponent({
  props: {
    name: String,
    status: Number,
    userInfo: Object
  },
  components: {
    ARow: Row,
    ACol: Col,
    AAvatar: Avatar,
    ATag: Tag,
    APopover: Popover,
    ATypographyText: Typography.Text,
  },
  setup (props) {
    const { getters } = useStore();

    const getEmployeeName = computed(() => {
      if (props.userInfo) {
        return getNameByLanguage(props.userInfo, getters.language);
      }
      return '-';
    })

    return {
      labelTaskDetailStatusEnum,
      getEmployeeName,
    }
  }
})
</script>

<style lang="less" scoped>
.status-success {
  background: @success-color;
}
</style>
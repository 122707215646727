<template>
  <a-modal
    v-model:visible="visible"
    :title="
      formState.id
        ? $t('warehouse.edit_application')
        : $t('warehouse.create_application')
    "
    :confirm-loading="loading"
    :mask-closable="false"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item
        :label="$t('warehouse.application_logo')"
        name="logo"
      >
        <a-upload
          name="img"
          list-type="picture-card"
          :customRequest="handleUpload"
          :showUploadList="false"
          accept="image/png, image/jpeg"
        >
          <div v-if="formState.logo" class="pabi-img">
            <loading-outlined v-if="uploadLoading"></loading-outlined>
            <img style="width: 100px;" :src="formState.logo" />
          </div>
          <div v-else>
            <loading-outlined v-if="uploadLoading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">{{ $t("common.upload") }}</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :label="$t('warehouse.application_name')" name="appName">
        <a-input v-model:value="formState.appName" allow-clear />
      </a-form-item>
      <a-form-item
        :label="$t('warehouse.call_back_address')"
        name="callBackUrl"
      >
        <a-input v-model:value="formState.callBackUrl" allow-clear />
      </a-form-item>
      <a-form-item :label="$t('warehouse.application_description')" name="description">
        <a-textarea v-model:value="formState.description" allow-clear />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n/index";
import { Button, Form, Input, message, Modal, Upload } from "ant-design-vue";
import CImage from "@/views/components/CImage";
import { uploadFile } from "@/api/modules/common/index";
import {
  getAuthorizeApplicationById,
  createAuthorizeApplication,
  updateAuthorizeApplication,
} from "@/api/modules/openPlatform/applys";

export default defineComponent({
  name: "editApplysModal",
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AUpload: Upload,
    AInput: Input,
    ATextarea: Input.TextArea,
    AButton: Button,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const formRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      uploadLoading: false,
      formState: {
        id: null,
        logo: null,
        appName: null,
        callBackUrl: null,
        description: null,
      },
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },
    });

    const rules = {
      logo: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.application_logo"),
          ]),
      },
      appName: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.application_name"),
          ]),
      },
      callBackUrl: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.call_back_address"),
          ]),
      },
      description: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.application_description"),
          ]),
      },
    };

    const show = (id) => {
      state.formState.id = id ?? null;
      state.formState.description = null;
      state.visible = true;
      nextTick(() => formRef.value.resetFields());
      if (id) {
        state.loading = true;
        getAuthorizeApplicationById({ id })
          .then(({ result }) => {
            if (result) {
              for (const key in result) {
                if (Object.hasOwnProperty.call(state.formState, key)) {
                  state.formState[key] = result[key];
                }
              }
            }
          })
          .catch(() => {})
          .finally(() => {
            state.loading = false;
          });
      }
    };

    const handleUpload = (e) => {
      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", e.file);
      parameter.append("functionModule", 17);
      uploadFile(parameter).then(({ result }) => {
        if (Array.isArray(result) && result.length > 0) {
          state.formState.logo = result[0].url;
          formRef.value.validate(['logo'])
        }
      })
        .catch((err) => {}).finally(() => {
          state.uploadLoading = false;
        })
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.loading = true;
        const data = Object.assign({}, state.formState);
        let appData = null;
        if (data.id) {
          await updateAuthorizeApplication(data);
        } else {
          let { result } = await createAuthorizeApplication(data);
          if (result) {
            appData = result;
          }
        }
        message.success(vueI18n.t("common.succeed"));
        state.visible = false;
        nextTick(() => {
          emit("refresh", appData);
        });
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    return {
      ...toRefs(state),
      formRef,
      rules,
      show,
      handleUpload,
      handleConfirm,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.warehouse_monitor_consignment_order_task") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="orderNoes"
            mode="tags"
            :maxTagCount="1"
            :maxTagTextLength="18"
            :token-separators="[',', '，', ' ', '\t', '\r', '\n']"
            :placeholder="$t('logistics.consignment_order_no')"
            allowClear
            @change="handleSelectValueChange('orderNoes')"
          ></a-select>
        </a-col>
        <a-col>
          <a-input
            v-model:value="searchKey"
            :placeholder="$t('logistics.relation_order_no') + '/' + $t('logistics.recipients')"
            allow-clear
            style="width: 250px;"
          />
        </a-col>
        <a-col>
          <CSearchShop v-model:shopId="shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-select
            v-model:value="selectedConsignmentNo"
            :allowClear="true"
            :showSearch="true"
            :placeholder="$t('warehouse.consignment_code')"
            optionFilterProp="search-key"
            style="width: 250px;"
          >
            <a-select-option
              v-for="(item,index) in warehousesCodes"
              :key="index"
              :value="item.id"
              :title="item.warehouseNo + '('+ item.warehouseName +')'"
              :search-key="item.warehouseNo + item.warehouseName"
            >
              {{item.warehouseNo + '('+ item.warehouseName +')'}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="selectedCountrys"
            :allowClear="true"
            :showSearch="true"
            :placeholder="$t('common.destination_country')"
            optionFilterProp="search-key"
            style="width: 250px;"
          >
            <a-select-option
              v-for="item in countrys"
              :key="item.id"
              :title="`${getLanguageName(item)}(${item.ioS2})`"
              :search-key="item.ioS2 + item.cnName + item.enName"
            >
              {{ getLanguageName(item) }}({{ item.ioS2 }})
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="logisticsType"
            allow-clear
            :placeholder="$t('warehouse.logistics_way')"
            style="width: 250px;"
          >
            <a-select-option
              v-for="(value, index) in agentOrderLogisticsTypeEnum"
              :key="index"
              :title="$t($enumLangkey('agentOrderLogisticsType', value))"
              :value="value"
            >
              {{ $t($enumLangkey('agentOrderLogisticsType', value)) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col>
          <a-select
            v-model:value="surfacePrintType"
            allow-clear
            :placeholder="$t('打印类型')"
            style="width: 250px;"
          >
            <a-select-option
              v-for="(value, index) in agentOrderLogisticsPrintTypeEnum"
              :key="index"
              :title="$t($enumLangkey('agentOrderLogisticsPrintType', value))"
              :value="value"
            >
              {{ $t($enumLangkey('agentOrderLogisticsPrintType', value)) }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col>
          <a-select
            v-model:value="selectedDeliveryWay"
            :allowClear="true"
            :showSearch="true"
            :placeholder="$t('logistics.delivery_way')"
            optionFilterProp="search-key"
            style="width: 250px;"
          >
            <a-select-option
              v-for="(value,key,index) in deliveryWay"
              :key="key"
              :title="$t($enumLangkey('deliveryWay',value))"
              :value="value"
              :data-index="index"
              :search-key="$t($enumLangkey('deliveryWay',value))"
            >
              {{ $t($enumLangkey('deliveryWay',value)) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="selectedWarehouseStatus"
            :allowClear="true"
            :placeholder="$t('warehouse.warehouse_operation_status')"
            style="width: 250px;"
          >
            <a-select-option
              v-for="(value,key) in warehouseAgentOrderStatus"
              :key="key"
              :value="value"
            >
              {{$t($enumLangkey('warehouseAgentOrderStatus',value))}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="status"
            :allowClear="true"
            :placeholder="$t('warehouse.order_status')"
            style="min-width: 250px;"
          >
            <a-select-option
              v-for="(value,key) in agentOrderStatusEnum"
              :key="key"
              :value="value"
            >
              {{$t($enumLangkey('agentOrderStatus',value))}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="isRefund"
            :allowClear="true"
            :placeholder="$t('warehouse.whether_to_refund')"
            style="width: 250px;"
          >
            <a-select-option
              v-for="(item, index) in refundAndCompensationStatusList"
              :key="index"
              :title="$t(item.label)"
              :value="item.value"
              :data-index="index"
            >
              {{ $t(item.label) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="isLoseMoney"
            :allowClear="true"
            :placeholder="$t('warehouse.whether_to_compensate')"
            style="width: 250px;"
          >
            <a-select-option
              v-for="(item, index) in refundAndCompensationStatusList"
              :key="index"
              :title="$t(item.label)"
              :value="item.value"
              :data-index="index"
            >
              {{ $t(item.label) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="hasException"
            :allowClear="true"
            :placeholder="$t('warehouse.is_it_abnormal')"
            style="width: 250px;"
          >
            <a-select-option :title="$t('warehouse.abnormal')" :value="1">
              {{ $t('warehouse.abnormal') }}
            </a-select-option>
            <a-select-option :title="$t('warehouse.no_abnormality')" :value="0">
              {{ $t('warehouse.no_abnormality') }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="isTimeout"
            :allowClear="true"
            :placeholder="$t('warehouse.is_it_timeout')"
            style="width: 250px;"
          >
            <a-select-option  :value="1">
              {{ $t('common.yes') }}
            </a-select-option>
            <a-select-option  :value="0">
              {{ $t('common.no') }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-range-picker style="width: 250px" v-model:value="dataRange" />
        </a-col>
         <a-col>
            <SearchProduct ref="refSearchProduct"></SearchProduct>
        </a-col>
        <a-col>
          <a-space>
            <a-button :loading="listLoading" type="primary" @click="handleChangeStatus">
              {{$t('common.query')}}
            </a-button>
            <a-button type="ghost" @click="handleExport(999)">
              {{$t('common.export')}}
            </a-button>
            <a-button type="ghost" @click="handleExport(998)">
              {{$t('warehouse.export_product')}}
            </a-button>
          </a-space>
        </a-col>
      </a-row>
      <div class="inventory-table mt-3">
        <a-table :columns="columns"
                 :data-source="orderList"
                 :scroll="{ x:700,y: wrap.contentHeight - 220}"
                 :pagination="false"
                 size="small"
                 :loading="listLoading"
                 class="product-table">
          <template #orderNo>
            <div>{{ $t("logistics.consignment_order_no") }}</div>
            <div>{{ $t("logistics.relation_order_no") }}</div>
          </template>
          <template #transportSchemeAndLogisticsTrackNo>
            <div>{{ $t("logistics.transport_scheme") }}</div>
            <div>{{ $t("logistics.logistics_track_no") }}</div>
          </template>
          <template #updateTimeTitle>
            <div>{{ $t("warehouse.creation_time") }}</div>
            <div>{{ $t("logistics.last_modification_time") }}</div>
          </template>

           <template #product="{ record }">
              <ProductDetails :record="record"></ProductDetails>
          </template>

          <template #destinationAddressCustom="{ record }">
            <span v-if="record.destinationAddress">{{ getAddress(record.destinationAddress) }}</span>
            <span v-else>-</span>
          </template>

          <template #orderNoCustom="{ record }">
            <div>
              {{record.orderNo}}
            </div>
            <div>
              {{record.relationOrderNo}}
            </div>
          </template>

          <template #customer="{ record }">
            {{record.shopName}}
            <div v-if="record.shopNo">
              NO: {{record.shopNo}}
            </div>
             <div>
               {{$t('common.shop_mark')}}: {{record.shopMark??"-"}}
            </div>
          </template>

          <template #recipientsCustom="{ record }">
            <div>
              {{record.recipientsName || '-'}}
            </div>
            <div>
              {{record.recipientsNumber || '-'}}
            </div>
          </template>
          <template #waveNoCustom="{ record }">
            <span v-if="record.waveNo">{{ record.waveNo }}</span>
            <span v-else>-</span>
          </template>
          <template #warehouseStatus="{ record }">
            {{$t($enumLangkey('warehouseAgentOrderStatus', record.warehouseStatus))}}
          </template>
          <template #statusCustom="{ record }">
            <a-space direction="vertical">
              <span>{{ $t($enumLangkey('agentOrderStatus', record.status)) }}</span>
              <span v-if="record.isShowSurfaceErrorMsg" style="cursor: pointer; margin-left: 5px;" @click="handleShowErrorMessage(record)">
                <ExclamationCircleOutlined style="color: red;" />
              </span>
              <div v-if="record.isApplyCancelDelivery">
                <a-tag v-if="record.cancelDeliveryType==cancelDeliveryTypeEnum.succeed"
                      color="success">
                  {{$t('warehouse.cancel_shipment_succeed')}}
                </a-tag>
                <a-tag v-else-if="record.cancelDeliveryType==cancelDeliveryTypeEnum.unsuccessful"
                      color="error">
                  {{$t('warehouse.cancel_shipment_unsuccessful')}}
                </a-tag>
                <a-tag v-else-if="!record.cancelDeliveryType"
                      color="processing">
                  {{$t('warehouse.cancel_shipment_processing')}}
                </a-tag>
              </div>
              <a-tag color="blue" v-if="record.refundMoney > 0">{{ $t('account.refund') }} {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.refundMoney) }}</a-tag>
              <a-tag color="orange" v-if="record.loseMoney > 0">{{ $t('warehouse.compensation') }} {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.loseMoney) }}</a-tag>
            </a-space>
            <div v-if="record.operateTimeOutSeconds && record.operateTimeOutSeconds > 0">
               <a-tag color="error">
                {{$t('warehouse.timeout')}}:
                {{formattedTime(record.operateTimeOutSeconds)}}
               </a-tag>
            </div>
          </template>
          <template #totalAmount="{ record }">
            {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.totalAmount) }}
          </template>

          <template #transportSchemeAndLogisticsTrackNoCustom="{ record }">
            <div v-if="record.logisticsType == agentOrderLogisticsTypeEnum.seLogistics">
                {{record.transportScheme}}
              <div>
                {{record.LogisticsTrackNo || '-'}}
              </div>
                <a-tag color="success" v-if="record.surfacePrintType == agentOrderLogisticsPrintTypeEnum.online">
                  {{ $t($enumLangkey('agentOrderLogisticsPrintType', record.surfacePrintType)) }}
                </a-tag>
                <a-tag color="error" v-if="record.surfacePrintType == agentOrderLogisticsPrintTypeEnum.offline">
                  {{ $t($enumLangkey('agentOrderLogisticsPrintType', record.surfacePrintType)) }}
                </a-tag>
            </div>
            <div v-else-if="record.logisticsType == agentOrderLogisticsTypeEnum.thirdparty">
              <a-row>
                <a-col>
                  <CFileDisplay :fileUrl="record.surfaceUrl"
                                class="mr-1"></CFileDisplay>
                </a-col>
                <a-col>
                  <small>{{$t('warehouse.third_side_list')}}</small>
                </a-col>
              </a-row>
            </div>
            <small v-else-if="record.logisticsType == agentOrderLogisticsTypeEnum.destruction">{{ $t('warehouse.destroy_order') }}</small>
            <span v-else>-</span>
          </template>

          <template #track="{ record }">
            <a-row :gutter="8">
              <a-col><small style="color: #aaa;">{{ $filters.utcToCurrentTime(record.track?.trackTime) }}</small></a-col>
              <a-col><small style="color: #aaa;">{{ record.track?.trackRemark }}</small></a-col>
            </a-row>
          </template>
          
          <template #deliveryMethod="{ record }">
            <span v-if="record.deliveryMethod">
              {{ $t($enumLangkey('deliveryWay', record.deliveryMethod)) }}
            </span>
            <span v-else>-</span>
          </template>

          <template #updateTimeCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>{{ $filters.utcToCurrentTime(record.updateTime) }}</div>
          </template>

          <template #operateCustom="{ record }">
            <a-dropdown-button>
              <router-link :to="'/warehousemonitor/consignment/ordertask/' + record.key">
                {{ $t('common.details') }}
              </router-link>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item :key="1" :record="record" :disabled="!record.isShowSurfaceErrorMsg">
                    {{ $t("warehouse.error_message") }}
                  </a-menu-item>
                  <a-menu-item :key="2" :record="record" :disabled="!record.logisticsTrackLabelUrl">
                    {{ $t("logistics.print_booking_note") }}
                  </a-menu-item> 
                  <a-menu-item :key="6" :record="record" :disabled="!record.isShowSurfaceErrorMsg">
                    {{ $t("logistics.reset_print") }}
                  </a-menu-item>
                  <a-menu-item :key="3" :record="record" :disabled="!record.isShowCancel">
                    {{ $t("common.cancel") }}
                  </a-menu-item>
                  <a-menu-item :key="4" :record="record" :disabled="!record.isShowRefund">
                    {{ $t("account.refund") }}
                  </a-menu-item>
                  <a-menu-item :key="5" :record="record" :disabled="!record.isShowLoseMoney">
                    {{ $t("warehouse.compensation") }}
                  </a-menu-item>
                  <a-menu-item :key="7" :record="record" >
                   {{ $t("warehouse.check_the_track") }}
                  </a-menu-item>
                  <a-menu-item :key="8" :record="record"  :disabled="record.status != agentOrderStatusEnum.toBeConfirmed">
                   确认订单
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>
      <CPinTipsModal
        v-model:visible="pinModalState.visible"
        v-model:loading="pinModalState.loading"
        @confirm="handlePinModalConfirm"
      />
      <LoseMoneyModal ref="loseMoneyModalRef" @confirm="handleShowNextModal" />

       <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("logistics.consignment_order_no") }}
        </template>
        <template v-slot:no>
          {{trackModal.orderNo}}
        </template>
      </CTrackInfoModal>
    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { reactive, toRefs, onMounted, ref, } from "vue";
import {
  Row, Col, Popconfirm,
  Table, Input, Tag,
  Select, Button, Form, message, DatePicker, Dropdown, Menu, Modal, Space
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue"
import CSearchShop from "@/views/components/CSearchShop.vue"
import CFileDisplay from "@/views/components/CFileDisplay.vue"
import LoseMoneyModal from '../components/LoseMoneyModal.vue'
import {
  getOrderList,
  createWavesTask,
  createShipment,
  cancelOrder,
  refundOrder,
  loseMoneyOrder,
  exportAgentOrder,
  exportAgentOrderSku,
} from "@/api/modules/warehouseMonitor/order";
import { getWarehouses } from "@/api/modules/common/index";
import { getCountrys } from "@/api/modules/common/index";
import { getName, getAddressByLanguageV2, currentTimeToUtc, dateString } from "@/utils/general";
import localPrint from "@/utils/localPrint";
import { downloadFile } from "@/utils/downloader";
import {
  agentOrderLogisticsType as agentOrderLogisticsTypeEnum,
  agentOrderStatus as agentOrderStatusEnum,
  cancelDeliveryType as cancelDeliveryTypeEnum,
  agentOrderLogisticsPrintType as agentOrderLogisticsPrintTypeEnum,
  warehouseAgentOrderStatus,
  deliveryWay,
} from "@/enum/enum.json";
import SearchProduct from "../../components/SearchProduct.vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import { getTrackList ,confirmOrder } from "@/api/modules/warehouseMonitor/order";
import ProductDetails from "../components/ProductDetails.vue";

export default ({
  name: "warehouse_monitor_consignment_order_task",
  components: {
    ProductDetails,
    SearchProduct,
    ARow: Row,
    CTrackInfoModal,
     ACol: Col,
    ATable: Table,
    AInput: Input,
    APopconfirm: Popconfirm,
    AInputSearch: Input.Search,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ARangePicker: DatePicker.RangePicker,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ASpace: Space,
    Content,
    CPager,
    CSearchShop,
    CFileDisplay,
    CPinTipsModal,
    LoseMoneyModal,
  },
  setup () {

    const refPage = ref();
    const loseMoneyModalRef = ref(null);
    const refSearchProduct = ref(null);
    const { getters } = useStore();
    const i18n = useI18n({ useScope: "global" });

    //#region columns
    const columns = [
      {
        fixed: "left",
        slots: {
          title: "orderNo",
          customRender: "orderNoCustom"
        },
        width: 170,
      },
      {
        title: () => i18n.t("warehouse.customer"),
        fixed: "left",
        slots: {
          customRender: "customer"
        },
        width: 170,
      },
      {
        dataIndex: "consignmentWarehouse",
        title: i18n.t("warehouse.consignment_warehouse"),
        width: 120,
      },
      {
        width: 130,
        title: () => i18n.t("menu.products"),
        slots: {
          customRender: "product"
        },
      },
      {
        title: i18n.t("logistics.recipients"),
        slots: {
          customRender: "recipientsCustom"
        },
        width: 100,
      },
      {
        title: i18n.t("logistics.destination_address"),
        slots: {
          customRender: "destinationAddressCustom"
        },
        width: 200,
      },
      {
        slots: {
          title: "transportSchemeAndLogisticsTrackNo",
          customRender: "transportSchemeAndLogisticsTrackNoCustom"
        },
        width: 120,
      },
         {
        width: 200,
        title: () => i18n.t("warehouse.turn_single_track"),
        slots: {
          customRender: "track",
        },
      },
      {
        title: i18n.t("logistics.delivery_way"),
        slots: {
          customRender: "deliveryMethod"
        },
        width: 100,
      },
      {
        title: i18n.t("warehouse.deal_with_wave_number"),
        slots: {
          customRender: "waveNoCustom"
        },
        width: 120,
      },
      {
        title: i18n.t("warehouse.warehouse_operation_status"),
        slots: {
          customRender: "warehouseStatus"
        },
        width: 100,
      },
      {
        title: i18n.t("warehouse.total_cost"),
        slots: {
          customRender: "totalAmount"
        },
        width: 80,
      },
      {
        title: i18n.t("warehouse.order_status"),
        slots: {
          customRender: "statusCustom"
        },
        width: 170,
      },
      {
        width: 140,
        slots: {
          title: "updateTimeTitle",
          customRender: "updateTimeCustom"
        },
      },
      {
        fixed: "right",
        title: i18n.t("common.operate"),
        slots: {
          customRender: "operateCustom",
        },
        width: 120,
      },
    ];
    //#endregion
    const state = reactive({
      listLoading: false,
      wavesTaskLoading: false,
      countrys: [],
      warehouseAgentOrderStatus: warehouseAgentOrderStatus,
      agentOrderStatusEnum,
      sourceSales: [],
      deliveryWay: deliveryWay,
      pinModalState: {
        visible: false,
        loading: false,
        // 3: 取消; 4: 退款; 5: 赔付;5: 确认订单; 999: 导出
        type: 3,
        dataTemp: null,
        loseMoney: null,
      },
      refundAndCompensationStatusList: [
        {
          label: 'common.yes',
          value: 1
        },
        {
          label: 'common.no',
          value: 0
        },
      ]
    });

    const data = reactive({
      agentOrderLogisticsTypeEnum,
      selectedWarehouseStatus: null,
      status: null,//订单状态
      selectedCountrys: null,
      selectedConsignmentNo: null,
      logisticsType: null,
      surfacePrintType: null,
      selectedDeliveryWay: null,
      isRefund: null,
      isLoseMoney: null,
      hasException: null,
      isTimeout: null,
      //selectedSourceSales: null,
      searchKey: "",
      orderNoes: [],
      orderList: [],
      dataRange: [],
      shopId: null,
      warehousesCodes: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });

    const handleSelectValueChange = (key) => {
      let value = data[key];
      if (Array.isArray(value)) {
        data[key] = value.map(item => {
          item = item.trim();
          return item;
        });
      }
    }

    const handlePrint = async (url) => {
      try {
        if (url) {
          await localPrint(url)
        }
      } catch (error) {
        
      }
    }

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    }

    const handleChangeStatus = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleSearch();
    }

    const handleWavesTask = async () => {
      state.wavesTaskLoading = true;
      createWavesTask().then(() => {
        handleSearch();
        message.success(i18n.t("common.succeed"));
        state.wavesTaskLoading = false;
      }).catch(() => {
        state.wavesTaskLoading = false;
      })

    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const getSearchData = () => {
      let beginTime, endTime;
      if (data.dataRange.length > 0) {
        beginTime = data.dataRange[0].utc().format();
        endTime = data.dataRange[1].utc().format();
        beginTime = currentTimeToUtc(data.dataRange[0]?.format('YYYY-MM-DD 00:00:00'));
        endTime = currentTimeToUtc(data.dataRange[1]?.format('YYYY-MM-DD 00:00:00'));
      }
      return {
        "surfacePrintType":data.surfacePrintType,
        "purposeCountryId": data.selectedCountrys,
        "warehouseId": data.selectedConsignmentNo,
        //"saleSourceName": data.selectedSourceSales,
        "status": data.status,
        "warehouseStatus": data.selectedWarehouseStatus,
        "deliveryMethod": data.selectedDeliveryWay,
        "beginTime": beginTime,
        "endTime": endTime,
        isTimeout: data.isTimeout ,
        "searchKey": data.searchKey,
        "orderNoes": data.orderNoes,
        "shopId": data.shopId,
        "isRefund": data.isRefund === 1 ? true : data.isRefund === 0 ? false : null,
        "isLoseMoney": data.isLoseMoney === 1 ? true : data.isLoseMoney === 0 ? false : null,
        "hasException": data.hasException === 1 ? true : data.hasException === 0 ? false : null,
        "logisticsType": data.logisticsType,
        productId: refSearchProduct.value.selectedProductId,
      }
    }

    const handleSearch = async () => {
      const searchData = Object.assign({}, data.pageData, getSearchData())

      state.listLoading = true;
      getOrderList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.orderList = items.map(x => {
              return {
                key: x.id,
                id: x.id,
                orderNo: x.orderNo,
                shopName: x.shopName,
                shopId: x.shopId,
                shopNo: x.shopNo,
                surfacePrintType: x.surfacePrintType,
                shopMark: x.shopMark,
                operateTimeOutSeconds: x.operateTimeOutSeconds,
                relationOrderNo: x.relationOrderNo,
                consignmentWarehouse: x.warehouseNo,
                recipientsName: x.receiptUserName,
                recipientsNumber: x.contactPhone,
                destinationAddress: x.receiveAddressData,
                transportScheme: x.transportScheme,
                LogisticsTrackNo: x.logisticsTrackNo,
                logisticsType: x.logisticsType,
                surfaceUrl: x.surfaceUrl?.length > 0 ? x.surfaceUrl[0] : null,
                //disposeAmount: x.currencySymbol + x.processTotalAmount,
                // transportCharge: x.currencySymbol + x.freightTotal,
                waveNo: x.waveNo,
                status: x.status,
                track: x.track,
                products: x.products,
                warehouseStatus: x.warehouseStatus,
                totalAmount: x.totalAmount,
                currencySymbol: x.currencySymbol,
                creationTime: x.createTime,
                updateTime: x.updateTime,
                //是否能取消发货
                isShowApplyCancelDelivery: x.isShowApplyCancelDelivery,
                //是否 申请了 取消发货
                isApplyCancelDelivery: x.isApplyCancelDelivery,
                //点了取消发货的结果：（null 处理中，true 取消发货-成功 false 取消发货-失败）
                cancelDeliveryType: x.cancelDeliveryType,
                // 是否显示错误信息
                isShowSurfaceErrorMsg: x.isShowSurfaceErrorMsg,
                // 错误信息
                surfaceErrorMsg: x.surfaceErrorMsg,
                // 打印物流面单地址
                logisticsTrackLabelUrl: x.logisticsTrackLabelUrl,
                isShowCancel: x.isShowCancel,
                isShowRefund: x.isShowRefund,
                isShowLoseMoney: x.isShowLoseMoney,
                refundMoney: x.refundMoney,
                loseMoney: x.loseMoney,
                // 派送方式
                deliveryMethod: x.deliveryMethod,
              }
            })
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
          }
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        });
    }

    const handleGetCountrys = async () => {
      getCountrys().then((res) => {
        state.countrys = res.result;
      })
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const funcWarehouses = () => {
      getWarehouses({ warehouseType: '2' }).then((res) => {
        if (res.result) {
          data.warehousesCodes = res.result;
        }
      }).finally()
    };

    const handleMenuClick = ({ key, item }) => {
      let { record } = item;
      switch (key) {
        case 1:
          handleShowErrorMessage(record)
          break;
        case 2:
          handlePrint(record.logisticsTrackLabelUrl)
          break;
        case 3:
        case 4:
          // 取消&退款
          state.pinModalState.visible = true
          state.pinModalState.type = key
          state.pinModalState.dataTemp = record.key
          break;
        case 5:
          // 赔偿
          state.pinModalState.loseMoney = null
          state.pinModalState.type = key
          state.pinModalState.dataTemp = record.key
          loseMoneyModalRef.value.open(record.currencySymbol)
          break;
        case 6:
          handleCreateShipment(record.key);
          break;
        case 7:
          handleShowTrackModal(record);
          break;
        case 8:
          // 确认订单
          state.pinModalState.visible = true
          state.pinModalState.type = key
          state.pinModalState.dataTemp = record
          break;
        default:
          break;
      }
    };

    const trackModal = reactive({
      visible:false,
      loading: false,
      list: [],
      orderId:null,
      orderNo:null
    })

    
    const handleShowTrackModal = (record) => {
      trackModal.visible = true;
      trackModal.orderId = record.id;
      trackModal.orderNo = record.orderNo;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    const handleShowErrorMessage = (record) => {
      Modal.error({
        title: () => i18n.t('warehouse.error_message'),
        content: () => record.surfaceErrorMsg ?? ''
      })
    }


    const handleCreateShipment = async (orderId) => {
      try {
        await createShipment({ orderId })
        message.success(i18n.t("common.succeed"));
        handleSearch();
      } catch (error) {
      }
    }

    // 取消订单
    const handleCancelOrder = (data) => {
      cancelOrder(data).then(() => {
        handleSearch()
        message.success(i18n.t("common.succeed"))
        state.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    // 退款
    const handleRefundOrder = (data) => {
      refundOrder(data).then(() => {
        handleSearch()
        message.success(i18n.t("common.succeed"))
        state.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    // 赔偿
    const handleLoseOrderMoney = (data) => {
      loseMoneyOrder(data).then(() => {
        handleSearch()
        message.success(i18n.t("common.succeed"))
        state.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handleShowNextModal = (loseMoney) => {
      state.pinModalState.loseMoney = loseMoney
      state.pinModalState.visible = true
    }


    //确认订单
    const handleConfirmOrder=(pinCode)=>{
      confirmOrder({
        id:state.pinModalState.dataTemp.id,
        pinCode:pinCode
      }).then(() => {
        handleSearch()
        message.success(i18n.t("common.succeed"))
        state.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }


    // 导出
    const handleExport = (type = 999) => {
      state.pinModalState.visible = true
      // 998: 导出产品列表; 999: 导出订单列表
      state.pinModalState.type = type
      state.pinModalState.dataTemp = null
    }

    // 下载导出的订单表格
    const handleDownloadExcel = (pinCode) => {
      let url = exportAgentOrder();
      const postData = Object.assign({}, getSearchData(), { pinCode })
      downloadFile(url, `order_task_${dateString()}.csv`, "POST", postData).then(()=>{
        state.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    // 下载导出的产品表格
    const handleDownloadProductExcel = (pinCode) => {
      let url = exportAgentOrderSku();
      const postData = Object.assign({}, getSearchData(), { pinCode })
      downloadFile(url, `order_task_product_${dateString()}.csv`, "POST", postData).then(()=>{
        state.pinModalState.visible = false
      }).catch(() => {}).finally(() => {
        state.pinModalState.loading = false
      })
    }

    const handlePinModalConfirm = (pinCode) => {
      const data = {
        id: state.pinModalState.dataTemp,
        pinCode
      }
      state.pinModalState.loading = true

      switch (state.pinModalState.type) {
        case 3:
          handleCancelOrder(data)
          break;
        case 4:
          handleRefundOrder(data)
          break;
        case 5:
          data.loseMoney = state.pinModalState.loseMoney
          handleLoseOrderMoney(data)
          break;
        case 8:
          handleConfirmOrder(pinCode)
          break;
        case 998:
          handleDownloadProductExcel(pinCode)
          break;
        case 999:
          handleDownloadExcel(pinCode)
          break;
        default:
          break;
      }
    }

    const formattedTime = (seconds) => {
      const totalSeconds = seconds;  
      const days = Math.floor(totalSeconds / (24 * 60 * 60));  
      const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));  
      const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);  
    
      let formatted = '';  
      if (days > 0) {  
        formatted += `${days}${i18n.t("common.days")}`;  
      }  
      if (hours > 0) {  
        formatted += `${hours}${i18n.t("common.hour")}`;  
      }  
      if (minutes > 0 || totalSeconds % 60 > 0) {  
        formatted += `${minutes + (totalSeconds % 60 > 0 ? 1 : 0)}${i18n.t("common.minute")}`;
      }  
      return formatted.trimEnd('-');  
    }; 

    onMounted(async () => {
      handleSearch();
      handleGetCountrys();
      funcWarehouses();
    })

    return {
      columns,
      refPage,
      loseMoneyModalRef,
      refSearchProduct,
      ...toRefs(state),
      ...toRefs(data),
      cancelDeliveryTypeEnum,
      agentOrderLogisticsPrintTypeEnum,

      handleSelectValueChange,
      handlePage,
      handleSearch,
      handleWavesTask,
      getLanguageName,
      getAddress,
      handleChangeStatus,
      handleMenuClick,
      handleShowErrorMessage,
      handlePinModalConfirm,
      handleShowNextModal,
      handleExport,
      trackModal,
      formattedTime
    };
  }
})
</script>
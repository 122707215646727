<template>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="formState.id ? $t('common.edit') : $t('common.create')"
    :centered="true"
    :mask-closable="false"
    :closable="false"
    :keyboard="false"
    :confirm-loading="confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-item :label="$t('warehouse.name')" name="title">
          <a-input v-model:value="formState.title"></a-input>
        </a-form-item>
        <a-form-item :label="$t('finance.type')" name="announceType">
          <a-select v-model:value="formState.announceType">
            <a-select-option
              v-for="(item, index) in announceTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('announceType', item))"
            >{{ $t($enumLangkey('announceType', item)) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('devops.release_time')" name="sendTime">
          <a-date-picker v-model:value="formState.sendTime" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" style="width: 100%;"></a-date-picker>
        </a-form-item>
        <a-form-item :label="$t('finance.status')" name="status">
          <a-radio-group v-model:value="formState.status">
            <a-radio-button
              v-for="(item, index) in announceStatusEnum"
              :key="index"
              :value="item"
            >{{ $t($enumLangkey('announceStatus', item)) }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="$t('devops.overview')" name="digest">
          <a-textarea v-model:value="formState.digest" :autoSize="{ minRows: 3, maxRows: 6 }"></a-textarea>
        </a-form-item>
        <a-form-item :label="$t('devops.content')" name="content">
          <QuillEditor
            ref="quillEditorRef"
            style="min-height: 200px;"
            content-type="html"
            v-model:content="formState.content"
            :options="editorOptions"
            @update:content="handleValidate('content')"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, toRefs, watch } from 'vue';
import { DatePicker, Form, Input, Modal, Radio, Select, Spin, message } from 'ant-design-vue';
import moment from "moment-timezone";
import { useI18n } from "vue-i18n/index";
import { useStore } from 'vuex';
import { setFormStateValue, currentTimeToUtc } from '@/utils/general.js';
import { getAnnounce, createAnnounce, updateAnnounce } from '@/api/modules/devops/announcement.js';
import { announceType as announceTypeEnum, announceStatus as announceStatusEnum } from '@/enum/enum.json';
import { QuillEditor, Quill } from '@vueup/vue-quill';
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

Quill.register('modules/blotFormatter', BlotFormatter);

export default defineComponent({
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADatePicker: DatePicker,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    ATextarea: Input.TextArea,
    QuillEditor,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: String,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    let userInfo = getters.userInfo;

    const formRef = ref(null);
    const quillEditorRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        id: null,
        title: null,
        announceType: null,
        sendTime: null,
        status: announceStatusEnum.release,
        digest: null,
        content: null,
      },
      editorOptions: {
        modules: {
          blotFormatter: {
            toolbar: {
              mainClassName: 'blot-formatter__toolbar'
            }
          },
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'font': [] }],
              ['clean'],
              [{ 'align': [] }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'header': 1 }, { 'header': 2 }],
              ['link', 'image'],
              [{ 'direction': 'rtl' }],
              [{ 'color': [] }],
              [{ 'background': [] }]
            ],
          }
        },
        theme: 'snow',
      }
    })

    const rules = {
      title: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.name')]),
      },
      announceType: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('finance.type')]),
      },
      sendTime: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.release_time')]),
      },
      status: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('finance.status')]),
      },
      digest: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.overview')]),
      },
      content: {
        required: true,
        message: () => vueI18n.t('common.p0_is_required', [vueI18n.t('devops.overview')]),
      },
    }

    const handleValidate = (name) => {
      formRef.value.validate([name]);
    }

    const getDetail = () => {
      state.loading = true;
      getAnnounce({ id: state.formState.id }).then(({ result }) => {
        if (result) {
          setFormStateValue(state.formState, result);
          state.formState.sendTime = moment.tz(result.sendTime, userInfo.timezone);
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    watch(() => [props.visible, props.id], ([visible, id]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          state.formState = {
            id: null,
            title: null,
            announceType: null,
            sendTime: null,
            status: announceStatusEnum.release,
            digest: null,
            content: '',
          }
          nextTick(() => {
            quillEditorRef.value.setHTML('');
            formRef.value.resetFields();
            if (!!id) {
              state.formState.id = id;
              getDetail();
            }
          })
        }
      }
    })

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        data.sendTime = currentTimeToUtc(
          data.sendTime?.format("YYYY-MM-DD HH:mm:00"),
          userInfo.timezone,
        );
        if (data.id) {
          await updateAnnounce(data);
        } else {
          delete data.id;
          await createAnnounce(data);
        }
        message.success(vueI18n.t('common.succeed'));
        emit('confirm');
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
      state.visible = false;
    }


    return {
      ...toRefs(state),
      announceTypeEnum,
      announceStatusEnum,
      formRef,
      quillEditorRef,
      rules,
      handleValidate,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>
<template>
  <Content>
    <template v-slot:contentBody>
      <a-row :gutter="16">
        <a-col :xs="24"
               :lg="8"
               :xl="6"
               class="pt-1 pb-1">
          <StaffLeft @changeDepartment="changeDepartment" @selectDepartment="selectDepartment"></StaffLeft>
        </a-col>

        <a-col :xs="24"
               :lg="16"
               :xl="18"
               class="pt-1 pb-1">
          <StaffRight :departments="departments" :selectdepartmentId="selectdepartmentId"></StaffRight>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>
<script>

import {
  Row,
  Col,
} from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue'
import StaffLeft from './components/StaffLeft.vue'
import StaffRight from './components/StaffRight.vue'
import {
  reactive,
  ref
} from 'vue';
export default ({
  name: "staff_organizational",
  components: {
    CPager,
    ARow: Row,
    ACol: Col,
    Content,
    StaffLeft,
    StaffRight,
  },
  setup () {

    const departments = reactive([])
    const selectdepartmentId = ref("0")

    const changeDepartment = (originalData) => {
      departments.splice(0, departments.length);
      departments.push.apply(departments, originalData);
    }

    const selectDepartment = (keys) => {
      selectdepartmentId.value = keys[0];
      //console.log("selectDepartment:"+selectdepartmentId.value);
    }

    return {
      departments,
      selectdepartmentId,

      changeDepartment,
      selectDepartment
    };

  }
})
</script>
<style lang="less" scoped>
</style>
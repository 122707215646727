<template>
  <Content>
    <template #contentTitle>{{ $t('menu.logistics_rebate_configuration') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input style="width: 250px" v-model:value="searchState.name" :placeholder="$t('finance.logistics_plan_name')" allow-clear></a-input>
            </a-col>
            <a-col>
              <a-select
                v-model:value="searchState.type"
                style="width: 250px"
                allow-clear
                :placeholder="$t('devops.scheme_type')"
              >
                <a-select-option 
                  v-for="(item, index) in logisticsRebateTypeEnum"
                  :key="index"
                  :value="item"
                  :title="$t($enumLangkey('logisticsRebateType', item))"
                >{{ $t($enumLangkey('logisticsRebateType', item)) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="searchState.logisticsId"
                style="width: 250px"
                optionFilterProp="searchKey"
                show-search
                allow-clear
                :loading="logisticsLoading"
                :placeholder="$t('logistics.logistics_channel')"
              >
                <a-select-option
                  v-for="item in logisticsList"
                  :key="item.id"
                  :value="item.id"
                  :searchKey="item.name + item.code"
                  :title="item.name + '(' + item.code + ')'"
                >{{ item.name }} ({{ item.code }})</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleSearch">{{ $t('common.search') }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="ghost" @click="handleOpenEditModal()">{{ $t('common.add') }}</a-button>
        </a-col>
      </a-row>

      <a-table
        class="mt-3"
        :columns="columns"
        :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
        :pagination="false"
        :rowKey="(record, index) => index"
        :data-source="tableData.tableList"
        :loading="tableData.loading"
      >
        <template #type="{ record }">
          <a-tag color="orange" v-if="record.type === logisticsRebateTypeEnum.volume">{{ $t($enumLangkey('logisticsRebateType', record.type)) }}</a-tag>
          <a-tag color="blue" v-else-if="record.type === logisticsRebateTypeEnum.weight">{{ $t($enumLangkey('logisticsRebateType', record.type)) }}</a-tag>
        </template>
        <template #volume="{ record }">
          <span v-if="record.volume">{{ cmCubicToM(record.volume) }} m<sup>3</sup></span>
          <span v-else>-</span>
        </template>
        <template #weight="{ record }">
          <span v-if="record.weight">{{ gToKg(record.weight) }} kg</span>
          <span v-else>-</span>
        </template>
        <template #ratio="{ record }">
          {{ record.ratio }} %
        </template>
        <template #items="{ record }">
          <template v-if="record.items.length > 0">
            <div>{{ record.items[0].logisticsName }}({{ record.items[0].logisticsCode }})</div>
            <a-popover v-if="record.items.length > 1">
              <template #content>
                <div class="interval-item" v-for="(item, index) in record.items" :key="index">{{ item.logisticsName }}({{ item.logisticsCode }})</div>
              </template>
              <span class="text-primary">{{ $t('common.more') }}</span>
            </a-popover>
          </template>
          <span v-else>-</span>
        </template>
        <template #isActive="{ record }">
          <a-switch
            v-model:checked="record.isActive"
            :checked-children="$t('common.enabled')"
            :un-checked-children="$t('common.disabled')"
            :loading="record.loading"
            @change="handleSwitchChange(record)"
          />
        </template>
        <template #operate="{ record }">
          <a-button @click="handleOpenEditModal(record.id)">{{ $t('common.edit') }}</a-button>
        </template>
      </a-table>

      <LogisticsRebateConfigurationEditModal
        v-model:visible="editModalState.visible"
        :id="editModalState.id"
        @refresh="handleSearch"
      />
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { Button, Col, Input, message, Popover, Row, Select, Switch, Table, Tag } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import LogisticsRebateConfigurationEditModal from "./components/LogisticsRebateConfigurationEditModal.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import { useI18n } from "vue-i18n/index";
import { cmCubicToM, gToKg } from "@/utils/general.js";
import { getLogisticsValidBasicData } from "@/api/modules/logistics/index.js";
import { getLogisticsRebateConfigListPaged, editLogisticsRebateConfigActive } from "@/api/modules/devops/logisticsRebateConfiguration.js"
import { logisticsRebateType as logisticsRebateTypeEnum } from "@/enum/enum.json";

export default defineComponent({
  name: "logistics_rebate_configuration",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ASwitch: Switch,
    ATag: Tag,
    APopover: Popover,
    LogisticsRebateConfigurationEditModal,
    CPinTipsModal,
    Content,
    CPager,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      logisticsLoading: false,
      logisticsList: [],
      searchState: {
        name: null,
        type: null,
        logisticsId: null,
      },
      searchStateCache: {},
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      editModalState: {
        visible: false,
        id: null,
      },
      pinModalState: {
        visible: false,
        loading: false,
        dataTemp: null,
      }
    })

    const columns = [
      {
        dataIndex: 'name',
        width: 150,
        title: () => vueI18n.t("devops.plan_name"),
      },
      {
        width: 120,
        title: () => vueI18n.t('devops.scheme_type'),
        slots: {
          customRender: 'type'
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('common.volume'),
        slots: {
          customRender: 'volume'
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('common.weight'),
        slots: {
          customRender: 'weight'
        }
      },
      {
        dataIndex: 'week',
        width: 120,
        title: () => vueI18n.t('devops.weeks_count'),
      },
      {
        width: 120,
        title: () => vueI18n.t('devops.discount_ratio'),
        slots: {
          customRender: 'ratio'
        }
      },
      {
        width: 300,
        title: () => vueI18n.t('logistics.logistics_channel'),
        slots: {
          customRender: 'items'
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('devops.running_state'),
        slots: {
          customRender: 'isActive'
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('common.operate'),
        slots: {
          customRender: 'operate'
        }
      },
    ]

    const handleSwitchChange = (record) => {
      record.loading = true;
      editLogisticsRebateConfigActive({ id: record.id, isActive: record.isActive }).then(() => {
        message.success(vueI18n.t("common.succeed"));
      }).catch(() => {
        record.isActive = !record.isActive;
      }).finally(() => {
        record.loading = false;
      });
    }

    const handleOpenEditModal = (id = null) => {
      state.editModalState.id = id;
      state.editModalState.visible = true;
    }

		const getPageList = () => {
			state.tableData.loading = true;
      const data = Object.assign({}, state.searchStateCache, state.tableData.pageData);
			getLogisticsRebateConfigListPaged(data).then(({ result }) => {
				if (result) {
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
				} else {
					state.tableData.tableList = [];
				}
			}).catch(() => { }).finally(() => {
				state.tableData.loading = false;
			})
		}

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    }

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    }

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitSearch();
    }

    const getLogisticsList = () => {
      state.logisticsLoading = true;
      getLogisticsValidBasicData().then(({ result }) => {
        if (Array.isArray(result)) {
          state.logisticsList = result;
        } else {
          state.logisticsList = [];
        }
      }).catch(() => {
      }).finally(() => {
        state.logisticsLoading = false;
      });
    }

    onMounted(() => {
      getLogisticsList();
      handleInitSearch();
    });

    return {
      ...toRefs(state),
      columns,
      logisticsRebateTypeEnum,
      cmCubicToM,
      gToKg,
      handleSwitchChange,
      handleOpenEditModal,
      handlePage,
      handleSearch,
    }
  }
})
</script>

<style lang="less" scoped>
.interval-item:not(:last-of-type)::after {
  content: ",";
}
</style>
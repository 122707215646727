<template>
  <div>
    <a-auto-complete v-model:value="searchKey"
                     style="width: 250px"
                     :allowClear="true"
                     @search="handleSearch"
                     @select="handleSelect"
                     @blur="handleCheckSearchKey"
                     @focus="handleFocus"
                     @change="handleChange">
      <template #default>
        <a-input :placeholder="$t('account.company_name') + '/NO/' + $t('common.shop_mark')"></a-input>
      </template>
      <template #options>
        <a-select-option v-for="item in list"
                         :key="item.id"
                         :disabled="!item?.id"
                         :data="item"
                         :value='`${item.name}(${item.no})`'>
          <a-empty v-if="!item?.id" :image="simpleImage" />
          <div v-else>
          {{item.name}}
          <div v-if="item.no">
            NO: {{item.no}}
          </div>
          <div v-if="item.shopMark">
            {{$t('common.shop_mark')}}: {{item.shopMark}}
          </div>
          </div>
        </a-select-option>
      </template>
    </a-auto-complete>
  </div>
</template>

<script>
import { reactive, toRefs, } from "vue";
import {
  Row, Col, AutoComplete, Input,
  Select,Empty
} from "ant-design-vue";
import { getShopInfo } from "../../api/modules/common/index";
import { debounce } from "../../utils/general";

export default ({
  name: "CSearchShop",
  components: {
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    AAutoComplete: AutoComplete,
    AInput: Input,
    AEmpty:Empty,
    emits: ["selectedData"],
    emits: ["shopId"],
  },
  setup (props, { emit }) {
    const state = reactive({
      list: [],
    })

    const data = reactive({
      oldSearchKey: null,
      searchKey: null,
      selectedId: null,
    });

    const funcGetShopInfo = () => {
      if (!data.searchKey || data.searchKey?.trim()?.length == 0) {
        return
      }

      data.selectedId = null;
      let searchData = {
        "searchKey": data.searchKey,
      };
      getShopInfo(searchData).then((res) => {
        if (res.result && res?.result?.length > 0) {
            state.list = res.result?.map((x) => {
            return {
              name: x.text,
              id: x.value,
              shopMark: x.text3,
              no: x.text1,
            }
          });
        } else {
         state.list = [{id:null}];
        }
        data.oldSearchKey = data.searchKey;
      });
    }

    const handleFocus = () => {
      state.list = [];
    };

    const handleSearch = debounce(funcGetShopInfo, 500);

    const handleCheckSearchKey = async () => {
      //直接根据id判断,如果动了输入框id就为空
      if (!data.selectedId) {
        data.searchKey = null;
        data.selectedId = null;
        emit("update:selectedData", null);
        emit("update:shopId", null);
      }
    }

    const handleChange = (option) => {
      if (
        !data.searchKey
      ) {
        data.selectedId = null;
        emit("update:selectedData", null);
        emit("update:shopId", null);
      }
    }

    const handleSelect = async (value, option) => {
      data.selectedId = option.key;
      emit("update:selectedData", option.data);
      emit("update:shopId", option.key);
    }

    const clear = () => {
      state.list = [];
      data.oldSearchKey = null;
      data.searchKey = null;
      data.selectedId = null;
    }

    return {
      ...toRefs(state),
      ...toRefs(data),

      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      handleFocus,
      handleSearch,
      handleCheckSearchKey,
      handleSelect,
      handleChange,
      clear,
    };
  }
})
</script>
<template>
  <div class="upload-container">
    <a-row type="flex"
           justify="start"
           align="middle">
      <!-- 显示的图片样式 -->
      <template v-for="item in showLableList"
                :key="item.imgUrl">
        <!-- <a-col v-if="!item.isDelete&&!item.isDefaultLabel"  隐藏默认的标签就用这行代码，并删除下面一行代码-->
        <a-col v-if="!item.isDelete"
               flex="0 0 300px"
               class="mr-3 mb-3 label-item">
          <a-row type="flex"
                 justify="center">
            <a-col class="mt-3 lable-name"
                   :span="24"
                   style="margin:auto;text-align:center;">
              <a-typography-paragraph v-model:content="item.name"
                                      class="mb-0"
                                      :ellipsis="true"
                                      :editable="!item.isDefaultLabel" />
            </a-col>
            <a-col :span="24">
              <a-typography-paragraph :ellipsis="{ tooltip: '01234567890123456789' }"
                                      style="width:280px;margin:auto;text-align:center;"
                                      class="mb-0"
                                      :content="`${$t('warehouse.can_identification_content')}: ${item.code}`" />
            </a-col>
            <a-col>
              <div class="customer-upload-label-component">
                <!-- <a-image :src="item.imgUrl" /> -->
                <CFileDisplay :fileUrl="item.imgUrl"></CFileDisplay>
              </div>
            </a-col>
            <a-tooltip placement="right"
                       v-if="!item.isDefaultLabel">
              <template #title>{{$t('common.delete')}}</template>
              <span class="delete-label"
                    @click="deleteLabel(item.key)">
                <DeleteOutlined />
              </span>
            </a-tooltip>
          </a-row>
        </a-col>
      </template>

      <!-- 上传组件 -->
      <a-col flex="0 0 300px"
             class="mr-3 mb-3 upload-button-wrapper">
        <a-upload name="avatar"
                  list-type="picture-card"
                   accept=" .jpeg,.jpg,.png,.pdf"
                  :customRequest="handleUpdate"
                  :showUploadList="false"
                  class="avatar-uploader">
          <div class="upload-button">
            <a-row type="flex"
                   justify="center"
                   align="middle">
              <a-col :span="24">
                <loading-outlined v-if="uploadLoading"></loading-outlined>
                <PlusOutlined v-else
                              class="upload-button-svg" />
              </a-col>
              <a-col :span="24">{{$t('common.upload')}}</a-col>
            </a-row>
          </div>
        </a-upload>

      </a-col>
    </a-row>

  </div>
</template>
<script>
import {
  Button, Col, Row, Image, Typography, Upload, Tooltip, message
} from "ant-design-vue";
import { reactive, toRefs } from 'vue';
import { uploadFile } from "../../../api/modules/common/index";
import CFileDisplay from "../../components/CFileDisplay.vue";
import { useI18n } from "vue-i18n/index";

export default ({
  name: "uploadLabel",
  components: {
    AButton: Button, ACol: Col, ATooltip: Tooltip,
    ARow: Row, AImage: Image, AUpload: Upload,
    ATypographyParagraph: Typography.Paragraph,
    CFileDisplay
  },

  setup () {
    const state = reactive({
      uploadLoading: false
    });

    const data = reactive({
      showLableList: [],
      key: 0
    })

    const i18n = useI18n({ useScope: "global" });
    const handleUpdate = async (file) => {
      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      //1是产品图片 2标签图片
      parameter.append("functionModule", 2);
      uploadFile(parameter).then((res) => {
        if (res.result && res.result.length > 0) {
          if (!res.result[0].parsingCode) {
            message.error(
              i18n.t("warehouse.the_code_cannot_be_recognized_please_change_the_file"));
          } else {
            data.key++;
            let fileItem = {
              key: data.key,
              name: res.result[0].fileName,
              imgUrl: res.result[0].url,
              isDelete: false,
              code: res.result[0].parsingCode,
            }
            data.showLableList.push(fileItem);
          }
        }
        state.uploadLoading = false;
      }).catch(() => {
        state.uploadLoading = false;
      });
    }

    const deleteLabel = async (lableKey) => {
      // data.showLableList.find(x => x.key == lableKey).isDelete = true;
      data.showLableList = data.showLableList.filter(x => x.key != lableKey);

      //后端要判断那些是删除了的
    }

    return {
      ...toRefs(data),
      ...toRefs(state),
      handleUpdate,
      deleteLabel
    };
  },
});
</script>
<style lang="less" scoped>
.upload-container {
  margin-top: 10px;
  margin-left: 10px;
  .customer-upload-label-component {
    width: 250px;
    height: 130px;
    //border: 1px solid #d9d9d9;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    :deep(.ant-image img) {
      max-height: 88px;
    }
  }

  .label-item {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-width: 200px;
    min-height: 200px;
    position: relative;

    .lable-name {
      :deep(.ant-typography-edit-content) {
        margin-left: 25px;
        width: 270px;
      }
    }

    .delete-label {
      position: absolute;
      top: 85%;
      left: 47%;
      font-size: 15px;
      color: #d9d9d9;
      :hover {
        color: #04befe;
        //font-size: 20px;
      }
    }
  }

  .upload-button-wrapper {
    width: 300px;
    height: 200px;
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .upload-button-svg {
      font-size: 30px;
      color: #c2c0c0;
    }
    :deep(.ant-upload) {
      width: 300px !important;
      height: 200px !important;
      box-sizing: border-box;
      margin: 0;
    }
  }
}
</style>
<template>
  <a-dropdown class="locale-item" :placement="placement">
    <span v-if="hasSolt">
      <slot></slot>
    </span>
    <GlobalOutlined v-else />
    <template #overlay>
      <a-menu :selectedKeys="langData.lang_current" @click="toggleLang">
        <a-menu-item
          v-for="item in langData.langs"
          :key="item.value"
        >
          <a href="javascript:;">{{ item.label }}</a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { Dropdown, Menu } from "ant-design-vue";
import { reactive, ref, useSlots } from "vue";
import { useStore } from "vuex";
import * as i18nLocal from "@/locale/index.js";
import { extensionKeys } from "../../consts/index";

export default {
  name: "lang_chooser",
  props: {
    placement: {
      type: String,
      default: "bottomRight",
    },
  },
  components: {
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
  },
  setup() {
    const slots = useSlots();
    const hasSolt = ref(!!slots.default);
    const langData = reactive({
      langs: i18nLocal.getLangs(),
      lang_current: [localStorage.getItem(extensionKeys.confLang)],
    });
    const store = useStore();

    const toggleLang = async function ({ key }) {
      langData.lang_current = [key];
      await store.dispatch("app/setLanguage", key); //缓存语言
    };

    return {
      langData,
      toggleLang,
      hasSolt,
    };
  },
};
</script>

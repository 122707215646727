import client from "../../client"

// API管理
const Api = {
  // 列表
  getSettingPagedList: "/api/SupplierService/OpenApiManagement/GetSettingPagedList",
  // 新增API账户
  createOpenApiInterfaceSetting: "/api/SupplierService/OpenApiManagement/CreateOpenApiInterfaceSetting",
  // 修改API账户
  updateOpenApiInterfaceSetting: "/api/SupplierService/OpenApiManagement/UpdateOpenApiInterfaceSetting",
  // 详情
  getSettingById: "/api/SupplierService/OpenApiManagement/GetSettingById",
  // 启用/禁用
  updateOpenApiInterfaceSettingActive: "/api/SupplierService/OpenApiManagement/UpdateOpenApiInterfaceSettingActive",
  // 更新运行状态
  updateOpenApiInterfaceSettingRunStatus: "/api/SupplierService/OpenApiManagement/UpdateOpenApiInterfaceSettingRunStatus",
  // 获取Json格式
  getSettingJson: "/api/SupplierService/OpenApiManagement/GetSettingJson",
  // 通过Api分类获取Code
  getOpenApiCodeByOpenApiCategory: "/api/SupplierService/OpenApiManagement/GetOpenApiCodeByOpenApiCategory",
}

export const getSettingPagedList = (data) => {
  return client.request({
    url: Api.getSettingPagedList,
    data,
    method: 'POST'
  })
}

export const createOpenApiInterfaceSetting = (data) => {
  return client.request({
    url: Api.createOpenApiInterfaceSetting,
    data,
    method: 'POST'
  })
}

export const updateOpenApiInterfaceSetting = (data) => {
  return client.request({
    url: Api.updateOpenApiInterfaceSetting,
    data,
    method: 'POST'
  })
}

export const getSettingById = (data) => {
  return client.request({
    url: Api.getSettingById,
    params: data,
    method: 'GET'
  })
}

export const updateOpenApiInterfaceSettingActive = (data) => {
  return client.request({
    url: Api.updateOpenApiInterfaceSettingActive,
    data,
    method: 'POST'
  })
}

export const updateOpenApiInterfaceSettingRunStatus = (data) => {
  return client.request({
    url: Api.updateOpenApiInterfaceSettingRunStatus,
    data,
    method: 'POST'
  })
}

export const getSettingJson = (data) => {
  return client.request({
    url: Api.getSettingJson,
    params: data,
    method: 'GET'
  })
}

export const getOpenApiCodeByOpenApiCategory = (data) => {
  return client.request({
    url: Api.getOpenApiCodeByOpenApiCategory,
    params: data,
    method: 'GET'
  })
}
<template>

  <Content>
    <template #contentTitle>
      <strong>{{$t('warehouse.allot_to_storage_plan_detail')}}</strong>
    </template>
    <template #contentBody>
      <a-spin :spinning="pageData.loading">
        <a-row :gutter="[0, 8]">
          <a-col :md="16" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]">
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.plan_no')}}: {{pageData.planNo}}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{$t('warehouse.storage_in_plan_no')}}: {{pageData.inWarehousePlanNo || '-'}}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.creation_time") }}: {{ $filters.utcToCurrentTime(pageData.creationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("logistics.last_modification_time") }}: {{ $filters.utcToCurrentTime(pageData.lastModificationTime) }}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.consignment_code") }}: {{pageData.delegateWarehouseNo}}
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                {{ $t("warehouse.storage_code") }}: {{pageData.storageWarehouseNo}}
              </a-col>
              <template v-if="pageData.shopData">
                <a-col :md="12" :sm="24" :xs="24">
                  {{ $t("warehouse.customer") }}: {{ pageData.shopData.shopName }}
                </a-col>
                <a-col :md="12" :sm="24" :xs="24">
                 {{$t('common.shop_mark')}}: {{ pageData.shopData.shopMark ??"-" }}
                </a-col>
                <a-col :md="12" :sm="24" :xs="24">
                  NO: {{ pageData.shopData.shopNo  }}
                </a-col>
              </template>
            </a-row>
          </a-col>
          <a-col :md="8" :sm="24" :xs="24">
            <a-row :gutter="[0, 8]" style="height: 100%;" type="flex" align="middle">
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{$t('warehouse.plan_status')}}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <a-typography-text type="success">{{$t($enumLangkey('allotPlanStatus', pageData.status))}}</a-typography-text>
                </div>
              </a-col>
              <a-col :md="12" :sm="24" :xs="24">
                <div style="text-align: center;">
                  <a-typography-text strong>{{$t('warehouse.payment_method')}}</a-typography-text>
                </div>
                <div style="text-align: center;">
                  <a-typography-text type="success">{{$t($enumLangkey('payWay',pageData.paymentMethod))}}</a-typography-text>
                </div>
              </a-col>
              <a-col :md="24" :sm="24" :xs="24" v-if="pageData.status === allotPlanStatusEnum.planned">
                <div style="text-align: center;">
                  <a-button
                    type="ghost"
                    danger
                    @click="pageData.pinModalState.visible = true"
                  >{{ $t('common.cancel') }}</a-button>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>

        <a-table
          class="mt-3"
          :columns="columns"
          :data-source="pageData?.boxs"
          size="small"
          style="min-height: 223px"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="false"
        >
          <template #packMaterialName="{ record }">
            <span v-if="record?.materialTypeCnName">{{ record.materialTypeCnName }}</span>
            <span v-else>-</span>
          </template>

          <template #materialsType="{ record }">
            <span v-if="record?.materialTypeCnName">
            {{ record.materialTypeCnName }}/{{ record.materialTypeEnName }}
            </span>
            <span v-else>-</span>
          </template>

          <template #outerDiameterSize="{ record }">
            <span v-if="record.packMaterialLength">
                {{ record.packMaterialLength }}x{{ record.packMaterialWidth }}x{{
                  record.packMaterialHeight
                }}
                cm
            </span>
            <span v-if="record?.productDatas[0]?.packingLength && !record.packMaterialLength">
                {{ record.productDatas[0].packingLength }}x{{ record.productDatas[0].packingWidth }}x{{
                  record.productDatas[0].packingHeight
                }}
                cm
              </span>
          </template>

          <template #weight="{ record }">
            <span v-if="record.packMaterialWeight">
              {{ gToKg(record.packMaterialWeight) }} kg
            </span>
            <span v-else> - </span>
          </template>

          <template #price="{ record }">
            <span v-if="record.currencySymbol">
            {{ record.currencySymbol }}
            {{ $filters.amountToFixed2(record.price) }}
            </span>
            <span v-else> - </span>
          </template>

          <template #totalWeight="{ record }">
            <span v-if="record.packMaterialTotalWeight">
              {{ gToKg(record.packMaterialTotalWeight) }} kg
            </span>
            <span v-else>-</span>
          </template>

          <template #footer v-if="pageData.totalBox > 0">
            <a-row :gutter="[64, 8]">
              <a-col
                >{{ $t("common.total") }}{{ pageData.totalBox
                }}{{ $t("warehouse.box") }}</a-col
              >
              <a-col>{{
                $t("common.p0_products", [pageData.totalProduct])
              }}</a-col>
              <a-col
                >{{ $t("common.total") }}{{ pageData.totalUnit }} Unit</a-col
              >
            </a-row>
          </template>

          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.productDatas"
              :pagination="false"
              :row-key="(record, index) => index"
              size="small"
            >
              <template #productInfo="{ record }">
                <a-space>
                  <div class="table-list-img-common">
                    <c-image :src="record.productImgUrl"></c-image>
                  </div>
                  <div>
                    <div>{{ record.productName }}</div>
                    <div>{{ record.productNo }}</div>
                  </div>
                </a-space>
              </template>

              <template #product_size="{ record }">
                <span v-if="record?.packingLength">
                  {{ record?.packingLength }}x{{ record?.packingWidth }}x{{
                    record?.packingHeight
                  }}
                  cm
                </span>
                <span v-else>-</span>
              </template>

              <template #weight="{ record }">
                <span v-if="record?.packingWeight">
                  {{ gToKg(record?.packingWeight) }} kg
                </span>
                <span v-else>-</span>
              </template>

              <template #encasement_count="{ record }">
                {{ record.containerCount }} Unit
              </template>

              <template #product_label="{ record }">
                <span v-if="record.label">
                  {{ $t("warehouse.label_service") }}:
                  {{ record?.label?.name }}
                </span>
                <span v-else>
                  {{ $t("warehouse.unlabeled_service") }}
                </span>
              </template>
               <template #extra_tags="{ record }">
                <CFileDisplay v-if="record.otherLabelUrl" :fileUrl="record.otherLabelUrl" />
                <span v-else>-</span>
                </template>
            </a-table>
          </template>
        </a-table>

        <div class="mt-3">
          <div>
            {{ $t("warehouse.product_information_summary") }}
          </div>
          <a-table
            :columns="productSummaryColumns"
            :data-source="pageData.productSummary"
            :pagination="false"
            :row-key="(record, index) => index"
            size="small"
          >
            <template #productInfo="{ record }">
              <a-space>
                <div class="table-list-img-common">
                  <c-image :src="record.productImgUrl"></c-image>
                </div>
                <div>
                  <div>{{ record.productName }}</div>
                  <div>{{ record.productNo }}</div>
                </div>
              </a-space>
            </template>

            <template #product_size="{ record }">
              <span v-if="record?.packingLength">
                {{ record?.packingLength }}x{{ record?.packingWidth }}x{{ record?.packingHeight }}cm
              </span>
              <span v-else>-</span>
            </template>

            <template #weight="{ record }">
              <span v-if="record?.packingWeight">
                {{ gToKg(record?.packingWeight) }} kg
              </span>
              <span v-else>-</span>
            </template>

            <template #current_warehouse_usable_inventory="{ record }">
              {{ record?.availableStock }} Unit
            </template>

            <template #encasement_count="{ record }">
              {{ record.transferQuantity }} Unit
            </template>
          </a-table>
        </div>

        <!-- 费用明细 -->
        <div class="mt-4" v-if="pageData.fee.totalFee">
          <CFee :fees="pageData.fee.fees" :totalFee="pageData.fee.totalFee"></CFee>
        </div>

        <div class="mr-5 mb-5 mt-5">
          <a-row type="flex" justify="end">
            <a-col class="ml-3">
              <a-button @click="handleBack">{{$t('common.backtrack')}}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>

      <ProductStatisticsModal ref="productStatisticsModalRef" />
      <CPinTipsModal
        v-model:visible="pageData.pinModalState.visible"
        v-model:loading="pageData.pinModalState.loading"
        @confirm="handleCancel"
      />
    </template>
  </Content>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import {
  Row, Col, Spin, Table, Button, Form, Space, Typography, message
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CImage from "../../components/CImage.vue"
import CFileDisplay from "../../components/CFileDisplay.vue"
import ProductStatisticsModal from "../components/ProductStatisticsModal.vue"
import CPinTipsModal from '@/views/components/CPinTipsModal'
import CFee from "../../components/CFee.vue";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { useTab } from "../../../hooks/tabs/index";
import { getName, gToKg } from "../../../utils/general"
import { getDetails, TransferToWarehousePlanCancel } from "../../../api/modules/warehouseMonitor/allot";
import { allotPlanStatus as allotPlanStatusEnum } from '@/enum/enum.json'

export default ({
  name: "consignment_allot_plan_details",
  components: {
    ARow: Row,CFee,CFileDisplay,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    ASpin: Spin,
    ASpace: Space,
    ATypographyText: Typography.Text,
    CImage,
    Content,
    CPager,
    ProductStatisticsModal,
    CPinTipsModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();
    const productStatisticsModalRef = ref();

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        dataIndex: "boxNo",
        width: 150,
        title: () => vueI18n.t("warehouse.box_number"),
      },
      {
        title: () => vueI18n.t("warehouse.packaging_material_name"),
        dataIndex: "packMaterialName",
        slots: {
          customRender: "packMaterialName",
        },
        width: 200,
      },
      {
        title: () => vueI18n.t("warehouse.materials"),
        slots: {
          customRender: "materialsType",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.outer_diameter_size"),
        slots: {
          customRender: "outerDiameterSize",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("common.weight"),
        slots: {
          customRender: "weight",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.material_fee"),
        slots: {
          customRender: "price",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.total_weight_after_packing"),
        slots: {
          customRender: "totalWeight",
        },
        width: 100,
      },
    ];

    const innerColumns = [
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 80,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 130,
      },
      {
        title: () => vueI18n.t("warehouse.product_of_size"),
        slots: {
          customRender: "product_size",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("common.weight"),
        slots: {
          customRender: "weight",
        },
        width: 70,
      },
      {
        title: () => vueI18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "encasement_count",
        },
        width: 100,
      },
      // {
      //   title: () => vueI18n.t("warehouse.volume"),
      //   slots: {
      //     customRender: "volume",
      //   },
      //   width: 100,
      // },
      {
        title: () => vueI18n.t("warehouse.product_label"),
        slots: {
          customRender: "product_label",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.extra_tags"),
        slots: {
          customRender: "extra_tags",
        },
        width: 100,
      },
    ];

    const productSummaryColumns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 80,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 130,
      },
      {
        title: () => vueI18n.t("warehouse.product_of_size"),
        slots: {
          customRender: "product_size",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("common.weight"),
        slots: {
          customRender: "weight",
        },
        width: 70,
      },
      {
        title: () => vueI18n.t("warehouse.current_warehouse_usable_inventory"),
        slots: {
          customRender: "current_warehouse_usable_inventory",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.allot_amount"),
        slots: {
          customRender: "encasement_count",
        },
        width: 100,
      },
    ];

    const pageData = reactive({
      planId: null,
      loading: false,

      delegateWarehouseNo: null,
      storageWarehouseNo: null,
      shopData: null,
      planNo: null,
      status: null,
      inWarehousePlanNo: null,
      paymentMethod: null,
      boxs: [],
      totalBox: null,
      totalProduct: null,
      totalUnit: null,
      totalWeight: null,
      fee: {
        fees: [],
        totalFee: null
      },

      pinModalState: {
        visible: false,
        loading: false
      }
    })

    const handleBack = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "warehouse_monitor_allot_plan" });
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const funcGetPageData = () => {
      pageData.loading = true;
      getDetails(pageData.planId)
        .then((res) => {
          let { result } = res;
          if (result) {
            Object.assign(pageData, result);
            pageData.productSummary = result.productSummary ?? [];
          }
          pageData.loading = false;
        }).catch(() => {
          pageData.loading = false;
        })
    }

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(2, route.params.id)
    }

    const handleCancel = (pinCode) => {
      pageData.pinModalState.loading = true
      TransferToWarehousePlanCancel({ id: pageData.planId, pinCode })
        .then(() => {
          message.success(vueI18n.t("common.successfully_canceled"));
          pageData.pinModalState.visible = false
          funcGetPageData();
        }).catch(() => { }).finally(() => {
          pageData.pinModalState.loading = false
        })
    }

    onMounted(async () => {
      let { id } = route.params;
      if (id) {
        pageData.planId = id;
        funcGetPageData();
      }
    });


    return {
      gToKg,

      allotPlanStatusEnum,

      columns,
      innerColumns,
      productSummaryColumns,

      pageData,
      productStatisticsModalRef,
      handleBack,
      getLanguageName,
      handleShowProductStatisticsModal,
      handleCancel,
    };
  }
})
</script>

<style lang="less" scoped>
</style>
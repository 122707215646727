<template>
  <Content>
    <template #contentTitle>{{ $t("devops.edit_configuration") }}</template>
    <template #contentBody>
      <a-form
        ref="formRef"
        class="mt-5"
        style="max-width: 600px"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item :label="$t('devops.currency')" name="currencyId">
          <a-select
            v-model:value="formState.currencyId"
            :placeholder="$t('common.please_select')"
            optionFilterProp="search-key"
            show-search
          >
            <a-select-option
              v-for="(item, index) in currencyList"
              :key="item.id"
              :value="item.id"
              :title="getCurrencyName(item)"
              :search-key="item.cnName + item.enName + item.code + item.symbol"
              >{{ getCurrencyName(item) }}</a-select-option
            >
          </a-select>
        </a-form-item>
       
        <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-space :size="50">
            <a-button @click="handleCancel">{{ $t("common.cancel") }}</a-button>
            <a-button type="primary" :loading="loading" @click="handleSubmit">{{
              $t("common.save")
            }}</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  nextTick,
} from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Select, Button, Form, Switch, Space, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import { useI18n } from "vue-i18n/index";
import { useTab } from "@/hooks/tabs/index";
import { getCurrencys } from "@/api/modules/common/index.js";
import {
  getCurrencyConfigRelationById,
  createCurrencyConfigRelation,
  updateCurrencyConfigRelation,
} from "@/api/modules/devops/currencyConfiguration.js";

export default defineComponent({
  name: "dev_currency_configuration_edit",
  components: {
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    ASpace: Space,
    Content,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const formRef = ref(null);

    const state = reactive({
      loading: false,
      formState: {
        id: null,
        currencyId: null,
        isSupportPayOnArrival: false,
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      currencyList: [],
    });

    const rules = {
      currencyId: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [vueI18n.t("devops.currency")]),
      },
      isSupportPayOnArrival: {
        required: true,
        message: () =>
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("devops.support_logistics_to_pay"),
          ]),
      },
    };

    const handleCancel = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "dev_currency_configuration_list" });
    };

    const handleSubmit = async () => {
      try {
        await formRef.value.validate();
        state.loading = true;
        const data = Object.assign({}, state.formState);
        if (data.id) {
          await updateCurrencyConfigRelation(data);
        } else {
          await createCurrencyConfigRelation(data);
        }
        message.success(vueI18n.t("common.succeed"));
        handleCancel();
      } catch (error) {
      } finally {
        state.loading = false;
      }
    };

    const getCurrencyName = (item) => {
      if (getters.language === "zh-CN") {
        return `${item.cnName} ${item.code} ${item.symbol}`;
      } else {
        return `${item.enName} ${item.code} ${item.symbol}`;
      }
    };

    const getCurrencyList = () => {
      getCurrencys()
        .then(({ result }) => {
          if (result) {
            state.currencyList = result;
          }
        })
        .catch(() => {});
    };

    const getDetailInfo = (id) => {
      state.loading = true;
      getCurrencyConfigRelationById({ id })
        .then(({ result }) => {
          if (result) {
            for (const key in result) {
              if (Object.hasOwnProperty.call(state.formState, key)) {
                state.formState[key] = result[key];
              }
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(() => {
      let id = route.params?.id ?? null;
      nextTick(() => {
        formRef.value.resetFields();
        state.formState.id = id;
      });
      getCurrencyList();
      if (id) {
        getDetailInfo(id);
      }
    });

    return {
      ...toRefs(state),
      formRef,
      rules,
      getCurrencyName,
      handleCancel,
      handleSubmit,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>{{ $t('menu.products_inventory_adjustment') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <CSearchShop v-model:shopId="searchState.shopId"></CSearchShop>
        </a-col>
        <a-col>
          <a-select v-model:value="searchState.adjustmentType" :placeholder="$t('warehouse.adjustment_type')" style="width: 250px;" allow-clear>
            <a-select-option
              v-for="(item, index) in inventoryAdjustmentTypeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('inventoryAdjustmentType', item))"
            >{{ $t($enumLangkey('inventoryAdjustmentType', item)) }}</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select v-model:value="searchState.warehouseId"
            :placeholder="$t('logistics.warehouse_code')"
            :show-search="true"
            option-filter-prop="search-key"
            allow-clear
            style="width: 250px;"
          >

                <a-select-option
                  v-for="item in warehouseList"
                  :key="item.value"
                  :title="item.text1 + '('+ item.text +')'"
                  :value="item.value"
                  :search-key="item.text1 + item.text"
                >
                  {{ item.text1 + '('+ item.text +')' }}
                </a-select-option>
              </a-select>
        </a-col>
         <a-col>
              <a-range-picker format="YYYY-MM-DD"
                              style="width: 250px"
                              @change="handleConfirmDate" />
        </a-col>
        <a-col>
            <a-input 
            :placeholder="'SKU'"
              v-model:value="searchState.seSku"></a-input>
        </a-col>
        <a-col><a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button></a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: 500, y: wrap.contentHeight - 100 }"
        :pagination="false"
        :rowKey="
          (record, index) => {
            return record.id;
          }
        "
        :loading="tableData.loading"
        size="small"
      >
        <template #shop="{ record }">
          <template v-if="record.shop">
            <div>{{ record.shop.shopName }}</div>
            <div>NO: {{ record.shop.shopNo }}</div>
            <div>{{ $t('common.shop_mark') }}: {{ record.shop.shopMark }}</div>
          </template>
          <span v-else>-</span>
        </template>
        <template #adjustmentType="{ record }">
          {{ $t($enumLangkey('inventoryAdjustmentType', record.adjustmentType)) }}
        </template>
        <template #warehouseInfo="{ record }">
          <span v-if="record.warehouse">
            {{ record.warehouse.warehouseName || '-' }}
            <template v-if="record.warehouse.warehouseNo">({{ record.warehouse.warehouseNo }})</template>
          </span>
          <span v-else>-</span>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #remark="{ record }">
          <span v-if="record.remark">{{ record.remark }}</span>
          <span v-else>-</span>
        </template>
        <template #employee="{ record }">
          <CEmployeeInfo v-if="record.employee" :employee="record.employee" />
          <span v-else>-</span>
        </template>
        <template #operation="{ record }">
          <a-button type="ghost" @click="handleOpenDetailModal(record.id)">{{ $t('common.details') }}</a-button>
        </template>
      </a-table>
      <StockProductAdjustmentListModal ref="stockProductAdjustmentListModalRef" />
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue';
import { DatePicker, Button, Col, Input, Row, Select, Table } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import CSearchShop from '@/views/components/CSearchShop.vue';
import CEmployeeInfo from '@/views/components/CEmployeeInfo.vue';
import StockProductAdjustmentListModal from './components/StockProductAdjustmentListModal.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from 'vuex';
import { getOptionWarehouses } from '@/api/modules/common/index';
import { getProductInventoryAdjustmentListPaged } from '@/api/modules/products/index.js';
import { inventoryAdjustmentType as inventoryAdjustmentTypeEnum, warehouseType as warehouseTypeEnum } from '@/enum/enum.json';
import { currentTimeToUtc } from '@/utils/general';

export default defineComponent({
  name: 'products_inventory_adjustment',
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    Content,
    CPager,
    CSearchShop,
    CEmployeeInfo,
    StockProductAdjustmentListModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const stockProductAdjustmentListModalRef = ref(null);

    const state = reactive({
      searchState: {
        shopId: null,
        warehouseId: null,
        adjustmentType: null,
        isNeedShop: true,
        isNeedEmployee: true,
        isNeedWarehouse: true,
        creationTimeBegin:null,
        creationTimeEnd:null,
        seSku:null,
      },
      searchStateCache: {},
      warehouseList: [],
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        width: 150,
        title: () => vueI18n.t('warehouse.company'),
        slots: {
          customRender: "shop"
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.adjustment_type'),
        slots: {
          customRender: "adjustmentType"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.warehouse_name'),
        slots: {
          customRender: "warehouseInfo"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.creation_time'),
        slots: {
          customRender: "creationTime"
        }
      },
      {
        width: 250,
        title: () => vueI18n.t('common.remark'),
        slots: {
          customRender: "remark"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.operator'),
        slots: {
          customRender: "employee"
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.operation'),
        slots: {
          customRender: "operation"
        }
      },
    ]

    const handleOpenDetailModal = (id) => {
      stockProductAdjustmentListModalRef.value.open(id);
    }

    const getWarehouseList = () => {
      const data = {
        warehouseType: warehouseTypeEnum.consignment,
        isActive: true,
      }
      getOptionWarehouses(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.warehouseList = result;
        } else {
          state.warehouseList = [];
        }
      }).catch(() => {})
    }

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getProductInventoryAdjustmentListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState)
      let { shopId, warehouseId } = state.searchState
      if (shopId) {
        state.searchStateCache.shopIds = [shopId]
      } else {
        state.searchStateCache.shopIds = []
      }
      if (warehouseId) {
        state.searchStateCache.warehouseIds = [warehouseId]
      } else {
        state.searchStateCache.warehouseIds = []
      }
      delete state.searchStateCache.shopId
      delete state.searchStateCache.warehouseId
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const handleConfirmDate = (e) => {
      let creationTimeBegin = null
      let creationTimeEnd = null
      if (Array.isArray(e) && e.length === 2) {
        creationTimeBegin = currentTimeToUtc(e[0]?.format('YYYY-MM-DD 00:00:00'))
        creationTimeEnd = currentTimeToUtc(e[1]?.format('YYYY-MM-DD 00:00:00'))
      }
      state.searchState.creationTimeBegin = creationTimeBegin
      state.searchState.creationTimeEnd = creationTimeEnd
    }

    

    onMounted(() => {
      handleSearch();
      getWarehouseList();
    });

    return {
      ...toRefs(state),
      inventoryAdjustmentTypeEnum,
      stockProductAdjustmentListModalRef,
      columns,
      handleOpenDetailModal,
      handleSearch,
      handlePage,
      handleConfirmDate
    }
  }
})
</script>

<style scoped></style>
<template>
  <Content>
    <template #contentTitle>{{ $t('menu.dev_cache_management') }}</template>
    <template #contentBody>
      <a-card :title="$t('devops.cache_cleanup')">
        <a-form
          ref="formRef"
          :label-col="{ style: { width: '200px' } }"
          :wrapper-col="{ span: 14 }"
        >
          <CurrencyItem ref="currencyItemRef" @confirm="handleOpenPinModal('currency')" />
          <TimeZoneItem ref="timeZoneItemRef" @confirm="handleOpenPinModal('timeZone')" />
          <PlatformAddressItem ref="platformAddressItemRef" @confirm="handleOpenPinModal('platformAddress')" />
          <NationalItem ref="nationalItemRef" @confirm="handleOpenPinModal('national')" />
        </a-form>
      </a-card>

      <a-card :title="$t('devops.storage_space_cleanup')" class="mt-3">
        <a-form
          ref="formRef"
          :label-col="{ style: { width: '200px' } }"
          :wrapper-col="{ span: 14 }"
        >
          <AliOSSItem @confirm="hour => handleOpenPinModal('aliOSS', hour)" />
        </a-form>
      </a-card>
      
      <CPinTipsModal v-model:visible="pinModalState.visible" :loading="pinModalState.loading" @confirm="handleCleanCache" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { Card, Form, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPinTipsModal from "@/views/components/CPinTipsModal.vue";
import CurrencyItem from "./components/CurrencyItem.vue";
import TimeZoneItem from "./components/TimeZoneItem.vue";
import PlatformAddressItem from "./components/PlatformAddressItem.vue";
import NationalItem from "./components/NationalItem.vue";
import AliOSSItem from "./components/AliOSSItem.vue";
import { useI18n } from "vue-i18n/index";
import { clearCurrencyCache, clearCustomTimeZoneCache, clearPlatformAddressCache, clearCountryCache, clearAliCloudOSSFiles } from "@/api/modules/devops/cacheManagement.js";

export default defineComponent({
  name: "dev_cache_management",
  components: {
    AForm: Form,
    ACard: Card,
    Content,
    CPinTipsModal,
    CurrencyItem,
    TimeZoneItem,
    PlatformAddressItem,
    NationalItem,
    AliOSSItem,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const currencyItemRef = ref(null);
    const timeZoneItemRef = ref(null);
    const platformAddressItemRef = ref(null);
    const nationalItemRef = ref(null);

    const pinModalState = reactive({
      visible: false,
      loading: false,
      dataKey: null,
      dataTemp: null,
    })

    const handleCleanCache = async (pinCode) => {
      try {
        const data = { pinCode };
        pinModalState.loading = true;
        if (pinModalState.dataKey === "currency") {
          // 清空币种缓存
          await clearCurrencyCache(data);
          currencyItemRef.value.getCacheInfo();
        } else if (pinModalState.dataKey === "timeZone") {
          // 清空时区缓存
          await clearCustomTimeZoneCache(data);
          timeZoneItemRef.value.getCacheInfo();
        } else if (pinModalState.dataKey === "platformAddress") {
          // 清空平台地址缓存
          await clearPlatformAddressCache(data);
          platformAddressItemRef.value.getCacheInfo();
        } else if (pinModalState.dataKey === "national") {
          // 清空国家缓存
          await clearCountryCache(data);
          nationalItemRef.value.getCacheInfo();
        } else if (pinModalState.dataKey === "aliOSS") {
          // 清空阿里云OSS缓存
          data.hour = pinModalState.dataTemp;
          await clearAliCloudOSSFiles(data);
        }
        message.success(vueI18n.t("common.succeed"));
        pinModalState.visible = false;
      } catch (error) {
      } finally {
        pinModalState.loading = false;
      }
    }

    const handleOpenPinModal = (key, data) => {
      pinModalState.dataKey = key;
      pinModalState.dataTemp = data;
      pinModalState.visible = true;
    }

    return {
      pinModalState,
      currencyItemRef,
      timeZoneItemRef,
      platformAddressItemRef,
      nationalItemRef,
      handleCleanCache,
      handleOpenPinModal,
    }
  }
})
</script>

<style scoped>

</style>
//操作相关的:英文
export default {
    operate: {
        "operator":"Operator",
        "change_operate_console": "Change",
        "accomplish_labelling": "Accomplish Labelling",
        "check_label": "",
        "sign_in_operating_desk":"Check in to the operating desk"
    }
}
  
  
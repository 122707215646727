export default {
  finance: {
    payment_serial_number: "付款流水号",
    collection_serial_number: "收款流水号",
    recharge_serial_number: "充值流水号",
    top_up_currency: "充值币种",
    payment_money: "付款金额",
    currency_of_payment: "付款币种",
    exchange_rate: "汇率",
    payment_method: "付款方式",
    account_to_be_credited: "收款账户",
    serial_number: "流水号",
    top_up_status: "充值状态",
    remark_info: "备注信息",
    confirm_the_inventory: "确认入账",
    in_account_of_failure: "入账失败",
    please_check_the_payment_serial_number_and_amount_carefully_and_cannot_be_modified_after_submission:
      "注:请仔细核对收款流水号和金额,提交后不能修改",
    currency: "币种",

    company_name_or_id: "公司名称或者ID",
    unlimited: "不限",

		minimum:"最低",
		highest:"最高",
		please_check_the_payment_serial_number_and_amount_carefully_and_cannot_be_modified_after_submission:"注: 请仔细核对收款流水号和金额,提交后不能修改",
		select_a_failure_cause:"请选择入账失败原因",

    partner_name_or_id:"合伙伙伴名称或编号",
    commission_grouping:"佣金分组",
    partner_account_opening:"合作伙伴开户",
    partner_commission:"合作伙伴分佣",
    partner_name:"合作伙伴名称",
    account_status:"账号状态",
    type:"类型",
    total_commission:"佣金总额",
    commission_to_be_confirmed:"待确认佣金",
    confirmed_commission:"已确认佣金",
    commission_cancelled:"已取消佣金",
    unsettled_commission:"未结算佣金",
    partner_type:"合作伙伴类型",
    company_name:"公司全称",
    license_number:"执照号码",
    id_card_No:"身份证号码",
    documents:"证件文件",
    settlement_channel:"结算渠道",
    account_name:"账户名称",
    depositary_bank:"开户银行",
    receiving_account:"收款账号",
    logistics_plan_name:"物流方案名称",
    channel_code:"渠道代码",
    warehousing_services:"仓储服务",
    transport_service:"运输服务",
    origin_support:"起运地支持",
    name:"名称",
    group_name:"分组名称",
    partner_group_set:"分组佣金设置",
    create_group:"创建分组",
    change_group:"更换分组",
    billing_info:'结算信息',
    add_channel:"添加渠道",
    normal:"正常",
    pay_commission:"支付佣金",

    total_amount_confirmed: "已确认总额",
    settled_total: "已结算总额",
    unsettled_total: "未结算总额",
    settlement_serial_number: "结算流水号",
    bank_account_no: "银行账号",
    date: "日期",
    settlement_object: "结算对象",
    payee: "收款方",

    commission_grouping: "佣金分组",
    partner_account_opening: "合作伙伴开户",
    partner: "合作伙伴",
    partner_name: "合作伙伴名称",
    account_status: "账号状态",
    status: "状态",
    total_commission: "佣金总额",
    commission_to_be_confirmed: "待确认佣金",
    confirmed_commission: "已确认佣金",
    commission_cancelled: "已取消佣金",
    unsettled_commission: "未结算佣金",
    partner_type: "合作伙伴类型",
    company_name: "公司全称",
    license_number: "执照号码",
    id_card_No: "身份证号码",
    documents: "证件文件",
    settlement_channel: "结算渠道",
    depositary_bank: "开户银行",
    receiving_account: "收款账号",
    warehousing_services: "仓储服务",
    transport_service: "运输服务",
    origin_support: "起运地支持",
    set: "设置",
    group_name: "分组名称",
    create_group: "创建分组",
    change_group: "更换分组",
    billing_info: "结算信息",
    add_channel: "添加渠道",
    channel: "渠道",
    normal: "正常",
    pay_commission: "支付佣金",
    country: "国家",
    timezone: "时区",
    settle_commission: "结算佣金",
    settle_sum: "结算金额",
    settle_accounts: "结算",
    commission_rate:"佣金比例",
    business_type:"业务类型",
    no_group:"无分组",

    normal_commission_distribution: "正常分佣",
    termination_of_commission_distribution: "终止分佣",

    the_settlement_amount_cannot_be_greater_than_the_unsettled_commission:"结算金额不能大于未结算佣金.",

    cost_record: "费用记录",

    cost_type: "费用类型",
    business_no: "业务编号",
    business_time: "业务发生时间",
    transaction_type: "交易类型",
    business_country: "业务发生国家",
    happen_currency: "发生币种",
    affiliation_order:"归属账单",
    fee_detail:"费用明细",

    facility_code: "设施代码",
    balance_transaction_details: "余额交易明细",

    trading_flow_number: "交易流水号",
    related_business_number: "相关业务编号",
    balance: "余额",
    accounting_period: "账期",

    view_storage_fee_details: "查看仓储费明细",
    view_return_temporary_storage_fee_details: "查看退货暂存费明细",
    export_storage_fee_details: "导出仓储费明细",
    export_return_temporary_storage_fee_details: "导出退货暂存费明细",
    storage_capacity: "存储量",
    storage_time: "存储时间",
    manual_recharge: "手动充值",
    please_select_currency_of_payment_first: "请先选择付款币种",
    account_number: "账户号码",
    bank_name: "银行名称",
    customer_remarks: "客户备注",
    display_on_client: "将在客户端显示",
    internal_remarks: "内部备注",
    internal_view_only: "仅内部人员可查看",
    refund_balance_insufficient_prompt: "退款后余额不足以支付冻结金额，是否确认退款?",
    contract_not_signed: "合同未签署",
    other_expenses: "其他费用",
    append: "追加",
    append_internal_remarks: "追加内部备注",
    purchase_exchange_rate: "买入汇率",
    selling_exchange_rate: "卖出汇率",
    middle_price: "中间价",
  },
};

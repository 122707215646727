import siteConfig from "@/siteconfigs";
import router from "../router/index";
import { toTree, hasRoute } from "../utils/menu";
import store from "../store/index";
import loaclRouters from "../router/module/local-routers";
import {getDynamicRoutes} from "../router/module/dynamic-routes";
import { getToken } from "../hooks/login/index";
// import {useI18n} from "vue-i18n/index"

export const createRouteByTree = (menuTreeList) => {
  //let tempId = -1;
  const routes = menuTreeList
    // .filter(function(item){
    //     return true;
    //     //return item.type =="group" || item.type =="router";
    //   })
    .map((menu) => {
      //type link 为自定义属性
      const {
        id,
        parent,
        path,
        name,
        icon,
        title,
        hidden = false,
        text,
        children = [],
        type,
        link,
      } = menu;
      const routeItem = {
        id,
        path,
        name,
        hidden,
        parent,
        type,
        link,
        meta: {
          title,
          icon,
          text,
        },
        children: children.length === 0 ? [] : createRouteByTree(children),
      };

      // if(menu.parent <= 0 && menu.type == "router"){
      //   const {id,name,icon,title,hidden=false,text} = menu;
      //   const newDefaultRoute = {
      //     id:tempId--,
      //     path:"",
      //     name,
      //     hidden,
      //     parent:menu.id,
      //     type,
      //     meta:{
      //       icon,
      //       title,
      //       text
      //     }
      //   };
      //   if(routeItem.children && routeItem.children.length > 0){
      //     var hasDefaultRoute = routeItem.children.filter(function(r){
      //       return r.path ===  "";
      //     });
      //     if(hasDefaultRoute.length == 0){
      //       routeItem.children.push(newDefaultRoute);
      //     }
      //   }else{
      //     routeItem.children = [newDefaultRoute];
      //   }
      // }

      if (routeItem.children.length <= 0) {
        delete routeItem.children;
      }
      return routeItem;
    });
  // console.log(routes);
  return routes;
};

export const createRouteByList = (menuList) => {
  const tree = toTree(menuList);
  return createRouteByTree(tree);
};

/**
 * 添加布局
 *
 * @param routes
 */
export const setUserRouteComponent = (routes) => {
  routes.forEach((r) => {
    // if(r.type != "link"){
    //   r.component = r.parent <= 0 ? loaclRouters.Layout : loaclRouters[r.name];
    //   if (r.children && r.children.length > 0) {
    //     setUserRouteComponent(r.children)
    //   }
    // }
    if (r.type != "link") {
      r.component =
        r.type === "group" && r.parent <= 0
          ? loaclRouters.Layout
          : loaclRouters[r.name];
      if (r.children && r.children.length > 0) {
        setUserRouteComponent(r.children);
      }
    }
  });
};

// /**
//  * 添加组件
//  *
//  * @param routes
//  */
// export const setComponent = routes => {
//   routes.forEach(r => {
//     if(r.type != "link"){
//           r.component = loaclRouters[r.name];
//       if (r.children && r.children.length > 0) {
//         setUserRouteComponent(r.children)
//       }
//     }
//   });
// }

// const setDocumentTitle = meta => {
//     let title = siteConfig.projectName;
//     if(meta.text){
//       // const i18n = useI18n({ useScope: 'global' })
//       // title = i18n.t(meta.text);
//       title = meta.text;
//     }else if(meta.title){
//       title = meta.title;
//     }
//     document.title = `${title}`
// }

export const navGuards = async (to, from, next) => {
  // const { meta } = to
  // setDocumentTitle(meta);
  // await store.dispatch('app/execCancelToken')
	if((to.meta||{}).disAuth){
		next();
		return;
	}
	window.__testRouter = router;
  if (!to.fullPath.includes("login") && !getToken()) {
    next({ path: "/login", query: { returnurl: to.fullPath } });
  } else {
    if (to.fullPath.startsWith("/error")) {
      next();
      return;
    }
    // console.log(router.getRoutes())
    let resolveRouter = router.resolve(to);
    // console.log(resolveRouter);
    if (resolveRouter.matched.length <= 0) {

      await store.dispatch("user/addUserRoutes");
      // const userRoutes = JSON.parse(JSON.stringify(store.getters.menu));
      const userRoutes = createRouteByTree(getDynamicRoutes());
      // console.log(userRoutes);
      if (userRoutes && userRoutes.length > 0) {
        //添加布局
        setUserRouteComponent(userRoutes);
        // //添加组件
        // setComponent(userRoutes);
        userRoutes.forEach((r) => {
          // console.log(r.type)
          if (r.type != "link") {
            if (!router.hasRoute(r.name)) {
              if (r.type === "router") {
                router.addRoute("Layout", r);
              } else {
                router.addRoute(r);
              }
            }
          }
          // if(r.type!="link")
          // {
          //   if(!router.hasRoute(r.name)){
          //     router.addRoute("Layout",r);
          //   }
          // }
        });
      }

      resolveRouter = router.resolve(to);
      // console.log("after add");
      if (resolveRouter.matched.length > 0) {
        next(to.fullPath);
      } else {
        next("/error/404");
      }
    } else {
      // console.log("next");
      next();
    }
    // if (!router.getRoutes().map(it => it.path).includes(to.fullPath)) {
    //   await store.dispatch("user/addUserRoutes")
    //   const userRoutes =  JSON.parse(JSON.stringify(store.getters.menu));
    //   if(hasRoute(userRoutes,to.fullPath)){
    //     // 添加布局
    //     setUserRouteComponent(userRoutes)
    //     userRoutes.forEach(r => {
    //       router.addRoute(r)
    //     });
    //     next(to.fullPath)
    //   }else{
    //     next('/error/404')
    //   }
    // } else {
    //   next()
    // }
  }
};

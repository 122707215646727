<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('warehouse.set_the_authority')"
    :confirm-loading="confirmLoading"
    :mask-closable="false"
    @ok="handleConfirm"
  >
    <a-spin :spinning="loading">
      <a-list>
        <a-list-item
          v-for="(item, index) in list"
          :key="index"
        >
          <a-list-item-meta :description="item.description">
            <template #title>
              <a-checkbox v-model:checked="item.checked">{{ item.authorizeName }}</a-checkbox>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { useI18n } from "vue-i18n/index";
import { Checkbox, List, message, Modal, Spin } from 'ant-design-vue';
import { getAuthorizeInfoList, getAuthorizeIdsByAppId, setAppRequiredAuthorizeInfo } from '@/api/modules/openPlatform/applys';

export default defineComponent({
  name: "setAuthorityModal",
  components: {
    AModal: Modal,
    ASpin: Spin,
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    ACheckbox: Checkbox,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      authorizeApplicationId: null,
      list: [],
      selectedList: [],
    })
    
    const handleConfirm = () => {
      let checkChanged = false
      let authorizeinfoIds = state.list.map(item => {
        if (item.checked) {
          if (!state.selectedList.includes(item.id)) {
            checkChanged = true
          }
          return item.id
        }
      }).filter(Boolean)
      if(!authorizeinfoIds.length) {
        message.error(vueI18n.t('warehouse.please_check_at_least_one_item'))
        return
      }
      if (authorizeinfoIds.length !== state.selectedList.length) {
        checkChanged = true
      }
      if (checkChanged) {
        state.confirmLoading = true
        const data = {
          authorizeApplicationId: state.authorizeApplicationId,
          authorizeinfoIds,
        }
        setAppRequiredAuthorizeInfo(data).then(() => {
          message.success(vueI18n.t('common.succeed'))
          state.visible = false
        }).catch().finally(() => {
          state.confirmLoading = false
        })
      } else {
        state.visible = false
      }
    }
    
    const getData = async (id) => {
      try {
        let { result: list } = await getAuthorizeInfoList()
        let { result } = await getAuthorizeIdsByAppId({ id })
        let { authorizeIds = [] } = result

        state.selectedList = authorizeIds
        state.list = list.map(item => {
          if (authorizeIds.includes(item.id)) {
            item.checked = true
          } else {
            item.checked = false
          }
          return item
        })
      } catch (error) {
      } finally {
        state.loading = false
      }
    }

    const show = (id) => {
      state.visible = true
      state.loading = true
      state.authorizeApplicationId = id
      getData(id)
    }
  
    return {
      ...toRefs(state),
      show,
      handleConfirm,
    }
  }
})
</script>
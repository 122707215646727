<template>
  <Content>
    <template #contentTitle>
      {{ $t('menu.partner') }}
    </template>
    <template v-slot:contentBody='wrap'>
      <div class="mb-3">

        <a-row type="flex"
               justify="space-between"
               align="bottom">
          <a-col>
            <a-form layout="inline">
              <a-form-item class="mt-1">
                <a-input :placeholder="$t('finance.partner_name_or_id')"
                         allow-clear
                         v-model:value="data.searchKey"></a-input>
              </a-form-item>
              <a-form-item class="mt-1">
                <a-select style="min-width: 200px"
                          v-model:value="data.isActive"
                          :placeholder="$t('finance.status')"
                          allow-clear>
                  <a-select-option value="true">
                    {{$t('common.normal')}}
                  </a-select-option>
                  <a-select-option value="false">
                    {{$t('account.freeze')}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="mt-1">
                <a-select style="min-width: 200px"
                          v-model:value="data.partnerType"
                          :placeholder="$t('finance.type')"
                          allow-clear>
                  <a-select-option v-for="(value,index) in partnerTypeEnum"
                                   :key="index"
                                   :title="$t($enumLangkey('partnerType',value))"
                                   :value="value">
                    {{$t($enumLangkey('partnerType',value))}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="mt-1">
                <a-select style="min-width: 200px"
                          v-model:value="data.partnerGroupId"
                          :placeholder="$t('finance.commission_grouping')"
                          :show-search="true"
                          option-filter-prop="search-key"
                          allow-clear>
                  <a-select-option v-for="(value,index) in data.groupCommissions"
                                   :key="index"
                                   :title="value.groupName"
                                   :value="value.id"
                                   :search-key="value.groupName">
                    {{value.groupName}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item class="mt-1">
                <a-button type="primary"
                          @click="handleSearch">{{ $t('common.query') }}</a-button>
              </a-form-item>

            </a-form>
          </a-col>
          <a-col>
            <a-button class="mt-1"
                      @click="hanldeShowModal"> {{ $t('finance.partner_account_opening') }}</a-button>
          </a-col>
        </a-row>
      </div>
      <div>
        <!-- 表格 -->
        <a-table :columns="columns"
                 :data-source="data.list"
                 :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
                 :pagination="false"
                 :rowKey="
          (record, id) => {
            return id;
          }
        "
                 size="small"
                 :loading="data.loading">
          <template #isActive="{record}">
            <span v-if="record.isActive">{{$t('common.normal')}}</span>
            <span v-else>{{$t('account.freeze')}}</span>

            <a-switch v-model:checked="record.isActive" class="ml-2" 
             @change="handleShowPinModal(record)"
            />
            
          </template>

          <template #creationTime="{record}">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
          </template>

          <template #partnerType="{record}">
            {{
              $t($enumLangkey("partnerType", record.partnerType))
            }}
          </template>

          <template #partnerGroupName="{record}">
            <span v-if="record.partnerGroupName">{{ record.partnerGroupName }}</span>
            <span v-else>-</span>
          </template>

          <template #amount="{record}">
            {{record.currencySymbol}}
            {{roundNumber(record.amount)}}
          </template>

          <template #awaitConfirmAmount="{record}">
            {{record.currencySymbol}}
            {{roundNumber(record.awaitConfirmAmount)}}
          </template>

          <template #confirmAmount="{record}">
            {{record.currencySymbol}}
            {{roundNumber(record.confirmAmount)}}
          </template>

          <template #canceledAmount="{ record }">
            {{record.currencySymbol}}
            {{roundNumber(record.canceledAmount)}}
          </template>

          <template #noSettlementAmount="{ record }">
            {{record.currencySymbol}}
            {{roundNumber(record.noSettlementAmount)}}
          </template>

          <template #set="{ record }">
            <a-dropdown>
              <a-button type="ghost">{{ $t('common.operate') }}<DownOutlined /></a-button>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1" :record="record">{{ $t('finance.pay_commission') }}</a-menu-item>
                  <a-menu-item key="2" :record="record">{{ $t('menu.settings') }}</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="modal.visible"
               :title="$t('finance.partner_account_opening')"
               :confirmLoading="modal.loading"
               :maskClosable="false"
               :footer="false">
        <a-form ref="refForm"
                :model="modal"
                :rules="rules"
                :label-col="{
        span: 6,
      }">
          <a-form-item :label="$t('finance.partner_type')"
                       name="partnerType">
            <a-select v-model:value="modal.partnerType"
                      :placeholder="$t('finance.partner_type')"
                      allow-clear>
              <a-select-option v-for="(value,index) in partnerTypeEnum"
                               :key="index"
                               :title="$t($enumLangkey('partnerType',value))"
                               :value="value">
                {{$t($enumLangkey('partnerType',value))}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="name">
            <template #label>
              <span v-if="modal.partnerType==partnerTypeEnum.company">
                {{$t('finance.company_name')}}</span>
              <span v-else>{{$t('warehouse.name')}}</span>
            </template>
            <a-input allow-clear
                     v-model:value="modal.name"></a-input>
          </a-form-item>
          <a-form-item name="businessLicenseCode">
            <template #label>
              <span v-if="modal.partnerType==partnerTypeEnum.company">
                {{$t('finance.license_number')}}</span>
              <span v-else>{{$t('finance.id_card_No')}}</span>
            </template>
            <a-input allow-clear
                     v-model:value="modal.businessLicenseCode"></a-input>
          </a-form-item>
          <a-form-item name="businessLicense"
                       :label="$t('finance.documents')">
            <CUpload v-model:fileUrl="modal.businessLicense"
                     :functionModule='14'></CUpload>
          </a-form-item>

          <a-form-item name="partnerGroupId"
                       :label="$t('finance.commission_grouping')">
            <a-select v-model:value="modal.partnerGroupId"
                      :placeholder="$t('finance.commission_grouping')"
                      :show-search="true"
                      option-filter-prop="search-key"
                      allow-clear>
              <a-select-option v-for="(value,index) in data.groupCommissions"
                               :key="index"
                               :title="value.groupName"
                               :value="value.id"
                               :search-key="value.groupName">
                {{value.groupName}}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="countryId"
                       :label="$t('common.country')">
            <a-select v-model:value="modal.countryId"
                      optionFilterProp="search-key"
                      show-search
                      :placeholder="$t('common.country')"
                      allow-clear>
              <a-select-option :value="item.id"
                               v-for="item in dropDownData.countrys"
                               :key="item.id"
                               :search-key="item.ioS2 + item.cnName + item.enName"
                               :title="`${getLanguageName(item)}(${item.ioS2})`"
                               :phoneCountryCode="item.telCode">
                {{getLanguageName(item)}}({{ item.ioS2 }})
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="timezone"
                       :label="$t('account.timezone')">
            <a-select v-model:value="modal.timezone"
                      :placeholder="$t('account.timezone')"
                      optionFilterProp="search-key"
                      show-search
                      allow-clear>
              <a-select-option :value="item.zoneName"
                               v-for="item in dropDownData.timezones"
                               :title="item.zoneName+(getLanguageName({ cnName:item.countryCnName, enName:item.countryEnName })+item.gmtOffset)"
                               :search-key="item.countryCode+item.zoneName+item.countryCnName + item.countryEnName+item.gmtOffset"
                               :key="item.id">
                {{item.zoneName}}({{getLanguageName({ cnName:item.countryCnName, enName:item.countryEnName })}}+{{item.gmtOffset}})
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="settlementChannelType"
                       :label="$t('finance.settlement_channel')">
            <a-select v-model:value="modal.settlementChannelType"
                      :placeholder="$t('finance.settlement_channel')"
                      allow-clear>
              <a-select-option v-for="(value,index) in settlementChannelTypeEnum"
                               :key="index"
                               :title="$t($enumLangkey('settlementChannelType',value))"
                               :value="value">
                {{$t($enumLangkey('settlementChannelType',value))}}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="accountName"
                       :label="$t('finance.account_name')">
            <a-input allow-clear
                     v-model:value="modal.partnerSettlementChannel.accountName"></a-input>
          </a-form-item>
          <a-form-item name="bankName"
                       :label="$t('finance.depositary_bank')">
            <a-input allow-clear
                     v-model:value="modal.partnerSettlementChannel.bankName"></a-input>
          </a-form-item>
          <a-form-item name="collectionAccountNumber"
                       :label="$t('finance.receiving_account')">
            <a-input allow-clear
                     v-model:value="modal.partnerSettlementChannel.collectionAccountNumber"></a-input>
          </a-form-item>

          <a-form-item name="phone"
                       :label="$t('warehouse.contact_information')">
            <a-row :gutter=[16,8]>
              <a-col :span="10">
                <a-select :allowClear="true"
                          show-search
                          optionFilterProp="search-key"
                          v-model:value="modal.phoneCountryCode"
                          @change="handleChangeAreaCode"
                          :placeholder="$t('warehouse.area_code')">
                  <a-select-option :value="item.telCode"
                                   v-for="item in dropDownData.countrys"
                                   :key="item.id"
                                   :search-key="item.ioS2 + item.cnName + item.enName + item.telCode"
                                   :title="getLanguageName(item)+'(+'+item.telCode+')'">
                    {{getLanguageName(item)}}(+{{item.telCode}})
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="14">
                <a-input v-model:value="modal.phone"
                         :placeholder="$t('warehouse.phone_number')"></a-input>
              </a-col>
            </a-row>
          </a-form-item>
        </a-form>
        <a-row type="flex"
               justify="center"
               align="middle"
               class="mt-3">
          <a-col>
            <a-button type="primary"
                      :loading="modal.loading"
                      @click="handleOpenAccount">{{$t('common.confirm')}}</a-button>
          </a-col>
          <a-col>
            <a-button class="ml-5"
                      :loading="modal.loading"
                      @click="modal.visible=false">{{$t('common.cancel')}}</a-button>
          </a-col>
        </a-row>
      </a-modal>

      <!-- 支付 -->
      <a-modal v-model:visible="payModal.visible"
               :title="$t('finance.settle_commission')"
               :confirmLoading="payModal.loading"
               :centered="true"
               :maskClosable="false"
               :footer="false">
        <a-form ref="refPayModalForm"
                :model="payModal"
                :rules="rulesPayModal"
                :label-col="{
        span: 7,
      }">
          <a-form-item>
            <template #label>
              <span v-if="payModal.partnerType==partnerTypeEnum.company">
                {{$t('finance.company_name')}}</span>
              <span v-else>{{$t('warehouse.name')}}</span>
            </template>
            {{payModal.name}}
          </a-form-item>
          <a-form-item :label="$t('finance.unsettled_commission')">
            {{payModal.currencySymbol}} {{roundNumber(payModal.noSettlementAmount)}}
          </a-form-item>
          <a-form-item :label="$t('finance.settle_sum')"
                       name="amount">
            <a-input-number v-model:value="payModal.amount"
                            style="width:150px"
                            :step="100" />
          </a-form-item>
          <a-form-item :label="$t('finance.settlement_serial_number')"
                       name="settlementNo">
            <a-input allow-clear
                     v-model:value="payModal.settlementNo"></a-input>
          </a-form-item>
        </a-form>
        <div style="max-width:600px">
          <a-card hoverable>
            <a-row :gutter=[32,16]>
              <a-col :xs="24"
                     :sm="5"
                     style="text-align:right"><strong> {{$t('finance.settlement_channel')}}</strong></a-col>
              <a-col :xs="24"
                     :sm="19"> {{$t($enumLangkey('settlementChannelType',payModal.partnerSettlementChannel.settlementChannelType))}}</a-col>

              <a-col :xs="24"
                     :sm="5"
                     style="text-align:right">{{$t('finance.account_name')}}</a-col>
              <a-col :xs="24"
                     :sm="19"> {{payModal.partnerSettlementChannel.accountName}}</a-col>

              <a-col :xs="24"
                     :sm="5"
                     style="text-align:right">{{$t('finance.depositary_bank')}}</a-col>
              <a-col :xs="24"
                     :sm="19"> {{payModal.partnerSettlementChannel.bankName}}</a-col>

              <a-col :xs="24"
                     :sm="5"
                     style="text-align:right">{{$t('finance.receiving_account')}}</a-col>
              <a-col :xs="24"
                     :sm="19">
                {{payModal.partnerSettlementChannel.collectionAccountNumber}}
              </a-col>
            </a-row>
          </a-card>
        </div>
        <a-row type="flex"
               justify="center"
               align="middle"
               class="mt-3">
          <a-col>
            <a-button type="primary"
                      :loading="payModal.payLoading"
                      @click="handelPay">{{$t('finance.settle_accounts')}}</a-button>
          </a-col>
          <a-col>
            <a-button class="ml-5"
                      :loading="payModal.payLoading"
                      @click="payModal.visible=false">{{$t('common.cancel')}}</a-button>
          </a-col>
        </a-row>
      </a-modal>


      <CPinTipsModal
        v-model:visible="data.pinModal.visible"
        v-model:loading="data.pinModal.loading"
        @confirm="handleUpdateStatus"
        @handleCancel="handleCancelUpdateStatus"
      /> 
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <CPager class="text-center"
              @handlePage="handlePage"
              :page-data='data.pageData'> </CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, ref } from 'vue';
import {
  Table,
  Button,
  Select,
  Row,
  Col, InputNumber,
  Input, Form, Modal, message, Card, Dropdown, Menu,Switch
} from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { getName, roundNumber } from "../../utils/general.js";
import {
  getList, openAccount, getPartnerInfo, payCommission,updateStatus
} from '../../api/modules/partner/list';
import { getPartnerGroupList } from '../../api/modules/partner/details';
import {
  partnerType as partnerTypeEnum,
  settlementChannelType as settlementChannelTypeEnum,
} from '../../enum/enum.json'
import moment from "moment-timezone";
import CUpload from '../partner/components/Upload.vue';
import { getGlobalCountrys } from "../../api/modules/common/index"
import { getTimezones } from "../../api/modules/company/index"
import {
  verifyMobilePhoneNumber
} from "../../utils/general";
import router from '../../router';
import CPinTipsModal from "@/views/components/CPinTipsModal.vue"


export default defineComponent({
  name: "partner_list",
  components: {
    CPinTipsModal,
    ATable: Table,
    AButton: Button,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    ACard: Card,
    AInput: Input,
    AInputNumber: InputNumber,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CUpload,
    ASwitch:Switch
  },

  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const router = useRouter();
    const refCredentials = ref();
    const refForm = ref();
    const refPayModalForm = ref();

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const columns = [
      {
        title: () => vueI18n.t('finance.partner_name'),
        dataIndex: 'name',
        width: 100,
      },
      {
        title: 'NO',
        dataIndex: 'partnerNo',
        width: 100,
      },
      {
        title: () => vueI18n.t('finance.account_status'),
        dataIndex: 'isActive',
        width: 70,
        slots: {
          customRender: 'isActive',
        },
      },
      {
        title: () => vueI18n.t('warehouse.creation_time'),
        dataIndex: 'creationTime',
        slots: {
          customRender: 'creationTime',
        },
        width: 70,
      },
      {
        title: () => vueI18n.t('finance.type'),
        dataIndex: 'partnerType',
        slots: {
          customRender: 'partnerType',
        },
        width: 50,
      },
      {
        title: () => vueI18n.t('finance.commission_grouping'),
        width: 80,
        slots: {
          customRender: "partnerGroupName"
        }
      },
      {
        title: () => vueI18n.t('finance.total_commission'),
        slots: {
          customRender: 'amount',
        }
        ,
        width: 80,
      },
      {
        title: () => vueI18n.t('finance.commission_to_be_confirmed'),
        dataIndex: 'awaitConfirmAmount',
        slots: {
          customRender: 'awaitConfirmAmount',
        }
        ,
        width: 80,
      },
      {
        title: () => vueI18n.t('finance.confirmed_commission'),
        dataIndex: 'confirmAmount',
        slots: {
          customRender: 'confirmAmount',
        },
        width: 80,
      },
      {
        title: () => vueI18n.t('finance.commission_cancelled'),
        width: 80,
        dataIndex: 'canceledAmount',
        slots: {
          customRender: "canceledAmount"
        },
      },
      {
        title: () => vueI18n.t('finance.unsettled_commission'),
        width: 80,
        dataIndex: 'noSettlementAmount',
        slots: {
          customRender: "noSettlementAmount"
        },
      },
      {
        title: '',
        width: 100,
        slots: {
          customRender: "set"
        },
      },
    ];

    const data = reactive({
      list: [],
      status: null,
      searchKey: null,
      isActive: null,
      partnerType: null,
      partnerGroupId: null,
      groupCommissions: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      loading: false,
      pinModal: {
        visible: false,
        loading: false,
      },
      tempRecord:null
    });

    const funcGetGroupCommissions = () => {
      getPartnerGroupList().then(({ result }) => {
        if (Array.isArray(result)) {
          data.groupCommissions = result;
        } else {
          data.groupCommissions = [];
        }
      })
        .catch(() => {

        })
    }

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      getTable();
    }

    const handleSearch = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      getTable()
    }

    const getTable = async () => {
      data.loading = true;
      let params = { ...data, ...data.pageData }
      getList(params).then(({ result }) => {
        if (result) {
          let { items, totalCount } = result;
          data.list = items;
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.pageData.totalCount = 0;
          data.list = [];
        }
        data.loading = false;
      }).catch(() => {
        data.loading = false;
      })
    }

    //---------------开户 start-------------------
    const modal = reactive({
      loading: false,
      visible: false,
      businessLicense: null,
      fileUrl: null,
    });

    const getModalData = () => {
      return {
        partnerType: partnerTypeEnum.company,
        name: null,
        businessLicenseCode: null,
        partnerGroupId: null,
        countryId: null,
        timezone: null,
        settlementChannelType: settlementChannelTypeEnum.bankCableTransfer,
        partnerSettlementChannel: {
          accountName: null,
          bankName: null,
          collectionAccountNumber: null,
        },
        phoneCountryCode: null,
        phone: null,
      }
    }

    const hanldeShowModal = () => {
      Object.assign(modal, getModalData());

      if (refForm.value && refForm.value.resetFields) {
        refForm.value.resetFields();
      }

      if (!dropDownData.countrys || dropDownData.countrys.length == 0) {
        funcGetCountrys();
      }
      if (!dropDownData.timezones || dropDownData.timezones.length == 0) {
        funcGetTimezones();
      }
      modal.visible = true;
    }

    const handleOpenAccount = () => {
      refForm.value
        .validate()
        .then(() => {
          modal.loading = true;
          openAccount({ ...modal }).then(() => {
            modal.loading = false;
            modal.visible = false;
            message.success(vueI18n.t('common.succeed'));
            getTable();
          }).catch(() => {
            modal.loading = false;
          })
        })
    }

    const handleChangeAreaCode = (record) => {
     if (modal.phoneCountryCode) {
        refForm.value.validate("phone");
     }
    };

    const rules = {
      partnerType: [{
        required: true,
        type: "number",
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.partner_type")])
        }
      }],
      name: [{
        required: true,
        whitespace: true,
        validator: (rules, value) => {
          if (!value || value.trim().length == 0) {
            if (modal.partnerType == partnerTypeEnum.company) {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.company_name")]));
            } else {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.name")]));
            }
          }
          return Promise.resolve();
        },
      }],
      businessLicenseCode: [{
        required: true,
        whitespace: true,
        validator: (rules, value) => {
          if (!value || value.trim().length == 0) {
            if (modal.partnerType == partnerTypeEnum.company) {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.license_number")]));
            } else {
              return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.id_card_No")]));
            }
          }
          return Promise.resolve();
        },
      }],
      businessLicense: [{
        required: true,
        trigger: ['change', 'blur'],
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.documents")])
        }
      }],

      countryId: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("common.country")])
        }
      }],
      timezone: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("account.timezone")])
        }
      }],
      settlementChannelType: [{
        required: true,
        type: "number",
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.settlement_channel")])
        }
      }],
      accountName: [{
        required: true,
        whitespace: true,
        validator: (rules, value) => {
          if (!modal.partnerSettlementChannel.accountName || modal.partnerSettlementChannel.accountName.trim().length == 0) {
            return Promise.reject();
          }
          return Promise.resolve();
        },
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.account_name")])
        }
      }],
      bankName: [{
        required: true,
        whitespace: true,
        validator: (rules, value) => {
          if (!modal.partnerSettlementChannel.bankName || modal.partnerSettlementChannel.bankName.trim().length == 0) {
            return Promise.reject();
          }
          return Promise.resolve();
        },
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.depositary_bank")])
        }
      }],
      collectionAccountNumber: [{
        required: true,
        whitespace: true,
        validator: (rules, value) => {
          if (!modal.partnerSettlementChannel.collectionAccountNumber || modal.partnerSettlementChannel.collectionAccountNumber.trim().length == 0) {
            return Promise.reject();
          }
          return Promise.resolve();
        },
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.receiving_account")])
        }
      }],
      phone: [{
        required: false,
        validator: (rules, value) => {
          if (modal.phoneCountryCode && !modal.phone) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.phone_number")]));
          }

          if (!modal.phoneCountryCode && modal.phone) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.area_code")]));
          }

          refForm.value.clearValidate("phone");

          if (modal.phoneCountryCode) {
            let ios2 = dropDownData.countrys.find(x => x.telCode == modal.phoneCountryCode)?.ioS2;
            if (ios2&&modal.phone && !verifyMobilePhoneNumber(ios2, modal.phoneCountryCode + modal.phone)) {
              return Promise.reject(vueI18n.t("warehouse.please_enter_the_correct_number"));
            }
          }
          return Promise.resolve();
        },
        
      }],
    };

    //---------------开户 end-------------------

    //------------- 下拉框数据 begin-----------------
    const dropDownData = reactive({
      countrys: [],
      countryLoading: false,
      timezones: [],
      timezoneLoading: false,
    })

    const funcGetCountrys = () => {
      dropDownData.countryLoading = false;
      getGlobalCountrys().then((res) => {
        if (res && res.result) {
          dropDownData.countrys = res.result;
        }
        dropDownData.countryLoading = false;
      }).catch(() => {
        dropDownData.countryLoading = false;
      })
    }

    const funcGetTimezones = () => {
      dropDownData.timezoneLoading = true;
      getTimezones().then((res) => {
        if (res.result && res.result.length > 0) {
          dropDownData.timezones = res.result;
        }
        dropDownData.timezoneLoading = false;
      }).catch(() => {
        dropDownData.timezoneLoading = false;
      })
    }
    //------------- 下拉框数据 end-----------------

    //------------- 支付modal start-----------------
    const payModal = reactive({
      loading: false,
      payLoading: false,
      visible: false,
    });

    const getPayModal = () => {
      return {
        name: null,
        currencySymbol: null,
        noSettlementAmount: null,
        partnerType: null,
        partnerSettlementChannel: {
          settlementChannelType: null,
          accountName: null,
          bankName: null,
          collectionAccountNumber: null
        },
        amount: null,
        settlementNo: null
      }
    }

    const handleShowPayModal = (record) => {
      Object.assign(payModal, getPayModal());
      if (refPayModalForm.value && refPayModalForm.value.resetFields) {
        refPayModalForm.value.resetFields();
      }
      payModal.loading = true;
      payModal.visible = true;
      getPartnerInfo(record.id).then(({ result }) => {
        if (result) {
          Object.assign(payModal, result);
          payModal.amount = payModal.noSettlementAmount;
        }
        payModal.loading = false;
      }).catch(() => {
        payModal.loading = false;
      })
    }

    const handelPay = () => {
      refPayModalForm.value
        .validate()
        .then(() => {
          let parameter = { ...payModal, ...payModal.partnerSettlementChannel };
          payModal.payLoading = true;
          return payCommission(parameter).then(({ result }) => {
            payModal.payLoading = false;
            payModal.visible = false;
            message.success(vueI18n.t('common.succeed'));
            getTable();
          })
        }).catch(() => {
          payModal.payLoading = false;
        })
    }

    const rulesPayModal = {
      amount: [{
        required: true,
        type: "number",
        whitespace: true,
        validator: (rules, value) => {
          if (!value || value <= 0) {
            return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.settle_sum")]));
          }
          if (value > payModal.noSettlementAmount) {
            return Promise.reject(vueI18n.t("finance.the_settlement_amount_cannot_be_greater_than_the_unsettled_commission"));
          }
          return Promise.resolve();
        },
        // message: () => {
        //   return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.settle_sum")])
        // }
      }],
      settlementNo: [{
        required: true,
        whitespace: true,
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("finance.settlement_serial_number")])
        }
      }]
    };
    //------------- 支付modal end-----------------

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          handleShowPayModal(record)
          break;
        case '2':
          router.push({ name: 'partner_details', params: { id: record.id } })
          break;
        default:
          break;
      }
    }


  //--------------调整账号状态 start-------------------------
  

  const handleShowPinModal=(record)=>{
    data.pinModal.visible = true;
    data.tempRecord = record;
  }

  const handleUpdateStatus=(pinCode)=>{
     const params = {
        id: data.tempRecord.id,
        pinCode: pinCode,
        isActive:data.tempRecord.isActive
      };
      data.pinModal.loading = true;
      updateStatus(params)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
          data.tempRecord = null;
          data.pinModal.visible = false;
          data.pinModal.loading = false;
          handleSearch();
        })
        .catch(() => {
          data.pinModal.loading = false;
        });
  }

  const handleCancelUpdateStatus=()=>{
     data.tempRecord.isActive=!data.tempRecord.isActive;
  }

  //--------------调整账号状态 end-------------------------

    onActivated(() => {
      getTable();
      if (!data.groupCommissions || data.groupCommissions.length == 0) {
        funcGetGroupCommissions();
      }
    });

    return {
      columns,
      data,
      modal,
      refCredentials,
      rules,
      rulesPayModal,
      refForm,
      refPayModalForm,
      payModal,
      moment,
      partnerTypeEnum,
      settlementChannelTypeEnum,
      handlePage,
      handleSearch,
      handleOpenAccount,
      hanldeShowModal,
      funcGetGroupCommissions,
      dropDownData,
      getLanguageName,
      handleShowPayModal,
      handelPay,
      roundNumber,
      handleMenuClick,
      handleShowPinModal,
      handleUpdateStatus,
      handleCancelUpdateStatus  ,

      handleChangeAreaCode

    };
  },
});
</script>
<style lang="less" scoped>
</style>

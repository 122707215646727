<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.partner_global_set") }}
    </template>
    <template v-slot:contentBody>
      <div class="mb-3">
        <div>{{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.storageService)) }}</div>
        <div class="ml-4 mt-3">
          <UpdateMoney
            v-model:price="storageCommission.percent"
            v-model:isEdit="storageCommission.isEdit"
            :saving="storageCommission.loading"
            @updateMoney="(e) => handleEditCommission(1, e)"
          />
        </div>
      </div>
      <div>
        <!-- 表格 -->
        <div>{{ $t($enumLangkey('commissionBusinessType', commissionBusinessTypeEnum.transportService)) }}</div>
        <a-table
          :columns="columns"
          :data-source="transportCommissions"
          :pagination="false"
          :rowKey="
            (record, id) => {
              return id;
            }
          "
          size="small"
          :loading="loading"
        >
          <template #serialNumber="{ index }">{{ index + 1 }}</template>
          <template #logisticAddress="{ record }">
            {{
              getLanguageName(
                record.logisticAddress.countryCnName,
                record.logisticAddress.countryEnName
              )
            }}
          </template>
          <template #partnerCommission="{ record }">
            <UpdateMoney
              v-model:price="record.percent"
              v-model:isEdit="record.isEdit"
              :saving="record.loading"
              :isShowTitle="false"
              @updateMoney="(e) => handleEditCommission(2, e, record)"
            />
          </template>
        </a-table>
      </div>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onActivated, reactive, ref, toRefs } from "vue";
import {
  Table,
  Button,
  Select,
  Row,
  Col,
  Input,
  Form,
  message,
} from "ant-design-vue";
import CPager from "../components/CPager.vue";
import Content from "../components/Content.vue";
import {
  getAllGlobalCommission,
  createOrUpdateGlobalStorageCommission,
  createOrUpdateGlobalTransportCommission,
} from "../../api/modules/partner/globalSet";
import { commissionBusinessType as commissionBusinessTypeEnum } from '@/enum/enum.json'
import { useI18n } from "vue-i18n/index";
import UpdateMoney from "./components/UpdateMoney.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "partner_global_set",
  components: {
    ATable: Table,
    AButton: Button,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AFormItem: Form.Item,
    AForm: Form,
    CPager,
    Content,
    UpdateMoney,
  },

  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        title: () => vueI18n.t("common.serial_number"),
        slots: {
          customRender: "serialNumber",
        },
        width: 80,
      },
      {
        dataIndex: "logisticName",
        title: () => vueI18n.t("logistics.logistics_scheme_name"),
        width: 250,
      },
      {
        dataIndex: "logisticCode",
        title: () => vueI18n.t("finance.channel_code"),
        width: 200,
      },
      {
        title: () => vueI18n.t("finance.origin_support"),
        slots: {
          customRender: "logisticAddress",
        },
        width: 200,
      },
      {
        title: () => vueI18n.t("finance.partner_commission"),
        slots: {
          customRender: "partnerCommission",
        },
        width: 200,
      },
    ];

    const state = reactive({
      loading: false,
      storageCommission: {
        percent: 0,
        loading: false,
        isEdit: false,
      },
      transportCommissions: [],
    });

    const handleEditCommission = async (type, percent, record) => {
      try {
        const data = { percent };
        if (type === 1) {
          state.storageCommission.loading = true;
          await createOrUpdateGlobalStorageCommission(data);
          state.storageCommission.isEdit = false;
          state.storageCommission.percent = percent;
        } else {
          data.businessId = record.logisticId;
          record.loading = true;
          await createOrUpdateGlobalTransportCommission(data);
          record.isEdit = false;
          record.percent = percent;
        }
        message.success(vueI18n.t("common.succeed"));
      } catch (error) {
      } finally {
        if (type === 1) {
          state.storageCommission.loading = false;
        } else {
          record.loading = false;
        }
      }
    };

    const getLanguageName = (cnName, enName) => {
      if (getters.language === "zh-CN") {
        return cnName;
      } else {
        return enName;
      }
    };

    const getData = async () => {
      state.loading = true;
      getAllGlobalCommission()
        .then(({ result }) => {
          if (result) {
            let { storageCommission, transportCommissions = [] } = result;
            state.storageCommission.percent = storageCommission.percent;
            state.transportCommissions = transportCommissions.map((item) => {
              item.loading = false;
              item.isEdit = false;
              return item;
            });
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onActivated(getData);

    return {
      columns,
      ...toRefs(state),
      getData,
      getLanguageName,
      handleEditCommission,
      commissionBusinessTypeEnum,
    };
  },
});
</script>

<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.product_size_modification_notice") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <SearchProduct ref="refSearchProduct" />
        </a-col>

        <a-col>
          <CSearchShop v-model:shopId="shopId"></CSearchShop>
        </a-col>

        <a-col>
          <a-select
            v-model:value="status"
            :allowClear="true"
            :placeholder="$t('finance.status')"
            style="min-width: 200px"
          >
            <a-select-option
              v-for="(value, key) in productPackingUpdateRecordStatusEnum"
              :key="key"
              :title="$t($enumLangkey('boxStatus', value))"
              :value="value"
            >
              {{ $t($enumLangkey("productPackingUpdateRecordStatus", value)) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-select
            v-model:value="warehouseId"
            :placeholder="$t('warehouse.warehouse')"
            allow-clear
            :showSearch="true"
            optionFilterProp="search-key"
            style="width: 250px"
          >
            <a-select-option
              v-for="(value, key) in searchWarehouseList"
              :key="key"
              :value="value.id"
              :title="`${value.warehouseNo}(${value.warehouseName})`"
              :search-key="value.warehouseNo + value.warehouseName"
            >
              {{ value.warehouseNo }}({{ value.warehouseName }}})
            </a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-range-picker style="width: 250px" @change="handleSelectedDate" />
        </a-col>
        <a-col>
          <a-button type="primary" :loading="loading" @click="handleSearch">{{
            $t("common.query")
          }}</a-button>
        </a-col>
      </a-row>
      <!-- list表格 -->
      <div class="mt-3">
        <a-table
          :columns="columns"
          :data-source="list"
          :scroll="{ x: true, y: wrap.contentHeight - 120 }"
          size="small"
          :pagination="false"
          :loading="loading"
          class="product-table"
          :rowKey="
            (record, index) => {
              return index + record.productName;
            }
          "
        >
          <template #time>
            {{ $t("warehouse.creation_time") }}
            <div>
              {{ $t("warehouse.last_updated") }}
            </div>
          </template>
          <template #creationTimeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.creationTime) }}
            <div>
              {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
            </div>
          </template>

          <template #shopNameAndShopId="{ record }">
            {{ record.shopName }}
            <div v-if="record.shopNo">NO: {{ record.shopNo }}</div>
            <div>
              {{ $t("common.shop_mark") }}: {{ record.shopMark ?? "-" }}
            </div>
          </template>

          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.imgUrl"></c-image>
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #packagingSize="{ record }">
            <div
              v-if="
                record.packingLength &&
                record.packingWidth &&
                record.packingHeight
              "
            >
              {{ record.packingLength }}x{{ record.packingWidth }}x{{
                record.packingHeight
              }}cm
            </div>
            <div v-else>-</div>
          </template>
          <template #weight="{ record }">
            {{gToKg(record.packingWeight)}}kg
          </template>
          <template #verify_images="{ record }">
            <a-popover
              v-if="record?.checkImgUrls && record?.checkImgUrls?.length > 0"
              :getPopupContainer="(trigger) => trigger.parentNode"
            >
              <template #content>
                <a-space>
                  <div
                    v-for="(imgItem, index) in record?.checkImgUrls"
                    class="table-list-img-common"
                    :key="index"
                  >
                    <c-image :src="imgItem" />
                  </div>
                </a-space>
              </template>
              <span class="text-primary" style="font-size: 20px">
                <FileImageOutlined />
              </span>
            </a-popover>
            <span v-else>-</span>
          </template>

          <template #packingImgUrl="{ record }">
            <div class="table-list-img-common" v-if="record.packingImgUrl">
              <c-image :src="record.packingImgUrl"></c-image>
            </div>
          </template>

          <template #allocated_warehouse="{ record }">
            <span v-if="record.warehouseNo">{{ record.warehouseNo }}</span>
            <span v-else>-</span>
          </template>

          <template #status="{ record }">
            {{
              $t(
                $enumLangkey("productPackingUpdateRecordStatus", record.status)
              )
            }}
          </template>

          <template #operate="{ record }">
            <a-spin :spinning="record?.loading ? record?.loading : false">
              <a-dropdown-button>
                {{ $t("common.operate") }}
                <template #overlay>
                  <a-menu @click="handleClickOperateMenu">
                    <a-menu-item
                      key="distribute"
                      :record="record"
                      :disabled="
                        record.status !=
                          productPackingUpdateRecordStatusEnum.toBeAllocated &&
                        record.status !=
                          productPackingUpdateRecordStatusEnum.assigned
                      "
                    >
                      {{ $t("warehouse.distribute") }}
                    </a-menu-item>
                    <a-popconfirm
                      :title="$t('common.are_you_sure')"
                      :ok-text="$t('common.confirm')"
                      :cancel-text="$t('common.cancel')"
                      @confirm="handleCancel(record)"
                      ><a-menu-item
                        key="cancel"
                        :record="record"
                        :disabled="!record.isCancel"
                      >
                        {{ $t("common.cancel") }}
                      </a-menu-item>
                    </a-popconfirm>
                    <a-popconfirm
                      :title="$t('common.are_you_sure')"
                      :ok-text="$t('common.confirm')"
                      :cancel-text="$t('common.cancel')"
                      @confirm="handleDelete(record)"
                    >
                      <a-menu-item
                        key="delete"
                        :record="record"
                        :disabled="!record.isDeleted"
                      >
                        {{ $t("common.delete") }}
                      </a-menu-item>
                    </a-popconfirm>
                  </a-menu>
                </template>
              </a-dropdown-button>
            </a-spin>
          </template>
        </a-table>
      </div>

      <a-modal
        v-model:visible="distributeModal.visible"
        :title="
          $t('warehouse.distribute') +
          '(' +
          distributeModal?.record?.seSku +
          ')'
        "
        @ok="handleDistributeModalOk"
        :centered="true"
        :cancelText="$t('common.cancel')"
        :okText="$t('common.confirm')"
      >
        <a-row>
          <a-col :span="24">
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{
                sm: { span: 10 },
                xl: { span: 12 },
              }"
              :label="$t('warehouse.warehouse')"
            >
              <a-select
                v-model:value="distributeModal.warehouseId"
                @change="handleChangeWarehouse"
                allow-clear
                :showSearch="true"
                optionFilterProp="search-key"
                style="width: 250px"
                :loading="distributeModal.warehouseListLoading"
              >
                <a-select-option
                  v-for="(value, key) in distributeModal.warehouseList"
                  :key="key"
                  :item="value"
                  :value="value.warehouseId"
                  :title="`${value.warehouseNo}(${value.warehouseName})`"
                  :search-key="value.warehouseNo + value.warehouseName"
                >
                  {{ value.warehouseNo }}({{ value.warehouseName }})
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{
                sm: { span: 10 },
                xl: { span: 12 },
              }"
              :label="$t('库存数量')"
            >
              <span
                v-if="
                  !distributeModal.warehouseList ||
                  distributeModal.warehouseList?.length == 0
                "
                class="text-error"
                >暂无可分配的仓库</span
              >
              <span v-else>{{ distributeModal.count }}</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-modal>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle" class="mb-1">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import Content from "../components/Content.vue";
import {
  message,
  Row,
  Popconfirm,
  Col,
  Table,
  Input,
  Select,
  Button,
  Tag,
  Space,
  Form,
  Tooltip,
  Dropdown,
  Menu,
  DatePicker,
  Modal,
  Popover,
  Spin,
} from "ant-design-vue";
import { toRefs, reactive, onActivated, ref } from "vue";
import CPager from "../components/CPager.vue";
import {
  getList,
  allocation,
  cancelRecord,
  deleteRecord,
  getDistributeWarehouseList,
} from "../../api/modules/products/sizeModificationNotification";
import { productPackingUpdateRecordStatus as productPackingUpdateRecordStatusEnum } from "../../enum/enum.json";
import CImage from "../components/CImage.vue";
import CSearchShop from "../components/CSearchShop.vue";
import { useI18n } from "vue-i18n/index";
import { gToKg, dateString, currentTimeToUtc } from "../../utils/general";

import SearchProduct from "../components/SearchProduct.vue";
import { getWarehouses } from "@/api/modules/common/index";

export default {
  name: "product_size_modification_notice",
  components: {
    SearchProduct,
    Content,
    CSearchShop,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    AInputSearch: Input.Search,
    ASelect: Select,
    AButton: Button,
    ATag: Tag,
    ASelectOption: Select.Option,
    ASpace: Space,
    CImage,
    CPager,
    AForm: Form,
    AFormItem: Form.Item,
    ATooltip: Tooltip,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    APopconfirm: Popconfirm,
    ARangePicker: DatePicker.RangePicker,
    AModal: Modal,
    APopover: Popover,
    ASpin: Spin,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const refSearchProduct = ref();

    //#region columns
    const columns = [
      {
        title: () => vueI18n.t("warehouse.customer"),
        dataIndex: "shopNameAndShopId",
        slots: {
          customRender: "shopNameAndShopId",
        },
        fixed: "left",
        width: 150,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("warehouse.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 150,
      },
      {
        dataIndex: "seSku",
        title: "SESKU",
        width: 100,
      },
      {
        title: () => vueI18n.t("common.product_packing_imag"),
        slots: {
          customRender: "packingImgUrl",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.product_dimensions"),
        slots: {
          customRender: "packagingSize",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.verify_images"),
        slots: {
          customRender: "verify_images",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("common.weight"),
        slots: {
          customRender: "weight",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.allocated_warehouse"),
        slots: {
          customRender: "allocated_warehouse",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "status",
        },
        width: 100,
      },
      {
        slots: {
          title: "time",
          customRender: "creationTimeCustom",
        },
        width: 100,
      },
      {
        dataIndex: "operate",
        fixed: "right",
        slots: {
          customRender: "operate",
        },
        width: 120,
      },
    ];
    //#endregion

    const data = reactive({
      loading: false,

      shopId: null,
      productId: null,
      status: null,
      warehouseId: null,
      beginCreationTime: null,
      endCreationTime: null,

      list: [],
      searchWarehouseList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const funcSearch = () => {
      let searchData = {
        ...data,
        ...data.pageData,
        productId: refSearchProduct.value.selectedProductId,
      };
      data.loading = true;
      getList(searchData)
        .then((res) => {
          let { items, totalCount } = res.result;
          if (items && items.length > 0) {
            data.list = items;
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
            data.list = [];
          }
          data.loading = false;
        })
        .catch(() => {
          data.loading = false;
        });
    };

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleSearch = async (pageData) => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcSearch();
    };

    const handleSelectedDate = (e) => {
      let startCreationTime = null;
      let endCreationTime = null;
      if (e.length === 2) {
        startCreationTime = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endCreationTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      data.beginCreationTime = startCreationTime;
      data.endCreationTime = endCreationTime;
    };

    //------------------分配 modal start-----------------------------------
    const distributeModal = reactive({
      loading: false,
      visible: false,
      record: null,
      warehouseId: null,
      warehouseList: [],
      warehouseListLoading: false,
    });

    const handleGetDistributeWarehouseList = () => {
      distributeModal.warehouseListLoading = true;
      getDistributeWarehouseList({
        shopId: distributeModal.record.shopId,
        productId: distributeModal.record.productId,
      })
        .then(({ result }) => {
          if (result) {
            distributeModal.warehouseList = result;
          }
          distributeModal.warehouseListLoading = false;
        })
        .catch(() => {
          distributeModal.warehouseListLoading = false;
        });
    };

    const handleShowDistributeModal = (record) => {
      distributeModal.loading = true;
      distributeModal.visible = true;
      distributeModal.record = record;
      distributeModal.count = null;
      distributeModal.warehouseId = null;
      handleGetDistributeWarehouseList();
    };

    const handleChangeWarehouse = (value, record) => {
      distributeModal.count = record?.item?.count ?? null;
    };

    const handleDistributeModalOk = () => {
      if (!distributeModal.warehouseId) {
        message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.warehouse")])
        );
        return;
      }
      distributeModal.loading = true;
      allocation({
        id: distributeModal.record.id,
        warehouseId: distributeModal.warehouseId,
      })
        .then(() => {
          distributeModal.visible = false;
          handleSearch();
        })
        .finally(() => {
          distributeModal.loading = false;
        });
    };

    //------------------分配 modal end-----------------------------------
    const handleClickOperateMenu = (e) => {
      let { key, item } = e;
      switch (key) {
        case "distribute":
          handleShowDistributeModal(item.record);
          break;
        case "cancel":
          handleCancel(item.record);
          break;
        case "delete":
          handleDelete(item.record);
          break;
        default:
          break;
      }
    };

    const handleDelete = (record) => {
      record.loading = true;
      deleteRecord({ ids: [record.id] })
        .then(() => {
          message.success(vueI18n.t("common.delete_success"));
          handleSearch();
          record.loading = false;
        })
        .catch(() => {
          record.loading = false;
        });
    };

    const handleCancel = (record) => {
      record.loading = true;
      cancelRecord(record.id)
        .then(() => {
          message.success(vueI18n.t("common.delete_success"));
          handleSearch();
          record.loading = false;
        })
        .catch(() => {
          record.loading = false;
        });
    };

    const funcGetAllocationWarehouseList = () => {
      getWarehouses({ isActive: true, warehouseType: "2" })
        .then(({ result }) => {
          if (result) {
            data.searchWarehouseList = result;
          }
        })
        .catch();
    };

    onActivated(async () => {
      handleSearch();
      funcGetAllocationWarehouseList();
    });

    return {
      refSearchProduct,
      ...toRefs(data),
      columns,
      gToKg,
      productPackingUpdateRecordStatusEnum,

      handleSelectedDate,
      handlePage,
      handleSearch,

      distributeModal,
      handleShowDistributeModal,
      handleDistributeModalOk,
      handleChangeWarehouse,

      handleClickOperateMenu,
      handleDelete,
      handleCancel,
    };
  },
};
</script>
<style lang="less" scoped></style>


import Layout from "@/views/layout/Index.vue";
export default {
    Layout,

    "dashboard":()=>import("@/views/dashboard/Index.vue"),
    "dashboard_index":()=>import("@/views/dashboard/Index.vue"),
    "dashboard_monitor": () => import("@/views/dashboard/Monitor.vue"),

    "account_info":()=>import("@/views/accounts/Info.vue"),
    
    "warehouse_device_manager":()=>import("@/views/warehouse/DeviceManager.vue"),
    "warehouse_fee_setting":()=>import("@/views/warehouse/FeeSetting.vue"),

    "warehouse_monitor_storage_outbound_task": ()=>import("@/views/warehouseMonitor/storage/OutboundTask.vue"),
    "warehouse_monitor_storage_outbound_task_detail": ()=>import("@/views/warehouseMonitor/storage/OutboundTaskDetail.vue"),
    "warehouse_monitor_storage_warehousing_task": ()=>import("@/views/warehouseMonitor/storage/WarehousingTask.vue"),
    "warehouse_monitor_storage_warehousing_task_detail": ()=>import("@/views/warehouseMonitor/storage/WarehousingTaskDetail.vue"),
    "warehouse_monitor_storage_label_change": ()=>import("@/views/warehouseMonitor/storage/LabelChange.vue"),
    "warehouse_monitor_storage_label_change_detail": ()=>import("@/views/warehouseMonitor/storage/LabelChangeDetail.vue"),
    "warehouse_monitor_consignment_warehousing_task": ()=>import("@/views/warehouseMonitor/consignment/WarehousingTask.vue"),
    "warehouse_monitor_consignment_warehousing_task_detail": ()=>import("@/views/warehouseMonitor/consignment/WarehousingTaskDetail.vue"),
    "warehouse_monitor_consignment_order_task": ()=>import("@/views/warehouseMonitor/consignment/OrderTask.vue"),
    "warehouse_monitor_consignment_order_task_detail": ()=>import("@/views/warehouseMonitor/consignment/OrderTaskDetail.vue"),
    "warehouse_monitor_consignment_return_temporary_storage": ()=>import("@/views/warehouseMonitor/consignment/ReturnTemporaryStorage.vue"),
    "warehouse_monitor_consignment_return_temporary_storage_shop": ()=>import("@/views/warehouseMonitor/consignment/ReturnTemporaryStorageShop.vue"),
    "warehouse_monitor_unload": ()=>import("@/views/warehouseMonitor/Unload.vue"),
    "warehouse_monitor_return": ()=>import("@/views/warehouseMonitor/return/Index.vue"),
    "warehouse_monitor_return_detail": ()=>import("@/views/warehouseMonitor/return/Detail.vue"),
    "warehouse_monitor_print_waybill": ()=>import("@/views/warehouseMonitor/printwaybill/List.vue"),
    "warehouse_monitor_print_waybill_detail": ()=>import("@/views/warehouseMonitor/printwaybill/Detail.vue"),
    "warehouse_monitor_allot_plan": ()=>import("@/views/warehouseMonitor/allot/List.vue"),
    "warehouse_monitor_allot_plan_detail": ()=>import("@/views/warehouseMonitor/allot/Detail.vue"),
    "warehouse_monitor_transit_inbound": ()=>import("@/views/warehouseMonitor/transit/Inbound.vue"),
    "warehouse_monitor_transit_inbound_detail": ()=>import("@/views/warehouseMonitor/transit/InboundDetail.vue"),
    "warehouse_monitor_transit_outbound": ()=>import("@/views/warehouseMonitor/transit/Outbound.vue"),
    "warehouse_monitor_transit_outbound_detail": ()=>import("@/views/warehouseMonitor/transit/OutboundDetail.vue"),
    "warehouse_monitor_transit_label_change": ()=>import("@/views/warehouseMonitor/transit/LabelChange.vue"),
    "warehouse_monitor_transit_label_change_detail": ()=>import("@/views/warehouseMonitor/transit/LabelChangeDetail.vue"),

    // "products_manager": ()=>import("@/views/products/Manager.vue"),
    "products_verify_data": ()=>import("@/views/products/VerifyData.vue"),
    "products_storage_inventory": ()=>import("@/views/products/StorageInventory.vue"),
    "products_consignment_inventory": ()=>import("@/views/products/ConsignmentInventory.vue"),
    "products_transit_inventory": ()=>import("@/views/products/TransitInventory.vue"),
    "products_transit_shipment": ()=>import("@/views/products/TransitShipment.vue"),
    "products_list": ()=>import("@/views/products/List.vue"),
    "products_edit": ()=>import("@/views/products/Edit.vue"),
    "products_historical_version": ()=>import("@/views/products/HistoricalVersion.vue"),
    "products_check_history": ()=>import("@/views/products/CheckHistory.vue"),
    "products_inventory_adjustment": () => import("@/views/products/InventoryAdjustment.vue"),
    "product_size_modification_notice": () => import("@/views/products/SizeModificationNotification.vue"),

    "logistics_channel":()=>import("@/views/logistics/Channel.vue"),
    "logistics_region":()=>import("@/views/logistics/Region.vue"),
    "logistics_region_edit":()=>import("@/views/logistics/RegionEdit.vue"),
    "logistics_fee_template":()=>import("@/views/logistics/FeeTemplate.vue"),
    "logistics_fee_config":()=>import("@/views/logistics/FeeConfig.vue"),
    "logistics_surcharge":()=>import("@/views/logistics/SurchargeConfig.vue"),
    "logistics_surcharge_edit":()=>import("@/views/logistics/SurchargeConfigEdit.vue"),
    "logistics_account":()=>import("@/views/logistics/account/List.vue"),
    "logistics_embargo_configuration":()=>import("@/views/logistics/EmbargoConfiguration.vue"),
    "logistics_limited_transportation_configuration":()=>import("@/views/logistics/LimitedTransportationConfiguration.vue"),
    "logistics_discount":()=>import("@/views/logistics/Discount.vue"),
    "logistics_address_api":()=>import("@/views/logistics/AddressApi.vue"),
    "logistics_validation": ()=>import("@/views/logistics/Validation.vue"),
    "logistics_rebate_configuration": () => import("@/views/logistics/LogisticsRebateConfiguration.vue"),
    "logistics_channel_discount": () => import("@/views/logistics/channelDiscount/List.vue"),

    "special_region": ()=>import("@/views/logistics/SpecialRegion.vue"),
    "special_region_edit": ()=>import("@/views/logistics/SpecialRegionEdit.vue"),

    "supplier_channel":()=>import("@/views/supplier/Channel.vue"),
    "supplier_region":()=>import("@/views/supplier/Region.vue"),
    "supplier_region_edit":()=>import("@/views/supplier/RegionEdit.vue"),
    "supplier_fee_template":()=>import("@/views/supplier/FeeTemplate.vue"),
    "supplier_fee_config":()=>import("@/views/supplier/FeeConfig.vue"),
    "supplier_surcharge":()=>import("@/views/supplier/SurchargeConfig.vue"),
    "supplier_surcharge_edit":()=>import("@/views/supplier/SurchargeConfigEdit.vue"),
    "supplier_embargo_configuration":()=>import("@/views/supplier/EmbargoConfiguration.vue"),
    "supplier_limited_transportation_configuration":()=>import("@/views/supplier/LimitedTransportationConfiguration.vue"),
    "supplier_discount":()=>import("@/views/supplier/Discount.vue"),
    "supplier_print_waybill_api":()=>import("@/views/supplier/PrintWaybillApi.vue"),
    "supplier_address_api":()=>import("@/views/supplier/AddressApi.vue"),
    "supplier_special_region": ()=>import("@/views/supplier/SpecialRegion.vue"),
    "supplier_special_region_edit": ()=>import("@/views/supplier/SpecialRegionEdit.vue"),
    "supplier_channel_discount": () => import("@/views/supplier/channelDiscount/List.vue"),

    "role_manager":()=>import("@/views/right/RoleManager.vue"),
    "right_manager":()=>import("@/views/right/RightManager.vue"),
    "staff_organizational":()=>import("@/views/staff/StaffOrganizational.vue"),

    "transport_list": ()=>import("@/views/transport/List.vue"),
    "transport_detail": ()=>import("@/views/transport/Detail.vue"),
    "transport_customer_list": ()=>import("@/views/transport/customer/List.vue"),
    "transport_customer_detail": ()=>import("@/views/transport/customer/Detail.vue"),
    "transport_shipment_transport": ()=>import("@/views/transport/shipment/transport/List.vue"),
    "transport_shipment_cabin": ()=>import("@/views/transport/shipment/cabin/List.vue"),
    "transport_shipment_cabin_detail": ()=>import("@/views/transport/shipment/cabin/Detail.vue"),

    "finance_recharge": ()=>import("@/views/finance/Recharge.vue"),
    "finance_inventory": ()=>import("@/views/finance/Inventory.vue"),
    "finance_inventory_details": ()=>import("@/views/finance/InventoryDetails.vue"),
    "finance_inventory_unsettled": ()=>import("@/views/finance/InventoryUnsettled.vue"),
    "finance_accounting_list": ()=>import("@/views/finance/accounting/List.vue"),
    "finance_accounting_cost": ()=>import("@/views/finance/accounting/Cost.vue"),
    "finance_accounting_details": ()=>import("@/views/finance/accounting/Details.vue"),
    "finance_customer_balance": ()=>import("@/views/finance/CustomerBalance.vue"),
    "finance_order_waybill": ()=>import("@/views/finance/OrderWaybill.vue"),

    "customer_account_list": ()=>import("@/views/customer/account/List.vue"),
    "customer_account_details": ()=>import("@/views/customer/account/Details.vue"),
    "customer_company_list": ()=>import("@/views/customer/company/List.vue"),
    "customer_company_details": ()=>import("@/views/customer/company/Details.vue"),
    "customer_company_transaction_record": ()=>import("@/views/customer/company/TransactionRecord.vue"),
    "customer_company_accounting_period": ()=>import("@/views/customer/company/LogisticsAccountingPeriod.vue"),
    "customer_level_list": ()=>import("@/views/customer/level/List.vue"),
    "customer_company_discount_list": ()=>import("@/views/customer/companyDiscount/List.vue"),
    "customer_company_discount_details": ()=>import("@/views/customer/companyDiscount/Details.vue"),
    
    "dev_ops_ali_log": ()=>import("@/views/devops/AliLog.vue"),
    "dev_scan_exception": ()=>import("@/views/devops/ScanException.vue"),
    "dev_box_edit_list": ()=>import("@/views/devops/BoxEditList.vue"),
    "dev_api_manage": ()=>import("@/views/devops/ApiManage.vue"),
    "dev_currency_configuration_list": ()=>import("@/views/devops/currencyConfiguration/List.vue"),
    "dev_currency_configuration_edit": ()=>import("@/views/devops/currencyConfiguration/Edit.vue"),
    "dev_abbr_code_convert": ()=>import("@/views/devops/abbrCodeConvert/List.vue"),
    "dev_export_log": ()=>import("@/views/devops/ExportLog.vue"),
    "dev_port_management": ()=>import("@/views/devops/PortManagement.vue"),
    "dev_airline_management": ()=>import("@/views/devops/AirlineManagement.vue"),
    "dev_announcement": ()=>import("@/views/devops/announcement/List.vue"),
    "dev_logistics_payment_countdown": () => import("@/views/devops/LogisticsPaymentCountdown.vue"),
    "dev_cache_management": () => import("@/views/devops/CacheManagement/Index.vue"),
    "dev_exchange_rate": () => import("@/views/devops/exchangeRate/List.vue"),
    "dev_exchange_rate_history": () => import("@/views/devops/exchangeRate/History.vue"),
    "dev_notice_list": () => import("@/views/devops/notice/List.vue"),
    "dev_notice_template": () => import("@/views/devops/notice/Template.vue"),

    "partner_list": ()=>import("@/views/partner/List.vue"),
    "partner_details": ()=>import("@/views/partner/Details.vue"),
    "partner_global_set": ()=>import("@/views/partner/GlobalSet.vue"),
    "partner_group_manage": ()=>import("@/views/partner/GroupManage.vue"),
    "partner_group_set": ()=>import("@/views/partner/GroupSet.vue"),
    "partner_settlement_record": ()=>import("@/views/partner/SettlementRecord.vue"),


    "open_platform_authorizes": ()=>import("@/views/openPlatform/Authorizes.vue"),
    "open_platform_authorize_api": ()=>import("@/views/openPlatform/AuthorizeAPI.vue"),
    "open_platform_applys": ()=>import("@/views/openPlatform/Applys.vue"),
    "open_platform_applys_company": ()=>import("@/views/openPlatform/ApplysCompany.vue"),
}
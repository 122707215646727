<template>
  <div>
    <a-modal
      v-model:visible="data.visible"
      :title="$t('logistics.check_the_postcode')"
      @cancel="handleClose"
    >
      <template #footer>
        <a-button key="submit" @click="handleClose">{{$t("common.close")}}</a-button>
      </template>
      <div class="mb-2">
        <a-input-search
          v-model:value="data.postCode"
          :placeholder="$t('common.post_code')"
          enter-button
          @search="handleQuery"
          :loading="data.loading"
        />
      </div>

      <div class="mb-2">
        <a-table
          size="small"
          :columns="columns"
          :data-source="data.items"
          :pagination="data.pagination"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :loading="data.loading"
          @change="handleTableChange"
        >
            <template #addressRender="{record}">
                {{getAddress(record)}}
            </template>
            <template #operateRender="{record}">
                <a-button @click="handleSelected(record)">
                    {{$t("common.select")}}
                </a-button>
            </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import { Modal, Button, Input, Table, message } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getAddressByLanguageV2 } from "@/utils/general"
import { getByCountryIdAndZipCode } from "@/api/modules/logistics/index.js";

export default {
  name: "CPostQuery",
  emits: ['update:visible', 'onSelected'],
  components: {
    AButton: Button,
    AInput: Input,
    AModal: Modal,
    AInputSearch: Input.Search,
    ATable: Table,
  },
  props: {
    postCode: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const data = reactive({
      visible: false,
      postCode: props.postCode,
      loading: false,
      items: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    });
    const { getters } = useStore();

    watch(() => [props.visible, props.postCode], ([visible, postCode]) => {
      if (typeof visible === 'boolean') {
        data.visible = visible;
        if (visible) {
          data.postCode = null;
          data.items = [];
          if (postCode) {
            data.postCode = postCode;
            handleQuery();
          }
        }
      }
    });

    const columns = [
      {
        width: 300,
        title: () => vueI18n.t("logistics.region"),
        slots: {
          customRender: "addressRender",
        },
      },
      {
        width: 80,
        title: () => "",
        slots: {
          customRender: "operateRender",
        },
      },
    ];

    const handleTableChange = (pageData) => {
      data.pagination = pageData
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(Object.assign({
          countryIos2:address.ioS2,
          provinceAbbr: address.abbrCode
      },address), getters.language, ", ");
    }

    const handleQuery = () => {
      let zipCode = data.postCode?.trim();
      if (!zipCode) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('common.post_code')]));
        return;
      }
      if (zipCode.length < 5) {
        message.error(vueI18n.t('common.p0_please_enter_at_least_p1_digits', [vueI18n.t('common.post_code'), 5]));
        return;
      }
      data.loading = true;
      var postCode = data.postCode;
      getByCountryIdAndZipCode({ zipCode })
        .then((res) => {
          var dataArr = res.result?.areaInfos || [];
          data.items = dataArr.map((x) => {
            return Object.assign({}, x, { postCode });
          });
          data.pagination.total = data.items.length
        })
        .catch(() => {})
        .finally(() => {
          data.loading = false;
        });
    };

    const handleSelected = (record) => {
      emit("onSelected", Object.assign({}, record));
      emit("update:visible", false);
    };

    const handleClose = () => {
      emit("update:visible", false);
    };

    return {
      columns,
      getAddress,

      handleQuery,
      handleSelected,
      handleClose,
      handleTableChange,

      data,
    };
  },
};
</script>

import client from "../../client";

const Api = {
  // 创建分组
  createPartnerGroup: "/api/SupplierService/PartnerGroup/Create",
  // 分组列表
  queryPartnerGroup: "/api/SupplierService/PartnerGroup/Query",
  // 修改分组
  editPartnerGroup: "/api/SupplierService/PartnerGroup/Edit",
};

export const createPartnerGroup = (data) => {
  return client.request({
    url: Api.createPartnerGroup,
    data: data,
    method: "POST",
  });
};

export const queryPartnerGroup = (data) => {
  return client.request({
    url: Api.queryPartnerGroup,
    data: data,
    method: "POST",
  });
};

export const editPartnerGroup = (data) => {
  return client.request({
    url: Api.editPartnerGroup,
    data: data,
    method: "POST",
  });
};
<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.out_plan_detail") }}
    </template>
    <template #contentBody>
      <a-spin :spinning="data.loading">
        <a-row>
          <a-col :span="20">
            <a-row class="mt-3" :gutter="[0, 12]">
              <a-col :span="24"
                >{{ $t("warehouse.out_plan_name") }}: {{ data.planName }}</a-col
              >
              <a-col :span="8"
                >{{ $t("warehouse.out_warehouse") }}:
                {{ data.warehouseNo }}</a-col
              >
              <a-col :span="16">
                {{ $t("warehouse.warehouse_address") }}:
                {{ getAddress(data.inventoryWarehouseAddress) }}
                <span class="ml-2" v-if="data.inventoryWarehouseAddress">
                  {{ $t("logistics.contacts") }}:
                  {{ getLinkman(data.inventoryWarehouseAddress) }}
                </span>
              </a-col>
              <a-col :span="8"
                >{{ $t("warehouse.out_warehouse_type") }}:
                {{ $t($enumLangkey("destinationType", data.destinationType)) }}
              </a-col>
              <a-col :span="8"
                   v-if=" data.destinationType == destinationTypeEnum.SeWarehouse"
                >{{ $t("warehouse.shipment_not_set") }}:
                {{ $t($enumLangkey("sendoutType", data.sendoutType)) }}
              </a-col>
              <!-- <a-col
                :span="8"
                v-if="
                  data.destinationType != destinationTypeEnum.OtherAddress &&
                  data.destinationType != destinationTypeEnum.SeWarehouse
                "
                >{{ $t("warehouse.recipients") }}:
                {{ data.targetAddress?.linkMan }}
              </a-col>
              <a-col
                :span="8"
                v-if="
                  data.destinationType != destinationTypeEnum.OtherAddress &&
                  data.destinationType != destinationTypeEnum.SeWarehouse
                "
                >{{ $t("warehouse.phone") }}: +{{
                  data.targetAddress?.phoneCountryCode
                }}
                {{ data.targetAddress?.linkManPhone }}
              </a-col> -->
              <a-col
                :span="8"
                v-if="data.destinationType == destinationTypeEnum.FBA"
                >{{ $t("warehouse.fba_warehouse_code") }}:
                {{ data.toWarehouseNo }}</a-col
              >
              <a-col
                :span="8"
                v-if="data.destinationType == destinationTypeEnum.mercadolibre"
                >{{ $t("warehouse.mercado_libre_warehouse_code") }}:
                {{ data.toWarehouseNo }}</a-col
              >

              <a-col
                :span="16"
                v-if="
                  data.destinationType == destinationTypeEnum.FBA ||
                  data.destinationType == destinationTypeEnum.mercadolibre
                "
              >
                {{ $t("logistics.destination_address") }}:
                {{ getAddress(data.targetAddress) }}
              </a-col>

              <a-col
                :span="16"
                v-if="data.destinationType == destinationTypeEnum.OtherAddress"
              >
                {{ $t("logistics.destination_address") }}:
                {{ getAddress(data.targetAddress) }}
                <span class="ml-2" v-if="data.targetAddress">
                  {{ $t("logistics.contacts") }}:
                  {{ getLinkman(data.targetAddress) }}
                </span>
              </a-col>
            </a-row>
            <a-row
              v-if="data.destinationType == destinationTypeEnum.SeWarehouse"
              :gutter="[0, 12]"
              class="mt-2"
            >
              <a-col
                :span="8"
                v-if="data.destinationType == destinationTypeEnum.SeWarehouse"
                >{{ $t("warehouse.se_warehouse_code") }}:
                {{ data.toWarehouseNo }}</a-col
              >
              <a-col :span="16">
                {{ $t("logistics.destination_address") }}:
                {{ getAddress(data.targetAddress) }}
                <span class="ml-2">
                  {{ $t("logistics.contacts") }}:
                  {{ getLinkman(data.targetAddress) }}
                </span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="4">
            <div class="text-center mt-4">
              <strong>
                {{ $t("warehouse.status") }}
              </strong>
              <div class="mt-3">
                <strong class="text-success">
                  {{
                    $t($enumLangkey("transitOutBoundWareStatus", data.wareStatus))
                  }}
                </strong>
              </div>
            </div>
          </a-col>
        </a-row>

        <a-table
          class="mt-2"
          :columns="columns"
          :data-source="data.transitShipments"
          :pagination="false"
          size="small"
          @expand="handleGetRowDetail"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
           <template #proxy_tenant_info="{ record }">
              <div v-if="record.representedShop">
                {{record.representedShop.shopName}}
                <div>{{record.representedShop.shopNo}}</div>
              </div>
              <span v-else>-</span>
          </template>
          <template #box_amount="{ record }">
            {{
              $filters.countProgressFormat(
                record.transitTotalInfo?.boxCount?.actual,
                record.transitTotalInfo?.boxCount?.plan
              )
            }}
          </template>

          <template #shipment_volume="{ record }">
            {{
              $filters.countProgressFormat(
                cmCubicToM(record.transitTotalInfo?.volume?.actual),
                cmCubicToM(record.transitTotalInfo?.volume?.plan)
              )
            }}
            <span
              v-if="
                record.transitTotalInfo?.volume?.actual ||
                record.transitTotalInfo?.volume?.plan
              "
              >m<sup>3</sup></span
            >
          </template>

          <template #shipment_weight="{ record }">
            {{
              $filters.countProgressFormat(
                gToKg(record.transitTotalInfo?.weight?.actual),
                gToKg(record.transitTotalInfo?.weight?.plan)
              )
            }}
            <span
              v-if="
                record.transitTotalInfo?.weight?.actual ||
                record.transitTotalInfo?.weight?.plan
              "
              >kg</span
            >
          </template>

          <template #uploadTitle>
            <span
              style="color: red"
              v-if="
                data.destinationType == destinationTypeEnum.FBA ||
                data.destinationType == destinationTypeEnum.mercadolibre
              "
              >*</span
            >
            {{ $t("warehouse.reservation_file") }}
          </template>

          <template #upload="{ record }">
            <!-- <UploadLetter
              :disabled="true"
              accept=" .jpeg,.jpg,.png,.pdf"
              v-model:showFileUrl="record.scheduleFileUrl"
              @handleUpdateFile="handleUpdateFile(record)"
            ></UploadLetter>
             -->
             <CFileDisplay :fileUrl="record.scheduleFileUrl" v-if="record.scheduleFileUrl" :disImgProcess="true"></CFileDisplay>
             <span v-else>-</span>
          </template>

          <template #shipment_transit_status="{ record }">
           {{
                    $t($enumLangkey("transitShipmentStatus", record.transitShipmentStatus))
                  }}
          </template>

          <template #footer>
            <a-row :gutter="[12, 12]">
              <a-col
                >{{ $t("common.total") }}:
                {{
                  $t("warehouse.p0_outbound_shipments", [
                    data.transitTotalInfo?.shipmentTotalCount?.plan,
                  ])
                }}</a-col
              >
              <a-col
                >{{ data.transitTotalInfo?.boxCount?.plan ?? "-"
                }}{{ $t("warehouse.box") }}</a-col
              >
              <a-col v-if="data.transitTotalInfo?.volume?.plan"
                >{{ cmCubicToM(data.transitTotalInfo?.volume?.plan) }}
                <span>m<sup>3</sup></span>
              </a-col>
              <a-col v-if="data.transitTotalInfo?.weight?.plan">
                {{ gToKg(data.transitTotalInfo?.weight?.plan) }}
                <span>kg</span>
              </a-col>
            </a-row>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :loading="record.loading"
              size="small"
              :data-source="record?.innerData"
              :pagination="false"
              :rowKey="
                (record, index) => {
                  return index;
                }
              "
            >
              <template #out_data="{ record }">
                {{ record.checkBoxSize?.length }}x
                {{ record.checkBoxSize?.width }}x
                {{ record.checkBoxSize?.height }}cm |
                {{ cmCubicToM(record.checkBoxSize?.volume) }} m<sup>3</sup>
                <div>{{ gToKg(record.checkBoxSize?.weight) }}kg</div>
              </template>
            </a-table>
          </template>
        </a-table>

        <!-- 打托服务 -->
        <div class="mt-2" v-if="data.daycareServiceId > 0">
          {{ $t("warehouse.hit_torr_serve") }}:
          <!-- 后端接口上说 大于0表示有打托 -->
          <span>
            {{ data.daycareService?.packMaterialName }} -
            {{ data.daycareService?.materialTypeName }}
            -
            {{ data.daycareService?.packMaterialLength }}x{{
              data.daycareService?.packMaterialWidth
            }}x{{ data.daycareService?.packMaterialHeight }}cm
            {{ $t("warehouse.tray_volume_restriction") }}:
            {{ cmCubicToM(data.daycareService?.packMaterialVolume) }}m<sup
              >3</sup
            >
            {{ $t("warehouse.tray_weight_limit") }}:
            {{ gToKg(data.daycareService?.packBearingQuantity) }}kg
          </span>
        </div>

        <div class="mt-2">
          <!-- 运输方式 -->
          {{ $t("warehouse.type_of_shipping") }}:
          {{
            $t(
              $enumLangkey("transitDeliveryMethod", data?.transitDeliveryMethod)
            )
          }}

          <!-- 物流方案 -->
          <div class="mt-3" v-if="data?.transitDeliveryMethod==transitDeliveryMethodEnum.seLogistics">
            <p>
              <strong>{{ $t("logistics.logistics_channel") }}</strong>
            </p>
            <div class="mb-1">
              <a-table
                :columns="data.logistics?.columnsLogisticsScheme"
                :data-source="data.logistics?.list"
                :scroll="{ x: true }"
                size="small"
                :pagination="false"
              >
                <template #logisticsScheme>
                  <span>
                    {{ $t("warehouse.logistics_scheme") }} /
                    {{ $t("warehouse.logistics_code") }}
                  </span>
                </template>

                <template #logisticsWay>
                  <span>
                    {{ $t("warehouse.logistics_type") }}
                  </span>
                </template>

                <template #deliveryWay>
                  <span>
                    {{ $t("warehouse.delivery_way") }}
                  </span>
                </template>

                <template #referenceAging>
                  <span>
                    {{ $t("warehouse.reference_aging") }}
                  </span>
                </template>
                <template #freights>
                  <span v-if="!data.fee">
                    {{ $t("logistics.freight_forecast_no_audit") }}
                  </span>
                  <span v-else>
                    {{ $t("logistics.freight") }}
                  </span>
                </template>

                <template #details>
                  <span>
                    {{ $t("common.details") }}
                  </span>
                </template>
                <template #optionalServices>
                  <span>
                    {{ $t("logistics.selected_service") }}
                  </span>
                </template>
                <template #logisticsSchemeCustom="{ record }">
                  <div class="text-warning">
                    {{ record.scheme }} / {{ record.code }}
                  </div>
                  <a-row :gutter="[8, 8]">
                    <a-col v-for="item in record.descriptionItems" :key="item">
                      <CheckCircleOutlined
                        class="text-warning mr-1"
                        :style="{ fontSize: '12px' }"
                      />
                      <span
                        style="font-size: 12px; color: rgba(102, 102, 102, 1)"
                      >
                        {{ item }}
                      </span>
                    </a-col>
                  </a-row>
                </template>
                <template #logisticsWayCustom="{ record }">
                  {{ $t($enumLangkey("logisticsWay", record.logisticsWay)) }}
                </template>
                <template #deliveryWayCustom="{ record }">
                  {{ $t($enumLangkey("deliveryWay", record.deliveryWay)) }}
                </template>
                <template #referenceAgingCustom="{ record }">
                  <div>
                    {{ record.daysMin }}-{{ record.daysMax }}
                    {{ $t("warehouse.workday") }}
                    ({{ $t($enumLangkey("aging", record.channelTimeType)) }})
                  </div>
                </template>
                <template #freightsCustom="{ record }">
                  <div class="text-warning">
                    {{ $t("warehouse.average_unit_price") }}:
                    {{ record.currencySymbol
                    }}{{ $filters.amountToFixed2(record.averagePrice) }}
                  </div>
                  <div class="text-warning">
                    {{ $t("warehouse.total_prices") }}:
                    {{ record.currencySymbol
                    }}{{ $filters.amountToFixed2(record.expressFee) }}
                  </div>
                </template>
                <template #detailsCustom="{ record }">
                  <div v-if="record.logisticsCalcMethod == 0">
                    {{ $t("logistics.chargeable_weight") }}:
                    {{ record.chargeableWeight }}kg
                  </div>
                  <div v-else>
                    {{ $t("logistics.chargeable_volume") }}:
                    {{ record.chargeableVolume }}m<sup>3</sup>
                  </div>
                  <div>
                    {{ $t("logistics.volume_weight_coefficient")
                    }}{{ record.volumeWeightParam }}
                  </div>
                </template>
                <template #optionalServicesCustom="{ record }">
                  <template
                    v-if="
                      record.logisticsRegionInfo.isChooseDdp ||
                      record.logisticsRegionInfo.isChooseSign
                    "
                  >
                    <div v-if="record.logisticsRegionInfo.isChooseDdp">
                      DDP({{ record.currencySymbol
                      }}{{
                        $filters.amountToFixed2(
                          record.logisticsRegionInfo.ddpFee
                        )
                      }})
                    </div>
                    <CSignSupported
                      v-if="record.logisticsRegionInfo.isChooseSign"
                      v-model:value="record.logisticsRegionInfo.signatureType"
                      type="readonly"
                      :symbol="record.currencySymbol"
                      :signFeeOptions="record.logisticsRegionInfo.signFeeDtos"
                    />
                  </template>
                  <span v-else>-</span>
                </template>
                <!-- 卡车专属 -->
                <template #quoteOptionsTitle="{ record }">
                  {{ $t("logistics.quote_options") }}
                  <CTooltip :title="$t('logistics.quote_options_tips')" />
                </template>
                <template #quoteOptions="{ record }">
                  <a-row :gutter="[16, 8]">
                    <a-col :span="24">
                      <a-row type="flex">
                        <a-col flex="100px">{{
                          $t("logistics.quote_type")
                        }}</a-col>
                        <a-col flex="1">
                          <span>{{
                            record.truckBaseInfo.quoteType?.name
                          }}</span>
                        </a-col>
                      </a-row>
                    </a-col>
                    <a-col :span="24" v-if="record.truckBaseInfo?.serviceLevel">
                      <a-row type="flex">
                        <a-col flex="100px">{{
                          $t("logistics.service_level")
                        }}</a-col>
                        <a-col flex="1">
                            <span>{{
                              record.truckBaseInfo?.serviceLevel?.name
                            }}</span>
                        </a-col>
                      </a-row>
                    </a-col>
                    <a-col :span="24">
                      <a-row type="flex">
                        <a-col flex="100px">{{
                          $t("logistics.additional_options")
                        }}</a-col>
                        <a-col flex="1">
                          <a-popover trigger="hover">
                            <template #content>
                              <div style="max-width: 300px">
                                {{
                                  getAccessorialsName(
                                    record.truckBaseInfo.accessorials
                                  )
                                }}
                              </div>
                            </template>
                            <div
                              style="
                                max-width: 250px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                              "
                            >
                              {{
                                getAccessorialsName(
                                  record.truckBaseInfo.accessorials
                                )
                              }}
                            </div>
                          </a-popover>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
                <template #quoteDetails="{ record }">
                  <span v-if="record.truckBaseInfo"
                    >{{ record.truckBaseInfo.carrierName }} [{{
                      $filters.formatCurrencyAmount(
                        record.truckBaseInfo.currencySymbol,
                        record.truckBaseInfo.totalCharge,
                        false
                      )
                    }},
                    {{ handleParseTime(record.truckBaseInfo.transitTime) }}
                    day(s)]</span
                  >
                  <span v-else>-</span>
                </template>
              </a-table>
              <div class="mt-2">
                <a-tag color="warning" v-if="data.logistics?.isRemoteArea">{{
                  $t("logistics.remote_site")
                }}</a-tag>
                <template v-if="data.logistics?.isCommercial != null">
                  <a-tag color="warning" v-if="data.logistics?.isCommercial">{{
                    $t("logistics.business_site")
                  }}</a-tag>
                  <a-tag color="warning" v-else>{{
                    $t("logistics.residence")
                  }}</a-tag>
                </template>
              </div>
            </div>
          </div>
          <div v-if="data.planLogistic?.logisticsRegionInfo?.isVatRequired">
            <div>
              <label>VAT: </label>
              <span>{{ data.planLogistic?.logisticsRegionInfo?.vatCode }}</span>
            </div>
          </div>

          <!-- 选择时间类型 -->
          <div
            class="mt-2"
            v-if="
              data.transitDeliveryMethod ==
              transitDeliveryMethodEnum.seLogistics
            "
          >
            {{ $t("warehouse.time_type") }}:
            {{
              $t(
                $enumLangkey("scheduleDeliveryType", data.scheduleDeliveryType)
              )
            }}
          </div>
          <div
            v-if="
              data.transitDeliveryMethod ==
                transitDeliveryMethodEnum.takeTheir ||
              (data.transitDeliveryMethod ==
                transitDeliveryMethodEnum.seLogistics &&
                data.scheduleDeliveryType != scheduleDeliveryTypeEnum.anytime)
            "
          >
            <span
              v-if="
                data.transitDeliveryMethod ==
                transitDeliveryMethodEnum.takeTheir
              "
            >
              {{ $t("warehouse.pick_up_time") }}:
            </span>
            <span
              v-if="
                data.planLogistic?.logisticsBaseInfo?.feeCalculationMethod ==
                feeCalculationMethodEnum.template
              "
            >
              {{ $t("warehouse.shipping_time") }}:
            </span>
            <span
              v-if="
                data.planLogistic?.logisticsBaseInfo?.feeCalculationMethod ==
                feeCalculationMethodEnum.truckApi
              "
            >
              {{ $t("warehouse.delivery_time") }}:
            </span>

            <span
              v-if="data.scheduleDeliveryType == scheduleDeliveryTypeEnum.date"
            >
              {{
                $filters.utcToSpecificTime(
                  data.scheduleDeliveryTime,
                  data.warehouseTimezone,
                  "yyyy-MM-DD"
                )
              }}
            </span>
            <span
              v-if="
              !data.scheduleDeliveryType||
                data.scheduleDeliveryType ==
                scheduleDeliveryTypeEnum.dateTime
              "
            >
              {{
                $filters.utcToSpecificTime(
                  data.scheduleDeliveryTime,
                  data.warehouseTimezone
                )
              }}
            </span>

            <a-tag color="warning" class="mt-1 ml-1">{{
              $t("warehouse.local_time")
            }}</a-tag>
          </div>
        </div>

        <!-- 费用明细与取消出库计划-->
        <div class="mt-3" v-if="data.fee">
          <CFee :fees="data.fee?.fees" :totalFee="{symbol:data.fee?.currencySymbol,value:data.fee?.totalAmount}"></CFee>

        </div>
        <a-row type="flex" justify="end" class="mt-3 mb-5">
          <a-col>
            <div style="width: 400px">
              <a-row type="flex" justify="center" :gutter="[24, 12]">
                <!-- <a-col>
                  <a-popconfirm
                    v-if="data.cancelled"
                    :title="$t('common.are_you_sure')"
                    :loading="data.cancelPlanLoading"
                    @confirm="handleCancelPlan"
                  >
                    <a-button>{{ $t("warehouse.cancel_plan") }}</a-button>
                  </a-popconfirm>
                </a-col> -->
                <a-col>
                  <a-button @click="handleBackList">{{
                    $t("common.backtrack")
                  }}</a-button>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted,
  onActivated,
  computed
} from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  Form,
  DatePicker,
  Space,
  Steps,
  message,
  Popconfirm,
  Modal,
  Checkbox,
  TimePicker,
  Typography,
  Popover,
  Tabs,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CTooltip from "@/views/components/CTooltip.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import CFee from "@/views/components/CFee.vue";
import CSignSupported from "@/views/components/CSignSupported.vue";
import moment from "moment-timezone";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useTab } from "@/hooks/tabs/index";
import {
  getLinkman,
  getAddressByLanguageV2,
  cmCubicToM,
  gToKg,
  kgToG,
} from "@/utils/general";
import {
  getShipmentDetailList,
  cancelOutPlan,
  getDetail,
} from "@/api/modules/warehouseMonitor/transit";
import {
  destinationType as destinationTypeEnum,
  deliveryWay as deliveryWayEnum,
  transitDeliveryMethod as transitDeliveryMethodEnum,
  scheduleDeliveryType as scheduleDeliveryTypeEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  transitOutBoundUserStatus as transitOutBoundUserStatusEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "warehouse_monitor_transit_outbound_detail",
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ATooltip: Tooltip,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ADatePicker: DatePicker,
    ATimePicker: TimePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ASteps: Steps,
    AStep: Steps.Step,
    APopconfirm: Popconfirm,
    AModal: Modal,
    ACheckbox: Checkbox,
    ATypographyText: Typography.Text,
    APopover: Popover,
    Content,
    CPager,
    CFee,
    CTooltip,
    CFileDisplay,
    CSignSupported,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const { delVisitedRoute } = useTab();
    const route = useRoute();

    const data = reactive({
      loading: false,
      cancelPlanLoading: false,
      planName: null,
      total: {},
      daycareServiceId: null, //打托服务
      transitDeliveryMethod: null, //物流方式
      scheduleDeliveryType: null, //时间类型

      warehouseNo: null,
      inventoryWarehouseAddress: null, //库存仓库地址对象
      targetAddress: null,
    });

    const getAddress = (address) => {
      if (!address) {
        return;
      }
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleGetRowDetail = (expanded, record) => {
      if (
        expanded &&
        record?.id &&
        (!record?.innerData || record?.innerData?.length == 0)
      ) {
        record.loading = true;
        getShipmentDetailList({ shipmentId: record.id })
          .then(({ result }) => {
            if (Array.isArray(result)) {
              record.innerData = result;
            } else {
              record.innerData = [];
            }
            record.loading = false;
          })
          .catch(() => {
            record.loading = false;
          });
      }
    };

    const columnsBase = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_name"),
        dataIndex: "name",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.se_shipment_number"),
        dataIndex: "seShipmentNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_volume"),
        slots: {
          customRender: "shipment_volume",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_weight"),
        slots: {
          customRender: "shipment_weight",
        },
        width: 100,
      },
         {
        title: () => vueI18n.t("warehouse.shipment_status"),
        slots: {
          customRender: "shipment_transit_status",
        },
        width: 100,
      },
      {
        className: "column-reservation-file",
        slots: {
          title: "uploadTitle",
          customRender: "upload",
        },
        width: 100,
      },
    
    ];

    const innerColumns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        title: () => vueI18n.t("warehouse.identifiable_box_number"),
        dataIndex: "canScanedBoxNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_id"),
        dataIndex: "boxNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.out_data"),
        slots: {
          customRender: "out_data",
        },
        width: 100,
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 400,
      },
      {
        dataIndex: "logisticsWay", //物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay", //交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging", //参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 100,
      },
      {
        dataIndex: "freights", //运费
        slots: {
          title: "freights",
          customRender: "freightsCustom", //平均单价和合计
        },
        width: 220,
      },
      {
        dataIndex: "details", //详情
        slots: {
          title: "details",
          customRender: "detailsCustom", //计费重、体积重系数：除600
        },
        width: 180,
      },
      {
        dataIndex: "optionalServices", //可选服务
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 180,
      },
    ];

    // 卡车可选服务
    const columnsLogisticsTruck = [
      {
        width: 200,
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom", //物流名称和基础服务
        },
      },
      {
        width: 100,
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
      },
      {
        width: 200,
        slots: {
          title: "quoteOptionsTitle",
          customRender: "quoteOptions",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.quote_details"),
        slots: {
          customRender: "quoteDetails",
        },
      },
    ];

    const handleGetDetail = (id) => {
      data.loading = true;
      getDetail(id)
        .then(({ result }) => {
          Object.assign(data, result);
          data.fee.fees=data.fee.fees?.map(x=>{
            return{
              symbol:data.fee?.currencySymbol,
              value:x.amount,
              feeType:x.feeType,
            }
          })
          data.transitBoundPlanAddress?.forEach((x) => {
            //addressType=1  发货地址，addressType=2  目的地址 后端说的
            if (x.addressType == 1) {
              data.inventoryWarehouseAddress = x;
            } else if (x.addressType == 2) {
              data.targetAddress = x;
            }
          });

          data.logistics = {
            list: [],
            columnsLogisticsScheme: null,
            isRemoteArea: null,
            isCommercial: null,
          };

          let logisticsTemp = result.planLogistic ? [result.planLogistic] : [];
          data.logistics.list = logisticsTemp?.map((x, index) => {
            return {
              key: index,
              id: x.logisticsBaseInfo.id,
              scheme: x.logisticsBaseInfo.name,
              code: x.logisticsBaseInfo.code,
              descriptionItems: x.logisticsBaseInfo.descriptionItems,

              logisticsWay: x.logisticsBaseInfo.logisticsType,
              deliveryWay: x.logisticsBaseInfo.deliveryType,

              daysMin: x.logisticsBaseInfo.daysMin,
              daysMax: x.logisticsBaseInfo.daysMax,
              channelTimeType: x.logisticsBaseInfo.channelTimeType, //签收或自提 枚举

              averagePrice: x.logisticsBaseInfo.averagePrice, //平均费
              expressFee: x.logisticsBaseInfo.totalFeeWithoutServices, //合计费用
              currencySymbol: x.logisticsBaseInfo.currencySymbol, //货号符号

              chargeableWeight: x?.logisticsBaseInfo?.chargeableWeight
                ? gToKg(x.logisticsBaseInfo.chargeableWeight)
                : "0", //计费重
              volumeWeightParam: x.logisticsBaseInfo.volumeWeightParam, //体积系数
              chargeableVolume: cmCubicToM(
                x.logisticsBaseInfo.chargeableVolume
              ),
              logisticsCalcMethod: x.logisticsBaseInfo.logisticsCalcMethod,
              logisticsRegionInfo: x.logisticsRegionInfo, //是否支持DDP和签字

              // 卡车相关
              feeCalculationMethod: x.logisticsBaseInfo?.feeCalculationMethod,
              truckBaseInfo: x.logisticsBaseInfo?.truckBaseInfo,
            };
          });

          if (data.logistics.list?.length) {
            let logisticsSchemeItem = data.logistics.list[0];
            if (
              logisticsSchemeItem.feeCalculationMethod ===
              feeCalculationMethodEnum.template
            ) {
              // 物流
              data.logistics.columnsLogisticsScheme = columnsLogisticsScheme;
            } else {
              // 卡车
              data.logistics.columnsLogisticsScheme = columnsLogisticsTruck;
            }
          }

          data.logistics.isRemoteArea =
            result.planLogistic?.logisticsBaseInfo?.isRemoteArea;
          data.logistics.isCommercial =
            result.planLogistic?.logisticsBaseInfo?.isCommercial;
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const handleBackList = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "warehouse_monitor_transit_outbound" });
    };

    const handleCancelPlan = () => {
      data.cancelPlanLoading = true;
      cancelOutPlan(data.id)
        .then(({ result }) => {
          message.success(vueI18n.t("common.succeed"));
          data.cancelPlanLoading = false;
          handleBackList();
        })
        .catch(() => {
          data.cancelPlanLoading = false;
        });
    };

    const getAccessorialsName = (list) => {
      if (!Array.isArray(list)) {
        return "-";
      }
      return list.map((item) => item.name).join(", ");
    };

    const handleParseTime = (num) => {
      let number = Number.parseInt(num);
      return Number.isNaN(number) ? num : number;
    };

    const handleUpdateFile = (record) => {
      // updateScheduleFile(data.id, record.id, record.scheduleFileUrl)
      //   .then(() => {
      //     message.success(vueI18n.t("common.succeed"));
      //   })
      //   .catch(() => {});
    };

     const columns = computed(() => {
      let result = [];
      if ( data.isRepresented) {
        const columnCustomer = {
          title: () => vueI18n.t("warehouse.third_party_customer"),
          slots: {
            customRender: "proxy_tenant_info",
          },
          width: 100,
        };
        result = [...columnsBase.map((item) => ({ ...item }))];
        result.splice(2, 0, columnCustomer);
      } else {
        result = columnsBase;
      }
      return result;
    });

    onMounted(() => {
      handleGetDetail(route.params.id);
    });

    onActivated(() => {});

    return {
      data,
      destinationTypeEnum,
      deliveryWayEnum,
      transitDeliveryMethodEnum,
      scheduleDeliveryTypeEnum,
      feeCalculationMethodEnum,
      transitOutBoundUserStatusEnum,
      moment,
      columns,
      innerColumns,
      cmCubicToM,
      gToKg,
      kgToG,
      getAddress,
      getLinkman,

      handleGetRowDetail,

      handleBackList,
      handleCancelPlan,
      handleUpdateFile,
      columnsLogisticsScheme,
      columnsLogisticsTruck,
      getAccessorialsName,
      handleParseTime,
    };
  },
});
</script>

<style lang="less" scoped></style>

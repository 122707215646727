<template>
  <a-modal
    v-model:visible="visible"
    :centered="true"
    :mask-closable="false"
    :confirmLoading="loading"
    :title="$t('common.operate')"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('account.the_refund_amount')" name="refundAmount">
        <div style="display: flex; align-items: center;">
          <label style="padding: 0 5px;">{{ currencySymbol }}</label>
          <a-input-number style="flex: 1;" :min="0" :max="max" v-model:value="formState.refundAmount" />
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue';
import { Modal, Form, Input, InputNumber } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import BigNumber from "bignumber.js";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInputGroup: Input.Group,
    AInputNumber: InputNumber,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref();

    const state = reactive({
      visible: false,
      loading: false,
      currencySymbol: null,
      max: null,
      freezeOverdraftFacility: null,
      formState: {
        balanceId: null,
        refundAmount: null,
      }
    })

    const rules = {
      refundAmount: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('account.the_refund_amount')]),
      }
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        const result = {
          data: JSON.parse(JSON.stringify(state.formState)),
          status: state.freezeOverdraftFacility > state.max
        }
        emit('confirm', result);
      } catch (error) {
      }
    }

    const close = () => {
      state.visible = false
    }

    const init = (balanceId, currencySymbol, refundAmount, freezeOverdraftFacility = 0) => {
      state.currencySymbol = currencySymbol
      // state.max = freezeOverdraftFacility > 0 ? (new BigNumber(refundAmount).minus(freezeOverdraftFacility).toNumber()) : refundAmount;
      state.max = refundAmount;
      state.freezeOverdraftFacility = freezeOverdraftFacility;
      state.visible = true
      nextTick(() => {
        formRef.value.resetFields();
        state.formState = {
          balanceId,
          refundAmount: state.max,
        }
      })
    }

    return {
      ...toRefs(state),
      formRef,
      rules,
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      handleConfirm,
      close,
      init,
    }
  },
})
</script>

<template>
  <a-modal
    v-model:visible="state.visible"
    :title="$t('finance.internal_remarks')"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.loading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="state.formState"
      :rules="rules"
    >
      <a-form-item label="" :colon="false" name="value">
        <a-textarea
          v-model:value="state.formState.value"
          :placeholder="$t('finance.append_internal_remarks')"
          :auto-size="{ minRows: 4, maxRows: 6 }"
          :maxlength="500"
          show-count
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { Form, Input, message, Modal } from 'ant-design-vue';
import { useI18n } from 'vue-i18n/index';
import { updateInternalRemark } from "@/api/modules/finance/recharge.js";

export default defineComponent({
  emits: ['update:visible', 'confirm'],
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ATextarea: Input.TextArea,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    remark: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: 'global' });
    const formRef = ref(null);
    
    const state = reactive({
      visible: false,
      loading: false,
      formState: {
        value: null,
      },
    });

    const validateValue = (rule, value) => {
      value = value.trim();
      
      if (!value) {
        return Promise.reject(vueI18n.t("common.p0_is_required", [vueI18n.t("finance.append_internal_remarks")]));
      }
      return Promise.resolve();
    }
    
    const rules = {
      value: [
        { validator: validateValue },
      ],
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.loading = true;
        let remark = props.remark ? props.remark + '\n' : '';
        let value = state.formState.value.trim();
        const data = {
          id: props.id,
          internalRemark: remark + value,
        }
        await updateInternalRemark(data);
        message.success(vueI18n.t('common.succeed'));
        emit('confirm');
        close();
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => props.visible, (visible) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          nextTick(() => {
            formRef.value.resetFields();
          });
        }
      }
    })

    return {
      state,
      rules,
      formRef,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>
const nameKeys = {
  token: "ACCESS-TOKEN",
  userInfo: "USER-INFO",
  userRoutes: "USER-ROUTES",
  timezone: "USER-TIMEZONE",
};

const extensionKeys = {
  confLang: "CONF-LANG",
};

const headerKeys = {
  authorization: "Authorization",
  bearer: "Bearer",
  customCulture: "custom-culture",
};

export {
  nameKeys,
  headerKeys,
  extensionKeys,
};

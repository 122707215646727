import client from "../../client"

const Api = {
  // 获取分页列表
  getLogisticsRebateConfigListPaged: "/api/LogisticsService/LogisticsRebateConfig/GetListPaged",
  // 获取详情
  getLogisticsRebateConfigDetailById: "/api/LogisticsService/LogisticsRebateConfig/GetDetailById",
  // 创建返利配置
  createLogisticsRebateConfig: "/api/LogisticsService/LogisticsRebateConfig/Create",
  // 修改返利配置
  updateLogisticsRebateConfig: "/api/LogisticsService/LogisticsRebateConfig/Update",
  // 获取配置集合
  getLogisticsRebateConfigList: "/api/LogisticsService/LogisticsRebateConfig/GetList",
  // 启用/禁用
  editLogisticsRebateConfigActive: "/api/LogisticsService/LogisticsRebateConfig/EditActive",
}

export const getLogisticsRebateConfigListPaged = (data) => {
  return client.request({
    url: Api.getLogisticsRebateConfigListPaged,
    data,
    method: 'POST'
  })
}

export const getLogisticsRebateConfigDetailById = (params) => {
  return client.request({
    url: Api.getLogisticsRebateConfigDetailById,
    params,
    method: 'GET'
  })
}

export const createLogisticsRebateConfig = (data) => {
  return client.request({
    url: Api.createLogisticsRebateConfig,
    data,
    method: 'POST'
  })
}

export const updateLogisticsRebateConfig = (data) => {
  return client.request({
    url: Api.updateLogisticsRebateConfig,
    data,
    method: 'POST'
  })
}

export const getLogisticsRebateConfigList = (data) => {
  return client.request({
    url: Api.getLogisticsRebateConfigList,
    data,
    method: 'POST'
  })
}

export const editLogisticsRebateConfigActive = (data) => {
  return client.request({
    url: Api.editLogisticsRebateConfigActive,
    data,
    method: 'POST'
  })
}
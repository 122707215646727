<template>
  <Content>
    <template #contentTitle
      >{{ $t("menu.logistics_channel_discount") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 8]">
        <a-col
          >{{ $t("logistics.logistics_scheme_name") }}:
          {{ logisticsData.name }}</a-col
        >
        <a-col
          >{{ $t("logistics.logistics_scheme_code") }}:
          {{ logisticsData.code }}</a-col
        >
      </a-row>
      <div style="height: 15px; width: 100%"></div>
      <a-row :gutter="[16, 8]">
        <a-col :xl="4" :lg="6" :md="8" :sm="12" :xs="24">
          <a-select
            style="width: 100%"
            v-model:value="searchData.shopLevelId"
            :placeholder="$t('logistics.customer_level')"
            optionFilterProp="search-key"
            show-search
            allowClear
          >
            <a-select-option
              v-for="(item, index) in shopLevelList"
              :key="index"
              :value="item.id"
              :title="item.name"
              :search-key="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :xl="6" :lg="8" :md="10" :sm="12" :xs="24">
          <a-space align="center">
            <a-input-group>
              <a-input-number
                class="input-group-mid-number"
                type="number"
                :min="0"
                :max="999"
                v-model:value="searchData.discountStart"
                @blur="handleStartBlur"
              ></a-input-number>
              <span class="ant-input-group-addon">%</span>
            </a-input-group>
            {{ $t('logistics.to') }}
            <a-input-group>
              <a-input-number
                class="input-group-mid-number"
                type="number"
                :min="0"
                :max="1000"
                v-model:value="searchData.discountEnd"
                @blur="handleEndBlur"
              ></a-input-number>
              <span class="ant-input-group-addon">%</span>
            </a-input-group>
          </a-space>
        </a-col>
        <a-col
          :xl="{ span: 10, offset: 0 }"
          :lg="{ span: 4, offset: 0 }"
          :md="{ span: 3, offset: 0 }"
          :sm="{ span: 4, offset: 16 }"
          :xs="{ span: 7, offset: 12 }"
        >
          <a-button type="primary" @click="handleSearch">{{
            $t("common.query")
          }}</a-button>
        </a-col>
        <a-col
          :xl="4"
          :lg="6"
          :md="3"
          :sm="2"
          :xs="5"
          style="text-align: right"
        >
          <a-button @click="handleShowModal()">{{
            $t("common.addition")
          }}</a-button>
        </a-col>
      </a-row>
      <a-table
        style="margin-top: 20px"
        size="small"
        :columns="columns"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 130 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #freightDiscountAmount="{ record }">
          {{ $filters.formatCurrencyAmount(logisticsData.currencySymbol, record.freightDiscountAmount, false) }}
        </template>
        <template #freightDiscountRatio="{ record }">
          {{ record.freightDiscountRatio }}%
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #lastModificationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operation="{ record }">
          <a-space>
            <a-popconfirm :title="$t('region.delete_confirm') + '?'" @confirm="handleDelete(record.id)">
              <template #icon
                ><question-circle-outlined style="color: red"
              /></template>
              <a-button type="link" danger>{{
                $t("common.delete")
              }}</a-button>
            </a-popconfirm>
            <a-button type="link" @click="handleShowModal(record)">{{
              $t("common.edit")
            }}</a-button>
          </a-space>
        </template>
      </a-table>
      <DiscountFormModal
        ref="discountFormModalRef"
        @refresh="getList"
      />
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import {
  Table,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Space,
  Popconfirm,
  message,
} from "ant-design-vue";
import CPager from "@/views/components/CPager.vue";
import Content from "@/views/components/Content.vue";
import DiscountFormModal from "./components/DiscountFormModal.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import {
  getPagedList,
  deleteLogisticsDiscount,
} from "@/api/modules/logistics/discount.js";
import { getShopLevelList } from "@/api/modules/customer/level/index.js";
import { getById as getLogisticsById } from "@/api/modules/logistics/index";

export default defineComponent({
  name: "logistics_discount",
  components: {
    ATable: Table,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASpace: Space,
    ASelectOption: Select.Option,
    APopconfirm: Popconfirm,
    AInputGroup: Input.Group,
    CPager,
    Content,
    DiscountFormModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const discountFormModalRef = ref();

    const state = reactive({
      shopLevelList: [],
      searchData: {
        shopLevelId: null,
        discountStart: null,
        discountEnd: null,
      },
      searchDataCache: {},
      logisticsData: {
        name: "",
        code: "",
        currencySymbol: "",
      },
      tableData: {
        loading: false,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: "shopLevelName",
        width: 150,
        title: () => vueI18n.t("logistics.customer_level"),
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.discount_amount"),
        slots: {
          customRender: "freightDiscountAmount"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.discount"),
        slots: {
          customRender: "freightDiscountRatio"
        }
      },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.creation_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 200,
        title: () => vueI18n.t("logistics.last_modification_time"),
        slots: {
          customRender: "lastModificationTime",
        },
      },
      {
        width: 150,
        slots: {
          customRender: "operation",
        },
      },
    ];

    const handleShowModal = (record = null) => {
      discountFormModalRef.value.init(route.params.id, record, state.logisticsData.currencySymbol);
    };

    const handleDelete = (id) => {
      const data = {
        ids: [id],
      }
      deleteLogisticsDiscount(data).then(() => {
        message.success(vueI18n.t('common.succeed'))
        initData();
      })
    };

    const getLogisticsInfo = () => {
      getLogisticsById(route.params.id)
        .then(({ result }) => {
          state.logisticsData.name = result.name;
          state.logisticsData.code = result.code;
          state.logisticsData.currencySymbol = result.currencySymbol;
        })
        .catch(() => {
          tableData.loading = false;
        });
    };

    const getList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchDataCache,
        {
          logisticsId: route.params.id,
        }
      );
      getPagedList(data)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getList();
    };

    const handleSearch = () => {
      Object.assign(state.searchDataCache, state.searchData);
      initData();
    };

    const initData = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getList();
    };

    const getShopLevelListArray = () => {
      getShopLevelList().then((res) => {
        let { result } = res
        if (result) {
          state.shopLevelList = result
        }
      })
    }

    const handleStartBlur = (e) => {
      let discountStart = state.searchData.discountStart
      let discountEnd = state.searchData.discountEnd
      if (!discountStart) {
        discountStart = null
      } else {
        if (discountEnd) {
          if (discountStart > discountEnd) {
            if (discountStart > 1) {
              discountStart = discountEnd - 1
            } else {
              discountStart = 0
            }
          }
        }
      }
      state.searchData.discountStart = discountStart
    }

    const handleEndBlur = (e) => {
      let discountStart = state.searchData.discountStart
      let discountEnd = state.searchData.discountEnd
      if (!discountEnd) {
        discountEnd = null
      } else {
        if (discountStart) {
          if (discountStart > discountEnd) {
            if (discountStart < 99) {
              discountEnd = discountStart + 1
            } else {
              discountEnd = 100
            }
          }
        }
      }
      state.searchData.discountEnd = discountEnd
    }

    onMounted(() => {
      getShopLevelListArray();
      getLogisticsInfo();
      initData();
    });

    return {
      ...toRefs(state),
      discountFormModalRef,
      columns,
      getList,
      handlePage,
      handleSearch,
      handleShowModal,
      handleDelete,
      handleStartBlur,
      handleEndBlur,
    };
  },
});
</script>

<style lang="less" scoped>
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
:deep(.input-group-mid-number) {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.input-group-end-number) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
:deep(.input-group-start-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
</style>
<template>
  <a-upload
    name="uploadFile"
    :customRequest="handleUpload"
    :accept="accept"
    :showUploadList="false"
  >
    <a-button
      v-if="state.files.length < maxFileNumber"
      size="small"
      :loading="state.uploadLoading"
      >{{ $t("common.upload_file") }}</a-button
    >
  </a-upload>

  <div style="position: relative">
    <a-row :gutter="[8, 8]">
      <a-col v-for="(item, index) in state.files" :key="index">
        <CFileDisplay :fileUrl="item"></CFileDisplay>
        <a-tooltip placement="right">
          <template #title>{{ $t("common.delete") }}</template>
          <span class="delete-label" @click="deleteFile(index)">
            <DeleteOutlined />
          </span>
        </a-tooltip>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { reactive, toRefs, watchEffect, watch } from "vue";
import { uploadFile } from "@/api/modules/common/index";
import urlHelper from "@/utils/urlHelper";
import {
  Row,
  Col,
  Upload,
  Tooltip,
  Select,
  Button,
  Form,
} from "ant-design-vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";

export default {
  components: {
    CFileDisplay,
    ATooltip: Tooltip,
    AUpload: Upload,
    AImage: Image,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
  },
  props: {
    accept: {
      type: String,
      default: "",
    },
    fileUrl: {
      type: String,
      default: null,
    },
    maxFileNumber: {
      type: Number,
      default: 1,
    },
    functionModule: {
      type: Number,
      default: 28,
    },
    emits: ["update:fileUrl"],
  },
  setup(props, { emit }) {
    const state = reactive({
      uploadLoading: false,
      files: [],
    });

    watchEffect(() => {
      if (props.fileUrl) {
        state.files = [props.fileUrl];
      } else {
        state.files = [];
      }
    });

    const handleUpload = (file) => {
      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", props.functionModule); //类型
      uploadFile(parameter)
        .then(async (res) => {
          if (res.result && res.result.length > 0) {
            await emit("update:fileUrl", res.result[0].url);
          }
        })
        .finally(() => {
          state.uploadLoading = false;
        });
    };

    const deleteFile = async (itemIndex) => {
      await emit("update:fileUrl", null);
    };

    return {
      state,
      handleUpload,
      deleteFile,
      urlHelper,
    };
  },
};
</script>

<style lang="less" scoped>
.delete-label {
  position: absolute;
  top: 100%;
  left: 40%;
  font-size: 15px;
  color: #d9d9d9;
  :hover {
    color: #04befe;
    //font-size: 20px;
  }
}
</style>

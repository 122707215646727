<template>
  <Content>
    <template #contentTitle>{{ $t('menu.transport_shipment_cabin_detail') }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[8, 8]" type="flex" align="middle">
          <a-col :xxl="18" :xl="16" :span="24">
            <a-row :gutter="[8, 8]">
              <a-col :xl="8" :span="24">
                <a-typography-text strong>{{ $t('warehouse.cabin_name') }}: </a-typography-text>
                <span>{{ info.shippingSpaceName || '-' }}</span>
              </a-col>
              <a-col :xl="8" :span="24">
                <a-typography-text strong>{{ $t('warehouse.cabin_no') }}: </a-typography-text>
                <span>{{ info.shippingSpaceNo || '-' }}</span>
              </a-col>
              <a-col :xl="8" :span="24">
                <a-typography-text strong>{{ $t('warehouse.cabin_type') }}: </a-typography-text>
                <span>{{ $t($enumLangkey('shippingSpaceType', info.shippingSpaceType)) }}</span>
              </a-col>
              <a-col :xl="12" :span="24">
                <a-typography-text strong>{{ $t('warehouse.type_of_shipping') }}: </a-typography-text>
                <span>{{ $t($enumLangkey('transportType', info.transportType)) }}</span>
              </a-col>
              <a-col :xl="8" :span="24">
                <template v-if="info.transportType === transportTypeEnum.maritimeTransport">
                  <a-typography-text strong>{{ $t('warehouse.ship_division') }}: </a-typography-text>
                  <span>{{ info.vesselCompany || '-' }}</span>
                </template>
                <template v-else-if="info.transportType === transportTypeEnum.airTransport">
                  <a-typography-text strong>{{ $t('warehouse.aviation_division') }}: </a-typography-text>
                  <span>{{ info.flightCompany || '-' }}</span>
                </template>
                <span v-else>-</span>
              </a-col>
              <a-col :span="24">
                <a-typography-text strong>{{ $t('warehouse.type_of_goods_accepted') }}: </a-typography-text>
                <span v-if="info.transportCharacteristics.length">
                  <a-tag color="orange" v-for="(item, index) in info.transportCharacteristics" :key="index">
                    {{ $t($enumLangkey('transportCharacteristics', item)) }}
                  </a-tag>
                </span>
                <span v-else>-</span>
              </a-col>
              <template v-if="info.transportType === transportTypeEnum.maritimeTransport">
                <a-col :xl="12" :span="24">
                  <a-typography-text strong>{{ $t('warehouse.booking_number') }}: </a-typography-text>
                  <span>{{ info.bookingNo || '-' }}</span>
                </a-col>
                <a-col :xl="12" :span="24">
                  <a-typography-text strong>{{ $t('warehouse.cabinet_no') }}: </a-typography-text>
                  <span>{{ info.cabinetNo || '-' }}</span>
                </a-col>
              </template>
              <template v-else-if="info.transportType === transportTypeEnum.airTransport">
                <a-col :span="24">
                  <a-typography-text strong>{{ $t('warehouse.booking_number') }}: </a-typography-text>
                  <span>{{ info.bookingNo || '-' }}</span>
                </a-col>
              </template>
              <a-col :xl="12" :span="24">
                <a-typography-text strong>{{ $t('warehouse.port_of_departure') }}: </a-typography-text>
                <span>{{ info.fromPortCode || '-' }}</span>
              </a-col>
              <a-col :xl="12" :span="24">
                <a-typography-text strong>{{ $t('warehouse.port_of_destination') }}: </a-typography-text>
                <span>{{ info.toPortCode || '-' }}</span>
              </a-col>
              <template v-if="info.transportType === transportTypeEnum.maritimeTransport">
                <a-col :xl="12" :span="24">
                  <a-typography-text strong>{{ $t('warehouse.name_of_ship') }}: </a-typography-text>
                  <span>{{ info.vessel || '-' }}</span>
                </a-col>
                <a-col :xl="12" :span="24">
                  <a-typography-text strong>{{ $t('warehouse.voyage_no') }}: </a-typography-text>
                  <span>{{ info.voyageNumber || '-' }}</span>
                </a-col>
              </template>
              <template v-else-if="info.transportType === transportTypeEnum.airTransport">
                <a-col :xl="12" :span="24">
                  <a-typography-text strong>{{ $t('logistics.flight_number') }}: </a-typography-text>
                  <span>{{ info.flightNumber || '-' }}</span>
                </a-col>
                <a-col :xl="12" :span="24">
                  <a-typography-text strong>{{ $t('logistics.take_off_time') }}: </a-typography-text>
                  <span>{{ $filters.utcToSpecificTime(info.cargoCutoffTime, info.fromTimezone, "yyyy-MM-DD") }}</span>
                </a-col>
              </template>
              <a-col :xl="12" :span="24">
                <a-typography-text strong>{{ $t('warehouse.closing_date') }}: </a-typography-text>
                <span>{{ $filters.utcToSpecificTime(info.vgmCutoffTime, info.fromTimezone, "yyyy-MM-DD") }}</span>
              </a-col>
              <a-col :xl="12" :span="24">
                <a-typography-text strong>ETD: </a-typography-text>
                <span>{{ $filters.utcToSpecificTime(info.etdTime, info.fromTimezone, "yyyy-MM-DD") }}</span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :xxl="6" :xl="8" :span="24">
            <div class="text-center"><a-typography-text strong>{{ $t('warehouse.status') }}</a-typography-text></div>
            <div class="text-center">
              <a-typography-text type="success">
                <span style="font-size: 20px;">{{ $t($enumLangkey('shippingSpaceStatus', info.status)) }}</span>
              </a-typography-text>
            </div>
          </a-col>
        </a-row>
        <a-row class="mt-4 mb-1" :gutter="16" type="flex" justify="space-between"  >
          <a-col>
            <strong>
              <span style="font-size: 16px;">{{ $t('warehouse.waybill_list') }}</span>
            </strong>
          </a-col>
           <a-col v-if="transportPlanList?.length > 0">
                  <a-button type="primary" 
                     :loading="exportPackingListLoading"
                   ghost @click="handleExportPackingList" >{{ $t('warehouse.export_packing_list') }}</a-button>
            </a-col>
        </a-row>
        <a-divider style="margin: 0;" />
        <a-table
          class="mt-3"
          size="small"
          :columns="transportPlanColumns"
          :data-source="transportPlanList"
          :scroll="{ x: 500 }"
          :pagination="false"
          :row-key="(record) => record.id"
          :row-class-name="setTableWaringBackground"
        >
          <template #specificationsTitle>
            {{ $t('common.volume') }}/{{ $t('common.weight') }}
          </template>
          <template #transportCharacteristics="{ record }">
            <a-row :gutter="[4, 4]">
              <a-col v-for="(item, index) in record.transportCharacteristics" :key="index">
                <a-tag style="margin: 0;">
                  {{ $t($enumLangkey('transportCharacteristics', item)) }}
                </a-tag>
              </a-col>
            </a-row>
          </template>
          <template #transportType="{ record }">
            {{ $t($enumLangkey('transportType', record.transportType)) }}
          </template>
          <template #destinationType="{ record }">
            {{ $t($enumLangkey('destinationType', record.destinationType)) }}
          </template>
          <template #toCountry="{ record }">
            {{ getLanguageText(record.toCountryCnName, record.toCountryEnName) }}/{{ getLanguageText(record.toCityCnName, record.toCityEnName) }}
          </template>
          <template #logisticsName="{ record }">
            {{ record.logisticsName || '-' }}
          </template>
          <template #specificationsCustomer="{ record }">
            <template v-if="record.transportVolume && record.transportWeight">
              <div>
                {{ cmCubicToM(record.transportVolume) }}m<sup>3</sup> / {{ gToKg(record.transportWeight) }}kg
              </div>
              <div>{{ getAcquirerStr(record.transportVolume, record.transportWeight) }}</div>
            </template>
            <span v-else>-</span>
          </template>
          <template #footer>
            <a-row :gutter="32" type="flex" justify="end" align="middle">
              <a-col>
                <a-row :gutter="8">
                  <a-col>{{ $t('common.the_total_number_of_boxes') }}:</a-col>
                  <a-col>{{ getTotalCarton }} Carton</a-col>
                </a-row>
              </a-col>
              <a-col>
                <a-row type="flex" align="middle" :gutter="8">
                  <a-col>
                    <div>{{ cmCubicToM(getTotalVolume) }}m<sup>3</sup>/{{ gToKg(getTotalWeight) }}KG</div>
                    <div>{{ getAcquirerStr(getTotalVolume, getTotalWeight) }}</div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </template>
        </a-table>
      </a-spin>
      <TransportTrajectoryList class="mt-4" :loading="transportTrajectoryLoading" :list="transportTrajectoryList" />
      <a-row class="mt-4 mb-4 pr-3" :gutter="16" type="flex" justify="end">
        <a-col>
          <a-button type="ghost" @click="handleBack">{{ $t('common.close') }}</a-button>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { Button, Card, Col, Divider, Row, Spin, Table, Tag, Typography, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import TransportTrajectoryList from './components/TransportTrajectoryList.vue';
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useTab } from "@/hooks/tabs/index";
import { getShippingSpaceConsole, getShippingHistoryByShipping ,exportPackingList} from '@/api/modules/transport/cabin.js';
import { getList } from '@/api/modules/transport/list.js';
import { setFormStateValue, cmCubicToM, gToKg,dateString  } from '@/utils/general.js';
import { transportType as transportTypeEnum } from '@/enum/enum.json';
import { downloadFile } from "@/utils/downloader";

export default defineComponent({
  name: 'transport_shipment_cabin_detail',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ATypographyText: Typography.Text,
    AButton: Button,
    ATable: Table,
    ACard: Card,
    ADivider: Divider,
    ATag: Tag,
    Content,
    TransportTrajectoryList,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const transportTrajectoryListRef = ref(null);

    const state = reactive({
      loading: false,
      info: {
        id: null,
        warehouseId: null,
        shippingSpaceName: null,
        shippingSpaceNo: null,
        shippingSpaceType: null,
        transportType: null,
        vesselCompany: null,
        flightCompany: null,
        bookingNo: null,
        cabinetNo: null,
        transportCharacteristics: [],
        fromPortCode: null,
        toPortCode: null,
        vessel: null,
        voyageNumber: null,
        flightNumber: null,
        cargoCutoffTime: null,
        vgmCutoffTime: null,
        etdTime: null,
        fromTimezone: null,
        toTimezone: null,
        status: null,
      },
      transportPlanLoading: false,
      transportPlanList: [],
      // 轨迹
      transportTrajectoryLoading: false,
      transportTrajectoryList: [],

      exportPackingListLoading:false,
    });

    const transportPlanColumns = [
      {
        dataIndex: "transportPlanNo",
        width: 150,
        title: () => vueI18n.t("logistics.transportation_plan_number"),
      },
      {
        width: 250,
        title: () => vueI18n.t("warehouse.type_of_goods"),
        slots: {
          customRender: "transportCharacteristics"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.type_of_shipping"),
        slots: {
          customRender: "transportType"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.destination_type"),
        slots: {
          customRender: "destinationType"
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.destination_country_city"),
        slots: {
          customRender: "toCountry"
        }
      },
      {
        dataIndex: "transportBoxesNum",
        width: 120,
        align: "center",
        title: () => vueI18n.t("logistics.transport_box_count"),
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.logistics_scheme"),
        slots: {
          customRender: "logisticsName"
        }
      },
      {
        width: 150,
        slots: {
          title: "specificationsTitle",
          customRender: "specificationsCustomer"
        }
      },
    ];

    const getTotalCarton = computed(() => {
      return state.transportPlanList.reduce((total, item) => {
        return total+=item.transportBoxesNum;
      }, 0);
    });

    const getTotalVolume = computed(() => {
      return state.transportPlanList.reduce((total, item) => {
        return total+=item.transportVolume;
      }, 0);
    });

    const getTotalWeight = computed(() => {
      return state.transportPlanList.reduce((total, item) => {
        return total+=item.transportWeight;
      }, 0);
    });

    const setTableWaringBackground = (record) => {
      let list = record.transportCharacteristics.filter(item => !state.info.transportCharacteristics.includes(item));
      return list.length > 0 ? 'warning-item' : ''
    }

    const getLanguageText = (cnName, enName) => {
      if (getters.language === 'zh-CN') {
        return cnName || '-';
      } else {
        return enName || '-';
      }
    }

    const handleBack = () => {
      delVisitedRoute(route);
      router.push('/transport/shipment/cabin');
    }

    const getAcquirerStr = (volume, weight) => {
      if (!volume || !weight) {
        return '-';
      }
      let result = Math.floor(gToKg(weight) / cmCubicToM(volume) * 10) / 10;
      return '1 : ' + result;
    }

    // 获取货物列表
    const getTransportList = () => {
      state.transportPlanLoading = true;
      const data = {
        shippingSpaceId: state.info.id,
        isIncludingCancel: false,
      }
      getList(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.transportPlanList = result;
        } else {
          state.transportPlanList = [];
        }
      }).catch(() => {}).finally(() => {
        state.transportPlanLoading = false;
      });
    }

    // 获取轨迹列表
    const getTransportTrajectoryList = () => {
      state.transportTrajectoryLoading = true;
      const data = {
        spaceId: state.info.id,
        warehouseId: state.info.warehouseId,
      }
      getShippingHistoryByShipping(data).then(({ result }) => {
        if (Array.isArray(result)) {
          state.transportTrajectoryList = result;
        } else {
          state.transportTrajectoryList = [];
        }
      }).catch(() => {}).finally(() => {
        state.transportTrajectoryLoading = false;
      });
    }

    const getDetail = (needRefreshTransport = true) => {
      state.loading = true;
      getShippingSpaceConsole({ id: state.info.id }).then(({ result }) => {
        if (result) {
          setFormStateValue(state.info, result);
          if (needRefreshTransport) {
            getTransportList();
          }
          getTransportTrajectoryList();
        } else {
          message.error(vueI18n.t('warehouse.anomaly_details'));
          handleBack();
        }
      }).catch((error) => {
      }).finally(() => {
        state.loading = false;
      });
    }

     const handleExportPackingList=()=>{
       state.exportPackingListLoading = true;
      let url = exportPackingList()
      const postData = {
        shippingSpaceId: route.params?.id,
      };
      downloadFile(url, `${state.info?.shippingSpaceNo}_packing_list_${dateString()}.xlsx`, "POST", postData)
        .then(() => {
          state.exportPackingListLoading = false
        })
        .catch(() => {
          state.exportPackingListLoading = false
        })
    }

    onMounted(() => {
      let id = route.params?.id;
      if (id) {
        state.info.id = id;
        getDetail();
      } else {
        message.error(vueI18n.t('warehouse.anomaly_details'));
        handleBack();
      }
    })

    return {
      ...toRefs(state),
      cmCubicToM,
      gToKg,
      transportTypeEnum,
      transportTrajectoryListRef,
      transportPlanColumns,
      getTotalCarton,
      getTotalVolume,
      getTotalWeight,
      setTableWaringBackground,
      getLanguageText,
      getAcquirerStr,
      handleBack,
      getDetail,
      handleExportPackingList
    }
  }
})
</script>

<style lang="less" scoped>
:deep(.warning-item) td {
  background-color: #ffd591;
}
</style>
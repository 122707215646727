<template>
  <div id="header" :class="[isMobile && 'layout-mobile', 'layout-header', 'light-header']">
    <template v-if="layout !== 'layout-head'">
      <div class="header-control">
        <!-- 左侧缩进功能键 -->
        <div class="menu-item">
          <!-- 左侧缩进功能键盘 -->
          <!-- <AlignLeftOutlined v-if="collapsed" />
                        <AlignRightOutlined v-else /> -->
          <MenuUnfoldOutlined v-if="collapsed"  @click="menu_trigger()"/>
          <MenuFoldOutlined v-else  @click="menu_trigger()"/>
        </div>
        <!-- 刷新当前页面路由 -->
        <!-- <div class="menu-item">
          <ReloadOutlined v-if="routerActive"  @click="menu_refresh()"/>
          <LoadingOutlined v-else  @click="menu_refresh()"/>
        </div> -->
      </div>
    </template>

    <template v-else>
      <div class="header-logo">
          <Logo></Logo>
      </div>
      <div class="header-menus" v-if="!isMobile">
        <Menu></Menu>
      </div>
      <div style="flex:1;display:flex;" v-else>
        <!-- 左侧缩进功能键 -->
        <div class="menu-item" @click="menu_trigger()">
          <!-- 左侧缩进功能键盘 -->
          <!-- <AlignLeftOutlined v-if="collapsed" />
                        <AlignRightOutlined v-else /> -->
          <MenuUnfoldOutlined v-if="collapsed" />
          <MenuFoldOutlined v-else />
        </div>
        <!-- 刷新当前页面路由 -->
        <!-- <div class="menu-item" @click="menu_refresh()">
          <ReloadOutlined v-if="routerActive" />
          <LoadingOutlined v-else />
        </div> -->
      </div>
    </template>

    <div class="header-actions">
      <a-space >
        <div class="menu-item" v-if="!isFull">
          <ExpandOutlined  @click="fullScreen()"/>
        </div>
        <div class="menu-item" v-else >
          <CompressOutlined @click="fullScreen()"/>
        </div>
        <div class="menu-item">
          <LangChooser></LangChooser>
        </div>
       <div class="menu-item">
            <a-dropdown class="avatar-item no-select" placement="bottomRight">
                  <a-avatar 
                  v-if="userInfo && userInfo.phone"
                  size="small"
                    :src="userInfo.phone"
                  ></a-avatar>
                  <UserOutlined v-else />
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <router-link to="/account/info">{{$t('menu.settings')}}</router-link>
                      </a-menu-item>
                      <a-menu-item @click="logout">
                        <a href="javascript:;">{{$t('common.logout')}}</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
            </a-dropdown>
       </div>
        <!-- <div v-if="!isMobile" class="menu-item" @click="menu_setting()">
          <MoreOutlined />
        </div> -->
      </a-space>
    </div>

    <!-- 综合菜单 -->
    <!-- <div v-if="layout == 'layout-comp'" class="comp-menu">
            <template :key="index" v-for="(route, index) in routes">
                <div @click="changeMenu(route)" class="menu-item" :class="[active === route.path ? 'is-active' : '']">
                    <span>{{ route.meta.title }}</span>
                </div>
            </template>
        </div> -->
  </div>
</template>

<script>
import { computed,toRefs, reactive } from "vue";
import { useStore } from "vuex";
import {
  AlignLeftOutlined,
  AlignRightOutlined,
  MoreOutlined,
  ExpandOutlined,
  CompressOutlined,
  ReloadOutlined,
  GlobalOutlined,
  BellOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
import { useFullScreen } from "@/utils/fullScreen.js";
import { useRoute, useRouter } from "vue-router";
import { useTab } from '@/hooks/tabs/index';
import Menu from "../menu/Index";
import * as i18nLocal from "@/locale/index.js"
import Logo from '../logo/index.vue'
import LangChooser from '../../../components/LangChooser.vue'
import { Space, Dropdown, Avatar, Menu as AMenu, Button as AButton, Popover as APopver } from 'ant-design-vue';
export default {
  name: "PageHeader",
  components: {
    AlignLeftOutlined,
    AlignRightOutlined,
    MoreOutlined,
    ExpandOutlined,
    CompressOutlined,
    ReloadOutlined,
    GlobalOutlined,
    BellOutlined,
    LoadingOutlined,
    Menu,
    Logo,
    ASpace: Space,
    ADropdown: Dropdown,
    AAvatar: Avatar,
    AMenu,
    AMenuItem:AMenu.Item,
    AButton,
    APopver,
    LangChooser,
  },
  setup() {
    const { getters, commit, dispatch } = useStore();
    const userInfo = getters.userInfo;
    const { isFull, fullScreen } = useFullScreen();
    const {delAllRoutes} = useTab();
    const routerActive = computed(() => getters.routerActive);
    const collapsed = computed(() => getters.collapsed);
    const isMobile = computed(() =>  getters.isMobile);
    const router = useRouter();
    const menu_trigger = () => {
      commit("app/TOGGLE_COLLAPSED");
    };
    const menu_refresh = async () => {
      commit("app/UPDATE_ROUTER_ACTIVE", false);
      setTimeout(() => {
        commit("app/UPDATE_ROUTER_ACTIVE", true);
      }, 500);
    };
    const layout = computed(()=>getters.layout);

    const logout = async ()=>{
      delAllRoutes();
      await dispatch("user/logout")
      router.push("/login");
    };

    return {
      userInfo,
      routerActive,
      collapsed,
      fullScreen,
      menu_trigger,
      menu_refresh,
      layout,
      isFull,
      isMobile,
      logout,
    };
  },
};
</script>

<style lang="less" scoped>
#header{
  border-bottom:1px solid #f0f0f0;
  margin-bottom: 5px;
  
}

.light-header {
  background: #fff;
  .header-logo{
    width: 50px;
    height: 50px;
  }
}
.layout-header {
  display: flex;
  justify-content: space-between;
  .header-control,
  .header-logo {
    display: flex;
    padding: 0 10px;
  }
  .menu-item{
    padding: 0 5px;
    *{
    cursor:pointer;
    }
  }
  .header-menus {
    height: 100%;
    flex: 1;
    min-width: 0;
  }
  .header-actions {
    display: flex;
    padding: 0 10px;
    align-items: center;
  }
}
</style>

<template>
    <Content>
        <template #contentTitle>
            {{ $t('物流分区') }}
        </template>
        <template v-slot:contentBody="wrap">
            <div class="lg-row border-b pb-3 mb-3">
                <div class="content">
                    <div class="d-inline-block">
                        <span class="d-inline-block mr-2">{{ $t('物流方案') }}:</span>
                        <b
                            class="d-inline-block mr-2"
                            v-if="tableData.logisticsData.code"
                        >{{ tableData.logisticsData.code }} ({{ tableData.logisticsData.name }})</b>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="actions">
                    <a-button size="small">
                        <router-link
                            :to="{ name: 'logistics_region_edit', params: { logisticsId: logisticsId } }"
                        >
                            <span>+</span>
                            <small>{{ $t('common.addition') }}</small>
                        </router-link>
                    </a-button>
                </div>
            </div>
            <div>
                <a-table
                    :columns="columns"
                    size="small"
                    :data-source="tableData.tableList"
                    :pagination="false"
                    :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
                    :rowKey="(record, index) => { return index; }"
                    :loading="tableData.loading"
                >
                    <template v-slot:tableIndex="{ index }">{{ index + 1 }}</template>
                    <template v-slot:regionName="{ record }">
                        <div>{{ record.name }}</div>
                        <div>
                            <small v-if="!record.feeTemplateId" class="text-warning mr-2">无费用模板</small>
                            <small v-if="!record.surchargeConfigId" class="text-warning mr-2">无附加费模板</small>
                        </div>
                    </template>
                    <template v-slot:isVatRequired="{ record }">
                        <small v-if="record.isVatRequired" class="text-primary">已启用</small>
                        <small v-else>未启用</small>
                    </template>
                    <template v-slot:services="{ record }">
                        <div v-if="record.isDdpSupported">
                            <small>
                                <span class="d-inline-block">关税预付</span>
                                <span
                                    class="d-inline-block"
                                >(费用:{{ record.currencyCode }} {{ record.currencySymbol }}{{ $filters.amountToFixed2(record.ddpFee) }})</span>
                            </small>
                        </div>
                        <div v-if="record.isSignSupported">
                            <small>
                                <span class="d-inline-block">签名签收:</span>
                                <div class="pl-2">                                    
                                    <div v-for="signatureItem in record.signatureDtos">
                                        {{$t($enumLangkey("signatureType", signatureItem.signatureType)) }}:{{ record.currencyCode }} {{ record.currencySymbol }}{{ $filters.amountToFixed2(signatureItem.fee) }}
                                    </div>
                                </div>
                            </small>
                        </div>
                    </template>
                    <template v-slot:regions="{ record }">
                        <div
                            v-if="record.regionCountryRls
                            && record.regionCountryRls.length > 0
                            && (record.regionType & regionTypeEnum.country) > 0"
                        >
                            <small class="mr-1">{{ $t('common.country') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="country in record.regionCountryRls"
                                    :key="country.countryId"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: country.countryCnName, enName: country.countryEnName }) }}</small>
                                <small v-if="record.hasMoreCountryRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.regionProvinceRls
                            && record.regionProvinceRls.length > 0
                            && (record.regionType & regionTypeEnum.province) > 0"
                        >
                            <small class="mr-1">{{ $t('common.province') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="province in record.regionProvinceRls"
                                    :key="province.provinceId"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: province.provinceCnName, enName: province.provinceEnName }) }}({{ getLanguageName({ cnName: province.countryCnName, enName: province.countryEnName }) }})</small>
                                <small v-if="record.hasMoreProvinceRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.regionCityRls
                            && record.regionCityRls.length > 0
                            && (record.regionType & regionTypeEnum.city) > 0"
                        >
                            <small class="mr-1">{{ $t('common.city') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="city in record.regionCityRls"
                                    :key="city.cityId"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: city.cityCnName, enName: city.cityEnName }) }}({{ getLanguageName({ cnName: city.countryCnName, enName: city.countryEnName }) }})</small>
                                <small v-if="record.hasMoreCityRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.regionCountyRls
                            && record.regionCountyRls.length > 0
                            && (record.regionType & regionTypeEnum.county) > 0"
                        >
                            <small class="mr-1">{{ $t('common.county') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="county in record.regionCountyRls"
                                    :key="county.countyId"
                                    class="mr-1 d-inline-block"
                                >{{ getCountyName({ cnName: county.countyCnName, enName: county.countyEnName, cityCnName: county.cityCnName, cityEnName: county.cityEnName }) }}({{ getLanguageName({ cnName: county.countryCnName, enName: county.countryEnName }) }})</small>
                                <small v-if="record.hasMoreCountyRl">...</small>
                            </span>
                        </div>
                        <div
                            v-if="record.regionPostCodeRls
                            && record.regionPostCodeRls.length > 0
                            && (record.regionType & regionTypeEnum.post) > 0"
                        >
                            <small class="mr-1">{{ $t('common.post_code') }}:</small>
                            <span class="items-wrap">
                                <small
                                    v-for="(postCode, index) in record.regionPostCodeRls"
                                    :key="index"
                                    class="mr-1 d-inline-block"
                                >{{ getLanguageName({ cnName: postCode.countryCnName, enName: postCode.countryEnName }) }}{{ postCode.postStartWith }}开头</small>
                                <small v-if="record.hasMorePostCodeRl">...</small>
                            </span>
                        </div>
                        <!-- <div v-if="record.hasMoreRegionRl">
                            <a @click="handleViewRegions(record.id)">
                                <small>{{ $t('更多') }}</small>
                            </a>
                        </div>-->
                        <div>
                            <a @click="handleViewRegions(record.id)">
                                <small>{{ $t('查看') }}</small>
                            </a>
                        </div>
                    </template>
                    <template v-slot:operation="{ record }">
                        <div>
                            <a-button type="link" size="small">
                                <router-link
                                    :to="{ name: 'logistics_region_edit', params: { logisticsId: record.logisticsId, id: record.id } }"
                                >
                                    <small>配置</small>
                                </router-link>
                            </a-button>
                        </div>
                        <div>
                            <a-popconfirm
                                :title="$t('common.are_you_sure_you_want_to_delete_this_item')"
                                @confirm="handleDeleteRegion(record.id)"
                            >
                                <a-button type="link" size="small" class="text-error">
                                    <small>删除</small>
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </template>
                </a-table>
            </div>
            <a-modal
                v-model:visible="viewModalData.visible"
                :title="'分区信息'"
                :width="600"
                :footer="null"
                @cancel="handleCloseViewRegionsModal"
                :destroyOnClose="true"
            >
                <a-spin size="small" :spinning="viewModalData.loading">
                    <div v-if="viewModalData.regionInfo && viewModalData.regionInfo.id">
                        <a-descriptions :column="1">
                            <a-descriptions-item label="分区名称">{{ viewModalData.regionInfo.name }}</a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.country)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.country') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.regionCountryRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="country in viewModalData.regionInfo.regionCountryRls"
                                        :key="country.countryId"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: country.countryCnName, enName: country.countryEnName }) }}</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.province)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.province') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.regionProvinceRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="province in viewModalData.regionInfo.regionProvinceRls"
                                        :key="province.provinceId"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: province.provinceCnName, enName: province.provinceEnName }) }}({{ getLanguageName({ cnName: province.countryCnName, enName: province.countryEnName }) }})</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.city)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.city') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.regionCityRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="city in viewModalData.regionInfo.regionCityRls"
                                        :key="city.cityId"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: city.cityCnName, enName: city.cityEnName }) }}({{ getLanguageName({ cnName: city.countryCnName, enName: city.countryEnName }) }})</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.county)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.county') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.regionCountyRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="county in viewModalData.regionInfo.regionCountyRls"
                                        :key="county.countyId"
                                        class="mr-1 d-inline-block"
                                    >{{ getCountyName({ cnName: county.countyCnName, enName: county.countyEnName, cityCnName: county.cityCnName, cityEnName: county.cityEnName }) }}({{ getLanguageName({ cnName: county.countryCnName, enName: county.countryEnName }) }})</small>
                                </div>
                                <span v-else>-</span>
                            </a-descriptions-item>
                            <a-descriptions-item>
                                <template #label>
                                    <span
                                        v-if="checkHasType(viewModalData.regionInfo, regionTypeEnum.post)"
                                        class="text-primary"
                                    >
                                        <CheckSquareOutlined />
                                    </span>
                                    <span v-else class="text-warning">
                                        <CloseSquareOutlined />
                                    </span>
                                    {{ $t('common.post_code') }}
                                </template>
                                <div
                                    v-if="checkArrAny(viewModalData.regionInfo.regionPostCodeRls)"
                                    class="items-wrap"
                                >
                                    <small
                                        v-for="(postCode, index) in viewModalData.regionInfo.regionPostCodeRls"
                                        :key="index"
                                        class="mr-1 d-inline-block"
                                    >{{ getLanguageName({ cnName: postCode.countryCnName, enName: postCode.countryEnName }) }}{{ postCode.postStartWith }}开头</small>
                                </div>

                                <span v-else>-</span>
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                    <a-empty v-else :image="simpleImage" />
                </a-spin>
            </a-modal>
        </template>
        <template #contentFooter>
            <CPager class="text-center" @handlePage="handlePage" :page-data="tableData.pageData"></CPager>
        </template>
    </Content>
</template>

<script>
import { Table, Button, Row, Col, Popconfirm, Modal, Empty, Spin, Descriptions, Tag } from 'ant-design-vue';
import CPager from '../components/CPager.vue'
import Content from '../components/Content.vue'
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, reactive, toRefs, ref, onActivated, } from 'vue'
import { getPagedList, deleteById, getById } from '../../api/modules/logistics/region'
import { getById as getLogisticsById } from '../../api/modules/logistics/index'
import { getName } from "../../utils/general"
import { useStore } from "vuex"
import { useI18n } from 'vue-i18n/index'
import { logisticsRegionType as regionTypeEnum, regionTag as regionTagEnum } from '../../enum/enum.json'
export default defineComponent({
    name: "logistics_region",
    components: {
        ATable: Table,
        AButton: Button,
        CPager,
        Content,
        ARow: Row,
        ACol: Col,
        APopconfirm: Popconfirm,
        AModal: Modal,
        AEmpty: Empty,
        ASpin: Spin,
        ADescriptions: Descriptions,
        ADescriptionsItem: Descriptions.Item,
        ATag: Tag
    },
    setup() {
        const route = useRoute();
        const { getters } = useStore();
        const vueI18n = useI18n({ useScope: 'global' });
        const logisticsId = route.params.logisticsId;

        const columns = [
            {
                width: 80,
                fixed: "left",
                title: () => "序号",
                slots: {
                    customRender: "tableIndex"
                }
            },
            {
                dataIndex: "name",
                width: 150,
                fixed: "left",
                title: () => "分区名称",
                slots: {
                    customRender: "regionName"
                }
            },
            {
                width: 150,
                title: () => "Vat填写",
                slots: {
                    customRender: "isVatRequired"
                }
            },
            {
                width: 150,
                title: () => "增值服务",
                slots: {
                    customRender: "services"
                }
            },
            {
                width: 300,
                title: () => "适用区域",
                slots: {
                    customRender: "regions"
                }
            },
            {
                width: 80,
                title: () => '',
                slots: {
                    customRender: "operation",
                }
            },

        ];

        const tableData = reactive({
            loading: false,
            tableList: [],
            pageData: {
                currentIndex: 1,
                skipCount: 0,
                totalCount: 0,
                maxResultCount: 10
            },
            logisticsData: {
                name: null,
                code: null
            },
            searchData: {

            },
        });

        const viewModalData = reactive({
            visible: false,
            loading: false,
            regionId: null,
            regionInfo: {}
        });

        const getLanguageName = (item) => {
            return getName(item, getters.language);
        }

        const getCountyName = (item) => {
            let countyName = getLanguageName(item);
            let cityName = getLanguageName({ cnName: item.cityCnName, enName: item.cityEnName })
            return cityName
                ? (countyName + "-" + cityName)
                : countyName;
        };

        const getLogisticsInfo = () => {
            tableData.loaging = true;
            getLogisticsById(logisticsId)
                .then((res) => {
                    tableData.loading = false;
                    let { result } = res;
                    tableData.logisticsData.name = result.name;
                    tableData.logisticsData.code = result.code;
                })
                .catch(() => {
                    tableData.loading = false;
                });
        }

        const getRegionList = () => {
            tableData.loading = true;
            getPagedList({
                logisticsId: logisticsId,
                skipCount: tableData.pageData.skipCount,
                maxResultCount: tableData.pageData.maxResultCount,
            })
                .then((res) => {
                    let { totalCount, items } = res.result;
                    tableData.pageData.totalCount = parseInt(totalCount);
                    tableData.tableList = items;
                    tableData.loading = false;
                }).catch(() => {
                    tableData.loading = false;
                })
        }

        const getRegionInfo = () => {
            if (viewModalData.regionId) {
                viewModalData.loading = true;
                getById(viewModalData.regionId)
                    .then((res) => {
                        viewModalData.loading = false;
                        let { result } = res;
                        viewModalData.regionInfo = result;
                    }).catch(() => {
                        viewModalData.loading = false;
                    })
            } else {
                viewModalData.regionInfo = {};
            }
        }

        const handlePage = (pageData) => {
            tableData.pageData.skipCount = pageData.skipCount;
            tableData.pageData.maxResultCount = pageData.maxResultCount;
            getRegionList();
        };

        const handleRefresh = () => {
            tableData.pageData.skipCount = 0;
            tableData.pageData.currentIndex = 1;
            getRegionList();
        };

        const handleViewRegions = (id) => {
            // if (viewModalData.regionId != id) {
            //     viewModalData.regionId = id;
            //     getRegionInfo();
            // }
            viewModalData.regionId = id;
            getRegionInfo();
            viewModalData.visible = true;
        }

        const handleCloseViewRegionsModal = ()=>{
            viewModalData.regionId = null;
            viewModalData.regionInfo = {};
        }

        const handleDeleteRegion = (id) => {
            deleteById(id)
                .then(() => {
                    getRegionList();
                })
        }

        const checkArrAny = (arr) => {
            return arr && arr.length > 0;
        }

        const checkHasType = (item, regionType) => {
            if (item && item.regionTypes && item.regionTypes.length > 0) {
                return item.regionTypes.findIndex(x => x == regionType) >= 0;
            }
            return false;
        }

        onActivated(() => {
            getLogisticsInfo();
            getRegionList();
        });
        return {
            logisticsId,
            regionTypeEnum,

            columns,
            tableData,
            viewModalData,

            handlePage,
            handleRefresh,
            handleViewRegions,
            handleCloseViewRegionsModal,
            handleDeleteRegion,
            getLanguageName,
            getCountyName,
            checkArrAny,
            checkHasType,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
        }
    }
})
</script>
<style lang="less" scoped>
.border-b {
    border-bottom: 1px solid #f0f0f0;
}
.lg-row {
    display: flex;
    flex-wrap: wrap;
    // justify-content: stretch;
    align-items: stretch;
    .content {
        flex-grow: 1;
    }
    .actions {
        flex-grow: 0;
    }
}
.items-wrap > *::after {
    content: ",";
    display: inline-block;
}
.items-wrap > *:last-child::after {
    content: none;
}
</style>
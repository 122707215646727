<template>
  <a-modal
    v-model:visible="visible"
    :confirmLoading="btnLoading"
    :title="$t('common.edit') + $t('menu.customer_level_list')"
    @ok="handleOk"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item :label="$t('logistics.level')">
          <a-select v-model:value="formState.shopLevelId" allowClear>
            <a-select-option
              v-for="(item, index) in list"
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from "vue";
import { Modal, Form, Select, message, Spin } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { getShopLevelList, updateCompanyShopLevel } from "@/api/modules/customer/level";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpin: Spin,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref();

    const state = reactive({
      visible: false,
      loading: false,
      btnLoading: false,
      formState: {
        shopId: null,
        shopLevelId: null,
      },
      list: []
    });

    const handleOk = async () => {
      try {
        await formRef.value.validate();
        state.btnLoading = true;
        const data = JSON.parse(JSON.stringify(state.formState));
        await updateCompanyShopLevel(data);
        message.success(vueI18n.t("common.succeed"));
        state.visible = false;
        emit("refresh");
      } catch (error) {
      } finally {
        state.btnLoading = false;
      }
    };

    const init = (id, levelId) => {
      state.formState.shopId = id;
      state.formState.shopLevelId = levelId;
      state.visible = true;
      state.loading = true
      getShopLevelList().then((res) => {
        let { result } = res
        state.list = result
      }).finally(() => {
        state.loading = false
      })
    };

    return {
      ...toRefs(state),
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      handleOk,
      init,
    };
  },
});
</script>

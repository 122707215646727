<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('warehouse.operation_tips')"
    :mask-closable="false"
    :closable="false"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :ok-text="$t('common.close')"
    @ok="handleClose"
  >
    <div class="mb-3" style="text-align: center;">{{ $t(titleStr) }}</div>
    <a-row :gutter="[16, 16]">
      <a-col :span="8" style="text-align: right;">{{ $t('warehouse.application_id') }}:</a-col>
      <a-col>{{ appId }}</a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="8" style="text-align: right;">{{ $t('warehouse.app_secret') }}:</a-col>
      <a-col>{{ appSecret }}</a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { Col, Modal, Row } from 'ant-design-vue';
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
  },
  setup () {
    const state = reactive({
      visible: false,
      titleStr: "",
      appId: "",
      appSecret: "",
    })

    const show = (appId, appSecret, titleStr) => {
      state.appId = appId
      state.appSecret = appSecret
      state.visible = true
      state.titleStr = titleStr
    }

    const handleClose = () => {
      state.visible = false
    }

    return {
      ...toRefs(state),
      show,
      handleClose,
    }
  }
})
</script>
import client from "../../client";

const Api = {
  //创建和编辑页面
  updateProduct: "/api/ProductService/ProductManage/Update",

  getPackingVerify: "/api/ProductService/PackingVerify/Get",

  getProducInfo: "/api/ProductService/Product/Get",

  updateProductPacking: "/api/ProductService/PackingVerify/Update",
};

export const getProducInfo = (data) => {
  return client.request({
    url: Api.getProducInfo,
    params: data,
    method: "get",
  });
};

export const updateProduct = (data) => {
  return client.request({
    url: Api.updateProduct,
    data: data,
    method: "post",
  });
};

export const getPackingVerify = (data) => {
  return client.request({
    url: Api.getPackingVerify,
    params: data,
    method: "get",
  });
};

export const updateProductPacking = ({
  id,
  productId,
  packingLength,
  packingWidth,
  packingHeight,
  packingWeight,
  checkImgUrls,
}) => {
  return client.request({
    url: Api.updateProductPacking,
    data: {
      id,
      productId,
      packingLength,
      packingWidth,
      packingHeight,
      packingWeight,
      checkImgUrls,
    },
    method: "post",
  });
};

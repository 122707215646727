<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.supplier_print_waybill_api") }}
    </template>
    <template #contentBody="wrap">
     
      <a-row type="flex" justify="space-between" align="bottom" :gutter="[16, 16]">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                style="width: 250px"
                v-model:value="searchData.keyword"
                :placeholder="$t('logistics.name_or_keyword')"
                allowClear
              />
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchData.settingId"
                :placeholder="$t('logistics.api_account_number')"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in apiAccountNumberList"
                  :key="index"
                  :value="item.value"
                  :title="item.text"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchData.objectId"
                :placeholder="$t('logistics.supply_channels')"
                :show-search="true"
                option-filter-prop="search-key"
                allowClear
              >
                <a-select-option
                  v-for="(item, index) in logisticsChannelList"
                  :key="index"
                  :value="item.value"
                  :title="`${item.text}(${item.text1})`"
                  :search-key="item.text + item.text1"
                  >{{ item.text }}({{ item.text1 }})</a-select-option
                >
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px;"
                v-model:value="searchData.isActive"
                :placeholder="$t('warehouse.status')"
                allow-clear
              >
                <a-select-option :value="1" :title="$t('logistics.enabled')">{{ $t('logistics.enabled') }}</a-select-option>
                <a-select-option :value="0" :title="$t('logistics.disabled')">{{ $t('logistics.disabled') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button
                type="primary"
                :loading="tableData.loading"
                @click="handleSearch"
                >{{ $t("common.query") }}</a-button
              >
            </a-col>
          </a-row>
        </a-col>
        <a-col
          ><a-button  @click="handleCreate">{{
            $t("common.create")
          }}</a-button></a-col
        >
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #receivingSupplyChannel="{ record }">
          {{ record.receivingSupplyChannelDto?.receivingSupplyChannel }}
        </template>
        <template #openApiRunStatus="{ record }">
          <span
            v-if="record.openApiRunStatus === openApiRunStatusEnum.normal"
            >{{ $t("common.normal") }}</span
          >
          <span
            v-else-if="
              record.openApiRunStatus === openApiRunStatusEnum.exception
            "
            class="text-error"
            >{{ $t("common.exception") }}</span
          >
        </template>
        <template #openApiCategory="{ record }">
          {{ $t($enumLangkey("openApiCategory", record.openApiCategory)) }}
        </template>
        <template #openApiCode="{ record }">
          {{ $t($enumLangkey("openApiCode", record.openApiCode)) }}
        </template>
        <template #creationTime="{ record }">{{
          $filters.utcToCurrentTime(record.creationTime)
        }}</template>
        <template #chanel="{ record }">
          <span v-if="record.chanel">{{ record.chanel?.name }}</span>
          <span v-else>-</span>
        </template>
        <template #isSupport="{ record }">
          <a-tag v-if="record.isSupportCommercialAddr">{{ $t('logistics.business_site') }}</a-tag>
          <a-tag v-if="record.isSupportResidentialAddr">{{ $t('logistics.residence') }}</a-tag>
        </template>
        <template #isEnable="{ record }">
          <a-switch
            v-model:checked="record.isActive"
            :loading="record.loading"
            @change="handleChangeActive(record)"
          />
        </template>
        <template #operate="{ record }">
          <a-dropdown-button @click="handleEdit(record.id)">
            {{ $t('common.edit') }}
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1" :record="record">{{ $t('common.delete') }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <PrintWaybillApiModal
        ref="printWaybillApiModalRef"
        :apiAccountNumberList="apiAccountNumberList"
        :logisticsChannelList="logisticsChannelList"
        @refresh="handleInitSearch"
      />
    </template>
    <template #contentFooter
      ><CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager
    ></template>
  </Content>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { useI18n } from "vue-i18n/index";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import PrintWaybillApiModal from './components/PrintWaybillApiModal.vue';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Switch,
  Table,
  Tag,
  Space,
  Popconfirm,
  message,
  Dropdown,
  Menu,
  Modal,
} from "ant-design-vue";
import {
  getConfigurationPagedList,
  updateOpenApiInterfaceConfigurationActive,
  deleteOpenApiInterfaceConfiguration,
  getAllCanChooseSetting,
  getAllCanChooseSupplier,
} from "@/api/modules/supplier/printWaybillApi";
import {
  openApiRunStatus as openApiRunStatusEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "supplier_print_waybill_api",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    ATable: Table,
    ASwitch: Switch,
    ATag: Tag,
    ASpace: Space,
    APopconfirm: Popconfirm,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    PrintWaybillApiModal,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const apiManageModalRef = ref(null);
    const formRef = ref(null);
    const printWaybillApiModalRef = ref(null);

    const state = reactive({
      apiAccountNumberList: [],
      logisticsChannelList: [],
      searchData: {
        keyword: null,
        settingId: null,
        objectId: null,
        isActive: null,
      },
      searchDataCache: {},
      tableData: {
        loading: true,
        tableList: [],
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: "name",
        title: () => vueI18n.t("logistics.billing_name"),
        width: 200,
        align: "center",
      },
      {
        dataIndex: "settingName",
        title: () => vueI18n.t("logistics.api_account_number"),
        width: 150,
        align: "center",
      },
      {
        title: () => vueI18n.t("logistics.receiving_channel_code"),
        width: 150,
        align: "center",
        slots: {
          customRender: "receivingSupplyChannel"
        }
      },
      {
        title: () => vueI18n.t("logistics.api_status"),
        width: 120,
        slots: {
          customRender: "openApiRunStatus",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("warehouse.creation_time"),
        width: 150,
        slots: {
          customRender: "creationTime",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("logistics.supply_channels"),
        width: 150,
        slots: {
          customRender: "chanel",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("finance.status"),
        width: 100,
        slots: {
          customRender: "isEnable",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("logistics.support_address"),
        width: 150,
        slots: {
          customRender: "isSupport",
        },
        align: "center",
      },
      {
        title: () => vueI18n.t("common.operate"),
        width: 150,
        slots: {
          customRender: "operate",
        },
        fixed: "right",
        align: "center",
      },
    ];

    const handleChangeActive = (record) => {
      record.loading = true;
      updateOpenApiInterfaceConfigurationActive({
        id: record.id,
        isActive: record.isActive,
      })
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .finally(() => {
          record.loading = false;
        });
    };

    const handleCreate = () => {
      printWaybillApiModalRef.value.init(0)
    };

    const handleEdit = (id) => {
      printWaybillApiModalRef.value.init(1, id)
    };

    const getPageData = () => {
      const data = Object.assign(
        {},
        state.searchDataCache,
        state.tableData.pageData
      );
      data.isActive = data.isActive === 1 ? true : data.isActive === 0 ? false : null;
      state.tableData.loading = true;
      getConfigurationPagedList(data)
        .then((res) => {
          let { result } = res;
          if (result) {
            let { items = [], totalCount = 0 } = result;
            state.tableData.pageData.totalCount = parseInt(totalCount);
            state.tableData.tableList = items.map((item) => {
              item.loading = false;
              return item;
            });
          }
        })
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleSearch = () => {
      Object.assign(state.searchDataCache, state.searchData);
      handleInitSearch();
    };

    const handleInitSearch = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageData();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageData();
    };

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          Modal.confirm({
              title: vueI18n.t("common.operate"),
              content: vueI18n.t("account.you_are_performing_a_delete_operation"),
              okText: vueI18n.t("common.confirm"),
              cancelText: vueI18n.t("common.cancel"),
              onOk: () => {
                  return deleteOpenApiInterfaceConfiguration({ id: record.id }).then(() => {
                      message.success(vueI18n.t("common.succeed"));
                      handleInitSearch();
                  });
              },
          });
          break;
        default:
          break;
      }
    }

    onMounted(async () => {
      try {
        let { result: apiAccountNumberList } = await getAllCanChooseSetting()
        state.apiAccountNumberList = apiAccountNumberList
        let { result: logisticsChannelList } = await getAllCanChooseSupplier()
        state.logisticsChannelList = logisticsChannelList
        handleInitSearch()
      } catch (error) {
      }
    });

    return {
      ...toRefs(state),
      apiManageModalRef,
      columns,
      formRef,
      printWaybillApiModalRef,
      openApiRunStatusEnum,
      handleSearch,
      handlePage,
      handleCreate,
      handleEdit,
      handleChangeActive,
      handleInitSearch,
      handleMenuClick,
    };
  },
});
</script>

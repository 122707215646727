<template>
  <a-row type="flex">
    <a-col>
      <a-row type="flex">
        <a-col v-if="isShowTitle" class="mr-4">
          <span>{{ $t("finance.partner_commission") }}</span>
        </a-col>
        <a-col v-if="!isEdit" style="width: 100px">
          <span>{{ price || 0 }}%</span>
          <a-button type="link" size="small" @click="handleShowEdit">
            <EditOutlined />
          </a-button>
        </a-col>
        <a-col v-if="props.isShowSwitch && !isEdit" class="ml-4">
          <a-switch
            :loading="props.saving"
            @change="changeSwitch"
            :checked="enable"
          />
        </a-col>
      </a-row>
    </a-col>
    <a-col v-if="isEdit">
      <a-input-group style="width: 300px" size="small" compact>
        <a-input
          size="small"
          style="width: 40%"
          v-model:value="data.price"
          @blur="handleInputBlur"
        />
        <a-input style="width: 10%" value="%" readonly />
        <a-button
          size="small"
          style="width: 25%"
          :loading="props.saving"
          @click="hanldeSave(data)"
        >
          <small>{{ $t("common.save") }}</small>
        </a-button>
        <a-button size="small" style="width: 25%" @click="handleCancel">
          <small>{{ $t("common.cancel") }} </small>
        </a-button>
      </a-input-group>
    </a-col>
  </a-row>
</template>
<script>

import {
  Spin,
  Row,
  Col,
  Button,
  Input,
  message,
  Tooltip,
  Switch,
  InputNumber,
} from "ant-design-vue";
import { reactive, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { number } from "echarts/core";

export default {
  name: "UserDefinedBoxNo",
  components: {
    ASpin: Spin,
    AButton: Button,
    AInput: Input,
    AInputGroup: Input.Group,
    ATooltip: Tooltip,
    AInputNumber: InputNumber,
    ARow: Row,
    ACol: Col,
    ASwitch: Switch,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: 0,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    isShowSwitch: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      required: true,
      default: false,
    },
    emits: ["enable", "updateMoney"],
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const data = reactive({
      edit: false,
      price: 0,
    });

    const handleInputBlur = (e) => {
      let value = e.target.value
      value = value.replace(/[^\d.]/g, '')
                   .replace(/^\./g)
                   .replace(/\.{2,}/g, ".")
                   .replace(".", "$#$").replace(/\./g, "")
                   .replace("$#$", ".")
                   .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      if (value) {
        if (value < 0) {
          value = 0
        } else if (value > 100) {
          value = 100
        }
      } else {
        value = 0
      }
      data.price = parseFloat(value)
    }

    const handleShowEdit = () => {
      data.price = props.price ?? 0;
      emit("update:isEdit", true);
    };

    const handleCancel = () => {
      emit("update:isEdit", false);
    };

    const changeSwitch = (e) => {
      emit("update:enable", e);
      emit("enablechange", e);
    };

    const hanldeSave = () => {
      let value = data.price
      if (Number.isNaN(Number.parseFloat(value))) {
        message.error(vueI18n.t('common.exception'))
        return
      }
      emit("updateMoney", value);
    };

    watch(
      () => props.enable,
      (val) => {
        if (typeof val === "boolean") {
          data.edit = val;
        }
      }
    );

    watch(
      () => props.isEdit,
      (val) => {
        if (typeof val === "boolean") {
          data.edit = val;
        }
      }
    );

    return {
      data,
      hanldeSave,
      handleCancel,
      props,
      changeSwitch,
      handleShowEdit,
      handleInputBlur,
    };
  },
};
</script>

<style lang="less">
</style>
